import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { useFetch } from '.';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useTimeClockApi = function () {
    var _a = useFetch(), request = _a.request, toastRequest = _a.toastRequest;
    var accountId = useRecoilValue(accountIdState);
    var timeClockBaseReq = {
        accountId: accountId,
    };
    // portal time clock
    var getPortalTimeClockStatus = function () {
        return request({
            url: 'time_clock/status',
            method: 'POST',
        }, timeClockBaseReq);
    };
    var sendPortalTimeClockPins = function () {
        return toastRequest({
            url: 'time_clock/send_pins',
            method: 'POST',
        }, null, '', 'Successfully sent pins to employees!', '');
    };
    var generatePortalTimeClockPins = function () {
        return toastRequest({
            url: 'time_clock/generate_pins',
            method: 'POST',
        }, null, '', 'Successfully generated and sent new pins to employees!', '');
    };
    var useGetPortalTimeClockStatusQuery = function () {
        return useQuery(['timeClockStatus', accountId], getPortalTimeClockStatus);
    };
    var disablePortalTimeClock = function () {
        return toastRequest({
            url: 'time_clock/disable',
            method: 'POST',
        }, timeClockBaseReq, '', 'Successfully disabled portal time clock!', '');
    };
    var enablePortalTimeClock = function () {
        return toastRequest({
            url: 'time_clock/enable',
            method: 'POST',
        }, timeClockBaseReq, '', 'Successfully enabled portal time clock!', '');
    };
    var getTimeClockCheckInInfo = function () {
        return request({
            url: 'time_clock/check_in',
            method: 'GET',
        });
    };
    // mobile time clock
    var useGetMobileTimeClockStatus = function () {
        return useQuery(['useGetMobileTimeClockStatus'], function () {
            return request({
                method: 'POST',
                url: 'scheduling/mobile_clock_in_status',
            });
        });
    };
    var enableMobileTimeClock = function () {
        return toastRequest({
            url: 'scheduling/enable_mobile_clock_in',
            method: 'POST',
        }, null, '', 'Successfully enabled mobile time clock!', '');
    };
    var disableMobileTimeClock = function () {
        return toastRequest({
            url: 'scheduling/disable_mobile_clock_in',
            method: 'POST',
        }, null, '', 'Successfully disabled mobile time clock!', '');
    };
    return {
        getPortalTimeClockStatus: getPortalTimeClockStatus,
        useGetPortalTimeClockStatusQuery: useGetPortalTimeClockStatusQuery,
        disablePortalTimeClock: disablePortalTimeClock,
        enablePortalTimeClock: enablePortalTimeClock,
        sendPortalTimeClockPins: sendPortalTimeClockPins,
        getTimeClockCheckInInfo: getTimeClockCheckInInfo,
        generatePortalTimeClockPins: generatePortalTimeClockPins,
        useGetMobileTimeClockStatus: useGetMobileTimeClockStatus,
        enableMobileTimeClock: enableMobileTimeClock,
        disableMobileTimeClock: disableMobileTimeClock,
    };
};
export { useTimeClockApi };
