var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Button, Col, Divider, Input, Row } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { accountIdState } from 'src/state';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { v4 as uuidv4 } from 'uuid';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import { useSetting } from '../../../hooks/api/useSettingApi';
import GeoMap from '../../MapView/GeoMap';
import AddressCell from '../../TableView/TableCell/AddressCell';
import ZiraActionButton from '../../../ui/ZiraActionButton';
import SettingsContentHeader from '../SettingsContentHeader';
import './GeofencePage.scss';
var GeofencePage = function () {
    var accountId = useRecoilValue(accountIdState);
    var _a = useSetting(), useGetGeofenceData = _a.useGetGeofenceData, useSaveGeofence = _a.useSaveGeofence, useDeleteGeofence = _a.useDeleteGeofence, useGeGeofenceStatusSetting = _a.useGeGeofenceStatusSetting, enableGeofence = _a.enableGeofence, disableGeofence = _a.disableGeofence;
    var _b = useGeGeofenceStatusSetting(), geofenceSettingsEnableStatus = _b.data, refetch = _b.refetch, isLoading = _b.isLoading;
    var isGeofenceOn = !!geofenceSettingsEnableStatus;
    var handleGeofenceEnabled = useCallback(function (isEnabled) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isEnabled) return [3 /*break*/, 2];
                    return [4 /*yield*/, enableGeofence()];
                case 1:
                    _a.sent();
                    refetch();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, disableGeofence()];
                case 3:
                    _a.sent();
                    refetch();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var geofenceData = useGetGeofenceData({
        options: { enabled: !!geofenceSettingsEnableStatus },
    }).data;
    var saveGeofenceData = useSaveGeofence();
    var deleteGeofenceMutation = useDeleteGeofence();
    var _c = useState(geofenceData !== null && geofenceData !== void 0 ? geofenceData : []), geofences = _c[0], setGeofences = _c[1];
    var _d = useState({}), geofenceVisibleMap = _d[0], setGeofenceVisibleMap = _d[1];
    useEffect(function () {
        if (geofenceData) {
            setGeofences(geofenceData);
        }
    }, [geofenceData]);
    var onSaveSettings = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = {
                        accountId: accountId,
                        geofences: geofences,
                    };
                    return [4 /*yield*/, saveGeofenceData.mutateAsync(request, {
                            onSuccess: function (data) {
                                return setGeofences(data);
                            },
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, geofences, saveGeofenceData]);
    var changeGeofenceName = useCallback(function (index, name) {
        var tempGeofences = cloneDeep(geofences);
        tempGeofences[index].name = name;
        setGeofences(tempGeofences);
    }, [geofences]);
    var changeGeofenceRadius = useCallback(function (index, radius) {
        var tempGeofences = cloneDeep(geofences);
        tempGeofences[index].shape.radius = parseFloat(radius);
        // Refresh map
        setGeofenceVisibleMap(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[index] = false, _a)));
        });
        setTimeout(function () { return setGeofenceVisibleMap(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
        }); }, 0);
        setGeofences(tempGeofences);
    }, [geofences]);
    var deleteGeofence = useCallback(function (index) {
        var tempGeofences = cloneDeep(geofences);
        deleteGeofenceMutation.mutate(tempGeofences[index].id, {
            onSuccess: function () {
                tempGeofences.splice(index, 1);
                setGeofences(tempGeofences);
            },
        });
    }, [deleteGeofenceMutation, geofences]);
    var addGeofence = useCallback(function () {
        var tempGeofences = cloneDeep(geofences);
        tempGeofences.push({
            accountId: accountId,
            id: uuidv4(),
            name: 'Untitled Geofence',
            address: '',
            shape: {
                type: 'CIRCLE',
                latitude: 38.8976763,
                longitude: -77.0365298,
                radius: 100,
            },
        });
        setGeofenceVisibleMap(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[tempGeofences.length - 1] = true, _a)));
        });
        setGeofences(tempGeofences);
        setTimeout(function () {
            var _a;
            return (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scroll({
                top: scrollRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }, 0);
    }, [geofences, accountId]);
    var getGeofenceObj = useCallback(function (index) {
        var geofence = geofences[index];
        return {
            coordinates: {
                lat: geofence.shape.latitude,
                lng: geofence.shape.longitude,
            },
            radius: geofence.shape.radius,
        };
    }, [geofences]);
    var onUpdateAddress = useCallback(function (address, coordinates, index) {
        var tempGeofences = cloneDeep(geofences);
        tempGeofences[index].shape.latitude = coordinates.lat;
        tempGeofences[index].shape.longitude = coordinates.lng;
        tempGeofences[index].address = address;
        // Refresh map
        setGeofenceVisibleMap(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[index] = false, _a)));
        });
        setTimeout(function () { return setGeofenceVisibleMap(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
        }); }, 0);
        setGeofences(tempGeofences);
    }, [geofences]);
    var scrollRef = useRef(null);
    if (isLoading)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "GeofencePageContainer", children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Geofences" }) }), _jsxs("div", { className: "SettingsPageContainer", children: [_jsxs("div", { className: "SettingContentView", id: "SettingContentView", ref: scrollRef, children: [!isGeofenceOn && (_jsx("div", { className: "SettingsHeroWrapper", children: _jsxs("div", { className: "SettingsHeroTextWrapper", children: [_jsx("div", { className: "SettingsHeroHeader", children: "Set up geofences for your team" }), _jsxs("div", { className: "SettingsHeroButtonWrapper", children: [_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.TIME_CLOCK_SETTING], children: _jsx("div", { className: "SettingsGrayButton", children: "Learn More About Geofences" }) }), _jsx("div", { className: "SettingsBlackButton", onClick: function () { return handleGeofenceEnabled(true); }, children: "Turn On" })] })] }) })), isGeofenceOn && (_jsxs("div", { className: "MainSettings", children: [_jsx("div", { className: "WelcomeToSettings mb-5", children: _jsxs("div", { className: "SettingsHeroTextWrapper", children: [_jsx("div", { className: "SettingsHeroHeaderSm", children: "Set up geofences for your team" }), _jsxs("div", { className: "SettingsHeroButtonWrapper", children: [_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.TIME_CLOCK_SETTING], children: _jsx("div", { className: "SettingsGrayButton", children: "Learn More About Geofences" }) }), _jsx("div", { className: "SettingsGrayButton", onClick: function () { return handleGeofenceEnabled(false); }, children: "Turn off Geofences" })] })] }) }), _jsxs("div", { className: "SettingsBody", children: [_jsx("div", { className: "SettingsHeroHeaderSm", children: "Geofences" }), _jsx("div", { className: "SettingsHeroSubText", children: "Add geofences for your locations." }), geofences.map(function (geofence, index) {
                                                return (_jsxs("div", { children: [_jsxs(Row, { justify: "space-between", children: [_jsx(Col, { span: 20, children: _jsx(Input, { className: "GeofenceGroupNameInput", placeholder: "Enter geofence name", value: geofence.name, onChange: function (e) {
                                                                            return changeGeofenceName(index, e.target.value);
                                                                        }, onFocus: function (e) { return e.target.select(); } }) }), _jsx(Col, { span: 4, className: "GeofenceDeleteGroup", children: _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this geofence?", onConfirm: function () { return deleteGeofence(index); }, placement: "rightBottom", children: _jsx(Button, { className: "GeofenceDeleteGroupBtn", children: "Delete" }) }) })] }), _jsxs(Row, { children: [_jsx(Col, { span: 10, children: _jsx("div", { className: "AddressInputField", children: _jsx(AddressCell, { defaultValue: geofence.address, updateGeofenceAddress: function (address, coordinates) {
                                                                                return onUpdateAddress(address, coordinates, index);
                                                                            } }) }) }), _jsx(Col, { span: 2, children: _jsx(Input, { className: "GeofenceGroupRadiusInput", placeholder: "Radius", value: geofence.shape.radius, type: "number", onChange: function (e) {
                                                                            return changeGeofenceRadius(index, e.target.value);
                                                                        } }) }), _jsx(Col, { span: 1, className: "MetersLabelCol flex items-center", children: _jsx("span", { className: "text-base text-gray-600", children: "meters" }) })] }), _jsx(Row, { children: _jsx("div", { className: "Map", children: geofenceVisibleMap[index] ? (_jsx(GeoMap, { geofence: getGeofenceObj(index) })) : (_jsx("div", { className: "flex h-full cursor-pointer items-center justify-center bg-gray-50 text-base", onClick: function () {
                                                                        return setGeofenceVisibleMap(function (prev) {
                                                                            var _a;
                                                                            return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
                                                                        });
                                                                    }, children: "Click to view geofence" })) }) }), _jsx(Divider, {})] }, geofence.id));
                                            })] })] }))] }), isGeofenceOn && (_jsxs("div", { className: "SettingContentFooter", children: [_jsx("div", { className: "SettingsGrayButton", onClick: function () { return addGeofence(); }, children: "Add Geofence" }), _jsx(ZiraActionButton, { className: "SaveSettingBtn", onClickFn: onSaveSettings, children: "Save" })] }))] })] }));
};
export default React.memo(GeofencePage);
