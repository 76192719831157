import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TaskSelectionDropdown from 'src/components/Settings/Tasks/shared/TaskSelectionDropdown';
import TaskSearchBar from 'src/components/Settings/Tasks/shared/TaskSearchBar';
import { useTasksApi } from 'src/hooks/api/useTasksApi';
import { collectionTableTypeSelector } from 'src/state';
import { useRecoilValue } from 'recoil';
import { CollectionTableType } from 'src/shared';
import RecordDetailTask from './RecordDetailTasks/RecordDetailTask';
import RecordDetailTaskGroup from './RecordDetailTasks/RecordDetailTaskGroup';
import './ReverseTasksView.scss';
var ReverseTasksView = function (_a) {
    var referenceRecordId = _a.referenceRecordId, referenceCollectionId = _a.referenceCollectionId;
    var referenceCollectionType = useRecoilValue(collectionTableTypeSelector({
        type: CollectionTableType.DETAIL_MODAL,
        collectionId: referenceCollectionId,
    }));
    var _b = useState([]), userTaskGroups = _b[0], setUserTaskGroups = _b[1];
    var _c = useState([]), userTasks = _c[0], setUserTasks = _c[1];
    var _d = useState(''), searchText = _d[0], setSearchText = _d[1];
    var actualSearchValue = useMemo(function () { return searchText.trim().toLowerCase(); }, [searchText]);
    var filteredTaskGroups = useMemo(function () {
        if (!actualSearchValue)
            return userTaskGroups;
        return userTaskGroups.filter(function (taskGroup) {
            if (taskGroup.name.toLowerCase().includes(actualSearchValue))
                return true;
            for (var _i = 0, _a = taskGroup.orderedTasks; _i < _a.length; _i++) {
                var task = _a[_i];
                if (task.taskTemplate.name.toLowerCase().includes(actualSearchValue))
                    return true;
            }
            return false;
        });
    }, [actualSearchValue, userTaskGroups]);
    var filteredTasks = useMemo(function () {
        if (!actualSearchValue)
            return userTasks;
        return userTasks.filter(function (task) {
            return task.taskTemplate.name.toLowerCase().includes(actualSearchValue);
        });
    }, [actualSearchValue, userTasks]);
    //! Fetch user tasks
    var _e = useTasksApi(), useGetUserTasks = _e.useGetUserTasks, useAssignTaskToRecord = _e.useAssignTaskToRecord;
    var _f = useGetUserTasks(referenceRecordId), data = _f.data, refetch = _f.refetch;
    useEffect(function () {
        if (data) {
            setUserTaskGroups(data.taskGroups);
            setUserTasks(data.standaloneTasks);
        }
    }, [data]);
    var assignTaskGroup = useCallback(function (taskGroup) {
        useAssignTaskToRecord.mutate({
            recordId: referenceRecordId,
            collectionType: referenceCollectionType,
            taskGroupId: taskGroup.id,
        }, {
            onSuccess: function (resp) {
                if (resp) {
                    refetch();
                    return;
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceRecordId, referenceCollectionType]);
    var assignTask = useCallback(function (task) {
        useAssignTaskToRecord.mutate({
            recordId: referenceRecordId,
            collectionType: referenceCollectionType,
            taskId: task.id,
        }, {
            onSuccess: function (resp) {
                if (resp) {
                    refetch();
                    return;
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [referenceRecordId, referenceCollectionType]);
    return (_jsxs("div", { className: "ReverseTasksView flex flex-col space-y-[32px]", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(TaskSearchBar, { className: "grow pl-0", placeholder: "Search Task or Task Group", value: searchText, onChange: function (e) { return setSearchText(e.target.value); } }), _jsx(TaskSelectionDropdown, { selectedTasks: [], onAddTask: assignTask, onAddTaskGroup: assignTaskGroup, children: _jsxs("div", { className: "TaskAddNewButton ml-auto", children: [_jsx(PlusOutlined, {}), "Assign Task"] }) })] }), _jsxs("div", { className: "flex flex-col space-y-[32px]", children: [filteredTaskGroups.map(function (taskGroup) { return (_jsx(RecordDetailTaskGroup, { taskGroup: taskGroup, refetchTasks: refetch }, taskGroup.id)); }), !!filteredTasks.length && (_jsxs("div", { style: filteredTaskGroups.length ? { marginTop: 64 } : undefined, children: [_jsx("div", { className: "TaskGroupHeader", children: "Other Tasks" }), _jsx("div", { className: "flex flex-col space-y-[16px]", children: filteredTasks.map(function (task) { return (_jsx(RecordDetailTask, { task: task, isGroupTask: false, refetchTasks: refetch }, task.id)); }) })] }))] })] }));
};
export default React.memo(ReverseTasksView);
