import { atom } from 'recoil';
var helpDrawerState = atom({
    key: 'helpDrawerState',
    default: {
        isVisible: false,
        step: 0,
        selectedHelpArticleId: '',
    },
});
export { helpDrawerState };
