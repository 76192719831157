var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { appSessionState } from 'src/state';
import { getOrgIdentifierFromURL } from 'src/utils/Account';
import { getIsRouteValidWithoutSession } from './constants';
export var useFetch = function () {
    var _a;
    var baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    var _b = useAuth0(), getAccessTokenSilently = _b.getAccessTokenSilently, logout = _b.logout;
    var _c = (_a = useRecoilValue(appSessionState)) !== null && _a !== void 0 ? _a : {}, sesssionId = _c.id, sessionAccountId = _c.accountId;
    var notificationError = function (comment) {
        toast(comment, {
            position: toast.POSITION.BOTTOM_CENTER,
            type: 'error',
        });
    };
    var request = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (params, payload) { return __awaiter(void 0, void 0, void 0, function () {
            var accountId, appSessionId, headers, _a, org, init, url, response, respJson, message, e_1;
            var _b, _c;
            var _d, _e, _f, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        accountId = (_e = (_d = snapshot.getLoadable(appSessionState).valueMaybe()) === null || _d === void 0 ? void 0 : _d.accountId) !== null && _e !== void 0 ? _e : sessionAccountId;
                        appSessionId = (_g = (_f = snapshot.getLoadable(appSessionState).valueMaybe()) === null || _f === void 0 ? void 0 : _f.id) !== null && _g !== void 0 ? _g : sesssionId;
                        if ((payload === null || payload === void 0 ? void 0 : payload.accountId) === '') {
                            logout();
                        }
                        if (!appSessionId && !getIsRouteValidWithoutSession(params.url, params.method)) {
                            return [2 /*return*/];
                        }
                        _b = {};
                        _c = {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        };
                        _a = "Bearer ".concat;
                        return [4 /*yield*/, getAccessTokenSilently()];
                    case 1:
                        headers = (_b.headers = (_c.Authorization = _a.apply("Bearer ", [_j.sent()]),
                            _c['X-Requested-With'] = 'XMLHttpRequest',
                            _c['X-Client-Name'] = 'AppDot',
                            _c['X-Client-Platform'] = 'Web',
                            _c),
                            _b);
                        if (appSessionId) {
                            headers.headers['X-Session-Id'] = appSessionId;
                        }
                        if (accountId) {
                            headers.headers['X-Account-Id'] = accountId;
                        }
                        org = getOrgIdentifierFromURL();
                        if (org) {
                            headers.headers['X-Client-Org-Name'] = org;
                        }
                        init = __assign(__assign(__assign({}, headers), (params.headers || {})), { body: payload ? JSON.stringify(payload) : undefined, method: params.method });
                        url = "".concat(baseUrl).concat(params.url);
                        _j.label = 2;
                    case 2:
                        _j.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, fetch(url, init)];
                    case 3:
                        response = _j.sent();
                        if (response.status === 403) {
                            if (params.onAuthorizedError) {
                                params.onAuthorizedError();
                            }
                            else {
                                notificationError('Unauthorized');
                                throw new Error('Unauthorized');
                            }
                        }
                        if (response.status === 204 || response.status === 202) {
                            return [2 /*return*/, undefined];
                        }
                        return [4 /*yield*/, response.json()];
                    case 4:
                        respJson = _j.sent();
                        if (!response.ok || respJson.error) {
                            message = (_h = respJson.error.message) !== null && _h !== void 0 ? _h : respJson.error.comment;
                            notificationError(message);
                            throw new Error(message);
                        }
                        if (respJson.data === undefined) {
                            return [2 /*return*/, respJson];
                        }
                        else {
                            return [2 /*return*/, respJson.data];
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _j.sent();
                        throw 'IgnoreApiError';
                    case 6: return [2 /*return*/];
                }
            });
        }); };
    }, [sessionAccountId, sesssionId, getAccessTokenSilently, baseUrl, logout]);
    var toastRequest = useCallback(function (params, payload, loadingMessage, successMessage) { return __awaiter(void 0, void 0, void 0, function () {
        var toastBox;
        return __generator(this, function (_a) {
            if ((payload === null || payload === void 0 ? void 0 : payload.accountId) === '') {
                logout();
            }
            toastBox = toast.promise(request(params, payload), {
                pending: loadingMessage || 'Loading...',
                success: successMessage || 'Success',
            }, {
                position: toast.POSITION.BOTTOM_CENTER,
                toastId: "toast-request-".concat(params),
            });
            return [2 /*return*/, toastBox];
        });
    }); }, [logout, request]);
    return { request: request, toastRequest: toastRequest };
};
