var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Input, Select } from 'antd';
import { useAccountApi } from 'src/hooks/api';
import { CollectionTableType, IpName } from 'src/shared';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useRecordComponent } from 'src/hooks/component';
import { ProfileTaskSchemaType, TaskTemplateType, } from '../../interface';
import AddFieldDropdown from '../ProfileDataTypeTask/AddFieldDropdown';
import EmptyRequiredDataContent from '../ProfileDataTypeTask/EmptyRequiredDataContent';
import ProfileDataTaskSchemaRow from '../ProfileDataTypeTask/ProfileDataTaskSchemaRow';
import { useTaskProfileDataSchemas } from '../../hooks/useTaskProfileDataSchemas';
var SigningPartnerTaskSettingSection = function (_a) {
    var collectionId = _a.collectionId, fileSchemaId = _a.fileSchemaId, templateId = _a.templateId, buttonLabel = _a.buttonLabel, selectedTaskId = _a.selectedTaskId, taskSchemas = _a.taskSchemas, cells = _a.cells, updateSigningPartnerTask = _a.updateSigningPartnerTask;
    var _b = useAccountApi(), useGetAccountCollectionOptionsQuery = _b.useGetAccountCollectionOptionsQuery, useGetAccountDocSigningTemplatesListQuery = _b.useGetAccountDocSigningTemplatesListQuery;
    var allCollections = useGetAccountCollectionOptionsQuery().data;
    var signingTemplates = useGetAccountDocSigningTemplatesListQuery({
        enabled: collectionId !== undefined,
    }).data;
    var docusignTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.DOCUSIGN; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var signNowTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.SIGNNOW; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var templates = docusignTemplates && docusignTemplates.length ? docusignTemplates : signNowTemplates;
    var templateOptions = templates === null || templates === void 0 ? void 0 : templates.map(function (template) {
        return template.templateName && (_jsx(Select.Option, { value: template.templateId, children: template.templateName }, template.templateId));
    });
    var ipName = docusignTemplates && docusignTemplates.length ? IpName.DOCUSIGN : IpName.SIGNNOW;
    var editRecordCell = useRecordComponent().editRecordCell;
    var dataSource = useMemo(function () { return ({
        type: CollectionTableType.TASK_TEMPLATE_MODAL,
        collectionId: collectionId !== null && collectionId !== void 0 ? collectionId : '',
    }); }, [collectionId]);
    var collectionInfo = useMemo(function () {
        if (!allCollections || !collectionId)
            return undefined;
        var collection = allCollections.find(function (collection) { return collection.id === collectionId; });
        if (!collection)
            return undefined;
        var collectionInfo = {
            id: collection.id,
            name: collection.name,
            type: collection.type,
        };
        return collectionInfo;
    }, [allCollections, collectionId]);
    var _c = useTaskProfileDataSchemas({
        selectedTaskId: selectedTaskId,
        collectionId: collectionId !== null && collectionId !== void 0 ? collectionId : '',
        schemaId: undefined,
        collectionInfo: collectionInfo,
        dataSource: dataSource,
        cells: cells,
        taskSchemas: taskSchemas,
        onUpdateCells: updateSigningPartnerTask,
        onUpdateTaskSchemas: updateSigningPartnerTask,
    }), schemas = _c.schemas, schemasFetched = _c.schemasFetched, recordData = _c.recordData, onUpdateTaskSchemaType = _c.onUpdateTaskSchemaType, onRemoveTaskSchema = _c.onRemoveTaskSchema, onDragTaskSchemaEnd = _c.onDragTaskSchemaEnd;
    var fileSchemas = useMemo(function () {
        return schemas === null || schemas === void 0 ? void 0 : schemas.columns.filter(function (schema) {
            return schema.type === 'file';
        });
    }, [schemas]);
    var selectedSchemaIds = useMemo(function () { var _a; return (_a = taskSchemas === null || taskSchemas === void 0 ? void 0 : taskSchemas.map(function (schema) { return schema.schemaId; })) !== null && _a !== void 0 ? _a : []; }, [taskSchemas]);
    var availableSchemas = useMemo(function () {
        if (!schemas) {
            return [];
        }
        else {
            // Filter out user relation schema
            return schemas.columns.filter(function (schema) { return schema.id !== fileSchemaId && schema.canEdit; });
        }
    }, [fileSchemaId, schemas]);
    var updateTaskSchemas = useCallback(function (newTaskSchemas) {
        updateSigningPartnerTask({
            taskSchemas: newTaskSchemas,
        });
    }, [updateSigningPartnerTask]);
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(CollectionTableType.TASK_TEMPLATE_MODAL, cellPos, newValues);
    }, [editRecordCell]);
    var onAddNewTaskSchema = useCallback(function (schemaId) {
        updateTaskSchemas(taskSchemas.concat({
            schemaId: schemaId,
            taskSchemaType: ProfileTaskSchemaType.HIDDEN,
        }));
    }, [taskSchemas, updateTaskSchemas]);
    return (_jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Collection" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Collection", value: collectionId || null, onChange: function (value) {
                    updateSigningPartnerTask({
                        collectionId: value,
                        fileSchemaId: undefined,
                        signingPartner: ipName,
                    });
                }, showSearch: true, filterOption: function (input, option) {
                    var _a;
                    return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase());
                }, children: allCollections === null || allCollections === void 0 ? void 0 : allCollections.map(function (collection) { return (_jsx(Select.Option, { value: collection.id, children: collection.name }, collection.id)); }) }), collectionId && (_jsxs(_Fragment, { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Field" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Field", value: fileSchemaId, onChange: function (value) {
                            updateSigningPartnerTask({
                                fileSchemaId: value,
                            });
                        }, showSearch: true, filterOption: function (input, option) {
                            var _a;
                            return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '')
                                .toLowerCase()
                                .includes(input.trim().toLowerCase());
                        }, children: fileSchemas === null || fileSchemas === void 0 ? void 0 : fileSchemas.map(function (schema) { return (_jsx(Select.Option, { value: schema.id, children: schema.name }, schema.id)); }) }), _jsx("div", { className: "TaskSectionLabel mt-4", children: "Template" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Template", value: templateId || undefined, onChange: function (value) {
                            return updateSigningPartnerTask({
                                templateId: value,
                            });
                        }, showSearch: true, filterOption: function (input, option) {
                            var _a;
                            return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '')
                                .toLowerCase()
                                .includes(input.trim().toLowerCase());
                        }, children: templateOptions }), _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Button Label" }), _jsx(Input, { className: "TaskDetailModalInput", value: buttonLabel, onChange: function (e) {
                                    updateSigningPartnerTask({
                                        buttonLabel: e.target.value,
                                    });
                                } })] })] })), _jsx("div", { className: "ProfileDataSettingContent", children: _jsx("div", { children: collectionId ? (_jsx("div", { className: "CollectionColumnsSection", children: _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Set Fields" }), _jsx(DragDropContext, { onDragEnd: onDragTaskSchemaEnd, children: _jsx(Droppable, { droppableId: "list", children: function (provided) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.droppableProps, { children: [taskSchemas.map(function (taskSchema, index) {
                                                    var schema = availableSchemas.find(function (schema) {
                                                        return schema.id === taskSchema.schemaId;
                                                    });
                                                    if (schema) {
                                                        return (_jsx(ProfileDataTaskSchemaRow, { taskType: TaskTemplateType.SIGNING_PARTNER, taskSchema: taskSchema, schema: schema, recordData: recordData, dataSource: dataSource, selectedCollectionId: collectionId, isUserProfile: false, index: index, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onUpdateTaskSchemaType: onUpdateTaskSchemaType, onRemoveTaskSchema: onRemoveTaskSchema }, schema.id));
                                                    }
                                                    else {
                                                        if (schemasFetched && !!schemas) {
                                                            // Remove deleted/invalid schema from profile data task schemas
                                                            onRemoveTaskSchema(taskSchema.schemaId);
                                                        }
                                                        //! Hidden draggable so correct index is preserved
                                                        return (_jsx(Draggable, { draggableId: taskSchema.schemaId, index: index, children: function (provided) { return (_jsx("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }))); } }, taskSchema.schemaId));
                                                    }
                                                }), provided.placeholder] }))); } }) }), _jsx(AddFieldDropdown, { availableSchemas: availableSchemas, selectedSchemaIds: selectedSchemaIds, recordData: recordData, selectedCollectionId: collectionId, dataSource: dataSource, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onAddNewTaskSchema: onAddNewTaskSchema })] }) })) : (_jsx(EmptyRequiredDataContent, {})) }) })] }));
};
export default React.memo(SigningPartnerTaskSettingSection);
