var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useRecipeApi } from 'src/hooks/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import AutomationModal from 'src/components/Settings/Automations/AutomationModal';
import { isSingleAutomationModalVisibleState, automationModalPropsState, } from 'src/state/atoms/automationModalState';
import RecipeModalHeader from '../RecipeModalHeader';
import { RECIPE_MODAL_FOOTER_HEIGHT } from '../constant';
import RecipeLoading from '../RecipeLoading';
import './RecipeAutomationsView.scss';
import RecipeAutomationCard from './RecipeAutomationCard';
var RecipeAutomationsView = function (_a) {
    var recipeId = _a.recipeId;
    var useGetRecipeAutomations = useRecipeApi().useGetRecipeAutomations;
    var _b = useGetRecipeAutomations(recipeId), data = _b.data, isLoading = _b.isLoading;
    var _c = useRecoilState(isSingleAutomationModalVisibleState), isAutomationModalVisible = _c[0], setIsAutomationModalVisible = _c[1];
    var automationProps = useRecoilValue(automationModalPropsState);
    if (isLoading) {
        return _jsx(RecipeLoading, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "flex", style: { height: "calc(100% - ".concat(RECIPE_MODAL_FOOTER_HEIGHT, "px") }, children: _jsxs("div", { className: "flex w-full flex-col overflow-y-auto pt-[48px]", children: [_jsx(RecipeModalHeader, { title: "Automations", description: "Confirm details for the automations that are part of this recipe" }), _jsx("div", { className: "space-y-[32px] pt-[32px]", children: data === null || data === void 0 ? void 0 : data.automationCards.map(function (automation, index) {
                                return (_jsx(RecipeAutomationCard, { recipeId: recipeId, automation: automation }, index));
                            }) }), (data === null || data === void 0 ? void 0 : data.automationCards.length) === 0 && (_jsx("div", { children: "There are no automations for this recipe" }))] }) }), isAutomationModalVisible && (_jsx(AutomationModal, __assign({}, automationProps, { closeModal: function () { return setIsAutomationModalVisible(false); }, refreshAutomationList: function () { } })))] }));
};
export default React.memo(RecipeAutomationsView);
