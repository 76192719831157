var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useFlexLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { useCallback } from 'react';
import './PaymentRulesTable.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import usePayRateV2Api from 'src/components/Settings/PayRate/usePayRateV2Api';
var PaymentRulesTableBuilder = function (_a) {
    var columns = _a.columns, payrules = _a.payrules, editPayruleModal = _a.editPayruleModal;
    var updatePayRulesOrder = usePayRateV2Api().updatePayRulesOrder;
    //TODO: Implement Sort By so we don't see popping when update happens
    var _b = useTable({
        columns: columns,
        data: payrules,
    }, useResizeColumns, useFlexLayout, useSortBy), getTableProps = _b.getTableProps, getTableBodyProps = _b.getTableBodyProps, headerGroups = _b.headerGroups, rows = _b.rows, prepareRow = _b.prepareRow;
    var onDragEnd = useCallback(function (result) { return __awaiter(void 0, void 0, void 0, function () {
        var rules, allValues;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!((result === null || result === void 0 ? void 0 : result.destination) != undefined)) return [3 /*break*/, 2];
                    rules = payrules;
                    allValues = rules
                        .map(function (payrule) { return payrule.info.id; })
                        .filter(function (id) { return result.draggableId != id; });
                    allValues.splice(result.destination.index, 0, result.draggableId);
                    return [4 /*yield*/, updatePayRulesOrder.mutateAsync(allValues)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [payrules, updatePayRulesOrder]);
    return (_jsxs("table", __assign({}, getTableProps(), { className: "PaymentRulesTable w-full table-fixed\t", children: [_jsx("thead", { children: headerGroups.map(function (headerGroup) {
                    return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps, { key: headerGroup.id }), headerGroup.headers.map(function (column) { return (_createElement("th", __assign({}, column.getHeaderProps(), { className: "table-cell", key: column.id }),
                        _jsx("span", { children: column.render('Header') }),
                        _jsx("div", __assign({}, column.getResizerProps(), { className: "resizer ".concat(column.isResizing ? 'isResizing' : '') })))); })));
                }) }), _jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "table-body", children: function (provided) { return (_jsx("tbody", __assign({}, getTableBodyProps(), { className: "gap-3", ref: provided.innerRef }, provided.droppableProps, { children: rows.map(function (row, rowIndex) {
                            prepareRow(row);
                            return (_jsx(Draggable, { index: rowIndex, draggableId: row.values.info.id, children: function (dragProvided) {
                                    return (_jsx("tr", __assign({}, row.getRowProps(), { onClick: function () {
                                            editPayruleModal(row.values);
                                        } }, dragProvided.draggableProps, { ref: dragProvided.innerRef, children: row.cells.map(function (cell, columnIndex) {
                                            var left = columnIndex === 0 &&
                                                'rounded-l-lg border-r-0';
                                            var center = columnIndex !== 0 &&
                                                columnIndex !== row.cells.length - 1 &&
                                                'border-r-0';
                                            var right = columnIndex === row.cells.length - 1 &&
                                                'rounded-r-lg border-r';
                                            return (_createElement("td", __assign({}, cell.getCellProps(), { key: "cell-".concat(rowIndex, "-").concat(columnIndex), className: "table-cell text-left align-top border border-gray-100 ".concat(left, " ").concat(center, " ").concat(right, " px-4 py-2") }), cell.render('Cell', {
                                                dragProps: dragProvided.dragHandleProps,
                                            })));
                                        }) })));
                                } }, "".concat(row.values.info.id, "-row-key")));
                        }) }))); } }) })] })));
};
export default PaymentRulesTableBuilder;
