var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { LeftArrowIcon } from 'src/utils/icons';
import moment from 'moment';
import { SignalIcon } from 'src/utils/icons/SignalIcon';
import { WifiIcon } from 'src/utils/icons/WifiIcon';
import { BatteryIcon } from 'src/utils/icons/BatteryIcon';
import TaskGroupMobilePreview from '../TaskGroupDetail/TaskGroupMobilePreview';
import TaskRuleMobilePreview from '../TasksSettingView/TaskRules/TaskRuleMobilePreview';
import TaskTemplateMobilePreview from '../TaskTemplate/TaskTemplateMobilePreview';
import TaskEmptyMobilePreview from '../TasksSettingView/TaskEmptyMobilePreview';
var TaskMobilePreview = function (_a) {
    var templateInfo = _a.templateInfo, groupInfo = _a.groupInfo, ruleInfo = _a.ruleInfo;
    var isEmpty = !templateInfo && !groupInfo && !ruleInfo;
    var taskMobilePreviewClass = templateInfo ? 'mt-5 min-h-[66vh]' : 'px-5 mt-5 min-h-[66vh]';
    return (_jsx("div", { children: _jsx("div", { className: "TaskMobilePreview", children: _jsxs("div", { className: "bg-white rounded-2xl py-4", children: [_jsxs("div", { className: "flex flex-row items-center justify-between px-5", children: [_jsx("span", { className: "CurrentTime", children: moment().format('h:mm') }), _jsxs("div", { className: "flex flex-row items-center gap-1", children: [_jsx(SignalIcon, { className: "SignalIcon" }), _jsx(WifiIcon, { className: "WifiIcon" }), _jsx(BatteryIcon, {})] })] }), !ruleInfo && (_jsxs("div", { className: "flex items-center gap-4 border-b border-b-gray-100 px-5 py-3", children: [_jsx(LeftArrowIcon, {}), (templateInfo || groupInfo) && (_jsx("div", { className: "font-semibold text-lg", children: templateInfo ? 'Tasks' : 'Groups' }))] })), _jsxs("div", { className: taskMobilePreviewClass, children: [templateInfo && _jsx(TaskTemplateMobilePreview, { templateInfo: templateInfo }), groupInfo && _jsx(TaskGroupMobilePreview, { groupInfo: groupInfo }), ruleInfo && _jsx(TaskRuleMobilePreview, __assign({}, ruleInfo)), isEmpty && _jsx(TaskEmptyMobilePreview, {})] })] }) }) }));
};
export default React.memo(TaskMobilePreview);
