var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTasksApi } from 'src/hooks/api';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { selectedTaskRuleIdState } from '../../atom';
import { NEW_TASK_RULE_ID } from '../../constant';
import TaskMenuPopover from '../../shared/TaskMenuPopover';
import TaskRuleActiveTag from '../TaskTags/TaskRuleActiveTag';
import { generateRandomFourDigitNumber } from '../../util';
var TaskRuleListItem = function (_a) {
    var rule = _a.rule;
    var id = rule.id, name = rule.name, displayId = rule.displayId, isActive = rule.isActive;
    var _b = useRecoilState(selectedTaskRuleIdState), selectedTaskRuleId = _b[0], setSelectedTaskRuleId = _b[1];
    var selectTaskRule = useCallback(function () {
        setSelectedTaskRuleId(rule.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var _c = useTasksApi(), useCreateTaskRule = _c.useCreateTaskRule, useDeleteTaskRule = _c.useDeleteTaskRule, refetchTaskRules = _c.refetchTaskRules;
    var duplicateTaskRule = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var requestBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    requestBody = __assign(__assign({}, rule), { name: "".concat(name, " - Copy"), displayId: "".concat(displayId, ".copy-").concat(generateRandomFourDigitNumber()), saveOptions: {
                            updateExistingJobs: [],
                        } });
                    return [4 /*yield*/, useCreateTaskRule.mutateAsync(requestBody)];
                case 1:
                    _a.sent();
                    refetchTaskRules();
                    return [2 /*return*/];
            }
        });
    }); }, [rule]);
    var deleteTaskRUle = useCallback(function (e) {
        e.stopPropagation();
        useDeleteTaskRule.mutate(id, {
            onSuccess: function (resp) {
                if (resp) {
                    setSelectedTaskRuleId(undefined);
                    refetchTaskRules();
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]);
    return (_jsx("div", { className: "flex items-center border-b-2 border-gray-50 w-full h-20 p-[16px] relative cursor-pointer", onClick: function () {
            setSelectedTaskRuleId(id);
        }, style: {
            backgroundColor: selectedTaskRuleId === id ? '#E9E9EC' : 'white',
        }, children: _jsxs("div", { className: "w-full flex flex-col items-start grow min-w-0", children: [_jsxs("div", { className: "flex items-center w-full", children: [_jsx("div", { className: "TaskItemTitle", children: name }), _jsx("div", { className: "flex items-center ml-auto gap-2", children: id !== NEW_TASK_RULE_ID && (_jsxs(_Fragment, { children: [_jsx(TaskRuleActiveTag, { isActive: isActive }), _jsx(TaskMenuPopover, { content: _jsxs(_Fragment, { children: [_jsx("div", { className: "TaskMenuItem", onClick: selectTaskRule, children: "Edit" }), _jsx("div", { className: "TaskMenuItem", onClick: duplicateTaskRule, children: "Duplicate" }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this task rule?", okText: "Delete", onConfirm: deleteTaskRUle, placement: "leftBottom", children: _jsx("div", { className: "TaskMenuItem text-red-1000", onClick: function (e) { return e.stopPropagation(); }, children: "Delete" }) })] }), children: _jsx("div", { onClick: function (e) {
                                                e.stopPropagation();
                                            }, children: _jsx(EllipsisOutlined, { className: "text-[24px]" }) }) })] })) })] }), _jsx("div", { className: "TaskItemSubtitle", children: displayId })] }) }));
};
export default React.memo(TaskRuleListItem);
