import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { PagePermissionLevelOptions } from '../Constants';
import ObjectPermissionDropdown from './ObjectPermissionDropdown';
import PagePermissionContent from './PagePermissionContent';
var PagePermissionDropdown = function (_a) {
    var currentPermission = _a.currentPermission, className = _a.className, onUpdate = _a.onUpdate, canUpdate = _a.canUpdate;
    var permissionText = useMemo(function () { return PagePermissionLevelOptions(currentPermission); }, [currentPermission]);
    return (_jsx(ObjectPermissionDropdown, { overlay: _jsx(PagePermissionContent, { onChange: onUpdate, currentPermission: currentPermission }), title: permissionText, className: className, canUpdate: canUpdate }));
};
export default React.memo(PagePermissionDropdown);
