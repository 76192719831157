import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import UserAvatar from 'src/components/User/UserAvatar';
import { getReportCellDisplayValue } from 'src/services/ReportService';
import { FAKE_UUID_FOR_TOTALS } from '..';
var ReportTableCell = function (_a) {
    var _b;
    var value = _a.value, _c = _a.isUserType, isUserType = _c === void 0 ? false : _c, openRecordDetail = _a.openRecordDetail;
    return (_jsxs("div", { className: "flex h-full w-full items-center justify-end overflow-hidden whitespace-nowrap pr-2 pt-2 font-medium", children: [isUserType && value.rid !== FAKE_UUID_FOR_TOTALS && (_jsx(UserAvatar, { className: "mr-1", name: (_b = value.title) !== null && _b !== void 0 ? _b : '', avatar: value.avatar })), openRecordDetail && value.rid !== FAKE_UUID_FOR_TOTALS ? (_jsx("span", { title: "Open ".concat(value.title, "'s record details"), className: "mr-4 font-semibold hover:cursor-pointer hover:underline", onClick: function (e) {
                    e.stopPropagation();
                    openRecordDetail(value.rid);
                }, children: getReportCellDisplayValue(value) })) : (_jsx(_Fragment, { children: getReportCellDisplayValue(value) }))] }));
};
export default React.memo(ReportTableCell);
