var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cloneDeep, isEqual, uniq, uniqBy } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { CollectionTableType, DefaultPagePagination, DefaultReverseCollectionPagePagination, } from 'src/shared';
import { tableCollectionIdSelector, nextPageOfTableState, collectionIdToBlockIdsState, collectionTableSchemasState, collectionTableState, tableCollectionRowsState, tableCollectionCurrentSizeSelector, tableCollectionInfoState, tableSearchTextState, tableSelectedViewIdState, tableViewRecordOrdersState, collectionTableNonSchemaNativeFieldsState, } from 'src/state';
import { usePageApi } from '../api';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useCollection = function () {
    var _a = usePageApi(), listBlockCollection = _a.listBlockCollection, useAddColorsToCollection = _a.useAddColorsToCollection;
    var processTableCollectionIntoState = useRecoilCallback(function (_a) {
        var set = _a.set, snapshot = _a.snapshot;
        return function (tableId_1, collection_1, pagination_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([tableId_1, collection_1, pagination_1], args_1, true), void 0, function (tableId, collection, pagination, isBlock) {
                var collectionId, viewId;
                var _a;
                if (isBlock === void 0) { isBlock = false; }
                return __generator(this, function (_b) {
                    //!MARK: - only set next page here!
                    if ((!pagination ||
                        (isBlock
                            ? pagination.size === DefaultPagePagination.size
                            : pagination.size === DefaultReverseCollectionPagePagination.size)) &&
                        collection.rows.length &&
                        isBlock
                        ? collection.rows.length <= DefaultPagePagination.size
                        : collection.rows.length <= DefaultReverseCollectionPagePagination.size) {
                        set(nextPageOfTableState(tableId), ((_a = pagination === null || pagination === void 0 ? void 0 : pagination.page) !== null && _a !== void 0 ? _a : 0) + 1);
                    }
                    collectionId = collection.collectionInfo.id;
                    set(collectionTableState({
                        type: CollectionTableType.TABLE,
                        collectionId: collectionId,
                    }), function (pre) {
                        if (!pre)
                            return collection;
                        return cloneDeep(__assign(__assign({}, collection), { rows: uniqBy(__spreadArray(__spreadArray([], pre.rows, true), collection.rows, true), 'recordId'), cells: __assign(__assign({}, pre.cells), collection.cells) }));
                    });
                    set(collectionTableNonSchemaNativeFieldsState({
                        type: CollectionTableType.TABLE,
                        collectionId: collectionId,
                    }), function (pre) {
                        return __assign(__assign({}, pre), collection.nonSchemaNativeFieldsMap);
                    });
                    set(collectionTableSchemasState(collectionId), function (pre) {
                        if (isEqual(pre, collection.columns))
                            return pre;
                        return collection.columns;
                    });
                    set(tableCollectionRowsState(tableId), function (pre) {
                        if (!(pagination === null || pagination === void 0 ? void 0 : pagination.page))
                            return collection.rows;
                        return uniqBy(__spreadArray(__spreadArray([], pre, true), collection.rows, true), 'recordId');
                    });
                    viewId = snapshot.getLoadable(tableSelectedViewIdState(tableId)).valueMaybe();
                    if (viewId) {
                        set(tableViewRecordOrdersState(viewId), function (pre) {
                            var newRecordIds = collection.rows.map(function (r) { return r.recordId; });
                            if (!(pagination === null || pagination === void 0 ? void 0 : pagination.page))
                                return newRecordIds;
                            return uniq(__spreadArray(__spreadArray([], (pre !== null && pre !== void 0 ? pre : []), true), newRecordIds, true));
                        });
                    }
                    if (!pagination) {
                        //! Only set once
                        set(tableCollectionInfoState(tableId), function (prev) {
                            var info = __assign({}, collection.collectionInfo);
                            if (isEqual(info, prev))
                                return prev;
                            return info;
                        });
                        if (isBlock) {
                            set(collectionIdToBlockIdsState(collection.collectionInfo.id), function (prev) {
                                return uniq(__spreadArray(__spreadArray([], prev, true), [tableId], false));
                            });
                        }
                    }
                    return [2 /*return*/];
                });
            });
        };
    }, []);
    var fetchAndProcessBlockCollectionIntoState = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (request) { return __awaiter(void 0, void 0, void 0, function () {
            var release, body, prevLoadedRecordSize, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        body = request;
                        prevLoadedRecordSize = snapshot
                            .getLoadable(tableCollectionCurrentSizeSelector(body.blockId))
                            .valueMaybe();
                        if ((prevLoadedRecordSize !== null && prevLoadedRecordSize !== void 0 ? prevLoadedRecordSize : 0) > DefaultPagePagination.size &&
                            !body.pagination) {
                            body.pagination = {
                                page: 0,
                                size: prevLoadedRecordSize !== null && prevLoadedRecordSize !== void 0 ? prevLoadedRecordSize : DefaultPagePagination.size,
                            };
                        }
                        return [4 /*yield*/, listBlockCollection(body)];
                    case 2:
                        resp = _a.sent();
                        if (resp) {
                            processTableCollectionIntoState(body.blockId, resp.collection, body.pagination, true);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        release();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listBlockCollection]);
    var resetBlockCollectionState = useRecoilCallback(function (_a) {
        var reset = _a.reset;
        return function (blockId, viewIds) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                reset(tableCollectionInfoState(blockId));
                reset(tableSearchTextState(blockId));
                reset(tableCollectionRowsState(blockId));
                viewIds.forEach(function (viewId) {
                    reset(tableViewRecordOrdersState(viewId));
                });
                return [2 /*return*/];
            });
        }); };
    }, []);
    var addColorsToCollection = useRecoilCallback(function (_a) {
        var set = _a.set, snapshot = _a.snapshot;
        return function (blockId, tableCollectionId) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionId;
            return __generator(this, function (_a) {
                collectionId = tableCollectionId !== null && tableCollectionId !== void 0 ? tableCollectionId : snapshot.getLoadable(tableCollectionIdSelector(blockId)).valueMaybe();
                if (!collectionId)
                    return [2 /*return*/];
                useAddColorsToCollection.mutate(collectionId, {
                    onSuccess: function (resp) {
                        if (resp) {
                            set(collectionTableState({
                                type: CollectionTableType.TABLE,
                                collectionId: collectionId,
                            }), function (prev) {
                                if (!prev)
                                    return prev;
                                return __assign(__assign({}, prev), { rows: prev.rows.map(function (row) {
                                        var newColor = resp.records[row.recordId].color;
                                        return __assign(__assign({}, row), { color: newColor !== null && newColor !== void 0 ? newColor : row.color });
                                    }) });
                            });
                        }
                    },
                });
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var processBoardViewCollectionIntoState = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (response_1, tableId_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([response_1, tableId_1], args_1, true), void 0, function (response, tableId, isBlock) {
                var collectionInfo, columns, permissions, nonSchemaNativeFieldsMap, collection, collectionId;
                if (isBlock === void 0) { isBlock = false; }
                return __generator(this, function (_a) {
                    collectionInfo = response.collectionInfo, columns = response.columns, permissions = response.permissions, nonSchemaNativeFieldsMap = response.nonSchemaNativeFieldsMap;
                    collection = {
                        collectionInfo: collectionInfo,
                        columns: columns,
                        rows: [],
                        cells: {},
                        nonSchemaNativeFieldsMap: nonSchemaNativeFieldsMap,
                        permissions: permissions,
                    };
                    collectionId = collectionInfo.id;
                    set(collectionTableState({
                        type: CollectionTableType.TABLE,
                        collectionId: collectionId,
                    }), function (pre) {
                        if (!pre)
                            return collection;
                        return cloneDeep(__assign(__assign({}, collection), { rows: uniqBy(__spreadArray([], pre.rows, true), 'recordId'), cells: __assign({}, pre.cells) }));
                    });
                    set(collectionTableSchemasState(collectionId), function (pre) {
                        if (isEqual(pre, collection.columns))
                            return pre;
                        return collection.columns;
                    });
                    if (tableId) {
                        set(tableCollectionInfoState(tableId), function (prev) {
                            var info = __assign({}, collection.collectionInfo);
                            if (isEqual(info, prev))
                                return prev;
                            return info;
                        });
                        if (isBlock) {
                            set(collectionIdToBlockIdsState(collection.collectionInfo.id), function (prev) {
                                return uniq(__spreadArray(__spreadArray([], prev, true), [tableId], false));
                            });
                        }
                    }
                    return [2 /*return*/];
                });
            });
        };
    }, []);
    var processLoadMoreBoardViewNonSchemaNativeFieldsIntoState = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (collectionData) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionInfo, nonSchemaNativeFieldsMap, collectionId;
            return __generator(this, function (_a) {
                collectionInfo = collectionData.collectionInfo, nonSchemaNativeFieldsMap = collectionData.nonSchemaNativeFieldsMap;
                collectionId = collectionInfo.id;
                set(collectionTableNonSchemaNativeFieldsState({
                    type: CollectionTableType.TABLE,
                    collectionId: collectionId,
                }), function (prev) {
                    return __assign(__assign({}, prev), nonSchemaNativeFieldsMap);
                });
                return [2 /*return*/];
            });
        }); };
    }, []);
    return {
        processTableCollectionIntoState: processTableCollectionIntoState,
        fetchAndProcessBlockCollectionIntoState: fetchAndProcessBlockCollectionIntoState,
        resetBlockCollectionState: resetBlockCollectionState,
        addColorsToCollection: addColorsToCollection,
        processBoardViewCollectionIntoState: processBoardViewCollectionIntoState,
        processLoadMoreBoardViewNonSchemaNativeFieldsIntoState: processLoadMoreBoardViewNonSchemaNativeFieldsIntoState,
    };
};
export { useCollection as useCollectionComponent };
