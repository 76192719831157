import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Select } from 'antd';
import { pageItemsSelector } from 'src/state';
import { useRecoilValue } from 'recoil';
var WorkspaceTypeTaskSettingSection = function (_a) {
    var pageId = _a.pageId, updateWorkSpacePageId = _a.updateWorkSpacePageId;
    var pageItems = useRecoilValue(pageItemsSelector);
    return (_jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Workspace Page" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select a Workspace Page", value: pageId || undefined, onChange: function (value) { return updateWorkSpacePageId(value); }, showSearch: true, children: pageItems.map(function (page) { return (_jsx(Select.Option, { value: page.id, children: page.data.name }, page.id)); }) })] }));
};
export default React.memo(WorkspaceTypeTaskSettingSection);
