import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Popover } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { shiftTemplateDetailModalVisibleState } from 'src/state';
import { PlusOutlined } from '@ant-design/icons';
import ShiftTemplateListInPopover from './ShiftTemplateListInPopover';
import './ShiftTemplateListPopover.scss';
import { orderedShiftTemplateListState } from './Selector';
var ShiftTemplateListPopover = function (_a) {
    var blockId = _a.blockId, groupByRecordId = _a.groupByRecordId, children = _a.children, onApplyTemplate = _a.onApplyTemplate;
    var _b = useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var templateList = useRecoilValue(orderedShiftTemplateListState(blockId));
    var setIsTemplateDetailModalVisible = useSetRecoilState(shiftTemplateDetailModalVisibleState);
    var addNewTemplateButton = useMemo(function () { return (_jsxs("div", { onClick: function () { return setIsTemplateDetailModalVisible(true); }, className: "mb-2 flex cursor-pointer items-center justify-center rounded border border-gray-300 p-1 text-center font-medium", children: [_jsx(PlusOutlined, { className: "mr-1 text-xs" }), "Add new template"] })); }, [setIsTemplateDetailModalVisible]);
    var renderTemplatesPopover = useCallback(function () {
        return (_jsx("div", { className: "TemplatePopConfirm", children: !templateList || templateList.length === 0 ? (_jsxs("div", { className: "w-40", children: [_jsx("span", { className: "text-warning", children: "No Templates Available:" }), _jsxs("div", { className: "mt-2", children: [addNewTemplateButton, " or", _jsx("span", { className: "font-semibold", children: " Change your templates filter" }), " to see available templates."] })] })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "ModalTitle", children: "Shift Templates" }), _jsx("div", { className: "ModalSubtitle", children: "Select a template below or" }), addNewTemplateButton, _jsx("div", { className: "TemplateListContainer mt-2", children: _jsx(ShiftTemplateListInPopover, { blockId: blockId, templateList: templateList, listHeight: 250, onApplyTemplate: onApplyTemplate, groupByRecordId: groupByRecordId, listWidth: 200 }) })] })) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockId, groupByRecordId, templateList]);
    return (_jsx(Popover, { content: renderTemplatesPopover, trigger: "click", placement: "leftBottom", open: isVisible, onOpenChange: setIsVisible, destroyTooltipOnHide: true, overlayClassName: "ShiftModalTemplateTagPopover", children: children !== null && children !== void 0 ? children : _jsx("div", { className: "AddNewText", children: "Template" }) }));
};
export default React.memo(ShiftTemplateListPopover);
