import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tag, Tooltip } from 'antd';
import { isNil } from 'lodash';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { getAccountCurrencySymbol } from '../../../utils/Account';
import { disableScroll } from '../../../utils/Collection';
import './TextCellWithPopUp.scss';
var TextCellWithPopUp = function (props) {
    var defaultText = props.text, editCell = props.editCell, canEdit = props.canEdit, colorTag = props.colorTag, isDetailModal = props.isDetailModal, updateWithDefault = props.updateWithDefault, isCurrency = props.isCurrency, validationFunction = props.validationFunction;
    var accountCurrency = getAccountCurrencySymbol();
    var _a = useState(defaultText), text = _a[0], setText = _a[1];
    var _b = useState(false), isPopUpShow = _b[0], setIsPopUpShow = _b[1];
    var _c = useState(false), toolTipVisible = _c[0], setToolTipVisible = _c[1];
    var _d = useState(true), isInputValid = _d[0], setIsInputValid = _d[1];
    var classNames = 'cell-span';
    var updateClassNames = function (text) {
        classNames += ' ' + text;
    };
    useEffect(function () {
        if (updateWithDefault) {
            setText(defaultText);
        }
    }, [defaultText, updateWithDefault]);
    var showAsColorTag = colorTag || false;
    var showCurrencySign = isCurrency && !isNil(text) && text !== '' ? accountCurrency : '';
    var textColorTag = useMemo(function () {
        var color = (colorTag || {}).color;
        return (_jsx(Tag, { color: color, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, className: color ? 'color-tag' : 'non-color-tag', children: text }));
    }, [colorTag, text]);
    var onEdit = useCallback(function (value) {
        if (validationFunction) {
            if (validationFunction(value)) {
                if (value !== String(defaultText))
                    editCell === null || editCell === void 0 ? void 0 : editCell(value);
            }
            else {
                setToolTipVisible(true);
                setIsInputValid(false);
                return;
            }
        }
        else {
            if (value !== String(defaultText))
                editCell === null || editCell === void 0 ? void 0 : editCell(value);
        }
        setIsPopUpShow(false);
        setIsInputValid(true);
        setToolTipVisible(false);
        disableScroll(false);
    }, [defaultText, editCell, validationFunction]);
    var textInputOnBlur = useCallback(function (e) {
        onEdit(e.target.value);
    }, [onEdit]);
    var textInputOnFocus = useCallback(function (e) {
        var val = e.target.value;
        if (validationFunction) {
            setToolTipVisible(false);
            setIsInputValid(true);
        }
        e.target.value = '';
        e.target.value = val;
    }, [validationFunction]);
    if (!canEdit) {
        return (_jsx("div", { className: "cell-span", children: showAsColorTag
                ? textColorTag
                : showCurrencySign && typeof defaultText === 'number'
                    ? "".concat(showCurrencySign).concat(defaultText.toFixed(2))
                    : defaultText }));
    }
    var isShowEmptyPlaceholder = isDetailModal ? 'Insert Value' : '';
    if (!isPopUpShow) {
        return (_jsx("div", { tabIndex: 0, onClick: function () {
                setIsPopUpShow(true);
                disableScroll(true);
            }, className: classNames + (isDetailModal && !text ? ' EmptyContent' : ''), onFocus: function () {
                setIsPopUpShow(true);
                disableScroll(true);
                updateClassNames('editing');
            }, children: text
                ? showAsColorTag
                    ? textColorTag
                    : "".concat(showCurrencySign).concat(text)
                : isShowEmptyPlaceholder }));
    }
    else {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames, children: [text, _jsx("div", { className: "editable-popup", children: _jsx(Tooltip, { placement: "top", title: "Invalid input, can not be saved!", destroyTooltipOnHide: true, open: validationFunction ? toolTipVisible : false, children: _jsx("textarea", { autoFocus: true, className: validationFunction && !isInputValid ? 'InvalidText' : '', onChange: function (e) {
                                        setText(e.target.value);
                                    }, onBlur: textInputOnBlur, onFocus: textInputOnFocus, defaultValue: text, onKeyDown: function (e) {
                                        if (e.key === 'Enter') {
                                            onEdit(text);
                                        }
                                    } }) }) })] }), _jsx("div", { className: "fpOverlay", onClick: function () {
                        if (validationFunction) {
                            if (validationFunction(text)) {
                                setIsPopUpShow(false);
                            }
                            else {
                                setText(defaultText);
                                return;
                            }
                        }
                        else {
                            setIsPopUpShow(false);
                        }
                    }, style: { display: isPopUpShow ? 'block' : 'none' } })] }));
    }
};
export default React.memo(TextCellWithPopUp);
