var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Row, Switch } from 'antd';
import { DefaultAttestationNote } from 'src/shared';
import { usePayApi } from 'src/hooks/api/usePayApi';
var warningContent = 'Your disclaimer input is empty, please add input or disable the setting.';
var AttestationSetting = function (props) {
    var attestationSettings = props.attestationSettings, onAttestationSettingChange = props.onAttestationSettingChange;
    var useGetAttestationSettingsQuery = usePayApi().useGetAttestationSettingsQuery;
    var attestationSettingData = useGetAttestationSettingsQuery().data;
    var _a = useState(), note = _a[0], setNote = _a[1];
    useEffect(function () {
        var _a;
        setNote((_a = attestationSettings === null || attestationSettings === void 0 ? void 0 : attestationSettings.note) !== null && _a !== void 0 ? _a : DefaultAttestationNote);
    }, [attestationSettings]);
    useEffect(function () {
        if (attestationSettingData) {
            onAttestationSettingChange(attestationSettingData);
        }
    }, [attestationSettingData, onAttestationSettingChange]);
    var onEnabledChange = useCallback(function (checked) {
        // when enable attestation with note empty, set default note
        if (!attestationSettings)
            return;
        if (checked && !attestationSettings.note) {
            onAttestationSettingChange({
                note: DefaultAttestationNote,
                attestationEnabled: checked,
            });
        }
        else {
            onAttestationSettingChange(__assign(__assign({}, attestationSettings), { attestationEnabled: checked }));
        }
    }, [attestationSettings, onAttestationSettingChange]);
    var onNoteChange = useCallback(function (newNote) {
        if (!attestationSettings)
            return;
        onAttestationSettingChange(__assign(__assign({}, attestationSettings), { note: newNote }));
    }, [attestationSettings, onAttestationSettingChange]);
    var fontColor = (attestationSettings === null || attestationSettings === void 0 ? void 0 : attestationSettings.attestationEnabled) ? 'text-black' : 'text-gray-300';
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { justify: "space-between", children: [_jsx(Col, { span: 15, children: _jsx("div", { className: "PaySettingTitle", children: "Attestation Settings" }) }), _jsx(Col, { span: 9, children: _jsxs("div", { className: "mt-2 flex items-end", children: [_jsx("div", { className: "mr-2 font-medium", children: "Enabled" }), _jsx(Switch, { checked: attestationSettings === null || attestationSettings === void 0 ? void 0 : attestationSettings.attestationEnabled, onChange: function (checked) { return onEnabledChange(checked); } })] }) })] }), _jsxs("div", { className: "mt-4", children: [_jsx("p", { className: fontColor + ' font-medium', children: "Attestation Disclaimer Text" }), (attestationSettings === null || attestationSettings === void 0 ? void 0 : attestationSettings.attestationEnabled) && note === '' && (_jsx(Alert, { message: warningContent, type: "warning", showIcon: true, className: "mt-2 w-2/5" })), _jsx("textarea", { rows: 5, disabled: !(attestationSettings === null || attestationSettings === void 0 ? void 0 : attestationSettings.attestationEnabled), className: fontColor +
                            ' mt-1 w-2/5 border-[1px] border-solid p-2 font-medium focus:border-black', value: note, onChange: function (e) { return setNote(e.target.value); }, onBlur: function (e) { return onNoteChange(e.target.value); } })] })] }));
};
export default React.memo(AttestationSetting);
