import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
import { CurrencyMap } from '../../../../../../shared';
var PayRateInputCell = function (props) {
    var rate = props.rate, currency = props.currency, updateWageRecordPayRate = props.updateWageRecordPayRate;
    var _a = useState(rate), rateValue = _a[0], setRateValue = _a[1];
    var inputRef = useRef(null);
    useEffect(function () { return setRateValue(rate); }, [rate]);
    return (_jsx(InputNumber, { ref: inputRef, bordered: false, className: "PayNumberInput", min: 0, prefix: CurrencyMap[currency], value: rateValue, onFocus: function (e) { return e.target.select(); }, onBlur: function () {
            updateWageRecordPayRate(rateValue);
        }, onPressEnter: function () {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }, onChange: function (value) {
            if (typeof value === 'number') {
                setRateValue(value);
            }
        } }));
};
export default React.memo(PayRateInputCell);
