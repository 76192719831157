import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, List } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useAccountApi } from 'src/hooks/api';
import SettingsContentHeader from '../SettingsContentHeader';
import CollectionEntry from './CollectionEntry';
import './Collections.scss';
var CollectionsSettings = function () {
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var _a = useGetAccountCollectionOptionsQuery(), collectionOptions = _a.data, refetch = _a.refetch, isLoading = _a.isLoading;
    var standardCollections = useMemo(function () { return (collectionOptions === null || collectionOptions === void 0 ? void 0 : collectionOptions.filter(function (o) { return o.type !== 'custom'; })) || []; }, [collectionOptions]);
    var customCollections = useMemo(function () { return (collectionOptions === null || collectionOptions === void 0 ? void 0 : collectionOptions.filter(function (o) { return o.type === 'custom'; })) || []; }, [collectionOptions]);
    var onActionSuccess = useCallback(function () {
        refetch();
    }, [refetch]);
    var _b = useState(''), renameEntry = _b[0], setRenameEntry = _b[1];
    var onSetRenameEntry = useCallback(function (entry) {
        setRenameEntry(entry);
    }, []);
    var entryList = useCallback(function (isCustom) {
        var dataSource = isCustom ? customCollections : standardCollections;
        return (_jsx(List, { loading: isLoading, dataSource: dataSource, footer: null, renderItem: function (item) { return (_jsx(CollectionEntry, { item: item, onActionSuccess: onActionSuccess, isRenamning: item.id === renameEntry, onSetRenameEntry: onSetRenameEntry })); } }));
    }, [
        customCollections,
        isLoading,
        onActionSuccess,
        onSetRenameEntry,
        renameEntry,
        standardCollections,
    ]);
    return (_jsxs("div", { className: "CollectionSettings", children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Database" }) }), _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [_jsx("div", { className: "CollectionSettingsTitle", children: "Database" }), _jsx("div", { className: "CollectionSettingDescription", children: " Manage your database here." }), _jsxs("div", { className: "CollectionSettingsSection", children: [_jsx("div", { className: "CollectionSettingsSubTitle", children: "Custom" }), entryList(true)] }), _jsxs("div", { className: "CollectionSettingsSection", children: [_jsx("div", { className: "CollectionSettingsSubTitle", children: "Standard" }), entryList(false)] })] })] }));
};
export default React.memo(CollectionsSettings);
