import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { produce } from 'immer';
import { dayOfWeekOptionsMap, DifferentialSelectorType, booleanDifferentialQualifierOptions, overtimeDifferentialQualifierOptions, } from 'src/shared';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { useSetting } from 'src/hooks/api';
import SelectorCell from '../../../../TableView/TableCell/SelectorCell';
import PayRateSettingTableBuilder from './PayRateSettingTableBuilder';
import AfterHourQualifierCell from './PayRateTableV1Cell/AfterHourQualifierCell';
import NameInputCell from './PayRateTableV1Cell/NameInputCell';
import './PayDifferentialTable.scss';
var SelectorCellWrapper = function (recordId, type, data, updateQualifier, collectionId) {
    var useGetCollectionOptionList = useSetting().useGetCollectionOptionList;
    var _a = useState(false), dropdownOpen = _a[0], setDropdownOpen = _a[1];
    var _b = useState([]), availableOptions = _b[0], setAvailableOptions = _b[1];
    var roleOptionList = useGetCollectionOptionList({
        collectionId: collectionId !== null && collectionId !== void 0 ? collectionId : '',
        options: {
            enabled: dropdownOpen && type === DifferentialSelectorType.ROLE && !!collectionId,
        },
    }).data;
    useEffect(function () {
        if (type === DifferentialSelectorType.DAY_OF_WEEK) {
            var options = Object.values(dayOfWeekOptionsMap).map(function (item) {
                return {
                    id: item.key,
                    text: item.displayName,
                    color: item.color,
                };
            });
            setAvailableOptions(options);
        }
        else if (type === DifferentialSelectorType.OVERTIME) {
            setAvailableOptions(overtimeDifferentialQualifierOptions);
        }
        else {
            setAvailableOptions(booleanDifferentialQualifierOptions);
        }
    }, [type]);
    useEffect(function () {
        if (roleOptionList && type === DifferentialSelectorType.ROLE) {
            var availableOptions_1 = roleOptionList.records.map(function (option) {
                return {
                    id: option.id,
                    text: option.title,
                    color: option.color || '',
                };
            });
            setAvailableOptions(availableOptions_1);
        }
    }, [roleOptionList, type]);
    var displayedOption = useMemo(function () {
        if (type === DifferentialSelectorType.ROLE) {
            var options = data;
            return options.map(function (item) {
                var _a;
                return {
                    id: item.rid,
                    text: item.displayName,
                    color: (_a = item.color) !== null && _a !== void 0 ? _a : '',
                };
            });
        }
        else if (type !== DifferentialSelectorType.HOLIDAY) {
            var days = data;
            return days.map(function (item) {
                return {
                    id: item,
                    text: item,
                    color: '',
                };
            });
        }
        else {
            var value = data;
            return [
                {
                    id: value ? 'true' : 'false',
                    text: value ? 'Yes' : 'No',
                    color: value ? '#E5F4EF' : '#FFEDEC',
                },
            ];
        }
    }, [data, type]);
    var getRoleOptionInfo = useCallback(function (id) {
        var targetOption = _.find(availableOptions, function (option) { return option.id === id; });
        return targetOption;
    }, [availableOptions]);
    return (_jsx(SelectorCell, { canEdit: true, inputMode: type === DifferentialSelectorType.DAY_OF_WEEK ||
            type === DifferentialSelectorType.ROLE
            ? 'multi'
            : 'single', displayedOption: displayedOption, availableOption: availableOptions, mutateDisplayedValueInItem: function (values) {
            if (type === DifferentialSelectorType.ROLE) {
                var newRoleValues = values.map(function (value) {
                    var _a, _b, _c;
                    return {
                        rid: value,
                        displayName: (_b = (_a = getRoleOptionInfo(value)) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : '',
                        color: (_c = getRoleOptionInfo(value)) === null || _c === void 0 ? void 0 : _c.color,
                    };
                });
                updateQualifier(recordId, type, newRoleValues);
            }
            else {
                updateQualifier(recordId, type, values);
            }
        }, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen }));
};
var AfterHourQualifierCellWrapper = function (data, updateQualifier) {
    return (_jsx(AfterHourQualifierCell, { start: data.start, end: data.end, onChange: function (start, end) {
            updateQualifier(data.recordId, start, end);
        } }));
};
var TextCellWrapper = function (id, text, updateWageRecord, deleteWageRecord, isCodeName) {
    var updateWageRecordName = useCallback(function (newName) {
        updateWageRecord(id, isCodeName ? newName : undefined, !isCodeName ? newName : undefined);
    }, [id, isCodeName, updateWageRecord]);
    return (_jsxs("div", { className: "EditableWageName", children: [deleteWageRecord && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this record?", onConfirm: function () { return deleteWageRecord(id); }, placement: "bottom", children: _jsx(DeleteOutlined, {}) })), _jsx(NameInputCell, { name: text, updateWageRecordName: function (newName) { return updateWageRecordName(newName); } })] }));
};
var PayDifferentialTable = function (props) {
    var payDifferentialData = props.payDifferentialData, payDifferentialDataOnChange = props.payDifferentialDataOnChange;
    var addNewDifferentialRecord = useCallback(function () {
        if (!payDifferentialData)
            return;
        var newDifferentialData = produce(payDifferentialData, function (draft) {
            var newRecord = {
                id: uuidv4(),
                code: 'New Code',
                displayName: 'Untitled Name',
                localStart: '10:00:00',
                localEnd: '17:00:00',
                dayOfWeek: ['MONDAY'],
                isHoliday: false,
                overTimeType: 'REGULAR',
                roles: [],
            };
            draft.settingsView.push(newRecord);
        });
        payDifferentialDataOnChange(newDifferentialData);
    }, [payDifferentialData, payDifferentialDataOnChange]);
    var deleteDifferentialRecord = useCallback(function (recordId) {
        if (!payDifferentialData)
            return;
        var newDifferentialData = produce(payDifferentialData, function (draft) {
            draft.settingsView = _.filter(draft.settingsView, function (item) { return item.id !== recordId; });
        });
        payDifferentialDataOnChange(newDifferentialData);
    }, [payDifferentialData, payDifferentialDataOnChange]);
    var updateRecordCodeOrName = useCallback(function (recordId, newCode, newName) {
        if (!payDifferentialData)
            return;
        var newDifferentialData = produce(payDifferentialData, function (draft) {
            var targetRecordIndex = _.findIndex(draft.settingsView, function (record) { return record.id === recordId; });
            if (targetRecordIndex >= 0) {
                if (newCode) {
                    draft.settingsView[targetRecordIndex].code = newCode;
                }
                if (newName) {
                    draft.settingsView[targetRecordIndex].displayName = newName;
                }
            }
        });
        payDifferentialDataOnChange(newDifferentialData);
    }, [payDifferentialData, payDifferentialDataOnChange]);
    var updateAfterHourQualifier = useCallback(function (recordId, start, end) {
        if (!payDifferentialData)
            return;
        var newDifferentialData = produce(payDifferentialData, function (draft) {
            var targetRecordIndex = _.findIndex(draft.settingsView, function (record) { return record.id === recordId; });
            if (targetRecordIndex >= 0) {
                draft.settingsView[targetRecordIndex].localStart = start;
                draft.settingsView[targetRecordIndex].localEnd = end;
            }
        });
        payDifferentialDataOnChange(newDifferentialData);
    }, [payDifferentialData, payDifferentialDataOnChange]);
    var updateSelectorQualifier = useCallback(function (recordId, type, newValues) {
        if (!payDifferentialData)
            return;
        var newDifferentialData = produce(payDifferentialData, function (draft) {
            var targetRecordIndex = _.findIndex(draft.settingsView, function (record) { return record.id === recordId; });
            if (targetRecordIndex >= 0) {
                if (type === DifferentialSelectorType.DAY_OF_WEEK) {
                    draft.settingsView[targetRecordIndex].dayOfWeek = newValues;
                }
                else if (type === DifferentialSelectorType.HOLIDAY) {
                    var value = newValues[0] === 'true' ? true : false;
                    draft.settingsView[targetRecordIndex].isHoliday = value;
                    if (value === true) {
                        draft.settingsView[targetRecordIndex].dayOfWeek = [];
                    }
                }
                else if (type === DifferentialSelectorType.OVERTIME) {
                    draft.settingsView[targetRecordIndex].overTimeType = newValues[0];
                }
                else if (type === DifferentialSelectorType.ROLE) {
                    var newRoleValues = newValues;
                    draft.settingsView[targetRecordIndex].roles = newRoleValues;
                }
            }
        });
        payDifferentialDataOnChange(newDifferentialData);
    }, [payDifferentialData, payDifferentialDataOnChange]);
    var parseToRecordsData = useMemo(function () {
        var tempRecords = [];
        payDifferentialData.settingsView.map(function (item) {
            var codeObj = {
                recordId: item.id,
                text: item.code,
            };
            var nameObj = {
                recordId: item.id,
                text: item.displayName,
            };
            var afterHourObj = {
                recordId: item.id,
                start: item.localStart,
                end: item.localEnd,
            };
            var datOfWeekObj = {
                recordId: item.id,
                values: item.dayOfWeek,
            };
            var holidayObj = {
                recordId: item.id,
                values: item.isHoliday,
            };
            var overtimeObj = {
                recordId: item.id,
                values: [item.overTimeType],
            };
            var roleObj = {
                recordId: item.id,
                values: item.roles,
            };
            var tempTestRecordValue = {
                id: item.id,
                code: codeObj,
                displayName: nameObj,
                afterHours: afterHourObj,
                dayOfWeek: datOfWeekObj,
                overtime: overtimeObj,
                holiday: holidayObj,
                role: roleObj,
            };
            tempRecords.push(tempTestRecordValue);
        });
        return tempRecords;
    }, [payDifferentialData]);
    var parseToColumnData = useMemo(function () {
        var tempColumns = [];
        tempColumns.push({
            Header: _jsx(_Fragment, {}),
            accessor: 'code',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return TextCellWrapper(value.recordId, value.text, updateRecordCodeOrName, deleteDifferentialRecord, true);
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "PaySettingColumnTitle", children: "Display Name" }),
            accessor: 'displayName',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return TextCellWrapper(value.recordId, value.text, updateRecordCodeOrName);
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "PaySettingColumnTitle", children: "After Hours" }),
            accessor: 'afterHours',
            width: 260,
            Cell: function (_a) {
                var value = _a.value;
                return AfterHourQualifierCellWrapper(value, updateAfterHourQualifier);
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "PaySettingColumnTitle", children: "Day of Week" }),
            accessor: 'dayOfWeek',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return SelectorCellWrapper(value.recordId, DifferentialSelectorType.DAY_OF_WEEK, value.values, updateSelectorQualifier);
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "PaySettingColumnTitle", children: "Holiday" }),
            accessor: 'holiday',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return SelectorCellWrapper(value.recordId, DifferentialSelectorType.HOLIDAY, value.values, updateSelectorQualifier);
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "PaySettingColumnTitle", children: "Overtime" }),
            accessor: 'overtime',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return SelectorCellWrapper(value.recordId, DifferentialSelectorType.OVERTIME, value.values, updateSelectorQualifier);
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "PaySettingColumnTitle", children: "Role" }),
            accessor: 'role',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return SelectorCellWrapper(value.recordId, DifferentialSelectorType.ROLE, value.values, updateSelectorQualifier, payDifferentialData.roleCollectionId);
            },
        });
        return tempColumns;
    }, [
        deleteDifferentialRecord,
        payDifferentialData,
        updateAfterHourQualifier,
        updateRecordCodeOrName,
        updateSelectorQualifier,
    ]);
    return (_jsxs("div", { className: "PayRateConfigTable", children: [_jsx(PayRateSettingTableBuilder, { columns: parseToColumnData, data: parseToRecordsData, footer: true }), _jsx(Button, { onClick: addNewDifferentialRecord, className: "AddPayRateItemBtn", children: "Add Differential" })] }));
};
export default React.memo(PayDifferentialTable);
