var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRecipeApi } from 'src/hooks/api';
import { Select } from 'antd';
import { SchemaPermissionLevelOptions } from 'src/components/Settings/Permission/Constants';
import { AccessGroupType } from 'src/services/Interfaces';
import RecipeModalHeader from '../RecipeModalHeader';
import { RECIPE_MODAL_FOOTER_HEIGHT } from '../constant';
import { recipeOverriddenDataChangesState } from '../atom';
import RecipeTextWrapper from '../RecipeTextWrapper';
import RecipeLoading from '../RecipeLoading';
var Option = Select.Option;
var RecipePermissionChangesView = function (_a) {
    var recipeId = _a.recipeId;
    var overriddenDataChanges = useRecoilValue(recipeOverriddenDataChangesState);
    var useGetRecipePermissionChanges = useRecipeApi().useGetRecipePermissionChanges;
    var _b = useGetRecipePermissionChanges({
        recipeTemplateId: recipeId,
        overriddenDataChanges: overriddenDataChanges,
    }), data = _b.data, isLoading = _b.isLoading;
    var _c = data !== null && data !== void 0 ? data : {}, _d = _c.allAccessGroups, allAccessGroups = _d === void 0 ? [] : _d, _e = _c.allRecipeSchemas, allRecipeSchemas = _e === void 0 ? [] : _e, _f = _c.permissionChanges, permissionChanges = _f === void 0 ? [] : _f;
    var recipeSchemaMap = allRecipeSchemas.reduce(function (acc, schema) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[schema.schemaId] = schema, _a)));
    }, {});
    if (isLoading) {
        return _jsx(RecipeLoading, {});
    }
    return (_jsx("div", { className: "flex", style: { height: "calc(100% - ".concat(RECIPE_MODAL_FOOTER_HEIGHT, "px") }, children: _jsxs("div", { className: "flex flex-col overflow-y-auto pt-[48px]", children: [_jsx(RecipeModalHeader, { title: "Permissions", description: "Confirm updates to the permission groups" }), _jsx("div", { className: "space-y-[32px] pt-[32px]", children: permissionChanges.map(function (_a, index) {
                        var applicableAccessGroupIds = _a.applicableAccessGroupIds, schemaPermissions = _a.schemaPermissions, mobilePermissions = _a.mobilePermissions;
                        return (_jsxs("div", { className: "flex flex-col space-y-[16px] text-sm font-medium", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Access Groups" }), _jsx(Select, { className: "h-max w-[400px] max-w-full", defaultValue: applicableAccessGroupIds, mode: "multiple", children: allAccessGroups
                                                .filter(function (accessGroup) {
                                                return accessGroup.type === AccessGroupType.OTHER;
                                            })
                                                .map(function (accessGroup) { return (_jsx(Option, { value: accessGroup.id, children: accessGroup.name }, accessGroup.id)); }) })] }), Object.values(schemaPermissions).map(function (permissionLevel, permissionIndex) {
                                    var schema = recipeSchemaMap[Object.keys(schemaPermissions)[permissionIndex]];
                                    if (!schema)
                                        return null;
                                    return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: schema.schemaName }), _jsx(RecipeTextWrapper, { defaultColor: true, children: SchemaPermissionLevelOptions(permissionLevel) })] }, "".concat(schema.schemaName, "-").concat(permissionIndex)));
                                }), (mobilePermissions.requiredFieldsSchemaIds ||
                                    mobilePermissions.optionalFieldsSchemaIds) && (_jsxs("div", { className: "flex flex-col space-y-[4px]", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Mobile" }), _jsxs("div", { className: "flex flex-col space-y-[12px]", children: [mobilePermissions.requiredFieldsSchemaIds && (_jsxs("div", { children: [_jsx("div", { children: "Required Fields" }), _jsx("div", { className: "flex space-x-[8px]", children: mobilePermissions.requiredFieldsSchemaIds.map(function (schemaId) {
                                                                return (_jsx(RecipeTextWrapper, { defaultColor: true, children: recipeSchemaMap[schemaId].schemaName }, schemaId));
                                                            }) })] })), mobilePermissions.optionalFieldsSchemaIds && (_jsxs("div", { children: [_jsx("div", { children: "Optional Fields" }), _jsx("div", { className: "flex space-x-[8px]", children: mobilePermissions.optionalFieldsSchemaIds.map(function (schemaId) {
                                                                return (_jsx(RecipeTextWrapper, { defaultColor: true, children: recipeSchemaMap[schemaId].schemaName }, schemaId));
                                                            }) })] }))] })] }))] }, index));
                    }) })] }) }));
};
export default React.memo(RecipePermissionChangesView);
