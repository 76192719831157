import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { startCase } from 'lodash';
import { getAutomationValueType } from 'src/utils/Automation';
import RecipeTextWrapper from '../RecipeTextWrapper';
import { getRecipeValueTypeColor } from '../util';
var RecipeSummaryDataChanges = function (_a) {
    var dataChanges = _a.dataChanges;
    return (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "pb-[8px] text-base font-medium", children: [dataChanges.length, " Custom Fields"] }), _jsx("div", { className: "flex space-x-[16px]", children: dataChanges.map(function (_a, index) {
                    var schemaName = _a.schemaName, schemaType = _a.schemaType;
                    return (_jsxs("div", { className: "flex h-[180px] w-[180px] flex-col justify-center space-y-[16px] rounded-xl border-2 border-azure-50 pl-[16px] text-sm font-medium", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Field Name" }), _jsx(RecipeTextWrapper, { defaultColor: true, children: schemaName })] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Field Type" }), _jsx(RecipeTextWrapper, { color: getRecipeValueTypeColor(schemaType), children: startCase(getAutomationValueType(schemaType)) })] })] }, index));
                }) })] }));
};
export default React.memo(RecipeSummaryDataChanges);
