import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import BreakPolicy from './BreakPolicy';
var BreakSection = function () {
    var _a = CompliancePageState.useContainer(), breakPolicies = _a.breakPolicies, addBreakPolicy = _a.addBreakPolicy, setBreakPolicies = _a.setBreakPolicies;
    var _b = useSetting(), useGetBreakSettingData = _b.useGetBreakSettingData, useGetBreakLocationCount = _b.useGetBreakLocationCount;
    var data = useGetBreakSettingData().data;
    var breakLocationCount = useGetBreakLocationCount().data;
    useEffect(function () {
        if (data) {
            setBreakPolicies(data.policies);
        }
    }, [data, setBreakPolicies]);
    return (_jsxs("div", { className: "SectionWrapper", children: [breakPolicies.map(function (policy) {
                var _a;
                var count = (_a = breakLocationCount === null || breakLocationCount === void 0 ? void 0 : breakLocationCount.find(function (obj) { return obj.breakPolicyId == policy.policyId; })) === null || _a === void 0 ? void 0 : _a.count;
                return _jsx(BreakPolicy, { policy: policy, locationCount: count }, policy.policyId);
            }), _jsx("div", { children: _jsx("div", { className: "SettingsGrayButton mt-3", onClick: function () { return addBreakPolicy(); }, children: "New Break Policy" }) })] }));
};
export default React.memo(BreakSection);
