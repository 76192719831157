import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTasksComponent } from 'src/components/Settings/Tasks/hooks/useTasks';
import { useTasksApi } from 'src/hooks/api';
import { NativePath } from 'src/shared';
var HoverTaskEntityMenu = function (_a) {
    var taskEntityId = _a.taskEntityId, entityName = _a.entityName, nativePath = _a.nativePath, children = _a.children;
    var _b = useTasksComponent(), viewTaskTemplate = _b.viewTaskTemplate, viewTaskGroup = _b.viewTaskGroup, viewTaskRule = _b.viewTaskRule;
    var useGetAssignedTaskGroupById = useTasksApi().useGetAssignedTaskGroupById;
    var _c = useState(false), isPopoverOpen = _c[0], setIsPopoverOpen = _c[1];
    var _d = useState(false), userIsTryingToViewTaskGroupTemplate = _d[0], setUserIsTryingToViewTaskGroupTemplate = _d[1];
    var isTaskGroupEntity = nativePath === NativePath.TASK_GROUP_ID;
    var _e = useGetAssignedTaskGroupById(taskEntityId, {
        enabled: isTaskGroupEntity && userIsTryingToViewTaskGroupTemplate,
    }), assignedGroup = _e.data, isFetchingAssignedGroup = _e.isFetching;
    useEffect(function () {
        // We need the assigned group to get the task group template ID to view/openthe task group template
        // so when the user initiates a click to view the group template, we update a state variable userIsTryingToViewTaskGroupTemplate
        // to enable the react-query hook to fetch assignedGroup and once we have the group we can open the task group modal and then reset state
        if (assignedGroup && userIsTryingToViewTaskGroupTemplate && !isFetchingAssignedGroup) {
            viewTaskGroup(assignedGroup.taskTemplateGroupId);
            setUserIsTryingToViewTaskGroupTemplate(false);
            setIsPopoverOpen(false);
        }
    }, [
        assignedGroup,
        userIsTryingToViewTaskGroupTemplate,
        isFetchingAssignedGroup,
        viewTaskGroup,
        setUserIsTryingToViewTaskGroupTemplate,
    ]);
    // A hash that maps the nativePath to its corresponding entity type label (how the user will see it)
    // and the function you'd call to view the task entity
    var taskEntityPathMap = useMemo(function () {
        var _a;
        return (_a = {},
            _a[NativePath.TASK_TEMPLATE_ID] = {
                label: 'Template',
                viewFunction: function () {
                    viewTaskTemplate(taskEntityId);
                    setIsPopoverOpen(false);
                },
            },
            _a[NativePath.TASK_RULE_ID] = {
                label: 'Rule',
                viewFunction: function () {
                    viewTaskRule(taskEntityId);
                    setIsPopoverOpen(false);
                },
            },
            _a[NativePath.TASK_GROUP_ID] = {
                label: 'Group',
                viewFunction: function () { return setUserIsTryingToViewTaskGroupTemplate(true); },
            },
            _a);
    }, [viewTaskTemplate, viewTaskRule, setUserIsTryingToViewTaskGroupTemplate, taskEntityId]);
    var popoverContent = useMemo(function () {
        if (!nativePath || !taskEntityPathMap[nativePath])
            return;
        var _a = taskEntityPathMap[nativePath], label = _a.label, viewFunction = _a.viewFunction;
        return (_jsxs("div", { children: [_jsx("p", { className: "text-lg font-medium", children: entityName }), _jsxs(Button, { type: "link", className: "flex flex-row items-center p-0 font-medium", onClick: function (e) {
                        e.stopPropagation();
                        viewFunction();
                    }, loading: isTaskGroupEntity && isFetchingAssignedGroup, children: ["View Task ", label, _jsx(ArrowRightOutlined, { className: "mt-[-1px]" })] })] }));
    }, [nativePath, taskEntityPathMap, entityName, isTaskGroupEntity, isFetchingAssignedGroup]);
    if (!nativePath) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsx(Popover, { placement: "topLeft", content: popoverContent, open: isPopoverOpen, onOpenChange: function (open) { return setIsPopoverOpen(open); }, children: children }));
};
export default React.memo(HoverTaskEntityMenu);
