import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlined } from '@ant-design/icons';
import { Divider, Popover } from 'antd';
import moment from 'moment-timezone';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
var UserAttestationPopOver = function (_a) {
    var name = _a.name, attestation = _a.attestation;
    var _b = attestation || {}, isAttested = _b.isAttested, signatureUrl = _b.signatureUrl, timeAttested = _b.timeAttested;
    var shouldShowTag = !!attestation;
    var timeAttestedDisplay = moment(timeAttested).format('MMMM DD, H:mmA');
    var onDownloadSignature = useCallback(function (e) {
        e.stopPropagation();
        if (signatureUrl) {
            var link = document.createElement('a');
            link.download = "".concat(name, " - signature.png");
            link.href = signatureUrl;
            link.target = '_blank';
            link.click();
        }
    }, [name, signatureUrl]);
    var popOverContent = useMemo(function () { return (_jsxs("div", { children: [_jsx("img", { src: signatureUrl, alt: "Signature", className: "w-[200px]" }), _jsx(Divider, {}), _jsxs("p", { className: "font-semibold text-gray-500", children: ["Attested on: ", _jsx("span", { className: "text-black", children: timeAttestedDisplay })] }), _jsxs("p", { className: "font-semibold text-gray-500", children: ["By ", _jsx("span", { className: "text-black", children: name })] }), _jsx("div", { className: "h-2" }), _jsx("a", { className: "rounded bg-gray-50 px-4 py-1 font-medium", onClick: onDownloadSignature, children: "Print" })] })); }, [name, onDownloadSignature, signatureUrl, timeAttestedDisplay]);
    if (!shouldShowTag) {
        return _jsx(_Fragment, {});
    }
    var labelStyle = 'mr-1 rounded px-3 py-[2px] font-semibold';
    if (!isAttested) {
        return _jsx("span", { className: "".concat(labelStyle, " bg-gray-50"), children: "Pending Attestation" });
    }
    return (_jsx(Popover, { placement: "bottom", content: popOverContent, children: _jsxs("a", { className: "".concat(labelStyle, " bg-green-50 text-green-200 hover:text-black"), children: [_jsx(CheckOutlined, { className: "pb-[2px]" }), " Attested"] }) }));
};
export default React.memo(UserAttestationPopOver);
