var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var MobilePreviewLinkSvg = function () { return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_13650_15982)", children: [_jsx("path", { d: "M8.61605 4H4.61605C4.26243 4 3.92329 4.14048 3.67324 4.39052C3.42319 4.64057 3.28271 4.97971 3.28271 5.33333V12C3.28271 12.3536 3.42319 12.6928 3.67324 12.9428C3.92329 13.1929 4.26243 13.3333 4.61605 13.3333H11.2827C11.6363 13.3333 11.9755 13.1929 12.2255 12.9428C12.4756 12.6928 12.616 12.3536 12.616 12V8", stroke: "#2F4DDF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M7.94922 8.66699L13.9492 2.66699", stroke: "#2F4DDF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.616 2.66699H13.9493V6.00033", stroke: "#2F4DDF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_13650_15982", children: _jsx("rect", { width: "16", height: "16", fill: "white", transform: "translate(0.615967)" }) }) })] })); };
export var MobilePreviewTaskTemplateLink = function (props) { return (_jsx(Icon, __assign({ component: MobilePreviewLinkSvg }, props))); };
