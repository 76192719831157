import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CollectionTableType, RecordSchemaType } from 'src/shared';
import { Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FilePreview } from 'src/utils/icons/TaskIcons/FilePreview';
import './TaskTemplateMobilePreview.scss';
import ModalCellWrapper from 'src/components/OptimizedTableView/Cell/ModalCellWrapper/ModalCellWrapper';
import { TaskTemplateType, } from '../interface';
import { REQUIRED_NATIVE_SCHEMAS_TO_IGNORE } from '../constant';
export var getFileDisplayValue = function (files) {
    return files.map(function (file, idx) {
        if (file.type.includes('image')) {
            return (_jsx("div", { className: "ImagePreview mb-5", children: _jsx(Image, { src: file.url, className: "flex items-center w-full" }) }, idx));
        }
        else {
            return (_jsxs("div", { className: "mb-3 font-medium text-black", children: [_jsx(FilePreview, { className: "mr-2" }), file.name] }, idx));
        }
    });
};
export var getFileUploadPreview = function () { return (_jsxs("div", { className: "bg-gray-50 flex flex-col items-center justify-center gap-1 p-5 border border-gray-100 rounded-lg", children: [_jsx(UploadOutlined, {}), _jsx("span", { className: "text-xs font-medium text-gray-400", children: "Upload File" })] })); };
export var getFieldDisplay = function (displayOptions) {
    var _a;
    var schemaInfo = displayOptions.schemaInfo, taskCollectionId = displayOptions.taskCollectionId, templateId = displayOptions.templateId, hideInputBorder = displayOptions.hideInputBorder;
    var isFileType = (schemaInfo === null || schemaInfo === void 0 ? void 0 : schemaInfo.type) === RecordSchemaType.FILE;
    return (_jsx("div", { className: isFileType || hideInputBorder ? '' : 'TemplateFieldPreview', children: _jsx(ModalCellWrapper, { cellPos: {
                collectionId: taskCollectionId,
                recordId: templateId,
                schemaId: (_a = schemaInfo === null || schemaInfo === void 0 ? void 0 : schemaInfo.id) !== null && _a !== void 0 ? _a : '',
            }, collectionTableType: CollectionTableType.TASK_TEMPLATE_MODAL, editCell: function () { }, style: {
                border: 'none',
                margin: '0',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: 0,
                minHeight: '0px',
            }, isMobilePreview: true }) }));
};
export var getTaskWarningModalContent = function (taskDetail) {
    var content = [];
    if (!(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.name.trim())) {
        content.push('Name');
    }
    if (!(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.displayId.trim())) {
        content.push('Task ID');
    }
    if (!(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type)) {
        content.push('Task Type');
    }
    else {
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.PROFILE_DATA &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).collectionId) {
            content.push('Profile Data');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.WORKSPACE_LINK &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).pageId) {
            content.push('Workspace Page');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.EXTERNAL_LINK &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).url) {
            content.push('External Link');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.VIEW_ONLY &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).buttonLabel) {
            content.push('Button Label');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.SIGNING_PARTNER &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).collectionId) {
            content.push('Collection Type');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.SIGNING_PARTNER &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).fileSchemaId) {
            content.push('File Field');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.SIGNING_PARTNER &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).templateId) {
            content.push('Signing Template');
        }
        if ((taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.SIGNING_PARTNER &&
            !(taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.taskData).buttonLabel) {
            content.push('Button Label');
        }
    }
    return content;
};
export var isTaskSchemaRequired = function (schema) {
    return (schema.isRequired &&
        (!schema.nativePath || !REQUIRED_NATIVE_SCHEMAS_TO_IGNORE.has(schema.nativePath)));
};
