import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Divider, Modal } from 'antd';
import { ActionCheckMarkIcon } from 'src/utils/icons/ActionCheckMarkIcon';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
var SavePayRateConfirmationModal = function (_a) {
    var isModalVisible = _a.isModalVisible, onCloseModal = _a.onCloseModal, onOpenRematchShiftsModal = _a.onOpenRematchShiftsModal;
    return (_jsx(Modal, { open: isModalVisible, closable: false, centered: true, width: 600, footer: _jsx(ZiraModalFooter, { onSave: onCloseModal, saveBtnText: "Done" }), children: _jsxs("div", { children: [_jsxs("div", { className: "flex gap-2 items-center", children: [_jsx(ActionCheckMarkIcon, {}), _jsx("div", { className: "text-base font-semibold", children: "Pay Rates Saved" })] }), _jsx("div", { className: "mt-3 font-medium", children: "Your pay rates have been saved, all shifts created from now on will match based on these rates." }), _jsx(Divider, {}), _jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "font-medium", children: "Need to sync these new rates to existing created shifts?" }), _jsx("button", { className: "rounded-full font-medium  bg-white border-solid border border-gray-60 px-5 py-1 text-center h-auto", onClick: function () {
                                onOpenRematchShiftsModal();
                                onCloseModal();
                            }, children: "Sync Shifts" })] })] }) }));
};
export default React.memo(SavePayRateConfirmationModal);
