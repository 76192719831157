import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ExpertTeamContactWidget from './ExpertTeam/ExpertTeamContactWidget';
import IntercomWhatsNewWidget from './IntercomWhatsNewWidget';
import OnboardingProgressWidget from './Onboarding/OnboardingProgressWidget';
import ReferralInfoCard from './ReferralInfoCard';
var DashboardSiderSection = function () {
    return (_jsxs("div", { className: "DashboardSiderSection", children: [_jsx(OnboardingProgressWidget, {}), _jsx(IntercomWhatsNewWidget, {}), _jsx(ExpertTeamContactWidget, {}), _jsx(ReferralInfoCard, {})] }));
};
export default React.memo(DashboardSiderSection);
