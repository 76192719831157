var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'src/state';
import { isCreatingThreadSelector, selectedMessagesSelector, selectedThreadSummarySelector, } from 'src/state/selectors/withChatState';
import { departmentsState, selectedThreadState } from 'src/state/atoms/chatState';
import { useChatComponent } from 'src/hooks/component/useChat';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { CreateThreadBackgroundIcon } from 'src/utils/icons/ChatIcons/CreateThreadBackgroundIcon';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import { MessageSenderType } from '../../interface';
import AddNewMessageSection from './AddNewMessageSection';
import '../ChatContent.scss';
import ReceiverMessageItem from './ReceiverMessageItem';
import SenderMessageItem from './SenderMessageItem';
var MESSAGE_LIMIT = 20;
var DirectMessageScreen = function () {
    var currentUserInfo = useRecoilValue(currentUserState);
    var selectedThread = useRecoilValue(selectedThreadState);
    var chatMessages = useRecoilValue(selectedMessagesSelector);
    var selectedThreadSummary = useRecoilValue(selectedThreadSummarySelector);
    var recordId = new URLSearchParams(location.search).get('rid');
    var isChatInRecordModal = !!recordId;
    var isDm = useMemo(function () {
        return selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants.every(function (participant) { return participant.participantType === MessageSenderType.USER; });
    }, [selectedThreadSummary]);
    var is2UsersDm = useMemo(function () { return (selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants.length) === 2 && isDm; }, [isDm, selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants.length]);
    var lastMessage = useMemo(function () { return chatMessages[chatMessages.length - 1]; }, [chatMessages]);
    var messageListRef = useRef(null);
    var _a = useState(0), scrollPosition = _a[0], setScrollPosition = _a[1];
    var departmentCanSendAs = useRecoilValue(departmentsState);
    var isCreatingThread = useRecoilValue(isCreatingThreadSelector);
    var department = useMemo(function () {
        var _a;
        if (isCreatingThread || isChatInRecordModal) {
            return departmentCanSendAs.map(function (e) {
                return {
                    id: e.id,
                    name: e.title,
                    type: MessageSenderType.DEPARTMENT,
                };
            });
        }
        if ((_a = selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants) === null || _a === void 0 ? void 0 : _a.length) {
            var departments = selectedThreadSummary.participants
                .filter(function (participant) {
                return participant.participantType === MessageSenderType.DEPARTMENT &&
                    departmentCanSendAs.map(function (e) { return e.id; }).includes(participant.participantRid);
            })
                .map(function (e) {
                return {
                    id: e.participantRid,
                    name: e.name,
                    type: MessageSenderType.DEPARTMENT,
                };
            });
            return departments;
        }
        return [];
    }, [
        departmentCanSendAs,
        isChatInRecordModal,
        isCreatingThread,
        selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants,
    ]);
    var user = useMemo(function () {
        var _a;
        if (isCreatingThread && currentUserInfo) {
            return [
                {
                    id: currentUserInfo.recordId,
                    name: currentUserInfo.fullName,
                    type: MessageSenderType.USER,
                },
            ];
        }
        if ((_a = selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants) === null || _a === void 0 ? void 0 : _a.length) {
            return selectedThreadSummary.participants
                .filter(function (participant) {
                return participant.participantType === MessageSenderType.USER &&
                    participant.participantRid === (currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId);
            })
                .map(function (e) {
                return {
                    id: e.participantRid,
                    name: e.name,
                    type: MessageSenderType.USER,
                };
            });
        }
        return [];
    }, [currentUserInfo, isCreatingThread, selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants]);
    var _b = useChatComponent(), sendMessage = _b.sendMessage, addMessage = _b.addMessage;
    var useGetMessagesFromThread = useChatApi().useGetMessagesFromThread;
    var _c = useState({
        threadId: selectedThread,
        beforeMessageSeqNum: -1,
        limit: MESSAGE_LIMIT,
    }), getMessagesFromThreadRequest = _c[0], setGetMessagesFromThreadRequest = _c[1];
    useEffect(function () {
        setGetMessagesFromThreadRequest({
            threadId: selectedThread,
            beforeMessageSeqNum: -1,
            limit: MESSAGE_LIMIT,
        });
    }, [selectedThread]);
    var _d = useGetMessagesFromThread(getMessagesFromThreadRequest), newMessages = _d.data, isFetching = _d.isFetching;
    useEffect(function () {
        if (newMessages === null || newMessages === void 0 ? void 0 : newMessages.length) {
            newMessages.forEach(function (message) {
                addMessage(message);
            });
            if (getMessagesFromThreadRequest.beforeMessageSeqNum > 0) {
                //! Keep scroll position on pagination
                setTimeout(function () {
                    var _a;
                    return (_a = messageListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                        top: messageListRef.current.scrollHeight - scrollPosition,
                        behavior: 'auto',
                    });
                }, 0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessages]);
    //! Scroll to bottom on new message or thread change
    useEffect(function () {
        if (lastMessage) {
            setTimeout(function () {
                var _a;
                return (_a = messageListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    top: messageListRef.current.scrollHeight,
                });
            }, 0);
        }
    }, [lastMessage]);
    var onSendMessage = useCallback(function (message, department, attachments) {
        sendMessage(message, selectedThread, department, attachments);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedThread]);
    return (_jsxs("div", { className: "MessageScreen", children: [currentUserInfo && chatMessages.length > 0 ? (_jsx("div", { className: "ChatMessageList", ref: messageListRef, onScroll: function (e) {
                    var _a = e.target, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight;
                    if (scrollTop <= 0 &&
                        getMessagesFromThreadRequest.beforeMessageSeqNum !== 0 &&
                        chatMessages.length > 0) {
                        setScrollPosition(scrollHeight);
                        setGetMessagesFromThreadRequest({
                            threadId: selectedThread,
                            beforeMessageSeqNum: Math.min.apply(Math, chatMessages.map(function (message) { return message.seqNum; })) - 1,
                            limit: MESSAGE_LIMIT,
                        });
                    }
                }, children: chatMessages.map(function (message, index) {
                    var _a, _b;
                    if (message.senderDetail.id === (currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId) ||
                        (((_b = (_a = selectedThreadSummary === null || selectedThreadSummary === void 0 ? void 0 : selectedThreadSummary.participants) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 1 &&
                            !!departmentCanSendAs.find(function (department) { var _a; return department.id === ((_a = message.departmentSenderDetail) === null || _a === void 0 ? void 0 : _a.id); }))) {
                        // Message is from you or one of your departments (& not a department only group chat)
                        return (_jsx(SenderMessageItem, { message: message, doNotShowReadByCount: is2UsersDm !== null && is2UsersDm !== void 0 ? is2UsersDm : false }, message.id));
                    }
                    else {
                        return _jsx(ReceiverMessageItem, { message: message }, index);
                    }
                }) })) : !newMessages && isFetching ? (_jsx(LoadingLogo, { className: "grid h-full place-items-center" })) : (_jsxs("div", { className: "ScrollChatNoMain", children: [_jsx(CreateThreadBackgroundIcon, {}), _jsx("span", { className: "NoMessage", children: "Say hi" })] })), _jsx("div", { className: "ChatFooter", children: _jsx(AddNewMessageSection, { currentMessage: '', onSendMessage: onSendMessage, senderOptions: __spreadArray(__spreadArray([], user, true), department, true) }) })] }));
};
export default DirectMessageScreen;
