var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { getAppSession, getOrgIdentifierFromURL } from 'src/utils/Account';
// MARK: -- prepare for different types of upload urls here
export var UploadUrlType;
(function (UploadUrlType) {
    UploadUrlType[UploadUrlType["Profile"] = 0] = "Profile";
    UploadUrlType[UploadUrlType["Page"] = 1] = "Page";
    UploadUrlType[UploadUrlType["Block"] = 2] = "Block";
    UploadUrlType[UploadUrlType["Collection"] = 3] = "Collection";
    UploadUrlType[UploadUrlType["Import"] = 4] = "Import";
    UploadUrlType[UploadUrlType["Store"] = 5] = "Store";
    UploadUrlType[UploadUrlType["Form"] = 6] = "Form";
    UploadUrlType[UploadUrlType["Message"] = 7] = "Message";
    UploadUrlType[UploadUrlType["Public"] = 8] = "Public";
    UploadUrlType[UploadUrlType["MessageAttachment"] = 9] = "MessageAttachment";
})(UploadUrlType || (UploadUrlType = {}));
export var getUploadUrl = function (type) {
    switch (type) {
        case UploadUrlType.Profile:
            return 'files/profile';
        case UploadUrlType.Page:
            return 'files/page';
        case UploadUrlType.Block:
            return 'files/block';
        case UploadUrlType.Collection:
            return 'files/collection_value';
        case UploadUrlType.Import:
            return 'files/import';
        case UploadUrlType.Store:
            return 'files/store_logo';
        case UploadUrlType.Form:
            return 'files/form';
        case UploadUrlType.Message:
            return 'files/in_app_msg';
        case UploadUrlType.Public:
            return 'files/public';
        case UploadUrlType.MessageAttachment:
            return 'files/message_attachment';
        default:
            return '';
    }
};
export var useUpload = function () {
    var baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _a = useState(0), progress = _a[0], setProgress = _a[1];
    var uploadFile = function (args) { return __awaiter(void 0, void 0, void 0, function () {
        var uploadUrlType, formData, uploadUrl, config, _a, appSessionId, org;
        var _b, _c;
        var _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    uploadUrlType = args.uploadUrlType, formData = args.formData;
                    uploadUrl = getUploadUrl(uploadUrlType);
                    _b = {};
                    _c = {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    };
                    _a = "Bearer ".concat;
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1:
                    config = (_b.headers = (_c.Authorization = _a.apply("Bearer ", [_e.sent()]),
                        _c['X-Requested-With'] = 'XMLHttpRequest',
                        _c['X-Client-Name'] = 'AppDot',
                        _c['X-Client-Platform'] = 'Web',
                        _c),
                        _b.onUploadProgress = function (progressEvent) {
                            var _a = progressEvent.progress, uploadProgress = _a === void 0 ? 1 : _a;
                            setProgress(uploadProgress * 100);
                        },
                        _b);
                    appSessionId = (_d = getAppSession()) === null || _d === void 0 ? void 0 : _d.id;
                    if (appSessionId) {
                        config.headers['X-Session-Id'] = appSessionId;
                    }
                    org = getOrgIdentifierFromURL();
                    if (org) {
                        config.headers['X-Client-Org-Name'] = org;
                    }
                    return [2 /*return*/, axios.post(baseUrl + uploadUrl, formData, config)];
            }
        });
    }); };
    var mutation = useMutation(function (args) { return uploadFile(args); });
    return __assign(__assign({}, mutation), { progress: progress });
};
