import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import GeoMap from 'src/components/MapView/GeoMap';
import { NonSchemaNativePath, NativePath } from 'src/shared';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { getNativeFieldCellContent, getNativeFieldCellOptions } from 'src/utils/Collection';
var ShiftDetailGeoMap = function (_a) {
    var dataSource = _a.dataSource, shiftTimeDetails = _a.shiftTimeDetails, nonSchemaNativeFields = _a.nonSchemaNativeFields;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var geoTrackingData = useMemo(function () {
        var _a, _b, _c, _d;
        var geoTracking = nonSchemaNativeFields.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_GEO_TRACKING; });
        if ((geoTracking === null || geoTracking === void 0 ? void 0 : geoTracking.clockInLocation) || (geoTracking === null || geoTracking === void 0 ? void 0 : geoTracking.clockOutLocation)) {
            var geofence = (_a = geoTracking.geofence) === null || _a === void 0 ? void 0 : _a.shape;
            var user = (_b = nonSchemaNativeFields === null || nonSchemaNativeFields === void 0 ? void 0 : nonSchemaNativeFields.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_ASSIGNEE_DATA; })) === null || _b === void 0 ? void 0 : _b.assignee;
            var clockInAt = shiftTimeDetails.clockInAt, clockOutAt = shiftTimeDetails.clockOutAt;
            return {
                clockInLocation: geoTracking.clockInLocation,
                clockInTime: clockInAt,
                clockOutLocation: geoTracking.clockOutLocation,
                clockOutTime: clockOutAt,
                trackedLocations: geoTracking.trackedLocations,
                geofence: geofence
                    ? {
                        coordinates: {
                            lat: geofence.latitude,
                            lng: geofence.longitude,
                        },
                        radius: geofence.radius,
                    }
                    : undefined,
                user: user
                    ? {
                        name: "".concat(user.firstName, " ").concat(user.lastName),
                        avatarUrl: user.avatarUrl,
                    }
                    : undefined,
                locationName: (_d = (_c = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.SHIFT_LOCATION_ID)[0]) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                shiftStatus: getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.SHIFT_STATUS),
            };
        }
        return undefined;
    }, [modalRecord, nonSchemaNativeFields, nativeSchemaMap, shiftTimeDetails]);
    return (_jsx("div", { className: "GeoMap", children: geoTrackingData && (_jsx(GeoMap, { geofence: geoTrackingData.geofence, shiftTrackingData: [geoTrackingData] })) }));
};
export default React.memo(ShiftDetailGeoMap);
