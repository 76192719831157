import { jsx as _jsx } from "react/jsx-runtime";
import { getTagTextColor } from 'src/utils/Collection';
var PaymentRulesTag = function (_a) {
    var children = _a.children, color = _a.color;
    return (_jsx("div", { className: "font-medium rounded-md px-2 py-1", style: {
            backgroundColor: color || '#fafafa',
            color: getTagTextColor(color || '#fafafa'),
        }, children: children }));
};
export default PaymentRulesTag;
