var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AutomationIconStandardSvg = function () { return (_jsxs("svg", { width: "25", height: "25", viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.5", y: "0.984375", width: "24", height: "24", rx: "6", fill: "url(#paint0_linear_15486_318685)" }), _jsx("path", { d: "M12.5 6.98438C9.17692 6.98438 6.5 9.6613 6.5 12.9844C6.5 16.3075 9.17692 18.9844 12.5 18.9844C15.8231 18.9844 18.5 16.3075 18.5 12.9844C18.5 9.6613 15.8231 6.98438 12.5 6.98438ZM14.6231 13.2153C14.6231 13.2153 14.623 13.2153 14.623 13.2153L12.2692 15.1999C11.9922 15.4307 11.5768 15.2461 11.5768 14.9691V10.9999C11.5768 10.723 11.9922 10.5384 12.2692 10.7691L14.6231 12.7538C14.7568 12.843 14.7614 13.0182 14.6784 13.1549C14.6726 13.1643 14.6697 13.1754 14.6661 13.1859C14.6616 13.1991 14.6505 13.2153 14.6231 13.2154C14.6231 13.2154 14.6231 13.2153 14.6231 13.2153Z", fill: "white" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_15486_318685", x1: "3.1087", y1: "6.23234", x2: "29.1957", y2: "21.8845", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#09B6B6" }), _jsx("stop", { offset: "1", stopColor: "#000AFF" })] }) })] })); };
export var AutomationIconStandard = function (props) { return (_jsx(Icon, __assign({ component: AutomationIconStandardSvg }, props))); };
