import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import TableTitleHOC from 'src/components/OptimizedTableView/OptionHeader/Title/TableTitleHOC';
import { useNaviTreeComponent, useTimeZone } from 'src/hooks/component';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { blockTitleSelector } from 'src/state';
import { HelpArticleType, HelpDrawerLinkedArticleMap, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import PaginationRow from 'src/components/OptimizedTableView/PaginationRow';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import PayPeriodModal from './PayPeriodModal';
import PayPeriodCard from './PayPeriodCard';
export var PAY_PERIODS_PAGE_SIZE = 5;
var PayPeriodsBlock = function (_a) {
    var blockId = _a.blockId, moveBlockUpDown = _a.moveBlockUpDown;
    var _b = useState([]), payPeriods = _b[0], setPayPeriods = _b[1];
    var _c = useState(false), isPayPeriodModalVisible = _c[0], setIsPayPeriodModalVisible = _c[1];
    var title = useRecoilValue(blockTitleSelector(blockId));
    var _d = useState({ page: 0, size: PAY_PERIODS_PAGE_SIZE }), pagination = _d[0], setPagination = _d[1];
    var useListPayPeriod = usePayApi().useListPayPeriod;
    var _e = useListPayPeriod({
        body: {
            pagination: pagination,
        },
    }), data = _e.data, isLoading = _e.isLoading, refetch = _e.refetch;
    useEffect(function () {
        if (data) {
            if (pagination.page) {
                setPayPeriods(function (prevPayPeriods) { return prevPayPeriods.concat(data.payPeriods); });
            }
            else {
                setPayPeriods(data.payPeriods);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    var onLoadNextPage = useCallback(function () {
        setPagination(function (prev) { return ({
            page: prev.page + ((data === null || data === void 0 ? void 0 : data.payPeriods.length) ? 1 : 0),
            size: PAY_PERIODS_PAGE_SIZE,
        }); });
    }, [data === null || data === void 0 ? void 0 : data.payPeriods.length]);
    var saveRenameNode = useNaviTreeComponent().saveRenameNode;
    var onUpdateTitle = useCallback(function (title, requestSave) {
        saveRenameNode('block', blockId, title, requestSave);
    }, [blockId, saveRenameNode]);
    var timeZone = useTimeZone().timeZone;
    var _f = useState(), editedPayPeriodId = _f[0], setEditedPayPeriodId = _f[1];
    var _g = useState(), defaultDates = _g[0], setDefaultDates = _g[1];
    var _h = useState(timeZone), editedpayPeriodTimeZone = _h[0], setEditedPayPeriodTimeZone = _h[1];
    var onOpenEditPayPeriod = useCallback(function (payPeriodId, startDate, endDate, payPeriodTimeZone) {
        setEditedPayPeriodId(payPeriodId);
        setDefaultDates([startDate, endDate]);
        setEditedPayPeriodTimeZone(payPeriodTimeZone);
        setIsPayPeriodModalVisible(true);
    }, []);
    var onOpenCreatePayPeriod = useCallback(function () {
        setEditedPayPeriodId(undefined);
        setDefaultDates(undefined);
        setEditedPayPeriodTimeZone(timeZone);
        setIsPayPeriodModalVisible(true);
    }, [timeZone]);
    var onRefetchPayPeriods = useCallback(function () {
        if (pagination.size !== Math.max(payPeriods.length, PAY_PERIODS_PAGE_SIZE)) {
            setPagination({
                page: 0,
                size: Math.max(payPeriods.length, PAY_PERIODS_PAGE_SIZE),
            });
        }
        else {
            refetch();
        }
    }, [pagination.size, payPeriods.length, refetch]);
    return isLoading && !payPeriods.length ? (_jsxs("div", { className: "TableOptionHeaderWrapper", children: [_jsx(HoverOptionButtons, { data: {
                    blockId: blockId,
                }, moveBlockUpDown: moveBlockUpDown }), _jsx(Skeleton, { active: true })] })) : (_jsxs("div", { className: "TableOptionHeaderWrapper", children: [_jsx(HoverOptionButtons, { data: {
                    blockId: blockId,
                }, moveBlockUpDown: moveBlockUpDown }), _jsxs("div", { className: "flex w-full justify-between", children: [_jsx(TableTitleHOC, { title: title, updateTitle: onUpdateTitle }), _jsxs("div", { className: "flex", children: [_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.PAY_PERIOD], children: _jsx(Button, { className: "mr-2 bg-gray-50 font-medium  hover:bg-gray-50 hover:text-black", children: "Learn About Pay Period" }) }), _jsx(Button, { className: "bg-black font-medium text-white hover:bg-black hover:text-white", onClick: onOpenCreatePayPeriod, children: "Add Pay Period" })] })] }), payPeriods.map(function (payPeriod) {
                return (_jsx(PayPeriodCard, { payPeriod: payPeriod, onOpenEditPayPeriod: function () {
                        return onOpenEditPayPeriod(payPeriod.payPeriodId, payPeriod.startInstant, payPeriod.endInstant, payPeriod.payPeriodTimezone);
                    }, onRefetchPayPeriods: onRefetchPayPeriods }, payPeriod.payPeriodId));
            }), !!payPeriods.length && (_jsx(PaginationRow, { isLoading: isLoading, onLoadMore: onLoadNextPage, noRecordsFound: !!payPeriods.length && !(data === null || data === void 0 ? void 0 : data.payPeriods.length) })), !payPeriods.length && (_jsx("div", { className: "ml-1.5 mt-1 font-medium text-gray-300", children: "No Pay Periods" })), _jsx("div", { className: "my-5" }), isPayPeriodModalVisible && (_jsx(PayPeriodModal, { setPayPeriods: setPayPeriods, onClose: function () { return setIsPayPeriodModalVisible(false); }, payPeriodId: editedPayPeriodId, defaultDates: defaultDates, payPeriodListSize: payPeriods.length, payPeriodTimeZone: editedpayPeriodTimeZone }))] }));
};
export default React.memo(PayPeriodsBlock);
