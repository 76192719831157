var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EllipsisOutlined, SettingOutlined, } from '@ant-design/icons';
import { Button, Collapse, Input, Row, Select } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useAccountApi, UploadUrlType, useSetting } from 'src/hooks/api';
import { useTimeZone } from 'src/hooks/component';
import { getMutateSchemaProperties } from 'src/services';
import { SubMenuContentIcon, ValueDataType } from '../../../../../../shared';
import { isDescription, isEntry, isImage, } from '../../../../../../services/Interfaces';
import { getColumnTypeKey } from '../../../../../../utils/Collection';
import CustomCell from '../../../../../Cell/CustomCell';
import ComponentWithUpload, { UploadFileType } from '../../../../../ComponentWithUpload';
import { SchemaWithNewValuesType } from '../../../../../RecordDetailModal/RecordDetailModal';
import ZiraDropdown from '../../../../../../ui/ZiraDropdown';
import FormTemplateState from '../../../FormTemplate';
var Panel = Collapse.Panel;
var Option = Select.Option, OptGroup = Select.OptGroup;
var ItemPanelHeader = function (props) {
    var item = props.item, index = props.index, availableSchemasOfSourceCollection = props.availableSchemasOfSourceCollection;
    var _a = FormTemplateState.useContainer(), formLayout = _a.formLayout, setFormLayout = _a.setFormLayout;
    var onHandleSelect = useCallback(function (value) {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            var itemToChange = copy[index];
            if (isEntry(itemToChange)) {
                if (value == 'IS_AUTHOR') {
                    copy[index] = __assign(__assign({}, itemToChange), { isAuthor: true, visibility: 'HIDDEN', required: false, defaultDisplayValue: undefined, defaultValue: undefined });
                }
                else if (value == 'REQUIRED') {
                    itemToChange = __assign(__assign({}, itemToChange), { required: true, visibility: 'EDITABLE' });
                    if (itemToChange.isAuthor)
                        itemToChange.isAuthor = false;
                    copy[index] = itemToChange;
                }
                else {
                    itemToChange = __assign(__assign({}, itemToChange), { visibility: value, required: false });
                    if (itemToChange.isAuthor)
                        itemToChange.isAuthor = false;
                    copy[index] = itemToChange;
                }
            }
            return copy;
        });
    }, [index, setFormLayout]);
    var onHandleDelete = useCallback(function () {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            copy.splice(index, 1);
            return copy;
        });
    }, [index, setFormLayout]);
    var stopPropagation = useCallback(function (e) {
        e.stopPropagation();
    }, []);
    var onHandleMoveUp = useCallback(function () {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            var toMove = copy.splice(index, 1)[0];
            copy.splice(index - 1, 0, toMove);
            return copy;
        });
    }, [index, setFormLayout]);
    var onHandleMoveDown = useCallback(function () {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            var toMove = copy.splice(index, 1)[0];
            copy.splice(index + 1, 0, toMove);
            return copy;
        });
    }, [index, setFormLayout]);
    var getHeaderIcon = useCallback(function () {
        if (!item)
            return (_jsx("div", { className: "ItemPanelHeaderIconContainer", children: _jsx(SettingOutlined, { className: "ItemPanelHeaderIcon", style: {
                        background: '#172670',
                        color: 'white',
                    } }) }));
        if (isEntry(item)) {
            var schema = availableSchemasOfSourceCollection === null || availableSchemasOfSourceCollection === void 0 ? void 0 : availableSchemasOfSourceCollection.find(function (schema) { return schema.id == item.schemaId; });
            if (schema == undefined)
                return;
            return (_jsx("div", { className: "ItemPanelHeaderIconContainer", children: SubMenuContentIcon[getColumnTypeKey(schema.inputMode, schema.type)] }));
        }
        if (isDescription(item)) {
            return _jsx("div", { className: "ItemPanelHeaderIconContainer", children: SubMenuContentIcon['text'] });
        }
        else {
            return (_jsx("div", { className: "ItemPanelHeaderIconContainer", children: SubMenuContentIcon['custom'] }));
        }
    }, [availableSchemasOfSourceCollection, item]);
    var menuItems = useMemo(function () {
        var items = [];
        items.push({
            key: 'delete',
            icon: _jsx(DeleteOutlined, { className: "DropdownItemIcon" }),
            label: 'Delete',
            onClick: onHandleDelete,
        });
        if (index != 0) {
            items.push({
                key: 'moveUp',
                icon: _jsx(ArrowUpOutlined, { className: "DropdownItemIcon" }),
                label: 'Move Up',
                onClick: onHandleMoveUp,
            });
        }
        if (index != formLayout.length - 1) {
            items.push({
                key: 'moveDown',
                icon: _jsx(ArrowDownOutlined, { className: "DropdownItemIcon" }),
                label: 'Move Down',
                onClick: onHandleMoveDown,
            });
        }
        return items;
    }, [formLayout.length, index, onHandleDelete, onHandleMoveDown, onHandleMoveUp]);
    if (!item) {
        return (_jsxs(Row, { align: "middle", className: "ItemPanelHeaderRow", children: [getHeaderIcon(), _jsx("div", { className: "ItemPanelHeaderText", children: "Form Settings" })] }));
    }
    if (isEntry(item)) {
        return (_jsxs(Row, { justify: "space-between", align: "middle", className: "ItemPanelHeaderRow", children: [_jsxs(Row, { children: [getHeaderIcon(), _jsx("div", { className: "ItemPanelHeaderText", children: item.label })] }), _jsxs(Row, { align: "middle", onClick: stopPropagation, children: [_jsxs(Select, { value: item.isAuthor
                                ? 'IS_AUTHOR'
                                : item.required
                                    ? 'REQUIRED'
                                    : item.visibility, onChange: onHandleSelect, bordered: false, className: "ItemPanelHeaderSelect", dropdownMatchSelectWidth: false, children: [_jsxs(OptGroup, { label: "Visibility", children: [_jsx(Option, { value: "EDITABLE", children: "Editable" }), _jsx(Option, { value: "VIEW_ONLY", children: "Locked" }), _jsx(Option, { value: "HIDDEN", children: "Hidden" })] }), _jsx(OptGroup, { label: "Requirement", children: _jsx(Option, { value: "REQUIRED", children: "Required" }) }), item.isAuthor != null && (_jsx(OptGroup, { label: "User Reference", children: _jsx(Option, { value: "IS_AUTHOR", children: "Mark as Author" }) }))] }), _jsx(ZiraDropdown, { menu: { items: menuItems }, trigger: ['click'], children: _jsx(Button, { icon: _jsx(EllipsisOutlined, {}) }) })] })] }));
    }
    return (_jsxs(Row, { justify: "space-between", align: "middle", className: "ItemPanelHeaderRow", children: [_jsxs(Row, { children: [getHeaderIcon(), _jsx("div", { className: "ItemPanelHeaderText", children: item.label })] }), _jsx("div", { onClick: stopPropagation, children: _jsx(ZiraDropdown, { menu: { items: menuItems }, trigger: ['click'], children: _jsx(Button, { icon: _jsx(EllipsisOutlined, {}) }) }) })] }));
};
var PanelBody = function (props) {
    var item = props.item, index = props.index, schema = props.schema;
    var _a = FormTemplateState.useContainer(), templateCollectionId = _a.templateCollectionId, modalTemplateName = _a.modalTemplateName, setFormLayout = _a.setFormLayout, setModalTemplateName = _a.setModalTemplateName, setTemplateCollectionId = _a.setTemplateCollectionId, deleteFile = _a.deleteFile;
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var collectionOptions = useGetAccountCollectionOptionsQuery().data;
    var handleChangeLabel = useCallback(function (e) {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            var itemToChange = copy[index];
            itemToChange.label = e.target.value;
            return copy;
        });
    }, [index, setFormLayout]);
    var handleChangeDescription = useCallback(function (e) {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            var itemToChange = copy[index];
            if (isEntry(itemToChange) || isDescription(itemToChange)) {
                itemToChange.description = e.target.value;
            }
            return copy;
        });
    }, [index, setFormLayout]);
    var acceptedFileType = useMemo(function () { return [UploadFileType.IMAGE]; }, []);
    var handleChangeImage = useCallback(function (info) {
        setFormLayout(function (prevFormLayout) {
            var copy = cloneDeep(prevFormLayout);
            var itemToChange = copy[index];
            if (isImage(itemToChange)) {
                if (itemToChange.imageUrl != '') {
                    deleteFile(itemToChange.imageUrl);
                }
                itemToChange.imageUrl = info.file.response.data.url;
            }
            return copy;
        });
    }, [deleteFile, index, setFormLayout]);
    var timeZone = useTimeZone().timeZone;
    var onEditDefaultValue = useCallback(function (value) {
        setFormLayout(function (prevFormlayout) {
            var copy = cloneDeep(prevFormlayout);
            var itemToChange = copy[index];
            if (isEntry(itemToChange) && schema && schema.type !== ValueDataType.FILE) {
                var defaultValue = getMutateSchemaProperties([
                    {
                        schema: schema,
                        newValues: value,
                        type: SchemaWithNewValuesType.NORMAL,
                    },
                ], timeZone)[0];
                itemToChange.defaultValue = defaultValue;
                itemToChange.defaultDisplayValue = value;
            }
            return copy;
        });
    }, [index, schema, setFormLayout, timeZone]);
    var setTemplateCollectionIdAndClearLayout = useCallback(function (value) {
        setTemplateCollectionId(value);
        setFormLayout([]);
    }, [setFormLayout, setTemplateCollectionId]);
    var collectionDropdownOptions = useMemo(function () {
        return collectionOptions === null || collectionOptions === void 0 ? void 0 : collectionOptions.map(function (option) { return ({
            key: option.id,
            title: option.name,
            type: option.type,
            description: option.columns.toString(),
        }); });
    }, [collectionOptions]);
    if (!item)
        return (_jsxs("div", { className: "PanelBodyWrapper", children: [_jsx("div", { className: "PanelBodyLabel", children: "Form Name" }), _jsx(Input, { className: "FormTemplateInput", placeholder: "Enter a name", value: modalTemplateName, onChange: function (e) { return setModalTemplateName(e.target.value); } }), _jsx("div", { className: "PanelBodyLabel", children: "Data Source" }), _jsx(Select, { className: "FormTemplateCollectionSelect", placeholder: "Select a source collection", showArrow: false, value: templateCollectionId, onChange: setTemplateCollectionIdAndClearLayout, children: collectionDropdownOptions === null || collectionDropdownOptions === void 0 ? void 0 : collectionDropdownOptions.map(function (item) { return (_jsx(Option, { value: item.key, children: _jsx("div", { className: "ItemTitleAndDescription", children: item.title }) }, item.key)); }) })] }));
    if (isEntry(item) && schema) {
        var cellProps = {
            schema: schema,
            editCell: onEditDefaultValue,
            canEdit: true,
            defaultValue: item.defaultDisplayValue,
        };
        return (_jsxs("div", { className: "PanelBodyWrapper", children: [_jsx("div", { className: "PanelBodyLabel", children: "Label" }), _jsx(Input, { value: item.label, onChange: handleChangeLabel, className: "FormTemplateInput" }), _jsx("div", { className: "PanelBodyLabel", children: "Description (Optional)" }), _jsx(Input, { value: item.description, onChange: handleChangeDescription, className: "FormTemplateInput" }), _jsx("div", { className: "PanelBodyLabel", children: "Default Value" }), schema.type !== ValueDataType.FILE && (_jsx(Row, { justify: "space-between", align: "middle", children: _jsx("div", { className: "DefaultValueInputWrapper", children: _jsx(CustomCell, __assign({}, cellProps)) }) }))] }));
    }
    if (isDescription(item)) {
        return (_jsxs("div", { className: "PanelBodyWrapper", children: [_jsx("div", { className: "PanelBodyLabel", children: "Label" }), _jsx(Input, { value: item.label, onChange: handleChangeLabel, className: "FormTemplateInput" }), _jsx("div", { className: "PanelBodyLabel", children: "Description (Optional)" }), _jsx(Input, { value: item.description, onChange: handleChangeDescription, className: "FormTemplateInput" })] }));
    }
    return (_jsxs("div", { className: "PanelBodyWrapper", children: [_jsx("div", { className: "PanelBodyLabel", children: "Label" }), _jsx(Input, { value: item.label, onChange: handleChangeLabel, className: "FormTemplateInput" }), _jsx("div", { className: "PanelBodyLabel", children: "Upload Image" }), _jsx(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: handleChangeImage, type: UploadUrlType.Form, children: _jsx(Button, { type: "primary", children: "Upload" }) })] }));
};
var FormTemplateLayoutItemCollapse = function () {
    var _a = FormTemplateState.useContainer(), templateCollectionId = _a.templateCollectionId, formLayout = _a.formLayout;
    var useGetAvailableSchemasInCollection = useSetting().useGetAvailableSchemasInCollection;
    var availableSchemasOfSourceCollection = useGetAvailableSchemasInCollection(templateCollectionId).data;
    return (_jsxs(Collapse, { bordered: false, className: "LayoutItemCollapse", ghost: true, defaultActiveKey: "settings", children: [_jsx(Panel, { header: _jsx(ItemPanelHeader, { index: -1 }), className: "LayoutItemPanel", children: _jsx(PanelBody, { index: -1 }) }, "settings"), formLayout.map(function (item, index) { return (_jsx(Panel, { header: _jsx(ItemPanelHeader, { item: item, index: index, availableSchemasOfSourceCollection: availableSchemasOfSourceCollection }), className: "LayoutItemPanel", children: _jsx(PanelBody, { item: item, index: index, schema: availableSchemasOfSourceCollection === null || availableSchemasOfSourceCollection === void 0 ? void 0 : availableSchemasOfSourceCollection.find(function (schema) { return isEntry(item) && schema.id == item.schemaId; }) }) }, index)); })] }));
};
export default React.memo(FormTemplateLayoutItemCollapse);
