import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Carousel, Skeleton } from 'antd';
import { useRecoilValue } from 'recoil';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useAccountHelpContentApi } from 'src/hooks/api/useAccountHelpContentApi';
import { isHelpDrawerVisibleState } from 'src/state/selectors/withHelpDrawerState';
import { selectFirstThreeArticlesInCollection } from 'src/utils/HelpDrawer';
import ArticleCard from './ArticleCard';
import HelpTopicCard from './HelpTopicCard';
var HelpHome = function (props) {
    var goToArticle = props.goToArticle, goToTopicCollection = props.goToTopicCollection;
    var articlesCarouselRef = useRef(null);
    var _a = useState(0), cardIndex = _a[0], setCardIndex = _a[1];
    var goNextCard = useCallback(function () { return setCardIndex(function (prev) { return prev + 1; }); }, []);
    var goPrevCard = useCallback(function () { return setCardIndex(function (prev) { return prev - 1; }); }, []);
    var isDrawerVisible = useRecoilValue(isHelpDrawerVisibleState);
    var useGetHelpTopicCollections = useAccountHelpContentApi().useGetHelpTopicCollections;
    var _b = useGetHelpTopicCollections({
        options: {
            enabled: isDrawerVisible,
        },
    }), helpCollectionData = _b.data, isLoading = _b.isLoading;
    var whatsNewArticlesCollection = useMemo(function () {
        if (!helpCollectionData)
            return [];
        return selectFirstThreeArticlesInCollection(helpCollectionData.groupedArticles[0]);
    }, [helpCollectionData]);
    if (!helpCollectionData || isLoading) {
        return (_jsx("div", { className: "px-5", children: _jsx(Skeleton, { active: true, className: "mt-5" }) }));
    }
    return (_jsxs("div", { className: "HelpHomeContent", children: [whatsNewArticlesCollection.length !== 0 && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-3 flex items-center justify-between px-5", children: [_jsx("div", { className: "font-medium", children: "What's New" }), _jsxs("div", { className: "flex items-center", children: [_jsx(Button, { className: "LeftRightBtn", icon: _jsx(LeftOutlined, {}), disabled: cardIndex === 0, onClick: function () {
                                            var _a;
                                            (_a = articlesCarouselRef === null || articlesCarouselRef === void 0 ? void 0 : articlesCarouselRef.current) === null || _a === void 0 ? void 0 : _a.prev();
                                            goPrevCard();
                                        } }), _jsx("div", { children: "".concat(cardIndex + 1, " / ").concat(whatsNewArticlesCollection.length + 1) }), _jsx(Button, { className: "LeftRightBtn", icon: _jsx(RightOutlined, {}), onClick: function () {
                                            var _a;
                                            (_a = articlesCarouselRef === null || articlesCarouselRef === void 0 ? void 0 : articlesCarouselRef.current) === null || _a === void 0 ? void 0 : _a.next();
                                            goNextCard();
                                        }, disabled: cardIndex === whatsNewArticlesCollection.length })] })] }), _jsxs(Carousel, { className: "ArticlesCarousel mb-8", ref: articlesCarouselRef, children: [whatsNewArticlesCollection.map(function (article) { return (_jsx("div", { className: "px-5", children: _jsx(ArticleCard, { goToArticle: function () {
                                        return goToArticle(article, helpCollectionData.groupedArticles[0]);
                                    }, articleCardInfo: article, renderImg: true }, article.id) }, article.id)); }), _jsx("div", { className: "px-5", children: _jsx("div", { className: "ArticleCard m-auto h-[200px] w-full cursor-pointer overflow-y-hidden rounded border border-gray-60 bg-gray-50 text-center text-base font-medium leading-[200px] text-gray-500", onClick: function () {
                                        return goToTopicCollection(helpCollectionData.groupedArticles[0]);
                                    }, children: "View All" }) })] })] })), _jsxs("div", { className: "px-5", children: [_jsx("div", { className: "mb-3 font-medium", children: "Explore Help Topics" }), helpCollectionData.groupedArticles.map(function (topic, index) {
                        if (index !== 0)
                            return (_jsx(HelpTopicCard, { info: topic.collection, goToTopicCollection: function () { return goToTopicCollection(topic); } }, "".concat(topic.collection.name, "+").concat(index)));
                    })] })] }));
};
export default React.memo(HelpHome);
