var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { Modal, Switch } from 'antd';
import { startCase } from 'lodash';
import SVG from 'react-inlinesvg';
import { InfoCircleOutlined } from '@ant-design/icons';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { TaskRuleJobStatus, } from '../interface';
var TaskSaveOptionsModal = function (_a) {
    var type = _a.type, _b = _a.saveType, saveType = _b === void 0 ? 'update' : _b, onClose = _a.onClose, onSave = _a.onSave;
    var _c = useState({
        updateExisting: false,
        deleteAssignedTasks: false,
        updateExistingJobs: [],
    }), saveOptions = _c[0], setSaveOptions = _c[1];
    var taskType = startCase(type);
    var svg = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M6.86001 2.80101L1.21335 12.2277C1.09693 12.4293 1.03533 12.6579 1.03467 12.8907C1.03402 13.1235 1.09434 13.3524 1.20963 13.5547C1.32492 13.757 1.49116 13.9255 1.69182 14.0436C1.89247 14.1616 2.12055 14.2251 2.35335 14.2277H13.6467C13.8795 14.2251 14.1076 14.1616 14.3082 14.0436C14.5089 13.9255 14.6751 13.757 14.7904 13.5547C14.9057 13.3524 14.966 13.1235 14.9654 12.8907C14.9647 12.6579 14.9031 12.4293 14.7867 12.2277L9.14001 2.80101C9.02117 2.60508 8.85383 2.44309 8.65414 2.33066C8.45446 2.21824 8.22917 2.15918 8.00001 2.15918C7.77086 2.15918 7.54557 2.21824 7.34588 2.33066C7.1462 2.44309 6.97886 2.60508 6.86001 2.80101Z" stroke="#818A9B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 6.22754V8.89421" stroke="#818A9B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 11.561H8.00667" stroke="#818A9B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    var description = useMemo(function () {
        switch (type) {
            case 'task':
                return (_jsxs("span", { children: ["This task will apply to", ' ', _jsx("span", { className: "text-[#626263]", children: "all future tasks" }), " by default. If you have custom preferences please select above."] }));
            case 'group':
                return (_jsxs("span", { children: ["This group will apply to", ' ', _jsx("span", { className: "text-[#626263]", children: "all future groups" }), " by default. If you have custom preferences please select above."] }));
            case 'rule':
                return (_jsxs("span", { children: ["This group will apply to", ' ', _jsx("span", { className: "text-[#626263]", children: "all future jobs and placements" }), " by default. If you have custom preferences please select above."] }));
            default:
                return (_jsxs("span", { children: ["This ", type, " will apply to", ' ', _jsxs("span", { className: "text-[#626263]", children: ["all ", type, "s"] }), " by default. If you have custom preferences please select above."] }));
        }
    }, [type]);
    return (_jsx(Modal, { wrapClassName: "TaskSaveOptionsModal", open: true, centered: true, zIndex: 1002, okText: "Save ".concat(taskType), okButtonProps: {
            type: 'default',
            className: 'ZiraModalSaveBtn',
        }, onCancel: onClose, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, onSave(saveOptions)];
                    case 1:
                        _a.sent();
                        onClose();
                        return [2 /*return*/];
                }
            });
        }); }, closable: false, className: "max-w-[360px]", children: _jsxs("div", { className: "flex flex-col gap-4 font-medium", children: [_jsxs("div", { className: "flex items-center text-base gap-2", children: [_jsx(SVG, { src: svg }), _jsxs("span", { children: ["Apply ", type, " to"] })] }), type === 'rule' && (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "text-sm", children: "Existing Jobs (Open Only)" }), _jsx("div", { className: "ml-auto flex items-center gap-2", children: _jsx(Switch, { className: "text-[#7E85F9] mt-0", checked: saveOptions.updateExistingJobs[0] === TaskRuleJobStatus.OPEN, onChange: function (checked) {
                                    setSaveOptions(function (prev) { return (__assign(__assign({}, prev), { updateExistingJobs: checked ? [TaskRuleJobStatus.OPEN] : [] })); });
                                } }) })] })), saveType === 'update' && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center", children: [_jsxs("div", { className: "text-sm", children: ["Existing ", type === 'rule' ? 'Placement' : taskType, "s"] }), _jsx(Switch, { className: "ml-auto mt-0", checked: saveOptions.updateExisting, onChange: function (checked) {
                                        setSaveOptions(function (prev) { return (__assign(__assign({}, prev), { updateExisting: checked })); });
                                    } })] }), (type === 'group' || type === 'rule') && (_jsxs("div", { className: "flex items-center", style: !saveOptions.updateExisting ? { opacity: 0.5 } : undefined, children: [_jsx("div", { className: "text-sm pr-[8px]", children: "Delete Tasks" }), _jsx(ZiraTooltip, { title: type === 'group'
                                        ? 'When enabled, this will delete all assigned tasks no longer part of this group'
                                        : 'When enabled, this will delete all assigned tasks/groups no longer part of this rule from existing placements', children: _jsx(InfoCircleOutlined, {}) }), _jsx(Switch, { className: "ml-auto mt-0", disabled: !saveOptions.updateExisting, checked: saveOptions.updateExisting &&
                                        saveOptions.deleteAssignedTasks, onChange: function (checked) {
                                        setSaveOptions(function (prev) { return (__assign(__assign({}, prev), { deleteAssignedTasks: checked })); });
                                    } })] }))] })), _jsxs("div", { className: "flex w-full h-full bg-gray-50 p-2 gap-2", children: [_jsx(InfoCircleOutlined, { className: "opacity-60 mt-1" }), _jsx("span", { className: "text-[#929294]", children: description })] })] }) }));
};
export default React.memo(TaskSaveOptionsModal);
