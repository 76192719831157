import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Row } from 'antd';
import { useRecoilValue } from 'recoil';
import { ClockOutlined } from 'src/utils/icons/DashboardIcons/ClockOutlined';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import { getSnapshotListTotalNumber } from '../DashboardUtils';
import { numberOfVisibleSchedulingSnapshotListSelector } from '../Selectors';
import { ScheduleSnapshotStatus } from '../Constant';
import SchedulingSnapshotList from './SchedulingSnapshotList';
import './SchedulingSnapshotWidget.scss';
import SchedulingSnapshotOptionsDropdown from './SchedulingListOptionsDropdown';
import EmptySnapShotListContent from './EmptySnapShotListContent';
var SchedulingSnapshotWidget = function () {
    var useGetSnapshotShiftTotalCounts = useDashboardApi().useGetSnapshotShiftTotalCounts;
    var _a = useGetSnapshotShiftTotalCounts(), data = _a.data, isLoading = _a.isLoading;
    var numberOfSchedulingSnapshotVisibleList = useRecoilValue(numberOfVisibleSchedulingSnapshotListSelector);
    return (_jsxs("div", { className: "SchedulingSnapshotWidgetContainer shadow-block", children: [_jsxs("div", { className: "mb-5 flex justify-between", children: [_jsxs("div", { className: "flex items-center pl-[7px] text-xl font-medium", children: [_jsx(ClockOutlined, { className: "mr-1", style: { color: 'black', width: '24px' } }), _jsx("div", { children: "Today's Snapshot" })] }), _jsx(SchedulingSnapshotOptionsDropdown, {})] }), numberOfSchedulingSnapshotVisibleList === 0 ? (_jsx(EmptySnapShotListContent, {})) : (_jsx(Row, { gutter: 24, children: Object.keys(ScheduleSnapshotStatus).map(function (k) {
                    var _a;
                    var status = k;
                    var totalNumber = getSnapshotListTotalNumber((_a = data === null || data === void 0 ? void 0 : data.counts) !== null && _a !== void 0 ? _a : [], status);
                    return (_jsx(SchedulingSnapshotList, { schedulingStatus: status, isTotalNumberLoading: isLoading, totalNumber: totalNumber }, status));
                }) }))] }));
};
export default React.memo(SchedulingSnapshotWidget);
