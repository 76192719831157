import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
export var useNavigationStaticMenuApi = function () {
    var customFetch = useFetch().request;
    var useGetExportHistory = function () {
        return useQuery(['useGetExportHistory'], function () {
            return customFetch({
                method: 'GET',
                url: 'export/history',
            });
        });
    };
    return {
        useGetExportHistory: useGetExportHistory,
    };
};
