import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
import React from 'react';
import spacetime from 'spacetime';
import allTimezones from './Constant';
var Option = Select.Option;
var TimeZoneSelector = function (props) {
    var timezoneValue = props.timezoneValue, timezoneOnChange = props.timezoneOnChange, _a = props.canEdit, canEdit = _a === void 0 ? true : _a, _b = props.useValues, useValues = _b === void 0 ? false : _b, _c = props.optionClassName, optionClassName = _c === void 0 ? '' : _c;
    var getOptions = React.useMemo(function () {
        return Object.entries(allTimezones)
            .reduce(function (selectOptions, zone) {
            var now = spacetime.now(zone[0]);
            var tz = now.timezone();
            var label = '';
            var min = tz.current.offset * 60;
            var hr = "".concat((min / 60) ^ 0, ":") + (min % 60 === 0 ? '00' : Math.abs(min % 60));
            var prefix = "(GMT".concat(hr.includes('-') ? hr : "+".concat(hr), ") ").concat(zone[1]);
            label = prefix;
            selectOptions.push({
                value: tz.name,
                label: label,
                offset: tz.current.offset,
            });
            return selectOptions;
        }, [])
            .sort(function (a, b) { return a.offset - b.offset; });
    }, []);
    return (_jsx(Select, { className: "TimeZoneSelector", value: timezoneValue, onChange: function (value) { return timezoneOnChange(value); }, disabled: !canEdit, children: getOptions.map(function (item) { return (_jsx(Option, { value: item.value, className: optionClassName, children: useValues ? item.value : item.label }, item.value)); }) }));
};
export default React.memo(TimeZoneSelector);
