import { selectorFamily } from 'recoil';
import { OPTIONS_COLOR_MAP } from 'src/shared';
import { taskGroupListState, taskRuleListState, taskTemplateListState } from './atom';
var taskGroupSelector = selectorFamily({
    key: 'taskGroupSelector',
    get: function (taskGroupId) {
        return function (_a) {
            var get = _a.get;
            var taskGroupList = get(taskGroupListState);
            return taskGroupList.find(function (taskGroup) { return taskGroup.id === taskGroupId; });
        };
    },
});
var taskTemplateSelector = selectorFamily({
    key: 'taskTemplateSelector',
    get: function (taskTemplateId) {
        return function (_a) {
            var get = _a.get;
            var taskTemplateList = get(taskTemplateListState);
            return taskTemplateList.find(function (taskTemplate) { return taskTemplate.id === taskTemplateId; });
        };
    },
});
var taskTemplateGroupTagInfoSelector = selectorFamily({
    key: 'taskTemplateGroupTagInfoSelector',
    get: function (taskTemplateId) {
        return function (_a) {
            var get = _a.get;
            var taskGroupList = get(taskGroupListState);
            var groupsTaskTemplateIsIn = taskGroupList.filter(function (taskGroup) {
                return taskGroup.orderedTaskIds.includes(taskTemplateId);
            });
            var groupCount = groupsTaskTemplateIsIn.length;
            return groupCount === 1
                ? { text: groupsTaskTemplateIsIn[0].name, backgroundColor: OPTIONS_COLOR_MAP.Blue }
                : { text: "".concat(groupCount, " groups"), backgroundColor: '#F4F4F7' };
        };
    },
});
var taskRuleSelector = selectorFamily({
    key: 'taskRuleSelector',
    get: function (taskRuleId) {
        return function (_a) {
            var get = _a.get;
            var taskRuleList = get(taskRuleListState);
            return taskRuleList.find(function (taskRule) { return taskRule.id === taskRuleId; });
        };
    },
});
export { taskGroupSelector, taskTemplateSelector, taskTemplateGroupTagInfoSelector, taskRuleSelector, };
