var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ManagementSettingsIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", height: "24", width: "24", children: _jsxs("g", { children: [_jsx("rect", { x: "0.5", y: "4.5", width: "13", height: "9", rx: "1", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "0.5", y1: "8.5", x2: "13.5", y2: "8.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "7", y1: "7.5", x2: "7", y2: "9.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10,4.5a3,3,0,0,0-3-3H7a3,3,0,0,0-3,3", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" })] }) })); };
export var ManagementSettingsIcon = function (props) { return (_jsx(Icon, __assign({ component: ManagementSettingsIconSvg }, props))); };
