var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cloneDeep, isEqual } from 'lodash';
export var addSchemaIdToDisplaySettings = function (displaySettings, newSchemaId, insertAt) {
    if (insertAt) {
        var temp = cloneDeep(displaySettings);
        var index = displaySettings.schemaOptions.findIndex(function (schema) { return schema.schemaId === insertAt.nextToSchemaId; });
        if (index >= 0) {
            temp.schemaOptions.splice(insertAt.location === 'LEFT' ? index : index + 1, 0, {
                hidden: false,
                schemaId: newSchemaId,
                width: 0,
            });
        }
    }
    return cloneDeep(__assign(__assign({}, displaySettings), { schemaOptions: __spreadArray(__spreadArray([], displaySettings.schemaOptions, true), [
            {
                hidden: false,
                schemaId: newSchemaId,
                width: 0,
            },
        ], false) }));
};
export var removeSchemaIdFromDisplaySettings = function (displaySettings, schemaId) {
    return cloneDeep(__assign(__assign({}, displaySettings), { filters: displaySettings.filters.filter(function (filter) { return filter.property !== schemaId; }), schemaOptions: displaySettings.schemaOptions.filter(function (schemaOption) { return schemaOption.schemaId !== schemaId; }), sortBy: displaySettings.sortBy.filter(function (sort) { return sort.property !== schemaId; }) }));
};
export var replaceSchemaIdFromDisplaySettings = function (displaySettings, oldSchemaId, newSchemaId) {
    return cloneDeep(__assign(__assign({}, displaySettings), { filters: displaySettings.filters.filter(function (filter) { return filter.property !== oldSchemaId; }), schemaOptions: displaySettings.schemaOptions.map(function (schemaOption) {
            return schemaOption.schemaId !== oldSchemaId
                ? schemaOption
                : { schemaId: newSchemaId, hidden: false, width: 0 };
        }), sortBy: displaySettings.sortBy.filter(function (sort) { return sort.property !== oldSchemaId; }) }));
};
export var removeValueIdFromDisplaySettings = function (displaySettings, valueId) {
    return cloneDeep(__assign(__assign({}, displaySettings), { filters: displaySettings.filters.filter(function (filter) {
            return filter.filterValue
                ? filter.filterValue !== valueId && !filter.filterValue.includes(valueId)
                : true;
        }) }));
};
export var displaySettingsForCollectionTable = function (initialDisplaySettings, displaySettings, isLoading) {
    return !isLoading &&
        (!isEqual(displaySettings.filters, initialDisplaySettings === null || initialDisplaySettings === void 0 ? void 0 : initialDisplaySettings.filters) ||
            !isEqual(displaySettings.sortBy, initialDisplaySettings === null || initialDisplaySettings === void 0 ? void 0 : initialDisplaySettings.sortBy) ||
            !isEqual(displaySettings.groupSetting, initialDisplaySettings === null || initialDisplaySettings === void 0 ? void 0 : initialDisplaySettings.groupSetting))
        ? displaySettings
        : initialDisplaySettings;
};
