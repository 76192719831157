import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import SettingsState from '../../../../hooks/state/Settings';
import AccessGroupsOverview from './AccessGroupsOverview/AccessGroupsOverview';
import AccessGroupDetail from './AccessGroupDetail/AccessGroupDetail';
var PermissionsAndAccess = function () {
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    return _jsx(_Fragment, { children: accessGroupDetail ? _jsx(AccessGroupDetail, {}) : _jsx(AccessGroupsOverview, {}) });
};
export default React.memo(PermissionsAndAccess);
