import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
var useDemandRatio = function () {
    var queryClient = useQueryClient();
    var refetchDemandRatioStats = useCallback(function () {
        queryClient.invalidateQueries(['schedulingDemandRatioStats']);
    }, [queryClient]);
    var refetchDemandRatioSettingData = useCallback(function () {
        queryClient.invalidateQueries(['demandRatios']);
    }, [queryClient]);
    return {
        refetchDemandRatioStats: refetchDemandRatioStats,
        refetchDemandRatioSettingData: refetchDemandRatioSettingData,
    };
};
export { useDemandRatio as useDemandRatioComponent };
