import { atom, atomFamily } from 'recoil';
var selectedShiftPayrollStatusState = atom({
    key: 'selectedShiftPayrollStatusState',
    default: undefined,
});
var isPayReportApprovedState = atomFamily({
    key: 'isPayReportApprovedState',
    default: false,
});
export { selectedShiftPayrollStatusState, isPayReportApprovedState };
