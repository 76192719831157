var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PulsingCircleSvg = function () { return (_jsxs("svg", { width: "25", height: "25", viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("circle", { cx: "20", cy: "20", fill: "none", r: "10", stroke: "#fff", strokeWidth: "2", children: [_jsx("animate", { attributeName: "r", from: "8", to: "20", dur: "1.5s", begin: "0s", repeatCount: "indefinite" }), _jsx("animate", { attributeName: "opacity", from: "1", to: "0", dur: "1.5s", begin: "0s", repeatCount: "indefinite" })] }), _jsx("circle", { cx: "20", cy: "20", fill: "#fff", r: "10" })] })); };
export var PulsingCircle = function (props) { return (_jsx(Icon, __assign({ component: PulsingCircleSvg }, props))); };
