var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ActivityFeedIconSvg = function () { return (_jsxs("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M1.8916 1.45015C1.8916 1.30058 2.01286 1.17932 2.16243 1.17932H8.66243C8.81201 1.17932 8.93327 1.30058 8.93327 1.45015V1.99182C8.93327 2.1414 8.81201 2.26265 8.66243 2.26265H2.16244C2.01286 2.26265 1.8916 2.1414 1.8916 1.99182V1.45015Z", fill: "black" }), _jsx("path", { d: "M1.8916 6.32564C1.8916 6.17607 2.01286 6.05481 2.16243 6.05481H12.4541C12.6037 6.05481 12.7249 6.17607 12.7249 6.32564V6.86731C12.7249 7.01689 12.6037 7.13814 12.4541 7.13814H2.16243C2.01286 7.13814 1.8916 7.01689 1.8916 6.86731V6.32564Z", fill: "black" }), _jsx("path", { d: "M1.8916 11.2002C1.8916 11.0506 2.01286 10.9293 2.16243 10.9293H4.87077C5.02035 10.9293 5.1416 11.0506 5.1416 11.2002V11.7418C5.1416 11.8914 5.02035 12.0127 4.87077 12.0127H2.16243C2.01286 12.0127 1.8916 11.8914 1.8916 11.7418V11.2002Z", fill: "black" })] })); };
export var ActivityFeedIcon = function (props) { return (_jsx(Icon, __assign({ component: ActivityFeedIconSvg }, props))); };
