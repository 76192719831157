import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { reportIsVariableDateRangeSelector } from 'src/state';
import ReportFixedDateRangeSelector from './ReportFixedDateRangeSelector';
import ReportVariableDateRangeSelector from './ReportVariableDateRangeSelector';
var ReportDateRangeSelector = function (_a) {
    var reportId = _a.reportId;
    var isVariableDateRange = useRecoilValue(reportIsVariableDateRangeSelector(reportId));
    return (_jsxs("div", { className: "ml-2", children: [isVariableDateRange && _jsx(ReportVariableDateRangeSelector, { reportId: reportId }), !isVariableDateRange && _jsx(ReportFixedDateRangeSelector, { reportId: reportId })] }));
};
export default React.memo(ReportDateRangeSelector);
