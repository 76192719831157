var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DefaultValue, selector } from 'recoil';
import { PageType } from 'src/components/CenterPanel/Interface';
import { pageItemSelector, pageItemsSelector } from '..';
import { currentPageState } from '../atoms';
var currentPageIdSelector = selector({
    key: 'currentPageIdSelector',
    get: function (_a) {
        var get = _a.get;
        var page = get(currentPageState);
        return page === null || page === void 0 ? void 0 : page.id;
    },
});
var currentPageImgSelector = selector({
    key: 'currentPageImgSelector',
    set: function (_a, newValue) {
        var set = _a.set;
        set(currentPageState, function (prev) {
            if (!prev)
                return;
            if (prev.image === newValue || newValue instanceof DefaultValue)
                return;
            return __assign(__assign({}, prev), { image: newValue });
        });
    },
    get: function (_a) {
        var get = _a.get;
        var pageData = get(currentPageState);
        return pageData === null || pageData === void 0 ? void 0 : pageData.image;
    },
});
var currentPageDescriptionsSelector = selector({
    key: 'currentPageDescriptionsSelector',
    set: function (_a, newValue) {
        var set = _a.set;
        set(currentPageState, function (prev) {
            if (!prev)
                return;
            if (prev.description === newValue || newValue instanceof DefaultValue)
                return;
            return __assign(__assign({}, prev), { description: newValue !== null && newValue !== void 0 ? newValue : '' });
        });
    },
    get: function (_a) {
        var get = _a.get;
        var pageData = get(currentPageState);
        return pageData === null || pageData === void 0 ? void 0 : pageData.description;
    },
});
var currentPageIconSelector = selector({
    key: 'currentPageIconSelector',
    get: function (_a) {
        var _b, _c;
        var get = _a.get;
        var pageId = get(currentPageIdSelector);
        if (!pageId)
            return;
        var pageItems = get(pageItemsSelector);
        var pageItem = (_b = pageItems.find(function (item) { return item.id === pageId; })) === null || _b === void 0 ? void 0 : _b.data;
        var icon = (_c = pageItem === null || pageItem === void 0 ? void 0 : pageItem.iconInfo) !== null && _c !== void 0 ? _c : pageItem === null || pageItem === void 0 ? void 0 : pageItem.emoji;
        return icon !== null && icon !== void 0 ? icon : undefined;
    },
});
var currentPageNameState = selector({
    key: 'currentPageName',
    get: function (_a) {
        var get = _a.get;
        var pageId = get(currentPageIdSelector);
        if (!pageId)
            return;
        var pageItem = get(pageItemSelector(pageId));
        var data = pageItem === null || pageItem === void 0 ? void 0 : pageItem.data;
        return data === null || data === void 0 ? void 0 : data.name;
    },
});
var isCurrentPageCanvasTypeSelector = selector({
    key: 'isCurrentPageCanvasTypeSelector',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var page = get(currentPageState);
        return (_b = (page === null || page === void 0 ? void 0 : page.type) === PageType.CANVAS) !== null && _b !== void 0 ? _b : false;
    },
});
var isCurrentPageScheduleTypeSelector = selector({
    key: 'isCurrentPageScheduleTypeSelector',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var page = get(currentPageState);
        return (_b = (page === null || page === void 0 ? void 0 : page.type) === PageType.SCHEDULE) !== null && _b !== void 0 ? _b : false;
    },
});
var isCurrentPageTableTypeSelector = selector({
    key: 'isCurrentPageTableTypeSelector',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var page = get(currentPageState);
        return (_b = (page === null || page === void 0 ? void 0 : page.type) === PageType.TABLE) !== null && _b !== void 0 ? _b : false;
    },
});
var isSelectingTableForTableTypePage = selector({
    key: 'isSelectingTableForTableTypePage',
    get: function (_a) {
        var get = _a.get;
        var page = get(currentPageState);
        return (page === null || page === void 0 ? void 0 : page.type) === PageType.TABLE && !page.blocks.length;
    },
});
export { currentPageIdSelector, currentPageImgSelector, currentPageDescriptionsSelector, currentPageIconSelector, currentPageNameState, isCurrentPageCanvasTypeSelector, isCurrentPageScheduleTypeSelector, isCurrentPageTableTypeSelector, isSelectingTableForTableTypePage, };
