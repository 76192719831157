import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select } from 'antd';
import { dropdownListFilterInputResult } from 'src/utils/Collection';
import { CellInputMode, SubMenuContentIcon } from 'src/shared';
import { LINK_TO_OBJECT_EMPTY_ID } from '../constant';
var Option = Select.Option;
var LinkToObjSchemaConfigurationSection = function (_a) {
    var linkedCollectionId = _a.linkedCollectionId, inputMode = _a.inputMode, optionsForDropdown = _a.optionsForDropdown, isCreatingNewColumn = _a.isCreatingNewColumn, onChangeOption = _a.onChangeOption;
    var onSelectChange = useCallback(function (value) {
        onChangeOption(value, inputMode);
    }, [inputMode, onChangeOption]);
    var onFilterOption = useCallback(function (input, option) {
        var optionItem = optionsForDropdown.find(function (item) { return item.key === option.value; });
        return optionItem ? dropdownListFilterInputResult(input, optionItem.title) : false;
    }, [optionsForDropdown]);
    return (_jsxs("div", { className: "flex flex-col", children: [_jsx(Select, { placeholder: "Please select an object to link...", showSearch: true, className: "w-full", onChange: onSelectChange, filterOption: onFilterOption, popupClassName: "LinkToObjSelectDropdown", value: linkedCollectionId === LINK_TO_OBJECT_EMPTY_ID ? undefined : linkedCollectionId, children: optionsForDropdown.map(function (item) { return (_jsx(Option, { value: item.key, children: _jsxs("div", { className: "flex justify-between", children: [_jsx("span", { className: "mr-[6px] font-medium", children: item.title }), _jsx("span", { title: item.description, className: "text-gray-300 overflow-hidden text-ellipsis", children: item.description })] }) }, item.key)); }) }), _jsxs("div", { className: "mt-3", children: [_jsx("span", { className: "opacity-45 font-medium text-xs", children: "Select Type" }), _jsxs(Select, { className: "w-full", popupClassName: "LinkToObjSelectDropdown SelectDropdownWithIcon", value: inputMode, onChange: function (newInputMode) { return onChangeOption(linkedCollectionId, newInputMode); }, disabled: !isCreatingNewColumn, children: [_jsx(Option, { value: CellInputMode.MULTI, children: _jsxs("div", { className: "flex items-center font-medium", children: [_jsx("span", { className: "mr-2", children: SubMenuContentIcon['multi'] }), _jsx("span", { children: "Multi Select" })] }) }), _jsx(Option, { value: CellInputMode.SINGLE, children: _jsxs("div", { className: "flex items-center font-medium", children: [_jsx("span", { className: "mr-2", children: SubMenuContentIcon['single'] }), _jsx("span", { children: "Single Select" })] }) })] })] }), _jsx("div", { className: "bg-gray-50 text-gray-400 text-xs font-medium rounded p-2 mt-3", children: "Link this column to an existing object/collection." })] }));
};
export default React.memo(LinkToObjSchemaConfigurationSection);
