import { atom, atomFamily } from 'recoil';
var scheduleTemplateState = atomFamily({
    key: 'scheduleTemplateState',
    default: undefined,
});
var templateIsDraggingState = atom({
    key: 'templateIsDraggingState',
    default: false,
});
var draggingTemplateState = atom({
    key: 'draggingTemplateState',
    default: undefined,
});
var shiftTemplateDetailModalVisibleState = atom({
    key: 'shiftTemplateDetailModalVisibleState',
    default: false,
});
var selectedShiftTemplateIdState = atom({
    key: 'selectedShiftTemplateIdState',
    default: undefined,
});
export { scheduleTemplateState, templateIsDraggingState, draggingTemplateState, shiftTemplateDetailModalVisibleState, selectedShiftTemplateIdState, };
