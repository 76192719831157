import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import './DummyTextCell.scss';
var DummyTextCell = function (props) {
    var text = props.text, setIsClicked = props.setIsClicked, canEdit = props.canEdit;
    var showLogicCell = useCallback(function () {
        if (canEdit) {
            setIsClicked === null || setIsClicked === void 0 ? void 0 : setIsClicked(true);
        }
    }, [canEdit, setIsClicked]);
    return (_jsx("div", { className: "DummyTextCell select-text", onClick: showLogicCell, children: text }));
};
export default React.memo(DummyTextCell);
