export var ThreadType;
(function (ThreadType) {
    ThreadType["DM"] = "DM";
    ThreadType["BROADCAST"] = "BROADCAST";
})(ThreadType || (ThreadType = {}));
export var MessageSenderType;
(function (MessageSenderType) {
    MessageSenderType["USER"] = "USER";
    MessageSenderType["DEPARTMENT"] = "DEPARTMENT";
})(MessageSenderType || (MessageSenderType = {}));
export var ThreadReceiverParticipantType;
(function (ThreadReceiverParticipantType) {
    ThreadReceiverParticipantType["USER"] = "USER";
    ThreadReceiverParticipantType["BROADCAST_ROLE"] = "BROADCAST_ROLE";
    ThreadReceiverParticipantType["BROADCAST_LOCATION"] = "BROADCAST_LOCATION";
    ThreadReceiverParticipantType["BROADCAST_ACCOUNT"] = "BROADCAST_ACCOUNT";
})(ThreadReceiverParticipantType || (ThreadReceiverParticipantType = {}));
