import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import './CommentFeed.scss';
import { useCommentApi } from 'src/hooks/api/useCommentApi';
import Comment from './Comment';
import CommentFeedEmptyState from './CommentFeedEmptyState';
var AccountCommentFeed = function () {
    var useGetAccountComments = useCommentApi().useGetAccountComments;
    var _a = useState(), accountComments = _a[0], setAccountComments = _a[1];
    var data = useGetAccountComments().data;
    useEffect(function () {
        if (data)
            setAccountComments(data.commentFullDetailsView);
    }, [data]);
    if ((accountComments === null || accountComments === void 0 ? void 0 : accountComments.length) === 0) {
        return (_jsx("div", { className: "commentsContainer h-full", children: _jsx(CommentFeedEmptyState, {}) }));
    }
    return (_jsx("div", { className: "commentsContainer", children: _jsx("div", { className: "accountFeedScrollable", children: accountComments === null || accountComments === void 0 ? void 0 : accountComments.map(function (comment, index) {
                return (_jsx(Comment, { comment: comment, isLastComment: index === accountComments.length - 1 }, index));
            }) }) }));
};
export default React.memo(AccountCommentFeed);
