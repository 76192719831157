var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTasksApi } from 'src/hooks/api';
import { selectedTaskRuleIdState, taskRuleListState } from '../../atom';
import { DEFAULT_TASK_RULE_INFO, NEW_TASK_RULE_ID } from '../../constant';
import TaskSearchBar from '../../shared/TaskSearchBar';
import TaskRuleListItem from './TaskRuleListItem';
var TaskRuleList = function () {
    var _a = useRecoilState(taskRuleListState), taskRuleList = _a[0], setTaskRuleList = _a[1];
    var _b = useRecoilState(selectedTaskRuleIdState), selectedTaskRuleId = _b[0], setSelectedTaskRuleId = _b[1];
    var useGetTaskRules = useTasksApi().useGetTaskRules;
    // Fetch task rules
    useGetTaskRules({
        onSuccess: function (data) {
            if (data === null || data === void 0 ? void 0 : data.taskRules) {
                setTaskRuleList(data.taskRules);
                if ((!selectedTaskRuleId || selectedTaskRuleId === NEW_TASK_RULE_ID) &&
                    data.taskRules.length) {
                    setSelectedTaskRuleId(data.taskRules[0].id);
                }
            }
        },
    });
    var _c = useState(''), searchText = _c[0], setSearchText = _c[1];
    var actualSearchValue = useMemo(function () { return searchText.trim().toLowerCase(); }, [searchText]);
    var filteredTaskRules = useMemo(function () {
        var filteredList = actualSearchValue
            ? taskRuleList.filter(function (taskRule) {
                return taskRule.name.toLowerCase().includes(actualSearchValue) ||
                    taskRule.displayId.toLowerCase().includes(actualSearchValue);
            })
            : taskRuleList;
        var creatingNewTaskGroup = selectedTaskRuleId === NEW_TASK_RULE_ID;
        return creatingNewTaskGroup ? __spreadArray([DEFAULT_TASK_RULE_INFO], filteredList, true) : filteredList;
    }, [actualSearchValue, selectedTaskRuleId, taskRuleList]);
    useEffect(function () {
        if (!selectedTaskRuleId && filteredTaskRules.length) {
            setSelectedTaskRuleId(filteredTaskRules[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTaskRules]);
    return (_jsxs("div", { className: "flex flex-col items-center w-full", children: [_jsx(TaskSearchBar, { placeholder: "Search", value: searchText, onChange: function (e) { return setSearchText(e.target.value); } }), _jsx("div", { className: "flex flex-col w-full items-center overflow-hidden border-t-2 border-gray-50", children: filteredTaskRules.map(function (rule) { return (_jsx(TaskRuleListItem, { rule: rule }, rule.id)); }) }), !filteredTaskRules.length && (_jsx("div", { className: "text-lg font-medium text-gray-300 mt-5", children: "No task rules found" }))] }));
};
export default React.memo(TaskRuleList);
