import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select, TimePicker } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { dayOfWeekOptionsMap } from 'src/shared';
import { AutomationTriggerRecurringDayOfMonth, AutomationTriggerRecurringType, } from '../Interface';
var Option = Select.Option;
var DISPLAY_TIME_FORMAT = 'hh:mm A';
var RecurringTriggerSetting = function (props) {
    var recurringSetting = props.recurringSetting, setRecurringSetting = props.setRecurringSetting;
    var onTypeChange = useCallback(function (type) {
        var newRecurringSetting = cloneDeep(recurringSetting);
        newRecurringSetting.type = type;
        if (type === AutomationTriggerRecurringType.DAILY) {
            newRecurringSetting.dayOfWeek = undefined;
            newRecurringSetting.dayOfMonth = undefined;
        }
        else if (type === AutomationTriggerRecurringType.WEEKLY) {
            newRecurringSetting.dayOfMonth = undefined;
        }
        else if (type === AutomationTriggerRecurringType.BIWEEKLY) {
            newRecurringSetting.dayOfMonth = undefined;
        }
        else {
            newRecurringSetting.dayOfWeek = undefined;
        }
        setRecurringSetting(newRecurringSetting);
    }, [recurringSetting, setRecurringSetting]);
    var onTimeOfDayChange = useCallback(function (value) {
        var newTime = moment(value, DISPLAY_TIME_FORMAT).format('HH:mm');
        var newRecurringSetting = cloneDeep(recurringSetting);
        newRecurringSetting.timeOfDay = newTime;
        setRecurringSetting(newRecurringSetting);
    }, [recurringSetting, setRecurringSetting]);
    var onDayOfWeekChange = useCallback(function (dayOfWeek) {
        var newRecurringSetting = cloneDeep(recurringSetting);
        newRecurringSetting.dayOfWeek = dayOfWeek;
        setRecurringSetting(newRecurringSetting);
    }, [recurringSetting, setRecurringSetting]);
    var shouldShowDayOfWeek = recurringSetting.type === AutomationTriggerRecurringType.WEEKLY ||
        recurringSetting.type === AutomationTriggerRecurringType.BIWEEKLY;
    return (_jsxs("div", { children: [_jsx("div", { children: "Frequency" }), _jsxs(Select, { className: "w-full", value: recurringSetting.type, onChange: function (type) { return onTypeChange(type); }, children: [_jsx(Option, { value: AutomationTriggerRecurringType.DAILY, children: "Daily" }), _jsx(Option, { value: AutomationTriggerRecurringType.WEEKLY, children: "Weekly" }), _jsx(Option, { value: AutomationTriggerRecurringType.BIWEEKLY, children: "Biweekly" }), _jsx(Option, { value: AutomationTriggerRecurringType.MONTHLY, children: "Monthly" })] }), _jsxs("div", { className: "mt-3 flex", children: [_jsxs("div", { children: [_jsx("div", { children: "Time of Day" }), _jsx(TimePicker, { className: "RecurringTriggerTimePicker", popupClassName: "RecurringTriggerTimePickerDropdown", value: moment(recurringSetting.timeOfDay, DISPLAY_TIME_FORMAT), format: DISPLAY_TIME_FORMAT, use12Hours: true, placeholder: "Select Time", onSelect: onTimeOfDayChange })] }), shouldShowDayOfWeek && (_jsxs("div", { className: "w-2/5 ml-5", children: [_jsx("div", { children: "Day Of Week" }), _jsx(Select, { className: "w-full", value: recurringSetting.dayOfWeek, onChange: onDayOfWeekChange, children: Object.values(dayOfWeekOptionsMap).map(function (item) { return (_jsx(Option, { value: item.key, children: item.displayName }, item.key)); }) })] })), recurringSetting.type === AutomationTriggerRecurringType.MONTHLY && (_jsxs("div", { className: "w-3/5 ml-5", children: [_jsx("div", { children: "Day Of Month" }), _jsxs(Select, { className: "w-full", children: [_jsx(Option, { value: AutomationTriggerRecurringDayOfMonth.START_OF_MONTH, children: "Start of Month" }), _jsx(Option, { value: AutomationTriggerRecurringDayOfMonth.MIDDLE_OF_MONTH, children: "Middle of Month" }), _jsx(Option, { value: AutomationTriggerRecurringDayOfMonth.END_OF_MONTH, children: "End of Month" })] })] }))] })] }));
};
export default React.memo(RecurringTriggerSetting);
