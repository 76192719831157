var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var StarIconSvg = function (props) { return (_jsx("svg", { width: "20", height: "20", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M10.9997 1.33301L13.9863 7.38501L20.6663 8.35567L15.833 13.0677L16.9743 19.7197L10.9997 16.5797L5.02501 19.7197L6.16634 13.0677L1.33301 8.35567L8.01301 7.38501L10.9997 1.33301Z", fill: props.fill || '#A7A8AC', stroke: props.fill || '#A7A8AC' }) })); };
export var StarIcon = function (props) { return (_jsx(Icon, __assign({ component: function () { return _jsx(StarIconSvg, __assign({}, props)); } }, props))); };
