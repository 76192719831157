var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var CheckMarkSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M5.10902 12.1371C4.97885 12.007 4.97885 11.7959 5.10902 11.6657L13.3832 3.39158C13.5134 3.2614 13.7244 3.2614 13.8546 3.39158L14.326 3.86298C14.4562 3.99316 14.4562 4.20421 14.326 4.33439L6.05183 12.6086C5.92166 12.7387 5.7106 12.7387 5.58043 12.6086L5.10902 12.1371Z", fill: "#BDBDC2" }), _jsx("path", { d: "M6.47022 12.1372C6.6004 12.007 6.6004 11.7959 6.47022 11.6658L2.61661 7.81215C2.48644 7.68197 2.27538 7.68197 2.14521 7.81215L1.6738 8.28355C1.54363 8.41373 1.54363 8.62478 1.6738 8.75496L5.52741 12.6086C5.65759 12.7387 5.86864 12.7387 5.99882 12.6086L6.47022 12.1372Z", fill: "#BDBDC2" })] })); };
export var CheckMarkIcon = function (props) { return (_jsx(Icon, __assign({ component: CheckMarkSvg }, props))); };
