import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSetRecoilState } from 'recoil';
import { prevIpSettingUrlState } from 'src/state/atoms/integrationSettingState';
import { getIpDisplayText } from '../../../../utils/Account';
import './IPDisplayCard.scss';
import SignNowInputModal from './SignNowInputModal';
var IPDisplayCard = function (_a) {
    var title = _a.title, category = _a.category, icon = _a.icon;
    var navigate = useNavigate();
    var setPrevUrl = useSetRecoilState(prevIpSettingUrlState);
    var _b = useState(false), isSignNowInputModalVisible = _b[0], setIsSignNowInputModalVisible = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ServiceCard", onClick: function () {
                    if (title !== 'signnow') {
                        navigate("/settings/integrations/".concat(title));
                        setPrevUrl("/settings/integrations/".concat(title));
                    }
                    else {
                        setIsSignNowInputModalVisible(true);
                    }
                }, children: [icon, _jsx("div", { className: "ServiceCardTitle", children: getIpDisplayText(title) }), _jsxs("div", { className: "ServiceCardDescription", children: ["Set up integrations to streamline ", category, " with third-party services like", ' ', getIpDisplayText(title)] }), _jsx(Button, { className: "ServiceCardGoToSetUpButton", size: "small", icon: _jsx(ArrowRightOutlined, { className: "SetUpBtnIcon" }), disabled: false })] }), title === 'signnow' && isSignNowInputModalVisible && (_jsx(SignNowInputModal, { onCloseModal: function () { return setIsSignNowInputModalVisible(false); }, onRedirectToSetupPage: function () {
                    navigate("/settings/integrations/".concat(title));
                    setPrevUrl("/settings/integrations/".concat(title));
                } }))] }));
};
export default React.memo(IPDisplayCard);
