import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ZiraVisibilityDropdown, ZiraVisibilityDropdownToggleItem, ZiraVisibilityDropdownVisibleItem, } from 'src/ui/ZiraVisibilityDropdown';
import { useScheduleApi } from 'src/hooks/api';
import { useScheduleComponent } from 'src/hooks/component';
import { scheduleVisibleStatsOptionsSelector } from 'src/state';
import { SettingsIcon } from 'src/utils/icons/SettingsIcon';
import { generateScheduleStatOptionName } from '../Utils';
var ScheduleStatsVisibilityDropdown = function (_a) {
    var blockId = _a.blockId;
    var scheduleStatVisibleOnChange = useScheduleComponent().scheduleStatVisibleOnChange;
    var scheduleStats = useRecoilValue(scheduleVisibleStatsOptionsSelector(blockId));
    var useFetchAvailableSchedulingStatOptions = useScheduleApi().useFetchAvailableSchedulingStatOptions;
    var availableStatOptions = useFetchAvailableSchedulingStatOptions().data;
    var allSortedStatOptions = useMemo(function () {
        return sortBy(availableStatOptions, function (option) {
            var targetStat = scheduleStats.find(function (stat) { return stat.first === option.first && stat.second === option.second; });
            var isVisible = !!targetStat;
            return isVisible ? 0 : 1;
        });
    }, [availableStatOptions, scheduleStats]);
    var allVisibleStatOptions = useMemo(function () {
        var _a;
        return (_a = availableStatOptions === null || availableStatOptions === void 0 ? void 0 : availableStatOptions.filter(function (option) {
            var targetStat = scheduleStats.find(function (stat) { return stat.first === option.first && stat.second === option.second; });
            var isVisible = !!targetStat;
            if (isVisible)
                return option;
        })) !== null && _a !== void 0 ? _a : [];
    }, [availableStatOptions, scheduleStats]);
    var allVisibleStatOptionsContent = useMemo(function () {
        if (allVisibleStatOptions.length === 0)
            return (_jsx("div", { className: "flex items-center justify-center text-base font-medium text-gray-300", children: "No visible stats" }));
        return (_jsx("div", { className: "flex w-full flex-col space-y-3 p-4", children: allVisibleStatOptions.map(function (option) {
                var title = generateScheduleStatOptionName(option);
                return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: title, onToggleOffItem: function () { return scheduleStatVisibleOnChange(option, blockId); } }, option.first + option.second));
            }) }));
    }, [allVisibleStatOptions, blockId, scheduleStatVisibleOnChange]);
    var allStatOptionsContent = useMemo(function () {
        return (_jsx("div", { className: "flex w-full flex-col", children: allSortedStatOptions.map(function (option, index) {
                var title = generateScheduleStatOptionName(option);
                var targetStat = scheduleStats.find(function (stat) { return stat.first === option.first && stat.second === option.second; });
                var isVisible = !!targetStat;
                var isLastVisible = isVisible && index === allVisibleStatOptions.length - 1;
                return (_jsx(ZiraVisibilityDropdownToggleItem, { title: title, isVisible: isVisible, onVisibilityToggled: function () { return scheduleStatVisibleOnChange(option, blockId); }, showDivider: isLastVisible }, option.first + option.second));
            }) }));
    }, [
        allSortedStatOptions,
        scheduleStats,
        allVisibleStatOptions,
        scheduleStatVisibleOnChange,
        blockId,
    ]);
    return (_jsx(ZiraVisibilityDropdown, { tabTitles: ['Visible Stats', 'All Stats'], tabContents: [allVisibleStatOptionsContent, allStatOptionsContent], placement: "bottomRight", destroyPopupOnHide: true, children: _jsxs("div", { className: "mr-2 flex cursor-pointer items-center rounded-lg bg-gray-40 px-2 py-1", children: [_jsx(SettingsIcon, { className: "w-[16px]" }), _jsx("div", { className: "ml-1 text-sm font-medium", children: "Configure Stats" })] }) }));
};
export default React.memo(ScheduleStatsVisibilityDropdown);
