var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Col, Divider, Form, Input, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import FillFormContent from '../../../../FormView/FillFormContent';
import FormTemplateState from '../../FormTemplate';
import AddQuestionOrBlockToForm from './AddQuestionOrBlockToForm/AddQuestionOrBlockToForm';
import FormTemplateLayoutItemCollapse from './FormTemplateLayoutItem/FormTemplateLayoutItemCollapse';
var ConfigureLayoutStep = function () {
    var _a = FormTemplateState.useContainer(), availableSchemasOfSourceCollection = _a.availableSchemasOfSourceCollection, modalTemplateName = _a.modalTemplateName, formLayout = _a.formLayout, setModalTemplateName = _a.setModalTemplateName;
    var fakeForm = Form.useForm()[0];
    var _b = useState(false), fakeLoading = _b[0], setFakeLoading = _b[1];
    var onFakeFinish = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setFakeLoading(true);
            setTimeout(function () { return setFakeLoading(false); }, 3000);
            return [2 /*return*/];
        });
    }); }, []);
    return (_jsx(_Fragment, { children: _jsxs(Row, { className: "ConfigureLayoutWrapper", children: [_jsxs(Col, { span: 16, className: "FormLayoutColumn", children: [_jsx(Input, { bordered: false, className: "FormTemplateLayoutNameInput", placeholder: "Enter a name", value: modalTemplateName, onChange: function (e) { return setModalTemplateName(e.target.value); } }), _jsx(Divider, { className: "FormLayoutDivider" }), _jsx(FormTemplateLayoutItemCollapse, {}), _jsx(AddQuestionOrBlockToForm, {})] }), _jsxs(Col, { span: 8, className: "FormPreviewColumn", children: [_jsx(Select, { defaultValue: "MOBILE_LOGGED_IN", className: "FormPreviewSelect", children: _jsx(Select.Option, { value: "MOBILE_LOGGED_IN", children: "Web (Mobile - Logged In)" }) }), _jsx("div", { className: "FormPreviewContainer", children: _jsx(FillFormContent, { schemas: _.keyBy(availableSchemasOfSourceCollection, 'id'), form: fakeForm, layout: formLayout, loading: fakeLoading, onFinish: onFakeFinish }) })] })] }) }));
};
export default React.memo(ConfigureLayoutStep);
