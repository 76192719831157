var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback } from 'react';
import { FormulaOperators } from 'src/shared';
var formulasTooltip = (_jsxs("div", { className: "flex-col", children: [_jsx("div", { children: "Each variable represents the value of a metric/formula." }), _jsx("div", { children: "Here are some example formulas:" }), _jsxs("div", { className: "flex flex-col font-semibold", children: [_jsx("span", { children: "m1 - m2" }), _jsx("span", { children: "m1 + m2" }), _jsx("span", { children: "m1 / m2" }), _jsx("span", { children: "m1 * m2" }), _jsx("span", { children: "(m1 / m2) * 100" }), _jsx("span", { children: "m1 / (m1 + m2)" })] })] }));
var ReportFormulas = function (_a) {
    var reportFormulas = _a.reportFormulas, setReportFormulas = _a.setReportFormulas, reportMetricNames = _a.reportMetricNames, reportSchemaIds = _a.reportSchemaIds, enabled = _a.enabled;
    var addNewReportFormula = useCallback(function () {
        setReportFormulas(__spreadArray(__spreadArray([], reportFormulas, true), [
            { label: "Formula ".concat(reportFormulas.length + 1), formula: '', isPlotted: false },
        ], false));
    }, [reportFormulas, setReportFormulas]);
    var editReportFormula = useCallback(function (newFormula, index) {
        var tempReportFormula = cloneDeep(reportFormulas);
        tempReportFormula[index].formula = newFormula;
        setReportFormulas(tempReportFormula);
    }, [reportFormulas, setReportFormulas]);
    var editReportFormulaName = useCallback(function (newFormulaName, index) {
        var tempReportFormula = cloneDeep(reportFormulas);
        tempReportFormula[index].label = newFormulaName;
        setReportFormulas(tempReportFormula);
    }, [reportFormulas, setReportFormulas]);
    var removeReportFormula = useCallback(function (index) {
        var tempReportFormula = cloneDeep(reportFormulas);
        tempReportFormula.splice(index, 1);
        setReportFormulas(tempReportFormula);
    }, [reportFormulas, setReportFormulas]);
    var onAddToFormula = useCallback(function (variable, index) {
        var tempReportFormula = cloneDeep(reportFormulas);
        tempReportFormula[index].formula = "".concat(tempReportFormula[index].formula).concat(variable);
        setReportFormulas(tempReportFormula);
    }, [reportFormulas, setReportFormulas]);
    return (_jsxs(_Fragment, { children: [reportFormulas.map(function (_a, idx) {
                var label = _a.label, formula = _a.formula;
                return (_jsxs("div", { className: "relative mb-2 flex items-center", children: [idx !== reportFormulas.length - 1 && (_jsxs("span", { className: "absolute -left-6 font-semibold", children: ["f", idx + 1] })), _jsxs("div", { className: "SelectWrapper flex-col", children: [_jsxs("div", { className: "mb-2 ml-2 flex w-full items-center", children: [_jsx("span", { className: " mr-11 font-semibold", children: "Name:" }), _jsx(Input, { placeholder: "Enter a Formula Name", defaultValue: label, onBlur: function (e) { return editReportFormulaName(e.target.value, idx); } })] }), _jsxs("div", { className: "ml-2 flex w-full items-center", children: [_jsxs("div", { className: "mr-2 flex font-semibold", children: ["Formula", _jsx(Tooltip, { title: formulasTooltip, children: _jsx(InfoCircleOutlined, { className: "px-1 pt-1" }) }), ":"] }), _jsx(Input, { className: "rounded-md", placeholder: "Enter a Formula (e.g., m1 + m2)", defaultValue: formula, onBlur: function (e) { return editReportFormula(e.target.value, idx); }, allowClear: true })] }), _jsxs("div", { className: "FormulaOperatorsWrapper", children: [_jsxs("div", { className: "mr-5 flex flex-col pr-0.5", children: [_jsx("span", { className: "font-semibold", children: "Variables" }), _jsx("span", { className: "text-xs text-gray-300", children: "Click to add" })] }), reportMetricNames.map(function (metricName, metricIdx) {
                                            return (_jsx("div", { className: "FormulaVariable", onClick: function () {
                                                    onAddToFormula("m".concat(metricIdx + 1, " "), idx);
                                                }, title: "m".concat(metricIdx + 1, " (").concat(metricName, ")"), children: _jsxs("span", { className: "font-semibold", children: ["m", metricIdx + 1] }) }, "".concat(metricName, "-").concat(metricIdx)));
                                        }), reportSchemaIds.map(function (schemaid, schemaIdx) {
                                            return (_jsx("div", { className: "FormulaVariable", onClick: function () {
                                                    onAddToFormula("c".concat(schemaIdx + 1, " "), idx);
                                                }, title: "c".concat(schemaIdx + 1), children: _jsxs("span", { className: "font-semibold", children: ["c", schemaIdx + 1] }) }, "".concat(schemaid, "-").concat(schemaIdx)));
                                        }), reportFormulas
                                            .filter(function (_, formulaIdx) { return formulaIdx < idx; })
                                            .map(function (_a, formulaIdx) {
                                            var label = _a.label, formula = _a.formula;
                                            return (_jsx("div", { className: "FormulaVariable", onClick: function () {
                                                    onAddToFormula("f".concat(formulaIdx + 1, " "), idx);
                                                }, title: "f".concat(formulaIdx + 1, " (").concat(label, ")"), children: _jsxs("span", { className: "font-semibold", children: ["f", formulaIdx + 1] }) }, "".concat(formula, "-").concat(formulaIdx)));
                                        })] }), _jsxs("div", { className: "FormulaOperatorsWrapper", children: [_jsxs("div", { className: "mr-5 flex flex-col pr-0.5", children: [_jsx("span", { className: "font-semibold", children: "Operators" }), _jsx("span", { className: "text-xs text-gray-300", children: "Click to add" })] }), FormulaOperators.map(function (_a) {
                                            var symbol = _a.symbol, value = _a.value, color = _a.color, backgroundColor = _a.backgroundColor;
                                            return (_jsx("div", { className: "FormulaOperator", onClick: function () {
                                                    onAddToFormula("".concat(value, " "), idx);
                                                }, style: { backgroundColor: backgroundColor, color: color }, children: symbol }, symbol));
                                        })] })] }), _jsx(Button, { icon: _jsx(CloseOutlined, {}), onClick: function () { return removeReportFormula(idx); }, title: "Remove formula" })] }, "".concat(formula, "-").concat(idx)));
            }), _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "AddButton", type: "primary", onClick: function () { return addNewReportFormula(); }, disabled: !enabled, children: "Add Formula" })] }));
};
export default React.memo(ReportFormulas);
