var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'antd';
import React, { useCallback, useContext } from 'react';
import { useRecoilState } from 'recoil';
import PaymentRulesConfigurationContext from '../PaymentRulesTab/component/PaymentRulesModal/PaymentRulesConfigurationContext';
import { currentDefaultRateState } from '../atoms';
import { PayItemType } from '../interface';
var PayItemInfoInput = function (_a) {
    var _b, _c;
    var payItemType = _a.payItemType;
    var _d = useContext(PaymentRulesConfigurationContext), paymentRule = _d.paymentRule, setPayRuleInfo = _d.setPayRuleInfo;
    var _e = useRecoilState(currentDefaultRateState), currentDefaultRate = _e[0], setCurrentDefaultRate = _e[1];
    var inputValue = payItemType === PayItemType.PAY_RULE
        ? (_b = paymentRule === null || paymentRule === void 0 ? void 0 : paymentRule.info) === null || _b === void 0 ? void 0 : _b.name
        : (_c = currentDefaultRate === null || currentDefaultRate === void 0 ? void 0 : currentDefaultRate.info) === null || _c === void 0 ? void 0 : _c.name;
    var inputOnChange = useCallback(function (e) {
        if (payItemType === PayItemType.PAY_RULE) {
            setPayRuleInfo(__assign(__assign({}, paymentRule === null || paymentRule === void 0 ? void 0 : paymentRule.info), { name: e.currentTarget.value }));
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { info: __assign(__assign({}, currentDefaultRate === null || currentDefaultRate === void 0 ? void 0 : currentDefaultRate.info), { name: e.currentTarget.value }) }));
        }
    }, [currentDefaultRate, payItemType, paymentRule === null || paymentRule === void 0 ? void 0 : paymentRule.info, setCurrentDefaultRate, setPayRuleInfo]);
    return (_jsxs("div", { className: "w-[200px]", children: [_jsx(Input, { className: "text-lg font-semibold ", value: inputValue, onChange: inputOnChange, placeholder: payItemType === PayItemType.PAY_RULE ? 'Untitled Rule' : 'Untitled Rate', bordered: false }), _jsx("div", { className: "border-b-2 border-dashed" })] }));
};
export default React.memo(PayItemInfoInput);
