var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var StartOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "path-2-inside-1", fill: "white", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.7151 10.6885L11.0001 7.4727L9.28504 10.6885L6.37995 11.1285L8.75521 13.1992L8.09778 16.7298L11.0001 15.0813L13.9024 16.7298L13.2449 13.1992L15.6202 11.1285L12.7151 10.6885ZM8.42355 9.47041L4.75683 10.0259C4.19529 10.1109 3.99048 10.8143 4.41859 11.1875L7.30499 13.7038L6.52439 17.8958C6.42058 18.4533 7.01596 18.8776 7.50905 18.5976L11.0001 16.6147L14.4911 18.5976C14.9842 18.8776 15.5796 18.4533 15.4757 17.8958L14.6951 13.7038L17.5815 11.1875C18.0097 10.8143 17.8048 10.1109 17.2433 10.0259L13.5766 9.47041L11.5883 5.74229C11.3373 5.27169 10.6628 5.27169 10.4118 5.74229L8.42355 9.47041Z" }) }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.7151 10.6885L11.0001 7.4727L9.28504 10.6885L6.37995 11.1285L8.75521 13.1992L8.09778 16.7298L11.0001 15.0813L13.9024 16.7298L13.2449 13.1992L15.6202 11.1285L12.7151 10.6885ZM8.42355 9.47041L4.75683 10.0259C4.19529 10.1109 3.99048 10.8143 4.41859 11.1875L7.30499 13.7038L6.52439 17.8958C6.42058 18.4533 7.01596 18.8776 7.50905 18.5976L11.0001 16.6147L14.4911 18.5976C14.9842 18.8776 15.5796 18.4533 15.4757 17.8958L14.6951 13.7038L17.5815 11.1875C18.0097 10.8143 17.8048 10.1109 17.2433 10.0259L13.5766 9.47041L11.5883 5.74229C11.3373 5.27169 10.6628 5.27169 10.4118 5.74229L8.42355 9.47041Z", fill: "#A7A8AC" })] })); };
export var StartOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: StartOutlinedSvg }, props, { style: { marginTop: '-2px' } }))); };
