import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import '../../../App.less';
import '../../Workspace.scss';
import './TableOptionHeader.scss';
import { Space, Button } from 'antd';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { collectionTableDisplayNameSelector, collectionTableTypeSelector, collectionTableSchemaMapSelector, } from 'src/state';
import { CollectionTableType, CollectionType } from 'src/shared';
import { PlusOutlined } from '@ant-design/icons';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import TableTitleHOC from './Title/TableTitleHOC';
import TableSearch from './Search/TableSearch';
import TableSort from './Sort/TableSort';
import './TableOptionHeader.scss';
import ColumnVisibilityDropdown from './ColumnOption/ColumnVisibilityDropdown';
import ViewTypeOptionsDropdown from './ViewTypeOptions/ViewTypeOptionsDropdown';
import { isBoardViewSettingModalVisibleState } from './ViewTypeOptions/atom';
import BoardViewGroupBySettingModal from './ViewTypeOptions/BoardViewGroupBySettingModal';
import GroupBySchemaTag from './ViewTypeOptions/GroupBySchemaTag';
var TableOptionHeader = function (props) {
    var _a;
    var viewId = props.viewId, collectionId = props.collectionId, displaySettings = props.displaySettings, title = props.title, isScheduleTypePage = props.isScheduleTypePage, updateTitle = props.updateTitle, searchRecord = props.searchRecord, addNewRecord = props.addNewRecord, applySchemaOptions = props.applySchemaOptions, applySort = props.applySort, applyGroupBySetting = props.applyGroupBySetting, canSwitchViewType = props.canSwitchViewType, moreOptionsDropdown = props.moreOptionsDropdown, hoverOptionButton = props.hoverOptionButton, collectionViews = props.collectionViews, tableFilters = props.tableFilters, scheduleColorCoding = props.scheduleColorCoding, schedulePublishShift = props.schedulePublishShift, extraOptions = props.extraOptions, bulkEditOptions = props.bulkEditOptions;
    var dataSource = useMemo(function () {
        return { type: CollectionTableType.TABLE, collectionId: collectionId };
    }, [collectionId]);
    var collectionName = useRecoilValue(collectionTableDisplayNameSelector(dataSource));
    var columns = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var hideBoardView = useRecoilValue(collectionTableTypeSelector(dataSource)) === CollectionType.SHIFT_GROUP;
    var hasCollectionViews = !!collectionViews;
    var getCollectionHelpArticleId = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var collectionType = snapshot
                .getLoadable(collectionTableTypeSelector(dataSource))
                .valueMaybe();
            if (!collectionType)
                return '';
            switch (collectionType) {
                case CollectionType.TIMEOFF:
                    return HelpDrawerLinkedArticleMap[HelpArticleType.TIME_OFF_TABLE];
            }
            return '';
        };
    }, [dataSource]);
    var tableSearchInput = useCallback(function () {
        return (_jsx(_Fragment, { children: searchRecord && !displaySettings.groupSetting && (_jsx(TableSearch, { searchInTable: searchRecord })) }));
    }, [displaySettings.groupSetting, searchRecord]);
    var addNewButton = useMemo(function () {
        return (_jsx(Button, { className: "AddNewButton", onClick: addNewRecord, children: _jsxs("div", { className: "icon-text-btn", children: [_jsx(PlusOutlined, {}), _jsx("span", { className: "overflow-hidden text-ellipsis", children: "New ".concat(collectionName) })] }) }));
    }, [addNewRecord, collectionName]);
    var isBoardViewSettingModalVisible = useRecoilValue(isBoardViewSettingModalVisibleState(viewId));
    var titleAndBulkEditOptions = useMemo(function () {
        return (_jsxs(_Fragment, { children: [!hasCollectionViews && _jsx(_Fragment, { children: bulkEditOptions }), title && _jsx(TableTitleHOC, { title: title, updateTitle: updateTitle })] }));
    }, [bulkEditOptions, hasCollectionViews, title, updateTitle]);
    var collectionViewsAndBulkEditOptions = useMemo(function () {
        return (_jsxs(_Fragment, { children: [bulkEditOptions, collectionViews] }));
    }, [collectionViews, bulkEditOptions]);
    return (_jsxs("div", { className: "TableOptionHeaderWrapper", children: [hoverOptionButton, collectionViews && (_jsxs("div", { className: "TopTableHeaderRow", children: [collectionViewsAndBulkEditOptions, _jsxs("div", { className: "ml-auto flex max-w-[480px] flex-shrink-0 flex-grow-0 basis-auto items-center space-x-[16px]", children: [searchRecord && tableSearchInput(), addNewRecord && _jsx(_Fragment, { children: addNewButton })] })] })), _jsxs("div", { className: "TableOptionHeader", children: [_jsxs("div", { className: "flex items-center", children: [titleAndBulkEditOptions, getCollectionHelpArticleId() && (_jsx(HelpActionWrapper, { articleId: getCollectionHelpArticleId(), children: _jsx(HelpIcon, { className: "ml-1 h-[18px] text-black" }) }))] }), _jsxs("div", { className: "TableOptionHeaderOptions ".concat(tableFilters && hasCollectionViews
                            ? 'justify-between py-[8px]'
                            : isScheduleTypePage
                                ? 'justify-between'
                                : 'justify-end'), children: [tableFilters && (hasCollectionViews || isScheduleTypePage) ? (_jsx("div", { className: "mr-[8px]", children: tableFilters })) : (_jsx("div", {})), _jsxs(Space, { align: "center", className: "table-header-option", children: [searchRecord && !hasCollectionViews && tableSearchInput(), extraOptions, scheduleColorCoding, canSwitchViewType && !hideBoardView && applyGroupBySetting && (_jsx(ViewTypeOptionsDropdown, { viewId: viewId, groupBySetting: displaySettings.groupSetting, applyGroupBySetting: applyGroupBySetting })), canSwitchViewType &&
                                        applyGroupBySetting &&
                                        !!displaySettings.groupSetting && (_jsx(GroupBySchemaTag, { viewId: viewId, groupBySetting: displaySettings.groupSetting, columns: columns })), applySchemaOptions && (_jsx(ColumnVisibilityDropdown, { collectionId: collectionId, schemaOptions: displaySettings.schemaOptions, applySchemaOptions: applySchemaOptions })), applySort && (_jsx(TableSort, { currentGroupBySchemaId: (_a = displaySettings.groupSetting) === null || _a === void 0 ? void 0 : _a.schema, sortBy: displaySettings.sortBy, columns: columns, applySort: applySort })), addNewRecord && !hasCollectionViews && _jsx(_Fragment, { children: addNewButton }), schedulePublishShift, moreOptionsDropdown] })] })] }), tableFilters && !hasCollectionViews && !isScheduleTypePage && _jsx(_Fragment, { children: tableFilters }), isBoardViewSettingModalVisible && applyGroupBySetting && (_jsx(BoardViewGroupBySettingModal, { viewId: viewId, collectionId: collectionId, columns: columns, groupBySetting: displaySettings.groupSetting, applyGroupBySetting: applyGroupBySetting }))] }));
};
export default React.memo(TableOptionHeader);
