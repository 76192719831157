var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DocumentSvg = function () { return (_jsxs("svg", { width: "25", height: "28", viewBox: "0 0 18 23", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.665875 0.670898C0.360686 0.670898 0.113281 0.915947 0.113281 1.21823V22.0168C0.113281 22.3191 0.360686 22.5641 0.665875 22.5641H17.2437C17.5489 22.5641 17.7963 22.3191 17.7963 22.0168V1.21823C17.7963 0.915947 17.5489 0.670898 17.2437 0.670898H0.665875ZM2.32366 2.86022V20.3748H15.5859V2.86022H2.32366Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3755 6.69154C13.3755 6.99382 13.1281 7.23887 12.8229 7.23887H5.08663C4.78144 7.23887 4.53403 6.99382 4.53403 6.69154V5.59688C4.53403 5.2946 4.78144 5.04955 5.08663 5.04955H12.8229C13.1281 5.04955 13.3755 5.2946 13.3755 5.59688V6.69154Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.8496 11.0702C7.8496 11.3725 7.60219 11.6175 7.297 11.6175H5.08663C4.78144 11.6175 4.53403 11.3725 4.53403 11.0702V9.97553C4.53403 9.67325 4.78144 9.4282 5.08663 9.4282H7.297C7.60219 9.4282 7.8496 9.67325 7.8496 9.97553V11.0702Z" })] })); };
export var DocumentIcon = function (props) { return (_jsx(Icon, __assign({ component: DocumentSvg }, props))); };
