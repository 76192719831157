var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DownOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Input } from 'antd';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
import CollectionViewCountBadge from './CollectionViewCountBadge';
var CollectionViewDropdown = function (_a) {
    var _b;
    var id = _a.id, selectedViewId = _a.selectedViewId, orderedViews = _a.orderedViews, recordCounts = _a.recordCounts, viewIdInRename = _a.viewIdInRename, _c = _a.canEditViews, canEditViews = _c === void 0 ? false : _c, _d = _a.showRecordCount, showRecordCount = _d === void 0 ? false : _d, setNewViewName = _a.setNewViewName, onViewChange = _a.onViewChange, onAddView = _a.onAddView, onUpdateViewName = _a.onUpdateViewName, onUpdateViewOrder = _a.onUpdateViewOrder, viewIdMenuOpen = _a.viewIdMenuOpen, setViewIdMenuOpen = _a.setViewIdMenuOpen, viewMenuProps = _a.viewMenuProps;
    var _e = useState(false), dropdownOpen = _e[0], setDropdownOpen = _e[1];
    var viewName = (_b = orderedViews.find(function (view) { return view.viewId === selectedViewId; })) === null || _b === void 0 ? void 0 : _b.name;
    var renderDropdownMenu = function () { return (_jsx("div", { className: "w-[288px] rounded bg-white px-[4px] py-[8px] shadow-2xl", children: _jsx(DragDropContext, { onDragEnd: function (dropResult) {
                if (dropResult.destination) {
                    onUpdateViewOrder(dropResult.draggableId, dropResult.destination.index);
                }
            }, children: _jsx(Droppable, { droppableId: "".concat(id, "-views"), children: function (provided) { return (_jsxs("div", __assign({ className: "flex w-full flex-col" }, provided.droppableProps, { ref: provided.innerRef, children: [orderedViews.map(function (_a, viewIndex) {
                            var viewId = _a.viewId, name = _a.name;
                            return (_jsx(Draggable, { draggableId: viewId, index: viewIndex, children: function (provided) {
                                    return (_jsxs("div", __assign({ className: "flex w-full cursor-pointer items-center rounded py-[2px] hover:bg-gray-50" }, provided.draggableProps, { ref: provided.innerRef, onClick: function () {
                                            onViewChange(viewId);
                                            setDropdownOpen(false);
                                        }, children: [canEditViews && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, provided.dragHandleProps, { className: "ml-1" }))), viewIdInRename === viewId ? (_jsx(Input, { className: "px-[8px] py-0 text-base font-medium text-black", placeholder: "Enter a view name", defaultValue: name, onBlur: function () { return onUpdateViewName(viewId); }, onPressEnter: function () {
                                                    onUpdateViewName(viewId);
                                                }, onChange: function (e) {
                                                    return setNewViewName(e.target.value);
                                                }, onKeyDown: function (e) {
                                                    // Fixes "Space" inside input
                                                    e.code === 'Space'
                                                        ? e.stopPropagation()
                                                        : null;
                                                }, onFocus: function (e) { return e.target.select(); }, bordered: false, autoFocus: true })) : (_jsxs("div", { className: "flex px-[8px] text-base font-medium text-black", children: [_jsx("span", { children: name }), showRecordCount && (_jsx(CollectionViewCountBadge, { count: recordCounts === null || recordCounts === void 0 ? void 0 : recordCounts[viewId] }))] })), canEditViews && (_jsx(ZiraDropdown, { menu: viewMenuProps(viewId), trigger: ['click'], open: viewId === viewIdMenuOpen, onLocalVisibleChange: function (open) {
                                                    return setViewIdMenuOpen(open ? viewId : '');
                                                }, destroyPopupOnHide: true, children: _jsx("div", { className: "ml-auto mr-[8px] rounded px-[4px] text-base text-black hover:bg-gray-100", onClick: function (e) { return e.stopPropagation(); }, children: _jsx(EllipsisOutlined, {}) }) }))] }), viewId));
                                } }, viewId));
                        }), provided.placeholder, canEditViews && (_jsxs(_Fragment, { children: [_jsx(Divider, { className: "my-[8px]" }), _jsxs("div", { className: "flex w-full cursor-pointer items-center rounded py-[2px] pl-[8px] text-base text-gray-400 hover:bg-gray-50", onClick: function () {
                                        onAddView();
                                        setDropdownOpen(false);
                                    }, children: [_jsx(PlusOutlined, {}), _jsx("span", { className: "pl-[12px]", children: "Add view" })] })] }))] }))); } }) }) })); };
    return (_jsx(ZiraDropdown, { trigger: ['click'], dropdownRender: renderDropdownMenu, placement: "bottom", open: dropdownOpen, onLocalVisibleChange: setDropdownOpen, destroyPopupOnHide: true, children: _jsxs("div", { className: "flex w-max cursor-pointer items-center rounded px-[8px] py-[4px] text-base font-semibold text-black hover:bg-gray-50", children: [_jsx("span", { children: viewName }), showRecordCount && (_jsx(CollectionViewCountBadge, { count: recordCounts === null || recordCounts === void 0 ? void 0 : recordCounts[selectedViewId] })), _jsx(DownOutlined, { className: "pl-[8px] pt-[1px] text-gray-300" })] }) }));
};
export default React.memo(CollectionViewDropdown);
