var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Button, Layout, Row, Switch, Table, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import './FormTemplatesPage.scss';
import moment from 'moment';
import { CheckOutlined, CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import FormTemplateState from '../FormTemplate';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import FormTemplatePopupModal from './FormTemplatePopupModal/FormTemplatePopupModal';
var Content = Layout.Content;
var FormTemplatesModalContent = function () {
    var _a = FormTemplateState.useContainer(), allFormTemplateData = _a.allFormTemplateData, setShowCreateFormTemplateModal = _a.setShowCreateFormTemplateModal, editExistingTemplate = _a.editExistingTemplate, refetchAllFormTemplates = _a.refetchAllFormTemplates;
    var useUpdateFormTemplate = useSetting().useUpdateFormTemplate;
    var mutateAsync = useUpdateFormTemplate().mutateAsync;
    var _b = useState({}), copied = _b[0], setCopied = _b[1];
    var _c = useState({}), isLoading = _c[0], setIsLoading = _c[1];
    var EditTableButton = useCallback(function (_text, data, _index) {
        return (_jsx(Button, { onClick: function () { return editExistingTemplate(data); }, className: "EditFormDetailBtn", children: "Edit" }));
    }, [editExistingTemplate]);
    var onUpdateFormTemplateData = useCallback(function (newData, index, onBack) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, mutateAsync(newData)];
                case 2:
                    response = _a.sent();
                    if (response) {
                        onBack === null || onBack === void 0 ? void 0 : onBack();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = false, _a)));
                    });
                    refetchAllFormTemplates();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [mutateAsync, refetchAllFormTemplates]);
    var OpenFormToPublic = useCallback(function (_text, data, index) {
        var url = "".concat(process.env.REACT_APP_CLIENT_BASE_URL, "form/").concat(data.id);
        var setIsFormPublic = function (checked) {
            return onUpdateFormTemplateData(__assign(__assign({}, data), { isPublic: checked }), index, function () {
                return setCopied(function (prev) {
                    var _a;
                    return (__assign(__assign({}, prev), (_a = {}, _a[index] = false, _a)));
                });
            });
        };
        return (_jsxs(Row, { align: "middle", className: "OpenFormRow", children: [_jsx(Switch, { checked: data.isPublic, onChange: setIsFormPublic, className: "OpenFormSwitch", loading: isLoading[index] }), data.isPublic && (_jsx(CopyToClipboard, { text: url, onCopy: function () { return setCopied(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
                    }); }, children: _jsx(ZiraTooltip, { title: "".concat(copied[index] ? 'Copied ' : 'Copy ', " To Clipboard"), children: _jsx("div", { className: "bg-gray-60 rounded py-1 px-2 cursor-pointer", children: copied[index] ? _jsx(CheckOutlined, {}) : _jsx(CopyOutlined, {}) }) }) }))] }));
    }, [copied, isLoading, onUpdateFormTemplateData]);
    var checkBoxColumn = useCallback(function (checked, isLoading, onChange) {
        return (_jsx(Switch, { checked: checked, onChange: onChange, className: "OpenFormSwitch", loading: isLoading }));
    }, []);
    var columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Source Collection',
            dataIndex: 'sourceCollectionName',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: function (date) { return "".concat(moment(date).format('YYYY-MM-DD')); },
        },
        {
            title: 'Edit',
            render: EditTableButton,
        },
        {
            title: 'Publish To Web',
            render: OpenFormToPublic,
        },
        {
            title: (_jsxs(_Fragment, { children: ["Limit Submissions", _jsx(Tooltip, { title: "Turn it on to limit each generated form link to be submited only once.", children: _jsx(InfoCircleOutlined, { className: "ml-2" }) })] })),
            dataIndex: 'isSubmissionLimited',
            render: function (isSubmissionLimited, data, index) {
                return checkBoxColumn(isSubmissionLimited, isLoading[index], function (checked) {
                    return onUpdateFormTemplateData(__assign(__assign({}, data), { isSubmissionLimited: checked }), index);
                });
            },
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            render: function (isActive, data, index) {
                return checkBoxColumn(isActive, isLoading[index], function (checked) {
                    return onUpdateFormTemplateData(__assign(__assign({}, data), { isActive: checked }), index);
                });
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "newBlockModalHeader", children: _jsx("div", { className: "newBlockModalHeaderText", children: "Forms" }) }), _jsxs("div", { className: "FormsLayoutWrapper", children: [_jsxs(Content, { children: [_jsx("div", { className: "SectionHeader", children: _jsx("div", { className: "FormsPageDescription", children: "For any data set that contains the following values, filter based on subsequent value." }) }), _jsx("hr", { className: "SettingsSiderDivider" }), _jsx(Table, { columns: columns, dataSource: allFormTemplateData, pagination: {
                                    position: ['bottomRight'],
                                    hideOnSinglePage: true,
                                    pageSize: 9,
                                } }), _jsx("hr", { className: "SettingsSiderDivider" }), _jsx(Button, { className: "mt-8 EditFormDetailBtn", onClick: function () { return setShowCreateFormTemplateModal(true); }, children: "Create New Form" })] }), _jsx(FormTemplatePopupModal, {})] })] }));
};
var FormTemplatesPageMemo = React.memo(FormTemplatesModalContent);
var FormTemplatesPageWrapper = function () {
    return (_jsx(FormTemplateState.Provider, { children: _jsx(FormTemplatesPageMemo, {}) }));
};
export default FormTemplatesPageWrapper;
