var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep, isArray } from 'lodash';
import { DefaultValue, selectorFamily } from 'recoil';
import { ScheduleGroupBy } from 'src/shared';
import { ScheduleCardHeight, getCardHeight } from 'src/services/ScheduleService';
import { tableCollectionViewsState, tableSelectedViewIdState } from 'src/state';
import { getAccountStartDayOfWeek } from 'src/utils/Account';
import { DEFAULT_SCHEDULE_BOTTOM_BAR_SETTINGS, defaultSchedulingBottomSectionTabSetting, } from 'src/components/ScheduleView/FullPageScheduleBottomBar/Constant';
import { accountTimeZoneSelector } from '../withAccountDetailState';
var tableSchedulingDisplaySettingsState = selectorFamily({
    key: 'tableSchedulingDisplaySettingsState',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var schedulingDisplaySettings = get(tableCollectionViewsState(tableId)).schedulingDisplaySettings;
            var settings = schedulingDisplaySettings === null || schedulingDisplaySettings === void 0 ? void 0 : schedulingDisplaySettings[get(tableSelectedViewIdState(tableId))];
            if (settings) {
                var stats = settings.statsOptions;
                var isArray_1 = Array.isArray(stats);
                // Manually migrate local storage old format statsOptions to new format
                //TODO! remove this after we push new stats to prod, and got it running for a while
                if (!isArray_1) {
                    var tempSetting = cloneDeep(settings);
                    tempSetting.statsOptions = [];
                    return tempSetting;
                }
                else {
                    return settings;
                }
            }
            else {
                return {};
            }
        };
    },
    set: function (tableId) {
        return function (_a, newSchedulingDisplaySettings) {
            var get = _a.get, set = _a.set;
            if (!(newSchedulingDisplaySettings instanceof DefaultValue)) {
                var viewId_1 = get(tableSelectedViewIdState(tableId));
                set(tableCollectionViewsState(tableId), function (prev) {
                    var _a;
                    return __assign(__assign({}, prev), { schedulingDisplaySettings: __assign(__assign({}, prev.schedulingDisplaySettings), (_a = {}, _a[viewId_1] = newSchedulingDisplaySettings, _a)) });
                });
            }
        };
    },
});
var scheduleGroupBySelector = selectorFamily({
    key: 'scheduleGroupBySelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var groupByKey = get(tableSchedulingDisplaySettingsState(tableId)).groupByKey;
            return groupByKey !== null && groupByKey !== void 0 ? groupByKey : ScheduleGroupBy.USER;
        };
    },
});
var scheduleColorCodingSelector = selectorFamily({
    key: 'scheduleColorCodingSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var colorCoding = get(tableSchedulingDisplaySettingsState(tableId)).colorCoding;
            return colorCoding === 'SHIFT_STATUS' ? 'status' : colorCoding;
        };
    },
});
var scheduleViewTypeSelector = selectorFamily({
    key: 'scheduleViewTypeSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var viewType = get(tableSchedulingDisplaySettingsState(tableId)).viewType;
            return viewType;
        };
    },
});
var scheduleVisibleStatsOptionsSelector = selectorFamily({
    key: 'scheduleVisibleStatsOptionsSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var statsOptions = get(tableSchedulingDisplaySettingsState(tableId)).statsOptions;
            return statsOptions !== null && statsOptions !== void 0 ? statsOptions : [];
        };
    },
});
var scheduleShowAllSelector = selectorFamily({
    key: 'scheduleShowAllSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var showAll = get(tableSchedulingDisplaySettingsState(tableId)).showAll;
            return showAll !== null && showAll !== void 0 ? showAll : true;
        };
    },
});
var scheduleTemplateFilterSelector = selectorFamily({
    key: 'scheduleTemplateFilterSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var templateFilter = get(tableSchedulingDisplaySettingsState(tableId)).templateFilter;
            return isArray(templateFilter)
                ? templateFilter
                : templateFilter !== undefined
                    ? [templateFilter]
                    : [];
        };
    },
});
var scheduleTimeZoneSelector = selectorFamily({
    key: 'scheduleTimeZoneSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var timezone = get(tableSchedulingDisplaySettingsState(tableId)).timezone;
            var accountTimezone = get(accountTimeZoneSelector);
            return timezone !== null && timezone !== void 0 ? timezone : accountTimezone;
        };
    },
});
var scheduleStartDayOfWeekSelector = selectorFamily({
    key: 'scheduleStartDayOfWeekSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var startDayOfWeek = get(tableSchedulingDisplaySettingsState(tableId)).startDayOfWeek;
            return startDayOfWeek !== null && startDayOfWeek !== void 0 ? startDayOfWeek : getAccountStartDayOfWeek();
        };
    },
});
var scheduleShowShiftTimesSelector = selectorFamily({
    key: 'scheduleShowShiftTimesSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var showShiftTimes = get(tableSchedulingDisplaySettingsState(tableId)).showShiftTimes;
            return showShiftTimes !== null && showShiftTimes !== void 0 ? showShiftTimes : true;
        };
    },
});
var scheduleCardHeightSettingSelector = selectorFamily({
    key: 'scheduleCardHeightSettingSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var cardHeight = get(tableSchedulingDisplaySettingsState(tableId)).cardHeight;
            return cardHeight !== null && cardHeight !== void 0 ? cardHeight : ScheduleCardHeight.COMPACT;
        };
    },
});
var scheduleCardHeightSelector = selectorFamily({
    key: 'scheduleCardHeightSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var cardHeight = get(scheduleCardHeightSettingSelector(tableId));
            return getCardHeight(cardHeight);
        };
    },
});
var scheduleBottomBarSettingsSelector = selectorFamily({
    key: 'scheduleBottomBarSettingsSelector',
    get: function (tableId) {
        return function (_a) {
            var get = _a.get;
            var bottomBarSettings = get(tableSchedulingDisplaySettingsState(tableId)).bottomBarSettings;
            return bottomBarSettings !== null && bottomBarSettings !== void 0 ? bottomBarSettings : DEFAULT_SCHEDULE_BOTTOM_BAR_SETTINGS;
        };
    },
});
var scheduleBottomBarTabsSelector = selectorFamily({
    key: 'scheduleBottomBarTabsSelector',
    get: function (tableId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var bottomBarSettings = get(scheduleBottomBarSettingsSelector(tableId));
            return (_b = bottomBarSettings === null || bottomBarSettings === void 0 ? void 0 : bottomBarSettings.bottomBarTabs) !== null && _b !== void 0 ? _b : defaultSchedulingBottomSectionTabSetting;
        };
    },
});
// If true, show stats for budgeted templates (templates configured from applied needs)
var scheduleBudgetedTemplateStatsSelector = selectorFamily({
    key: 'scheduleBudgetedTemplateStatsSelector',
    get: function (tableId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var bottomBarSettings = get(scheduleBottomBarSettingsSelector(tableId));
            return (_b = bottomBarSettings === null || bottomBarSettings === void 0 ? void 0 : bottomBarSettings.budgetedTemplateStats) !== null && _b !== void 0 ? _b : false;
        };
    },
});
export { tableSchedulingDisplaySettingsState, scheduleGroupBySelector, scheduleColorCodingSelector, scheduleViewTypeSelector, scheduleVisibleStatsOptionsSelector, scheduleShowAllSelector, scheduleTemplateFilterSelector, scheduleTimeZoneSelector, scheduleStartDayOfWeekSelector, scheduleShowShiftTimesSelector, scheduleCardHeightSettingSelector, scheduleCardHeightSelector, scheduleBottomBarSettingsSelector, scheduleBottomBarTabsSelector, scheduleBudgetedTemplateStatsSelector, };
