import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { RollupConfigurationContext } from 'src/components/RollupConfigurationModal/RollupConfigurationModal';
import RollupSwitchWithIconLabel from 'src/components/RollupConfigurationModal/components/RollupViewSettingsToggles/component/RollupSwitchWithIconLabel';
import RollupViewSettingsPreview from 'src/components/RollupConfigurationModal/components/RollupViewSettingsToggles/component/RollupViewSettingsPreview';
var RollupViewSettingsToggles = function () {
    var _a = useContext(RollupConfigurationContext), rollupConfiguration = _a.rollupConfiguration, setViewConfiguration = _a.setViewConfiguration;
    var _b = rollupConfiguration.viewSettings, showValues = _b.showValues, showProgressBar = _b.showProgressBar, showPercent = _b.showPercent;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Preferences" }), _jsx(RollupSwitchWithIconLabel, { checked: showValues, onClick: function (value) {
                            setViewConfiguration({
                                showValues: value,
                                showProgressBar: showProgressBar,
                                showPercent: showPercent,
                            });
                        }, label: "Values" }), _jsx(RollupSwitchWithIconLabel, { checked: showProgressBar, label: "Progress Bar", onClick: function (value) {
                            setViewConfiguration({
                                showValues: showValues,
                                showProgressBar: value,
                                showPercent: showPercent,
                            });
                        } }), _jsx(RollupSwitchWithIconLabel, { checked: showPercent, onClick: function (value) {
                            setViewConfiguration({
                                showValues: showValues,
                                showProgressBar: showProgressBar,
                                showPercent: value,
                            });
                        }, label: "Percentage" })] }), _jsx(RollupViewSettingsPreview, {})] }));
};
export default RollupViewSettingsToggles;
