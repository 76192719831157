import { atom } from 'recoil';
var automationBaseCollectionIdState = atom({
    key: 'automationBaseCollectionIdState',
    default: '',
});
var automationFilterOptionsState = atom({
    key: 'automationFilterOptionsState',
    default: [],
});
export { automationBaseCollectionIdState, automationFilterOptionsState };
