var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Input, Select, Skeleton, Switch } from 'antd';
import Emitter from 'src/utils/EventEmitter';
import { useRecoilValue } from 'recoil';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { DefaultPagePagination, EmitterEventType } from 'src/shared';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { useDebounce, useRecordDetail, useWindowSize } from 'src/hooks/component';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
import { checkAllZeroValueMetricsPayInfo } from '../utils';
import { selectedShiftPayrollStatusState } from '../atom';
import { ReportSourceType, } from '../Interface';
import IndividualPayReportCard from './IndividualPayReportCard';
var Option = Select.Option;
var IndividualPayReportCardList = function (_a) {
    var _b, _c;
    var payPeriodId = _a.payPeriodId, reportSourceType = _a.reportSourceType, changeGroupBy = _a.changeGroupBy, visible = _a.visible, payrollEnhancementEnabled = _a.payrollEnhancementEnabled;
    var selectedShiftPayrollStatus = useRecoilValue(selectedShiftPayrollStatusState);
    var _d = useState([]), payReportListData = _d[0], setPayReportListData = _d[1];
    var _e = useState(''), searchText = _e[0], setSearchText = _e[1];
    var debouncedSearchText = useDebounce(searchText, 500);
    var _f = useState(0), page = _f[0], setPage = _f[1];
    var _g = useState(false), isOverlayVisible = _g[0], setIsOverlayVisible = _g[1];
    var _h = useState(true), showAllPayCards = _h[0], setShowAllPayCards = _h[1];
    var _j = usePayApi(), useGetPayReportListData = _j.useGetPayReportListData, payReportCardListData = _j.payReportCardListData;
    var getGetPayReportListDataRequestBody = function () {
        var body = {
            payPeriodId: payPeriodId,
            reportSourceType: reportSourceType,
            pagination: undefined,
            search: debouncedSearchText || undefined,
            payrollStatus: selectedShiftPayrollStatus,
        };
        if (selectedShiftPayrollStatus == undefined) {
            body.pagination = {
                page: page,
                size: DefaultPagePagination.size,
            };
        }
        return body;
    };
    var _k = useGetPayReportListData({
        body: getGetPayReportListDataRequestBody(),
        options: {
            enabled: visible || !!payReportListData.length, // Makes it so on initial load we only fetch user list (see IndividualPayReportCardListWrapper)
        },
    }), data = _k.data, isLoading = _k.isLoading;
    useEffect(function () {
        if (data) {
            var newReportListData_1 = [];
            if (reportSourceType === ReportSourceType.USER) {
                newReportListData_1 = data.users;
            }
            else if (reportSourceType === ReportSourceType.LOCATION) {
                newReportListData_1 = data.locations;
            }
            if (page && selectedShiftPayrollStatus === undefined) {
                setPayReportListData(function (prev) { return prev.concat(newReportListData_1); });
            }
            else {
                setPayReportListData(newReportListData_1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    // Reset pagination when selected status is updated
    useEffect(function () {
        if (selectedShiftPayrollStatus === undefined) {
            setPage(0);
        }
    }, [selectedShiftPayrollStatus]);
    // Refetch logic with current data size for when shift/timeoff record gets
    // updated/created/deleted inside the pay detail modal
    var refetchListWithRecordDataMutation = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var body, resp, newReportListData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!visible)
                        return [2 /*return*/];
                    body = {
                        payPeriodId: payPeriodId,
                        reportSourceType: reportSourceType,
                        pagination: {
                            page: 0,
                            size: (page + 1) * DefaultPagePagination.size,
                        },
                        search: debouncedSearchText || undefined,
                        payrollStatus: selectedShiftPayrollStatus,
                    };
                    return [4 /*yield*/, payReportCardListData(body)];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        newReportListData = [];
                        if (reportSourceType === ReportSourceType.USER) {
                            newReportListData = resp.users;
                        }
                        else if (reportSourceType === ReportSourceType.LOCATION) {
                            newReportListData = resp.locations;
                        }
                        setPayReportListData(newReportListData);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [
        debouncedSearchText,
        page,
        payPeriodId,
        payReportCardListData,
        reportSourceType,
        visible,
        selectedShiftPayrollStatus,
    ]);
    useEffect(function () {
        Emitter.on(EmitterEventType.REFETCH_PAYCARD_LIST, refetchListWithRecordDataMutation);
        return function () {
            Emitter.off(EmitterEventType.REFETCH_PAYCARD_LIST, refetchListWithRecordDataMutation);
        };
    });
    var canLoadMore = useMemo(function () { var _a, _b; return !!(((_a = data === null || data === void 0 ? void 0 : data.users) === null || _a === void 0 ? void 0 : _a.length) || ((_b = data === null || data === void 0 ? void 0 : data.locations) === null || _b === void 0 ? void 0 : _b.length)); }, [(_b = data === null || data === void 0 ? void 0 : data.locations) === null || _b === void 0 ? void 0 : _b.length, (_c = data === null || data === void 0 ? void 0 : data.users) === null || _c === void 0 ? void 0 : _c.length]);
    useEffect(function () {
        if (visible && searchText) {
            // Reset search
            setSearchText('');
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    var onGroupByChange = useCallback(function (newGroupBy) {
        changeGroupBy(newGroupBy);
    }, [changeGroupBy]);
    var onSearchChange = useCallback(function (e) {
        setPage(0);
        setSearchText(e.target.value);
    }, []);
    var onLoadMore = useCallback(function () {
        if (!isLoading) {
            setPage(function (prevPage) { return prevPage + 1; });
        }
    }, [isLoading]);
    var navigate = useNavigate();
    var url = useResolvedPath('').pathname;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var onOpenPayDetail = useCallback(function (recordId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate({
                pathname: "".concat(url),
                search: "?ridForPay=".concat(recordId),
            });
            return [2 /*return*/];
        });
    }); }, [navigate, url]);
    var updateSinglePayCardShiftCount = useCallback(function (cardIndex, newShiftCount) {
        setPayReportListData(function (prev) {
            var newPayReportListData = __spreadArray([], prev, true);
            var card = newPayReportListData[cardIndex];
            if (card) {
                card.shiftsPerStatus = newShiftCount;
            }
            return newPayReportListData;
        });
    }, []);
    var windowWidth = useWindowSize().windowWidth;
    var payReportCardList = useMemo(function () {
        var rows = payReportListData === null || payReportListData === void 0 ? void 0 : payReportListData.map(function (item, index) {
            var cardHasAllZeroValueMetrics = checkAllZeroValueMetricsPayInfo(item);
            if (!cardHasAllZeroValueMetrics || showAllPayCards) {
                return (_jsx(IndividualPayReportCard, { payRollData: item, openRecordDetail: openRecordDetail, openPayDetail: onOpenPayDetail, payrollEnhancementEnabled: payrollEnhancementEnabled, payPeriodId: payPeriodId, reportSourceType: reportSourceType, updateSinglePayCardShiftCount: function (newData) {
                        return updateSinglePayCardShiftCount(index, newData);
                    } }, item.recordId));
            }
        });
        return rows;
    }, [
        onOpenPayDetail,
        openRecordDetail,
        payPeriodId,
        payReportListData,
        payrollEnhancementEnabled,
        reportSourceType,
        showAllPayCards,
        updateSinglePayCardShiftCount,
    ]);
    if (!visible) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { style: {
            padding: (windowWidth || 577) > 576 ? '0px 48px 48px 40px' : '0px 0px 40px 0px',
        }, children: [_jsxs("div", { className: "flex flex-wrap mb-5 justify-between", children: [_jsxs("div", { className: "flex items-center", children: [_jsxs(Select, { className: "w-48 PayCardGroupBySelector", onClick: function () { return setIsOverlayVisible(true); }, value: reportSourceType, onChange: onGroupByChange, bordered: false, children: [_jsx(Option, { value: ReportSourceType.USER, children: "Group By: User" }), _jsx(Option, { value: ReportSourceType.LOCATION, children: "Group By: Location" })] }), _jsxs("div", { className: "flex items-center ml-4 font-semibold rounded-lg bg-white py-[5px] px-3", hidden: !!selectedShiftPayrollStatus, children: ["Show All Cards", _jsx(Switch, { className: "mt-0 ml-2", checked: showAllPayCards, onChange: setShowAllPayCards })] })] }), _jsx(Input, { className: "order-3 mt-1 w-full lg:order-2 lg:ml-2 lg:mt-0 lg:w-96 PayCardSearchInput", prefix: _jsx(SearchOutlinedIcon, {}), value: searchText, placeholder: "Search...", onChange: onSearchChange, bordered: false })] }), (!isLoading || (isLoading && !debouncedSearchText)) && _jsx(_Fragment, { children: payReportCardList }), canLoadMore && !isLoading && selectedShiftPayrollStatus === undefined && (_jsxs("div", { className: "LoadMore rounded-xl border border-white/0 shadow-block hover:border-azure-50 hover:shadow-hover", onClick: onLoadMore, children: [_jsx(ArrowDownOutlined, {}), _jsx("span", { children: "Load More" })] })), !canLoadMore && !isLoading && selectedShiftPayrollStatus === undefined && (_jsx("div", { className: "LoadMore rounded-xl hover:cursor-auto", children: "No Items to Load" })), isLoading && (_jsx(Skeleton, { className: "SinglePay mt-2", active: true, title: false, paragraph: { rows: 5, width: '100%' } })), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setIsOverlayVisible(false);
                }, style: { display: isOverlayVisible ? 'block' : 'none' } })] }));
};
export default React.memo(IndividualPayReportCardList);
