import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import moment from 'moment';
import { useTimeZone } from 'src/hooks/component';
import { getAccountCurrencyCode } from 'src/utils/Account';
import { DefaultRateTableHeaderType, } from '../../../interface';
import DefaultRateTagCell from './DefaultRateTagCell';
var RateTableCell = function (_a) {
    var columnType = _a.columnType, cellData = _a.cellData;
    var timeZone = useTimeZone().timeZone;
    var cellContent = useMemo(function () {
        var _a, _b, _c, _d, _e, _f;
        if (columnType === DefaultRateTableHeaderType.CONDITION) {
            var condition_1 = cellData;
            if ((_a = condition_1.bespokeValue) === null || _a === void 0 ? void 0 : _a.daysOfWeek) {
                return (_c = (_b = condition_1.bespokeValue) === null || _b === void 0 ? void 0 : _b.daysOfWeek) === null || _c === void 0 ? void 0 : _c.map(function (dayOfWeek) { return (_jsx("div", { children: dayOfWeek }, "".concat(condition_1.id, "-").concat(dayOfWeek))); });
            }
            else if (!condition_1.bespokeValue && ((_d = condition_1.filterValue) === null || _d === void 0 ? void 0 : _d.type) === 'datetime') {
                var type = ((_e = condition_1.filterValue) === null || _e === void 0 ? void 0 : _e.type) || '';
                var value = (_f = condition_1.filterValue) === null || _f === void 0 ? void 0 : _f[type];
                return (_jsx("div", { className: "font-medium", children: moment(value).tz(timeZone).format('h:mm A') }));
            }
            else {
                return _jsx(DefaultRateTagCell, { condition: condition_1 });
            }
        }
        else if (columnType === DefaultRateTableHeaderType.ADJUSTMENT) {
            var value = cellData.value;
            var accountCurrency = getAccountCurrencyCode();
            var displayValue = value.toLocaleString(undefined, {
                style: 'currency',
                currency: accountCurrency,
                minimumFractionDigits: 2,
            });
            return _jsx("div", { className: "font-medium", children: displayValue });
        }
    }, [cellData, columnType, timeZone]);
    return _jsx(_Fragment, { children: cellContent });
};
export default React.memo(RateTableCell);
