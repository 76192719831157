import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Input, Modal, Select, Switch, Tabs } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, PlusOutlined } from '@ant-design/icons';
import { ifUserHasGeneralPermission, tableDisplaySettingsState } from 'src/state';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import Emitter from 'src/utils/EventEmitter';
import { EmitterEventType } from 'src/shared';
import { useScheduleNeedsCoverageComponent } from 'src/hooks/component';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { isNeedsCoverageConfigModalVisibleState, needsCoverageConfigModalActiveTabState, needsCoverageSelectedTemplateIdState, needsCoverageTemplateVisibleOption, } from '../../Atom';
import { NeedsCoverageType } from '../../interface';
import './NeedsCoverageTemplateConfigModal.scss';
import NeedsCoverageTemplateGeneralSettingTable from './NeedsCoverageTemplateGeneralSettingTable';
import NeedsCoverageTemplateDetailModal from './NeedsCoverageTemplateDetailModal';
import LocationSelector from './LocationSelector';
import EmptyTableContent from './EmptyNeedCoverageSettingTableContent';
var NeedsCoverageTemplateConfigModal = function (_a) {
    var blockId = _a.blockId;
    var _b = useRecoilState(isNeedsCoverageConfigModalVisibleState), isModalVisible = _b[0], setIsModalVisible = _b[1];
    var _c = useState(false), showArchived = _c[0], setShowArchived = _c[1];
    var _d = useState('name'), sortKey = _d[0], setSortKey = _d[1];
    var _e = useState(1), sortOrder = _e[0], setSortOrder = _e[1];
    var _f = useState(''), searchTerm = _f[0], setSearchTerm = _f[1];
    var schedulingDisplaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var _g = useRecoilState(needsCoverageConfigModalActiveTabState), activeTab = _g[0], setActiveTab = _g[1];
    var _h = useRecoilState(needsCoverageTemplateVisibleOption), isTemplateDetailModalVisible = _h[0], setIsTemplateDetailModalVisible = _h[1];
    var _j = useRecoilState(needsCoverageSelectedTemplateIdState), selectedTemplate = _j[0], setSelectedTemplate = _j[1];
    var _k = useState('AllLocations'), selectedLocationOption = _k[0], setSelectedLocationOption = _k[1];
    var onOpenExistingTemplateDetailModal = useScheduleNeedsCoverageComponent().onOpenExistingTemplateDetailModal;
    var fetchAllTemplatesRequestBody = useMemo(function () {
        return {
            filters: selectedLocationOption === 'BlockFilter' ? schedulingDisplaySettings.filters : null,
            locations: selectedLocationOption === 'BlockFilter' ||
                selectedLocationOption === 'AllLocations'
                ? undefined
                : selectedLocationOption,
        };
    }, [schedulingDisplaySettings.filters, selectedLocationOption]);
    var _l = useNeedCoverageApi(), useGetAllNeedTemplates = _l.useGetAllNeedTemplates, useGetAllCoverageTemplates = _l.useGetAllCoverageTemplates;
    //Get all need templates
    var _m = useGetAllNeedTemplates({
        body: fetchAllTemplatesRequestBody,
        options: { enabled: activeTab === NeedsCoverageType.NEED },
    }), allNeedTemplates = _m.data, isNeedTemplatesLoading = _m.isLoading, refetchNeedTemplates = _m.refetch;
    //Get all coverage templates
    var _o = useGetAllCoverageTemplates({
        body: fetchAllTemplatesRequestBody,
        options: { enabled: activeTab === NeedsCoverageType.COVERAGE },
    }), allCoverageTemplates = _o.data, isCoverageTemplatesLoading = _o.isLoading, refetchCoverageTemplates = _o.refetch;
    var needsCoverageEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE));
    var needsEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_EDIT_PERMISSION)) ||
        needsCoverageEditPermission;
    var coverageEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.COVERAGE_EDIT_PERMISSION)) ||
        needsCoverageEditPermission;
    var isLoading = isNeedTemplatesLoading || isCoverageTemplatesLoading;
    var hasEditPermission = activeTab === NeedsCoverageType.NEED ? needsEditPermission : coverageEditPermission;
    var tabItems = useMemo(function () {
        return [
            {
                key: NeedsCoverageType.NEED,
                label: _jsx("div", { className: "px-6", children: "Needs" }),
            },
            {
                key: NeedsCoverageType.COVERAGE,
                label: _jsx("div", { className: "px-4", children: "Coverages" }),
            },
        ];
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, { className: "NeedsCoverageTemplateConfigModal", open: isModalVisible, closable: false, width: "93%", footer: null, bodyStyle: { height: 'calc(100vh - 100px)', overflowY: 'auto' }, centered: true, children: [_jsx(Button, { className: "ModalCloseButton", onClick: function () {
                            setIsModalVisible(false);
                            setActiveTab(NeedsCoverageType.NEED);
                            Emitter.emit(EmitterEventType.REFRESH_SCHEDULE, null);
                        }, children: "\u2715" }), _jsx("div", { className: "mb-3 text-3xl font-semibold", children: "Needs & Coverage" }), _jsx("div", { className: "text-xs font-medium", children: "Setup shift needs & coverage rules to automatically track and add schedules." }), _jsxs("div", { className: "flex space-x-2 mt-[40px]", children: [_jsxs("div", { children: [_jsx("div", { className: "font-medium", children: "Location" }), _jsx(LocationSelector, { blockId: blockId, isDetailConfigModal: false, selectedLocationOption: selectedLocationOption, updateSelectedLocationOption: setSelectedLocationOption })] }), _jsxs("div", { children: [_jsx("span", { className: "font-medium", children: "Quick Search" }), _jsx(Input, { placeholder: "Search Names", onChange: function (event) {
                                            setSearchTerm(event.target.value.trim());
                                        } })] })] }), _jsxs("div", { className: "flex items-end", children: [_jsx(Tabs, { items: tabItems, className: "NeedsCoverageTableTabs mt-[15px]", onChange: function (tab) { return setActiveTab(tab); }, activeKey: activeTab }), _jsx("div", { className: "w-[184px]" }), _jsxs("div", { className: "pb-[12px] mr-[128px] flex items-center justify-self-end justify-end", children: [_jsx("span", { className: "pr-[8px] font-medium", children: "Show Archived" }), _jsx(Switch, { className: "m-0", onChange: function () {
                                            setShowArchived(function (toShow) { return !toShow; });
                                        } })] }), _jsxs("div", { className: "pb-[8px] mr-[28px] flex items-center justify-self-end justify-end", children: [_jsx("span", { className: "pr-[8px] font-medium", children: "Sort By" }), _jsxs("div", { className: "flex space-x-1", children: [_jsx(Select, { options: [
                                                    { label: 'Alphabetical', value: 'name' },
                                                    { label: 'Last Updated', value: 'lastUpdated' },
                                                ], onChange: function (key) { return setSortKey(key); }, value: sortKey }), _jsx(Select, { options: [
                                                    {
                                                        label: _jsx(ArrowDownOutlined, {}),
                                                        value: 1,
                                                    },
                                                    {
                                                        label: _jsx(ArrowUpOutlined, {}),
                                                        value: -1,
                                                    },
                                                ], onChange: function (value) { return setSortOrder(value); }, value: sortOrder })] })] })] }), isLoading ? (_jsx("div", { className: "mt-5", children: _jsx(ZiraRowsSkeleton, { rowNumber: 5 }) })) : (allNeedTemplates === null || allNeedTemplates === void 0 ? void 0 : allNeedTemplates.length) === 0 || (allCoverageTemplates === null || allCoverageTemplates === void 0 ? void 0 : allCoverageTemplates.length) === 0 ? (_jsx(EmptyTableContent, { children: _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "CreateNewBtn", onClick: function () {
                                setSelectedTemplate(undefined);
                                setIsTemplateDetailModalVisible({
                                    visible: true,
                                    viewOnly: false,
                                });
                            }, children: "Create New" }) })) : (_jsxs(_Fragment, { children: [_jsx(NeedsCoverageTemplateGeneralSettingTable, { blockId: blockId, isNeedTemplates: activeTab === NeedsCoverageType.NEED, onOpenExistingTemplateDetailModal: onOpenExistingTemplateDetailModal, tableData: activeTab === NeedsCoverageType.NEED
                                    ? (allNeedTemplates !== null && allNeedTemplates !== void 0 ? allNeedTemplates : [])
                                    : (allCoverageTemplates !== null && allCoverageTemplates !== void 0 ? allCoverageTemplates : []), refetchNeedTemplates: refetchNeedTemplates, refetchCoverageTemplates: refetchCoverageTemplates, showArchived: showArchived, sortKey: sortKey, sortOrder: sortOrder, searchTerm: searchTerm }), hasEditPermission && (_jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "CreateNewBtn mt-[20px]", onClick: function () {
                                    setSelectedTemplate(undefined);
                                    setIsTemplateDetailModalVisible({
                                        visible: true,
                                        viewOnly: false,
                                    });
                                }, children: "Create New" }))] }))] }), isTemplateDetailModalVisible && (_jsx(NeedsCoverageTemplateDetailModal, { isModalVisible: isTemplateDetailModalVisible.visible, onCloseModal: function () {
                    return setIsTemplateDetailModalVisible({
                        visible: false,
                        viewOnly: false,
                    });
                }, viewOnly: isTemplateDetailModalVisible.viewOnly, selectedTab: activeTab, blockId: blockId, templateId: selectedTemplate, refetchNeedTemplates: function () {
                    // Reset location selector to AllLocations to guarantee user can see the template they just created/updated
                    // In case user create/update template with other location
                    setSelectedLocationOption('AllLocations');
                    refetchNeedTemplates();
                }, refetchCoverageTemplates: function () {
                    setSelectedLocationOption('AllLocations');
                    refetchCoverageTemplates();
                }, onCloseConfigModal: function () { return setIsModalVisible(false); }, defaultLocation: selectedLocationOption }))] }));
};
export default React.memo(NeedsCoverageTemplateConfigModal);
