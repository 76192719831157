var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, DatePicker, Form, InputNumber, Radio, Row, Select, Space } from 'antd';
import { Option } from 'antd/lib/mentions';
import moment from 'moment-timezone';
import React, { useCallback, useMemo, useState } from 'react';
import { collectionTableNonSchemaMutationsState, shiftModalIsGroupState, } from 'src/state';
import { NonSchemaNativePath } from 'src/shared';
import './RecurringRulePopup.scss';
import { useRecoilState, useRecoilCallback } from 'recoil';
import { uniqBy } from 'lodash';
import { DayOfWeekOptions, DefaultFrequencyOptions, RecurringFrequency } from '../Constant';
var RecurringRulePopup = function (_a) {
    var recurringShiftData = _a.recurringShiftData, setRecurringShiftData = _a.setRecurringShiftData, _b = _a.recordId, recordId = _b === void 0 ? '' : _b, dataSource = _a.dataSource;
    var formInstance = Form.useForm()[0];
    var _c = useState((recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.endsOn.endDate) ? 'endDate' : 'occurrences'), endsOn = _c[0], setEndsOn = _c[1];
    var _d = useRecoilState(shiftModalIsGroupState(__assign(__assign({}, dataSource), { recordId: recordId }))), isGroupState = _d[0], setIsGroupState = _d[1];
    var onIsGroupChange = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (isGroup) {
            setIsGroupState(isGroup);
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schema: NonSchemaNativePath.SHIFT_IS_GROUP,
                        boolean: isGroup !== null && isGroup !== void 0 ? isGroup : false,
                    }
                ], prev, true), 'schema');
            });
        };
    }, [dataSource, setIsGroupState]);
    var onFormChange = useCallback(function (changedValues, allValues) {
        var newRecurringShiftData = {
            frequency: allValues.frequency,
            interval: allValues.interval,
            daysOfWeek: allValues.daysOfWeek,
            endsOn: {
                occurrences: endsOn === 'occurrences' &&
                    'occurrences' in allValues &&
                    allValues.occurrences
                    ? allValues.occurrences
                    : null,
                endDate: endsOn === 'endDate' && 'endDate' in allValues && allValues.endDate
                    ? allValues.endDate.toISOString()
                    : null,
            },
            enforceAssignee: allValues.enforceAssignee,
            createShiftGroup: isGroupState,
        };
        setRecurringShiftData(newRecurringShiftData);
    }, [endsOn, setRecurringShiftData, isGroupState]);
    var recurringFormContent = useMemo(function () {
        var _a, _b, _c;
        return (_jsxs(Form, { layout: "horizontal", form: formInstance, requiredMark: false, onValuesChange: onFormChange, children: [_jsx("div", { className: "RecurringRuleModalTitle", children: "Recurring Shift" }), _jsxs(Row, { children: [_jsx(Form.Item, { className: "intervalInput", name: "interval", label: _jsx("div", { className: "SelectorTitle", children: "Repeat every" }), initialValue: (_a = recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.interval) !== null && _a !== void 0 ? _a : 1, children: _jsx(InputNumber, { min: 1 }) }), _jsx(Form.Item, { className: "frequencyInput", name: "frequency", initialValue: (_b = recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.frequency) !== null && _b !== void 0 ? _b : RecurringFrequency.WEEKLY, children: _jsx(Select, { children: DefaultFrequencyOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) }) })] }), (!recurringShiftData ||
                    recurringShiftData.frequency == RecurringFrequency.WEEKLY) && (_jsx(Form.Item, { name: "daysOfWeek", label: _jsx("div", { className: "SelectorTitle", children: "Repeat on" }), initialValue: recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.daysOfWeek, children: _jsx(Checkbox.Group, { className: "daysOfWeekBoxGroup", options: DayOfWeekOptions }) })), _jsx("div", { className: "SelectorTitle", children: "Ends:" }), _jsx(Form.Item, { name: "endsOn", children: _jsxs(Space, { direction: "vertical", children: [_jsxs(Row, { className: "radioOption endsOnRadio", children: [_jsx(Radio, { value: "endDate", onChange: function (e) { return setEndsOn(e.target.value); }, checked: endsOn === 'endDate', children: "On" }), _jsx(Form.Item, { name: "endDate", initialValue: (recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.endsOn.endDate)
                                            ? moment(recurringShiftData.endsOn.endDate)
                                            : null, children: _jsx(DatePicker, { value: (recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.endsOn.endDate)
                                                ? moment(recurringShiftData.endsOn.endDate)
                                                : null, disabled: endsOn !== 'endDate' }) })] }), _jsxs(Row, { className: "radioOption endsAfterRadio", children: [_jsx(Radio, { value: "occurrences", onChange: function (e) { return setEndsOn(e.target.value); }, checked: endsOn === 'occurrences', children: "After occurrences" }), _jsx(Form.Item, { name: "occurrences", initialValue: recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.endsOn.occurrences, children: _jsx(InputNumber, { min: 1, value: recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.endsOn.occurrences, disabled: endsOn !== 'occurrences' }) })] })] }) }), _jsx(Form.Item, { name: "createShiftGroup", valuePropName: "checked", style: { marginBottom: '0px' }, children: _jsxs("div", { className: "PopUpOptionsContainer", children: [_jsx(Checkbox, { checked: isGroupState, onChange: function (e) {
                                    onIsGroupChange(e.target.checked);
                                } }), _jsxs("div", { className: "PopUpOptionsText", children: [_jsx("span", { className: "SelectorTitle", children: "Create Shift Group" }), _jsx("div", { className: "PopUpOptionsSub", children: "Shift groups will show up as a single entity for staff to request" })] })] }) }), _jsx(Form.Item, { name: "enforceAssignee", initialValue: (_c = recurringShiftData === null || recurringShiftData === void 0 ? void 0 : recurringShiftData.enforceAssignee) !== null && _c !== void 0 ? _c : false, valuePropName: "checked", children: _jsxs("div", { className: "PopUpOptionsContainer", style: { backgroundColor: '#ecfff9' }, children: [_jsx(Checkbox, {}), _jsxs("div", { className: "PopUpOptionsText", children: [_jsx("span", { className: "SelectorTitle", children: "Enforce Same Assignee" }), _jsx("div", { className: "PopUpOptionsSub", children: "Recurring shifts created will all have the same assignee" })] })] }) })] }));
    }, [endsOn, formInstance, onFormChange, recurringShiftData, isGroupState, onIsGroupChange]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "RecurringShiftBuilder", children: _jsx("div", { className: "FormContent", children: recurringFormContent }) }) }));
};
export default React.memo(RecurringRulePopup);
