var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UploadOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getTagTextColor } from 'src/utils/Collection';
import { UploadFileType } from '../ComponentWithUpload';
import FileUploadPopover from '../TableView/TableCell/FileUploadPopover';
import ZiraTooltip from '../../ui/ZiraTooltip';
import './UserTagWithIcon.scss';
var acceptedFileType = [
    UploadFileType.PNG,
    UploadFileType.JPG,
    UploadFileType.JPEG,
];
var UserAvatar = function (_a) {
    var className = _a.className, name = _a.name, defaultAvatar = _a.avatar, defaultSize = _a.size, defaultIsActive = _a.isActive, defaultBorderRadius = _a.borderRadius, defaultIsCircular = _a.isCirclular, defaultColor = _a.color, uploadProps = _a.uploadProps, children = _a.children, tooltip = _a.tooltip, showEditIcon = _a.showEditIcon;
    var canEdit = useMemo(function () { return uploadProps === null || uploadProps === void 0 ? void 0 : uploadProps.canEdit; }, [uploadProps === null || uploadProps === void 0 ? void 0 : uploadProps.canEdit]);
    var onUploadSuccess = useMemo(function () { return uploadProps === null || uploadProps === void 0 ? void 0 : uploadProps.onUploadSuccess; }, [uploadProps === null || uploadProps === void 0 ? void 0 : uploadProps.onUploadSuccess]);
    var _b = useState(defaultAvatar), avatar = _b[0], setAvatar = _b[1];
    useEffect(function () {
        if (!canEdit && defaultAvatar !== avatar) {
            setAvatar(defaultAvatar);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultAvatar]);
    var size = useMemo(function () { return defaultSize || 24; }, [defaultSize]);
    var isCircular = useMemo(function () { return defaultIsCircular || false; }, [defaultIsCircular]);
    var isActive = useMemo(function () { return defaultIsActive !== false; }, [defaultIsActive]);
    var borderRadius = useMemo(function () { return (defaultBorderRadius ? defaultBorderRadius : isCircular ? size / 2 : 6); }, [defaultBorderRadius, isCircular, size]);
    var color = useMemo(function () { return (avatar ? '#fff' : defaultColor); }, [avatar, defaultColor]);
    var borderColor = useMemo(function () { return defaultColor || '#cccccc'; }, [defaultColor]);
    var lastName = useMemo(function () { return name.split(' ')[1]; }, [name]);
    var avatarProps = useMemo(function () {
        return { src: avatar ? _jsx("img", { src: avatar }) : undefined };
    }, [avatar]);
    var onFileUpload = useCallback(function (files) {
        var newAvatarUrl = files[0].url;
        onUploadSuccess(newAvatarUrl);
        setAvatar(newAvatarUrl);
    }, [onUploadSuccess]);
    var userAvatar = useMemo(function () {
        var fontSize = Math.max(14, size / 2);
        return (_jsx(Avatar, __assign({ className: "".concat(className, " ").concat(!isActive ? 'brightness-75' : '') }, avatarProps, { style: {
                width: size,
                height: size,
                borderRadius: "".concat(borderRadius, "px"),
                backgroundColor: color,
                color: defaultColor ? getTagTextColor(defaultColor) : '#fff',
                lineHeight: "".concat(size, "px"),
                border: "0.5px solid ".concat(borderColor),
                fontSize: "".concat(fontSize, "px"),
            }, icon: showEditIcon &&
                !avatar && (_jsx(UploadOutlined, { style: {
                    marginBottom: '3px',
                    color: 'white',
                    stroke: 'white',
                    fontSize: '16px',
                    strokeWidth: '25',
                } })), children: avatar ? undefined : "".concat(name[0]).concat(lastName ? lastName[0] : '') })));
    }, [
        avatar,
        avatarProps,
        borderColor,
        borderRadius,
        className,
        color,
        defaultColor,
        isActive,
        lastName,
        name,
        showEditIcon,
        size,
    ]);
    return canEdit ? (_jsx(FileUploadPopover, { files: [{ name: '', url: avatar !== null && avatar !== void 0 ? avatar : '', type: 'image/' }], acceptedFileType: acceptedFileType, editFile: onFileUpload, isDetailModal: true, children: _jsx(ZiraTooltip, { title: tooltip !== null && tooltip !== void 0 ? tooltip : "".concat(avatar ? 'Edit' : 'Upload', " Your Avatar Here"), children: userAvatar }) })) : (_jsxs("div", { className: "UserAvatar", children: [userAvatar, children] }));
};
export default React.memo(UserAvatar);
