var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown } from 'antd';
import React, { useCallback } from 'react';
import { disableScroll } from '../utils/Collection';
var ZiraDropdown = function (props) {
    var onLocalVisibleChange = useCallback(function (visible) {
        var _a;
        (_a = props.onLocalVisibleChange) === null || _a === void 0 ? void 0 : _a.call(props, visible);
        disableScroll(visible);
    }, [props]);
    return _jsx(Dropdown, __assign({ onOpenChange: onLocalVisibleChange }, props));
};
export default React.memo(ZiraDropdown);
