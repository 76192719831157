var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
var Option = Select.Option, OptGroup = Select.OptGroup;
import TextArea from 'antd/lib/input/TextArea';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CollectionType } from '../../../shared';
import { AutomationFilterType, MessageAttachmentType, TargetType, } from '../Interface';
import { schemaTypeIsValidForAutomationSendMessage } from '../util';
import Attachments from './Attachments';
import AutomationFilteredTarget from './AutomationFilteredTarget';
import './MessageCard.scss';
import Personalizations from './Personalizations';
var SendSmsForm = function (props) {
    var _a, _b, _c, _d;
    var currentAction = props.currentAction, detail = props.detail, updateAction = props.updateAction, filterOptions = props.filterOptions, baseCollectionId = props.baseCollectionId, allCollections = props.allCollections, roleSchemaId = props.roleSchemaId, locationSchemaId = props.locationSchemaId;
    var targetType = detail.targetType, content = detail.content, targetDetail = detail.targetDetail, attachments = detail.attachments;
    var _e = useState(currentAction), newActionObj = _e[0], setNewActionObj = _e[1];
    var newActionObjRef = useRef(newActionObj);
    var _f = useState(targetType), selectedTargetType = _f[0], setSelectedTargetType = _f[1];
    var _g = useState((_a = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.schemaId) !== null && _a !== void 0 ? _a : null), targetSchemaId = _g[0], setTargetSchemaId = _g[1];
    var _h = useState((_b = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.secondarySchemaId) !== null && _b !== void 0 ? _b : null), targetSecondarySchemaId = _h[0], setTargetSecondarySchemaId = _h[1];
    var _j = useState(content), messageContent = _j[0], setMessageContent = _j[1];
    var _k = useState((_c = targetDetail.filters) !== null && _c !== void 0 ? _c : {
        locations: undefined,
        roles: undefined,
    }), filters = _k[0], setFilters = _k[1];
    var _l = useState((_d = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.phoneNumbers) !== null && _d !== void 0 ? _d : []), phoneNumbers = _l[0], setPhoneNumbers = _l[1];
    var _m = useState(''), newPhoneNumberInput = _m[0], setNewPhoneNumberInput = _m[1];
    var initialBaseCollectionId = useState(baseCollectionId)[0];
    // Reset automation determined target on base collection change
    useEffect(function () {
        if (initialBaseCollectionId &&
            initialBaseCollectionId !== baseCollectionId &&
            selectedTargetType === TargetType.AUTOMATION_DETERMINED) {
            setTargetSchemaId(null);
            setTargetSecondarySchemaId(null);
            var tempActionObj = cloneDeep(newActionObjRef.current);
            var tempActionDetail = tempActionObj.detail;
            tempActionDetail.targetDetail = {
                type: TargetType.AUTOMATION_DETERMINED,
                schemaId: null,
            };
            tempActionObj.detail = tempActionDetail;
            setNewActionObj(tempActionObj);
            updateAction(tempActionObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseCollectionId, initialBaseCollectionId]);
    useEffect(function () {
        var _a;
        if (targetType !== TargetType.HARDCODED) {
            setTargetSchemaId(targetDetail.schemaId);
            setTargetSecondarySchemaId((_a = targetDetail.secondarySchemaId) !== null && _a !== void 0 ? _a : null);
        }
    }, [targetDetail, targetType]);
    var formTargetDetailObj = useCallback(function (newTargetSchemaId, newTargetSecondarySchemaId) {
        switch (selectedTargetType) {
            case TargetType.AUTOMATION_DETERMINED: {
                return {
                    type: TargetType.AUTOMATION_DETERMINED,
                    schemaId: newTargetSchemaId != '' ? newTargetSchemaId : null,
                    secondarySchemaId: newTargetSecondarySchemaId,
                };
            }
            case TargetType.FILTERED_SET: {
                return {
                    type: TargetType.AUTOMATION_DETERMINED,
                    schemaId: newTargetSchemaId,
                    filters: filters,
                };
            }
            case TargetType.HARDCODED: {
                return {
                    phoneNumbers: phoneNumbers || [],
                };
            }
            case TargetType.QUALIFIED_USERS: {
                return {
                    collectionId: baseCollectionId,
                };
            }
        }
    }, [baseCollectionId, filters, phoneNumbers, selectedTargetType]);
    var onTargetTypeChange = useCallback(function (event) {
        setSelectedTargetType(event);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.targetType = event;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onFiltersChange = useCallback(function (newFilters) {
        setFilters(newFilters);
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        var tempTargetDetail = tempActionDetail.targetDetail;
        tempTargetDetail.filters = newFilters;
        tempActionDetail.targetDetail = tempTargetDetail;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onContentChange = useCallback(function (event) {
        setMessageContent(event.target.value);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.content = event.target.value;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var updateContent = useCallback(function (personalization) {
        var newMessageContent = messageContent + personalization.label;
        // add this entry to overall personalizations object and update action object
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.content = newMessageContent;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
        // also update content with label
        setMessageContent(newMessageContent);
    }, [messageContent, newActionObj, updateAction]);
    var updateAttachments = useCallback(function (attachments) {
        // update action detail with form template id
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.attachments = attachments.map(function (attachmentData) {
            return {
                type: MessageAttachmentType.FORM,
                formTemplateId: attachmentData,
            };
        });
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onOptionSelection = useCallback(function (val, elem) {
        setPhoneNumbers([]);
        if (elem.parentSchemaId) {
            setTargetSchemaId(elem.parentSchemaId);
            setTargetSecondarySchemaId(elem.optionId);
        }
        else if (elem.optionId) {
            setTargetSchemaId(elem.optionId);
        }
        else {
            setTargetSchemaId(null);
            setTargetSecondarySchemaId(null);
        }
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.targetDetail = formTargetDetailObj(elem.parentSchemaId ? elem.parentSchemaId : elem.optionId, elem.parentSchemaId ? elem.optionId : null);
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [formTargetDetailObj, newActionObj, updateAction]);
    var onAddPhoneNumber = useCallback(function () {
        setPhoneNumbers(function (prevPhoneNumbers) {
            var tempActionObj = cloneDeep(newActionObj);
            var tempActionDetail = tempActionObj.detail;
            tempActionDetail.targetDetail = {
                phoneNumbers: __spreadArray(__spreadArray([], prevPhoneNumbers, true), [newPhoneNumberInput], false),
            };
            setNewActionObj(tempActionObj);
            updateAction(tempActionObj);
            return __spreadArray(__spreadArray([], prevPhoneNumbers, true), [newPhoneNumberInput], false);
        });
        setNewPhoneNumberInput('');
    }, [newActionObj, newPhoneNumberInput, updateAction]);
    var tagRender = useCallback(function (props) {
        var value = props.value;
        return (_jsx(Tag, { className: "InputTag", onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, color: "#e5f4ef", closable: true, closeIcon: _jsx(CloseOutlined, { className: "CloseIcon" }), onClose: function () {
                var newPhoneNumbers = cloneDeep(phoneNumbers);
                newPhoneNumbers = newPhoneNumbers.filter(function (phoneNumber) { return phoneNumber !== value; });
                setPhoneNumbers(newPhoneNumbers);
                var tempActionObj = cloneDeep(newActionObj);
                var tempActionDetail = tempActionObj.detail;
                tempActionDetail.targetDetail = { phoneNumbers: newPhoneNumbers };
                setNewActionObj(tempActionObj);
                updateAction(tempActionObj);
            }, children: value }));
    }, [newActionObj, phoneNumbers, updateAction]);
    var baseCollectionType = useMemo(function () {
        var _a;
        return (_a = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.id == baseCollectionId; })) === null || _a === void 0 ? void 0 : _a.type;
    }, [allCollections, baseCollectionId]);
    return (_jsxs("div", { className: "messageCard", children: [_jsxs("div", { className: "targetSelectorRow", children: [_jsx("p", { className: "inputLabel", children: "Send To" }), _jsxs(Select, { className: "targetSelector", defaultValue: TargetType.AUTOMATION_DETERMINED, value: selectedTargetType, onChange: function (event) { return onTargetTypeChange(event); }, children: [baseCollectionId && (_jsx(Option, { value: TargetType.AUTOMATION_DETERMINED, children: "Automation Workflow" })), _jsx(Option, { value: TargetType.FILTERED_SET, children: "Specific Group of Users" }), _jsx(Option, { value: TargetType.HARDCODED, children: "Phone Numbers" }), baseCollectionType == CollectionType.SHIFT && (_jsx(Option, { value: TargetType.QUALIFIED_USERS, children: "All Qualified Users" }))] })] }), !!filterOptions.length && selectedTargetType === TargetType.AUTOMATION_DETERMINED && (_jsxs(Select, { className: "attributeSelector", placeholder: "Choose contact attribute", value: targetSecondarySchemaId
                    ? "".concat(targetSchemaId).concat(targetSecondarySchemaId)
                    : (targetSchemaId !== null && targetSchemaId !== void 0 ? targetSchemaId : ''), onSelect: onOptionSelection, children: [(baseCollectionType == CollectionType.USER ||
                        baseCollectionType == CollectionType.CONTACT) && (_jsx(OptGroup, { label: "Default", children: _jsx(Option, { value: '', children: baseCollectionType == CollectionType.USER ? 'User' : 'Contact' }, "default") }, "defaultGroup")), filterOptions
                        .filter(function (group) { return group.type === AutomationFilterType.RECORD; })
                        .map(function (group) {
                        var showGroup = group.schemas.some(function (option) {
                            return schemaTypeIsValidForAutomationSendMessage(option.type);
                        });
                        return (_jsx(_Fragment, { children: showGroup && (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option) {
                                    var _a;
                                    if (schemaTypeIsValidForAutomationSendMessage(option.type)) {
                                        var label = group.parentSchemaId !== null
                                            ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                                            : "".concat(option.name);
                                        return (_jsx(Option, { value: "".concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), optionId: option.id, label: label, parentSchemaId: group.parentSchemaId, children: label }, uuidv4()));
                                    }
                                }) }, uuidv4())) }));
                    })] })), selectedTargetType === TargetType.FILTERED_SET && (_jsx(AutomationFilteredTarget, { filters: filters, onChange: onFiltersChange, roleSchemaId: roleSchemaId, locationSchemaId: locationSchemaId })), selectedTargetType === TargetType.HARDCODED && (_jsx(Select, { className: "HardCodedInput", mode: "multiple", placeholder: "Enter phone numbers", searchValue: newPhoneNumberInput, onSearch: function (value) { return setNewPhoneNumberInput(value); }, onKeyDown: function (e) {
                    if (e.key === 'Enter' &&
                        newPhoneNumberInput &&
                        !phoneNumbers.includes(newPhoneNumberInput)) {
                        onAddPhoneNumber();
                    }
                }, onChange: function (value) {
                    setPhoneNumbers(value);
                    var tempActionObj = cloneDeep(newActionObj);
                    var tempActionDetail = tempActionObj.detail;
                    tempActionDetail.targetDetail = { phoneNumbers: value };
                    setNewActionObj(tempActionObj);
                    updateAction(tempActionObj);
                }, value: phoneNumbers, tagRender: tagRender, notFoundContent: _jsx(_Fragment, { children: newPhoneNumberInput && !phoneNumbers.includes(newPhoneNumberInput) && (_jsx("div", { style: { display: 'flex', flexWrap: 'nowrap' }, children: _jsxs("a", { style: {
                                flex: 'none',
                                display: 'block',
                                cursor: 'pointer',
                                color: 'grey',
                                fontSize: '12px',
                                height: '22px',
                                fontWeight: 500,
                            }, onClick: function () { return onAddPhoneNumber(); }, children: ["Add", ' ', _jsx("span", { style: {
                                        backgroundColor: '#e5f4ef',
                                        fontWeight: 600,
                                        borderRadius: '8px',
                                        height: '22px',
                                        padding: '4px 8px',
                                    }, children: newPhoneNumberInput })] }) })) }) })), _jsx("p", { className: "inputLabel message", children: "Message" }), _jsx(TextArea, { rows: 4, allowClear: true, value: messageContent, onChange: function (event) { return setMessageContent(event.target.value); }, onBlur: function (event) { return onContentChange(event); } }), baseCollectionId && (_jsx("div", { className: "personalizationRow", children: _jsx(Personalizations, { filterOptions: filterOptions, updatePersonalizations: function (personalizations) {
                        return updateContent(personalizations);
                    } }) })), _jsx("div", { className: "personalizationRow", children: _jsx(Attachments, { attachments: attachments, updateAttachments: function (attachments) { return updateAttachments(attachments); } }) })] }));
};
export default React.memo(SendSmsForm);
