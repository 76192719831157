import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
var TaskCompletionStatus = function (_a) {
    var isCompleted = _a.isCompleted;
    return (_jsx("div", { className: "min-w-[36px] min-h-[36px] bg-[#f2f8fa] rounded-full grid place-items-center", children: isCompleted ? (_jsx(CheckCircleFilled, { style: {
                color: '#66CAA9',
                fontSize: 22,
            } })) : (_jsx("div", { className: "w-[22px] h-[22px] rounded-full border-4 border-[#8086F8]" })) }));
};
export default React.memo(TaskCompletionStatus);
