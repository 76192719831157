var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import './ShiftCardDiv.scss';
import { FixedSizeList } from 'react-window';
import { InView } from 'react-intersection-observer';
import { scheduleCardHeightSelector, scheduleCardShouldDelayRenderState, scheduleViewTypeSelector, } from 'src/state';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { ScheduleView } from 'src/services';
import { SHIFT_CARD_HEIGHT_SM, SHIFT_CARD_ROW_GAP, VIRTUAL_LIST_ON_CARD_COUNT } from '../Constant';
import ScheduleCardWrapper from './ScheduleCardWrapper';
var ShiftCardDiv = function (props) {
    var blockId = props.blockId, shiftRIds = props.shiftRIds, tableScrollDiv = props.tableScrollDiv;
    var scrollContainer = (tableScrollDiv === null || tableScrollDiv === void 0 ? void 0 : tableScrollDiv.className) === 'ant-table-body'
        ? tableScrollDiv
        : tableScrollDiv === null || tableScrollDiv === void 0 ? void 0 : tableScrollDiv.querySelector('.ant-table-body');
    var viewType = useRecoilValue(scheduleViewTypeSelector(blockId));
    var cardHeight = useRecoilValue(scheduleCardHeightSelector(blockId));
    var scheduleCardHeight = useMemo(function () { return (viewType === ScheduleView.WEEK ? cardHeight : SHIFT_CARD_HEIGHT_SM); }, [cardHeight, viewType]);
    var renderScheduleCard = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function renderScheduleCard(recordId, index, disableDelayRender, style) {
            return (_jsx(InView, { root: scrollContainer, rootMargin: '300px 300px', initialInView: true, children: function (_a) {
                    var inView = _a.inView, ref = _a.ref;
                    if (!inView && !disableDelayRender) {
                        //! Should reset delay render when it is not in view
                        set(scheduleCardShouldDelayRenderState({ blockId: blockId, recordId: recordId }), true);
                    }
                    return (_jsx(_Fragment, { children: _jsx("div", { ref: ref, style: __assign({ position: 'relative', height: scheduleCardHeight }, style), children: inView ? (_jsx(ScheduleCardWrapper, __assign({}, props, { recordId: recordId, index: index, height: scheduleCardHeight, disableDelayRender: disableDelayRender }))) : null }) }));
                } }));
        };
    }, [blockId, props, scheduleCardHeight, scrollContainer]);
    var scheduleCardScrollList = useCallback(function () { return (_jsx(FixedSizeList, { height: scheduleCardHeight * (VIRTUAL_LIST_ON_CARD_COUNT - 1) +
            (VIRTUAL_LIST_ON_CARD_COUNT - 2) * SHIFT_CARD_ROW_GAP +
            44, itemCount: shiftRIds.length, itemSize: scheduleCardHeight + SHIFT_CARD_ROW_GAP, width: "100%", className: "VirtualCardList", children: function (_a) {
            var index = _a.index, style = _a.style;
            var recordId = shiftRIds[index];
            return renderScheduleCard(recordId, index, true, style);
        } })); }, [renderScheduleCard, scheduleCardHeight, shiftRIds]);
    var scheduleCardList = useCallback(function () {
        return (_jsx(_Fragment, { children: shiftRIds.map(function (recordId, index) {
                return renderScheduleCard(recordId, index);
            }) }));
    }, [renderScheduleCard, shiftRIds]);
    return (_jsx("div", { className: "ShiftCardDiv", style: { rowGap: SHIFT_CARD_ROW_GAP }, children: shiftRIds.length < VIRTUAL_LIST_ON_CARD_COUNT
            ? scheduleCardList()
            : scheduleCardScrollList() }));
};
export default React.memo(ShiftCardDiv);
