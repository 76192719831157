var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Button, Select } from 'antd';
import { useAccountApi, usePageApi } from 'src/hooks/api';
import { CollectionType, ValueDataType } from 'src/shared';
import { ReportSourceIcon } from 'src/utils/icons/ReportSourceIcon';
import { PlusOutlined } from '@ant-design/icons';
import FilterEntry from 'src/components/OptimizedTableView/OptionHeader/Filter/FilterEntry';
import { v4 as uuidv4 } from 'uuid';
var Option = Select.Option;
var ReportSourceCollectionSet = new Set([CollectionType.USER, CollectionType.LOCATION]);
var ReportSourceCard = function (_a) {
    var setReportSource = _a.setReportSource, reportSourceCollectionId = _a.reportSourceCollectionId, sourceFilters = _a.sourceFilters, setSourceFilters = _a.setSourceFilters;
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var collectionOptions = useGetAccountCollectionOptionsQuery().data;
    var reportCollectionOptions = useMemo(function () {
        var _a;
        return ((_a = collectionOptions === null || collectionOptions === void 0 ? void 0 : collectionOptions.filter(function (option) { return ReportSourceCollectionSet.has(option.type); })) !== null && _a !== void 0 ? _a : []);
    }, [collectionOptions]);
    var usePageFilters = usePageApi().usePageFilters;
    var collectionFilters = usePageFilters({
        body: {
            collectionId: reportSourceCollectionId,
            collectionViewId: null,
        },
        options: {
            enabled: !!reportSourceCollectionId,
        },
    }).data;
    var columns = collectionFilters === null || collectionFilters === void 0 ? void 0 : collectionFilters.schemas;
    var initFilterEntryElements = useCallback(function () {
        var tempFilterElements = [];
        sourceFilters === null || sourceFilters === void 0 ? void 0 : sourceFilters.map(function (filter, index) {
            tempFilterElements.push(_jsx(FilterEntry, { existingFilter: filter, columns: columns, onDeleteEntry: function () {
                    var newFilters = __spreadArray([], sourceFilters, true);
                    newFilters.splice(index);
                    setSourceFilters(newFilters);
                }, onEditEntry: function (newEntry) {
                    var newFilters = __spreadArray([], sourceFilters, true);
                    newFilters[index] = newEntry;
                    setSourceFilters(newFilters);
                } }, index));
        });
        return tempFilterElements;
    }, [columns, setSourceFilters, sourceFilters]);
    var onAddNewFilter = useCallback(function () {
        if (!columns)
            return;
        var defaultColumnKey = Object.keys(columns)[0];
        var defaultChosenColumn = columns[defaultColumnKey];
        var columnValues = Object.values(columns);
        var newFilterSetting = {
            id: uuidv4(),
            property: defaultColumnKey,
            clauseType: columnValues.length ? columnValues[0].type : ValueDataType.TEXT,
            clauseValue: defaultChosenColumn.modifiers[0].value,
            filterValue: '',
        };
        setSourceFilters(__spreadArray(__spreadArray([], sourceFilters, true), [newFilterSetting], false));
    }, [columns, setSourceFilters, sourceFilters]);
    return (_jsxs("div", { className: "ReportBuilderCard", children: [_jsx("div", { className: "CardHeader", children: _jsxs("div", { className: "HeaderText", children: [_jsx(ReportSourceIcon, { className: "CardIcon" }), _jsx("div", { className: "HeaderTitle", children: "Report Source" }), _jsx("div", { className: "HeaderSubtitle", children: "Pick the main source of the report" })] }) }), _jsxs("div", { className: "CardContent", children: [_jsx("div", { className: "SelectWrapper", children: _jsx(Select, { showSearch: true, onChange: setReportSource, placeholder: "Select a Report Source", value: reportSourceCollectionId, children: reportCollectionOptions.map(function (option) { return (_jsx(Option, { value: option.id, children: option.name }, option.id)); }) }) }), !!sourceFilters.length && (_jsx("div", { className: "mt-4 w-full pl-2 text-base font-medium", children: "Source Filters" })), columns && initFilterEntryElements(), _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "AddButton", type: "primary", onClick: onAddNewFilter, disabled: !reportSourceCollectionId, children: "Add Filter" })] })] }));
};
export default React.memo(ReportSourceCard);
