var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Select, Space, Tag } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { getTagTextColor } from 'src/utils/Collection';
import ShiftTemplateTimeOverrideModal from './ShiftTemplateTimeOverrideModal';
var formatTime = function (time) {
    var displayTime = moment(time, 'HH:mm').format('h:mma');
    return displayTime.substring(0, displayTime.length - 1);
};
var ShiftTemplateSelectorCell = function (props) {
    var shiftTemplateIds = props.shiftTemplateIds, shiftTemplates = props.shiftTemplates, templateList = props.templateList, updateRecordShiftTemplate = props.updateRecordShiftTemplate, updateDayOfWeekShiftTemplate = props.updateDayOfWeekShiftTemplate, overrideRecordDayOfWeekShiftTemplateTime = props.overrideRecordDayOfWeekShiftTemplateTime, isNeedsTableType = props.isNeedsTableType, selectedShiftTemplateIds = props.selectedShiftTemplateIds, disabled = props.disabled;
    var _a = useState(false), dropdownOpen = _a[0], setDropdownOpen = _a[1];
    var _b = useState(false), isTimeOverrideModalVisible = _b[0], setIsTimeOverrideModalVisible = _b[1];
    //For override time modal
    var _c = useState(), selectedTemplateToOverride = _c[0], setSelectedTemplateToOverride = _c[1];
    var _d = useState(), selectedTemplateName = _d[0], setSelectedTemplateName = _d[1];
    var selectedItemIds = useMemo(function () {
        if (isNeedsTableType) {
            return shiftTemplateIds !== null && shiftTemplateIds !== void 0 ? shiftTemplateIds : [];
        }
        else {
            return (shiftTemplates === null || shiftTemplates === void 0 ? void 0 : shiftTemplates.map(function (template) { return template.templateId; })) || [];
        }
    }, [isNeedsTableType, shiftTemplateIds, shiftTemplates]);
    var isMulti = !isNeedsTableType;
    var availableOptions = useMemo(function () {
        return templateList.map(function (option) {
            return {
                id: option.id,
                text: option.name,
                color: option.color,
            };
        });
    }, [templateList]);
    var onRemoveTag = useCallback(function (id) {
        if (isNeedsTableType && updateRecordShiftTemplate) {
            var selectedIds = selectedItemIds.filter(function (itemId) { return itemId !== id; });
            updateRecordShiftTemplate(selectedIds);
        }
        else {
            var selectedTemplates = shiftTemplates === null || shiftTemplates === void 0 ? void 0 : shiftTemplates.filter(function (template) { return template.templateId !== id; });
            updateDayOfWeekShiftTemplate === null || updateDayOfWeekShiftTemplate === void 0 ? void 0 : updateDayOfWeekShiftTemplate(selectedTemplates || []);
        }
        if (!isMulti) {
            setDropdownOpen(false);
        }
    }, [
        isMulti,
        isNeedsTableType,
        selectedItemIds,
        shiftTemplates,
        updateDayOfWeekShiftTemplate,
        updateRecordShiftTemplate,
    ]);
    var onClearAll = useCallback(function () {
        if (isNeedsTableType && updateRecordShiftTemplate) {
            updateRecordShiftTemplate([]);
        }
        else {
            updateDayOfWeekShiftTemplate === null || updateDayOfWeekShiftTemplate === void 0 ? void 0 : updateDayOfWeekShiftTemplate([]);
        }
    }, [isNeedsTableType, updateDayOfWeekShiftTemplate, updateRecordShiftTemplate]);
    var onSelectAll = useCallback(function () {
        if (isNeedsTableType && updateRecordShiftTemplate) {
            var selectedIds = availableOptions.map(function (option) { return option.id; });
            updateRecordShiftTemplate(selectedIds);
        }
        else {
            if (!shiftTemplates || !updateDayOfWeekShiftTemplate)
                return;
            var selectedTemplates = availableOptions.map(function (option) {
                return {
                    templateId: option.id,
                    dataOverride: undefined,
                };
            });
            updateDayOfWeekShiftTemplate(selectedTemplates);
        }
    }, [
        availableOptions,
        isNeedsTableType,
        shiftTemplates,
        updateDayOfWeekShiftTemplate,
        updateRecordShiftTemplate,
    ]);
    var overrideTimeDisplayText = useCallback(function (templateId) {
        if (isNeedsTableType)
            return '';
        var targetTemplate = shiftTemplates === null || shiftTemplates === void 0 ? void 0 : shiftTemplates.find(function (template) { return template.templateId === templateId; });
        if (targetTemplate === null || targetTemplate === void 0 ? void 0 : targetTemplate.dataOverride) {
            var startTime = formatTime(targetTemplate.dataOverride.startTime);
            var endTime = formatTime(targetTemplate.dataOverride.endTime);
            return "(".concat(startTime, " - ").concat(endTime, ")");
        }
        return '';
    }, [isNeedsTableType, shiftTemplates]);
    var tagRender = useCallback(function (props, openDropdown) {
        var _a, _b, _c;
        var label = props.label;
        var totalOptions = __spreadArray([], availableOptions, true);
        var tagColor = (_a = find(totalOptions, function (item) { return item.id === label; })) === null || _a === void 0 ? void 0 : _a.color;
        var tagText = (_b = find(totalOptions, function (item) { return item.id === label; })) === null || _b === void 0 ? void 0 : _b.text;
        var time = overrideTimeDisplayText(label);
        if (!tagText) {
            return _jsx(_Fragment, {});
        }
        var closable = dropdownOpen;
        var foundTemplate = templateList === null || templateList === void 0 ? void 0 : templateList.find(function (template) { return template.id === label; });
        var currentDataOverride = (_c = shiftTemplates === null || shiftTemplates === void 0 ? void 0 : shiftTemplates.find(function (template) { return template.templateId === label; })) === null || _c === void 0 ? void 0 : _c.dataOverride;
        var targetTemplate = {
            templateId: label,
            dataOverride: currentDataOverride
                ? currentDataOverride
                : foundTemplate
                    ? {
                        startTime: foundTemplate.startTime,
                        endTime: foundTemplate.endTime,
                    }
                    : undefined,
        };
        return (_jsxs("div", { className: "ShiftTemplateTagContainer flex items-center", onClick: openDropdown, children: [_jsx(Tag, { color: tagColor, onMouseDown: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }, className: "selector-tag", closeIcon: _jsx(CloseOutlined, { className: "selector-tag-close" }), closable: closable, onClose: function () { return onRemoveTag(label); }, style: {
                        color: getTagTextColor(tagColor),
                        minHeight: isNeedsTableType ? 'auto' : '37px',
                    }, children: _jsxs("div", { children: [_jsx("span", { children: tagText }), _jsx("br", {}), _jsx("span", { className: "text-xs text-black", children: time })] }) }), !isNeedsTableType && (_jsx(EditOutlined, { className: "TemplateOverrideTimeIcon", onClick: function (e) {
                        e.stopPropagation();
                        setSelectedTemplateToOverride(targetTemplate);
                        setSelectedTemplateName(tagText);
                        setIsTimeOverrideModalVisible(true);
                    } }))] }));
    }, [
        availableOptions,
        dropdownOpen,
        isNeedsTableType,
        onRemoveTag,
        overrideTimeDisplayText,
        shiftTemplates,
        templateList,
    ]);
    var onFilterOption = useCallback(function (input, option) {
        var optionItem = availableOptions.find(function (item) { return item.id === option.value; });
        return optionItem
            ? optionItem.text.toLowerCase().indexOf(input.toLowerCase()) > -1
            : false;
    }, [availableOptions]);
    var notFoundContent = useMemo(function () { return _jsx("span", { children: "No options found" }); }, []);
    var dropdownMenuRender = useCallback(function (menu) { return (_jsxs("div", { onMouseDown: function (e) {
            e.preventDefault();
        }, children: [_jsxs("div", { className: "selector-dropdown-wrapper", children: ["Select an option", isMulti && (_jsxs(Space, { className: "SelectAndClearAllWrapper", size: "large", children: [selectedItemIds.length !== availableOptions.length && (_jsx("span", { className: "SelectAll", onClick: onSelectAll, children: "Select All" })), !!selectedItemIds.length && (_jsx("span", { className: "ClearAll", onClick: onClearAll, children: "Clear All" }))] }))] }), menu] })); }, [availableOptions.length, isMulti, onClearAll, onSelectAll, selectedItemIds.length]);
    var renderTemplateDetails = useCallback(function (template) {
        var startTime = formatTime(template.startTime);
        var endTime = formatTime(template.endTime);
        var location = template.location;
        var roles = template.roles;
        var tags = __spreadArray([location], roles, true);
        return (_jsxs("div", { children: [_jsx("span", { className: "text-[0.625rem] text-gray-400", children: "".concat(startTime, " - ").concat(endTime) }), _jsx(_Fragment, { children: tags.map(function (tag) {
                        if (tag) {
                            return (_jsx("span", { className: "ml-[5px] text-[0.625rem] text-gray-400", children: tag.name }, tag.rid));
                        }
                    }) })] }));
    }, []);
    var renderDropdownOptions = useCallback(function () {
        return templateList.map(function (option) {
            // For need table type, only show the options that are not selected before
            if (!isNeedsTableType ||
                (isNeedsTableType && !selectedShiftTemplateIds.includes(option.id)))
                return (_jsx(Select.Option, { value: option.id, label: option.id, children: _jsx(ZiraTooltip, { title: option.name, children: _jsx("div", { className: "selector-option-wrapper", children: _jsxs("div", { className: "flex w-full justify-between", children: [_jsx("div", { className: "ShiftTemplateOptionName", style: {
                                            backgroundColor: option.color,
                                            color: getTagTextColor(option.color),
                                        }, children: option.name }), _jsx("div", { children: renderTemplateDetails(option) })] }) }) }) }, option.id));
        });
    }, [isNeedsTableType, renderTemplateDetails, selectedShiftTemplateIds, templateList]);
    var onSelectChange = useCallback(function (values) {
        if (isNeedsTableType && updateRecordShiftTemplate) {
            var singleChoiceValue = values.length === 0 ? undefined : values[values.length - 1];
            var selectedIds = singleChoiceValue ? [singleChoiceValue] : [];
            updateRecordShiftTemplate(selectedIds);
        }
        else {
            if (!updateDayOfWeekShiftTemplate || !shiftTemplates)
                return;
            var newSelectedTemplates_1 = [];
            values.map(function (value) {
                var _a;
                var existingTemplate = shiftTemplates.find(function (template) { return template.templateId === value; });
                var dataOverride = (_a = existingTemplate === null || existingTemplate === void 0 ? void 0 : existingTemplate.dataOverride) !== null && _a !== void 0 ? _a : undefined;
                var newTemplate = {
                    templateId: value,
                    dataOverride: dataOverride,
                };
                newSelectedTemplates_1.push(newTemplate);
            });
            updateDayOfWeekShiftTemplate(newSelectedTemplates_1);
        }
    }, [isNeedsTableType, shiftTemplates, updateDayOfWeekShiftTemplate, updateRecordShiftTemplate]);
    var renderSelector = useMemo(function () { return (_jsx(_Fragment, { children: _jsx(Select, { placeholder: isNeedsTableType ? ('Select a shift template') : (_jsx("div", { className: "h-auto rounded border border-gray-60 px-[4px] py-[6px]", children: "No Shift" })), open: dropdownOpen, showSearch: true, bordered: false, placement: "bottomLeft", mode: "multiple", className: "SelectorCellWrapper", popupClassName: "SelectorCellOptionDropdown TemplateSelectorCellOptionDropdown", dropdownMatchSelectWidth: false, optionFilterProp: "children", disabled: disabled, value: selectedItemIds, onChange: onSelectChange, filterOption: onFilterOption, menuItemSelectedIcon: _jsx(_Fragment, {}), tagRender: function (props) { return tagRender(props, function () { return setDropdownOpen(true); }); }, optionLabelProp: "label", notFoundContent: notFoundContent, dropdownRender: dropdownMenuRender, onDropdownVisibleChange: function (open) {
                if (!open) {
                    setDropdownOpen(open);
                }
            }, onClick: function () {
                setDropdownOpen(true);
            }, children: renderDropdownOptions() }) })); }, [
        disabled,
        dropdownMenuRender,
        dropdownOpen,
        isNeedsTableType,
        notFoundContent,
        onFilterOption,
        onSelectChange,
        renderDropdownOptions,
        selectedItemIds,
        tagRender,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "ShiftTemplateSelectorCellWrapper ".concat(selectedItemIds.length === 0 && isNeedsTableType
                    ? 'ShiftTemplateSelectorCellWrapperWithWarning'
                    : '', " ").concat(!isNeedsTableType ? 'SelectorCellWithNoBoarder' : ''), style: disabled
                    ? { pointerEvents: 'none', cursor: 'not-allowed', opacity: 0.5 }
                    : undefined, children: renderSelector }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setDropdownOpen(false);
                }, style: { display: dropdownOpen ? 'block' : 'none' } }), !isNeedsTableType &&
                isTimeOverrideModalVisible &&
                selectedTemplateToOverride &&
                selectedTemplateName &&
                overrideRecordDayOfWeekShiftTemplateTime && (_jsx(ShiftTemplateTimeOverrideModal, { isModalVisible: isTimeOverrideModalVisible, onCloseModal: function () { return setIsTimeOverrideModalVisible(false); }, selectedTemplate: selectedTemplateToOverride, selectedTemplateName: selectedTemplateName, overrideRecordDayOfWeekShiftTemplateTime: overrideRecordDayOfWeekShiftTemplateTime }))] }));
};
export default React.memo(ShiftTemplateSelectorCell);
