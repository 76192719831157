import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Select, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { dropdownListFilterInputResult } from 'src/utils/Collection';
import { SortSettingClause } from '../../../../shared';
import './SortEntry.scss';
var Option = Select.Option;
var SortEntry = React.memo(function (props) {
    var columns = props.columns, existingEntry = props.existingEntry, onDeleteEntry = props.onDeleteEntry, onEditEntry = props.onEditEntry, lockClose = props.lockClose, lockOpen = props.lockOpen, currentGroupBySchemaId = props.currentGroupBySchemaId;
    var _a = useState((existingEntry === null || existingEntry === void 0 ? void 0 : existingEntry.property) === undefined ? Object.keys(columns)[0] : existingEntry.property), columnId = _a[0], setColumnId = _a[1];
    var _b = useState((existingEntry === null || existingEntry === void 0 ? void 0 : existingEntry.order) === undefined ? SortSettingClause.ASC : existingEntry.order), sortClause = _b[0], setSortClause = _b[1];
    var onEditProperty = useCallback(function (property) {
        setColumnId(property);
        onEditEntry({
            id: existingEntry.id,
            property: property,
            order: existingEntry.order,
        });
    }, [existingEntry, onEditEntry]);
    var onEditOrder = useCallback(function (order) {
        setSortClause(order);
        onEditEntry({
            id: existingEntry.id,
            property: existingEntry.property,
            order: order,
        });
    }, [existingEntry, onEditEntry]);
    var onFilterOption = useCallback(function (input, option) {
        var optionItem = Object.values(columns).find(function (item) { return item.id === option.value; });
        return optionItem ? dropdownListFilterInputResult(input, optionItem.name) : false;
    }, [columns]);
    return (_jsxs(Space, { className: "SortEntryWrapper", children: [_jsx(Select, { showSearch: true, value: columnId, className: "SortSelect", dropdownMatchSelectWidth: false, popupClassName: "DropdownSelect", onMouseEnter: lockClose, onMouseLeave: lockOpen, onChange: onEditProperty, filterOption: onFilterOption, children: Object.values(columns).map(function (c) {
                    if (!c.formulaId) {
                        return (_jsx(Option, { value: c.id, disabled: c.id === currentGroupBySchemaId, children: c.name }, c.id));
                    }
                }) }), _jsxs(Select, { value: sortClause, className: "SortSelect", dropdownMatchSelectWidth: 150, popupClassName: "DropdownSelect", onMouseEnter: lockClose, onMouseLeave: lockOpen, onChange: onEditOrder, children: [_jsx(Option, { value: SortSettingClause.ASC, children: "Ascending" }), _jsx(Option, { value: SortSettingClause.DESC, children: "Descending" })] }), _jsx(Button, { className: "DeleteButton", icon: _jsx(DeleteOutlined, {}), onClick: onDeleteEntry })] }));
});
SortEntry.displayName = 'SortEntry';
export default SortEntry;
