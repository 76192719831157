var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DeleteOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0)", children: [_jsx("mask", { id: "path-2-inside-1", fill: "white", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.3333 7.0371H7.66659V16.3704H14.3333V7.0371ZM6.33325 5.70377V17.3704C6.33325 17.5545 6.48249 17.7038 6.66659 17.7038H15.3333C15.5173 17.7038 15.6666 17.5545 15.6666 17.3704V5.70377H6.33325Z" }) }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.3333 7.0371H7.66659V16.3704H14.3333V7.0371ZM6.33325 5.70377V17.3704C6.33325 17.5545 6.48249 17.7038 6.66659 17.7038H15.3333C15.5173 17.7038 15.6666 17.5545 15.6666 17.3704V5.70377H6.33325Z", fill: "currentColor" }), _jsx("path", { d: "M5.5 6.5371V6.20377H16.5V6.5371H5.5Z", fill: "currentColor", stroke: "currentColor" }), _jsx("path", { d: "M12.1667 14.5371V8.87044H12.5001V14.5371H12.1667Z", fill: "currentColor", stroke: "currentColor" }), _jsx("path", { d: "M9.5 14.5371V8.87044H9.83333V14.5371H9.5Z", fill: "currentColor", stroke: "currentColor" }), _jsx("path", { d: "M9.5 5.20377V4.87044H12.5V5.20377H9.5Z", fill: "currentColor", stroke: "currentColor" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0", children: _jsx("rect", { width: "16", height: "16", fill: "white", transform: "translate(3 3.03711)" }) }) })] })); };
export var DeleteOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: DeleteOutlinedSvg }, props))); };
