import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { diffWords } from 'diff';
import './ReviewTaskChangesModal.scss';
import { collectionTableSchemaMapSelector } from 'src/state';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { CollectionType } from 'src/shared';
import { getTagTextColor } from 'src/utils/Collection';
import FileWithIconTag from 'src/components/OptimizedTableView/Cell/FileCell/FileWithIconTag';
import { isEqual } from 'lodash';
var ChangesDiff = function (_a) {
    var differences = _a.differences;
    var taskCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.TASK));
    var schemaMap = useRecoilValue(collectionTableSchemaMapSelector(taskCollectionId !== null && taskCollectionId !== void 0 ? taskCollectionId : ''));
    var oldContent = [];
    var newContent = [];
    var numOfOverallChanges = differences.size;
    if (numOfOverallChanges === 0) {
        oldContent.push(_jsx(_Fragment, { children: _jsx("span", { className: "text-gray-500 text-xs font-medium", children: "No differences found" }) }));
        newContent.push(_jsx(_Fragment, { children: _jsx("span", { className: "text-gray-500 text-xs font-medium", children: "No differences found" }) }));
    }
    Array.from(differences).forEach(function (_a) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        var schemaId = _a[0], collectionCell = _a[1];
        var schemaName = schemaMap[schemaId].name;
        var current = collectionCell.current, template = collectionCell.template;
        var contentDiff = diffWords((_b = template.content) !== null && _b !== void 0 ? _b : '', (_c = current.content) !== null && _c !== void 0 ? _c : '');
        if (!isEqual(current.files, template.files)) {
            oldContent.push(_jsxs(_Fragment, { children: [_jsx("span", { className: "text-gray-400 text-xs font-medium", children: schemaName }), _jsx("div", { className: "flex flex-row gap-1 mt-1 mb-2 flex-wrap", children: ((_d = template.files) === null || _d === void 0 ? void 0 : _d.length) === 0
                            ? '---'
                            : (_e = template.files) === null || _e === void 0 ? void 0 : _e.map(function (file, index) { return (_jsx(FileWithIconTag, { file: file }, index)); }) })] }));
            newContent.push(_jsxs(_Fragment, { children: [_jsx("span", { className: "text-gray-400 text-xs font-medium", children: schemaName }), _jsx("div", { className: "flex flex-row gap-1 mt-1 mb-2 flex-wrap", children: ((_f = current.files) === null || _f === void 0 ? void 0 : _f.length) === 0
                            ? '---'
                            : (_g = current.files) === null || _g === void 0 ? void 0 : _g.map(function (file, index) { return (_jsx(FileWithIconTag, { file: file }, index)); }) })] }));
        }
        else if (!isEqual(current.options, template.options)) {
            oldContent.push(_jsxs(_Fragment, { children: [_jsx("span", { className: "text-gray-400 text-xs font-medium", children: schemaName }), _jsx("div", { className: "flex flex-row gap-1 mt-1 mb-2 flex-wrap", children: ((_h = template.options) === null || _h === void 0 ? void 0 : _h.length) === 0
                            ? '---'
                            : (_j = template.options) === null || _j === void 0 ? void 0 : _j.map(function (opt) { return (_jsx(Tag, { color: opt.color, onMouseDown: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }, className: "selector-tag", closable: false, style: { color: getTagTextColor(opt.color) }, children: opt.name }, opt.id + '_old')); }) })] }));
            newContent.push(_jsxs(_Fragment, { children: [_jsx("span", { className: "text-gray-400 text-xs font-medium", children: schemaName }), _jsx("div", { className: "flex flex-row gap-1 mt-1 mb-2 flex-wrap", children: ((_k = current.options) === null || _k === void 0 ? void 0 : _k.length) === 0
                            ? '---'
                            : (_l = current.options) === null || _l === void 0 ? void 0 : _l.map(function (opt) { return (_jsx(Tag, { color: opt.color, onMouseDown: function (e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }, className: "selector-tag", closable: false, style: { color: getTagTextColor(opt.color) }, children: opt.name }, opt.id + '_new')); }) })] }));
        }
        else if (contentDiff.length > 0) {
            var isNewlyEmpty = !current.content;
            oldContent.push(_jsxs(_Fragment, { children: [_jsx("span", { className: "text-gray-400 text-xs font-medium", children: schemaName }), _jsx("div", { className: "text-gray-800 text-xs font-medium mb-2", children: template.content || '---' })] }));
            newContent.push(_jsxs(_Fragment, { children: [_jsx("span", { className: "text-gray-400 text-xs font-medium", children: schemaName }), _jsx("div", { className: "text-gray-800 text-xs font-medium mb-2", children: isNewlyEmpty
                            ? '---'
                            : contentDiff.map(function (piece, idx) {
                                if (piece.removed)
                                    return null;
                                var className = piece.added
                                    ? "text-[#006745] font-semibold"
                                    : "text-gray-800";
                                return (_jsx("span", { className: className, children: piece.value }, "piece-".concat(idx, "-").concat(piece.value)));
                            }) })] }));
        }
    });
    return (_jsxs("section", { className: "mt-2", children: [_jsx("span", { className: "text-gray-400 uppercase text-[10px] font-semibold", children: "Review Changes" }), _jsxs("div", { className: "grid grid-cols-2 rounded-md mt-2 overflow-hidden", children: [_jsxs("div", { className: "flex flex-col p-3 bg-red-50", children: [_jsx("span", { className: "text-red-500 uppercase text-[10px] font-semibold mb-3", children: "Old" }), oldContent] }), _jsxs("div", { className: "flex flex-col p-3 bg-green-50", children: [_jsx("span", { className: "text-[#006745] uppercase text-[10px] font-semibold mb-2", children: "New" }), newContent] })] })] }));
};
export default React.memo(ChangesDiff);
