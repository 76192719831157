import { getPageGroupExpandedStatus } from 'src/utils/Account';
import { findIndex } from 'lodash';
import { isNaviTreeShowableBlockContentType, TransactionTableNameMap } from '../shared';
var NaviTreeChildColumnName;
(function (NaviTreeChildColumnName) {
    NaviTreeChildColumnName["GROUP"] = "page_ids";
    NaviTreeChildColumnName["PAGE"] = "block_ids";
})(NaviTreeChildColumnName || (NaviTreeChildColumnName = {}));
/**
 * Get table name for transaction
 * @param _pos
 * @returns
 */
var getTableName = function (_pos) {
    switch (_pos.length) {
        case 2:
            return TransactionTableNameMap.PAGE_GROUP;
        case 3:
            return TransactionTableNameMap.PAGE;
        case 4:
            return TransactionTableNameMap.PAGE_BLOCK;
        default:
            break;
    }
};
/**
 * Get table groups info
 * @param _naviTreeData
 * @param _pos
 * @returns
 */
var getTableGroups = function (_naviTreeData, _pos) {
    switch (_pos.length) {
        case 2:
            return _naviTreeData.pageGroups;
        case 3:
            return _naviTreeData.pages;
        case 4:
            return _naviTreeData.blocks;
        default:
            break;
    }
};
/**
 * Get node's child column name
 * @param _pos
 * @returns
 */
var getTableChildColumnName = function (_pos) {
    switch (_pos.length) {
        case 2:
            return NaviTreeChildColumnName.GROUP;
        case 3:
            return NaviTreeChildColumnName.PAGE;
        default:
            break;
    }
};
/**
 * get base tree node data with id, name & table
 * @param _naviTreeData
 * @param _pos
 * @param _nodeKey
 * @returns
 */
var getTableBaseNode = function (_data, _pos, _nodeKey) {
    var tableName = getTableName(_pos);
    var tableGroups = getTableGroups(_data, _pos);
    if (tableGroups === undefined) {
        return undefined;
    }
    var tableNode = tableGroups[_nodeKey];
    return {
        id: tableNode.id,
        name: tableNode.name,
        tableName: tableName,
    };
};
/**
 * generate tree node from response data
 */
var generateTreeNode = function (data) {
    var items = {};
    items['rootId'] = {
        id: 'rootId',
        children: Object.keys(data.pageGroups),
        hasChildren: Object.keys(data.pageGroups).length > 0,
        isExpanded: true,
    };
    if (data) {
        Object.values(data.pageGroups).forEach(function (pageGroup) {
            //TODO: Needs investigation. Got to a state where pageGroup contains a deleted page, so it doesn't show up
            // on the data.page object but is included in pageGroup
            var filteredPageIds = pageGroup.pageIds.filter(function (item) { return !!data.pages[item]; });
            items[pageGroup.id] = {
                id: pageGroup.id,
                children: filteredPageIds,
                hasChildren: filteredPageIds.length > 0,
                isExpanded: getPageGroupExpandedStatus(pageGroup.id),
                data: {
                    name: pageGroup.name,
                    type: 'pageGroup',
                    nodeKeyPaths: [pageGroup.id],
                    titleId: '1',
                    emoji: pageGroup.emoji,
                    iconInfo: pageGroup.iconInfo,
                },
            };
            pageGroup.pageIds.map(function (pageId) {
                var page = data.pages[pageId];
                if (!page)
                    return;
                var blocks = page.blocks;
                if (!blocks)
                    return;
                items[page.id] = {
                    id: page.id,
                    children: blocks.length ? blocks : [page.id + 'dv'],
                    hasChildren: true,
                    isExpanded: false,
                    parentId: pageGroup.id,
                    data: {
                        name: page.name,
                        type: 'page',
                        pageType: page.type,
                        nodeKeyPaths: [pageGroup.id, pageId],
                        titleId: '2',
                        emoji: page.emoji,
                        iconInfo: page.iconInfo,
                    },
                };
                if (!blocks.length) {
                    items[page.id + 'dv'] = {
                        id: page.id + 'dv',
                        children: [],
                        hasChildren: false,
                        isExpanded: false,
                        parentId: page.id,
                        data: {
                            name: 'Default View',
                            type: 'block',
                            nodeKeyPaths: [pageGroup.id, pageId],
                            titleId: '3',
                            contentType: 'collection',
                            isHidden: false,
                        },
                    };
                }
                else {
                    blocks.map(function (blockId) {
                        var block = data.blocks[blockId];
                        if (!block)
                            return;
                        items[block.id] = {
                            id: block.id,
                            hasChildren: false,
                            isExpanded: false,
                            children: [],
                            parentId: page.id,
                            data: {
                                name: block.title,
                                type: 'block',
                                nodeKeyPaths: [pageGroup.id, pageId, blockId],
                                titleId: blockId,
                                contentType: block.contentType,
                                isHidden: !isNaviTreeShowableBlockContentType(block.contentType),
                            },
                        };
                    });
                }
            });
        });
    }
    return { rootId: 'rootId', items: items };
};
/**
 * get node path from node id
 */
var getPathsFromKey = function (data, id) {
    var tree = data;
    var paths = [];
    var groupKeys = Object.keys(tree.pageGroups);
    var pageKeys = Object.keys(tree.pages);
    var collectionKeys = Object.keys(tree.blocks);
    if (findIndex(groupKeys, function (e) { return e === id; }) !== -1) {
        paths.push(tree.pageGroups[id]);
    }
    else if (findIndex(pageKeys, function (e) { return e === id; }) !== -1) {
        groupKeys.every(function (groupKey) {
            if (findIndex(tree.pageGroups[groupKey].pageIds, function (e) { return e === id; }) !== -1) {
                paths.push(tree.pageGroups[groupKey]);
                return false;
            }
            return true;
        });
        paths.push(tree.pages[id]);
    }
    else if (findIndex(collectionKeys, function (e) { return e === id; }) !== -1) {
        // find page
        pageKeys.every(function (pageKey) {
            if (findIndex(tree.pages[pageKey].blocks, function (e) { return e === id; }) !== -1) {
                paths.unshift(tree.blocks[id]);
                paths.unshift(tree.pages[pageKey]);
                return false;
            }
            return true;
        });
        groupKeys.every(function (groupKey) {
            // find group
            if (findIndex(tree.pageGroups[groupKey].pageIds, function (e) { return e === paths[0].id; }) !== -1) {
                paths.unshift(tree.pageGroups[groupKey]);
                return false;
            }
            return true;
        });
    }
    return paths;
};
export { getTableName, getTableGroups, getTableChildColumnName, getTableBaseNode, generateTreeNode, getPathsFromKey, };
