var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { CollectionTableType, CollectionType } from 'src/shared';
import { useRecordComponent } from 'src/hooks/component';
import { useTasksApi } from 'src/hooks/api/useTasksApi';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ProfileTaskSchemaType, TaskTemplateType, } from '../../interface';
import { NEW_TASK_TEMPLATE_ID } from '../../constant';
import { isTaskSchemaRequired } from '../util';
import { useTaskProfileDataSchemas } from '../../hooks/useTaskProfileDataSchemas';
import EmptyRequiredDataContent from './EmptyRequiredDataContent';
import ProfileDataCollectionSelector from './ProfileDataCollectionSelector';
import AddFieldDropdown from './AddFieldDropdown';
import ProfileDataTaskSchemaRow from './ProfileDataTaskSchemaRow';
var ProfileDataTypeTaskSettingSection = function (_a) {
    var selectedTaskId = _a.selectedTaskId, profileData = _a.profileData, updateProfileData = _a.updateProfileData;
    var useGetUserReverseCollections = useTasksApi().useGetUserReverseCollections;
    var editRecordCell = useRecordComponent().editRecordCell;
    var allCollections = useGetUserReverseCollections().data;
    var selectedCollectionId = profileData.collectionId;
    var selectedSchemaId = profileData.schemaId;
    var collectionInfo = useMemo(function () {
        if (!allCollections || !selectedCollectionId)
            return undefined;
        var collection = allCollections.reverseCollections.find(function (collection) { return collection.collectionId === selectedCollectionId; });
        if (!collection)
            return undefined;
        var collectionInfo = {
            id: collection.collectionId,
            name: collection.title,
            type: collection.collectionType,
        };
        return collectionInfo;
    }, [allCollections, selectedCollectionId]);
    var dataSource = useMemo(function () { return ({
        type: CollectionTableType.TASK_TEMPLATE_MODAL,
        collectionId: selectedCollectionId !== null && selectedCollectionId !== void 0 ? selectedCollectionId : '',
    }); }, [selectedCollectionId]);
    var isCreatingNew = selectedTaskId === NEW_TASK_TEMPLATE_ID;
    var _b = useTaskProfileDataSchemas({
        selectedTaskId: selectedTaskId,
        collectionId: selectedCollectionId,
        schemaId: selectedSchemaId,
        collectionInfo: collectionInfo,
        dataSource: dataSource,
        cells: profileData.cells,
        taskSchemas: profileData.taskSchemas,
        onUpdateCells: updateProfileData,
        onUpdateTaskSchemas: updateProfileData,
    }), schemas = _b.schemas, schemasFetched = _b.schemasFetched, recordData = _b.recordData, onUpdateTaskSchemaType = _b.onUpdateTaskSchemaType, onRemoveTaskSchema = _b.onRemoveTaskSchema, onDragTaskSchemaEnd = _b.onDragTaskSchemaEnd;
    var taskSchemas = useMemo(function () { var _a; return (_a = profileData === null || profileData === void 0 ? void 0 : profileData.taskSchemas) !== null && _a !== void 0 ? _a : []; }, [profileData === null || profileData === void 0 ? void 0 : profileData.taskSchemas]);
    var selectedSchemaIds = useMemo(function () { var _a; return (_a = taskSchemas === null || taskSchemas === void 0 ? void 0 : taskSchemas.map(function (schema) { return schema.schemaId; })) !== null && _a !== void 0 ? _a : []; }, [taskSchemas]);
    var availableSchemas = useMemo(function () {
        if (!schemas) {
            return [];
        }
        else {
            // Filter out user relation schema
            return schemas.columns.filter(function (schema) { return schema.id !== (profileData === null || profileData === void 0 ? void 0 : profileData.schemaId) && schema.canEdit; });
        }
    }, [profileData === null || profileData === void 0 ? void 0 : profileData.schemaId, schemas]);
    var updateTaskSchemas = useCallback(function (newTaskSchemas) {
        updateProfileData({
            taskSchemas: newTaskSchemas,
        });
    }, [updateProfileData]);
    //! Pre-fill required fields for new profile data tasks
    useEffect(function () {
        // Only pre-fill required fields for new tasks for easier state management
        // All profile data tasks create a record (except user profile update; no selectedSchemaId), so we need to add required fields
        if (isCreatingNew && availableSchemas && selectedSchemaId) {
            var requiredSchemas = availableSchemas.filter(function (schema) { return isTaskSchemaRequired(schema) && schema.id !== selectedSchemaId; });
            updateTaskSchemas(requiredSchemas.map(function (schema) { return ({
                schemaId: schema.id,
                taskSchemaType: ProfileTaskSchemaType.REQUIRED,
            }); }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableSchemas, selectedSchemaId]);
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(CollectionTableType.TASK_TEMPLATE_MODAL, cellPos, newValues);
    }, [editRecordCell]);
    var onAddNewTaskSchema = useCallback(function (schemaId) {
        updateTaskSchemas(taskSchemas.concat({
            schemaId: schemaId,
            taskSchemaType: ProfileTaskSchemaType.REQUIRED,
        }));
    }, [taskSchemas, updateTaskSchemas]);
    return (_jsx("div", { className: "ProfileDataSettingContent", children: _jsxs("div", { children: [_jsx(ProfileDataCollectionSelector, { reverseCollections: allCollections === null || allCollections === void 0 ? void 0 : allCollections.reverseCollections, selectedCollectionId: selectedCollectionId, selectedSchemaId: selectedSchemaId, updateProfileData: updateProfileData }), selectedCollectionId ? (_jsx("div", { className: "CollectionColumnsSection", children: _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Form Setup" }), _jsx(DragDropContext, { onDragEnd: onDragTaskSchemaEnd, children: _jsx(Droppable, { droppableId: "list", children: function (provided) { return (_jsxs("div", __assign({ ref: provided.innerRef }, provided.droppableProps, { children: [taskSchemas.map(function (taskSchema, index) {
                                                var schema = availableSchemas.find(function (schema) { return schema.id === taskSchema.schemaId; });
                                                if (schema) {
                                                    return (_jsx(ProfileDataTaskSchemaRow, { taskType: TaskTemplateType.PROFILE_DATA, taskSchema: taskSchema, schema: schema, recordData: recordData, dataSource: dataSource, selectedCollectionId: selectedCollectionId, isUserProfile: (collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.type) ===
                                                            CollectionType.USER &&
                                                            !selectedSchemaId, index: index, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onUpdateTaskSchemaType: onUpdateTaskSchemaType, onRemoveTaskSchema: onRemoveTaskSchema }, schema.id));
                                                }
                                                else {
                                                    if (schemasFetched && !!schemas) {
                                                        // Remove deleted/invalid schema from profile data task schemas
                                                        onRemoveTaskSchema(taskSchema.schemaId);
                                                    }
                                                    //! Hidden draggable so correct index is preserved
                                                    return (_jsx(Draggable, { draggableId: taskSchema.schemaId, index: index, children: function (provided) { return (_jsx("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }))); } }, taskSchema.schemaId));
                                                }
                                            }), provided.placeholder] }))); } }) }), _jsx(AddFieldDropdown, { availableSchemas: availableSchemas, selectedSchemaIds: selectedSchemaIds, recordData: recordData, selectedCollectionId: selectedCollectionId, dataSource: dataSource, dataSourceRid: selectedTaskId, onEditModalCell: onEditModalCell, onAddNewTaskSchema: onAddNewTaskSchema })] }) })) : (_jsx(EmptyRequiredDataContent, {}))] }) }));
};
export default React.memo(ProfileDataTypeTaskSettingSection);
