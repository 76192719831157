var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PasteIconSvg = function () { return (_jsx("svg", { width: "12", height: "10", viewBox: "0 0 12 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.92969 3.33301V1.04134C3.92969 0.926282 4.03229 0.833008 4.15885 0.833008H10.1172C10.2438 0.833008 10.3464 0.926282 10.3464 1.04134V6.45801C10.3464 6.57307 10.2438 6.66634 10.1172 6.66634H7.59635V8.95801C7.59635 9.07307 7.49375 9.16634 7.36719 9.16634H1.40885C1.28229 9.16634 1.17969 9.07307 1.17969 8.95801V3.54134C1.17969 3.42628 1.28229 3.33301 1.40885 3.33301H3.92969ZM4.84635 3.33301V1.66634H9.42969V5.83301H7.59635V3.54134C7.59635 3.42628 7.49375 3.33301 7.36719 3.33301H4.84635ZM3.92969 4.16634V6.45801C3.92969 6.57307 4.03229 6.66634 4.15885 6.66634H5.53385C5.66042 6.66634 5.76302 6.57307 5.76302 6.45801V6.04134C5.76302 5.92628 5.66042 5.83301 5.53385 5.83301H4.84635V4.16634H6.67969V8.33301H2.09635V4.16634H3.92969Z", fill: "black" }) })); };
export var PasteIcon = function (props) { return (_jsx(Icon, __assign({ component: PasteIconSvg }, props))); };
