export var PayMetricValueType;
(function (PayMetricValueType) {
    PayMetricValueType["TOTAL_PAID"] = "TOTAL_PAID";
    PayMetricValueType["TOTAL_BILLED"] = "TOTAL_BILLED";
    PayMetricValueType["CURRENT_PAID"] = "CURRENT_PAID";
    PayMetricValueType["CURRENT_BILLED"] = "CURRENT_BILLED";
    PayMetricValueType["NONE"] = "NONE";
})(PayMetricValueType || (PayMetricValueType = {}));
export var ShiftMetricValueType;
(function (ShiftMetricValueType) {
    ShiftMetricValueType["OPEN_SHIFTS"] = "OPEN_SHIFTS";
    ShiftMetricValueType["SHIFT_REQUESTS"] = "SHIFT_REQUESTS";
})(ShiftMetricValueType || (ShiftMetricValueType = {}));
export var PayMetricValueOptions = [
    {
        value: PayMetricValueType.TOTAL_PAID,
        displayValue: 'Last Payroll Amount',
    },
    {
        value: PayMetricValueType.CURRENT_PAID,
        displayValue: 'Current Payroll Amount',
    },
    {
        value: PayMetricValueType.TOTAL_BILLED,
        displayValue: 'Last Billed Amount',
    },
    {
        value: PayMetricValueType.CURRENT_BILLED,
        displayValue: 'Current Billed Amount',
    },
];
export var ShiftMetricValueOptions = [
    {
        value: ShiftMetricValueType.OPEN_SHIFTS,
        displayValue: 'Open Shifts',
    },
    {
        value: ShiftMetricValueType.SHIFT_REQUESTS,
        displayValue: 'Shift Requests',
    },
];
