import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import RatioNumberInput from './RatioNumberInput';
import RoleSelector from './RoleSelector';
var RatioSettingRow = function (_a) {
    var rowData = _a.rowData, roleList = _a.roleList, selectedRoles = _a.selectedRoles, deleteRatioSettingRow = _a.deleteRatioSettingRow, updateSelectedRole = _a.updateSelectedRole, updateRatioNumber = _a.updateRatioNumber;
    return (_jsxs("div", { className: "mt-2 RatioSettingRowContainer", children: [_jsx(RoleSelector, { roleId: rowData.roleRid, roleList: roleList, selectedRoles: selectedRoles, updateSelectedRole: updateSelectedRole }), _jsx(RatioNumberInput, { value: rowData.data, updateRatioNumber: updateRatioNumber }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this ratio?", onConfirm: function () {
                    deleteRatioSettingRow(rowData.roleRid);
                }, placement: "leftBottom", children: _jsx(ZiraTooltip, { title: "Delete", children: _jsx("div", { className: "RatioRowDeleteBtn", children: _jsx(DeleteOutlinedIcon, { fill: "red" }) }) }) })] }));
};
export default React.memo(RatioSettingRow);
