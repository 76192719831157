import { useMutation } from 'react-query';
import { useFetch } from './useFetch';
var useSessionApi = function () {
    var request = useFetch().request;
    var useSaveSessionMutation = function () {
        return useMutation(function (body) {
            return request({
                method: 'POST',
                url: 'app_sessions/create',
            }, body);
        });
    };
    // !MARK: pass sessionId here as param to for async work
    var deleteAppSession = function (sessionId) {
        return request({ method: 'DELETE', url: "app_sessions/".concat(sessionId) });
    };
    return {
        useSaveSessionMutation: useSaveSessionMutation(),
        deleteAppSession: deleteAppSession,
    };
};
export { useSessionApi };
