var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Breadcrumb } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useSetting } from 'src/hooks/api';
import { CompliancePageState } from '../../../hooks/state/Compliance';
import JobCodeImg from '../../../utils/icons/images/settings/JobCode.png';
import JobCodeNoBgImg from '../../../utils/icons/images/settings/JobCodeNoBg.png';
import './JobCodesPage.scss';
import SettingsContentHeader from '../SettingsContentHeader';
import JobCodeEntry from './JobCodeEntry';
var JobCodesPage = function () {
    var _a = useSetting(), enableProjectTracking = _a.enableProjectTracking, disableProjectTracking = _a.disableProjectTracking, useGetProjectTrackingSetting = _a.useGetProjectTrackingSetting, useGetProjectTrackingProjects = _a.useGetProjectTrackingProjects, useCreateProject = _a.useCreateProject, useDeleteProject = _a.useDeleteProject, useUpdateProjectName = _a.useUpdateProjectName;
    var _b = useGetProjectTrackingSetting(), projectTrackingEnableStatus = _b.data, isLoading = _b.isLoading, refetchEnableStatus = _b.refetch;
    var isProjectTrackingOn = !!projectTrackingEnableStatus;
    var _c = useGetProjectTrackingProjects({
        options: { enabled: !!projectTrackingEnableStatus },
    }), projects = _c.data, refetch = _c.refetch;
    var handleCreateJobCode = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useCreateProject.mutateAsync({
                        projectRid: uuidv4(),
                        name: 'New Job Code',
                    })];
                case 1:
                    _a.sent();
                    refetch();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var onProjectDelete = useCallback(function (projectId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useDeleteProject.mutateAsync(projectId)];
                case 1:
                    _a.sent();
                    refetch();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var onProjectNameUpdate = useCallback(function (projectId, newName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useUpdateProjectName.mutateAsync({
                        projectId: projectId,
                        name: newName,
                    })];
                case 1:
                    _a.sent();
                    refetch();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    if (isLoading)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Job Codes" }) }), _jsx("div", { className: "SettingsPageContainer", children: _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [!isProjectTrackingOn && (_jsxs("div", { className: "SettingsHeroWrapper", children: [_jsxs("div", { className: "SettingsHeroTextWrapper", children: [_jsx("div", { className: "SettingsHeroHeader", children: "Set up job codes for your team" }), _jsxs("div", { className: "SettingsHeroButtonWrapper", children: [_jsx("div", { className: "SettingsGrayButton", children: "Learn More" }), _jsx("div", { className: "SettingsBlackButton", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, enableProjectTracking()];
                                                                case 1:
                                                                    _a.sent();
                                                                    refetchEnableStatus();
                                                                    refetch();
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); }, children: "Turn On" })] })] }), _jsx("img", { className: "SettingsImg", src: JobCodeImg })] })), isProjectTrackingOn && (_jsxs("div", { className: "MainSettings", children: [_jsxs("div", { className: "WelcomeToSettings mb-5", children: [_jsxs("div", { className: "SettingsHeroTextWrapper", children: [_jsx("div", { className: "SettingsHeroHeaderSm", children: "Set up job codes for your team" }), _jsxs("div", { className: "SettingsHeroButtonWrapper", children: [_jsx("div", { className: "SettingsGrayButton", children: "Learn More About Job Codes" }), _jsx("div", { className: "SettingsGrayButton", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                                return __generator(this, function (_a) {
                                                                    switch (_a.label) {
                                                                        case 0: return [4 /*yield*/, disableProjectTracking()];
                                                                        case 1:
                                                                            _a.sent();
                                                                            refetchEnableStatus();
                                                                            return [2 /*return*/];
                                                                    }
                                                                });
                                                            }); }, children: "Turn off Job Codes" })] })] }), _jsx("img", { className: "SettingsImg", src: JobCodeNoBgImg })] }), projects && (_jsxs("div", { className: "SettingsBody", children: [_jsx("div", { className: "SettingsHeroHeaderSm", children: "Job Codes" }), _jsx("div", { className: "SettingsHeroSubText", children: "Add job codes for your account." }), _jsxs("div", { className: "ProjectsWrapper", children: [_jsx("div", { className: "ProjectColHeader", children: "Job Code Name" }), projects.map(function (project) {
                                                    return (_jsx(JobCodeEntry, { project: project, deleteProject: function () {
                                                            return onProjectDelete(project.id);
                                                        }, updateName: onProjectNameUpdate }, project.id));
                                                }), _jsx("div", { className: "AddJobCodeBtn", onClick: handleCreateJobCode, children: "Add Job Code" })] })] }))] }))] }) })] }));
};
var JobCodesPageContainer = function () {
    return (_jsx(CompliancePageState.Provider, { children: _jsx(JobCodesPage, {}) }));
};
export default React.memo(JobCodesPageContainer);
