var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import pluralize from 'pluralize';
import { useFetch } from '.';
var useJobPlacementApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var useGetPropagateJobTasksFromPlacementInfo = function (request) {
        return useQuery(['getPropagateJobTasksFromPlacementInfo', JSON.stringify(request.body)], function () {
            return customFetch({
                method: 'POST',
                url: 'jobs/propagate_job_tasks_from_placement_info',
            }, request.body);
        }, __assign({}, request.options));
    };
    var propagateJobTasksFromPlacement = function (request, jobCount) {
        return toastRequest({
            method: 'POST',
            url: 'jobs/propagate_job_tasks_from_placement',
        }, request, undefined, "Successfuly published changes to ".concat(jobCount, " ").concat(pluralize('job', jobCount)));
    };
    return {
        useGetPropagateJobTasksFromPlacementInfo: useGetPropagateJobTasksFromPlacementInfo,
        propagateJobTasksFromPlacement: propagateJobTasksFromPlacement,
    };
};
export { useJobPlacementApi };
