import { jsx as _jsx } from "react/jsx-runtime";
import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
var TaskMenuPopover = function (_a) {
    var children = _a.children, content = _a.content, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    useEffect(function () {
        setOpen(isOpen);
    }, [isOpen]);
    return (_jsx(Popover, { content: _jsx("div", { onClick: function (e) {
                e.stopPropagation();
                setOpen(false);
            }, children: content }), trigger: ['click'], placement: "bottomRight", overlayClassName: "TaskOptionsMenu", open: open, onOpenChange: setOpen, children: children }));
};
export default React.memo(TaskMenuPopover);
