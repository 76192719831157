import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ZiraVisibilityDropdownVisibleItem, ZiraVisibilityDropdownToggleItem, ZiraVisibilityDropdown, } from 'src/ui/ZiraVisibilityDropdown';
import { usePageApi } from 'src/hooks/api';
import { useModalRecordComponent } from 'src/hooks/component/useModalRecord';
import { visibleSchemaColumnsInDetailModalSiderState } from '../Schemas/atom';
import { allAvailableDetailModalSchemasInSiderSelector } from '../Schemas/selector';
var ConfigureMetricsDropdown = function (_a) {
    var collectionId = _a.collectionId, collectionType = _a.collectionType, visibleMetrics = _a.visibleMetrics, refetchMetrics = _a.refetchMetrics;
    var _b = usePageApi(), useGetApplicableRecordMetrics = _b.useGetApplicableRecordMetrics, useEnableRecordDetailMetric = _b.useEnableRecordDetailMetric, useDisableRecordDetailMetric = _b.useDisableRecordDetailMetric;
    var detailModalSiderSchemasVisibleOnChange = useModalRecordComponent().detailModalSiderSchemasVisibleOnChange;
    var allAvailableDetailModalSchemas = useRecoilValue(allAvailableDetailModalSchemasInSiderSelector({ collectionId: collectionId, collectionType: collectionType }));
    var visibleSchemas = useRecoilValue(visibleSchemaColumnsInDetailModalSiderState(collectionId));
    var applicableRecordMetrics = useGetApplicableRecordMetrics({
        collectionId: collectionId,
        options: {
            enabled: !!collectionId,
        },
    }).data;
    var onRecordMetricToggle = useCallback(function (isToggledOn, metricName) {
        if (isToggledOn) {
            useEnableRecordDetailMetric.mutate({ collectionId: collectionId, metricName: metricName }, {
                onSuccess: function () {
                    refetchMetrics();
                },
            });
        }
        else if (!isToggledOn) {
            useDisableRecordDetailMetric.mutate({ collectionId: collectionId, metricName: metricName }, {
                onSuccess: function () {
                    refetchMetrics();
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionId, visibleMetrics]);
    var onRecordSchemaColumnToggle = useCallback(function (isToggledOn, schemaId) {
        var request = {
            collectionId: collectionId,
            schemaId: schemaId,
        };
        if (isToggledOn) {
            useEnableRecordDetailMetric.mutate(request, {
                onSuccess: function () {
                    detailModalSiderSchemasVisibleOnChange(collectionId, schemaId, true);
                },
            });
        }
        else if (!isToggledOn) {
            useDisableRecordDetailMetric.mutate(request, {
                onSuccess: function () {
                    detailModalSiderSchemasVisibleOnChange(collectionId, schemaId, false);
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionId]);
    var visibleSchemasAndMetricsContent = useMemo(function () {
        return (_jsxs("div", { className: "flex w-full flex-col space-y-3 p-4", children: [allAvailableDetailModalSchemas.map(function (_a) {
                    var id = _a.id, name = _a.name;
                    var foundSchema = visibleSchemas.find(function (schemaId) { return schemaId === id; });
                    var isVisible = !!foundSchema;
                    if (isVisible) {
                        return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: name, onToggleOffItem: function () { return onRecordSchemaColumnToggle(false, id); } }, id));
                    }
                }), visibleMetrics.map(function (_a) {
                    var displayName = _a.displayName, internalName = _a.internalName;
                    return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: displayName, onToggleOffItem: function () { return onRecordMetricToggle(false, internalName); } }, internalName));
                })] }));
    }, [
        allAvailableDetailModalSchemas,
        onRecordMetricToggle,
        onRecordSchemaColumnToggle,
        visibleMetrics,
        visibleSchemas,
    ]);
    var allMetricsContent = useMemo(function () {
        return (_jsxs("div", { className: "flex w-full flex-col", children: [allAvailableDetailModalSchemas.map(function (_a) {
                    var id = _a.id, name = _a.name;
                    var foundSchema = visibleSchemas.find(function (schemaId) { return schemaId === id; });
                    var isVisible = !!foundSchema;
                    return (_jsx(ZiraVisibilityDropdownToggleItem, { title: name, isVisible: isVisible, onVisibilityToggled: function (visible) {
                            return onRecordSchemaColumnToggle(visible, id);
                        } }, id));
                }), applicableRecordMetrics === null || applicableRecordMetrics === void 0 ? void 0 : applicableRecordMetrics.applicableMetrics.map(function (_a) {
                    var displayName = _a.displayName, internalName = _a.internalName;
                    var foundMetric = visibleMetrics.find(function (metric) { return metric.internalName === internalName; });
                    var isVisible = !!foundMetric;
                    return (_jsx(ZiraVisibilityDropdownToggleItem, { title: displayName, isVisible: isVisible, onVisibilityToggled: function (isToggledOn) {
                            return onRecordMetricToggle(isToggledOn, internalName);
                        } }, internalName));
                })] }));
    }, [
        allAvailableDetailModalSchemas,
        applicableRecordMetrics === null || applicableRecordMetrics === void 0 ? void 0 : applicableRecordMetrics.applicableMetrics,
        onRecordMetricToggle,
        onRecordSchemaColumnToggle,
        visibleMetrics,
        visibleSchemas,
    ]);
    return (_jsx(ZiraVisibilityDropdown, { tabTitles: ['Visible Metrics', 'All Metrics'], tabContents: [visibleSchemasAndMetricsContent, allMetricsContent], children: _jsx(Button, { className: "light-gray-btn text-sm font-medium", children: "Configure Metrics" }) }));
};
export default React.memo(ConfigureMetricsDropdown);
