var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { selectorFamily } from 'recoil';
import { collectionTableRecordState, collectionTableState, } from 'src/state';
/** Record ID of detail/quick-create modal */
var collectionTableModalRecordIdSelector = selectorFamily({
    key: 'collectionTableModalRecordIdSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var collectionTableData = get(collectionTableState(dataSource));
            var rows = (collectionTableData !== null && collectionTableData !== void 0 ? collectionTableData : {}).rows;
            return rows ? rows[0].recordId : undefined;
        };
    },
});
/** Collection record of detail/quick-create modal */
var collectionTableModalRecordSelector = selectorFamily({
    key: 'collectionTableModalRecordSelector',
    get: function (dataSource) {
        return function (_a) {
            var get = _a.get;
            var recordId = get(collectionTableModalRecordIdSelector(dataSource));
            if (!recordId)
                return {};
            var collectionRecord = get(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: recordId })));
            return collectionRecord !== null && collectionRecord !== void 0 ? collectionRecord : {};
        };
    },
});
export { collectionTableModalRecordIdSelector, collectionTableModalRecordSelector };
