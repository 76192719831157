import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TASKS_OVERVIEW_HEADER_HEIGHT } from '../../constant';
import { TaskTab } from '../../interface';
import { taskEditorActiveTabState } from '../../atom';
import TaskRuleList from '../TaskRules/TaskRuleList';
import TaskTemplateListV2 from './TaskTemplateListV2';
import TaskGroupListV2 from './TaskGroupListV2';
var TasksSettingLeftNav = function () {
    var activeTab = useRecoilValue(taskEditorActiveTabState);
    return (_jsxs("div", { className: "relative flex flex-col w-full overflow-auto border-r border-gray-60 pb-[64px]", style: {
            height: "calc(100vh - ".concat(TASKS_OVERVIEW_HEADER_HEIGHT, "px"),
        }, children: [activeTab === TaskTab.TEMPLATES && _jsx(TaskTemplateListV2, {}), activeTab === TaskTab.GROUPS && _jsx(TaskGroupListV2, {}), activeTab === TaskTab.RULES && _jsx(TaskRuleList, {})] }));
};
export default React.memo(TasksSettingLeftNav);
