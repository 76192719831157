import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Select } from 'antd';
import { CollectionType } from 'src/shared';
import { TaskTemplateType } from '../../interface';
var ProfileDataCollectionSelector = function (_a) {
    var reverseCollections = _a.reverseCollections, selectedCollectionId = _a.selectedCollectionId, selectedSchemaId = _a.selectedSchemaId, updateProfileData = _a.updateProfileData;
    var filteredReverseCollections = useMemo(function () {
        var _a;
        return (_a = reverseCollections === null || reverseCollections === void 0 ? void 0 : reverseCollections.filter(function (_a) {
            var collectionType = _a.collectionType;
            return collectionType !== CollectionType.TASK;
        })) !== null && _a !== void 0 ? _a : [];
    }, [reverseCollections]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "TaskSectionLabel mt-4", children: "Profile Data" }), _jsx(Select, { className: "TaskDetailModalSelector", placeholder: "Select Required Data Type", 
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange: function (_, option) {
                    if (!option)
                        return;
                    updateProfileData({
                        type: TaskTemplateType.PROFILE_DATA,
                        collectionId: option.collectionId,
                        schemaId: option.schemaId,
                        cells: {},
                        requiredSchemaIds: [],
                        preSaveFields: [],
                        taskSchemas: [],
                    });
                }, value: selectedCollectionId ? "".concat(selectedCollectionId, "-").concat(selectedSchemaId) : null, showSearch: true, filterOption: function (input, option) { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.children) !== null && _a !== void 0 ? _a : '').toLowerCase().includes(input.trim().toLowerCase()); }, children: filteredReverseCollections === null || filteredReverseCollections === void 0 ? void 0 : filteredReverseCollections.map(function (collection) { return (_jsx(Select.Option, { value: "".concat(collection.collectionId, "-").concat(collection.schemaId), collectionId: collection.collectionId, schemaId: collection.schemaId, children: collection.title }, "".concat(collection.collectionId, "-").concat(collection.schemaId))); }) })] }));
};
export default React.memo(ProfileDataCollectionSelector);
