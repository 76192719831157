import { atom, atomFamily } from 'recoil';
var shiftTemplateListRespState = atomFamily({
    key: 'shiftTemplateListRespState',
    default: undefined,
});
var selectedTemplateIdForDeleteState = atom({
    key: 'selectedTemplateIdForDeleteState',
    default: undefined,
});
export { shiftTemplateListRespState, selectedTemplateIdForDeleteState };
