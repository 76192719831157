import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormulaOperators } from 'src/shared';
import { useFormulaApi } from 'src/hooks/api/useFormulaApi';
import { collectionTableSchemaMapSelector } from 'src/state';
import { schemaFormulaState } from 'src/state/atoms/schemaState';
import './FormulaModal.scss';
var FormulaModal = function (props) {
    var schemaId = props.schemaId, formulaId = props.formulaId, isClicked = props.isClicked, setIsClicked = props.setIsClicked, collectionId = props.collectionId;
    var useUpdateFormula = useFormulaApi().useUpdateFormula;
    var useUpdateFormulaMutation = useUpdateFormula();
    var schemasMap = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var formula = useRecoilValue(schemaFormulaState(schemaId));
    var _a = useState(''), formulaString = _a[0], setFormulaString = _a[1];
    var parseExpressionForDisplay = useCallback(function (data) {
        var formulaStr = '';
        var schemaIds = Object.keys(schemasMap);
        data === null || data === void 0 ? void 0 : data.expression.map(function (elem) {
            if (schemaIds.includes(elem)) {
                formulaStr += '{{' + schemasMap[elem].name + '}} ';
            }
            else {
                formulaStr += elem + ' ';
            }
        });
        setFormulaString(formulaStr);
    }, [schemasMap]);
    useEffect(function () {
        if (formula) {
            parseExpressionForDisplay(formula);
        }
    }, [formula, parseExpressionForDisplay]);
    var closeModal = useCallback(function () {
        setIsClicked(false);
    }, [setIsClicked]);
    var getNumericColumns = useCallback(function () {
        if (!schemasMap)
            return [];
        var cols = Object.keys(schemasMap)
            .map(function (schemaId) {
            if ([
                'number',
                'metric',
                'native_number',
                'metric_currency',
                'metric_percentage',
                'native_currency',
            ].includes(schemasMap[schemaId].type))
                return schemasMap[schemaId];
        })
            .filter(function (val) { return val != undefined; });
        return cols;
    }, [schemasMap]);
    var parseFormula = useCallback(function (formulaStr, index, stack) {
        if (formulaStr === '' || index >= formulaStr.length || !schemasMap) {
            return stack;
        }
        var char = formulaStr.charAt(index);
        var nextChar = formulaStr.charAt(index + 1);
        var newIndex = index + 1;
        if (char === '{' && nextChar === '{') {
            // Jump to end of schema variable (triggers next condition)
            return parseFormula(formulaStr, index + formulaStr.substring(index).indexOf('}'), stack);
        }
        else if (char === '}' && nextChar === '}') {
            // Find schema id of variable
            var schemaName_1 = formulaStr.substring(2, index);
            var schema = Object.values(schemasMap).find(function (schema) { return schema.name.replaceAll(' ', '') === schemaName_1; });
            if (!schema)
                return stack;
            // push onto stack
            stack.push(schema.id);
            if (index === formulaStr.length - 2) {
                // End of string
                return stack;
            }
            // continue parsing
            formulaStr = formulaStr.substring(index + 2);
            newIndex = 0;
        }
        else if (['+', '-', '*', '/', '(', ')'].includes(char)) {
            stack.push(char);
            formulaStr = formulaStr.substring(index + 1);
            newIndex = 0;
        }
        else if (formulaStr.substring(0, index + 1).match('^[0-9.]+$') &&
            (char === '.' || (char >= '0' && char <= '9'))) {
            if (nextChar == '.' || (nextChar >= '0' && nextChar <= '9')) {
                newIndex = index + 1;
            }
            else {
                stack.push(formulaStr.substring(0, index + 1));
                formulaStr = formulaStr.substring(index + 1);
                newIndex = 0;
            }
        }
        return parseFormula(formulaStr, newIndex, stack);
    }, [schemasMap]);
    var setSchemaFormulaState = useSetRecoilState(schemaFormulaState(schemaId));
    var onApplyFormula = useCallback(function () {
        var fs = formulaString.replaceAll(' ', '');
        var ts = parseFormula(fs, 0, []);
        setIsClicked(false);
        useUpdateFormulaMutation.mutate({ formulaId: formulaId, expression: ts }, {
            onSuccess: function (resp) {
                setSchemaFormulaState(resp);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formulaId, formulaString, parseFormula, setIsClicked]);
    return (_jsx("div", { onClick: function (e) { return e.stopPropagation(); }, children: _jsx(Modal, { open: isClicked, onCancel: closeModal, width: 600, bodyStyle: { minHeight: '400px', height: 'auto' }, zIndex: 1100, footer: null, closable: false, centered: true, children: _jsxs("div", { className: "FormulaModalContainer", children: [_jsx("div", { className: "FormulaModalHeader", children: "Configure Formula" }), _jsx("div", { className: "divider" }), _jsx(Input, { className: "FormulaInput", placeholder: "Add values from the list below or build your formula here", value: formulaString, onChange: function (e) {
                            setFormulaString(e.target.value);
                        } }), _jsx("div", { className: "divider" }), _jsx("div", { className: "FormulaSectionHeader", children: "Add Operator" }), _jsx("div", { className: "FormulaSectionSubHeader", children: "Click to add" }), _jsx("div", { className: "FormulaOperatorsWrapper", children: FormulaOperators.map(function (_a) {
                            var symbol = _a.symbol, value = _a.value, color = _a.color, backgroundColor = _a.backgroundColor;
                            return (_jsx("div", { className: "FormulaOperator", onClick: function () {
                                    setFormulaString("".concat(formulaString).concat(value, " "));
                                }, style: { backgroundColor: backgroundColor, color: color }, children: symbol }, symbol));
                        }) }), _jsx("div", { className: "FormulaSectionHeader", children: "Add Column Value" }), _jsx("div", { className: "FormulaSectionSubHeader", children: "Click to add" }), _jsx("div", { className: "FormulaOperatorsWrapper", children: getNumericColumns().map(function (schema) {
                            if (!schema)
                                return;
                            return (_jsx("div", { onClick: function () {
                                    return setFormulaString(formulaString + '{{' + schema.name + '}} ');
                                }, className: "ColumnName", children: schema.name }, schema.id));
                        }) }), _jsx("div", { className: "ApplyBtn", onClick: function () { return onApplyFormula(); }, children: "Apply" })] }) }) }));
};
export default React.memo(FormulaModal);
