import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { NativePath } from 'src/shared';
import { collectionTableSchemaSelector } from 'src/state';
import { getTagTextColor } from 'src/utils/Collection';
import { convertStyleToColors } from 'src/utils/Collection';
import './DummyTagCell.scss';
import HoverRecordMenu from './HoverRecordMenu';
import HoverTaskEntityMenu from './HoverTaskEntityMenu';
var DummyTagCell = function (props) {
    var defaultTags = props.tags, cellPos = props.cellPos, setIsClicked = props.setIsClicked, canEdit = props.canEdit;
    var schema = useRecoilValue(collectionTableSchemaSelector(cellPos));
    var tags = useMemo(function () {
        return defaultTags;
    }, [defaultTags]);
    var showLogicCell = useCallback(function () {
        if (canEdit) {
            setIsClicked(true);
        }
    }, [canEdit, setIsClicked]);
    var tagContent = useCallback(function (option) {
        var color = convertStyleToColors(option.color);
        return (_jsx("span", { className: "DummyTag", style: {
                color: getTagTextColor(color),
                backgroundColor: color,
            }, children: option.text }, option.id));
    }, []);
    var isTaskEditorNativePath = [
        NativePath.TASK_TEMPLATE_ID,
        NativePath.TASK_RULE_ID,
        NativePath.TASK_GROUP_ID,
    ].some(function (path) { return path === schema.nativePath; });
    return (_jsx("div", { className: "DummyTagCell", onClick: showLogicCell, children: tags.map(function (tag) {
            var _a, _b;
            if (schema.refCollectionId) {
                return (_jsx(HoverRecordMenu, { rid: tag.id, title: (_a = tag.text) !== null && _a !== void 0 ? _a : 'Record', children: tagContent(tag) }, tag.id));
            }
            else if (isTaskEditorNativePath) {
                return (_jsx(HoverTaskEntityMenu, { taskEntityId: tag.id, entityName: (_b = tag.text) !== null && _b !== void 0 ? _b : 'Record', nativePath: schema.nativePath, children: tagContent(tag) }, tag.id));
            }
            else {
                return tagContent(tag);
            }
        }) }));
};
export default React.memo(DummyTagCell);
