var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from '@ant-design/icons';
import { Input, Select, Spin, Tag } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import UserAvatar from 'src/components/User/UserAvatar';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { useDebounce } from 'src/hooks/component';
var DepartmentItem = function (_a) {
    var department = _a.department, onDelete = _a.onDelete, onUpdate = _a.onUpdate;
    var _b = useState([]), options = _b[0], setOptions = _b[1];
    var useGetAdminCanBeAddedInDepartment = useChatApi().useGetAdminCanBeAddedInDepartment;
    var _c = useState(''), searchQuery = _c[0], setSearchQuery = _c[1];
    var debouncedSearchQuery = useDebounce(searchQuery, 500);
    var onSearch = useCallback(function (value) {
        if (value.trim() === '')
            return;
        setSearchQuery(value);
    }, []);
    var _d = useGetAdminCanBeAddedInDepartment(debouncedSearchQuery), data = _d.data, isFetching = _d.isFetching;
    useEffect(function () {
        if (data) {
            setOptions(data);
        }
    }, [data]);
    var userOptions = useMemo(function () {
        return options.map(function (user) {
            return {
                value: user.id,
                label: user.name,
                avatar: user.avatarUrl,
            };
        });
    }, [options]);
    return (_jsx("div", { className: "mb-5", children: _jsxs("div", { className: "CreateDepartmentItem", children: [_jsx(UserAvatar, { className: "CreateDepartmentAvatar", name: ' ', isCirclular: true, size: 32, avatar: department.avatarUrl, uploadProps: {
                        canEdit: true,
                        onUploadSuccess: function (newAvatarUrl) {
                            onUpdate(__assign(__assign({}, department), { avatarUrl: newAvatarUrl }));
                        },
                    }, tooltip: "".concat(department.avatarUrl ? 'Edit' : 'Upload', " Department Avatar Here"), showEditIcon: true }), _jsxs("div", { className: "CreateDepartmentContent", children: [_jsx(Input, { className: "CreateDepartmentTitle", bordered: false, value: department.title, onChange: function (event) {
                                if (event.target.value !== '\n') {
                                    onUpdate(__assign(__assign({}, department), { title: event.target.value }));
                                }
                            }, placeholder: "Enter department name", autoFocus: true }), _jsx(Select, { mode: "multiple", className: "CreateDepartmentSelect", labelInValue: true, filterOption: false, onSearch: onSearch, value: department.users.map(function (user) {
                                return {
                                    value: user.id,
                                    label: user.name,
                                    avatar: user.avatarUrl,
                                };
                            }), onChange: function (value) {
                                onUpdate(__assign(__assign({}, department), { users: value.map(function (user) {
                                        return {
                                            id: user.value,
                                            name: user.label,
                                            avatarUrl: user.avatar,
                                        };
                                    }) }));
                                setOptions([]);
                            }, tagRender: function (props) {
                                var label = props.label, onClose = props.onClose;
                                return (_jsx(Tag, { closable: true, onClose: onClose, children: label }));
                            }, notFoundContent: isFetching ? _jsx(Spin, { size: "small" }) : null, options: userOptions })] }), _jsx("div", { className: "CreateDepartmentTrailing", children: _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this department?", onConfirm: onDelete, placement: "right", okText: "Yes", children: _jsx(DeleteOutlined, { className: "CreateDepartmentSaveIcon" }) }) })] }) }));
};
export default DepartmentItem;
