var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Select, Switch, Tabs } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
import { cloneDeep, sortBy } from 'lodash';
import { useAccountApi, usePageApi } from 'src/hooks/api';
import { ZiraVisibilityDropdownToggleItem, ZiraVisibilityDropdownVisibleItem, } from 'src/ui/ZiraVisibilityDropdown';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { currentReverseRelationIdState } from 'src/state';
import { isBoardViewSettingModalVisibleState } from './atom';
import './BoardViewGroupBySettingModal.scss';
import { EMPTY_GROUP_FAKED_ID } from './Constants';
var Option = Select.Option;
var BoardViewGroupBySettingModal = function (_a) {
    var _b;
    var groupBySetting = _a.groupBySetting, applyGroupBySetting = _a.applyGroupBySetting, columns = _a.columns, collectionId = _a.collectionId, viewId = _a.viewId;
    var useGetAllAvailableGroupBySchemas = usePageApi().useGetAllAvailableGroupBySchemas;
    var _c = useGetAllAvailableGroupBySchemas(collectionId), groupBySchemas = _c.data, isLoadingAvailableSchemas = _c.isLoading;
    var reverseRelationId = useRecoilValue(currentReverseRelationIdState);
    var availableGroupBySchemas = useMemo(function () {
        return reverseRelationId
            ? groupBySchemas === null || groupBySchemas === void 0 ? void 0 : groupBySchemas.filter(function (schema) { return schema.schemaId !== reverseRelationId; })
            : groupBySchemas;
    }, [groupBySchemas, reverseRelationId]);
    var setIsBoardViewSettingModalVisible = useSetRecoilState(isBoardViewSettingModalVisibleState(viewId));
    var _d = useState(groupBySetting), currentGroupBySetting = _d[0], setCurrentGroupBySetting = _d[1];
    var hasSelectedSchema = useRef(false);
    useEffect(function () {
        if (groupBySetting) {
            setCurrentGroupBySetting(groupBySetting);
        }
    }, [groupBySetting]);
    // Clean up state when unmount modal
    useEffect(function () {
        return function () {
            setIsBoardViewSettingModalVisible(false);
        };
    }, [setIsBoardViewSettingModalVisible]);
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var schemaOptionList = useGetUniversalSimpleList({
        body: {
            schemaId: (_b = currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema) !== null && _b !== void 0 ? _b : '',
        },
        options: {
            enabled: !!(currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema),
        },
    }).data;
    var _e = useState([]), optionList = _e[0], setOptionList = _e[1];
    useEffect(function () {
        var _a;
        // Append null option to list when fetching schema options
        if (schemaOptionList) {
            var list = __spreadArray([], schemaOptionList, true);
            var optionName = '';
            var schemaId = (_a = currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema) !== null && _a !== void 0 ? _a : '';
            var schema = columns[schemaId];
            if (schema.name) {
                optionName = "No ".concat(schema.name);
            }
            var nullOption = {
                id: EMPTY_GROUP_FAKED_ID,
                name: optionName,
                color: '#EAEDFC',
            };
            list.push(nullOption);
            setOptionList(list);
        }
    }, [columns, currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema, schemaOptionList]);
    useEffect(function () {
        // When new schema is selected, fetch schema options
        // and push all options to schemaOptionsSetting with visible = true
        if (optionList && hasSelectedSchema.current) {
            var newGroupBySetting = cloneDeep(currentGroupBySetting);
            if (!newGroupBySetting)
                return;
            var schemaOptionSetting = optionList.map(function (option) {
                var schemaOption = {
                    id: option.id,
                    hidden: false,
                };
                return schemaOption;
            });
            newGroupBySetting.options = schemaOptionSetting;
            setCurrentGroupBySetting(newGroupBySetting);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionList]);
    var getSchemaOptionName = useCallback(function (id) {
        var _a;
        if (!optionList)
            return '';
        var schemaOption = optionList.find(function (option) { return option.id === id; });
        return (_a = schemaOption === null || schemaOption === void 0 ? void 0 : schemaOption.name) !== null && _a !== void 0 ? _a : '';
    }, [optionList]);
    var onOptionVisibleToggle = useCallback(function (optionId, isToggledOn) {
        var temp = cloneDeep(currentGroupBySetting);
        if (!temp)
            return;
        var newGroupOption = temp.options.map(function (option) {
            return option.id !== optionId ? option : __assign(__assign({}, option), { hidden: !isToggledOn });
        });
        temp.options = newGroupOption;
        setCurrentGroupBySetting(temp);
    }, [currentGroupBySetting]);
    var allOptions = useMemo(function () {
        var _a;
        var allOptions = (_a = currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.options) !== null && _a !== void 0 ? _a : [];
        return sortBy(allOptions, function (option) { return option.hidden; });
    }, [currentGroupBySetting]);
    var allVisibleOptions = useMemo(function () {
        if (currentGroupBySetting) {
            return currentGroupBySetting.options.filter(function (option) {
                return !option.hidden;
            });
        }
        else {
            return [];
        }
    }, [currentGroupBySetting]);
    var onOptionDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination)
            return;
        var sourceSchema = allVisibleOptions[source.index];
        var destinationSchema = allOptions[destination.index];
        if (!sourceSchema || !destinationSchema)
            return;
        var sourceSchemaIndex = allOptions.findIndex(function (option) { return option.id === sourceSchema.id; });
        var destinationSchemaIndex = allOptions.findIndex(function (option) { return option.id === destinationSchema.id; });
        if (sourceSchemaIndex === -1 || destinationSchemaIndex === -1)
            return;
        var temp = cloneDeep(allOptions);
        var reorderedItem = temp.splice(sourceSchemaIndex, 1)[0];
        temp.splice(destinationSchemaIndex, 0, reorderedItem);
        var newGroupBySetting = cloneDeep(currentGroupBySetting);
        if (!newGroupBySetting)
            return;
        newGroupBySetting.options = temp;
        setCurrentGroupBySetting(newGroupBySetting);
    }, [allOptions, allVisibleOptions, currentGroupBySetting]);
    var allVisibleSchemaOptionsContent = useMemo(function () {
        var _a;
        if (allVisibleOptions.length === 0)
            return (_jsx("div", { className: "flex items-center justify-center text-base font-medium text-gray-300", children: "No visible groups" }));
        return (_jsx(DragDropContext, { onDragEnd: onOptionDragEnd, children: _jsx(Droppable, { droppableId: (_a = currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema) !== null && _a !== void 0 ? _a : 'visible-groups', children: function (provided) { return (_jsxs("div", __assign({ className: "flex w-full flex-col space-y-3 p-4" }, provided.droppableProps, { ref: provided.innerRef, children: [allVisibleOptions.map(function (option, index) {
                            return (_jsx(Draggable, { draggableId: option.id, index: index, children: function (provided) {
                                    return (_jsx(ZiraVisibilityDropdownVisibleItem, { draggableProvided: provided, title: getSchemaOptionName(option.id), onToggleOffItem: function () {
                                            return onOptionVisibleToggle(option.id, false);
                                        } }, option.id));
                                } }, option.id));
                        }), provided.placeholder] }))); } }) }));
    }, [
        allVisibleOptions,
        currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema,
        getSchemaOptionName,
        onOptionDragEnd,
        onOptionVisibleToggle,
    ]);
    var allSchemaOptionsContent = useMemo(function () {
        return (_jsx("div", { className: "flex w-full flex-col", children: allOptions.map(function (option, index) {
                var isVisible = !option.hidden;
                var isLastVisible = isVisible && index === allVisibleOptions.length - 1;
                return (_jsx(ZiraVisibilityDropdownToggleItem, { title: getSchemaOptionName(option.id), isVisible: isVisible, onVisibilityToggled: function (isToggledOn) {
                        return onOptionVisibleToggle(option.id, isToggledOn);
                    }, showDivider: isLastVisible }, option.id));
            }) }));
    }, [allOptions, allVisibleOptions.length, getSchemaOptionName, onOptionVisibleToggle]);
    var onSelectedSchemaChanged = useCallback(function (id) {
        hasSelectedSchema.current = true;
        var newGroupBySetting = cloneDeep(currentGroupBySetting);
        if (!newGroupBySetting) {
            var setting = {
                schema: id,
                options: [],
                hideEmptyGroups: false,
            };
            setCurrentGroupBySetting(setting);
        }
        else {
            newGroupBySetting.schema = id;
            setCurrentGroupBySetting(newGroupBySetting);
        }
    }, [currentGroupBySetting]);
    var onToggleHideEmptyGroups = useCallback(function (checked) {
        var newGroupBySetting = cloneDeep(currentGroupBySetting);
        if (!newGroupBySetting)
            return;
        newGroupBySetting.hideEmptyGroups = checked;
        setCurrentGroupBySetting(newGroupBySetting);
    }, [currentGroupBySetting]);
    var onSave = useCallback(function () {
        if (currentGroupBySetting) {
            applyGroupBySetting(currentGroupBySetting);
        }
        setIsBoardViewSettingModalVisible(false);
    }, [applyGroupBySetting, currentGroupBySetting, setIsBoardViewSettingModalVisible]);
    return (_jsx(Modal, { open: true, centered: true, closable: false, footer: _jsx(ZiraModalFooter, { onCancel: function () { return setIsBoardViewSettingModalVisible(false); }, onSave: onSave }), className: "BoardViewGroupBySettingModal", children: isLoadingAvailableSchemas ? (_jsx(ZiraRowsSkeleton, { rowNumber: 5 })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "GroupBySchemaSelection", children: [_jsx("div", { className: "mr-2 font-medium", children: "Group by:" }), _jsx(Select, { placeholder: "Please select column", className: "w-[330px]", value: currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema, onChange: function (value) {
                                onSelectedSchemaChanged(value);
                            }, children: availableGroupBySchemas === null || availableGroupBySchemas === void 0 ? void 0 : availableGroupBySchemas.map(function (schema) { return (_jsx(Option, { value: schema.schemaId, disabled: !schema.available, children: schema.name }, schema.schemaId)); }) })] }), (currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema) && (_jsxs("div", { className: "mt-5 flex", children: [_jsx("div", { className: "mr-2 font-medium", children: "Hide Empty Groups" }), _jsx(Switch, { className: "ml-2", checked: currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.hideEmptyGroups, onChange: onToggleHideEmptyGroups })] })), (currentGroupBySetting === null || currentGroupBySetting === void 0 ? void 0 : currentGroupBySetting.schema) ? (_jsx("div", { className: "ZiraVisibilityDropdown mt-3 flex w-[450px] flex-col bg-white", children: _jsx(Tabs, { className: "mb-0 w-full", tabBarStyle: { width: '100%', margin: 0 }, children: ['Visible Groups', 'All Groups'].map(function (tabTitle, tabIndex) { return (_jsx(Tabs.TabPane, { tab: tabTitle, children: _jsx("div", { className: "ZiraVisibilityDropdownTabContent", children: [
                                    allVisibleSchemaOptionsContent,
                                    allSchemaOptionsContent,
                                ][tabIndex] }) }, "".concat(tabTitle, "-").concat(tabIndex))); }) }) })) : (_jsx("div", { className: "flex items-center justify-center text-base font-medium text-gray-300 h-[488px] text-center", children: "Select Column to Group By" }))] })) }));
};
export default React.memo(BoardViewGroupBySettingModal);
