var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Tag, Input, Select } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';
import { CollectionType } from 'src/shared';
import { usePageApi } from 'src/hooks/api';
import { aggregateReportFormatOptions } from 'src/components/PayView/Constant';
import { v4 as uuidv4 } from 'uuid';
import { TargetType, ReportPayPeriodType, AutomationReportType, AutomationFilterType, } from '../Interface';
import { schemaTypeIsValidForAutomationSendMessage } from '../util';
import AutomationFilteredTarget from './AutomationFilteredTarget';
var Option = Select.Option, OptGroup = Select.OptGroup;
var SendReportForm = function (props) {
    var _a, _b, _c, _d;
    var currentAction = props.currentAction, detail = props.detail, updateAction = props.updateAction, filterOptions = props.filterOptions, baseCollectionId = props.baseCollectionId, allCollections = props.allCollections, roleSchemaId = props.roleSchemaId, locationSchemaId = props.locationSchemaId;
    var targetType = detail.targetType, subject = detail.subject, replyToAddress = detail.replyToAddress, targetDetail = detail.targetDetail, payPeriodType = detail.payPeriodType, reportDetail = detail.reportDetail;
    var _e = usePageApi(), useGetAggregatePayReportTypes = _e.useGetAggregatePayReportTypes, useGetExportPayReportTemplate = _e.useGetExportPayReportTemplate;
    var aggregatePayReportTypes = useGetAggregatePayReportTypes({
        enabled: true,
    }).data;
    var defaultFormatOptions = useMemo(function () {
        return aggregatePayReportTypes
            ? aggregateReportFormatOptions.filter(function (option) {
                return aggregatePayReportTypes.supported.includes(option.value);
            })
            : aggregateReportFormatOptions.slice(0, -1);
    }, [aggregatePayReportTypes]);
    var exportReportTemplates = useGetExportPayReportTemplate({
        enabled: true,
    }).data;
    var _f = useState(currentAction), newActionObj = _f[0], setNewActionObj = _f[1];
    var newActionObjRef = useRef(newActionObj);
    var _g = useState(targetType), selectedTargetType = _g[0], setSelectedTargetType = _g[1];
    var _h = useState(subject), emailSubject = _h[0], setEmailSubject = _h[1];
    var _j = useState(replyToAddress || ''), replyTo = _j[0], setReplyTo = _j[1];
    var _k = useState((_a = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.schemaId) !== null && _a !== void 0 ? _a : null), targetSchemaId = _k[0], setTargetSchemaId = _k[1];
    var _l = useState((_b = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.secondarySchemaId) !== null && _b !== void 0 ? _b : null), targetSecondarySchemaId = _l[0], setTargetSecondarySchemaId = _l[1];
    var _m = useState((_c = targetDetail.filters) !== null && _c !== void 0 ? _c : {
        locations: undefined,
        roles: undefined,
    }), filters = _m[0], setFilters = _m[1];
    var _o = useState((_d = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.emails) !== null && _d !== void 0 ? _d : []), emails = _o[0], setEmails = _o[1];
    var _p = useState(''), newEmailInput = _p[0], setNewEmailInput = _p[1];
    var initialBaseCollectionId = useState(baseCollectionId)[0];
    // Reset automation determined target on base collection change
    useEffect(function () {
        if (initialBaseCollectionId &&
            initialBaseCollectionId !== baseCollectionId &&
            selectedTargetType === TargetType.AUTOMATION_DETERMINED) {
            setTargetSchemaId(null);
            setTargetSecondarySchemaId(null);
            var tempActionObj = cloneDeep(newActionObjRef.current);
            var tempActionDetail = tempActionObj.detail;
            tempActionDetail.targetDetail = {
                type: TargetType.AUTOMATION_DETERMINED,
                schemaId: null,
            };
            tempActionObj.detail = tempActionDetail;
            setNewActionObj(tempActionObj);
            updateAction(tempActionObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseCollectionId, initialBaseCollectionId]);
    useEffect(function () {
        var _a;
        if (targetType !== TargetType.HARDCODED) {
            setTargetSchemaId(targetDetail.schemaId);
            setTargetSecondarySchemaId((_a = targetDetail.secondarySchemaId) !== null && _a !== void 0 ? _a : null);
        }
    }, [targetDetail, targetType]);
    var formTargetDetailObj = useCallback(function (newTargetSchemaId, newTargetSecondarySchemaId) {
        switch (selectedTargetType) {
            case TargetType.AUTOMATION_DETERMINED: {
                return {
                    type: TargetType.AUTOMATION_DETERMINED,
                    schemaId: newTargetSchemaId != '' ? newTargetSchemaId : null,
                    secondarySchemaId: newTargetSecondarySchemaId,
                };
            }
            case TargetType.FILTERED_SET: {
                return {
                    type: TargetType.AUTOMATION_DETERMINED,
                    schemaId: newTargetSchemaId,
                    filters: filters,
                };
            }
            case TargetType.HARDCODED: {
                return {
                    emails: emails || [],
                };
            }
            case TargetType.QUALIFIED_USERS: {
                return {
                    collectionId: baseCollectionId,
                };
            }
        }
    }, [selectedTargetType, filters, emails, baseCollectionId]);
    var onTargetTypeChange = useCallback(function (event) {
        setSelectedTargetType(event);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.targetType = event;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onFiltersChange = useCallback(function (newFilters) {
        setFilters(newFilters);
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        var tempTargetDetail = tempActionDetail.targetDetail;
        tempTargetDetail.filters = newFilters;
        tempActionDetail.targetDetail = tempTargetDetail;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onOptionSelection = useCallback(function (val, elem) {
        setEmails([]);
        if (elem.parentSchemaId) {
            setTargetSchemaId(elem.parentSchemaId);
            setTargetSecondarySchemaId(elem.optionId);
        }
        else if (elem.optionId) {
            setTargetSchemaId(elem.optionId);
        }
        else {
            setTargetSchemaId(null);
            setTargetSecondarySchemaId(null);
        }
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.targetDetail = formTargetDetailObj(elem.parentSchemaId ? elem.parentSchemaId : elem.optionId, elem.parentSchemaId ? elem.optionId : null);
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [formTargetDetailObj, newActionObj, updateAction]);
    var onSubjectChange = useCallback(function (event) {
        setEmailSubject(event.target.value);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.subject = event.target.value;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onReplyToChange = useCallback(function (event) {
        setReplyTo(event.target.value);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.replyToAddress = event.target.value;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onAddEmail = useCallback(function () {
        setEmails(function (prevEmails) {
            var tempActionObj = cloneDeep(newActionObj);
            var tempActionDetail = tempActionObj.detail;
            tempActionDetail.targetDetail = { emails: __spreadArray(__spreadArray([], prevEmails, true), [newEmailInput], false) };
            setNewActionObj(tempActionObj);
            updateAction(tempActionObj);
            return __spreadArray(__spreadArray([], prevEmails, true), [newEmailInput], false);
        });
        setNewEmailInput('');
    }, [newActionObj, newEmailInput, updateAction]);
    var onChangePayPeriodType = useCallback(function (newPayPeriodType) {
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.payPeriodType = newPayPeriodType;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onChangeReport = useCallback(function (selectedReport) {
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        var isDefaultFormat = !!defaultFormatOptions.find(function (option) { return option.value === selectedReport; });
        var newDetail = {
            type: isDefaultFormat
                ? AutomationReportType.FROM_REPORT_FORMAT
                : AutomationReportType.FROM_TEMPLATE,
            reportFormatType: isDefaultFormat
                ? selectedReport
                : undefined,
            reportTemplateId: isDefaultFormat ? undefined : selectedReport,
        };
        tempActionDetail.reportDetail = newDetail;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [defaultFormatOptions, newActionObj, updateAction]);
    var tagRender = useCallback(function (props) {
        var value = props.value;
        return (_jsx(Tag, { className: "InputTag", onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, color: "#ffecc8", closable: true, closeIcon: _jsx(CloseOutlined, { className: "CloseIcon" }), onClose: function () {
                var newEmails = cloneDeep(emails);
                newEmails = newEmails.filter(function (email) { return email !== value; });
                setEmails(newEmails);
                var tempActionObj = cloneDeep(newActionObj);
                var tempActionDetail = tempActionObj.detail;
                tempActionDetail.targetDetail = { emails: newEmails };
                setNewActionObj(tempActionObj);
                updateAction(tempActionObj);
            }, children: value }));
    }, [emails, newActionObj, updateAction]);
    var baseCollectionType = useMemo(function () {
        var _a;
        return (_a = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.id == baseCollectionId; })) === null || _a === void 0 ? void 0 : _a.type;
    }, [allCollections, baseCollectionId]);
    return (_jsxs("div", { className: "messageCard", children: [_jsx("p", { className: "inputLabel firstLabel", children: "Subject" }), _jsx(Input, { placeholder: "Empty", value: emailSubject, onChange: function (event) { return setEmailSubject(event.target.value); }, onBlur: function (event) { return onSubjectChange(event); } }), _jsx("p", { className: "inputLabel firstLabel", children: "Reply-To Address" }), _jsx(Input, { placeholder: "Empty", value: replyTo, onChange: function (event) { return setReplyTo(event.target.value); }, onBlur: function (event) { return onReplyToChange(event); } }), _jsxs("div", { className: "targetSelectorRow mt-6", children: [_jsx("p", { className: "inputLabel", children: "Send To" }), _jsxs(Select, { className: "targetSelector", defaultValue: TargetType.AUTOMATION_DETERMINED, value: selectedTargetType, onChange: function (event) { return onTargetTypeChange(event); }, children: [baseCollectionId && (_jsx(Option, { value: TargetType.AUTOMATION_DETERMINED, children: "Automation Workflow" })), _jsx(Option, { value: TargetType.FILTERED_SET, children: "Specific Group of Users" }), _jsx(Option, { value: TargetType.HARDCODED, children: "Emails" }), baseCollectionId && baseCollectionType == CollectionType.SHIFT && (_jsx(Option, { value: TargetType.QUALIFIED_USERS, children: "All Qualified Users" }))] })] }), !!filterOptions.length && selectedTargetType === TargetType.AUTOMATION_DETERMINED && (_jsxs(Select, { className: "attributeSelector", placeholder: "Choose contact attribute", value: targetSecondarySchemaId
                    ? "".concat(targetSchemaId).concat(targetSecondarySchemaId)
                    : (targetSchemaId !== null && targetSchemaId !== void 0 ? targetSchemaId : ''), onSelect: onOptionSelection, children: [(baseCollectionType == CollectionType.USER ||
                        baseCollectionType == CollectionType.CONTACT) && (_jsx(OptGroup, { label: "Default", children: _jsx(Option, { value: '', children: baseCollectionType == CollectionType.USER ? 'User' : 'Contact' }, "default") }, "defaultGroup")), filterOptions
                        .filter(function (group) { return group.type === AutomationFilterType.RECORD; })
                        .map(function (group) {
                        var showGroup = group.schemas.some(function (option) {
                            return schemaTypeIsValidForAutomationSendMessage(option.type);
                        });
                        return (_jsx(_Fragment, { children: showGroup && (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option) {
                                    var _a;
                                    if (schemaTypeIsValidForAutomationSendMessage(option.type)) {
                                        var label = group.parentSchemaId !== null
                                            ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                                            : "".concat(option.name);
                                        return (_jsx(Option, { value: "".concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), optionId: option.id, label: label, parentSchemaId: group.parentSchemaId, children: label }, uuidv4()));
                                    }
                                }) }, group.collectionId)) }));
                    })] })), selectedTargetType === TargetType.FILTERED_SET && (_jsx(AutomationFilteredTarget, { filters: filters, onChange: onFiltersChange, roleSchemaId: roleSchemaId, locationSchemaId: locationSchemaId })), selectedTargetType === TargetType.HARDCODED && (_jsx(Select, { className: "HardCodedInput", mode: "multiple", placeholder: "Enter emails", searchValue: newEmailInput, onSearch: function (value) { return setNewEmailInput(value); }, onKeyDown: function (e) {
                    if (e.key === 'Enter' && !emails.includes(newEmailInput)) {
                        onAddEmail();
                    }
                }, onChange: function (value) {
                    setEmails(value);
                    var tempActionObj = cloneDeep(newActionObj);
                    var tempActionDetail = tempActionObj.detail;
                    tempActionDetail.targetDetail = { emails: value };
                    setNewActionObj(tempActionObj);
                    updateAction(tempActionObj);
                }, value: emails, tagRender: tagRender, notFoundContent: _jsx(_Fragment, { children: newEmailInput && !emails.includes(newEmailInput) && (_jsx("div", { style: { display: 'flex', flexWrap: 'nowrap' }, children: _jsxs("a", { style: {
                                flex: 'none',
                                display: 'block',
                                cursor: 'pointer',
                                color: 'grey',
                                fontSize: '12px',
                                height: '22px',
                                fontWeight: 500,
                            }, onClick: function () { return onAddEmail(); }, children: ["Add", ' ', _jsx("span", { style: {
                                        backgroundColor: '#ffecc8',
                                        fontWeight: 600,
                                        borderRadius: '8px',
                                        height: '22px',
                                        padding: '4px 8px',
                                    }, children: newEmailInput })] }) })) }) })), _jsx("div", { className: "mt-3", children: "Pay Period" }), _jsxs(Select, { className: "attributeSelector", value: payPeriodType, onChange: function (value) { return onChangePayPeriodType(value); }, children: [_jsx(Option, { value: ReportPayPeriodType.CURRENT_PAY_PERIOD, children: "Current" }), _jsx(Option, { value: ReportPayPeriodType.LAST_PAY_PERIOD, children: "Last" })] }), _jsx("div", { className: "mt-3", children: "Report" }), _jsxs(Select, { className: "attributeSelector", value: (reportDetail === null || reportDetail === void 0 ? void 0 : reportDetail.type) === AutomationReportType.FROM_REPORT_FORMAT
                    ? reportDetail.reportFormatType
                    : reportDetail === null || reportDetail === void 0 ? void 0 : reportDetail.reportTemplateId, onChange: function (value) { return onChangeReport(value); }, children: [_jsxs(OptGroup, { label: "CSV Format", children: [defaultFormatOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.csvExportTemplates.map(function (option) { return (_jsx(Option, { value: option.id, children: option.name }, option.id)); })] }), (!isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.pdfExportTemplates) ||
                        !isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.shiftDetailPdfExportTemplates) ||
                        !isEmpty(exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates)) && (_jsxs(OptGroup, { label: "PDF Format", children: [exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.pdfExportTemplates.map(function (option) { return (_jsx(Option, { value: option.id, children: option.name }, option.id)); }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.shiftDetailPdfExportTemplates.map(function (option) { return (_jsx(Option, { value: option.id, children: option.name }, option.id)); }), exportReportTemplates === null || exportReportTemplates === void 0 ? void 0 : exportReportTemplates.locationInvoicePdfExportTemplates.map(function (option) { return (_jsx(Option, { value: option.id, children: option.name }, option.id)); })] }))] })] }));
};
export default React.memo(SendReportForm);
