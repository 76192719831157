import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Input } from 'antd';
var NameInputCell = function (props) {
    var name = props.name, updateWageRecordName = props.updateWageRecordName;
    return (_jsx(Input, { className: "name-input-cell", bordered: false, defaultValue: name, onBlur: function (e) {
            updateWageRecordName(e.currentTarget.value);
        }, onPressEnter: function (e) { return e.currentTarget.blur(); } }));
};
export default React.memo(NameInputCell);
