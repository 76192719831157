var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useFetch } from './useFetch';
export var useDemandRatioApi = function () {
    var customFetch = useFetch().request;
    var useGetDemandRatio = function (request) {
        return useQuery(['demandRatios', JSON.stringify(request)], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/demand_ratio',
            }, request.body);
        }, __assign({}, request.options));
    };
    var useSaveDemandRatio = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "scheduling/demand_ratio/save",
            }, request);
        });
    };
    var useGetSchedulingDemandRatioStats = function (request) {
        var _a;
        var body = request.body, options = request.options;
        return useQuery([
            'schedulingDemandRatioStats',
            body.blockId,
            body.scheduleTimeZone,
            body.startFrom,
            body.endAt,
            JSON.stringify((_a = body.displaySettings) === null || _a === void 0 ? void 0 : _a.filters),
        ], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/demand_ratio/stats',
            }, body);
        }, __assign(__assign({}, options), { enabled: body && (options === null || options === void 0 ? void 0 : options.enabled) === true }));
    };
    var useUpdateDemandRatioCensusValue = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "scheduling/demand_ratio/census",
            }, request);
        });
    };
    return {
        useGetDemandRatio: useGetDemandRatio,
        useSaveDemandRatio: useSaveDemandRatio(),
        useGetSchedulingDemandRatioStats: useGetSchedulingDemandRatioStats,
        useUpdateDemandRatioCensusValue: useUpdateDemandRatioCensusValue(),
    };
};
