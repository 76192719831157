var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import { useMemo } from 'react';
import PaymentRulesTableBuilder from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableBuilder';
import { DragHandlerIcon } from 'src/utils/icons/DragHandlerIcon';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import usePayRateV2Api from '../../../usePayRateV2Api';
import PaymentRulesAdjustmentCell from './component/PaymentRulesTableCell/PaymentRulesAdjustmentCell';
import PaymentRulesConditionCell from './component/PaymentRulesTableCell/PaymentRulesConditionCell';
var PaymentRulesTable = function (_a) {
    var payrules = _a.payrules, editPayruleModal = _a.editPayruleModal;
    var deletePayRule = usePayRateV2Api().deletePayRule;
    var paymentRulesColumns = useMemo(function () { return [
        {
            Header: _jsx("div", { className: "flex", children: "Name" }),
            width: 150,
            accessor: 'info',
            Cell: function (_a) {
                var value = _a.value, dragProps = _a.dragProps;
                return (_jsxs("div", { className: "flex font-medium", children: [_jsx("div", __assign({ className: "absolute left-[-30px]" }, dragProps, { children: _jsx(DragHandlerIcon, {}) })), value === null || value === void 0 ? void 0 : value.name] }));
            },
        },
        {
            Header: _jsx("div", { className: "flex", children: "Conditions" }),
            width: 600,
            accessor: 'conditions',
            Cell: function (_a) {
                var value = _a.value, row = _a.row;
                return (_jsx("div", { className: "", children: _jsx(PaymentRulesConditionCell, { conditions: value, info: row.values.info }) }));
            },
        },
        {
            Header: _jsx("div", { className: "flex", children: "Adjustments" }),
            width: 300,
            accessor: 'adjustments',
            Cell: function (_a) {
                var value = _a.value;
                return (_jsxs("div", { children: [_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this pay rule?", onConfirm: function (e) {
                                e === null || e === void 0 ? void 0 : e.stopPropagation();
                                deletePayRule.mutateAsync(value[0].payRuleId);
                            }, onCancel: function (e) {
                                e === null || e === void 0 ? void 0 : e.stopPropagation();
                            }, placement: "topLeft", okText: "Delete", children: _jsx(Button, { className: "RecordDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-gray-60 ml-2 absolute right-[-40px]", icon: _jsx(DeleteOutlinedIcon, {}), onClick: function (e) {
                                    e.stopPropagation(); //block the onclick for the row
                                } }) }), _jsx(PaymentRulesAdjustmentCell, { adjustments: value })] }));
            },
        },
    ]; }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return (_jsx("div", { className: "ReactTableWrapper", children: payrules && (payrules === null || payrules === void 0 ? void 0 : payrules.length) !== 0 && (_jsx(PaymentRulesTableBuilder, { columns: paymentRulesColumns, payrules: payrules, editPayruleModal: editPayruleModal })) }));
};
export default PaymentRulesTable;
