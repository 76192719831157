var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TasksTabSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M6 7.33341L8 9.33341L14.6667 2.66675", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var TasksTabIcon = function (props) { return (_jsx(Icon, __assign({ component: TasksTabSvg }, props))); };
var RulesTabSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_11112_15169)", children: [_jsx("path", { d: "M2.6665 13.9999V9.33325", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.6665 6.66667V2", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8 14V8", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8 5.33333V2", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13.3335 14.0001V10.6667", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13.3335 8V2", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M0.666504 9.33325H4.6665", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M6 5.33325H10", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.3335 10.6667H15.3335", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_11112_15169", children: _jsx("rect", { width: "16", height: "16", fill: "white" }) }) })] })); };
export var RulesTabIcon = function (props) { return (_jsx(Icon, __assign({ component: RulesTabSvg }, props))); };
var GroupsTabSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_11112_15163)", children: [_jsx("path", { d: "M8.00016 1.33325L1.3335 4.66659L8.00016 7.99992L14.6668 4.66659L8.00016 1.33325Z", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M1.3335 11.3333L8.00016 14.6666L14.6668 11.3333", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M1.3335 8L8.00016 11.3333L14.6668 8", stroke: "black", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_11112_15163", children: _jsx("rect", { width: "16", height: "16", fill: "white" }) }) })] })); };
export var GroupsTabIcon = function (props) { return (_jsx(Icon, __assign({ component: GroupsTabSvg }, props))); };
