var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var RecommendShiftOutlinedSvg = function () { return (_jsx("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.415 3.86098L3.94213 7.01861L7.73129 9.54471L13.415 3.86098ZM8.74935 10.648L11.1051 14.1815L14.0497 5.34764L8.74935 10.648ZM15.6352 1.53978C16.2215 1.34434 16.7793 1.90215 16.5839 2.48847L12.0431 16.1107C11.8458 16.7027 11.0538 16.8088 10.7076 16.2895L7.24142 11.0903C7.1865 11.0079 7.1158 10.9372 7.03341 10.8822L1.83415 7.41607C1.31486 7.06987 1.42092 6.27788 2.013 6.08052L15.6352 1.53978Z" }) })); };
export var RecommendShiftIcon = function (props) { return (_jsx(Icon, __assign({ component: RecommendShiftOutlinedSvg }, props))); };
