import { useFetchWithoutAuth } from './useFetchWithoutAuth';
var useOrganizationApi = function () {
    var customFetchWithoutAuth = useFetchWithoutAuth().request;
    var getBrandingOrganization = function (name, id, accountId) {
        return customFetchWithoutAuth({
            method: 'GET',
            url: "organizations?".concat(name ? "name=".concat(name) : '').concat(id ? "id=".concat(id) : '').concat(accountId ? "accountId=".concat(accountId) : ''),
        });
    };
    return {
        getBrandingOrganization: getBrandingOrganization,
    };
};
export default useOrganizationApi;
