var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var BottomBarCollapseIconSvg = function () { return (_jsxs("svg", { width: "16", height: "17", viewBox: "0 0 14 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_914_12894)", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.2998 10.7999C8.47738 12.6223 5.52262 12.6223 3.70017 10.7999C1.87772 8.97745 1.87772 6.02268 3.70017 4.20024C5.52262 2.37779 8.47738 2.37779 10.2998 4.20024C12.1223 6.02268 12.1223 8.97745 10.2998 10.7999ZM2.87521 11.6249C5.15327 13.9029 8.84673 13.9029 11.1248 11.6249C13.4028 9.3468 13.4028 5.65334 11.1248 3.37528C8.84673 1.09722 5.15327 1.09722 2.87521 3.37528C0.597151 5.65334 0.597152 9.3468 2.87521 11.6249Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.14368 7.29381C5.02978 7.17991 4.8451 7.17991 4.7312 7.29381L4.31872 7.70629C4.20482 7.82019 4.20482 8.00487 4.31872 8.11877L6.38112 10.1812L6.7936 10.5936C6.90038 10.7004 7.06936 10.7071 7.18392 10.6137C7.19156 10.6074 7.19896 10.6008 7.20607 10.5936L7.61855 10.1812L7.61893 10.1808L9.68095 8.11877C9.79485 8.00487 9.79485 7.82019 9.68095 7.70629L9.26847 7.29381C9.15457 7.17991 8.96989 7.17991 8.85599 7.29381L7.58305 8.56675V4.62947C7.58305 4.46839 7.45247 4.33781 7.29139 4.33781L6.70806 4.33781C6.54697 4.33781 6.41639 4.46839 6.41639 4.62947L6.41639 8.56652L5.14368 7.29381Z", fill: "black" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_914_12894", children: _jsx("rect", { width: "14", height: "14", fill: "white", transform: "matrix(0 1 1 0 0 0.5)" }) }) })] })); };
export var BottomBarCollapseIcon = function (props) { return (_jsx(Icon, __assign({ component: BottomBarCollapseIconSvg }, props))); };
