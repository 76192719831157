var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { EmitterEventType } from 'src/shared';
import { useScheduleApi, useTimeClockApi } from 'src/hooks/api';
import Emitter from 'src/utils/EventEmitter';
import { PulsingCircle } from 'src/utils/icons/PulsingCircle';
import './TimeClockWidget.scss';
var TimeClockWidget = function () {
    var _a, _b, _c, _d, _e, _f;
    var getTimeClockCheckInInfo = useTimeClockApi().getTimeClockCheckInInfo;
    var _g = useScheduleApi(), unscheduledShiftClockIn = _g.unscheduledShiftClockIn, scheduledShiftClockIn = _g.scheduledShiftClockIn, shiftBreakIn = _g.shiftBreakIn, shiftBreakOut = _g.shiftBreakOut, shiftClockOut = _g.shiftClockOut;
    var _h = useState(), timeClockData = _h[0], setTimeClockData = _h[1];
    var _j = useState(false), locationSelectOpen = _j[0], setLocationSelectOpen = _j[1];
    var _k = useState(false), roleSelectOpen = _k[0], setRoleSelectOpen = _k[1];
    var _l = useState(), locationRid = _l[0], setLocationRid = _l[1];
    var _m = useState(false), dropDownVisible = _m[0], setDropDownVisible = _m[1];
    var _o = useState(false), breakSelectOpen = _o[0], setBreakSelectOpen = _o[1];
    var isOnBreak = !!((_a = timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.actionableShift) === null || _a === void 0 ? void 0 : _a.ongoingBreak);
    var isClockedIn = !!((_b = timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.actionableShift) === null || _b === void 0 ? void 0 : _b.clockIn);
    var fetchTimeClockData = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTimeClockCheckInInfo()];
                case 1:
                    data = _a.sent();
                    if (!data)
                        return [2 /*return*/];
                    setTimeClockData(data);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () {
        Emitter.emit(EmitterEventType.REFRESH_SCHEDULE, null);
    }, [timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext]);
    useEffect(function () {
        if (!timeClockData)
            fetchTimeClockData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeClockData]);
    var getTimeClockStatus = isOnBreak ? 'On Break' : isClockedIn ? 'Working' : '';
    var handleUnscheduledShiftClockIn = useCallback(function (location, role) { return __awaiter(void 0, void 0, void 0, function () {
        var tempTimeClockData, req, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDropDownVisible(false);
                    tempTimeClockData = cloneDeep(timeClockData);
                    if (!tempTimeClockData)
                        return [2 /*return*/];
                    req = {
                        userRid: tempTimeClockData.user.rid,
                        locationRid: location,
                        roleRid: role,
                    };
                    return [4 /*yield*/, unscheduledShiftClockIn(req)];
                case 1:
                    response = _a.sent();
                    if (!(response === null || response === void 0 ? void 0 : response.userUpNext))
                        return [2 /*return*/];
                    setTimeClockData(__assign(__assign({}, tempTimeClockData), { userUpNext: response.userUpNext }));
                    return [2 /*return*/];
            }
        });
    }); }, [timeClockData, unscheduledShiftClockIn]);
    var handleScheduledShiftClockIn = useCallback(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!((_a = data === null || data === void 0 ? void 0 : data.userUpNext.actionableShift) === null || _a === void 0 ? void 0 : _a.rid))
                        return [2 /*return*/];
                    setDropDownVisible(false);
                    return [4 /*yield*/, scheduledShiftClockIn({
                            shiftRid: data.userUpNext.actionableShift.rid,
                            userRid: data.user.rid,
                        })];
                case 1:
                    response = _b.sent();
                    if (!(response === null || response === void 0 ? void 0 : response.userUpNext))
                        return [2 /*return*/];
                    setTimeClockData(__assign(__assign({}, data), { userUpNext: response.userUpNext }));
                    return [2 /*return*/];
            }
        });
    }); }, [scheduledShiftClockIn]);
    var timeClockBreakIn = useCallback(function (ruleId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempTimeClockData, resp;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setDropDownVisible(false);
                    tempTimeClockData = cloneDeep(timeClockData);
                    if (!((_a = tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.userUpNext.actionableShift) === null || _a === void 0 ? void 0 : _a.rid))
                        return [2 /*return*/];
                    return [4 /*yield*/, shiftBreakIn({
                            ruleId: ruleId,
                            shiftRid: (_b = tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.userUpNext.actionableShift) === null || _b === void 0 ? void 0 : _b.rid,
                            userRid: tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.user.rid,
                        })];
                case 1:
                    resp = _c.sent();
                    if (!(resp === null || resp === void 0 ? void 0 : resp.userUpNext))
                        return [2 /*return*/];
                    setTimeClockData(__assign(__assign({}, tempTimeClockData), { userUpNext: resp.userUpNext }));
                    return [2 /*return*/];
            }
        });
    }); }, [shiftBreakIn, timeClockData]);
    var timeClockBreakOut = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var tempTimeClockData, resp;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    setDropDownVisible(false);
                    tempTimeClockData = cloneDeep(timeClockData);
                    if (!((_a = tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.userUpNext.actionableShift) === null || _a === void 0 ? void 0 : _a.rid))
                        return [2 /*return*/];
                    return [4 /*yield*/, shiftBreakOut({
                            shiftRid: (_b = tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.userUpNext.actionableShift) === null || _b === void 0 ? void 0 : _b.rid,
                            userRid: tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.user.rid,
                        })];
                case 1:
                    resp = _c.sent();
                    if (!(resp === null || resp === void 0 ? void 0 : resp.userUpNext))
                        return [2 /*return*/];
                    setTimeClockData(__assign(__assign({}, tempTimeClockData), { userUpNext: resp.userUpNext }));
                    return [2 /*return*/];
            }
        });
    }); }, [shiftBreakOut, timeClockData]);
    var timeClockCheckIn = useCallback(function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var unscheduled;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            if (timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.actionableShift) {
                // act on shift
                handleScheduledShiftClockIn(timeClockData);
            }
            else if (timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.unscheduled) {
                unscheduled = timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.unscheduled;
                if (unscheduled.locations.length > 1) {
                    setLocationSelectOpen(true);
                    return [2 /*return*/];
                }
                if (unscheduled.roles.length > 1) {
                    setLocationRid((_a = unscheduled.locations[0]) === null || _a === void 0 ? void 0 : _a.rid);
                    setRoleSelectOpen(true);
                    return [2 /*return*/];
                }
                if (unscheduled.locations.length === 1 && unscheduled.roles.length === 1) {
                    e.stopPropagation();
                    handleUnscheduledShiftClockIn((_b = unscheduled.locations[0]) === null || _b === void 0 ? void 0 : _b.rid, (_c = unscheduled.roles[0]) === null || _c === void 0 ? void 0 : _c.rid);
                }
                else {
                    setLocationSelectOpen(true);
                }
            }
            return [2 /*return*/];
        });
    }); }, [handleScheduledShiftClockIn, handleUnscheduledShiftClockIn, timeClockData]);
    var timeClockClockOut = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var tempTimeClockData, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDropDownVisible(false);
                    tempTimeClockData = cloneDeep(timeClockData);
                    if (!tempTimeClockData || !(tempTimeClockData === null || tempTimeClockData === void 0 ? void 0 : tempTimeClockData.userUpNext.actionableShift))
                        return [2 /*return*/];
                    return [4 /*yield*/, shiftClockOut({
                            shiftRid: tempTimeClockData.userUpNext.actionableShift.rid,
                            userRid: tempTimeClockData.user.rid,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp || !resp.userUpNext)
                        return [2 /*return*/];
                    setTimeClockData(__assign(__assign({}, tempTimeClockData), { userUpNext: resp.userUpNext }));
                    return [2 /*return*/];
            }
        });
    }); }, [shiftClockOut, timeClockData]);
    var getTimeClockCta = useCallback(function () {
        if (getTimeClockStatus == 'On Break') {
            return _jsx("span", { className: "timeClockCta", children: "End Break" });
        }
        else if (getTimeClockStatus == 'Working') {
            return _jsx("span", { className: "timeClockCta", children: "Clock Out" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTimeClockStatus]);
    return (_jsxs(_Fragment, { children: [_jsx(Dropdown, { open: dropDownVisible, placement: "bottomRight", overlay: _jsxs(Menu, { className: "TimeClockMenu", children: [locationSelectOpen &&
                            (timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.unscheduled.locations.map(function (location) {
                                return (_jsx(Menu.Item, { className: "MenuItem", onClick: function () {
                                        var _a;
                                        setDropDownVisible(false);
                                        setLocationRid(location.rid);
                                        setLocationSelectOpen(false);
                                        if (timeClockData.userUpNext.unscheduled.roles.length >
                                            1) {
                                            setRoleSelectOpen(true);
                                            setDropDownVisible(true);
                                        }
                                        else {
                                            setDropDownVisible(false);
                                            handleUnscheduledShiftClockIn(location.rid, (_a = timeClockData.userUpNext.unscheduled.roles[0]) === null || _a === void 0 ? void 0 : _a.rid);
                                        }
                                    }, children: location.name }, location.rid));
                            })), roleSelectOpen &&
                            (timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.unscheduled.roles.map(function (role) {
                                return (_jsx(Menu.Item, { className: "MenuItem", onClick: function () {
                                        setRoleSelectOpen(false);
                                        setDropDownVisible(false);
                                        handleUnscheduledShiftClockIn(locationRid, role.rid);
                                    }, children: role.name }, role.rid));
                            })), breakSelectOpen &&
                            ((_d = (_c = timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.actionableShift) === null || _c === void 0 ? void 0 : _c.breakOptions) === null || _d === void 0 ? void 0 : _d.map(function (brk) {
                                return (_jsx(Menu.Item, { className: "MenuItem", onClick: function () {
                                        setBreakSelectOpen(false);
                                        setDropDownVisible(false);
                                        timeClockBreakIn(brk.ruleId);
                                    }, children: brk.name }, brk.ruleId));
                            })), isClockedIn &&
                            !breakSelectOpen &&
                            !!((_f = (_e = timeClockData === null || timeClockData === void 0 ? void 0 : timeClockData.userUpNext.actionableShift) === null || _e === void 0 ? void 0 : _e.breakOptions) === null || _f === void 0 ? void 0 : _f.length) && (_jsx(Menu.Item, { className: "MenuItem", onClick: function () { return setBreakSelectOpen(true); }, children: "Start Break" }, "startBreak")), isClockedIn && isOnBreak && (_jsx(Menu.Item, { className: "MenuItem", onClick: function () { return timeClockBreakOut(); }, children: "End Break" }, "endBreak")), !locationSelectOpen &&
                            !roleSelectOpen &&
                            !breakSelectOpen &&
                            isClockedIn && (_jsx(Menu.Item, { className: "MenuItem EndShiftBtn", onClick: function () { return timeClockClockOut(); }, children: "End Shift" }, "clockOut"))] }), children: _jsx("div", { className: 'TimeClockBtn' + (getTimeClockStatus == 'On Break' ? ' OnBreak' : ''), onClick: function () { return setDropDownVisible(true); }, children: getTimeClockStatus !== '' ? (_jsxs(_Fragment, { children: [_jsx(PulsingCircle, { className: "pulsingCircle" }), _jsx("span", { className: "timeClockStatus", children: getTimeClockStatus }), getTimeClockCta()] })) : (_jsxs(_Fragment, { children: [_jsx(ClockCircleOutlined, { className: "timeIcon" }), _jsx("div", { className: "timeClockStatus", onClick: function (e) { return timeClockCheckIn(e); }, children: "Clock In" })] })) }) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setDropDownVisible(false);
                }, style: { display: dropDownVisible ? 'block' : 'none' } })] }));
};
export default React.memo(TimeClockWidget);
