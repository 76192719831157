var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ApplicationTrackingSvg = function () { return (_jsx("svg", { width: "100%", height: "29", viewBox: "0 0 26 29", stroke: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M18.7454 8.96518V9.96518H19.7454H24.3591V27.4165H1.09766V9.96518H5.71131H6.71131V8.96518V7.5758C6.71131 4.30087 9.39574 1.62891 12.7284 1.62891C16.061 1.62891 18.7454 4.30087 18.7454 7.5758V8.96518ZM16.9386 9.96518H17.9386V8.96518V7.5758C17.9386 4.71207 15.5964 2.40766 12.7284 2.40766C9.86034 2.40766 7.51814 4.71207 7.51814 7.5758V8.96518V9.96518H8.51814H16.9386ZM2.90448 10.7439H1.90448V11.7439V25.6377V26.6377H2.90448H22.5523H23.5523V25.6377V11.7439V10.7439H22.5523H2.90448Z", strokeWidth: "2", fill: "transparent" }) })); };
export var ApplicationTrackingIcon = function (props) { return (_jsx(Icon, __assign({ component: ApplicationTrackingSvg }, props))); };
