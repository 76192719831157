var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var LinkOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M5.66659 7.40756C5.66659 7.22347 5.51735 7.07423 5.33325 7.07423H4.66659C4.48249 7.07423 4.33325 7.22347 4.33325 7.40756V17.4076C4.33325 17.5917 4.48249 17.7409 4.66659 17.7409H14.6666C14.8507 17.7409 14.9999 17.5917 14.9999 17.4076V16.7409C14.9999 16.5568 14.8507 16.4076 14.6666 16.4076H5.66659V7.40756Z", fill: "#A7A8AC" }), _jsx("path", { d: "M16.3333 13.4076C16.3333 13.5917 16.4825 13.7409 16.6666 13.7409H17.3333C17.5173 13.7409 17.6666 13.5917 17.6666 13.4076L17.6666 4.7409C17.6666 4.5568 17.5173 4.40756 17.3333 4.40756L8.66659 4.40756C8.48249 4.40756 8.33325 4.5568 8.33325 4.7409V5.40756C8.33325 5.59166 8.48249 5.7409 8.66659 5.7409L15.9999 5.7409C16.184 5.7409 16.3333 5.89013 16.3333 6.07423L16.3333 13.4076Z", fill: "#A7A8AC" }), _jsx("path", { d: "M16.0114 5.10963C16.1415 4.97945 16.3526 4.97945 16.4828 5.10963L16.9542 5.58103C17.0843 5.71121 17.0843 5.92226 16.9542 6.05244L9.38151 13.6251C9.25134 13.7553 9.04028 13.7553 8.91011 13.6251L8.4387 13.1537C8.30853 13.0235 8.30853 12.8125 8.4387 12.6823L16.0114 5.10963Z", fill: "#A7A8AC" })] })); };
export var LinkOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: LinkOutlinedSvg }, props))); };
