var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { produce } from 'immer';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useScheduleApi } from 'src/hooks/api';
import { useTimeZone, useWindowSize } from 'src/hooks/component';
import { noteColumn, requestStatusTag, renderUserCell, shiftSimpleTag } from '../Utils';
var ShiftReleaseTableRow = function (_a) {
    var blockId = _a.blockId, recordData = _a.recordData, index = _a.index, style = _a.style, tableData = _a.tableData, schemaOptions = _a.schemaOptions, timeZone = _a.timeZone, setRowHeight = _a.setRowHeight, setTableData = _a.setTableData, onOpenRecordDetailModal = _a.onOpenRecordDetailModal;
    var rowRef = useRef(null);
    var windowWidth = useWindowSize().windowWidth;
    var record = recordData[index];
    useEffect(function () {
        if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);
    var useShiftReleaseApprovalAction = useScheduleApi().useShiftReleaseApprovalAction;
    var _b = useTimeZone(timeZone), momentFormatFn = _b.momentFormatFn, timezoneAbbr = _b.timezoneAbbr;
    var hasActionInfo = useMemo(function () {
        return record.shiftRequest.actorName && record.shiftRequest.actTime;
    }, [record.shiftRequest.actTime, record.shiftRequest.actorName]);
    var renderOtherVisibleColumns = useCallback(function (recordId) {
        var pillTagData = [];
        schemaOptions.map(function (schemaOpt) {
            var _a, _b, _c;
            if (schemaOpt.hidden || !tableData)
                return null;
            var schema = tableData.shiftCollection.schemas[schemaOpt.schemaId];
            if (!schema)
                return null;
            if (!schema.nativePath) {
                var item = Object.values(tableData.shiftCollection.items).find(function (item) { return item.schemaId === schema.id && item.recordId === recordId; });
                if (!item)
                    return null;
                var values = item.values;
                if (values.length > 0) {
                    values.map(function (value) {
                        var _a, _b, _c, _d;
                        var valueData = tableData.shiftCollection.selectionValues[value];
                        if (valueData) {
                            pillTagData.push({
                                name: (_a = valueData === null || valueData === void 0 ? void 0 : valueData.data) === null || _a === void 0 ? void 0 : _a.text,
                                color: (_c = (_b = valueData === null || valueData === void 0 ? void 0 : valueData.data) === null || _b === void 0 ? void 0 : _b.color) !== null && _c !== void 0 ? _c : undefined,
                            });
                        }
                        else {
                            var relations = tableData.shiftCollection.relations[value];
                            pillTagData.push({
                                name: relations === null || relations === void 0 ? void 0 : relations.title,
                                color: (_d = relations === null || relations === void 0 ? void 0 : relations.color) !== null && _d !== void 0 ? _d : undefined,
                            });
                        }
                    });
                }
                else if (item.data) {
                    pillTagData.push({
                        name: item.data.text,
                        color: (_a = item.data.color) !== null && _a !== void 0 ? _a : undefined,
                    });
                }
            }
            else {
                var nativeItem = tableData.shiftCollection.nativeFields.find(function (field) { return field.schemaId === schema.id && field.recordId === recordId; });
                if (!nativeItem)
                    return null;
                pillTagData.push({
                    name: (_b = nativeItem.name) !== null && _b !== void 0 ? _b : nativeItem.number,
                    color: (_c = nativeItem.color) !== null && _c !== void 0 ? _c : undefined,
                });
            }
        });
        return (_jsx(_Fragment, { children: pillTagData.map(function (data) {
                if (data.name != undefined)
                    return shiftSimpleTag(data.name, data.color);
            }) }));
    }, [schemaOptions, tableData]);
    var shiftCell = useCallback(function (shift) {
        var startAt = shift.startAt, endAt = shift.endAt, location = shift.location, roles = shift.roles;
        var startAtDate = moment(startAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        var endAtDate = moment(endAt, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
        var startAtTime = momentFormatFn(startAtDate, 'h:mma').slice(0, -1);
        var endAtTime = momentFormatFn(endAtDate, 'h:mma').slice(0, -1);
        var startAtDateString = momentFormatFn(startAtDate, 'MMM D');
        var endAtDateString = startAtDate.day() !== endAtDate.day()
            ? momentFormatFn(endAtDate, 'MMM D') + ', '
            : '';
        var timezone = timezoneAbbr();
        return (_jsxs("div", { className: "ShiftCell", onClick: function () { return onOpenRecordDetailModal(shift.recordId); }, children: [_jsxs("div", { className: "ShiftDateTimeWrapper", children: [_jsx("span", { className: "ShiftDate", children: startAtDateString + ', ' }), _jsx("span", { className: "ShiftTime", children: "".concat(startAtTime, " - ").concat(endAtDateString).concat(endAtTime) +
                                (timezone ? ", ".concat(timezone) : '') })] }), location && shiftSimpleTag(location.label, location.color), roles.length > 0 &&
                    roles.map(function (role) { return shiftSimpleTag(role.label, role.color); }), renderOtherVisibleColumns(shift.recordId)] }));
    }, [momentFormatFn, onOpenRecordDetailModal, renderOtherVisibleColumns, timezoneAbbr]);
    var renderActionInfo = useCallback(function () {
        var _a = record.shiftRequest, actorName = _a.actorName, actTime = _a.actTime;
        var status = record.shiftRequest.status;
        var time = momentFormatFn(moment(actTime), 'MMM D, h:mma');
        return (_jsx("div", { className: "RequestActionInfoCell", children: "".concat(actorName, " ").concat(status, " this request on ").concat(time) }));
    }, [momentFormatFn, record.shiftRequest]);
    var _c = useState(), isProcessingAction = _c[0], setIsProcessingAction = _c[1];
    var processKey = useCallback(function (record) { return record.userRid + record.shiftRid; }, []);
    var onActionRequest = useCallback(function (isApprove, record) { return __awaiter(void 0, void 0, void 0, function () {
        var requestShiftId, requestUserId;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    requestShiftId = record.shiftRid;
                    requestUserId = record.userRid;
                    setIsProcessingAction((_a = {},
                        _a[processKey(record)] = true,
                        _a));
                    return [4 /*yield*/, useShiftReleaseApprovalAction.mutateAsync({
                            shiftRid: requestShiftId,
                            userRid: requestUserId,
                            approved: isApprove,
                        }, {
                            onSuccess: function () {
                                var _a;
                                //!MARK: - update table data locally only
                                setIsProcessingAction((_a = {},
                                    _a[processKey(record)] = false,
                                    _a));
                                //!MARK: - table data update will trigger record data update
                                var nextTableDataState = produce(tableData, function (draft) {
                                    draft === null || draft === void 0 ? void 0 : draft.requests.map(function (request) {
                                        if (request.shiftRid === requestShiftId) {
                                            request.status = isApprove ? 'approved' : 'rejected';
                                        }
                                    });
                                });
                                setTableData(nextTableDataState);
                            },
                            onError: function () { },
                        })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockId, processKey, setTableData, tableData]);
    var onPerformAction = useCallback(function (isApprove, record) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onActionRequest(isApprove, record)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [onActionRequest]);
    var actionCell = useCallback(function (record) {
        var _a;
        var status = record.status;
        switch (status) {
            case 'pending':
                return (_jsx("div", { className: "ActionCellButtons", children: _jsxs(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }), spinning: (_a = isProcessingAction === null || isProcessingAction === void 0 ? void 0 : isProcessingAction[processKey(record)]) !== null && _a !== void 0 ? _a : false, children: [_jsxs("div", { className: "ActionButton ApproveButton", onClick: function () { return onPerformAction(true, record); }, children: [_jsx(CheckOutlined, { style: { color: '#FFF' } }), "Approve"] }), _jsxs("div", { className: "ActionButton RejectButton", onClick: function () { return onPerformAction(false, record); }, children: [_jsx(CloseOutlined, { className: "CloseIcon" }), "Reject"] })] }) }));
            default:
                return requestStatusTag(status);
        }
    }, [isProcessingAction, onPerformAction, processKey]);
    return (_jsxs("div", { ref: rowRef, className: "ShiftRequestRow", style: __assign(__assign({}, style), { marginBottom: index === recordData.length - 1 ? 'none' : '6px', height: 'auto' }), children: [renderUserCell(record.shiftRequest.userRid, momentFormatFn(moment(record.shiftRequest.requestTime), 'MMM D, h:mma'), record.shiftRequest.userName, function () { return onOpenRecordDetailModal(record.shiftRequest.userRid); }, record.shiftRequest.userAvatar, 'Requested to release on '), _jsxs("span", { className: "ShiftRequestRowBorder", children: [shiftCell(record.shift), noteColumn(record.shiftRequest.notes), hasActionInfo && renderActionInfo(), actionCell(record.shiftRequest)] })] }));
};
export default React.memo(ShiftReleaseTableRow);
