var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusCircleFilled } from '@ant-design/icons';
import { Modal, Input, Select, InputNumber, Button, Divider } from 'antd';
import { lowerCase, startCase } from 'lodash';
import { useMemo, useState, useCallback, useEffect, useRef, memo } from 'react';
import FilterEntry from 'src/components/OptimizedTableView/OptionHeader/Filter/FilterEntry';
import { useHealthMetricApi } from 'src/hooks/api';
import { timeRangeToDisplayText } from './HealthMetricCalculator';
import HealthMetricLoadingBar from './HealthMetricLoadingBar';
import './EditHealthMetricRuleModal.scss';
var EditHealthRuleModal = function (_a) {
    var _b, _c;
    var visible = _a.visible, setVisible = _a.setVisible, configOptions = _a.configOptions, onSave = _a.onSave, rule = _a.rule;
    var _d = useState(rule === null || rule === void 0 ? void 0 : rule.type), selectedPreset = _d[0], setSelectedPreset = _d[1];
    var preset = (rule === null || rule === void 0 ? void 0 : rule.metric) || selectedPreset || '';
    var useGetPresetConfigQuery = useHealthMetricApi().useGetPresetConfigQuery;
    var presetConfig = useGetPresetConfigQuery({
        preset: preset === 'custom' ? 'TOTAL_SHIFTS' : preset,
        options: {
            enabled: !!preset,
            cacheTime: 0,
        },
    }).data;
    var timeRangeOptions = useMemo(function () {
        return presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.timeRanges.map(function (r) { return ({
            value: r,
            label: 'Past ' + timeRangeToDisplayText(r),
        }); });
    }, [presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.timeRanges]);
    var metricRuleOptions = useMemo(function () {
        return presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.availableRules.map(function (r) { return ({
            value: r,
            label: startCase(r.toLowerCase()),
        }); });
    }, [presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.availableRules]);
    var intervalOptions = useMemo(function () {
        return presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.availableIntervals.map(function (r) { return ({
            value: r,
            label: startCase(lowerCase(r)),
        }); });
    }, [presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.availableIntervals]);
    var defaultMetricName = useMemo(function () { return (preset === 'custom' ? '' : (rule === null || rule === void 0 ? void 0 : rule.name) || startCase(preset.toLowerCase() || '')); }, [preset, rule === null || rule === void 0 ? void 0 : rule.name]);
    var defaultChosenMetricType = useMemo(function () { return (presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.type) || (rule === null || rule === void 0 ? void 0 : rule.metric) || (metricRuleOptions === null || metricRuleOptions === void 0 ? void 0 : metricRuleOptions[0].value); }, [metricRuleOptions, presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.type, rule === null || rule === void 0 ? void 0 : rule.metric]);
    var defaultTimeRange = useMemo(function () { return (rule === null || rule === void 0 ? void 0 : rule.timeRange) || (timeRangeOptions === null || timeRangeOptions === void 0 ? void 0 : timeRangeOptions[0].value); }, [rule === null || rule === void 0 ? void 0 : rule.timeRange, timeRangeOptions]);
    var defaultInterval = useMemo(function () { var _a; return ((_a = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _a === void 0 ? void 0 : _a.interval) || (intervalOptions === null || intervalOptions === void 0 ? void 0 : intervalOptions[0].value); }, [intervalOptions, (_b = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _b === void 0 ? void 0 : _b.interval]);
    var _e = useState(), metricName = _e[0], setMetricName = _e[1];
    var _f = useState(), chosenMetricType = _f[0], setChosenMetricType = _f[1];
    var _g = useState(), chosenTimeRange = _g[0], setChosenTimeRange = _g[1];
    var _h = useState(), chosenInterval = _h[0], setChosenInterval = _h[1];
    useEffect(function () {
        if (defaultMetricName) {
            setMetricName(defaultMetricName);
        }
    }, [defaultMetricName]);
    useEffect(function () {
        if (defaultChosenMetricType) {
            setChosenMetricType(defaultChosenMetricType);
        }
    }, [defaultChosenMetricType]);
    useEffect(function () {
        if (defaultTimeRange) {
            setChosenTimeRange(defaultTimeRange);
        }
    }, [defaultTimeRange]);
    useEffect(function () {
        if (defaultInterval) {
            setChosenInterval(defaultInterval);
        }
    }, [defaultInterval]);
    var _j = useState((rule === null || rule === void 0 ? void 0 : rule.goal) || undefined), goal = _j[0], setGoal = _j[1];
    var defaultFilter = useMemo(function () { var _a; return ((_a = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _a === void 0 ? void 0 : _a.filters) || (presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.presetFilters) || []; }, [presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.presetFilters, (_c = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _c === void 0 ? void 0 : _c.filters]);
    var _k = useState(defaultFilter), filter = _k[0], setFilter = _k[1];
    useEffect(function () {
        setFilter(defaultFilter);
    }, [defaultFilter]);
    var filterRef = useRef(filter);
    useEffect(function () {
        filterRef.current = filter;
    }, [filter]);
    var _l = useState(false), hasError = _l[0], setHasError = _l[1];
    var subTitleStyle = 'mt-4 text-sm font-medium text-gray-500 mb-2';
    var spacer = _jsx("div", { className: "h-1" });
    var handleCreate = useCallback(function () {
        if (!metricName || !chosenMetricType || !chosenTimeRange) {
            setHasError(true);
            return;
        }
        onSave(metricName, chosenMetricType, chosenTimeRange, goal, filterRef.current.filter(function (f) { return f !== undefined; }), chosenInterval);
    }, [metricName, chosenMetricType, chosenTimeRange, onSave, goal, chosenInterval]);
    var editRuleContent = useMemo(function () {
        if (!presetConfig) {
            return (_jsx("div", { className: "flex h-[65vh] max-h-[530px] w-[100%] items-center justify-center", children: _jsx(HealthMetricLoadingBar, {}) }));
        }
        return (_jsx("div", { className: "h-[50vh] overflow-y-auto", children: _jsxs("div", { children: [_jsx("div", { className: subTitleStyle, children: "Title *" }), _jsx(Input, { className: "h-[34px] w-full rounded-md border border-solid border-gray-100", onChange: function (e) { return setMetricName(e.target.value); }, onBlur: function (e) { return setMetricName(e.target.value); }, onPressEnter: function (e) { return e.currentTarget.blur(); }, placeholder: "Enter metric name", status: hasError && !metricName ? 'error' : undefined, defaultValue: defaultMetricName }), spacer, _jsx("div", { className: subTitleStyle, children: "Metric" }), _jsx(Select, { className: "w-full", options: metricRuleOptions, defaultValue: defaultChosenMetricType, onChange: function (value) { return setChosenMetricType(value); } }), spacer, _jsx("div", { className: subTitleStyle, children: "Time Range" }), _jsx(Select, { className: "w-full", options: timeRangeOptions, defaultValue: defaultTimeRange, onChange: function (value) { return setChosenTimeRange(value); } }), spacer, _jsx("div", { className: subTitleStyle, children: "Interval" }), _jsx(Select, { className: "w-full", options: intervalOptions, defaultValue: defaultInterval, onChange: function (value) { return setChosenInterval(value); } }), spacer, _jsx("div", { className: subTitleStyle, children: "Filters" }), filter.map(function (f, index) { return (_jsxs(_Fragment, { children: [_jsx(FilterEntry, { existingFilter: f, columns: presetConfig === null || presetConfig === void 0 ? void 0 : presetConfig.availableFilters, onDeleteEntry: function () {
                                    setFilter(function (prev) {
                                        var newFilter = __spreadArray([], prev, true);
                                        newFilter.splice(index, 1);
                                        return newFilter;
                                    });
                                }, onEditEntry: function (newFilter) {
                                    return setFilter(function (prev) {
                                        return prev.map(function (f, i) { return (i === index ? newFilter : f); });
                                    });
                                }, options: {
                                    className: 'p-0 shadow-none w-full ',
                                    autoFocusOnDropdown: false,
                                    showCurrentUserFilter: false,
                                } }, (f === null || f === void 0 ? void 0 : f.id) || index), index !== filter.length - 1 && _jsx(Divider, {})] })); }), _jsx(PlusCircleFilled, { className: "my-2 cursor-pointer text-3xl text-black", onClick: function () { return setFilter(function (prev) { return __spreadArray(__spreadArray([], prev, true), [undefined], false); }); } }), _jsx("div", { className: subTitleStyle, children: "Goal" }), _jsx(InputNumber, { className: "h-[34px] w-full rounded-md border border-solid border-gray-100", min: 0, defaultValue: goal, onChange: function (value) { return setGoal(value || undefined); }, onBlur: function (e) {
                            return setGoal(e.target.value ? parseInt(e.target.value) : undefined);
                        }, onPressEnter: function (e) { return e.currentTarget.blur(); }, placeholder: "Enter metric goal" })] }) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        defaultChosenMetricType,
        defaultInterval,
        defaultMetricName,
        defaultTimeRange,
        filter,
        goal,
        hasError,
        intervalOptions,
        metricName,
        metricRuleOptions,
        presetConfig,
        timeRangeOptions,
    ]);
    return (_jsx(Modal, { className: "EditHealthMetricRuleModal", destroyOnClose: true, open: visible, onCancel: function () {
            if (!rule) {
                setSelectedPreset(undefined);
            }
            setVisible(false);
        }, footer: selectedPreset ? (_jsx("div", { className: "flex w-full items-center justify-center", children: _jsx(Button, { className: "bg-black font-medium text-white hover:bg-black hover:text-white active:bg-black active:text-white", onClick: handleCreate, children: "Continue" }) })) : null, title: _jsxs("div", { className: "text-2xl font-semibold", children: [selectedPreset ? 'Edit Report' : 'Add Report', selectedPreset && (_jsx("div", { className: "text-sm font-medium text-gray-400", children: "Configure and add a new metric to your dashboard" }))] }), centered: true, children: selectedPreset ? (editRuleContent) : (_jsx(PresetSelectionContent, { configOptions: configOptions, setSelectedPreset: setSelectedPreset })) }));
};
var PresetSelectionContent = memo(function (_a) {
    var configOptions = _a.configOptions, setSelectedPreset = _a.setSelectedPreset;
    var presetEntry = useCallback(function (option) { return (_jsxs("div", { className: "my-2 flex items-center", children: [_jsx("div", { className: "h-[50px] w-[50px] pt-1", children: _jsx("img", { src: (option === null || option === void 0 ? void 0 : option.icon) || configOptions.placeHolderIcon }) }), _jsxs("div", { className: "flex-auto", children: [_jsx("div", { className: "text-sm font-semibold", children: startCase((option === null || option === void 0 ? void 0 : option.type.toLowerCase()) || 'Start from scratch') }), _jsx("div", { className: "text-xs text-gray-400", children: (option === null || option === void 0 ? void 0 : option.description) || 'Build your own metric rule' })] }), _jsx("div", { className: "hover:cursor-pointer", onClick: function () { return setSelectedPreset((option === null || option === void 0 ? void 0 : option.type) || 'custom'); }, children: _jsx(PlusCircleFilled, { style: { fontSize: '24px' } }) })] })); }, [configOptions.placeHolderIcon, setSelectedPreset]);
    return (_jsx("div", { className: "h-[400px] overflow-auto overscroll-y-none pr-6", children: __spreadArray([undefined], configOptions.rules, true).map(function (option) { return presetEntry(option); }) }));
});
PresetSelectionContent.displayName = 'PresetSelectionContent';
export default EditHealthRuleModal;
