var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DefaultValue, selector, selectorFamily } from 'recoil';
import { isInternalUserEmail } from 'src/utils/Account';
import { accountDetailState, collectionOptionsState, currentUserState } from '../atoms';
var accountTimeZoneSelector = selector({
    key: 'accountTimeZoneSelector',
    get: function (_a) {
        var get = _a.get;
        var accountDetail = get(accountDetailState);
        return (accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.timeZoneId) || 'America/Los_Angeles';
    },
});
var accountCurrencySelector = selector({
    key: 'accountCurrencySelector',
    get: function (_a) {
        var get = _a.get;
        var accountDetail = get(accountDetailState);
        return (accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.currency) || '$';
    },
});
var accountNameState = selector({
    key: 'accountNameState',
    set: function (_a, newName) {
        var set = _a.set;
        if (!(newName instanceof DefaultValue)) {
            set(accountDetailState, function (prev) {
                if (!prev)
                    return;
                return __assign(__assign({}, prev), { name: newName });
            });
        }
    },
    get: function (_a) {
        var get = _a.get;
        var accountDetail = get(accountDetailState);
        return (accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.name) || 'My Business Name';
    },
});
var accountWeekOneStartDateSelector = selector({
    key: 'accountWeekOneStartDateSelector',
    get: function (_a) {
        var _b, _c;
        var get = _a.get;
        var schedulingSettings = ((_b = get(accountDetailState)) !== null && _b !== void 0 ? _b : {}).schedulingSettings;
        return (_c = schedulingSettings === null || schedulingSettings === void 0 ? void 0 : schedulingSettings.weekOneStartDate) !== null && _c !== void 0 ? _c : null;
    },
});
var currentUserRecordIdSelector = selector({
    key: 'currentUserRecordIdSelector',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var currentUser = get(currentUserState);
        return (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.recordId) !== null && _b !== void 0 ? _b : '';
    },
});
var isInternalUserSelector = selector({
    key: 'isInternalUserSelector',
    get: function (_a) {
        var _b, _c;
        var get = _a.get;
        var currentUserEmail = (_b = get(currentUserState)) === null || _b === void 0 ? void 0 : _b.email;
        return (_c = isInternalUserEmail(currentUserEmail !== null && currentUserEmail !== void 0 ? currentUserEmail : '')) !== null && _c !== void 0 ? _c : false;
    },
});
var standardCollectionIdSelector = selectorFamily({
    key: 'standardCollectionIdSelector',
    get: function (type) {
        return function (_a) {
            var get = _a.get;
            var collectionOptions = get(collectionOptionsState);
            var collectionInfo = collectionOptions.find(function (o) { return o.type === type; });
            return collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.id;
        };
    },
});
var collectionNameSelector = selectorFamily({
    key: 'collectionNameSelector',
    get: function (id) {
        return function (_a) {
            var get = _a.get;
            var collectionOptions = get(collectionOptionsState);
            var collectionInfo = collectionOptions.find(function (o) { return o.id === id; });
            return collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.name;
        };
    },
});
export { accountTimeZoneSelector, accountCurrencySelector, accountNameState, accountWeekOneStartDateSelector, currentUserRecordIdSelector, isInternalUserSelector, standardCollectionIdSelector, collectionNameSelector, };
