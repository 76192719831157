import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './PayView.scss';
import { useSetting } from 'src/hooks/api';
import IndividualPayReportCardListWrapper from './IndividualPay/IndividualPayReportCardListWrapper';
import PayReportDetailModal from './PayReportDetailModal';
import { ReportSourceType } from './Interface';
import PayReportSummarySection from './PayReport/PayReportSummarySection';
var PayView = function () {
    var _a = useParams().payPeriodId, payPeriodId = _a === void 0 ? '' : _a;
    var _b = useState(ReportSourceType.USER), sourceType = _b[0], setSourceType = _b[1];
    var useGetPayrollEnhancementSetting = useSetting().useGetPayrollEnhancementSetting;
    // Endpoint for getting payroll enhancement enable status
    var _c = useGetPayrollEnhancementSetting(), payrollEnhancementEnabled = _c.data, isLoading = _c.isLoading;
    if (isLoading || payrollEnhancementEnabled === undefined) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: "PayView", children: [_jsx(PayReportSummarySection, { payrollEnhancementEnabled: payrollEnhancementEnabled }), _jsx(IndividualPayReportCardListWrapper, { payPeriodId: payPeriodId, setSourceType: setSourceType, payrollEnhancementEnabled: payrollEnhancementEnabled }), PayDetailModal(sourceType, payrollEnhancementEnabled)] }));
};
var PayDetailModal = function (sourceType, payrollEnhancementEnabled) {
    var recordId = new URLSearchParams(location.search).get('ridForPay');
    var isPayRecordModalVisible = !!recordId;
    return (_jsx(_Fragment, { children: isPayRecordModalVisible && (_jsx(PayReportDetailModal, { recordId: recordId, sourceType: sourceType, payrollEnhancementEnabled: payrollEnhancementEnabled })) }));
};
export default React.memo(PayView);
