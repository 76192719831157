import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PayRuleAdjustmentType } from 'src/components/Settings/PayRate/interface';
import ShiftPayRulesLineItem from 'src/components/RecordDetailModal/component/ShiftPayRulesBreakdown/component/ShiftPayRulesLineItem';
import { findLastSetToPayRuleTypeItemIndex } from './component/utils';
var ShiftPayRulesBreakdown = function (_a) {
    var _b, _c, _d;
    var breakdown = _a.breakdown, defaultRateName = _a.defaultRateName;
    var lastSetToPayRuleTypeItemIndex = findLastSetToPayRuleTypeItemIndex(((_b = breakdown === null || breakdown === void 0 ? void 0 : breakdown.lineItems) === null || _b === void 0 ? void 0 : _b.items) || []);
    return (_jsxs("div", { className: "flex-col max-w-[640px] pb-2", children: [_jsx("div", { className: "ml-6", children: _jsx(ShiftPayRulesLineItem, { payRuleName: defaultRateName !== null && defaultRateName !== void 0 ? defaultRateName : 'Base Rate', lineItemType: PayRuleAdjustmentType.SET_TO, calculatedAdjustment: (breakdown === null || breakdown === void 0 ? void 0 : breakdown.baseValue) || 0, hasDivider: true, lastSetToPayRuleTypeItemIndex: lastSetToPayRuleTypeItemIndex }) }), breakdown
                ? (_d = (_c = breakdown === null || breakdown === void 0 ? void 0 : breakdown.lineItems) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d.map(function (lineItem, index, lineItems) {
                    return (_jsx("div", { className: "ml-6", children: _jsx(ShiftPayRulesLineItem, { index: index, payRuleName: lineItem.payRuleName, lineItemType: lineItem.type, calculatedAdjustment: lineItem.calculatedAdjustment, hasDivider: lineItems.length - 1 !== index, lastSetToPayRuleTypeItemIndex: lastSetToPayRuleTypeItemIndex }) }, "".concat(lineItem.payRuleId, "-payrule-breakdown}")));
                })
                : null] }));
};
export default ShiftPayRulesBreakdown;
