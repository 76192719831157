import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { OrganizationContext } from 'src/App';
import DashboardMetricWidget from './Metrics/DashboardMetricWidget';
import AutomationSummaryWidget from './AutomationSummary/AutomationSummaryWidget';
import SchedulingSnapshotWidget from './SchedulingSnapshot/SchedulingSnapshotWidget';
import ShiftAlertWidget from './AlertWidget/ShiftAlert/ShiftAlertWidget';
import UserAlertWidget from './AlertWidget/UserAlert/UserAlertWidget';
var DashboardMainSection = function () {
    var orgMode = useContext(OrganizationContext);
    return (_jsxs("div", { className: 'DashboardMainSection' + (orgMode ? ' OrgMode' : ''), children: [_jsx(DashboardMetricWidget, {}), _jsx(SchedulingSnapshotWidget, {}), _jsx(ShiftAlertWidget, {}), _jsx(UserAlertWidget, {}), _jsx(AutomationSummaryWidget, {})] }));
};
export default React.memo(DashboardMainSection);
