import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useRef } from 'react';
import { ExportFileItemStatus, fileItemStatusMap } from 'src/shared';
import { getTagTextColor } from 'src/utils/Collection';
var FileItemActionButton = function (props) {
    var status = props.status, downloadUrl = props.downloadUrl;
    var downloadFileRef = useRef(null);
    var buttonOnClick = useCallback(function () {
        if (status === ExportFileItemStatus.SUCCEED && downloadUrl && downloadFileRef.current) {
            downloadFileRef.current.click();
        }
    }, [downloadUrl, status]);
    return (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "FileActionBtn ".concat(status === ExportFileItemStatus.SUCCEED ? 'hover:shadow-hover' : ''), style: {
                    backgroundColor: fileItemStatusMap[status].color,
                    cursor: status === ExportFileItemStatus.SUCCEED ? 'pointer' : 'default',
                }, onClick: buttonOnClick, children: _jsx("span", { className: "font-semibold", style: {
                        color: status === ExportFileItemStatus.SUCCEED
                            ? 'black'
                            : getTagTextColor(fileItemStatusMap[status].color),
                    }, children: fileItemStatusMap[status].btnText }) }), status === ExportFileItemStatus.SUCCEED && downloadUrl && (_jsx("a", { ref: downloadFileRef, href: downloadUrl, download: true }))] }));
};
export default React.memo(FileItemActionButton);
