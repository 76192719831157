var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import TaskSearchBar from '../../shared/TaskSearchBar';
import { selectedTaskTemplateIdState, taskTemplateListState } from '../../atom';
import { DEFAULT_TASK_TEMPLATE_INFO, NEW_TASK_TEMPLATE_ID } from '../../constant';
import TaskTemplateListItemV2 from './TaskTemplateListItemV2';
var TaskTemplateListV2 = function () {
    var taskTemplateList = useRecoilValue(taskTemplateListState);
    var _a = useRecoilState(selectedTaskTemplateIdState), selectedTaskTemplateId = _a[0], setSelectedTaskTemplateId = _a[1];
    var _b = useState(''), searchText = _b[0], setSearchText = _b[1];
    var actualSearchValue = useMemo(function () { return searchText.trim().toLowerCase(); }, [searchText]);
    var filteredTaskTemplates = useMemo(function () {
        var filteredList = actualSearchValue
            ? taskTemplateList.filter(function (taskTemplate) {
                return taskTemplate.name.toLowerCase().includes(actualSearchValue) ||
                    taskTemplate.displayId.toLowerCase().includes(actualSearchValue) ||
                    taskTemplate.description.toLowerCase().includes(actualSearchValue);
            })
            : taskTemplateList;
        var creatingNewTaskTemplate = selectedTaskTemplateId === NEW_TASK_TEMPLATE_ID;
        return creatingNewTaskTemplate
            ? __spreadArray([DEFAULT_TASK_TEMPLATE_INFO], filteredList, true) : filteredList;
    }, [actualSearchValue, selectedTaskTemplateId, taskTemplateList]);
    useEffect(function () {
        if (!selectedTaskTemplateId && filteredTaskTemplates.length) {
            setSelectedTaskTemplateId(filteredTaskTemplates[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTaskTemplates]);
    return (_jsxs("div", { className: "flex flex-col items-center w-full", children: [_jsx(TaskSearchBar, { placeholder: "Search", value: searchText, onChange: function (e) { return setSearchText(e.target.value); } }), _jsx("div", { className: "flex flex-col w-full items-center overflow-hidden border-t-2 border-gray-50", children: filteredTaskTemplates.map(function (task) { return (_jsx(TaskTemplateListItemV2, { task: task }, task.id)); }) }), !filteredTaskTemplates.length && (_jsx("div", { className: "text-lg font-medium text-gray-300 mt-5", children: "No task templates found" }))] }));
};
export default React.memo(TaskTemplateListV2);
