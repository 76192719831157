import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from 'antd';
import { useWindowSize } from 'src/hooks/component';
import ChatContent from './ChatContent/ChatContent';
import ChatList from './ChatList/ChatList';
import './ChatPage.scss';
var Sider = Layout.Sider;
var ChatPage = function (_a) {
    var chatSiderCollapsed = _a.chatSiderCollapsed, setChatSiderCollapsed = _a.setChatSiderCollapsed;
    var windowWidth = useWindowSize().windowWidth;
    return (_jsxs(Layout, { className: "h-full", children: [_jsx(Sider, { collapsible: true, collapsed: chatSiderCollapsed, onCollapse: function (collapsed) {
                    setChatSiderCollapsed(collapsed);
                }, trigger: null, theme: "light", breakpoint: "lg", collapsedWidth: 0, width: (windowWidth || 577) > 576 ? 300 : '100%', children: _jsx(ChatList, { onChatSiderCollapsed: function () {
                        if ((windowWidth || 577) <= 576) {
                            setChatSiderCollapsed(true);
                        }
                    } }) }), _jsx(ChatContent, {})] }));
};
export default ChatPage;
