import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { needsCoverageSelectedTemplateIdState, needsCoverageTemplateVisibleOption, } from 'src/components/ScheduleView/FullPageScheduleBottomBar/Atom';
var useScheduleNeedsCoverage = function () {
    var setIsTemplateDetailModalVisible = useSetRecoilState(needsCoverageTemplateVisibleOption);
    var setSelectedTemplate = useSetRecoilState(needsCoverageSelectedTemplateIdState);
    var onOpenExistingTemplateDetailModal = useCallback(function (templateId, viewOnly) {
        setSelectedTemplate(templateId);
        setIsTemplateDetailModalVisible({
            visible: true,
            viewOnly: viewOnly,
        });
    }, [setIsTemplateDetailModalVisible, setSelectedTemplate]);
    var queryClient = useQueryClient();
    var refetchTemplateCountStats = useCallback(function () {
        queryClient.invalidateQueries(['scheduleTemplateCountStats']);
    }, [queryClient]);
    var refetchNeedsTemplatesInBottomBar = useCallback(function () {
        queryClient.invalidateQueries(['allNeedTemplates']);
    }, [queryClient]);
    var refetchCoverageTemplatesInBottomBar = useCallback(function () {
        queryClient.invalidateQueries(['allCoverageTemplates']);
    }, [queryClient]);
    var refetchNeedsCoverageLogsStatus = useCallback(function () {
        queryClient.invalidateQueries(['schedulingNeedsCoverageStatusByWeek']);
    }, [queryClient]);
    return {
        onOpenExistingTemplateDetailModal: onOpenExistingTemplateDetailModal,
        refetchTemplateCountStats: refetchTemplateCountStats,
        refetchNeedsTemplatesInBottomBar: refetchNeedsTemplatesInBottomBar,
        refetchCoverageTemplatesInBottomBar: refetchCoverageTemplatesInBottomBar,
        refetchNeedsCoverageLogsStatus: refetchNeedsCoverageLogsStatus,
    };
};
export { useScheduleNeedsCoverage as useScheduleNeedsCoverageComponent };
