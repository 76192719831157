var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var LeftArrowIconSvg = function () { return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.82849 11L12.7678 4.06068C12.9631 3.86541 12.9631 3.54883 12.7678 3.35357L12.0607 2.64646C11.8654 2.4512 11.5489 2.4512 11.3536 2.64646L2.3536 11.6465C2.15834 11.8417 2.15834 12.1583 2.3536 12.3536L11.3536 21.3536C11.5489 21.5488 11.8654 21.5488 12.0607 21.3536L12.7678 20.6465C12.9631 20.4512 12.9631 20.1346 12.7678 19.9394L5.82846 13H21.573C21.8492 13 22.073 12.7761 22.073 12.5V11.5C22.073 11.2239 21.8492 11 21.573 11H5.82849Z", fill: "black" }) })); };
export var LeftArrowIcon = function (props) { return (_jsx(Icon, __assign({ component: LeftArrowIconSvg }, props))); };
