import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
var LoadingStateAnimation = function (_a) {
    var isLoading = _a.isLoading, loadingFailed = _a.loadingFailed;
    var isLoadingRef = useRef(isLoading);
    var _b = useState(false), showCheck = _b[0], setShowCheck = _b[1];
    var _c = useState(false), showExclamation = _c[0], setShowExclamation = _c[1];
    useEffect(function () {
        var previsLoading = cloneDeep(isLoadingRef.current);
        isLoadingRef.current = isLoading;
        if (previsLoading && !isLoading) {
            if (!loadingFailed) {
                setShowCheck(true);
                setTimeout(function () {
                    setShowCheck(false);
                }, 2000);
            }
            else {
                setShowExclamation(true);
                setTimeout(function () {
                    setShowExclamation(false);
                }, 2000);
            }
        }
    }, [isLoading, loadingFailed]);
    var loadingAnimation = function () { return (_jsx("svg", { className: "mr-4 h-5 w-5 animate-spin", viewBox: "0 0 24 24", children: _jsx("path", { className: "opacity-75", fill: "#fd7238", d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" }) })); };
    // placeholder for correct display
    if (!isLoading && !showCheck && !showExclamation)
        return _jsx("div", { className: "mr-4 h-5 w-5" });
    return (_jsxs(_Fragment, { children: [isLoading && loadingAnimation(), !isLoading && showCheck && !showExclamation && (_jsx(CheckOutlined, { className: "mr-4 h-[24px] w-[24px] pb-1 text-lg text-positive" })), !isLoading && !showCheck && showExclamation && (_jsx(CloseOutlined, { className: "mr-4 h-[24px] w-[24px] pb-1 text-lg text-negative" }))] }));
};
export default React.memo(LoadingStateAnimation);
