var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var InvisibleEyeIconSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.9494 8C4.23772 9.67959 6.05146 10.6667 7.99995 10.6667C9.94843 10.6667 11.7622 9.67959 13.0505 8C11.7622 6.32041 9.94843 5.33333 7.99995 5.33333C6.05146 5.33333 4.23772 6.32041 2.9494 8ZM1.55547 7.65527C1.41478 7.86368 1.41478 8.13632 1.55547 8.34473C3.05844 10.5711 5.38654 12 7.99995 12C10.6134 12 12.9415 10.5711 14.4444 8.34473C14.5851 8.13632 14.5851 7.86368 14.4444 7.65527C12.9415 5.42891 10.6134 4 7.99995 4C5.38654 4 3.05844 5.42891 1.55547 7.65527Z", fill: "#A7A8AC" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z", fill: "#A7A8AC" }), _jsx("path", { d: "M3.05027 3.99308C2.92009 3.8629 2.92009 3.65185 3.05027 3.52167L3.52167 3.05027C3.65185 2.92009 3.8629 2.92009 3.99308 3.05027L12.9498 12.007C13.0799 12.1371 13.0799 12.3482 12.9498 12.4784L12.4784 12.9498C12.3482 13.0799 12.1371 13.0799 12.007 12.9498L3.05027 3.99308Z", fill: "#A7A8AC" })] })); };
export var InvisibleEyeIcon = function (props) { return (_jsx(Icon, __assign({ component: InvisibleEyeIconSvg }, props))); };
