var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { uniqBy } from 'lodash';
import { Draggable, Droppable, } from 'react-beautiful-dnd';
import { FixedSizeList as List } from 'react-window';
import { DefaultBoardViewColumnPageSize } from 'src/shared';
import { usePageApi, useReverseCollectionApi } from 'src/hooks/api';
import { useCollectionComponent } from 'src/hooks/component';
import { defaultColumnColor } from './BoardViewColumn';
import RecordCard from './RecordCard';
var RecordCardList = function (_a) {
    var collectionId = _a.collectionId, column = _a.column, height = _a.height, index = _a.index, paginationBaseRequestBody = _a.paginationBaseRequestBody, visibleSchemaIds = _a.visibleSchemaIds, setColumns = _a.setColumns;
    var listBlockCollection = usePageApi().listBlockCollection;
    var listReverseCollection = useReverseCollectionApi().listReverseCollection;
    var processLoadMoreBoardViewNonSchemaNativeFieldsIntoState = useCollectionComponent().processLoadMoreBoardViewNonSchemaNativeFieldsIntoState;
    var listRef = useRef(null);
    useLayoutEffect(function () {
        var list = listRef.current;
        if (list) {
            list.scrollTo(0);
        }
    }, [index]);
    // Page start with 1 since we already fetched page 0 with total columns endpoint
    var _b = useState(1), listPage = _b[0], setListPage = _b[1];
    var canLoadMore = useRef(true);
    //! dragndrop resets pagination; temporary fix to allow paginating again
    useEffect(function () {
        setListPage(1);
        canLoadMore.current = true;
    }, [column.totalRecordsCount]);
    var listData = useMemo(function () {
        if (!column)
            return [];
        var rows = column.orderedRows;
        var cells = column.cells;
        return rows.map(function (record) {
            var _a;
            var recordInfo = cells[record.recordId];
            return {
                rid: record.recordId,
                recordColumnInfo: recordInfo,
                collectionId: collectionId,
                cardColor: (_a = column.color) !== null && _a !== void 0 ? _a : defaultColumnColor,
                columnId: column.id,
            };
        });
    }, [collectionId, column]);
    var handleScroll = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var listElement, pagination, request, resp, _a, collection, newRows_1, newCells_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    //Only load more when scroll down
                    if (event.scrollDirection !== 'forward')
                        return [2 /*return*/];
                    listElement = document.querySelector(".RecordCardList_".concat(column.id));
                    if (!listElement)
                        return [2 /*return*/];
                    if (!(canLoadMore.current &&
                        event.scrollOffset !== 0 &&
                        listElement.scrollHeight - (event.scrollOffset + listElement.offsetHeight) < 100)) return [3 /*break*/, 5];
                    canLoadMore.current = false;
                    pagination = {
                        page: listPage,
                        size: DefaultBoardViewColumnPageSize,
                    };
                    request = __assign(__assign({}, paginationBaseRequestBody), { 
                        //Don't support search for now
                        pagination: pagination, groupId: column.id });
                    if (!('blockId' in request)) return [3 /*break*/, 2];
                    return [4 /*yield*/, listBlockCollection(request)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, listReverseCollection(request)];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    resp = _a;
                    collection = 'blockId' in request
                        ? resp === null || resp === void 0 ? void 0 : resp.collection
                        : resp;
                    if (resp && (collection === null || collection === void 0 ? void 0 : collection.rows.length) !== 0) {
                        //If request success, increase page and add new records to list
                        setListPage(listPage + 1);
                        newRows_1 = uniqBy(__spreadArray(__spreadArray([], column.orderedRows, true), collection.rows, true), 'recordId');
                        newCells_1 = __assign(__assign({}, column.cells), collection.cells);
                        setColumns(function (prev) {
                            return prev.map(function (col) {
                                return col.id === column.id
                                    ? __assign(__assign({}, column), { orderedRows: newRows_1, cells: newCells_1 }) : col;
                            });
                        });
                        processLoadMoreBoardViewNonSchemaNativeFieldsIntoState(collection);
                        canLoadMore.current = true;
                    }
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [
        column,
        listBlockCollection,
        listPage,
        listReverseCollection,
        paginationBaseRequestBody,
        processLoadMoreBoardViewNonSchemaNativeFieldsIntoState,
        setColumns,
    ]);
    var recordCardWrapper = function (props) {
        var items = props.data, index = props.index, style = props.style;
        var card = items[index];
        if (!card)
            return _jsx(_Fragment, {});
        var draggableId = "".concat(card.rid, "+").concat(card.columnId);
        return (_jsx(Draggable, { draggableId: draggableId, index: index, children: function (provided, snapshot) {
                var isDragging = snapshot.isDragging;
                return (_jsx(RecordCard, { collectionId: card.collectionId, info: card, style: style, draggableProvided: provided, cardColor: card.cardColor, isDragging: isDragging, visibleSchemaIds: visibleSchemaIds }));
            } }, card.rid));
    };
    return (_jsx(Droppable, { droppableId: column.id, mode: "virtual", renderClone: function (provided, snapshot, rubric) {
            var _a, _b;
            return (_jsx(RecordCard, { collectionId: collectionId, info: listData[rubric.source.index], style: (_a = provided.draggableProps.style) !== null && _a !== void 0 ? _a : {}, draggableProvided: provided, isDragging: snapshot.isDragging, cardColor: (_b = column.color) !== null && _b !== void 0 ? _b : defaultColumnColor, visibleSchemaIds: visibleSchemaIds }));
        }, children: function (provided, snapshot) {
            // Add an extra item to our list to make space for a dragging item
            // Usually the DroppableProvided.placeholder does this, but that won't
            // work in a virtual list
            var itemCount = snapshot.isUsingPlaceholder
                ? column.orderedRows.length + 1
                : column.orderedRows.length;
            return (_jsx(List, { className: "RecordCardList_".concat(column.id), style: { marginTop: 15 }, height: height, itemCount: itemCount, itemSize: 130, width: 240, outerRef: provided.innerRef, itemData: listData, onScroll: handleScroll, ref: listRef, children: recordCardWrapper }));
        } }));
};
export default React.memo(RecordCardList);
