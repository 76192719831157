import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecordDetail } from 'src/hooks/component';
var OpenRecordButton = function (_a) {
    var recordId = _a.recordId;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    return (_jsx("div", { className: "OpenDetailButton", onClick: function (e) {
            e.stopPropagation();
            openRecordDetail(recordId);
        }, children: "OPEN" }));
};
export default React.memo(OpenRecordButton);
