import { atom } from 'recoil';
var isAutomationPageModalVisibleState = atom({
    key: 'isAutomationModalVisibleState',
    default: false,
});
var isSingleAutomationModalVisibleState = atom({
    key: 'isSingleAutomationModalVisibleState',
    default: false,
});
var automationModalPropsState = atom({
    key: 'automationModalPropsState',
    default: {
        isActive: false,
        automationId: undefined,
        name: undefined,
        isDefault: false,
        isRecipeCustom: false,
    },
});
var automationPageTabKeyState = atom({
    key: 'automationPageTabKeyState',
    default: 'Manage',
});
export { isAutomationPageModalVisibleState, isSingleAutomationModalVisibleState, automationModalPropsState, automationPageTabKeyState, };
