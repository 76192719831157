import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { useDemandRatioApi } from 'src/hooks/api/useDemandRatioApi';
import { useDemandRatioComponent } from 'src/hooks/component/useDemandRatio';
import CensusNumberEditPopover from './CensusNumberEditPopover';
var CensusInputCell = function (_a) {
    var date = _a.date, locationRid = _a.locationRid, censusValue = _a.censusValue;
    var _b = useState(false), isEditPopoverOpen = _b[0], setIsEditPopoverOpen = _b[1];
    var useUpdateDemandRatioCensusValue = useDemandRatioApi().useUpdateDemandRatioCensusValue;
    var refetchDemandRatioStats = useDemandRatioComponent().refetchDemandRatioStats;
    var onSetCensusValue = useCallback(function (newValue, applyToAll) {
        var request = {
            locationRid: locationRid,
            override: {},
        };
        if (applyToAll) {
            request.default = newValue;
        }
        else {
            request.override[date] = newValue;
        }
        useUpdateDemandRatioCensusValue.mutate(request, {
            onSuccess: function () {
                refetchDemandRatioStats();
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationRid, date]);
    var cellContent = (_jsx("div", { className: "relative h-full border-b border-r border-[#F8F8F9] bg-[#F8F8F9] px-[4px] cursor-pointer", onClick: function () { return setIsEditPopoverOpen(true); }, children: _jsx("div", { className: "mx-[4px] my-[6px] flex items-center justify-center rounded py-[2px] bg-white", children: censusValue }) }));
    if (!isEditPopoverOpen) {
        return cellContent;
    }
    return (_jsx(CensusNumberEditPopover, { defaultValue: censusValue, setDefaultNumber: onSetCensusValue, onClose: function () { return setIsEditPopoverOpen(false); }, children: cellContent }));
};
export default React.memo(CensusInputCell);
