import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { first, isArray } from 'lodash';
import { collectionTableNativeSchemaSelector, collectionTableNonSchemaNativeFieldsState, scheduleTableState, scheduleTimeRangeState, scheduleTimeZoneSelector, tableCollectionIdSelector, tableDisplaySettingsState, } from 'src/state';
import { useAccountApi } from 'src/hooks/api';
import { useDemandRatioApi } from 'src/hooks/api/useDemandRatioApi';
import { CollectionTableType, NativePath } from 'src/shared';
import { calculateTotalActualHours } from '../Utils';
import RatioConfigModal from './ConfigModal/RatioConfigModal';
import RatioSummaryRow from './RatioSummaryRow';
import './RatioSummary.scss';
import LocationFiltersWarningContent from './LocationFiltersWarningContent';
import { currentRatioSummaryLocationRidState, isRatioConfigModalVisibleState } from './Atom';
var RatioSummary = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var blockId = _a.blockId, widths = _a.widths;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var roleSchemaId = (_c = (_b = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.SHIFT_ROLE_IDS,
    }))) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : '';
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var scheduleTimeZone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var table = useRecoilValue(scheduleTableState(blockId));
    var nonSchemaNativeFields = useRecoilValue(collectionTableNonSchemaNativeFieldsState({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var setCurrentLocationRid = useSetRecoilState(currentRatioSummaryLocationRidState);
    var locationFilterNumber = useMemo(function () {
        var total = 0;
        displaySettings.filters.map(function (filter) {
            if (filter.clauseType === 'native_location_id' &&
                filter.clauseValue === 'option_contains') {
                // For one filter setting entry contains multi location options
                if (isArray(filter.filterValue)) {
                    total += filter.filterValue.length;
                }
                else {
                    total++;
                }
            }
        });
        return total;
    }, [displaySettings.filters]);
    var _o = useDemandRatioApi(), useGetDemandRatio = _o.useGetDemandRatio, useGetSchedulingDemandRatioStats = _o.useGetSchedulingDemandRatioStats;
    var _p = useGetSchedulingDemandRatioStats({
        body: {
            blockId: blockId,
            displaySettings: displaySettings,
            scheduleTimeZone: scheduleTimeZone,
            startFrom: timeRange[0],
            endAt: timeRange[timeRange.length - 1],
        },
        options: { enabled: locationFilterNumber === 1 },
    }), demandRatioStats = _p.data, refetch = _p.refetch;
    useEffect(function () {
        if (table && locationFilterNumber === 1) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table, nonSchemaNativeFields, locationFilterNumber]);
    // Ratio data for all roles
    var demandRatioData = useGetDemandRatio({
        body: displaySettings.filters,
        options: { enabled: locationFilterNumber === 1 },
    }).data;
    useEffect(function () {
        if ((demandRatioData === null || demandRatioData === void 0 ? void 0 : demandRatioData.length) === 1) {
            var location_1 = first(demandRatioData);
            setCurrentLocationRid(location_1 === null || location_1 === void 0 ? void 0 : location_1.locationRid);
        }
        else {
            setCurrentLocationRid(undefined);
        }
    }, [demandRatioData, setCurrentLocationRid]);
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var roleList = useGetUniversalSimpleList({
        body: {
            schemaId: roleSchemaId,
        },
        options: {
            enabled: !!roleSchemaId,
        },
    }).data;
    var isConfigModalVisible = useRecoilValue(isRatioConfigModalVisibleState);
    var containsMultiLocation = (demandRatioData && demandRatioData.length > 1) || locationFilterNumber > 1;
    var emptyData = locationFilterNumber === 0 ||
        displaySettings.filters.length === 0 ||
        (demandRatioData === null || demandRatioData === void 0 ? void 0 : demandRatioData.length) === 0;
    var availableHppdData = useMemo(function () {
        var _a, _b;
        return (_b = (_a = first(demandRatioData)) === null || _a === void 0 ? void 0 : _a.ratio) !== null && _b !== void 0 ? _b : [];
    }, [demandRatioData]);
    if (containsMultiLocation || emptyData) {
        return (_jsxs(_Fragment, { children: [_jsx(LocationFiltersWarningContent, { noData: emptyData }), isConfigModalVisible && _jsx(RatioConfigModal, { blockId: blockId })] }));
    }
    return (_jsxs("div", { className: "SummaryScrollContainer", style: { width: widths.tableWidth }, children: [_jsx(RatioSummaryRow, { blockId: blockId, type: "census", widths: widths, locationRid: (_e = (_d = first(demandRatioData)) === null || _d === void 0 ? void 0 : _d.locationRid) !== null && _e !== void 0 ? _e : '', name: "Census", censusData: demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.census }, 'CensusSettingRow'), _jsx(RatioSummaryRow, { blockId: blockId, type: "totalHours", widths: widths, locationRid: (_g = (_f = first(demandRatioData)) === null || _f === void 0 ? void 0 : _f.locationRid) !== null && _g !== void 0 ? _g : '', name: "Hours (Actual/Budgeted)", totalHoursData: calculateTotalActualHours(timeRange, (_h = demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.stats) !== null && _h !== void 0 ? _h : []), hppdData: (_j = first(demandRatioData)) === null || _j === void 0 ? void 0 : _j.ratio, censusData: demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.census }, 'TotalHoursRow'), demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.stats.map(function (role) {
                var _a, _b, _c;
                var roleItem = roleList === null || roleList === void 0 ? void 0 : roleList.find(function (item) { return item.id === role.roleRid; });
                if (roleItem) {
                    return (_jsx(RatioSummaryRow, { blockId: blockId, type: "hours", widths: widths, locationRid: (_b = (_a = first(demandRatioData)) === null || _a === void 0 ? void 0 : _a.locationRid) !== null && _b !== void 0 ? _b : '', name: roleItem.name, roleRid: roleItem.id, color: roleItem.color, demandRatioStatData: role.data, hppdData: (_c = first(demandRatioData)) === null || _c === void 0 ? void 0 : _c.ratio, censusData: demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.census }, roleItem.id));
                }
            }), _jsx(RatioSummaryRow, { blockId: blockId, type: "hppd", widths: widths, locationRid: (_l = (_k = first(demandRatioData)) === null || _k === void 0 ? void 0 : _k.locationRid) !== null && _l !== void 0 ? _l : '', name: "HPPD", totalHoursData: calculateTotalActualHours(timeRange, (_m = demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.stats) !== null && _m !== void 0 ? _m : []), roleNumber: availableHppdData.length, hppdData: availableHppdData, censusData: demandRatioStats === null || demandRatioStats === void 0 ? void 0 : demandRatioStats.census }, 'HppdRow'), isConfigModalVisible && _jsx(RatioConfigModal, { blockId: blockId })] }));
};
export default React.memo(RatioSummary);
