var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ShiftTemplateIconSvg = function () { return (_jsxs("svg", { width: "16", height: "13", viewBox: "0 0 16 13", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 1.86719V3.97656H10.25V1.86719H2ZM0.875 0.460938C0.667893 0.460938 0.5 0.618338 0.5 0.8125V5.03125C0.5 5.22541 0.667894 5.38281 0.875 5.38281H11.375C11.5821 5.38281 11.75 5.22541 11.75 5.03125V0.8125C11.75 0.618337 11.5821 0.460938 11.375 0.460938H0.875Z", fill: "currentColor" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 8.89844V11.0078H10.25V8.89844H2ZM0.875 7.49219C0.667893 7.49219 0.5 7.64959 0.5 7.84375V12.0625C0.5 12.2567 0.667894 12.4141 0.875 12.4141H11.375C11.5821 12.4141 11.75 12.2567 11.75 12.0625V7.84375C11.75 7.64959 11.5821 7.49219 11.375 7.49219H0.875Z", fill: "currentColor" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.75391 5.38281V7.49219H14.0009V5.38281H5.75391ZM4.62891 3.97656C4.4218 3.97656 4.25391 4.13396 4.25391 4.32813V8.54688C4.25391 8.74104 4.4218 8.89844 4.62891 8.89844H15.1259C15.333 8.89844 15.5009 8.74104 15.5009 8.54688V4.32813C15.5009 4.13396 15.333 3.97656 15.1259 3.97656H4.62891Z", fill: "currentColor" })] })); };
export var ShiftTemplateIcon = function (props) { return (_jsx(Icon, __assign({ component: ShiftTemplateIconSvg }, props))); };
