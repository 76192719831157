var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DragHandlerSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "5", y: "3", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "5", y: "7", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "5", y: "11", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "9", y: "3", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "9", y: "7", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "9", y: "11", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" })] })); };
export var DragHandlerIcon = function (props) { return (_jsx(Icon, __assign({ component: DragHandlerSvg }, props))); };
