var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ArrowRightSmall = function () { return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11.646 8.81847C11.4507 8.62321 11.4507 8.30663 11.646 8.11137L12.3531 7.40426C12.5483 7.209 12.8649 7.209 13.0602 7.40426L17.3028 11.6469C17.4981 11.8422 17.4981 12.1587 17.3028 12.354L13.0602 16.5966C12.8649 16.7919 12.5483 16.7919 12.3531 16.5966L11.646 15.8895C11.4507 15.6943 11.4507 15.3777 11.646 15.1824L13.8278 13.0006H7.07813C6.80198 13.0006 6.57813 12.7767 6.57812 12.5006L6.57813 11.5006C6.57813 11.2244 6.80198 11.0006 7.07812 11.0006H13.828L11.646 8.81847Z", fill: "#A7A8AC" }) })); };
export var ArrowRightSmallIcon = function (props) { return (_jsx(Icon, __assign({ component: ArrowRightSmall }, props))); };
