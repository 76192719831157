import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
import TaskGroupCompletionStatus from 'src/components/Settings/Tasks/shared/TaskGroupCompletionStatus';
import { MoreOutlined } from '@ant-design/icons';
import TaskMenuPopover from 'src/components/Settings/Tasks/shared/TaskMenuPopover';
import { useTasksApi } from 'src/hooks/api';
import { OPTIONS_COLOR_MAP } from 'src/shared';
import { getTagTextColor } from 'src/utils/Collection';
import moment from 'moment';
import RecordDetailTask from './RecordDetailTask';
var RecordDetailTaskGroup = function (_a) {
    var taskGroup = _a.taskGroup, refetchTasks = _a.refetchTasks;
    var id = taskGroup.id, name = taskGroup.name, iconInfo = taskGroup.iconInfo, orderedTasks = taskGroup.orderedTasks, dueDate = taskGroup.dueDate;
    var tasksCompleted = orderedTasks.filter(function (task) { return task.isCompleted; }).length;
    var isCompleted = tasksCompleted === orderedTasks.length;
    var useUnassignTaskGroupFromUser = useTasksApi().useUnassignTaskGroupFromUser;
    var unassignTaskGroup = useCallback(function () {
        useUnassignTaskGroupFromUser.mutate(id, {
            onSuccess: function (resp) {
                if (resp) {
                    refetchTasks();
                    return;
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    var isDue = !isCompleted && !!dueDate;
    var isPastDue = !isCompleted && moment(dueDate).isBefore();
    return (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "TaskGroupHeader flex items-center", children: [_jsx(CustomIconInline, { iconUrl: iconInfo.content, iconSize: 24, backgroundColor: iconInfo.backgroundColor, className: "rounded-[20px] !min-h-[60px] !min-w-[60px] mr-[16px]" }), _jsxs("div", { className: "flex flex-col items-start", children: [_jsx("div", { children: name }), _jsx(TaskGroupCompletionStatus, { completed: tasksCompleted, total: orderedTasks.length })] }), isDue && (_jsx("div", { className: "flex items-center ml-auto rounded-2xl bg-gray-50 px-[12px] py-[4px] text-sm font-medium", style: isPastDue
                            ? {
                                color: getTagTextColor(OPTIONS_COLOR_MAP.Red),
                                backgroundColor: OPTIONS_COLOR_MAP.Red,
                            }
                            : undefined, children: "Due ".concat(moment(dueDate).fromNow()) })), _jsx(TaskMenuPopover, { content: _jsx("div", { className: "TaskMenuItem text-red-1000", onClick: unassignTaskGroup, children: "Unassign" }), children: _jsx("div", { className: "mr-[32px] cursor-pointer", style: dueDate ? { marginLeft: '16px' } : { marginLeft: 'auto' }, onClick: function (e) { return e.stopPropagation(); }, children: _jsx(MoreOutlined, { className: "text-[32px]" }) }) })] }), _jsx("div", { className: "flex flex-col space-y-[16px]", children: orderedTasks.map(function (task) { return (_jsx(RecordDetailTask, { task: task, isGroupTask: true, refetchTasks: refetchTasks }, task.id)); }) })] }));
};
export default React.memo(RecordDetailTaskGroup);
