import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button, Modal } from 'antd';
var BACKFILL_MODAL_Z_INDEX = 1100;
var SchemaBackfillModal = function (props) {
    var columnName = props.columnName, onModalClose = props.onModalClose, onModalCloseAndSave = props.onModalCloseAndSave, onBackfillSchema = props.onBackfillSchema;
    return (_jsx("div", { onClick: function (e) { return e.stopPropagation(); }, children: _jsx(Modal, { width: 500, zIndex: BACKFILL_MODAL_Z_INDEX, open: true, centered: true, closable: false, bodyStyle: { padding: '16px' }, onOk: function () {
                onBackfillSchema();
                onModalClose();
            }, onCancel: onModalClose, okText: "Regenerate", destroyOnClose: true, okButtonProps: {
                type: 'default',
                className: 'ZiraModalSaveBtn',
            }, footer: [
                _jsx(Button, { onClick: onModalClose, children: "Cancel" }, "Cancel"),
                _jsx(Button, { onClick: onModalCloseAndSave, children: "Save Without Regenerating" }, "Save Without Regenerating"),
                _jsx(Button, { type: "default", className: "ZiraModalSaveBtn", onClick: function () {
                        onBackfillSchema();
                        onModalClose();
                    }, children: "Save and Regenerate" }, "Save and Regenerate"),
            ], maskClosable: false, children: _jsxs("div", { className: "flex flex-col space-y-4", children: [_jsxs("div", { className: "ModalTitle space-x-2", children: ["Regenerate existing records for ", columnName, "?"] }), "This will regenerate all rows in the column based on the updated configuration."] }) }) }));
};
export default SchemaBackfillModal;
