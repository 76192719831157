import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { CollectionPermissionLevelOptions } from '../Constants';
import ObjectPermissionDropdown from './ObjectPermissionDropdown';
import CollectionPermissionContent from './CollectionPermissionContent';
var CollectionPermissionDropdown = function (_a) {
    var currentPermission = _a.currentPermission, className = _a.className, labelClassName = _a.labelClassName, onUpdate = _a.onUpdate, canUpdate = _a.canUpdate;
    var permissionText = useMemo(function () { return CollectionPermissionLevelOptions(currentPermission); }, [currentPermission]);
    return (_jsx(ObjectPermissionDropdown, { overlay: _jsx(CollectionPermissionContent, { onChange: onUpdate, currentPermission: currentPermission }), title: permissionText, className: className, labelClassName: labelClassName, canUpdate: canUpdate }));
};
export default React.memo(CollectionPermissionDropdown);
