import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { NativePath, ValueDataType } from 'src/shared';
import { useScheduleComponent } from 'src/hooks/component';
import { collectionTableSchemaNativePathMapSelector, collectionTableSchemasState } from 'src/state';
import { scheduleColorCodingSelector } from 'src/state/selectors/schedule';
import './TableColorCoding.scss';
import { NEW_SHIFT_TEMPLATE } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import ColorCodingEntry from './ColorCodingEntry';
var TableColorCoding = function (_a) {
    var tableId = _a.tableId, collectionId = _a.collectionId, type = _a.type;
    var colorCodingSchemaId = useRecoilValue(scheduleColorCodingSelector(tableId));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(collectionId));
    var schemaList = useRecoilValue(collectionTableSchemasState(collectionId));
    var locationSchema = nativeSchemaMap[NativePath.SHIFT_LOCATION_ID];
    var rolesSchema = nativeSchemaMap[NativePath.SHIFT_ROLE_IDS];
    var templateSchema = nativeSchemaMap[NativePath.SHIFT_TEMPLATE_ID];
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var colorCodingSchemas = useMemo(function () {
        var schemas = [{ id: 'status', name: 'Status' }];
        if (locationSchema) {
            schemas.push({ id: locationSchema.id, name: locationSchema.name });
        }
        if (rolesSchema) {
            schemas.push({ id: rolesSchema.id, name: rolesSchema.name });
        }
        if (templateSchema && isNewShiftTemplateEnabled) {
            schemas.push({ id: templateSchema.id, name: templateSchema.name });
        }
        var plainSelectSchemas = schemaList.filter(function (schema) { return schema.type === ValueDataType.PLAIN_SELECTION; });
        plainSelectSchemas.forEach(function (schema) {
            schemas.push({ id: schema.id, name: schema.name });
        });
        return schemas;
    }, [isNewShiftTemplateEnabled, locationSchema, rolesSchema, schemaList, templateSchema]);
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var onTogglePrimarySchema = useCallback(function (schemaId) {
        applyScheduleDisplaySettings(tableId, { colorCoding: schemaId }, type);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId, type]);
    var generateEntries = function () {
        return colorCodingSchemas.map(function (schema, index) {
            return (_jsxs("span", { children: [_jsx(ColorCodingEntry, { name: schema.name, isOn: colorCodingSchemaId === schema.id, onToggle: function () { return onTogglePrimarySchema(schema.id); } }), index != colorCodingSchemas.length - 1 && (_jsx(Divider, { className: "color-coding-entry-divider" }))] }, schema.id));
        });
    };
    var dropDownRender = function () {
        return (_jsxs("div", { className: "TableColorCodingContent", children: [_jsx("div", { className: "SubTitleWrapper", children: "Choose how you would like to color code shifts on the calendar." }), _jsx("div", { className: "TableColorCodingEntries", children: generateEntries() })] }));
    };
    return (_jsx(Select, { className: "TableColorCoding OptionSelect", dropdownMatchSelectWidth: 250, popupClassName: "DropdownSelect", bordered: false, suffixIcon: null, placeholder: _jsx("span", { className: "OptionTitle", children: "Color Coding" }), dropdownRender: dropDownRender }));
};
export default React.memo(TableColorCoding);
