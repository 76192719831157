var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Layout, Tabs } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
var Content = Layout.Content;
import './AutomationsPage.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { automationModalPropsState, automationPageTabKeyState, isSingleAutomationModalVisibleState, } from 'src/state/atoms/automationModalState';
import { useAutomation } from '../../../hooks/api/useAutomationApi';
import { AutomationListType, } from '../../Automations/Interface';
import { NaviBarAddButton } from '../../../utils/icons/NaviBarAddButton';
import TemplateBannerOne from '../../../utils/icons/images/TemplateBannerOne.png';
import TemplateBannerTwo from '../../../utils/icons/images/TemplateBannerTwo.png';
import TemplateBannerThree from '../../../utils/icons/images/TemplateBannerThree.png';
import TemplateBannerFour from '../../../utils/icons/images/TemplateBannerFour.png';
import TemplateBannerFive from '../../../utils/icons/images/TemplateBannerFive.png';
import { automationTags } from '../../../utils/Automation';
import AutomationModal from './AutomationModal';
import AutomationUsage from './AutomationUsage';
import AutomationCard from './AutomationCard';
var TabPane = Tabs.TabPane;
var templateCategories = [
    'Onboarding',
    'Training',
    'Scheduling',
    'Time Tracking',
    'Performance',
    'Pay',
];
var templateBannerColorMap = {
    Onboarding: '#FF4A43',
    Training: '#FD7238',
    Scheduling: '#FFC047',
    'Time Tracking': '#00A76F',
    Performance: '#167EE4',
    Pay: '#5C5396',
};
var templateBannerImageMap = {
    Onboarding: TemplateBannerOne,
    Training: TemplateBannerFour,
    'Time Tracking': TemplateBannerTwo,
    Scheduling: TemplateBannerThree,
    Performance: TemplateBannerFour,
    Pay: TemplateBannerFive,
};
var AutomationsPage = function () {
    var accountId = useRecoilValue(accountIdState);
    var _a = useAutomation(), useGetAutomationList = _a.useGetAutomationList, useGetDefaultsList = _a.useGetDefaultsList;
    var _b = useGetAutomationList({
        accountId: accountId,
        type: AutomationListType.ACCOUNT,
    }), automationList = _b.data, refetchAutomationList = _b.refetch;
    var automationDefaultList = useGetDefaultsList().data;
    var automationTemplateList = useMemo(function () {
        var _a, _b;
        return (_b = (_a = automationDefaultList === null || automationDefaultList === void 0 ? void 0 : automationDefaultList.defaultCategories) === null || _a === void 0 ? void 0 : _a.slice().sort(function (a, b) {
            return templateCategories.indexOf(a.categoryTitle) -
                templateCategories.indexOf(b.categoryTitle);
        })) !== null && _b !== void 0 ? _b : [];
    }, [automationDefaultList === null || automationDefaultList === void 0 ? void 0 : automationDefaultList.defaultCategories]);
    var _c = useRecoilState(automationPageTabKeyState), activeTabKey = _c[0], setActiveTabKey = _c[1];
    var _d = automationList !== null && automationList !== void 0 ? automationList : {}, _e = _d.activeAutomations, activeAutomations = _e === void 0 ? [] : _e, _f = _d.inactiveAutomations, inactiveAutomations = _f === void 0 ? [] : _f;
    var _g = useRecoilState(isSingleAutomationModalVisibleState), isAutomationModalVisible = _g[0], setIsAutomationModalVisible = _g[1];
    var _h = useRecoilState(automationModalPropsState), automationProps = _h[0], setAutomationProps = _h[1];
    var openDefaultAutomationModal = useCallback(function (defaultAutomation) {
        var _a = defaultAutomation !== null && defaultAutomation !== void 0 ? defaultAutomation : {}, id = _a.id, description = _a.description;
        setAutomationProps({
            isActive: true,
            automationId: id,
            name: description,
            isDefault: true,
            isRecipeCustom: false,
        });
        setIsAutomationModalVisible(true);
    }, [setAutomationProps, setIsAutomationModalVisible]);
    var openAutomationModal = useCallback(function (isActive, automation) {
        var _a = automation !== null && automation !== void 0 ? automation : {}, id = _a.id, name = _a.name;
        setAutomationProps({
            isActive: isActive !== null && isActive !== void 0 ? isActive : false,
            automationId: id,
            name: name,
            isDefault: false,
            isRecipeCustom: false,
        });
        setIsAutomationModalVisible(true);
    }, [setAutomationProps, setIsAutomationModalVisible]);
    var onCloseAutomationModal = useCallback(function () {
        setIsAutomationModalVisible(false);
    }, [setIsAutomationModalVisible]);
    var onRefreshAutomationList = useCallback(function () {
        refetchAutomationList();
    }, [refetchAutomationList]);
    var _j = useState(templateCategories[0]), activeTemplateTabKey = _j[0], setActiveTemplateTabKey = _j[1];
    var templateTabs = useCallback(function () {
        return (_jsx(Tabs, { className: "TemplateTabs ".concat(activeTemplateTabKey), activeKey: activeTemplateTabKey, onChange: function (activeTabKey) {
                var containerEle = document.querySelector(".AutomationsLayoutWrapper");
                var templateBanner = containerEle === null || containerEle === void 0 ? void 0 : containerEle.querySelector("#".concat(activeTabKey.split(' ')[0]));
                containerEle === null || containerEle === void 0 ? void 0 : containerEle.scrollTo({
                    top: (templateBanner === null || templateBanner === void 0 ? void 0 : templateBanner.offsetTop) - 102,
                    behavior: 'smooth',
                });
                setActiveTemplateTabKey(activeTabKey);
            }, children: automationTemplateList.map(function (category) {
                return (_jsx(TabPane, { tab: category.categoryTitle }, category.categoryTitle));
            }) }));
    }, [activeTemplateTabKey, automationTemplateList]);
    var templateBanner = useCallback(function (templateCategory) {
        return (_jsxs("div", { className: "TemplateBanner", style: { background: templateBannerColorMap[templateCategory] }, id: templateCategory.split(' ')[0], children: [_jsx("img", { className: "TemplateBannerImage", src: templateBannerImageMap[templateCategory] }), _jsxs("div", { className: "TemplateBannerHeader", children: [_jsx("div", { className: "TemplateBannerTitle", children: templateCategory }), _jsxs("div", { className: "TemplateBannerSubtitle", children: ["Automate your team ", templateCategory.toLowerCase(), " with the top workflows"] })] })] }));
    }, []);
    var renderActiveAutomations = useCallback(function () {
        return activeAutomations.map(function (automation) {
            return _jsx(AutomationCard, { automation: automation }, automation.id);
        });
    }, [activeAutomations]);
    var renderDefaultAutomations = useCallback(function (defaultCards) {
        return defaultCards.map(function (defaultCard) {
            return (_jsx(Button, { className: "AutomationCard", onClick: function () { return openDefaultAutomationModal(defaultCard); }, children: _jsxs("span", { className: "AutomationCardContent", children: [_jsxs("span", { className: "AutomationTags", children: [automationTags(defaultCard.triggerCategories), !!defaultCard.actionCategories.length && (_jsxs(_Fragment, { children: [_jsx(ArrowRightOutlined, {}), automationTags(defaultCard.actionCategories)] }))] }), _jsx("span", { className: "AutomationName default", children: defaultCard.description }), _jsx("span", { className: "AutomationView", children: "Turn On" })] }) }, defaultCard.id));
        });
    }, [openDefaultAutomationModal]);
    var renderInactiveAutomations = useCallback(function () {
        return inactiveAutomations.map(function (automation) {
            return _jsx(AutomationCard, { automation: automation }, automation.id);
        });
    }, [inactiveAutomations]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "AutomationsPageHeader", children: _jsxs(Tabs, { activeKey: activeTabKey, onChange: setActiveTabKey, children: [_jsx(TabPane, { tab: 'Templates' }, 'Templates'), _jsx(TabPane, { tab: 'Manage' }, 'Manage'), _jsx(TabPane, { tab: 'Usage' }, 'Usage')] }) }), _jsxs(_Fragment, { children: [activeTabKey === 'Manage' && (_jsx("div", { className: "AutomationsLayoutWrapper", children: _jsxs(Content, { children: [_jsxs("div", { className: "AutomationSection", children: [_jsx("div", { className: "AutomationsPageTitle", id: 'Active Automations', children: "Active Automations" }), _jsxs("div", { className: "AutomationCardList", children: [_jsx(Button, { className: "AddNewCard", icon: _jsx(NaviBarAddButton, {}), onClick: function () { return openAutomationModal(true); }, children: "Add New" }), renderActiveAutomations()] })] }), _jsxs("div", { className: "AutomationSection", children: [_jsx("div", { className: "AutomationsPageTitle", id: 'Inactive Automations', children: "Inactive Automations" }), _jsxs("div", { className: "AutomationCardList", children: [_jsx(Button, { className: "AddNewCard", icon: _jsx(NaviBarAddButton, {}), onClick: function () { return openAutomationModal(false); }, children: "Add New" }), renderInactiveAutomations()] })] })] }) })), activeTabKey === 'Templates' && (_jsxs("div", { className: "AutomationsLayoutWrapper Templates", children: [templateTabs(), _jsx(Content, { children: automationTemplateList.map(function (template, idx) {
                                    return (_jsxs("div", { className: "AutomationSection", children: [templateBanner(template.categoryTitle), _jsx("div", { className: "AutomationCardList", children: renderDefaultAutomations(template.defaultCards) })] }, "defaultCategory-".concat(idx)));
                                }) })] })), activeTabKey === 'Usage' && (_jsx("div", { className: "AutomationsLayoutWrapper align-center flex justify-center pb-0 pt-0", children: _jsx(AutomationUsage, {}) }))] }), isAutomationModalVisible && (_jsx(AutomationModal, __assign({}, automationProps, { closeModal: onCloseAutomationModal, refreshAutomationList: onRefreshAutomationList })))] }));
};
export default React.memo(AutomationsPage);
