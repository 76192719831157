var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
import { EllipsisOutlined } from '@ant-design/icons';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
import { Switch } from 'antd';
import { useTasksComponent } from '../../hooks/useTasks';
import { taskGroupSelector } from '../../selector';
import TaskMenuPopover from '../../shared/TaskMenuPopover';
import TaskListItemTag from '../TaskTags/TaskListItemTag';
var TaskGroupDragCard = function (_a) {
    var _b, _c, _d;
    var taskGroupId = _a.taskGroupId, index = _a.index, onRemoveTaskGroup = _a.onRemoveTaskGroup, draggableProvided = _a.draggableProvided, isPreRequirement = _a.isPreRequirement, onToggleCountCompletion = _a.onToggleCountCompletion;
    var taskGroup = useRecoilValue(taskGroupSelector(taskGroupId));
    var viewTaskGroup = useTasksComponent().viewTaskGroup;
    return (_jsxs("div", __assign({ className: "flex items-center" }, draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.draggableProps, { ref: draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.innerRef, children: [_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, draggableProvided.dragHandleProps, { className: "mr-[4px]" })), _jsxs("div", { className: "w-full flex justify-between border border-gray-60 p-[16px] rounded-lg items-center cursor-pointer bg-white", children: [_jsx(CustomIconInline, { iconUrl: (_b = taskGroup === null || taskGroup === void 0 ? void 0 : taskGroup.iconInfo.content) !== null && _b !== void 0 ? _b : '', iconSize: 16, backgroundColor: (_c = taskGroup === null || taskGroup === void 0 ? void 0 : taskGroup.iconInfo.backgroundColor) !== null && _c !== void 0 ? _c : '', className: "rounded-lg !min-h-[32px] !min-w-[32px] mr-[12px] self-start" }), _jsxs("div", { className: "w-full flex flex-col items-start grow min-w-0", children: [_jsxs("div", { className: "flex items-center w-full", children: [_jsx("div", { className: "TaskItemTitle", style: !taskGroup ? { color: 'red' } : undefined, children: (_d = taskGroup === null || taskGroup === void 0 ? void 0 : taskGroup.name) !== null && _d !== void 0 ? _d : 'Deleted Task Group' }), _jsxs("div", { className: "flex items-center ml-auto gap-2", children: [taskGroup && (_jsx(TaskListItemTag, { backgroundColor: "#EFEFEF", children: _jsxs("span", { className: "text-[#686868]", children: [taskGroup.orderedTaskIds.length, " tasks"] }) })), _jsx(TaskMenuPopover, { content: _jsxs(_Fragment, { children: [_jsx("div", { className: "TaskMenuItem", onClick: function () {
                                                                if (!taskGroup)
                                                                    return;
                                                                viewTaskGroup(taskGroup.id);
                                                            }, children: "View Task Group" }), _jsx("div", { className: "TaskMenuItem text-red-1000", onClick: function () {
                                                                onRemoveTaskGroup(index);
                                                            }, children: "Remove Task Group" })] }), children: _jsx("div", { className: "rounded-md p-1 cursor-pointer hover:bg-gray-60 self-start mt-[-4px]", onClick: function (e) {
                                                        e.stopPropagation();
                                                    }, children: _jsx(EllipsisOutlined, { className: "text-[24px]" }) }) })] })] }), _jsxs("div", { className: "w-full flex justify-between mt-1", children: [_jsx("div", { className: "TaskItemSubtitle", children: taskGroup === null || taskGroup === void 0 ? void 0 : taskGroup.displayId }), onToggleCountCompletion && (_jsxs("div", { className: "flex", children: [_jsx("div", { children: "Pre-compliance" }), _jsx("div", { children: _jsx(Switch, { className: "m-0 self-start ml-3", checked: isPreRequirement, onChange: function () {
                                                        onToggleCountCompletion(taskGroupId, !isPreRequirement);
                                                    } }) })] }))] })] })] })] })));
};
export default React.memo(TaskGroupDragCard);
