import { CollectionType, OPTIONS_COLOR_MAP, ValueDataType } from 'src/shared';
import { cloneDeep } from 'lodash';
import { ActionType, MessageActionTypeSet, TargetType, } from '../Automations/Interface';
export var getRecipeCollectionTypeColor = function (type) {
    switch (type) {
        case CollectionType.CUSTOM:
            return OPTIONS_COLOR_MAP.Blue;
        case CollectionType.USER:
        case CollectionType.ROLE:
            return OPTIONS_COLOR_MAP.Green;
        case CollectionType.TIMEOFF:
            return OPTIONS_COLOR_MAP.Brown;
        case CollectionType.SHIFT:
            return OPTIONS_COLOR_MAP.Purple;
        case CollectionType.BREAK:
            return OPTIONS_COLOR_MAP.Default;
        case CollectionType.LOCATION:
        case CollectionType.CONTACT:
            return OPTIONS_COLOR_MAP.Yellow;
        case CollectionType.PROJECT:
            return OPTIONS_COLOR_MAP.Gray;
        case CollectionType.DOCUMENT:
            return OPTIONS_COLOR_MAP.Orange;
        case CollectionType.SHIFT_GROUP:
            return OPTIONS_COLOR_MAP.Default;
        default:
            return OPTIONS_COLOR_MAP.Default;
    }
};
export var getRecipeValueTypeColor = function (type) {
    switch (type) {
        case ValueDataType.PLAIN_SELECTION:
        case ValueDataType.NATIVE_USER_ID:
        case ValueDataType.NATIVE_USER_IDS:
        case ValueDataType.NATIVE_TYPE_ID:
        case ValueDataType.NATIVE_LOCATION_ID:
        case ValueDataType.NATIVE_LOCATION_IDS:
        case ValueDataType.NATIVE_ACCESS_GROUP_IDS:
        case ValueDataType.NATIVE_ROLE_IDS:
        case ValueDataType.NATIVE_TIMEOFF_POLICY_ID:
        case ValueDataType.NATIVE_OVERTIME_POLICY_ID:
        case ValueDataType.NATIVE_BREAK_POLICY_ID:
        case ValueDataType.NATIVE_BREAK_RULE_ID:
        case ValueDataType.NATIVE_GEOFENCE_ID:
        case ValueDataType.NATIVE_GENERATED:
        case ValueDataType.GENERATED:
        case ValueDataType.CUSTOM:
        case ValueDataType.SHIFT:
        case ValueDataType.USER:
        case ValueDataType.LOCATION:
        case ValueDataType.ROLE:
        case ValueDataType.PAY_PERIOD:
        case ValueDataType.NATIVE_SELECTION:
            return OPTIONS_COLOR_MAP.Green;
        case ValueDataType.NUMBER:
        case ValueDataType.NATIVE_NUMBER:
        case ValueDataType.NATIVE_DURATION:
        case ValueDataType.METRIC:
        case ValueDataType.CURRENCY:
            return OPTIONS_COLOR_MAP.Orange;
        case ValueDataType.PHONE:
        case ValueDataType.NATIVE_PHONE:
            return OPTIONS_COLOR_MAP.Red;
        case ValueDataType.DATETIME:
        case ValueDataType.DATETIME_RANGE:
        case ValueDataType.NATIVE_DATETIME:
            return OPTIONS_COLOR_MAP.Yellow;
        case ValueDataType.BOOLEAN:
        case ValueDataType.NATIVE_BOOL:
            return OPTIONS_COLOR_MAP.Blue;
        case ValueDataType.NATIVE_EMAIL:
        case ValueDataType.EMAIL:
        case ValueDataType.NATIVE_URL:
        case ValueDataType.URL:
        case ValueDataType.TEXT:
        default:
            return OPTIONS_COLOR_MAP.Brown;
    }
};
export var replaceAutomationRecipeSchemaIds = function (automations, recipeSchemaIdMap) {
    var temp = cloneDeep(automations);
    var recipeSchemaIds = Object.keys(recipeSchemaIdMap);
    temp.forEach(function (automation) {
        automation.triggers.forEach(function (trigger) {
            var _a;
            if (!trigger.detail)
                return;
            var schemaId = (_a = trigger === null || trigger === void 0 ? void 0 : trigger.detail) === null || _a === void 0 ? void 0 : _a.schemaId;
            if (schemaId && recipeSchemaIds.includes(schemaId)) {
                // eslint-disable-next-line no-extra-semi
                ;
                trigger.detail.schemaId = recipeSchemaIdMap[schemaId];
            }
        });
        automation.filters.forEach(function (filter) {
            var _a, _b, _c, _d, _e;
            var filterSchemaId = (_a = filter.secondarySchemaId) !== null && _a !== void 0 ? _a : filter.schemaId;
            var valueSchemaId = (_c = (_b = filter.dynamicValue) === null || _b === void 0 ? void 0 : _b.dynamicSecondarySchemaId) !== null && _c !== void 0 ? _c : (_d = filter.dynamicValue) === null || _d === void 0 ? void 0 : _d.dynamicSchemaId;
            if (filterSchemaId && recipeSchemaIds.includes(filterSchemaId)) {
                if (filter.secondarySchemaId) {
                    filter.secondarySchemaId = recipeSchemaIdMap[filterSchemaId];
                }
                else {
                    filter.schemaId = recipeSchemaIdMap[filterSchemaId];
                }
            }
            if (valueSchemaId && recipeSchemaIds.includes(valueSchemaId)) {
                if (filter.dynamicValue.dynamicSecondarySchemaId) {
                    filter.dynamicValue.dynamicSecondarySchemaId = recipeSchemaIdMap[valueSchemaId];
                }
                else {
                    filter.dynamicValue.dynamicSchemaId = recipeSchemaIdMap[valueSchemaId];
                }
            }
            else if ((_e = filter.valueIds) === null || _e === void 0 ? void 0 : _e.length) {
                filter.valueIds.forEach(function (valueId) {
                    if (recipeSchemaIds.includes(valueId)) {
                        var index = filter.valueIds.indexOf(valueId);
                        filter.valueIds[index] = recipeSchemaIdMap[valueId];
                    }
                });
            }
        });
        automation.actions.forEach(function (action) {
            var _a, _b, _c, _d, _e, _f, _g;
            if (!action.detail)
                return;
            if (MessageActionTypeSet.has(action.type) &&
                action.detail.targetType === TargetType.AUTOMATION_DETERMINED) {
                var targetDetail = action.detail
                    .targetDetail;
                var schemaId = (_a = targetDetail.secondarySchemaId) !== null && _a !== void 0 ? _a : targetDetail.schemaId;
                if (schemaId && recipeSchemaIds.includes(schemaId)) {
                    if (targetDetail.secondarySchemaId) {
                        // eslint-disable-next-line no-extra-semi
                        ;
                        action.detail
                            .targetDetail.secondarySchemaId = recipeSchemaIdMap[schemaId];
                    }
                    else {
                        // eslint-disable-next-line no-extra-semi
                        ;
                        action.detail
                            .targetDetail.schemaId = recipeSchemaIdMap[schemaId];
                    }
                }
            }
            else if (action.type === ActionType.MODIFY_OBJECT) {
                var detail_1 = action.detail;
                var modifySchemaId = (_b = detail_1.secondarySchemaId) !== null && _b !== void 0 ? _b : detail_1.schemaId;
                var valueSchemaId = (_d = (_c = detail_1.dynamicValue) === null || _c === void 0 ? void 0 : _c.dynamicSecondarySchemaId) !== null && _d !== void 0 ? _d : (_e = detail_1.dynamicValue) === null || _e === void 0 ? void 0 : _e.dynamicSchemaId;
                if (modifySchemaId && recipeSchemaIds.includes(modifySchemaId)) {
                    if (detail_1.secondarySchemaId) {
                        detail_1.secondarySchemaId = recipeSchemaIdMap[modifySchemaId];
                    }
                    else {
                        detail_1.schemaId = recipeSchemaIdMap[modifySchemaId];
                    }
                }
                if (valueSchemaId && recipeSchemaIds.includes(valueSchemaId)) {
                    if (detail_1.dynamicValue.dynamicSecondarySchemaId) {
                        detail_1.dynamicValue.dynamicSecondarySchemaId =
                            recipeSchemaIdMap[valueSchemaId];
                    }
                    else {
                        detail_1.dynamicValue.dynamicSchemaId = recipeSchemaIdMap[valueSchemaId];
                    }
                }
                else if ((_f = detail_1.selectionIds) === null || _f === void 0 ? void 0 : _f.length) {
                    detail_1.selectionIds.forEach(function (selectionId) {
                        if (recipeSchemaIds.includes(selectionId)) {
                            var index = detail_1.selectionIds.indexOf(selectionId);
                            detail_1.selectionIds[index] = recipeSchemaIdMap[selectionId];
                        }
                    });
                }
            }
            else if (action.type === ActionType.CREATE_RECORD) {
                var detail = action.detail;
                detail.inputSchemaProperties.forEach(function (property) {
                    var schemaId = property.schemaId;
                    if (schemaId && recipeSchemaIds.includes(schemaId)) {
                        property.schemaId = recipeSchemaIdMap[schemaId];
                    }
                });
                (_g = detail.dynamicValues) === null || _g === void 0 ? void 0 : _g.forEach(function (dynamicValue) {
                    var _a, _b;
                    var valueSchemaId = (_b = (_a = dynamicValue.dynamicSecondarySchemaId) !== null && _a !== void 0 ? _a : dynamicValue.dynamicSchemaId) !== null && _b !== void 0 ? _b : dynamicValue.schemaId;
                    if (valueSchemaId && recipeSchemaIds.includes(valueSchemaId)) {
                        if (dynamicValue.dynamicSecondarySchemaId) {
                            dynamicValue.dynamicSecondarySchemaId = recipeSchemaIdMap[valueSchemaId];
                        }
                        else if (dynamicValue.dynamicSchemaId) {
                            dynamicValue.dynamicSchemaId = recipeSchemaIdMap[valueSchemaId];
                        }
                        else {
                            dynamicValue.schemaId = recipeSchemaIdMap[valueSchemaId];
                        }
                    }
                });
            }
        });
    });
    return temp;
};
