import { atom } from 'recoil';
import { TaskTab } from './interface';
var taskSettingModalVisibleState = atom({
    key: 'taskSettingModalVisibleState',
    default: false,
});
var taskEditorActiveTabState = atom({
    key: 'taskEditorActiveTabState',
    default: TaskTab.TEMPLATES,
});
var selectedTaskGroupIdState = atom({
    key: 'selectedTaskGroupIdState',
    default: undefined,
});
var taskGroupDetailState = atom({
    key: 'taskGroupDetailState',
    default: undefined,
});
var taskRuleDetailState = atom({
    key: 'taskRuleDetailState',
    default: undefined,
});
var selectedTaskTemplateIdState = atom({
    key: 'selectedTaskTemplateIdState',
    default: undefined,
});
var selectedTaskRuleIdState = atom({
    key: 'selectedTaskRuleIdState',
    default: undefined,
});
var taskGroupListState = atom({
    key: 'taskGroupListState',
    default: [],
});
var taskTemplateListState = atom({
    key: 'taskTemplateListState',
    default: [],
});
var taskRuleListState = atom({
    key: 'taskRuleListState',
    default: [],
});
var isTaskSettingMobilePreviewVisibleState = atom({
    key: 'isTaskSettingMobilePreviewVisibleState',
    default: false,
});
var isTaskSettingActivityFeedVisibleState = atom({
    key: 'isTaskSettingActivityFeedVisibleState',
    default: false,
});
var selectedTaskRecordIdState = atom({
    key: 'selectedTaskRecordIdState',
    default: null,
});
export { taskSettingModalVisibleState, taskEditorActiveTabState, selectedTaskGroupIdState, taskGroupDetailState, selectedTaskTemplateIdState, selectedTaskRuleIdState, taskRuleDetailState, taskGroupListState, taskTemplateListState, taskRuleListState, isTaskSettingMobilePreviewVisibleState, isTaskSettingActivityFeedVisibleState, selectedTaskRecordIdState, };
