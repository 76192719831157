export var onTableDataKeyDown = function (e, columnIndex) {
    var _a, _b, _c, _d, _e, _f;
    var validKeyPress = ['ArrowUp', 'ArrowDown', 'Tab', 'Enter'];
    if (validKeyPress.includes(e.key)) {
        e.preventDefault();
    }
    e.stopPropagation();
    // ! Update as needed for keyboard traversal to work
    var clickableClasses = '.ant-select-selector, .cell-span, .DatePickerPopOver, .ant-input, .FileUploadPopover';
    var currentCell = e.currentTarget;
    var currentRow = currentCell.parentElement;
    var nextRow = currentRow === null || currentRow === void 0 ? void 0 : currentRow.nextElementSibling;
    var previousRow = currentRow === null || currentRow === void 0 ? void 0 : currentRow.previousElementSibling;
    var nextCell;
    // Closes DatePickerPopover or FileUploadPopover since it does not close on traversal
    var cancelCurrentCell = function (currentCell) {
        var cancelCurrentCell = currentCell.getElementsByClassName('DatePickerPopOver')[0] ||
            currentCell.getElementsByClassName('FileUploadPopover')[0];
        cancelCurrentCell === null || cancelCurrentCell === void 0 ? void 0 : cancelCurrentCell.click();
    };
    var goToNextCell = function (nextCell) {
        var _a;
        var preventNextMove = // True if trying to go up/down on select option
         ((_a = currentCell
            .getElementsByClassName('ant-select-selector')[0]) === null || _a === void 0 ? void 0 : _a.className.includes('ant-select-selector')) &&
            ['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key);
        if (nextCell && !preventNextMove) {
            if (!nextCell.className.includes('ant-input')) {
                nextCell.click();
            }
            else {
                // Call focus() for .ant-input
                nextCell.focus();
            }
        }
    };
    switch (e.key) {
        case 'Tab':
        case 'ArrowRight':
            // Go to right cell
            nextCell = (_a = currentCell.nextElementSibling) === null || _a === void 0 ? void 0 : _a.querySelectorAll(clickableClasses)[0];
            goToNextCell(nextCell);
            if (!nextCell) {
                // Go to next row instead
                nextCell = (_b = nextRow === null || nextRow === void 0 ? void 0 : nextRow.children[0]) === null || _b === void 0 ? void 0 : _b.querySelectorAll(clickableClasses)[0];
                goToNextCell(nextCell);
            }
            cancelCurrentCell(currentCell);
            break;
        case 'Enter':
        case 'ArrowDown':
            // Go to cell below
            nextCell = (_c = nextRow === null || nextRow === void 0 ? void 0 : nextRow.children[columnIndex]) === null || _c === void 0 ? void 0 : _c.querySelectorAll(clickableClasses)[0];
            goToNextCell(nextCell);
            if (nextCell) {
                cancelCurrentCell(currentCell);
            }
            break;
        case 'ArrowLeft':
            // Go to left cell
            nextCell = (_d = currentCell.previousElementSibling) === null || _d === void 0 ? void 0 : _d.querySelectorAll(clickableClasses)[0];
            goToNextCell(nextCell);
            if (!nextCell) {
                // Go to previous row instead
                nextCell = (_e = previousRow === null || previousRow === void 0 ? void 0 : previousRow.children[previousRow.children.length - 2]) === null || _e === void 0 ? void 0 : _e.querySelectorAll(clickableClasses)[0];
                goToNextCell(nextCell);
            }
            cancelCurrentCell(currentCell);
            break;
        case 'ArrowUp':
            // Go to cell above
            nextCell = (_f = previousRow === null || previousRow === void 0 ? void 0 : previousRow.children[columnIndex]) === null || _f === void 0 ? void 0 : _f.querySelectorAll(clickableClasses)[0];
            goToNextCell(nextCell);
            if (nextCell) {
                cancelCurrentCell(currentCell);
            }
            break;
        default:
            break;
    }
};
