var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PayrollSvg = function () { return (_jsx("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.5805 17.8801C29.5805 24.2358 24.4282 29.3881 18.0725 29.3881C11.7168 29.3881 6.5645 24.2358 6.5645 17.8801C6.5645 11.5244 11.7168 6.37211 18.0725 6.37211C24.4282 6.37211 29.5805 11.5244 29.5805 17.8801ZM32.4575 17.8801C32.4575 25.8247 26.0171 32.2651 18.0725 32.2651C10.1279 32.2651 3.6875 25.8247 3.6875 17.8801C3.6875 9.93549 10.1279 3.49512 18.0725 3.49512C26.0171 3.49512 32.4575 9.93549 32.4575 17.8801ZM16.7257 10.2207C16.7257 9.82346 17.0477 9.50145 17.4449 9.50145H18.8834C19.2807 9.50145 19.6027 9.82346 19.6027 10.2207V11.1627C20.5802 11.4956 21.3944 12.1793 21.8955 13.0607L22.251 13.6859C22.4474 14.0313 22.3266 14.4704 21.9813 14.6667L20.7308 15.3777C20.3855 15.574 19.9464 15.4533 19.75 15.108L19.3945 14.4827C19.1667 14.082 18.7394 13.817 18.252 13.817C17.5272 13.817 16.9396 14.4046 16.9396 15.1294C16.9396 15.8542 17.5272 16.4569 18.252 16.4569C20.3981 16.6386 22.0832 18.4381 22.0832 20.6312C22.0832 22.3362 21.0646 23.8034 19.6027 24.4573V25.5397C19.6027 25.9369 19.2807 26.2589 18.8834 26.2589H17.4449C17.0477 26.2589 16.7257 25.9369 16.7257 25.5397V24.6554C15.6672 24.3485 14.7824 23.6355 14.2503 22.6999L13.8948 22.0746C13.6985 21.7293 13.8193 21.2902 14.1646 21.0939L15.4151 20.3829C15.7604 20.1865 16.1995 20.3073 16.3958 20.6526L16.7513 21.2779C16.9792 21.6785 17.4065 21.9435 17.8939 21.9435C18.6187 21.9435 19.2062 21.356 19.2062 20.6312C19.2062 19.9064 18.6187 19.3037 17.8939 19.3037C15.7478 19.122 14.0626 17.3225 14.0626 15.1294C14.0626 13.3542 15.1667 11.8369 16.7257 11.2268V10.2207Z" }) })); };
export var PayrollIcon = function (props) { return (_jsx(Icon, __assign({ component: PayrollSvg }, props))); };
