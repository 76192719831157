var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TextOutlinedBlackSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M6.49992 4.37045C6.40787 4.37045 6.33325 4.44506 6.33325 4.53711V6.87045C6.33325 6.96249 6.40787 7.03711 6.49992 7.03711H7.49992C7.59197 7.03711 7.66659 6.96249 7.66659 6.87045V5.70378H10.3333V13.7038H8.49992C8.40787 13.7038 8.33325 13.7784 8.33325 13.8704V14.8704C8.33325 14.9625 8.40787 15.0371 8.49992 15.0371H13.4999C13.592 15.0371 13.6666 14.9625 13.6666 14.8704V13.8704C13.6666 13.7784 13.592 13.7038 13.4999 13.7038H11.6666V5.70378H14.3333V6.87045C14.3333 6.96249 14.4079 7.03711 14.4999 7.03711H15.4999C15.592 7.03711 15.6666 6.96249 15.6666 6.87045V4.53711C15.6666 4.44506 15.592 4.37045 15.4999 4.37045H6.49992Z", fill: "#000000" }), _jsx("path", { d: "M17.6666 16.5371C17.6666 16.4451 17.592 16.3704 17.4999 16.3704H4.49992C4.40787 16.3704 4.33325 16.4451 4.33325 16.5371V17.5371C4.33325 17.6292 4.40787 17.7038 4.49992 17.7038H17.4999C17.592 17.7038 17.6666 17.6292 17.6666 17.5371V16.5371Z", fill: "#000000" })] })); };
export var TextOutlinedBlackIcon = function (props) { return (_jsx(Icon, __assign({ component: TextOutlinedBlackSvg }, props))); };
