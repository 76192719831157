var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons/lib/components/Icon';
var CreateColumnSvg = function () { return (_jsxs("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M5.50001 3.66699H9.16668C9.40979 3.66699 9.64295 3.76357 9.81486 3.93548C9.98677 4.10739 10.0833 4.34054 10.0833 4.58366V17.417C10.0833 17.6601 9.98677 17.8933 9.81486 18.0652C9.64295 18.2371 9.40979 18.3337 9.16668 18.3337H5.50001C5.2569 18.3337 5.02374 18.2371 4.85183 18.0652C4.67992 17.8933 4.58334 17.6601 4.58334 17.417V4.58366C4.58334 4.34054 4.67992 4.10739 4.85183 3.93548C5.02374 3.76357 5.2569 3.66699 5.50001 3.66699Z", stroke: "#212121", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M13.75 11H17.4167", stroke: "#212121", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M15.5833 9.16699V12.8337", stroke: "#212121", strokeWidth: "1.8", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var CreateColumnIcon = function (props) { return (_jsx(Icon, __assign({ component: CreateColumnSvg }, props))); };
