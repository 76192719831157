import moment from 'moment';
import { ReportShowCurrencySignSet, ValueDataType } from 'src/shared/Constant';
export var getSecondsAgo = function (lastCalculatedAt) {
    var timestamp = moment(lastCalculatedAt);
    var diff = moment.duration(moment().diff(timestamp));
    return diff.asSeconds();
};
export var secondsToString = function (elapsedSeconds) {
    var diff = moment.duration(elapsedSeconds, 'seconds');
    var hours = diff.hours();
    if (hours > 0) {
        return hours + (hours > 1 ? ' hours ago' : ' hour ago');
    }
    var minutes = diff.minutes();
    if (minutes > 0) {
        return minutes + (minutes > 1 ? ' minutes ago' : ' minute ago');
    }
    return 'just now';
};
export var secondsToShortString = function (elapsedSeconds) {
    var diff = moment.duration(elapsedSeconds, 'seconds');
    var hours = diff.hours();
    if (hours > 0) {
        return hours + (hours > 1 ? ' hrs' : ' hs');
    }
    var minutes = diff.minutes();
    if (minutes > 0) {
        return minutes + (minutes > 1 ? ' mins' : ' min');
    }
    return 'just now';
};
export var getMetricSchemaType = function (schema) {
    if (schema.type === 'STATIC') {
        if (ReportShowCurrencySignSet.has(schema.metricName)) {
            return ValueDataType.METRIC_CURRENCY;
        }
        else if (schema.metricName === 'show_rate') {
            return ValueDataType.METRIC_PERCENTAGE;
        }
        else {
            return ValueDataType.METRIC;
        }
    }
    return ValueDataType.METRIC;
};
