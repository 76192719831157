import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { useTimeZone, useWindowSize } from 'src/hooks/component';
import { getPayPeriodPageRoute, setPayPeriodPageRoute } from 'src/utils/Account';
import TotalPayReportCard from './TotalPayReportCard';
var PayReportSummarySection = function (_a) {
    var payrollEnhancementEnabled = _a.payrollEnhancementEnabled;
    var _b = useParams().payPeriodId, payPeriodId = _b === void 0 ? '' : _b;
    var windowWidth = useWindowSize().windowWidth;
    var navigate = useNavigate();
    var _c = useTimeZone(), timeFormatFn = _c.timeFormatFn, timezoneAbbr = _c.timezoneAbbr;
    var _d = useState([]), payPeriodTimeRange = _d[0], setPayPeriodTimeRange = _d[1];
    var displayTimeRange = useMemo(function () {
        var startDate = payPeriodTimeRange[0];
        var endDate = payPeriodTimeRange[1];
        var startYear = timeFormatFn(startDate, 'YYYY', false);
        var endYear = timeFormatFn(endDate, 'YYYY', false);
        var isSameYear = startYear === endYear;
        var timeFormat = isSameYear ? 'MMM DD' : 'MMM DD YYYY';
        return "".concat(timeFormatFn(startDate, timeFormat, false), " - ").concat(timeFormatFn(endDate, timeFormat, false), " ").concat(isSameYear ? startYear : '', " ").concat(timezoneAbbr());
    }, [payPeriodTimeRange, timeFormatFn, timezoneAbbr]);
    var onBackToPage = useCallback(function () {
        var prevPageRoute = getPayPeriodPageRoute();
        if (prevPageRoute) {
            navigate(prevPageRoute);
            setPayPeriodPageRoute('');
        }
        else {
            navigate('/home');
        }
    }, [navigate]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "PayViewTitle", style: {
                            margin: (windowWidth !== null && windowWidth !== void 0 ? windowWidth : 577) > 576 ? '32px 10px 0px 48px' : '32px 0px 0px 48px',
                        }, children: displayTimeRange }), _jsxs("div", { children: [_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.PAY_PERIOD], children: _jsx(Button, { className: "HelpBtn", style: {
                                        margin: (windowWidth !== null && windowWidth !== void 0 ? windowWidth : 577) > 576
                                            ? '32px 10px 0px 0px'
                                            : '32px 0px 0px 0px',
                                    }, children: "Learn About Pay" }) }), _jsx(Button, { className: "BackBtn", style: {
                                    margin: (windowWidth !== null && windowWidth !== void 0 ? windowWidth : 577) > 576
                                        ? '32px 48px 0px 0px'
                                        : '32px 0px 0px 0px',
                                }, onClick: onBackToPage, children: "Back" })] })] }), _jsx(TotalPayReportCard, { payrollEnhancementEnabled: payrollEnhancementEnabled, payPeriodId: payPeriodId, setHeaderTimeRange: setPayPeriodTimeRange })] }));
};
export default React.memo(PayReportSummarySection);
