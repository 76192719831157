import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import HoverOptionButtons from 'src/components/PageView/HoverOptions/HoverOptionButtons';
import OpenRecordButton from 'src/components/TableView/OpenRecordButton';
import { useRecordComponent } from 'src/hooks/component';
import { collectionTableCanEditSelector, collectionTableTypeSelector, tableCollectionRecordColorSelector, } from 'src/state';
import './OpenButtonForCell.scss';
import { CollectionTableType } from 'src/shared';
import { NoTitleColorCollectionTypeSet } from '../Constants';
var OpenButtonForCell = function (_a) {
    var blockId = _a.blockId, cellPos = _a.cellPos;
    var _b = useRecordComponent(), deleteRecordInPage = _b.deleteRecordInPage, addColorToSingleRecord = _b.addColorToSingleRecord;
    var recordColor = useRecoilValue(tableCollectionRecordColorSelector({ tableId: blockId, recordId: cellPos.recordId }));
    var onDeleteRecord = useCallback(function () {
        deleteRecordInPage(cellPos.collectionId, [cellPos.recordId]);
    }, [cellPos.collectionId, cellPos.recordId, deleteRecordInPage]);
    var onUpdateRecordColor = useCallback(function (color) {
        addColorToSingleRecord(cellPos.collectionId, cellPos.recordId, color);
    }, [addColorToSingleRecord, cellPos.collectionId, cellPos.recordId]);
    var tableDataSource = {
        type: CollectionTableType.TABLE,
        collectionId: cellPos.collectionId,
    };
    var canEditCollection = useRecoilValue(collectionTableCanEditSelector(tableDataSource));
    var collectionType = useRecoilValue(collectionTableTypeSelector(tableDataSource));
    return (_jsxs("div", { className: "OpenButtonContainer", children: [_jsx(HoverOptionButtons, { data: {
                    recordId: cellPos.recordId,
                    collectionId: cellPos.collectionId,
                    collectionType: collectionType,
                }, actions: {
                    deleteRecord: canEditCollection ? onDeleteRecord : undefined,
                    updateRecordColor: NoTitleColorCollectionTypeSet.has(collectionType) || !canEditCollection
                        ? undefined
                        : onUpdateRecordColor,
                }, currentColor: recordColor, moveBlockUpDown: function () { } }), _jsx(OpenRecordButton, { recordId: cellPos.recordId })] }));
};
export default React.memo(OpenButtonForCell);
