import { jsx as _jsx } from "react/jsx-runtime";
import InputNumber from 'antd/lib/input-number';
import React, { useRef } from 'react';
var ExpectedCountInput = function (props) {
    var index = props.index, expectedCount = props.expectedCount, onExpectedNumberChange = props.onExpectedNumberChange;
    var inputRef = useRef(null);
    return (_jsx(InputNumber, { ref: inputRef, bordered: false, value: expectedCount, min: 0, height: 30, onBlur: function (e) {
            onExpectedNumberChange(index, Number(e.target.value));
        }, onPressEnter: function () {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        } }));
};
export default React.memo(ExpectedCountInput);
