import { v4 as uuidv4 } from 'uuid';
import { PayRuleAdjustmentField } from './interface';
export var PAYMENT_RULES_MODAL_Z_INDEX = 10;
export var INITIAL_PAY_RATE = {
    info: {
        collectionId: '',
        name: 'Untitled Rate',
        isActive: false,
    },
    conditions: [],
    adjustments: [
        {
            id: uuidv4(),
            field: PayRuleAdjustmentField.PAY_RATE,
            value: 0,
        },
    ],
};
