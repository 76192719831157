import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { memo, useCallback, useState } from 'react';
import BlockActionDropdown from './Block/BlockActionDropdown';
import RecordActionDropdown from './Record/RecordActionDropdown';
import './HoverOptionButtons.scss';
var HoverOptionButtons = function (_a) {
    var data = _a.data, actions = _a.actions, currentColor = _a.currentColor, moveBlockUpDown = _a.moveBlockUpDown, url = _a.url, menuChildren = _a.menuChildren, isNonTitleBlock = _a.isNonTitleBlock;
    var _b = actions || {}, deleteRecord = _b.deleteRecord, duplicateRecord = _b.duplicateRecord, updateRecordColor = _b.updateRecordColor;
    var _c = useState(false), hoverOptionVisible = _c[0], setHoverOptionVisible = _c[1];
    var className = hoverOptionVisible ? 'HoverOptionVisible' : '';
    var blockId = 'blockId' in data ? data.blockId : undefined;
    var recordId = 'recordId' in data ? data.recordId : undefined;
    var collectionId = 'collectionId' in data ? data.collectionId : undefined;
    var collectionType = 'collectionType' in data ? data.collectionType : undefined;
    var hoverOptionSectionId = "HoverOptionSection-".concat(blockId || recordId);
    var optionMenuDropdownForBlock = useCallback(function (blockId) {
        return (_jsxs(_Fragment, { children: [_jsx(BlockActionDropdown, { blockId: blockId, parentElementId: hoverOptionSectionId, moveBlockUpDown: moveBlockUpDown, url: url, menuChildren: menuChildren, setHoverOptionVisible: setHoverOptionVisible, isNonTitleBlock: isNonTitleBlock, children: _jsx(MenuOutlined, { className: "hover-option-menu" }) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                        setHoverOptionVisible(false);
                    }, style: { display: hoverOptionVisible ? 'block' : 'none' } })] }));
    }, [
        hoverOptionSectionId,
        hoverOptionVisible,
        isNonTitleBlock,
        menuChildren,
        moveBlockUpDown,
        url,
    ]);
    var optionMenuDropdownForRecord = useCallback(function (recordId, collectionId, collectionType) {
        return (_jsxs(_Fragment, { children: [_jsx(RecordActionDropdown, { recordId: recordId, collectionId: collectionId, collectionType: collectionType, parentElementId: hoverOptionSectionId, onDeleteRecord: deleteRecord, onDuplicateRecord: duplicateRecord, onUpdateRecordColor: updateRecordColor, currentColor: currentColor, setHoverOptionVisible: setHoverOptionVisible, children: _jsx(EllipsisOutlined, { className: "RecordActionIcon" }) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                        setHoverOptionVisible(false);
                    }, style: { display: hoverOptionVisible ? 'block' : 'none' } })] }));
    }, [
        currentColor,
        deleteRecord,
        duplicateRecord,
        hoverOptionSectionId,
        hoverOptionVisible,
        updateRecordColor,
    ]);
    return (_jsxs("div", { className: "HoverOptionSection ".concat(className), id: hoverOptionSectionId, children: [_jsx("span", { className: "BlockHoverOptionButtons", children: blockId && optionMenuDropdownForBlock(blockId) }), _jsx("span", { className: "RecordHoverOptionButtons", children: recordId &&
                    collectionId &&
                    collectionType &&
                    (updateRecordColor || deleteRecord) &&
                    optionMenuDropdownForRecord(recordId, collectionId, collectionType) })] }));
};
export default memo(HoverOptionButtons);
