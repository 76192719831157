import { atom, atomFamily } from 'recoil';
import { localStorageEffect } from './localStorageEffects';
var threadSummaryListState = atom({
    key: 'threadSummaryListState',
    default: [],
});
var participantsInNewThread = atom({
    key: 'participantsInNewThread',
    default: undefined,
});
var selectedThreadState = atom({
    key: 'selectedThreadState',
    default: undefined,
});
var threadChatMessagesState = atomFamily({
    key: 'threadChatMessagesState',
    default: [],
});
var departmentsState = atom({
    key: 'departmentsState',
    default: [],
});
var recipientsDropdownOpenState = atom({
    key: 'recipientsDropdownOpenState',
    default: false,
});
var threadSectionExpandedStatusState = atomFamily({
    key: 'threadSectionExpandedStatusState',
    default: true,
    effects: function (key) { return [localStorageEffect("threadSectionExpandedStatusState:".concat(key))]; },
});
export { participantsInNewThread, threadSummaryListState, selectedThreadState, threadChatMessagesState, departmentsState, recipientsDropdownOpenState, threadSectionExpandedStatusState, };
