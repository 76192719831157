var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, Col, Row } from 'antd';
import { useHolidayPayApi } from 'src/hooks/api';
import HolidayMultiplierItem from './HolidayMultiplierItem';
import HolidayPolicyItem from './HolidayPolicyItem';
var HolidayPaySection = function () {
    var _a = useHolidayPayApi(), useGetHolidayMultipliers = _a.useGetHolidayMultipliers, useGetHolidayPolicies = _a.useGetHolidayPolicies, useCreateHolidayMultiplier = _a.useCreateHolidayMultiplier, useCreateHolidayPolicy = _a.useCreateHolidayPolicy, useUpdateHolidayPolicy = _a.useUpdateHolidayPolicy;
    var _b = useGetHolidayMultipliers(), multipliers = _b.data, refetchMultipliers = _b.refetch;
    var _c = useGetHolidayPolicies(), policies = _c.data, refetchPolicies = _c.refetch;
    var addHolidayMultiplier = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useCreateHolidayMultiplier.mutateAsync({
                        name: 'New Holiday',
                        startDate: '2023-12-25T00:00',
                        endDate: '2023-12-25T23:59',
                        payMultiplier: 1,
                        billMultiplier: 1,
                        overtimePayMultiplier: 1,
                    })];
                case 1:
                    _a.sent();
                    refetchMultipliers();
                    return [2 /*return*/];
            }
        });
    }); }, [refetchMultipliers, useCreateHolidayMultiplier]);
    var addHolidayPolicy = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useCreateHolidayPolicy.mutateAsync({
                        name: 'New Holiday Policy',
                    })];
                case 1:
                    _a.sent();
                    refetchPolicies();
                    return [2 /*return*/];
            }
        });
    }); }, [refetchPolicies, useCreateHolidayPolicy]);
    var makeHolidayPolicyDefault = function (policyId) { return __awaiter(void 0, void 0, void 0, function () {
        var _i, _a, policy;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _i = 0, _a = (_b = policies === null || policies === void 0 ? void 0 : policies.holidayPolicies) !== null && _b !== void 0 ? _b : [];
                    _c.label = 1;
                case 1:
                    if (!(_i < _a.length)) return [3 /*break*/, 4];
                    policy = _a[_i];
                    return [4 /*yield*/, useUpdateHolidayPolicy.mutateAsync({
                            id: policy.id,
                            isDefault: policy.id === policyId,
                        })];
                case 2:
                    _c.sent();
                    _c.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    refetchPolicies();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "SectionWrapper", children: [_jsxs("div", { className: "mb-10", children: [_jsx("div", { className: "SectionDescriptionTitle", children: "Holiday Multipliers" }), _jsx("div", { className: "SectionSubTitle", children: "Add all holiday multipliers here. In the policy section below you can choose to which holiday multipliers define your policies for your locations." }), _jsxs(Row, { gutter: 8, children: [_jsx(Col, { span: 6, children: _jsx("div", { className: "pl-[4px] font-semibold", children: "Name" }) }), _jsx(Col, { span: 9, children: _jsx("div", { className: "pl-[4px] font-semibold", children: "Date" }) }), _jsx(Col, { span: 3, children: _jsx("div", { className: "pl-[4px] font-semibold", children: "Pay Rate" }) }), _jsx(Col, { span: 3, children: _jsx("div", { className: "pl-[4px] font-semibold", children: "Bill Rate" }) }), _jsx(Col, { span: 3, children: _jsx("div", { className: "pl-[4px] font-semibold", children: "Overtime Pay" }) })] }), _jsx("div", { className: "flex flex-col gap-[8px] mt-[8px]", children: multipliers === null || multipliers === void 0 ? void 0 : multipliers.holidays.map(function (holiday) {
                            return (_jsx(HolidayMultiplierItem, { holidayMultiplier: holiday, refetchMultipliers: refetchMultipliers }, holiday.id));
                        }) }), _jsx(Button, { className: "AddTimeOffTypeBtn", onClick: function () {
                            addHolidayMultiplier();
                        }, children: "+ Add Holiday" })] }), _jsx("div", { className: "SectionDescriptionTitle", children: "Holiday Policies" }), _jsx("div", { className: "SectionSubTitle", children: "Create policies using the holidays rates you created above." }), policies === null || policies === void 0 ? void 0 : policies.holidayPolicies.map(function (policy) {
                var _a;
                return (_jsx(HolidayPolicyItem, { policy: policy, holidayMultipliers: (_a = multipliers === null || multipliers === void 0 ? void 0 : multipliers.holidays) !== null && _a !== void 0 ? _a : [], refetchPolicies: refetchPolicies, makeHolidayPolicyDefault: makeHolidayPolicyDefault }, policy.id));
            }), _jsx("div", { className: "SettingsGrayButton mt-5", onClick: function () {
                    addHolidayPolicy();
                }, children: "New Holiday Policy" })] }));
};
export default React.memo(HolidayPaySection);
