var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TotalShiftsIconSvg = function () { return (_jsxs("svg", { width: "33", height: "33", viewBox: "0 0 33 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.5", y: "0.5", width: "32", height: "32", rx: "16", fill: "#2F3037" }), _jsx("path", { d: "M16.5 8.625C12.1512 8.625 8.625 12.1512 8.625 16.5C8.625 20.8488 12.1512 24.375 16.5 24.375C20.8488 24.375 24.375 20.8488 24.375 16.5C24.375 12.1512 20.8488 8.625 16.5 8.625ZM16.5 23.0391C12.8895 23.0391 9.96094 20.1105 9.96094 16.5C9.96094 12.8895 12.8895 9.96094 16.5 9.96094C20.1105 9.96094 23.0391 12.8895 23.0391 16.5C23.0391 20.1105 20.1105 23.0391 16.5 23.0391ZM17.3385 16.0922L16.892 15.9885V13.6277C17.56 13.7191 17.973 14.1375 18.0434 14.6508C18.0521 14.7211 18.1119 14.7721 18.1822 14.7721H18.9715C19.0541 14.7721 19.1191 14.7 19.1121 14.6174C19.0049 13.5223 18.1031 12.8191 16.899 12.6979V12.123C16.899 12.0457 16.8357 11.9824 16.7584 11.9824H16.2645C16.1871 11.9824 16.1238 12.0457 16.1238 12.123V12.7031C14.8793 12.8244 13.9055 13.5117 13.9055 14.7949C13.9055 15.9832 14.7809 16.5563 15.7002 16.776L16.1344 16.8867V19.3951C15.3574 19.2914 14.9215 18.8766 14.8318 18.3176C14.8213 18.2508 14.7615 18.2016 14.693 18.2016H13.8809C13.7982 18.2016 13.7332 18.2719 13.7402 18.3545C13.8193 19.3213 14.5523 20.2107 16.1168 20.325V20.877C16.1168 20.9543 16.1801 21.0176 16.2574 21.0176H16.7566C16.834 21.0176 16.8973 20.9543 16.8973 20.8752L16.8938 20.318C18.2701 20.1967 19.2545 19.4602 19.2545 18.1383C19.2527 16.9184 18.4775 16.3734 17.3385 16.0922ZM16.1326 15.8074C16.0342 15.7793 15.9516 15.7529 15.8689 15.7195C15.2748 15.5051 14.9988 15.1588 14.9988 14.7123C14.9988 14.0742 15.4822 13.7104 16.1326 13.6277V15.8074ZM16.892 19.4004V17.0502C16.9465 17.066 16.9957 17.0783 17.0467 17.0889C17.8781 17.342 18.1576 17.6936 18.1576 18.2332C18.1576 18.9205 17.6408 19.3336 16.892 19.4004Z", fill: "white" })] })); };
export var TotalShiftsIcon = function (props) { return (_jsx(Icon, __assign({ component: TotalShiftsIconSvg }, props))); };
