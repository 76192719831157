var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var BlockErrorIconSvg = function () { return (_jsx("svg", { width: "45", height: "45", viewBox: "0 0 45 45", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M41.25 22.5C41.25 12.1447 32.8553 3.75 22.5 3.75C12.1447 3.75 3.75 12.1447 3.75 22.5C3.75 32.8553 12.1447 41.25 22.5 41.25C32.8553 41.25 41.25 32.8553 41.25 22.5ZM20.625 28.125V31.875H24.375V28.125H20.625ZM20.625 13.125V24.375H24.375V13.125H20.625Z", fill: "#B9B9B9" }) })); };
export var BlockErrorIcon = function (props) { return (_jsx(Icon, __assign({ component: BlockErrorIconSvg }, props))); };
