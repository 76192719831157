var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cloneDeep } from 'lodash';
import { DefaultValue, selector, selectorFamily } from 'recoil';
import { NEEDS_COVERAGE, NEW_SHIFT_TEMPLATE, SHIFT_RECOMMENDATIONS_FEATURE, } from 'src/components/FeatureGatingKeyConstant';
import { NativePath, NonSchemaNativePath, ValueDataType } from 'src/shared';
import { getCardColor } from 'src/services';
import { getNativeFieldCellContent, getNativeFieldCellOptions } from 'src/utils/Collection';
import moment from 'moment';
import { collectionOptionsState, collectionTableNonSchemaNativeFieldsState, collectionTableRecordState, collectionTableSchemaCanEditSelector, collectionTableSchemaMapSelector, collectionTableSchemaNativePathMapSelector, collectionTableSchemasState, scheduleColorCodingSelector, scheduleTableRowState, scheduleTimeOffsState, scheduleTimeRangeState, scheduleTimeZoneSelector, } from '../..';
import { featureEnableStatusState } from '../withAccountFeatureFlagState';
var scheduleZonedTimeRangeSelector = selectorFamily({
    key: 'scheduleZonedTimeRangeSelector',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var timeRange = get(scheduleTimeRangeState(blockId));
            var timeZone = get(scheduleTimeZoneSelector(blockId));
            return timeRange.map(function (time) { return moment.tz(time, 'YYYY-MM-DD', timeZone).toISOString(); });
        };
    },
});
var scheduleRowLengthSelector = selectorFamily({
    key: 'scheduleRowLengthSelector',
    get: function (blockId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var rowData = get(scheduleTableRowState(blockId));
            return (_b = rowData.length) !== null && _b !== void 0 ? _b : 0;
        };
    },
});
var scheduleCollectionIdState = selector({
    key: 'scheduleCollectionIdState',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var collectionOptions = get(collectionOptionsState);
        var shiftCollectionId = (_b = collectionOptions.find(function (o) { return o.type === 'shift'; })) === null || _b === void 0 ? void 0 : _b.id;
        return shiftCollectionId;
    },
});
var scheduleTimeOffSelector = selectorFamily({
    key: 'scheduleTimeOffSelector',
    get: function (_a) {
        var blockId = _a.blockId, recordId = _a.recordId;
        return function (_a) {
            var get = _a.get;
            var timeOffs = get(scheduleTimeOffsState(blockId));
            return timeOffs === null || timeOffs === void 0 ? void 0 : timeOffs[recordId];
        };
    },
});
var scheduleShiftCardBasicInfoSelector = selectorFamily({
    key: 'scheduleShiftCardBasicInfoSelector',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var get = _a.get;
            var shiftRecord = get(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: recordId })));
            if (!shiftRecord) {
                return {
                    rid: recordId,
                    published: false,
                    startAt: '',
                    endAt: '',
                    clockIn: '',
                    clockOut: '',
                };
            }
            var nativeSchemaMap = get(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
            var startAt = getNativeFieldCellContent(shiftRecord, nativeSchemaMap, NativePath.SHIFT_START_AT);
            var endAt = getNativeFieldCellContent(shiftRecord, nativeSchemaMap, NativePath.SHIFT_END_AT);
            var clockIn = getNativeFieldCellContent(shiftRecord, nativeSchemaMap, NativePath.SHIFT_CLOCK_IN);
            var clockOut = getNativeFieldCellContent(shiftRecord, nativeSchemaMap, NativePath.SHIFT_CLOCK_OUT);
            var published = getNativeFieldCellContent(shiftRecord, nativeSchemaMap, NativePath.SHIFT_PUBLISHED) === 'true';
            var isAssigned = !!getNativeFieldCellOptions(shiftRecord, nativeSchemaMap, NativePath.SHIFT_USER_ID)[0];
            var location = getNativeFieldCellOptions(shiftRecord, nativeSchemaMap, NativePath.SHIFT_LOCATION_ID);
            var roles = getNativeFieldCellOptions(shiftRecord, nativeSchemaMap, NativePath.SHIFT_ROLE_IDS);
            var template = getNativeFieldCellOptions(shiftRecord, nativeSchemaMap, NativePath.SHIFT_TEMPLATE_ID);
            var schemaList = get(collectionTableSchemasState(dataSource.collectionId));
            var customSelects = {};
            schemaList.forEach(function (schema) {
                var _a;
                var _b, _c, _d, _e;
                if (schema.type === ValueDataType.PLAIN_SELECTION) {
                    var cellOption = ((_c = (_b = shiftRecord[schema.id]) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.length)
                        ? shiftRecord[schema.id].options
                        : ((_e = (_d = shiftRecord[schema.id]) === null || _d === void 0 ? void 0 : _d.versionedOptions) !== null && _e !== void 0 ? _e : []);
                    customSelects = __assign(__assign({}, customSelects), (_a = {}, _a[schema.id] = cellOption, _a));
                }
            });
            return {
                rid: recordId,
                published: published,
                isAssigned: isAssigned,
                startAt: startAt,
                endAt: endAt,
                clockIn: clockIn,
                clockOut: clockOut,
                location: (location === null || location === void 0 ? void 0 : location.length) ? location[0] : undefined,
                roles: (roles === null || roles === void 0 ? void 0 : roles.length) ? roles : undefined,
                template: (template === null || template === void 0 ? void 0 : template.length) ? template[0] : undefined,
                customSelects: customSelects,
            };
        };
    },
});
var scheduleShiftCanRecommendSelector = selectorFamily({
    key: 'scheduleShiftCanRecommendSelector',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var get = _a.get;
            var recommendFeatureEnabled = get(featureEnableStatusState(SHIFT_RECOMMENDATIONS_FEATURE));
            var canEditAssignee = get(collectionTableSchemaCanEditSelector(__assign(__assign({}, dataSource), { nativePath: NativePath.SHIFT_USER_ID })));
            var shiftCardInfo = get(scheduleShiftCardBasicInfoSelector(__assign(__assign({}, dataSource), { recordId: recordId })));
            var published = shiftCardInfo.published, startAt = shiftCardInfo.startAt, endAt = shiftCardInfo.endAt;
            return recommendFeatureEnabled && canEditAssignee && published && !!startAt && !!endAt;
        };
    },
});
var scheduleShiftCardColorSelector = selectorFamily({
    key: 'scheduleShiftCardColorSelector',
    get: function (_a) {
        var blockId = _a.blockId, recordId = _a.recordId, dataSource = __rest(_a, ["blockId", "recordId"]);
        return function (_a) {
            var get = _a.get;
            var shiftCardInfo = get(scheduleShiftCardBasicInfoSelector(__assign(__assign({}, dataSource), { recordId: recordId })));
            var colorCodingSchemaId = get(scheduleColorCodingSelector(blockId));
            var schemaMap = get(collectionTableSchemaMapSelector(dataSource.collectionId));
            return getCardColor(shiftCardInfo, colorCodingSchemaId, schemaMap);
        };
    },
});
var scheduleShiftOpenCountState = selectorFamily({
    key: 'scheduleShiftOpenCountState',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var _b, _c, _d;
            var get = _a.get;
            return ((_d = (_c = (_b = get(collectionTableNonSchemaNativeFieldsState(dataSource))[recordId]) === null || _b === void 0 ? void 0 : _b.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_OPEN_COUNT; })) === null || _c === void 0 ? void 0 : _c.openCount) !== null && _d !== void 0 ? _d : 1);
        };
    },
    set: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a, newOpenCount) {
            var set = _a.set;
            if (newOpenCount && !(newOpenCount instanceof DefaultValue)) {
                set(collectionTableNonSchemaNativeFieldsState(dataSource), function (prev) {
                    var _a;
                    var recordNonNativeFields = cloneDeep(prev[recordId]);
                    if (!recordNonNativeFields) {
                        // For new shift detail modal
                        recordNonNativeFields = [];
                    }
                    var openCountFieldIndex = recordNonNativeFields.findIndex(function (field) { return field.path === NonSchemaNativePath.SHIFT_OPEN_COUNT; });
                    if (openCountFieldIndex >= 0) {
                        recordNonNativeFields.splice(openCountFieldIndex, 1, {
                            path: NonSchemaNativePath.SHIFT_OPEN_COUNT,
                            openCount: newOpenCount,
                        });
                    }
                    else {
                        recordNonNativeFields.push({
                            path: NonSchemaNativePath.SHIFT_OPEN_COUNT,
                            openCount: newOpenCount,
                        });
                    }
                    return __assign(__assign({}, prev), (_a = {}, _a[recordId] = recordNonNativeFields, _a));
                });
            }
        };
    },
});
var shiftModalTimeZoneIdState = selectorFamily({
    key: 'timeZoneIdState',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var _b, _c;
            var get = _a.get;
            return (_c = (_b = get(collectionTableNonSchemaNativeFieldsState(dataSource))[recordId]) === null || _b === void 0 ? void 0 : _b.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_TIME_ZONE_ID; })) === null || _c === void 0 ? void 0 : _c.timeZoneId;
        };
    },
    set: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a, newTimeZoneId) {
            var set = _a.set;
            if (newTimeZoneId && !(newTimeZoneId instanceof DefaultValue)) {
                set(collectionTableNonSchemaNativeFieldsState(dataSource), function (prev) {
                    var _a;
                    var recordNonNativeFields = cloneDeep(prev[recordId]);
                    if (!recordNonNativeFields) {
                        // For new shift detail modal
                        recordNonNativeFields = [];
                    }
                    var timeZoneIdFieldIndex = recordNonNativeFields.findIndex(function (field) { return field.path === NonSchemaNativePath.SHIFT_TIME_ZONE_ID; });
                    if (timeZoneIdFieldIndex >= 0) {
                        recordNonNativeFields.splice(timeZoneIdFieldIndex, 1, {
                            path: NonSchemaNativePath.SHIFT_TIME_ZONE_ID,
                            timeZoneId: newTimeZoneId,
                        });
                    }
                    else {
                        recordNonNativeFields.push({
                            path: NonSchemaNativePath.SHIFT_TIME_ZONE_ID,
                            timeZoneId: newTimeZoneId,
                        });
                    }
                    return __assign(__assign({}, prev), (_a = {}, _a[recordId] = recordNonNativeFields, _a));
                });
            }
        };
    },
});
var shiftModalIsGroupState = selectorFamily({
    key: 'isGroupState',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var _b, _c;
            var get = _a.get;
            return (((_c = (_b = get(collectionTableNonSchemaNativeFieldsState(dataSource))[recordId]) === null || _b === void 0 ? void 0 : _b.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_IS_GROUP; })) === null || _c === void 0 ? void 0 : _c.isGroup) || false);
        };
    },
    set: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a, newIsGroup) {
            var set = _a.set;
            if (!(newIsGroup instanceof DefaultValue)) {
                set(collectionTableNonSchemaNativeFieldsState(dataSource), function (prev) {
                    var _a;
                    var recordNonNativeFields = cloneDeep(prev[recordId]);
                    if (!recordNonNativeFields) {
                        // For new shift detail modal
                        recordNonNativeFields = [];
                    }
                    var isGroupFieldIndex = recordNonNativeFields.findIndex(function (field) { return field.path === NonSchemaNativePath.SHIFT_IS_GROUP; });
                    if (isGroupFieldIndex >= 0) {
                        recordNonNativeFields.splice(isGroupFieldIndex, 1, {
                            path: NonSchemaNativePath.SHIFT_IS_GROUP,
                            isGroup: newIsGroup,
                        });
                    }
                    else {
                        recordNonNativeFields.push({
                            path: NonSchemaNativePath.SHIFT_IS_GROUP,
                            isGroup: newIsGroup,
                        });
                    }
                    return __assign(__assign({}, prev), (_a = {}, _a[recordId] = recordNonNativeFields, _a));
                });
            }
        };
    },
});
var isNeedsCoverageEnabledState = selector({
    key: 'isNeedsCoverageEnabledState',
    get: function (_a) {
        var get = _a.get;
        var isNewShiftTemplateEnabled = get(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
        var isNeedsCoverageFeatureEnabled = get(featureEnableStatusState(NEEDS_COVERAGE));
        // Need both feature gating to be turned on
        return isNeedsCoverageFeatureEnabled && isNewShiftTemplateEnabled;
    },
});
export { scheduleZonedTimeRangeSelector, scheduleRowLengthSelector, scheduleCollectionIdState, scheduleTimeOffSelector, scheduleShiftCardBasicInfoSelector, scheduleShiftCanRecommendSelector, scheduleShiftCardColorSelector, scheduleShiftOpenCountState, shiftModalTimeZoneIdState, isNeedsCoverageEnabledState, shiftModalIsGroupState, };
