import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal, Spin, Steps } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DefaultRateFileValidationResultType } from 'src/services/Interfaces';
import { LoadingOutlined } from '@ant-design/icons';
import usePayRateV2Api from '../../../usePayRateV2Api';
import { defaultRateValidationErrorInfoState, uploadedDefaultRateFileState, uploadedRateFilePassVerifyState, } from '../../../atoms';
import './DefaultRateImportModal.scss';
import { TemplateDownloadIcon } from './utils/icons/TemplateDownloadIcon';
import UploadDefaultRateFileSection from './ImportSteps/UploadDefaultRateFileSection';
import VerifyFileSection from './ImportSteps/VerifyFileSection';
import ImportResultSection from './ImportSteps/ImportResultSection';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var DefaultRateImportModal = function (_a) {
    var isModalVisible = _a.isModalVisible, onModalClose = _a.onModalClose;
    var _b = usePayRateV2Api(), exportDefaultRateTemplateFile = _b.exportDefaultRateTemplateFile, validateDefaultRateFile = _b.validateDefaultRateFile, processDefaultRateFile = _b.processDefaultRateFile;
    var _c = useState(0), currentStep = _c[0], setCurrentStep = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var downloadTemplateRef = useRef(null);
    var _e = useState(), templateFileUrl = _e[0], setTemplateFileUrl = _e[1];
    var _f = useRecoilState(uploadedDefaultRateFileState), uploadedFile = _f[0], setUploadedFile = _f[1];
    var _g = useRecoilState(uploadedRateFilePassVerifyState), uploadedRateFilePassVerify = _g[0], setUploadedRateFilePassVerify = _g[1];
    var setDefaultRateValidationErrorInfo = useSetRecoilState(defaultRateValidationErrorInfoState);
    var _h = useState(false), importSuccess = _h[0], setImportSuccess = _h[1];
    useEffect(function () {
        if (templateFileUrl && downloadTemplateRef.current) {
            downloadTemplateRef.current.click();
        }
    }, [templateFileUrl]);
    var onStepChange = useCallback(function () {
        setCurrentStep(function (prev) { return prev + 1; });
    }, []);
    var onCancelBtnClick = useCallback(function () {
        setUploadedFile(undefined);
        setUploadedRateFilePassVerify(false);
        setDefaultRateValidationErrorInfo([]);
        onModalClose();
    }, [
        onModalClose,
        setDefaultRateValidationErrorInfo,
        setUploadedFile,
        setUploadedRateFilePassVerify,
    ]);
    var onStepButtonClick = useCallback(function () {
        if (currentStep === 0) {
            if (!(uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url)) {
                return;
            }
            setIsLoading(true);
            validateDefaultRateFile.mutate({
                url: uploadedFile.url,
            }, {
                onSuccess: function (resp) {
                    if (resp) {
                        if (resp.result === DefaultRateFileValidationResultType.VALIDATED) {
                            setUploadedRateFilePassVerify(true);
                        }
                        else if (resp.result === DefaultRateFileValidationResultType.FAILED) {
                            setUploadedRateFilePassVerify(false);
                            setDefaultRateValidationErrorInfo(resp.errors);
                        }
                        setIsLoading(false);
                        onStepChange();
                    }
                },
                onError: function () {
                    setIsLoading(false);
                },
            });
        }
        else if (currentStep === 1) {
            // File is not valid, surface error msg and go back to upload step
            if (!uploadedRateFilePassVerify) {
                setCurrentStep(function (prev) { return prev - 1; });
                return;
            }
            // File is valid, import file to system
            else {
                if (!(uploadedFile === null || uploadedFile === void 0 ? void 0 : uploadedFile.url)) {
                    return;
                }
                // Import file to system
                setIsLoading(true);
                processDefaultRateFile.mutate({
                    url: uploadedFile.url,
                }, {
                    onSuccess: function (resp) {
                        if (resp) {
                            if (resp.result === DefaultRateFileValidationResultType.PROCESSED) {
                                setImportSuccess(true);
                            }
                            else if (resp.result === DefaultRateFileValidationResultType.FAILED) {
                                setImportSuccess(false);
                            }
                            setIsLoading(false);
                            onStepChange();
                        }
                    },
                    onError: function () {
                        setIsLoading(false);
                    },
                });
            }
        }
        else if (currentStep === 2) {
            if (importSuccess) {
                onCancelBtnClick();
            }
            else {
                //Go back to upload step
                setCurrentStep(0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentStep,
        onStepChange,
        setUploadedFile,
        setUploadedRateFilePassVerify,
        setDefaultRateValidationErrorInfo,
        onCancelBtnClick,
        uploadedFile,
        uploadedRateFilePassVerify,
        setIsLoading,
    ]);
    var renderGoNextButtonText = useMemo(function () {
        if (currentStep === 0)
            return 'Verify Upload';
        if (currentStep === 1) {
            if (uploadedRateFilePassVerify) {
                return 'Import';
            }
            else {
                return 'Go Back and Upload Again';
            }
        }
        if (currentStep === 2) {
            if (importSuccess) {
                return 'Finish';
            }
            else {
                return 'Go Back and Retry';
            }
        }
        return 'Finish';
    }, [currentStep, importSuccess, uploadedRateFilePassVerify]);
    var generateTemplateFile = useCallback(function () {
        exportDefaultRateTemplateFile.mutate(undefined, {
            onSuccess: function (resp) {
                if (resp) {
                    setTemplateFileUrl(resp.url);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var modalFooter = useMemo(function () { return (_jsxs("div", { className: "ImportModalFooter", children: [_jsxs("div", { className: "text-blue-400 font-medium cursor-pointer flex items-center hover:underline", onClick: generateTemplateFile, children: [_jsx(TemplateDownloadIcon, { width: '', height: '', fill: '', className: "mb-[2px]" }), "Download Default Rates Templates"] }), _jsxs("div", { children: [_jsx(Button, { className: "CancelBtn", onClick: onCancelBtnClick, children: "Cancel" }), _jsx(Button, { className: "SaveBtn", onClick: onStepButtonClick, disabled: currentStep === 0 && !uploadedFile, children: renderGoNextButtonText })] })] })); }, [
        currentStep,
        generateTemplateFile,
        onCancelBtnClick,
        onStepButtonClick,
        renderGoNextButtonText,
        uploadedFile,
    ]);
    return (_jsxs(Modal, { open: isModalVisible, centered: true, footer: modalFooter, closable: false, destroyOnClose: true, className: "DefaultRateImportModal", width: 800, children: [_jsx(Spin, { spinning: isLoading, size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: _jsxs("div", { children: [_jsx("div", { className: "text-lg font-medium mb-8", children: "Import Default Rates" }), _jsx(Steps, { className: "DefaultRateImportSteps", size: "small", current: currentStep, items: [
                                {
                                    title: 'Upload',
                                },
                                {
                                    title: 'Verify',
                                },
                                {
                                    title: 'Finish',
                                },
                            ] }), currentStep === 0 && _jsx(UploadDefaultRateFileSection, {}), currentStep === 1 && _jsx(VerifyFileSection, {}), currentStep === 2 && _jsx(ImportResultSection, { isSuccess: importSuccess })] }) }), _jsx("a", { ref: downloadTemplateRef, href: templateFileUrl, download: true })] }));
};
export default React.memo(DefaultRateImportModal);
