var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
import { cloneDeep } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { minColumnWidth } from 'src/shared';
import { tableDisplaySettingsState, tableSelectedViewIdState } from 'src/state';
import { getNewValidatedDisplaySettings } from 'src/utils/Collection';
import { usePageApi, useReverseCollectionApi } from '../api';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useDisplaySettings = function () {
    var useUpdateDisplaySettings = usePageApi().useUpdateDisplaySettings;
    var useUpdateReverseCollectionView = useReverseCollectionApi().useUpdateReverseCollectionView;
    var applyDisplaySettingsToView = useRecoilCallback(function (_a) {
        var set = _a.set, snapshot = _a.snapshot;
        return function (tableId_1, newDisplaySetting_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([tableId_1, newDisplaySetting_1], args_1, true), void 0, function (tableId, newDisplaySetting, shouldSave, //! Should be passed in when changing filter
            isBlock, //! Should be false for reverse collection
            tableViewId, isPayShiftOrTimeoff) {
                var release, viewId, displaySettings, prevDisplaySettings_1, _a, newDisplaySettingsState, newDisplaySettingsRequest, requestBody, requestBodyForPay;
                if (shouldSave === void 0) { shouldSave = true; }
                if (isBlock === void 0) { isBlock = true; }
                if (isPayShiftOrTimeoff === void 0) { isPayShiftOrTimeoff = undefined; }
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            release = snapshot.retain();
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, , 6, 7]);
                            viewId = tableViewId !== null && tableViewId !== void 0 ? tableViewId : snapshot.getLoadable(tableSelectedViewIdState(tableId)).valueMaybe();
                            displaySettings = snapshot
                                .getLoadable(tableDisplaySettingsState(tableId))
                                .valueMaybe();
                            if (!viewId || !displaySettings)
                                return [2 /*return*/];
                            prevDisplaySettings_1 = cloneDeep(displaySettings);
                            _a = getNewValidatedDisplaySettings(prevDisplaySettings_1, newDisplaySetting), newDisplaySettingsState = _a[0], newDisplaySettingsRequest = _a[1];
                            //! Set displaySettings state
                            set(tableDisplaySettingsState(tableId), newDisplaySettingsState);
                            if (!shouldSave)
                                return [2 /*return*/];
                            requestBody = {
                                viewId: viewId,
                                displaySettings: newDisplaySettingsRequest,
                            };
                            requestBodyForPay = {
                                payViewId: viewId,
                                displaySettings: newDisplaySettingsRequest,
                                shiftOrTimeoff: isPayShiftOrTimeoff,
                            };
                            if (!isBlock) return [3 /*break*/, 3];
                            return [4 /*yield*/, useUpdateDisplaySettings.mutateAsync(isPayShiftOrTimeoff !== undefined ? requestBodyForPay : requestBody, {
                                    onError: function () {
                                        //!MARK: revert state
                                        set(tableDisplaySettingsState(tableId), prevDisplaySettings_1);
                                    },
                                })];
                        case 2:
                            _b.sent();
                            return [3 /*break*/, 5];
                        case 3: 
                        // for reverse collection
                        return [4 /*yield*/, useUpdateReverseCollectionView.mutateAsync({ viewId: viewId, displaySettings: newDisplaySettingsRequest }, {
                                onError: function () {
                                    //!MARK: Revert state
                                    set(tableDisplaySettingsState(tableId), prevDisplaySettings_1);
                                },
                            })];
                        case 4:
                            // for reverse collection
                            _b.sent();
                            _b.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            release();
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var addSortEntryToBlock = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, sortEntry) { return __awaiter(void 0, void 0, void 0, function () {
            var displaySettings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        displaySettings = snapshot
                            .getLoadable(tableDisplaySettingsState(blockId))
                            .valueMaybe();
                        if (!displaySettings)
                            return [2 /*return*/];
                        return [4 /*yield*/, applyDisplaySettingsToView(blockId, {
                                sortBy: __spreadArray(__spreadArray([], displaySettings.sortBy, true), [sortEntry], false),
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var editSortEntryInBlock = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId_1, editedSortEntry_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([blockId_1, editedSortEntry_1], args_1, true), void 0, function (blockId, editedSortEntry, isPayShiftOrTimeoff) {
                var displaySettings, tempBlockViewSetting, prevIndex;
                if (isPayShiftOrTimeoff === void 0) { isPayShiftOrTimeoff = undefined; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            displaySettings = snapshot
                                .getLoadable(tableDisplaySettingsState(blockId))
                                .valueMaybe();
                            if (!displaySettings)
                                return [2 /*return*/];
                            tempBlockViewSetting = cloneDeep(displaySettings);
                            prevIndex = _.findIndex(tempBlockViewSetting.sortBy, function (e) { return e.id === editedSortEntry.id; });
                            tempBlockViewSetting.sortBy[prevIndex] = editedSortEntry;
                            return [4 /*yield*/, applyDisplaySettingsToView(blockId, {
                                    sortBy: tempBlockViewSetting.sortBy,
                                }, undefined, undefined, undefined, isPayShiftOrTimeoff)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var updateColumnWidth = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId_1, columnId_1, width_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([blockId_1, columnId_1, width_1], args_1, true), void 0, function (blockId, columnId, width, isPayShiftOrTimeoff) {
                var displaySettings, tempBlockViewSetting, schemaOption;
                if (isPayShiftOrTimeoff === void 0) { isPayShiftOrTimeoff = undefined; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            displaySettings = snapshot
                                .getLoadable(tableDisplaySettingsState(blockId))
                                .valueMaybe();
                            if (!displaySettings)
                                return [2 /*return*/];
                            tempBlockViewSetting = cloneDeep(displaySettings);
                            schemaOption = _.find(tempBlockViewSetting.schemaOptions, function (option) { return option.schemaId === columnId; });
                            if (schemaOption) {
                                schemaOption.width = Math.max(minColumnWidth, width);
                            }
                            return [4 /*yield*/, applyDisplaySettingsToView(blockId, {
                                    schemaOptions: tempBlockViewSetting.schemaOptions,
                                }, undefined, undefined, undefined, isPayShiftOrTimeoff)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var updateColumnOrder = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId_1, newSchemaOptions_1, newColumnIds_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([blockId_1, newSchemaOptions_1, newColumnIds_1], args_1, true), void 0, function (blockId, newSchemaOptions, newColumnIds, isPayShiftOrTimeoff) {
                var displaySettings, tempBlockViewSetting;
                if (isPayShiftOrTimeoff === void 0) { isPayShiftOrTimeoff = undefined; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            displaySettings = snapshot
                                .getLoadable(tableDisplaySettingsState(blockId))
                                .valueMaybe();
                            if (!displaySettings)
                                return [2 /*return*/];
                            tempBlockViewSetting = cloneDeep(displaySettings);
                            if (!newSchemaOptions && !newColumnIds)
                                return [2 /*return*/];
                            tempBlockViewSetting.schemaOptions = newSchemaOptions
                                ? newSchemaOptions
                                : _.sortBy(tempBlockViewSetting.schemaOptions, function (s) {
                                    return newColumnIds.indexOf(s.schemaId);
                                });
                            return [4 /*yield*/, applyDisplaySettingsToView(blockId, {
                                    schemaOptions: tempBlockViewSetting.schemaOptions,
                                }, undefined, undefined, undefined, isPayShiftOrTimeoff)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var showOrHideColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId_1, schemaId_1, hidden_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(void 0, __spreadArray([blockId_1, schemaId_1, hidden_1], args_1, true), void 0, function (blockId, schemaId, hidden, isPayShiftOrTimeoff) {
                var displaySettings, tempBlockViewSetting, schemaOpts;
                if (isPayShiftOrTimeoff === void 0) { isPayShiftOrTimeoff = undefined; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            displaySettings = snapshot
                                .getLoadable(tableDisplaySettingsState(blockId))
                                .valueMaybe();
                            if (!displaySettings)
                                return [2 /*return*/];
                            tempBlockViewSetting = cloneDeep(displaySettings);
                            schemaOpts = tempBlockViewSetting.schemaOptions;
                            schemaOpts
                                .filter(function (schema) { return schema.schemaId === schemaId; })
                                .map(function (opt) { return (opt.hidden = hidden); });
                            tempBlockViewSetting.schemaOptions = schemaOpts;
                            return [4 /*yield*/, applyDisplaySettingsToView(blockId, {
                                    schemaOptions: tempBlockViewSetting.schemaOptions,
                                }, undefined, undefined, undefined, isPayShiftOrTimeoff)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return {
        applyDisplaySettingsToView: applyDisplaySettingsToView,
        addSortEntryToBlock: addSortEntryToBlock,
        editSortEntryInBlock: editSortEntryInBlock,
        updateColumnOrder: updateColumnOrder,
        updateColumnWidth: updateColumnWidth,
        showOrHideColumn: showOrHideColumn,
    };
};
export { useDisplaySettings };
