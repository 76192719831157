var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdState, scheduleCollectionIdState } from 'src/state';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { toast } from 'react-toastify';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useScheduleTemplateApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var accountId = useRecoilValue(accountIdState);
    var scheduleCollectionId = useRecoilValue(scheduleCollectionIdState);
    var refetchCollection = useRefetchCollection();
    var useFetchScheduleTemplateList = function (request) {
        var _a;
        return useQuery(["templateList", accountId, (_a = request.filters[0]) !== null && _a !== void 0 ? _a : ''], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/template/list',
            }, {
                filters: request.filters,
                accountId: accountId,
            });
        }, __assign({}, request.options));
    };
    var fetchScheduleTemplateList = function (request) {
        return customFetch({
            method: 'POST',
            url: 'scheduling/template/list',
        }, {
            filters: request.filters,
            accountId: accountId,
        });
    };
    var useFetchScheduleTemplateApply = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/template/apply',
            }, request);
        });
    };
    var useFetchScheduleTemplateSave = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/template',
            }, request);
        });
    };
    var useFetchScheduleTemplateDelete = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/template/delete',
            }, request);
        });
    };
    var useFetchScheduleTemplateEdit = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/template/edit',
            }, request);
        });
    };
    // New scheduling template
    var useGetAllShiftTemplates = function (request) {
        var _a;
        return useQuery(['allShiftTemplates', JSON.stringify((_a = request.body) === null || _a === void 0 ? void 0 : _a.filters)], function () {
            return customFetch({
                method: 'POST',
                url: 'shift_templates/list',
            }, request.body);
        }, __assign({}, request.options));
    };
    var useApplyShiftTemplateListSettings = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'shift_templates/config',
            }, request);
        });
    };
    var useDeleteShiftTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'DELETE',
                url: "shift_templates/".concat(request.templateId),
            }, request);
        });
    };
    var useCreateNewShiftTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'shift_templates',
            }, request);
        });
    };
    var useGetShiftTemplateDetail = function (request) {
        return useQuery(['shiftTemplateDetail', JSON.stringify(request.body)], function () {
            var _a, _b, _c, _d, _e;
            return customFetch({
                method: 'GET',
                url: "shift_templates/".concat((_a = request.body) === null || _a === void 0 ? void 0 : _a.templateId).concat(((_b = request.body) === null || _b === void 0 ? void 0 : _b.date) ? "?date=".concat((_c = request.body) === null || _c === void 0 ? void 0 : _c.date) : '').concat(((_d = request.body) === null || _d === void 0 ? void 0 : _d.timezone) ? "&timezone=".concat((_e = request.body) === null || _e === void 0 ? void 0 : _e.timezone) : ''),
            });
        }, __assign({}, request.options));
    };
    var useUpdateShiftTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "shift_templates/".concat(request.templateId),
            }, request.templateInfo);
        });
    };
    var useApplyShiftTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'shift_templates/apply',
            }, request);
        });
    };
    var useCheckTemplateRelations = function (templateId) {
        return useQuery(['shiftTemplateRelations', templateId], function () {
            return customFetch({
                method: 'GET',
                url: "shift_templates/check_relations/".concat(templateId),
            });
        });
    };
    var useGetShiftsForTemplates = function (request) {
        var _a, _b, _c, _d;
        return useQuery([
            'shiftTemplatePropagateShifts',
            ((_a = request.body) === null || _a === void 0 ? void 0 : _a.fromTemplateId) || 'undefined',
            ((_b = request.body) === null || _b === void 0 ? void 0 : _b.startAt) || 'undefined',
            ((_c = request.body) === null || _c === void 0 ? void 0 : _c.endAt) || 'undefined',
            String((_d = request.body) === null || _d === void 0 ? void 0 : _d.includesPublished),
        ], function () {
            return customFetch({
                method: 'POST',
                url: "shift_templates/propagate",
            }, request.body);
        }, __assign({}, request.options));
    };
    var propagateShiftsByTemplatesMutation = useMutation(function (request) {
        return customFetch({
            method: 'PUT',
            url: "shift_templates/propagate",
        }, request);
    }, {
        onSuccess: function (data) {
            if (scheduleCollectionId) {
                refetchCollection(scheduleCollectionId);
            }
            toast("Successfully updated ".concat(data === null || data === void 0 ? void 0 : data.size, " Shifts"), {
                position: toast.POSITION.BOTTOM_CENTER,
                type: 'success',
            });
        },
    });
    return {
        useFetchScheduleTemplateApply: useFetchScheduleTemplateApply,
        useFetchScheduleTemplateList: useFetchScheduleTemplateList,
        useFetchScheduleTemplateSave: useFetchScheduleTemplateSave,
        useFetchScheduleTemplateDelete: useFetchScheduleTemplateDelete,
        useFetchScheduleTemplateEdit: useFetchScheduleTemplateEdit,
        fetchScheduleTemplateList: fetchScheduleTemplateList,
        useGetAllShiftTemplates: useGetAllShiftTemplates,
        useDeleteShiftTemplate: useDeleteShiftTemplate(),
        useCreateNewShiftTemplate: useCreateNewShiftTemplate(),
        useGetShiftTemplateDetail: useGetShiftTemplateDetail,
        useUpdateShiftTemplate: useUpdateShiftTemplate(),
        useApplyShiftTemplate: useApplyShiftTemplate(),
        useApplyShiftTemplateListSettings: useApplyShiftTemplateListSettings(),
        useCheckTemplateRelations: useCheckTemplateRelations,
        useGetShiftsForTemplates: useGetShiftsForTemplates,
        propagateShiftsByTemplatesMutation: propagateShiftsByTemplatesMutation,
    };
};
