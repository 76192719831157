import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useRecordDetail } from 'src/hooks/component';
import { shiftTemplateDetailModalVisibleState } from 'src/state';
var HoverRecordMenu = function (props) {
    var rid = props.rid, title = props.title, children = props.children;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var isShiftTemplateDetailModalVisible = useRecoilValue(shiftTemplateDetailModalVisibleState);
    var content = useMemo(function () { return (_jsxs("div", { children: [_jsx("p", { className: "text-lg font-medium", children: title }), _jsxs(Button, { type: "link", className: "flex flex-row items-center p-0 font-medium", onClick: function (e) {
                    e.stopPropagation();
                    openRecordDetail(rid);
                }, children: ["View Details", _jsx(ArrowRightOutlined, { className: "mt-[-1px]" })] })] })); }, [openRecordDetail, rid, title]);
    if (isShiftTemplateDetailModalVisible) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsx(Popover, { placement: "topLeft", content: content, children: children }));
};
export default React.memo(HoverRecordMenu);
