var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableCanEditSelector, tableCollectionIdSelector } from 'src/state';
import { CollectionTableType } from 'src/shared';
import EmptyCard from './EmptyCard';
import './EmptyCard.scss';
var EmptyCardWrapper = function (props) {
    var _a = useState(false), isHovering = _a[0], setIsHovering = _a[1];
    var collectionId = useRecoilValue(tableCollectionIdSelector(props.tableId));
    var canEditCollection = useRecoilValue(collectionTableCanEditSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    if (!canEditCollection) {
        return _jsx(_Fragment, {});
    }
    else if (!isHovering) {
        return _jsx("div", { className: "EmptyCardContent", onMouseOver: function () { return setIsHovering(true); } });
    }
    return _jsx(EmptyCard, __assign({}, props, { setIsHovering: setIsHovering }));
};
export default React.memo(EmptyCardWrapper);
