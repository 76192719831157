import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UploadOutlined } from '@ant-design/icons';
import { Card, Button, Tag, Image, Popconfirm } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { useFileApi } from 'src/hooks/api/useFileApi';
import { disableScroll } from '../../../utils/Collection';
import ComponentWithUpload, { UploadFileType } from '../../ComponentWithUpload';
import ZiraPopover from '../../../ui/ZiraPopover';
import './FileUploadPopover.scss';
var defaultAcceptedFileType = [
    UploadFileType.IMAGE,
    UploadFileType.AUDIO,
    UploadFileType.VIDEO,
    UploadFileType.DOC,
    UploadFileType.DOCX,
    UploadFileType.PDF,
];
var FileUploadPopover = function (_a) {
    var _b;
    var files = _a.files, editFile = _a.editFile, isDetailModal = _a.isDetailModal, acceptedFileType = _a.acceptedFileType, children = _a.children;
    var _c = useState((_b = files === null || files === void 0 ? void 0 : files[0]) !== null && _b !== void 0 ? _b : { name: '', url: '', type: '' }), currentFile = _c[0], setCurrentFile = _c[1];
    var _d = useMemo(function () { return currentFile; }, [currentFile]), name = _d.name, url = _d.url, type = _d.type;
    var isImageType = useMemo(function () { return UploadFileType.IMAGE.includes(type.split('/')[0]); }, [type]);
    var _e = useState(false), popOverVisible = _e[0], setPopOverVisible = _e[1];
    acceptedFileType = acceptedFileType !== null && acceptedFileType !== void 0 ? acceptedFileType : defaultAcceptedFileType;
    var onVisibleChange = useCallback(function (visible) {
        setPopOverVisible(visible);
    }, []);
    var handleDeleteFile = useFileApi().deleteFile;
    var updateFile = useCallback(function (fileInfo) {
        onVisibleChange(false);
        var file = fileInfo.file;
        var newFileData = { name: file.name, url: file.response.data.url, type: file.type };
        editFile([newFileData]);
        // Delete old file
        if (isDetailModal === false) {
            handleDeleteFile(url);
        }
        setCurrentFile(newFileData);
        disableScroll(false);
    }, [editFile, handleDeleteFile, isDetailModal, onVisibleChange, url]);
    var uploadContent = useCallback(function () { return (_jsxs(Card, { size: "small", className: "UploadPopoverCard", bordered: false, children: [url && (_jsxs(_Fragment, { children: [isImageType ? (_jsx(Image, { onClick: function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }, width: 150, src: url })) : (_jsx(Tag, { className: "FileTag", children: _jsx(Popconfirm, { title: "Open ".concat(name, " in a new tab."), okText: "Open", cancelText: "Cancel", onConfirm: function () { var _a; return (_a = open(url, '_blank')) === null || _a === void 0 ? void 0 : _a.focus(); }, children: _jsx("span", { className: "TagText", children: name }) }) })), _jsx(ZiraDeletePopconfirm, { className: "UploadFileButton", title: "Are you sure you want to delete this file?", onConfirm: function () {
                            return updateFile({
                                file: { name: '', response: { data: { url: '' } }, type: '' },
                            });
                        }, children: _jsx(Button, { type: "text", danger: true, children: "Delete file" }) })] })), _jsx(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: updateFile, type: UploadUrlType.Collection, children: _jsxs(Button, { type: "primary", className: "UploadFileButton", style: { marginTop: url ? '10px' : '' }, children: [_jsx(UploadOutlined, {}), "Upload a file"] }) })] })); }, [acceptedFileType, isImageType, name, updateFile, url]);
    return (_jsxs(_Fragment, { children: [_jsxs(ZiraPopover, { className: !children ? 'FileUploadPopover' : '', trigger: "click", content: uploadContent, destroyTooltipOnHide: true, open: popOverVisible, onLocalOpenChange: onVisibleChange, children: [url && !children && (_jsx(_Fragment, { children: isImageType ? (_jsx("img", { height: 24, src: url })) : (_jsx(Tag, { style: { maxWidth: '90%' }, children: _jsx("span", { className: "TagText", children: name }) })) })), !url && !children && (_jsx("span", { className: isDetailModal ? 'EmptyContent' : '', children: isDetailModal ? 'Insert Value' : '' })), children] }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setPopOverVisible(false);
                }, style: { display: popOverVisible ? 'block' : 'none' } })] }));
};
export default React.memo(FileUploadPopover);
