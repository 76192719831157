import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'src/state';
var CANNY_APP_ID = process.env.REACT_APP_CANNY_APP_ID;
var FeedbackBtn = function () {
    var currentUserInfo = useRecoilValue(currentUserState);
    var _a = useState(true), showFeedbackBtn = _a[0], setShowFeedbackBtn = _a[1];
    useEffect(function () {
        if (currentUserInfo) {
            try {
                // eslint-disable-next-line no-extra-semi, @typescript-eslint/no-explicit-any
                ;
                window.Canny('identify', {
                    appID: CANNY_APP_ID,
                    user: {
                        // Pass back current user info
                        email: currentUserInfo.email,
                        name: currentUserInfo.fullName,
                        id: currentUserInfo.recordId,
                        companies: [
                            {
                                id: currentUserInfo.accountId,
                                name: currentUserInfo.accountName,
                                created: currentUserInfo.accountCreatedAt,
                            },
                        ],
                    },
                });
            }
            catch (_a) {
                setShowFeedbackBtn(false);
            }
        }
    }, [currentUserInfo]);
    if (!showFeedbackBtn) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(FormOutlined, { className: "mr-2" }), _jsx("a", { className: "text-black hover:text-black", "data-canny-link": true, href: "https://teambridge.canny.io", target: "_blank", rel: "noreferrer", children: "Feedback" })] }));
};
export default React.memo(FeedbackBtn);
