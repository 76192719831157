var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FormIconSvg = function () { return (_jsxs("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.66634 3.4375V5.3125H9.99967V3.4375H2.66634ZM1.66634 2.1875C1.48225 2.1875 1.33301 2.32741 1.33301 2.5V6.25C1.33301 6.42259 1.48225 6.5625 1.66634 6.5625H10.9997C11.1838 6.5625 11.333 6.42259 11.333 6.25V2.5C11.333 2.32741 11.1838 2.1875 10.9997 2.1875H1.66634Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.66634 9.6875V11.5625H9.99967V9.6875H2.66634ZM1.66634 8.4375C1.48225 8.4375 1.33301 8.57741 1.33301 8.75V12.5C1.33301 12.6726 1.48225 12.8125 1.66634 12.8125H10.9997C11.1838 12.8125 11.333 12.6726 11.333 12.5V8.75C11.333 8.57741 11.1838 8.4375 10.9997 8.4375H1.66634Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.00228 6.5625V8.4375H13.3329V6.5625H6.00228ZM5.00228 5.3125C4.81818 5.3125 4.66895 5.45241 4.66895 5.625V9.375C4.66895 9.54759 4.81818 9.6875 5.00228 9.6875H14.3329C14.517 9.6875 14.6663 9.54759 14.6663 9.375V5.625C14.6663 5.45241 14.517 5.3125 14.3329 5.3125H5.00228Z", fill: "black" })] })); };
export var FormIcon = function (props) { return (_jsx(Icon, __assign({ component: FormIconSvg }, props))); };
