var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useRecordDetail, useTimeZone } from 'src/hooks/component';
import { ScheduleView } from 'src/services';
import { scheduleCardHeightSelector, scheduleTimeOffSelector, scheduleViewTypeSelector, } from 'src/state';
import { SHIFT_CARD_HEIGHT_SM } from '../Constant';
import './ShiftCard/ShiftCard.scss';
import ShiftCardButtons from './ShiftCard/ShiftCardButtons';
var TimeOffCard = function (_a) {
    var blockId = _a.blockId, recordId = _a.recordId, groupByRecordId = _a.groupByRecordId, style = _a.style, openShiftDetailModal = _a.openShiftDetailModal, createNewShift = _a.createNewShift, deleteRecord = _a.deleteRecord;
    var timeOff = useRecoilValue(scheduleTimeOffSelector({ blockId: blockId, recordId: recordId }));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var _b = useState(false), isHovering = _b[0], setIsHovering = _b[1];
    var _c = useState(false), isOverlayVisible = _c[0], setIsOverlayVisible = _c[1];
    var cardHeight = useRecoilValue(scheduleCardHeightSelector(blockId));
    var timeZone = useTimeZone().timeZone;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    if (!timeOff)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ShiftCard bg-gray-50", onClick: function () { return openRecordDetail(recordId, timeZone); }, style: __assign(__assign({}, style), { height: scheduleView === ScheduleView.WEEK ? cardHeight : SHIFT_CARD_HEIGHT_SM }), onMouseEnter: function () { return setIsHovering(true); }, onMouseLeave: function () { return setIsHovering(false); }, children: [_jsx("div", { className: 'tagContainer ' + (scheduleView == 'WEEK')
                            ? 'displayedTime'
                            : 'displayedTimeSm', children: _jsxs("span", { children: [timeOff.displayTime, " ", "".concat(timeOff.type, " (Time Off)")] }) }), (isHovering || isOverlayVisible) && (_jsx(ShiftCardButtons, { blockId: blockId, recordId: recordId, groupByRecordId: groupByRecordId, type: "timeOff", openShiftDetailModal: openShiftDetailModal, createNewShift: createNewShift, deleteRecord: deleteRecord, isOverlayVisible: isOverlayVisible, setIsOverlayVisible: setIsOverlayVisible }))] }), isOverlayVisible && (_jsx("div", { className: "fpOverlay", onClick: function () {
                    setIsOverlayVisible(false);
                }, style: { display: 'block' } }))] }));
};
export default React.memo(TimeOffCard);
