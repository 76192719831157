import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { ExportFileItemStatus } from 'src/shared';
import { useNavigationStaticMenuApi } from 'src/hooks/api/useNavigationStaticMenuApi';
import FileItem from './FileItem';
import FilesHubEmptyStateView from './FilesHubEmptyStateView';
var ExportSection = function () {
    var useGetExportHistory = useNavigationStaticMenuApi().useGetExportHistory;
    var _a = useGetExportHistory(), data = _a.data, refetch = _a.refetch, isLoading = _a.isLoading;
    var intervalRef = useRef(null);
    // when data changed, detect pending items and refetch every 3s
    useEffect(function () {
        if (!data)
            return;
        var containsPendingItem = false;
        for (var i = 0; i < data.exportHistories.length; i++) {
            if (data.exportHistories[i].status === ExportFileItemStatus.RUNNING ||
                data.exportHistories[i].status === ExportFileItemStatus.REQUESTED) {
                containsPendingItem = true;
                break;
            }
        }
        if (containsPendingItem) {
            intervalRef.current = setInterval(function () { return refetch(); }, 3000);
        }
        else if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        return function () {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    if (isLoading) {
        return (_jsx("div", { className: "FilesPageContainer px-10", children: _jsx(ZiraRowsSkeleton, { rowNumber: 10 }) }));
    }
    return (_jsx("div", { className: "FilesPageContainer px-10", children: (data === null || data === void 0 ? void 0 : data.exportHistories.length) === 0 ? (_jsx(FilesHubEmptyStateView, {})) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-xl font-semibold", children: "Export Files" }), _jsx("div", { className: "mb-10 mt-2 font-medium text-gray-400", children: "This is where you can view all your export requests and download files." }), data === null || data === void 0 ? void 0 : data.exportHistories.map(function (item) { return _jsx(FileItem, { file: item }, item.id); })] })) }));
};
export default React.memo(ExportSection);
