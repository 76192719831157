var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import SimpleDisplayTitle from './SimpleDisplayTitle';
import TitleInput from './TitleInput';
var TableTitle = function (props) {
    return _jsx(PlainDisplayWithInput, __assign({}, props));
};
var PlainDisplayWithInput = function (props) {
    var title = props.title, updateTitle = props.updateTitle;
    if (!updateTitle) {
        return _jsx(SimpleDisplayTitle, { title: title });
    }
    return _jsx(TitleInput, { title: title, updateTitle: updateTitle });
};
export default React.memo(TableTitle);
