var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { capitalize, upperCase, cloneDeep, filter, findIndex, uniq, max } from 'lodash';
import { useRecoilValue } from 'recoil';
import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { useAccountApi } from 'src/hooks/api';
import { tableCollectionIdSelector, scheduleStartDayOfWeekSelector, scheduleTimeRangeState, blockTimeZoneState, } from 'src/state';
import { accountWeekOneStartDateSelector } from 'src/state/selectors/withAccountDetailState';
import moment from 'moment';
import { getWeekDayNumber } from 'src/utils/Account';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { USE_NC_WEEK_CORRECT_CALCULATION } from 'src/components/FeatureGatingKeyConstant';
import { shiftCollectionAssigneeSchemaIdSelector } from '../../Selector';
import { calculateDiffBetweenMomentConsideringDaylightSaving, convertDayOfWeekKeyToString, } from '../../Utils';
import { DEFAULT_NEED_TEMPLATE_DAY_OF_WEEK_DATA, DEFAULT_COVERAGE_TEMPLATE_DAY_OF_WEEK_DATA, NEED_COVERAGE_TEMPLATE_TABLE_FIRST_COLUMN_WIDTH, } from '../../Constant';
import { needsCoverageTemplateVisibleOption } from '../../Atom';
import ShiftNumberInputCell from './TableCell/ShiftNumberInputCell';
import ShiftTemplateSelectorCell from './TableCell/ShiftTemplateSelectorCell';
import TemplateIntervalSelectorCell from './TableCell/TemplateIntervalSelectorCell';
import UserSelectorCell from './TableCell/UserSelectorCell';
import NeedCoverageTableBuilder from './NeedCoverageTableBuilder';
import EmptyTableContent from './EmptyNeedCoverageSettingTableContent';
export var MAX_WEEK_INDEX_IN_NEEDS_COVERAGE = 5;
var ColumnSortArrow = function (_a) {
    var column = _a.column;
    return _jsx("span", { children: column && column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '' });
};
var ColumnHeaderWrapper = function (_a) {
    var children = _a.children, viewOnly = _a.viewOnly, column = _a.column;
    return (_jsxs("div", { className: "TableColumnTitle space-x-2 ".concat(viewOnly ? 'ml-[8px]' : 'ml-[25px]'), children: [_jsx("span", { children: children }), column && _jsx(ColumnSortArrow, { column: column })] }));
};
var NeedsCoverageTemplateDetailSettingTable = function (props) {
    var blockId = props.blockId, tableData = props.tableData, setTableData = props.setTableData, shiftTemplateList = props.shiftTemplateList, isNeedTemplate = props.isNeedTemplate;
    var blockStartDayOfWeek = useRecoilValue(scheduleStartDayOfWeekSelector(blockId));
    var timeRanges = useRecoilValue(scheduleTimeRangeState(blockId));
    var weekOneStartDate = useRecoilValue(accountWeekOneStartDateSelector);
    var startDayOfWeekNumber = weekOneStartDate
        ? moment(weekOneStartDate).day()
        : getWeekDayNumber(blockStartDayOfWeek);
    var timeZone = useRecoilValue(blockTimeZoneState(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var isCorrectCalculation = useRecoilValue(featureEnableStatusState(USE_NC_WEEK_CORRECT_CALCULATION));
    var userSchemaId = useRecoilValue(shiftCollectionAssigneeSchemaIdSelector(collectionId));
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var allUser = useGetUniversalSimpleList({
        body: {
            schemaId: userSchemaId,
        },
        options: {
            enabled: !!userSchemaId && !isNeedTemplate,
        },
    }).data;
    var userMap = useMemo(function () {
        var result = {};
        allUser === null || allUser === void 0 ? void 0 : allUser.forEach(function (user) {
            result[user.id] = user;
        });
        return result;
    }, [allUser]);
    var selectedShiftTemplateIds = useMemo(function () {
        if (!isNeedTemplate) {
            return [];
        }
        else {
            var allSelectedShiftTemplateIds_1 = [];
            tableData.map(function (record) {
                if (record.templateId) {
                    allSelectedShiftTemplateIds_1.push(record.templateId);
                }
            });
            return uniq(allSelectedShiftTemplateIds_1);
        }
    }, [isNeedTemplate, tableData]);
    var selectedUserIds = useMemo(function () {
        if (isNeedTemplate) {
            return [];
        }
        else {
            var allSelectedUserIds_1 = [];
            tableData.map(function (record) {
                if (record.userRid) {
                    allSelectedUserIds_1.push(record.userRid);
                }
            });
            return uniq(allSelectedUserIds_1);
        }
    }, [isNeedTemplate, tableData]);
    var containsEmptyFirstColumnSelection = useMemo(function () {
        var result = false;
        for (var _i = 0, tableData_1 = tableData; _i < tableData_1.length; _i++) {
            var record = tableData_1[_i];
            if (!record.templateId && isNeedTemplate) {
                result = true;
                break;
            }
            else if (!record.userRid && !isNeedTemplate) {
                result = true;
                break;
            }
        }
        return result;
    }, [isNeedTemplate, tableData]);
    // Table data mutation methods
    var addNewRecord = useCallback(function () {
        var newTableData = cloneDeep(tableData);
        var newRecordData = {
            id: uuidv4(),
            templateId: undefined,
            interval: 1,
            data: isNeedTemplate
                ? [DEFAULT_NEED_TEMPLATE_DAY_OF_WEEK_DATA]
                : [DEFAULT_COVERAGE_TEMPLATE_DAY_OF_WEEK_DATA],
        };
        newTableData.push(newRecordData);
        setTableData(newTableData);
    }, [isNeedTemplate, setTableData, tableData]);
    var deleteRecord = useCallback(function (recordId) {
        var tempTableData = cloneDeep(tableData);
        var newTableData = filter(tempTableData, function (record) { return record.id !== recordId; });
        setTableData(newTableData);
    }, [setTableData, tableData]);
    //Need template update shift template
    var updateRecordShiftTemplate = useCallback(function (recordId, selectedShiftTemplateIds) {
        var tempTableData = cloneDeep(tableData);
        var targetRecordIndex = findIndex(tempTableData, function (record) { return record.id === recordId; });
        if (targetRecordIndex >= 0 && shiftTemplateList) {
            if (selectedShiftTemplateIds.length === 0) {
                tempTableData[targetRecordIndex].templateId = undefined;
            }
            else {
                tempTableData[targetRecordIndex].templateId = selectedShiftTemplateIds[0];
            }
            setTableData(tempTableData);
        }
    }, [setTableData, shiftTemplateList, tableData]);
    var updateRecordShiftNumber = useCallback(function (recordId, shiftNumber, dayOfWeek, weekIndex) {
        var tempTableData = cloneDeep(tableData);
        var targetRecordIndex = findIndex(tempTableData, function (record) { return record.id === recordId; });
        if (targetRecordIndex >= 0) {
            var record = tempTableData[targetRecordIndex];
            if (!record.data[weekIndex])
                return;
            var dayOfWeekDataIndex = record.data[weekIndex].findIndex(function (entry) { return entry.date === dayOfWeek; });
            if (dayOfWeekDataIndex < 0)
                return;
            tempTableData[targetRecordIndex].data[weekIndex] = __spreadArray(__spreadArray(__spreadArray([], record.data[weekIndex].slice(0, dayOfWeekDataIndex), true), [
                {
                    date: dayOfWeek,
                    need: shiftNumber,
                }
            ], false), record.data[weekIndex].slice(dayOfWeekDataIndex + 1), true);
            setTableData(tempTableData);
        }
    }, [setTableData, tableData]);
    var updateRecordIntervalNumber = useCallback(function (recordId, interval) {
        var tempTableData = cloneDeep(tableData);
        var targetRecordIndex = findIndex(tempTableData, function (record) { return record.id === recordId; });
        if (targetRecordIndex >= 0) {
            tempTableData[targetRecordIndex].interval = interval;
            var prevDataLength = tempTableData[targetRecordIndex].data.length;
            for (var i = prevDataLength; i < interval; i++) {
                if (!tempTableData[targetRecordIndex].data[i]) {
                    tempTableData[targetRecordIndex].data.push(tempTableData[targetRecordIndex].data[(i - prevDataLength) % interval]);
                }
            }
            if (tempTableData[targetRecordIndex].data.length > interval) {
                tempTableData[targetRecordIndex].data = tempTableData[targetRecordIndex].data.slice(0, interval);
            }
            setTableData(tempTableData);
        }
    }, [setTableData, tableData]);
    var updateRecordSelectedUser = useCallback(function (recordId, userId) {
        var tempTableData = cloneDeep(tableData);
        var targetRecordIndex = findIndex(tempTableData, function (record) { return record.id === recordId; });
        if (targetRecordIndex >= 0) {
            tempTableData[targetRecordIndex].userRid = userId;
            setTableData(tempTableData);
        }
    }, [setTableData, tableData]);
    //Coverage template update shift template
    var updateRecordDayOfWeekShiftTemplates = useCallback(function (recordId, selectedShiftTemplates, dayOfWeek, weekIndex) {
        var tempTableData = cloneDeep(tableData);
        var targetRecordIndex = findIndex(tempTableData, function (record) { return record.id === recordId; });
        if (targetRecordIndex >= 0) {
            var record = tempTableData[targetRecordIndex];
            if (!record.data[weekIndex])
                return;
            var dayOfWeekDataIndex = record.data[weekIndex].findIndex(function (entry) { return entry.date === dayOfWeek; });
            tempTableData[targetRecordIndex].data[weekIndex] = __spreadArray(__spreadArray(__spreadArray([], record.data[weekIndex].slice(0, dayOfWeekDataIndex), true), [
                {
                    date: dayOfWeek,
                    templates: selectedShiftTemplates,
                }
            ], false), record.data[weekIndex].slice(dayOfWeekDataIndex + 1), true);
            setTableData(tempTableData);
        }
    }, [setTableData, tableData]);
    var overrideRecordDayOfWeekShiftTemplateTime = useCallback(function (recordId, selectedShiftTemplate, time, dayOfWeek, weekIndex, overrideAllTemplates) {
        var _a;
        var tempTableData = cloneDeep(tableData);
        var targetRecordIndex = findIndex(tempTableData, function (record) { return record.id === recordId; });
        if (targetRecordIndex >= 0) {
            var record = tempTableData[targetRecordIndex];
            if (overrideAllTemplates) {
                var newWeekData = record.data;
                newWeekData.map(function (item) {
                    item.map(function (d) {
                        var _a;
                        return (_a = d.templates) === null || _a === void 0 ? void 0 : _a.map(function (template) {
                            if (template.templateId === selectedShiftTemplate.templateId) {
                                template.dataOverride = time;
                            }
                        });
                    });
                });
                tempTableData[targetRecordIndex].data = newWeekData;
            }
            //Single template time override
            else {
                if (!record.data[weekIndex])
                    return;
                var dayOfWeekDataIndex = record.data[weekIndex].findIndex(function (entry) { return entry.date === dayOfWeek; });
                var currentTemplates = (_a = tempTableData[targetRecordIndex].data[weekIndex][dayOfWeekDataIndex]
                    .templates) !== null && _a !== void 0 ? _a : [];
                var index = currentTemplates.findIndex(function (template) { return template.templateId === selectedShiftTemplate.templateId; });
                if (index >= 0) {
                    currentTemplates[index].dataOverride = time;
                }
                tempTableData[targetRecordIndex].data[weekIndex] = __spreadArray(__spreadArray(__spreadArray([], record.data[weekIndex].slice(0, dayOfWeekDataIndex), true), [
                    {
                        date: dayOfWeek,
                        templates: currentTemplates,
                    }
                ], false), record.data[weekIndex].slice(dayOfWeekDataIndex + 1), true);
            }
            setTableData(tempTableData);
        }
    }, [setTableData, tableData]);
    var parseToRecordsData = useMemo(function () {
        var _a;
        var tempRecords = [];
        var maxIntervalInTable = (_a = max(tableData.map(function (record) { return record.interval; }))) !== null && _a !== void 0 ? _a : 1;
        var initialWeekOneStartDate = weekOneStartDate ? moment(weekOneStartDate) : undefined;
        tableData.map(function (record) {
            var _a;
            var shiftTemplateObj = {
                recordId: record.id,
                shiftTemplate: record.templateId,
            };
            var userObj = {
                recordId: record.id,
                userId: record.userRid,
            };
            var intervalObj = {
                recordId: record.id,
                interval: record.interval,
            };
            var templateRecordValue = {
                id: record.id,
                user: userObj,
                shiftTemplate: shiftTemplateObj,
                interval: intervalObj,
            };
            var weeksCountBetweenWeekOnAndCurrentWeek = initialWeekOneStartDate
                ? calculateDiffBetweenMomentConsideringDaylightSaving(moment(timeRanges[0]), initialWeekOneStartDate, timeZone, isCorrectCalculation) % maxIntervalInTable
                : 0;
            // loop from 0 to MAX_WEEK_INDEX
            for (var weekIndex = 0; weekIndex <= MAX_WEEK_INDEX_IN_NEEDS_COVERAGE; weekIndex++) {
                var _loop_1 = function (dayOfWeek) {
                    var actualDataWeekOffset = weekIndex + weeksCountBetweenWeekOnAndCurrentWeek;
                    var dayOfWeekKey = convertDayOfWeekKeyToString(dayOfWeek);
                    var key = "".concat(dayOfWeekKey).concat(weekIndex);
                    var actualDataIndex = actualDataWeekOffset % record.interval;
                    if (!record.data[actualDataIndex]) {
                        // copy from first week
                        templateRecordValue[key] = templateRecordValue["".concat(dayOfWeekKey, "0")];
                        return "continue";
                    }
                    var dayOfWeekData = record.data[actualDataIndex].find(function (entry) { return entry.date === dayOfWeekKey; });
                    var dayOfWeekValue = isNeedTemplate
                        ? ((_a = dayOfWeekData === null || dayOfWeekData === void 0 ? void 0 : dayOfWeekData.need) !== null && _a !== void 0 ? _a : 0)
                        : dayOfWeekData === null || dayOfWeekData === void 0 ? void 0 : dayOfWeekData.templates;
                    var dayObj = {
                        recordId: record.id,
                        number: dayOfWeekValue,
                        shiftTemplates: dayOfWeekValue,
                        dayOfWeek: dayOfWeekKey,
                        interval: record.interval,
                        weekIndex: weekIndex,
                    };
                    templateRecordValue[key] = dayObj;
                };
                for (var dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
                    _loop_1(dayOfWeek);
                }
            }
            tempRecords.push(templateRecordValue);
        });
        return tempRecords;
    }, [isNeedTemplate, tableData, timeRanges, timeZone, weekOneStartDate, isCorrectCalculation]);
    var viewOnly = useRecoilValue(needsCoverageTemplateVisibleOption).viewOnly;
    var parseToColumnData = useMemo(function () {
        var _a;
        var tempColumns = [];
        //First column
        if (isNeedTemplate) {
            tempColumns.push({
                Header: function (_a) {
                    var column = _a.column;
                    return (_jsx(ColumnHeaderWrapper, { column: column, viewOnly: viewOnly, children: "Shift Template" }));
                },
                accessor: 'shiftTemplate',
                width: NEED_COVERAGE_TEMPLATE_TABLE_FIRST_COLUMN_WIDTH,
                sticky: 'left',
                sortType: function (rowA, rowB, columnId) {
                    var _a, _b;
                    var valueA = rowA.values[columnId];
                    var valueB = rowB.values[columnId];
                    var rowAName = ((_a = shiftTemplateList.find(function (template) { return template.id === valueA.shiftTemplate; })) === null || _a === void 0 ? void 0 : _a.name) || '';
                    var rowBName = ((_b = shiftTemplateList.find(function (template) { return template.id === valueB.shiftTemplate; })) === null || _b === void 0 ? void 0 : _b.name) || '';
                    if (rowAName > rowBName)
                        return -1;
                    if (rowBName > rowAName)
                        return 1;
                    return 0;
                },
                Cell: function (_a) {
                    var value = _a.value;
                    return (_jsxs("div", { className: "MainColumnCellWrapper", children: [!viewOnly && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this record?", onConfirm: function () { return deleteRecord(value.recordId); }, placement: "bottomRight", children: _jsx(ZiraTooltip, { title: "Delete", children: _jsx("div", { className: "RecordDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-gray-60", onClick: function (e) { return e.stopPropagation(); }, children: _jsx(DeleteOutlinedIcon, {}) }) }) })), _jsx(ShiftTemplateSelectorCell, { shiftTemplateIds: value.shiftTemplate ? [value.shiftTemplate] : [], templateList: shiftTemplateList, updateRecordShiftTemplate: function (shiftTemplateIds) {
                                    return updateRecordShiftTemplate(value.recordId, shiftTemplateIds);
                                }, isNeedsTableType: true, selectedShiftTemplateIds: selectedShiftTemplateIds, disabled: false })] }));
                },
            });
        }
        else {
            tempColumns.push({
                Header: function (_a) {
                    var column = _a.column;
                    return (_jsx(ColumnHeaderWrapper, { viewOnly: viewOnly, column: column, children: "Assignee" }));
                },
                accessor: 'user',
                width: NEED_COVERAGE_TEMPLATE_TABLE_FIRST_COLUMN_WIDTH,
                sticky: 'left',
                sortType: function (rowA, rowB, columnId) {
                    var _a, _b;
                    var userAId = rowA.values[columnId].userId;
                    var userBId = rowB.values[columnId].userId;
                    var rowAName = ((_a = userMap === null || userMap === void 0 ? void 0 : userMap[userAId]) === null || _a === void 0 ? void 0 : _a.name) || '';
                    var rowBName = ((_b = userMap === null || userMap === void 0 ? void 0 : userMap[userBId]) === null || _b === void 0 ? void 0 : _b.name) || '';
                    if (rowAName > rowBName)
                        return -1;
                    if (rowBName > rowAName)
                        return 1;
                    return 0;
                },
                Cell: function (_a) {
                    var value = _a.value;
                    return (_jsxs("div", { className: "MainColumnCellWrapper", children: [!viewOnly && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this record?", onConfirm: function () { return deleteRecord(value.recordId); }, placement: "bottomRight", children: _jsx(ZiraTooltip, { title: "Delete", children: _jsx("div", { className: "RecordDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-gray-60", onClick: function (e) { return e.stopPropagation(); }, children: _jsx(DeleteOutlinedIcon, {}) }) }) })), _jsx(UserSelectorCell, { userList: allUser !== null && allUser !== void 0 ? allUser : [], userMap: userMap, userRid: value.userId, selectedUsers: selectedUserIds, updateRecordSelectedUser: function (newValue) {
                                    return updateRecordSelectedUser(value.recordId, newValue);
                                } })] }));
                },
            });
        }
        tempColumns.push({
            Header: function (_a) {
                var column = _a.column;
                return (_jsx(ColumnHeaderWrapper, { viewOnly: viewOnly, column: column, children: "Interval" }));
            },
            sortType: function (rowA, rowB, columnId) {
                var _a, _b;
                var rowAInterval = ((_a = rowA.values[columnId]) === null || _a === void 0 ? void 0 : _a.interval) || 0;
                var rowBInterval = ((_b = rowB.values[columnId]) === null || _b === void 0 ? void 0 : _b.interval) || 0;
                return rowBInterval - rowAInterval;
            },
            accessor: 'interval',
            width: 130,
            sticky: 'left',
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx(TemplateIntervalSelectorCell, { interval: value.interval, updateRecordIntervalNumber: function (newValue) {
                        updateRecordIntervalNumber(value.recordId, newValue);
                    } }));
            },
        });
        var _loop_2 = function (weekIndex) {
            var maxIntervalInTable = (_a = max(tableData.map(function (record) { return record.interval; }))) !== null && _a !== void 0 ? _a : 1;
            if (weekIndex >= maxIntervalInTable) {
                return "break";
            }
            var _loop_3 = function (dayOfWeek) {
                var initialWeekOneStartDate = weekOneStartDate
                    ? moment(weekOneStartDate)
                    : undefined;
                var initialWeekOneStartDateClone = initialWeekOneStartDate === null || initialWeekOneStartDate === void 0 ? void 0 : initialWeekOneStartDate.clone();
                // find nearest date which has same day of week with initialWeekOneStartDate
                var weeksCountBetweenWeekOnAndCurrentWeek = initialWeekOneStartDateClone
                    ? calculateDiffBetweenMomentConsideringDaylightSaving(moment(timeRanges[0]), initialWeekOneStartDateClone, timeZone, isCorrectCalculation) % maxIntervalInTable
                    : 0;
                var actualStartDate = moment(timeRanges[0]);
                var daysFromInitialDate = dayOfWeek - startDayOfWeekNumber + weekIndex * 7;
                var curDate = actualStartDate.add(daysFromInitialDate, 'days');
                var dayOfWeekStr = upperCase(curDate.format('dddd'));
                var header = function (_a) {
                    var column = _a.column;
                    return (_jsx(ZiraTooltip, { title: initialWeekOneStartDate ? (_jsxs("div", { children: [curDate.format('MMM D, YYYY'), " "] })) : undefined, children: _jsxs("div", { className: "TableColumnTitle relative ml-1 pt-2 space-x-1", children: [_jsxs("span", { children: [capitalize(dayOfWeekStr.substring(0, 3)), weekIndex > 0 && maxIntervalInTable > 2 && (_jsx("div", { className: "pl-1 text-xs text-gray-300", children: weekIndex + 1 })), initialWeekOneStartDate && initialWeekOneStartDateClone && (_jsxs("div", { className: "absolute right-[2px] top-0 text-xs text-gray-300", children: [curDate.format('M-D'), ' '] }))] }), column && _jsx(ColumnSortArrow, { column: column })] }) }));
                };
                if (isNeedTemplate) {
                    tempColumns.push({
                        Header: header,
                        accessor: "".concat(dayOfWeekStr).concat(weekIndex),
                        width: 60,
                        Cell: function (_a) {
                            var _b, _c, _d, _e;
                            var value = _a.value;
                            if (!value)
                                return _jsx(_Fragment, {});
                            var isDisabledWeek = weekIndex >= value.interval;
                            var actualDataWeekOffset = weekIndex + weeksCountBetweenWeekOnAndCurrentWeek;
                            var actualWeekIndex = actualDataWeekOffset % value.interval;
                            var numberValue = !isDisabledWeek
                                ? value.number
                                : ((_e = (_d = (_c = (_b = tableData
                                    .find(function (record) { return record.id === value.recordId; })) === null || _b === void 0 ? void 0 : _b.data[actualWeekIndex]) === null || _c === void 0 ? void 0 : _c.find(function (day) { return day.date === dayOfWeekStr; })) === null || _d === void 0 ? void 0 : _d.need) !== null && _e !== void 0 ? _e : 0);
                            return (_jsx(ShiftNumberInputCell, { value: numberValue, disabled: isDisabledWeek, updateRecordShiftNumber: function (newValue) {
                                    return updateRecordShiftNumber(value.recordId, newValue, value.dayOfWeek, actualWeekIndex);
                                } }));
                        },
                    });
                }
                else {
                    tempColumns.push({
                        Header: header,
                        accessor: "".concat(dayOfWeekStr).concat(weekIndex),
                        width: 150,
                        disableSortBy: true,
                        Cell: function (_a) {
                            var _b, _c, _d, _e;
                            var value = _a.value;
                            if (!value)
                                return _jsx(_Fragment, {});
                            var isDisabledWeek = weekIndex >= value.interval;
                            var actualWeekIndex = (weekIndex + weeksCountBetweenWeekOnAndCurrentWeek) % value.interval;
                            var templateValue = !isDisabledWeek
                                ? value.shiftTemplates
                                : ((_e = (_d = (_c = (_b = tableData
                                    .find(function (record) { return record.id === value.recordId; })) === null || _b === void 0 ? void 0 : _b.data[actualWeekIndex]) === null || _c === void 0 ? void 0 : _c.find(function (day) { return day.date === dayOfWeekStr; })) === null || _d === void 0 ? void 0 : _d.templates) !== null && _e !== void 0 ? _e : []);
                            return (_jsx(ShiftTemplateSelectorCell, { shiftTemplates: templateValue, templateList: shiftTemplateList, updateDayOfWeekShiftTemplate: function (shiftTemplates) {
                                    updateRecordDayOfWeekShiftTemplates(value.recordId, shiftTemplates, value.dayOfWeek, actualWeekIndex);
                                }, overrideRecordDayOfWeekShiftTemplateTime: function (shiftTemplate, time, overrideAllTemplates) {
                                    overrideRecordDayOfWeekShiftTemplateTime(value.recordId, shiftTemplate, time, value.dayOfWeek, actualWeekIndex, overrideAllTemplates);
                                }, isNeedsTableType: false, disabled: isDisabledWeek, selectedShiftTemplateIds: [] }));
                        },
                    });
                }
            };
            for (var dayOfWeek = startDayOfWeekNumber; dayOfWeek < startDayOfWeekNumber + 7; dayOfWeek++) {
                _loop_3(dayOfWeek);
            }
        };
        //Day of week column
        for (var weekIndex = 0; weekIndex <= MAX_WEEK_INDEX_IN_NEEDS_COVERAGE; weekIndex++) {
            var state_1 = _loop_2(weekIndex);
            if (state_1 === "break")
                break;
        }
        return tempColumns;
    }, [
        isNeedTemplate,
        viewOnly,
        shiftTemplateList,
        selectedShiftTemplateIds,
        deleteRecord,
        updateRecordShiftTemplate,
        userMap,
        allUser,
        selectedUserIds,
        updateRecordSelectedUser,
        updateRecordIntervalNumber,
        tableData,
        startDayOfWeekNumber,
        weekOneStartDate,
        timeRanges,
        timeZone,
        updateRecordShiftNumber,
        updateRecordDayOfWeekShiftTemplates,
        overrideRecordDayOfWeekShiftTemplateTime,
        isCorrectCalculation,
    ]);
    if (tableData.length === 0) {
        if (viewOnly) {
            return _jsx(EmptyTableContent, { children: "No data" });
        }
        return (_jsx(EmptyTableContent, { children: _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "CreateNewBtn", onClick: addNewRecord, children: "Add ".concat(isNeedTemplate ? 'Need' : 'Coverage') }) }));
    }
    return (_jsxs("div", { className: "mt-[30px]", children: [containsEmptyFirstColumnSelection && (_jsx(Alert, { message: "".concat(isNeedTemplate ? 'Shift template' : 'Assignee', " is required, please select one or delete that record."), banner: true, type: "warning", className: "mb-2 w-[500px] text-sm font-medium" })), _jsx(NeedCoverageTableBuilder, { columns: parseToColumnData, data: parseToRecordsData }), !viewOnly && (_jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "CreateNewBtn mt-[20px]", onClick: addNewRecord, children: "Add ".concat(isNeedTemplate ? 'Need' : 'Coverage') }))] }));
};
export default React.memo(NeedsCoverageTemplateDetailSettingTable);
