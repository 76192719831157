import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InputNumber } from 'antd';
import { produce } from 'immer';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import TableBuilder from '../OptimizedTableView/TableBuilder';
import './TimeoffSummaryTable.scss';
var NumberInputCellWrapper = function (props) {
    var record = props.record, isAccrued = props.isAccrued, editHours = props.editHours;
    var defaultHourValue = isAccrued ? record.hoursAccrued : record.hoursUsed;
    var _a = useState(defaultHourValue), inputHour = _a[0], setInputHour = _a[1];
    var inputRef = useRef(null);
    var inputNumberOnBlur = function () {
        if (isAccrued) {
            editHours(record.typeId, inputHour, record.hoursUsed);
        }
        else {
            editHours(record.typeId, record.hoursAccrued, inputHour);
        }
    };
    return (_jsx(InputNumber, { ref: inputRef, bordered: false, value: inputHour, onChange: function (e) {
            if (e) {
                setInputHour(e);
            }
        }, onBlur: inputNumberOnBlur, onPressEnter: function () {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        } }));
};
NumberInputCellWrapper.displayName = 'NumberInputCellWrapper';
var TimeoffSummaryTable = function (props) {
    var timeoffSummaryData = props.timeoffSummaryData, summaryDataOnChange = props.summaryDataOnChange;
    var DisplayOnlyCellWrapper = useCallback(function (props) {
        var displayValue = props.displayValue, isText = props.isText;
        return (_jsx(_Fragment, { children: _jsx("span", { className: isText ? 'DisplayValueTag' : '', children: displayValue }) }));
    }, []);
    var updateHours = useCallback(function (typeId, newHoursAccrued, newHoursUsed) {
        var newTimeoffSummaryData = produce(timeoffSummaryData, function (draft) {
            var targetType = draft.find(function (record) { return record.typeId === typeId; });
            if (targetType) {
                targetType.hoursAccrued = newHoursAccrued;
                targetType.hoursUsed = newHoursUsed;
            }
        });
        summaryDataOnChange(newTimeoffSummaryData);
    }, [summaryDataOnChange, timeoffSummaryData]);
    var parseToRecordsData = useMemo(function () {
        var tempRecords = [];
        timeoffSummaryData.map(function (item) {
            var inputCellDataObj = {
                typeId: item.typeId,
                hoursAccrued: item.hoursAccrued,
                hoursUsed: item.hoursUsed,
            };
            var hoursRemaining = item.hoursAccrued - item.hoursUsed;
            var record = {
                typeName: item.typeName,
                hoursAccrued: inputCellDataObj,
                hoursUsed: inputCellDataObj,
                hoursRemaining: hoursRemaining,
            };
            tempRecords.push(record);
        });
        return tempRecords;
    }, [timeoffSummaryData]);
    var parseToColumnData = useMemo(function () {
        var tempColumns = [];
        var typeHeader = _jsx("div", { className: "TimeoffSummaryTableColumnTitle", children: "Time off Type" });
        tempColumns.push({
            Header: typeHeader,
            accessor: 'typeName',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return DisplayOnlyCellWrapper({ displayValue: value, isText: true });
            },
        });
        var accruedHeader = _jsx("div", { className: "TimeoffSummaryTableColumnTitle", children: "Hours Accrued" });
        tempColumns.push({
            Header: accruedHeader,
            accessor: 'hoursAccrued',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return NumberInputCellWrapper({
                    record: value,
                    isAccrued: true,
                    editHours: updateHours,
                });
            },
        });
        var usedHeader = _jsx("div", { className: "TimeoffSummaryTableColumnTitle", children: "Hours Used" });
        tempColumns.push({
            Header: usedHeader,
            accessor: 'hoursUsed',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return NumberInputCellWrapper({
                    record: value,
                    isAccrued: false,
                    editHours: updateHours,
                });
            },
        });
        var remainingHeader = (_jsx("div", { className: "TimeoffSummaryTableColumnTitle", children: "Hours Remaining" }));
        tempColumns.push({
            Header: remainingHeader,
            accessor: 'hoursRemaining',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return DisplayOnlyCellWrapper({ displayValue: value, isText: false });
            },
        });
        return tempColumns;
    }, [DisplayOnlyCellWrapper, updateHours]);
    var TableSummary = useMemo(function () {
        return (_jsxs("div", { className: "TableSummary", children: ["Count", _jsx("span", { children: timeoffSummaryData === null || timeoffSummaryData === void 0 ? void 0 : timeoffSummaryData.length })] }));
    }, [timeoffSummaryData]);
    return (_jsx(TableBuilder, { columns: parseToColumnData, data: parseToRecordsData, summary: TableSummary, scrollContainerClassName: "ModalBody" }));
};
export default React.memo(TimeoffSummaryTable);
