var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { tableGroupBySettingSelector, tableSelectedViewIdState } from 'src/state';
import { useInitReverseCollectionViews } from 'src/hooks/component';
import { Skeleton } from 'antd';
import TableOptionHeaderFromReverseCollection from './TableOptionHeaderFromReverseCollection';
import ReverseCollectionTable from './ReverseCollectionTable';
import ReverseCollectionBoardView from './ReverseCollectionBoardView';
var ReverseCollectionView = function (props) {
    var referenceRecordId = props.referenceRecordId, schemaId = props.schemaId, standardRelation = props.standardRelation, collectionComponent = props.collectionComponent;
    //!IMPORTANT: Unique reverseRelationId for each reverse collection; there can be multiple reverse collections from same collection due to multiple link to objects
    var reverseRelationId = useMemo(function () { var _a; return (_a = schemaId !== null && schemaId !== void 0 ? schemaId : standardRelation) !== null && _a !== void 0 ? _a : collectionComponent; }, [collectionComponent, schemaId, standardRelation]);
    var groupSettings = useRecoilValue(tableGroupBySettingSelector(reverseRelationId));
    var isBoardView = !!groupSettings;
    var viewId = useRecoilValue(tableSelectedViewIdState(reverseRelationId));
    var _a = useState(true), isLoadingRecords = _a[0], setIsLoadingRecords = _a[1];
    var initialViews = useInitReverseCollectionViews(referenceRecordId, reverseRelationId, schemaId, standardRelation, collectionComponent, isLoadingRecords);
    var initialDisplaySettings = initialViews === null || initialViews === void 0 ? void 0 : initialViews.displaySettings[viewId];
    if (!viewId || !initialViews) {
        return _jsx(Skeleton, { active: true });
    }
    return (_jsxs(_Fragment, { children: [!isLoadingRecords && (_jsx(TableOptionHeaderFromReverseCollection, __assign({}, props, { reverseRelationId: reverseRelationId, viewId: viewId }))), (isLoadingRecords ? initialDisplaySettings : true) && (_jsx(_Fragment, { children: isBoardView ? (_jsx(ReverseCollectionBoardView, __assign({}, props, { reverseRelationId: reverseRelationId, initialDisplaySettings: initialDisplaySettings, isLoading: isLoadingRecords, setIsLoading: setIsLoadingRecords }))) : (_jsx(ReverseCollectionTable, __assign({}, props, { reverseRelationId: reverseRelationId, initialDisplaySettings: initialDisplaySettings, isLoading: isLoadingRecords, setIsLoading: setIsLoadingRecords }))) }))] }));
};
export default React.memo(ReverseCollectionView);
