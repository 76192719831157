var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'src/state';
import { v4 as uuidv4 } from 'uuid';
import DepartmentItem from './DepartmentItem';
var DepartmentList = function (_a) {
    var editingDepartmentsState = _a.editingDepartmentsState;
    var departments = editingDepartmentsState[0], setDepartments = editingDepartmentsState[1];
    var currentUser = useRecoilValue(currentUserState);
    return (_jsxs("div", { className: "DepartmentList", children: [departments.map(function (department) { return (_jsx(DepartmentItem, { department: department, onDelete: function () {
                    setDepartments(function (prev) { return prev.filter(function (item) { return item.id !== department.id; }); });
                }, onUpdate: function (updatedDepartment) {
                    setDepartments(function (prev) {
                        return prev.map(function (item) {
                            if (item.id === department.id) {
                                return updatedDepartment;
                            }
                            return item;
                        });
                    });
                } }, department.id)); }), _jsx(Button, { className: "AddDepartmentButton", onClick: function () {
                    setDepartments(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                        {
                            id: uuidv4(),
                            title: '',
                            users: [
                                {
                                    id: currentUser === null || currentUser === void 0 ? void 0 : currentUser.recordId,
                                    name: currentUser === null || currentUser === void 0 ? void 0 : currentUser.fullName,
                                    avatarUrl: currentUser === null || currentUser === void 0 ? void 0 : currentUser.avatarUrl,
                                },
                            ],
                        },
                    ], false); });
                }, children: "Add Department" })] }));
};
export default DepartmentList;
