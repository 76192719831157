import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './NavigationSideBar.scss';
import { Layout, Popover, Divider, Modal, Input } from 'antd';
import _, { uniqBy } from 'lodash';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { accountDetailState, accountIdState, currentGeneralPermissions } from 'src/state';
import { isInternalUserSelector } from 'src/state/selectors/withAccountDetailState';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useWindowSize } from 'src/hooks/component/useWindowSize';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
import { useAppSession } from 'src/hooks/component';
import { isAutomationPageModalVisibleState } from 'src/state/atoms/automationModalState';
import { OrganizationContext } from 'src/App';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import ZiraTooltip from '../../ui/ZiraTooltip';
import AccountState from '../../hooks/state/Account';
import AutomationsPage from '../Settings/Automations/AutomationsPage';
import FormTemplatesPage from '../Settings/Forms/FormTemplatesPage/FormTemplatesPage';
import AccountSetUpModal from '../Landing/AccountSetUpModal';
import TasksViewContainer from '../Settings/Tasks/TasksViewContainer';
import { CREATE_NEW_ACCOUNT_BUTTON } from '../FeatureGatingKeyConstant';
import NaviTree from './NaviTree/NaviTree';
import LogoDropdown from './LogoDropdown';
import NavigationStaticMenu from './NavigationStaticMenu';
var Sider = Layout.Sider;
var NavigationSideBar = function (props) {
    var collapsed = props.collapsed, onCollapse = props.onCollapse, onOpenRecordDetail = props.onOpenRecordDetail, onlyShowAccountSwitcher = props.onlyShowAccountSwitcher;
    var organizations = AccountState.useContainer().organizations;
    var navigate = useNavigate();
    var windowWidth = useWindowSize().windowWidth;
    var _a = useState(false), showAccountSetUpModal = _a[0], setShowAccountSetUpModal = _a[1];
    var _b = useState(false), popoverOpen = _b[0], setPopoverOpen = _b[1];
    var _c = useRecoilState(isAutomationPageModalVisibleState), isAutomationModalVisible = _c[0], setAutomationModalVisible = _c[1];
    var _d = useState(false), isFormsModalVisible = _d[0], setFormsModalVisible = _d[1];
    var _e = useRecoilState(accountIdState), accountId = _e[0], setAccountId = _e[1];
    var resetAccountDetail = useResetRecoilState(accountDetailState);
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var currentAccountData = useMemo(function () {
        if (!organizations || organizations.length === 0)
            return;
        return _.find(organizations, function (org) { return org.accountId === accountId; });
    }, [accountId, organizations]);
    var clearAppCache = useAppSession().clearAppCache;
    var switchAccount = useCallback(function (accountId) {
        clearAppCache(true);
        resetAccountDetail();
        setAccountId(accountId);
        navigate('/home', { replace: true });
        navigate(0);
    }, [clearAppCache, navigate, resetAccountDetail, setAccountId]);
    var isTeamBridgeMember = useRecoilValue(isInternalUserSelector);
    var isCreateNewButtonEnabled = useRecoilValue(featureEnableStatusState(CREATE_NEW_ACCOUNT_BUTTON));
    var _f = useState([]), accountList = _f[0], setAccountList = _f[1];
    useEffect(function () {
        setAccountList(organizations);
    }, [organizations]);
    var searchAccounts = useCallback(function (searchInput) {
        var isInputEmpty = !searchInput.replace(/\s/g, '').length;
        if (!isInputEmpty) {
            var filteredData = organizations.filter(function (item) {
                return item.accountName.toLowerCase().includes(searchInput.toLowerCase());
            });
            setAccountList(filteredData);
        }
        else {
            setAccountList(organizations);
        }
    }, [organizations]);
    var renderOrgs = useCallback(function () {
        return (_jsxs("div", { className: "orgsPopover", children: [_jsx("div", { className: "myAccountsTitle", children: "My Accounts" }), isTeamBridgeMember && (_jsx("div", { className: "mb-3 px-[15px]", children: _jsx(Input, { prefix: _jsx(SearchOutlinedIcon, {}), placeholder: "Search...", onChange: function (e) { return searchAccounts(e.target.value); } }) })), _jsx(Divider, {}), _jsx("div", { className: "accountsWrapper", children: accountList.length === 0 ? (_jsx("div", { className: "flex h-[61px] items-center justify-center text-base font-medium text-gray-300", children: "No Account Found" })) : (uniqBy(accountList, 'accountId')
                        .sort(function (a, b) {
                        return a.accountName > b.accountName ? 1 : -1;
                    })
                        .map(function (org) {
                        var matches = org.accountName.match(/\b(\w)/g);
                        var acronym = matches === null || matches === void 0 ? void 0 : matches.join('').substring(0, 2);
                        var isSelectedAccount = accountId == org.accountId;
                        return (_jsx(ZiraTooltip, { title: org.accountName, placement: "right", children: _jsxs("div", { className: 'accountOption ' +
                                    (isSelectedAccount ? 'selected' : ''), onClick: function () {
                                    setPopoverOpen(false);
                                    if (!isSelectedAccount)
                                        switchAccount(org.accountId);
                                }, children: [(org === null || org === void 0 ? void 0 : org.webLogo) ? (_jsx("img", { className: "accountIcon", src: org.webLogo })) : (_jsx("div", { className: "accountIcon", children: acronym })), _jsx("div", { className: "accountName", children: org.accountName })] }, org.accountId) }, org.accountId));
                    })) }), _jsx(Divider, {}), (isTeamBridgeMember || isCreateNewButtonEnabled) && (_jsx("div", { role: "button", className: "createNewAccount", onClick: function () {
                        setPopoverOpen(false);
                        setShowAccountSetUpModal(true);
                    }, children: "Create New Account" }))] }));
    }, [
        accountId,
        accountList,
        isTeamBridgeMember,
        isCreateNewButtonEnabled,
        searchAccounts,
        switchAccount,
    ]);
    var orgMode = useContext(OrganizationContext);
    var accountDisplaySection = useMemo(function () { return (_jsxs("div", { className: "AccountDataContainer", children: [_jsxs("div", { className: "AccountImgName", children: [(currentAccountData === null || currentAccountData === void 0 ? void 0 : currentAccountData.webLogo) && (_jsx("img", { className: "accountIcon", src: currentAccountData.webLogo })), _jsx("div", { className: "AccountName", children: currentAccountData === null || currentAccountData === void 0 ? void 0 : currentAccountData.accountName })] }), !orgMode &&
                (popoverOpen ? (_jsx(CaretUpOutlined, { className: "DownOutIcon" })) : (_jsx(CaretDownOutlined, { className: "DownOutIcon" })))] })); }, [currentAccountData === null || currentAccountData === void 0 ? void 0 : currentAccountData.accountName, currentAccountData === null || currentAccountData === void 0 ? void 0 : currentAccountData.webLogo, orgMode, popoverOpen]);
    return (_jsxs(_Fragment, { children: [_jsxs(Sider, { collapsible: true, collapsed: collapsed, onCollapse: onCollapse, trigger: null, theme: "light", breakpoint: "sm", collapsedWidth: 0, width: (windowWidth || 577) > 576 ? 260 : '100%', className: "Sider", children: [!orgMode ? (_jsx(Popover, { className: "naviLogoWrapper", placement: "bottom", content: renderOrgs, trigger: "click", open: popoverOpen, onOpenChange: function () { return setPopoverOpen(!popoverOpen); }, overlayClassName: "NaviLogoPopoverContent", children: accountDisplaySection })) : (accountDisplaySection), _jsx(NavigationStaticMenu, { onSiderCollapse: onCollapse }), !onlyShowAccountSwitcher && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB) === true && (_jsx(NaviTree, { onSiderCollapse: onCollapse })), !onlyShowAccountSwitcher && (_jsx(LogoDropdown, { leftNavCollapsed: collapsed, onOpenFormModal: function () { return setFormsModalVisible(true); }, onOpenAutomationModal: function () { return setAutomationModalVisible(true); }, onOpenRecordDetail: onOpenRecordDetail }))] }), _jsx(AccountSetUpModal, { isModalVisible: showAccountSetUpModal, modalOnClose: function () { return setShowAccountSetUpModal(false); } }), _jsx(Modal, { className: "newBlockModal Automations", open: isAutomationModalVisible, closable: true, width: "100%", centered: true, footer: null, onCancel: function () { return setAutomationModalVisible(false); }, destroyOnClose: true, children: _jsx(AutomationsPage, {}) }), _jsx(Modal, { className: "newBlockModal", open: isFormsModalVisible, closable: true, width: "85vw", centered: true, footer: null, onCancel: function () { return setFormsModalVisible(false); }, destroyOnClose: true, children: _jsx(FormTemplatesPage, {}) }), (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.TASKS) && _jsx(TasksViewContainer, {})] }));
};
export default React.memo(NavigationSideBar);
