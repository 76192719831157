import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { RECIPE_MODAL_FOOTER_HEIGHT } from './constant';
var RecipeModalFooter = function (_a) {
    var recipeStep = _a.recipeStep, onGoBack = _a.onGoBack, onGoNext = _a.onGoNext;
    return (_jsxs("div", { className: "mt-auto flex items-center border-t-[2px] border-gray-50 font-medium", style: { height: "".concat(RECIPE_MODAL_FOOTER_HEIGHT, "px") }, children: [recipeStep !== 4 && (_jsx("div", { className: "cursor-pointer rounded p-4 text-black ", onClick: onGoBack, children: "Cancel" })), _jsx("div", { className: "ml-auto mr-[16px] flex h-[32px] cursor-pointer items-center rounded-lg bg-black px-[16px] text-white", onClick: onGoNext, children: recipeStep === 0
                    ? 'Use this recipe'
                    : recipeStep === 3
                        ? 'Start Cooking 🍳'
                        : recipeStep === 4
                            ? 'Enjoy your meal!'
                            : 'Next' })] }));
};
export default React.memo(RecipeModalFooter);
