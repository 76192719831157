import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from 'antd';
import React, { useMemo } from 'react';
var WarningModalForShiftPayrollStatusSelector = function (_a) {
    var onClose = _a.onClose, onConfirm = _a.onConfirm;
    var modalFooter = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: onClose, children: "No" }), _jsx(Button, { className: "ZiraModalSaveBtn", onClick: onConfirm, children: "Yes" })] }));
    }, [onClose, onConfirm]);
    return (_jsx(Modal, { open: true, centered: true, footer: modalFooter, closable: false, className: "TimeOffApprovalModalForUnassignShift", width: 500, zIndex: 1101, children: _jsx("div", { className: "mt-5", children: "Are you sure you want to update the payroll status of this shift?" }) }));
};
export default React.memo(WarningModalForShiftPayrollStatusSelector);
