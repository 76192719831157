var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd';
import TemplateCard from './NewShiftTemplateCard';
var getStyle = function (provided, style, isDragging) {
    var _a;
    var combined = __assign(__assign({}, style), provided.draggableProps.style);
    var marginBottom = 8;
    var withSpacing = __assign(__assign({}, combined), { height: isDragging ? combined.height : ((_a = combined.height) !== null && _a !== void 0 ? _a : 95 - marginBottom), marginBottom: marginBottom });
    return withSpacing;
};
var TemplateCardWrapper = function (props) {
    var items = props.data, index = props.index, style = props.style;
    var template = items[index];
    return (_jsx(Draggable, { draggableId: template.id, index: index, children: function (provided, snapshot) {
            var isDragging = snapshot.isDragging;
            var containerStyle = getStyle(provided, style, isDragging);
            return (_jsx(TemplateCard, { style: containerStyle, template: template, onDeleteTemplate: template.onDeleteTemplate, openExistingTemplate: template.openExistingTemplate, draggableProvided: provided }));
        } }, template.id));
};
var Card = React.memo(TemplateCardWrapper, areEqual);
var ShiftTemplateList = function (props) {
    var templateList = props.templateList, onTemplateCardDragEnd = props.onTemplateCardDragEnd, openExistingTemplate = props.openExistingTemplate, onDeleteTemplate = props.onDeleteTemplate, listHeight = props.listHeight;
    var listItemData = useMemo(function () {
        var list = templateList.map(function (template) { return (__assign(__assign({}, template), { openExistingTemplate: openExistingTemplate, onDeleteTemplate: onDeleteTemplate, onTemplateCardDragEnd: onTemplateCardDragEnd })); });
        return list;
    }, [onDeleteTemplate, onTemplateCardDragEnd, openExistingTemplate, templateList]);
    return (_jsx(DragDropContext, { onDragEnd: onTemplateCardDragEnd, children: _jsx(Droppable, { droppableId: "droppable", mode: "virtual", renderClone: function (provided, snapshot, rubric) {
                var _a;
                return (_jsx(TemplateCard, { template: templateList[rubric.source.index], style: (_a = provided.draggableProps.style) !== null && _a !== void 0 ? _a : { margin: 0 }, onDeleteTemplate: onDeleteTemplate, openExistingTemplate: openExistingTemplate, draggableProvided: provided }));
            }, children: function (provided) { return (_jsx(List, { className: "List", height: listHeight, itemCount: listItemData.length, itemSize: 95, width: 224, outerRef: provided.innerRef, itemData: listItemData, children: Card })); } }) }));
};
export default React.memo(ShiftTemplateList);
