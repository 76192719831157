var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var NeedsCoverageIconSvg = function () { return (_jsxs("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.33268 3.20841V4.95841H8.74935V3.20841H2.33268ZM1.45768 2.04175C1.2966 2.04175 1.16602 2.17233 1.16602 2.33341V5.83341C1.16602 5.9945 1.2966 6.12508 1.45768 6.12508H9.62435C9.78543 6.12508 9.91601 5.9945 9.91601 5.83341V2.33341C9.91601 2.17233 9.78543 2.04175 9.62435 2.04175H1.45768Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.33268 9.04167V10.7917H8.74935V9.04167H2.33268ZM1.45768 7.875C1.2966 7.875 1.16602 8.00558 1.16602 8.16667V11.6667C1.16602 11.8277 1.2966 11.9583 1.45768 11.9583H9.62435C9.78543 11.9583 9.91601 11.8277 9.91601 11.6667V8.16667C9.91601 8.00558 9.78543 7.875 9.62435 7.875H1.45768Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.2526 6.12516V7.87516H11.6669V6.12516H5.2526ZM4.3776 4.9585C4.21652 4.9585 4.08594 5.08908 4.08594 5.25016V8.75016C4.08594 8.91125 4.21652 9.04183 4.3776 9.04183H12.5419C12.703 9.04183 12.8336 8.91125 12.8336 8.75016V5.25016C12.8336 5.08908 12.703 4.9585 12.5419 4.9585H4.3776Z" })] })); };
export var NeedsCoverageIcon = function (props) { return (_jsx(Icon, __assign({ component: NeedsCoverageIconSvg }, props))); };
