var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Select } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined, } from '@ant-design/icons';
import { useBlockLayout, useFilters, useGlobalFilter, usePagination, useResizeColumns, useTable, } from 'react-table';
import { onTableDataKeyDown } from 'src/shared';
import './PayRateSettingTableBuilder.scss';
import PayItemTableSearch from '../../PayItemTableSearch';
var Option = Select.Option;
var globalFilterFunction = function (rows, columnIds, filterValue) {
    if (!filterValue)
        return rows;
    return rows.filter(function (row) {
        var _a, _b, _c;
        for (var _i = 0, columnIds_1 = columnIds; _i < columnIds_1.length; _i++) {
            var columnId = columnIds_1[_i];
            var cell = (_a = row.values) === null || _a === void 0 ? void 0 : _a[columnId];
            var cellNameValue = (_b = cell === null || cell === void 0 ? void 0 : cell.name) !== null && _b !== void 0 ? _b : cell === null || cell === void 0 ? void 0 : cell.displayName;
            var cellRateValue = (_c = cell === null || cell === void 0 ? void 0 : cell.rate) === null || _c === void 0 ? void 0 : _c.toString();
            if ((cellNameValue &&
                cellNameValue
                    .trim()
                    .toLowerCase()
                    .includes(filterValue.trim().toLowerCase())) ||
                (cellRateValue === null || cellRateValue === void 0 ? void 0 : cellRateValue.includes(filterValue.trim()))) {
                return true;
            }
        }
        return false;
    });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var PayRateSettingTableBuilder = function (props) {
    var defaultColumns = props.columns, defaultData = props.data, footer = props.footer;
    var _a = useState(10), initialPageSize = _a[0], setInitialPageSize = _a[1];
    var _b = useState(0), currentPageIndex = _b[0], setCurrentPageIndex = _b[1];
    var columns = useMemo(function () { return defaultColumns; }, [defaultColumns]);
    var data = useMemo(function () { return defaultData; }, [defaultData]);
    var _c = useTable({
        columns: columns,
        data: data,
        globalFilter: globalFilterFunction,
        autoResetGlobalFilter: false,
        initialState: {
            pageIndex: currentPageIndex,
            pageSize: initialPageSize,
        },
    }, useBlockLayout, useResizeColumns, useFilters, useGlobalFilter, usePagination), getTableProps = _c.getTableProps, getTableBodyProps = _c.getTableBodyProps, headerGroups = _c.headerGroups, page = _c.page, prepareRow = _c.prepareRow, canPreviousPage = _c.canPreviousPage, canNextPage = _c.canNextPage, pageOptions = _c.pageOptions, pageCount = _c.pageCount, gotoPage = _c.gotoPage, nextPage = _c.nextPage, previousPage = _c.previousPage, setPageSize = _c.setPageSize, _d = _c.state, pageIndex = _d.pageIndex, pageSize = _d.pageSize, globalFilter = _d.globalFilter, setGlobalFilter = _c.setGlobalFilter;
    useEffect(function () {
        setInitialPageSize(pageSize);
        setCurrentPageIndex(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);
    useEffect(function () {
        // when delete last record form page or add very first page,set page index to the correct one
        if (currentPageIndex + 1 > pageOptions.length) {
            setCurrentPageIndex(pageOptions.length);
        }
    }, [currentPageIndex, pageOptions]);
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("tr", { children: _jsx("th", { colSpan: columns.length, style: {
                            textAlign: 'left',
                        }, children: _jsx(PayItemTableSearch, { globalFilter: globalFilter, setGlobalFilter: setGlobalFilter }) }) }), _jsxs("table", __assign({}, getTableProps(), { className: "table", children: [_jsx("thead", { className: "thead", children: headerGroups.map(function (headerGroup) { return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps(), { key: headerGroup.id, className: "tr" }), headerGroup.headers.map(function (column, index) {
                                return (_createElement("th", __assign({}, column.getHeaderProps(), { className: "th ".concat(column.isResizing ? 'isResizing' : ''), key: index }),
                                    _jsx("span", { children: column.render('Header') }),
                                    index !== headerGroup.headers.length - 1 && (_jsx("div", __assign({}, column.getResizerProps(), { className: "resizer ".concat(column.isResizing ? 'isResizing' : '') })))));
                            }))); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { children: page.map(function (row) {
                                prepareRow(row);
                                return (_createElement("tr", __assign({}, row.getRowProps(), { key: row.id, className: "tr" }), row.cells.map(function (cell, columnIndex) {
                                    return (_createElement("td", __assign({}, cell.getCellProps(), { key: columnIndex, className: "td", onKeyDown: function (e) {
                                            return onTableDataKeyDown(e, columnIndex);
                                        } }), cell.render('Cell')));
                                })));
                            }) })), footer && (_jsx("tfoot", { className: "tfoot", children: _jsx("tr", { children: _jsxs("div", { className: "flex h-10 items-center", children: [_jsx(Button, { icon: _jsx(DoubleLeftOutlined, {}), onClick: function () {
                                                gotoPage(0);
                                                setCurrentPageIndex(0);
                                            }, disabled: !canPreviousPage }), _jsx(Button, { icon: _jsx(LeftOutlined, {}), onClick: function () {
                                                previousPage();
                                                setCurrentPageIndex(function (prev) { return prev - 1; });
                                            }, disabled: !canPreviousPage }), _jsx(Button, { icon: _jsx(RightOutlined, {}), onClick: function () {
                                                nextPage();
                                                setCurrentPageIndex(function (prev) { return prev + 1; });
                                            }, disabled: !canNextPage }), _jsx(Button, { icon: _jsx(DoubleRightOutlined, {}), onClick: function () {
                                                gotoPage(pageCount - 1);
                                                setCurrentPageIndex(pageCount - 1);
                                            }, disabled: !canNextPage }), _jsxs("span", { children: ["Page", ' ', _jsxs("strong", { children: [pageOptions.length === 0 ? 0 : pageIndex + 1, " of", ' ', pageOptions.length] })] }), _jsx(Select, { className: "ml-2", value: pageSize, onChange: setPageSize, children: [10, 25, 50, 100].map(function (pageSize) { return (_jsxs(Option, { value: pageSize, children: ["Show ", pageSize] }, pageSize)); }) })] }) }) }))] }))] }) }));
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var PayRateSettingTableBuilderWrapper = function (props) {
    return (_jsx("div", { className: "ReactTableWrapper", children: _jsx(PayRateSettingTableBuilder, __assign({}, props)) }));
};
export default React.memo(PayRateSettingTableBuilderWrapper);
