import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input, Switch } from 'antd';
var ViewOnlyTaskSettingSection = function (_a) {
    var allowCompletion = _a.allowCompletion, buttonLabel = _a.buttonLabel, updateViewOnly = _a.updateViewOnly;
    return (_jsxs("div", { children: [_jsx("div", { className: "flex flex-col gap-5 mt-4", children: _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel", children: "Button Label" }), _jsx(Input, { className: "TaskDetailModalInput", value: buttonLabel, onChange: function (e) {
                                updateViewOnly({ buttonLabel: e.target.value, allowCompletion: allowCompletion });
                            } })] }) }), _jsxs("div", { className: "w-full flex items-center justify-between mt-5", children: [_jsxs("div", { children: [_jsx("div", { className: "text-base font-semibold pb-2", children: "Allow Assigned User to Complete" }), _jsx("div", { className: "text-sm font-medium opacity-50", children: "Users will be able to see this task. Activate this setting to allow the assigned user to complete this task by viewing and confirming. Otherwise, only an admin or automation will be able to complete this task." })] }), _jsx(Switch, { className: "m-0 self-start", checked: allowCompletion, onChange: function (allowCompletion) { return updateViewOnly({ allowCompletion: allowCompletion, buttonLabel: buttonLabel }); } })] })] }));
};
export default React.memo(ViewOnlyTaskSettingSection);
