var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { produce } from 'immer';
import { usePageApi } from 'src/hooks/api';
import { useTimeZone } from 'src/hooks/component';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { convertToPercentNumber, generateTimeIntervals, getBackgroundColorByPercentageNumber, getPercentageNumberColor, initDefaultExpectedData, } from 'src/utils/Headcount';
import { Button, Col, Row } from 'antd';
import './HeadcountBlock.scss';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import CalculateMetricBar from '../MetricBar/CalculateMetricBar';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import { HeadcountRowType } from './Interface';
import ExpectedCountInput from './ExpectedCountInput';
var HeadcountBlock = function (_a) {
    var blockId = _a.blockId, moveBlockUpDown = _a.moveBlockUpDown;
    var _b = useTimeZone(), timeZone = _b.timeZone, timeFormatFn = _b.timeFormatFn;
    var useGetUpdateHeadcountBlock = usePageApi().useGetUpdateHeadcountBlock;
    var _c = useState(moment().tz(timeZone).startOf('day').toISOString()), startTime = _c[0], setStartTime = _c[1];
    var _d = useState(), headcountData = _d[0], setHeadcountData = _d[1];
    var _e = useState(moment().toISOString()), lastCalculatedAt = _e[0], setLastCalculatedAt = _e[1];
    var _f = useState(), expectedData = _f[0], setExpectedData = _f[1];
    var _g = useGetUpdateHeadcountBlock(blockId, startTime, moment(startTime).add(11.5, 'hours').toISOString(), expectedData ? { expected: expectedData } : undefined), fetchedHeadcountData = _g.data, refetch = _g.refetch;
    var intervals = useMemo(function () { return generateTimeIntervals(startTime); }, [startTime]);
    useEffect(function () {
        var _a;
        if (fetchedHeadcountData) {
            setHeadcountData(fetchedHeadcountData);
            setLastCalculatedAt(moment().toISOString());
            var defaultExpectedData = initDefaultExpectedData(intervals, (_a = fetchedHeadcountData.setting) === null || _a === void 0 ? void 0 : _a.expected);
            setExpectedData(defaultExpectedData);
        }
    }, [fetchedHeadcountData, intervals]);
    var onTimeRangeChange = useCallback(function (offset) {
        var newStartTime = moment(startTime).clone().add(offset, 'hours').toISOString();
        setStartTime(newStartTime);
    }, [startTime]);
    var onExpectedNumberChange = useCallback(function (index, newValue) {
        var newExpectedData = produce(expectedData, function (draft) {
            if (draft)
                draft[index].count = newValue;
        });
        if (!startTime || !newExpectedData)
            return;
        setExpectedData(newExpectedData);
    }, [expectedData, startTime]);
    var renderDataRows = useCallback(function (type, isPercentage) {
        if (!expectedData || !headcountData)
            return;
        return (_jsx("div", { className: "DataRow flex justify-between", children: _jsxs(Row, { className: "w-full items-center", children: [_jsx(Col, { flex: "85px", style: { display: 'flex', justifyContent: 'center' }, children: isPercentage ? '%' : upperCaseStringToStartCase(type) }), intervals.map(function (time, index) {
                        var expected = expectedData[index].count;
                        var displayValue = 0;
                        if (type === HeadcountRowType.ACTUAL) {
                            displayValue = isPercentage
                                ? headcountData.actual[index].count / expected
                                : headcountData.actual[index].count;
                        }
                        else if (type === HeadcountRowType.SCHEDULED) {
                            displayValue = isPercentage
                                ? headcountData.scheduled[index].count / expected
                                : headcountData.scheduled[index].count;
                        }
                        return (_jsx(Col, { flex: "50px", className: "bg-white text-center", children: type === HeadcountRowType.EXPECTED ? (_jsx(ExpectedCountInput, { index: index, expectedCount: expected, onExpectedNumberChange: onExpectedNumberChange })) : (_jsx("div", { className: "NumberContainer", style: {
                                    backgroundColor: isPercentage
                                        ? getBackgroundColorByPercentageNumber(convertToPercentNumber(displayValue))
                                        : '',
                                    color: isPercentage
                                        ? getPercentageNumberColor(convertToPercentNumber(displayValue))
                                        : 'black',
                                }, children: isPercentage
                                    ? !Number.isFinite(displayValue)
                                        ? 'Max'
                                        : "".concat(convertToPercentNumber(displayValue), "%")
                                    : displayValue })) }, index));
                    }), _jsx(Col, { flex: "80px" })] }) }));
    }, [expectedData, headcountData, intervals, onExpectedNumberChange]);
    var refetchHeadcount = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refetch()];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        setLastCalculatedAt(moment().toISOString());
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [refetch]);
    return (_jsxs("div", { className: "HeadcountBlockWrapper text-[10px]", children: [_jsx(HoverOptionButtons, { data: {
                    blockId: blockId,
                }, moveBlockUpDown: moveBlockUpDown, isNonTitleBlock: true }), _jsxs("div", { className: "mb-4 flex items-center justify-between", children: [_jsx("div", { className: "mr-4 text-base font-semibold", children: timeFormatFn(startTime, 'MMM D, YYYY', true) }), _jsx(CalculateMetricBar, { lastCalculatedAt: lastCalculatedAt, onRecalculate: refetchHeadcount })] }), headcountData && expectedData && (_jsxs("div", { className: "HeadcountContent", children: [_jsx("div", { className: "flex justify-between", children: _jsxs(Row, { className: "w-full items-center", children: [_jsx(Col, { flex: "85px", className: "flex justify-center", children: _jsx(Button, { icon: _jsx(CaretLeftOutlined, { className: "text-[#897A7A]" }), onClick: function () { return onTimeRangeChange(-12); } }) }), intervals.map(function (interval) { return (_jsx(Col, { flex: "50px", className: "text-left", children: moment(interval).tz(timeZone).format('h:mm A') }, interval)); }), _jsx(Col, { flex: "85px", className: "flex justify-center", children: _jsx(Button, { icon: _jsx(CaretRightOutlined, { className: "text-[#897A7A]" }), onClick: function () { return onTimeRangeChange(12); } }) })] }) }), renderDataRows(HeadcountRowType.EXPECTED, false), _jsx("div", { className: "PaddingContent" }), renderDataRows(HeadcountRowType.SCHEDULED, false), renderDataRows(HeadcountRowType.SCHEDULED, true), _jsx("div", { className: "PaddingContent" }), renderDataRows(HeadcountRowType.ACTUAL, false), renderDataRows(HeadcountRowType.ACTUAL, true)] }))] }));
};
export default React.memo(HeadcountBlock);
