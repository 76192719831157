import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { usePageApi } from 'src/hooks/api';
import { blockPlaceholderImgState, isAddNewBlockDrawerVisible, } from 'src/state/atoms/addNewBlockDrawerState';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import './AddNewBlockDrawer.scss';
import { isSelectingTableForTableTypePage } from 'src/state';
import BlockSelectionStep from './BlockSelectionStep';
import BlockCreationStep from './BlockCreationStep';
import NewBlockPreviewContent from './NewBlockPreviewContent';
var AddNewBlockDrawer = function () {
    var resetBlockPlaceholderInfoState = useResetRecoilState(blockPlaceholderImgState);
    var useGetAccountBlockCategories = usePageApi().useGetAccountBlockCategories;
    var _a = useRecoilState(isAddNewBlockDrawerVisible), isDrawerVisible = _a[0], setIsDrawerVisible = _a[1];
    var isCreatingTableTypePage = useRecoilValue(isSelectingTableForTableTypePage);
    var blockCategories = useGetAccountBlockCategories({
        options: {
            enabled: isDrawerVisible,
        },
    }).data;
    useEffect(function () {
        if (isCreatingTableTypePage) {
            setIsDrawerVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreatingTableTypePage]);
    var _b = useState(0), stepIndex = _b[0], setStepIndex = _b[1];
    var goNextStep = useCallback(function () { return setStepIndex(function (prevIndex) { return prevIndex + 1; }); }, []);
    var goPrevStep = useCallback(function () {
        setStepIndex(function (prevIndex) { return prevIndex - 1; });
        resetBlockPlaceholderInfoState();
    }, [resetBlockPlaceholderInfoState]);
    var onCloseDrawer = useCallback(function () {
        setIsDrawerVisible(false);
        resetBlockPlaceholderInfoState();
        setTimeout(function () { return setStepIndex(0); }, 400);
    }, [resetBlockPlaceholderInfoState, setIsDrawerVisible]);
    var _c = useState(), selectedBlock = _c[0], setSelectedBlock = _c[1];
    var onSelectBlockCard = useCallback(function (selectedCard) {
        setSelectedBlock(selectedCard);
    }, []);
    return (_jsxs(_Fragment, { children: [!isDrawerVisible ? (_jsx("div", { className: "AddNewBlockBtnWrapper", children: _jsxs("div", { className: "AddNewBlockBtn py-4", onClick: function () { return setIsDrawerVisible(true); }, children: [_jsx("div", { className: "PlusIcon m-auto p-1 text-center", children: "+" }), _jsx("div", { className: "mt-3 text-center", children: isCreatingTableTypePage ? 'Select Workspace Table' : 'Add New Block' })] }) })) : (_jsx(NewBlockPreviewContent, { isCreatingTableTypePage: isCreatingTableTypePage })), _jsx(Drawer, { open: isDrawerVisible, onClose: onCloseDrawer, closable: false, destroyOnClose: true, maskStyle: { backgroundColor: 'transparent' }, className: "AddNewBlockDrawer", width: 360, children: blockCategories && (_jsxs("div", { className: "h-full", children: [_jsxs("div", { className: "mb-3 flex items-center justify-between px-5 pt-5", children: [stepIndex === 1 ? (_jsx(Button, { className: "GoBackBtn icon-text-btn", icon: _jsx(ArrowLeftOutlined, {}), onClick: goPrevStep, children: _jsx("span", { className: "ml-3 text-lg font-semibold", children: selectedBlock === null || selectedBlock === void 0 ? void 0 : selectedBlock.blockName }) })) : (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "text-lg font-semibold", children: isCreatingTableTypePage
                                                ? 'Select Workspace Table'
                                                : 'Add Block' }), !isCreatingTableTypePage && (_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.ADD_NEW_BLOCK], children: _jsx(HelpIcon, { className: "ml-1 h-[18px] text-black" }) }))] })), _jsx(Button, { className: "CloseDrawerBtn", icon: _jsx(CloseOutlined, {}), onClick: onCloseDrawer })] }), stepIndex === 0 && (_jsx(BlockSelectionStep, { goNextStep: goNextStep, onSelectBlockCard: onSelectBlockCard, blockCategories: blockCategories, isCreatingTableTypePage: isCreatingTableTypePage })), stepIndex === 1 && selectedBlock && (_jsx(BlockCreationStep, { availableCollection: blockCategories.blockCategoryMap.Tables, selectedBlock: selectedBlock, onCloseDrawer: onCloseDrawer, isCreatingTableTypePage: isCreatingTableTypePage }))] })) })] }));
};
export default React.memo(AddNewBlockDrawer);
