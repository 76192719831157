import { useMutation, useQuery } from 'react-query';
import { useFetch } from './useFetch';
import { useFetchWithoutAuth } from './useFetchWithoutAuth';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useForm = function () {
    var customFetchWithoutAuth = useFetchWithoutAuth().request;
    var customFetchWithAuth = useFetch().request;
    var useGetFormToFill = function (formId) {
        return useQuery(['getFormToFill', formId], function () {
            return customFetchWithoutAuth({
                method: 'GET',
                url: "form/fill/".concat(formId),
            });
        });
    };
    var useFillFormWithoutAuth = function () {
        return useMutation(function (request) {
            return customFetchWithoutAuth({
                method: 'POST',
                url: 'form/fill/process-filled',
            }, request);
        });
    };
    var useFillFormWithAuth = function () {
        return useMutation(function (request) {
            return customFetchWithAuth({
                method: 'POST',
                url: 'form/fill/process-filled',
            }, request);
        });
    };
    var useGenerateSingleForm = function (templateId) {
        return useMutation(function () {
            return customFetchWithoutAuth({
                method: 'POST',
                url: 'form/public',
            }, {
                type: 'CREATE_RECORD',
                templateId: templateId,
                remainingCount: 1,
            });
        });
    };
    var useGenerateInternalForm = function (templateId) {
        return useQuery(['generateInternalForm', templateId], function () {
            return customFetchWithAuth({
                method: 'POST',
                url: 'form/internal',
            }, {
                type: 'CREATE_RECORD',
                templateId: templateId,
                remainingCount: 1,
            });
        });
    };
    return {
        useGetFormToFill: useGetFormToFill,
        useFillFormWithoutAuth: useFillFormWithoutAuth,
        useFillFormWithAuth: useFillFormWithAuth,
        useGenerateSingleForm: useGenerateSingleForm,
        useGenerateInternalForm: useGenerateInternalForm,
    };
};
