import { atom } from 'recoil';
import { localStorageEffect } from './localStorageEffects';
var prevIpSettingUrlState = atom({
    key: 'prevIpSettingUrl',
    default: '',
    effects: [localStorageEffect('prevIpSettingUrl')],
});
var signNowCredentialInfoState = atom({
    key: 'signNowCredentialState',
    default: undefined,
});
export { prevIpSettingUrlState, signNowCredentialInfoState };
