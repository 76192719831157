export var emailValidation = function (email) {
    if (email === '')
        return true;
    var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(email);
};
export var phoneNumberValidation = function (phone) {
    if (phone === '')
        return true;
    var re = /^(\+\d{1,3}( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4}$/;
    return re.test(phone);
};
export var nameInputValidation = function (name) {
    if (name === '')
        return false;
    if (!name.replace(/\s/g, '').length)
        return false;
    return true;
};
export var numberInputValidation = function (number) {
    // Alow empty string
    if (number === '')
        return true;
    // Check if the string contains a comma, which is not allowed from backend, e.g. 1,000
    if (number.includes(','))
        return false;
    // Try parsing the string to a float
    var num = parseFloat(number);
    // Check if parsing was successful and the result is a finite number
    return !isNaN(num) && isFinite(num);
};
