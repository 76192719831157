var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Space, Tag, Select } from 'antd';
import { orderBy } from 'lodash';
import { useRef, useMemo, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import UserAvatar from 'src/components/User/UserAvatar';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { useWindowSize } from 'src/hooks/component';
import { useChatComponent } from 'src/hooks/component/useChat';
import { accountDetailState, currentUserState } from 'src/state';
import { participantsInNewThread, selectedThreadState } from 'src/state/atoms/chatState';
import { isCreatingThreadSelector } from 'src/state/selectors/withChatState';
import AccountBroadcastIcon from 'src/utils/icons/ChatIcons/AccountBroadcastIcon';
import LocationBroadcastIcon from 'src/utils/icons/ChatIcons/LocationBroadcastIcon';
import RoleBroadcastIcon from 'src/utils/icons/ChatIcons/RoleBroadcastIcon';
import { useSetting } from 'src/hooks/api';
import { ThreadReceiverParticipantType, MessageSenderType, ThreadType, } from '../../interface';
var NewThreadHeader = function (_a) {
    var toggleChatSiderBar = _a.toggleChatSiderBar, recipientsDropdownOpen = _a.recipientsDropdownOpen, setRecipientsDropdownOpen = _a.setRecipientsDropdownOpen, chatSiderCollapsed = _a.chatSiderCollapsed;
    var windowWidth = useWindowSize().windowWidth;
    var setSelectedThread = useSetRecoilState(selectedThreadState);
    var accountDetail = useRecoilValue(accountDetailState);
    var _b = useRecoilState(participantsInNewThread), participantsInThread = _b[0], setParticipantsInThread = _b[1];
    var threadType = useRecoilValue(isCreatingThreadSelector);
    var threadParticipantIds = useMemo(function () { var _a; return (_a = participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants.map(function (p) { return p.id; })) !== null && _a !== void 0 ? _a : []; }, [participantsInThread]);
    var useGetAllPossibleRecipients = useChatApi().useGetAllPossibleRecipients;
    var getThreadByParticipant = useChatComponent().getThreadByParticipant;
    var allPossibleRecipients = useGetAllPossibleRecipients().data;
    var selectRef = useRef();
    // select thread if thread with same participants already exists
    useEffect(function () {
        if (participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants.length) {
            var thread = getThreadByParticipant(participantsInThread);
            setSelectedThread(thread === null || thread === void 0 ? void 0 : thread.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantsInThread]);
    useEffect(function () {
        var _a;
        if (recipientsDropdownOpen) {
            (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [recipientsDropdownOpen]);
    var userDetail = useRecoilValue(currentUserState);
    var accessGroupId = userDetail === null || userDetail === void 0 ? void 0 : userDetail.accessGroupIds[0];
    var useGetAccessGroupGlobalFiltersQuery = useSetting().useGetAccessGroupGlobalFiltersQuery;
    var globalFiltersData = useGetAccessGroupGlobalFiltersQuery({
        accessGroupId: accessGroupId !== null && accessGroupId !== void 0 ? accessGroupId : '',
        options: {
            enabled: !!accessGroupId,
        },
    }).data;
    var canAccountBroadCast = (globalFiltersData === null || globalFiltersData === void 0 ? void 0 : globalFiltersData.filters.length) == 0;
    var allRecipientOptions = useMemo(function () {
        var options = allPossibleRecipients && accountDetail
            ? __spreadArray(__spreadArray(__spreadArray([], allPossibleRecipients.roles.map(function (role) {
                return {
                    id: role.id,
                    key: role.id,
                    value: role.name,
                    label: (_jsxs("div", { className: "CreateThreadReceiverDropDown", children: [_jsxs(Space, { children: [_jsx("span", { children: _jsx(RoleBroadcastIcon, { size: "small", color: role.color }) }), _jsx(ZiraTooltip, { title: role.name, children: role.name })] }), _jsx(Tag, { color: "blue", children: "Broadcast" })] })),
                    type: ThreadReceiverParticipantType.BROADCAST_ROLE,
                    color: role.color,
                };
            }), true), allPossibleRecipients.locations.map(function (location) {
                return {
                    id: location.id,
                    key: location.id,
                    value: location.name,
                    label: (_jsxs("div", { className: "CreateThreadReceiverDropDown", children: [_jsxs(Space, { children: [_jsx("span", { children: _jsx(LocationBroadcastIcon, { size: "small", color: location.color }) }), _jsx(ZiraTooltip, { title: location.name, children: location.name })] }), _jsx(Tag, { color: "blue", children: "Broadcast" })] })),
                    type: ThreadReceiverParticipantType.BROADCAST_LOCATION,
                    color: location.color,
                };
            }), true), allPossibleRecipients.users.map(function (user) {
                return {
                    id: user.id,
                    key: user.id,
                    value: user.name,
                    label: (_jsxs("div", { className: "CreateThreadReceiverDropDown", children: [_jsxs(Space, { children: [_jsx(UserAvatar, { size: 24, name: user.name, avatar: user.avatarUrl, isCirclular: true }), _jsx(ZiraTooltip, { title: user.name, children: user.name })] }), _jsx(Tag, { children: "Direct Message" })] })),
                    type: MessageSenderType.USER,
                };
            }), true) : [];
        if (accountDetail && canAccountBroadCast) {
            options = __spreadArray([
                {
                    id: accountDetail.id,
                    key: accountDetail.id,
                    label: (_jsxs("div", { className: "CreateThreadReceiverDropDown", children: [_jsxs(Space, { children: [_jsx("span", { children: _jsx(AccountBroadcastIcon, {}) }), "Entire Account"] }), _jsx(Tag, { color: "blue", children: "Broadcast" })] })),
                    value: accountDetail.name,
                    type: ThreadReceiverParticipantType.BROADCAST_ACCOUNT,
                }
            ], options, true);
        }
        return options;
    }, [accountDetail, allPossibleRecipients, canAccountBroadCast]);
    // ! Only show options that are same type as participantsInThread,
    var typeFilteredOptions = useMemo(function () {
        var threadType = participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.type;
        if (!threadType) {
            return [];
        }
        var options = allRecipientOptions.filter(function (option) {
            return !(participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants.some(function (participant) { return participant.id === option.id; }));
        });
        if (threadType === ThreadType.BROADCAST) {
            if (threadParticipantIds[0] === (accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.id)) {
                // Selected broadcast account; Show no options
                return [];
            }
            var roles = options.filter(function (option) { return option.type === ThreadReceiverParticipantType.BROADCAST_ROLE; });
            var locations = options.filter(function (option) { return option.type === ThreadReceiverParticipantType.BROADCAST_LOCATION; });
            var accountBroadcast = options.filter(function (option) { return option.type === ThreadReceiverParticipantType.BROADCAST_ACCOUNT; });
            var groupedOptions = __spreadArray(__spreadArray([], (threadParticipantIds.length ? [] : accountBroadcast), true), [
                {
                    id: ThreadReceiverParticipantType.BROADCAST_LOCATION,
                    key: ThreadReceiverParticipantType.BROADCAST_LOCATION,
                    value: ThreadReceiverParticipantType.BROADCAST_LOCATION,
                    type: ThreadReceiverParticipantType.BROADCAST_LOCATION,
                    label: _jsx("div", { className: "text-sm font-medium text-gray-500", children: "Locations" }),
                    options: locations,
                },
                {
                    id: ThreadReceiverParticipantType.BROADCAST_ROLE,
                    key: ThreadReceiverParticipantType.BROADCAST_ROLE,
                    value: ThreadReceiverParticipantType.BROADCAST_ROLE,
                    type: ThreadReceiverParticipantType.BROADCAST_ROLE,
                    label: _jsx("div", { className: "text-sm font-medium text-gray-500", children: "Roles" }),
                    options: roles,
                },
            ], false);
            return groupedOptions;
        }
        else if (threadType === ThreadType.DM) {
            // Show user options
            return options.filter(function (option) {
                return option.type === MessageSenderType.USER;
            });
        }
    }, [
        accountDetail === null || accountDetail === void 0 ? void 0 : accountDetail.id,
        allRecipientOptions,
        participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants,
        participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.type,
        threadParticipantIds,
    ]);
    return (_jsxs("div", { className: "NewChatHeaderTitle", children: [_jsx("div", { className: "mb-[2px] cursor-pointer px-2", onClick: function () {
                    toggleChatSiderBar(function (prevChatSiderCollapsed) { return !prevChatSiderCollapsed; });
                }, children: chatSiderCollapsed ? _jsx(MenuUnfoldOutlined, {}) : _jsx(MenuFoldOutlined, {}) }), _jsx("span", { className: "text-md text-gray-400", children: "To:" }), _jsx(Select, { mode: "multiple", value: threadParticipantIds, allowClear: true, bordered: false, style: {
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '0',
                }, open: recipientsDropdownOpen, onDropdownVisibleChange: setRecipientsDropdownOpen, popupClassName: "CreateThreadReceiverPopup", placeholder: threadType === ThreadType.BROADCAST
                    ? 'Select a broadcast target'
                    : 'Select a user', options: typeFilteredOptions, className: "px-0", ref: function (ref) {
                    if (ref) {
                        selectRef.current = ref;
                    }
                }, dropdownStyle: {
                    background: 'white',
                    borderBottom: '1px solid #f4f4f5',
                    maxWidth: '400px',
                    minWidth: '200px',
                }, onSelect: function (_, option) {
                    var _a;
                    setParticipantsInThread(function (prev) {
                        var _a;
                        return __assign(__assign({}, prev), { participants: orderBy(orderBy(__spreadArray(__spreadArray([], ((_a = prev === null || prev === void 0 ? void 0 : prev.participants) !== null && _a !== void 0 ? _a : []).filter(function (participant) { return participant.id !== option.id; }), true), [
                                {
                                    id: option.id,
                                    type: option.type,
                                    name: option.value,
                                    color: option.color,
                                    avatarUrl: option.avatarUrl,
                                },
                            ], false), 'name'), 'type', 'desc') });
                    });
                    (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                    toggleChatSiderBar(function (prevChatSiderCollapsed) {
                        if ((windowWidth || 577) <= 576 && !prevChatSiderCollapsed) {
                            return true;
                        }
                        return prevChatSiderCollapsed;
                    });
                }, tagRender: function (props) {
                    var _a, _b;
                    var value = props.value, closable = props.closable, onClose = props.onClose;
                    var participantIndex = (_a = participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants.findIndex(function (p) { return p.id == value; })) !== null && _a !== void 0 ? _a : -1;
                    if (!participantsInThread || participantIndex < 0)
                        return _jsx(_Fragment, {});
                    var participant = participantsInThread.participants[participantIndex];
                    var _c = participant !== null && participant !== void 0 ? participant : {}, name = _c.name, type = _c.type, color = _c.color, avatarUrl = _c.avatarUrl;
                    var icon;
                    if (type === MessageSenderType.USER) {
                        icon = (_jsx(UserAvatar, { size: 24, name: name !== null && name !== void 0 ? name : '', color: color, avatar: avatarUrl, isCirclular: true }));
                    }
                    else if (type === ThreadReceiverParticipantType.BROADCAST_LOCATION) {
                        icon = _jsx(LocationBroadcastIcon, { size: "small", color: color });
                    }
                    else if (type === ThreadReceiverParticipantType.BROADCAST_ROLE) {
                        icon = _jsx(RoleBroadcastIcon, { size: "small", color: color });
                    }
                    else {
                        icon = _jsx(AccountBroadcastIcon, {});
                    }
                    return (_jsxs("div", { className: "flex items-center", children: [_jsx(Tag, { closable: closable, onClose: onClose, icon: icon, className: "flex items-center text-lg", style: {
                                    background: 'white',
                                    borderRadius: '8px',
                                    border: '1px solid #f4f4f5',
                                }, children: _jsx("span", { className: "ml-[4px]", children: name }) }), type === ThreadReceiverParticipantType.BROADCAST_ROLE &&
                                ((_b = participantsInThread.participants[participantIndex + 1]) === null || _b === void 0 ? void 0 : _b.type) ===
                                    ThreadReceiverParticipantType.BROADCAST_LOCATION && (_jsx("span", { className: "pr-[8px]", children: "in" }))] }));
                }, onDeselect: function (value) {
                    setParticipantsInThread(function (prev) {
                        var _a;
                        return __assign(__assign({}, prev), { participants: ((_a = prev === null || prev === void 0 ? void 0 : prev.participants) !== null && _a !== void 0 ? _a : []).filter(function (participant) { return participant.id !== value; }) });
                    });
                }, onClear: function () {
                    setParticipantsInThread(function (prev) {
                        return __assign(__assign({}, prev), { participants: [] });
                    });
                } })] }));
};
export default NewThreadHeader;
