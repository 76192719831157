var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { useFetch } from './useFetch';
var usePayApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var accountId = useRecoilValue(accountIdState);
    var useGetPayPeriodData = function (request) {
        var _a;
        return useQuery(['payPeriodData', request.body], function () {
            return customFetch({
                method: 'POST',
                url: "pay/pay_period_data",
            }, request.body);
        }, {
            enabled: (_a = request === null || request === void 0 ? void 0 : request.options) === null || _a === void 0 ? void 0 : _a.enabled,
        });
    };
    var useGetPayReportListData = function (request) {
        var _a, _b;
        var body = request.body;
        return useQuery(['payReportListData', request.body], function () { return payReportCardListData(body); }, {
            enabled: !!((_a = request.body) === null || _a === void 0 ? void 0 : _a.payPeriodId) && ((_b = request.options) === null || _b === void 0 ? void 0 : _b.enabled),
        });
    };
    var payReportCardListData = function (body) {
        return customFetch({
            method: 'POST',
            url: "pay/paystubs",
        }, body);
    };
    var useGetRecordPayDetails = function (body) {
        return useQuery(['recordPayDetails', body], function () {
            return customFetch({
                method: 'POST',
                url: "pay/record_details",
            }, body);
        }, {
            enabled: !!body.payPeriodId && !!body.recordId,
        });
    };
    var getDynamicMetricList = function (body) {
        return customFetch({
            method: 'POST',
            url: 'pay/list_dynamic_metrics',
        }, body);
    };
    var useEditDynamicMetricList = function () {
        return useMutation(function (body) {
            return toastRequest({
                method: 'POST',
                url: 'pay/edit_dynamic_metrics',
            }, body, 'Calculating dynamic metrics...', 'Successfully updated dynamic metrics.', 'Failed to update dynamic metrics.');
        });
    };
    var useListPayPeriod = function (request) {
        var _a;
        return useQuery(['recordPayDetails', accountId, request], function () {
            return customFetch({
                method: 'POST',
                url: 'pay/pay_period_list',
            }, __assign(__assign({}, request.body), { accountId: accountId }));
        }, {
            enabled: (_a = request === null || request === void 0 ? void 0 : request.options) === null || _a === void 0 ? void 0 : _a.enabled,
        });
    };
    var useSavePayPeriod = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'pay/save',
            }, __assign(__assign({}, body), { accountId: accountId }));
        });
    };
    var useApprovePayPeriod = function () {
        return useMutation(function (body) {
            return toastRequest({
                method: 'POST',
                url: 'pay/approve',
            }, __assign(__assign({}, body), { accountId: accountId }));
        });
    };
    var useDeletePayPeriod = function () {
        return useMutation(function (payPeriodId) {
            return toastRequest({
                method: 'DELETE',
                url: "pay/".concat(payPeriodId),
            });
        });
    };
    /**
     * Attestation
     */
    var useUpdateAccountAttestationSettingsMutation = function () {
        return useMutation(function (body) {
            return toastRequest({
                method: 'POST',
                url: "accounts/attestation",
            }, __assign(__assign({}, body), { accountId: accountId }));
        });
    };
    var useGetAttestationSettingsQuery = function () {
        return useQuery([accountId, 'attestationSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "accounts/attestation/".concat(accountId),
            });
        });
    };
    // For payroll enhancement, payroll v2
    var useGetPayPeriodDataV2 = function (request) {
        var _a;
        return useQuery(['payPeriodDataV2', request.body], function () {
            return customFetch({
                method: 'POST',
                url: "pay/pay_period_data/v2",
            }, request.body);
        }, {
            enabled: (_a = request === null || request === void 0 ? void 0 : request.options) === null || _a === void 0 ? void 0 : _a.enabled,
        });
    };
    var useApprovedAllShifts = function () {
        return useMutation(function (body) {
            return toastRequest({
                method: 'POST',
                url: 'pay/approve/grouped_shifts',
            }, body);
        });
    };
    return {
        useGetPayReportListData: useGetPayReportListData,
        useGetPayPeriodData: useGetPayPeriodData,
        payReportCardListData: payReportCardListData,
        useGetRecordPayDetails: useGetRecordPayDetails,
        getDynamicMetricList: getDynamicMetricList,
        useListPayPeriod: useListPayPeriod,
        useEditDynamicMetricList: useEditDynamicMetricList(),
        useSavePayPeriod: useSavePayPeriod(),
        useApprovePayPeriod: useApprovePayPeriod(),
        useDeletePayPeriod: useDeletePayPeriod(),
        useUpdateAccountAttestationSettingsMutation: useUpdateAccountAttestationSettingsMutation(),
        useGetAttestationSettingsQuery: useGetAttestationSettingsQuery,
        useGetPayPeriodDataV2: useGetPayPeriodDataV2,
        useApprovedAllShifts: useApprovedAllShifts(),
    };
};
export { usePayApi };
