import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { useRecoilCallback } from 'recoil';
import { useTemplateComponent } from 'src/hooks/component/useTemplate';
import { scheduleDropTargetState } from 'src/state/atoms/schedule';
import './ScheduleCellDropWrapper.scss';
var ScheduleCellDropWrapper = function (props) {
    var recordKey = props.recordKey, recordIndex = props.recordIndex, columnKey = props.columnKey, columnIndex = props.columnIndex, children = props.children, blockId = props.blockId;
    var _a = useTemplateComponent(blockId), draggingTemplate = _a.draggingTemplate, isDraggingTemplate = _a.isDraggingTemplate, moveTemplate = _a.moveTemplate, overPosition = _a.overPosition;
    var _b = useDrop(function () { return ({
        accept: ['ShiftCard', 'TemplateCard'],
        collect: function (monitor) { return ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }); },
    }); }, []), isOver = _b[0].isOver, drop = _b[1];
    var setDropTargetState = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (newDropState) {
            set(scheduleDropTargetState(blockId), newDropState);
        };
    }, [blockId]);
    useEffect(function () {
        if (isOver) {
            setDropTargetState({ columnKey: columnKey, recordKey: recordKey });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOver]);
    useEffect(function () {
        if (isOver)
            moveTemplate(recordIndex, columnIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOver, moveTemplate]);
    var shouldHighlight = useCallback(function () {
        if (!overPosition || !draggingTemplate)
            return false;
        return isDraggingTemplate;
    }, [draggingTemplate, isDraggingTemplate, overPosition]);
    var dragState = useMemo(function () {
        return (!isDraggingTemplate && isOver) || (isOver && isDraggingTemplate && shouldHighlight());
    }, [isDraggingTemplate, isOver, shouldHighlight]);
    return (_jsxs("div", { ref: function (ref) {
            drop(ref);
        }, className: 'ScheduleCellWrapper' + (dragState ? ' isDragging' : ''), children: [dragState && _jsx("div", { className: "dropHere", children: "Drop Here" }), children] }));
};
export default React.memo(ScheduleCellDropWrapper);
