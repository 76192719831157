import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './Loading.scss';
var Loading = function (_a) {
    var _b = _a.fullPage, fullPage = _b === void 0 ? true : _b;
    var antIcon = _jsx(LoadingOutlined, { className: fullPage ? 'large-icon' : 'small-icon', spin: true });
    return (_jsx("div", { className: fullPage ? 'large-loading' : 'small-loading', children: _jsx(Spin, { indicator: antIcon }) }));
};
export default React.memo(Loading);
