import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { useFileApi } from 'src/hooks/api/useFileApi';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ComponentWithUpload, { UploadFileType } from '../../ComponentWithUpload';
var BusinessDetailsImgUploadSection = function (props) {
    var handleDeleteFile = useFileApi().deleteFile;
    var logoUrl = props.logoUrl, canEdit = props.canEdit, onChangeAccountLogo = props.onChangeAccountLogo;
    var acceptedFileType = useMemo(function () { return [UploadFileType.IMAGE]; }, []);
    var onChangeLogo = useCallback(function (info) {
        var url = info.file.response.data.url;
        if (logoUrl) {
            var oldUrl = logoUrl;
            handleDeleteFile(oldUrl).then(function () {
                onChangeAccountLogo(url);
            });
        }
    }, [logoUrl, handleDeleteFile, onChangeAccountLogo]);
    var onDeleteLogo = useCallback(function () {
        if (!logoUrl)
            return;
        var url = logoUrl;
        handleDeleteFile(url).then(function () {
            onChangeAccountLogo('');
        });
    }, [handleDeleteFile, logoUrl, onChangeAccountLogo]);
    return (_jsxs(_Fragment, { children: [_jsx("img", { className: "WebLogoImgPreview", src: logoUrl }), canEdit && (_jsxs("div", { className: "mb-5", children: [_jsx(ComponentWithUpload, { acceptedFileType: acceptedFileType, successAction: onChangeLogo, type: UploadUrlType.Store, children: _jsx(Button, { size: "small", children: "Change" }) }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this logo?", onConfirm: onDeleteLogo, placement: "rightBottom", children: _jsx(Button, { size: "small", children: "Delete" }) })] }))] }));
};
export default React.memo(BusinessDetailsImgUploadSection);
