import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { dateValidation } from 'src/utils/DatePicker';
var DateInput = function (props) {
    var startDate = props.startDate, isDateValid = props.isDateValid, startDateOnChange = props.startDateOnChange, dateValidOnChange = props.dateValidOnChange;
    var _a = useState(''), dateValue = _a[0], setDateValue = _a[1];
    useEffect(function () {
        setDateValue(startDate);
    }, [startDate]);
    var handleValidation = useCallback(function () {
        if (dateValidation(dateValue)) {
            startDateOnChange(dateValue);
        }
        dateValidOnChange(dateValidation(dateValue));
    }, [dateValidOnChange, dateValue, startDateOnChange]);
    var onBlurChange = useCallback(function () {
        handleValidation();
    }, [handleValidation]);
    var onPressEnter = useCallback(function (e) {
        e.currentTarget.blur();
    }, []);
    var onInputChange = useCallback(function (e) {
        setDateValue(e.target.value);
    }, []);
    var onClick = useCallback(function (e) {
        e.currentTarget.focus();
    }, []);
    return (_jsx("div", { className: "DateInfo", style: { borderColor: isDateValid ? '#D9D9D9' : 'red' }, children: _jsx(Input, { autoFocus: true, className: "DatePickerInput", bordered: false, value: dateValue, onBlur: onBlurChange, onPressEnter: onPressEnter, onChange: onInputChange, onClick: onClick }) }));
};
export default DateInput;
