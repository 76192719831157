var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { collectionTableSelectedRecordIdsState, tableCollectionRowsState } from 'src/state';
var SelectRecordCheckBox = function (_a) {
    var _b, _c;
    var blockId = _a.blockId, reverseRelationId = _a.reverseRelationId, recordId = _a.recordId;
    var _d = useRecoilState(collectionTableSelectedRecordIdsState((_b = blockId !== null && blockId !== void 0 ? blockId : reverseRelationId) !== null && _b !== void 0 ? _b : '')), selectedRecordIds = _d[0], setSelectedRecordIds = _d[1];
    var tableRows = useRecoilValue(tableCollectionRowsState((_c = blockId !== null && blockId !== void 0 ? blockId : reverseRelationId) !== null && _c !== void 0 ? _c : ''));
    var isSelectAll = !recordId;
    var isSelected = isSelectAll
        ? selectedRecordIds.length === tableRows.length && tableRows.length > 0
        : selectedRecordIds.includes(recordId);
    var onCheckBoxClick = useCallback(function () {
        !isSelectAll && isSelected
            ? // Unselect record
                setSelectedRecordIds(function (prev) { return prev.filter(function (id) { return id !== recordId; }); })
            : !isSelectAll
                ? // Select record
                    setSelectedRecordIds(function (prev) { return __spreadArray(__spreadArray([], prev, true), [recordId], false); })
                : isSelectAll && isSelected
                    ? // Unselect all
                        setSelectedRecordIds([])
                    : // Select all
                        setSelectedRecordIds(tableRows.map(function (row) { return row.recordId; }));
    }, [isSelectAll, isSelected, recordId, setSelectedRecordIds, tableRows]);
    return (_jsx("div", { className: "SelectRecordCheckBox", style: { visibility: isSelected ? 'visible' : 'hidden' }, children: _jsx(Checkbox, { checked: isSelected, onClick: onCheckBoxClick }) }));
};
export default React.memo(SelectRecordCheckBox);
