import { atom } from 'recoil';
var availableShiftPaySchemasState = atom({
    key: 'availableShiftPaySchemasState',
    default: undefined,
});
var availablePayConditionSchemasState = atom({
    key: 'availablePayConditionSchemasState',
    default: undefined,
});
var isDateRangePropagationModalOpenState = atom({
    key: 'isDateRangePropagationModalOpenState',
    default: false,
});
var isConfigDefaultRateModalOpenState = atom({
    key: 'isConfigDefaultRateModalOpenState',
    default: false,
});
var currentDefaultRateState = atom({
    key: 'currentDefaultRateState',
    default: undefined,
});
var isCreatingDefaultRateState = atom({
    key: 'isCreatingDefaultRateState',
    default: false,
});
var isDuplicatingDefaultRateState = atom({
    key: 'isDuplicatingDefaultRateState',
    default: false,
});
var uploadedDefaultRateFileState = atom({
    key: 'uploadedDefaultRateFileState',
    default: undefined,
});
var uploadedRateFilePassVerifyState = atom({
    key: 'uploadedRateFilePassVerifyState',
    default: false,
});
var defaultRateValidationErrorInfoState = atom({
    key: 'defaultRateValidationErrorInfoState',
    default: [],
});
var defaultRateImportSuccessState = atom({
    key: 'defaultRateImportSuccessState',
    default: false,
});
export { availablePayConditionSchemasState, currentDefaultRateState, isCreatingDefaultRateState, availableShiftPaySchemasState, isDateRangePropagationModalOpenState, isConfigDefaultRateModalOpenState, isDuplicatingDefaultRateState, uploadedDefaultRateFileState, uploadedRateFilePassVerifyState, defaultRateValidationErrorInfoState, defaultRateImportSuccessState, };
