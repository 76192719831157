import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
var RatesUploadErrorContent = function (_a) {
    var errors = _a.errors;
    var sortedErrors = useMemo(function () {
        var temp = cloneDeep(errors);
        return temp.sort(function (a, b) { return a.lineNumber - b.lineNumber; });
    }, [errors]);
    return (_jsxs("div", { className: "max-h-[400px] overflow-y-auto", children: [_jsx("div", { className: "font-medium ", children: "We found the following error(s) with your upload: " }), _jsx("div", { className: "mt-3", children: sortedErrors.map(function (error, index) { return (_jsxs("div", { className: "mt-1", children: ["\u2022 Line ", error.lineNumber, ": ", error.message] }, index)); }) })] }));
};
export default React.memo(RatesUploadErrorContent);
