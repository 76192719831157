var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var GridIconSvg = function () { return (_jsxs("svg", { width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "3.37598", y: "3.39587", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "3.37598", y: "7.89587", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "3.37598", y: "12.3959", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "7.87598", y: "3.39587", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "7.87598", y: "7.89587", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "7.87598", y: "12.3959", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "12.376", y: "3.39587", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "12.376", y: "7.89587", width: "2.25", height: "2.25", rx: "0.5", fill: "black" }), _jsx("rect", { x: "12.376", y: "12.3959", width: "2.25", height: "2.25", rx: "0.5", fill: "black" })] })); };
export var GridIcon = function (props) { return (_jsx(Icon, __assign({ component: GridIconSvg }, props))); };
