import { selectorFamily } from 'recoil';
import { shiftTemplateListRespState } from './Atom';
var orderedShiftTemplateListState = selectorFamily({
    key: 'orderedShiftTemplateListState',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var templateListResp = get(shiftTemplateListRespState(blockId));
            var list = [];
            if (templateListResp) {
                if (templateListResp.settings && templateListResp.settings.order.length !== 0) {
                    templateListResp.settings.order.map(function (id) {
                        var template = templateListResp.data[id];
                        if (template)
                            list.push(template);
                    });
                }
                else {
                    Object.values(templateListResp.data).map(function (template) {
                        if (template)
                            list.push(template);
                    });
                }
            }
            return list;
        };
    },
});
var shiftTemplateListOrderState = selectorFamily({
    key: 'shiftTemplateListOrderState',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var templateListResp = get(shiftTemplateListRespState(blockId));
            if (!templateListResp)
                return [];
            if (templateListResp.settings && templateListResp.settings.order.length !== 0) {
                return templateListResp.settings.order;
            }
            else {
                return Object.keys(templateListResp.data);
            }
        };
    },
});
export { orderedShiftTemplateListState, shiftTemplateListOrderState };
