import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import ZiraRowsSkeleton from '../../../../ui/ZiraRowsSkeleton';
import { calculateOnboardingProgress } from '../DashboardUtils';
import OnboardingCheckList from './OnboardingCheckList';
var OnboardingProgressWidget = function () {
    var _a;
    var useGetOnboardingCheckList = useDashboardApi().useGetOnboardingCheckList;
    var _b = useGetOnboardingCheckList(), data = _b.data, isLoading = _b.isLoading, refetch = _b.refetch;
    var onboardingProgressPercentageNumber = calculateOnboardingProgress((_a = data === null || data === void 0 ? void 0 : data.items) !== null && _a !== void 0 ? _a : []);
    if (!data || onboardingProgressPercentageNumber === 100)
        return _jsx(_Fragment, {});
    if (isLoading)
        return (_jsx("div", { className: "ExpertTeamContactWidgetContainer w-full rounded-[15px] bg-white shadow-block", children: _jsx(ZiraRowsSkeleton, { rowNumber: 8 }) }));
    return (_jsx(_Fragment, { children: _jsx("div", { className: "ExpertTeamContactWidgetContainer w-full rounded-[15px] bg-white shadow-block", children: _jsx(OnboardingCheckList, { list: data.items, progressPercentageNumber: onboardingProgressPercentageNumber, refetch: refetch }) }) }));
};
export default React.memo(OnboardingProgressWidget);
