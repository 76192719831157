import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { accountNameState } from 'src/state/selectors/withAccountDetailState';
import RecordDetailModalViewContainer from '../RecordDetailModal/RecordDetailModalViewContainer';
import HelpDrawer from '../CenterPanel/Header/HelpDrawer/HelpDrawer';
import SettingsSider from './SettingsSider';
import SettingsContent from './SettingsContent';
import './SettingsPage.scss';
import { IntegrationStepsState } from './Integration/SyncStepsContainer';
var Sider = Layout.Sider, Content = Layout.Content;
var SettingsPage = function () {
    var navigate = useNavigate();
    var accountId = useRecoilValue(accountIdState);
    var accountName = useRecoilValue(accountNameState);
    useEffect(function () {
        var pageName = location.pathname.split('/').pop();
        if (accountName) {
            document.title = "".concat(accountName, " | ").concat(upperCaseStringToStartCase(pageName));
        }
    }, [accountName]);
    useEffect(function () {
        if (accountId) {
            return;
        }
        else {
            navigate('/', { replace: true });
        }
    }, [accountId, navigate]);
    return (_jsxs("div", { className: "SettingPageContainer", children: [_jsx(IntegrationStepsState.Provider, { children: _jsxs(Layout, { children: [_jsx(Sider, { width: 260, children: _jsx(SettingsSider, {}) }), _jsx(Content, { className: "SettingContent", children: _jsx(SettingsContent, {}) })] }) }), _jsx(HelpDrawer, {}), _jsx(RecordDetailModalViewContainer, {})] }));
};
export default React.memo(SettingsPage);
