import { DefaultValue } from 'recoil';
/**
 * observe atom updates & save to localStorage
 */
function localStorageEffect(key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            try {
                setSelf(JSON.parse(savedValue));
            }
            catch (_b) {
                var jsonStringValue = JSON.stringify(savedValue);
                setSelf(JSON.parse(jsonStringValue));
                localStorage.setItem(key, jsonStringValue);
            }
        }
        onSet(function (newValue, _, isReset) {
            newValue === undefined || newValue instanceof DefaultValue || isReset
                ? localStorage.removeItem(key)
                : localStorage.setItem(key, JSON.stringify(newValue));
        });
    };
}
export { localStorageEffect };
