import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { JOBS_AND_PLACEMENTS } from 'src/components/FeatureGatingKeyConstant';
import { TASKS_OVERVIEW_TABS_HEIGHT, TASK_SETTINGS_TABS } from '../constant';
import { TaskTab } from '../interface';
import { taskEditorActiveTabState } from '../atom';
var TabPane = Tabs.TabPane;
var TasksSettingTabs = function () {
    var _a = useRecoilState(taskEditorActiveTabState), activeTab = _a[0], setActiveTab = _a[1];
    var jobsPlacementsFeatureEnabled = useRecoilValue(featureEnableStatusState(JOBS_AND_PLACEMENTS));
    var filteredTabs = useMemo(function () {
        return TASK_SETTINGS_TABS.filter(function (tab) {
            if (jobsPlacementsFeatureEnabled) {
                return true;
            }
            return tab.key !== TaskTab.RULES;
        });
    }, [jobsPlacementsFeatureEnabled]);
    return (_jsx(Tabs, { className: "TaskTabs", activeKey: activeTab, onChange: function (tab) { return setActiveTab(tab); }, style: { height: TASKS_OVERVIEW_TABS_HEIGHT }, moreIcon: null, children: filteredTabs.map(function (tab) { return (_jsx(TabPane, { tab: _jsxs("div", { className: "flex items-center px-[4px]", style: tab.key === activeTab ? { opacity: 1 } : { opacity: 0.7 }, children: [tab.icon, _jsx("div", { children: tab.name })] }) }, tab.key)); }) }));
};
export default React.memo(TasksSettingTabs);
