import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
var useCommentApi = function () {
    var customFetch = useFetch().request;
    var useGetRecordComments = function (recordId) {
        return useQuery(['getRecordComments', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "comment/record/".concat(recordId),
            });
        });
    };
    var deleteRecordComment = function (id) {
        return customFetch({
            method: 'DELETE',
            url: "comment/".concat(id),
        });
    };
    var useGetAccountComments = function () {
        return useQuery(['getAccountComments'], function () {
            return customFetch({
                method: 'GET',
                url: "comment/account",
            });
        });
    };
    var createCommentRequest = function (request) {
        return customFetch({
            method: 'POST',
            url: 'comment',
        }, request);
    };
    return {
        useGetRecordComments: useGetRecordComments,
        useGetAccountComments: useGetAccountComments,
        createCommentRequest: createCommentRequest,
        deleteRecordComment: deleteRecordComment,
    };
};
export { useCommentApi };
