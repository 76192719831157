import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { departmentsState } from 'src/state/atoms/chatState';
import { selectedThreadSummarySelector } from 'src/state/selectors/withChatState';
import { threadIsBroadcast } from '../utils';
import './ChatContent.scss';
import BroadcastScreen from './MessagesScreen/BroadcastScreen';
import DirectMessageScreen from './MessagesScreen/DirectMessageScreen';
import NewThreadScreen from './NewThreadScreen/NewThreadScreen';
var ChatContent = function () {
    var selectedThreadSummary = useRecoilValue(selectedThreadSummarySelector);
    var useGetDepartmentCanSendAs = useChatApi().useGetDepartmentCanSendAs;
    var allDepartments = useGetDepartmentCanSendAs().data;
    var setInitialDepartments = useSetRecoilState(departmentsState);
    useEffect(function () {
        setInitialDepartments(allDepartments !== null && allDepartments !== void 0 ? allDepartments : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDepartments]);
    return (_jsx("div", { className: "w-full", children: selectedThreadSummary ? (_jsx("div", { className: "ChatContentScreen", children: threadIsBroadcast(selectedThreadSummary) ? (_jsx(BroadcastScreen, {})) : (_jsx(DirectMessageScreen, {})) })) : (_jsx(NewThreadScreen, {})) }));
};
export default React.memo(ChatContent);
