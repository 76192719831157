import { jsx as _jsx } from "react/jsx-runtime";
import { CsvExportFileIcon } from 'src/utils/icons/CsvExportFileIcon';
import { PdfExportFileIcon } from 'src/utils/icons/PdfExportFileIcon';
// TODO: add sample file download link when it's available
export var SampleFiles = [
    { title: 'Example PDF Report', icon: _jsx(PdfExportFileIcon, {}), downloadUrl: '' },
    {
        title: 'Example Quickbooks Report',
        icon: _jsx(CsvExportFileIcon, {}),
        downloadUrl: '',
    },
    {
        title: 'Example Shift Detail Report',
        icon: _jsx(CsvExportFileIcon, {}),
        downloadUrl: '',
    },
];
