import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Image } from 'antd';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { documentPreviewModalDataState } from 'src/components/PageView/DocumentPreviewModal/Atom';
var FileQuickView = function (_a) {
    var fileData = _a.fileData;
    var setDocumentPreviewData = useSetRecoilState(documentPreviewModalDataState);
    var _b = useState(true), showPreview = _b[0], setShowPreview = _b[1];
    var isPDFType = useMemo(function () { return fileData.type.includes('pdf'); }, [fileData.type]);
    var isImgType = useMemo(function () { return fileData.type.includes('image'); }, [fileData.type]);
    var docs = useMemo(function () {
        var file = {
            uri: fileData.url,
            fileName: fileData.name,
            fileType: fileData.type,
        };
        return [file];
    }, [fileData]);
    if (!isPDFType && !isImgType) {
        return (_jsx("div", { className: "mb-2 mt-2 text-xs text-gray-300", onClick: function () { return setDocumentPreviewData([fileData]); }, children: "View File" }));
    }
    return (_jsxs("div", { className: "FileQuickViewContainer mb-2 mr-[10px] mt-2", children: [_jsxs("div", { className: "mb-2 mt-2 text-xs text-gray-300", onClick: function () { return setShowPreview(!showPreview); }, children: [!showPreview ? 'Show' : 'Hide', " Preview"] }), showPreview && (_jsxs("div", { className: "flex justify-center", children: [isPDFType && (_jsxs("div", { className: "PDFViewContainer", onClick: function () { return setDocumentPreviewData([fileData]); }, children: [_jsx(DocViewer, { documents: docs, pluginRenderers: DocViewerRenderers, prefetchMethod: "GET", className: "TeamBridgeDocViewer", style: {
                                    width: '100%',
                                    maxHeight: '400px',
                                }, config: {
                                    header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                    },
                                } }), _jsx("div", { className: "PDFPreviewOverlay" }), _jsx("div", { className: "PDFPreviewOverlayText", children: "View" })] })), isImgType && fileData.url && (_jsx(Image, { style: { maxHeight: '400px' }, preview: {
                            mask: _jsx("div", { children: "View" }),
                        }, src: fileData.url }))] }))] }));
};
export default React.memo(FileQuickView);
