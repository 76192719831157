var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Divider, Input, Tabs } from 'antd';
import FilterEntry from 'src/components/OptimizedTableView/OptionHeader/Filter/FilterEntry';
import { usePageApi } from 'src/hooks/api';
import { useRecoilValue } from 'recoil';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { NaviBarAddButton } from 'src/utils/icons/NaviBarAddButton';
import { useTimeZone } from 'src/hooks/component';
import { CollectionType } from 'src/shared';
import { SHIFT_RECOMMENDATIONS_MODAL_PADDING, ShiftRecommendationsView } from './constant';
var TabPane = Tabs.TabPane;
var ShiftRecommendationsModalHeader = function (_a) {
    var startAt = _a.startAt, endAt = _a.endAt, assignedCount = _a.assignedCount, totalCount = _a.totalCount, searchText = _a.searchText, view = _a.view, filters = _a.filters, setSearchText = _a.setSearchText, setView = _a.setView, setFilters = _a.setFilters;
    var usePageFilters = usePageApi().usePageFilters;
    var userCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.USER));
    var collectionFilters = usePageFilters({
        body: {
            collectionId: userCollectionId,
            collectionViewId: null,
        },
        options: {
            enabled: !!userCollectionId,
        },
    }).data;
    var columns = collectionFilters === null || collectionFilters === void 0 ? void 0 : collectionFilters.schemas;
    var _b = useTimeZone(), timeFormatFn = _b.timeFormatFn, timezoneAbbr = _b.timezoneAbbr;
    return (_jsxs("div", { className: "ShiftRecommendationsModalHeader", style: { paddingRight: SHIFT_RECOMMENDATIONS_MODAL_PADDING }, children: [_jsxs("div", { className: "flex", children: [_jsxs("div", { className: "flex flex-col text-lg", children: [_jsx("div", { className: "font-semibold", children: timeFormatFn(startAt, 'ddd, MMM Do', false) }), _jsxs("div", { className: "font-medium", children: [timeFormatFn(startAt, 'h:mma', false), " -", ' ', timeFormatFn(endAt, 'h:mma', false), " ", timezoneAbbr(true)] })] }), _jsxs("div", { className: "ml-auto flex h-[56px] w-[56px] items-center justify-center rounded-[28px] bg-gray-50", children: [_jsx("span", { children: assignedCount }), "/", _jsx("span", { className: "font-medium", children: totalCount })] })] }), _jsx(Input, { className: "my-[8px] rounded-xl", value: searchText, onChange: function (e) { return setSearchText(e.target.value); }, size: "large", placeholder: "Search...", allowClear: true }), _jsxs("div", { children: [filters.map(function (f, index) { return (_jsxs(_Fragment, { children: [_jsx(FilterEntry, { existingFilter: f, columns: columns !== null && columns !== void 0 ? columns : {}, onDeleteEntry: function () {
                                    setFilters(function (prev) {
                                        var newFilter = __spreadArray([], prev, true);
                                        newFilter.splice(index, 1);
                                        return newFilter;
                                    });
                                }, onEditEntry: function (newFilter) {
                                    return setFilters(function (prev) {
                                        return prev.map(function (f, i) { return (i === index ? newFilter : f); });
                                    });
                                }, options: {
                                    className: 'p-0 shadow-none w-full ',
                                    autoFocusOnDropdown: false,
                                    showCurrentUserFilter: false,
                                } }, (f === null || f === void 0 ? void 0 : f.id) || index), index === filters.length - 1 ? _jsx("div", { className: "pb-[16px]" }) : _jsx(Divider, {})] })); }), _jsx(Button, { className: "AddTableFilterButton", icon: _jsx(NaviBarAddButton, {}), onClick: function () { return setFilters(function (prev) { return __spreadArray(__spreadArray([], prev, true), [undefined], false); }); }, children: "Add Filter" })] }), _jsxs(Tabs, { activeKey: view, onChange: function (view) { return setView(view); }, children: [_jsx(TabPane, { tab: _jsx("div", { className: "w-[32px] text-center text-base", children: "All" }) }, ShiftRecommendationsView.ALL), _jsx(TabPane, { tab: _jsx("div", { className: "text-center text-base", children: "Recommended" }) }, ShiftRecommendationsView.RECOMMENDED)] })] }));
};
export default React.memo(ShiftRecommendationsModalHeader);
