import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CollectionType } from 'src/shared';
import TaskRecordActions from './Task/TaskRecordActions';
var StandardCollectionRecordActions = function (_a) {
    var recordId = _a.recordId, collectionId = _a.collectionId, collectionType = _a.collectionType, onCloseHoverOptions = _a.onCloseHoverOptions;
    if (collectionType === CollectionType.TASK) {
        return (_jsx(TaskRecordActions, { recordId: recordId, collectionId: collectionId, onCloseHoverOptions: onCloseHoverOptions }));
    }
    return null;
};
export default React.memo(StandardCollectionRecordActions);
