import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import './CenterPanelContent.scss';
import { isAddNewBlockDrawerVisible } from 'src/state/atoms/addNewBlockDrawerState';
import { Layout } from 'antd';
import { isCurrentPageCanvasTypeSelector, isSelectingTableForTableTypePage } from 'src/state';
import PageView from '../../PageView/PageView';
var Content = Layout.Content;
var CenterPanelContent = function () {
    var isAddNewBlockDrawerOpen = useRecoilValue(isAddNewBlockDrawerVisible);
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var isCreatingTableTypePage = useRecoilValue(isSelectingTableForTableTypePage);
    return (_jsx(Content, { className: "".concat(isCanvasPageType ? 'CenterPanelContent' : 'FullTableViewCenterPanelContent', " ").concat(isAddNewBlockDrawerOpen ? 'CenterPanelContentEditMode' : ''), id: "CenterPanelContent", children: _jsx("div", { className: "LayoutBackground pb-[300px]", style: isCreatingTableTypePage ? { background: 'none' } : undefined, children: _jsx(PageView, {}) }) }));
};
export default React.memo(CenterPanelContent);
