import psyduckEmoji from 'src/utils/icons/CustomEmojis/psyduck.png';
export var CUSTOM_EMOJIS = [
    {
        id: 'quack',
        name: 'Quack',
        emojis: [
            {
                id: 'psyduck',
                name: 'Psyduck',
                shortcodes: ':psyduck:',
                keywords: ['psyduck', 'duck'],
                skins: [{ src: psyduckEmoji }],
            },
        ],
    },
];
