import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useSetting } from 'src/hooks/api';
import { collectionOptionsState, isQuickCreateModalVisibleState, quickCreateModalCollectionInfoState, } from 'src/state';
import { GridIcon } from 'src/utils/icons/GridIcon';
import { InvisibleEyeIcon } from 'src/utils/icons/InvisibleEyeIcon';
import { VisibleEyeIcon } from 'src/utils/icons/VisibleEyeIcon';
import { toggleList } from 'src/utils/Tools/LodashTool';
import QuickCreateModalWrapper from '../QuickCreateModal/QuickCreateModalWrapper';
import './QuickCreateMenu.scss';
import TimeClockWidget from './TimeClockWidget/TimeClockWidget';
var QuickCreateMenu = function () {
    var _a;
    var collectionOptions = useRecoilValue(collectionOptionsState);
    var _b = useSetting(), useGetUserQuickMenuSettings = _b.useGetUserQuickMenuSettings, useSaveUserQuickMenuSettings = _b.useSaveUserQuickMenuSettings;
    var settings = useGetUserQuickMenuSettings().data;
    var _c = useState({
        collections: [],
        timeClock: false,
    }), userMenuSettings = _c[0], setUserMenuSettings = _c[1];
    var _d = useState(false), userMenuSettingsVisible = _d[0], setUserMenuSettingsVisible = _d[1];
    var userMenuSettingsRef = useRef(userMenuSettings);
    useEffect(function () {
        userMenuSettingsRef.current = userMenuSettings;
    }, [userMenuSettings]);
    var _e = useRecoilState(isQuickCreateModalVisibleState), isQuickCreateModalVisible = _e[0], setQuickCreateModalVisible = _e[1];
    var setQuickCreateModalCollectionInfo = useSetRecoilState(quickCreateModalCollectionInfoState);
    useEffect(function () {
        if (settings === null || settings === void 0 ? void 0 : settings.quickMenuSettings)
            setUserMenuSettings(settings.quickMenuSettings);
    }, [settings]);
    var toggleCollection = useCallback(function (collectionId) {
        var tempUserMenuSettings = cloneDeep(userMenuSettingsRef.current);
        tempUserMenuSettings.collections = toggleList(tempUserMenuSettings.collections, collectionId);
        setUserMenuSettings(tempUserMenuSettings);
        useSaveUserQuickMenuSettings.mutate({
            collections: tempUserMenuSettings.collections,
            timeClock: tempUserMenuSettings.timeClock,
        });
    }, [useSaveUserQuickMenuSettings]);
    var toggleTimeClock = useCallback(function () {
        var tempUserMenuSettings = cloneDeep(userMenuSettingsRef.current);
        tempUserMenuSettings.timeClock = !userMenuSettings.timeClock;
        setUserMenuSettings(tempUserMenuSettings);
        useSaveUserQuickMenuSettings.mutate({
            collections: tempUserMenuSettings.collections,
            timeClock: tempUserMenuSettings.timeClock,
        });
    }, [useSaveUserQuickMenuSettings, userMenuSettings]);
    var quickCreateMenuItems = useMemo(function () {
        var items = [];
        userMenuSettings.collections.map(function (collectionId, index) {
            var collection = collectionOptions.find(function (option) { return option.id === collectionId; });
            var isLastItem = index === userMenuSettings.collections.length - 1;
            if (collection && collection.isEditable) {
                items.push({
                    key: collectionId,
                    label: collection.name,
                    onClick: function () {
                        setQuickCreateModalCollectionInfo({
                            collectionId: collectionId,
                            collectionName: collection.name,
                            collectionType: collection.type,
                        });
                        setQuickCreateModalVisible(true);
                    },
                });
                if (!isLastItem)
                    items.push({ key: 'divider', type: 'divider' });
            }
        });
        return items;
    }, [
        collectionOptions,
        setQuickCreateModalCollectionInfo,
        setQuickCreateModalVisible,
        userMenuSettings.collections,
    ]);
    return (_jsxs("div", { className: "QuickCreateMenuWrapper", children: [_jsxs("div", { className: "MenuWrapper", children: [userMenuSettings.timeClock && _jsx(TimeClockWidget, {}), userMenuSettings.collections.length > 1 && (_jsx(Dropdown, { className: "AddNewObjectQC", placement: "bottomRight", menu: { items: quickCreateMenuItems }, children: _jsxs("div", { className: "NewCollectionItemBtn", children: [_jsx(PlusOutlined, { className: "plusIcon" }), _jsx("span", { className: "newCollectionText", children: "New Object" })] }) })), userMenuSettings.collections.length === 1 && (_jsxs("div", { className: "NewCollectionItemBtn", onClick: function () {
                            var collectionId = userMenuSettings.collections[0];
                            var collection = collectionOptions.find(function (option) { return option.id === collectionId; });
                            if (!collection || !collection.isEditable)
                                return;
                            setQuickCreateModalCollectionInfo({
                                collectionId: collectionId,
                                collectionName: collection.name,
                                collectionType: collection.type,
                            });
                            setQuickCreateModalVisible(true);
                        }, children: [_jsx(PlusOutlined, { className: "plusIcon" }), _jsxs("span", { className: "newCollectionText", children: ["New", ' ', (_a = collectionOptions.find(function (option) { return option.id === userMenuSettings.collections[0]; })) === null || _a === void 0 ? void 0 : _a.name] })] })), _jsx(Dropdown, { className: "h-min", open: userMenuSettingsVisible, onOpenChange: setUserMenuSettingsVisible, overlay: _jsxs(Menu, { onClick: function (e) {
                                e.domEvent.stopPropagation();
                                e.domEvent.preventDefault();
                            }, children: [_jsx("h1", { className: "qcMenuHeader", children: "Quick Create" }), collectionOptions.map(function (option) {
                                    var canEdit = option.isEditable;
                                    if (!canEdit)
                                        return null;
                                    return (_jsxs(React.Fragment, { children: [_jsx(Menu.Item, { onClick: function () {
                                                    toggleCollection(option.id);
                                                }, children: _jsxs("span", { className: "qcMenuItemWrapper", children: [_jsx("div", { className: "optionName", children: option.name }), _jsx("span", { className: "eyeIcon", children: userMenuSettings.collections.includes(option.id) ? (_jsx(VisibleEyeIcon, {})) : (_jsx(InvisibleEyeIcon, {})) })] }) }), _jsx(Menu.Divider, {})] }, option.id));
                                }), _jsx("h1", { className: "qcMenuHeader", children: "Widgets" }), _jsx(Menu.Item, { onClick: function () { return toggleTimeClock(); }, children: _jsxs("span", { className: "qcMenuItemWrapper", children: [_jsx("div", { className: "optionName", children: "Time Clock" }), _jsx("span", { className: "eyeIcon", children: userMenuSettings.timeClock ? (_jsx(VisibleEyeIcon, {})) : (_jsx(InvisibleEyeIcon, {})) })] }) })] }), trigger: ['click'], placement: "bottomRight", children: _jsx("div", { className: "MenuSettings", children: _jsx(GridIcon, {}) }) })] }), isQuickCreateModalVisible && _jsx(QuickCreateModalWrapper, {})] }));
};
export default React.memo(QuickCreateMenu);
