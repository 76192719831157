import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
var LocationFiltersWarningContent = function (_a) {
    var noData = _a.noData;
    var content = useMemo(function () {
        return noData ? (_jsxs(_Fragment, { children: ["No Data", _jsx("br", {}), "Please make sure you have one location filter applied and have your ratio setting set up.", _jsx("br", {})] })) : (_jsxs(_Fragment, { children: ["There are multiple location filters applied to this schedule,", _jsx("br", {}), "which is causing error matching the correct census data.", _jsx("br", {}), "Please make sure you only have one location filter applied."] }));
    }, [noData]);
    return (_jsx("div", { className: "flex h-[150px] flex-col justify-center", children: _jsx("div", { className: "text-center text-base font-medium text-gray-300", children: content }) }));
};
export default React.memo(LocationFiltersWarningContent);
