import { TaskTemplateType } from 'src/components/Settings/Tasks/interface';
export var getTaskTypeDisplayText = function (val) {
    switch (val) {
        case TaskTemplateType.EXTERNAL_LINK:
            return 'External Link ';
        case TaskTemplateType.PROFILE_DATA:
            return 'Profile Data ';
        case TaskTemplateType.SIGNING_PARTNER:
            return 'Document Signing ';
        case TaskTemplateType.VIEW_ONLY:
            return 'View Only ';
        case TaskTemplateType.WORKSPACE_LINK:
            return 'Workspace Link ';
        default:
            return "".concat(val, " ");
    }
};
