var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse, Divider, Popconfirm, Spin } from 'antd';
import { useShiftRecommendationsApi } from 'src/hooks/api/useShiftRecommendationsApi';
import { FixedSizeList } from 'react-window';
import { RecommendShiftIcon } from 'src/utils/icons/SchedulingIcons/RecommendShiftIcon';
import ShiftRecommendationsUserItem from './ShiftRecommendationsUserItem';
import { SHIFT_RECOMMENDATIONS_RECOMMENDATION_BUTTON_WIDTH, SHIFT_RECOMMENDATIONS_USER_ITEM_HEIGHT, SHIFT_RECOMMENDATION_GROUP_PAGE_SIZE, ShiftRecommendationGroupDisplayNameMap, } from './constant';
var Panel = Collapse.Panel;
var ShiftRecommendationsGroup = function (_a) {
    var groupType = _a.groupType, groupCount = _a.groupCount, baseRequest = _a.baseRequest, canRecommend = _a.canRecommend, isDefaultOpen = _a.isDefaultOpen, refetchCounts = _a.refetchCounts;
    var _b = useState([]), users = _b[0], setUsers = _b[1];
    var _c = useState(isDefaultOpen ? groupType : undefined), activePanelKey = _c[0], setActivePanelKey = _c[1];
    var _d = useState(__assign(__assign({}, baseRequest), { groupType: groupType, pagination: {
            page: 0,
            size: SHIFT_RECOMMENDATION_GROUP_PAGE_SIZE,
        } })), requestBody = _d[0], setRequestBody = _d[1];
    var page = requestBody.pagination.page;
    var groupTitle = useMemo(function () { return ShiftRecommendationGroupDisplayNameMap[groupType]; }, [groupType]);
    var _e = useShiftRecommendationsApi(), useGetShiftRecommendationEvaluations = _e.useGetShiftRecommendationEvaluations, useRecommendShiftToUserGroup = _e.useRecommendShiftToUserGroup, getShiftRecommendationEvaluations = _e.getShiftRecommendationEvaluations;
    var _f = useGetShiftRecommendationEvaluations({
        body: requestBody,
        options: {
            enabled: !!activePanelKey || !!requestBody.pagination.page,
        },
    }), data = _f.data, isFetching = _f.isFetching;
    // Set users
    useEffect(function () {
        if (data) {
            if (page === 0) {
                setUsers(data.userEvaluations);
            }
            else {
                setUsers(function (prev) { return __spreadArray(__spreadArray([], prev, true), data.userEvaluations, true); });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    // Reset page when filters or query changes
    useEffect(function () {
        setRequestBody(__assign(__assign({}, baseRequest), { groupType: groupType, pagination: { page: 0, size: SHIFT_RECOMMENDATION_GROUP_PAGE_SIZE } }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseRequest.filters, baseRequest.query]);
    var refetchGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    refetchCounts();
                    return [4 /*yield*/, getShiftRecommendationEvaluations(__assign(__assign({}, baseRequest), { groupType: groupType, pagination: {
                                page: 0,
                                size: users.length,
                            } }))];
                case 1:
                    data = _a.sent();
                    if (data) {
                        setUsers(data.userEvaluations);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [baseRequest, getShiftRecommendationEvaluations, groupType, refetchCounts, users.length]);
    var recommendShiftToUserGroup = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!canRecommend)
                return [2 /*return*/];
            useRecommendShiftToUserGroup.mutateAsync(__assign(__assign({}, baseRequest), { groupType: groupType }), {
                onSuccess: function () {
                    refetchGroup();
                },
            });
            return [2 /*return*/];
        });
    }); }, [baseRequest, canRecommend, groupType, refetchGroup]);
    var onLoadMoreUsers = useCallback(function () {
        setRequestBody(function (prev) { return (__assign(__assign({}, prev), { pagination: {
                page: prev.pagination.page + 1,
                size: SHIFT_RECOMMENDATION_GROUP_PAGE_SIZE,
            } })); });
    }, []);
    return (_jsx(Collapse, { activeKey: activePanelKey, onChange: function (key) { return setActivePanelKey(Array.isArray(key) ? key[0] : key); }, ghost: true, children: _jsx(Panel, { header: _jsxs("div", { className: "mb-[8px] flex items-center sticky top-0", children: [_jsxs("div", { className: "text-base font-semibold", children: [groupTitle, " (", groupCount, ")"] }), canRecommend && groupCount > 0 && (_jsx(Popconfirm, { title: _jsxs("div", { className: "text-sm font-medium", children: ["Are you sure you want to recommend this shift to all", ' ', groupCount, " users in ", groupTitle, "?"] }), onConfirm: function (e) {
                            e === null || e === void 0 ? void 0 : e.stopPropagation();
                            recommendShiftToUserGroup();
                        }, onCancel: function (e) { return e === null || e === void 0 ? void 0 : e.stopPropagation(); }, placement: "topRight", children: _jsxs("div", { className: "ShiftRecommendationsTag ml-auto bg-black px-[12px] text-sm text-white", style: {
                                width: SHIFT_RECOMMENDATIONS_RECOMMENDATION_BUTTON_WIDTH,
                            }, onClick: function (e) { return e.stopPropagation(); }, children: [_jsx(RecommendShiftIcon, { className: "mr-auto" }), _jsx("span", { className: "mr-auto pt-[2px]", children: "Send All" })] }) }))] }), children: _jsxs("div", { className: "flex flex-col text-sm", children: [_jsx(FixedSizeList, { height: SHIFT_RECOMMENDATIONS_USER_ITEM_HEIGHT * users.length, itemCount: users.length, itemSize: SHIFT_RECOMMENDATIONS_USER_ITEM_HEIGHT, width: "100%", children: function (_a) {
                            var index = _a.index, style = _a.style;
                            var user = users[index];
                            return (_jsx(ShiftRecommendationsUserItem, { user: user, groupType: groupType, canRecommend: canRecommend, style: style, onRefetchGroup: refetchGroup }, user.recordId));
                        } }), isFetching && _jsx(Spin, {}), users.length < groupCount && (_jsxs("div", { className: "cursor-pointer text-gray-300 underline", onClick: onLoadMoreUsers, children: ["Show", ' ', Math.min(groupCount - users.length, SHIFT_RECOMMENDATION_GROUP_PAGE_SIZE), ' ', "more"] })), users.length === 0 && (_jsxs("div", { className: "font-medium text-gray-300", children: ["No users found", _jsx(Divider, {})] }))] }) }, groupType) }));
};
export default React.memo(ShiftRecommendationsGroup);
