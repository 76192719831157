import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import React from 'react';
import { usePageApi } from '../../hooks/api';
import { BlockHoverOptionButton } from '../OptimizedTableView/OptionHeader/TableOptionHeaderFromBlock';
import ReportBlock from './ReportBlock';
var ReportBlockWrapper = function (_a) {
    var _b;
    var blockId = _a.blockId, contentType = _a.contentType, openRecordDetail = _a.openRecordDetail;
    var useListBlockQuery = usePageApi().useListBlockQuery;
    var data = useListBlockQuery({
        blockId: blockId,
        contentType: contentType,
    }).data;
    return !((_b = data === null || data === void 0 ? void 0 : data[0]) === null || _b === void 0 ? void 0 : _b.report) ? (_jsxs("div", { className: "BlockTableWrapper", children: [_jsx(BlockHoverOptionButton, { blockId: blockId }), _jsx(Skeleton, { active: true })] })) : (_jsxs("div", { className: "BlockTableWrapper", children: [_jsx(BlockHoverOptionButton, { blockId: blockId }), _jsx(ReportBlock, { blockId: blockId, reportId: data[0].report.reportId, openRecordDetail: openRecordDetail })] }));
};
export default React.memo(ReportBlockWrapper);
