import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense } from 'react';
import DummyTagCell from '../DummyTagCell';
import DummyTextCell from '../DummyTextCell';
var LogicTextCell = lazy(function () { return import('./LogicTextCell'); });
var TextCellHOC = function (props) {
    var text = props.text, editCell = props.editCell, cellPos = props.cellPos, canEdit = props.canEdit, color = props.color, isClicked = props.isClicked, setIsClicked = props.setIsClicked, isModalCell = props.isModalCell;
    var dummyCell = (_jsxs(_Fragment, { children: [!isClicked && !color && (_jsx(DummyTextCell, { text: text, setIsClicked: setIsClicked, canEdit: canEdit })), !isClicked && color && (_jsx(DummyTagCell, { tags: [{ id: 'title', text: text, color: color }], cellPos: cellPos, setIsClicked: setIsClicked, canEdit: canEdit }))] }));
    return (_jsxs(_Fragment, { children: [isClicked && canEdit && (_jsx(Suspense, { fallback: dummyCell, children: _jsx(LogicTextCell, { text: text, editCell: editCell, isClicked: isClicked, setIsClicked: setIsClicked, cellPos: cellPos, isModalCell: isModalCell }) })), dummyCell] }));
};
export default React.memo(TextCellHOC);
