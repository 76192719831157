var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useScheduleComponent, useTemplateComponent } from 'src/hooks/component';
import { scheduleDropTargetState, scheduleEvaluationState, tableSchedulingDisplaySettingsState, scheduleTemplateDrawerVisibleState, scheduleRulesDrawerVisibleState, isCurrentPageCanvasTypeSelector, blockTimeZoneState, tableCollectionIdSelector, scheduleGroupBySelector, shiftTemplateDetailModalVisibleState, selectedShiftTemplateIdState, ifUserHasGeneralPermission, scheduleTemplateConfirmVisibleState, tableDisplaySettingsState, } from 'src/state';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ScheduleView } from 'src/services/ScheduleService';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import Emitter from 'src/utils/EventEmitter';
import { disableScroll } from 'src/utils/Collection';
import { useShiftTemplateComponent } from 'src/hooks/component/useShiftTemplate';
import { cloneDeep } from 'lodash';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { useScheduleTemplateApi } from 'src/hooks/api';
import TemplatePropagationModal from 'src/components/ScheduleView/TemplatePropagationModal/TemplatePropagationModal';
import { EmitterEventType, SCHEDULE_UNASSIGNED_KEY, ScheduleGroupBy } from '../../shared';
import { getAccountStartDayOfWeek, getWeekDayNumber } from '../../utils/Account';
import { GeneralPermissionKey } from '../Settings/Permission/Constants';
import { NEW_SHIFT_TEMPLATE, TEMPLATE_PROPAGATION_MODAL } from '../FeatureGatingKeyConstant';
import ScheduleColumnGroupHeader from './ScheduleColumnGroupHeader';
import './ScheduleTable.scss';
import RulesDrawer from './RulesDrawer';
import NewShiftTemplateDrawer from './NewShiftTemplate/NewShiftTemplateDrawer';
import ClearSelectedButton from './ClearSelectedButton';
import ShiftTemplateDetailModal from './NewShiftTemplate/ShiftTemplateDetailModal';
import TemplateDrawer from './TemplateDrawer';
import TemplateConfirmModal from './TemplateConfirmModal';
import { shiftTemplateListRespState } from './NewShiftTemplate/Atom';
import ScheduleTableCalendarView from './CalendarView/ScheduleTableCalendarView';
import ScheduleBlockNonMonthViewWrapper from './TableView/ScheduleBlockNonMonthViewWrapper';
import ScheduleReverseNonMonthViewWrapper from './TableView/ScheduleReverseNonMonthViewWrapper';
var ScheduleTable = function (props) {
    var tableId = props.tableId, type = props.type, tableDataSource = props.tableDataSource, addNewShift = props.addNewShift, openShiftDetailModal = props.openShiftDetailModal;
    var applyShiftTemplate = useShiftTemplateComponent().applyShiftTemplate;
    var applyTemplate = useTemplateComponent(tableId).applyTemplate;
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var isTemplatePropagationModalEnabled = useRecoilValue(featureEnableStatusState(TEMPLATE_PROPAGATION_MODAL));
    var changeCalView = useScheduleComponent().changeCalView;
    var scheduleEvaluation = useRecoilValue(scheduleEvaluationState(tableId));
    var viewDisplaySettings = useRecoilValue(tableSchedulingDisplaySettingsState(tableId));
    var _a = useMemo(function () { return viewDisplaySettings; }, [viewDisplaySettings]), scheduleView = _a.viewType, blockTimeZone = _a.timezone;
    var collectionId = useRecoilValue(tableCollectionIdSelector(tableId));
    // Update time range on start day of week change
    useEffect(function () {
        var _a;
        moment.updateLocale(tableId, {
            week: {
                dow: getWeekDayNumber((_a = viewDisplaySettings.startDayOfWeek) !== null && _a !== void 0 ? _a : getAccountStartDayOfWeek()),
            },
        });
        changeCalView(tableId, scheduleView, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewDisplaySettings.startDayOfWeek]);
    // Sync to block time zone state
    var setBlockTimeZone = useSetRecoilState(blockTimeZoneState(tableId));
    useEffect(function () {
        if (blockTimeZone) {
            setBlockTimeZone(blockTimeZone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockTimeZone]);
    var componentRef = useRef(null);
    var _b = useRecoilState(scheduleTemplateDrawerVisibleState(tableId)), templateDrawerVisible = _b[0], setTemplateDrawerVisible = _b[1];
    var _c = useRecoilState(scheduleTemplateConfirmVisibleState(tableId)), templateConfirmVisible = _c[0], setTemplateConfirmVisible = _c[1];
    var _d = useRecoilState(scheduleRulesDrawerVisibleState(tableId)), rulesDrawerVisible = _d[0], setRulesDrawerVisible = _d[1];
    var onApplyOldTemplate = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (template, colKey, rowKey) {
            var release = snapshot.retain();
            try {
                var dropTarget_1 = snapshot
                    .getLoadable(scheduleDropTargetState(tableId))
                    .valueMaybe();
                var column = colKey || (dropTarget_1 === null || dropTarget_1 === void 0 ? void 0 : dropTarget_1.columnKey);
                if (!column)
                    return;
                var recordId = undefined;
                var dropRecordIdx = tableDataSource.findIndex(function (r) { return r.groupByRow.rowKey === (rowKey || (dropTarget_1 === null || dropTarget_1 === void 0 ? void 0 : dropTarget_1.recordKey)); });
                if (dropRecordIdx < 0)
                    return;
                var tempDropRecordIndex = cloneDeep(dropRecordIdx);
                recordId =
                    tableDataSource[tempDropRecordIndex].groupByRow.rowKey ===
                        SCHEDULE_UNASSIGNED_KEY
                        ? undefined
                        : tableDataSource[tempDropRecordIndex].groupByRow.rowKey;
                applyTemplate(template.id, column, tableId, recordId);
            }
            finally {
                release();
            }
        };
    }, [applyTemplate, tableId, tableDataSource]);
    var onApplyTemplate = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (template, colKey, recordId) {
            var release = snapshot.retain();
            try {
                var dropTarget = snapshot
                    .getLoadable(scheduleDropTargetState(tableId))
                    .valueMaybe();
                var column = colKey || (dropTarget === null || dropTarget === void 0 ? void 0 : dropTarget.columnKey);
                if (!column)
                    return;
                applyShiftTemplate(template.id, column, tableId, recordId);
            }
            finally {
                release();
            }
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId, tableDataSource]);
    var refetch = useRefetchCollection();
    useEffect(function () {
        var refreshSchedule = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                refetch(collectionId);
                return [2 /*return*/];
            });
        }); };
        Emitter.on(EmitterEventType.REFRESH_SCHEDULE, refreshSchedule);
        return function () {
            Emitter.off(EmitterEventType.REFRESH_SCHEDULE, refreshSchedule);
        };
    });
    var onBlur = useCallback(function () {
        disableScroll(false);
    }, []);
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var groupBy = useRecoilValue(scheduleGroupBySelector(tableId));
    var _e = useRecoilState(shiftTemplateDetailModalVisibleState), isTemplateDetailModalVisible = _e[0], setIsTemplateDetailModalVisible = _e[1];
    var setSelectedTemplateId = useSetRecoilState(selectedShiftTemplateIdState);
    var ifUserHasTemplatePermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.SCHEDULE_TEMPLATE));
    var _f = useState(undefined), fromTemplateId = _f[0], setFromTemplateId = _f[1];
    var useGetAllShiftTemplates = useScheduleTemplateApi().useGetAllShiftTemplates;
    var schedulingDisplaySettings = useRecoilValue(tableDisplaySettingsState(tableId));
    var templateListResp = useGetAllShiftTemplates({
        body: { filters: schedulingDisplaySettings.filters },
        options: { enabled: true },
    }).data;
    var setTemplateListResp = useSetRecoilState(shiftTemplateListRespState(tableId));
    useEffect(function () {
        if (templateListResp) {
            setTemplateListResp(templateListResp);
        }
    }, [setTemplateListResp, templateListResp]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "BlockTableWrapper ".concat(isCanvasPageType || type === 'reverseCollection'
                    ? 'ScheduleTableWrapper'
                    : 'SchedulePageTableWrapper'), id: "ScheduleTableOverFlow", ref: componentRef, tabIndex: 0, onBlur: onBlur, children: [_jsx(ScheduleColumnGroupHeader, { blockId: tableId, type: type, isTemplateDrawerOpen: templateDrawerVisible, isRulesDrawerOpen: rulesDrawerVisible, openTemplateDrawer: function () {
                            setTemplateDrawerVisible(!templateDrawerVisible);
                            if (rulesDrawerVisible)
                                setRulesDrawerVisible(false);
                        }, openRulesDrawer: function () {
                            setRulesDrawerVisible(!rulesDrawerVisible);
                            if (templateDrawerVisible)
                                setTemplateDrawerVisible(false);
                        } }), scheduleView !== ScheduleView.MONTH && type === 'block' && (_jsx(ScheduleBlockNonMonthViewWrapper, __assign({ dataSource: tableDataSource, onApplyTemplate: onApplyTemplate, onApplyOldTemplate: onApplyOldTemplate, componentRef: componentRef, blockId: tableId }, props))), scheduleView !== ScheduleView.MONTH && type === 'reverseCollection' && (_jsx(ScheduleReverseNonMonthViewWrapper, __assign({ dataSource: tableDataSource, onApplyTemplate: onApplyTemplate, onApplyOldTemplate: onApplyOldTemplate, componentRef: componentRef, collectionComponent: tableId }, props))), scheduleView === ScheduleView.MONTH && (_jsx(ScheduleTableCalendarView, { tableId: tableId, addNewShift: addNewShift, openShiftDetailModal: openShiftDetailModal, type: type })), rulesDrawerVisible && (_jsx("div", { className: "DrawerWrapper", children: _jsx(RulesDrawer, { constraintMatchSummary: scheduleEvaluation === null || scheduleEvaluation === void 0 ? void 0 : scheduleEvaluation.constraintMatchSummary, shiftCounts: scheduleEvaluation
                                ? __assign(__assign({}, scheduleEvaluation.shiftCounts), { validShiftCount: scheduleEvaluation.shiftCounts.totalShiftCount -
                                        scheduleEvaluation.indictments.shiftIndictments
                                            .length }) : undefined, closeDrawer: function () { return setRulesDrawerVisible(false); } }) })), templateDrawerVisible && (_jsx("div", { className: "DrawerWrapper", id: "TemplateDrawerWrapper", children: isNewShiftTemplateEnabled ? (_jsx(NewShiftTemplateDrawer, { closeDrawer: function () {
                                setTemplateDrawerVisible(false);
                                // Refetch schedule data if group by template, in case user create new template or update template color
                                if (groupBy === ScheduleGroupBy.TEMPLATE) {
                                    Emitter.emit(EmitterEventType.REFRESH_SCHEDULE, null);
                                }
                            }, blockId: tableId, collectionId: collectionId })) : (_jsx(TemplateDrawer, { closeDrawer: function () { return setTemplateDrawerVisible(false); }, onDragTemplateEnd: onApplyOldTemplate, blockId: tableId })) })), isTemplateDetailModalVisible && ifUserHasTemplatePermission && (_jsx(ShiftTemplateDetailModal, { collectionId: collectionId, isModalVisible: isTemplateDetailModalVisible, closeModal: function (saved) {
                            setIsTemplateDetailModalVisible(false);
                            setSelectedTemplateId(undefined);
                            if (saved) {
                                setFromTemplateId(saved);
                            }
                        } })), isTemplatePropagationModalEnabled && fromTemplateId !== undefined && (_jsx(TemplatePropagationModal, { closeModal: function () { return setFromTemplateId(undefined); }, fromTemplateId: fromTemplateId, timezone: blockTimeZone }))] }), templateConfirmVisible && (_jsx(TemplateConfirmModal, { isModalVisible: templateConfirmVisible, setIsModalVisible: function (visible) {
                    setTemplateConfirmVisible(visible);
                }, blockId: tableId })), _jsx(ClearSelectedButton, { blockId: tableId })] }));
};
export default React.memo(ScheduleTable);
