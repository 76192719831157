var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { availableMetricsDataState } from 'src/state';
import useMetricBarApi from '../api/useMetricBarApi';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useMetricsBar = function () {
    var _a = useMetricBarApi(), getAvailableMetricsApi = _a.getAvailableMetrics, addMetricBarEntryApi = _a.addMetricBarEntry, updateMetricBarEntryApi = _a.updateMetricBarEntry, deleteMetricBarEntryApi = _a.deleteMetricBarEntry, recalculateMetricBarApi = _a.recalculateMetricBar;
    var setAvailableMetrics = useSetRecoilState(availableMetricsDataState);
    var getAvailableMetrics = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var staticMetrics, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAvailableMetricsApi()];
                case 1:
                    staticMetrics = _a.sent();
                    if (staticMetrics) {
                        data = {
                            staticMetrics: staticMetrics,
                            dynamicMetrics: [],
                        };
                        setAvailableMetrics(data);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [getAvailableMetricsApi]);
    var addMetricBarEntry = useCallback(function (body) { return __awaiter(void 0, void 0, void 0, function () {
        var metricBar;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, addMetricBarEntryApi(body)];
                case 1:
                    metricBar = _a.sent();
                    if (metricBar) {
                        //TODO: update state
                        // setPageMetricBarBlocksWithImmer(body.blockId, metricBar)
                        return [2 /*return*/, metricBar];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [addMetricBarEntryApi]);
    var updateMetricBarEntry = useCallback(function (body) { return __awaiter(void 0, void 0, void 0, function () {
        var metricBar;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateMetricBarEntryApi(body)];
                case 1:
                    metricBar = _a.sent();
                    if (metricBar) {
                        // TODO: update state
                        // setPageMetricBarBlocksWithImmer(body.blockId, metricBar)
                        return [2 /*return*/, metricBar];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [updateMetricBarEntryApi]);
    var deleteMetricBarEntry = useCallback(function (_blockId, metricId, _updatedMetricBar) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deleteMetricBarEntryApi(metricId)
                        //TODO: update state
                        // updateMetricState(blockId, updateMetricBarEntry)
                    ];
                case 1:
                    _a.sent();
                    //TODO: update state
                    // updateMetricState(blockId, updateMetricBarEntry)
                    return [2 /*return*/, true];
                case 2:
                    error_1 = _a.sent();
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [deleteMetricBarEntryApi]);
    var recalculateMetricBar = useCallback(function (blockId) { return __awaiter(void 0, void 0, void 0, function () {
        var metricBar;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, recalculateMetricBarApi(blockId)];
                case 1:
                    metricBar = _a.sent();
                    if (metricBar) {
                        // TODO: update state
                        // setPageMetricBarBlocksWithImmer(blockId, metricBar)
                        return [2 /*return*/, metricBar];
                    }
                    return [2 /*return*/, null];
            }
        });
    }); }, [recalculateMetricBarApi]);
    return {
        getAvailableMetrics: getAvailableMetrics,
        addMetricBarEntry: addMetricBarEntry,
        updateMetricBarEntry: updateMetricBarEntry,
        deleteMetricBarEntry: deleteMetricBarEntry,
        recalculateMetricBar: recalculateMetricBar,
    };
};
export { useMetricsBar as useMetricsBarComponent };
