var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useAccountApi } from 'src/hooks/api';
import { useCellHook, usePaginationWithSearch } from 'src/hooks/component';
import { getAvailableSelectionOptionsForApprovalStatus } from 'src/services';
import { isNativeBooleanOrSpecialType, ValueDataType, NativePath, SELECTION_DROPDOWN_PAGE_SIZE, } from 'src/shared';
import { collectionTableSchemaSelector } from 'src/state';
import LogicSelectionCell from './LogicSelectionCell';
var LogicSelectionCellWrapper = function (_a) {
    var selections = _a.selections, editCell = _a.editCell, cellPos = _a.cellPos, blockId = _a.blockId, setIsClicked = _a.setIsClicked, fetchType = _a.fetchType;
    var _b = useState(selections), selectionOptions = _b[0], setSelectionOptions = _b[1];
    var _c = useState([]), availableOptions = _c[0], setAvailableOptions = _c[1];
    var _d = useState(false), selectedAll = _d[0], setSelectedAll = _d[1];
    useEffect(function () {
        setSelectionOptions(selections);
    }, [selections]);
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var _e = useCellHook(cellPos), mutateSelectorColumnOption = _e.mutateSelectorColumnOption, addSelectorColumnOption = _e.addSelectorColumnOption, deleteSelectorColumnOption = _e.deleteSelectorColumnOption, reorderSelectorColumnOptions = _e.reorderSelectorColumnOptions;
    var schema = useRecoilValue(collectionTableSchemaSelector(cellPos));
    var _f = usePaginationWithSearch({}), page = _f.page, searchText = _f.searchText, canLoadMore = _f.canLoadMore, setCanLoadMore = _f.setCanLoadMore, onScroll = _f.onScroll, onSearch = _f.onSearch, setIsLoading = _f.setIsLoading;
    var isFetching = useGetUniversalSimpleList({
        body: {
            schemaId: cellPos.schemaId,
            pagination: selectedAll
                ? undefined
                : {
                    page: page,
                    size: SELECTION_DROPDOWN_PAGE_SIZE,
                },
            query: searchText,
        },
        options: {
            enabled: !isNativeBooleanOrSpecialType(schema.type) &&
                (!schema.refCollectionId || canLoadMore),
            onSuccess: function (data) {
                if (data) {
                    var newData = data.map(function (val) {
                        return {
                            id: val.id,
                            text: val.name,
                            color: val.color || '',
                            userAvatar: val.image,
                        };
                    });
                    if (selectedAll) {
                        setAvailableOptions(newData);
                        setCanLoadMore(false);
                        return;
                    }
                    if (page === 0) {
                        setAvailableOptions(newData);
                    }
                    else {
                        setAvailableOptions(function (prev) {
                            return uniqBy(__spreadArray(__spreadArray([], prev, true), data.map(function (val) {
                                return {
                                    id: val.id,
                                    text: val.name,
                                    color: val.color || '',
                                    userAvatar: val.image,
                                };
                            }), true), 'id');
                        });
                    }
                    if (canLoadMore) {
                        setCanLoadMore(data.length > 0);
                    }
                }
            },
            onSettled: function () {
                setIsLoading(false);
            },
        },
    }, schema.refCollectionId).isFetching;
    useEffect(function () {
        if (isNativeBooleanOrSpecialType(schema.type)) {
            var opts = void 0;
            if (schema.type === ValueDataType.NATIVE_BOOL) {
                opts = [
                    { id: 'true', text: 'Yes', color: '#E5F4EF' },
                    { id: 'false', text: 'No', color: '#FFEDEC' },
                ];
            }
            else if (schema.nativePath == NativePath.TIMEOFF_APPROVAL_STATUS) {
                opts = getAvailableSelectionOptionsForApprovalStatus().map(function (opt) { return ({
                    id: opt.id,
                    text: opt.label,
                    color: opt.color || '',
                }); });
            }
            if (!opts)
                return;
            setAvailableOptions(opts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema, schema.type, schema.values]);
    var updateSelections = useCallback(function (newValues) {
        setSelectionOptions(newValues);
    }, []);
    return (_jsx("div", { className: "SelectorCellWrapper", children: _jsx(LogicSelectionCell, { cellPos: cellPos, displayedOption: selectionOptions, availableOption: availableOptions, isUserSelecting: fetchType == 'users', mutateColumnOption: function (optionId, newName, newColor) {
                return mutateSelectorColumnOption(blockId, availableOptions, optionId, newName, newColor);
            }, addColumnOption: addSelectorColumnOption, deleteColumnOption: function (optionId) {
                return deleteSelectorColumnOption(blockId, optionId);
            }, reorderColumnOptions: function (optionIds) {
                return reorderSelectorColumnOptions(blockId, optionIds);
            }, editCell: function (newValues) {
                editCell(newValues);
                updateSelections(newValues);
            }, setIsClicked: setIsClicked, paginationProps: schema.refCollectionId
                ? {
                    onScroll: onScroll,
                    onSearch: onSearch,
                }
                : undefined, isLoading: isFetching, selectedAll: selectedAll, setSelectedAll: setSelectedAll }) }));
};
export default React.memo(LogicSelectionCellWrapper);
