import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { scheduleCardHeightSettingSelector } from 'src/state';
import { useRecoilValue } from 'recoil';
import { useScheduleComponent } from 'src/hooks/component';
import { Radio, Space } from 'antd';
import { ScheduleCardHeight } from 'src/services';
import ZiraPopover from 'src/ui/ZiraPopover';
var CardHeightOptionPopover = function (_a) {
    var tableId = _a.tableId, type = _a.type, children = _a.children;
    var cardDensity = useRecoilValue(scheduleCardHeightSettingSelector(tableId));
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var content = useMemo(function () {
        return (_jsx(Radio.Group, { className: "w-[140px] text-base font-medium", onChange: function (event) {
                return applyScheduleDisplaySettings(tableId, { cardHeight: event.target.value }, type);
            }, value: cardDensity, children: _jsxs(Space, { direction: "vertical", children: [_jsx(Radio, { value: ScheduleCardHeight.COMPACT, children: "Compact" }), _jsx(Radio, { value: ScheduleCardHeight.DEFAULT, children: "Normal" }), _jsx(Radio, { value: ScheduleCardHeight.COMFORTABLE, children: "Comfortable" })] }) }));
    }, [applyScheduleDisplaySettings, cardDensity, tableId, type]);
    return (_jsx(ZiraPopover, { placement: "leftTop", content: content, trigger: ['click'], children: children }));
};
export default React.memo(CardHeightOptionPopover);
