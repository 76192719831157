import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import './FlatFileCustomSchemaImportWarningModal.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
var FlatFileCustomSchemaImportWarningModal = function (props) {
    var isModalVisible = props.isModalVisible, onModalClose = props.onModalClose, onLaunchFlatFile = props.onLaunchFlatFile, fields = props.fields;
    var modalFooter = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "CancelBtn", onClick: onModalClose, children: "Cancel" }), _jsx(Button, { className: "ConfirmImportBtn", onClick: function () {
                    onModalClose();
                    onLaunchFlatFile();
                }, children: "Import Anyway" })] })); }, [onLaunchFlatFile, onModalClose]);
    return (_jsxs(Modal, { open: isModalVisible, onCancel: function () {
            onModalClose();
            onLaunchFlatFile();
        }, footer: modalFooter, centered: true, closable: false, destroyOnClose: true, className: "FlatFileCustomSchemaImportWarningModal", maskClosable: false, children: [_jsxs("div", { className: "flex items-center justify-between rounded bg-yellow-50 p-4", children: [_jsx(ExclamationCircleOutlined, { style: {
                            color: '#FFC047',
                            fontSize: '24px',
                            stroke: '#FFC047',
                            strokeWidth: '10',
                        }, size: 24 }), _jsx("div", { className: "ml-3 text-sm font-medium", children: "The following columns contain an invalid symbol (#, &) in their name. These columns will be ignored and missing from your FlatFile import fields." })] }), _jsx("div", { className: "mt-5 pl-3", children: fields.map(function (field) { return (_jsxs("div", { className: "mb-2 text-sm font-medium", children: [_jsx("span", { className: "mr-2", children: "\u2022" }), field.label] }, field.field)); }) })] }));
};
export default React.memo(FlatFileCustomSchemaImportWarningModal);
