var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var WarningIconSvg = function () { return (_jsx("svg", { width: "32", height: "36", viewBox: "0 0 32 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M30.4375 5.97656C25.7766 5.97656 21.236 4.32547 16.7875 0.989063C16.3208 0.639063 15.6792 0.639063 15.2125 0.989063C10.7639 4.32547 6.22347 5.97656 1.5625 5.97656C0.837633 5.97656 0.25 6.5642 0.25 7.28906V16.4766C0.25 25.2287 5.42573 31.659 15.5189 35.6352C15.8281 35.757 16.1719 35.757 16.4811 35.6352C26.5742 31.659 31.75 25.2287 31.75 16.4766V7.28906C31.75 6.5642 31.1623 5.97656 30.4375 5.97656ZM14.6994 10.5797C14.7864 9.93904 15.3355 9.44526 16 9.44526C16.6645 9.44526 17.2136 9.93904 17.3006 10.5797L17.3125 10.7578V22.1327L17.3006 22.3109C17.2136 22.9516 16.6645 23.4452 16 23.4452C15.3355 23.4452 14.7864 22.9516 14.6994 22.3109L14.6875 22.1327V10.7578L14.6994 10.5797ZM16 28.7266C15.0335 28.7266 14.25 27.9431 14.25 26.9766C14.25 26.01 15.0335 25.2266 16 25.2266C16.9665 25.2266 17.75 26.01 17.75 26.9766C17.75 27.9431 16.9665 28.7266 16 28.7266Z", fill: "#FF4A43" }) })); };
export var WarningIcon = function (props) { return (_jsx(Icon, __assign({ component: WarningIconSvg }, props))); };
