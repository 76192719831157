import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { useFileApi } from 'src/hooks/api/useFileApi';
import { usePageApi } from 'src/hooks/api';
import ComponentWithUpload, { UploadFileType } from '../../ComponentWithUpload';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
var VideoBlock = function (props) {
    var blockId = props.blockId, pageId = props.pageId, moveBlockUpDown = props.moveBlockUpDown;
    var acceptedFileVideoType = useMemo(function () { return [UploadFileType.VIDEO]; }, []);
    var handleDeleteFile = useFileApi().deleteFile;
    var _a = usePageApi(), useUpdatePageBlock = _a.useUpdatePageBlock, useGetMediaBlock = _a.useGetMediaBlock;
    var data = useGetMediaBlock({
        blockId: blockId,
        pageId: pageId,
        type: 'video',
    }).data;
    var _b = useState(''), videoUrl = _b[0], setVideoUrl = _b[1];
    useEffect(function () {
        if (data) {
            setVideoUrl(data.url);
        }
    }, [data]);
    var onChangeVideoUrl = useCallback(function (info) {
        var newUrl = info.file.response.data.url;
        if (newUrl && videoUrl) {
            useUpdatePageBlock.mutate({
                blockId: blockId,
                content: {
                    video: newUrl,
                },
            }, {
                onSuccess: function () {
                    var oldUrl = videoUrl;
                    handleDeleteFile(oldUrl);
                    setVideoUrl(newUrl);
                },
            });
        }
    }, [videoUrl, useUpdatePageBlock, blockId, handleDeleteFile]);
    var menuChildren = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(ComponentWithUpload, { acceptedFileType: acceptedFileVideoType, successAction: onChangeVideoUrl, type: UploadUrlType.Block, children: _jsx(Menu.Item, { icon: _jsx(EditOutlined, { className: "EditIcon" }), children: "Replace" }, "replace") }), _jsx(Menu.Divider, {})] }));
    }, [acceptedFileVideoType, onChangeVideoUrl]);
    return (_jsx("div", { className: "ImageBlockWrapper", children: videoUrl && (_jsxs(_Fragment, { children: [_jsx(HoverOptionButtons, { data: {
                        blockId: blockId,
                    }, moveBlockUpDown: moveBlockUpDown, url: videoUrl, menuChildren: menuChildren, isNonTitleBlock: true }), _jsx("video", { className: "shadow-block", src: videoUrl, width: "600", height: "300", controls: true })] })) }));
};
export default React.memo(VideoBlock);
