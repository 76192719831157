var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ExclamationMarkSvg = function () { return (_jsxs("svg", { width: "17", height: "18", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z", fill: "white" }), _jsx("path", { d: "M11 10.5C11 10.7761 10.7761 11 10.5 11H9.5C9.22386 11 9 10.7761 9 10.5V5.5C9 5.22386 9.22386 5 9.5 5H10.5C10.7761 5 11 5.22386 11 5.5V10.5Z", fill: "white" }), _jsx("path", { d: "M11 14.5C11 14.7761 10.7761 15 10.5 15H9.5C9.22386 15 9 14.7761 9 14.5V13.5C9 13.2239 9.22386 13 9.5 13H10.5C10.7761 13 11 13.2239 11 13.5V14.5Z", fill: "white" })] })); };
export var ExclamationMarkIcon = function (props) { return (_jsx(Icon, __assign({ component: ExclamationMarkSvg }, props))); };
