var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DeleteRecordSvg = function () { return (_jsxs("svg", { width: "13", height: "17", viewBox: "0 0 13 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M0.0390625 3.54102L1.61887 16.9996H11.3793L12.9591 3.54102H0.0390625ZM10.1189 15.5831H2.87925L1.6255 4.95814H11.3726L10.1189 15.5831Z", fill: "black" }), _jsx("path", { d: "M12.875 1.41646H8.62498V0.70856H7.20854V0H5.79144V0.70856H4.375V1.41646H0.125V2.83356H12.875L12.875 1.41646Z", fill: "black" }), _jsx("path", { d: "M5.24484 12.5097L6.49859 11.2559L7.75234 12.5097L8.75109 11.5043L7.49734 10.2505L8.75109 9.00344L7.75234 7.99805L6.49859 9.2518L5.24484 7.99805L4.24609 9.00344L5.49984 10.2505L4.24609 11.5043L5.24484 12.5097Z", fill: "black" })] })); };
export var DeleteRecordIcon = function (props) { return (_jsx(Icon, __assign({ component: DeleteRecordSvg }, props))); };
