import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
var IndividualPayCardPrintInformationModal = function (_a) {
    var onClose = _a.onClose, onConfirm = _a.onConfirm;
    var modalFooter = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: onClose, children: "Cancel" }), _jsx(Button, { className: "ZiraModalSaveBtn", onClick: onConfirm, children: "Print" })] }));
    }, [onClose, onConfirm]);
    return (_jsxs(Modal, { open: true, centered: true, footer: modalFooter, closable: false, className: "TimeOffApprovalModalForUnassignShift", width: 500, zIndex: 1101, children: [_jsx("div", { className: "mt-5 font-medium", children: "Are you sure you want to print this pay report card?" }), _jsxs("div", { className: "flex rounded bg-blue-50 text-blue-400 py-1 px-2 mt-3 items-baseline", children: [_jsx(InfoCircleOutlined, { className: "text-blue-400 stroke-blue-400 stroke-[25px]" }), _jsxs("div", { className: "ml-2", children: ["For printing this report card, we only support limited columns for shift and time off list.", _jsx("br", {}), "If you want to export entire shift list, please close this modal and use", ' ', _jsx("span", { className: "font-medium", children: "Export Payroll" }), "."] })] })] }));
};
export default React.memo(IndividualPayCardPrintInformationModal);
