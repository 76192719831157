import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
var DISPLAY_DATE_FORMAT = 'MMM D, YYYY';
var NeedsCoverageDateSelector = function (props) {
    var isStartDate = props.isStartDate, date = props.date, onEditDate = props.onEditDate, allowEmptyDate = props.allowEmptyDate;
    var _a = useState(false), isClicked = _a[0], setIsClicked = _a[1];
    var onDateChange = useCallback(function (date) {
        onEditDate(date, isStartDate);
    }, [isStartDate, onEditDate]);
    return (_jsx("div", { className: "SchemaRow SpecialTimePicker w-full", children: _jsxs("div", { className: "ModalCellWrapper".concat(isClicked ? ' border-modalAccent bg-white' : '', " bg-white"), children: [_jsx("span", { className: "SchemaName", children: "".concat(isStartDate ? 'Start' : 'End', " Date") }), _jsx("div", { className: "CellContent", children: _jsx(DatePicker, { value: date ? moment(date) : undefined, format: DISPLAY_DATE_FORMAT, allowClear: true, suffixIcon: null, bordered: false, onFocus: function () { return setIsClicked(true); }, onBlur: function () { return setIsClicked(false); }, onChange: onDateChange, placeholder: allowEmptyDate ? 'Infinity' : 'Select Date' }) })] }) }));
};
export default React.memo(NeedsCoverageDateSelector);
