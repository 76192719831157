var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Input } from 'antd';
import { SchemaConfigurationType } from 'src/shared';
import ButtonColorSelector from 'src/components/SchemaConfigModal/ButtonSchemaConfig/ButtonColorSelector';
var DEFAULT_TEMPLATE_COLOR = '#FFFFFF';
var ButtonSchemaConfigurationSection = function (_a) {
    var buttonSchemaConfig = _a.buttonSchemaConfig, schemaConfigOnChange = _a.schemaConfigOnChange;
    var formInstance = Form.useForm()[0];
    var _b = useState((buttonSchemaConfig === null || buttonSchemaConfig === void 0 ? void 0 : buttonSchemaConfig.color) || DEFAULT_TEMPLATE_COLOR), currentColor = _b[0], setCurrentColor = _b[1];
    var formInitialValues = useMemo(function () {
        return {
            label: (buttonSchemaConfig === null || buttonSchemaConfig === void 0 ? void 0 : buttonSchemaConfig.title) || 'Untitled Button Label',
        };
    }, [buttonSchemaConfig]);
    var onUpdate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, labelName, setting;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formInstance.validateFields()];
                case 1:
                    formValues = _a.sent();
                    labelName = formValues.label;
                    setting = {
                        type: SchemaConfigurationType.BUTTON,
                        title: labelName,
                        color: currentColor,
                    };
                    schemaConfigOnChange(setting);
                    return [2 /*return*/];
            }
        });
    }); }, [formInstance, currentColor, schemaConfigOnChange]);
    var onChangeLabel = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onUpdate();
            return [2 /*return*/];
        });
    }); }, [onUpdate]);
    useEffect(function () {
        onUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentColor]);
    return (_jsxs(_Fragment, { children: [_jsx(Form, { layout: "vertical", form: formInstance, initialValues: formInitialValues, children: _jsx(Form.Item, { label: _jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Button Label" }), name: "label", required: false, rules: [
                        {
                            required: true,
                            message: 'Please input button label!',
                        },
                    ], children: _jsx(Input, { placeholder: "Enter button label here...", bordered: false, onChange: onChangeLabel }) }) }), _jsx("div", { className: "bg-gray-50 text-gray-500 text-xs rounded p-2", children: "Activate this button using the \u201CButton Clicked\u201D automation trigger." }), _jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Color" }), _jsx("div", { onClick: function (e) { return e.stopPropagation(); }, children: _jsx(ButtonColorSelector, { currentColor: currentColor, onUpdateColor: function (color) { return setCurrentColor(color); } }) })] }));
};
export default React.memo(ButtonSchemaConfigurationSection);
