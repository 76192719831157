import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { departmentsState } from 'src/state/atoms/chatState';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import { MessageSenderType, ThreadReceiverParticipantType } from '../interface';
import { participantsInThreadTitle, threadIsBroadcastFromParticipants } from '../utils';
var ThreadTitle = function (_a) {
    var _b, _c;
    var participants = _a.participants, isUnread = _a.isUnread;
    var currentUserRId = useRecoilValue(currentUserRecordIdSelector);
    var filteredParticipants = participantsInThreadTitle(participants, currentUserRId);
    var isBroadcast = threadIsBroadcastFromParticipants(participants);
    var departments = useRecoilValue(departmentsState);
    var getBroadCastTitleTooltip = useCallback(function () {
        return (_jsxs("div", { children: [filteredParticipants
                    .filter(function (participant) {
                    return participant.participantType !==
                        ThreadReceiverParticipantType.BROADCAST_LOCATION;
                })
                    .map(function (participant) { return participant.name; })
                    .join(', '), !!filteredParticipants.length &&
                    filteredParticipants[0].participantType ===
                        ThreadReceiverParticipantType.BROADCAST_ROLE &&
                    filteredParticipants[filteredParticipants.length - 1].participantType ===
                        ThreadReceiverParticipantType.BROADCAST_LOCATION && _jsx(_Fragment, { children: "\u00A0in\u00A0" }), filteredParticipants
                    .filter(function (participant) {
                    return participant.participantType ===
                        ThreadReceiverParticipantType.BROADCAST_LOCATION;
                })
                    .map(function (participant) { return participant.name; })
                    .join(', ')] }));
    }, [filteredParticipants]);
    if (isBroadcast) {
        return (_jsx(ZiraTooltip, { title: getBroadCastTitleTooltip(), children: _jsxs("div", { className: "ThreadTitle", style: isUnread ? { fontWeight: 600 } : undefined, children: [_jsx("div", { className: "max-h-full overflow-hidden text-ellipsis whitespace-nowrap", children: filteredParticipants
                            .filter(function (participant) {
                            return participant.participantType !==
                                ThreadReceiverParticipantType.BROADCAST_LOCATION;
                        })
                            .map(function (participant) { return participant.name; })
                            .join(', ') }), !!filteredParticipants.length &&
                        ((_b = filteredParticipants[0]) === null || _b === void 0 ? void 0 : _b.participantType) ===
                            ThreadReceiverParticipantType.BROADCAST_ROLE &&
                        ((_c = filteredParticipants[filteredParticipants.length - 1]) === null || _c === void 0 ? void 0 : _c.participantType) ===
                            ThreadReceiverParticipantType.BROADCAST_LOCATION && _jsx(_Fragment, { children: "\u00A0in\u00A0" }), _jsx("div", { className: "max-h-full overflow-hidden text-ellipsis whitespace-nowrap", children: filteredParticipants
                            .filter(function (participant) {
                            return participant.participantType ===
                                ThreadReceiverParticipantType.BROADCAST_LOCATION;
                        })
                            .map(function (participant) { return participant.name; })
                            .join(', ') })] }) }));
    }
    return (_jsx(ZiraTooltip, { title: filteredParticipants.map(function (participant) { return participant.name; }).join(', '), children: _jsxs("div", { className: "ThreadTitle", style: isUnread ? { fontWeight: 600 } : undefined, children: [_jsx("div", { className: "max-h-full overflow-hidden text-ellipsis whitespace-nowrap", children: filteredParticipants
                        .filter(function (participant) {
                        return participant.participantType !== MessageSenderType.DEPARTMENT;
                    })
                        .map(function (participant) { return participant.name; })
                        .join(', ') }), filteredParticipants
                    .filter(function (participant) {
                    return participant.participantType === MessageSenderType.DEPARTMENT;
                })
                    .map(function (participant) {
                    var _a;
                    return (_jsx("div", { className: "ChatDepartmentTag".concat(((_a = filteredParticipants[0]) === null || _a === void 0 ? void 0 : _a.participantType) ===
                            ThreadReceiverParticipantType.USER &&
                            departments.find(function (department) { return department.id === participant.participantRid; })
                            ? ' MyChatDepartmentTag'
                            : ''), children: participant.name }, participant.id));
                })] }) }));
};
export default ThreadTitle;
