var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var CopyIconSvg = function () { return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "-0.75 -0.75 48 48", strokeWidth: "4.5", height: "48", width: "48", children: [_jsx("path", { stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round", d: "M37.781 14.047h5.328a1.938 1.938 0 0 1 1.938 1.938v27.125a1.938 1.938 0 0 1 -1.938 1.938h-27.125a1.938 1.938 0 0 1 -1.938 -1.938V37.781", strokeWidth: "4.5" }), _jsx("path", { stroke: "#000000", strokeLinejoin: "round", d: "M1.453 3.391a1.938 1.938 0 0 1 1.938 -1.938h27.125a1.938 1.938 0 0 1 1.938 1.938v27.125a1.938 1.938 0 0 1 -1.938 1.938h-27.125a1.938 1.938 0 0 1 -1.938 -1.938v-27.125Z", strokeWidth: "4.5" })] })); };
export var CopyIcon = function (props) { return (_jsx(Icon, __assign({ component: CopyIconSvg }, props))); };
