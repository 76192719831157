var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useRef } from 'react';
import { Select } from 'antd';
import { TriggerPropertyType } from 'src/shared';
var TriggerPropertyTypeToDisplay = (_a = {},
    _a[TriggerPropertyType.ACTOR] = 'Initiating User',
    _a);
var AutomationTriggerPropertySelect = function (_a) {
    var currentValue = _a.currentValue, triggerTypes = _a.triggerTypes, schema = _a.schema, onSelect = _a.onSelect;
    var triggerOptions = useMemo(function () {
        return triggerTypes.map(function (t) { return ({ type: t, schemaId: schema.id }); });
    }, [triggerTypes, schema]);
    var selectRef = useRef(null);
    var onSelectWithBlur = function (val, elem) {
        var _a;
        onSelect(val, elem);
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    };
    return (_jsx("div", { className: "triggerOptionSelector", children: _jsx(Select, { ref: selectRef, className: "Selector", placeholder: "Choose trigger property", onSelect: onSelectWithBlur, value: (currentValue === null || currentValue === void 0 ? void 0 : currentValue.type) && TriggerPropertyTypeToDisplay[currentValue.type], children: triggerOptions.map(function (option, _) {
                return (_jsx(Select.Option, { value: option.schemaId, children: TriggerPropertyTypeToDisplay[option.type] }, option.type));
            }) }) }));
};
export default React.memo(AutomationTriggerPropertySelect);
