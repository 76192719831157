var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Modal } from 'antd';
import DateRangeShiftPropagationModalContent from 'src/components/DateRangeShiftPropagationModal/DateRangeShiftPropagationModalContent';
import usePayRateV2Api from 'src/components/Settings/PayRate/usePayRateV2Api';
import moment from 'moment-timezone';
import { useScheduleApi } from 'src/hooks/api';
import { useTimeZone } from 'src/hooks/component';
var DateRangeShiftPropagationModal = function (_a) {
    var onClose = _a.onClose;
    var useGetSimpleShiftsByDateRange = useScheduleApi().useGetSimpleShiftsByDateRange;
    var useRematchShifts = usePayRateV2Api().useRematchShifts;
    var timeZone = useTimeZone().timeZone;
    var _b = useState(moment()), startAt = _b[0], setStartAt = _b[1];
    var _c = useState(null), endAt = _c[0], setEndAt = _c[1];
    var memoizedRequest = useMemo(function () { return ({
        startAt: (startAt ? startAt : moment()).format('YYYY-MM-DD'),
        endAt: endAt ? endAt.format('YYYY-MM-DD') : undefined,
        timezone: timeZone,
    }); }, [startAt, endAt, timeZone]);
    var _d = useGetSimpleShiftsByDateRange({ body: memoizedRequest }), shiftData = _d.data, isLoading = _d.isLoading;
    var shifts = (shiftData === null || shiftData === void 0 ? void 0 : shiftData.shifts) || [];
    return (_jsx(Modal, { open: true, onCancel: onClose, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (shifts) {
                    useRematchShifts.mutate(memoizedRequest);
                    onClose();
                }
                return [2 /*return*/];
            });
        }); }, okButtonProps: {
            disabled: ((shiftData === null || shiftData === void 0 ? void 0 : shiftData.size) || 0) < 1 || useRematchShifts.isLoading,
        }, centered: true, children: _jsx(DateRangeShiftPropagationModalContent, { shifts: shifts, isShiftsLoading: isLoading, isPropagationLoading: false, startAt: startAt, endAt: endAt, setStartAt: setStartAt, setEndAt: setEndAt }) }));
};
export default DateRangeShiftPropagationModal;
