import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath } from 'src/shared';
import { getNativeFieldCellContent } from 'src/utils/Collection';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var JobDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var jobTitle = useMemo(function () {
        var title = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.JOB_NAME);
        return _jsx("span", { children: title || 'Job' });
    }, [modalRecord, nativeSchemaMap]);
    return _jsx(RecordDetailModalTitle, { title: jobTitle });
};
export default React.memo(JobDetailModalTitle);
