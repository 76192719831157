var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { RatingSchemaIconType, SchemaConfigurationType } from 'src/shared';
import { CircleIcon } from 'src/utils/icons/RatingSchemaIcons/CircleIcon';
import { FlagIcon } from 'src/utils/icons/RatingSchemaIcons/FlagIcon';
import { HeartIcon } from 'src/utils/icons/RatingSchemaIcons/HeartIcon';
import { StarIcon } from 'src/utils/icons/RatingSchemaIcons/StarIcon';
import { ThumbsUpIcon } from 'src/utils/icons/RatingSchemaIcons/ThumbsUpIcon';
export var RATING_ICON_COLOR_OPTIONS = [
    '#FF4A43', //red
    '#167EE4', //blue
    '#00A76F', //green
    '#FFC047', //yellow
    '#9D5A3E', //brown
];
export var generateRatingMaxValueOptions = function () {
    var values = [];
    for (var i = 10; i > 0; i--) {
        values.push(i);
    }
    return values;
};
export var ratingSchemaIconMap = (_a = {},
    _a[RatingSchemaIconType.STAR] = _jsx(StarIcon, {}),
    _a[RatingSchemaIconType.HEART] = _jsx(HeartIcon, {}),
    _a[RatingSchemaIconType.THUMBS_UP] = _jsx(ThumbsUpIcon, {}),
    _a[RatingSchemaIconType.FLAG] = _jsx(FlagIcon, {}),
    _a[RatingSchemaIconType.CIRCLE] = _jsx(CircleIcon, {}),
    _a);
export var RATING_SCHEMA_DEFAULT_SETTING = {
    type: SchemaConfigurationType.RATING,
    iconType: RatingSchemaIconType.STAR,
    color: '#FFC047',
    maxRating: 5,
};
