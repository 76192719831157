var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Collapse } from 'antd';
var Panel = Collapse.Panel;
var TaskCollapsePanel = function (_a, ref) {
    var step = _a.step, activeSteps = _a.activeSteps, title = _a.title, subtitle = _a.subtitle, children = _a.children, setActiveSteps = _a.setActiveSteps;
    var handleActiveStepChange = useCallback(function () {
        if (activeSteps.includes(step)) {
            // Close
            setActiveSteps(activeSteps.filter(function (activeStep) { return activeStep !== step; }));
        }
        else {
            // Open
            setActiveSteps(__spreadArray(__spreadArray([], activeSteps, true), [step], false));
        }
    }, [activeSteps, setActiveSteps, step]);
    return (_jsx(Collapse, { className: "bg-white", expandIconPosition: "end", activeKey: activeSteps, onChange: handleActiveStepChange, children: _jsx(Panel, { header: _jsxs("div", { className: "flex", ref: ref, children: [_jsx("div", { style: {
                            background: 'rgba(64.81, 110.21, 255, 0.30)',
                        }, className: "w-[26px] h-[26px] rounded-[13px] flex justify-center items-center mx-[16px]", children: _jsx("div", { className: "font-semibold pt-[1px]", style: {
                                color: '#416EFF',
                            }, children: step }) }), _jsxs("div", { className: "flex flex-col bg-white", children: [_jsx("span", { className: "text-base font-semibold", children: title }), _jsx("span", { className: "text-sm font-medium text-gray-300", children: subtitle })] })] }), forceRender: true, children: _jsxs("div", { className: "p-[16px]", children: [children, " "] }) }, step) }));
};
export default React.memo(React.forwardRef(TaskCollapsePanel));
