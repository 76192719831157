import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import React from 'react';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import { AutomationTriggerIcon } from 'src/utils/icons/AutomationTriggerIcon';
import { isAutomationLogEmpty, numberStringIntoSingleDigitArray } from '../DashboardUtils';
import ZiraRowsSkeleton from '../../../../ui/ZiraRowsSkeleton';
import AutomationEmptyStateImg from '../../../../utils/icons/images/AutomationLogEmptyStateImg.png';
import AutomationActionCard from './AutomationActionCard';
import AutomationTemplatesContent from './AutomationTemplatesContent';
var AutomationSummaryWidget = function () {
    var _a, _b;
    var useGetAutomationSummaryData = useDashboardApi().useGetAutomationSummaryData;
    var _c = useGetAutomationSummaryData(), data = _c.data, isLoading = _c.isLoading;
    var isAutomationLogEmptyState = isAutomationLogEmpty((_a = data === null || data === void 0 ? void 0 : data.automationRunViews) !== null && _a !== void 0 ? _a : []);
    var hours = (_b = data === null || data === void 0 ? void 0 : data.estimatedHours) !== null && _b !== void 0 ? _b : 0;
    var arr = numberStringIntoSingleDigitArray(hours);
    if (isLoading)
        return (_jsx("div", { className: "AutomationSummaryWidgetContainer p-5 shadow-block", children: _jsx(ZiraRowsSkeleton, { rowNumber: 5 }) }));
    if (!data)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "AutomationSummaryWidgetContainer shadow-block", children: [_jsxs("div", { className: "HoursSummaryContainer", children: [_jsxs("div", { className: "flex items-center text-xl font-medium", children: [_jsx(AutomationTriggerIcon, { className: "mr-1", style: { color: 'black' } }), _jsx("span", { children: "Automation" })] }), _jsx("div", { className: "ml-1 mt-3 text-xs", children: isAutomationLogEmptyState
                            ? 'Take a to-do off your list'
                            : 'Your automated workflows saved your team' }), isAutomationLogEmptyState ? (_jsx("img", { className: "AutomationEmptyStateImg", src: AutomationEmptyStateImg })) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "mt-8 flex items-center justify-center space-x-3", children: arr.map(function (digit, index) { return (_jsx("div", { className: "bg-blue-50 ".concat(arr.length > 4 ? 'p-1' : arr.length > 2 ? 'p-2' : 'p-4', " text-[32px] text-blue-400"), children: digit }, "".concat(index, "+").concat(digit))); }) }), _jsx("div", { className: "mt-5 font-medium", children: "hour".concat(hours > 1 ? 's' : '', " this week alone.") })] }))] }), isAutomationLogEmptyState ? (_jsx(AutomationTemplatesContent, {})) : (_jsxs("div", { className: "AutomationSummaryCardContainer shadow-block", children: [_jsx("div", { className: "mb-5 text-xl font-medium", children: "Automated Actions" }), _jsx("div", { className: "flex", children: _jsx(Row, { gutter: 24, children: data.automationRunViews.map(function (item, index) { return (_jsx(Col, { span: 12, children: _jsx(AutomationActionCard, { automationInfo: item }) }, "".concat(item.type, "+").concat(index))); }) }) })] }))] }));
};
export default React.memo(AutomationSummaryWidget);
