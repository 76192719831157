var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import SignaturePad from 'signature_pad';
import { useUpload, UploadUrlType } from '../../../../hooks/api/useUploadApi';
import { useDomNodeRef } from '../../../../hooks/component/useDomNodeRef';
import ZiraActionButton from '../../../../ui/ZiraActionButton';
import './LogicSignatureCell.scss';
var LogicSignatureCell = function (props) {
    var url = props.url, editCell = props.editCell, isClicked = props.isClicked, setIsClicked = props.setIsClicked;
    var _a = useState(undefined), signaturePad = _a[0], setSignaturePad = _a[1];
    var mutate = useUpload().mutate;
    // Adjust canvas coordinate otherwise it will have some weird drawing offsets
    var resizeCanvas = useCallback(function (canvas) {
        var _a;
        var ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        (_a = canvas.getContext('2d')) === null || _a === void 0 ? void 0 : _a.scale(ratio, ratio);
    }, []);
    var initSignaturePad = useCallback(function (canvas) { return __awaiter(void 0, void 0, void 0, function () {
        var signaturePad;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    signaturePad = new SignaturePad(canvas, {
                        minWidth: 1,
                        maxWidth: 2,
                    });
                    if (!url) return [3 /*break*/, 2];
                    // local set up will have issue to load image, just ignore the catched error
                    // for production, skip the error and let user to sign again
                    return [4 /*yield*/, signaturePad.fromDataURL(url).catch(function () { })];
                case 1:
                    // local set up will have issue to load image, just ignore the catched error
                    // for production, skip the error and let user to sign again
                    _a.sent();
                    _a.label = 2;
                case 2:
                    setSignaturePad(signaturePad);
                    return [2 /*return*/];
            }
        });
    }); }, [url]);
    var _b = useDomNodeRef({
        onInit: initSignaturePad,
    }), canvas = _b.node, canvasRef = _b.ref;
    useLayoutEffect(function () {
        if (canvas) {
            resizeCanvas(canvas);
        }
    }, [canvas, resizeCanvas]);
    var onClearSignature = useCallback(function () {
        signaturePad === null || signaturePad === void 0 ? void 0 : signaturePad.clear();
    }, [signaturePad]);
    var onDownloadSignature = useCallback(function () {
        if (signaturePad === null || signaturePad === void 0 ? void 0 : signaturePad.isEmpty())
            return;
        var data = signaturePad === null || signaturePad === void 0 ? void 0 : signaturePad.toDataURL();
        if (data) {
            var link = document.createElement('a');
            link.download = 'signature.png';
            link.href = data;
            link.click();
        }
    }, [signaturePad]);
    var onSaveSignature = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formData;
        return __generator(this, function (_a) {
            if (!signaturePad || !canvas)
                return [2 /*return*/];
            if (signaturePad === null || signaturePad === void 0 ? void 0 : signaturePad.isEmpty()) {
                editCell('');
                setIsClicked(false);
                return [2 /*return*/];
            }
            formData = new FormData();
            canvas.toBlob(function (blob) {
                if (!blob)
                    return;
                formData.append('file', blob, 'signature.png');
                mutate({
                    uploadUrlType: UploadUrlType.Collection,
                    formData: formData,
                }, {
                    onSuccess: function (resp) {
                        var _a, _b;
                        var url = (_b = (_a = resp.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url;
                        if (url) {
                            editCell(url);
                            setIsClicked(false);
                        }
                    },
                });
            });
            return [2 /*return*/];
        });
    }); }, [signaturePad, canvas, mutate, editCell, setIsClicked]);
    var ModalContent = useMemo(function () {
        return (_jsx("div", { className: "SignatureModalContent", children: _jsx("canvas", { ref: canvasRef, className: "SignatureCanvas" }) }));
    }, [canvasRef]);
    // Customize Modal's footer
    var ModalFooter = useMemo(function () {
        return (_jsx(_Fragment, { children: _jsxs("div", { className: "SignatureModalFooter", children: [_jsx(Button, { onClick: onClearSignature, id: "clearBtn", children: "Clear" }), _jsx(Button, { onClick: onDownloadSignature, id: "downloadBtn", children: "Download" }), _jsx("div", { className: "flexSpace" }), _jsx(ZiraActionButton, { id: "saveBtn", onClickFn: onSaveSignature, children: "Save" })] }) }));
    }, [onClearSignature, onDownloadSignature, onSaveSignature]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "SignatureCell", children: _jsx("img", { height: 40, src: url !== null && url !== void 0 ? url : undefined }) }), _jsxs(Modal, { className: "SignatureEditModal", title: 'Edit Signature', onCancel: function () { return setIsClicked(false); }, maskClosable: false, open: isClicked, children: [ModalContent, canvas && ModalFooter] })] }));
};
export default React.memo(LogicSignatureCell);
