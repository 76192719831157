import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinusOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useAccountApi, useScheduleTemplateApi } from 'src/hooks/api';
import { useTemplateComponent } from 'src/hooks/component';
import { scheduleTimeZoneSelector } from 'src/state/selectors/schedule';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from '../CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from '../CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import TemplateCard from './TemplateCard';
import './TemplateDrawer.scss';
var TemplateDrawer = function (props) {
    var closeDrawer = props.closeDrawer, onDragTemplateEnd = props.onDragTemplateEnd, blockId = props.blockId;
    var timezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var _a = useTemplateComponent(blockId), locationFilter = _a.locationFilter, saveTemplateLocationFilter = _a.saveTemplateLocationFilter, templateList = _a.templateList, setTemplateList = _a.setTemplateList;
    var locationFilterRef = useRef(locationFilter);
    useEffect(function () {
        locationFilterRef.current = locationFilter;
    }, [locationFilter]);
    var useFetchScheduleTemplateList = useScheduleTemplateApi().useFetchScheduleTemplateList;
    var data = useFetchScheduleTemplateList({
        filters: locationFilter,
    }).data;
    useEffect(function () {
        if (data) {
            setTemplateList(data.templates);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    var useGetObjSimpleList = useAccountApi().useGetObjSimpleList;
    var locationsData = useGetObjSimpleList('locations', {
        body: {},
        options: {
            enabled: true,
        },
    }).data;
    var renderLocations = useCallback(function () {
        if (locationsData && 'locations' in locationsData) {
            return (_jsxs("div", { className: "popover-inner template-filter-popover", children: [_jsx("span", { className: 'filterGroup ' + (locationFilter.length == 0 ? 'checked' : ''), onClick: function () { return saveTemplateLocationFilter([]); }, children: "All Locations" }), locationsData.locations.map(function (location) {
                        return (_jsxs("label", { className: 'filterGroup ' +
                                (locationFilter.includes(location.recordId) ? 'checked' : ''), children: [_jsx("input", { type: "checkbox", checked: locationFilter.includes(location.recordId), onChange: function () {
                                        var index = locationFilter.indexOf(location.recordId);
                                        var tempLocationFilter = cloneDeep(locationFilterRef.current);
                                        if (index >= 0) {
                                            tempLocationFilter.splice(index, 1);
                                        }
                                        else {
                                            tempLocationFilter.push(location.recordId);
                                        }
                                        saveTemplateLocationFilter(tempLocationFilter);
                                    } }), location.title] }, location.recordId));
                    })] }));
        }
    }, [locationFilter, locationsData, saveTemplateLocationFilter]);
    return (_jsxs("div", { className: "TemplateDrawerContainer", children: [_jsxs("div", { className: "DrawerTitleWrapper", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "DrawerTitle", children: "Shift Templates" }), _jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.SCHEDULING_TEMPLATE], children: _jsx(HelpIcon, { className: "ml-2 text-black" }) })] }), _jsx(Button, { className: "CloseDrawerBtn", icon: _jsx(MinusOutlined, {}), onClick: closeDrawer })] }), _jsx("div", { className: "DrawerDescription", children: "Drag and drop a template below." }), _jsx("div", { className: "FiltersWrapper", children: _jsx(Popover, { placement: "bottom", content: renderLocations, trigger: "click", children: _jsx("span", { className: "filterGroup", children: "Filter by Location" }) }) }), _jsx("div", { className: "TemplateCardsContainer", children: templateList &&
                    templateList.map(function (template) { return (_jsx(TemplateCard, { template: template, onDragTemplateEnd: onDragTemplateEnd, timezone: timezone, blockId: blockId }, template.id)); }) })] }));
};
export default React.memo(TemplateDrawer);
