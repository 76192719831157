import { jsx as _jsx } from "react/jsx-runtime";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment-timezone';
import { getMaxYFromGraphData, getStepSizeByGraphData, numberFormatterWithType, } from './HealthMetricCalculator';
import { ChartColors, getChartGradient } from './Styles';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, annotationPlugin);
var HealthMetricGraphBuilder = function (_a) {
    var title = _a.title, chartData = _a.data, type = _a.type, interval = _a.interval, goal = _a.goal, _b = _a.smallLabelStep, smallLabelStep = _b === void 0 ? false : _b;
    var labels = chartData.map(function (d) { return d.date; });
    var data = {
        labels: labels,
        datasets: [
            {
                label: title,
                data: labels.map(function (d, index) {
                    var foundData = chartData.find(function (d) { return d.date === labels[index]; });
                    return foundData ? foundData.number : 0;
                }),
                fill: 'start',
                backgroundColor: function (context) {
                    return getChartGradient(context.chart.ctx, ChartColors.blue.base);
                },
                borderColor: ChartColors.blue.base,
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 5,
                pointHitRadius: 30,
                pointHoverBackgroundColor: ChartColors.blue.base,
                hoverBorderWidth: 2,
                segment: {
                    borderDash: function (ctx) {
                        return (ctx.p0DataIndex === chartData.length - 1 ||
                            ctx.p0DataIndex === chartData.length - 2) &&
                            interval !== 'DAILY'
                            ? [5, 5]
                            : [];
                    },
                },
            },
        ],
    };
    var options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: smallLabelStep
                        ? labels.length + 2
                        : Math.round(labels.length / 2) || 1,
                    callback: function (value) {
                        if (typeof value !== 'number')
                            return value;
                        if (value === chartData.length - 1 && interval !== 'DAILY')
                            return '';
                        var label = labels[value];
                        var date = moment(label.split(' - ')[0]);
                        var format = interval === 'MONTHLY' ? 'MMM' : 'MMM DD';
                        return moment(date).format(format);
                    },
                },
            },
            y: {
                grid: {
                    color: ChartColors.gray.extraLight,
                },
                ticks: {
                    callback: function (value) {
                        return numberFormatterWithType(Number(value), type, true);
                    },
                    autoSkip: true,
                    //define by how many digits it has
                    stepSize: getStepSizeByGraphData(chartData, type),
                },
                suggestedMax: type === 'PERCENTAGE' ? 100 : getMaxYFromGraphData(chartData),
                min: 0,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                cornerRadius: 6,
                titleColor: 'white',
                bodyColor: 'white',
                bodySpacing: 10,
                titleMarginBottom: 10,
                callbacks: {
                    title: function (tooltipItems) {
                        return numberFormatterWithType(tooltipItems[0].parsed.y, type) + ' ' + title;
                    },
                    beforeBody: function (tooltipItems) {
                        return tooltipItems[0].label;
                    },
                    label: function () {
                        return '';
                    },
                    footer: function (tooltipItems) {
                        var index = tooltipItems[0].dataIndex;
                        if (index === 0)
                            return '';
                        var previousValue = chartData[index - 1].number;
                        var currentValue = chartData[index].number;
                        var diffPercentage = previousValue === 0
                            ? Math.min(currentValue, 1) * 100
                            : ((currentValue - previousValue) / previousValue) * 100;
                        var label = interval === 'DAILY' ? 'day' : interval === 'WEEKLY' ? 'week' : 'month';
                        var numberLabel = diffPercentage > 0 ? '+' : '';
                        if (diffPercentage === 0)
                            return "No change from previous ".concat(label);
                        return "".concat(numberLabel).concat(diffPercentage.toFixed(2), "% from previous ").concat(label);
                    },
                },
            },
            annotation: goal
                ? {
                    annotations: {
                        goal: {
                            type: 'line',
                            yMin: goal,
                            yMax: goal,
                            borderColor: ChartColors.gray.light,
                            borderWidth: 1,
                            borderDash: [5, 5],
                        },
                    },
                }
                : undefined,
        },
    };
    return _jsx(Line, { data: data, options: options });
};
export default HealthMetricGraphBuilder;
