import { atom } from 'recoil';
var isQuickCreateModalVisibleState = atom({
    key: 'isQuickCreateModalVisibleState',
    default: false,
});
var quickCreateModalCollectionInfoState = atom({
    key: 'quickCreateModalCollectionInfoState',
    default: undefined,
});
export { isQuickCreateModalVisibleState, quickCreateModalCollectionInfoState };
