import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilCallback } from 'recoil';
import { blockPlaceholderImgState } from 'src/state/atoms/addNewBlockDrawerState';
var BlockDisplayCard = function (_a) {
    var blockInfo = _a.blockInfo, goNextStep = _a.goNextStep, onSelectCard = _a.onSelectCard;
    var onMouseEnterCard = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            if (!blockInfo.blockImage)
                return;
            set(blockPlaceholderImgState, blockInfo.blockImage);
        };
    }, [blockInfo]);
    var onMouseLeaveCard = useRecoilCallback(function (_a) {
        var reset = _a.reset;
        return function () {
            reset(blockPlaceholderImgState);
        };
    }, []);
    var onClickCard = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            set(blockPlaceholderImgState, blockInfo.blockImage);
            goNextStep();
            onSelectCard();
        };
    }, [blockInfo, goNextStep, onSelectCard]);
    return (_jsxs("div", { className: "BlockCard mt-3 cursor-pointer", onClick: onClickCard, onMouseEnter: onMouseEnterCard, onMouseLeave: onMouseLeaveCard, children: [_jsx("div", { className: "BlockIconContainer mt-2", children: _jsx("img", { src: blockInfo.blockIcon }) }), _jsx("div", { className: "text-center font-medium", children: blockInfo.blockName })] }));
};
export default React.memo(BlockDisplayCard);
