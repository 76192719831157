var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LanguageDisplayMap } from './interface';
export var getDropdownMenuItems = function (localizationSettings, setLocalizationSettings) {
    return Object.entries(LanguageDisplayMap).map(function (_a) {
        var _b;
        var key = _a[0], displayValue = _a[1];
        return ({
            key: key,
            label: displayValue,
            onClick: function () {
                if (localizationSettings) {
                    setLocalizationSettings(__assign(__assign({}, localizationSettings), { accountLanguages: __spreadArray(__spreadArray([], (localizationSettings.accountLanguages || []), true), [
                            key,
                        ], false) }));
                }
            },
            disabled: (_b = localizationSettings === null || localizationSettings === void 0 ? void 0 : localizationSettings.accountLanguages) === null || _b === void 0 ? void 0 : _b.includes(key),
        });
    });
};
