import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { automationTags } from 'src/utils/Automation';
import 'src/components/Settings/Automations/AutomationCard.scss';
var RecipeSummaryAutomations = function (_a) {
    var automationCards = _a.automationCards;
    return (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "pb-[8px] text-base font-medium", children: [automationCards.length, " Automations"] }), _jsx("div", { className: "flex space-x-[16px]", children: automationCards.map(function (automation) {
                    return (_jsx("div", { className: "AutomationCard pointer-events-none rounded-xl border-2 border-azure-50 px-[16px] text-sm font-medium", children: _jsxs("span", { className: "AutomationCardContent", children: [_jsx("div", { className: "relative flex w-full items-center", children: _jsxs("span", { className: "AutomationTags justify-start", children: [automationTags(automation.triggerCategories), !!automation.actionCategories.length && (_jsxs(_Fragment, { children: [_jsx(ArrowRightOutlined, {}), automationTags(automation.actionCategories)] }))] }) }), _jsx("span", { className: "AutomationName", children: automation.name }), _jsx("div", { className: "mt-auto" })] }) }, automation.id));
                }) })] }));
};
export default React.memo(RecipeSummaryAutomations);
