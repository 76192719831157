import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, Modal } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { useTimeZone } from 'src/hooks/component';
import { PAY_PERIODS_PAGE_SIZE } from './PayPeriodsBlock';
var RangePicker = DatePicker.RangePicker;
var PayPeriodModal = function (_a) {
    var _b, _c;
    var setPayPeriods = _a.setPayPeriods, onClose = _a.onClose, payPeriodListSize = _a.payPeriodListSize, payPeriodTimeZone = _a.payPeriodTimeZone, payPeriodId = _a.payPeriodId, defaultDates = _a.defaultDates;
    var timeZone = useTimeZone().timeZone;
    var _d = useState((_b = defaultDates === null || defaultDates === void 0 ? void 0 : defaultDates[0]) !== null && _b !== void 0 ? _b : moment().tz(timeZone).startOf('week').toISOString()), startDate = _d[0], setStartDate = _d[1];
    var _e = useState((_c = defaultDates === null || defaultDates === void 0 ? void 0 : defaultDates[1]) !== null && _c !== void 0 ? _c : moment()
        .tz(timeZone)
        .startOf('week')
        .add(1, 'week')
        .subtract(1, 'day')
        .endOf('day')
        .toISOString()), endDate = _e[0], setEndDate = _e[1];
    var onDateRangeChange = useCallback(function (momentDates) {
        if (!momentDates)
            return;
        setStartDate(moment.utc(momentDates[0]).tz(payPeriodTimeZone).startOf('day').toISOString());
        setEndDate(moment.utc(momentDates[1]).tz(payPeriodTimeZone).endOf('day').toISOString());
    }, [payPeriodTimeZone]);
    var useSavePayPeriod = usePayApi().useSavePayPeriod;
    var onSavePayPeriod = useCallback(function () {
        var returnSize = Math.max(payPeriodId ? payPeriodListSize : payPeriodListSize + 1, PAY_PERIODS_PAGE_SIZE);
        useSavePayPeriod.mutate({
            payPeriodId: payPeriodId,
            startDate: startDate,
            endDate: endDate,
            returnSize: returnSize,
        }, {
            onSuccess: function (resp) {
                if (resp) {
                    setPayPeriods(resp.payPeriods);
                    onClose();
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate, onClose, payPeriodId, payPeriodListSize, startDate]);
    return (_jsx(Modal, { title: "".concat(payPeriodId ? 'Edit' : 'Create', " Pay Period"), open: true, centered: true, onOk: onSavePayPeriod, onCancel: onClose, children: _jsxs("div", { className: "flex flex-col font-semibold", children: [_jsxs("div", { className: "w-full", children: [_jsx("span", { children: "Start Date" }), _jsx("span", { className: "ml-44", children: "End Date" })] }), _jsx(RangePicker, { className: "rounded-md", picker: "date", value: [
                        moment
                            .utc(startDate)
                            .utcOffset(moment.tz(payPeriodTimeZone).utcOffset())
                            .tz(timeZone),
                        moment
                            .utc(endDate)
                            .utcOffset(moment.tz(payPeriodTimeZone).utcOffset())
                            .tz(timeZone)
                            .startOf('day'),
                    ], onChange: onDateRangeChange, allowClear: true })] }) }));
};
export default React.memo(PayPeriodModal);
