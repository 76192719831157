import { atom, selectorFamily } from 'recoil';
import { tableCollectionViewsState } from './collectionTable';
var currentReverseRelationIdState = atom({
    key: 'currentReverseRelationIdState',
    default: undefined,
});
//! ID of reverse collection in DB (view requests)
var reverseCollectionIdSelector = selectorFamily({
    key: 'reverseCollectionIdSelector',
    get: function (reverseRelationId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var views = get(tableCollectionViewsState(reverseRelationId));
            return (_b = views.reverseCollectionId) !== null && _b !== void 0 ? _b : '';
        };
    },
});
export { currentReverseRelationIdState, reverseCollectionIdSelector };
