import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { isBoardViewSettingModalVisibleState } from './atom';
var GroupBySchemaTag = function (_a) {
    var viewId = _a.viewId, groupBySetting = _a.groupBySetting, columns = _a.columns;
    var setIsBoardViewSettingModalVisible = useSetRecoilState(isBoardViewSettingModalVisibleState(viewId));
    var groupBySchemaName = useMemo(function () {
        var _a, _b;
        return (_b = (_a = columns[groupBySetting.schema]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
    }, [columns, groupBySetting.schema]);
    return (_jsx(ZiraTooltip, { title: "Config Group by Settings", children: _jsxs("div", { className: "flex cursor-pointer items-center rounded-md p-[4px] hover:bg-gray-50", onClick: function () { return setIsBoardViewSettingModalVisible(true); }, children: [_jsx("span", { className: "OptionTitle", children: "Group by" }), _jsx("span", { className: "GroupBySchemaTag", children: groupBySchemaName })] }) }));
};
export default React.memo(GroupBySchemaTag);
