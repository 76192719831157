var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { cloneDeep, sortBy } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { ZiraVisibilityDropdownVisibleItem, ZiraVisibilityDropdownToggleItem, ZiraVisibilityDropdown, } from 'src/ui/ZiraVisibilityDropdown';
import { collectionTableCanEditSchemasSelector, collectionTableSchemaMapSelector } from 'src/state';
import { ColumnIcon } from 'src/utils/icons/ColumnIcon';
import { useSchemaComponent } from 'src/hooks/component';
import { PlusOutlined } from '@ant-design/icons';
import { CollectionTableType } from 'src/shared';
import CreateSchemaModalV2 from 'src/components/CollectionComponents/CreateSchemaModal/CreateSchemaModalV2';
var ColumnVisibilityDropdown = function (_a) {
    var collectionId = _a.collectionId, schemaOptions = _a.schemaOptions, applySchemaOptions = _a.applySchemaOptions;
    var _b = useState(false), createSchemaModalVisible = _b[0], setCreateSchemaModalVisible = _b[1];
    var schemaMap = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var canAddNewColumn = useRecoilValue(collectionTableCanEditSchemasSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var addNewColumn = useSchemaComponent().addNewColumn;
    var onAddNewColumn = useCallback(function (column) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, addNewColumn('record_details', '', undefined, column, undefined, collectionId)];
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectionId]);
    var allSortedSchemaOptions = useMemo(function () { return sortBy(schemaOptions, function (schemaOption) { return schemaOption.hidden; }); }, [schemaOptions]);
    var visibleSchemaOptions = useMemo(function () { return allSortedSchemaOptions.filter(function (schemaOption) { return !schemaOption.hidden; }); }, [allSortedSchemaOptions]);
    var onSchemaOptionVisibleToggle = useCallback(function (schemaId, isToggledOn) {
        applySchemaOptions(allSortedSchemaOptions.map(function (schemaOption) {
            return schemaOption.schemaId !== schemaId
                ? schemaOption
                : __assign(__assign({}, schemaOption), { hidden: !isToggledOn });
        }));
    }, [allSortedSchemaOptions, applySchemaOptions]);
    var onSchemaOptionDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination)
            return;
        var sourceSchema = visibleSchemaOptions[source.index];
        var destinationSchema = visibleSchemaOptions[destination.index];
        if (!sourceSchema || !destinationSchema)
            return;
        var sourceSchemaIndex = allSortedSchemaOptions.findIndex(function (schemaOption) { return schemaOption.schemaId === sourceSchema.schemaId; });
        var destinationSchemaIndex = allSortedSchemaOptions.findIndex(function (schemaOption) { return schemaOption.schemaId === destinationSchema.schemaId; });
        if (sourceSchemaIndex === -1 || destinationSchemaIndex === -1)
            return;
        var temp = cloneDeep(allSortedSchemaOptions);
        var reorderedItem = temp.splice(sourceSchemaIndex, 1)[0];
        temp.splice(destinationSchemaIndex, 0, reorderedItem);
        applySchemaOptions(temp);
    }, [allSortedSchemaOptions, applySchemaOptions, visibleSchemaOptions]);
    var visibleSchemaOptionsContent = useMemo(function () {
        return (_jsx(DragDropContext, { onDragEnd: onSchemaOptionDragEnd, children: _jsx(Droppable, { droppableId: collectionId, children: function (provided) { return (_jsxs("div", __assign({ className: "flex w-full flex-col space-y-3 p-4" }, provided.droppableProps, { ref: provided.innerRef, children: [visibleSchemaOptions.map(function (schemaOption, schemaOptionIndex) {
                            var schemaId = schemaOption.schemaId;
                            var schema = schemaMap[schemaId];
                            return (_jsx(Draggable, { draggableId: schemaId, index: schemaOptionIndex, children: function (provided) {
                                    if (!schema) {
                                        return (_jsx("div", { className: "text-gray-300", children: _jsx(ZiraVisibilityDropdownVisibleItem, { title: "Hidden Column (No Permission)", draggableProvided: provided }) }));
                                    }
                                    return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: schema.name, draggableProvided: provided, onToggleOffItem: function () {
                                            return onSchemaOptionVisibleToggle(schemaId, false);
                                        } }));
                                } }, schemaId));
                        }), provided.placeholder, canAddNewColumn && (_jsxs("div", { className: "light-gray-btn min-h-[52px] font-semibold", onClick: function () { return setCreateSchemaModalVisible(true); }, children: [_jsx("span", { className: "-mt-1 ml-4 mr-4 text-gray-300", children: _jsx(PlusOutlined, {}) }), "Add Column"] })), createSchemaModalVisible && (_jsx(CreateSchemaModalV2, { onAddNewColumn: onAddNewColumn, isModalVisible: createSchemaModalVisible, setIsModalVisible: setCreateSchemaModalVisible, collectionId: collectionId }))] }))); } }) }));
    }, [
        canAddNewColumn,
        collectionId,
        createSchemaModalVisible,
        onAddNewColumn,
        onSchemaOptionDragEnd,
        onSchemaOptionVisibleToggle,
        schemaMap,
        visibleSchemaOptions,
    ]);
    var allSortedSchemaOptionsContent = useMemo(function () {
        return (_jsx("div", { className: "flex w-full flex-col", children: allSortedSchemaOptions.map(function (schemaOption) {
                var schemaId = schemaOption.schemaId;
                var isVisible = !schemaOption.hidden;
                var isLastVisible = isVisible &&
                    visibleSchemaOptions[visibleSchemaOptions.length - 1].schemaId === schemaId;
                var schema = schemaMap[schemaId];
                if (!schema) {
                    return isLastVisible ? (_jsx("div", { className: "w-full bg-[#F8F8F9] py-[12px]" })) : null;
                }
                return (_jsx(ZiraVisibilityDropdownToggleItem, { title: schema.name, isVisible: isVisible, onVisibilityToggled: function (isToggledOn) {
                        return onSchemaOptionVisibleToggle(schemaId, isToggledOn);
                    }, showDivider: isLastVisible }, schemaId));
            }) }));
    }, [allSortedSchemaOptions, visibleSchemaOptions, schemaMap, onSchemaOptionVisibleToggle]);
    return (_jsx(ZiraVisibilityDropdown, { tabTitles: ['Visible Columns', 'All Columns'], tabContents: [visibleSchemaOptionsContent, allSortedSchemaOptionsContent], onOpenChange: function (open) {
            if (!open) {
                setCreateSchemaModalVisible(false);
            }
        }, placement: "bottomRight", destroyPopupOnHide: true, children: _jsx(ZiraTooltip, { title: "Show/Hide Columns", children: _jsxs("div", { className: "flex cursor-pointer items-center rounded-md p-[4px] hover:bg-gray-50", children: [_jsx(ColumnIcon, {}), _jsx("span", { className: "OptionTitle", children: "Columns" })] }) }) }));
};
export default React.memo(ColumnVisibilityDropdown);
