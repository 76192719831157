import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRecoilValue } from 'recoil';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import { useMemo } from 'react';
import { useRecordDetail } from 'src/hooks/component';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { getMessageTimeStamp } from '../../utils';
import MessageItemUserAvatar from './MessageItemUserAvatar';
import './MessageItem.scss';
import ChatUserListModal from './ChatUserListModal';
import MessageAttachmentItem from './MessageAttachmentItem';
import TextWithLinks from './TextWithLinks';
var SenderMessageItem = function (_a) {
    var _b;
    var message = _a.message, doNotShowReadByCount = _a.doNotShowReadByCount;
    var currentUserRId = useRecoilValue(currentUserRecordIdSelector);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    // Filter out current user/sender
    var messageViewedUserList = useMemo(function () { var _a; return (_a = message.readBy) === null || _a === void 0 ? void 0 : _a.filter(function (user) { return user.id !== currentUserRId; }); }, [currentUserRId, message.readBy]);
    var renderReadByContent = useMemo(function () {
        if (!messageViewedUserList || messageViewedUserList.length === 0)
            return _jsx("div", { className: "MessageReadBy", children: "Delivered" });
        else if (!doNotShowReadByCount) {
            if (messageViewedUserList.length === 1) {
                return (_jsx("div", { className: "MessageReadBy", children: "Read by ".concat(messageViewedUserList[0].name) }));
            }
            else {
                return (_jsx(ChatUserListModal, { defaultUserList: messageViewedUserList, showReadStatus: true, children: _jsx(ZiraTooltip, { title: "Open Viewed List", placement: "bottomRight", children: _jsx("div", { className: "MessageReadBy cursor-pointer", children: "".concat(messageViewedUserList.length, " Views") }) }) }));
            }
        }
        else {
            // For 2 users dm
            return (_jsx("div", { className: "MessageReadBy", children: messageViewedUserList.length ? 'Read' : 'Delivered' }));
        }
    }, [doNotShowReadByCount, messageViewedUserList]);
    return (_jsx("div", { className: "ChatMessageListItem MyMessage", children: _jsxs("div", { className: "MessageChatView", children: [_jsxs("div", { className: "MessageChatItem", children: [_jsxs("span", { className: "MessageSenderName", children: [message.senderDetail.id !== currentUserRId && (_jsxs(_Fragment, { children: [_jsx("span", { className: "cursor-pointer hover:underline", onClick: function () { return openRecordDetail(message.senderDetail.id); }, children: message.senderDetail.name }), ' ', message.departmentSenderDetail &&
                                            " \u2022 ".concat((_b = message.departmentSenderDetail) === null || _b === void 0 ? void 0 : _b.name)] })), _jsx("span", { className: "MessageTime", children: getMessageTimeStamp(message.createdAt) })] }), message.body.text && (_jsx("div", { className: "MessageChat", children: _jsx("p", { className: "text-sm font-medium", children: _jsx(TextWithLinks, { text: message.body.text }) }) })), message.body.attachments.map(function (attachment, index) {
                            return (_jsx(MessageAttachmentItem, { attachment: attachment }, "attachment-".concat(index)));
                        }), renderReadByContent] }), _jsx("div", { className: "MessageChatSender", children: _jsx(MessageItemUserAvatar, { message: message }) })] }) }));
};
export default SenderMessageItem;
