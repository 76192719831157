import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { useRecoilValue } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import { tableDisplaySettingsState, tableSelectedViewIdState, tableVisibleColumnIdsSelector, collectionTableCanEditSelector, } from 'src/state';
import { useCollectionComponent, useDisplaySettings, useRecordComponent } from 'src/hooks/component';
import { CollectionTableType, CollectionType } from 'src/shared';
import { displaySettingsForCollectionTable } from 'src/services';
import CollectionBoardView from './BoardView/CollectionBoardView';
var BlockCollectionBoardView = function (_a) {
    var _b, _c;
    var blockId = _a.blockId, defaultDisplaySettings = _a.initialDisplaySettings, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading;
    var initialDisplaySettings = useState(defaultDisplaySettings)[0];
    var viewId = useRecoilValue(tableSelectedViewIdState(blockId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var boardViewGroupBySetting = displaySettings.groupSetting;
    var processBoardViewCollectionIntoState = useCollectionComponent().processBoardViewCollectionIntoState;
    var useGetBoardViewGroupsData = usePageApi().useGetBoardViewGroupsData;
    var requestBody = useMemo(function () { return ({
        blockId: blockId,
        viewId: viewId,
        displaySettings: displaySettingsForCollectionTable(initialDisplaySettings, displaySettings, isLoading),
    }); }, [blockId, viewId, displaySettings, initialDisplaySettings, isLoading]);
    var _d = useGetBoardViewGroupsData({
        body: requestBody,
        options: {
            enabled: !!viewId &&
                !!requestBody.displaySettings &&
                !!requestBody.displaySettings.groupSetting,
        },
    }), data = _d.data, isFetching = _d.isFetching;
    useEffect(function () {
        if (data && !isFetching) {
            processBoardViewCollectionIntoState(data, blockId, true);
            setIsLoading(false);
            setColumns(data.orderedGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFetching]);
    var _e = useState([]), columns = _e[0], setColumns = _e[1];
    var collectionId = (_b = data === null || data === void 0 ? void 0 : data.collectionInfo.id) !== null && _b !== void 0 ? _b : '';
    var collectionType = (_c = data === null || data === void 0 ? void 0 : data.collectionInfo.type) !== null && _c !== void 0 ? _c : CollectionType.USER;
    var onCreateNewRecordInColumn = useRecordComponent().onCreateNewRecordInColumn;
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var canAddNewRecord = useRecoilValue(collectionTableCanEditSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var handleAddNewRecord = useCallback(function (newValue) {
        if (!boardViewGroupBySetting)
            return;
        var schemaId = boardViewGroupBySetting.schema;
        onCreateNewRecordInColumn(blockId, collectionId, schemaId, newValue);
    }, [blockId, boardViewGroupBySetting, collectionId, onCreateNewRecordInColumn]);
    var handleUpdateGroupSetting = useCallback(function (newSetting) {
        applyDisplaySettingsToView(blockId, { groupSetting: newSetting });
    }, [applyDisplaySettingsToView, blockId]);
    var paginationBaseRequestBody = useMemo(function () { return ({
        blockId: blockId,
        displaySettings: displaySettings,
    }); }, [blockId, displaySettings]);
    var visibleSchemaIds = useRecoilValue(tableVisibleColumnIdsSelector({ tableId: blockId, collectionId: collectionId }));
    return isLoading ? (_jsx(Skeleton, { active: true })) : (_jsx(CollectionBoardView, { collectionId: collectionId, collectionType: collectionType, columns: columns, setColumns: setColumns, groupBySetting: boardViewGroupBySetting, onUpdateGroupSetting: handleUpdateGroupSetting, onAddNewRecord: canAddNewRecord ? handleAddNewRecord : undefined, paginationBaseRequestBody: paginationBaseRequestBody, visibleSchemaIds: visibleSchemaIds }));
};
export default React.memo(BlockCollectionBoardView);
