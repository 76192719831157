var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TemplateDownloadIconSvg = function () { return (_jsx("svg", { width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.39998 2.52637C4.92259 2.52637 4.46475 2.71601 4.12718 3.05357C3.78962 3.39114 3.59998 3.84898 3.59998 4.32637V15.1264C3.59998 15.6038 3.78962 16.0616 4.12718 16.3992C4.46475 16.7367 4.92259 16.9264 5.39998 16.9264H12.6C13.0774 16.9264 13.5352 16.7367 13.8728 16.3992C14.2103 16.0616 14.4 15.6038 14.4 15.1264V7.39897C14.3999 6.92162 14.2102 6.46385 13.8726 6.12637L10.8 3.05377C10.4625 2.71618 10.0047 2.52647 9.52738 2.52637H5.39998ZM9.89998 7.92637C9.89998 7.68767 9.80515 7.45875 9.63637 7.28997C9.46759 7.12119 9.23867 7.02637 8.99998 7.02637C8.76128 7.02637 8.53236 7.12119 8.36358 7.28997C8.1948 7.45875 8.09998 7.68767 8.09998 7.92637V11.1538L6.93628 9.99007C6.85325 9.90411 6.75394 9.83554 6.64414 9.78838C6.53434 9.74121 6.41624 9.71638 6.29674 9.71534C6.17724 9.7143 6.05872 9.73707 5.94812 9.78233C5.83751 9.82758 5.73702 9.89441 5.65252 9.97891C5.56802 10.0634 5.50119 10.1639 5.45594 10.2745C5.41068 10.3851 5.38791 10.5036 5.38895 10.6231C5.38999 10.7426 5.41482 10.8607 5.46198 10.9705C5.50915 11.0803 5.57772 11.1796 5.66368 11.2627L8.36368 13.9627C8.53245 14.1314 8.76133 14.2262 8.99998 14.2262C9.23862 14.2262 9.4675 14.1314 9.63628 13.9627L12.3363 11.2627C12.5002 11.0929 12.5909 10.8656 12.5889 10.6296C12.5868 10.3936 12.4922 10.1679 12.3253 10.001C12.1584 9.83416 11.9327 9.73951 11.6967 9.73746C11.4608 9.73541 11.2334 9.82613 11.0637 9.99007L9.89998 11.1538V7.92637Z", fill: "#2F4DDF" }) })); };
export var TemplateDownloadIcon = function (props) { return (_jsx(Icon, __assign({ component: TemplateDownloadIconSvg }, props))); };
