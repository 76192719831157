import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined, DownOutlined, MinusOutlined, UpOutlined, } from '@ant-design/icons';
import { Button, Divider, Popover, Progress, Space, Tag } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { scheduleRuleColorSet, RuleType, scheduleRulesCheckColorSet } from './Constant';
import ScheduleRulesModal from './ScheduleRulesModal';
import './RulesDrawer.scss';
export var ruleTypeSortOrder = [RuleType.STRICT, RuleType.MODERATE, RuleType.MILD];
var RulesDrawer = function (_a) {
    var closeDrawer = _a.closeDrawer, matchSummary = _a.constraintMatchSummary, shiftCounts = _a.shiftCounts;
    var _b = useState(false), rulesModalVisible = _b[0], setRulesModalVisible = _b[1];
    var _c = useState(false), requirementsMetListVisible = _c[0], setRequirementsMetListVisible = _c[1];
    var _d = shiftCounts !== null && shiftCounts !== void 0 ? shiftCounts : {
        assignedShiftCount: 0,
        totalShiftCount: 0,
        validShiftCount: 0,
    }, assignedShiftCount = _d.assignedShiftCount, totalShiftCount = _d.totalShiftCount, validShiftCount = _d.validShiftCount;
    var totalShiftCountPercentage = useCallback(function (numerator, label, popoverLabel) {
        var percent = Math.floor((numerator / totalShiftCount) * 100) || 0;
        var shiftCountPopover = (_jsxs("div", { children: [_jsx("span", { className: "BoldText", children: numerator }), " out of", ' ', _jsx("span", { className: "BoldText", children: totalShiftCount }), " ", popoverLabel] }));
        return (_jsxs("div", { className: "RightSpacing", children: [_jsxs(Space, { align: "baseline", children: [_jsxs("span", { className: "Percentage BoldText", children: [percent, "%"] }), _jsx("span", { className: "PercentageLabel", children: label })] }), _jsx(Popover, { content: shiftCountPopover, placement: "right", children: _jsx(Progress, { percent: percent, showInfo: false }) })] }));
    }, [totalShiftCount]);
    var constraintMatchSummary = useMemo(function () {
        if (matchSummary) {
            // Remove null/unused constraints
            var constraintSummary = Object.fromEntries(Object.entries(matchSummary).filter(function (_a) {
                var value = _a[1];
                return value !== null;
            }));
            // Sort by rule type
            return Object.fromEntries(Object.entries(constraintSummary).sort(function (_a, _b) {
                var a = _a[1];
                var b = _b[1];
                return ruleTypeSortOrder.indexOf(a.type) - ruleTypeSortOrder.indexOf(b.type);
            }));
        }
        else {
            return {};
        }
    }, [matchSummary]);
    var requirementsMetNames = useMemo(function () {
        var names = [];
        Object.keys(constraintMatchSummary).map(function (key) {
            if (constraintMatchSummary[key].matchCount === 0) {
                names.push(constraintMatchSummary[key].ruleDisplayName);
            }
        });
        return names;
    }, [constraintMatchSummary]);
    var requirementsMetHeader = useMemo(function () {
        var requirementsMetCount = requirementsMetNames.length;
        return (_jsxs("div", { className: "RequirementsMetHeader", children: [_jsxs("span", { className: "BoldText", children: [requirementsMetCount, " Requirement", requirementsMetCount > 1 ? 's' : '', " Met"] }), !!requirementsMetCount && (_jsx(_Fragment, { children: !requirementsMetListVisible ? (_jsx(DownOutlined, { className: "ArrowButton", onClick: function () { return setRequirementsMetListVisible(true); } })) : (_jsx(UpOutlined, { className: "ArrowButton", onClick: function () { return setRequirementsMetListVisible(false); } })) })), _jsx(Tag, { className: "RequirementsMetTag", color: requirementsMetCount
                        ? scheduleRulesCheckColorSet.SUCCESS
                        : scheduleRulesCheckColorSet.ERROR, children: requirementsMetCount ? _jsx(CheckOutlined, {}) : _jsx(CloseOutlined, {}) })] }));
    }, [requirementsMetListVisible, requirementsMetNames.length]);
    var requirementsMetList = useMemo(function () {
        return (_jsx("div", { className: "RuleConstraintList", children: requirementsMetNames.map(function (name) {
                return (_jsxs("div", { className: "RuleConstraintItem", children: [_jsx("div", { children: name }), _jsx(Tag, { className: "ConstraintTag", color: scheduleRulesCheckColorSet.SUCCESS, children: _jsx(CheckOutlined, {}) })] }, name));
            }) }));
    }, [requirementsMetNames]);
    var indictmentList = useMemo(function () {
        return (_jsx("div", { className: "RuleConstraintList", children: Object.keys(constraintMatchSummary).map(function (key) {
                var _a;
                if (constraintMatchSummary[key] && ((_a = constraintMatchSummary[key]) === null || _a === void 0 ? void 0 : _a.matchCount)) {
                    var _b = constraintMatchSummary[key], type = _b.type, matchCount = _b.matchCount, ruleDisplayName = _b.ruleDisplayName;
                    return (_jsxs("div", { className: "RuleConstraintItem", children: [_jsx("h4", { children: ruleDisplayName }), _jsx(Tag, { className: "ConstraintTag", color: scheduleRuleColorSet[type], children: matchCount })] }, key));
                }
            }) }));
    }, [constraintMatchSummary]);
    return (_jsxs("div", { className: "RulesDrawerWrapper", children: [_jsxs("div", { className: "RulesHeaderWrapper", children: [_jsx("span", { className: "DrawerTitle", children: "Scheduling Analysis" }), _jsx(Button, { className: "CloseButton", icon: _jsx(MinusOutlined, {}), onClick: closeDrawer })] }), _jsx(Divider, {}), _jsxs("div", { className: "RulesContentContainer", children: [totalShiftCount ? (_jsxs(_Fragment, { children: [totalShiftCountPercentage(assignedShiftCount, 'Shifts Assigned', 'shifts assigned'), _jsx(Divider, {}), totalShiftCountPercentage(validShiftCount, 'Meet Requirements', 'shifts meet requirements'), _jsx(Divider, {}), Object.keys(constraintMatchSummary).length ? (_jsxs(_Fragment, { children: [requirementsMetHeader, _jsx(Divider, {}), requirementsMetListVisible && (_jsxs(_Fragment, { children: [requirementsMetList, _jsx(Divider, {})] }))] })) : (_jsxs("div", { className: "Placeholder", children: [_jsx("span", { className: "Header", children: "No Rules" }), _jsx("span", { className: "SubText", children: "Add rules below to activate your schedule validator." })] }))] })) : (_jsxs("div", { className: "Placeholder", children: [_jsx("span", { className: "Header", children: "No Shifts" }), _jsx("span", { className: "SubText", children: "Add upcoming shifts to view your scheduling analysis." })] })), indictmentList] }), _jsx(Button, { className: "EditRulesButton RightSpacing", type: "primary", onClick: function () { return setRulesModalVisible(true); }, children: "Edit Rules" }), rulesModalVisible && (_jsx(ScheduleRulesModal, { closeModal: function () { return setRulesModalVisible(false); } }))] }));
};
export default React.memo(RulesDrawer);
