import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Carousel } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import HelpActionWrapper from '../Header/HelpDrawer/HelpActionWrapper';
import ZiraRowsSkeleton from '../../../ui/ZiraRowsSkeleton';
var IntercomWhatsNewWidget = function () {
    var useGetWhatsNewArticleCollection = useDashboardApi().useGetWhatsNewArticleCollection;
    var _a = useGetWhatsNewArticleCollection(), data = _a.data, isLoading = _a.isLoading;
    var articlesCarouselRef = useRef(null);
    var _b = useState(0), cardIndex = _b[0], setCardIndex = _b[1];
    var goNextCard = useCallback(function () { return setCardIndex(function (prev) { return prev + 1; }); }, []);
    var goPrevCard = useCallback(function () { return setCardIndex(function (prev) { return prev - 1; }); }, []);
    if (isLoading) {
        return (_jsx("div", { className: "WhatsNewCarouselContainer w-full rounded-[15px] bg-white p-5 shadow-block", children: _jsx(ZiraRowsSkeleton, { rowNumber: 8 }) }));
    }
    if (!data)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "WhatsNewCarouselContainer w-full rounded-[15px] bg-white shadow-block", children: [cardIndex !== 0 && (_jsx(Button, { className: "LeftBtn", icon: _jsx(LeftOutlined, {}), onClick: function () {
                    var _a;
                    (_a = articlesCarouselRef === null || articlesCarouselRef === void 0 ? void 0 : articlesCarouselRef.current) === null || _a === void 0 ? void 0 : _a.prev();
                    goPrevCard();
                } })), cardIndex !== data.articles.length - 1 && (_jsx(Button, { className: "RightBtn", icon: _jsx(RightOutlined, {}), onClick: function () {
                    var _a;
                    (_a = articlesCarouselRef === null || articlesCarouselRef === void 0 ? void 0 : articlesCarouselRef.current) === null || _a === void 0 ? void 0 : _a.next();
                    goNextCard();
                } })), _jsx(Carousel, { ref: articlesCarouselRef, children: data.articles.map(function (article) { return (_jsxs("div", { children: [_jsx("img", { src: article.image, className: "mb-5" }), _jsxs("div", { className: "ArticleInfoContainer", children: [_jsx("div", { className: "mb-2  text-xl font-semibold", children: article.title }), _jsx("div", { className: "mb-3 text-sm font-medium text-gray-500", children: article.description }), _jsx(HelpActionWrapper, { articleId: article.id, children: _jsx("div", { className: "cursor-pointer rounded-[15px] border-2 border-gray-50 p-3 text-center text-[16px] font-semibold", children: "Learn More" }) })] })] }, article.id)); }) })] }));
};
export default React.memo(IntercomWhatsNewWidget);
