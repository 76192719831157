import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Input, Row } from 'antd';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import UserImgSrc from '../../../../utils/icons/images/userimg.png';
import BreakRule from './BreakRule';
import '../TimeOff/TimeOffPolicy.scss';
var BreakPolicy = function (props) {
    var _a = CompliancePageState.useContainer(), changeBreakPolicyIntoDefault = _a.changeBreakPolicyIntoDefault, deleteBreakPolicy = _a.deleteBreakPolicy, changeBreakPolicyName = _a.changeBreakPolicyName, addBreakRule = _a.addBreakRule;
    var policy = props.policy, locationCount = props.locationCount;
    var policyId = policy.policyId;
    return (_jsxs("div", { className: "TimeOffPolicyGroup", children: [_jsxs(Row, { className: "TimeoffPolicyHeaderRow", children: [_jsx(Col, { span: 10, children: _jsx(Input, { className: "PolicyNameInput", placeholder: "Enter a name for this group", value: policy.policyName, onChange: function (e) { return changeBreakPolicyName(policyId, e.target.value); } }) }), _jsx(Col, { span: 14, className: "ComplianceDeleteGroup", children: _jsx("div", { className: "GroupRuleDeleteBtn", onClick: function () { return deleteBreakPolicy(policyId); }, children: _jsx(TrashOutlinedIcon, {}) }) })] }), _jsxs("div", { className: "PolicyWrapper", children: [_jsx(Row, { className: "TimeOffPolicyHeader", children: _jsxs(Col, { flex: "250px", className: "TimeOffNameStatus", children: [_jsx("div", { className: "w-[215px]", children: "Break Name" }), _jsx("div", { children: "Rule" })] }) }), policy.rules.map(function (rule) {
                        return _jsx(BreakRule, { rule: rule, policyId: policyId }, rule.id);
                    }), _jsx(Button, { className: "AddSectionRuleBtn", onClick: function () { return addBreakRule(policyId); }, children: "Add Break to Policy" }), _jsxs("div", { className: "ApplyToUsersWrapper", children: [_jsxs("div", { className: "LeftSection", children: [_jsx("img", { src: UserImgSrc }), _jsxs("span", { className: "TextHolder", children: [_jsxs("div", { className: "MiniHeader", children: ["Applied to ", locationCount !== null && locationCount !== void 0 ? locationCount : 0, " locations"] }), _jsx("div", { className: "Subtext", children: "How do I apply this policy to specific locations?" })] })] }), _jsx("div", { className: "RightSection", children: policy.isDefault ? (_jsx("div", { className: "DefaultButton", children: "Default" })) : (_jsx("div", { className: "WhiteButton", onClick: function () { return changeBreakPolicyIntoDefault(policyId); }, children: "Make Default" })) })] })] })] }));
};
export default React.memo(BreakPolicy);
