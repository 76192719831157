import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Button } from 'antd';
import React from 'react';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { useSetting } from '../../../hooks/api/useSettingApi';
import SettingsContentHeader from '../SettingsContentHeader';
import './BillingPage.scss';
var BillingPage = function () {
    var _a;
    var accountId = useRecoilValue(accountIdState);
    var _b = useSetting(), useGetBillableSeatsData = _b.useGetBillableSeatsData, useCreateBillingPortal = _b.useCreateBillingPortal;
    var billableSeatsData = useGetBillableSeatsData(accountId).data;
    var createBillingPortalMutation = useCreateBillingPortal();
    var goToPortal = useCallback(function () {
        createBillingPortalMutation.mutate({ accountId: accountId }, {
            onSuccess: function (resp) {
                if (resp === null || resp === void 0 ? void 0 : resp.portalUrl) {
                    location.assign(resp.portalUrl);
                }
            },
        });
    }, [accountId, createBillingPortalMutation]);
    return (_jsxs("div", { className: "BillingPageContainer", children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Billing" }) }), _jsx("div", { className: "SectionWrapper", children: _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [_jsx("div", { className: "SectionTitle", children: "Billing" }), _jsx("div", { className: "SectionSubTitle", children: "Manage your payment method and billing information here" }), _jsxs("div", { className: "BillingMetricCard", children: [_jsx("div", { className: "BillingMetricCardTitle", children: (_a = ' ' + (billableSeatsData === null || billableSeatsData === void 0 ? void 0 : billableSeatsData.totalBillableSeats.toLocaleString())) !== null && _a !== void 0 ? _a : 0 }), _jsx("div", { className: "BillingMetricCardDescription", children: "Active Users" })] }), _jsx(Button, { className: "ManageBillingBtn", onClick: function () { return goToPortal(); }, children: "Manage Payment Details" })] }) })] }));
};
export default React.memo(BillingPage);
