import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTemplateComponent } from 'src/hooks/component/useTemplate';
import { useSetRecoilState } from 'recoil';
import { scheduleIsDraggingState } from 'src/state';
import { DragHandlerIcon } from '../../utils/icons/DragHandlerIcon';
import ZiraDeletePopconfirm from '../../ui/ZiraDeletePopconfirm';
import './TemplateCard.scss';
import TemplateEditModal from './TemplateEditModal';
var TemplateCard = function (props) {
    var template = props.template, onDragTemplateEnd = props.onDragTemplateEnd, timezone = props.timezone, blockId = props.blockId;
    var _a = useState(false), isDropdownVisible = _a[0], setDropdownVisible = _a[1];
    var _b = useState(false), isTemplateModalVisible = _b[0], setTemplateModalVisible = _b[1];
    var _c = useTemplateComponent(blockId), setIsDraggingTemplate = _c.setIsDraggingTemplate, setDraggingTemplate = _c.setDraggingTemplate, deleteTemplate = _c.deleteTemplate;
    var setIsDragging = useSetRecoilState(scheduleIsDraggingState(blockId));
    var _d = useDrag(function () { return ({
        type: 'TemplateCard',
        collect: function (monitor) {
            var isDragging = !!monitor.isDragging();
            if (isDragging) {
                setIsDragging(true);
            }
            return { isDragging: isDragging };
        },
        item: { template: template },
        end: function (_, monitor) {
            if (monitor.didDrop()) {
                setTimeout(function () { return onDragTemplateEnd(template); }, 0);
            }
            setIsDragging(false);
        },
    }); }), isDragging = _d[0].isDragging, drag = _d[1];
    useEffect(function () {
        if (isDragging) {
            setDraggingTemplate(template);
        }
        setIsDraggingTemplate(isDragging);
    }, [isDragging, setDraggingTemplate, setIsDraggingTemplate, template]);
    var handleEditTemplate = useCallback(function () {
        setTemplateModalVisible(true);
        setDropdownVisible(false);
    }, []);
    var handleDeleteTemplate = useCallback(function () {
        deleteTemplate(template.id);
        setDropdownVisible(false);
    }, [deleteTemplate, template]);
    return useMemo(function () {
        var menu = (_jsxs(Menu, { className: "ShiftCardDropdownMenu", children: [_jsx(Menu.Item, { className: "menuItem", onClick: handleEditTemplate, children: "Edit Template" }, "edit"), _jsx(Menu.Divider, {}), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this template?", onConfirm: handleDeleteTemplate, placement: "bottom", children: _jsx(Menu.Item, { className: "menuItem text-negative", children: "Delete Template" }, "delete") })] }));
        return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: drag, className: "TemplateCardContainer", children: [_jsx(DragHandlerIcon, {}), _jsxs("div", { className: "TemplateCardContent", children: [_jsx("span", { className: "TemplateName", children: template.name }), _jsx("span", { className: "TemplateShift", children: "".concat(template.numberOfShifts, " ") +
                                        (template.numberOfShifts > 1 ? 'shifts' : 'shift') })] }), _jsx("span", { onClick: function () {
                                if (!isDropdownVisible)
                                    setDropdownVisible(true);
                            }, children: _jsx(Dropdown, { overlay: menu, open: isDropdownVisible, children: _jsx(EllipsisOutlined, { className: "Ellipsis" }) }) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                                setDropdownVisible(false);
                            }, style: { display: isDropdownVisible ? 'block' : 'none' } })] }), _jsx(TemplateEditModal, { isModalVisible: isTemplateModalVisible, setIsModalVisible: setTemplateModalVisible, template: template, timezone: timezone, blockId: blockId })] }));
    }, [
        blockId,
        drag,
        handleDeleteTemplate,
        handleEditTemplate,
        isDropdownVisible,
        isTemplateModalVisible,
        template,
        timezone,
    ]);
};
export default React.memo(TemplateCard);
