var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ViewEyeOutlinedSvg = function () { return (_jsxs("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "20", height: "20", rx: "8", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.94867 10C6.23699 11.6796 8.05073 12.6667 9.99922 12.6667C11.9477 12.6667 13.7614 11.6796 15.0498 10C13.7614 8.32041 11.9477 7.33333 9.99922 7.33333C8.05073 7.33333 6.23699 8.32041 4.94867 10ZM3.55474 9.65527C3.41405 9.86368 3.41405 10.1363 3.55474 10.3447C5.0577 12.5711 7.3858 14 9.99922 14C12.6126 14 14.9407 12.5711 16.4437 10.3447C16.5844 10.1363 16.5844 9.86368 16.4437 9.65527C14.9407 7.42891 12.6126 6 9.99922 6C7.3858 6 5.0577 7.42891 3.55474 9.65527Z", fill: "#00A76F" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z", fill: "#00A76F" })] })); };
export var ViewEyeOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: ViewEyeOutlinedSvg }, props))); };
