var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Row } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
var MobileCustomJobPlacementDraggableItem = function (_a) {
    var _b;
    var field = _a.field, index = _a.index, onToggle = _a.onToggle;
    return (_jsx(Draggable, { draggableId: ((_b = field.schema) === null || _b === void 0 ? void 0 : _b.id) || field.type, index: index, children: function (provided) {
            var _a;
            return (_jsxs(Row, __assign({}, provided === null || provided === void 0 ? void 0 : provided.draggableProps, { ref: provided === null || provided === void 0 ? void 0 : provided.innerRef, onClick: function (e) { return e.stopPropagation(); }, align: "middle", justify: "space-between", children: [provided && (_jsxs("div", __assign({}, provided.dragHandleProps, { className: "flex flex-row gap-1 items-center font-medium pr-3 cursor-grab", children: [_jsx(DnDHandleIcon, {}), _jsx("p", { className: "truncate", children: (_a = field.schema) === null || _a === void 0 ? void 0 : _a.name })] }))), onToggle && _jsx(Checkbox, { onChange: function () { return onToggle(field); }, defaultChecked: true })] })));
        } }));
};
export default MobileCustomJobPlacementDraggableItem;
