var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ModalCellWrapper from 'src/components/OptimizedTableView/Cell/ModalCellWrapper/ModalCellWrapper';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { CollectionTableType } from 'src/shared';
import { DragHandlerIcon } from 'src/utils/icons/DragHandlerIcon';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import '../ProfileDataTypeTask/ProfileDataTaskSchemaRow.scss';
import TaskTemplateCustomFieldVisibilityToggle from './TaskTemplateCustomFieldVisibilityToggle';
var TaskTemplateCustomFieldRow = function (_a) {
    var customField = _a.customField, schema = _a.schema, taskCollectionId = _a.taskCollectionId, dataSourceRid = _a.dataSourceRid, index = _a.index, onEditModalCell = _a.onEditModalCell, onToggleTaskSchemaVisibility = _a.onToggleTaskSchemaVisibility, onRemoveTaskSchema = _a.onRemoveTaskSchema;
    return (_jsx(Draggable, { draggableId: schema.id, index: index, children: function (provided) { return (_jsx("div", __assign({ className: "DraggableItem", ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { onMouseDown: function (e) { return e.currentTarget.focus(); }, children: _jsxs("div", { className: "SchemaRow", children: [_jsx(DragHandlerIcon, { className: "flex items-center mr-2" }), _jsxs("div", { className: "flex w-full items-center justify-between min-w-0 TaskRowWrapper", children: [_jsx(ModalCellWrapper, { cellPos: {
                                    collectionId: taskCollectionId,
                                    recordId: dataSourceRid,
                                    schemaId: schema.id,
                                }, collectionTableType: CollectionTableType.TASK_TEMPLATE_MODAL, editCell: onEditModalCell, style: {
                                    border: 'none',
                                    margin: '0',
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                } }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx(TaskTemplateCustomFieldVisibilityToggle, { visible: customField.visible, onToggle: function () { return onToggleTaskSchemaVisibility(schema.id); } }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete ".concat(schema.name, " field?"), onConfirm: function () { return onRemoveTaskSchema(schema.id); }, placement: "leftBottom", children: _jsx(ZiraTooltip, { title: "Delete Field", children: _jsx("div", { className: "rounded-lg p-1 hover:cursor-pointer hover:bg-gray-60 border-2 border-[#f4f4f5] mr-2", children: _jsx(DeleteOutlinedIcon, { fill: "black" }) }) }) })] })] })] }) }))); } }));
};
export default React.memo(TaskTemplateCustomFieldRow);
