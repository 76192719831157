import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import CollectionViewCountBadge from './CollectionViewCountBadge';
var CollectionViewTabs = function (_a) {
    var selectedViewId = _a.selectedViewId, orderedViews = _a.orderedViews, recordCounts = _a.recordCounts, viewIdInRename = _a.viewIdInRename, _b = _a.canEditViews, canEditViews = _b === void 0 ? false : _b, _c = _a.isCanvasPageType, isCanvasPageType = _c === void 0 ? false : _c, _d = _a.showRecordCount, showRecordCount = _d === void 0 ? false : _d, setNewViewName = _a.setNewViewName, onViewChange = _a.onViewChange, onAddView = _a.onAddView, onUpdateViewName = _a.onUpdateViewName, onUpdateViewOrder = _a.onUpdateViewOrder, viewIdMenuOpen = _a.viewIdMenuOpen, setViewIdMenuOpen = _a.setViewIdMenuOpen, viewMenuProps = _a.viewMenuProps;
    var tabItems = orderedViews.map(function (_a) {
        var viewId = _a.viewId, name = _a.name;
        return ({
            key: viewId,
            label: (_jsx(ZiraDropdown, { menu: viewMenuProps(viewId), trigger: selectedViewId === viewId && canEditViews ? ['click'] : [], open: viewId === viewIdMenuOpen, onLocalVisibleChange: function (open) { return setViewIdMenuOpen(open ? viewId : ''); }, destroyPopupOnHide: true, children: viewIdInRename === viewId ? (_jsx(Input, { className: "w-full min-w-[100px] px-[8px] py-0 text-base text-black", placeholder: "Enter a view name", defaultValue: name, onBlur: function () { return onUpdateViewName(viewId); }, onPressEnter: function () { return onUpdateViewName(viewId); }, onChange: function (e) { return setNewViewName(e.target.value); }, onKeyDown: function (e) {
                        // Fixes "Space" inside input
                        e.code === 'Space' ? e.stopPropagation() : null;
                    }, onFocus: function (e) { return e.target.select(); }, bordered: false, autoFocus: true })) : (_jsxs("div", { className: "flex px-[8px] text-base", children: [_jsx("span", { children: name }), showRecordCount && (_jsx(CollectionViewCountBadge, { count: recordCounts === null || recordCounts === void 0 ? void 0 : recordCounts[viewId] }))] })) }, viewId)),
        });
    });
    // To position Add View button (prevents glitchy tabs)
    var tabsRef = useRef(null);
    var _e = useState(0), tabsWidth = _e[0], setTabsWidth = _e[1];
    useEffect(function () {
        if (!(tabsRef === null || tabsRef === void 0 ? void 0 : tabsRef.current))
            return;
        var tabsObserver = new ResizeObserver(function (entries) {
            var _a, _b, _c, _d;
            if (entries[0].target) {
                var visualTabsWidth = (_b = (_a = entries[0].target.querySelector('.ant-tabs')) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0;
                var actualTabsWidth = (_d = (_c = entries[0].target.querySelector('.ant-tabs-nav-list')) === null || _c === void 0 ? void 0 : _c.clientWidth) !== null && _d !== void 0 ? _d : 0;
                setTabsWidth(Math.min(visualTabsWidth, actualTabsWidth));
            }
        });
        tabsObserver.observe(tabsRef.current);
        return function () {
            tabsObserver.disconnect();
        };
    }, [orderedViews, recordCounts, viewIdInRename]);
    return (_jsxs("div", { className: "CollectionViewTabs", ref: tabsRef, children: [_jsxs(DragDropContext, { onDragEnd: function (dropResult) {
                    if (dropResult.destination) {
                        onUpdateViewOrder(dropResult.draggableId, dropResult.destination.index);
                    }
                }, children: [_jsx(Tabs, { activeKey: selectedViewId, onChange: onViewChange, style: { width: 'calc(100% - 32px)' }, items: tabItems, moreIcon: _jsx(EllipsisOutlined, {}) }), canEditViews && (_jsx(ZiraTooltip, { title: "Add View", children: _jsx("div", { className: "absolute grid h-[32px] w-[32px] cursor-pointer place-items-center rounded text-base text-gray-400 hover:bg-gray-50", style: { left: tabsWidth }, onClick: function () { return onAddView(); }, children: _jsx(PlusOutlined, {}) }) }))] }), isCanvasPageType && (_jsx("div", { className: "absolute bottom-[-2px] w-full border-b-2 border-gray-50" }))] }));
};
export default React.memo(CollectionViewTabs);
