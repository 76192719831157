var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Select, Form, Divider, Switch } from 'antd';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { useAccountApi, usePageApi } from 'src/hooks/api';
import { CollectionType } from 'src/shared';
import { getIntegrationIcon, getIpDisplayText } from 'src/utils/Account';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import ExportIntegrationUnconnectedPartner from './ExportIntegrationUnconnectedPartner';
var Option = Select.Option;
var ExportInvoiceIntegrationContent = function (_a) {
    var setIsModalVisible = _a.setIsModalVisible, setIsLoading = _a.setIsLoading;
    var _b = useParams().payPeriodId, payPeriodId = _b === void 0 ? '' : _b;
    var navigate = useNavigate();
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var _c = usePageApi(), useGetAvailableInvoiceIp = _c.useGetAvailableInvoiceIp, useSendInvoice = _c.useSendInvoice;
    var formInstance = Form.useForm()[0];
    var _d = useState(), selectedPartner = _d[0], setSelectedPartner = _d[1];
    var partnerDisplayName = getIpDisplayText(selectedPartner);
    var partnerDisplayIcon = getIntegrationIcon(selectedPartner);
    var _e = useGetAvailableInvoiceIp(), invoiceIpData = _e.data, isLoading = _e.isLoading;
    useEffect(function () {
        if (invoiceIpData === null || invoiceIpData === void 0 ? void 0 : invoiceIpData.connectedPartners.length) {
            setSelectedPartner(invoiceIpData.connectedPartners[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceIpData]);
    useEffect(function () {
        setIsLoading(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    var isConnectedToPartner = !!selectedPartner && !!(invoiceIpData === null || invoiceIpData === void 0 ? void 0 : invoiceIpData.connectedPartners.includes(selectedPartner));
    var shiftSchemas = useGetSchemaColumnsInCollection({
        collectionId: shiftCollectionId !== null && shiftCollectionId !== void 0 ? shiftCollectionId : '',
        options: {
            enabled: !!shiftCollectionId,
        },
    }).data;
    var onSendInvoice = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, requestBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedPartner)
                        return [2 /*return*/];
                    return [4 /*yield*/, formInstance.validateFields()];
                case 1:
                    formValues = _a.sent();
                    requestBody = {
                        payPeriodId: payPeriodId,
                        orderedDescriptionSchemaIds: formValues.selectedSchemas,
                    };
                    setIsLoading(true);
                    useSendInvoice.mutate({ ipName: selectedPartner, body: requestBody }, {
                        onSettled: function () {
                            setIsModalVisible(false);
                            setIsLoading(false);
                            toast('We are processing your invoice export request. Please check your email in a few minutes for the completion status. Thank you.', {
                                type: 'info',
                                position: toast.POSITION.BOTTOM_CENTER,
                            });
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [
        formInstance,
        payPeriodId,
        selectedPartner,
        setIsLoading,
        setIsModalVisible,
        useSendInvoice,
    ]);
    var modalFooter = useMemo(function () {
        return (_jsxs("div", { className: "w-min ml-auto flex space-x-[8px] mt-[-8px] mb-[-16px]", children: [_jsx(Button, { className: "CancelBtn", onClick: function () { return setIsModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "ConfirmBtn", onClick: function (e) {
                        e.stopPropagation();
                        if (isConnectedToPartner) {
                            onSendInvoice();
                            return;
                        }
                        else {
                            navigate("/settings/integrations/".concat(selectedPartner !== null && selectedPartner !== void 0 ? selectedPartner : ''));
                        }
                    }, children: isConnectedToPartner ? "Send to ".concat(partnerDisplayName) : 'Go to Setting' })] }));
    }, [
        isConnectedToPartner,
        navigate,
        onSendInvoice,
        partnerDisplayName,
        selectedPartner,
        setIsModalVisible,
    ]);
    return (_jsxs(Form, { layout: "vertical", form: formInstance, requiredMark: false, children: [_jsx(Form.Item, { name: "selectedInvoicePartner", label: _jsx("div", { className: "SelectorTitle", children: "Please select invoice export partner" }), initialValue: selectedPartner, children: _jsx(Select, { className: "ExportPartnerSelector", value: selectedPartner, onChange: setSelectedPartner, children: invoiceIpData === null || invoiceIpData === void 0 ? void 0 : invoiceIpData.availablePartners.map(function (ip) {
                        return (_jsx(Option, { value: ip.ipInternalName, children: ip.displayName }, ip.ipInternalName));
                    }) }) }), _jsx(Divider, {}), isConnectedToPartner ? (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Shift Fields" }), name: "selectedSchemas", rules: [
                            {
                                required: true,
                                message: 'Please at least select one field.',
                            },
                        ], children: _jsx(Select, { className: "w-full", mode: "multiple", children: shiftSchemas === null || shiftSchemas === void 0 ? void 0 : shiftSchemas.columns.map(function (schema) {
                                return (_jsx(Option, { value: schema.id, children: schema.name }, schema.id));
                            }) }) }), _jsxs(Form.Item, { name: "exportApprovedOnly", className: "ExportSwitch", valuePropName: "checked", children: [_jsx(Switch, {}), _jsx("span", { className: "font-medium ml-3", children: "Export Approved Only" })] }), _jsxs(Form.Item, { name: "markExportPaid", className: "ExportSwitch", valuePropName: "checked", children: [_jsx(Switch, {}), _jsx("span", { className: "font-medium ml-3", children: "Mark Exported as Billed" })] })] })) : (_jsx(ExportIntegrationUnconnectedPartner, { partnerDisplayName: partnerDisplayName, partnerDisplayIcon: partnerDisplayIcon })), _jsx(Divider, {}), modalFooter] }));
};
export default React.memo(ExportInvoiceIntegrationContent);
