import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import ActivityFeedState from 'src/hooks/state/ActivityFeed';
import { useTasksApi } from 'src/hooks/api';
import { selectedTaskGroupIdState, selectedTaskTemplateIdState, taskGroupListState, taskSettingModalVisibleState, taskTemplateListState, } from './atom';
import './TaskSettingModal.scss';
import TasksSettingView from './TasksSettingView/TasksSettingView';
var TaskSettingModal = function () {
    var _a = useRecoilState(taskSettingModalVisibleState), taskSettingModalVisible = _a[0], setTaskSettingModalVisible = _a[1];
    var setTaskGroupList = useSetRecoilState(taskGroupListState);
    var setTaskTemplateList = useSetRecoilState(taskTemplateListState);
    var setSelectedTaskTemplateId = useSetRecoilState(selectedTaskTemplateIdState);
    var setSelectedTaskGroupId = useSetRecoilState(selectedTaskGroupIdState);
    var _b = useState(false), isInitialized = _b[0], setIsInitialized = _b[1];
    //! Fetch tasks
    var useGetTasksOverview = useTasksApi().useGetTasksOverview;
    var data = useGetTasksOverview({ enabled: taskSettingModalVisible || !isInitialized }).data;
    useEffect(function () {
        var _a, _b;
        if (data) {
            setTaskTemplateList(data.tasks);
            setTaskGroupList(data.taskGroups);
            setSelectedTaskTemplateId((_a = data.tasks[0]) === null || _a === void 0 ? void 0 : _a.id);
            setSelectedTaskGroupId((_b = data.taskGroups[0]) === null || _b === void 0 ? void 0 : _b.id);
            setIsInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (_jsx(Modal, { className: "TaskSettingModal", open: taskSettingModalVisible, closable: true, width: "100%", centered: true, footer: null, onCancel: function () { return setTaskSettingModalVisible(false); }, destroyOnClose: true, children: _jsx(ActivityFeedState.Provider, { children: _jsx(TasksSettingView, {}) }) }));
};
export default React.memo(TaskSettingModal);
