import { atom } from 'recoil';
var needsOrCoverageInTransactionState = atom({
    key: 'needsOrCoverageInTransactionState',
    default: false,
});
var currentNeedOrCoverageBeingAppliedState = atom({
    key: 'currentNeedOrCoverageBeingAppliedState',
    default: null,
});
export { needsOrCoverageInTransactionState, currentNeedOrCoverageBeingAppliedState };
