var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var NameInputPenSvg = function () { return (_jsxs("svg", { width: "30", height: "30", viewBox: "0 0 30 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M17.8896 9.1102L19.1834 7.81644C19.7714 7.22848 19.7744 6.27668 19.1901 5.69324L18.3066 4.80972C17.7231 4.2262 16.7781 4.22176 16.1834 4.81644L14.8896 6.1102L17.8896 9.1102Z", fill: "black", fillOpacity: "0.2" }), _jsx("path", { d: "M5.36023 15.6398L5.25 15.75L4.5 19.5L8.25 18.75L8.36023 18.6398L5.36023 15.6398Z", fill: "black", fillOpacity: "0.2" }), _jsx("path", { d: "M5.89062 15.1096L14.361 6.6392L17.361 9.6392L8.89062 18.1096L5.89062 15.1096Z", fill: "black", fillOpacity: "0.2" })] })); };
export var NameInputPenIcon = function (props) { return (_jsx(Icon, __assign({ component: NameInputPenSvg }, props))); };
