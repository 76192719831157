import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { tableCollectionCurrentSizeSelector } from 'src/state';
var RecordsCountSummary = function (_a) {
    var blockId = _a.blockId;
    var recordCount = useRecoilValue(tableCollectionCurrentSizeSelector(blockId));
    return (_jsxs("div", { className: "TableSummary", children: ["Count", _jsx("span", { children: recordCount })] }));
};
export default React.memo(RecordsCountSummary);
