import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Switch, Tooltip } from 'antd';
import React, { useState } from 'react';
var NeedsCoverageAutoApplyConfirmModal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, onClose = _a.onClose;
    var _b = useState(true), autoApply = _b[0], setAutoApply = _b[1];
    return (_jsx(Modal, { className: "NeedsCoverageTemplateDetailModal", open: isOpen, closable: false, zIndex: 1002, centered: true, onCancel: function () { return setIsOpen(false); }, onOk: function () { return onClose(autoApply); }, maskClosable: false, title: "Auto Apply Settings", children: _jsx("div", { children: _jsx("div", { className: "flex items-start space-x-3", children: _jsxs("div", { className: "pt-2", children: [_jsxs("div", { className: "font-medium", children: [_jsx("span", { className: "font-semibold underline", children: "Auto apply" }), _jsx(Tooltip, { title: _jsxs("div", { className: "text-[13px] font-medium", children: ["Auto apply will automatically schedule tasks to create shifts based on the created template. ", _jsx("br", {}), "For the first time applied, it will create 8 weeks of shifts ahead. After that, it will create 6 weeks of shifts ahead every week."] }), color: "black", placement: "bottom", children: _jsx(QuestionCircleOutlined, { className: "mx-1" }) }), "will be enabled for this template by default. ", _jsx("br", {}), "If you would like to turn off auto apply, please switch the toggle below."] }), _jsxs("div", { className: "mt-5 flex items-end space-x-2", children: [_jsx(Switch, { checked: autoApply, onChange: function (v) { return setAutoApply(v); } }), _jsx("span", { className: "font-medium", children: "Auto Apply" })] })] }) }) }) }));
};
export default React.memo(NeedsCoverageAutoApplyConfirmModal);
