import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { LinkOutlined } from '@ant-design/icons';
import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useAccountHelpContentApi } from 'src/hooks/api/useAccountHelpContentApi';
import { helpDrawerSelectedArticleIdState } from 'src/state/selectors/withHelpDrawerState';
var HelpArticleView = function (_a) {
    var goToRelatedArticle = _a.goToRelatedArticle, relatedArticles = _a.relatedArticles;
    var useGetHelpArticleContent = useAccountHelpContentApi().useGetHelpArticleContent;
    var selectedArticleId = useRecoilValue(helpDrawerSelectedArticleIdState);
    var _b = useGetHelpArticleContent({
        articleId: selectedArticleId !== null && selectedArticleId !== void 0 ? selectedArticleId : '',
        options: {
            enabled: !!selectedArticleId,
        },
    }), helpArticleContent = _b.data, isLoading = _b.isLoading;
    if (isLoading || !helpArticleContent) {
        return (_jsx("div", { className: "px-5", children: _jsx(Skeleton, { active: true, className: "mt-5" }) }));
    }
    return (_jsxs("div", { className: "ArticleContentContainer px-5", children: [_jsxs("div", { className: "mb-5 flex w-full items-center", children: [_jsx("div", { className: "text-lg font-semibold", children: helpArticleContent.title }), helpArticleContent.url && (_jsx("div", { className: "ml-5", children: _jsx(Tooltip, { title: _jsx("div", { className: "text-[13px] font-medium", children: "Go to article page" }), color: "black", placement: "bottom", overlayClassName: "HelpArticleUrlTooltip", children: _jsx("a", { href: helpArticleContent.url, target: "_blank", rel: "noopener noreferrer", children: _jsx(LinkOutlined, {}) }) }) }))] }), _jsx("div", { className: "mb-5", dangerouslySetInnerHTML: {
                    __html: helpArticleContent.content,
                } }), relatedArticles.length !== 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-2 text-base font-medium", children: "Related Articles:" }), relatedArticles.map(function (article) { return (_jsx("div", { className: "mb-1 cursor-pointer hover:underline hover:underline-offset-1", onClick: function () { return goToRelatedArticle(article); }, children: "".concat(article.title, " \u2192") }, article.id)); })] }))] }));
};
export default React.memo(HelpArticleView);
