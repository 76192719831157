import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { EmitterEventType } from 'src/shared';
import { useScheduleApi } from 'src/hooks/api';
import { useTimeZone } from 'src/hooks/component';
import { timeOffRecordForUnassignShiftsState } from 'src/state/atoms/unassignShiftsOnTimeOffApprovalModalState';
import Emitter from 'src/utils/EventEmitter';
import './UnassignShiftsOnTimeOffApprovalModal.scss';
var UnassignShiftsOnTimeOffApprovalModal = function () {
    var useUnassignShiftsOnTimeoffApproval = useScheduleApi().useUnassignShiftsOnTimeoffApproval;
    var _a = useTimeZone(), timeFormatFn = _a.timeFormatFn, timezoneAbbr = _a.timezoneAbbr;
    var _b = useRecoilState(timeOffRecordForUnassignShiftsState), timeOffRecordInfo = _b[0], setTimeOffRecordInfo = _b[1];
    var onApply = useCallback(function () {
        if (timeOffRecordInfo === null || timeOffRecordInfo === void 0 ? void 0 : timeOffRecordInfo.recordId) {
            useUnassignShiftsOnTimeoffApproval.mutate(timeOffRecordInfo.recordId, {
                onSuccess: function () {
                    setTimeOffRecordInfo(undefined);
                    // refetch schedule collection when unassignment is done
                    Emitter.emit(EmitterEventType.REFRESH_SCHEDULE, null);
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTimeOffRecordInfo, timeOffRecordInfo]);
    var modalFooter = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "CancelBtn", onClick: function () { return setTimeOffRecordInfo(undefined); }, children: "No" }), _jsx(Button, { className: "ConfirmBtn", onClick: onApply, children: "Yes" })] }));
    }, [onApply, setTimeOffRecordInfo]);
    return (_jsx(Modal, { open: true, centered: true, footer: modalFooter, closable: false, className: "TimeOffApprovalModalForUnassignShift", width: 500, children: timeOffRecordInfo && (_jsxs("div", { className: "mt-5", children: [_jsx("div", { className: "mb-5", children: "".concat(timeOffRecordInfo.userFullName, " has ").concat(timeOffRecordInfo.overlappingShiftCount, " shift").concat(timeOffRecordInfo.overlappingShiftCount > 1 ? 's' : '', " from ").concat(timeFormatFn(timeOffRecordInfo.startAt, 'MMM D, h:mm A', false), " to ").concat(timeFormatFn(timeOffRecordInfo.endAt, 'MMM D, h:mm A', false), " \n                        ").concat(timezoneAbbr(true), ".") }), _jsxs("div", { className: "flex items-center font-semibold", children: [_jsx(ExclamationCircleFilled, { style: { color: 'red' } }), _jsx("div", { className: "ml-2", children: "Do you want to unassign ".concat(timeOffRecordInfo.overlappingShiftCount > 1 ? 'these' : 'this', " shift").concat(timeOffRecordInfo.overlappingShiftCount > 1 ? 's' : '', " ?") })] })] })) }));
};
export default React.memo(UnassignShiftsOnTimeOffApprovalModal);
