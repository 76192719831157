var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { CollectionTableType, CollectionType, defaultColumnWidth, minColumnWidth, NativePath, printColumnWidth, printTitleColumnWidth, } from 'src/shared';
import { collectionTableCellDataBulkSelector, collectionTableNativeSchemaSelector, collectionTableSchemaCanEditMapSelector, collectionTableSchemaCanOnlyUpdateFromEmptyMapSelector, tableCollectionTypeSelector, } from 'src/state';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import TableCellWrapper from './Cell/TableCellWrapper/TableCellWrapper';
import TableBuilder from './TableBuilder';
import OpenButtonForCell from './Cell/OpenButtonForCell';
import ColumnHeader from './ColumnHeader';
import { SELECT_RECORD_COLUMN_ID, SELECT_RECORD_COLUMN_WIDTH, TITLE_COLUMN_ID, TITLE_COLUMN_WIDTH, ADD_NEW_COLUMN_COLUMN_ID, } from './Constants';
import SelectRecordCheckbox from './SelectRecord/SelectRecordCheckbox';
var CollectionTable = function (props) {
    var collectionId = props.collectionId, recordOrders = props.recordOrders, schemaIds = props.schemaIds, columnWidthsMap = props.columnWidthsMap, editCell = props.editCell, clickButtonCell = props.clickButtonCell, addNewColumnButton = props.addNewColumnButton, blockId = props.blockId, reverseRelationId = props.reverseRelationId, addNewRecordButton = props.addNewRecordButton, paginationButton = props.paginationButton, recordsCountSummary = props.recordsCountSummary, paginationLoadingSummary = props.paginationLoadingSummary, hasSelectRecordColumn = props.hasSelectRecordColumn, onEndResize = props.onEndResize, onEndDragAndDrop = props.onEndDragAndDrop, _a = props.isPrintOnly, isPrintOnly = _a === void 0 ? false : _a;
    var schemaCanEditMap = useRecoilValue(collectionTableSchemaCanEditMapSelector(collectionId));
    var schemaCanOnlyUpdateEmptyMap = useRecoilValue(collectionTableSchemaCanOnlyUpdateFromEmptyMapSelector({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
    }));
    var collectionCell = useCallback(function (value, canEdit, canOnlyUpdateEmpty) {
        if (blockId) {
            var hasOpenButton = value.schemaId === schemaIds[0];
            return (_jsxs(_Fragment, { children: [_jsx(TableCellWrapper, { cellPos: value, blockId: blockId, editCell: editCell, clickButtonCell: clickButtonCell, canEdit: canEdit, canOnlyUpdateEmpty: canOnlyUpdateEmpty, collectionTableType: CollectionTableType.TABLE }), hasOpenButton && _jsx(OpenButtonForCell, { blockId: blockId, cellPos: value })] }));
        }
        else {
            // Reverse collection
            var hasOpenButton = value.schemaId === schemaIds[0];
            //! blockId not required since collection can be accessed with CellPos
            return (_jsxs(_Fragment, { children: [_jsx(TableCellWrapper, { cellPos: value, blockId: "", editCell: editCell, clickButtonCell: clickButtonCell, canEdit: canEdit, canOnlyUpdateEmpty: canOnlyUpdateEmpty, collectionTableType: CollectionTableType.TABLE }), hasOpenButton && _jsx(OpenButtonForCell, { blockId: "", cellPos: value })] }));
        }
    }, [blockId, clickButtonCell, editCell, schemaIds]);
    var parseToColumnData = useRecoilCallback(function () { return function () {
        var tempColumns = [];
        if (hasSelectRecordColumn) {
            // Record selection column
            tempColumns.push({
                Header: (_jsx(SelectRecordCheckbox, { blockId: blockId, reverseRelationId: reverseRelationId })),
                accessor: SELECT_RECORD_COLUMN_ID,
                width: SELECT_RECORD_COLUMN_WIDTH,
                sticky: 'left',
                Cell: function (_a) {
                    var value = _a.value;
                    return (_jsx(SelectRecordCheckbox, { blockId: blockId, reverseRelationId: reverseRelationId, recordId: value.recordId }));
                },
            });
        }
        schemaIds.forEach(function (schemaId, index) {
            var _a;
            var canEdit = (_a = schemaCanEditMap[schemaId]) !== null && _a !== void 0 ? _a : false;
            var width;
            if (schemaId == TITLE_COLUMN_ID) {
                if (isPrintOnly) {
                    width = printTitleColumnWidth;
                }
                else {
                    width = TITLE_COLUMN_WIDTH;
                }
            }
            else {
                if (isPrintOnly) {
                    width = printColumnWidth; //
                }
                else {
                    width = columnWidthsMap[schemaId] || defaultColumnWidth;
                }
            }
            tempColumns.push({
                id: schemaId,
                Header: (_jsx(ColumnHeader, { schemaId: schemaId, collectionId: collectionId, blockId: blockId, reverseRelationId: reverseRelationId })),
                accessor: schemaId,
                sticky: index === 0 ? 'left' : undefined,
                minWidth: minColumnWidth,
                width: width,
                Cell: function (_a) {
                    var _b;
                    var value = _a.value;
                    return collectionCell(value, canEdit, (_b = schemaCanOnlyUpdateEmptyMap[schemaId]) !== null && _b !== void 0 ? _b : false);
                },
            });
        });
        if (addNewColumnButton) {
            tempColumns.push({
                Header: addNewColumnButton,
                accessor: ADD_NEW_COLUMN_COLUMN_ID,
                minWidth: 30,
            });
        }
        return tempColumns;
    }; }, [
        addNewColumnButton,
        blockId,
        collectionCell,
        collectionId,
        columnWidthsMap,
        hasSelectRecordColumn,
        reverseRelationId,
        schemaCanEditMap,
        schemaCanOnlyUpdateEmptyMap,
        schemaIds,
        isPrintOnly,
    ]);
    var parseToRecordsData = useMemo(function () {
        var tempRecords = [];
        recordOrders.map(function (recordId) {
            var innerRecord = {};
            innerRecord[SELECT_RECORD_COLUMN_ID] = {
                recordId: recordId,
                schemaId: SELECT_RECORD_COLUMN_ID,
                collectionId: collectionId,
            };
            schemaIds.map(function (schemaId) {
                innerRecord[schemaId] = {
                    recordId: recordId,
                    schemaId: schemaId,
                    collectionId: collectionId,
                };
            });
            tempRecords.push(innerRecord);
        });
        return tempRecords;
        //!IMPORTANT - depends on collectionId, recordOrders, schemaIds only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId, recordOrders, schemaIds]);
    var grayedOutRecordIds = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var collectionType = snapshot
                .getLoadable(tableCollectionTypeSelector(blockId !== null && blockId !== void 0 ? blockId : reverseRelationId))
                .valueMaybe();
            // TODO - Handle other collection types + move logic to selector
            if (collectionType !== CollectionType.TASK)
                return [];
            var taskDismissedSchema = snapshot
                .getLoadable(collectionTableNativeSchemaSelector({
                collectionId: collectionId,
                nativePath: NativePath.TASK_DISMISSED,
            }))
                .valueMaybe();
            if (!taskDismissedSchema)
                return [];
            var cells = recordOrders.map(function (recordId) {
                return {
                    recordId: recordId,
                    schemaId: taskDismissedSchema.id,
                    collectionId: collectionId,
                };
            });
            var collectionCells = snapshot
                .getLoadable(collectionTableCellDataBulkSelector({
                dataSource: {
                    type: CollectionTableType.TABLE,
                    collectionId: collectionId,
                },
                cells: cells,
            }))
                .valueMaybe();
            return collectionCells === null || collectionCells === void 0 ? void 0 : collectionCells.reduce(function (acc, cell, index) {
                var _a, _b, _c;
                if (!cell)
                    return acc;
                // Task is dismissed
                if (((_c = (_b = (_a = Object.values(cell)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) === 'true') {
                    return __spreadArray(__spreadArray([], acc, true), [cells[index].recordId], false);
                }
                return acc;
            }, []);
        };
    }, [blockId, collectionId, recordOrders, reverseRelationId]);
    return (_jsx(TableBuilder, { columns: parseToColumnData(), data: parseToRecordsData, footer: addNewRecordButton, pagination: paginationButton, summary: recordsCountSummary ? recordsCountSummary : paginationLoadingSummary, onEndResize: onEndResize, onEndDragAndDrop: onEndDragAndDrop, isBlock: !!blockId, isPrintOnly: isPrintOnly, grayedOutRecordIds: grayedOutRecordIds() }));
};
export default React.memo(CollectionTable);
