var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var CalendarSvg = function () { return (_jsx("svg", { width: "100%", height: "100%", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.5 7C7.22386 7 7 6.77614 7 6.5V6H4V9H20V6H17V6.5C17 6.77614 16.7761 7 16.5 7H15.5C15.2239 7 15 6.77614 15 6.5V6H9V6.5C9 6.77614 8.77614 7 8.5 7H7.5ZM15 4H9V2.5C9 2.22386 8.77614 2 8.5 2H7.5C7.22386 2 7 2.22386 7 2.5V4H2.5C2.22386 4 2 4.22386 2 4.5V9V11V21.5C2 21.7761 2.22386 22 2.5 22H21.5C21.7761 22 22 21.7761 22 21.5V11V9V4.5C22 4.22386 21.7761 4 21.5 4H17V2.5C17 2.22386 16.7761 2 16.5 2H15.5C15.2239 2 15 2.22386 15 2.5V4ZM20 11V20H4V11H20Z" }) })); };
export var CalendarIcon = function (props) { return (_jsx(Icon, __assign({ component: CalendarSvg }, props))); };
