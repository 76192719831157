var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Divider, Switch } from 'antd';
var ProfileDataTaskTemplateConfiguration = function (_a) {
    var taskDetail = _a.taskDetail, updateTaskInfo = _a.updateTaskInfo;
    var taskData = taskDetail.taskData;
    var isUserProfile = !taskData.schemaId; // Reverse collection not selected or is user profile
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col w-full pl-[42px] justify-start items-start gap-4", children: [_jsxs("div", { className: "w-full flex items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "text-base font-semibold pb-2", children: "Check for Existing Data" }), _jsx("div", { className: "text-sm font-medium opacity-50", children: "When task is added, check existing profile data and complete task if all required, locked & hidden fields match existing data." })] }), _jsx(Switch, { className: "m-0 self-start", checked: taskDetail.configuration.autoComplete, onChange: function (autoComplete) {
                                    return updateTaskInfo({
                                        configuration: __assign(__assign({}, taskDetail.configuration), { autoComplete: autoComplete }),
                                    });
                                } })] }), _jsx(Divider, { className: isUserProfile ? 'mb-0' : undefined })] }), !isUserProfile && (_jsxs("div", { className: "flex flex-col w-full pl-[42px] justify-start items-start gap-4", children: [_jsxs("div", { className: "w-full flex items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "text-base font-semibold pb-2", children: "Allow multiple records in form" }), _jsx("div", { className: "text-sm font-medium opacity-50", children: "This setting will introduce a button in the form that will allow them to add multiple records." })] }), _jsx(Switch, { className: "m-0 self-start", checked: taskDetail.configuration.canCreateMultiple, onChange: function (canCreateMultiple) {
                                    return updateTaskInfo({
                                        configuration: __assign(__assign({}, taskDetail.configuration), { canCreateMultiple: canCreateMultiple }),
                                    });
                                } })] }), _jsx(Divider, { className: "mb-0" })] }))] }));
};
export default React.memo(ProfileDataTaskTemplateConfiguration);
