import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
import moment from 'moment';
export var isDateToday = function (date) {
    return date.toDate().toDateString() === new Date().toDateString();
};
export var dateValidation = function (startDate) {
    return moment(startDate, 'MMM D, YYYY', true).isValid();
};
export var generateMonths = function (current, localeData) {
    var months = [];
    for (var i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
    }
    return months;
};
export var generateMonthOptions = function (months) {
    var monthOptions = [];
    for (var index = 0; index < 12; index++) {
        monthOptions.push(_jsx(Select.Option, { className: "month-item", value: "".concat(index), children: months[index] }, "".concat(index)));
    }
    return monthOptions;
};
export var generateYearOptions = function () {
    var currentYear = moment().year();
    var options = [];
    for (var i = 1900; i < currentYear; i += 1) {
        options.push(_jsx(Select.Option, { value: i, className: "year-item", children: i }, i));
    }
    return options;
};
