import { jsx as _jsx } from "react/jsx-runtime";
import { CurrencySchemaType, SchemaConfigurationType } from 'src/shared';
import { Select } from 'antd';
import { lowerCase, startCase } from 'lodash';
import { useRecoilValue } from 'recoil';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { FRONTEND_TOTAL_BILL } from 'src/components/FeatureGatingKeyConstant';
var CURRENCY_SCHEMA_TYPES = [
    CurrencySchemaType.NONE,
    CurrencySchemaType.HOURLY_PAY,
    CurrencySchemaType.HOURLY_BILL,
    CurrencySchemaType.FIXED_PAY,
    CurrencySchemaType.FIXED_BILL,
];
var CurrencyConfigurationSection = function (_a) {
    var onChange = _a.onChange, schemaConfig = _a.schemaConfig;
    var currencySchemaConfig = schemaConfig
        ? schemaConfig
        : {
            type: SchemaConfigurationType.CURRENCY,
            currencySchemaType: CurrencySchemaType.NONE,
            applyToOvertime: false,
        };
    var isBillSchemaEnabled = useRecoilValue(featureEnableStatusState(FRONTEND_TOTAL_BILL));
    var featureGatedCurrencySchemaTypes = isBillSchemaEnabled
        ? CURRENCY_SCHEMA_TYPES
        : CURRENCY_SCHEMA_TYPES.filter(function (type) {
            return ![CurrencySchemaType.HOURLY_BILL, CurrencySchemaType.FIXED_BILL].includes(type);
        });
    return (_jsx("div", { children: _jsx("div", { className: "flex min-w-[200px] align-center", children: _jsx(Select, { className: "w-full", defaultValue: CurrencySchemaType.NONE, onChange: function (value) {
                    onChange === null || onChange === void 0 ? void 0 : onChange(SchemaConfigurationType.CURRENCY, {
                        type: currencySchemaConfig.type,
                        applyToOvertime: currencySchemaConfig.applyToOvertime,
                        currencySchemaType: value,
                    });
                }, dropdownMatchSelectWidth: true, value: currencySchemaConfig.currencySchemaType, children: featureGatedCurrencySchemaTypes.map(function (value) { return (_jsx(Select.Option, { value: value, children: startCase(lowerCase(value.toString())) }, "currency-type-".concat(value))); }) }) }) }));
};
export default CurrencyConfigurationSection;
