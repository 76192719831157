import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { sumBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { ScheduleView } from 'src/services';
import { scheduleViewTypeSelector, scheduleTimeRangeState } from 'src/state';
import { getTagTextColor } from 'src/utils/Collection';
import { calculateTotalHours, convertSecondsToHours } from '../Utils';
import { MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, } from '../../Constant';
import RoleHoursCell from './SummaryCell/RoleHoursCell';
import CensusInputCell from './SummaryCell/CensusInputCell';
import HppdCell from './SummaryCell/HppdCell';
var ROW_HEIGHT = 40;
var CENSOR_ROW_BG_COLOR = '#F8F8F9';
var HPPD_ROW_BG_COLOR = '#F2F9FF';
var RatioSummaryRow = function (_a) {
    var blockId = _a.blockId, type = _a.type, widths = _a.widths, locationRid = _a.locationRid, name = _a.name, roleRid = _a.roleRid, color = _a.color, _b = _a.demandRatioStatData, demandRatioStatData = _b === void 0 ? {} : _b, _c = _a.totalHoursData, totalHoursData = _c === void 0 ? {} : _c, _d = _a.censusData, censusData = _d === void 0 ? {} : _d, _e = _a.hppdData, hppdData = _e === void 0 ? [] : _e;
    var tableWidth = widths.tableWidth, tableFirstColumnWith = widths.tableFirstColumnWith;
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var columnNumber = timeRange.slice(0, -1).length;
    var cellMinWidth = scheduleView !== ScheduleView.WEEK
        ? MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH
        : WEEK_VIEW_DATE_COLUMN_MIN_WIDTH;
    var isTotalHoursRow = type === 'totalHours';
    var isCensusRow = type === 'census';
    var isHoursRow = type === 'hours';
    var isHppdRow = type === 'hppd';
    var headerBgColor = useMemo(function () {
        var bgColor = 'white';
        if (isCensusRow) {
            bgColor = CENSOR_ROW_BG_COLOR;
        }
        else if (isHppdRow) {
            bgColor = HPPD_ROW_BG_COLOR;
        }
        return bgColor;
    }, [isCensusRow, isHppdRow]);
    var renderCells = useCallback(function (time) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var date = moment(time).format('YYYY-MM-DD');
        if (isHoursRow && roleRid) {
            var actualHours = Number(convertSecondsToHours((_b = (_a = demandRatioStatData === null || demandRatioStatData === void 0 ? void 0 : demandRatioStatData[date]) === null || _a === void 0 ? void 0 : _a.actualSeconds) !== null && _b !== void 0 ? _b : 0));
            var censusValue = (_c = censusData === null || censusData === void 0 ? void 0 : censusData[date]) !== null && _c !== void 0 ? _c : 0;
            var targetRole = hppdData.find(function (role) { return role.roleRid === roleRid; });
            var ratio = (_d = targetRole === null || targetRole === void 0 ? void 0 : targetRole.data) !== null && _d !== void 0 ? _d : 0;
            var budgetedHours = Number((censusValue * ratio).toFixed(2));
            return (_jsx(RoleHoursCell, { actual: actualHours, budgeted: budgetedHours }, "".concat(date, "+hours")));
        }
        else if (isTotalHoursRow) {
            var totalBudgetedHoursData = calculateTotalHours(timeRange, hppdData, censusData);
            var actualHours = (_e = totalHoursData[date]) !== null && _e !== void 0 ? _e : 0;
            var budgetedHours = (_f = totalBudgetedHoursData[date]) !== null && _f !== void 0 ? _f : 0;
            return (_jsx(RoleHoursCell, { actual: actualHours, budgeted: budgetedHours }, "".concat(date, "+totalHours")));
        }
        else if (isCensusRow && locationRid) {
            var census = (_g = censusData === null || censusData === void 0 ? void 0 : censusData[date]) !== null && _g !== void 0 ? _g : 0;
            return (_jsx(CensusInputCell, { locationRid: locationRid, date: date, censusValue: census }, "".concat(date, "+census")));
        }
        else if (isHppdRow) {
            var totalBudgetedRatio = sumBy(hppdData, 'data');
            var actualTotalHoursData = (_h = totalHoursData === null || totalHoursData === void 0 ? void 0 : totalHoursData[date]) !== null && _h !== void 0 ? _h : 0;
            var censusValue = (_j = censusData === null || censusData === void 0 ? void 0 : censusData[date]) !== null && _j !== void 0 ? _j : 0;
            //role value doesn't factor into ratio
            // If we want the previous math: it should be totalHoursDataPerRole[role][date] + totalHoursDataPerRole[role][date] / roles instead of total hour
            var actualRatio = censusValue
                ? Number((actualTotalHoursData / censusValue).toFixed(2))
                : 0;
            return (_jsx(HppdCell, { actual: actualRatio, budgeted: totalBudgetedRatio }, "".concat(date, "+hppd")));
        }
    }, [
        isHoursRow,
        roleRid,
        isTotalHoursRow,
        isCensusRow,
        locationRid,
        isHppdRow,
        demandRatioStatData,
        hppdData,
        timeRange,
        totalHoursData,
        censusData,
    ]);
    return (_jsxs("div", { className: "flex items-center", style: {
            backgroundColor: isCensusRow ? CENSOR_ROW_BG_COLOR : 'none',
        }, children: [_jsx("div", { className: "sticky left-0 z-[1] flex items-center border-b border-r border-[#f0f0f0] pl-[8px]", style: {
                    height: ROW_HEIGHT,
                    width: tableFirstColumnWith,
                    minWidth: tableFirstColumnWith,
                    backgroundColor: headerBgColor,
                }, children: _jsx(ZiraTooltip, { title: name, children: _jsx("div", { className: "m-[4px] overflow-hidden text-ellipsis whitespace-nowrap rounded px-[8px] py-[2px] font-medium", style: {
                            backgroundColor: isHoursRow ? color : 'none',
                            color: getTagTextColor(color),
                        }, children: name }) }) }), _jsx("div", { className: "grid flex-shrink-0", style: {
                    width: "calc(".concat(tableWidth, " - ").concat(tableFirstColumnWith, "px)"),
                    gridTemplateColumns: "repeat(".concat(columnNumber, ", minmax(").concat(cellMinWidth, "px, 1fr))"),
                    height: ROW_HEIGHT,
                }, children: timeRange.slice(0, -1).map(function (time) {
                    return renderCells(time);
                }) })] }));
};
export default React.memo(RatioSummaryRow);
