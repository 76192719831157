import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
var Option = Select.Option, OptGroup = Select.OptGroup;
import React, { useCallback, useRef, useState } from 'react';
import { useSetting } from '../../../hooks/api/useSettingApi';
import './MessageCard.scss';
var Attachments = function (props) {
    var attachments = props.attachments, updateAttachments = props.updateAttachments;
    var useGetAllFormTemplates = useSetting().useGetAllFormTemplates;
    var allFormTemplateData = useGetAllFormTemplates().data;
    var _a = useState(attachments ? attachments[0].formTemplateId : undefined), selectedFormTemplateId = _a[0], setSelectedFormTemplateId = _a[1];
    var selectRef = useRef(null);
    var onOptionSelection = useCallback(function (val, elem) {
        var _a;
        setSelectedFormTemplateId(elem.value);
        updateAttachments([elem.value]);
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    }, [updateAttachments]);
    return (_jsx(Select, { ref: selectRef, className: "personalizationSelector", mode: "multiple", placeholder: "Insert Attachment", onSelect: onOptionSelection, value: selectedFormTemplateId, children: _jsx(OptGroup, { label: "Form Templates", children: allFormTemplateData === null || allFormTemplateData === void 0 ? void 0 : allFormTemplateData.map(function (formTemplate) {
                return (_jsx(Option, { value: formTemplate.id, label: formTemplate.name, name: formTemplate.name, children: formTemplate.name }, formTemplate.id));
            }) }, "forms-group") }));
};
export default React.memo(Attachments);
