import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Popover, Badge } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { PlusOutlined } from '@ant-design/icons';
import { shiftTemplateDetailModalVisibleState } from 'src/state';
import { useScheduleTemplateApi } from 'src/hooks/api';
import ShiftTemplateListInPopover from 'src/components/ScheduleView/NewShiftTemplate/ShiftTemplateListInPopover';
import { BulkDuplicateIcon } from 'src/utils/icons/BulkDuplicateIcon';
import { ShiftTemplateIcon } from 'src/utils/icons/ShiftTemplateIcon';
import { ShiftRecurringIcon } from 'src/utils/icons/ShiftRecurringIcon';
import { NEW_SHIFT_TEMPLATE } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { generateShiftTemplateListInOrder } from 'src/components/ScheduleView/FullPageScheduleBottomBar/Utils';
import RecurringRulePopup from './RecurringRule/RecurringRulePopup';
import DuplicateShiftCalendarPopup from './DuplicateShiftCalendarPopup';
var ShiftDetailHeaderActions = function (_a) {
    var duplicateAction = _a.duplicateAction, onApplyTemplateFields = _a.onApplyTemplateFields, onApplyOldTemplateFields = _a.onApplyOldTemplateFields, recurringShiftAction = _a.recurringShiftAction, canEditShiftTemplate = _a.canEditShiftTemplate, recordId = _a.recordId, dataSource = _a.dataSource;
    var collectionId = dataSource.collectionId;
    var defaultDate = duplicateAction.defaultDate, duplicateData = duplicateAction.duplicateData, setDuplicateData = duplicateAction.setDuplicateData;
    var recurringShiftData = recurringShiftAction.recurringShiftData, setRecurringShiftData = recurringShiftAction.setRecurringShiftData;
    var duplicateCount = useMemo(function () {
        var totalDuplicateShiftNumber = 0;
        for (var date in duplicateData) {
            var copies = duplicateData[date];
            totalDuplicateShiftNumber += copies;
        }
        return totalDuplicateShiftNumber;
    }, [duplicateData]);
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var _b = useState(false), templatePopoverVisible = _b[0], setTemplatePopoverVisible = _b[1];
    var useGetAllShiftTemplates = useScheduleTemplateApi().useGetAllShiftTemplates;
    var templateListResp = useGetAllShiftTemplates({
        body: { filters: [] },
        options: { enabled: templatePopoverVisible && isNewShiftTemplateEnabled, cacheTime: 0 },
    }).data;
    var templateList = useMemo(function () { return generateShiftTemplateListInOrder(templateListResp); }, [templateListResp]);
    var applyTemplateInRecordDetail = useCallback(function (templateInfo) {
        onApplyTemplateFields(templateInfo);
        setTemplatePopoverVisible(false);
    }, [onApplyTemplateFields]);
    var generateTemplateOptions = useCallback(function (templates) {
        return (_jsx(ShiftTemplateListInPopover, { templateList: templates, listHeight: 300, onApplyTemplate: applyTemplateInRecordDetail, listWidth: 170 }));
    }, [applyTemplateInRecordDetail]);
    var useFetchScheduleTemplateList = useScheduleTemplateApi().useFetchScheduleTemplateList;
    var templateData = useFetchScheduleTemplateList({
        filters: [],
        options: {
            enabled: templatePopoverVisible && !isNewShiftTemplateEnabled,
            cacheTime: 0,
        },
    }).data;
    var generateOldTemplateOptions = useCallback(function (templates) {
        return templates.map(function (template) {
            return (_jsx("div", { className: "mb-2 cursor-pointer overflow-ellipsis rounded border pl-2 hover:border-black", onClick: function () {
                    onApplyOldTemplateFields(template.id);
                    setTemplatePopoverVisible(false);
                }, children: template.name }, template.id));
        });
    }, [onApplyOldTemplateFields]);
    var setIsTemplateDetailModalVisible = useSetRecoilState(shiftTemplateDetailModalVisibleState);
    var addNewTemplateButton = useMemo(function () { return (_jsxs("div", { onClick: function () { return setIsTemplateDetailModalVisible(true); }, className: "mb-2 flex cursor-pointer items-center justify-center rounded border border-gray-300 p-1 text-center font-medium", children: [_jsx(PlusOutlined, { className: "mr-1 text-xs" }), "Add new template"] })); }, [setIsTemplateDetailModalVisible]);
    var renderTemplatesPopoverContent = useCallback(function () {
        return (_jsx("div", { children: isNewShiftTemplateEnabled ? (_jsx(_Fragment, { children: !templateList || templateList.length === 0 ? (_jsxs("div", { className: "w-40", children: [_jsx("span", { className: "text-warning", children: "No Templates Available:" }), _jsx("div", { className: "mt-2", children: addNewTemplateButton })] })) : (_jsxs("div", { className: "max-h-[800px] w-40", children: [_jsx("div", { className: "font-semibold", children: "Apply a Template" }), _jsx("div", { className: "ModalSubtitle", children: "Select a template below or" }), addNewTemplateButton, generateTemplateOptions(templateList || [])] })) })) : (_jsxs("div", { className: "max-h-[800px] w-40", children: [_jsx("div", { className: "mb-4 font-semibold", children: "Apply a Template" }), generateOldTemplateOptions((templateData === null || templateData === void 0 ? void 0 : templateData.templates) || [])] })) }));
    }, [
        addNewTemplateButton,
        generateOldTemplateOptions,
        generateTemplateOptions,
        isNewShiftTemplateEnabled,
        templateData === null || templateData === void 0 ? void 0 : templateData.templates,
        templateList,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Popover, { placement: "bottomRight", content: _jsx(DuplicateShiftCalendarPopup, { defaultDate: defaultDate, duplicateData: duplicateData, setDuplicateData: setDuplicateData }), trigger: "click", children: _jsx(ZiraTooltip, { title: "Duplicate Shift", children: _jsx(Badge, { count: duplicateCount ? duplicateCount : undefined, color: "#4CB391", children: _jsx("div", { className: "ActionButton", children: _jsx(BulkDuplicateIcon, {}) }) }) }) }), canEditShiftTemplate && (_jsx(Popover, { placement: "bottomRight", content: renderTemplatesPopoverContent, open: templatePopoverVisible, onOpenChange: setTemplatePopoverVisible, trigger: "click", children: _jsx(ZiraTooltip, { title: "Apply Template", children: _jsx("div", { className: "ActionButton", children: _jsx(ShiftTemplateIcon, { className: "text-[#181818]" }) }) }) })), _jsx(Popover, { placement: "bottomLeft", content: _jsx(RecurringRulePopup, { recurringShiftData: recurringShiftData, setRecurringShiftData: setRecurringShiftData, collectionId: collectionId, recordId: recordId, dataSource: dataSource }), trigger: "click", children: _jsx(ZiraTooltip, { title: "Repeat Shift", children: _jsx("div", { className: "ActionButton", children: _jsx(ShiftRecurringIcon, {}) }) }) })] }));
};
export default React.memo(ShiftDetailHeaderActions);
