var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { usePageApi } from '../../../hooks/api';
import GeoMap from '../../MapView/GeoMap';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import './GeoMapBlock.scss';
var GeoMapBlock = function (_a) {
    var blockId = _a.blockId, pageId = _a.pageId, moveBlockUpDown = _a.moveBlockUpDown;
    var useGetGeomapBlock = usePageApi().useGetGeomapBlock;
    var _b = useState(), geoMapData = _b[0], setGeoMapData = _b[1];
    var _c = useGetGeomapBlock(blockId, pageId), data = _c.data, isFetched = _c.isFetched;
    useEffect(function () {
        if (data) {
            setGeoMapData(data.shiftGeoMapData.map(function (shiftData) { return (__assign(__assign({}, shiftData), { geofence: shiftData.locationGeofence
                    ? {
                        coordinates: {
                            lat: shiftData.locationGeofence.shape.latitude,
                            lng: shiftData.locationGeofence.shape.longitude,
                        },
                        radius: shiftData.locationGeofence.shape.radius,
                    }
                    : undefined })); }));
        }
    }, [data]);
    return (_jsxs("div", { className: "GeoMapBlockWrapper", children: [_jsx(HoverOptionButtons, { data: {
                    blockId: blockId,
                }, moveBlockUpDown: moveBlockUpDown, isNonTitleBlock: true }), isFetched && _jsx(GeoMap, { shiftTrackingData: geoMapData, isBlock: true })] }));
};
export default React.memo(GeoMapBlock);
