var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import './ApprovalStatusCell.scss';
import SelectorCell from './SelectorCell';
var ApprovalStatusCell = function (props) {
    var nativeTypeValue = props.nativeTypeValue, editCell = props.editCell, isDetailModal = props.isDetailModal, extraProps = props.extraProps;
    var _a = useState((nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text) || ''), status = _a[0], setStatus = _a[1];
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var statusRef = useRef(status);
    useEffect(function () {
        statusRef.current = status;
    }, [status]);
    var availableStatusOptions = [
        {
            id: 'PENDING',
            text: 'Pending',
            color: '#F4F4F7',
        },
        {
            id: 'REJECTED',
            text: 'Rejected',
            color: '#FFEDEC',
        },
        {
            id: 'APPROVED',
            text: 'Approved',
            color: '#E5F4EF',
        },
    ];
    var foundStatusOption = _.find(availableStatusOptions, function (option) { return option.id === (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text); });
    var displayOptions = useMemo(function () { return [
        {
            id: status || (nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text) || '',
            text: (foundStatusOption === null || foundStatusOption === void 0 ? void 0 : foundStatusOption.text) || '',
            color: (foundStatusOption === null || foundStatusOption === void 0 ? void 0 : foundStatusOption.color) || '',
        },
    ]; }, [foundStatusOption === null || foundStatusOption === void 0 ? void 0 : foundStatusOption.color, foundStatusOption === null || foundStatusOption === void 0 ? void 0 : foundStatusOption.text, nativeTypeValue === null || nativeTypeValue === void 0 ? void 0 : nativeTypeValue.text, status]);
    var onMutateDisplayValuesInItem = useCallback(function (values) {
        setStatus(values[0]);
        editCell(values);
    }, [editCell]);
    if (!status || status === 'PENDING') {
        return (_jsxs("div", { className: "ActionButtons", children: [_jsx("div", { className: "ActionButton DeclineBtn", onClick: function () { return onMutateDisplayValuesInItem(['APPROVED']); }, children: "Approve" }), _jsx("div", { className: "ActionButton ApproveBtn", onClick: function () { return onMutateDisplayValuesInItem(['REJECTED']); }, children: "Decline" })] }));
    }
    return (_jsx(SelectorCell, __assign({ nativePath: extraProps.schema.nativePath, inputMode: extraProps.schema.inputMode, isUserSelecting: false, mutateDisplayedValueInItem: onMutateDisplayValuesInItem, displayedOption: displayOptions, availableOption: availableStatusOptions, isDetailModal: isDetailModal, type: extraProps.schema.type, showColorMenu: true, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen }, extraProps)));
};
export default React.memo(ApprovalStatusCell);
