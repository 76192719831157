import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import RecurringRulePopup from 'src/components/RecordDetailModal/RecordDetailModalHeader/Actions/Shift/RecurringRule/RecurringRulePopup';
import { CellInputMode, CollectionTableType, CollectionType } from 'src/shared';
import { usePageApi } from 'src/hooks/api';
import { useRecoilValue } from 'recoil';
import { usePrevious } from 'src/hooks/component/usePrevious';
import '../SendMessage/MessageCard.scss';
import './CreateRecordForm.scss';
import { automationBaseCollectionIdState } from '../atom';
import CreateRecordSchemaRow from './CreateRecordSchemaRow';
var CreateRecordForm = function (_a) {
    var currentAction = _a.currentAction, updateAction = _a.updateAction, allCollections = _a.allCollections;
    var baseCollectionId = useRecoilValue(automationBaseCollectionIdState);
    var prevBaseCollectionId = usePrevious(baseCollectionId);
    var useGetCollectionSchemas = usePageApi().useGetCollectionSchemas;
    var detail = currentAction.detail;
    var shiftCollectionId = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.type === CollectionType.SHIFT; }).id;
    var collectionSchemas = useGetCollectionSchemas({
        body: { collectionId: detail.collectionId },
        options: { enabled: !!detail.collectionId },
    }).data;
    var dataSource = {
        collectionId: shiftCollectionId || '',
        type: CollectionTableType.TABLE,
    };
    // Clear dynamic values on base collection change
    useEffect(function () {
        if (!prevBaseCollectionId)
            return;
        var tempActionObj = cloneDeep(currentAction);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.dynamicValues = [];
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseCollectionId]);
    var setRecurringShiftData = useCallback(function (recurrenceDetails) {
        var tempActionObj = cloneDeep(currentAction);
        var tempActionDetail = tempActionObj.detail;
        // update action to include recurrence details
        tempActionDetail.recurrenceDetails = recurrenceDetails;
        tempActionObj.detail = tempActionDetail;
        updateAction(tempActionObj);
    }, [currentAction, updateAction]);
    var renderSchemaFields = function () {
        var schemasToDisplay = collectionSchemas === null || collectionSchemas === void 0 ? void 0 : collectionSchemas.filter(function (schema) {
            return schema.inputMode !== CellInputMode.GENERATED;
        });
        return (_jsx("div", { className: "AutomationCreateRecord", children: schemasToDisplay === null || schemasToDisplay === void 0 ? void 0 : schemasToDisplay.map(function (schema, index) { return (_jsx(CreateRecordSchemaRow, { index: index, schema: schema, actionObject: currentAction, updateAction: updateAction }, 'schemaRow-' + index)); }) }));
    };
    return (_jsxs("div", { className: "messageCard", children: [renderSchemaFields(), detail.collectionId === shiftCollectionId && (_jsx(RecurringRulePopup, { recurringShiftData: detail.recurrenceDetails, setRecurringShiftData: setRecurringShiftData, dataSource: dataSource }))] }));
};
export default React.memo(CreateRecordForm);
