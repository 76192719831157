var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from 'lodash';
var contactArrayCustomizer = function (objValue, srcValue) {
    if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
    }
};
var contactArrayCustomizerWithDedup = function (objValue, srcValue) {
    if (_.isArray(objValue)) {
        // plain array dedup
        return _.uniqWith(_.concat(objValue, srcValue), _.isEqual);
    }
};
var replaceWithNewArray = function (objValue, srcValue) {
    if (_.isArray(objValue)) {
        return srcValue;
    }
};
/**
 * if key value is array type, contact with new object's key array value
 * e.g.
 *      one = var object = { 'a': [1], 'b': 2 };
        var other = { 'a': [3], 'b': 3 };
 
        result => { 'a': [1, 3], 'b': 3}
 *
 *
 * @param object
 * @param other
 * @returns
 */
export var mergeObjectWithContactArray = function (object, other) {
    return _.mergeWith({}, object, other, contactArrayCustomizer);
};
export var mergeObjectWithContactArrayWithDedup = function (object, other) {
    return _.mergeWith({}, object, other, contactArrayCustomizerWithDedup);
};
export var mergeObjectWithNewArrayReplaced = function (object, other) {
    return _.mergeWith({}, object, other, replaceWithNewArray);
};
export var hasDuplicate = function (array) { return _.uniq(array).length !== array.length; };
export var upperCaseStringToStartCase = function (str) { return _.startCase(_.toLower(str)); };
export var toggleList = function (list, item) {
    var tempList = _.cloneDeep(list);
    var index = tempList.indexOf(item);
    if (index > -1) {
        return tempList.filter(function (i, _index) { return _index !== index; });
    }
    else {
        return __spreadArray(__spreadArray([], tempList, true), [item], false);
    }
};
export function arrayEqualUnordered(a, b, compare) {
    if (a.length !== b.length) {
        return false;
    }
    if (compare) {
        return a.every(function (aItem) { return b.some(function (bItem) { return compare(aItem, bItem); }); });
    }
    return a.every(function (aItem) { return b.includes(aItem); });
}
