var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DnDHandleSvg = function () { return (_jsxs("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "8", y: "6", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "8", y: "10", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "8", y: "14", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "12", y: "6", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "12", y: "10", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" }), _jsx("rect", { x: "12", y: "14", width: "2", height: "2", rx: "0.5", fill: "#A7A8AC" })] })); };
export var DnDHandleIcon = function (props) { return (_jsx(Icon, __assign({ component: DnDHandleSvg }, props))); };
