import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { getIpDisplayText } from 'src/utils/Account';
import { IntegrationStepsState } from '../SyncStepsContainer';
import '../SyncStepsContainer.scss';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import IntegrationHeaderContent from './IntegrationHeaderContent';
var IpCollectionStep = function (_a) {
    var onIntegrationModalClose = _a.onIntegrationModalClose;
    var _b = useParams().ipName, ipName = _b === void 0 ? '' : _b;
    var _c = useSetting(), useGetAvailableIpCollections = _c.useGetAvailableIpCollections, useDisconnectIpCollection = _c.useDisconnectIpCollection;
    var _d = IntegrationStepsState.useContainer(), setSelectedIpCollection = _d.setSelectedIpCollection, goNextStep = _d.goNextStep;
    var _e = useState(), ipCollectionOptions = _e[0], setIpCollectionOptions = _e[1];
    var fetchAvailableIpCollections = useGetAvailableIpCollections(ipName.toLowerCase()).data;
    var mutation = useDisconnectIpCollection();
    useEffect(function () {
        if (fetchAvailableIpCollections) {
            setIpCollectionOptions(fetchAvailableIpCollections);
        }
    }, [fetchAvailableIpCollections]);
    var disconnectIpCollection = useCallback(function (ipName, ziraCollectionId) {
        mutation.mutate({ ipName: ipName, ziraCollectionId: ziraCollectionId }, {
            onSuccess: function (data) {
                setIpCollectionOptions(data);
            },
        });
    }, [mutation]);
    var handleGoNext = useCallback(function (ip) {
        setSelectedIpCollection(ip);
        goNextStep();
    }, [goNextStep, setSelectedIpCollection]);
    var closeIntegrationModal = useCallback(function () {
        onIntegrationModalClose();
    }, [onIntegrationModalClose]);
    return (_jsxs("div", { className: "IntegrationWrapper", children: [_jsx(IntegrationHeaderContent, { children: _jsx(Button, { onClick: closeIntegrationModal, children: "Done" }) }), _jsxs("div", { className: "SelectData", children: [_jsx("div", { className: "StepTitle", children: "Select Data to Sync" }), _jsxs("div", { className: "StepDescription", children: ["TeamBridge effortlessly automates the process of importing and synchronizing your data from ", getIpDisplayText(ipName), " with just a few clicks."] }), _jsx("div", { className: "ContentPadding" }), _jsx("div", { className: "IntegrationSubTitle", children: "Available Data" }), _jsx(Space, { direction: "vertical", children: ipCollectionOptions === null || ipCollectionOptions === void 0 ? void 0 : ipCollectionOptions.map(function (item) {
                            return (_jsxs("div", { children: [item.possibleZiraCollectionId ? (_jsx(Button, { disabled: true, style: { borderColor: '#f5f5f5' }, children: item.ipCollectionName })) : (_jsx(Button, { onClick: function () { return handleGoNext(item.ipCollectionName); }, children: item.ipCollectionName }, item.ipCollectionName)), item.possibleZiraCollectionId && (_jsx(Button, { style: { border: 0 }, onClick: function () {
                                            return disconnectIpCollection(ipName, item.possibleZiraCollectionId);
                                        }, children: "disconnect" }, item.possibleZiraCollectionId))] }, item.ipCollectionName));
                        }) })] })] }));
};
export default React.memo(IpCollectionStep);
