var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, Modal, Spin, Switch } from 'antd';
import { useScheduleTemplateApi } from 'src/hooks/api';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { useDebounce, useTimeZone } from 'src/hooks/component';
import { LoadingOutlined } from '@ant-design/icons';
var RangePicker = DatePicker.RangePicker;
var TemplatePropagationModal = function (_a) {
    var closeModal = _a.closeModal, fromTemplateId = _a.fromTemplateId, timezone = _a.timezone;
    var _b = useScheduleTemplateApi(), useGetShiftsForTemplates = _b.useGetShiftsForTemplates, propagateShiftsByTemplatesMutation = _b.propagateShiftsByTemplatesMutation;
    var _c = useState(moment()), startAt = _c[0], setStartAt = _c[1];
    var _d = useState(null), endAt = _d[0], setEndAt = _d[1];
    var _e = useState(false), published = _e[0], setPublished = _e[1];
    var debouncedStart = useDebounce(startAt, 1000);
    var debouncedEnd = useDebounce(endAt, 1000);
    var timeZone = useTimeZone().timeZone;
    var propagationRequest = useMemo(function () { return ({
        fromTemplateId: fromTemplateId,
        includesPublished: published,
        startAt: debouncedStart === null || debouncedStart === void 0 ? void 0 : debouncedStart.format('YYYY-MM-DD'),
        endAt: debouncedEnd === null || debouncedEnd === void 0 ? void 0 : debouncedEnd.format('YYYY-MM-DD'),
        timezone: timezone || timeZone,
    }); }, [fromTemplateId, published, debouncedStart, debouncedEnd, timezone, timeZone]);
    var _f = useGetShiftsForTemplates({
        body: propagationRequest,
        options: { enabled: !!fromTemplateId },
    }), simpleShifts = _f.data, isFetching = _f.isFetching;
    return (_jsxs(Modal, { closable: false, open: true, onCancel: propagateShiftsByTemplatesMutation.isLoading ? function () { return undefined; } : closeModal, onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, propagateShiftsByTemplatesMutation.mutateAsync(propagationRequest)];
                    case 1:
                        _a.sent();
                        closeModal();
                        return [2 /*return*/];
                }
            });
        }); }, okButtonProps: {
            disabled: ((simpleShifts === null || simpleShifts === void 0 ? void 0 : simpleShifts.size) || 0) < 1 || propagateShiftsByTemplatesMutation.isLoading,
        }, cancelButtonProps: { disabled: propagateShiftsByTemplatesMutation.isLoading }, children: [_jsx("span", { className: "font-medium text-[15px]", children: "Template Propagation" }), propagateShiftsByTemplatesMutation.isLoading ? (_jsxs("div", { className: "flex flex-col align-center items-center", children: [_jsxs("div", { children: ["Propagating to ", (simpleShifts === null || simpleShifts === void 0 ? void 0 : simpleShifts.size) || 0, " shifts"] }), _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) })] })) : (_jsxs("div", { children: [_jsx("div", { children: "Changes will take effect from the date range selected below." }), _jsx("div", { children: !isFetching
                            ? "Changes will affect ".concat((simpleShifts === null || simpleShifts === void 0 ? void 0 : simpleShifts.size) || 0, " ").concat((simpleShifts === null || simpleShifts === void 0 ? void 0 : simpleShifts.size) !== 1 ? 'Shifts' : 'Shift')
                            : 'Calculating shifts...' }), _jsx("span", { className: "text-xs text-gray-500", children: "Please note that updating Schedule Template times will also update the times on the coverage template, even if they have been manually adjusted. Be sure to note any that may need to be adjusted again." }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("div", { children: _jsxs("div", { className: "flex space-x-2 items-start mt-3", children: [_jsx("div", { className: "font-semibold", children: "Include Published Shifts" }), _jsx(Switch, { className: "m-0 self-start", onChange: setPublished })] }) }), _jsx(RangePicker, { defaultValue: [startAt, endAt], onChange: function (dates) {
                                    setStartAt((dates === null || dates === void 0 ? void 0 : dates[0]) || null);
                                    setEndAt((dates === null || dates === void 0 ? void 0 : dates[1]) || null);
                                }, allowEmpty: [false, true] })] })] }))] }));
};
export default TemplatePropagationModal;
