var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemasState, } from 'src/state';
import { TEMP_NODE_TITLE, ValueDataType } from 'src/shared';
import RecordColorSelectorDropdown from 'src/components/RecordColorSelectorDropdown/RecordColorSelectorDropdown';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var CustomDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource, recordColorSelectorProps = _a.recordColorSelectorProps;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var schemaList = useRecoilValue(collectionTableSchemasState(dataSource.collectionId));
    var customTitle = useMemo(function () {
        var _a, _b;
        var titleSchemaId = (_a = schemaList.find(function (schema) { return schema.type === ValueDataType.TITLE; })) === null || _a === void 0 ? void 0 : _a.id;
        var title = titleSchemaId
            ? ((_b = modalRecord[titleSchemaId]) === null || _b === void 0 ? void 0 : _b.content) || TEMP_NODE_TITLE
            : TEMP_NODE_TITLE;
        return (_jsxs("div", { className: "flex flex-col gap-1", children: [recordColorSelectorProps && (_jsx(RecordColorSelectorDropdown, __assign({}, recordColorSelectorProps))), _jsx("span", { children: title })] }));
    }, [modalRecord, recordColorSelectorProps, schemaList]);
    return _jsx(RecordDetailModalTitle, { title: customTitle });
};
export default React.memo(CustomDetailModalTitle);
