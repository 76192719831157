import { selector } from 'recoil';
import { collectionOptionsState } from 'src/state';
import { schedulingSnapshotOptionSettingState } from './Atoms';
var numberOfVisibleSchedulingSnapshotListSelector = selector({
    key: 'numberOfVisibleSchedulingSnapshotListSelector',
    get: function (_a) {
        var get = _a.get;
        var schedulingSnapshotOptions = get(schedulingSnapshotOptionSettingState);
        var count = 0;
        Object.values(schedulingSnapshotOptions).map(function (visible) {
            if (visible)
                count++;
        });
        return count;
    },
});
var dashboardUserCollectionIdSelector = selector({
    key: 'dashboardUserCollectionIdSelector',
    get: function (_a) {
        var get = _a.get;
        var collectionOptions = get(collectionOptionsState);
        var userCollectionInfo = collectionOptions.find(function (o) { return o.type === 'user'; });
        if (!userCollectionInfo)
            return '';
        return userCollectionInfo.isEditable ? userCollectionInfo.id : '';
    },
});
var dashboardShiftCollectionIdSelector = selector({
    key: 'dashboardShiftCollectionIdSelector',
    get: function (_a) {
        var get = _a.get;
        var collectionOptions = get(collectionOptionsState);
        var shiftCollectionInfo = collectionOptions.find(function (o) { return o.type === 'shift'; });
        if (!shiftCollectionInfo)
            return '';
        return shiftCollectionInfo.isEditable ? shiftCollectionInfo.id : '';
    },
});
export { numberOfVisibleSchedulingSnapshotListSelector, dashboardUserCollectionIdSelector, dashboardShiftCollectionIdSelector, };
