var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
var ZiraVisibilityDropdownVisibleItem = function (_a) {
    var title = _a.title, draggableProvided = _a.draggableProvided, onToggleOffItem = _a.onToggleOffItem;
    return (_jsxs("div", __assign({ className: "flex w-full items-center rounded-sm border border-black/10 bg-white py-[14px]" }, draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.draggableProps, { ref: draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.innerRef, children: [draggableProvided && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, draggableProvided.dragHandleProps, { className: "ml-1" }))), _jsx("span", { className: "ml-4 text-sm font-medium", children: title }), onToggleOffItem && (_jsx("span", { className: "ml-auto mr-4 cursor-pointer text-gray-300 hover:text-black", onClick: onToggleOffItem, children: "\u2715" }))] })));
};
export default React.memo(ZiraVisibilityDropdownVisibleItem);
