import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { CollectionTableType, CollectionType, NativePath, QuickCreateNativeHiddenSet, QuickCreateNativePathDefaultSet, ValueDataType, } from 'src/shared';
import { useRecordComponent, useTimeZone } from 'src/hooks/component';
import { useShiftModalComponent } from 'src/hooks/component/useShiftModal/useShiftModal';
import { collectionTableModalRecordIdSelector, collectionTableSchemasState, quickCreateModalCollectionInfoState, } from 'src/state';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import ModalCellWrapper from '../OptimizedTableView/Cell/ModalCellWrapper/ModalCellWrapper';
var QuickCreateModalBody = function () {
    var _a;
    var _b = useRecoilValue(quickCreateModalCollectionInfoState), collectionId = _b.collectionId, collectionName = _b.collectionName, collectionType = _b.collectionType;
    var recordId = (_a = useRecoilValue(collectionTableModalRecordIdSelector({
        type: CollectionTableType.QUICK_CREATE_MODAL,
        collectionId: collectionId,
    }))) !== null && _a !== void 0 ? _a : '';
    var schemas = useRecoilValue(collectionTableSchemasState(collectionId));
    var quickCreateSchemas = schemas === null || schemas === void 0 ? void 0 : schemas.filter(function (schema) {
        return schema.canEdit &&
            (schema.isRequired ||
                schema.isQuickCreate ||
                (schema.nativePath && QuickCreateNativePathDefaultSet.has(schema.nativePath)));
    });
    var editRecordCell = useRecordComponent().editRecordCell;
    var timeZone = useTimeZone().timeZone;
    // Hook for bespoke shift detail logic
    var _c = useShiftModalComponent({
        recordId: recordId,
        dataSource: {
            type: CollectionTableType.QUICK_CREATE_MODAL,
            collectionId: collectionId,
        },
        collectionInfo: { id: collectionId, name: collectionName, type: collectionType },
        isCreatingNew: true,
        defaultTimeZone: timeZone,
    }), renderShiftTimeSchemas = _c.renderShiftTimeSchemas, renderShiftAssigneeSchemaCell = _c.renderShiftAssigneeSchemaCell, renderShiftClientSchemaCell = _c.renderShiftClientSchemaCell;
    var _d = renderShiftTimeSchemas(), scheduleTimeCell = _d[0], clockedInOutTimeCell = _d[1];
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(CollectionTableType.QUICK_CREATE_MODAL, cellPos, newValues);
    }, [editRecordCell]);
    if (!collectionId || !recordId || !quickCreateSchemas) {
        return (_jsx("div", { className: "QuickCreateModalBody flex items-center justify-center", children: _jsx(LoadingLogo, { className: "main-loading-logo" }) }));
    }
    return (_jsxs("div", { className: "QuickCreateModalBody", children: [quickCreateSchemas.map(function (_a) {
                var id = _a.id, nativePath = _a.nativePath, type = _a.type;
                if (nativePath && QuickCreateNativeHiddenSet.has(nativePath)) {
                    return;
                }
                return (_jsxs("div", { className: "QuickCreateSchemaRow", children: [nativePath === NativePath.SHIFT_START_AT && _jsx(_Fragment, { children: scheduleTimeCell }), nativePath === NativePath.SHIFT_CLOCK_IN && _jsx(_Fragment, { children: clockedInOutTimeCell }), nativePath === NativePath.SHIFT_USER_ID && (_jsx(_Fragment, { children: renderShiftAssigneeSchemaCell() })), collectionType === CollectionType.SHIFT &&
                            type === ValueDataType.CONTACT && _jsx(_Fragment, { children: renderShiftClientSchemaCell() }), ((nativePath &&
                            ![
                                NativePath.SHIFT_START_AT,
                                NativePath.SHIFT_CLOCK_IN,
                                NativePath.SHIFT_USER_ID,
                            ].includes(nativePath)) ||
                            (!nativePath &&
                                !(collectionType === CollectionType.SHIFT &&
                                    type === ValueDataType.CONTACT))) && (_jsx(ModalCellWrapper, { cellPos: { collectionId: collectionId, recordId: recordId, schemaId: id }, collectionTableType: CollectionTableType.QUICK_CREATE_MODAL, editCell: onEditModalCell }))] }, id));
            }), _jsxs("div", { className: "text-gray-300", children: ["How to add a column field:", _jsx("br", {}), "1. Open a ", collectionName, " detail popup", _jsx("br", {}), "2. Click the ", _jsx(MenuOutlined, {}), " icon to the left of a field", _jsx("br", {}), "3. Click ", '"Add To Quick Create"'] })] }));
};
export default React.memo(QuickCreateModalBody);
