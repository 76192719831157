import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { cloneDeep, findIndex, first } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tableCollectionIdSelector } from 'src/state';
import { useAccountApi } from 'src/hooks/api';
import { useDemandRatioApi } from 'src/hooks/api/useDemandRatioApi';
import { useDemandRatioComponent } from 'src/hooks/component/useDemandRatio';
import { currentRatioSummaryLocationRidState, isRatioConfigModalVisibleState } from '../Atom';
import { shiftCollectionLocationSchemaIdSelector } from '../../Selector';
import RatioConfigLocationSelector from './RatioConfigLocationSelector';
import RatioSettingSection from './RatioSettingSection';
var RatioConfigModal = function (_a) {
    var blockId = _a.blockId;
    var setIsRatioConfigModalVisible = useSetRecoilState(isRatioConfigModalVisibleState);
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var locationSchemaId = useRecoilValue(shiftCollectionLocationSchemaIdSelector(collectionId));
    var _b = useDemandRatioApi(), useGetDemandRatio = _b.useGetDemandRatio, useSaveDemandRatio = _b.useSaveDemandRatio;
    var _c = useDemandRatioComponent(), refetchDemandRatioSettingData = _c.refetchDemandRatioSettingData, refetchDemandRatioStats = _c.refetchDemandRatioStats;
    var demandRatioData = useGetDemandRatio({
        body: undefined,
    }).data;
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var locationList = useGetUniversalSimpleList({
        body: {
            schemaId: locationSchemaId,
        },
        options: {
            enabled: !!locationSchemaId,
        },
    }).data;
    var _d = useState(), ratioSettingData = _d[0], setRatioSettingData = _d[1];
    var _e = useState(), selectedLocation = _e[0], setSelectedLocation = _e[1];
    var currentRatioSummaryLocationRid = useRecoilValue(currentRatioSummaryLocationRidState);
    useEffect(function () {
        if (demandRatioData) {
            // First time initial set up ratio
            if (demandRatioData.length === 0 && locationList) {
                setRatioSettingData(function () {
                    var defaultRatioSettingData = [];
                    locationList === null || locationList === void 0 ? void 0 : locationList.map(function (location) {
                        defaultRatioSettingData.push({
                            locationRid: location.id,
                            ratio: [],
                        });
                    });
                    return defaultRatioSettingData;
                });
            }
            else {
                setRatioSettingData(demandRatioData);
            }
        }
    }, [demandRatioData, locationList]);
    useEffect(function () {
        // if has existing locationRid, set it as default selected location
        // else set first location in location list as default selected location
        if (currentRatioSummaryLocationRid) {
            setSelectedLocation(currentRatioSummaryLocationRid);
        }
        else {
            var firstLocation = first(locationList);
            if (firstLocation) {
                setSelectedLocation(firstLocation.id);
            }
        }
    }, [currentRatioSummaryLocationRid, locationList]);
    var updateSelectedLocationOption = useCallback(function (selectedLocationRid) {
        if (!ratioSettingData)
            return;
        var targetLocationSetting = ratioSettingData.find(function (item) { return item.locationRid === selectedLocationRid; });
        // Add new location setting when config it for the first time
        if (!targetLocationSetting) {
            var temp = cloneDeep(ratioSettingData);
            var newLocationSetting = {
                locationRid: selectedLocationRid,
                ratio: [],
            };
            temp.push(newLocationSetting);
            setRatioSettingData(temp);
        }
        setSelectedLocation(selectedLocationRid);
    }, [ratioSettingData]);
    var currentLocationRatioSettingData = useMemo(function () {
        if (ratioSettingData && selectedLocation) {
            return ratioSettingData.find(function (item) { return item.locationRid === selectedLocation; });
        }
    }, [ratioSettingData, selectedLocation]);
    var updateCurrentLocationRatioSettingDate = useCallback(function (newData) {
        if (ratioSettingData && selectedLocation) {
            var newRatioSettingData = cloneDeep(ratioSettingData);
            var currentLocationRatioSettingDataIndex = findIndex(ratioSettingData, function (item) { return item.locationRid === selectedLocation; });
            if (currentLocationRatioSettingDataIndex !== -1) {
                newRatioSettingData[currentLocationRatioSettingDataIndex].ratio = newData;
                setRatioSettingData(newRatioSettingData);
            }
        }
    }, [ratioSettingData, selectedLocation]);
    var onSave = useCallback(function () {
        useSaveDemandRatio.mutate(ratioSettingData !== null && ratioSettingData !== void 0 ? ratioSettingData : [], {
            onSuccess: function () {
                setIsRatioConfigModalVisible(false);
                refetchDemandRatioSettingData();
                refetchDemandRatioStats();
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratioSettingData, setIsRatioConfigModalVisible]);
    var modalFooter = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: function () { return setIsRatioConfigModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "ZiraModalSaveBtn", onClick: onSave, children: "Save" })] }));
    }, [onSave, setIsRatioConfigModalVisible]);
    return (_jsxs(Modal, { open: true, closable: false, width: 600, zIndex: 1001, maskClosable: true, footer: modalFooter, centered: true, className: "RatioConfigModal", children: [_jsx("div", { className: "text-3xl font-semibold", children: "Configure Ratios" }), selectedLocation && (_jsx(RatioConfigLocationSelector, { locationList: locationList !== null && locationList !== void 0 ? locationList : [], selectedLocationOption: selectedLocation, updateSelectedLocationOption: updateSelectedLocationOption })), currentLocationRatioSettingData && (_jsx(RatioSettingSection, { blockId: blockId, ratioSettingData: currentLocationRatioSettingData.ratio, updateRatioSettingData: updateCurrentLocationRatioSettingDate }))] }));
};
export default React.memo(RatioConfigModal);
