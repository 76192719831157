var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, Divider, Form, Input, Modal } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useTemplateComponent } from 'src/hooks/component/useTemplate';
import { tableCollectionIdSelector, collectionTableSchemasState, scheduleShiftCardBasicInfoSelector, scheduleTimeZoneSelector, } from 'src/state';
import { selectedShiftsState } from 'src/state/atoms/schedule';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { CellInputMode, CollectionTableType, NativePath } from '../../shared';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from '../CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from '../CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import './TemplateConfirmModal.scss';
var CheckboxGroup = Checkbox.Group;
var schemaToHide = new Set([
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_WAGE,
]);
var schemaToDefault = new Set([
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_LOCATION_ID,
    NativePath.SHIFT_ROLE_IDS,
]);
var schemaToAlwaysEnable = new Set([NativePath.SHIFT_START_AT, NativePath.SHIFT_END_AT]);
var TemplateConfirmModal = function (props) {
    var isModalVisible = props.isModalVisible, setIsModalVisible = props.setIsModalVisible, blockId = props.blockId;
    var timezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var schemaList = useRecoilValue(collectionTableSchemasState(collectionId));
    var schemaOptions = useMemo(function () {
        return schemaList
            .filter(function (_a) {
            var nativePath = _a.nativePath, inputMode = _a.inputMode;
            return inputMode !== CellInputMode.GENERATED &&
                (!nativePath || (nativePath && !schemaToHide.has(nativePath)));
        })
            .map(function (schema) { return ({
            label: schema.name,
            value: schema.id,
            nativePath: schema.nativePath,
        }); });
    }, [schemaList]);
    var defaultSchemas = schemaOptions
        .filter(function (_a) {
        var nativePath = _a.nativePath;
        return nativePath && schemaToDefault.has(nativePath);
    })
        .map(function (option) { return option.value; });
    var alwaysEnabledSchemas = schemaList
        .filter(function (_a) {
        var nativePath = _a.nativePath;
        return nativePath && schemaToAlwaysEnable.has(nativePath);
    })
        .map(function (schema) { return schema.id; });
    var selectedShifts = useRecoilValue(selectedShiftsState(blockId));
    var saveTemplate = useTemplateComponent(blockId).saveTemplate;
    var detailForm = Form.useForm()[0];
    var onCheckForm = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var formValues, shifts, shiftCard, shiftStart, rangeStart, includedSchemaIds, errorInfo_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, detailForm.validateFields()];
                    case 1:
                        formValues = _a.sent();
                        shifts = selectedShifts.map(function (shift) { return ({
                            shiftId: shift,
                            recordId: shift,
                        }); });
                        shiftCard = snapshot
                            .getLoadable(scheduleShiftCardBasicInfoSelector({
                            type: CollectionTableType.TABLE,
                            collectionId: collectionId,
                            recordId: selectedShifts[0],
                        }))
                            .valueMaybe();
                        shiftStart = shiftCard === null || shiftCard === void 0 ? void 0 : shiftCard.startAt;
                        if (!shiftStart) {
                            toast('Unscheduled shift can not be saved as template', { type: 'error' });
                            return [2 /*return*/];
                        }
                        rangeStart = moment(shiftStart)
                            .tz(timezone)
                            .startOf('day')
                            .format('YYYY-MM-DD');
                        includedSchemaIds = __spreadArray(__spreadArray([], alwaysEnabledSchemas, true), formValues.properties, true);
                        return [4 /*yield*/, saveTemplate(formValues.name, shifts, rangeStart, timezone, includedSchemaIds)];
                    case 2:
                        _a.sent();
                        set(selectedShiftsState(blockId), []);
                        setIsModalVisible(false);
                        return [3 /*break*/, 4];
                    case 3:
                        errorInfo_1 = _a.sent();
                        return [2 /*return*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, [
        alwaysEnabledSchemas,
        blockId,
        collectionId,
        detailForm,
        saveTemplate,
        selectedShifts,
        setIsModalVisible,
        timezone,
    ]);
    var modalFooter = (_jsxs(_Fragment, { children: [_jsx(Button, { className: "CancelBtn", onClick: function () { return setIsModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "SaveBtn", onClick: function (e) {
                    e.stopPropagation();
                    onCheckForm();
                }, children: "Save" })] }));
    return (_jsxs(Modal, { className: "TemplateConfirmModal", closable: false, open: isModalVisible, centered: true, footer: modalFooter, destroyOnClose: true, onCancel: function () { return setIsModalVisible(false); }, children: [_jsxs("div", { className: "mb-5 flex items-center", children: [_jsx("div", { className: "TemplateSaveTitle", children: "Save as Template" }), _jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.SCHEDULING_TEMPLATE], children: _jsx(HelpIcon, { className: "ml-2 text-black" }) })] }), _jsxs(Form, { labelCol: { span: 6 }, wrapperCol: { span: 24 }, form: detailForm, layout: "vertical", initialValues: {
                    properties: defaultSchemas,
                }, requiredMark: 'optional', preserve: false, children: [_jsx(Form.Item, { label: _jsx("span", { className: "ModalOptionTitle", children: "Name" }), name: "name", rules: [
                            {
                                required: true,
                                message: 'Please input template name',
                            },
                        ], children: _jsx(Input, { placeholder: "Template Name", autoFocus: true }) }), _jsx(Divider, {}), _jsx("div", { className: "ModalOptionTitle pb-2 pt-3", children: "Include fields" }), _jsx(Checkbox, { checked: true, disabled: true, className: "w-full", children: "Start Time" }), _jsx(Checkbox, { checked: true, disabled: true, className: "w-full", children: "End Time" }), _jsx(Form.Item, { name: "properties", required: true, children: _jsx(CheckboxGroup, { options: schemaOptions, className: "PropertiesCheckboxGroup" }) })] })] }));
};
export default React.memo(TemplateConfirmModal);
