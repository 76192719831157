import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment';
import { ShiftRecommendationMatchGroupSet, ShiftRecommendationStatusGroupSet, ShiftRecommendationsView, } from './constant';
import ShiftRecommendationsGroup from './ShiftRecommendationsGroup';
var ShiftRecommendationMatchGroupSetArray = Array.from(ShiftRecommendationMatchGroupSet);
var ShiftRecommendationStatusGroupSetArray = Array.from(ShiftRecommendationStatusGroupSet);
var ShiftRecommendationsTabView = function (_a) {
    var _b, _c;
    var view = _a.view, baseRequest = _a.baseRequest, summaryCounts = _a.summaryCounts, shiftStartAt = _a.shiftStartAt, refetchCounts = _a.refetchCounts;
    // Can recommend if there are unassigned shifts and the shift hasn't started yet
    var canRecommend = view === ShiftRecommendationsView.ALL &&
        summaryCounts.assignedShiftsCount !== summaryCounts.shiftsTotalCount &&
        moment().isBefore(shiftStartAt);
    // First group with count > 0 or the first group
    var defaultOpenGroup = view === ShiftRecommendationsView.ALL
        ? ((_b = ShiftRecommendationMatchGroupSetArray.find(function (group) { return summaryCounts.groupCounts[group] > 0; })) !== null && _b !== void 0 ? _b : ShiftRecommendationMatchGroupSetArray[0])
        : ((_c = ShiftRecommendationStatusGroupSetArray.find(function (group) { return summaryCounts.groupCounts[group] > 0; })) !== null && _c !== void 0 ? _c : ShiftRecommendationStatusGroupSetArray[0]);
    return (_jsx(_Fragment, { children: (view === ShiftRecommendationsView.ALL
            ? ShiftRecommendationMatchGroupSetArray
            : ShiftRecommendationStatusGroupSetArray).map(function (groupType) { return (_jsx(ShiftRecommendationsGroup, { groupType: groupType, groupCount: summaryCounts.groupCounts[groupType], baseRequest: baseRequest, canRecommend: canRecommend, isDefaultOpen: groupType === defaultOpenGroup, refetchCounts: refetchCounts }, groupType)); }) }));
};
export default React.memo(ShiftRecommendationsTabView);
