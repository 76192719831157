var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
var useDashboardApi = function () {
    var customFetch = useFetch().request;
    var useGetSnapshotShiftTotalCounts = function () {
        return useQuery(['snapshotShiftTotalCounts'], function () {
            return customFetch({
                method: 'GET',
                url: "account_dashboard/snapshot_shift_counts",
            });
        });
    };
    var useGetSnapshotShiftList = function (request) {
        return useQuery(['snapshotShiftList', request.shiftStatus, request.pagination], function () {
            var _a, _b;
            return customFetch({
                method: 'GET',
                url: "account_dashboard/snapshot_shifts?shiftStatus=".concat(request.shiftStatus, "&page=").concat((_a = request.pagination) === null || _a === void 0 ? void 0 : _a.page, "&size=").concat((_b = request.pagination) === null || _b === void 0 ? void 0 : _b.size),
            });
        });
    };
    var useGetDashboardMetrics = function () {
        return useQuery(['DashboardMetrics'], function () {
            return customFetch({
                method: 'GET',
                url: 'account_dashboard/dashboard_metrics',
            });
        });
    };
    var useGetWhatsNewArticleCollection = function () {
        return useQuery(['DashboardWhatsNewCollectionArticles'], function () {
            return customFetch({
                method: 'GET',
                url: 'account_dashboard/feature_articles',
            });
        }, {
            staleTime: Infinity,
        });
    };
    var useGetAutomationSummaryData = function () {
        return useQuery(['DashboardAutomationSummaryData'], function () {
            return customFetch({
                method: 'GET',
                url: 'account_dashboard/automation_runs',
            });
        });
    };
    var useGetOnboardingCheckList = function () {
        return useQuery(['DashboardOnboardingCheckList'], function () {
            return customFetch({
                method: 'GET',
                url: 'account_dashboard/onboarding_list',
            });
        });
    };
    var updateOnboardingStatus = function (body) {
        return customFetch({
            method: 'POST',
            url: 'account_dashboard/onboarding_status',
        }, body);
    };
    var useGetShiftAlertStatusData = function (timeRange) {
        return useQuery(['DashboardShiftAlertStatusData', timeRange], function () {
            return customFetch({
                method: 'GET',
                url: "account_dashboard/shift_alert_data?dateRangeType=".concat(timeRange),
            });
        });
    };
    var useGetShiftAlertTableData = function (request, options) {
        return useQuery([
            'DashboardShiftAlertTableData',
            request.paramsPath,
            JSON.stringify(request.pagination),
        ], function () {
            var _a, _b;
            return customFetch({
                method: 'GET',
                url: "account_dashboard/shift_alerts?".concat(request.paramsPath, "&page=").concat((_a = request.pagination) === null || _a === void 0 ? void 0 : _a.page, "&size=").concat((_b = request.pagination) === null || _b === void 0 ? void 0 : _b.size),
            });
        }, __assign({}, options));
    };
    var useGetUserAlertStatusData = function () {
        return useQuery(['DashboardUserAlertStatusData'], function () {
            return customFetch({
                method: 'GET',
                url: "account_dashboard/user_alert_data",
            });
        });
    };
    var useGetUserAlertTableData = function (request, options) {
        return useQuery(['DashboardUserAlertTableData', request.paramsPath, JSON.stringify(request.pagination)], function () {
            var _a, _b;
            return customFetch({
                method: 'GET',
                url: "account_dashboard/user_alerts?".concat(request.paramsPath, "&page=").concat((_a = request.pagination) === null || _a === void 0 ? void 0 : _a.page, "&size=").concat((_b = request.pagination) === null || _b === void 0 ? void 0 : _b.size),
            });
        }, __assign({}, options));
    };
    return {
        useGetSnapshotShiftTotalCounts: useGetSnapshotShiftTotalCounts,
        useGetSnapshotShiftList: useGetSnapshotShiftList,
        useGetDashboardMetrics: useGetDashboardMetrics,
        useGetWhatsNewArticleCollection: useGetWhatsNewArticleCollection,
        useGetAutomationSummaryData: useGetAutomationSummaryData,
        useGetOnboardingCheckList: useGetOnboardingCheckList,
        updateOnboardingStatus: updateOnboardingStatus,
        useGetShiftAlertStatusData: useGetShiftAlertStatusData,
        useGetShiftAlertTableData: useGetShiftAlertTableData,
        useGetUserAlertStatusData: useGetUserAlertStatusData,
        useGetUserAlertTableData: useGetUserAlertTableData,
    };
};
export { useDashboardApi };
