var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DataAccessIconSvg = function () { return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", height: "24", width: "24", children: [_jsx("path", { d: "M13.39,3a.47.47,0,0,0-.21-.16L7.18.57a.45.45,0,0,0-.36,0l-6,2.31A.47.47,0,0,0,.61,3a.48.48,0,0,0-.11.3v7.32a.5.5,0,0,0,.32.46l6,2.31.18,0,.18,0,6-2.31a.5.5,0,0,0,.32-.46V3.34A.48.48,0,0,0,13.39,3Z", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsxs("g", { children: [_jsx("line", { x1: "7", y1: "13.46", x2: "7", y2: "5.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "7", y1: "5.5", x2: "7", y2: "13.46", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("polyline", { points: "0.61 3.04 7 5.5 13.39 3.04", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" })] })] })); };
export var DataAccessIcon = function (props) { return (_jsx(Icon, __assign({ component: DataAccessIconSvg }, props))); };
