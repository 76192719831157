var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import TaskSearchBar from '../../shared/TaskSearchBar';
import { selectedTaskGroupIdState, taskGroupListState } from '../../atom';
import { DEFAULT_TASK_GROUP_INFO, NEW_TASK_GROUP_ID } from '../../constant';
import TaskGroupListItemV2 from './TaskGroupListItemV2';
var TaskGroupListV2 = function () {
    var taskGroupList = useRecoilValue(taskGroupListState);
    var _a = useRecoilState(selectedTaskGroupIdState), selectedTaskGroupId = _a[0], setSelectedTaskGroupId = _a[1];
    var _b = useState(''), searchText = _b[0], setSearchText = _b[1];
    var actualSearchValue = useMemo(function () { return searchText.trim().toLowerCase(); }, [searchText]);
    var filteredTaskGroups = useMemo(function () {
        var filteredList = actualSearchValue
            ? taskGroupList.filter(function (taskGroup) {
                return taskGroup.name.toLowerCase().includes(actualSearchValue) ||
                    taskGroup.displayId.toLowerCase().includes(actualSearchValue) ||
                    taskGroup.description.toLowerCase().includes(actualSearchValue);
            })
            : taskGroupList;
        var creatingNewTaskGroup = selectedTaskGroupId === NEW_TASK_GROUP_ID;
        return creatingNewTaskGroup ? __spreadArray([DEFAULT_TASK_GROUP_INFO], filteredList, true) : filteredList;
    }, [actualSearchValue, selectedTaskGroupId, taskGroupList]);
    useEffect(function () {
        if (!selectedTaskGroupId && filteredTaskGroups.length) {
            setSelectedTaskGroupId(filteredTaskGroups[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTaskGroups]);
    return (_jsxs("div", { className: "flex flex-col items-center w-full", children: [_jsx(TaskSearchBar, { placeholder: "Search", value: searchText, onChange: function (e) { return setSearchText(e.target.value); } }), _jsx("div", { className: "flex flex-col w-full items-center overflow-hidden border-t-2 border-gray-50", children: filteredTaskGroups.map(function (group) { return (_jsx(TaskGroupListItemV2, { group: group }, group.id)); }) }), !filteredTaskGroups.length && (_jsx("div", { className: "text-lg font-medium text-gray-300 mt-5", children: "No task groups found" }))] }));
};
export default React.memo(TaskGroupListV2);
