var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SingleSelectOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.9999 5.74089C13.9454 5.74089 16.3333 8.1287 16.3333 11.0742C16.3333 14.0197 13.9454 16.4076 10.9999 16.4076C8.0544 16.4076 5.66659 14.0197 5.66659 11.0742C5.66659 8.1287 8.0544 5.74089 10.9999 5.74089ZM10.9999 4.40755C14.6818 4.40755 17.6666 7.39232 17.6666 11.0742C17.6666 14.7561 14.6818 17.7409 10.9999 17.7409C7.31802 17.7409 4.33325 14.7561 4.33325 11.0742C4.33325 7.39232 7.31802 4.40755 10.9999 4.40755Z", fill: "#A7A8AC" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.1212 9.53896C13.2514 9.40879 13.4624 9.40879 13.5926 9.53896L14.064 10.0104C14.1942 10.1405 14.1942 10.3516 14.064 10.4818L11.2356 13.3102C11.1054 13.4404 10.8944 13.4404 10.7642 13.3102L7.93577 10.4818C7.80559 10.3516 7.80559 10.1405 7.93577 10.0104L8.40717 9.53896C8.53735 9.40879 8.7484 9.40879 8.87857 9.53896L10.9999 11.6603L13.1212 9.53896Z", fill: "#A7A8AC" })] })); };
export var SingleSelectOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: SingleSelectOutlinedSvg }, props))); };
