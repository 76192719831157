var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
var MobileCustomCardDropdownComponent = function (_a) {
    var content = _a.content, setDropdownVisible = _a.setDropdownVisible, disabled = _a.disabled, className = _a.className, _b = _a.style, style = _b === void 0 ? {} : _b;
    return (_jsx(Dropdown, { menu: {
            items: [],
        }, disabled: disabled, trigger: ['click'], onOpenChange: setDropdownVisible, getPopupContainer: function (triggerNode) { return triggerNode; }, overlayClassName: "cursor-auto", destroyPopupOnHide: true, dropdownRender: function () {
            return (_jsx("div", { id: "custom-job-card-dropdown", className: "bg-white shadow-lg rounded-sm overflow-auto max-h-[60vh] mr-2 ".concat(className !== null && className !== void 0 ? className : ''), style: __assign({ boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)' }, style), children: content }));
        }, children: _jsxs("div", { className: "rounded bg-gray-50 px-3 py-1 font-medium hover:".concat(disabled ? 'cursor-not-allowed' : 'cursor-pointer'), children: ["Select Options ", _jsx(DownOutlined, {})] }) }));
};
export default MobileCustomCardDropdownComponent;
