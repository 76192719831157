import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BottomBarExpandIcon } from 'src/utils/icons/SchedulingIcons/BottomBarExpandIcon';
import { BottomBarCollapseIcon } from 'src/utils/icons/SchedulingIcons/BottomBarCollapseIcon';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { ifUserHasGeneralPermission, isNeedsCoverageEnabledState, scheduleBottomBarTabsSelector, scheduleViewTypeSelector, } from 'src/state';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import './ScheduleBottomBar.scss';
import { Resizable } from 're-resizable';
import ScheduleStatsSummary from './SchedulingStats/ScheduleStatsSummary';
import { scheduleBottomBarTabsMap } from './Constant';
import { firstAvailableScheduleBottomBarTabSelector, noAvailableScheduleBottomBarTabsSelector, } from './Selector';
import { scheduleBottomBarHeightState, schedulingBlockBottomBarExpandedStatusState, schedulingBottomBarActiveTabState, } from './Atom';
import BottomSectionTabConfigDropdown from './BottomSectionTabConfigDropdown';
import { SchedulingSummaryType } from './interface';
import ScheduleNeedsCoverageSummaryHeader from './Needs&Coverage/NeedsCoverageSummary/ScheduleNeedsCoverageSummaryHeader';
import NeedsCoverageConfigureButton from './Needs&Coverage/NeedsCoverageConfigureButton';
import ScheduleStatsVisibilityDropdown from './SchedulingStats/ScheduleStatsVisibilityDropdown';
import RatioConfigureButton from './DemandRatio/RatioConfigureButton';
import RatioSummary from './DemandRatio/RatioSummary';
var ScheduleBottomBar = function (props) {
    var blockId = props.blockId;
    var _a = useRecoilState(schedulingBlockBottomBarExpandedStatusState(blockId)), isExpanded = _a[0], setIsExpanded = _a[1];
    var _b = useRecoilState(schedulingBottomBarActiveTabState(blockId)), activeTab = _b[0], setActiveTab = _b[1];
    var bottomSectionTabOptions = useRecoilValue(scheduleBottomBarTabsSelector(blockId));
    var noAvailableScheduleBottomBarTab = useRecoilValue(noAvailableScheduleBottomBarTabsSelector(blockId));
    var firstAvailableScheduleBottomBarTab = useRecoilValue(firstAvailableScheduleBottomBarTabSelector(blockId));
    var isNeedsCoveragePermissionAllowed = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE));
    var isNeedsEditAllowed = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_EDIT_PERMISSION));
    var isCoverageEditAllowed = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.COVERAGE_EDIT_PERMISSION));
    var isRatioEditAllowed = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.RATIO_EDIT_PERMISSION));
    var anyEditsAllowed = isNeedsEditAllowed ||
        isCoverageEditAllowed ||
        isRatioEditAllowed ||
        isNeedsCoveragePermissionAllowed;
    var isNeedsCoverageViewOnlyPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE_VIEW_ONLY));
    var needAndCoverageEnabled = useRecoilValue(isNeedsCoverageEnabledState) &&
        (isNeedsCoveragePermissionAllowed || isNeedsCoverageViewOnlyPermission);
    useEffect(function () {
        if (activeTab && !bottomSectionTabOptions[activeTab]) {
            setActiveTab(firstAvailableScheduleBottomBarTab);
        }
        else if (!activeTab) {
            setActiveTab(firstAvailableScheduleBottomBarTab);
        }
        else if (!needAndCoverageEnabled) {
            setActiveTab(firstAvailableScheduleBottomBarTab);
        }
    }, [
        activeTab,
        bottomSectionTabOptions,
        firstAvailableScheduleBottomBarTab,
        needAndCoverageEnabled,
        setActiveTab,
    ]);
    //! To get the width of the columns in the table
    var _c = useState('100%'), tableWidth = _c[0], setTableWidth = _c[1];
    var _d = useState(175), tableFirstColumnWith = _d[0], setTableFirstColumnWith = _d[1];
    var viewType = useRecoilValue(scheduleViewTypeSelector(blockId));
    var _f = useState(false), tableNeedsResync = _f[0], setTableNeedsResync = _f[1];
    useEffect(function () {
        var scheduleTable = document.querySelector('.ant-table-body');
        if (!scheduleTable) {
            setTimeout(function () { return setTableNeedsResync(true); }, 1000);
            return;
        }
        var resizeObserver = new ResizeObserver(function (entries) {
            if (entries[0]) {
                setTableWidth("".concat(entries[0].target.clientWidth, "px"));
                var firstColumn = entries[0].target.querySelector('.ScheduleTablePrimaryColumn');
                if (firstColumn) {
                    // Extra 1 px for the border
                    setTableFirstColumnWith(firstColumn.clientWidth + 1);
                }
            }
        });
        resizeObserver.observe(scheduleTable);
        setTableNeedsResync(false);
        return function () { return resizeObserver.disconnect(); };
    }, [viewType, tableNeedsResync]);
    //! Sync scroll of the table and the bottom bar
    useEffect(function () {
        var scheduleTable = document.querySelector('.ant-table-body');
        if (!scheduleTable)
            return;
        var handleTableScroll = function (e) {
            var table = e.target;
            var scrollContainer = document === null || document === void 0 ? void 0 : document.querySelector('.SummaryScrollContainer');
            if (scrollContainer) {
                scrollContainer.scrollLeft = table.scrollLeft;
            }
        };
        scheduleTable.addEventListener('scroll', handleTableScroll);
        return function () { return scheduleTable === null || scheduleTable === void 0 ? void 0 : scheduleTable.removeEventListener('scroll', handleTableScroll); };
    }, [tableNeedsResync]);
    var renderTabs = useMemo(function () {
        return Object.entries(scheduleBottomBarTabsMap).map(function (_a) {
            var key = _a[0], info = _a[1];
            var tabKey = key;
            var isTabVisible = bottomSectionTabOptions[tabKey];
            if (!isTabVisible ||
                ((tabKey === SchedulingSummaryType.NEEDS_AND_COVERAGE ||
                    tabKey === SchedulingSummaryType.DEMAND_RATIO) &&
                    !needAndCoverageEnabled))
                return _jsx(_Fragment, {});
            var isSelected = tabKey === activeTab;
            return (_jsxs("div", { className: "flex h-full cursor-pointer items-center ".concat(isSelected ? 'bg-[#F8F8F9]' : 'bg-white', " border-r border-gray-30 px-3"), onClick: function () { return setActiveTab(tabKey); }, children: [_jsx("div", { className: "".concat(isSelected ? 'text-black' : 'text-gray-500'), children: info.icon }), _jsx("div", { className: "ml-1 font-medium ".concat(isSelected ? 'text-black' : 'text-gray-500', " mt-[3px]"), children: info.name })] }, "".concat(tabKey, "+").concat(info.name, "}")));
        });
    }, [activeTab, bottomSectionTabOptions, needAndCoverageEnabled, setActiveTab]);
    var renderSummaryContent = useMemo(function () {
        if (noAvailableScheduleBottomBarTab) {
            return (_jsxs("div", { className: "flex h-[100px] flex-col justify-center", children: [_jsx("div", { className: "text-center text-base font-medium text-gray-300", children: "Empty Content" }), _jsxs("div", { className: "flex justify-center text-gray-300", children: ["Click", _jsx("span", { className: "mx-1 rounded bg-gray-40 px-1 text-center font-medium text-gray-500", children: "\u22EE" }), "to change settings"] })] }));
        }
        if (activeTab === SchedulingSummaryType.SCHEDULING_STATS) {
            return (_jsx(ScheduleStatsSummary, { blockId: blockId, widths: { tableWidth: tableWidth, tableFirstColumnWith: tableFirstColumnWith } }));
        }
        else if (activeTab === SchedulingSummaryType.NEEDS_AND_COVERAGE) {
            return (_jsx(ScheduleNeedsCoverageSummaryHeader, { blockId: blockId, widths: { tableWidth: tableWidth, tableFirstColumnWith: tableFirstColumnWith } }));
        }
        else if (activeTab === SchedulingSummaryType.DEMAND_RATIO) {
            return _jsx(RatioSummary, { blockId: blockId, widths: { tableWidth: tableWidth, tableFirstColumnWith: tableFirstColumnWith } });
        }
    }, [activeTab, blockId, noAvailableScheduleBottomBarTab, tableFirstColumnWith, tableWidth]);
    var _g = useRecoilState(scheduleBottomBarHeightState(blockId)), height = _g[0], setHeight = _g[1];
    return (_jsx("div", { className: " absolute bottom-0 z-[4] w-full bg-white", children: isExpanded ? (_jsxs(Resizable, { className: "ScheduleBottomBarFullView", onResizeStop: function (_e, _direction, ref) {
                setHeight(ref.clientHeight);
            }, size: { height: height, width: '100%' }, minHeight: 200, maxHeight: '80vh', enable: { top: true }, children: [_jsxs("div", { className: "flex justify-between border-b border-gray-30", children: [_jsx("div", { className: "flex", children: renderTabs }), _jsxs("div", { className: "relative mr-2 flex py-2", children: [!noAvailableScheduleBottomBarTab &&
                                    activeTab === SchedulingSummaryType.SCHEDULING_STATS && (_jsx(ScheduleStatsVisibilityDropdown, { blockId: blockId })), !noAvailableScheduleBottomBarTab &&
                                    activeTab === SchedulingSummaryType.NEEDS_AND_COVERAGE &&
                                    anyEditsAllowed && _jsx(NeedsCoverageConfigureButton, {}), !noAvailableScheduleBottomBarTab &&
                                    activeTab === SchedulingSummaryType.DEMAND_RATIO && (_jsx(RatioConfigureButton, {})), _jsx(BottomSectionTabConfigDropdown, { blockId: blockId }), _jsx(ZiraTooltip, { title: "Collapse Bottom Section", children: _jsx("div", { onClick: function () { return setIsExpanded(false); }, className: "cursor-pointer rounded-lg bg-gray-40 px-2 py-1", children: _jsx(BottomBarCollapseIcon, {}) }) })] })] }), renderSummaryContent] })) : (_jsxs("div", { className: "ScheduleBottomBarCollapsedView", onClick: function () {
                if (!isExpanded) {
                    setIsExpanded(true);
                }
            }, children: [_jsx(BottomBarExpandIcon, {}), _jsx("div", { className: "ml-[5px] mt-[2px] text-xs font-medium text-gray-600", children: "Click to open" })] })) }));
};
export default React.memo(ScheduleBottomBar);
