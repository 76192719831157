var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PaidShiftsIconSvg = function () { return (_jsxs("svg", { width: "32", height: "33", viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { y: "0.5", width: "32", height: "32", rx: "16", fill: "#2F4DDF" }), _jsx("path", { d: "M7.66667 14H24.3333V15.6667H7.66667V14Z", fill: "white" }), _jsx("path", { d: "M11 17.7497C11 17.5196 11.1865 17.333 11.4167 17.333H13.9167C14.1468 17.333 14.3333 17.5196 14.3333 17.7497V18.583C14.3333 18.8131 14.1468 18.9997 13.9167 18.9997H11.4167C11.1865 18.9997 11 18.8131 11 18.583V17.7497Z", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.08334 10.6665C7.85322 10.6665 7.66667 10.8531 7.66667 11.0832V21.9165C7.66667 22.1466 7.85322 22.3332 8.08334 22.3332H23.9167C24.1468 22.3332 24.3333 22.1466 24.3333 21.9165V11.0832C24.3333 10.8531 24.1468 10.6665 23.9167 10.6665H8.08334ZM9.33334 12.3332V20.6665H22.6667V12.3332H9.33334Z", fill: "white" })] })); };
export var PaidShiftsIcon = function (props) { return (_jsx(Icon, __assign({ component: PaidShiftsIconSvg }, props))); };
