import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import DefaultRateTable from 'src/components/Settings/PayRate/DefaultRatesTab/DefaultRates/DefaultRatesTable/DefaultRateTable';
import usePayRateV2Api from 'src/components/Settings/PayRate/usePayRateV2Api';
var UserDefaultRateTable = function (_a) {
    var recordId = _a.recordId;
    var useGetDefaultRatesForIndividualUser = usePayRateV2Api().useGetDefaultRatesForIndividualUser;
    var _b = useGetDefaultRatesForIndividualUser(recordId), defaultRateData = _b.data, isLoading = _b.isLoading;
    return (_jsx(DefaultRateTable, { defaultRateData: defaultRateData, isLoading: isLoading, canEditTable: false }));
};
export default React.memo(UserDefaultRateTable);
