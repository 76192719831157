import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TeamOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { useWindowSize } from 'src/hooks/component';
import { selectedBroadcastRecipientsSelector } from 'src/state/selectors/withChatState';
import ChatUserListModal from '../ChatContent/MessagesScreen/ChatUserListModal';
var BroadcastRecipientsCount = function () {
    var selectedBroadcastRecipients = useRecoilValue(selectedBroadcastRecipientsSelector);
    var useGetBroadcastRecipients = useChatApi().useGetBroadcastRecipients;
    var broadcastRecipients = useGetBroadcastRecipients({
        recipients: selectedBroadcastRecipients,
    }).data;
    var windowWidth = useWindowSize().windowWidth;
    var hideText = (windowWidth || 577) <= 576;
    if (!broadcastRecipients) {
        return null;
    }
    return (_jsx(ChatUserListModal, { defaultUserList: broadcastRecipients, children: _jsx(ZiraTooltip, { title: "Open Member List", placement: "bottomRight", children: _jsxs("div", { className: "BroadcastRecipientsCountRow mr-3 cursor-pointer rounded-[15px] bg-white px-[12px] py-[8px] text-sm font-medium shadow-block", children: [_jsx(TeamOutlined, { className: "mr-2" }), broadcastRecipients.length, !hideText && " Member".concat(broadcastRecipients.length > 1 ? 's' : '')] }) }) }));
};
export default BroadcastRecipientsCount;
