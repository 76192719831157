import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Select } from 'antd';
import { MetricDateRanges } from 'src/shared';
import { useRecoilState } from 'recoil';
import { reportVariableDateRangeTypeState } from 'src/state';
var Option = Select.Option;
var ReportVariableDateRangeSelector = function (_a) {
    var reportId = _a.reportId;
    var _b = useRecoilState(reportVariableDateRangeTypeState(reportId)), dateRangeType = _b[0], setDateRangeType = _b[1];
    return (_jsx("div", { className: "ReportDateRangeSelector", children: _jsx(Select, { value: dateRangeType, onChange: setDateRangeType, className: "w-[165px]", children: MetricDateRanges.map(function (metric) { return (_jsx(Option, { value: metric.value, children: metric.label }, metric.value)); }) }) }));
};
export default React.memo(ReportVariableDateRangeSelector);
