var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FileOutlinedSvg = function () { return (_jsx("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M13.6518 5.58694C13.1497 5.58694 12.6682 5.77347 12.3132 6.10548L6.5184 11.5248C5.92675 12.0781 5.59436 12.8286 5.59436 13.6111C5.59436 14.3936 5.92675 15.144 6.5184 15.6973C7.11005 16.2506 7.91251 16.5615 8.74923 16.5615C9.58595 16.5615 10.3884 16.2506 10.9801 15.6973L16.5472 10.491C16.6753 10.3711 16.8744 10.3711 17.0025 10.491L17.4063 10.8685C17.5471 11.0003 17.5471 11.2237 17.4063 11.3555L11.8718 16.5313C11.0436 17.3058 9.92042 17.7409 8.74923 17.7409C7.57804 17.7409 6.45482 17.3058 5.62666 16.5313C4.79851 15.7568 4.33325 14.7064 4.33325 13.6111C4.33325 12.5158 4.79851 11.4653 5.62666 10.6908L11.4214 5.27153C12.013 4.71833 12.8152 4.40755 13.6518 4.40755C14.4883 4.40755 15.2906 4.71833 15.8821 5.27153C16.4736 5.82472 16.806 6.57501 16.806 7.35734C16.806 8.13967 16.4736 8.88996 15.8821 9.44316L10.081 14.8625C9.72614 15.1943 9.24481 15.3808 8.74292 15.3808C8.24104 15.3808 7.75971 15.1943 7.40482 14.8625C7.04994 14.5306 6.85056 14.0804 6.85056 13.6111C6.85056 13.1417 7.04994 12.6916 7.40482 12.3597L12.7585 7.3588C13.0049 7.12865 13.4041 7.12887 13.6502 7.35929C13.8963 7.58972 13.8961 7.96309 13.6497 8.19325L8.29656 13.1936C8.17834 13.3043 8.11167 13.4546 8.11167 13.6111C8.11167 13.7676 8.17818 13.9178 8.29656 14.0285C8.41494 14.1392 8.57551 14.2014 8.74292 14.2014C8.91034 14.2014 9.07091 14.1392 9.18929 14.0285L14.9904 8.6092C15.3452 8.27721 15.5448 7.82677 15.5448 7.35734C15.5448 6.8878 15.3454 6.4375 14.9904 6.10548C14.6354 5.77347 14.1539 5.58694 13.6518 5.58694Z", fill: "#A7A8AC" }) })); };
export var FileOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: FileOutlinedSvg }, props))); };
