var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { OrganizationContext } from 'src/App';
var SignupButton = function (props) {
    var loginWithRedirect = useAuth0().loginWithRedirect;
    var buttonClassName = props.className, text = props.text;
    var displayText = useMemo(function () { return text || 'Sign Up'; }, [text]);
    var org = useContext(OrganizationContext);
    return (_jsx(Button, { className: buttonClassName, onClick: function () {
            return loginWithRedirect({
                authorizationParams: __assign({ screen_hint: 'signup' }, (org && { organization: org })),
            });
        }, children: displayText }));
};
export default React.memo(SignupButton);
