var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Menu } from 'antd';
import _, { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue } from 'recoil';
import { existingDetailModalInfo } from 'src/state';
import { useDisplaySettings, useSchemaComponent } from 'src/hooks/component';
import { useSetting } from 'src/hooks/api';
import FormulaModal from 'src/components/OptimizedTableView/Cell/FormulaCell/FormulaModal';
import { MenuOutlined } from '@ant-design/icons';
import MenuTitleWithIcon from 'src/components/SharedComponent/DropdownMenu/MenuTitleWithIcon';
import ObjectInlinePermission from 'src/components/Settings/Permission/ObjectPermission/ObjectInlinePermission';
import CreateSchemaModalV2 from 'src/components/CollectionComponents/CreateSchemaModal/CreateSchemaModalV2';
import HardConfirmWrapper from 'src/components/HardConfirmWrapper/HardConfirmWrapper';
import { DeleteOutlinedIcon } from '../../../utils/icons/DropdownIcons/DeleteOutlined';
import { DuplicateOutlinedIcon } from '../../../utils/icons/DropdownIcons/DuplicateOutlined';
import { PlusGreyOutlinedIcon } from '../../../utils/icons/DropdownIcons/PlusGreyOutlined';
import './TableHeaderDropdown.scss';
import { CellInputMode, ObjType, QuickCreateNativePathDefaultSet, SortSettingClause, SubMenuContentIcon, ValueDataType, } from '../../../shared';
import { ArrowUpOutlinedIcon } from '../../../utils/icons/ArrowUpOutlined';
import { ArrowDownOutlinedIcon } from '../../../utils/icons/ArrowDownOutline';
import { AddColorOutlinedIcon } from '../../../utils/icons/DropdownIcons/AddColorOutlined';
import { disableScroll, getColumnTypeKey, isNativePrimaryColumn } from '../../../utils/Collection';
import ZiraDropdown from '../../../ui/ZiraDropdown';
import MenuGroupTitle from '../../SharedComponent/DropdownMenu/MenuGroupTitle';
var TableHeaderDropdown = React.memo(function (props) {
    var blockId = props.blockId, recordId = props.recordId, defaultColumn = props.column, displaySettings = props.displaySettings, canEdit = props.canEdit, isDetailModal = props.isDetailModal, addColorToCollection = props.addColorToCollection, collectionId = props.collectionId, isCreatingNew = props.isCreatingNew, getPreSaveRequestBody = props.getPreSaveRequestBody;
    var column = useMemo(function () { return defaultColumn; }, [defaultColumn]);
    var _a = useState(false), isDropdownVisible = _a[0], setIsDropdownVisible = _a[1];
    var _b = useState(false), isFormulaConfigVisible = _b[0], setIsFormulaConfigVisible = _b[1];
    var _c = useState(false), isSchemaConfigModalVisible = _c[0], setIsSchemaConfigModalVisible = _c[1];
    var fetchFormulaForSchema = useSchemaComponent().fetchFormulaForSchema;
    var useSaveQuickMenuSettings = useSetting().useSaveQuickMenuSettings;
    useEffect(function () {
        if (column && column.formulaId)
            fetchFormulaForSchema(column.id, column.formulaId);
    }, [column, fetchFormulaForSchema]);
    var _d = useState(column ? column.isQuickCreate : false), colIsQuickCreate = _d[0], setColIsQuickCreate = _d[1];
    var _e = useState(column ? column.name : 'New Column'), currentColName = _e[0], setCurrentColName = _e[1];
    var _f = useState(column ? getColumnTypeKey(column.inputMode, column.type, column.nativePath) : 'text'), columnTypeKey = _f[0], setColumnTypeKey = _f[1];
    var isNativeOrGeneratedSchema = useMemo(function () {
        return (column === null || column === void 0 ? void 0 : column.inputMode) === CellInputMode.NATIVE ||
            (column === null || column === void 0 ? void 0 : column.inputMode) === CellInputMode.GENERATED;
    }, [column === null || column === void 0 ? void 0 : column.inputMode]);
    var isTitleTypeColumn = useMemo(function () { return columnTypeKey === 'title' || isNativePrimaryColumn(column); }, [column, columnTypeKey]);
    var isFormulaTypeColumn = useMemo(function () { return columnTypeKey === 'formula'; }, [columnTypeKey]);
    var _g = useState(false), isInput = _g[0], setIsInput = _g[1];
    var referenceDetailModalInfo = useRecoilValue(existingDetailModalInfo);
    var isNewReverseCollectionRecord = !!new URLSearchParams(location.search).get('rid') && isCreatingNew;
    var preSaveRequest = getPreSaveRequestBody && isNewReverseCollectionRecord && referenceDetailModalInfo
        ? getPreSaveRequestBody(blockId, collectionId, referenceDetailModalInfo.collectionInfo.id, referenceDetailModalInfo.rows[0].recordId)
        : getPreSaveRequestBody
            ? getPreSaveRequestBody(blockId, collectionId)
            : undefined;
    var _h = useSchemaComponent(), updateColumnName = _h.updateColumnName, deleteColumn = _h.deleteColumn, mutateColumn = _h.mutateColumn, addNewColumn = _h.addNewColumn, backfillGenerativeColumn = _h.backfillGenerativeColumn;
    var _j = useDisplaySettings(), addSortEntryToBlock = _j.addSortEntryToBlock, editSortEntryInBlock = _j.editSortEntryInBlock, showOrHideColumn = _j.showOrHideColumn;
    var requestFromBodyForDetailModal = useMemo(function () {
        if (preSaveRequest) {
            return {
                proposedRecordId: recordId,
                request: preSaveRequest,
                requestFrom: 'pre_save',
            };
        }
        return {
            recordId: recordId,
            requestFrom: 'record_details',
        };
    }, [preSaveRequest, recordId]);
    useEffect(function () {
        disableScroll(isDropdownVisible || isFormulaConfigVisible);
    }, [isDropdownVisible, isFormulaConfigVisible]);
    useEffect(function () {
        return function () { return disableScroll(false); };
    }, []);
    var findSortEntry = useCallback(function (property) {
        var result = _.find(displaySettings === null || displaySettings === void 0 ? void 0 : displaySettings.sortBy, function (entry) { return entry.property === property; });
        return result;
    }, [displaySettings === null || displaySettings === void 0 ? void 0 : displaySettings.sortBy]);
    var handleSortOnClick = useCallback(function (sort) {
        var entry = findSortEntry(column.id);
        var sortOpposite = sort === SortSettingClause.ASC ? SortSettingClause.DESC : SortSettingClause.ASC;
        if (!entry) {
            addSortEntryToBlock(blockId, {
                id: uuidv4(),
                property: column === null || column === void 0 ? void 0 : column.id,
                order: sort,
            });
        }
        else {
            if (entry.order === sortOpposite) {
                editSortEntryInBlock(blockId, {
                    id: entry.id,
                    property: entry.property,
                    order: sort,
                });
            }
        }
    }, [addSortEntryToBlock, blockId, column, editSortEntryInBlock, findSortEntry]);
    var handleSortASCOnClick = useCallback(function () {
        handleSortOnClick(SortSettingClause.ASC);
    }, [handleSortOnClick]);
    var handleSortDESCOnClick = useCallback(function () {
        handleSortOnClick(SortSettingClause.DESC);
    }, [handleSortOnClick]);
    var onToggleSchemaForQuickCreateMenu = useCallback(function () {
        if (collectionId && (column === null || column === void 0 ? void 0 : column.id)) {
            useSaveQuickMenuSettings.mutate({
                collectionId: collectionId,
                schemaId: column === null || column === void 0 ? void 0 : column.id,
                isQuickCreate: !colIsQuickCreate,
            });
            setColIsQuickCreate(!colIsQuickCreate);
        }
    }, [colIsQuickCreate, collectionId, column === null || column === void 0 ? void 0 : column.id, useSaveQuickMenuSettings]);
    var onAddColorToCollection = useCallback(function () {
        addColorToCollection === null || addColorToCollection === void 0 ? void 0 : addColorToCollection();
        setIsDropdownVisible(false);
    }, [addColorToCollection]);
    var getSubMenuTitle = useCallback(function (key) {
        switch (key) {
            case 'datetime':
                return 'Date';
            case 'currency':
                return 'Pay';
            case 'custom':
                return 'Link To Object';
            case 'native':
                return 'Link To Object';
            case 'multi':
                return 'Multi Select';
            case 'single':
                return 'Single Select';
            case 'generative':
                return 'Generated';
            default:
                return key;
        }
    }, []);
    var columnNameInputOnBlur = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var editedColumn;
        return __generator(this, function (_a) {
            editedColumn = cloneDeep(column);
            if (e.target.value === (editedColumn === null || editedColumn === void 0 ? void 0 : editedColumn.name))
                return [2 /*return*/];
            editedColumn.name = e.target.value;
            if (isDetailModal) {
                updateColumnName(requestFromBodyForDetailModal, column.id, editedColumn, collectionId);
            }
            else {
                updateColumnName({ requestFrom: 'block', blockId: blockId }, column.id, editedColumn);
            }
            setCurrentColName(e.target.value);
            setIsInput(false);
            return [2 /*return*/];
        });
    }); };
    var onInsertLeftRightClick = useCallback(function (isLeft) {
        addNewColumn('block', blockId, {
            relativeSchemaId: column.id,
            insertLocation: isLeft ? 'LEFT' : 'RIGHT',
            toDuplicate: false,
            proposedSchemaId: uuidv4(),
        });
    }, [addNewColumn, blockId, column]);
    var deleteSchema = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (isDetailModal) {
                deleteColumn(requestFromBodyForDetailModal, column.id, collectionId);
            }
            else {
                deleteColumn({ requestFrom: 'block', blockId: blockId }, column.id);
            }
            return [2 /*return*/];
        });
    }); }, [blockId, collectionId, column, deleteColumn, isDetailModal, requestFromBodyForDetailModal]);
    var menu = (_jsxs(Menu, { className: "DropdownMenu", triggerSubMenuAction: 'click', onClick: function (info) { return __awaiter(void 0, void 0, void 0, function () {
            var menuKey, editedColumn;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isInput)
                            return [2 /*return*/];
                        menuKey = info.keyPath[0];
                        if (!_.hasIn(SubMenuContentIcon, menuKey)) return [3 /*break*/, 3];
                        setColumnTypeKey(menuKey);
                        editedColumn = cloneDeep(column);
                        editedColumn.name = currentColName;
                        if (menuKey === 'linkToObj' ||
                            menuKey === 'linkToSchema' ||
                            menuKey === 'metric') {
                            return [2 /*return*/];
                        }
                        if (menuKey === 'multi' || menuKey === 'single') {
                            editedColumn.inputMode = menuKey;
                            editedColumn.type = ValueDataType.PLAIN_SELECTION;
                        }
                        else if (menuKey === 'formula') {
                            editedColumn.inputMode = CellInputMode.GENERATED;
                            editedColumn.type = menuKey;
                            editedColumn.formulaId = uuidv4();
                        }
                        else {
                            editedColumn.inputMode = CellInputMode.PLAIN;
                            editedColumn.type = menuKey;
                        }
                        if (!isDetailModal) return [3 /*break*/, 2];
                        return [4 /*yield*/, mutateColumn(requestFromBodyForDetailModal, column.id, editedColumn, collectionId)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        mutateColumn({ requestFrom: 'block', blockId: blockId }, column.id, editedColumn);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }, children: [_jsx("div", { className: "DropdownInputWrapper", children: _jsx(Input, { autoFocus: true, onFocus: function (e) {
                        e.target.select();
                    }, value: currentColName, className: "DropDownInput", onClick: function (e) {
                        setIsInput(true);
                        e.stopPropagation();
                    }, onChange: function (e) { return setCurrentColName(e.target.value); }, onBlur: columnNameInputOnBlur, onPressEnter: function (e) {
                        e.currentTarget.blur();
                    }, disabled: isNativeOrGeneratedSchema }) }), !(column === null || column === void 0 ? void 0 : column.nativePath) && (_jsx(Menu.ItemGroup, { title: _jsx(MenuGroupTitle, { text: "Type" }), children: _jsx(Menu.Item, { onClick: function () { return setIsSchemaConfigModalVisible(true); }, children: _jsx(MenuTitleWithIcon, { icon: SubMenuContentIcon[columnTypeKey], text: getSubMenuTitle(columnTypeKey) }) }, "ColumnType") })), _jsxs(Menu.ItemGroup, { title: _jsx(MenuGroupTitle, { text: "Actions" }), children: [column &&
                        !column.isRequired &&
                        ![CellInputMode.GENERATED, CellInputMode.METRIC].includes(column.inputMode) &&
                        (!column.nativePath ||
                            (column.nativePath &&
                                !QuickCreateNativePathDefaultSet.has(column.nativePath))) && (_jsx(Menu.Item, { onClick: onToggleSchemaForQuickCreateMenu, children: _jsx(MenuTitleWithIcon, { icon: _jsx(AddColorOutlinedIcon, {}), text: (colIsQuickCreate ? 'Remove from ' : 'Add to ') +
                                ' Quick Create' }) })), isTitleTypeColumn && (_jsx(Menu.Item, { onClick: onAddColorToCollection, children: _jsx(MenuTitleWithIcon, { icon: _jsx(AddColorOutlinedIcon, {}), text: 'Add Color' }) })), !isDetailModal && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { onClick: handleSortASCOnClick, children: _jsx(MenuTitleWithIcon, { icon: _jsx(ArrowUpOutlinedIcon, {}), text: 'Sort Ascending' }) }), _jsx(Menu.Item, { onClick: handleSortDESCOnClick, children: _jsx(MenuTitleWithIcon, { icon: _jsx(ArrowDownOutlinedIcon, {}), text: 'Sort Descending' }) }), _jsx(Menu.Item, { onClick: function () { return onInsertLeftRightClick(true); }, children: _jsx(MenuTitleWithIcon, { icon: _jsx(PlusGreyOutlinedIcon, {}), text: 'Insert left' }) }), _jsx(Menu.Item, { onClick: function () { return onInsertLeftRightClick(false); }, children: _jsx(MenuTitleWithIcon, { icon: _jsx(PlusGreyOutlinedIcon, {}), text: 'Insert right' }) })] })), !isTitleTypeColumn && (_jsxs(_Fragment, { children: [(!isNativeOrGeneratedSchema || isFormulaTypeColumn) && (_jsx(Menu.Item, { children: _jsx(HardConfirmWrapper, { changeType: "delete", entityName: "column", onConfirm: deleteSchema, children: _jsx("span", { children: _jsx(MenuTitleWithIcon, { icon: _jsx(DeleteOutlinedIcon, {}), text: 'Delete' }) }) }) }, "delete")), !isDetailModal && (_jsx(_Fragment, { children: _jsx(Menu.Item, { onClick: function () { return showOrHideColumn(blockId, column.id, true); }, children: _jsx(MenuTitleWithIcon, { icon: _jsx(DuplicateOutlinedIcon, {}), text: 'Hide' }) }) }))] }))] }), _jsx(ObjectInlinePermission, { className: "px-5", objectId: column.id, type: ObjType.SCHEMA })] }));
    var dropDownOpenOnChange = useCallback(function (dropdownOpen) {
        setIsDropdownVisible(dropdownOpen);
    }, []);
    return column ? (_jsxs(_Fragment, { children: [_jsx(ZiraDropdown, { overlay: menu, overlayClassName: "DropdownMenu", trigger: ['click'], visible: isDropdownVisible, disabled: !canEdit, onLocalVisibleChange: dropDownOpenOnChange, children: _jsx("div", { className: "ColumnDropdownButton", onClick: function (e) { return e.preventDefault(); }, children: _jsx(MenuOutlined, {}) }) }), _jsx(FormulaModal, { schemaId: column.id, formulaId: column.formulaId, collectionId: collectionId, setIsClicked: setIsFormulaConfigVisible, isClicked: isFormulaConfigVisible }), mutateColumn && isSchemaConfigModalVisible && (_jsx(CreateSchemaModalV2, { currentColumn: column, collectionId: collectionId, isModalVisible: isSchemaConfigModalVisible, mutateColumn: function (newSchemaContent) {
                    return mutateColumn(requestFromBodyForDetailModal, newSchemaContent.id, newSchemaContent, collectionId);
                }, setIsModalVisible: function (visible) {
                    setIsSchemaConfigModalVisible(visible);
                }, backfillColumn: function (backfillExisting) {
                    return backfillGenerativeColumn(collectionId, column, backfillExisting);
                } }))] })) : (_jsx(_Fragment, {}));
});
TableHeaderDropdown.displayName = 'TableHeaderDropdown';
export default TableHeaderDropdown;
