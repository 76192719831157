import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { payExportOptionsMap } from '../Constant';
var PayExportOptionModal = function (_a) {
    var onCloseExportOptionModal = _a.onCloseExportOptionModal, onOpenExportReportModal = _a.onOpenExportReportModal, onOpenExportIntegrationModal = _a.onOpenExportIntegrationModal;
    var onSelectOptionCard = useCallback(function (optionType) {
        if (optionType === 'REPORT') {
            onOpenExportReportModal();
        }
        else if (optionType === 'INTEGRATION') {
            onOpenExportIntegrationModal();
        }
        onCloseExportOptionModal();
    }, [onCloseExportOptionModal, onOpenExportIntegrationModal, onOpenExportReportModal]);
    var renderOptionCard = useCallback(function (optionType) {
        return (_jsxs("div", { className: "flex flex-col !w-[200px] !h-[200px] rounded-xl text-base items-center justify-center bg-[#F2F8FA] p-[20px] cursor-pointer hover:shadow-hover", onClick: function () { return onSelectOptionCard(optionType); }, children: [_jsx("div", { className: "pb-[16px]", children: _jsx("img", { height: 36, width: 36, src: "data:image/svg+xml;utf8,".concat(encodeURIComponent(payExportOptionsMap[optionType].icon.replaceAll('#ffffff', '#000000'))) }) }), _jsx("div", { className: "text-base font-medium", children: payExportOptionsMap[optionType].name }), _jsx("div", { className: "text-xs text-gray-300 text-center font-normal", children: payExportOptionsMap[optionType].description })] }));
    }, [onSelectOptionCard]);
    return (_jsx(Modal, { width: 600, zIndex: 1100, className: "TaskOptionModal", open: true, onCancel: onCloseExportOptionModal, closable: false, footer: null, centered: true, maskClosable: true, children: _jsxs("div", { className: "flex flex-col space-y-[32px] p-[32px] pt-3", children: [_jsx("div", { className: "text-2xl font-semibold", children: "Select Export Type" }), _jsx("div", { className: "flex flex-wrap gap-[32px] items-center justify-center", children: Object.keys(payExportOptionsMap).map(function (option) {
                        return renderOptionCard(option);
                    }) })] }) }));
};
export default React.memo(PayExportOptionModal);
