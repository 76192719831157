import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useAccountApi } from 'src/hooks/api';
import { tableCollectionIdSelector } from 'src/state';
import { shiftCollectionLocationSchemaIdSelector } from '../../Selector';
var LocationSelector = function (props) {
    var blockId = props.blockId, isDetailConfigModal = props.isDetailConfigModal, selectedLocationOption = props.selectedLocationOption, updateSelectedLocationOption = props.updateSelectedLocationOption, clearTableData = props.clearTableData, isTableDataEmpty = props.isTableDataEmpty;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var locationSchemaId = useRecoilValue(shiftCollectionLocationSchemaIdSelector(collectionId));
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var locationList = useGetUniversalSimpleList({
        body: {
            schemaId: locationSchemaId,
        },
        options: {
            enabled: !!locationSchemaId,
        },
    }).data;
    var _a = useState(selectedLocationOption), location = _a[0], setLocation = _a[1];
    useEffect(function () {
        setLocation(selectedLocationOption);
    }, [selectedLocationOption]);
    var _b = useState(false), isWarningModalVisible = _b[0], setIsWarningModalVisible = _b[1];
    var warningModalFooter = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "CancelBtn", onClick: function () { return setIsWarningModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "SaveBtn", onClick: function () {
                    setIsWarningModalVisible(false);
                    updateSelectedLocationOption(location);
                    clearTableData === null || clearTableData === void 0 ? void 0 : clearTableData();
                }, children: "Yes" })] })); }, [clearTableData, location, updateSelectedLocationOption]);
    return (_jsxs(_Fragment, { children: [_jsxs(Select, { bordered: false, className: "LocationSelector", dropdownMatchSelectWidth: false, onChange: function (value) {
                    if (isDetailConfigModal && isTableDataEmpty === false) {
                        setLocation(value);
                        setIsWarningModalVisible(true);
                    }
                    else {
                        updateSelectedLocationOption(value);
                    }
                }, value: selectedLocationOption, children: [!isDetailConfigModal && (_jsx(_Fragment, { children: _jsx(Select.Option, { children: "Use Current Calendar Locations" }, "BlockFilter") })), _jsx(Select.Option, { children: "All Locations" }, "AllLocations"), locationList === null || locationList === void 0 ? void 0 : locationList.map(function (option) { return (_jsx(Select.Option, { children: option.name }, option.id)); })] }), isDetailConfigModal && !isTableDataEmpty && (_jsx(Modal, { zIndex: 1002, open: isWarningModalVisible, closable: false, footer: warningModalFooter, className: "LocationSelectorWarningModal", centered: true, children: _jsxs("div", { className: "flex items-center justify-between rounded bg-yellow-50 p-4", children: [_jsx(ExclamationCircleOutlined, { style: {
                                color: '#FFC047',
                                fontSize: '24px',
                                stroke: '#FFC047',
                                strokeWidth: '10',
                            }, size: 24 }), _jsx("div", { className: "ml-3 text-sm font-medium", children: "Select another location will clear all existing table data. Are you sure you want to switch to another location?" })] }) }))] }));
};
export default React.memo(LocationSelector);
