var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { getVariableDateRangeTimespan } from 'src/services';
import { ReportChartType, TrendReportTimespan, } from '..';
import ReportSourceCard from './ReportSourceCard';
import ReportTypeCard from './ReportTypeCard';
import ReportMetricsCard from './ReportMetricsCard';
var ReportBuilder = function (_a) {
    var _b, _c, _d;
    var timeZone = _a.timeZone, _e = _a.defaultReportValue, defaultReportValue = _e === void 0 ? {
        settings: {
            chartType: ReportChartType.BAR,
            showChart: true,
            showTable: true,
            sourceCollectionId: undefined,
            sourceFilters: [],
            metrics: [],
            schemas: [],
            formulas: [],
        },
        dateDetails: {
            dateRange: {
                type: 'fixed',
                startDate: moment().tz(timeZone).subtract(1, 'weeks').startOf('week').toISOString(),
                endDate: moment().tz(timeZone).subtract(1, 'weeks').endOf('week').toISOString(),
            },
            timespan: TrendReportTimespan.DAY,
        },
        displayConfig: {
            pageSize: 10,
            selectedRows: [],
        },
    } : _e, metricList = _a.metricList, setReportBuilderData = _a.setReportBuilderData;
    var settings = defaultReportValue.settings, dateDetails = defaultReportValue.dateDetails, displayConfig = defaultReportValue.displayConfig;
    var _f = useState(settings.sourceCollectionId), reportSourceCollectionId = _f[0], setReportSourceCollectionId = _f[1];
    var _g = useState(settings.sourceFilters), sourceFilters = _g[0], setSourceFilters = _g[1];
    var _h = useState(!!dateDetails.timespan), isTimeBased = _h[0], setIsTimeBased = _h[1];
    var _j = useState(dateDetails.dateRange.type === 'variable'), isVariableDateRange = _j[0], setIsVariableDateRange = _j[1];
    var _k = useState(settings.showChart), showChart = _k[0], setShowChart = _k[1];
    var _l = useState(settings.showTable), showTable = _l[0], setShowTable = _l[1];
    var _m = useState((_b = settings.metrics) !== null && _b !== void 0 ? _b : []), reportMetrics = _m[0], setReportMetrics = _m[1];
    var _o = useState((_c = settings.schemas) !== null && _c !== void 0 ? _c : []), reportSchemas = _o[0], setReportSchemas = _o[1];
    var _p = useState((_d = settings.formulas) !== null && _d !== void 0 ? _d : []), reportFormulas = _p[0], setReportFormulas = _p[1];
    var reportBuilderObject = useMemo(function () {
        var _a, _b, _c, _d, _e;
        return {
            settings: __assign(__assign({}, settings), { showChart: showChart, showTable: showTable, sourceCollectionId: reportSourceCollectionId, sourceFilters: sourceFilters, metrics: reportMetrics, schemas: reportSchemas, formulas: reportFormulas }),
            dateDetails: __assign(__assign({}, dateDetails), { dateRange: {
                    type: isVariableDateRange ? 'variable' : 'fixed',
                    dateRangeType: isVariableDateRange
                        ? ((_a = dateDetails.dateRange.dateRangeType) !== null && _a !== void 0 ? _a : 'last_seven_days')
                        : undefined,
                    startDate: !isVariableDateRange
                        ? ((_b = dateDetails.dateRange.startDate) !== null && _b !== void 0 ? _b : moment().tz(timeZone).subtract(1, 'weeks').startOf('week').toISOString())
                        : undefined,
                    endDate: !isVariableDateRange
                        ? ((_c = dateDetails.dateRange.endDate) !== null && _c !== void 0 ? _c : moment().tz(timeZone).subtract(1, 'weeks').endOf('week').toISOString())
                        : undefined,
                }, timespan: isTimeBased
                    ? isVariableDateRange
                        ? getVariableDateRangeTimespan((_d = dateDetails.dateRange.dateRangeType) !== null && _d !== void 0 ? _d : 'last_seven_days')
                        : ((_e = dateDetails.timespan) !== null && _e !== void 0 ? _e : TrendReportTimespan.DAY)
                    : null }),
            displayConfig: displayConfig,
        };
    }, [
        dateDetails,
        displayConfig,
        isTimeBased,
        isVariableDateRange,
        reportFormulas,
        reportMetrics,
        reportSchemas,
        reportSourceCollectionId,
        settings,
        showChart,
        showTable,
        sourceFilters,
        timeZone,
    ]);
    useEffect(function () {
        setReportBuilderData(reportBuilderObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportBuilderObject]);
    return (_jsxs("div", { className: "ReportBuilder", children: [_jsx(ReportSourceCard, { reportSourceCollectionId: reportSourceCollectionId, setReportSource: setReportSourceCollectionId, sourceFilters: sourceFilters, setSourceFilters: setSourceFilters }), _jsx("div", { className: "my-4" }), _jsx(ReportTypeCard, { isTimeBased: isTimeBased, setIsTimeBased: setIsTimeBased, isVariableDateRange: isVariableDateRange, setIsVariableDateRange: setIsVariableDateRange, showChart: showChart, setShowChart: setShowChart, showTable: showTable, setShowTable: setShowTable }), _jsx("div", { className: "my-4" }), _jsx(ReportMetricsCard, { metricList: metricList, reportMetrics: reportMetrics, setReportMetrics: setReportMetrics, reportSchemas: reportSchemas, setReportSchemas: setReportSchemas, reportFormulas: reportFormulas, setReportFormulas: setReportFormulas, sourceCollectionId: reportSourceCollectionId })] }));
};
export default React.memo(ReportBuilder);
