var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { scheduleIsDraggingState } from 'src/state';
import ScheduleCellDropWrapper from './ScheduleCellDropWrapper';
var ScheduleCellWrapper = function (props) {
    var blockId = props.blockId, children = props.children;
    var _a = useState(false), isHovering = _a[0], setIsHovering = _a[1];
    var isDragging = useRecoilValue(scheduleIsDraggingState(blockId));
    if (!isDragging || !isHovering) {
        return (_jsx("div", { className: "h-full", onDragEnter: function () {
                setIsHovering(true);
            }, onDragLeave: function () { return setIsHovering(false); }, children: children }));
    }
    return _jsx(ScheduleCellDropWrapper, __assign({}, props));
};
export default React.memo(ScheduleCellWrapper);
