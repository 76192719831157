import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { usePayComponent } from 'src/hooks/component/usePay';
import { getTagTextColor } from 'src/utils/Collection';
import { useInView } from 'react-intersection-observer';
import UserAvatar from '../../User/UserAvatar';
import { isPayReportApprovedState } from '../atom';
import { shiftPayrollCountTagInfoMap } from '../Constant';
import { ShiftPayrollStatus, } from '../Interface';
import PayReportMetricRow from '../PayReportMetricRow';
import UserAttestationPopOver from '../UserAttestationPopOver';
import { checkAllZeroValueShiftStatusCount } from '../utils';
var IndividualPayReportCard = function (_a) {
    var payPeriodId = _a.payPeriodId, reportSourceType = _a.reportSourceType, payRollData = _a.payRollData, openRecordDetail = _a.openRecordDetail, openPayDetail = _a.openPayDetail, updateSinglePayCardShiftCount = _a.updateSinglePayCardShiftCount, payrollEnhancementEnabled = _a.payrollEnhancementEnabled;
    var _b = useInView({
        root: document.querySelector('.CenterPanelNonPageViewContent'),
    }), inView = _b.inView, ref = _b.ref, entry = _b.entry;
    var name = payRollData.name, recordId = payRollData.recordId, metrics = payRollData.metrics, avatarUrl = payRollData.avatarUrl;
    var useApprovedAllShifts = usePayApi().useApprovedAllShifts;
    var refetchPayPeriodShiftStatusCount = usePayComponent().refetchPayPeriodShiftStatusCount;
    var isApproved = useRecoilValue(isPayReportApprovedState(payPeriodId));
    var renderShiftPayrollStatusCount = useMemo(function () {
        return Object.entries(payRollData.shiftsPerStatus).map(function (_a) {
            var key = _a[0], value = _a[1];
            //Only show the status count if it is not zero
            if (value) {
                var status_1 = key;
                var name_1 = shiftPayrollCountTagInfoMap[status_1].displayName;
                var color = shiftPayrollCountTagInfoMap[status_1].color;
                var tagIcon = shiftPayrollCountTagInfoMap[status_1].icon;
                return (_jsxs("span", { className: "ml-2 bg-azure-50 rounded-xl pl-2 pr-3 py-1 text-xs font-semibold", style: {
                        color: getTagTextColor(color),
                        backgroundColor: color,
                    }, children: [tagIcon, "".concat(value, " ").concat(name_1)] }, key));
            }
        });
    }, [payRollData.shiftsPerStatus]);
    var onClickApproveAll = useCallback(function () {
        var request = {
            isApproved: isApproved,
            payPeriodId: payPeriodId,
            groupByRid: recordId,
            groupByType: reportSourceType,
        };
        useApprovedAllShifts.mutate(request, {
            onSuccess: function (resp) {
                if (resp) {
                    //Update shift status count state for single pay card
                    updateSinglePayCardShiftCount(resp);
                }
                //Refetch the pay period shift status count(rerender metric cards on the top)
                setTimeout(function () { return refetchPayPeriodShiftStatusCount(); }, 2000);
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApproved, payPeriodId, recordId, reportSourceType, updateSinglePayCardShiftCount]);
    return (_jsx("div", { className: "mt-2 flex items-center overflow-hidden rounded-xl border border-white/0 bg-white py-2 pl-5 shadow-block hover:cursor-pointer hover:border-azure-50 hover:shadow-hover", title: "Open ".concat(name, "'s pay details"), onClick: function () { return openPayDetail(recordId); }, ref: ref, style: {
            minHeight: 104,
            height: inView ? undefined : entry === null || entry === void 0 ? void 0 : entry.boundingClientRect.height,
        }, children: inView && (_jsxs(_Fragment, { children: [avatarUrl !== undefined && (_jsx(UserAvatar, { className: "mr-5 flex-none mb-4", name: name, avatar: avatarUrl, size: 48, isCirclular: true })), _jsxs("div", { className: "flex-auto flex-col", children: [_jsxs("div", { className: "flex items-center", children: [_jsxs("div", { className: "grow", children: [_jsx("span", { title: "Open ".concat(name, "'s record details"), className: "mr-4 font-semibold hover:cursor-pointer hover:underline", onClick: function (e) {
                                                e.stopPropagation();
                                                openRecordDetail(recordId);
                                            }, children: name }), payrollEnhancementEnabled && renderShiftPayrollStatusCount] }), _jsxs("div", { children: ['attestation' in payRollData && (_jsx(UserAttestationPopOver, { name: payRollData.name, attestation: payRollData.attestation })), payrollEnhancementEnabled &&
                                            !checkAllZeroValueShiftStatusCount(payRollData.shiftsPerStatus) &&
                                            payRollData.shiftsPerStatus[ShiftPayrollStatus.PENDING] > 0 && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to approve all shifts?", onConfirm: function (e) {
                                                if (e) {
                                                    e.stopPropagation();
                                                }
                                                onClickApproveAll();
                                            }, placement: "right", okText: "Yes", children: _jsx(Button, { size: "small", type: "text", className: "ml-auto mr-4 rounded text-sm font-semibold px-3", onClick: function (e) {
                                                    e.stopPropagation();
                                                }, style: { backgroundColor: 'black', color: 'white' }, children: "Approve All" }) })), _jsx(Button, { title: "Open ".concat(name, "'s pay details"), className: "ml-auto mr-4 w-20 rounded bg-gray-50 text-sm font-semibold", size: "small", type: "text", onClick: function () { return openPayDetail(recordId); }, children: "Details" })] })] }), _jsx(Divider, { style: { margin: '8px 0px' } }), _jsx(PayReportMetricRow, { metrics: metrics })] })] })) }));
};
export default React.memo(IndividualPayReportCard);
