export var CALENDAR_DEFAULT_ICON_INFO = {
    content: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14"><path stroke="#ffffff" stroke-linecap="round" d="M4.75342 0.75L4.75342 3.28918"></path><path stroke="#ffffff" stroke-linecap="round" d="M9.23975 0.75L9.23975 3.28918"></path><path stroke="#ffffff" d="M1.09183 10.525C1.25317 11.9644 2.42311 13.1072 3.86983 13.1769C4.86978 13.225 5.89127 13.2501 6.99991 13.2501C8.10855 13.2501 9.13003 13.225 10.13 13.1769C11.5767 13.1072 12.7466 11.9644 12.908 10.525C13.0166 9.55566 13.1058 8.56188 13.1058 7.54953C13.1058 6.53719 13.0166 5.54341 12.908 4.5741C12.7466 3.13471 11.5767 1.99187 10.13 1.92219C9.13003 1.87403 8.10855 1.849 6.99991 1.849C5.89127 1.849 4.86978 1.87403 3.86983 1.92219C2.42311 1.99187 1.25317 3.13471 1.09183 4.5741C0.983182 5.54341 0.894043 6.53719 0.894043 7.54953C0.894043 8.56188 0.983182 9.55566 1.09183 10.525Z"></path><path stroke="#ffffff" d="M1.18628 5.5H12.8738"></path></svg>',
};
export var LIST_WORKSPACE_ICON_INFO = {
    content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="14" width="14"><g><circle cx="1" cy="2.5" r="0.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></circle><line x1="4.5" y1="2.5" x2="13.5" y2="2.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></line><circle cx="1" cy="7" r="0.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></circle><line x1="4.5" y1="7" x2="13.5" y2="7" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></line><circle cx="1" cy="11.5" r="0.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></circle><line x1="4.5" y1="11.5" x2="13.5" y2="11.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></line></g></svg>',
};
export var DEFAULT_PAGE_GROUP_ICON_INFO = {
    backgroundColor: '#9D5A3E',
    content: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14"><path stroke="#ffffff" d="M12.3738 11.0838C12.4565 9.76567 12.5 8.39969 12.5 7C12.5 6.43312 12.4929 5.87177 12.4789 5.31688C12.4691 4.92707 12.3426 4.54824 12.1119 4.2339C11.2324 3.03578 10.5318 2.29092 9.3771 1.39977C9.05979 1.15487 8.67041 1.022 8.26969 1.01324C7.86786 1.00446 7.44825 1 7 1C5.64305 1 4.54845 1.04087 3.4157 1.11875C2.45206 1.18501 1.68673 1.95214 1.62622 2.91616C1.54349 4.23433 1.5 5.60031 1.5 7C1.5 8.39969 1.54349 9.76567 1.62622 11.0838C1.68673 12.0479 2.45206 12.815 3.4157 12.8812C4.54845 12.9591 5.64305 13 7 13C8.35695 13 9.45155 12.9591 10.5843 12.8812C11.5479 12.815 12.3133 12.0479 12.3738 11.0838Z"></path><path stroke="#ffffff" stroke-linecap="round" d="M5.09375 7.01562H8.90625"></path><path stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" d="M5.09375 9.92185H8.8944M5.09375 4H7.3944"></path></svg>',
};
