import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedThreadState } from 'src/state/atoms/chatState';
import { useEffect, useMemo } from 'react';
import { Badge } from 'antd';
import { useChatComponent } from 'src/hooks/component/useChat';
import { isCreatingThreadSelector, selectedBroadcastRecipientsSelector, } from 'src/state/selectors/withChatState';
import { isEqualWith } from 'lodash';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import { MessageSenderType } from '../interface';
import { threadIsBroadcastFromParticipants, threadParticipantsToBroadcastRecipients, broadcastRecipientsFromParticipants, getTimeSince, } from '../utils';
import ThreadTitle from './ThreadTitle';
import ThreadAvatar from './ThreadAvatar';
var ChatItem = function (_a) {
    var participants = _a.participants, threadId = _a.threadId, onChatSiderCollapsed = _a.onChatSiderCollapsed;
    var _b = useRecoilState(selectedThreadState), selectedThread = _b[0], setSelectedThread = _b[1];
    var setCreateNewThread = useSetRecoilState(isCreatingThreadSelector);
    var currentUserRId = useRecoilValue(currentUserRecordIdSelector);
    var _c = useChatComponent(), latestMessageInThreadIds = _c.latestMessageInThreadIds, unreadMessageCountFromThreadIds = _c.unreadMessageCountFromThreadIds, broadcastsWithRecipients = _c.broadcastsWithRecipients, viewThread = _c.viewThread;
    var chatUnreadMessageCount = useMemo(function () {
        var broadcasts = threadIsBroadcastFromParticipants(participants)
            ? broadcastsWithRecipients(threadParticipantsToBroadcastRecipients(participants))
            : undefined;
        if (broadcasts) {
            return unreadMessageCountFromThreadIds(broadcasts.map(function (thread) { return thread.id; }));
        }
        return unreadMessageCountFromThreadIds([threadId]);
    }, [broadcastsWithRecipients, participants, threadId, unreadMessageCountFromThreadIds]);
    var latestMessage = useMemo(function () {
        var broadcasts = threadIsBroadcastFromParticipants(participants)
            ? broadcastsWithRecipients(threadParticipantsToBroadcastRecipients(participants))
            : undefined;
        if (broadcasts) {
            return latestMessageInThreadIds(broadcasts.map(function (thread) { return thread.id; }));
        }
        return latestMessageInThreadIds([threadId]);
    }, [broadcastsWithRecipients, latestMessageInThreadIds, participants, threadId]);
    var selectedBroadcastRecipients = useRecoilValue(selectedBroadcastRecipientsSelector);
    var isCurrentActiveThread = useMemo(function () {
        if (!threadIsBroadcastFromParticipants(participants) &&
            selectedThread &&
            selectedThread === threadId) {
            return true;
        }
        return isEqualWith(broadcastRecipientsFromParticipants(participants), selectedBroadcastRecipients);
    }, [participants, selectedThread, threadId, selectedBroadcastRecipients]);
    var isTwoPersonDM = useMemo(function () {
        return (participants.length === 2 &&
            participants[0].participantType === MessageSenderType.USER &&
            participants[1].participantType === MessageSenderType.USER);
    }, [participants]);
    // view activated current thread when unread message count > 0
    useEffect(function () {
        if (isCurrentActiveThread) {
            var broadcasts = threadIsBroadcastFromParticipants(participants)
                ? broadcastsWithRecipients(threadParticipantsToBroadcastRecipients(participants))
                : undefined;
            // if thread is broadcast, check which broadcast has unread message
            if (broadcasts) {
                broadcasts.forEach(function (broadcast) {
                    if (unreadMessageCountFromThreadIds([broadcast.id])) {
                        // view broadcast thread with unread message
                        viewThread(broadcast.id);
                    }
                });
            }
            else if (chatUnreadMessageCount > 0) {
                // view thread with unread message
                viewThread(threadId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatUnreadMessageCount, isCurrentActiveThread, participants, selectedThread, threadId]);
    var messageContentPreview = useMemo(function () {
        if (latestMessage) {
            if (latestMessage.body.text.length > 0) {
                return latestMessage.body.text;
            }
            else if (latestMessage.body.attachments.length > 0) {
                return '[Attachment]';
            }
            else {
                return '';
            }
        }
    }, [latestMessage]);
    return (_jsxs("div", { className: clsx('ChatListItem', {
            active: isCurrentActiveThread,
        }), onClick: function () {
            setCreateNewThread(undefined);
            setSelectedThread(threadId);
            onChatSiderCollapsed();
        }, children: [_jsx(ThreadAvatar, { participants: participants }), _jsxs("div", { className: "ChatListItemContent", children: [_jsx(ThreadTitle, { participants: participants, isUnread: !!chatUnreadMessageCount }), latestMessage && (_jsxs("div", { className: "LastMessagePreview", style: chatUnreadMessageCount ? { color: 'black', fontWeight: 600 } : undefined, children: [latestMessage.senderDetail.id === currentUserRId
                                ? 'You: '
                                : isTwoPersonDM
                                    ? ''
                                    : "".concat(latestMessage.senderDetail.name, ": "), messageContentPreview] }))] }), _jsxs("div", { className: "flex h-full flex-col justify-between pt-[3px] text-right", children: [latestMessage && (_jsx("div", { className: "pl-[4px] text-xs font-semibold text-gray-300", style: chatUnreadMessageCount ? { color: 'black' } : undefined, children: getTimeSince(latestMessage.createdAt) })), chatUnreadMessageCount > 0 && (_jsx("div", { className: "pl-[4px]", children: _jsx(Badge, { count: chatUnreadMessageCount, color: "#2F4DDF" }) }))] })] }));
};
export default ChatItem;
