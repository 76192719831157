var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { sortBy } from 'lodash';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { useChatComponent } from 'src/hooks/component/useChat';
import { currentUserState } from 'src/state';
import { departmentsState, selectedThreadState } from 'src/state/atoms/chatState';
import { broadcastsHaveSameRecipientsAsSelectedThreadSelector, selectedBroadcastRecipientsSelector, selectedMessagesSelector, } from 'src/state/selectors/withChatState';
import { CreateThreadBackgroundIcon } from 'src/utils/icons/ChatIcons/CreateThreadBackgroundIcon';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import BroadcastBanner from '../../BroadcastBanner';
import { MessageSenderType, ThreadType, } from '../../interface';
import { threadParticipantsToBroadcastRecipients } from '../../utils';
import AddNewMessageSection from './AddNewMessageSection';
import BroadcastMessageItem from './BroadcastMessageItem';
var MESSAGE_LIMIT = 20;
var BroadcastScreen = function () {
    var _a;
    var selectedBroadcastRecipients = useRecoilValue(selectedBroadcastRecipientsSelector);
    var threadSummaries = useRecoilValue(broadcastsHaveSameRecipientsAsSelectedThreadSelector);
    var currentUserInfo = useRecoilValue(currentUserState);
    var chatMessages = useRecoilValue(selectedMessagesSelector);
    var selectedThreadId = useRecoilValue(selectedThreadState);
    var departmentCanSendAs = useRecoilValue(departmentsState);
    var lastMessage = useMemo(function () { var _a; return (_a = chatMessages[chatMessages.length - 1]) === null || _a === void 0 ? void 0 : _a.id; }, [chatMessages]);
    var messageListRef = useRef(null);
    var _b = useState(0), scrollPosition = _b[0], setScrollPosition = _b[1];
    var departmentOptions = useMemo(function () {
        return departmentCanSendAs.map(function (department) {
            return {
                id: department.id,
                name: department.title,
                type: MessageSenderType.DEPARTMENT,
            };
        });
    }, [departmentCanSendAs]);
    var _c = useChatComponent(), sendMessage = _c.sendMessage, addMessage = _c.addMessage, getThreadByParticipant = _c.getThreadByParticipant, createThread = _c.createThread;
    var useGetMessagesFromThreadList = useChatApi().useGetMessagesFromThreadList;
    var _d = useState({
        threadIds: (_a = threadSummaries === null || threadSummaries === void 0 ? void 0 : threadSummaries.map(function (threadSummary) { return threadSummary.id; })) !== null && _a !== void 0 ? _a : [],
        limit: MESSAGE_LIMIT,
    }), getMessagesFromThreadRequest = _d[0], setGetMessagesFromThreadRequest = _d[1];
    var _e = useGetMessagesFromThreadList(getMessagesFromThreadRequest), newMessages = _e.data, isFetching = _e.isFetching;
    useEffect(function () {
        var _a;
        setGetMessagesFromThreadRequest({
            threadIds: (_a = threadSummaries === null || threadSummaries === void 0 ? void 0 : threadSummaries.map(function (threadSummary) { return threadSummary.id; })) !== null && _a !== void 0 ? _a : [],
            limit: MESSAGE_LIMIT,
        });
    }, [threadSummaries]);
    useEffect(function () {
        if (newMessages === null || newMessages === void 0 ? void 0 : newMessages.length) {
            newMessages.forEach(function (message) {
                addMessage(message);
            });
            if (getMessagesFromThreadRequest.beforeMessageId) {
                //! Keep scroll position on pagination
                setTimeout(function () {
                    var _a;
                    return (_a = messageListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                        top: messageListRef.current.scrollHeight - scrollPosition,
                        behavior: 'auto',
                    });
                }, 0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newMessages]);
    //! Scroll to bottom on new message or thread change
    useEffect(function () {
        if (lastMessage) {
            setTimeout(function () {
                var _a;
                return (_a = messageListRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    top: messageListRef.current.scrollHeight,
                });
            }, 0);
        }
    }, [lastMessage]);
    var onSendMessage = function (message, department, attachments) {
        if (!department || !threadSummaries)
            return;
        var broadcastRecipients = threadParticipantsToBroadcastRecipients(threadSummaries[0].participants);
        var findThread = getThreadByParticipant({
            participants: broadcastRecipients,
            sender: department,
            type: ThreadType.BROADCAST,
        });
        if (findThread) {
            sendMessage(message, findThread.id, department, attachments);
        }
        else if (selectedThreadId) {
            createThread(broadcastRecipients, message, department, function () { }, attachments);
        }
    };
    return (_jsxs("div", { className: "MessageScreen", children: [_jsx(BroadcastBanner, { broadcastRecipients: selectedBroadcastRecipients }), currentUserInfo && chatMessages.length > 0 ? (_jsx("div", { className: "ChatMessageList", ref: messageListRef, onScroll: function (e) {
                    var _a;
                    var _b = e.target, scrollTop = _b.scrollTop, scrollHeight = _b.scrollHeight;
                    if (scrollTop <= 0 &&
                        !getMessagesFromThreadRequest.beforeMessageId &&
                        chatMessages.length > 0) {
                        setScrollPosition(scrollHeight);
                        setGetMessagesFromThreadRequest({
                            threadIds: (_a = threadSummaries === null || threadSummaries === void 0 ? void 0 : threadSummaries.map(function (threadSummary) { return threadSummary.id; })) !== null && _a !== void 0 ? _a : [],
                            beforeMessageId: sortBy(chatMessages, function (item) {
                                return Date.parse(item.createdAt);
                            })[0].id,
                            limit: MESSAGE_LIMIT,
                        });
                    }
                }, children: chatMessages.map(function (message) {
                    return _jsx(BroadcastMessageItem, { message: message }, message.id);
                }) })) : !newMessages && isFetching ? (_jsx(LoadingLogo, { className: "grid h-full place-items-center" })) : (_jsxs("div", { className: "ScrollChatNoMain", children: [_jsx(CreateThreadBackgroundIcon, {}), _jsx("span", { className: "NoMessage", children: "Send a broadcast" })] })), _jsx("div", { className: "ChatFooter", children: _jsx(AddNewMessageSection, { currentMessage: '', onSendMessage: onSendMessage, senderOptions: __spreadArray([], departmentOptions.filter(function (item) { return item !== undefined; }), true), isBroadcast: true }) })] }));
};
export default BroadcastScreen;
