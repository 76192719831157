var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SettingsIconSvg = function () { return (_jsx("svg", { width: "100%", height: "100%", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.1416 2.26265C8.1416 2.07856 8.29084 1.92932 8.47494 1.92932H9.1416C9.3257 1.92932 9.47494 2.07856 9.47494 2.26265V3.30392C10.3753 3.4162 11.2061 3.75293 11.9093 4.25635L12.6673 3.49833C12.7975 3.36815 13.0086 3.36815 13.1387 3.49833L13.6101 3.96973C13.7403 4.09991 13.7403 4.31096 13.6101 4.44114L12.8892 5.16207C13.5358 5.92974 13.9698 6.88241 14.1003 7.92932H15.1416C15.3257 7.92932 15.4749 8.07856 15.4749 8.26265V8.92932C15.4749 9.11342 15.3257 9.26265 15.1416 9.26265H14.1003C13.9789 10.2365 13.5949 11.1288 13.0215 11.8665L13.61 12.455C13.7402 12.5852 13.7402 12.7962 13.61 12.9264L13.1386 13.3978C13.0084 13.528 12.7974 13.528 12.6672 13.3978L12.0786 12.8093C11.341 13.3827 10.4487 13.7666 9.47494 13.8881V14.9293C9.47494 15.1134 9.3257 15.2627 9.1416 15.2627H8.47494C8.29084 15.2627 8.1416 15.1134 8.1416 14.9293V13.8881C7.09469 13.7575 6.14202 13.3235 5.37435 12.6769L4.65345 13.3978C4.52327 13.528 4.31222 13.528 4.18204 13.3978L3.71064 12.9264C3.58046 12.7962 3.58046 12.5852 3.71064 12.455L4.46863 11.697C3.96521 10.9938 3.62848 10.1631 3.5162 9.26265H2.47493C2.29084 9.26265 2.1416 9.11342 2.1416 8.92932V8.26265C2.1416 8.07856 2.29084 7.92932 2.47493 7.92932H3.5162C3.63763 6.95554 4.02157 6.06329 4.59499 5.32562L3.71051 4.44114C3.58033 4.31096 3.58033 4.09991 3.71051 3.96973L4.18191 3.49833C4.31209 3.36815 4.52314 3.36815 4.65332 3.49833L5.53778 4.38279C6.27548 3.80933 7.16777 3.42535 8.1416 3.30392V2.26265ZM8.80827 12.596C11.0174 12.596 12.8083 10.8051 12.8083 8.59599C12.8083 6.38685 11.0174 4.59599 8.80827 4.59599C6.59913 4.59599 4.80827 6.38685 4.80827 8.59599C4.80827 10.8051 6.59913 12.596 8.80827 12.596Z", fill: "black" }) })); };
export var SettingsIcon = function (props) { return (_jsx(Icon, __assign({ component: SettingsIconSvg }, props))); };
