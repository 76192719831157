import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { pageItemSelector } from 'src/state';
import { usePageApi } from 'src/hooks/api';
import { ButtonType } from '../../../shared';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import './ButtonBlock.scss';
import ButtonBlockModal from '../NewBlockDropdown/ButtonBlockModal';
var ButtonBlock = function (props) {
    var blockId = props.blockId, moveBlockUpDown = props.moveBlockUpDown;
    var _a = usePageApi(), useListBlockQuery = _a.useListBlockQuery, useUpdatePageBlock = _a.useUpdatePageBlock;
    var _b = useListBlockQuery({
        blockId: blockId,
        contentType: 'button',
    }), data = _b.data, isFetched = _b.isFetched;
    var _c = useState(), buttonBlockData = _c[0], setButtonBlockData = _c[1];
    useEffect(function () {
        if (data && data[0] && data[0].button) {
            setButtonBlockData(data[0].button);
        }
    }, [data]);
    var _d = useState(false), showButtonModal = _d[0], setShowButtonModal = _d[1];
    var onEditBlock = useCallback(function () {
        if (buttonBlockData) {
            setShowButtonModal(true);
        }
    }, [buttonBlockData]);
    var updateButtonBlock = useCallback(function (values) {
        useUpdatePageBlock.mutate({
            blockId: blockId,
            content: {
                buttonContent: values,
            },
        });
        setButtonBlockData(values);
        setShowButtonModal(false);
    }, [blockId, useUpdatePageBlock]);
    var menuChildren = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { icon: _jsx(EditOutlined, { className: "EditIcon" }), onClick: onEditBlock, children: "Edit" }, "edit"), _jsx(Menu.Divider, {})] }));
    }, [onEditBlock]);
    return !isFetched ? (_jsx(_Fragment, { children: " " })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "buttonBlockWrapper", children: [_jsx(HoverOptionButtons, { data: { blockId: blockId }, moveBlockUpDown: moveBlockUpDown, menuChildren: menuChildren, isNonTitleBlock: true }), _jsxs("div", { className: "buttonBlock", children: [(buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.PAGE && (_jsx(PageTypeButton, { data: buttonBlockData })), (buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.FORM && (_jsx(FormTypeButton, { data: buttonBlockData })), (buttonBlockData === null || buttonBlockData === void 0 ? void 0 : buttonBlockData.type) === ButtonType.WEB_LINK && (_jsx(WebLinkTypeButton, { data: buttonBlockData }))] })] }), _jsx(ButtonBlockModal, { visible: showButtonModal, content: buttonBlockData, onOk: updateButtonBlock, onCancel: function () { return setShowButtonModal(false); }, isEdit: true })] }));
};
var PageTypeButton = function (_a) {
    var data = _a.data;
    var page = useRecoilValue(pageItemSelector(data.link));
    var link = useMemo(function () {
        return "/home/page/".concat(page === null || page === void 0 ? void 0 : page.parentId, "/").concat(page === null || page === void 0 ? void 0 : page.id);
    }, [page === null || page === void 0 ? void 0 : page.id, page === null || page === void 0 ? void 0 : page.parentId]);
    return (_jsx("a", { href: link, target: "_blank", rel: "noopener noreferrer", children: data.label }));
};
var FormTypeButton = function (_a) {
    var data = _a.data;
    var link = "/internal-form/".concat(data.link);
    return (_jsx("a", { href: link, target: "_blank", rel: "noopener noreferrer", children: data.label }));
};
var WebLinkTypeButton = function (_a) {
    var data = _a.data;
    var link = data.link.includes('://') ? data.link : "//".concat(data.link);
    return (_jsx("a", { href: link, target: "_blank", rel: "noopener noreferrer", children: data.label }));
};
export default React.memo(ButtonBlock);
