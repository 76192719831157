import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { isNil } from 'lodash';
import { useTimeZone } from 'src/hooks/component';
import { getRecordMetricColor } from 'src/services/CollectionDataService/CollectionCellService';
import RecordDetailMetricWrapper from './RecordDetailMetricWrapper';
var RecordDetailModalMetrics = function (_a) {
    var recordDetailMetrics = _a.recordDetailMetrics, timeZone = _a.timeZone, extraMetrics = _a.extraMetrics;
    var timeFormatFn = useTimeZone(timeZone).timeFormatFn;
    var parseRecordMetricValue = function (recordMetric) {
        var datetimeValue = recordMetric.datetimeValue, intValue = recordMetric.intValue, textValue = recordMetric.textValue, bigDecimalValue = recordMetric.bigDecimalValue;
        return !isNil(intValue)
            ? intValue
            : !isNil(bigDecimalValue)
                ? bigDecimalValue
                : datetimeValue
                    ? timeFormatFn(datetimeValue, 'MMM D, YYYY h:mm A')
                    : textValue
                        ? textValue
                        : 'N/A';
    };
    return (_jsxs(_Fragment, { children: [extraMetrics && _jsx("div", { className: "pb-5", children: extraMetrics }), _jsx("div", { className: "flex w-max flex-col space-y-5 overflow-hidden ".concat(recordDetailMetrics.length ? ' pb-5' : ''), children: recordDetailMetrics.map(function (metric) {
                    return (_jsx(RecordDetailMetricWrapper, { title: metric.displayName, content: parseRecordMetricValue(metric), backgroundColor: getRecordMetricColor(metric.textValue) }, metric.schemaId));
                }) })] }));
};
export default React.memo(RecordDetailModalMetrics);
