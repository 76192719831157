var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DuplicateOutlinedSvg = function () { return (_jsx("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.33325 8.37044V4.70377C8.33325 4.51968 8.48249 4.37044 8.66659 4.37044H17.3333C17.5173 4.37044 17.6666 4.51968 17.6666 4.70377V13.3704C17.6666 13.5545 17.5173 13.7038 17.3333 13.7038H13.6666V17.3704C13.6666 17.5545 13.5173 17.7038 13.3333 17.7038H4.66659C4.48249 17.7038 4.33325 17.5545 4.33325 17.3704V8.70377C4.33325 8.51968 4.48249 8.37044 4.66659 8.37044H8.33325ZM9.66659 8.37044V5.70377H16.3333V12.3704H13.6666V8.70377C13.6666 8.51968 13.5173 8.37044 13.3333 8.37044H9.66659ZM8.33325 9.70377V13.3704C8.33325 13.5545 8.48249 13.7038 8.66659 13.7038H10.6666C10.8507 13.7038 10.9999 13.5545 10.9999 13.3704V12.7038C10.9999 12.5197 10.8507 12.3704 10.6666 12.3704H9.66659V9.70377H12.3333V16.3704H5.66659V9.70377H8.33325Z", fill: "currentColor" }) })); };
export var DuplicateOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: DuplicateOutlinedSvg }, props))); };
