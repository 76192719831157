import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from 'antd';
import React from 'react';
import { BlockErrorIcon } from 'src/utils/icons/BlockErrorIcon';
import './BlockCollectionTableErrorBoundary.scss';
var CollectionTableErrorBoundary = function (_a) {
    var title = _a.title;
    return (_jsx("div", { className: "BlockTableWrapper", children: _jsx("div", { role: "alert", className: "BlockErrorDisplayContainer", children: _jsxs(Space, { size: 18, children: [_jsx(BlockErrorIcon, {}), _jsxs("div", { children: [_jsx("div", { className: "ErrorTitle", children: "Error" }), _jsxs("div", { className: "ErrorDescription", children: [title, " failed to load. Please refresh the page to try again."] }), _jsx("div", { className: "ErrorDescription", children: "If the problem persists please contact tech support." })] })] }) }) }));
};
export default React.memo(CollectionTableErrorBoundary);
