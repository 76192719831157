var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { reportMetricColumnsSelector, reportPlottedMetricsState } from 'src/state';
import { HiddenEyeOutlinedIcon } from 'src/utils/icons/HiddenEyeOutlined';
import { ViewEyeOutlinedIcon } from 'src/utils/icons/ViewEyeOutlined';
import { toggleList } from 'src/utils/Tools/LodashTool';
var ReportMetricsDropdown = function (_a) {
    var reportId = _a.reportId;
    var _b = useRecoilState(reportPlottedMetricsState(reportId)), plottedMetrics = _b[0], setPlottedMetrics = _b[1];
    var _c = useState(plottedMetrics), newPlottedMetrics = _c[0], setNewPlottedMetrics = _c[1];
    var metricColumns = useRecoilValue(reportMetricColumnsSelector(reportId));
    var metricsList = useMemo(function () {
        return metricColumns.map(function (metric) {
            var _a, _b;
            return __assign(__assign({}, metric), { isPlotted: newPlottedMetrics.includes((_b = (_a = metric.metricType) !== null && _a !== void 0 ? _a : metric.schemaId) !== null && _b !== void 0 ? _b : metric.formula) });
        });
    }, [metricColumns, newPlottedMetrics]);
    useEffect(function () {
        setNewPlottedMetrics(plottedMetrics);
    }, [plottedMetrics]);
    var onDropdownVisibleChange = useCallback(function (visible) {
        if (!visible && !isEqual(newPlottedMetrics, plottedMetrics)) {
            setPlottedMetrics(newPlottedMetrics);
        }
    }, [newPlottedMetrics, plottedMetrics, setPlottedMetrics]);
    var ReportMetricsDropdownRender = useCallback(function () {
        return (_jsxs("div", { className: "flex w-[300px] flex-col rounded bg-white px-4 py-2 shadow-lg", children: [_jsx("span", { className: "text-lg font-medium text-black", children: "Report Metrics" }), _jsx("span", { className: "text-xs text-gray-300", children: "Choose which metric to plot." }), _jsx(Divider, {}), metricsList.map(function (metric) {
                    var _a;
                    return (_jsxs("div", { className: "flex w-full cursor-pointer items-center", onClick: function () {
                            setNewPlottedMetrics(function (prevNewPlottedMetrics) {
                                var _a, _b;
                                return toggleList(prevNewPlottedMetrics, (_b = (_a = metric.metricType) !== null && _a !== void 0 ? _a : metric.schemaId) !== null && _b !== void 0 ? _b : metric.formula);
                            });
                        }, children: [_jsx("span", { className: "text-base text-black", children: metric.label }), _jsx("span", { className: "ml-auto", children: metric.isPlotted ? (_jsx(ViewEyeOutlinedIcon, {})) : (_jsx(HiddenEyeOutlinedIcon, {})) })] }, (_a = metric.metricType) !== null && _a !== void 0 ? _a : metric.formula));
                }, [])] }));
    }, [metricsList]);
    return (_jsx(ZiraDropdown, { className: "ml-2 w-[130px] max-w-1/2 shadow-block", dropdownRender: ReportMetricsDropdownRender, onLocalVisibleChange: onDropdownVisibleChange, trigger: ['click'], placement: "bottomRight", children: _jsxs("div", { className: "flex h-[30px] cursor-pointer items-center justify-center rounded-md border border-[#d9d9d9] bg-white font-semibold text-black", children: ["Report Metrics", _jsx(DownOutlined, { className: "pl-[8px] text-[12px] text-gray-200" })] }) }));
};
export default React.memo(ReportMetricsDropdown);
