import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import DashboardAlertTableWrapper from '../AlertTable/DashboardAlertTableWrapper';
import AlertWidgetSiderSection from '../AlertWidgetSiderSection';
import SendUserInviteButton from './SendUserInviteButton';
var UserAlertWidget = function () {
    var useGetUserAlertStatusData = useDashboardApi().useGetUserAlertStatusData;
    var _a = useState(), selectedUserStatus = _a[0], setSelectedUserStatus = _a[1];
    var _b = useGetUserAlertStatusData(), userAlertStatusInfo = _b.data, isLoading = _b.isLoading;
    // Init user status
    useEffect(function () {
        if (userAlertStatusInfo && userAlertStatusInfo.data.length !== 0 && !selectedUserStatus) {
            setSelectedUserStatus(userAlertStatusInfo.data[0]);
        }
    }, [selectedUserStatus, userAlertStatusInfo]);
    return (_jsxs("div", { className: "AlertTableWidgetContainer shadow-block", children: [_jsx(AlertWidgetSiderSection, { isLoading: isLoading, allStatus: userAlertStatusInfo === null || userAlertStatusInfo === void 0 ? void 0 : userAlertStatusInfo.data, selectedStatus: selectedUserStatus, setSelectedStatus: setSelectedUserStatus, isUserType: true }), _jsxs("div", { className: "TableContentContainer shadow-block", children: [_jsxs("div", { className: "item-center mb-5 flex h-[34px] justify-between", children: [_jsx("div", { className: "text-lg font-medium", children: selectedUserStatus === null || selectedUserStatus === void 0 ? void 0 : selectedUserStatus.name }), (selectedUserStatus === null || selectedUserStatus === void 0 ? void 0 : selectedUserStatus.type) === 'NOT_SIGNED_UP' && _jsx(SendUserInviteButton, {})] }), _jsx(DashboardAlertTableWrapper, { userAlertProps: { selectedUserStatus: selectedUserStatus } })] })] }));
};
export default React.memo(UserAlertWidget);
