import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { TASKS_OVERVIEW_HEADER_HEIGHT, TASKS_OVERVIEW_TABS_HEIGHT } from '../constant';
import { taskEditorActiveTabState } from '../atom';
import TasksSettingTabs from './TasksSettingTabs';
import TasksSettingLeftNav from './LeftNav/TasksSettingLeftNav';
import TasksSettingContent from './TasksSettingContent';
import TasksSettingRightSidebar from './TasksSettingRightSidebar';
import TasksSettingAddButton from './TasksSettingAddButton';
var TasksSettingView = function () {
    var activeTab = useRecoilValue(taskEditorActiveTabState);
    return (_jsxs("div", { className: "TasksOverview flex flex-col items-center", children: [_jsxs("div", { className: "w-full", style: { height: TASKS_OVERVIEW_HEADER_HEIGHT }, children: [_jsx("div", { className: "TasksOverviewModalHeader font-semibold border-b-2 border-gray-60 w-full grid place-items-center", style: { height: TASKS_OVERVIEW_HEADER_HEIGHT - TASKS_OVERVIEW_TABS_HEIGHT }, children: "Task Editor" }), _jsx(TasksSettingTabs, {})] }), _jsxs("div", { className: "flex w-full", style: {
                    height: "calc(100vh - ".concat(TASKS_OVERVIEW_HEADER_HEIGHT, "px"),
                }, children: [_jsxs("div", { className: "relative w-1/4 min-w-[300px]", children: [_jsx(TasksSettingLeftNav, {}), _jsx(TasksSettingAddButton, { activeTab: activeTab })] }), _jsx("div", { className: "w-3/4", children: _jsx(TasksSettingContent, {}) }), _jsx(TasksSettingRightSidebar, {})] })] }));
};
export default React.memo(TasksSettingView);
