var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './LogoDropdown.scss';
import { useNavigate } from 'react-router-dom';
import { useAppSession } from 'src/hooks/component';
import { renderAccountUserInfo, setPrevPageRoute } from 'src/utils/Account';
import { SettingsIcon } from 'src/utils/icons/SettingsIcon';
import { FormIcon } from 'src/utils/icons/FormIcon';
import { AutomateIcon } from 'src/utils/icons/Automate';
import { LogoutIcon } from 'src/utils/icons/LogoutIcon';
import { TasksSettingIcon } from 'src/utils/icons/TaskGroupSettingIcon';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentGeneralPermissions, currentUserState, firstAvailableSettingSectionPathSelector, ifCurrentUserHasTasksPermission, showSettingsSiderSelector, } from 'src/state';
import { UserProfileIcon } from 'src/utils/icons/UserProfileIcon';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { useQueryClient } from 'react-query';
import ZiraDeletePopconfirm from '../../ui/ZiraDeletePopconfirm';
import { taskSettingModalVisibleState } from '../Settings/Tasks/atom';
import { RECIPES_FEATURE_GATING_KEY } from '../FeatureGatingKeyConstant';
var LogoDropdown = function (_a) {
    var onOpenFormModal = _a.onOpenFormModal, onOpenAutomationModal = _a.onOpenAutomationModal, onOpenRecordDetail = _a.onOpenRecordDetail, leftNavCollapsed = _a.leftNavCollapsed;
    var userInfo = useRecoilValue(currentUserState);
    var _b = useState(false), isDropdownVisible = _b[0], setIsDropdownVisible = _b[1];
    var logout = useAuth0().logout;
    var clearAppCache = useAppSession().clearAppCache;
    var queryClient = useQueryClient();
    var navigate = useNavigate();
    var onLogOut = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clearAppCache();
                    queryClient.clear();
                    return [4 /*yield*/, logout({ logoutParams: { returnTo: "".concat(window.location.origin) } })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [clearAppCache, logout, queryClient]);
    var showSettingsSider = useRecoilValue(showSettingsSiderSelector);
    var firstAvailableSettingSectionPath = useRecoilValue(firstAvailableSettingSectionPathSelector);
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var setTaskSettingModalVisible = useSetRecoilState(taskSettingModalVisibleState);
    var hasTasksPermission = useRecoilValue(ifCurrentUserHasTasksPermission);
    var isRecipesFeatureEnabled = useRecoilValue(featureEnableStatusState(RECIPES_FEATURE_GATING_KEY));
    var className = leftNavCollapsed || (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB) === false
        ? 'StaticUserLogoDropdownItem'
        : 'UserLogoDropdownItem';
    var dropdownMenu = useMemo(function () {
        var items = [];
        if (showSettingsSider) {
            items.push({
                key: 'settings',
                icon: _jsx(SettingsIcon, { className: "dropdownMenuIcon w-[16px]" }),
                label: 'Settings',
                onClick: function () {
                    setPrevPageRoute(location.pathname);
                    navigate({ pathname: "/settings/".concat(firstAvailableSettingSectionPath) });
                },
            });
        }
        if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.AUTOMATION) {
            items.push({
                key: 'automations',
                icon: _jsx(AutomateIcon, { className: "dropdownMenuIcon" }),
                label: 'Automations',
                onClick: function () {
                    setIsDropdownVisible(false);
                    onOpenAutomationModal();
                },
            });
        }
        if (hasTasksPermission) {
            items.push({
                key: 'tasks',
                icon: _jsx(TasksSettingIcon, { className: "dropdownMenuIcon" }),
                label: 'Tasks',
                onClick: function () {
                    setIsDropdownVisible(false);
                    setTaskSettingModalVisible(true);
                },
            });
        }
        if (isRecipesFeatureEnabled) {
            items.push({
                key: 'recipes',
                icon: _jsx("div", { className: "ml-[-2px] pr-[9px]", children: "\uD83E\uDDD1\u200D\uD83C\uDF73" }),
                label: 'Recipes',
                onClick: function () {
                    setIsDropdownVisible(false);
                    navigate({
                        pathname: location.pathname,
                        search: '?recipe=d5e0dac7-b3a5-4775-a9fc-12288cf1efa7',
                    });
                },
            });
        }
        if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.FORMS) {
            items.push({
                key: 'forms',
                icon: _jsx(FormIcon, { className: "dropdownMenuIcon" }),
                label: 'Forms',
                onClick: function () {
                    setIsDropdownVisible(false);
                    onOpenFormModal();
                },
            });
        }
        if (userInfo && onOpenRecordDetail && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB)) {
            items.push({
                key: 'myProfile',
                icon: _jsx(UserProfileIcon, { className: "-ml-0.5 mr-[7px]" }),
                label: 'My Profile',
                onClick: function () {
                    setIsDropdownVisible(false);
                    onOpenRecordDetail(userInfo.recordId);
                },
            });
        }
        // Only add divider when at least one previous items exist
        if (items.length > 0) {
            items.push({
                type: 'divider',
            });
        }
        items.push({
            key: 'logout',
            icon: _jsx(LogoutIcon, { className: "dropdownMenuIcon" }),
            label: (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to log out?", onConfirm: onLogOut, placement: "right", okText: "Yes", children: _jsx("div", { className: "text-red-1000", children: "Log Out" }) })),
        });
        return { items: items };
    }, [
        firstAvailableSettingSectionPath,
        generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.AUTOMATION,
        generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.FORMS,
        generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.READ_WEB,
        hasTasksPermission,
        isRecipesFeatureEnabled,
        navigate,
        onLogOut,
        onOpenAutomationModal,
        onOpenFormModal,
        onOpenRecordDetail,
        setTaskSettingModalVisible,
        showSettingsSider,
        userInfo,
    ]);
    return (_jsx("div", { className: className, children: _jsx(Dropdown, { menu: dropdownMenu, trigger: ['click'], placement: "top", open: isDropdownVisible, onOpenChange: function (visible) { return setIsDropdownVisible(visible); }, overlayClassName: "LogoDropdownMenu", children: _jsxs("div", { className: "UserDropdownButton flex w-full items-center justify-between font-medium text-gray-500", children: [renderAccountUserInfo(userInfo === null || userInfo === void 0 ? void 0 : userInfo.avatarUrl, userInfo === null || userInfo === void 0 ? void 0 : userInfo.fullName), isDropdownVisible ? (_jsx(CaretDownOutlined, { className: "UpDownIcon" })) : (_jsx(CaretUpOutlined, { className: "UpDownIcon" }))] }) }) }));
};
export default React.memo(LogoDropdown);
