var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import ReverseCollectionView from 'src/components/OptimizedTableView/ReverseCollection/ReverseCollectionView';
import { Radio } from 'antd';
import { useRecoilState } from 'recoil';
import { tasksReverseCollectionViewState } from 'src/state';
import ReverseTasksView from './ReverseTasksView';
import TaskAssignmentDropdown from './TaskAssignmentDropdown';
var TasksReverseCollection = function (_a) {
    var _b, _c;
    var referenceCollectionId = _a.referenceCollectionId, referenceRecordId = _a.referenceRecordId, reverseCollection = _a.reverseCollection;
    var _d = useRecoilState(tasksReverseCollectionViewState), currentView = _d[0], setCurrentView = _d[1];
    return (_jsxs("div", { className: "ModalBodyCollections", id: (_b = reverseCollection.schemaId) !== null && _b !== void 0 ? _b : reverseCollection.standardRelation, children: [_jsxs(Radio.Group, { className: "mb-[20px]", buttonStyle: "solid", size: "large", value: currentView, onChange: function (e) { return setCurrentView(e.target.value); }, children: [_jsx(Radio.Button, { className: "!rounded-l-lg", value: "table", children: "Table View" }), _jsx(Radio.Button, { className: "!rounded-r-lg", value: "group", children: "Group View" })] }), currentView === 'table' ? (_jsxs(_Fragment, { children: [_jsx(TaskAssignmentDropdown, { taskCollectionId: reverseCollection.collectionId, referenceRecordId: referenceRecordId, referenceCollectionId: referenceCollectionId }), _jsx(ReverseCollectionView, __assign({}, reverseCollection, { referenceCollectionId: referenceCollectionId, referenceRecordId: referenceRecordId }))] })) : (_jsx(ReverseTasksView, { referenceRecordId: referenceRecordId, referenceCollectionId: referenceCollectionId }))] }, (_c = reverseCollection.schemaId) !== null && _c !== void 0 ? _c : reverseCollection.standardRelation));
};
export default React.memo(TasksReverseCollection);
