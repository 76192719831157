var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SparkleOutlined = function () { return (_jsxs("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M5.65802 2.99L4.39502 2.569L3.97402 1.306C3.83702 0.898 3.16202 0.898 3.02502 1.306L2.60402 2.569L1.34102 2.99C1.13702 3.058 0.999023 3.249 0.999023 3.464C0.999023 3.679 1.13702 3.87 1.34102 3.938L2.60402 4.359L3.02502 5.622C3.09302 5.826 3.28502 5.964 3.50002 5.964C3.71502 5.964 3.90602 5.826 3.97502 5.622L4.39602 4.359L5.65902 3.938C5.86302 3.87 6.00102 3.679 6.00102 3.464C6.00102 3.249 5.86202 3.058 5.65802 2.99Z", fill: "#A7A8AC" }), _jsx("path", { d: "M9.5 2.75L11.412 7.587L16.25 9.5L11.412 11.413L9.5 16.25L7.587 11.413L2.75 9.5L7.587 7.587L9.5 2.75Z", stroke: "#A7A8AC", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var SparkleOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: SparkleOutlined }, props))); };
