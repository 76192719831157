import { CellInputMode, NativePath, ValueDataType } from 'src/shared';
export var shiftTemplateNativeSchemaNativePathSet = new Set([
    NativePath.SHIFT_LOCATION_ID,
    NativePath.SHIFT_ROLE_IDS,
    NativePath.SHIFT_BONUS,
    NativePath.SHIFT_PUBLISHED,
]);
export var shiftTemplateCustomSchemaInputModeSet = new Set([
    CellInputMode.SINGLE,
    CellInputMode.MULTI,
    CellInputMode.PLAIN,
]);
export var shiftTemplateCustomSchemaTypeSet = new Set([
    ValueDataType.FILE,
    ValueDataType.SIGNATURE,
]);
