import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getTagTextColor } from 'src/utils/Collection';
import { getDemandRatioHoursStatColor } from '../../Utils';
var RoleHoursCell = function (_a) {
    var actual = _a.actual, budgeted = _a.budgeted;
    var backgroundColor = getDemandRatioHoursStatColor(actual, budgeted);
    return (_jsx("div", { className: "relative h-full border-b border-r border-[#f0f0f0]", children: _jsxs("div", { className: "mx-[4px] my-[6px] flex items-center justify-center rounded py-[2px]", style: {
                backgroundColor: backgroundColor,
                color: getTagTextColor(backgroundColor),
            }, children: [actual, "/", budgeted] }) }));
};
export default React.memo(RoleHoursCell);
