import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { getTagTextColor } from 'src/utils/Collection';
import { useAccountApi } from 'src/hooks/api';
var DefaultRateTagCell = function (_a) {
    var _b;
    var condition = _a.condition;
    var useGetUniversalSimpleListV2 = useAccountApi().useGetUniversalSimpleListV2;
    var schemaId = useMemo(function () { return condition.schemaId; }, [condition.schemaId]);
    var schemaOptions = useGetUniversalSimpleListV2({
        schemaId: schemaId !== null && schemaId !== void 0 ? schemaId : '',
        options: { enabled: !!schemaId },
    }).data;
    if (!schemaOptions)
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: (_b = condition.valueIds) === null || _b === void 0 ? void 0 : _b.map(function (id) {
            var universalListOption = schemaOptions === null || schemaOptions === void 0 ? void 0 : schemaOptions.options.find(function (item) { return item.id === id; });
            var tagColor = universalListOption === null || universalListOption === void 0 ? void 0 : universalListOption.color;
            if (!universalListOption)
                return null;
            return (_jsx("div", { className: "TagPill", style: {
                    backgroundColor: tagColor || '#fafafa',
                    color: getTagTextColor(tagColor || '#fafafa'),
                }, children: universalListOption.name }, "tag-".concat(id, "}")));
        }) }));
};
export default React.memo(DefaultRateTagCell);
