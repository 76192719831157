import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tag } from 'antd';
import React from 'react';
import { getTagTextColor } from 'src/utils/Collection';
import { OPTIONS_COLOR } from '../../../shared';
import UserTagWithIcon from '../../User/UserTagWithIcon';
import './NativeCollectionTitle.scss';
var NativeCollectionTitle = function (_a) {
    var data = _a.data, color = _a.color, children = _a.children;
    var title = data.title, avatar = data.avatar;
    if (title) {
        if (avatar !== undefined) {
            return (_jsx(UserTagWithIcon, { className: "TagTitle", avatarProps: { avatar: avatar, color: color }, name: title }));
        }
        return (_jsxs(Tag, { className: "TagTitle", color: color || OPTIONS_COLOR[0], style: {
                color: getTagTextColor(color),
            }, children: [title, children] }));
    }
    return _jsx(_Fragment, {});
};
export default React.memo(NativeCollectionTitle);
