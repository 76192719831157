var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PermissionLevelIconSvg = function () { return (_jsxs("svg", { width: "46", height: "46", viewBox: "0 0 46 46", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { opacity: "0.3", children: _jsxs("g", { clipPath: "url(#clip0_4895_53618)", children: [_jsx("path", { d: "M16.7852 20.6172H19.822V27.283H16.7852V20.6172Z", fill: "currentColor" }), _jsx("path", { d: "M20.834 18.4922H23.8708V27.2645H20.834V18.4922Z", fill: "currentColor" }), _jsx("path", { d: "M24.8828 16.0352H27.9196V27.266H24.8828V16.0352Z", fill: "currentColor" }), _jsx("path", { d: "M15.8637 28.2595V15.2812H13.7383V30.3849H28.8419V28.2595H15.8637Z", fill: "currentColor" })] }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_4895_53618", children: _jsx("rect", { width: "23", height: "23", fill: "white", transform: "translate(9 9.125)" }) }) })] })); };
export var PermissionLevelIcon = function (props) { return (_jsx(Icon, __assign({ component: PermissionLevelIconSvg }, props))); };
