import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Menu, Radio, Select } from 'antd';
import React, { useState } from 'react';
import { PermissionLevelIcon } from 'src/utils/icons';
var Option = Select.Option;
import './ObjectPermissionDropdown.scss';
var ObjectPermissionContent = function (_a) {
    var title = _a.title, options = _a.options, onChange = _a.onChange, chosenPermission = _a.chosenPermission;
    var _b = useState(chosenPermission), currentPermission = _b[0], setCurrentPermission = _b[1];
    return (_jsxs(Menu, { className: "overflow-none ObjectPermissionDropdown overflow-hidden pb-0", children: [_jsx("div", { className: "mt-2 text-center text-base font-medium text-gray-700", children: title }), _jsx(Divider, { className: "mb-0 mt-3" }), _jsx(Radio.Group, { defaultValue: currentPermission, value: parseInt(currentPermission), onChange: function (e) {
                    var newPermission = e.target.value;
                    setCurrentPermission(newPermission);
                    onChange(newPermission);
                }, children: options.map(function (option) { return (_jsx(Radio.Button, { className: "h-auto w-full border-none text-black", value: parseInt(option.id), disabled: !!option.dependencyDescription, children: _jsxs("div", { className: "flex items-center", children: [_jsx(PermissionLevelIcon, {}), _jsxs("div", { className: "ml-4 flex flex-col", children: [_jsx("span", { className: "text-sm font-medium", children: option.title }), option.dependencyDescription && (_jsxs("span", { className: "text-xs font-medium text-gray-500", children: ["(", option.dependencyDescription, ")"] }))] })] }) }, option.id)); }) })] }, title));
};
export default React.memo(ObjectPermissionContent);
