var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { PageType } from 'src/components/CenterPanel/Interface';
import { useNaviTreeComponent } from 'src/hooks/component';
import { isAddNewBlockDrawerVisible } from 'src/state/atoms/addNewBlockDrawerState';
import { CanvasPageTypeIcon } from 'src/utils/icons/CanvasPageTypeIcon';
import { ColumnIcon } from 'src/utils/icons/ColumnIcon';
import { CalendarIcon } from 'src/utils/icons/DashboardIcons/CalendarIcon';
import { TemplatePageTypeIcon } from 'src/utils/icons/TemplatePageIcon';
var CreateNewPageDropdownMenu = function (props) {
    var pageGroupId = props.pageGroupId, openPageTemplateModal = props.openPageTemplateModal, children = props.children, disabled = props.disabled;
    var navigate = useNavigate();
    var setIsAddBlockDrawerVisible = useSetRecoilState(isAddNewBlockDrawerVisible);
    var createPageInPageGroup = useNaviTreeComponent().createPageInPageGroup;
    var onCreatePage = useCallback(function (templateId, pageType) { return __awaiter(void 0, void 0, void 0, function () {
        var newPageId, tempSelectedKeys;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createPageInPageGroup(pageGroupId, templateId, pageType)];
                case 1:
                    newPageId = _a.sent();
                    if (newPageId) {
                        tempSelectedKeys = [];
                        tempSelectedKeys[0] = pageGroupId;
                        tempSelectedKeys[1] = newPageId;
                        navigate("/home/page/".concat(tempSelectedKeys[0], "/").concat(newPageId));
                    }
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageGroupId]);
    var items = useMemo(function () { return [
        {
            key: '1',
            type: 'group',
            label: _jsx("div", { className: "text-xs font-semibold text-gray-300", children: "ADD WORKSPACE" }),
            children: [
                {
                    type: 'divider',
                },
                {
                    key: 'list',
                    label: 'List',
                    icon: _jsx(ColumnIcon, { className: "ml-[1px] mr-[9px]" }),
                    onClick: function () {
                        onCreatePage(undefined, PageType.TABLE);
                        setIsAddBlockDrawerVisible(true);
                    },
                },
                {
                    type: 'divider',
                },
                {
                    key: 'calendar',
                    label: 'Calendar',
                    icon: (_jsx(CalendarIcon, { style: { width: '16px' }, className: "ml-[4px] mr-[12px]" })),
                    onClick: function () { return onCreatePage(undefined, PageType.SCHEDULE); },
                },
                {
                    type: 'divider',
                },
                {
                    key: 'canvas',
                    label: 'Blank Canvas',
                    icon: _jsx(CanvasPageTypeIcon, { className: "ml-[5px] mr-[13px]" }),
                    onClick: function () { return onCreatePage('BLANK_PAGE', undefined); },
                },
                {
                    type: 'divider',
                },
                {
                    key: 'templates',
                    label: 'Choose From Templates',
                    icon: _jsx(TemplatePageTypeIcon, { className: "ml-[4px] mr-[13px]" }),
                    onClick: openPageTemplateModal,
                },
            ],
        },
    ]; }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openPageTemplateModal, setIsAddBlockDrawerVisible]);
    return disabled ? (_jsx(Tooltip, { title: "Cannot have more than 50 pages per group.", color: "black", children: _jsx("span", { children: _jsx(Dropdown, { disabled: true, menu: { items: items }, trigger: ['click'], overlayClassName: "NewPageDropdownMenu", children: children }) }) })) : (_jsx(Dropdown, { menu: { items: items }, trigger: ['click'], overlayClassName: "NewPageDropdownMenu", children: children }));
};
export default React.memo(CreateNewPageDropdownMenu);
