import { jsx as _jsx } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { schedulingSnapshotOptionSettingState } from 'src/components/CenterPanel/Dashboard/Atoms';
import { OnboardingProgressListItemType, } from 'src/components/CenterPanel/Dashboard/Constant';
import { numberOfVisibleSchedulingSnapshotListSelector, dashboardUserCollectionIdSelector, dashboardShiftCollectionIdSelector, } from 'src/components/CenterPanel/Dashboard/Selectors';
import { getTagTextColor } from 'src/utils/Collection';
var useDashboard = function () {
    // scheduling snapshot widget related
    var schedulingSnapshotOptionVisibleOnChange = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (shiftStatusKey, visible) {
            var optionsSetting = snapshot
                .getLoadable(schedulingSnapshotOptionSettingState)
                .valueMaybe();
            if (!optionsSetting)
                return;
            var newOptionsSetting = cloneDeep(optionsSetting);
            newOptionsSetting[shiftStatusKey] = visible;
            set(schedulingSnapshotOptionSettingState, newOptionsSetting);
        };
    }, []);
    var getSnapshotColSpanNumber = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var numberOfVisibleList = snapshot
                .getLoadable(numberOfVisibleSchedulingSnapshotListSelector)
                .valueMaybe();
            if (!numberOfVisibleList)
                return 0;
            switch (numberOfVisibleList) {
                case 1: {
                    return 24;
                }
                case 2: {
                    return 12;
                }
                case 3: {
                    return 8;
                }
                case 4: {
                    return 6;
                }
            }
            return 5;
        };
    }, []);
    var renderShiftDataTags = useCallback(function (tags) {
        return (_jsx("div", { className: "ShiftTagsContainer", children: tags.map(function (_a, index) {
                var name = _a.name, color = _a.color;
                if (!name)
                    return null;
                return (_jsx("span", { className: "ShiftTagInfo", style: {
                        backgroundColor: color,
                        color: getTagTextColor(color),
                    }, children: name }, index));
            }) }));
    }, []);
    // Onboarding progress widget and modal related
    var getTargetCollectionId = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (type) {
            var _a, _b;
            if (type === OnboardingProgressListItemType.USER) {
                return ((_a = snapshot.getLoadable(dashboardUserCollectionIdSelector).valueMaybe()) !== null && _a !== void 0 ? _a : '');
            }
            else if (type === OnboardingProgressListItemType.SHIFT) {
                return ((_b = snapshot.getLoadable(dashboardShiftCollectionIdSelector).valueMaybe()) !== null && _b !== void 0 ? _b : '');
            }
            return '';
        };
    }, []);
    return {
        renderShiftDataTags: renderShiftDataTags,
        schedulingSnapshotOptionVisibleOnChange: schedulingSnapshotOptionVisibleOnChange,
        getSnapshotColSpanNumber: getSnapshotColSpanNumber,
        getTargetCollectionId: getTargetCollectionId,
    };
};
export { useDashboard as useDashboardComponent };
