import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import UserTagWithIcon from '../User/UserTagWithIcon';
import HoverRecordMenu from './Cell/HoverRecordMenu';
import './DummyUserCell.scss';
var DummyUserCell = function (props) {
    var tags = props.tags, setIsClicked = props.setIsClicked, canEdit = props.canEdit;
    var showLogicCell = useCallback(function () {
        if (canEdit) {
            setIsClicked(true);
        }
    }, [canEdit, setIsClicked]);
    return (_jsx("div", { className: "DummyUserCell", onClick: showLogicCell, children: tags.map(function (tag) {
            var _a;
            return (_jsx(HoverRecordMenu, { rid: tag.id, title: (_a = tag.text) !== null && _a !== void 0 ? _a : 'User', children: _jsx("span", { children: _jsx(UserTagWithIcon, { name: tag.text, size: 22, avatarProps: {
                            avatar: tag.userAvatar,
                        } }, tag.id) }) }, tag.id));
        }) }));
};
export default React.memo(DummyUserCell);
