import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import { collectionOptionsState } from 'src/state';
import { InfoCircleOutlined } from '@ant-design/icons';
var AddNewStandardObjConfirmationModal = function (_a) {
    var schemaName = _a.schemaName, collectionId = _a.collectionId, onClose = _a.onClose, onAddNewObj = _a.onAddNewObj;
    var collectionOptions = useRecoilValue(collectionOptionsState);
    var collectionName = useMemo(function () {
        var _a;
        var targetCollection = collectionOptions.find(function (item) { return item.id === collectionId; });
        return (_a = targetCollection === null || targetCollection === void 0 ? void 0 : targetCollection.name) !== null && _a !== void 0 ? _a : '';
    }, [collectionId, collectionOptions]);
    var onConfirm = useCallback(function () {
        onAddNewObj();
        onClose();
    }, [onAddNewObj, onClose]);
    var modalFooter = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: onClose, children: "Cancel" }), _jsx(Button, { className: "ZiraModalSaveBtn", onClick: onConfirm, children: "Confirm" })] }));
    }, [onClose, onConfirm]);
    return (_jsx("div", { onKeyDown: function (e) {
            if (e.key === 'Enter') {
                onConfirm();
            }
        }, children: _jsxs(Modal, { open: true, centered: true, footer: modalFooter, closable: false, className: "TimeOffApprovalModalForUnassignShift", width: 500, zIndex: 1101, onCancel: onClose, keyboard: true, children: [_jsxs("div", { className: "mt-5 font-medium", children: ["Are you sure you want to add a new option to ", schemaName, "?"] }), _jsxs("div", { className: "flex items-center rounded bg-blue-50 text-blue-400 py-1 px-2 mt-3", children: [_jsx(InfoCircleOutlined, { className: "text-blue-400 stroke-blue-400 stroke-[25px]" }), _jsxs("div", { className: "ml-2", children: ["This will also create a new record under ", collectionName, "."] })] })] }) }));
};
export default React.memo(AddNewStandardObjConfirmationModal);
