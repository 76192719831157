var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TimeSheetMetricSvg = function () { return (_jsxs("svg", { width: "31", height: "31", viewBox: "0 0 31 31", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "31", height: "31", rx: "15.5", fill: "#00A76F" }), _jsx("path", { d: "M16 7.1875C11.6512 7.1875 8.125 10.9096 8.125 15.5C8.125 20.0904 11.6512 23.8125 16 23.8125C20.3488 23.8125 23.875 20.0904 23.875 15.5C23.875 10.9096 20.3488 7.1875 16 7.1875ZM16 22.4023C12.3895 22.4023 9.46094 19.3111 9.46094 15.5C9.46094 11.6889 12.3895 8.59766 16 8.59766C19.6105 8.59766 22.5391 11.6889 22.5391 15.5C22.5391 19.3111 19.6105 22.4023 16 22.4023ZM16.8385 15.0695L16.392 14.9601V12.4682C17.06 12.5646 17.473 13.0062 17.5434 13.548C17.5521 13.6223 17.6119 13.6761 17.6822 13.6761H18.4715C18.5541 13.6761 18.6191 13.6 18.6121 13.5128C18.5049 12.3568 17.6031 11.6146 16.399 11.4866V10.8799C16.399 10.7982 16.3357 10.7314 16.2584 10.7314H15.7645C15.6871 10.7314 15.6238 10.7982 15.6238 10.8799V11.4922C14.3793 11.6202 13.4055 12.3457 13.4055 13.7002C13.4055 14.9545 14.2809 15.5594 15.2002 15.7913L15.6344 15.9082V18.556C14.8574 18.4465 14.4215 18.0086 14.3318 17.4186C14.3213 17.348 14.2615 17.2961 14.193 17.2961H13.3809C13.2982 17.2961 13.2332 17.3703 13.2402 17.4575C13.3193 18.478 14.0523 19.4169 15.6168 19.5375V20.1201C15.6168 20.2018 15.6801 20.2686 15.7574 20.2686H16.2566C16.334 20.2686 16.3973 20.2018 16.3973 20.1183L16.3938 19.5301C17.7701 19.4021 18.7545 18.6246 18.7545 17.2293C18.7527 15.9416 17.9775 15.3664 16.8385 15.0695ZM15.6326 14.7689C15.5342 14.7393 15.4516 14.7114 15.3689 14.6762C14.7748 14.4498 14.4988 14.0843 14.4988 13.613C14.4988 12.9395 14.9822 12.5554 15.6326 12.4682V14.7689ZM16.392 18.5615V16.0808C16.4465 16.0975 16.4957 16.1104 16.5467 16.1216C17.3781 16.3888 17.6576 16.7599 17.6576 17.3295C17.6576 18.055 17.1408 18.491 16.392 18.5615Z", fill: "white" })] })); };
export var TimeSheetMetricIcon = function (props) { return (_jsx(Icon, __assign({ component: TimeSheetMetricSvg }, props))); };
