import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useRecordDetail, useTimeZone } from 'src/hooks/component';
var ScheduleTemplateCountManuallyUpdatedBadge = function (_a) {
    var manuallyUpdatedInfo = _a.manuallyUpdatedInfo;
    var timeFormatFn = useTimeZone().timeFormatFn;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    return (_jsx(ZiraTooltip, { title: _jsxs("div", { className: "flex flex-col text-sm", children: [_jsx("span", { className: "font-semibold", children: "Expected count manually set by:" }), _jsx("span", { className: "cursor-pointer text-accent", onClick: function () { return openRecordDetail(manuallyUpdatedInfo.actor.recordId); }, children: manuallyUpdatedInfo.actor.name }), _jsx("span", { children: timeFormatFn(manuallyUpdatedInfo.updatedAt, 'MMM D, YYYY - h:mma', true) })] }), children: _jsx("div", { className: "absolute right-[2px] top-[4px] h-[8px] w-[8px] rounded bg-black" }) }));
};
export default React.memo(ScheduleTemplateCountManuallyUpdatedBadge);
