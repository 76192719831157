var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { DownOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Tooltip } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { participantsInNewThread } from 'src/state/atoms/chatState';
import { useChatComponent } from 'src/hooks/component/useChat';
import { isCreatingThreadSelector, selectedThreadSummarySelector, } from 'src/state/selectors/withChatState';
import { SendMessageIcon } from 'src/utils/icons/ChatIcons/SendMessageIcon';
import { UploadFileType } from 'src/components/ComponentWithUpload';
import { UploadUrlType } from 'src/hooks/api';
import ComponentWithUploadAttachment from 'src/components/ComponentWithUploadAttachment';
import ArchiveThreadButton from '../../ArchiveThreadButton';
var allAcceptedFileTypes = [
    UploadFileType.IMAGE,
    UploadFileType.AUDIO,
    UploadFileType.VIDEO,
    UploadFileType.PNG,
    UploadFileType.JPG,
    UploadFileType.JPEG,
    UploadFileType.DOC,
    UploadFileType.DOCX,
    UploadFileType.PDF,
    UploadFileType.CSV,
];
var AddNewMessageSection = function (_a) {
    var _b, _c;
    var onSendMessage = _a.onSendMessage, senderOptions = _a.senderOptions, _d = _a.currentMessage, currentMessage = _d === void 0 ? '' : _d, _e = _a.isBroadcast, isBroadcast = _e === void 0 ? false : _e;
    var recordId = new URLSearchParams(location.search).get('rid');
    var isChatInRecordModal = !!recordId;
    var sortedSenderOptionsByLastMessageTime = useChatComponent().sortedSenderOptionsByLastMessageTime;
    var _f = useRecoilState(participantsInNewThread), userInThread = _f[0], setUserInThread = _f[1];
    var _g = useRecoilState(isCreatingThreadSelector), isCreatingThread = _g[0], setIsCreatingThread = _g[1];
    var selectedThreadSummary = useRecoilValue(selectedThreadSummarySelector);
    var _h = useState(currentMessage), message = _h[0], setMessage = _h[1];
    var _j = useState(undefined), selectedSenderOption = _j[0], setSelectedSenderOption = _j[1];
    // Initialize selected sender option if not set
    useEffect(function () {
        if (!selectedSenderOption) {
            if ((isCreatingThread || isChatInRecordModal) && (userInThread === null || userInThread === void 0 ? void 0 : userInThread.sender)) {
                setSelectedSenderOption(userInThread.sender);
            }
            else if (senderOptions.length > 0) {
                var options = isChatInRecordModal
                    ? sortedSenderOptionsByLastMessageTime(senderOptions)
                    : senderOptions;
                setSelectedSenderOption(options[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isChatInRecordModal,
        isCreatingThread,
        selectedSenderOption,
        senderOptions,
        userInThread === null || userInThread === void 0 ? void 0 : userInThread.sender,
    ]);
    var _k = useState([]), fileList = _k[0], setFileList = _k[1];
    useEffect(function () {
        setMessage(currentMessage);
    }, [currentMessage]);
    var onClickSendMessage = function () {
        if ((message || attachments.length > 0) && selectedSenderOption) {
            onSendMessage(message, selectedSenderOption, attachments);
            setMessage('');
            if (!isChatInRecordModal) {
                setIsCreatingThread(undefined);
            }
            setFileList([]);
        }
    };
    var attachments = useMemo(function () {
        return fileList.flatMap(function (file) {
            var _a, _b;
            return ((_b = (_a = file.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.url)
                ? [
                    (location.hostname === 'localhost'
                        ? process.env.REACT_APP_FILE_URL
                        : '') + file.response.data.url,
                ]
                : [];
        });
    }, [fileList]);
    useEffect(function () {
        if (isCreatingThread || isChatInRecordModal) {
            setSelectedSenderOption(function () {
                if (isCreatingThread && (userInThread === null || userInThread === void 0 ? void 0 : userInThread.sender)) {
                    return userInThread.sender;
                }
                else if (senderOptions.length > 0) {
                    var options = isChatInRecordModal
                        ? sortedSenderOptionsByLastMessageTime(senderOptions)
                        : senderOptions;
                    return options[0];
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreatingThread, isChatInRecordModal]);
    // if user updated sender option in thread creation, update the setUserInThread state
    useEffect(function () {
        if ((isCreatingThread || isChatInRecordModal) && selectedSenderOption) {
            setUserInThread(function (prev) {
                if (prev) {
                    return __assign(__assign({}, prev), { sender: selectedSenderOption });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSenderOption, isChatInRecordModal]);
    // Select valid sender option if the selected one is not in the list
    useEffect(function () {
        if (selectedSenderOption &&
            !senderOptions.map(function (option) { return option.id; }).includes(selectedSenderOption.id)) {
            setSelectedSenderOption(senderOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [senderOptions]);
    var senderButtonSingleOption = useMemo(function () {
        return senderOptions.length <= 1;
    }, [senderOptions]);
    var senderOptionMenuItems = useMemo(function () {
        return senderOptions.map(function (department) {
            return {
                key: department.id,
                label: department.name,
                onClick: function () { return setSelectedSenderOption(department); },
            };
        });
    }, [senderOptions]);
    return (_jsxs("div", { className: "AddNewMessage", children: [_jsx(Input.TextArea, { className: "text-sm", bordered: false, placeholder: isBroadcast ? 'Send broadcast' : 'Send message', value: message, onChange: function (event) {
                    if (event.target.value !== '\n')
                        setMessage(event.target.value);
                }, autoSize: { minRows: 5, maxRows: 5 }, style: {
                    padding: '16px',
                }, onKeyDown: function (event) {
                    if (event.key === 'Enter' && event.shiftKey === false) {
                        onClickSendMessage();
                    }
                } }), _jsxs("div", { className: "AddNewMessageButtons ".concat(selectedThreadSummary ? 'justify-between' : 'justify-end'), children: [selectedThreadSummary && _jsx(ArchiveThreadButton, { isBroadcast: isBroadcast }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "AddAttachmentAction", children: _jsx(ComponentWithUploadAttachment, { acceptedFileType: allAcceptedFileTypes, type: UploadUrlType.MessageAttachment, fileList: fileList, setFileList: setFileList, children: _jsx(Button, { icon: _jsx(PaperClipOutlined, { className: "flex -rotate-45 items-center text-sm" }) }) }) }), _jsx(Tooltip, { title: !selectedSenderOption ? 'You can only broadcast as a department' : '', children: _jsx("div", { className: "AddNewMessageAction", children: senderButtonSingleOption ? (_jsx(Button, { icon: _jsx(SendMessageIcon, { className: "SendMsgIcon" }), className: "flex items-center text-sm", disabled: (!message && attachments.length === 0) ||
                                            !selectedSenderOption, onClick: onClickSendMessage, children: (selectedSenderOption === null || selectedSenderOption === void 0 ? void 0 : selectedSenderOption.name)
                                            ? 'Send as ' + ((_b = selectedSenderOption === null || selectedSenderOption === void 0 ? void 0 : selectedSenderOption.name) !== null && _b !== void 0 ? _b : 'Department')
                                            : 'Send' })) : (_jsx(Dropdown.Button, { icon: _jsx(DownOutlined, {}), className: "MessageDropdownBtn", overlayClassName: "CommentActionsMenu", menu: { items: senderOptionMenuItems }, onClick: onClickSendMessage, disabled: !selectedSenderOption, children: (selectedSenderOption === null || selectedSenderOption === void 0 ? void 0 : selectedSenderOption.name)
                                            ? 'Send as ' + ((_c = selectedSenderOption === null || selectedSenderOption === void 0 ? void 0 : selectedSenderOption.name) !== null && _c !== void 0 ? _c : 'Department')
                                            : 'Send' })) }) })] })] })] }));
};
export default AddNewMessageSection;
