var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toNumber } from 'lodash';
import { PayRuleAdjustmentField, PayRuleAdjustmentType, PayRuleConditionOperand, } from './interface';
export var payRateDataHasDuplicateQualifiers = function (payRateData) {
    if (!payRateData)
        return false;
    // Only one base rate wage
    var data = payRateData.wages;
    if (data.length <= 1) {
        return false;
    }
    // Multiple 0 qualifier rate wages
    if (payRateData.wages.length > 1 &&
        payRateData.wages.filter(function (wage) { return Object.keys(wage.qualifiers).length === 0; }).length > 1) {
        return true;
    }
    var duplicateRecords = [];
    var map = new Map();
    payRateData.wages.map(function (wage) {
        // Only process non base rate wage
        if (wage.type !== 'SYSTEM') {
            var qualifiers_1 = wage.qualifiers;
            var qualifiersObj_1 = {};
            // Using qualifier cols data to iterate, make sure order consistency
            payRateData.qualifierCols.map(function (col) {
                var key = col.id;
                var qualifier = qualifiers_1[key];
                if (qualifier) {
                    if (qualifier.type === 'COLLECTION') {
                        qualifiersObj_1[key] = qualifier.recordId;
                    }
                    else if (qualifier.type === 'SELECTION') {
                        qualifiersObj_1[key] = qualifier.selectionOptionId;
                    }
                    else {
                        qualifiersObj_1[key] = qualifier.time;
                    }
                }
            });
            var qualifiersString = JSON.stringify(qualifiersObj_1);
            if (map.has(qualifiersString)) {
                var duplicateRecordId = map.get(qualifiersString);
                duplicateRecords.push(wage.id);
                duplicateRecords.push(duplicateRecordId);
            }
            else {
                map.set(qualifiersString, wage.id);
            }
        }
    });
    return duplicateRecords.length !== 0;
};
export var validatePayRules = function (partialRequest) {
    var defaultPayRules = {
        accountId: partialRequest.accountId || '',
        collectionId: partialRequest.collectionId || '',
        conditionOperand: partialRequest.conditionOperand || PayRuleConditionOperand.OR,
        id: partialRequest.id || '',
        isActive: partialRequest.isActive || false,
        name: partialRequest.name || 'Undefined Name',
    };
    return defaultPayRules;
};
var getFieldSchemaId = function (adjustment, payRate, billRate) {
    switch (adjustment.field) {
        case PayRuleAdjustmentField.PAY_RATE:
            return payRate === null || payRate === void 0 ? void 0 : payRate.id;
        case PayRuleAdjustmentField.BILL_RATE:
            return billRate === null || billRate === void 0 ? void 0 : billRate.id;
    }
};
export var constructCreateUpdatePayItemRequest = function (data, shiftCollectionId, accountId, payRate, billRate) {
    var payRuleInfo = data.info, adjustments = data.adjustments, conditions = data.conditions;
    return {
        info: {
            collectionId: shiftCollectionId,
            name: payRuleInfo.name || 'Untitled Pay Rules',
            conditionOperand: payRuleInfo.conditionOperand || PayRuleConditionOperand.OR,
            isActive: (payRuleInfo === null || payRuleInfo === void 0 ? void 0 : payRuleInfo.isActive) ? payRuleInfo === null || payRuleInfo === void 0 ? void 0 : payRuleInfo.isActive : true,
        },
        conditions: conditions.map(function (condition) {
            return __assign(__assign({}, condition), { id: undefined });
        }),
        adjustments: adjustments.map(function (adjustment) {
            var _a;
            var adjustmentCast = __assign(__assign({}, adjustment), { value: typeof adjustment.value === 'number'
                    ? adjustment.value
                    : toNumber((_a = adjustment.value) === null || _a === void 0 ? void 0 : _a.replace(/,/g, '')), fieldSchemaId: getFieldSchemaId(adjustment, payRate, billRate) });
            return __assign({ accountId: accountId, field: PayRuleAdjustmentField.PAY_RATE, type: PayRuleAdjustmentType.SET_TO }, adjustmentCast);
        }),
    };
};
