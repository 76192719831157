import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { InputNumber, Row, Select } from 'antd';
import { entries } from 'lodash';
import { overtimeDayOfWeekOptionsMap } from 'src/shared';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { SpecialOvertimeRuleEnumsAsArray, SpecialOvertimeRuleWithNoMultiplier, SpecialOvertimeRuleWithMultiplier, } from '../Interface';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import './OverTimeRule.scss';
var OvertimeRule = function (props) {
    var rule = props.rule, groupIndex = props.groupIndex, ruleIndex = props.ruleIndex, type = props.type;
    var _a = CompliancePageState.useContainer(), changeOvertimeRuleMultiplier = _a.changeOvertimeRuleMultiplier, changeOvertimeRuleThreshold = _a.changeOvertimeRuleThreshold, changeOvertimeRuleSpecialRule = _a.changeOvertimeRuleSpecialRule, changeOvertimeRuleDayOfWeek = _a.changeOvertimeRuleDayOfWeek, deleteOvertimeRule = _a.deleteOvertimeRule;
    var handleChangeMultiplier = useCallback(function (value) {
        changeOvertimeRuleMultiplier(groupIndex, ruleIndex, type, value);
    }, [changeOvertimeRuleMultiplier, groupIndex, ruleIndex, type]);
    var handleChangeThreshold = useCallback(function (value) {
        changeOvertimeRuleThreshold(groupIndex, ruleIndex, type, value);
    }, [changeOvertimeRuleThreshold, groupIndex, ruleIndex, type]);
    var handleChangeSpecialRule = useCallback(function (value) {
        changeOvertimeRuleSpecialRule(groupIndex, ruleIndex, value);
    }, [changeOvertimeRuleSpecialRule, groupIndex, ruleIndex]);
    var handleChangeDayOfWeek = useCallback(function (value) {
        changeOvertimeRuleDayOfWeek(groupIndex, ruleIndex, value);
    }, [changeOvertimeRuleDayOfWeek, groupIndex, ruleIndex]);
    var onDeleteOvertimeRule = useCallback(function () {
        deleteOvertimeRule(groupIndex, ruleIndex, type);
    }, [deleteOvertimeRule, groupIndex, ruleIndex, type]);
    var showMultiplier = useMemo(function () {
        if (SpecialOvertimeRuleEnumsAsArray.some(function (element) { return element === rule.rule; })) {
            return false;
        }
        return true;
    }, [rule]);
    return (_jsx("div", { className: "OvertimeRuleWrapper", children: _jsxs(Row, { align: "middle", children: [type === 'SPECIAL' ? (_jsx("div", { className: "OvertimeRuleSelector", children: _jsxs(Select, { className: "TimeOffRuleNameSelector SelectorCellWrapper", value: rule.rule || '', bordered: false, onChange: function (value) {
                            handleChangeSpecialRule(value);
                        }, dropdownMatchSelectWidth: false, children: [_jsx(Select.Option, { value: SpecialOvertimeRuleWithNoMultiplier.SEVEN_DAY_RULE, children: "Seven Day Rule" }), _jsx(Select.Option, { value: SpecialOvertimeRuleWithNoMultiplier.SEVEN_MINUTE_RULE, children: "Seven Minutes Rule" }), _jsx(Select.Option, { value: SpecialOvertimeRuleWithNoMultiplier.ROUND_SHIFT_RULE, children: "Round Clock In & Out Rule" }), _jsx(Select.Option, { value: SpecialOvertimeRuleWithMultiplier.US_HOLIDAYS, children: "US Holidays" })] }) })) : (_jsx("div", { className: "w-[180px] font-medium", children: upperCaseStringToStartCase(rule.rule || type) })), _jsx("div", { className: "RulesDetailsContainer", children: _jsxs(Row, { children: [showMultiplier && (_jsxs(Select, { value: rule.multiplier, bordered: false, onChange: handleChangeMultiplier, children: [_jsx(Select.Option, { value: "REGULAR", children: "1.0x" }), _jsx(Select.Option, { value: "OVERTIME", children: "1.5x" }), _jsx(Select.Option, { value: "DOUBLE_OVERTIME", children: "2.0x" })] })), 'threshold' in rule && (_jsxs(Row, { align: "middle", children: [_jsx("span", { className: "RuleDescriptionPiece", children: "after" }), _jsxs(Row, { className: "overtimeNumberInputContainer", align: "middle", justify: "space-around", children: [_jsx(InputNumber, { className: "settingNumberInputForHours", value: rule.threshold, bordered: false, onChange: handleChangeThreshold }), _jsx("span", { className: "settingNumberInputSuffix", children: "hrs" })] })] })), 'dayOfWeek' in rule && (_jsxs(Row, { align: "middle", children: [_jsx("span", { className: "RuleDescriptionPiece", children: "on" }), _jsx(Select, { className: "settingSelect", value: rule.dayOfWeek, bordered: false, onChange: handleChangeDayOfWeek, dropdownMatchSelectWidth: false, children: entries(overtimeDayOfWeekOptionsMap).map(function (_a) {
                                            var type = _a[0], displayValue = _a[1];
                                            return (_jsx(Select.Option, { value: type, children: displayValue }, type));
                                        }) })] }))] }) }), _jsx("div", { className: "GroupRuleDeleteBtn", onClick: onDeleteOvertimeRule, children: _jsx(TrashOutlinedIcon, {}) })] }) }));
};
export default React.memo(OvertimeRule);
