import { atomFamily } from 'recoil';
import { localStorageEffect } from '../localStorageEffects';
/*
 * For day and week view types
 */
var scheduleTableState = atomFamily({
    key: 'scheduleTableState',
    default: [],
});
var scheduleTableRowState = atomFamily({
    key: 'scheduleTableRowState',
    default: [],
});
// For week view types
var scheduleCardShouldDelayRenderState = atomFamily({
    key: 'scheduleCardShouldDelayRenderState',
    default: true,
});
var scheduleIsDraggingState = atomFamily({
    key: 'scheduleIsDraggingState',
    default: false,
});
var scheduleDropTargetState = atomFamily({
    key: 'scheduleDropTargetState',
    default: {},
});
// For month view (and future calendar views)
var scheduleCalendarState = atomFamily({
    key: 'scheduleCalendarState',
    default: {},
});
var scheduleTimeOffsState = atomFamily({
    key: 'scheduleTimeOffsState',
    default: {},
});
var selectedShiftsState = atomFamily({
    key: 'selectedShiftsState',
    default: [],
});
var scheduleEvaluationState = atomFamily({
    key: 'scheduleEvaluationState',
    default: undefined,
});
var scheduleTimeRangeState = atomFamily({
    key: 'scheduleTimeRangeState',
    default: [],
    effects: function (blockId) { return [localStorageEffect("scheduleTimeRangeState:".concat(blockId))]; },
});
var scheduleStatsSummaryState = atomFamily({
    key: 'scheduleStatsSummaryState',
    default: undefined,
});
var scheduleRulesDrawerVisibleState = atomFamily({
    key: 'scheduleRulesDrawerVisibleState',
    default: false,
});
var scheduleTemplateDrawerVisibleState = atomFamily({
    key: 'scheduleTemplateDrawerVisibleState',
    default: false,
});
var scheduleTemplateConfirmVisibleState = atomFamily({
    key: 'scheduleTemplateConfirmVisibleState',
    default: false,
});
export { scheduleTableState, scheduleTableRowState, scheduleTimeOffsState, scheduleCardShouldDelayRenderState, scheduleIsDraggingState, scheduleDropTargetState, selectedShiftsState, scheduleEvaluationState, scheduleTimeRangeState, scheduleStatsSummaryState, scheduleCalendarState, scheduleRulesDrawerVisibleState, scheduleTemplateDrawerVisibleState, scheduleTemplateConfirmVisibleState, };
