import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { head } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useAccountHistoryApi } from 'src/hooks/api';
import { appSessionState } from 'src/state';
var ActivityHistoryPollingIndicator = function (_a) {
    var _b;
    var showIndicator = _a.showIndicator, onIndicatorVisibleChange = _a.onIndicatorVisibleChange;
    var useGetAccountHistoryQuery = useAccountHistoryApi().useGetAccountHistoryQuery;
    var _c = ((_b = useRecoilValue(appSessionState)) !== null && _b !== void 0 ? _b : {}).accountId, accountId = _c === void 0 ? '' : _c;
    var data = useGetAccountHistoryQuery({
        body: {
            accountId: accountId,
            filters: {
                actorIds: [],
                actorTypes: ['AUTOMATION'],
                collectionIds: [],
                eventTypes: [],
            },
            from: moment().toISOString(),
            pageSize: 1,
        },
        options: {
            // !MARK: polling every 1 min
            refetchInterval: 60000,
        },
    }).data;
    var fetchedEvents = head(data === null || data === void 0 ? void 0 : data.accountHistories);
    var latestEventFromData = head(fetchedEvents === null || fetchedEvents === void 0 ? void 0 : fetchedEvents.sort(function (a, b) {
        // sort by latest event first
        return moment(a.recordedAt).isAfter(moment(b.recordedAt)) ? -1 : 1;
    }));
    var lastShownEventRecordedAt = latestEventFromData === null || latestEventFromData === void 0 ? void 0 : latestEventFromData.recordedAt;
    useEffect(function () {
        if (lastShownEventRecordedAt) {
            onIndicatorVisibleChange(true);
            //!MARK: make icon disappear once done showing latest event indicator
            setTimeout(function () {
                onIndicatorVisibleChange(false);
            }, 2000);
        }
        //!MARK: only depends on last fetched date string updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastShownEventRecordedAt]);
    if (!showIndicator) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("span", { className: "rotat absolute left-4 top-2.5 flex h-3 w-3 -translate-x-4 rotate-180", children: _jsx("p", { className: "account-history-indicator", children: _jsx("a", { href: "#complements", className: "animate" }) }) }));
};
export default React.memo(ActivityHistoryPollingIndicator);
