import { atom, atomFamily } from 'recoil';
import { localStorageEffect } from 'src/state/atoms/localStorageEffects';
import { NeedsCoverageType, SchedulingSummaryType } from './interface';
var schedulingBlockBottomBarExpandedStatusState = atomFamily({
    key: 'schedulingBlockBottomBarExpandedStatusState',
    default: false,
    effects: function (blockId) { return [
        localStorageEffect("schedulingBlockBottomBarExpandedStatusState:".concat(blockId)),
    ]; },
});
var schedulingBottomBarActiveTabState = atomFamily({
    key: 'schedulingBottomBarActiveTabState',
    default: SchedulingSummaryType.SCHEDULING_STATS,
    effects: function (blockId) { return [
        localStorageEffect("schedulingBottomBarActiveTabState:".concat(blockId)),
    ]; },
});
var scheduleBottomBarHeightState = atomFamily({
    key: 'scheduleBottomBarHeightState',
    default: 200,
    effects: function (key) { return [localStorageEffect("scheduleBottomBarHeightState:".concat(key))]; },
});
var isNeedsCoverageConfigModalVisibleState = atom({
    key: 'isNeedsCoverageConfigModalVisibleState',
    default: false,
});
var needsCoverageConfigModalActiveTabState = atom({
    key: 'needsCoverageConfigModalActiveTabState',
    default: NeedsCoverageType.NEED,
});
var needsCoverageTemplateVisibleOption = atom({
    key: 'needsCoverageTemplateVisibleOption',
    default: {
        visible: false,
        viewOnly: false,
    },
});
var needsCoverageSelectedTemplateIdState = atom({
    key: 'needsCoverageSelectedTemplateIdState',
    default: undefined,
});
export { schedulingBlockBottomBarExpandedStatusState, schedulingBottomBarActiveTabState, scheduleBottomBarHeightState, isNeedsCoverageConfigModalVisibleState, needsCoverageConfigModalActiveTabState, needsCoverageTemplateVisibleOption, needsCoverageSelectedTemplateIdState, };
