import { PayReportExportType } from 'src/components/PayView/Constant';
export var CuttingEdgeEventReportTypeOptions = [
    {
        value: PayReportExportType.CUTTING_EDGE_EVENT_INVOICE,
        displayValue: 'Event Invoice',
    },
    {
        value: PayReportExportType.CUTTING_EDGE_EVENT_ESTIMATE,
        displayValue: 'Event Estimate',
    },
    {
        value: PayReportExportType.CUTTING_EDGE_EVENT_STAFF_LIST,
        displayValue: 'Event Staff List',
    },
];
