import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Modal, Spin, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './ExportIntegrationModal.scss';
import ExportInvoiceIntegrationContent from './ExportInvoiceIntegrationContent';
import ExportPayrollIntegrationContent from './ExportPayrollIntegrationContent';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var TabPane = Tabs.TabPane;
var ExportIntegrationModal = function (props) {
    var isModalVisible = props.isModalVisible, setIsModalVisible = props.setIsModalVisible;
    var _a = useState('payroll'), exportTab = _a[0], setExportTab = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    return (_jsx(Modal, { className: "ExportIntegrationModal", open: isModalVisible, centered: true, footer: null, destroyOnClose: true, onCancel: function () { return setIsModalVisible(false); }, closable: false, children: _jsx(Spin, { spinning: isLoading, tip: "Loading...", size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: _jsxs(_Fragment, { children: [_jsx("div", { className: "ExportModalTitle", children: "Export" }), _jsxs(Tabs, { activeKey: exportTab, onChange: function (key) { return setExportTab(key); }, children: [_jsx(TabPane, { className: "px-[16px]", tab: "Payroll" }, "payroll"), _jsx(TabPane, { className: "px-[16px]", tab: "Invoice" }, "invoice")] }), exportTab === 'payroll' && (_jsx(ExportPayrollIntegrationContent, { setIsModalVisible: setIsModalVisible, setIsLoading: setIsLoading })), exportTab === 'invoice' && (_jsx(ExportInvoiceIntegrationContent, { setIsModalVisible: setIsModalVisible, setIsLoading: setIsLoading }))] }) }) }));
};
export default React.memo(ExportIntegrationModal);
