var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ToastMessage } from 'src/shared';
import { useFetch } from './useFetch';
export var useChatApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var useGetThreadsQuery = function (request) {
        return useQuery(['threadSummaryList'], function () { return getThreads(); }, __assign({}, request === null || request === void 0 ? void 0 : request.options));
    };
    var getThreads = useCallback(function () {
        return customFetch({
            method: 'GET',
            url: "chat/participating_threads",
        });
    }, [customFetch]);
    var useCreateThread = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "chat/thread",
            }, request);
        });
    };
    var useSendMessage = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "chat/send",
            }, request);
        });
    };
    // Departments that current user can access
    var useGetDepartmentCanSendAs = function () {
        return useQuery(['departmentCanSendAs'], function () {
            return customFetch({
                method: 'GET',
                url: "chat/my_departments",
            });
        });
    };
    // All departments in account for owner to manage
    var useGetAllDepartments = function () {
        return useQuery(['allChatDepartments'], function () {
            return customFetch({
                method: 'GET',
                url: "chat/web_account_departments",
            });
        });
    };
    var useGetAllPossibleRecipients = function () {
        return useQuery(['allPossibleRecipients'], function () {
            return customFetch({
                method: 'GET',
                url: "chat/possible_recipient",
            });
        });
    };
    var useViewThread = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "chat/view/",
            }, request);
        });
    };
    var useGetAdminCanBeAddedInDepartment = function (query) {
        return useQuery(['adminCanBeAddedInDepartment', query], function () {
            return customFetch({
                method: 'GET',
                url: "chat/admins?query=".concat(query),
            });
        });
    };
    var useDeleteDepartment = function () {
        return useMutation(function (departmentId) {
            return toastRequest({
                method: 'DELETE',
                url: "chat/department/".concat(departmentId),
            }, undefined, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useCreateDepartment = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "chat/department",
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useUpdateDepartment = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "chat/department/".concat(request.id),
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetMessagesFromThread = function (request) {
        var _a, _b, _c;
        return useQuery([
            'threadMessages',
            (_a = request === null || request === void 0 ? void 0 : request.threadId) !== null && _a !== void 0 ? _a : '',
            (_b = request === null || request === void 0 ? void 0 : request.beforeMessageSeqNum.toFixed(0)) !== null && _b !== void 0 ? _b : '',
            (_c = request === null || request === void 0 ? void 0 : request.limit.toFixed(0)) !== null && _c !== void 0 ? _c : '',
        ], function () {
            return customFetch({
                method: 'GET',
                url: "chat/messages/".concat(request.threadId, "?beforeMessageSeqNum=").concat(request.beforeMessageSeqNum, "&limit=").concat(request.limit),
            });
        }, __assign({ enabled: !!request && !!request.threadId }, request === null || request === void 0 ? void 0 : request.options));
    };
    var useGetMessagesFromThreadList = function (request) {
        var _a, _b;
        return useQuery(__spreadArray(__spreadArray([
            'threadMessages'
        ], request.threadIds, true), [
            (_a = request === null || request === void 0 ? void 0 : request.beforeMessageId) !== null && _a !== void 0 ? _a : '',
            (_b = request === null || request === void 0 ? void 0 : request.limit.toFixed(0)) !== null && _b !== void 0 ? _b : '',
        ], false), function () {
            return customFetch({
                method: 'POST',
                url: "chat/messages",
            }, request);
        }, __assign({ enabled: !!request }, request === null || request === void 0 ? void 0 : request.options));
    };
    var useGetBroadcastRecipients = function (request) {
        var _a, _b;
        return useQuery(['broadcastRecipients', (_b = (_a = request === null || request === void 0 ? void 0 : request.recipients) === null || _a === void 0 ? void 0 : _a.map(function (r) { return r.id; }).join(',')) !== null && _b !== void 0 ? _b : ''], function () {
            return customFetch({
                method: 'POST',
                url: "chat/broadcast_recipients",
            }, request);
        }, __assign({ enabled: !!request && !!request.recipients && request.recipients.length > 0 }, request === null || request === void 0 ? void 0 : request.options));
    };
    var useArchiveThread = function () {
        return useMutation(function (threadId) {
            return toastRequest({
                method: 'POST',
                url: "chat/archive/".concat(threadId),
            }, undefined, '', 'Successfully archived thread', ToastMessage.SAVE_FAIL);
        });
    };
    var useGetAllThreadsWithUser = function (userRid) {
        return useQuery(['AllChatThreadsWithUser', userRid], function () {
            return customFetch({
                method: 'GET',
                url: "chat/participating_threads/".concat(userRid),
            });
        }, { enabled: !!userRid, cacheTime: 0 });
    };
    return {
        useGetThreadsQuery: useGetThreadsQuery,
        getThreads: getThreads,
        useCreateThread: useCreateThread(),
        useSendMessage: useSendMessage(),
        useGetDepartmentCanSendAs: useGetDepartmentCanSendAs,
        useGetAllDepartments: useGetAllDepartments,
        useGetAllPossibleRecipients: useGetAllPossibleRecipients,
        useViewThread: useViewThread(),
        useGetAdminCanBeAddedInDepartment: useGetAdminCanBeAddedInDepartment,
        useDeleteDepartment: useDeleteDepartment(),
        useUpdateDepartment: useUpdateDepartment(),
        useCreateDepartment: useCreateDepartment(),
        useGetMessagesFromThread: useGetMessagesFromThread,
        useGetMessagesFromThreadList: useGetMessagesFromThreadList,
        useGetBroadcastRecipients: useGetBroadcastRecipients,
        useArchiveThread: useArchiveThread(),
        useGetAllThreadsWithUser: useGetAllThreadsWithUser,
    };
};
