import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Select } from 'antd';
var RatioConfigLocationSelector = function (_a) {
    var selectedLocationOption = _a.selectedLocationOption, locationList = _a.locationList, updateSelectedLocationOption = _a.updateSelectedLocationOption;
    return (_jsxs("div", { className: "mt-[20px]", children: [_jsx("div", { className: "font-medium", children: "Location" }), _jsx("div", { className: "text-xs font-medium text-gray-400", children: "Choose a location to configure HPPD ratio" }), _jsx(Select, { bordered: false, className: "LocationSelector mt-3", dropdownMatchSelectWidth: false, onChange: function (value) {
                    updateSelectedLocationOption(value);
                }, value: selectedLocationOption, children: locationList === null || locationList === void 0 ? void 0 : locationList.map(function (option) { return (_jsx(Select.Option, { children: option.name }, option.id)); }) })] }));
};
export default React.memo(RatioConfigLocationSelector);
