import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTimeZone } from 'src/hooks/component';
import './DummyTimeCellWithCountdown.scss';
var countdownDays = function (time, tz, shouldShowTime) {
    if (!shouldShowTime) {
        var currentTimeMoment = moment().tz(tz).startOf('day');
        var timeMoment = moment(time).tz(tz).startOf('day');
        var diff = timeMoment.diff(currentTimeMoment, 'days');
        return diff;
    }
    else {
        var currentTimeMoment = moment().tz(tz);
        var timeMoment = moment(time).tz(tz);
        var diff = timeMoment.diff(currentTimeMoment, 'minutes');
        var days = Math.floor(diff / 60 / 24);
        return days;
    }
};
var DummyTimeCellWithCountdown = function (props) {
    var time = props.time, setIsClicked = props.setIsClicked, canEdit = props.canEdit, recordTimeZone = props.recordTimeZone, formatString = props.formatString, shouldShowTime = props.shouldShowTime;
    var _a = useTimeZone(recordTimeZone), timeZone = _a.timeZone, timeFormatFn = _a.timeFormatFn, timezoneAbbr = _a.timezoneAbbr;
    var timeZoneSuffix = " ".concat(timezoneAbbr(true));
    var formattedTime = time
        ? "".concat(timeFormatFn(time, formatString, false), " ").concat(shouldShowTime ? timeZoneSuffix : '')
        : '';
    var showLogicCell = useCallback(function () {
        if (canEdit) {
            setIsClicked === null || setIsClicked === void 0 ? void 0 : setIsClicked(true);
        }
    }, [canEdit, setIsClicked]);
    var renderCountdownTag = useMemo(function () {
        if (!time)
            return null;
        var days = countdownDays(time, timeZone, shouldShowTime);
        var textColor = '#FB5418';
        var text = '';
        if (days < 0) {
            text = 'expired';
        }
        else if (days === 0) {
            text = "expires ".concat(shouldShowTime ? 'soon' : 'today');
        }
        else if (days < 30) {
            text = "".concat(days, "d remaining");
        }
        else if (days >= 30) {
            textColor = '#13B35D';
            if (days >= 365) {
                var years = Math.floor(days / 365);
                text = "".concat(years, "y remaining");
            }
            else {
                var months = Math.floor(days / 30);
                text = "".concat(months === 12 ? '1y' : "".concat(months, "m"), " remaining");
            }
        }
        return (_jsx("div", { style: { color: textColor }, className: "font-medium", children: text }));
    }, [time, timeZone, shouldShowTime]);
    return (_jsxs("div", { className: "DummyTimeCellWithCountdown", onClick: showLogicCell, children: [_jsx("div", { children: formattedTime }), renderCountdownTag] }));
};
export default React.memo(DummyTimeCellWithCountdown);
