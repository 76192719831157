var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var UserGroupLogoSvg = function () { return (_jsxs("svg", { width: "24", height: "17", viewBox: "0 0 24 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M8.26728 0.101562C6.20556 0.101562 4.5332 1.77283 4.5332 3.83452C4.5332 5.89622 6.20556 7.5686 8.26728 7.5686C10.329 7.5686 12.0002 5.89625 12.0002 3.83452C12.0002 1.7728 10.329 0.101562 8.26728 0.101562Z", fill: "white" }), _jsx("path", { d: "M11.9998 9.43359H4.53276C2.47104 9.43359 0.799805 11.1059 0.799805 13.1677V16.9006H15.7328V13.1677C15.7328 11.107 14.0615 9.43359 11.9998 9.43359Z", fill: "white" }), _jsx("path", { d: "M15.7328 0.10159C14.7375 0.10159 13.8374 0.497538 13.168 1.133C13.612 1.93363 13.8658 2.85455 13.8658 3.83455C13.8658 4.81455 13.612 5.73441 13.168 6.5361C13.8384 7.17266 14.7375 7.5686 15.7328 7.5686C17.7945 7.5686 19.4669 5.89625 19.4669 3.83452C19.4669 1.7728 17.7945 0.101562 15.7328 0.101562V0.10159Z", fill: "white" }), _jsx("path", { d: "M19.4664 9.43359H16.1621C17.0524 10.4256 17.5993 11.7326 17.5993 13.1677V16.9006H23.1993V13.1677C23.1993 11.107 21.528 9.43359 19.4663 9.43359H19.4664Z", fill: "white" })] })); };
export var UserGroupLogo = function (props) { return (_jsx(Icon, __assign({ component: UserGroupLogoSvg }, props))); };
