import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { blockItemsSelector, blockTabsSelector, currentAccessGroupState, currentPageBlockTab, currentPageState, ifCurrentUserHasDataBuilderPermission, pageItemSelector, pagePermissionFamilyState, } from 'src/state';
import { detailModalPropsState, isNewModalVisibleState } from 'src/state/atoms/detailModal';
import { timeOffRecordForUnassignShiftsState } from 'src/state/atoms/unassignShiftsOnTimeOffApprovalModalState';
import { useRecordComponent } from 'src/hooks/component';
import { disableScroll } from 'src/utils/Collection';
import { ObjType } from 'src/shared';
import BlockWrapper from '../TableView/BlockWrapper';
import { usePageApi, useSetting } from '../../hooks/api';
import BlockCollectionTableErrorBoundary from '../OptimizedTableView/Error/BlockCollectionTableErrorBoundary';
import { PagePermissionLevel } from '../Settings/Permission/Constants';
import { PageType } from '../CenterPanel/Interface';
import PageHeaderSection from './PageHeaderSection';
import './PageView.scss';
import UnassignShiftsOnTimeOffApprovalModal from './ShiftComponents/UnassignShiftsOnTimeOff/UnassignShiftsOnTimeOffApprovalModal';
import AddNewBlockDrawer from './AddNewBlockDrawer/AddNewBlockDrawer';
import { documentPreviewModalDataState } from './DocumentPreviewModal/Atom';
import DocumentPreviewModal from './DocumentPreviewModal/DocumentPreviewModal';
var PageView = function () {
    var _a, _b;
    var _c = useParams().pageId, pageId = _c === void 0 ? '' : _c;
    var useGetPageDetails = usePageApi().useGetPageDetails;
    var useGetObjectPermissionQuery = useSetting().useGetObjectPermissionQuery;
    var _d = useGetPageDetails(pageId), fetchedPageData = _d.data, refetch = _d.refetch;
    var currentAccessGroup = useRecoilValue(currentAccessGroupState);
    var pagePermissions = useGetObjectPermissionQuery({
        body: {
            objectId: pageId,
            type: ObjType.PAGE,
            accessGroupIds: currentAccessGroup ? [currentAccessGroup.id] : [],
        },
        options: {
            enabled: !!currentAccessGroup && !!pageId && !!fetchedPageData,
        },
    }).data;
    useEffect(function () {
        refetch();
        disableScroll(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageId]);
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var setCurrentPage = useSetRecoilState(currentPageState);
    useEffect(function () {
        if (fetchedPageData) {
            setCurrentPage(fetchedPageData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedPageData]);
    var setPagePermission = useSetRecoilState(pagePermissionFamilyState(pageId));
    useEffect(function () {
        if (pagePermissions && pagePermissions.length > 0) {
            setPagePermission(pagePermissions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagePermissions]);
    var pageItem = useRecoilValue(pageItemSelector(pageId));
    var pageData = useMemo(function () { return (pageItem ? (pageItem === null || pageItem === void 0 ? void 0 : pageItem.data) || undefined : undefined); }, [pageItem]);
    var blockIds = useMemo(function () { return (pageItem ? pageItem.children.filter(function (id) { return id !== "".concat(pageId, "dv"); }) : undefined); }, [pageId, pageItem]);
    var blockItems = useRecoilValue(blockItemsSelector);
    var treeBlocks = useMemo(function () { return blockItems.filter(function (b) { return b.parentId === pageId; }); }, [blockItems, pageId]);
    var blockTabs = useRecoilValue(blockTabsSelector(pageId));
    var _e = useRecoilState(currentPageBlockTab), activeTabKey = _e[0], setActiveTabKey = _e[1];
    var handlePageScroll = useCallback(function () {
        var containerEle = document.getElementById('CenterPanelContent');
        if (!containerEle || !blockTabs.length)
            return;
        var tabDistance = {};
        for (var _i = 0, blockTabs_1 = blockTabs; _i < blockTabs_1.length; _i++) {
            var tab = blockTabs_1[_i];
            var ele = document.getElementById(tab.id);
            if (ele) {
                var rect = ele.getBoundingClientRect();
                var diff = rect.bottom - containerEle.getBoundingClientRect().top;
                var threshold = 100; // Due to sticky header
                if (diff >= threshold) {
                    if (activeTabKey !== tab.id) {
                        setActiveTabKey(tab.id);
                    }
                    break;
                }
                tabDistance[tab.id] = Math.abs(diff) - threshold;
            }
        }
        if (!activeTabKey &&
            !blockTabs.find(function (b) { return b.id === activeTabKey; }) &&
            Object.keys(tabDistance).length) {
            setActiveTabKey(Object.keys(tabDistance).reduce(function (a, b) { return (tabDistance[a] > tabDistance[b] ? a : b); }));
        }
    }, [activeTabKey, blockTabs, setActiveTabKey]);
    useEffect(function () {
        window.addEventListener('scroll', handlePageScroll);
        return function () { return window.removeEventListener('scroll', handlePageScroll); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (blockTabs.length) {
            handlePageScroll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockTabs]);
    var setDetailModalProps = useSetRecoilState(detailModalPropsState);
    var setIsNewModalVisible = useSetRecoilState(isNewModalVisibleState);
    var isUnassignShiftsModalVisible = !!((_a = useRecoilValue(timeOffRecordForUnassignShiftsState)) === null || _a === void 0 ? void 0 : _a.overlappingShiftCount);
    var isDocumentPreviewModalVisible = useRecoilValue(documentPreviewModalDataState).length > 0;
    var blockWrapper = useCallback(function (blockId) {
        var _a;
        var blockContentType = (_a = treeBlocks.find(function (b) { return b.id === blockId; })) === null || _a === void 0 ? void 0 : _a.data.contentType;
        return (_jsx("div", { className: "BlockWrapper", id: blockId, children: _jsx(ErrorBoundary, { fallback: _jsx(BlockCollectionTableErrorBoundary, { pageId: pageId, blockId: blockId }), children: _jsx(BlockWrapper, { blockId: blockId, blockContentType: blockContentType, pageId: pageId, onCreateNewRecord: onCreateNewRecord, setDetailModalProps: setDetailModalProps, setIsModalVisible: setIsNewModalVisible }) }) }, blockId));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCreateNewRecord, pageId, treeBlocks]);
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var hasWritePermissionOnPage = parseInt(((_b = useRecoilValue(pagePermissionFamilyState(pageId))) === null || _b === void 0 ? void 0 : _b.permission) || '0') ==
        parseInt(PagePermissionLevel.WRITE);
    return (_jsxs("div", { className: "PageView", onWheel: handlePageScroll, children: [fetchedPageData && pageData && (_jsxs(_Fragment, { children: [pageData.pageType === PageType.CANVAS && _jsx(PageHeaderSection, {}), blockIds && blockIds.length > 0 && (_jsx("div", { className: "PageContent", children: blockIds.map(function (blockId) { return blockWrapper(blockId); }) })), hasDataBuilderAccess &&
                        hasWritePermissionOnPage &&
                        (pageData.pageType === PageType.CANVAS ||
                            (pageData.pageType === PageType.TABLE && !(blockIds === null || blockIds === void 0 ? void 0 : blockIds.length))) && (_jsx(AddNewBlockDrawer, {}))] })), isUnassignShiftsModalVisible && _jsx(UnassignShiftsOnTimeOffApprovalModal, {}), isDocumentPreviewModalVisible && _jsx(DocumentPreviewModal, {})] }));
};
export default React.memo(PageView);
