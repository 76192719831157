import { jsx as _jsx } from "react/jsx-runtime";
var RollupProgressBar = function (_a) {
    var percentage = _a.percentage;
    var colors = ['#B31313', '#FFD357', '#13B35D'];
    return (_jsx("div", { style: {
            backgroundColor: '#E7E7E7',
            position: 'relative',
        }, className: "rounded w-full h-[8px] overflow-hidden", children: _jsx("div", { style: {
                backgroundColor: colors[Math.min(Math.floor(percentage * colors.length), colors.length - 1)],
                width: "".concat(percentage * 100, "%"),
                height: '100%',
            }, className: "rounded h-full" }) }));
};
export default RollupProgressBar;
