import { selectorFamily } from 'recoil';
import { NativePath } from 'src/shared';
import { collectionTableSchemasState, scheduleBottomBarTabsSelector } from 'src/state';
import { SchedulingSummaryType } from './interface';
var noAvailableScheduleBottomBarTabsSelector = selectorFamily({
    key: 'noAvailableScheduleBottomBarTabsSelector',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var tabOptions = get(scheduleBottomBarTabsSelector(blockId));
            var count = 0;
            Object.values(tabOptions).map(function (visible) {
                if (visible)
                    count++;
            });
            return count === 0;
        };
    },
});
var firstAvailableScheduleBottomBarTabSelector = selectorFamily({
    key: 'firstAvailableScheduleBottomBarTabSelector',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var tabOptions = get(scheduleBottomBarTabsSelector(blockId));
            var visibleTabs = Object.keys(SchedulingSummaryType).filter(function (key) {
                var type = key;
                if (tabOptions[type] === true)
                    return type;
            });
            return visibleTabs.length === 0 ? undefined : visibleTabs[0];
        };
    },
});
var shiftCollectionAssigneeSchemaIdSelector = selectorFamily({
    key: 'shiftCollectionAssigneeSchemaSelector',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var shiftCollectionSchemas = get(collectionTableSchemasState(collectionId));
            var assigneeSchema = shiftCollectionSchemas.find(function (schema) { return schema.nativePath === NativePath.SHIFT_USER_ID; });
            return (assigneeSchema === null || assigneeSchema === void 0 ? void 0 : assigneeSchema.id) || '';
        };
    },
});
var shiftCollectionLocationSchemaIdSelector = selectorFamily({
    key: 'shiftCollectionLocationSchemaIdSelector',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var shiftCollectionSchemas = get(collectionTableSchemasState(collectionId));
            var locationSchema = shiftCollectionSchemas.find(function (schema) { return schema.nativePath === NativePath.SHIFT_LOCATION_ID; });
            return (locationSchema === null || locationSchema === void 0 ? void 0 : locationSchema.id) || '';
        };
    },
});
export { noAvailableScheduleBottomBarTabsSelector, firstAvailableScheduleBottomBarTabSelector, shiftCollectionAssigneeSchemaIdSelector, shiftCollectionLocationSchemaIdSelector, };
