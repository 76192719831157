import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
var ProtectedRoute = function (_a) {
    var children = _a.children;
    var _b = useAuth0(), user = _b.user, isAuthenticated = _b.isAuthenticated;
    if (!user || !isAuthenticated) {
        return _jsx(Navigate, { to: "/login" });
    }
    return children;
};
export default ProtectedRoute;
