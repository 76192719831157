var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ThumbsUpIconSvg = function (props) { return (_jsxs("svg", { width: "20", height: "20", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M21.7747 9.43098C21.0747 8.52164 20.016 8.00031 18.8693 8.00031H14.4347L15.4987 5.11098C16.116 3.43631 15.3773 1.57098 13.78 0.77231C13.3707 0.56831 12.8747 0.666976 12.576 1.01364L6.89067 7.59631C6.316 8.26298 6 9.11364 6 9.99231V17.667C6 19.6896 7.64533 21.3336 9.66667 21.3336H17.28C18.9413 21.3336 20.3987 20.2123 20.8253 18.6056L22.4133 12.6056C22.7067 11.4963 22.4733 10.3403 21.7747 9.43098Z", fill: props.fill || '#A7A8AC' }), _jsx("path", { d: "M5.66634 21.3333H3.66634C2.37967 21.3333 1.33301 20.2867 1.33301 19V10.3333C1.33301 9.04667 2.37967 8 3.66634 8H5.66634C6.95301 8 7.99967 9.04667 7.99967 10.3333V19C7.99967 20.2867 6.95301 21.3333 5.66634 21.3333ZM3.66634 10C3.48234 10 3.33301 10.1493 3.33301 10.3333V19C3.33301 19.184 3.48234 19.3333 3.66634 19.3333H5.66634C5.85034 19.3333 5.99967 19.184 5.99967 19V10.3333C5.99967 10.1493 5.85034 10 5.66634 10H3.66634Z", fill: props.fill || '#A7A8AC' })] })); };
export var ThumbsUpIcon = function (props) { return (_jsx(Icon, __assign({ component: function () { return _jsx(ThumbsUpIconSvg, __assign({}, props)); } }, props))); };
