import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PayReportMetricRow from 'src/components/PayView/PayReportMetricRow';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { setPayPeriodPageRoute } from 'src/utils/Account';
var PayPeriodCard = function (_a) {
    var payPeriod = _a.payPeriod, onOpenEditPayPeriod = _a.onOpenEditPayPeriod, onRefetchPayPeriods = _a.onRefetchPayPeriods;
    var payPeriodId = payPeriod.payPeriodId, payPeriodStart = payPeriod.payPeriodStart, payPeriodEnd = payPeriod.payPeriodEnd, metrics = payPeriod.metrics, isApproved = payPeriod.isApproved;
    var _b = useState(false), dropdownVisible = _b[0], setDropdownVisible = _b[1];
    var navigate = useNavigate();
    var onGoToPayPeriodPage = useCallback(function () {
        if (dropdownVisible)
            return;
        setPayPeriodPageRoute(location.pathname);
        navigate({ pathname: "/home/pay/".concat(payPeriodId) });
    }, [dropdownVisible, navigate, payPeriodId]);
    var openEditModal = useCallback(function () {
        onOpenEditPayPeriod();
    }, [onOpenEditPayPeriod]);
    var useDeletePayPeriod = usePayApi().useDeletePayPeriod;
    var onDeletePayPeriod = useCallback(function () {
        useDeletePayPeriod.mutate(payPeriodId, {
            onSuccess: function (resp) {
                if (resp) {
                    onRefetchPayPeriods();
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payPeriodId]);
    var dropdownMenu = useMemo(function () {
        return (_jsxs(Menu, { className: "p-0", children: [_jsx(Menu.Item, { onClick: openEditModal, children: "Edit" }), _jsx(Menu.Item, { children: _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this pay period?", onConfirm: onDeletePayPeriod, placement: "rightBottom", children: _jsx("div", { className: "cursor-pointer text-red-1000", children: "Delete" }) }) })] }));
    }, [onDeletePayPeriod, openEditModal]);
    return (_jsx("div", { className: "relative my-3 flex cursor-pointer items-center rounded-lg bg-white py-6 shadow-block hover:shadow-hover", onClick: onGoToPayPeriodPage, children: _jsxs("div", { className: "flex w-full flex-wrap items-center gap-y-4 px-4", children: [_jsxs("span", { className: "mr-4 w-40 font-semibold", children: [payPeriodStart, " - ", payPeriodEnd] }), _jsx(PayReportMetricRow, { metrics: metrics }), _jsx("div", { className: "ml-auto mr-2 font-semibold", children: isApproved ? (_jsx("div", { className: "ml-auto mr-4 flex h-8 w-36 items-center justify-center rounded bg-green-50 text-green-500 ", children: "Approved" })) : (_jsx("div", { className: "ml-auto mr-4 flex h-8 w-36 items-center justify-center rounded bg-yellow-50 text-yellow-400 ", children: "Pending" })) }), _jsx(Button, { className: "mr-4 bg-gray-50 font-semibold text-black hover:text-black", onClick: onGoToPayPeriodPage, title: "View pay period details", children: "View Details" }), _jsx(ZiraDropdown, { className: "absolute right-2 top-2", visible: dropdownVisible, onLocalVisibleChange: function (visible) { return setDropdownVisible(visible); }, overlay: dropdownMenu, trigger: ['click'], placement: "bottomRight", children: _jsx(EllipsisOutlined, { onClick: function (e) { return e.stopPropagation(); } }) })] }) }));
};
export default React.memo(PayPeriodCard);
