var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AutomationTriggerIconSvg = function () { return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M11.8856 2.24564C11.9755 2.0934 12.1392 2 12.316 2H13.5948C13.9819 2 14.2222 2.42105 14.0252 2.75436L7.97105 13H6.40701C6.01986 13 5.77959 12.5789 5.97655 12.2456L11.8856 2.24564Z" }), _jsx("path", { d: "M15.8856 11.2456C15.9755 11.0934 16.1392 11 16.316 11H17.5948C17.9819 11 18.2222 11.4211 18.0252 11.7544L11.971 22H10.407C10.0199 22 9.77959 21.5789 9.97655 21.2456L15.8856 11.2456Z" }), _jsx("path", { d: "M5.87569 3.62545C5.96552 3.4727 6.12949 3.37891 6.30669 3.37891H7.58684C7.97352 3.37891 8.21385 3.79905 8.01783 4.13237L5.18456 8.95019C5.09473 9.10294 4.93077 9.19673 4.75356 9.19673H3.47341C3.08673 9.19673 2.8464 8.77659 3.04242 8.44327L5.87569 3.62545Z" }), _jsx("path", { d: "M18.8151 15.0708C18.905 14.918 19.0689 14.8242 19.2461 14.8242H20.5263C20.913 14.8242 21.1533 15.2444 20.9573 15.5777L18.124 20.3955C18.0342 20.5483 17.8702 20.642 17.693 20.642H16.4129C16.0262 20.642 15.7859 20.2219 15.9819 19.8886L18.8151 15.0708Z" }), _jsx("path", { d: "M7.43945 11H16.4395V13H7.43945V11Z" })] })); };
export var AutomationTriggerIcon = function (props) { return (_jsx(Icon, __assign({ component: AutomationTriggerIconSvg }, props))); };
