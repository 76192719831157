import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { Layout } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { startCase } from 'lodash';
import { useRecoilValue } from 'recoil';
import { currentUserState } from 'src/state';
import { accountNameState } from 'src/state/selectors/withAccountDetailState';
import { generateDashboardHeaderGreetings } from '../Dashboard/DashboardUtils';
import HelpAndActivityBtnsSection from './HelpAndActivityBtnsSection';
import StripeBillingWarningBanner from './StripeBillingWarningBanner';
var Header = Layout.Header;
var NonPageViewHeader = function (props) {
    var _a;
    var title = props.title, bgColor = props.bgColor, toggleActivityFeed = props.toggleActivityFeed, leftNavCollapsed = props.leftNavCollapsed, toggleNaviBar = props.toggleNaviBar;
    var location = useLocation();
    var currentUserName = (_a = useRecoilValue(currentUserState)) === null || _a === void 0 ? void 0 : _a.fullName;
    var accountName = useRecoilValue(accountNameState);
    useEffect(function () {
        var currentPath = location.pathname.split('/')[2];
        var title = '';
        if (currentPath === undefined) {
            title = 'Dashboard';
        }
        else {
            title = startCase(currentPath);
        }
        document.title = "".concat(accountName, " | ").concat(title);
    }, [accountName, location]);
    var generateHeaderTitle = useCallback(function () {
        if (title) {
            return title;
        }
        else {
            var userFirstName = 'User';
            if (currentUserName)
                userFirstName = currentUserName.split(' ')[0];
            return "".concat(generateDashboardHeaderGreetings(), ", ").concat(userFirstName !== null && userFirstName !== void 0 ? userFirstName : 'User');
        }
    }, [currentUserName, title]);
    return (_jsxs(Header, { className: "NonPageViewHeader", style: { backgroundColor: bgColor }, children: [_jsx(StripeBillingWarningBanner, {}), _jsxs("div", { className: "NonPageViewHeaderContainer flex h-full items-center justify-between pl-[40px] pr-[40px]", children: [_jsxs("div", { className: "flex items-center ", children: [leftNavCollapsed && (_jsx(MenuUnfoldOutlined, { className: "mr-2 cursor-pointer text-[18px]", onClick: toggleNaviBar })), _jsx("div", { className: " text-xl font-medium", children: generateHeaderTitle() })] }), _jsx(HelpAndActivityBtnsSection, { toggleActivityFeed: toggleActivityFeed })] })] }));
};
export default React.memo(NonPageViewHeader);
