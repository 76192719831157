var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilState } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import { detailModalVisibleReverseCollectionIds } from 'src/state';
import SliderImgSrc from 'src/utils/icons/images/slider.png';
import ZiraTooltip from '../../ui/ZiraTooltip';
import { ZiraVisibilityDropdown, ZiraVisibilityDropdownToggleItem, ZiraVisibilityDropdownVisibleItem, } from '../../ui/ZiraVisibilityDropdown';
export var sortAllReverseCollections = function (allReverseCollections, visibleReverseCollectionIds) {
    return allReverseCollections.sort(function (a, b) {
        var aIndex = visibleReverseCollectionIds.findIndex(function (reverseCollection) {
            return (a.schemaId && a.schemaId === reverseCollection.schemaId) ||
                (a.standardRelation && a.standardRelation === reverseCollection.standardRelation) ||
                (a.collectionComponent &&
                    a.collectionComponent === reverseCollection.collectionComponent);
        });
        var bIndex = visibleReverseCollectionIds.findIndex(function (reverseCollection) {
            return (b.schemaId && b.schemaId === reverseCollection.schemaId) ||
                (b.standardRelation && b.standardRelation === reverseCollection.standardRelation) ||
                (b.collectionComponent &&
                    b.collectionComponent === reverseCollection.collectionComponent);
        });
        if (aIndex === -1 && bIndex === -1) {
            return -1;
        }
        if (aIndex === -1 && bIndex >= 0) {
            return 1;
        }
        if (bIndex === -1 && aIndex >= 0) {
            return -1;
        }
        return aIndex - bIndex;
    });
};
export var getVisibleReverseCollections = function (reverseCollections, visibleReverseCollectionIds) {
    return reverseCollections.filter(function (reverseCollection) {
        return visibleReverseCollectionIds.find(function (_a) {
            var schemaId = _a.schemaId, standardRelation = _a.standardRelation, collectionComponent = _a.collectionComponent;
            return (schemaId && schemaId === reverseCollection.schemaId) ||
                (standardRelation && standardRelation === reverseCollection.standardRelation) ||
                (collectionComponent &&
                    collectionComponent === reverseCollection.collectionComponent);
        });
    });
};
var ReverseCollectionSettingsDropdown = function (_a) {
    var collectionId = _a.collectionId, allReverseCollections = _a.allReverseCollections;
    var _b = useRecoilState(detailModalVisibleReverseCollectionIds), visibleReverseCollectionIds = _b[0], setVisibleReverseCollectionIds = _b[1];
    var isInit = useRef(false);
    var updateCollectionSettings = usePageApi().updateCollectionSettings;
    useEffect(function () {
        if (!isInit.current) {
            setTimeout(function () { return (isInit.current = true); }, 1000);
            return;
        }
        updateCollectionSettings({
            collectionId: collectionId,
            settings: { reverseCollections: visibleReverseCollectionIds },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleReverseCollectionIds]);
    var allSortedReverseCollections = useMemo(function () { return sortAllReverseCollections(allReverseCollections, visibleReverseCollectionIds); }, [allReverseCollections, visibleReverseCollectionIds]);
    var visibleReverseCollections = useMemo(function () {
        return getVisibleReverseCollections(allSortedReverseCollections, visibleReverseCollectionIds);
    }, [allSortedReverseCollections, visibleReverseCollectionIds]);
    var onReverseCollectionVisibleToggle = useCallback(function (reverseCollectionIndex, isToggledOn) {
        if (isToggledOn) {
            //! Sort again since its out of order for some reason
            var sortedReverseCollections_1 = sortAllReverseCollections(allSortedReverseCollections, visibleReverseCollectionIds);
            setVisibleReverseCollectionIds(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                {
                    schemaId: sortedReverseCollections_1[reverseCollectionIndex].schemaId,
                    standardRelation: sortedReverseCollections_1[reverseCollectionIndex].standardRelation,
                    collectionComponent: sortedReverseCollections_1[reverseCollectionIndex].collectionComponent,
                },
            ], false); });
        }
        else {
            setVisibleReverseCollectionIds(function (prev) {
                var temp = cloneDeep(prev);
                temp.splice(reverseCollectionIndex, 1);
                return temp;
            });
        }
    }, [allSortedReverseCollections, setVisibleReverseCollectionIds, visibleReverseCollectionIds]);
    var onReverseCollectionDragEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination)
            return;
        var temp = cloneDeep(visibleReverseCollections.map(function (_a) {
            var schemaId = _a.schemaId, standardRelation = _a.standardRelation, collectionComponent = _a.collectionComponent;
            return {
                schemaId: schemaId,
                standardRelation: standardRelation,
                collectionComponent: collectionComponent,
            };
        }));
        var reorderedItem = temp.splice(source.index, 1)[0];
        temp.splice(destination.index, 0, reorderedItem);
        setVisibleReverseCollectionIds(temp);
    }, [setVisibleReverseCollectionIds, visibleReverseCollections]);
    var visibleReverseCollectionsContent = useMemo(function () {
        return (_jsx(DragDropContext, { onDragEnd: onReverseCollectionDragEnd, children: _jsx(Droppable, { droppableId: collectionId, children: function (provided) { return (_jsxs("div", __assign({ className: "flex w-full flex-col space-y-3 p-4" }, provided.droppableProps, { ref: provided.innerRef, children: [visibleReverseCollections.map(function (reverseCollection, reverseCollectionIndex) {
                            var _a, _b;
                            var reverseCollectionId = (_b = (_a = reverseCollection.schemaId) !== null && _a !== void 0 ? _a : reverseCollection.standardRelation) !== null && _b !== void 0 ? _b : reverseCollection.collectionComponent;
                            return (_jsx(Draggable, { draggableId: reverseCollectionId, index: reverseCollectionIndex, children: function (provided) {
                                    return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: reverseCollection.title, draggableProvided: provided, onToggleOffItem: function () {
                                            return onReverseCollectionVisibleToggle(reverseCollectionIndex, false);
                                        } }));
                                } }, reverseCollectionId));
                        }), provided.placeholder] }))); } }) }));
    }, [
        collectionId,
        onReverseCollectionDragEnd,
        onReverseCollectionVisibleToggle,
        visibleReverseCollections,
    ]);
    var allReverseCollectionsContent = useMemo(function () {
        return (_jsx("div", { className: "flex w-full flex-col", children: allSortedReverseCollections.map(function (reverseCollection, reverseCollectionIndex) {
                var _a, _b;
                var isVisible = visibleReverseCollections.includes(reverseCollection);
                var isLastVisible = isVisible &&
                    visibleReverseCollections.indexOf(reverseCollection) ===
                        visibleReverseCollections.length - 1;
                return (_jsx(ZiraVisibilityDropdownToggleItem, { title: reverseCollection.title, isVisible: isVisible, onVisibilityToggled: function (isToggledOn) {
                        return onReverseCollectionVisibleToggle(reverseCollectionIndex, isToggledOn);
                    }, showDivider: isLastVisible }, (_b = (_a = reverseCollection.schemaId) !== null && _a !== void 0 ? _a : reverseCollection.standardRelation) !== null && _b !== void 0 ? _b : reverseCollection.collectionComponent));
            }) }));
    }, [allSortedReverseCollections, onReverseCollectionVisibleToggle, visibleReverseCollections]);
    return (_jsx(ZiraVisibilityDropdown, { tabTitles: ['Visible Linked Data', 'All Linked Data'], tabContents: [visibleReverseCollectionsContent, allReverseCollectionsContent], placement: "bottomRight", children: _jsx(ZiraTooltip, { title: "Show/Hide Linked Data", children: _jsx("div", { className: "HeaderButton mb-1.5 flex w-8 items-center justify-center", children: _jsx("img", { height: 20, width: 20, src: SliderImgSrc }) }) }) }));
};
export default React.memo(ReverseCollectionSettingsDropdown);
