var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FileHubOutlinedSvg = function () { return (_jsxs("svg", { width: "16", height: "21", viewBox: "0 0 17 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.375 2C2.16789 2 2 2.16789 2 2.375V16.625C2 16.8321 2.16789 17 2.375 17H13.625C13.8321 17 14 16.8321 14 16.625V2.375C14 2.16789 13.8321 2 13.625 2H2.375ZM3.5 3.5V15.5H12.5V3.5H3.5Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.79199 5.35938C7.79199 5.54059 7.67307 5.6875 7.52637 5.6875H3.80762C3.66092 5.6875 3.54199 5.54059 3.54199 5.35938V4.70312C3.54199 4.52191 3.66092 4.375 3.80762 4.375H7.52637C7.67307 4.375 7.79199 4.52191 7.79199 4.70312V5.35938Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.13574 7.98438C5.13574 8.16559 5.01682 8.3125 4.87012 8.3125H3.80762C3.66092 8.3125 3.54199 8.16559 3.54199 7.98438V7.32812C3.54199 7.14691 3.66092 7 3.80762 7H4.87012C5.01682 7 5.13574 7.14691 5.13574 7.32812V7.98438Z", fill: "black" })] })); };
export var FileHubOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: FileHubOutlinedSvg }, props))); };
