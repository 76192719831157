var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import { formatMetricValue, getColumnWidthPercent, getMetricTypeUnit, getReportCellValue, } from 'src/services';
import { reportSelectedRecordsState } from 'src/state';
import { reportChartTypeState, reportSourceTypeSelector, reportPlottedMetricsState, } from 'src/state';
import { ReportChartTypeDisplay } from '.';
var ReportChart = function (_a) {
    var reportId = _a.reportId, rowData = _a.rowData, dateColumns = _a.dateColumns, metricColumns = _a.metricColumns;
    var chartType = useRecoilValue(reportChartTypeState(reportId));
    var sourceType = useRecoilValue(reportSourceTypeSelector(reportId));
    var plottedMetrics = useRecoilValue(reportPlottedMetricsState(reportId));
    var plottedMetricList = useMemo(function () {
        return metricColumns.filter(function (metric) { var _a, _b; return plottedMetrics.includes((_b = (_a = metric.metricType) !== null && _a !== void 0 ? _a : metric.schemaId) !== null && _b !== void 0 ? _b : metric.formula); });
    }, [metricColumns, plottedMetrics]);
    var selectedRecords = useRecoilValue(reportSelectedRecordsState(reportId));
    var generateSeries = useCallback(function () {
        if (!(plottedMetricList === null || plottedMetricList === void 0 ? void 0 : plottedMetricList.length))
            return [];
        var series = [];
        var _loop_1 = function (rid) {
            var selectedRow = rowData.find(function (row) { return row[sourceType].rid === rid; });
            if (selectedRow) {
                var rowSeries = [];
                var _loop_2 = function (plottedMetric) {
                    var metricData = Object.keys(selectedRow).reduce(function (acc, key) {
                        var _a, _b;
                        //! If a formula is a substring of another formula, charting will glitch out
                        if (key.includes((_b = (_a = plottedMetric.metricType) !== null && _a !== void 0 ? _a : plottedMetric.schemaId) !== null && _b !== void 0 ? _b : plottedMetric.formula)) {
                            acc.push(getReportCellValue(selectedRow[key]));
                        }
                        return acc;
                    }, []);
                    var label = selectedRecords.length > 1
                        ? "".concat(selectedRow[sourceType].title, " - ").concat(plottedMetric.label)
                        : plottedMetric.label;
                    var nextSeries = [{ name: label, data: metricData }];
                    rowSeries = __spreadArray(__spreadArray([], rowSeries, true), nextSeries, true);
                };
                for (var _a = 0, plottedMetricList_1 = plottedMetricList; _a < plottedMetricList_1.length; _a++) {
                    var plottedMetric = plottedMetricList_1[_a];
                    _loop_2(plottedMetric);
                }
                series = __spreadArray(__spreadArray([], series, true), rowSeries, true);
            }
        };
        for (var _i = 0, selectedRecords_1 = selectedRecords; _i < selectedRecords_1.length; _i++) {
            var rid = selectedRecords_1[_i];
            _loop_1(rid);
        }
        return series;
    }, [plottedMetricList, selectedRecords, rowData, sourceType]);
    var chartTitle = useMemo(function () {
        var title = '';
        selectedRecords.forEach(function (rid, idx) {
            var _a;
            var newText = (_a = rowData.find(function (row) { return row[sourceType].rid === rid; })) === null || _a === void 0 ? void 0 : _a[sourceType].title;
            if (newText) {
                if (idx) {
                    title += ' | ';
                }
                title += newText;
            }
        });
        return title;
    }, [rowData, selectedRecords, sourceType]);
    var yAxes = useMemo(function () {
        var yAxes = [];
        plottedMetricList.forEach(function (plottedMetric, idx) {
            yAxes.push({
                opposite: !!(idx % 2),
                title: {
                    text: plottedMetric.metricType
                        ? "".concat(plottedMetric.label, " (").concat(getMetricTypeUnit(plottedMetric.type), ")")
                        : "".concat(plottedMetric.label),
                },
                labels: {
                    formatter: function (value) {
                        return plottedMetric.metricType
                            ? formatMetricValue(value, plottedMetric.type)
                            : "".concat(value);
                    },
                },
            });
        });
        return yAxes;
    }, [plottedMetricList]);
    return (_jsx("div", { className: "bg-white pt-4 shadow-block", children: _jsx(Chart, { options: {
                chart: {
                    id: reportId,
                },
                noData: {
                    text: selectedRecords.length
                        ? 'Select a report metric to plot'
                        : 'Click/Select a row to view metrics',
                },
                xaxis: {
                    categories: dateColumns[0] !== 'Metrics' ? dateColumns : [],
                    type: 'category',
                },
                yaxis: yAxes,
                colors: [
                    '#8476D6',
                    '#2F4DDF',
                    '#167EE4',
                    '#00A76F',
                    '#FFC047',
                    '#FD7238',
                    '#FF4A43',
                ],
                fill: {
                    opacity: 1,
                },
                title: {
                    text: chartTitle,
                    align: 'center',
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        columnWidth: getColumnWidthPercent(dateColumns.length) + '%',
                    },
                },
            }, series: generateSeries(), type: ReportChartTypeDisplay[chartType], height: 350 }, "".concat(reportId, "-").concat(chartType)) }));
};
export default React.memo(ReportChart);
