var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { selector, selectorFamily } from 'recoil';
import { isNaviTreeDataItem, } from 'src/hooks/api/interfaces/Interface';
import { generateTreeNode } from 'src/services';
import { accountDetailState, tableCollectionViewsState, tableSelectedViewIdState, naviTreeStructureState, } from '../atoms';
var naviTreeStructureStateSelector = selector({
    key: 'naviTreeStructureStateSelector',
    set: function (_a, newValue) {
        var set = _a.set;
        set(naviTreeStructureState, newValue);
    },
    get: function (_a) {
        var get = _a.get;
        var naviTreeStructure = get(naviTreeStructureState);
        if (naviTreeStructure) {
            return naviTreeStructure;
        }
        // init when no data of current state
        var accountDetailsData = get(accountDetailState);
        if (!accountDetailsData)
            return;
        return generateTreeNode(accountDetailsData);
    },
});
var naviTreeItemFamilySelector = selectorFamily({
    key: 'naviTreeItemFamilySelector',
    get: function (id) {
        return function (_a) {
            var get = _a.get;
            var naviTreeStructure = get(naviTreeStructureStateSelector);
            if (!naviTreeStructure)
                return;
            var naviTreeItem = naviTreeStructure.items[id];
            if (!naviTreeItem || !isNaviTreeDataItem(naviTreeItem))
                return;
            return naviTreeItem;
        };
    },
});
var naviTreeNodeKeyPathsSelector = selectorFamily({
    key: 'naviTreeItemFamilySelector',
    get: function (id) {
        return function (_a) {
            var get = _a.get;
            var naviTreeStructure = get(naviTreeStructureStateSelector);
            if (!naviTreeStructure)
                return;
            var naviTreeItem = naviTreeStructure.items[id];
            if (!naviTreeItem || !isNaviTreeDataItem(naviTreeItem))
                return;
            if (!naviTreeItem.data)
                return;
            return naviTreeItem.data.nodeKeyPaths;
        };
    },
});
var pageGroupItemsSelector = selector({
    key: 'pageGroupsSelector',
    get: function (_a) {
        var get = _a.get;
        var naviTreeStructure = get(naviTreeStructureStateSelector);
        if (!naviTreeStructure)
            return [];
        var groupItems = Object.values(naviTreeStructure.items).filter(function (item) { return isNaviTreeDataItem(item) && item.data.type === 'pageGroup'; });
        return groupItems;
    },
});
var pageItemsSelector = selector({
    key: 'pageItemsSelector',
    get: function (_a) {
        var get = _a.get;
        var naviTreeStructure = get(naviTreeStructureStateSelector);
        if (!naviTreeStructure)
            return [];
        var pageItems = Object.values(naviTreeStructure.items).filter(function (item) { return isNaviTreeDataItem(item) && item.data.type === 'page'; });
        return pageItems;
    },
});
var pageItemSelector = selectorFamily({
    key: 'pageItemSelector',
    get: function (pageId) {
        return function (_a) {
            var get = _a.get;
            var pageItems = get(pageItemsSelector);
            return pageItems.find(function (page) { return page.id === pageId; });
        };
    },
});
var blockItemsSelector = selector({
    key: 'blockItemsSelector',
    get: function (_a) {
        var get = _a.get;
        var naviTreeStructure = get(naviTreeStructureStateSelector);
        if (!naviTreeStructure)
            return [];
        var blockItems = Object.values(naviTreeStructure.items).filter(function (item) { return isNaviTreeDataItem(item) && item.data.type === 'block'; });
        return blockItems;
    },
});
var blockItemSelector = selectorFamily({
    key: 'blockItemSelector',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var blockItems = get(blockItemsSelector);
            return blockItems.find(function (item) { return item.id === blockId; });
        };
    },
});
var blockContentTypeSelector = selectorFamily({
    key: 'blockContentTypeSelector',
    get: function (blockId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var blockItem = get(blockItemSelector(blockId));
            return (_b = blockItem === null || blockItem === void 0 ? void 0 : blockItem.data.contentType) !== null && _b !== void 0 ? _b : '';
        };
    },
});
var blockTabsSelector = selectorFamily({
    key: 'blockTabsSelector',
    get: function (pageId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var blockItems = get(blockItemsSelector);
            var blockIds = (_b = get(pageItemSelector(pageId))) === null || _b === void 0 ? void 0 : _b.children;
            if (!blockItems.length || !(blockIds === null || blockIds === void 0 ? void 0 : blockIds.length))
                return [];
            var blockTabs = blockIds.reduce(function (acc, id) {
                var _a, _b;
                var foundBlockItem = blockItems.find(function (item) { return item.id === id; });
                if (foundBlockItem === null || foundBlockItem === void 0 ? void 0 : foundBlockItem.data.name) {
                    var blockId = foundBlockItem.id;
                    var blockTabName = foundBlockItem === null || foundBlockItem === void 0 ? void 0 : foundBlockItem.data.name;
                    var isCollection = foundBlockItem.data.contentType === 'collection';
                    if (isCollection) {
                        blockTabName = 'Table';
                        // Find block view name
                        var currentBlockViewId_1 = get(tableSelectedViewIdState(blockId));
                        var orderedViews = get(tableCollectionViewsState(blockId)).orderedViews;
                        if (orderedViews.length) {
                            blockTabName = currentBlockViewId_1
                                ? ((_b = (_a = orderedViews.find(function (view) { return view.viewId === currentBlockViewId_1; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : orderedViews[0].name)
                                : orderedViews[0].name;
                        }
                    }
                    return __spreadArray(__spreadArray([], acc, true), [{ id: foundBlockItem.id, name: blockTabName }], false);
                }
                return acc;
            }, []);
            return blockTabs;
        };
    },
});
export { naviTreeStructureStateSelector, naviTreeItemFamilySelector, naviTreeNodeKeyPathsSelector, pageGroupItemsSelector, pageItemsSelector, pageItemSelector, blockItemsSelector, blockItemSelector, blockContentTypeSelector, blockTabsSelector, };
