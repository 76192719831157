var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEqual } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { collectionTableState, collectionTableSchemaMutationsState, collectionTableRecordState, } from 'src/state';
import { useAccountApi } from 'src/hooks/api';
import { NEW_TASK_TEMPLATE_ID } from '../constant';
export var useTaskProfileDataSchemas = function (_a) {
    var selectedTaskId = _a.selectedTaskId, collectionId = _a.collectionId, schemaId = _a.schemaId, collectionInfo = _a.collectionInfo, dataSource = _a.dataSource, cells = _a.cells, taskSchemas = _a.taskSchemas, onUpdateCells = _a.onUpdateCells, onUpdateTaskSchemas = _a.onUpdateTaskSchemas;
    var isCreatingNew = selectedTaskId === NEW_TASK_TEMPLATE_ID;
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var setCollectionTableData = useSetRecoilState(collectionTableState(dataSource));
    //! Profile state management
    var setSchemasToMutate = useSetRecoilState(collectionTableSchemaMutationsState(dataSource));
    var recordData = useRecoilValue(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: selectedTaskId })));
    var _b = useGetSchemaColumnsInCollection({
        collectionId: collectionId !== null && collectionId !== void 0 ? collectionId : '',
        options: {
            enabled: !!collectionId,
        },
    }), schemas = _b.data, schemasFetched = _b.isFetched;
    var _c = useState(false), isInitialized = _c[0], setIsInitialized = _c[1];
    useEffect(function () {
        setIsInitialized(false);
    }, [selectedTaskId]);
    //! Initialize records & schema mutations state
    useEffect(function () {
        if (isInitialized ||
            !selectedTaskId ||
            !collectionId ||
            !(schemas === null || schemas === void 0 ? void 0 : schemas.columns) ||
            !collectionInfo) {
            return;
        }
        var initializeState = function () {
            var _a, _b;
            var cellData = {};
            if (isCreatingNew) {
                var taskModalRecord = recordData !== null && recordData !== void 0 ? recordData : {};
                cellData = (_a = {}, _a[selectedTaskId] = taskModalRecord, _a);
            }
            else {
                var templateModalRecord = cells !== null && cells !== void 0 ? cells : {};
                cellData = (_b = {},
                    _b[selectedTaskId] = templateModalRecord,
                    _b);
            }
            setCollectionTableData({
                collectionInfo: collectionInfo,
                columns: schemas.columns,
                rows: [],
                cells: cellData,
                nonSchemaNativeFieldsMap: {},
                permissions: { canEditCollection: false, canEditSchemas: false },
            });
            setSchemasToMutate(taskSchemas === null || taskSchemas === void 0 ? void 0 : taskSchemas.filter(function (schema) { return schema.defaultValue; }).map(function (schema) { return schema.defaultValue; }));
            setIsInitialized(true);
        };
        initializeState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, selectedTaskId, collectionId, schemas, collectionInfo]);
    //! Reset state on unmount
    useEffect(function () {
        return function () {
            setCollectionTableData(undefined);
            setSchemasToMutate([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //! Reset state on reverse collection change
    useEffect(function () {
        if (isInitialized) {
            setIsInitialized(false);
            setCollectionTableData(undefined);
            setSchemasToMutate([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId, schemaId]);
    //! Update profile data when recordData changes
    useEffect(function () {
        var _a;
        if (!isEqual(recordData, cells)) {
            onUpdateCells({ cells: (_a = recordData !== null && recordData !== void 0 ? recordData : cells) !== null && _a !== void 0 ? _a : {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordData]);
    var onUpdateTaskSchemaType = useCallback(function (schemaId, type) {
        onUpdateTaskSchemas({
            taskSchemas: taskSchemas.map(function (schema) {
                return schema.schemaId === schemaId ? __assign(__assign({}, schema), { taskSchemaType: type }) : schema;
            }),
        });
    }, [onUpdateTaskSchemas, taskSchemas]);
    var onRemoveTaskSchema = useCallback(function (schemaId) {
        onUpdateTaskSchemas({
            taskSchemas: taskSchemas === null || taskSchemas === void 0 ? void 0 : taskSchemas.filter(function (schema) { return schema.schemaId !== schemaId; }),
        });
    }, [onUpdateTaskSchemas, taskSchemas]);
    var reorder = useCallback(function (list, startIndex, endIndex) {
        var result = Array.from(list);
        var removed = result.splice(startIndex, 1)[0];
        result.splice(endIndex, 0, removed);
        return result;
    }, []);
    var onDragTaskSchemaEnd = useCallback(function (_a) {
        var source = _a.source, destination = _a.destination;
        if (!destination) {
            return;
        }
        onUpdateTaskSchemas({
            taskSchemas: reorder(taskSchemas, source.index, destination.index),
        });
    }, [onUpdateTaskSchemas, reorder, taskSchemas]);
    return {
        schemas: schemas,
        schemasFetched: schemasFetched,
        recordData: recordData,
        onUpdateTaskSchemaType: onUpdateTaskSchemaType,
        onRemoveTaskSchema: onRemoveTaskSchema,
        onDragTaskSchemaEnd: onDragTaskSchemaEnd,
    };
};
