var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { collectionTableNonSchemaMutationsState, scheduleShiftOpenCountState, } from 'src/state';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { uniqBy } from 'lodash';
import { NonSchemaNativePath } from 'src/shared';
import RecordDetailMetricWrapper from '../../Metrics/RecordDetailMetricWrapper';
var ShiftDetailOpenCount = function (_a) {
    var recordId = _a.recordId, dataSource = _a.dataSource, canEdit = _a.canEdit;
    var _b = useRecoilState(scheduleShiftOpenCountState(__assign(__assign({}, dataSource), { recordId: recordId }))), openCount = _b[0], setOpenCount = _b[1];
    var originalOpenCount = useState(openCount)[0];
    var resetOpenCountChanges = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            setOpenCount(originalOpenCount);
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return prev.filter(function (mutation) { return mutation.schema !== NonSchemaNativePath.SHIFT_OPEN_COUNT; });
            });
        };
    }, [dataSource, originalOpenCount, setOpenCount]);
    //! Reset open count changes when there is an assignee
    useEffect(function () {
        return function () {
            resetOpenCountChanges();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onOpenCountChange = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (newOpenCount) {
            if (newOpenCount > 0) {
                setOpenCount(newOpenCount);
                // Update non-schema mutations state
                set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                    return uniqBy(__spreadArray([
                        {
                            schema: NonSchemaNativePath.SHIFT_OPEN_COUNT,
                            integer: newOpenCount,
                        }
                    ], prev, true), 'schema');
                });
            }
        };
    }, [dataSource, setOpenCount]);
    if (!canEdit)
        return (_jsx(RecordDetailMetricWrapper, { title: "Count", content: _jsx("div", { className: "w-[133px]", children: openCount }) }));
    return (_jsx(RecordDetailMetricWrapper, { title: "Count", content: canEdit ? (_jsx("div", { className: "flex flex-col", children: _jsx(Input, { className: "w-[133px]", type: "number", value: openCount, onChange: function (e) { return onOpenCountChange(parseInt(e.target.value)); }, min: 1, onFocus: function (e) { return e.target.select(); } }) })) : (_jsx("div", { className: "w-[133px]", children: openCount })) }));
};
export default React.memo(ShiftDetailOpenCount);
