import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined } from '@ant-design/icons';
import { Select, Tag } from 'antd';
import { find } from 'lodash';
var Option = Select.Option, OptGroup = Select.OptGroup;
import React, { useCallback, useState } from 'react';
import { useAccountApi } from 'src/hooks/api';
import './MessageCard.scss';
var AutomationFilteredTarget = function (_a) {
    var _b, _c;
    var filters = _a.filters, onChange = _a.onChange, roleSchemaId = _a.roleSchemaId, locationSchemaId = _a.locationSchemaId;
    var _d = useState((_b = filters.locations) !== null && _b !== void 0 ? _b : []), locationIds = _d[0], setLocationIds = _d[1];
    var _e = useState((_c = filters.roles) !== null && _c !== void 0 ? _c : []), roleIds = _e[0], setRoleIds = _e[1];
    var _f = useState(false), locationSelectOpen = _f[0], setLocationSelectOpen = _f[1];
    var _g = useState(false), roleSelectOpen = _g[0], setRoleSelectOpen = _g[1];
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var roleOptions = useGetUniversalSimpleList({
        body: {
            schemaId: roleSchemaId,
        },
        options: {
            enabled: !!roleSchemaId.length,
        },
    }).data;
    var locationOptions = useGetUniversalSimpleList({
        body: {
            schemaId: locationSchemaId,
        },
        options: {
            enabled: !!locationSchemaId.length,
        },
    }).data;
    var onUpdateLocationIds = useCallback(function (newValues) {
        setLocationIds(newValues);
        onChange({ locations: newValues, roles: roleIds });
    }, [onChange, roleIds]);
    var onUpdateRolesIds = useCallback(function (newValues) {
        setRoleIds(newValues);
        onChange({ locations: locationIds, roles: newValues });
    }, [locationIds, onChange]);
    var onRemoveLocationTag = useCallback(function (id) {
        var newLocations = locationIds.filter(function (locationId) { return locationId !== id; });
        setLocationIds(newLocations);
        onChange({ locations: newLocations, roles: roleIds });
    }, [locationIds, onChange, roleIds]);
    var onRemoveRoleTag = useCallback(function (id) {
        var newRoles = roleIds.filter(function (locationId) { return locationId !== id; });
        setRoleIds(newRoles);
        onChange({ locations: locationIds, roles: newRoles });
    }, [locationIds, onChange, roleIds]);
    var locationTagRender = useCallback(function (props) {
        var value = props.value;
        var foundOption = find(locationOptions, function (option) { return option.id === value; });
        var tagColor = foundOption === null || foundOption === void 0 ? void 0 : foundOption.color;
        var tagText = foundOption === null || foundOption === void 0 ? void 0 : foundOption.name;
        return (_jsx(Tag, { className: "SchemaOptionTag", color: tagColor, closeIcon: _jsx(CloseOutlined, { className: "CloseIcon" }), closable: locationSelectOpen, onClose: function () { return onRemoveLocationTag(value); }, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, children: tagText }));
    }, [locationOptions, locationSelectOpen, onRemoveLocationTag]);
    var roleTagRender = useCallback(function (props) {
        var value = props.value;
        var foundOption = find(roleOptions, function (option) { return option.id === value; });
        var tagColor = foundOption === null || foundOption === void 0 ? void 0 : foundOption.color;
        var tagText = foundOption === null || foundOption === void 0 ? void 0 : foundOption.name;
        return (_jsx(Tag, { className: "SchemaOptionTag", color: tagColor, closeIcon: _jsx(CloseOutlined, { className: "CloseIcon" }), closable: roleSelectOpen, onClose: function () { return onRemoveRoleTag(value); }, onMouseDown: function (e) {
                e.preventDefault();
                e.stopPropagation();
            }, children: tagText }));
    }, [onRemoveRoleTag, roleOptions, roleSelectOpen]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "ml-0.5", children: "Filter by Locations:" }), _jsx(Select, { className: "FilterTargetSelect", mode: "multiple", tagRender: locationTagRender, value: filters.locations, onChange: function (values) { return onUpdateLocationIds(values); }, menuItemSelectedIcon: _jsx(_Fragment, {}), open: locationSelectOpen, onDropdownVisibleChange: setLocationSelectOpen, showSearch: true, placeholder: "Filter by locations", children: _jsx(OptGroup, { label: "Locations", children: locationOptions === null || locationOptions === void 0 ? void 0 : locationOptions.map(function (option) {
                        return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("div", { className: "SchemaOptionWrapper", children: _jsx("span", { className: "SchemaOption", style: {
                                        backgroundColor: option.color,
                                    }, children: option.name }) }) }, option.id));
                    }) }, "Locations") }), _jsx("div", { className: "ml-0.5 mt-1", children: "Filter by Roles:" }), _jsx(Select, { className: "FilterTargetSelect", mode: "multiple", tagRender: roleTagRender, value: filters.roles, onChange: function (values) { return onUpdateRolesIds(values); }, menuItemSelectedIcon: _jsx(_Fragment, {}), open: roleSelectOpen, onDropdownVisibleChange: setRoleSelectOpen, showSearch: true, placeholder: "Filter by roles", children: _jsx(OptGroup, { label: "Roles", children: roleOptions === null || roleOptions === void 0 ? void 0 : roleOptions.map(function (option) {
                        return (_jsx(Option, { value: option.id, label: option.name, children: _jsx("div", { className: "SchemaOptionWrapper", children: _jsx("span", { className: "SchemaOption", style: {
                                        backgroundColor: option.color,
                                    }, children: option.name }) }) }, option.id));
                    }) }, "Roles") })] }));
};
export default React.memo(AutomationFilteredTarget);
