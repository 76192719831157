import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { CollectionPermissionLevel, CollectionPermissionLevelOptions } from '../Constants';
import ObjectPermissionContent from './ObjectPermissionContent';
var CollectionPermissionContent = function (_a) {
    var onChange = _a.onChange, currentPermission = _a.currentPermission;
    var options = Object.values(CollectionPermissionLevel).map(function (key) { return ({
        id: key,
        title: CollectionPermissionLevelOptions(key),
    }); });
    return (_jsx(ObjectPermissionContent, { title: "Collection Functions", options: options, onChange: function (id) { return onChange(id); }, chosenPermission: currentPermission }));
};
export default React.memo(CollectionPermissionContent);
