import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import ContactEmailModal from './ContactEmailModal';
import ExpertContactItem from './ExpertContactItem';
var EXPERTS = [
    {
        name: 'Patrick Shunney',
        title: 'Account Executive',
        email: 'patrick@teambridge.com',
    },
];
var SUPPORT_CONTACT_INFO = { name: 'TeamBridge Support', title: 'Support Team' };
var ExpertTeamContactWidget = function () {
    var _a;
    var _b = useState(), emailContactModalInfo = _b[0], setEmailContactModalInfo = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ExpertTeamContactWidgetContainer w-full rounded-[15px] bg-white shadow-block", children: [_jsx("div", { className: "mb-5 flex items-center text-xl font-medium", children: "Need Help?" }), (_a = []) !== null && _a !== void 0 ? _a : EXPERTS.map(function (expert) { return (_jsx(ExpertContactItem, { info: expert, setEmailContactModalInfo: function () { return setEmailContactModalInfo(expert); } }, expert.email)); }), _jsx(ExpertContactItem, { info: SUPPORT_CONTACT_INFO })] }), _jsx(ContactEmailModal, { contactInfo: emailContactModalInfo, onClose: function () { return setEmailContactModalInfo(undefined); } })] }));
};
export default React.memo(ExpertTeamContactWidget);
