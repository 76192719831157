import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider, Input } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { usePageApi } from 'src/hooks/api';
import './CollectionEntry.scss';
import HardConfirmWrapper from 'src/components/HardConfirmWrapper/HardConfirmWrapper';
var CollectionEntry = function (_a) {
    var item = _a.item, onActionSuccess = _a.onActionSuccess, isRenamning = _a.isRenamning, onSetRenameEntry = _a.onSetRenameEntry;
    var _b = usePageApi(), useDeleteCollection = _b.useDeleteCollection, useUpdateCollection = _b.useUpdateCollection;
    var inputRef = useRef(null);
    var _c = useState(isRenamning), isLocalRename = _c[0], setIsLocalRename = _c[1];
    var _d = useState(item.name), entryName = _d[0], setEntryName = _d[1];
    useEffect(function () {
        setEntryName(item.name);
    }, [item.name]);
    useEffect(function () {
        setIsLocalRename(isRenamning);
    }, [isRenamning]);
    var isDeletable = item.type === 'custom';
    var isEditable = item.isEditable;
    var toggleIsRenaming = useCallback(function (on) {
        if (on) {
            onSetRenameEntry(item.id);
        }
        setIsLocalRename(on);
    }, [item.id, onSetRenameEntry]);
    var onRenameCollection = useCallback(function () {
        var _a, _b;
        var newName = (_b = (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.input) === null || _b === void 0 ? void 0 : _b.value;
        if (!newName) {
            toast('Please enter a name', { type: 'error' });
            return;
        }
        useUpdateCollection.mutate({
            collectionId: item.id,
            name: newName,
        }, {
            onSuccess: function () {
                setIsLocalRename(false);
                setEntryName(newName);
            },
        });
    }, [item.id, useUpdateCollection]);
    var onDeleteCollection = useCallback(function () {
        useDeleteCollection.mutate(item.id, {
            onSuccess: function () { return onActionSuccess === null || onActionSuccess === void 0 ? void 0 : onActionSuccess(); },
        });
    }, [item.id, onActionSuccess, useDeleteCollection]);
    var nameInput = useMemo(function () { return (_jsx(Input, { className: "CollectionNameInput", ref: inputRef, defaultValue: entryName, onPressEnter: onRenameCollection })); }, [entryName, onRenameCollection]);
    var renameButton = useMemo(function () { return (_jsxs(Button, { onClick: function () { return toggleIsRenaming(true); }, className: "CustomEntryButton EditButton", children: [_jsx(EditOutlined, {}), " Rename"] })); }, [toggleIsRenaming]);
    var actionButton = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsxs(Button, { onClick: onRenameCollection, children: [' ', _jsx(CheckOutlined, { style: { color: '#00A76F' } }), ' '] }), _jsx(Button, { onClick: function () { return toggleIsRenaming(false); }, children: _jsx(CloseOutlined, { style: { color: '#FF4A43' } }) })] })); }, [onRenameCollection, toggleIsRenaming]);
    var deleteButton = useMemo(function () { return (_jsx(HardConfirmWrapper, { changeType: "delete", entityName: "collection", explanationText: "This action will delete all referencing blocks.", onConfirm: onDeleteCollection, children: _jsxs(Button, { className: "CustomEntryButton DeleteButton", children: [_jsx(DeleteOutlined, {}), "Delete"] }) })); }, [onDeleteCollection]);
    var columnString = useMemo(function () {
        return item.columns
            .map(function (column) {
            return column;
        })
            .join(', ');
    }, [item.columns]);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "CollectionEntry", children: [_jsxs("div", { className: "MainRow", children: [isLocalRename ? nameInput : _jsx(_Fragment, { children: entryName }), _jsx("div", { className: "FlexGrow" }), !isEditable ? (_jsx("span", { className: "ViewOnlyLabel", children: "View Only" })) : (_jsxs(_Fragment, { children: [isLocalRename ? actionButton : renameButton, isDeletable && !isLocalRename && deleteButton] }))] }), _jsx("div", { className: "ColumnRow", children: columnString }), _jsx(Divider, {})] }) }));
};
export default React.memo(CollectionEntry);
