var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TableSortIconSvg = function () { return (_jsxs("svg", { width: "15", height: "15", viewBox: "1 1 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M6.78517 6.30201C6.67127 6.41591 6.4866 6.41591 6.3727 6.30201L5.09994 5.02925L5.09994 11.2543C5.09994 11.4154 4.96935 11.5459 4.80827 11.5459H4.22494C4.06385 11.5459 3.93327 11.4154 3.93327 11.2543V5.02912L2.66039 6.30201C2.54648 6.41591 2.36181 6.41591 2.24791 6.30201L1.83543 5.88953C1.72152 5.77562 1.72152 5.59095 1.83543 5.47705L4.3103 3.00217C4.40997 2.90251 4.56381 2.89005 4.67702 2.9648C4.6932 2.97548 4.70854 2.98794 4.72278 3.00218L7.19765 5.47705C7.31156 5.59095 7.31156 5.77562 7.19765 5.88953L6.78517 6.30201Z", fill: "black" }), _jsx("path", { d: "M7.62328 8.22736C7.50937 8.11345 7.3247 8.11345 7.2108 8.22736L6.79832 8.63983C6.68441 8.75374 6.68441 8.93841 6.79832 9.05231L9.27319 11.5272C9.32971 11.5837 9.40366 11.6122 9.47775 11.6126C9.55299 11.6131 9.62839 11.5846 9.6858 11.5272L12.1607 9.05231C12.2746 8.93841 12.2746 8.75374 12.1607 8.63983L11.7482 8.22736C11.6343 8.11345 11.4496 8.11345 11.3357 8.22736L10.0628 9.50024L10.0628 3.22941C10.0628 3.06833 9.93224 2.93774 9.77116 2.93774L9.18783 2.93774C9.02675 2.93774 8.89616 3.06833 8.89616 3.22941L8.89616 9.50024L7.62328 8.22736Z", fill: "black" })] })); };
export var TableSortIcon = function (props) { return (_jsx(Icon, __assign({ component: TableSortIconSvg }, props))); };
