var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Button, Divider } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState } from 'recoil';
import { ValueDataType } from 'src/shared';
import FilterEntry from 'src/components/OptimizedTableView/OptionHeader/Filter/FilterEntry';
import { ROLLUP_MODAL_Z_INDEX, RollupConfigurationContext, } from 'src/components/RollupConfigurationModal/RollupConfigurationModal';
import { collectionTableFilterPropertiesState } from 'src/state';
import { usePageApi } from 'src/hooks/api';
import { BlackPlusIcon } from 'src/utils/icons/BlackPlusIcon';
var RollupAddFilter = function (_a) {
    var _b;
    var type = _a.type;
    //get columns?
    var _c = useContext(RollupConfigurationContext), rollupConfiguration = _c.rollupConfiguration, setNumeratorFilters = _c.setRollupFilter, setDenominatorFilter = _c.setDenominatorFilter;
    var refCollectionId = rollupConfiguration.refCollectionId, refSchemaId = rollupConfiguration.refSchemaId, numeratorSettings = rollupConfiguration.filterSettings, denominatorFilterSettings = rollupConfiguration.denominatorFilterSettings;
    var _d = useRecoilState(collectionTableFilterPropertiesState(refCollectionId)), setFilterProperties = _d[1];
    var setRollupFilter = type === 'numerator' ? setNumeratorFilters : setDenominatorFilter;
    var filterSettings = type === 'numerator' ? numeratorSettings : denominatorFilterSettings;
    var usePageFilters = usePageApi().usePageFilters;
    var _e = usePageFilters({
        body: {
            collectionId: refCollectionId,
            collectionViewId: null,
        },
        options: {
            enabled: !!refCollectionId,
            onSuccess: function (pageFilterData) {
                if (pageFilterData) {
                    setFilterProperties(function (prev) { return ({
                        propertyNames: __assign(__assign({}, prev.propertyNames), pageFilterData.propertyNames),
                        propertyColors: __assign(__assign({}, prev.propertyColors), pageFilterData.propertyColors),
                    }); });
                }
            },
        },
    }), filterData = _e.data, isLoading = _e.isLoading;
    var columns = (_b = filterData === null || filterData === void 0 ? void 0 : filterData.schemas) !== null && _b !== void 0 ? _b : {};
    if (!refCollectionId || !refSchemaId) {
        return _jsx("div", { children: "Please select a collection and schema for filters" });
    }
    return (_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx("div", { className: "flex flex-col space-y-2", children: !isLoading &&
                    columns &&
                    (filterSettings === null || filterSettings === void 0 ? void 0 : filterSettings.map(function (filter, index) {
                        return (_jsxs(_Fragment, { children: [_jsx(FilterEntry, { existingFilter: filter, columns: columns, onDeleteEntry: function () {
                                        var newFilters = filterSettings
                                            ? filterSettings.slice()
                                            : [];
                                        newFilters.splice(index, 1);
                                        setRollupFilter(newFilters);
                                    }, onEditEntry: function (newFilter) {
                                        var newFilters = (filterSettings || [])
                                            .slice()
                                            .map(function (f, i) { return (i === index ? newFilter : f); });
                                        setRollupFilter(newFilters);
                                    }, options: {
                                        className: 'p-0 shadow-none w-full',
                                        autoFocusOnDropdown: false,
                                        showCurrentUserFilter: true,
                                        popoverZIndex: ROLLUP_MODAL_Z_INDEX + 1,
                                    } }, "filter-".concat(index)), index !== filterSettings.length - 1 && _jsx(Divider, {})] }));
                    })) }), _jsx("div", { children: _jsx(Button, { icon: _jsx(BlackPlusIcon, {}), className: "AddTableFilterButton", type: "primary", onClick: function () {
                        if (!Object.keys(columns).length) {
                            return;
                        }
                        var defaultColumnKey = Object.keys(columns)[0];
                        var defaultChosenColumn = columns[defaultColumnKey];
                        var columnValues = Object.values(columns);
                        var newFilterSetting = {
                            id: uuidv4(),
                            property: defaultColumnKey,
                            clauseType: columnValues.length
                                ? columnValues[0].type
                                : ValueDataType.TEXT,
                            clauseValue: defaultChosenColumn.modifiers[0].value,
                            filterValue: '',
                        };
                        setRollupFilter(__spreadArray(__spreadArray([], (filterSettings || []), true), [newFilterSetting], false));
                    }, children: "Add Filter" }) })] }));
};
export default RollupAddFilter;
