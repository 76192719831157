import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import { useState } from 'react';
import PaymentRulesTable from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/PaymentRulesTable';
import usePayRateV2Api from '../usePayRateV2Api';
import PaymentRulesModalWrapper from './component/PaymentRulesModal/PaymentRulesModalWrapper';
var PaymentRulesTab = function () {
    var useGetPayRulesForAccount = usePayRateV2Api().useGetPayRulesForAccount;
    var _a = useGetPayRulesForAccount(), data = _a.data, isLoading = _a.isLoading;
    var _b = useState(false), isCreateModalOpen = _b[0], setIsCreateModalOpen = _b[1];
    var _c = useState(undefined), editPayrules = _c[0], setEditPayrules = _c[1];
    return (_jsxs("div", { className: "mt-5", children: [_jsx("button", { className: "font-medium cursor-pointer p-2 rounded-full text-white bg-black w-[150px] text-center mb-8", onClick: function () {
                    setIsCreateModalOpen(true);
                }, children: "Create New Rule" }), isLoading ? (_jsx(Skeleton, {})) : (_jsx(PaymentRulesTable, { payrules: data, editPayruleModal: function (editPayRules) {
                    setEditPayrules(editPayRules);
                    setIsCreateModalOpen(true);
                } })), isCreateModalOpen && (_jsx(PaymentRulesModalWrapper, { editPayRules: editPayrules, onClose: function () {
                    setEditPayrules(undefined);
                    setIsCreateModalOpen(false);
                } }))] }));
};
export default PaymentRulesTab;
