import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ObjType } from 'src/shared';
import { useSetting } from 'src/hooks/api';
import { accountAccessGroupDetailsState } from 'src/state';
import { CollectionPermissionLevel, CollectionPermissionLevelOptions, PagePermissionLevel, PagePermissionLevelOptions, SchemaPermissionLevel, SchemaPermissionLevelOptions, } from '../Constants';
import CollectionPermissionContent from './CollectionPermissionContent';
import ObjectPermissionDropdown from './ObjectPermissionDropdown';
import PagePermissionContent from './PagePermissionContent';
import SchemaPermissionContent from './SchemaPermissionContent';
var ObjectInlinePermission = function (_a) {
    var objectId = _a.objectId, type = _a.type, className = _a.className;
    var allAccessGroups = useRecoilValue(accountAccessGroupDetailsState);
    var _b = useSetting(), useGetObjectPermissionQuery = _b.useGetObjectPermissionQuery, useUpdateObjectPermission = _b.useUpdateObjectPermission;
    var _c = useGetObjectPermissionQuery({
        body: {
            objectId: objectId,
            type: type,
            accessGroupIds: allAccessGroups.map(function (group) { return group.id; }),
        },
        options: {
            enabled: !!allAccessGroups && !!allAccessGroups.length,
        },
    }), objectPermissions = _c.data, refetch = _c.refetch;
    var updateObjectPermission = useUpdateObjectPermission().mutateAsync;
    var handleObjectPermissionChange = useCallback(function (permission, accessGroupId) {
        updateObjectPermission({
            objectId: objectId,
            type: type,
            permission: permission,
            accessGroupId: accessGroupId,
        }, {
            onSuccess: function () {
                refetch();
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objectId, type]);
    if (allAccessGroups.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: className, children: [_jsx(Divider, { className: "mb-1 mt-1" }), _jsx("div", { className: "text-xs font-medium text-gray-200", children: "Who can see this data?" }), allAccessGroups.map(function (group) {
                var _a;
                var permission = (_a = objectPermissions === null || objectPermissions === void 0 ? void 0 : objectPermissions.find(function (perm) { return perm.accessGroupId === group.id; })) === null || _a === void 0 ? void 0 : _a.permission;
                var title = type == ObjType.PAGE
                    ? PagePermissionLevelOptions(permission)
                    : type == ObjType.COLLECTION
                        ? CollectionPermissionLevelOptions(permission)
                        : type == ObjType.SCHEMA
                            ? SchemaPermissionLevelOptions(permission)
                            : '';
                var overlay = type == ObjType.PAGE ? (_jsx(PagePermissionContent, { onChange: function (id) { return handleObjectPermissionChange(id, group.id); }, currentPermission: (permission || PagePermissionLevel.NONE) })) : type == ObjType.COLLECTION ? (_jsx(CollectionPermissionContent, { onChange: function (id) { return handleObjectPermissionChange(id, group.id); }, currentPermission: (permission ||
                        CollectionPermissionLevel.NONE) })) : type == ObjType.SCHEMA ? (_jsx(SchemaPermissionContent, { onChange: function (id) { return handleObjectPermissionChange(id, group.id); }, currentPermission: (permission || SchemaPermissionLevel.NONE) })) : (_jsx(_Fragment, {}));
                return (_jsxs("div", { className: "my-2 flex items-center space-x-1", children: [_jsxs("div", { className: "flex-1 font-medium ", children: [" ", group.name, " "] }), _jsx("div", { className: "min-w-[40px]" }), _jsx(ObjectPermissionDropdown, { title: title, overlay: overlay, canUpdate: group.type !== 'owner' })] }, group.id));
            })] }));
};
export default React.memo(ObjectInlinePermission);
