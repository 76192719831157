var _a, _b;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { RecoilRoot, useRecoilValue } from 'recoil';
import { createContext, lazy, Suspense, useContext, useEffect } from 'react';
import { RecoilDevTools } from 'recoil-gear';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes, Navigate, useLocation, createRoutesFromChildren, matchRoutes, useNavigationType, } from 'react-router-dom';
import './App.less';
import { IntercomProvider } from 'react-use-intercom';
import { useAuth0 } from '@auth0/auth0-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment-timezone';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import Workspace from './components/Workspace';
import AccountState from './hooks/state/Account';
import 'react-toastify/dist/ReactToastify.css';
import EmailVerificationPage from './components/Auth/EmailVerificationPage';
import SettingsPage from './components/Settings/SettingsPage';
import { LoadingLogo } from './utils/icons/LoadingLogo';
import Loading from './components/Auth/Loading';
import { isSelfOnboardingOnState } from './state/atoms/accountOnboardingState';
import { useAccountApi } from './hooks/api';
import AccountSelectPage from './components/Auth/AccountSelectPage';
import SsoLoginPage from './components/Auth/SsoLoginPage';
import { getOrgIdentifierFromURL } from './utils/Account';
var SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
var SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
var SENTRY_TRACING_SAMPLE_RATE = process.env.REACT_APP_SENTRY_TRACING_SAMPLE_RATE;
var SENTRY_RELEASE = process.env.COMMIT_HASH;
var INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
var BETTERSTACK_DATA_ID = process.env.REACT_APP_BETTERSTACK_DATA_ID;
var hostname = new URL(process.env.REACT_APP_SERVER_BASE_URL || 'http://localhost:8080/').hostname;
var SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
var LandingPage = lazy(function () { return import('./components/Landing/LandingPage'); });
var FillFormPage = lazy(function () { return import('./components/FormView/FillFormPage'); });
var GenerateFormPage = lazy(function () { return import('./components/FormView/GenerateFormPage'); });
var GenerateInternalFormPage = lazy(function () { return import('./components/FormView/GenerateInternalFormPage'); });
var AuthPage = lazy(function () { return import('./components/Auth/AuthPage'); });
var UnauthorizedWebAccessPage = lazy(function () { return import('./components/Auth/UnauthorizedWebAccessPage'); });
var SignupPage = lazy(function () { return import('./components/Auth/SignupPage'); });
var OnboardingLandingPage = lazy(function () { return import('./components/SelfOnboarding/OnboardingLandingPage'); });
// Sentry integration
Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
            tracingOrigins: [hostname],
        }),
        new Sentry.Replay({
            maskAllInputs: false,
            blockAllMedia: true,
        }),
    ],
    // production: 0.1
    // development: 1.0
    tracesSampleRate: _.toNumber(SENTRY_TRACING_SAMPLE_RATE) || 1.0,
    ignoreErrors: [
        // ignore errors from api calls, already handled by server reports
        'IgnoreApiError',
        // ignore redeployement issue
        'ChunkLoadError',
        /^Could not load$/,
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        /^TypeError: Failed to fetch$/,
        'Error: Login required',
        /^Script error\.$/,
        /^NotAllowedError$/,
    ],
    beforeSend: function (event) {
        var _a, _b, _c, _d, _e, _f, _g;
        // ignore errors from node_modules
        if ((_g = (_f = (_e = (_d = (_c = (_b = (_a = event.exception) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.stacktrace) === null || _d === void 0 ? void 0 : _d.frames) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.filename) === null || _g === void 0 ? void 0 : _g.includes('node_modules')) {
            return null;
        }
        return event;
    },
});
Sentry.setUser({
    accountId: localStorage.getItem('accountId'),
    appSessionId: (_a = JSON.parse(localStorage.getItem('appSession') || '{}')) === null || _a === void 0 ? void 0 : _a.id,
    userId: (_b = JSON.parse(localStorage.getItem('currentUserState') || '{}')) === null || _b === void 0 ? void 0 : _b.id,
});
// React-Query Client
// disable automatically requests fresh data globally
var reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
export var OrganizationContext = createContext(undefined);
// Main app
var App = function () {
    var isLoading = useAuth0().isLoading;
    //load canny lib script
    useEffect(function () {
        var cannyScript = document.createElement('script');
        cannyScript.src = 'https://canny.io/sdk.js';
        window.document.body.appendChild(cannyScript);
    }, []);
    //load google place lib
    useEffect(function () {
        var googleMapScript = document.createElement('script');
        googleMapScript.src = "https://maps.googleapis.com/maps/api/js?key=".concat(process.env
            .REACT_APP_GOOGLE_PLACE_API_KEY, "&libraries=places");
        window.document.body.appendChild(googleMapScript);
    }, []);
    //load better stack announcement script
    useEffect(function () {
        var betterStackScript = document.createElement('script');
        betterStackScript.src = 'https://uptime.betterstack.com/widgets/announcement.js';
        betterStackScript.async = true;
        betterStackScript.setAttribute('data-id', BETTERSTACK_DATA_ID !== null && BETTERSTACK_DATA_ID !== void 0 ? BETTERSTACK_DATA_ID : '');
        window.document.body.appendChild(betterStackScript);
    }, []);
    // update moment relative time format
    useEffect(function () {
        moment.updateLocale('en', {
            relativeTime: {
                s: 'just now',
            },
        });
    }, []);
    useEffect(function () {
        document.title = _.startCase(getOrgIdentifierFromURL() || 'TeamBridge');
    }, []);
    if (isLoading) {
        return (_jsx("div", { className: "logo-container", children: _jsx(LoadingLogo, { className: "main-loading-logo" }) }));
    }
    var showRecoilDevTools = process.env.REACT_APP_RECOIL_DEBUGGER === 'true';
    return (_jsx(OrganizationContext.Provider, { value: getOrgIdentifierFromURL(), children: _jsxs(QueryClientProvider, { client: reactQueryClient, children: [_jsx(DndProvider, { backend: HTML5Backend, children: _jsxs(RecoilRoot, { children: [showRecoilDevTools && _jsx(RecoilDevTools, {}), _jsx(IntercomProvider, { appId: INTERCOM_APP_ID, autoBootProps: {
                                    customLauncherSelector: '#IntercomHelpBtn',
                                    hideDefaultLauncher: true,
                                }, autoBoot: true, children: _jsx(AccountState.Provider, { children: _jsx(AppContent, {}) }) })] }) }), _jsx(ReactQueryDevtools, { initialIsOpen: false })] }) }));
};
var AppContent = function () {
    var _a;
    var user = useAuth0().user;
    var useGetCurrentUserAccountCreatorStatus = useAccountApi().useGetCurrentUserAccountCreatorStatus;
    var isSelfOnboardingOn = useRecoilValue(isSelfOnboardingOnState);
    var _b = useGetCurrentUserAccountCreatorStatus((_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : '', {
        enabled: !!(user === null || user === void 0 ? void 0 : user.email),
    }), data = _b.data, isLoading = _b.isLoading;
    var isUserAccountCreator = !!(data === null || data === void 0 ? void 0 : data.isCreator);
    var orgMode = useContext(OrganizationContext);
    if (isLoading) {
        return _jsx(Loading, {});
    }
    else if (user && !user.email_verified && !isSelfOnboardingOn && !isUserAccountCreator) {
        return _jsx(EmailVerificationPage, {});
    }
    return (_jsxs(Suspense, { fallback: _jsx("div", {}), children: [_jsxs(SentryRoutes, { children: [_jsx(Route, { path: "/landing/:page?", element: _jsx(ProtectedRoute, { children: _jsx(LandingPage, {}) }) }), _jsx(Route, { path: "/home/*", element: _jsx(ProtectedRoute, { children: _jsx(Workspace, {}) }) }), _jsx(Route, { path: "/settings/*", element: _jsx(ProtectedRoute, { children: _jsx(SettingsPage, {}) }) }), _jsx(Route, { path: "/fill-form/:id", element: _jsx(FillFormPage, {}) }), _jsx(Route, { path: "/form/:id", element: _jsx(GenerateFormPage, {}) }), _jsx(Route, { path: "/internal-form/:id", element: _jsx(GenerateInternalFormPage, {}) }), _jsx(Route, { path: "/login", element: _jsx(AuthPage, {}) }), _jsx(Route, { path: "/signup", element: _jsx(SignupPage, {}) }), _jsx(Route, { path: "/unauthorized", element: _jsx(UnauthorizedWebAccessPage, {}) }), !orgMode && (_jsxs(_Fragment, { children: [_jsx(Route, { path: "/get-started", element: _jsx(OnboardingLandingPage, {}) }), ' ', _jsx(Route, { path: "/accounts/select", element: _jsx(AccountSelectPage, {}) }), _jsx(Route, { path: "/rippling/auth", element: _jsx(AuthPage, {}) }), _jsx(Route, { path: "/sso", element: _jsx(SsoLoginPage, {}) })] })), _jsx(Route, { path: "/*", element: _jsx(Navigate, { to: "/".concat(isSelfOnboardingOn && !orgMode ? 'get-started' : 'login'), replace: true }) })] }), _jsx(ToastContainer, { pauseOnHover: true, hideProgressBar: true })] }));
};
export default Sentry.withProfiler(App);
