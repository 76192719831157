import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useParams } from 'react-router-dom';
import { getIntegrationIcon, getIpDisplayText } from '../../../../utils/Account';
var IntegrationHeaderContent = function (props) {
    var children = props.children;
    var ipName = useParams().ipName;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "Header", children: children }), _jsx("div", { className: "IpICon", children: getIntegrationIcon(ipName) }), _jsx("div", { className: "IntegrationTitle", children: getIpDisplayText(ipName) }), _jsx("div", { className: "ContentPadding" })] }));
};
export default React.memo(IntegrationHeaderContent);
