import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { NativePath } from 'src/shared';
import TaskDetailLinkedRecordContent from './Tasks/TaskDetailLinkedRecordContent';
var DetailModalCellExtraContent = function (_a) {
    var recordId = _a.recordId, nativePath = _a.nativePath, dataSource = _a.dataSource, isCreatingNew = _a.isCreatingNew;
    var renderExtraContent = useMemo(function () {
        if (isCreatingNew)
            return null;
        switch (nativePath) {
            case NativePath.TASK_STATUS:
                return _jsx(TaskDetailLinkedRecordContent, { recordId: recordId, dataSource: dataSource });
            default:
                return null;
        }
    }, [dataSource, isCreatingNew, nativePath, recordId]);
    return renderExtraContent;
};
export default React.memo(DetailModalCellExtraContent);
