var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Image, Input, Menu } from 'antd';
import { cloneDeep } from 'lodash';
import { ArrowRightSmallIcon } from 'src/utils/icons/ArrowRightSmall';
import moment from 'moment-timezone';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { renderAuthorEmployeeIcon } from '../../../utils/Account';
import { useFeed } from '../../../hooks/api/useFeedApi';
import { BlackPlusIcon } from '../../../utils/icons/BlackPlusIcon';
var Picker = lazy(function () { return import('emoji-mart').then(function (_a) {
    var Picker = _a.Picker;
    return ({ default: Picker });
}); });
var NewsFeedPost = function (props) {
    var post = props.post, updateReplyCount = props.updateReplyCount, updateFeedData = props.updateFeedData;
    var DEFAULT_REACTJIS = [
        { native: '❤️', unified: '2764-fe0f' },
        { native: '👏', unified: '1f44f' },
        { native: '👍', unified: '1f44d' },
    ];
    var _a = useState({}), userReactjis = _a[0], setUserReactjis = _a[1];
    var userReactjisRef = useRef(userReactjis);
    useEffect(function () {
        userReactjisRef.current = userReactjis;
    }, [userReactjis]);
    var _b = useState(''), emojiPickerOpen = _b[0], setEmojiPickerOpen = _b[1];
    var _c = useState(''), replyInput = _c[0], setReplyInput = _c[1];
    var _d = useState(post.preLoadReplies), replies = _d[0], setReplies = _d[1];
    var repliesRef = useRef(replies);
    useEffect(function () {
        repliesRef.current = replies;
    }, [replies]);
    var replyInputRef = useRef(replyInput);
    useEffect(function () {
        replyInputRef.current = replyInput;
    }, [replyInput]);
    var _e = useFeed(), useAddMessageInteraction = _e.useAddMessageInteraction, usePostReply = _e.usePostReply, useFetchMoreReplies = _e.useFetchMoreReplies, useDeleteFeedPost = _e.useDeleteFeedPost;
    var addMessageMutation = useAddMessageInteraction();
    var postReplyMutation = usePostReply();
    var fetchMoreRepliesMutation = useFetchMoreReplies();
    var updateReplies = useCallback(function (newReplies) {
        var tempReplyMap = cloneDeep(repliesRef.current);
        if (!tempReplyMap)
            return;
        tempReplyMap = tempReplyMap.concat(newReplies);
        tempReplyMap.sort(function (a, b) { return (a.sentAt > b.sentAt ? 1 : b.sentAt > a.sentAt ? -1 : 0); });
        setReplies(tempReplyMap);
    }, []);
    var renderEmployeeIcon = useCallback(function (post) {
        return renderAuthorEmployeeIcon({
            authorTitle: post.senderTitle,
            authorIcon: post.senderIcon,
        });
    }, []);
    var renderPostImage = useCallback(function (post) {
        if (post.contentImage !== null) {
            return (_jsx("div", { className: "postImageWrapper", children: _jsx(Image, { className: "postImage", src: post.contentImage }) }));
        }
        return _jsx(_Fragment, {});
    }, []);
    var toggleEmojiPicker = useCallback(function (postId) {
        setEmojiPickerOpen(postId);
    }, []);
    var getPostReactjis = useCallback(function (post) {
        var reactjis = [];
        post.userInteractions.forEach(function (interaction) {
            var _a;
            if (reactjis.length >= 3)
                return;
            if (interaction.currentUserMarked && ((_a = userReactjis[post.id]) === null || _a === void 0 ? void 0 : _a.native))
                reactjis.push(userReactjis[post.id].native);
            else
                reactjis.push(interaction.native);
        });
        return reactjis;
    }, [userReactjis]);
    var postReply = useCallback(function (post) { return __awaiter(void 0, void 0, void 0, function () {
        var reply;
        return __generator(this, function (_a) {
            reply = cloneDeep(replyInputRef.current);
            if (!reply)
                return [2 /*return*/];
            postReplyMutation.mutate({
                messageId: post.id,
                reply: reply,
            }, {
                onSuccess: function (resp) {
                    updateReplyCount(post.id);
                    setReplyInput('');
                    if (resp)
                        updateReplies([resp]);
                },
                onError: function () { },
            });
            return [2 /*return*/];
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateReplies, updateReplyCount]);
    useEffect(function () {
        if (emojiPickerOpen) {
            document.addEventListener('mousedown', function (event) {
                var target = event.target;
                if (typeof target.className != 'string')
                    return;
                if (target.id.substr(0, 10) !== 'emoji-mart' &&
                    typeof target.className == 'string' &&
                    target.className.substr(0, 10) !== 'emoji-mart')
                    setEmojiPickerOpen('');
            });
        }
    }, [emojiPickerOpen]);
    var viewMoreReplies = useCallback(function (post) {
        if (!replies)
            return false;
        return post.replyCount > replies.length;
    }, [replies]);
    var fetchMoreReplies = useCallback(function (post) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!replies)
                return [2 /*return*/];
            fetchMoreRepliesMutation.mutate({
                messageId: post.id,
                from: replies.length ? replies[0].sentAt : new Date(),
            }, {
                onSuccess: function (resp) {
                    if (resp)
                        updateReplies(resp);
                },
                onError: function () { },
            });
            return [2 /*return*/];
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [replies, updateReplies]);
    var addEmoji = useCallback(function (reactji, messageId) { return __awaiter(void 0, void 0, void 0, function () {
        var temp;
        return __generator(this, function (_a) {
            temp = cloneDeep(userReactjisRef.current);
            temp[messageId] = { native: reactji.native, unified: reactji.unified };
            setUserReactjis(temp);
            addMessageMutation.mutate({
                messageId: messageId,
                reactji: { native: reactji.native, unified: reactji.unified },
            }, {
                onSuccess: function () {
                    setEmojiPickerOpen('');
                },
                onError: function () { },
            });
            return [2 /*return*/];
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var deletePost = useCallback(function () {
        useDeleteFeedPost.mutate({
            messageId: post.id,
        }, {
            onSuccess: function (data) {
                if (data) {
                    updateFeedData(data);
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var optionsDropdown = useMemo(function () {
        return (_jsx(Dropdown, { className: "h-min", overlay: _jsx(Menu, { children: _jsx(Menu.Item, { onClick: deletePost, children: _jsxs("div", { className: "flex items-center space-x-4", children: [_jsx(DeleteOutlined, {}), " ", _jsx("span", { className: "text-red-1000", children: "Delete" })] }) }) }), trigger: ['click'], placement: "bottomRight", children: _jsx(EllipsisOutlined, {}) }));
    }, [deletePost]);
    return (_jsxs("div", { className: "postCard shadow-block", children: [_jsxs("div", { className: "postHead", children: [renderEmployeeIcon(post), _jsx("div", { className: "AuthorWrapper", children: _jsxs("div", { className: "authorInfo", children: [_jsxs("div", { className: "author", children: [post.senderTitle, _jsx(ArrowRightSmallIcon, { className: "RightArrow" }), _jsx("span", { className: "sendToText", children: post.sendToText })] }), _jsx("div", { className: "timeAgo", children: moment(post.sentAt).fromNow() })] }) }), _jsxs("div", { className: "audience", children: [post.receiverIds.length > 0 ? post.receiverIds.length : 'All ', " users can view"] }), optionsDropdown] }), _jsx("div", { className: "postContent", children: post.content }), renderPostImage(post), _jsxs("div", { className: "postFooter", children: [_jsxs("span", { className: "floatLeft", children: [post.reactjiCount > 0 ? (_jsxs("span", { children: [getPostReactjis(post), post.reactjiCount] })) : (_jsx(_Fragment, {})), _jsxs("span", { children: [post.replyCount, " Comments"] }), _jsxs("span", { children: [post.viewCount, " Views"] })] }), _jsxs("span", { className: "floatRight", children: [DEFAULT_REACTJIS.map(function (reactji) {
                                return (_jsx("span", { className: "EmojiIcon", onClick: function () { return addEmoji(reactji, post.id); }, children: reactji.native }, reactji.unified));
                            }), _jsx("span", { className: "PlusIcon", onClick: function () { return toggleEmojiPicker(post.id); }, children: _jsx(BlackPlusIcon, {}) }), emojiPickerOpen == post.id ? (_jsx(Picker, { title: "Pick your emoji\u2026", emoji: "point_up", onSelect: function (val) {
                                    return addEmoji(val, post.id);
                                } })) : (_jsx(_Fragment, {}))] })] }), viewMoreReplies(post) ? (_jsx("div", { className: "viewMoreReplies", onClick: function () { return fetchMoreReplies(post); }, children: "View More Replies" })) : (_jsx(_Fragment, {})), _jsxs("div", { className: "repliesWrapper", children: [replies.map(function (reply) {
                        return (_jsxs("div", { children: [_jsxs("div", { className: "replyHead", children: [renderEmployeeIcon(reply), _jsxs("div", { children: [_jsx("div", { className: "author", children: reply.senderTitle }), _jsx("div", { className: "timeAgo", children: moment(reply.sentAt).fromNow() })] })] }), _jsx("div", { className: "replyContent", children: reply.content })] }, reply.id));
                    }), _jsx("div", { className: "commentsWrapper", children: _jsxs("div", { className: "commentBar", children: [renderEmployeeIcon(post), _jsx(Input, { className: "newsfeed-input", placeholder: "Write a comment...", value: replyInput, onPressEnter: function () { return postReply(post); }, onChange: function (e) {
                                        setReplyInput(e.target.value);
                                    } }), _jsx("button", { className: "commentBtn", onClick: function () { return postReply(post); }, children: "Comment" })] }) })] })] }, post.id));
};
export default React.memo(NewsFeedPost);
