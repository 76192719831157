import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { PayReportExportType } from 'src/components/PayView/Constant';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { usePageApi } from 'src/hooks/api';
import { PdfExportFileIcon } from 'src/utils/icons/PdfExportFileIcon';
import { CuttingEdgeEventReportTypeOptions } from './constant';
var Option = Select.Option;
var LocationDetailExportReportActionButton = function (_a) {
    var recordId = _a.recordId;
    var _b = useState(false), popupVisible = _b[0], setPopupVisible = _b[1];
    var _c = useState(PayReportExportType.CUTTING_EDGE_EVENT_INVOICE), reportType = _c[0], setReportType = _c[1];
    var useExportCuttingEdgeReport = usePageApi().useExportCuttingEdgeReport;
    var exportCuttingEdgeReportMutate = useExportCuttingEdgeReport.mutate;
    var exportCuttingEdgeReport = useCallback(function () {
        exportCuttingEdgeReportMutate({
            locationRid: recordId,
            reportType: reportType,
        }, {
            onSuccess: function () {
                toast('We are processing your report export request. Please check the "Files" tab for its status. Thank you.', {
                    type: 'info',
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                setPopupVisible(false);
            },
        });
    }, [exportCuttingEdgeReportMutate, recordId, reportType]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraTooltip, { title: "Export Event Report", children: _jsx("div", { className: "ActionButton", onClick: function () { return setPopupVisible(true); }, children: _jsx(PdfExportFileIcon, { className: "scale-[0.6]" }) }) }), _jsx(Modal, { width: 350, open: popupVisible, closable: false, centered: true, destroyOnClose: true, onCancel: function () { return setPopupVisible(false); }, onOk: exportCuttingEdgeReport, okText: "Export", children: _jsxs("div", { className: "flex flex-col gap-y-[16px]", children: [_jsx("div", { className: "text-lg font-semibold", children: "Export Event Report" }), _jsxs("div", { className: "flex items-center font-medium", children: [_jsx("span", { children: "Report Type:" }), _jsx(Select, { className: "ml-auto w-[200px]", value: reportType, onChange: setReportType, children: CuttingEdgeEventReportTypeOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) })] })] }) })] }));
};
export default React.memo(LocationDetailExportReportActionButton);
