import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
var Auth0ProviderWithHistory = function (_a) {
    var children = _a.children;
    var domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
    var clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
    var audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
    var navigate = useNavigate();
    var onRedirectCallback = useCallback(function (appState) {
        navigate((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    }, [navigate]);
    return (_jsx(Auth0Provider, { domain: domain, clientId: clientId, onRedirectCallback: onRedirectCallback, cacheLocation: "localstorage", authorizationParams: {
            audience: audience,
            redirect_uri: typeof window !== 'undefined' ? "".concat(window.location.origin, "/landing") : '',
        }, children: children }));
};
export default Auth0ProviderWithHistory;
