import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Layout, Row } from 'antd';
import React from 'react';
import SelectIndustryCategorySiderImg from '../../../utils/icons/images/SelectIndustryCategorySiderImg.svg';
import SiderHeader from '../CreateAccountDetailModal/SiderHeader';
import IndustryCategoryCard from './IndustryCategoryCard';
var Sider = Layout.Sider, Content = Layout.Content;
var IndustryCategorySelectionPage = function (props) {
    var categoryMetaDataMap = props.categoryMetaDataMap, selectedCategoryOnChange = props.selectedCategoryOnChange, goNextStep = props.goNextStep;
    return (_jsxs(Layout, { className: "h-full", children: [_jsx(Sider, { width: "30vw", className: "AccountTemplateSider", children: _jsxs("div", { className: "flex h-full flex-col justify-between", children: [_jsx(SiderHeader, { showWelcomeText: true }), _jsx("div", { className: "ImgContainer", children: _jsx("img", { className: "h-full w-full", src: SelectIndustryCategorySiderImg }) })] }) }), _jsx(Layout, { className: "bg-gray-50", children: _jsx(Content, { children: _jsxs("div", { className: "AccountIndustryCategoryContent", children: [_jsx("div", { className: "Title", children: "Select Your Industry" }), _jsx("div", { children: _jsx(Row, { gutter: 8, children: Object.values(categoryMetaDataMap).map(function (item) { return (_jsx(Col, { span: 8, children: _jsx(IndustryCategoryCard, { categoryItem: item, goNext: goNextStep, selectedCategoryOnChange: function () {
                                                return selectedCategoryOnChange(item.category);
                                            } }) }, item.category)); }) }) })] }) }) })] }));
};
export default React.memo(IndustryCategorySelectionPage);
