import { TagStatusColor } from 'src/components/RecordDetailModal/RecordDetailModalHeader/RecordDetailModalHeaderWrapper';
export var ApprovalStatus;
(function (ApprovalStatus) {
    ApprovalStatus[ApprovalStatus["PENDING"] = 0] = "PENDING";
    ApprovalStatus[ApprovalStatus["APPROVED"] = 1] = "APPROVED";
    ApprovalStatus[ApprovalStatus["REJECTED"] = 2] = "REJECTED";
})(ApprovalStatus || (ApprovalStatus = {}));
export var getAvailableSelectionOptionsForApprovalStatus = function () {
    return [ApprovalStatus.PENDING, ApprovalStatus.APPROVED, ApprovalStatus.REJECTED].map(function (status) { return getSelectionOptionFromApprovalStatus(status); });
};
export var getSelectionOptionFromApprovalStatusString = function (status) {
    var approvalStatus = getApprovalStatusFromString(status || 'PENDING');
    return getSelectionOptionFromApprovalStatus(approvalStatus);
};
export var getApprovalStatusFromString = function (status) {
    switch (status) {
        case 'PENDING':
            return ApprovalStatus.PENDING;
        case 'APPROVED':
            return ApprovalStatus.APPROVED;
        case 'REJECTED':
            return ApprovalStatus.REJECTED;
        default:
            return ApprovalStatus.PENDING;
    }
};
export var getSelectionOptionFromApprovalStatus = function (status) {
    switch (status) {
        case ApprovalStatus.PENDING:
            return { id: 'PENDING', label: 'PENDING', color: TagStatusColor.grey };
        case ApprovalStatus.APPROVED:
            return { id: 'APPROVED', label: 'APPROVED', color: TagStatusColor.green };
        case ApprovalStatus.REJECTED:
            return { id: 'REJECTED', label: 'REJECTED', color: TagStatusColor.red };
    }
};
export var TimeOffApprovalStatusFilterOptions = [
    { id: '8931a992-b3b7-4efe-87e7-303556582f88', label: 'PENDING', color: TagStatusColor.grey },
    { id: '7d843596-472c-4d24-95db-7f623b92e051', label: 'APPROVED', color: TagStatusColor.green },
    { id: '9cf4341f-6e62-48be-aabe-6057f2b58bc6', label: 'REJECTED', color: TagStatusColor.red },
];
