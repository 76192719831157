import { atom } from 'recoil';
var isRatioConfigModalVisibleState = atom({
    key: 'isRatioConfigModalVisibleState',
    default: false,
});
var currentRatioSummaryLocationRidState = atom({
    key: 'currentRatioSummaryLocationRidState',
    default: undefined,
});
export { isRatioConfigModalVisibleState, currentRatioSummaryLocationRidState };
