var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var NumberlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M4.33325 7.90755C4.33325 7.8155 4.40787 7.74089 4.49992 7.74089H6.83325C6.9253 7.74089 6.99992 7.8155 6.99992 7.90755V14.2409C6.99992 14.3329 6.9253 14.4076 6.83325 14.4076H5.83325C5.7412 14.4076 5.66659 14.3329 5.66659 14.2409V9.07422H4.49992C4.40787 9.07422 4.33325 8.9996 4.33325 8.90755V7.90755Z", fill: "#A7A8AC" }), _jsx("path", { d: "M8.49992 7.74089C8.40787 7.74089 8.33325 7.8155 8.33325 7.90755V8.90755C8.33325 8.9996 8.40787 9.07422 8.49992 9.07422H10.9999V10.4076H8.49992C8.40787 10.4076 8.33325 10.4822 8.33325 10.5742V14.2409C8.33325 14.3329 8.40787 14.4076 8.49992 14.4076H12.1666C12.2586 14.4076 12.3333 14.3329 12.3333 14.2409V13.2409C12.3333 13.1488 12.2586 13.0742 12.1666 13.0742H9.66659V11.7409H12.1666C12.2586 11.7409 12.3333 11.6663 12.3333 11.5742V7.90755C12.3333 7.8155 12.2586 7.74089 12.1666 7.74089H8.49992Z", fill: "#A7A8AC" }), _jsx("path", { d: "M13.8333 7.74089C13.7412 7.74089 13.6666 7.8155 13.6666 7.90755V8.90755C13.6666 8.9996 13.7412 9.07422 13.8333 9.07422H16.3333V10.4076H13.8333C13.7412 10.4076 13.6666 10.4822 13.6666 10.5742V11.5742C13.6666 11.6663 13.7412 11.7409 13.8333 11.7409H16.3333V13.0742H13.8333C13.7412 13.0742 13.6666 13.1488 13.6666 13.2409V14.2409C13.6666 14.3329 13.7412 14.4076 13.8333 14.4076H17.4999C17.592 14.4076 17.6666 14.3329 17.6666 14.2409V7.90755C17.6666 7.8155 17.592 7.74089 17.4999 7.74089H13.8333Z", fill: "#A7A8AC" })] })); };
export var NumberlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: NumberlinedSvg }, props))); };
