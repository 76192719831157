var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import GoogleMapReact from 'google-map-react';
import React, { useCallback, useMemo, useState } from 'react';
import { PlayCircleFilled, StopOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { isEqual, uniqWith } from 'lodash';
import moment from 'moment';
import { useTimeZone } from 'src/hooks/component';
import UserAvatar from '../User/UserAvatar';
import MapComponent from './MapComponent';
import './GeoMap.scss';
import MapSummaryCard from './MapSummaryCard';
var GeoMap = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var zoom = _a.zoom, isSchema = _a.isSchema, geofence = _a.geofence, shiftTrackingData = _a.shiftTrackingData, isBlock = _a.isBlock;
    var timeZone = useTimeZone().timeZone;
    var _l = useState(0), pathShiftIndex = _l[0], setPathShiftIndex = _l[1];
    var _m = useState({}), clockInPopoverVisible = _m[0], setClockInPopoverVisible = _m[1];
    var _o = useState({}), clockOutPopoverVisible = _o[0], setClockOutPopoverVisible = _o[1];
    var summaryCounts = useMemo(function () {
        var _a = [0, 0, 0, 0, 0], upcomingCount = _a[0], ongoingCount = _a[1], lateCount = _a[2], noShowCount = _a[3], completedCount = _a[4];
        for (var _i = 0, _b = shiftTrackingData !== null && shiftTrackingData !== void 0 ? shiftTrackingData : []; _i < _b.length; _i++) {
            var shiftData = _b[_i];
            switch (shiftData.shiftStatus) {
                case 'Upcoming':
                    upcomingCount++;
                    break;
                case 'Ongoing':
                    ongoingCount++;
                    break;
                case 'Late':
                    lateCount++;
                    break;
                case 'No Show':
                    noShowCount++;
                    break;
                case 'Completed':
                    completedCount++;
                    break;
            }
        }
        return { upcomingCount: upcomingCount, ongoingCount: ongoingCount, lateCount: lateCount, noShowCount: noShowCount, completedCount: completedCount };
    }, [shiftTrackingData]);
    var geofences = useMemo(function () {
        return uniqWith(shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.reduce(function (geofenceList, _a) {
            var geofence = _a.geofence;
            if (geofence) {
                geofenceList.push(geofence);
            }
            return geofenceList;
        }, []), function (geofenceA, geofenceB) { return isEqual(geofenceA, geofenceB); });
    }, [shiftTrackingData]);
    var trackedLocationsPath = useCallback(function (clockInLocation, clockOutLocation, trackedLocations) {
        var _a;
        var path = (_a = trackedLocations === null || trackedLocations === void 0 ? void 0 : trackedLocations.map(function (location) { return ({
            lat: location.l,
            lng: location.g,
        }); })) !== null && _a !== void 0 ? _a : [];
        if ((trackedLocations === null || trackedLocations === void 0 ? void 0 : trackedLocations.length) && clockInLocation) {
            path.unshift(clockInLocation);
        }
        if ((trackedLocations === null || trackedLocations === void 0 ? void 0 : trackedLocations.length) && clockOutLocation) {
            path.push(clockOutLocation);
        }
        return path;
    }, []);
    return (_jsxs("div", { className: "MapWrapper shadow-block", children: [_jsxs(GoogleMapReact, { yesIWantToUseGoogleMapApiInternals: true, center: (_h = (_f = (_e = (_c = (_b = shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.find(function (_a) {
                    var clockInLocation = _a.clockInLocation;
                    return !!clockInLocation;
                })) === null || _b === void 0 ? void 0 : _b.clockInLocation) !== null && _c !== void 0 ? _c : (_d = shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.find(function (_a) {
                    var clockOutLocation = _a.clockOutLocation;
                    return !!clockOutLocation;
                })) === null || _d === void 0 ? void 0 : _d.clockOutLocation) !== null && _e !== void 0 ? _e : geofence === null || geofence === void 0 ? void 0 : geofence.coordinates) !== null && _f !== void 0 ? _f : (_g = geofences === null || geofences === void 0 ? void 0 : geofences.find(function (geofence) { return !!(geofence === null || geofence === void 0 ? void 0 : geofence.coordinates); })) === null || _g === void 0 ? void 0 : _g.coordinates) !== null && _h !== void 0 ? _h : {
                    lat: 38.8976763,
                    lng: -77.0365298,
                }, zoom: zoom !== null && zoom !== void 0 ? zoom : 23, options: {
                    scrollwheel: false,
                    fullscreenControl: !isSchema,
                    keyboardShortcuts: !isSchema,
                }, onGoogleApiLoaded: function (_a) {
                    var map = _a.map;
                    if (geofence) {
                        // Single geofence
                        var geofenceCircle = new google.maps.Circle({
                            strokeOpacity: 0,
                            strokeWeight: 0,
                            fillColor: 'rgba(185, 216, 247, 0.61)',
                            fillOpacity: 1,
                            map: map,
                            center: geofence.coordinates,
                            radius: geofence.radius,
                        });
                        map.fitBounds(geofenceCircle.getBounds());
                    }
                    else if (geofences.length) {
                        // Multiple geofences (widget)
                        var bounds_1 = new google.maps.LatLngBounds();
                        geofences.map(function (geofence) {
                            var geofenceCircle = new google.maps.Circle({
                                strokeOpacity: 0,
                                strokeWeight: 0,
                                fillColor: 'rgba(185, 216, 247, 0.61)',
                                fillOpacity: 1,
                                map: map,
                                center: geofence.coordinates,
                                radius: geofence.radius,
                            });
                            var geofenceCircleBounds = geofenceCircle.getBounds();
                            if (geofenceCircleBounds) {
                                bounds_1.union(geofenceCircleBounds);
                            }
                        });
                        map.fitBounds(bounds_1, 0);
                    }
                    var mapBounds = map.getBounds();
                    // Tracked location lines
                    shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.map(function (_a) {
                        var clockInLocation = _a.clockInLocation, clockOutLocation = _a.clockOutLocation, trackedLocations = _a.trackedLocations;
                        new google.maps.Polyline({
                            strokeColor: '#1774FF',
                            strokeOpacity: 1,
                            strokeWeight: 1,
                            map: map,
                            path: trackedLocationsPath(clockInLocation, clockOutLocation, trackedLocations),
                            zIndex: 1,
                        });
                        // Extend map bounds
                        if (clockInLocation) {
                            mapBounds.extend(clockInLocation);
                        }
                        if (clockOutLocation) {
                            mapBounds.extend(clockOutLocation);
                        }
                        if (shiftTrackingData.length === 1) {
                            trackedLocations === null || trackedLocations === void 0 ? void 0 : trackedLocations.forEach(function (location) {
                                mapBounds.extend({ lat: location.l, lng: location.g });
                            });
                        }
                    });
                    // Extend map to show all shifts
                    if (shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.length) {
                        setTimeout(function () { return map.fitBounds(mapBounds, 0); }, 1000);
                    }
                    if (geofence || geofences.length || (shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.length)) {
                        setTimeout(function () { return map.panToBounds(mapBounds); }, 1000);
                    }
                }, children: [(_k = (_j = shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData[pathShiftIndex]) === null || _j === void 0 ? void 0 : _j.trackedLocations) === null || _k === void 0 ? void 0 : _k.map(function (location) {
                        var _a, _b;
                        return (_jsx(MapComponent, { className: "MapComponent", lat: location.l, lng: location.g, children: _jsx(Popover, { overlayClassName: "LocationPopoverCard", title: "Ongoing Shift", content: _jsxs(_Fragment, { children: [_jsxs("div", { className: "GeoInfoTitle", children: ["User:", ' ', _jsx("span", { children: (_b = (_a = shiftTrackingData[pathShiftIndex].user) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '' })] }), _jsxs("div", { className: "GeoInfoTitle", children: ["Time:", ' ', _jsxs("span", { children: [moment(location.t).tz(timeZone).format('h:mm A'), " on", ' ', moment(location.t).tz(timeZone).format('MMM D, YYYY')] })] }), !!shiftTrackingData[pathShiftIndex].locationName && (_jsxs("div", { className: "GeoInfoTitle", children: ["Location:", ' ', _jsx("span", { children: shiftTrackingData[pathShiftIndex].locationName })] })), !!shiftTrackingData[pathShiftIndex].shiftStatus && (_jsxs("div", { className: "GeoInfoTitle", children: ["Status:", ' ', _jsx("span", { children: shiftTrackingData[pathShiftIndex].shiftStatus })] }))] }), children: _jsx("div", { className: "TrackedLocationDot" }) }) }, location.t));
                    }), shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.map(function (_a, index) {
                        var _b, _c, _d;
                        var clockInLocation = _a.clockInLocation, clockInTime = _a.clockInTime, user = _a.user, locationName = _a.locationName, shiftStatus = _a.shiftStatus;
                        if (clockInLocation) {
                            var userName = (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : '';
                            var userAvatar = user === null || user === void 0 ? void 0 : user.avatarUrl;
                            return (_jsx(MapComponent, { className: "MapComponent", lat: clockInLocation.lat, lng: clockInLocation.lng, onMouseEnter: function () {
                                    setClockInPopoverVisible(function (prev) {
                                        var _a;
                                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
                                    });
                                    setPathShiftIndex(index);
                                }, onMouseLeave: function () {
                                    setClockInPopoverVisible(function (prev) {
                                        var _a;
                                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = false, _a)));
                                    });
                                }, children: _jsx(Popover, { overlayClassName: "LocationPopoverCard", title: "Clock In", visible: clockInPopoverVisible[index], content: _jsxs(_Fragment, { children: [_jsxs("div", { className: "GeoInfoTitle", children: ["User:", ' ', _jsx("span", { children: (_d = (_c = shiftTrackingData[pathShiftIndex].user) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '' })] }), _jsxs("div", { className: "GeoInfoTitle", children: ["Time:", ' ', _jsxs("span", { children: [moment(clockInTime)
                                                                .tz(timeZone)
                                                                .format('h:mm A'), ' ', "on", ' ', moment(clockInTime)
                                                                .tz(timeZone)
                                                                .format('MMM D, YYYY')] })] }), !!locationName && (_jsxs("div", { className: "GeoInfoTitle", children: ["Location: ", _jsx("span", { children: locationName })] })), !!shiftStatus && (_jsxs("div", { className: "GeoInfoTitle", children: ["Status: ", _jsx("span", { children: shiftStatus })] }))] }), children: _jsx(UserAvatar, { name: userName, avatar: userAvatar, size: 32, children: _jsx(PlayCircleFilled, { className: "ClockInIcon" }) }) }) }, clockInTime));
                        }
                    }), shiftTrackingData === null || shiftTrackingData === void 0 ? void 0 : shiftTrackingData.map(function (_a, index) {
                        var _b, _c, _d;
                        var clockOutLocation = _a.clockOutLocation, clockOutTime = _a.clockOutTime, user = _a.user, locationName = _a.locationName, shiftStatus = _a.shiftStatus;
                        if (clockOutLocation) {
                            var userName = (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : '';
                            var userAvatar = user === null || user === void 0 ? void 0 : user.avatarUrl;
                            return (_jsx(MapComponent, { className: "MapComponent", lat: clockOutLocation.lat, lng: clockOutLocation.lng, onMouseEnter: function () {
                                    setClockOutPopoverVisible(function (prev) {
                                        var _a;
                                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = true, _a)));
                                    });
                                    setPathShiftIndex(index);
                                }, onMouseLeave: function () {
                                    setClockOutPopoverVisible(function (prev) {
                                        var _a;
                                        return (__assign(__assign({}, prev), (_a = {}, _a[index] = false, _a)));
                                    });
                                }, children: _jsx(Popover, { overlayClassName: "LocationPopoverCard", title: "Clock Out", visible: clockOutPopoverVisible[index], content: _jsxs(_Fragment, { children: [_jsxs("div", { className: "GeoInfoTitle", children: ["User:", ' ', _jsx("span", { children: (_d = (_c = shiftTrackingData[pathShiftIndex].user) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '' })] }), _jsxs("div", { className: "GeoInfoTitle", children: ["Time:", ' ', _jsxs("span", { children: [moment(clockOutTime)
                                                                .tz(timeZone)
                                                                .format('h:mm A'), ' ', "on", ' ', moment(clockOutTime)
                                                                .tz(timeZone)
                                                                .format('MMM D, YYYY')] })] }), !!locationName && (_jsxs("div", { className: "GeoInfoTitle", children: ["Location: ", _jsx("span", { children: locationName })] })), !!shiftStatus && (_jsxs("div", { className: "GeoInfoTitle", children: ["Status: ", _jsx("span", { children: shiftStatus })] }))] }), children: _jsx(UserAvatar, { name: userName, avatar: userAvatar, size: 32, children: _jsx(StopOutlined, { className: "ClockOutIcon" }) }) }) }, "".concat(clockOutLocation.lat).concat(clockOutLocation.lng).concat(index)));
                        }
                    })] }), isBlock && _jsx(MapSummaryCard, { summaryCounts: summaryCounts })] }));
};
export default React.memo(GeoMap);
