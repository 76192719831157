var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, Input, Row, Select } from 'antd';
import { useHolidayPayApi } from 'src/hooks/api';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
var Option = Select.Option;
var HolidayPolicyItem = function (_a) {
    var policy = _a.policy, holidayMultipliers = _a.holidayMultipliers, refetchPolicies = _a.refetchPolicies, makeHolidayPolicyDefault = _a.makeHolidayPolicyDefault;
    var id = policy.id;
    var _b = useHolidayPayApi(), useUpdateHolidayPolicy = _b.useUpdateHolidayPolicy, useDeleteHolidayPolicy = _b.useDeleteHolidayPolicy;
    var updateHolidayPolicyName = function (name) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useUpdateHolidayPolicy.mutateAsync({ id: id, name: name })];
                case 1:
                    _a.sent();
                    refetchPolicies();
                    return [2 /*return*/];
            }
        });
    }); };
    var updateHolidayPolicyMultipliers = function (holidayIds) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useUpdateHolidayPolicy.mutateAsync({ id: id, holidayIds: holidayIds })];
                case 1:
                    _a.sent();
                    refetchPolicies();
                    return [2 /*return*/];
            }
        });
    }); };
    var deleteHolidayPolicy = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, useDeleteHolidayPolicy.mutateAsync(id)];
                case 1:
                    _a.sent();
                    refetchPolicies();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "TimeOffPolicyGroup relative pl-[16px]", children: [_jsxs(Row, { className: "TimeoffPolicyHeaderRow items-center !mb-0", gutter: [16, 16], children: [_jsx(Col, { span: 6, children: _jsx(Input, { className: "PolicyNameInput bg-white pl-[8px]", placeholder: "Enter a name for this policy", defaultValue: policy.name, onChange: function (e) { return updateHolidayPolicyName(e.target.value); } }) }), _jsx(Col, { span: 14, children: _jsx(Select, { className: "PolicyNameInput w-full", placeholder: "Select holidays", defaultValue: policy.holidayMultipliers.map(function (multiplier) { return multiplier.id; }), onChange: function (e) { return updateHolidayPolicyMultipliers(e); }, mode: "multiple", children: holidayMultipliers.map(function (holiday) { return (_jsx(Option, { value: holiday.id, children: _jsx("div", { children: _jsx("span", { children: holiday.name }) }) }, holiday.id)); }) }) }), _jsx("div", { className: "PolicyWrapper ml-auto !mb-0", children: _jsx("div", { className: "ApplyToUsersWrapper !m-0", children: _jsx("div", { className: "RightSection !m-0", children: policy.isDefault ? (_jsx("div", { className: "DefaultButton", children: "Default" })) : (_jsx("div", { className: "WhiteButton cursor-pointer", onClick: function () { return makeHolidayPolicyDefault(id); }, children: "Make Default" })) }) }) })] }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this policy?", onConfirm: deleteHolidayPolicy, placement: "left", children: _jsx("div", { className: "GroupRuleDeleteBtn absolute top-[25%] right-[-50px]", children: _jsx(TrashOutlinedIcon, {}) }) })] }));
};
export default React.memo(HolidayPolicyItem);
