var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Empty } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { useRecoilValue } from 'recoil';
import { isEmpty } from 'lodash';
import Emitter from 'src/utils/EventEmitter';
import { EmitterEventType } from 'src/shared';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import { useDashboardComponent } from 'src/hooks/component/useDashboard';
import { LoadingOutlined } from '@ant-design/icons';
import { schedulingSnapshotOptionSettingState } from '../Atoms';
import { schedulingSnapshotStatusInfoMap } from '../Constant';
import ZiraRowsSkeleton from '../../../../ui/ZiraRowsSkeleton';
import SnapshotShiftCard from './SnapshotShiftCard';
export var SHIFT_LIST_PAGINATION_SIZE = 20;
var SchedulingSnapshotList = function (props) {
    var schedulingStatus = props.schedulingStatus, totalNumber = props.totalNumber, isTotalNumberLoading = props.isTotalNumberLoading;
    var schedulingStatusInfo = schedulingSnapshotStatusInfoMap[schedulingStatus];
    var schedulingSnapshotOptionsSetting = useRecoilValue(schedulingSnapshotOptionSettingState);
    var show = schedulingSnapshotOptionsSetting[schedulingStatus];
    var getSnapshotColSpanNumber = useDashboardComponent().getSnapshotColSpanNumber;
    var colSpanValue = getSnapshotColSpanNumber();
    var _a = useState(0), snapshotListPage = _a[0], setSnapshotListPage = _a[1];
    var _b = useState([]), shiftCards = _b[0], setShiftCards = _b[1];
    var useGetSnapshotShiftList = useDashboardApi().useGetSnapshotShiftList;
    var _c = useGetSnapshotShiftList({
        shiftStatus: schedulingStatus,
        pagination: {
            page: snapshotListPage,
            size: SHIFT_LIST_PAGINATION_SIZE,
        },
    }), data = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    useEffect(function () {
        Emitter.on(EmitterEventType.REFETCH_DASHBOARD_SNAPSHOT, refetch);
        return function () {
            Emitter.off(EmitterEventType.REFETCH_DASHBOARD_SNAPSHOT, refetch);
        };
    });
    useEffect(function () {
        if (data && snapshotListPage === 0) {
            setShiftCards(data.shiftCards);
        }
        else if (data && snapshotListPage > 0) {
            setShiftCards(function (prev) { return __spreadArray(__spreadArray([], prev, true), data.shiftCards, true); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    var onLoadMore = useCallback(function () {
        setSnapshotListPage(function (prev) { return prev + 1; });
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var listRef = useRef(null);
    var outerRef = useRef(null);
    var listColWidthRef = useRef(null);
    var _d = useState(140), listColWidth = _d[0], setListColWidth = _d[1];
    var modalHeaderObserver = useMemo(function () {
        return new ResizeObserver(function (entries) {
            if (entries[0]) {
                setListColWidth(entries[0].contentRect.width);
            }
        });
    }, []);
    useEffect(function () {
        if (listColWidthRef === null || listColWidthRef === void 0 ? void 0 : listColWidthRef.current) {
            modalHeaderObserver.observe(listColWidthRef.current);
        }
        return function () {
            modalHeaderObserver.disconnect();
        };
    }, [modalHeaderObserver, show]);
    var rowHeights = useRef({});
    var setRowHeight = useCallback(function (index, size) {
        var _a;
        listRef.current.resetAfterIndex(0);
        rowHeights.current = __assign(__assign({}, rowHeights.current), (_a = {}, _a[index] = size, _a));
    }, []);
    var getRowHeight = function (index) {
        return rowHeights.current[index] + 15 || 82;
    };
    var renderTotalNumber = useMemo(function () {
        if (isTotalNumberLoading) {
            return _jsx(LoadingOutlined, { spin: true });
        }
        else {
            return _jsx("div", { children: totalNumber !== null && totalNumber !== void 0 ? totalNumber : 0 });
        }
    }, [isTotalNumberLoading, totalNumber]);
    var handleScroll = useCallback(function (event) {
        if (!outerRef.current) {
            return;
        }
        if (event.scrollOffset !== 0 &&
            outerRef.current.scrollHeight -
                (event.scrollOffset + outerRef.current.offsetHeight) <
                5 &&
            !isLoading) {
            onLoadMore();
        }
    }, [isLoading, onLoadMore]);
    if (!show)
        return _jsx(_Fragment, {});
    return (_jsxs(Col, { span: colSpanValue, ref: listColWidthRef, style: { padding: 8 }, children: [_jsxs("div", { className: "mb-5 pl-[10px]", style: {
                    width: listColWidth,
                }, children: [_jsxs("div", { className: "mb-3 flex items-center justify-center font-medium", style: {
                            color: schedulingStatusInfo.headerColor,
                        }, children: [_jsx("div", { className: "mr-1", children: schedulingStatusInfo.displayStatusName }), renderTotalNumber] }), _jsx("div", { className: "ShiftStatusBar", style: {
                            backgroundColor: schedulingStatus === 'UPCOMING'
                                ? '#E9E9EC'
                                : schedulingStatusInfo.headerColor,
                        } })] }), isLoading && snapshotListPage === 0 ? (_jsx("div", { className: "mt-5 pl-2", children: _jsx(ZiraRowsSkeleton, { rowNumber: 5 }) })) : isEmpty(shiftCards) ? (_jsx(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, style: { height: 400, margin: 0 } })) : (_jsx(List, { ref: listRef, height: 400, itemCount: shiftCards.length, itemSize: getRowHeight, width: listColWidth + 12, onScroll: handleScroll, outerRef: outerRef, children: function (_a) {
                    var index = _a.index, style = _a.style;
                    var shiftInfo = shiftCards[index];
                    var cardColor = schedulingStatusInfo.cardColor;
                    return (_jsx(SnapshotShiftCard, { index: index, style: style, cardColor: cardColor, shiftInfo: shiftInfo, setRowHeight: setRowHeight }));
                } }))] }));
};
export default React.memo(SchedulingSnapshotList);
