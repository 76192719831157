var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Droppable } from 'react-beautiful-dnd';
import MobileCustomJobPlacementDraggableItem from './MobileCustomJobPlacementDraggableItem';
var MobileCustomJobPlacementDroppableSection = function (_a) {
    var droppableId = _a.droppableId, fields = _a.fields, onToggle = _a.onToggle;
    var hasItemsInSection = fields.length > 0;
    return (_jsx(Droppable, { droppableId: droppableId, isDropDisabled: ['title', 'imageTag', 'titleAccent'].includes(droppableId) && hasItemsInSection, children: function (provided, snapshot) { return (_jsxs("div", __assign({ className: "flex w-full flex-col min-h-[40px] ".concat(hasItemsInSection ? 'p-2' : 'items-center justify-center', " ").concat(hasItemsInSection || snapshot.isDraggingOver ? '' : 'bg-gray-50') }, provided.droppableProps, { ref: provided.innerRef, children: [_jsx("div", { className: "flex w-full flex-col space-y-3", children: fields.map(function (field, idx) {
                        var _a;
                        return field.schema && (_jsx(MobileCustomJobPlacementDraggableItem, { field: field, index: idx, onToggle: function (field) {
                                return onToggle(field, droppableId);
                            } }, "selected-schema-".concat((_a = field.schema) === null || _a === void 0 ? void 0 : _a.id)));
                    }) }), !hasItemsInSection && (_jsx("div", { className: "flex items-center justify-center text-xs text-gray-500 font-semibold", children: snapshot.isDraggingOver
                        ? ''
                        : "Drag ".concat(droppableId === 'otherFields' ? 'fields' : 'field', " here") })), provided.placeholder] }))); } }));
};
export default MobileCustomJobPlacementDroppableSection;
