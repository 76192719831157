import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense } from 'react';
import DummyTextCell from '../DummyTextCell';
var LogicPhoneInputCell = lazy(function () { return import('./LogicPhoneInputCell'); });
var PhoneInputCellHOC = function (props) {
    var phone = props.phone, editCell = props.editCell, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked;
    var dummyCell = (_jsx(_Fragment, { children: !isClicked && (_jsx(DummyTextCell, { text: phone, setIsClicked: setIsClicked, canEdit: canEdit })) }));
    return (_jsxs(_Fragment, { children: [isClicked && canEdit && (_jsx(Suspense, { fallback: dummyCell, children: _jsx(LogicPhoneInputCell, { phone: phone, editCell: editCell, setIsClicked: setIsClicked }) })), dummyCell] }));
};
export default React.memo(PhoneInputCellHOC);
