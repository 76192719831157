import { jsx as _jsx } from "react/jsx-runtime";
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React, { useMemo } from 'react';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import usePayRateV2Api from '../../../usePayRateV2Api';
var DefaultRateRecordRowActionMenu = function (_a) {
    var defaultRateId = _a.defaultRateId, openDuplicateActionModal = _a.openDuplicateActionModal;
    var deleteDefaultRate = usePayRateV2Api().deleteDefaultRate;
    var dropdownMenuItems = useMemo(function () { return [
        {
            key: 'duplicate',
            label: 'Duplicate',
            onClick: function (info) {
                info.domEvent.stopPropagation();
                openDuplicateActionModal();
            },
        },
        {
            type: 'divider',
        },
        {
            key: 'delete',
            label: (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this default rate?", okText: "Delete", onConfirm: function () { return deleteDefaultRate.mutateAsync(defaultRateId); }, placement: "topLeft", children: _jsx("div", { className: "font-medium text-red-1000", children: "Delete" }) })),
            onClick: function (info) {
                info.domEvent.stopPropagation();
            },
        },
    ]; }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultRateId, openDuplicateActionModal]);
    return (_jsx(Dropdown, { menu: { items: dropdownMenuItems }, trigger: ['hover'], overlayClassName: "DefaultRateRecordRowActionDropdownMenu", children: _jsx("div", { onClick: function (e) { return e.stopPropagation(); }, className: "absolute right-[-40px]", children: _jsx(EllipsisOutlined, { className: "text-[24px] rotate-90" }) }) }));
};
export default React.memo(DefaultRateRecordRowActionMenu);
