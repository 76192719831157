var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { useHealthMetricApi } from 'src/hooks/api';
import { chosenMetricWithDataState, deletedRuleIdsState } from './Atoms';
var DeleteRuleButton = function (_a) {
    var ruleId = _a.ruleId;
    var useDeleteMetricRuleMutation = useHealthMetricApi().useDeleteMetricRuleMutation;
    var _b = useDeleteMetricRuleMutation(), mutate = _b.mutate, isLoading = _b.isLoading;
    var resetChosenMetric = useResetRecoilState(chosenMetricWithDataState);
    var setDeletedRuleIds = useSetRecoilState(deletedRuleIdsState);
    var navigate = useNavigate();
    var handleDeleteRule = useCallback(function () {
        mutate(ruleId, {
            onSuccess: function (res) {
                if (res) {
                    resetChosenMetric();
                    setDeletedRuleIds(function (old) { return __spreadArray(__spreadArray([], old, true), [ruleId], false); });
                    navigate('/home/goals');
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, ruleId]);
    if (isLoading)
        return (_jsx(Button, { className: "bg-negative text-white hover:bg-negative hover:text-white", loading: true }));
    return (_jsx(ZiraDeletePopconfirm, { title: "Are you sure deleting this metric?", onConfirm: handleDeleteRule, children: _jsx("div", { className: "flex items-center justify-center rounded-md bg-red-300 px-3 py-2 font-medium shadow-block hover:cursor-pointer hover:bg-red-500", children: _jsx(DeleteOutlined, { style: { color: 'white' } }) }) }));
};
export default React.memo(DeleteRuleButton);
