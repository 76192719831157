import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { collectionTableSelectedRecordIdsState, tableCollectionRowsState } from 'src/state';
import { intersection } from 'lodash';
import BulkEditColumnButton from './BulkEditColumnButton';
import BulkEditDeleteButton from './BulkEditDeleteButton';
import './BulkEditButtonOptions.scss';
var BulkEditButtonOptions = function (_a) {
    var _b, _c;
    var blockId = _a.blockId, reverseRelationId = _a.reverseRelationId, collectionId = _a.collectionId;
    var _d = useRecoilState(collectionTableSelectedRecordIdsState((_b = blockId !== null && blockId !== void 0 ? blockId : reverseRelationId) !== null && _b !== void 0 ? _b : '')), selectedRecordIds = _d[0], setSelectedRecordIds = _d[1];
    var numberOfSelectedRecords = selectedRecordIds.length;
    var tableRows = useRecoilValue(tableCollectionRowsState((_c = blockId !== null && blockId !== void 0 ? blockId : reverseRelationId) !== null && _c !== void 0 ? _c : ''));
    var tableRowIds = useMemo(function () { return tableRows.map(function (row) { return row.recordId; }); }, [tableRows]);
    //! Reset selectedRecordIds when unmount
    useEffect(function () {
        return function () { return setSelectedRecordIds([]); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //! Unselect records not in table anymore
    useEffect(function () {
        var filteredSelectedRecordsIds = intersection(selectedRecordIds, tableRowIds);
        if (filteredSelectedRecordsIds.length !== selectedRecordIds.length) {
            setSelectedRecordIds(filteredSelectedRecordsIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableRowIds]);
    if (!blockId && !reverseRelationId)
        return null;
    return (_jsx("div", { className: "BulkEditButtonOptions h-[38px] flex items-center w-max", children: _jsxs("div", { className: "flex items-center text-sm font-medium bg-white cursor-pointer rounded-lg border border-gray-60", children: [_jsxs("div", { className: "BulkEditButtonOption text-accent", onClick: function () { return setSelectedRecordIds([]); }, children: [numberOfSelectedRecords, " selected"] }), _jsx(BulkEditColumnButton, { blockId: blockId, reverseRelationId: reverseRelationId, collectionId: collectionId, selectedRecordIds: selectedRecordIds }), _jsx(BulkEditDeleteButton, { collectionId: collectionId, selectedRecordIds: selectedRecordIds, setSelectedRecordIds: setSelectedRecordIds })] }) }));
};
export default React.memo(BulkEditButtonOptions);
