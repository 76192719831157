import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import { useContext } from 'react';
import { RollupType } from 'src/components/RollupConfigurationModal/interface/constants';
import { RollupConfigurationContext } from '../RollupConfigurationModal';
var RollupTypeSelector = function () {
    var options = [
        {
            value: RollupType.COUNT,
            label: 'Count',
        },
        {
            value: RollupType.PROGRESS_TRACKER,
            label: 'Progress Tracker',
        },
        {
            value: RollupType.SUM,
            label: 'Sum',
        },
        {
            value: RollupType.AVERAGE,
            label: 'Average',
        },
    ];
    var _a = useContext(RollupConfigurationContext), rollupConfiguration = _a.rollupConfiguration, setRollupType = _a.setRollupType;
    return (_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Type" }), _jsx(Select, { options: options, onSelect: function (value) {
                    setRollupType(value);
                }, value: rollupConfiguration.rollupType, defaultValue: options[0].value, className: "w-[161px]" })] }));
};
export default RollupTypeSelector;
