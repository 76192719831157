import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense, useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { billRateValueShouldManuallyUpdateState, collectionTableSchemaSelector, payRateValueShouldManuallyUpdateState, } from 'src/state';
import { NativePath } from 'src/shared';
import DummyTextCell from './DummyTextCell';
var LogicTextCell = lazy(function () { return import('./TextCell/LogicTextCell'); });
var NumberCellHOC = function (props) {
    var amount = props.amount, editCell = props.editCell, cellPos = props.cellPos, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked, isModalCell = props.isModalCell;
    var schema = useRecoilValue(collectionTableSchemaSelector({
        collectionId: cellPos.collectionId,
        schemaId: cellPos.schemaId,
    }));
    var setPayRateValueShouldManuallyUpdate = useSetRecoilState(payRateValueShouldManuallyUpdateState(cellPos));
    var setBillRateValueShouldManuallyUpdate = useSetRecoilState(billRateValueShouldManuallyUpdateState(cellPos));
    var editNumberCell = useCallback(function (newValues) {
        if ((schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_WAGE) {
            setPayRateValueShouldManuallyUpdate(true);
        }
        if ((schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_BILL_RATE) {
            setBillRateValueShouldManuallyUpdate(true);
        }
        editCell(newValues);
    }, [
        editCell,
        schema === null || schema === void 0 ? void 0 : schema.nativePath,
        setBillRateValueShouldManuallyUpdate,
        setPayRateValueShouldManuallyUpdate,
    ]);
    var displayValue = useMemo(function () {
        var value = amount.value;
        var symbol = amount.symbol;
        if (!value) {
            return '';
        }
        else {
            if (symbol) {
                var currencyValue = Number(value).toLocaleString(undefined, {
                    style: 'currency',
                    currency: symbol,
                    minimumFractionDigits: 2,
                });
                return currencyValue;
            }
            else {
                return value;
            }
        }
    }, [amount.symbol, amount.value]);
    var dummyCell = (_jsx(_Fragment, { children: !isClicked && (_jsx(DummyTextCell, { text: displayValue, setIsClicked: setIsClicked, canEdit: canEdit })) }));
    return (_jsxs(_Fragment, { children: [isClicked && (_jsx(Suspense, { fallback: dummyCell, children: _jsx(LogicTextCell, { text: amount.value, editCell: editNumberCell, isClicked: isClicked, setIsClicked: setIsClicked, cellPos: cellPos, isModalCell: isModalCell }) })), dummyCell] }));
};
export default React.memo(NumberCellHOC);
