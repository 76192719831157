var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilCallback } from 'recoil';
import { CollectionTableType, CollectionType, DefaultPagePagination, EmitterEventType, } from 'src/shared';
import { tableCollectionCurrentSizeSelector, blockContentTypeSelector, collectionIdToBlockIdsState, collectionTableSchemaSelector, tableIsBoardViewSelector, tableSearchTextState, tableDisplaySettingsState, collectionTableTypeSelector, } from 'src/state';
import { getColumnTypeKey, isNativePrimaryColumn } from 'src/utils/Collection';
import Emitter from 'src/utils/EventEmitter';
import { ShiftSchemasThatRequireRefetchSet } from 'src/components/ScheduleView/Constant';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { useCollectionComponent } from './useCollection';
export var useRefetchCollection = function () {
    var queryClient = useQueryClient();
    var fetchAndProcessBlockCollectionIntoState = useCollectionComponent().fetchAndProcessBlockCollectionIntoState;
    var refetchTableViewBlock = useCallback(function (blockId) {
        queryClient.invalidateQueries(['listBlock', blockId]);
        queryClient.invalidateQueries(['blockViewCounts', blockId]);
    }, [queryClient]);
    var refetchBoardViewBlock = useCallback(function (blockId) {
        queryClient.invalidateQueries(['blockBoardViewGroups', blockId]);
        queryClient.invalidateQueries(['blockViewCounts', blockId]);
    }, [queryClient]);
    var refetchAllBlocks = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockIds) {
            Promise.all(blockIds.map(function (blockId) { return __awaiter(void 0, void 0, void 0, function () {
                var isBoardView;
                return __generator(this, function (_a) {
                    isBoardView = snapshot
                        .getLoadable(tableIsBoardViewSelector(blockId))
                        .valueMaybe();
                    if (isBoardView) {
                        refetchBoardViewBlock(blockId);
                    }
                    else {
                        refetchTableViewBlock(blockId);
                    }
                    return [2 /*return*/];
                });
            }); }));
        };
    }, [refetchBoardViewBlock, refetchTableViewBlock]);
    var refetchRelatedCollections = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (collectionId) {
            // Refetch directly related collections
            var collectionType = snapshot
                .getLoadable(collectionTableTypeSelector({
                type: CollectionTableType.TABLE,
                collectionId: collectionId,
            }))
                .valueMaybe();
            if (collectionType === CollectionType.SHIFT) {
                var shiftGroupCollectionId = snapshot
                    .getLoadable(standardCollectionIdSelector(CollectionType.SHIFT_GROUP))
                    .valueMaybe();
                if (shiftGroupCollectionId) {
                    refetchCollection(shiftGroupCollectionId);
                }
            }
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var refetchCollection = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (collectionId, recordAction, updatedCellsSchemaIds) {
            if (!collectionId)
                return;
            Emitter.emit(EmitterEventType.REFETCH_COLLECTION, collectionId);
            // Refetch reverse collection & record metrics in detail modal
            queryClient.invalidateQueries(['reverseCollection', collectionId]);
            setTimeout(function () { return queryClient.invalidateQueries(['recordMetrics']); }, 0); // Delay so not refetched for deleted record
            var blockIds = snapshot
                .getLoadable(collectionIdToBlockIdsState(collectionId))
                .valueMaybe();
            if (blockIds) {
                // Refetch block collections
                switch (recordAction) {
                    case 'update':
                        if (!updatedCellsSchemaIds) {
                            refetchAllBlocks(blockIds);
                            break;
                        }
                        Promise.all(blockIds.map(function (blockId) { return __awaiter(void 0, void 0, void 0, function () {
                            var isBlockCollectionTable, blockDisplaySettings, schemaIdInFilters, schemaIdInSort, isShiftStatusRelatedSchema, isBoardView, hasTitleSchema, _i, updatedCellsSchemaIds_1, schemaId, schema, searchText;
                            var _a;
                            return __generator(this, function (_b) {
                                isBlockCollectionTable = ((_a = snapshot
                                    .getLoadable(blockContentTypeSelector(blockId))
                                    .valueMaybe()) !== null && _a !== void 0 ? _a : '') === 'collection';
                                if (isBlockCollectionTable) {
                                    blockDisplaySettings = snapshot
                                        .getLoadable(tableDisplaySettingsState(blockId))
                                        .valueMaybe();
                                    schemaIdInFilters = blockDisplaySettings === null || blockDisplaySettings === void 0 ? void 0 : blockDisplaySettings.filters.find(function (filter) {
                                        return updatedCellsSchemaIds.includes(filter.property);
                                    });
                                    schemaIdInSort = blockDisplaySettings === null || blockDisplaySettings === void 0 ? void 0 : blockDisplaySettings.sortBy.find(function (sort) { var _a; return updatedCellsSchemaIds.includes((_a = sort.property) !== null && _a !== void 0 ? _a : ''); });
                                    isShiftStatusRelatedSchema = snapshot
                                        .getLoadable(collectionTableTypeSelector({
                                        type: CollectionTableType.TABLE,
                                        collectionId: collectionId,
                                    }))
                                        .valueMaybe()
                                        ? updatedCellsSchemaIds.some(function (schemaId) {
                                            var schema = snapshot
                                                .getLoadable(collectionTableSchemaSelector({
                                                collectionId: collectionId,
                                                schemaId: schemaId,
                                            }))
                                                .valueMaybe();
                                            return ((schema === null || schema === void 0 ? void 0 : schema.nativePath) &&
                                                ShiftSchemasThatRequireRefetchSet.has(schema.nativePath));
                                        })
                                        : false;
                                    isBoardView = snapshot
                                        .getLoadable(tableIsBoardViewSelector(blockId))
                                        .valueMaybe();
                                    if (isBoardView) {
                                        refetchBoardViewBlock(blockId);
                                        return [2 /*return*/];
                                    }
                                    if (schemaIdInFilters ||
                                        schemaIdInSort ||
                                        isShiftStatusRelatedSchema) {
                                        // Refetch if updated cells' schemaIds exist in filter/sort
                                        refetchTableViewBlock(blockId);
                                        return [2 /*return*/];
                                    }
                                    else {
                                        hasTitleSchema = false;
                                        for (_i = 0, updatedCellsSchemaIds_1 = updatedCellsSchemaIds; _i < updatedCellsSchemaIds_1.length; _i++) {
                                            schemaId = updatedCellsSchemaIds_1[_i];
                                            schema = snapshot
                                                .getLoadable(collectionTableSchemaSelector({
                                                collectionId: collectionId,
                                                schemaId: schemaId,
                                            }))
                                                .valueMaybe();
                                            if (schema &&
                                                (getColumnTypeKey(schema.inputMode, schema.type, schema.nativePath) === 'title' ||
                                                    isNativePrimaryColumn(schema))) {
                                                hasTitleSchema = true;
                                                break;
                                            }
                                        }
                                        if (hasTitleSchema) {
                                            searchText = snapshot
                                                .getLoadable(tableSearchTextState(blockId))
                                                .valueMaybe();
                                            if (searchText) {
                                                // Refetch if one of updated cells' schemaIds is search title schema with search text
                                                refetchTableViewBlock(blockId);
                                                return [2 /*return*/];
                                            }
                                        }
                                    }
                                }
                                else {
                                    refetchTableViewBlock(blockId);
                                }
                                return [2 /*return*/];
                            });
                        }); }));
                        break;
                    case 'create':
                        Promise.all(blockIds.map(function (blockId) { return __awaiter(void 0, void 0, void 0, function () {
                            var isBlockCollectionTable, blockDisplaySettings, searchText, isBoardView, currentCollectionSize, request;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                isBlockCollectionTable = ((_a = snapshot
                                    .getLoadable(blockContentTypeSelector(blockId))
                                    .valueMaybe()) !== null && _a !== void 0 ? _a : '') === 'collection';
                                if (isBlockCollectionTable) {
                                    blockDisplaySettings = snapshot
                                        .getLoadable(tableDisplaySettingsState(blockId))
                                        .valueMaybe();
                                    searchText = snapshot
                                        .getLoadable(tableSearchTextState(blockId))
                                        .valueMaybe();
                                    isBoardView = snapshot
                                        .getLoadable(tableIsBoardViewSelector(blockId))
                                        .valueMaybe();
                                    if (isBoardView) {
                                        refetchBoardViewBlock(blockId);
                                        return [2 /*return*/];
                                    }
                                    if ((blockDisplaySettings === null || blockDisplaySettings === void 0 ? void 0 : blockDisplaySettings.filters.length) ||
                                        (blockDisplaySettings === null || blockDisplaySettings === void 0 ? void 0 : blockDisplaySettings.sortBy.length) ||
                                        searchText) {
                                        // Refetch if block has any filter/sort/search
                                        refetchTableViewBlock(blockId);
                                        return [2 /*return*/];
                                    }
                                    else {
                                        currentCollectionSize = (_b = snapshot
                                            .getLoadable(tableCollectionCurrentSizeSelector(blockId))
                                            .valueMaybe()) !== null && _b !== void 0 ? _b : -1;
                                        if (blockDisplaySettings &&
                                            currentCollectionSize >= 0 &&
                                            currentCollectionSize < DefaultPagePagination.size) {
                                            request = {
                                                blockId: blockId,
                                                displaySettings: blockDisplaySettings,
                                                pagination: {
                                                    page: currentCollectionSize,
                                                    size: 1,
                                                },
                                            };
                                            fetchAndProcessBlockCollectionIntoState(request);
                                            return [2 /*return*/];
                                        }
                                    }
                                }
                                refetchTableViewBlock(blockId);
                                return [2 /*return*/];
                            });
                        }); }));
                        break;
                    case 'delete':
                        refetchAllBlocks(blockIds);
                        break;
                    default:
                        refetchAllBlocks(blockIds);
                }
            }
            refetchRelatedCollections(collectionId);
        };
    }, [
        fetchAndProcessBlockCollectionIntoState,
        queryClient,
        refetchAllBlocks,
        refetchBoardViewBlock,
        refetchTableViewBlock,
        refetchRelatedCollections,
    ]);
    return refetchCollection;
};
