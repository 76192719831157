import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { MobilePreviewExternalLink } from 'src/utils/icons/TaskIcons/MobilePreviewExternalLink';
import { useAccountApi } from 'src/hooks/api';
import { MobilePreviewTaskTemplateLink } from 'src/utils/icons/TaskIcons/MobilePreviewTaskTemplateLink';
import { TaskTemplateType, } from '../interface';
import TaskListItemTag from '../TasksSettingView/TaskTags/TaskListItemTag';
import './TaskTemplateMobilePreview.scss';
import CustomFieldMobilePreview from './TaskCustomFields/CustomFieldMobilePreview';
import ProfileTaskSchemaMobilePreview from './ProfileDataTypeTask/ProfileTaskSchemaMobilePreview';
var TaskTemplateMobilePreview = function (_a) {
    var _b, _c, _d;
    var templateInfo = _a.templateInfo;
    var templateType = templateInfo.type, daysUntilDue = templateInfo.daysUntilDue;
    var useGetAccountDocSigningTemplatesListQuery = useAccountApi().useGetAccountDocSigningTemplatesListQuery;
    var signingTemplates = useGetAccountDocSigningTemplatesListQuery().data;
    var isExternalLinkType = templateType === TaskTemplateType.EXTERNAL_LINK;
    var isViewOnlyType = templateType === TaskTemplateType.VIEW_ONLY;
    var isProfileDataType = templateType === TaskTemplateType.PROFILE_DATA;
    var isSigningPartnerType = templateType === TaskTemplateType.SIGNING_PARTNER;
    var customFieldValues = (_b = templateInfo.customFieldInfoAndView.customFieldInfo) === null || _b === void 0 ? void 0 : _b.customFieldSchemas.filter(function (field) { return field.visible && field.value; }).map(function (field) { return field.value; });
    var templateName = useMemo(function () {
        var _a, _b;
        var hasSigningTemplates = signingTemplates && (signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.length) > 0;
        var templateId = (_a = templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData) === null || _a === void 0 ? void 0 : _a.templateId;
        var shouldRenderTemplateButton = isSigningPartnerType && hasSigningTemplates && templateId;
        if (shouldRenderTemplateButton) {
            return (_b = signingTemplates.templates.find(function (template) { return template.templateId === templateId; })) === null || _b === void 0 ? void 0 : _b.templateName;
        }
    }, [isSigningPartnerType, signingTemplates, templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData]);
    var MobileLinkButton = (_jsxs("div", { className: "OpenLinkButtonPreview font-semibold mr-5 ml-5 mt-3", children: [_jsx("span", { className: "mr-1", children: isExternalLinkType
                    ? 'Open Link'
                    : isViewOnlyType
                        ? (templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData).buttonLabel || 'Confirm'
                        : isSigningPartnerType
                            ? (templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData).buttonLabel || 'Confirm'
                            : isProfileDataType
                                ? 'Submit'
                                : 'Open Workspace' }), isExternalLinkType && _jsx(MobilePreviewExternalLink, {})] }));
    var taskSchemas = ((_c = templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData) === null || _c === void 0 ? void 0 : _c.type) === TaskTemplateType.PROFILE_DATA &&
        ((_d = templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData) === null || _d === void 0 ? void 0 : _d.taskSchemas);
    var divider = _jsx("div", { className: "flex items-center gap-4 border-b border-b-gray-100 pt-3" });
    var daysText = daysUntilDue === 1 ? '1 day' : "".concat(daysUntilDue, " days");
    return (_jsxs("div", { className: "TemplateMobilePreview select-none", children: [_jsxs("div", { children: [_jsx("div", { className: "font-semibold text-lg ml-5 mr-5", children: templateInfo.name }), _jsxs("div", { className: "ml-5 mr-5", children: [_jsx(TaskListItemTag, { backgroundColor: "#FFC2C2", margin: "10px 8px 0 0", children: _jsx("span", { style: { color: '#B01919' }, children: "Incomplete" }) }), templateInfo.customFieldInfoAndView.customFieldInfo.dueDateVisible &&
                                daysUntilDue && (_jsx(TaskListItemTag, { backgroundColor: "rgb(255, 236, 200)", children: _jsx("span", { style: { color: 'rgb(186, 124, 0)' }, children: "Due in ".concat(daysText) }) }))] }), templateInfo.customFieldInfoAndView.customFieldInfo.descriptionVisible &&
                        templateInfo.description && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "ml-5 mr-5 mt-3", children: [_jsx("div", { className: "text-gray-400 font-semibold mb-1", children: "Description" }), _jsx("div", { children: templateInfo.description })] }), Object.keys(customFieldValues).length ? divider : _jsx(_Fragment, {})] })), Object.keys(customFieldValues).length ? (_jsxs(_Fragment, { children: [_jsx(CustomFieldMobilePreview, { customFieldValues: customFieldValues, templateId: templateInfo.id }), divider] })) : (_jsx(_Fragment, {})), taskSchemas && (_jsx(ProfileTaskSchemaMobilePreview, { templateId: templateInfo.id, taskData: templateInfo === null || templateInfo === void 0 ? void 0 : templateInfo.taskData })), isSigningPartnerType && (_jsxs("div", { className: "TemplateLinkButtonPreview font-semibold mr-5 ml-5 mt-5", children: [_jsx("span", { className: "mr-1", children: templateName || 'Open Document' }), _jsx(MobilePreviewTaskTemplateLink, {})] }))] }), _jsx(_Fragment, { children: MobileLinkButton })] }));
};
export default React.memo(TaskTemplateMobilePreview);
