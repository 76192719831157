var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var MobilePreviewImagePlaceholderSvg = function () { return (_jsxs("svg", { width: "276", height: "226", viewBox: "0 0 276 226", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "path-1-inside-1_13292_7802", fill: "white", children: _jsx("path", { d: "M0.269287 0.633301H275.73V225.949H0.269287V0.633301Z" }) }), _jsx("path", { d: "M275.73 225.196H0.269287V226.701H275.73V225.196Z", fill: "black", fillOpacity: "0.1", mask: "url(#path-1-inside-1_13292_7802)" }), _jsx("circle", { cx: "19.2253", cy: "19.5893", r: "18.956", fill: "#DBDBDB" }), _jsx("rect", { width: "95.5834", height: "6.021", rx: "3.0105", transform: "matrix(1 0 0 -1 47.2125 16.5786)", fill: "#8F8F8F" }), _jsx("rect", { width: "75.2625", height: "6.021", rx: "3.0105", transform: "matrix(1 0 0 -1 47.2125 28.6206)", fill: "#D9D9D9" }), _jsx("rect", { width: "275.461", height: "6.021", rx: "3.0105", transform: "matrix(1 0 0 -1 0.269287 56.6084)", fill: "#8F8F8F" }), _jsx("rect", { width: "95.5834", height: "6.021", rx: "3.0105", transform: "matrix(1 0 0 -1 0.269287 71.6606)", fill: "#8F8F8F" }), _jsx("rect", { x: "0.269287", y: "83.7026", width: "275.461", height: "130.204", rx: "10.5368", fill: "#DBDBDB" })] })); };
export var MobilePreviewImagePlaceholder = function (props) { return (_jsx(Icon, __assign({ component: MobilePreviewImagePlaceholderSvg }, props))); };
