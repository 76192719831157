import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Select, Skeleton } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import LoadingStateAnimation from 'src/ui/LoadingStateAnimation';
import { ObjType } from 'src/shared';
import { usePageApi, useSetting } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
import { CollectionPermissionLevel, SchemaPermissionLevel } from '../../../Constants';
import CollectionPermissionDropdown from '../../../ObjectPermission/CollectionPermissionDropdown';
import SchemaPermissionOverviewContent from '../../../ObjectPermission/SchemaPermissionOverviewContent';
import AccessDetailHelperBanner from './AccessDetailHelperBanner';
var CustomCollectionIconUrl = 'https://teambridge.s3.us-west-2.amazonaws.com/images/block_icons/customIcon.jpg';
var DataAccessTab = function () {
    var headerWithDescription = function (header, description) { return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "font-semibold", children: [" ", header, " "] }), _jsxs("span", { className: "font-medium text-gray-500", children: [" ", description, " "] })] })); };
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    var _a = useSetting(), useGetDataSourcePermission = _a.useGetDataSourcePermission, useGetGlobalFilterCollectionOptions = _a.useGetGlobalFilterCollectionOptions, useGetAccessGroupGlobalFiltersQuery = _a.useGetAccessGroupGlobalFiltersQuery, useUpdateAccessGroupFilterMutation = _a.useUpdateAccessGroupFilterMutation;
    var useGetAccountBlockCategories = usePageApi().useGetAccountBlockCategories;
    var _b = useGetDataSourcePermission(accessGroupDetail.id), dataSourcePermission = _b.data, refetch = _b.refetch;
    var collectionInfo = useGetAccountBlockCategories().data;
    var _c = useGetGlobalFilterCollectionOptions(), globalFilterCollectionOptions = _c.data, isFetchingCollectionOptions = _c.isFetching;
    var _d = useGetAccessGroupGlobalFiltersQuery({
        accessGroupId: accessGroupDetail.id,
        options: {
            enabled: !!(accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.id) && !!collectionInfo,
        },
    }), globalFilters = _d.data, isFetchingFilters = _d.isFetching;
    var _e = useState([]), selectedGlobalFilterCollectionIds = _e[0], setSelectedGlobalFilterCollectionIds = _e[1];
    useEffect(function () {
        var collectionIds = !(globalFilters === null || globalFilters === void 0 ? void 0 : globalFilters.filters)
            ? []
            : globalFilters.filters.map(function (f) { return f.collectionId; });
        setSelectedGlobalFilterCollectionIds(collectionIds);
    }, [globalFilters]);
    var allCollectionInfo = Object.values((collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.blockCategoryMap) || {}).flat();
    var isLoading = !dataSourcePermission || !collectionInfo;
    var visibleData = useMemo(function () {
        return dataSourcePermission === null || dataSourcePermission === void 0 ? void 0 : dataSourcePermission.collections.filter(function (c) { return c.permission != CollectionPermissionLevel.NONE; });
    }, [dataSourcePermission === null || dataSourcePermission === void 0 ? void 0 : dataSourcePermission.collections]);
    var hiddenData = useMemo(function () {
        return dataSourcePermission === null || dataSourcePermission === void 0 ? void 0 : dataSourcePermission.collections.filter(function (c) { return c.permission == CollectionPermissionLevel.NONE; });
    }, [dataSourcePermission === null || dataSourcePermission === void 0 ? void 0 : dataSourcePermission.collections]);
    var collectionList = useCallback(function (collections) {
        return (_jsx(_Fragment, { children: isLoading ? (_jsx(Skeleton, {})) : (_jsx("div", { className: "ml-[-1em] mt-6", children: collections.map(function (collection) {
                    var info = allCollectionInfo.find(function (info) { return info.collectionId === collection.collectionId; });
                    return (_jsx(CollectionPermissionEntry, { accessGroupDetail: accessGroupDetail, info: info, collection: collection, onRefresh: function () { return refetch(); } }, collection.collectionId));
                }) })) }));
    }, [accessGroupDetail, allCollectionInfo, isLoading, refetch]);
    var _f = useUpdateAccessGroupFilterMutation(), updateGlobalFilterRequest = _f.mutate, isUpdatingGlobalFilter = _f.isLoading, hasUpdateGlobalFilterError = _f.isError;
    var _g = useState(false), selectorDropdownOpen = _g[0], setSelectorDropdownOpen = _g[1];
    var onUpdateGlobalFilterCollectionIds = useCallback(function (collectionIds) {
        updateGlobalFilterRequest({
            accessGroupId: accessGroupDetail.id,
            filterCollectionIds: collectionIds,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessGroupDetail]);
    var onRemoveCollectionOptionTag = useCallback(function (value) {
        var selectedIds = selectedGlobalFilterCollectionIds.filter(function (itemId) { return itemId !== value; });
        if (!selectorDropdownOpen) {
            onUpdateGlobalFilterCollectionIds(selectedIds);
        }
    }, [onUpdateGlobalFilterCollectionIds, selectedGlobalFilterCollectionIds, selectorDropdownOpen]);
    var onDropdownVisibleChange = useCallback(function (open) {
        setSelectorDropdownOpen(open);
        if (!open) {
            onUpdateGlobalFilterCollectionIds(selectedGlobalFilterCollectionIds);
        }
    }, [onUpdateGlobalFilterCollectionIds, selectedGlobalFilterCollectionIds]);
    return (_jsxs("div", { className: "AccessTabContainer", children: [_jsx(AccessDetailHelperBanner, { title: 'Data Access', description: 'Give your team access to only the data they need to keep their workflows simple and keep access under control.' }), _jsx("div", { className: "AccessMainTitle", children: "Data Filters" }), !collectionInfo ||
                !globalFilters ||
                isFetchingFilters ||
                isFetchingCollectionOptions ||
                !globalFilterCollectionOptions ? (_jsx(Skeleton, {})) : (_jsxs("div", { className: "mt-5 flex items-center", children: [_jsx("span", { className: "font-medium", children: "Users can only view data filtered by their" }), _jsx("div", { className: "grow" }), _jsxs("div", { className: "flex items-center space-x-2", children: [_jsx(LoadingStateAnimation, { isLoading: isUpdatingGlobalFilter, loadingFailed: hasUpdateGlobalFilterError }), _jsx(Select, { open: selectorDropdownOpen, mode: "multiple", className: "GlobalFilterCollectionSelector".concat(selectedGlobalFilterCollectionIds.length > 0
                                    ? ''
                                    : ' EmptyGlobalFilterCollectionSelector'), placeholder: "Select a collection", value: selectedGlobalFilterCollectionIds, onDropdownVisibleChange: onDropdownVisibleChange, onChange: setSelectedGlobalFilterCollectionIds, onDeselect: onRemoveCollectionOptionTag, children: globalFilterCollectionOptions.map(function (option) { return (_jsx(Select.Option, { value: option.id, children: option.name }, option.id)); }) })] })] })), _jsx(Divider, { className: "mb-10 mt-10" }), _jsx("div", { className: "AccessMainTitle", children: "Data Source" }), _jsxs("div", { className: "mt-10", children: [headerWithDescription('Visible Data', 'Within the section below you can define which data source your users have access to. '), collectionList(visibleData || []), _jsx("div", { className: "h-10" }), headerWithDescription('Hidden Data', "Within the section below you can define which data source your users don't have access to. "), collectionList(hiddenData || [])] })] }));
};
var CollectionPermissionEntry = function (_a) {
    var accessGroupDetail = _a.accessGroupDetail, info = _a.info, collection = _a.collection, onRefresh = _a.onRefresh;
    var _b = useState(false), isColumnModalVisible = _b[0], setIsColumnModalVisible = _b[1];
    var collectionIconSrc = (info === null || info === void 0 ? void 0 : info.blockIcon) || CustomCollectionIconUrl;
    var useUpdateObjectPermission = useSetting().useUpdateObjectPermission;
    var _c = useUpdateObjectPermission(), updateCollectionPermission = _c.mutateAsync, isLoading = _c.isLoading, isError = _c.isError;
    var onHandleUpdatePermission = useCallback(function (permission) {
        updateCollectionPermission({
            accessGroupId: accessGroupDetail.id,
            objectId: collection.collectionId,
            permission: permission,
            type: ObjType.COLLECTION,
        }, {
            onSuccess: function () {
                onRefresh();
            },
        });
    }, [accessGroupDetail.id, collection.collectionId, onRefresh, updateCollectionPermission]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center space-x-2", children: [_jsx("img", { src: collectionIconSrc }), _jsx("div", { className: "font-medium", children: collection.collectionName }), _jsx("div", { className: "grow" }), _jsxs("div", { className: "flex items-center space-x-[0.2rem]", children: [_jsx(LoadingStateAnimation, { isLoading: isLoading, loadingFailed: isError }), _jsxs("div", { onClick: function () { return setIsColumnModalVisible(true); }, className: "mr-2 w-[6rem] rounded bg-gray-50 py-[0.4em] text-center font-medium text-gray-400 hover:cursor-pointer", children: [collection.schemas.filter(function (s) { return s.permission != SchemaPermissionLevel.NONE; }).length, ' ', "Columns"] }), _jsx(CollectionPermissionDropdown, { currentPermission: collection.permission, onUpdate: function (p) { return onHandleUpdatePermission(p); }, labelClassName: "rounded-sm bg-gray-50 py-[0.4em] w-[6rem] text-center px-2 font-medium text-gray-400 hover:cursor-pointer", canUpdate: accessGroupDetail.type !== 'owner' })] })] }, collection.collectionId), _jsx(Divider, { className: "mb-3 mt-0" }), _jsx(Modal, { className: "PermissionModal", open: isColumnModalVisible, onCancel: function () {
                    setIsColumnModalVisible(false);
                    onRefresh();
                }, footer: null, style: { height: '30vh', width: '540px' }, destroyOnClose: true, children: _jsx(SchemaPermissionOverviewContent, { collectionIconUrl: collectionIconSrc, collectionName: collection.collectionName, collectionSettings: collection.collectionSettings, schemas: collection.schemas }) })] }));
};
export default React.memo(DataAccessTab);
