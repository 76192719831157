var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import './PayRateTable.scss';
import SelectorCell from 'src/components/TableView/TableCell/SelectorCell';
import { useSetting } from 'src/hooks/api';
import { QualifierType } from 'src/shared';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { BillRateSettingType, } from './Interface';
import PayRateSettingTableBuilder from './PayRateSettingTableBuilder';
import BillRateInputCell from './PayRateTableV1Cell/BillRateInputCell';
import DateQualifierCell from './PayRateTableV1Cell/DateQualifierCell';
import NameInputCell from './PayRateTableV1Cell/NameInputCell';
import PayRateInputCell from './PayRateTableV1Cell/PayRateInputCell';
var SelectorCellWrapper = function (canEdit, wageId, collectionId, data, type, addQualifier, deleteQualifier, updateQualifier) {
    var _a = useSetting(), useGetCollectionOptionList = _a.useGetCollectionOptionList, useGetSelectionOptionList = _a.useGetSelectionOptionList;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var displayedOption = data
        ? [
            {
                id: data.recordId,
                text: data.displayName,
                color: '',
            },
        ]
        : [];
    var _c = useState([]), availableOptions = _c[0], setAvailableOptions = _c[1];
    var collectionList = useGetCollectionOptionList({
        collectionId: collectionId,
        options: {
            enabled: type === QualifierType.COLLECTION,
        },
    }).data;
    var selectionList = useGetSelectionOptionList({
        groupId: collectionId,
        options: {
            enabled: type === QualifierType.SELECTION,
        },
    }).data;
    useEffect(function () {
        if (collectionList) {
            var availableOptions_1 = collectionList === null || collectionList === void 0 ? void 0 : collectionList.records.map(function (option) {
                return {
                    id: option.id,
                    text: option.title,
                    color: option.color || '',
                };
            });
            setAvailableOptions(availableOptions_1);
        }
    }, [collectionId, collectionList]);
    useEffect(function () {
        if (selectionList) {
            var availableOptions_2 = selectionList === null || selectionList === void 0 ? void 0 : selectionList.options.map(function (option) {
                return {
                    id: option.id,
                    text: option.title,
                    color: option.color || '',
                };
            });
            setAvailableOptions(availableOptions_2);
        }
    }, [collectionId, selectionList]);
    var getQualifierName = function (id) {
        var targetQualifier = _.find(availableOptions, function (option) { return option.id === id; });
        return targetQualifier === null || targetQualifier === void 0 ? void 0 : targetQualifier.text;
    };
    return (_jsx(SelectorCell, { inputMode: "single", displayedOption: displayedOption, availableOption: availableOptions, mutateDisplayedValueInItem: function (values) {
            if (!values[0]) {
                deleteQualifier(data.wageId, collectionId);
            }
            else if (displayedOption.length === 0) {
                var name_1 = getQualifierName(values[0]);
                addQualifier(name_1, wageId, collectionId, values[0], type);
            }
            else {
                var name_2 = getQualifierName(values[0]);
                updateQualifier(name_2, data.wageId, collectionId, values[0]);
            }
        }, canEdit: canEdit, isDetailModal: false, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen }));
};
var DateQualifierCellWrapper = function (canEdit, wageId, schemaId, data, type, addQualifier, deleteQualifier, updateQualifier) {
    var _a, _b;
    return (_jsx(DateQualifierCell, { defaultModifier: (_a = data === null || data === void 0 ? void 0 : data.symbol) !== null && _a !== void 0 ? _a : '', defaultTime: (_b = data === null || data === void 0 ? void 0 : data.time) !== null && _b !== void 0 ? _b : '', onChange: function (symbol, time) {
            if ((!symbol || !time) && data) {
                deleteQualifier(wageId, schemaId);
            }
            else if (symbol && time && !data) {
                addQualifier(time, wageId, schemaId, symbol, type);
            }
            else if (symbol && time && data) {
                updateQualifier(time, wageId, schemaId, symbol);
            }
        }, canEdit: canEdit }));
};
var TextCellWrapper = function (canEditValue, nameInput, id, name, billRate, payRate, billRateSetting, currency, updateWageRecord, deleteWageRecord, isBillRate, canEditBillRateType) {
    var updateWageRecordName = useCallback(function (newName) {
        updateWageRecord(id, newName, undefined, undefined, undefined);
    }, [id, updateWageRecord]);
    var updatePayRate = useCallback(function (newPayRate) {
        var newBillRate = billRateSetting.type === BillRateSettingType.PERCENTAGE &&
            billRateSetting.percentage
            ? (newPayRate * billRateSetting.percentage) / 100
            : undefined;
        updateWageRecord(id, undefined, undefined, newPayRate, newBillRate);
    }, [billRateSetting, id, updateWageRecord]);
    var updateBillRate = useCallback(function (newBillRateSetting, newBillRate) {
        updateWageRecord(id, undefined, newBillRateSetting, undefined, newBillRate);
    }, [id, updateWageRecord]);
    if (nameInput) {
        return canEditValue ? (_jsxs("div", { className: "EditableWageName", children: [_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this record?", onConfirm: function () { return deleteWageRecord(id); }, placement: "bottom", children: _jsx(DeleteOutlined, {}) }), _jsx(NameInputCell, { name: name, updateWageRecordName: updateWageRecordName })] })) : (_jsx("div", { className: "UneditableWageName", children: name }));
    }
    else if (isBillRate) {
        return (_jsx(BillRateInputCell, { billRateSetting: billRateSetting, billRate: billRate, payRate: payRate, currency: currency, updateWageRecordBillRate: updateBillRate, canEditBillRateType: canEditBillRateType !== null && canEditBillRateType !== void 0 ? canEditBillRateType : true }));
    }
    return (_jsx(PayRateInputCell, { rate: payRate, currency: currency, updateWageRecordPayRate: updatePayRate }));
};
var PayRateTable = function (props) {
    var useGetAvailableQualifyObject = useSetting().useGetAvailableQualifyObject;
    var payRateData = props.payRateData, payRateDataOnChange = props.payRateDataOnChange, _a = props.canEdit, canEdit = _a === void 0 ? false : _a, showDuplicateWarning = props.showDuplicateWarning, defaultQualifiers = props.defaultQualifiers;
    var availableQualifyObject = useGetAvailableQualifyObject().data;
    var _b = useState(false), dropDownVisible = _b[0], setDropDownVisible = _b[1];
    // disable qualifiers that source data already contains
    var checkQualifierAvailability = useCallback(function (id) {
        var targetItem = payRateData === null || payRateData === void 0 ? void 0 : payRateData.qualifierCols.find(function (item) { return item.id === id; });
        if (targetItem) {
            return true;
        }
        return false;
    }, [payRateData]);
    // pay rate data edit functions
    var addNewWageRecord = useCallback(function () {
        var newPayRateData = __assign({}, payRateData);
        var newWageData = {
            id: uuidv4(),
            name: 'Untitled Value',
            regularRate: 0,
            billRate: 0,
            qualifiers: defaultQualifiers !== null && defaultQualifiers !== void 0 ? defaultQualifiers : {},
            unit: 'HOURLY',
            type: 'USER',
            billRateSetting: {
                type: BillRateSettingType.VALUE,
            },
        };
        newPayRateData.wages.push(newWageData);
        payRateDataOnChange(newPayRateData);
    }, [defaultQualifiers, payRateData, payRateDataOnChange]);
    var deleteWageRecord = useCallback(function (wageId) {
        var newPayRateData = __assign({}, payRateData);
        var newWageData = _.filter(newPayRateData.wages, function (wage) { return wage.id !== wageId; });
        newPayRateData.wages = newWageData;
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    var updateWageRecord = useCallback(function (wageId, newName, newBillRateSetting, newPayRate, newBillRate) {
        var newPayRateData = __assign({}, payRateData);
        var newWageData = newPayRateData.wages;
        var targetWageRecordIndex = _.findIndex(newPayRateData.wages, function (wage) { return wage.id === wageId; });
        // only update the value that is not undefined
        if (newName !== undefined) {
            newWageData[targetWageRecordIndex].name = newName;
        }
        if (newPayRate !== undefined) {
            newWageData[targetWageRecordIndex].regularRate = newPayRate;
        }
        if (newBillRate !== undefined) {
            newWageData[targetWageRecordIndex].billRate = newBillRate;
        }
        if (newBillRateSetting !== undefined) {
            newWageData[targetWageRecordIndex].billRateSetting = newBillRateSetting;
        }
        newPayRateData.wages = newWageData;
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    var addQualifierCol = useCallback(function (id, name, type) {
        var newPayRateData = __assign({}, payRateData);
        var newCollection = {
            id: id,
            displayName: name,
            type: type,
        };
        newPayRateData.qualifierCols.push(newCollection);
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    var deleteQualifierCol = useCallback(function (id) {
        var newPayRateData = __assign({}, payRateData);
        var newQualifierCollections = _.filter(newPayRateData.qualifierCols, function (qualifierCol) { return qualifierCol.id !== id; });
        newPayRateData.qualifierCols = newQualifierCollections;
        var newWageData = newPayRateData.wages;
        newWageData.map(function (wage) {
            delete wage.qualifiers[id];
        });
        newPayRateData.wages = newWageData;
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    var addQualifier = useCallback(function (value, wageId, referenceId, qualifierId, type) {
        var newPayRateData = __assign({}, payRateData);
        var newWageData = newPayRateData.wages;
        var targetWageRecordIndex = _.findIndex(newPayRateData.wages, function (wage) { return wage.id === wageId; });
        var newQualifierObj;
        var extraQualifierObjKeys = {
            id: uuidv4(),
            displayName: value,
        };
        if (type === QualifierType.COLLECTION) {
            newQualifierObj = __assign(__assign({}, extraQualifierObjKeys), { collectionId: referenceId, recordId: qualifierId, displayName: value, type: 'COLLECTION' });
        }
        else if (type === QualifierType.SELECTION) {
            newQualifierObj = __assign(__assign({}, extraQualifierObjKeys), { selectionGroupId: referenceId, selectionOptionId: qualifierId, type: 'SELECTION' });
        }
        else {
            newQualifierObj = __assign(__assign({}, extraQualifierObjKeys), { schemaId: referenceId, time: value, symbol: qualifierId, type: 'TIME' });
        }
        newWageData[targetWageRecordIndex].qualifiers[referenceId] = newQualifierObj;
        newPayRateData.wages = newWageData;
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    var updateQualifier = useCallback(function (value, wageId, referenceId, qualifierId) {
        var newPayRateData = __assign({}, payRateData);
        var newWageData = newPayRateData.wages;
        var targetWageRecordIndex = _.findIndex(newPayRateData.wages, function (wage) { return wage.id === wageId; });
        newWageData[targetWageRecordIndex].qualifiers[referenceId].displayName = value;
        var targetQualifier = newWageData[targetWageRecordIndex].qualifiers[referenceId];
        if (targetQualifier.type === 'COLLECTION') {
            targetQualifier.recordId = qualifierId;
        }
        else if (targetQualifier.type === QualifierType.SELECTION) {
            targetQualifier.selectionOptionId = qualifierId;
        }
        else {
            targetQualifier.time = value;
            targetQualifier.symbol = qualifierId;
        }
        newPayRateData.wages = newWageData;
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    var deleteQualifier = useCallback(function (wageId, referenceId) {
        var newPayRateData = __assign({}, payRateData);
        var newWageData = newPayRateData.wages;
        var targetWageRecordIndex = _.findIndex(newPayRateData.wages, function (wage) { return wage.id === wageId; });
        delete newWageData[targetWageRecordIndex].qualifiers[referenceId];
        newPayRateData.wages = newWageData;
        payRateDataOnChange(newPayRateData);
    }, [payRateData, payRateDataOnChange]);
    // qualifier collection dropdown menu
    var qualifierDropdownMenuItems = useMemo(function () {
        return availableQualifyObject === null || availableQualifyObject === void 0 ? void 0 : availableQualifyObject.map(function (option) {
            var disableOption = checkQualifierAvailability(option.id);
            return {
                key: option.id,
                label: option.displayName,
                onClick: function () {
                    addQualifierCol(option.id, option.displayName, option.type);
                    setDropDownVisible(false);
                },
                disabled: disableOption, // Disable option when it's already included
            };
        });
    }, [addQualifierCol, availableQualifyObject, checkQualifierAvailability]);
    var parseToRecordsData = useMemo(function () {
        var tempRecords = [];
        payRateData === null || payRateData === void 0 ? void 0 : payRateData.wages.map(function (wage) {
            var _a;
            var nameObj = {
                wageId: wage.id,
                name: wage.name,
                payRate: wage.regularRate,
                billRate: wage.billRate,
                type: wage.type,
                billRateSetting: wage.billRateSetting,
            };
            var payRateObj = {
                wageId: wage.id,
                name: wage.name,
                payRate: wage.regularRate,
                billRate: wage.billRate,
                type: wage.type,
                billRateSetting: wage.billRateSetting,
            };
            var billRateObj = {
                wageId: wage.id,
                name: wage.name,
                payRate: wage.regularRate,
                billRate: wage.billRate,
                type: wage.type,
                billRateSetting: wage.billRateSetting,
            };
            var tempTestRecordValue = {
                wageId: wage.id,
                name: nameObj,
                payRate: payRateObj,
                billRate: billRateObj,
                type: wage.type,
            };
            (_a = Object.keys(wage.qualifiers)) === null || _a === void 0 ? void 0 : _a.map(function (key) {
                var qualifier = wage.qualifiers[key];
                if (qualifier.type === 'COLLECTION') {
                    var qualifierObj = {
                        wageId: wage.id,
                        collectionId: key,
                        recordId: qualifier.recordId,
                        displayName: qualifier.displayName,
                        type: qualifier.type,
                    };
                    tempTestRecordValue[key] = qualifierObj;
                }
                else if (qualifier.type === 'SELECTION') {
                    var qualifierObj = {
                        wageId: wage.id,
                        collectionId: key,
                        recordId: qualifier.selectionOptionId,
                        displayName: qualifier.displayName,
                        type: qualifier.type,
                    };
                    tempTestRecordValue[key] = qualifierObj;
                }
                else {
                    var qualifierObj = {
                        wageId: wage.id,
                        schemaId: key,
                        time: qualifier.time,
                        symbol: qualifier.symbol,
                        displayName: qualifier.displayName,
                        type: qualifier.type,
                    };
                    tempTestRecordValue[key] = qualifierObj;
                }
            });
            tempRecords.push(tempTestRecordValue);
        });
        return tempRecords;
    }, [payRateData]);
    var parseToColumnData = useMemo(function () {
        var _a;
        var tempColumns = [];
        var nameHeader = _jsx("div", { className: "PaySettingColumnTitle", children: "Name" });
        tempColumns.push({
            Header: nameHeader,
            accessor: 'name',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                var isEditable = canEdit && value.type === 'USER';
                return TextCellWrapper(isEditable, true, value.wageId, value.name, value.billRate, value.payRate, value.billRateSetting, payRateData.currency, updateWageRecord, deleteWageRecord);
            },
        });
        (_a = payRateData === null || payRateData === void 0 ? void 0 : payRateData.qualifierCols) === null || _a === void 0 ? void 0 : _a.map(function (qualifierCol) {
            var lockedQualifier = defaultQualifiers
                ? !!Object.keys(defaultQualifiers).find(function (id) { return id === qualifierCol.id; })
                : false;
            tempColumns.push({
                Header: (_jsxs("div", { className: "PaySettingColumnTitle", children: [_jsx("div", { className: "overflow-hidden text-ellipsis whitespace-nowrap", children: qualifierCol.displayName }), canEdit && !lockedQualifier && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this qualifier column?", onConfirm: function () { return deleteQualifierCol(qualifierCol.id); }, placement: "bottom", children: _jsx(DeleteOutlined, { style: { marginLeft: '10px' } }) }))] })),
                accessor: qualifierCol.id,
                width: qualifierCol.type === 'TIME' ? 240 : 150,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: function (value) {
                    var _a;
                    var index = value.row.index;
                    var wageData = value.data[index];
                    var wageId = wageData.wageId;
                    var isEditable = canEdit && wageData.type === 'USER' && !lockedQualifier;
                    var type = qualifierCol.type;
                    if (((_a = wageData === null || wageData === void 0 ? void 0 : wageData.name) === null || _a === void 0 ? void 0 : _a.name) === 'Base Rate') {
                        return _jsx(_Fragment, {});
                    }
                    if (qualifierCol.type === 'TIME') {
                        return DateQualifierCellWrapper(isEditable, wageId, qualifierCol.id, value.value, type, addQualifier, deleteQualifier, updateQualifier);
                    }
                    return SelectorCellWrapper(isEditable, wageId, qualifierCol.id, value.value, type, addQualifier, deleteQualifier, updateQualifier);
                },
            });
        });
        var payRateHeader = _jsx("div", { className: "PaySettingColumnTitle", children: "Pay Rate" });
        tempColumns.push({
            Header: payRateHeader,
            accessor: 'payRate',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return TextCellWrapper(canEdit, false, value.wageId, value.name, value.billRate, value.payRate, value.billRateSetting, payRateData.currency, updateWageRecord, deleteWageRecord);
            },
        });
        var billRateHeader = _jsx("div", { className: "PaySettingColumnTitle", children: "Bill Rate" });
        tempColumns.push({
            Header: billRateHeader,
            accessor: 'billRate',
            width: 180,
            Cell: function (_a) {
                var value = _a.value;
                var isTypeEditable = value.type === 'USER';
                return TextCellWrapper(canEdit, false, value.wageId, value.name, value.billRate, value.payRate, value.billRateSetting, payRateData.currency, updateWageRecord, deleteWageRecord, true, isTypeEditable);
            },
        });
        return tempColumns;
    }, [
        addQualifier,
        canEdit,
        defaultQualifiers,
        deleteQualifier,
        deleteQualifierCol,
        deleteWageRecord,
        payRateData.currency,
        payRateData === null || payRateData === void 0 ? void 0 : payRateData.qualifierCols,
        updateQualifier,
        updateWageRecord,
    ]);
    return (_jsxs("div", { className: "PayRateConfigTable", children: [showDuplicateWarning && (_jsx(Alert, { message: "Table contains duplicate records.", banner: true, type: "warning", className: "mt-4" })), _jsx(PayRateSettingTableBuilder, { columns: parseToColumnData, data: parseToRecordsData, footer: true }), _jsxs("div", { className: "flex space-x-[8px] mt-[16px]", children: [canEdit && (_jsx(Button, { onClick: addNewWageRecord, className: "AddPayRateItemBtn", children: "Add Pay Rate" })), canEdit && (_jsx(ZiraDropdown, { menu: { items: qualifierDropdownMenuItems }, trigger: ['click'], open: dropDownVisible, onOpenChange: function (visble) {
                            setDropDownVisible(visble);
                        }, children: _jsx(Button, { className: "AddQualifierBtn", children: "Add a Qualifier" }) }))] })] }));
};
export default React.memo(PayRateTable);
