import { atom, atomFamily } from 'recoil';
import { localStorageEffect } from './localStorageEffects';
var detailModalIsFeedVisibleState = atom({
    key: 'detailModalIsFeedVisibleState',
    default: true,
    effects: [localStorageEffect('detailModalIsFeedVisibleState')],
});
var detailModalPropsState = atom({
    key: 'detailModalProps',
    default: {
        recordId: '',
        defaultModalData: undefined,
        bulkEditData: undefined,
        schemaDefaults: undefined,
        referenceRid: undefined,
    },
});
var isNewModalVisibleState = atom({
    key: 'isNewModalVisible',
    default: false,
});
var detailModalActiveTab = atomFamily({
    key: 'detailModalActiveTab',
    default: '',
});
var detailModalVisibleReverseCollectionIds = atom({
    key: 'detailModalVisibleReverseCollectionIds',
    default: [],
});
var existingDetailModalInfo = atom({
    key: 'existingDetailModalInfo',
    default: undefined,
});
var detailModalSchemaGroups = atomFamily({
    key: 'detailModalSchemaGroups',
    default: [],
});
var detailModalTabs = atomFamily({
    key: 'detailModalTabs',
    default: [],
});
var feedSiderActiveTabState = atomFamily({
    key: 'feedSiderActiveTabState',
    default: undefined,
});
var tasksReverseCollectionViewState = atom({
    key: 'tasksReverseCollectionViewState',
    default: 'table',
    effects: [localStorageEffect('tasksReverseCollectionViewState')],
});
var shiftDetailModalBreakdownInfoState = atomFamily({
    key: 'shiftDetailModalBreakdownInfo',
    default: [],
});
export { detailModalPropsState, isNewModalVisibleState, detailModalActiveTab, detailModalVisibleReverseCollectionIds, existingDetailModalInfo, detailModalSchemaGroups, detailModalTabs, detailModalIsFeedVisibleState, feedSiderActiveTabState, tasksReverseCollectionViewState, shiftDetailModalBreakdownInfoState, };
