import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Button, InputNumber, Select, Space } from 'antd';
var Option = Select.Option;
import { CloseOutlined } from '@ant-design/icons';
import { NaviBarAddButton } from '../../../utils/icons/NaviBarAddButton';
import { OffsetType } from '../Interface';
var ModifyTiming = function (_a) {
    var triggerOffset = _a.triggerOffset, setTriggerOffset = _a.setTriggerOffset, isOffsetDelayed = _a.isOffsetDelayed, setIsOffsetDelayed = _a.setIsOffsetDelayed, canTriggerBefore = _a.canTriggerBefore, offsetType = _a.offsetType, setOffsetType = _a.setOffsetType, min = _a.min, _b = _a.hideClose, hideClose = _b === void 0 ? false : _b;
    // Set offset delay to true if it cant be triggered before
    useEffect(function () {
        return setIsOffsetDelayed(function (prevIsOffsetDelayed) {
            if (!canTriggerBefore && prevIsOffsetDelayed === false) {
                return true;
            }
            return prevIsOffsetDelayed;
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canTriggerBefore]);
    var isMultiple = triggerOffset && triggerOffset > 1;
    var minutesText = isMultiple ? 'Mins' : 'Min';
    var hoursText = isMultiple ? 'Hours' : 'Hour';
    var daysText = isMultiple ? 'Days' : 'Day';
    return triggerOffset !== null ? (_jsxs(Space, { className: "OffsetOption", align: "baseline", children: [_jsx(InputNumber, { className: "OffsetNumberInput", min: min, value: triggerOffset, onChange: function (value) {
                    if (value !== null && value >= min) {
                        setTriggerOffset(value);
                    }
                } }), _jsxs(Select, { className: "GraySelect", value: offsetType !== null && offsetType !== void 0 ? offsetType : OffsetType.MINUTES, onChange: function (value) {
                    setOffsetType(value);
                }, children: [_jsx(Option, { value: OffsetType.MINUTES, children: minutesText }), _jsx(Option, { value: OffsetType.HOURS, children: hoursText }), _jsx(Option, { value: OffsetType.DAYS, children: daysText })] }), _jsxs(Select, { className: "GraySelect", defaultValue: "after", value: isOffsetDelayed ? 'after' : canTriggerBefore ? 'before' : 'after', onChange: function (value) {
                    if (value === 'after') {
                        setIsOffsetDelayed(true);
                    }
                    else {
                        setIsOffsetDelayed(false);
                    }
                }, children: [_jsx(Option, { value: "after", children: "After" }), canTriggerBefore && _jsx(Option, { value: "before", children: "Before" })] }), !hideClose && (_jsx(Button, { className: "TimingDeleteButton", icon: _jsx(CloseOutlined, {}), onClick: function () {
                    setTriggerOffset(null);
                    setIsOffsetDelayed(null);
                    setOffsetType(null);
                } }))] })) : (_jsx(Button, { onClick: function () {
            setTriggerOffset(min);
            setIsOffsetDelayed(true);
            setOffsetType(OffsetType.MINUTES);
        }, className: "ModifyTimingButton", icon: _jsx(NaviBarAddButton, {}), children: "Modify Timing" }));
};
export default React.memo(ModifyTiming);
