import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { RollupConfigurationContext } from '../../../RollupConfigurationModal';
import RollupCellHOC from '../../../../OptimizedTableView/Cell/RollupCell/RollupCellHOC';
var RollupViewSettingsPreview = function () {
    var rollupConfiguration = useContext(RollupConfigurationContext).rollupConfiguration;
    var viewSettings = rollupConfiguration.viewSettings;
    return (_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Preview" }), _jsx("div", { className: "flex items-center justify-center border-y-[1px] bg-gray-50 h-[32px]", children: _jsx("div", { className: "w-[80%]", children: _jsx(RollupCellHOC, { cellPos: {
                            collectionId: '',
                            schemaId: '',
                            recordId: '',
                        }, forceViewSettings: viewSettings, numerator: 3, denominator: 10, value: "0.3", forceShow: true }) }) })] }));
};
export default RollupViewSettingsPreview;
