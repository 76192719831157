import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { ReportSourceType } from '../Interface';
import IndividualPayReportCardList from './IndividualPayReportCardList';
var IndividualPayReportCardListWrapper = function (_a) {
    var payPeriodId = _a.payPeriodId, setSourceType = _a.setSourceType, payrollEnhancementEnabled = _a.payrollEnhancementEnabled;
    var _b = useState(ReportSourceType.USER), groupBy = _b[0], setGroupBy = _b[1];
    useEffect(function () {
        setSourceType(groupBy);
    }, [groupBy, setSourceType]);
    return (_jsxs(_Fragment, { children: [_jsx(IndividualPayReportCardList, { payPeriodId: payPeriodId, reportSourceType: groupBy, changeGroupBy: setGroupBy, visible: groupBy === ReportSourceType.USER, payrollEnhancementEnabled: payrollEnhancementEnabled }), _jsx(IndividualPayReportCardList, { payPeriodId: payPeriodId, reportSourceType: ReportSourceType.LOCATION, changeGroupBy: setGroupBy, visible: groupBy === ReportSourceType.LOCATION, payrollEnhancementEnabled: payrollEnhancementEnabled })] }));
};
export default React.memo(IndividualPayReportCardListWrapper);
