import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Col, Row } from 'antd';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import { DashboardMetricType, dashboardMetricsInfoMap, } from 'src/components/CenterPanel/Dashboard/Constant';
import DashboardMetricCard from './DashboardMetricCard';
var getDateRange = function (dateRange, data) {
    if (!data) {
        return undefined;
    }
    switch (dateRange) {
        case 'CURRENT':
            return data.metrics[DashboardMetricType.CURRENT_DATE_RANGE];
        case 'LATEST':
            return data.metrics[DashboardMetricType.TIMESHEET_DATE_RANGE];
        default:
            return undefined;
    }
};
var DashboardMetricsCards = function () {
    var useGetDashboardMetrics = useDashboardApi().useGetDashboardMetrics;
    var _a = useGetDashboardMetrics(), data = _a.data, isLoading = _a.isLoading;
    return (_jsx(_Fragment, { children: dashboardMetricsInfoMap
            .filter(function (cardInfo) { return !!(data === null || data === void 0 ? void 0 : data.metrics[cardInfo.dataKey]); })
            .map(function (cardInfo) {
            var metricKeyProp = "".concat(cardInfo.dataKey, "-col");
            return (_jsx(Col, { span: 8, children: _jsx(DashboardMetricCard, { cardInfo: cardInfo, isLoading: isLoading, metricValue: data === null || data === void 0 ? void 0 : data.metrics[cardInfo.dataKey], timeSheetDateRange: getDateRange(cardInfo.dateRange, data) }) }, metricKeyProp));
        }) }));
};
var DashboardMetricWidget = function () {
    return (_jsx("div", { className: "DashboardMetricsWidgetContainer", children: _jsx(Row, { gutter: [8, 8], children: _jsx(DashboardMetricsCards, {}) }) }));
};
export default React.memo(DashboardMetricWidget);
