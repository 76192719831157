import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../../../../App.less';
import './DateTimeCell.scss';
import { useTimeZone } from 'src/hooks/component/useTimeZone';
import { DoubleLeftOutlined, LeftOutlined, RightOutlined, DoubleRightOutlined, } from '@ant-design/icons';
import { Input, Calendar, Tooltip, Divider, Button } from 'antd';
import moment from 'moment-timezone';
import { ValueDataType } from '../../../../shared';
import ZiraPopover from '../../../../ui/ZiraPopover';
import { useBreakDefaultStartDate } from './hooks/useBreakDefaultStartDate';
// input value validation
var dateValidation = function (startDate) {
    return moment(startDate, 'MMM D, YYYY', true).isValid();
};
var timeValidation = function (time) {
    return moment(time, 'h:mm A', true).isValid();
};
var DateInput = function (props) {
    var date = props.date, isDateValid = props.isDateValid, startDateOnChange = props.startDateOnChange, dateValidOnChange = props.dateValidOnChange;
    var _a = useState(''), dateValue = _a[0], setDateValue = _a[1];
    useEffect(function () {
        setDateValue(date);
    }, [date]);
    var changeFocus = useCallback(function () {
        var _a;
        (_a = document.getElementById('timeInput')) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    var handleValidation = useCallback(function () {
        if (dateValidation(dateValue)) {
            startDateOnChange(dateValue);
        }
        dateValidOnChange(dateValidation(dateValue));
    }, [dateValidOnChange, dateValue, startDateOnChange]);
    var onBlurChange = useCallback(function () {
        handleValidation();
    }, [handleValidation]);
    var onPressEnter = useCallback(function (e) {
        e.currentTarget.blur();
        changeFocus();
    }, [changeFocus]);
    var onInputChange = useCallback(function (e) {
        setDateValue(e.target.value);
    }, []);
    var onClick = useCallback(function (e) {
        e.currentTarget.focus();
    }, []);
    return (_jsx("div", { className: "DateInfo", style: { borderColor: isDateValid ? '#D9D9D9' : 'red' }, children: _jsx(Input, { className: "DatePickerInput", id: 'startDate', bordered: false, value: dateValue, onBlur: onBlurChange, onPressEnter: onPressEnter, onChange: onInputChange, onClick: onClick, onFocus: function () {
                if (!dateValue) {
                    setDateValue(moment().format('MMM D, YYYY'));
                }
            } }) }));
};
var TimeInput = function (props) {
    var time = props.time, date = props.date, timeOnChange = props.timeOnChange, timeValidOnChange = props.timeValidOnChange;
    var _a = useState(time), timeValue = _a[0], setTimeValue = _a[1];
    var handleValidation = useCallback(function () {
        if (!date) {
            if (timeValidation(timeValue)) {
                timeOnChange(timeValue);
            }
            timeValidOnChange(timeValidation(timeValue));
        }
        else {
            if (timeValidation(timeValue)) {
                timeOnChange(timeValue);
            }
            timeValidOnChange(timeValidation(timeValue));
        }
    }, [date, timeOnChange, timeValidOnChange, timeValue]);
    var onPressEnter = useCallback(function (e) {
        e.currentTarget.blur();
    }, []);
    var onInputChange = useCallback(function (e) {
        setTimeValue(e.target.value);
    }, []);
    var onClick = useCallback(function (e) {
        e.currentTarget.focus();
    }, []);
    return (_jsx(Input, { className: "TimeInput", id: "timeInput", bordered: false, value: timeValue, onBlur: handleValidation, onPressEnter: onPressEnter, onChange: onInputChange, onClick: onClick, onFocus: function () {
            if (!timeValue) {
                setTimeValue(defaultTime);
            }
        } }));
};
var DateWithTimeInput = function (props) {
    var date = props.date, time = props.time, setIsDateTimeValid = props.setIsDateTimeValid, dateOnChange = props.dateOnChange, timeOnChange = props.timeOnChange;
    var _a = useState(true), isDateValid = _a[0], setDateValid = _a[1];
    var _b = useState(true), isTimeValid = _b[0], setTimeValid = _b[1];
    useEffect(function () {
        setIsDateTimeValid(isDateValid && isTimeValid);
    }, [isDateValid, isTimeValid, setIsDateTimeValid]);
    var dateValidOnChange = useCallback(function (status) {
        setDateValid(status);
    }, []);
    var timeValidOnChange = useCallback(function (status) {
        setTimeValid(status);
    }, []);
    return (_jsx(Tooltip, { title: "Invalid input, cannot be saved!", color: "black", placement: "top", open: !(isDateValid && isTimeValid), destroyTooltipOnHide: true, overlayStyle: { zIndex: 1101 }, overlayClassName: "LogicDateTimeCellValidationTooltip", children: _jsxs("div", { className: "DateTimeInfo", style: {
                borderColor: isDateValid && isTimeValid ? '#D9D9D9' : 'red',
            }, children: [_jsx(DateInput, { date: date, startDateOnChange: dateOnChange, isDateValid: isDateValid, dateValidOnChange: dateValidOnChange }), _jsx("span", { className: "Divider", children: "|" }), _jsx(TimeInput, { time: time, date: undefined, timeOnChange: timeOnChange, isTimeValid: isTimeValid, timeValidOnChange: timeValidOnChange })] }) }));
};
// default value
var defaultTime = '12:00 AM';
var LogicDateTimeCell = function (props) {
    var _a;
    var data = props.data, editCell = props.editCell, isClicked = props.isClicked, setIsClicked = props.setIsClicked, timeZone = props.timeZone;
    var isTimeShown = useMemo(function () { return data.showTime || false; }, [data]);
    var onlyShowDate = !isTimeShown;
    var dateWithTime = isTimeShown;
    var timeFormatFn = useTimeZone(timeZone).timeFormatFn;
    var breakDefaultStartDate = useBreakDefaultStartDate();
    var defaultDate = data.type === ValueDataType.DATETIME
        ? ((_a = data.dateTime) !== null && _a !== void 0 ? _a : breakDefaultStartDate)
        : data.from;
    var _b = useState(defaultDate ? timeFormatFn(defaultDate, 'MMM D, YYYY', false) : undefined), date = _b[0], setDate = _b[1];
    var timeDefaultValue = useMemo(function () {
        if (!defaultDate)
            return '';
        if (data.showTime && data.type === ValueDataType.DATETIME) {
            return timeFormatFn(data.dateTime, 'h:mm A', false);
        }
        return '';
    }, [data, defaultDate, timeFormatFn]);
    var _c = useState(timeDefaultValue), time = _c[0], setTime = _c[1];
    var _d = useState(true), isDateValid = _d[0], setDateValid = _d[1];
    var _e = useState(true), isDateTimeValid = _e[0], setDateTimeValid = _e[1];
    var firstTimeOnClosePopover = useRef(true);
    var dateOnChange = useCallback(function (date) {
        setDate(date);
    }, []);
    var timeOnChange = useCallback(function (Time) {
        setTime(Time);
    }, []);
    var dateValidOnChange = useCallback(function (status) {
        setDateValid(status);
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var calendarHeaderRender = function (_a) {
        var value = _a.value, onChange = _a.onChange;
        var month = value.month();
        var year = value.year();
        return (_jsx("div", { className: "mb-2 flex items-center justify-center", children: _jsxs("div", { className: "ml-2 flex items-center p-2", children: [_jsx(Button, { icon: _jsx(DoubleLeftOutlined, {}), onClick: function () {
                            var newValue = value.clone();
                            newValue.year(year - 1);
                            onChange(newValue);
                        } }), _jsx(Button, { icon: _jsx(LeftOutlined, {}), onClick: function () {
                            var newValue = value.clone();
                            newValue.month(month - 1);
                            onChange(newValue);
                        } }), _jsx("div", { className: "font-medium", children: value.format('MMMM, YYYY') }), _jsx(Button, { icon: _jsx(RightOutlined, {}), onClick: function () {
                            var newValue = value.clone();
                            newValue.month(month + 1);
                            onChange(newValue);
                        } }), _jsx(Button, { icon: _jsx(DoubleRightOutlined, {}), onClick: function () {
                            var newValue = value.clone();
                            newValue.year(year + 1);
                            onChange(newValue);
                        } })] }) }));
    };
    var calendarCellRender = function (cellDate) {
        var isToday = cellDate.toDate().toDateString() === new Date().toDateString();
        var extraStyleString = isToday ? '1px solid #1890ff' : '';
        if (cellDate.format('MMM D, YYYY') === date) {
            return _jsxs("div", { className: "CalendarStartEndCell", children: [" ", cellDate.date(), " "] });
        }
        return (_jsx("div", { className: "CalendarCell", style: { border: extraStyleString }, children: cellDate.date() }));
    };
    var calendarDataOnSelect = useCallback(function (e) {
        setDate(e.format('MMM D, YYYY'));
    }, []);
    var handleClear = useCallback(function () {
        setDate(undefined);
        setTime('');
        editCell([]);
        setIsClicked(false);
    }, [editCell, setIsClicked]);
    var tableCellDisplayValue = useMemo(function () {
        if (onlyShowDate) {
            return date || '';
        }
        else if (dateWithTime) {
            if (date) {
                return date + ' ' + time;
            }
            return time;
        }
        return '';
    }, [onlyShowDate, dateWithTime, date, time]);
    var content = (_jsxs("div", { className: "DatePickerContainer", children: [_jsxs("div", { className: "DateSettingInput", children: [onlyShowDate && (_jsx(Tooltip, { title: "Invalid input, cannot be saved!", color: "black", placement: "top", open: !isDateValid, destroyTooltipOnHide: true, overlayStyle: { zIndex: 1101 }, children: _jsx(DateInput, { date: date || '', isDateValid: isDateValid, startDateOnChange: dateOnChange, dateValidOnChange: dateValidOnChange }) })), dateWithTime && (_jsx(DateWithTimeInput, { date: date || '', time: time, setIsDateTimeValid: setDateTimeValid, dateOnChange: dateOnChange, timeOnChange: timeOnChange }))] }), _jsx(Calendar, { className: "CalendarCard", fullscreen: false, onSelect: calendarDataOnSelect, value: date ? moment(date, 'MMM D, YYYY') : moment(), dateFullCellRender: calendarCellRender, headerRender: calendarHeaderRender }), _jsx(Divider, {}), _jsx("div", { className: "flex h-[36px] items-center font-semibold text-center cursor-pointer hover:bg-gray-60 rounded mb-2 pl-2", onClick: handleClear, children: "Clear" })] }));
    return (_jsxs(_Fragment, { children: [_jsx(ZiraPopover, { className: "DatePickerPopOver", content: content, trigger: "click", destroyTooltipOnHide: true, overlayStyle: { zIndex: 1100 }, open: true, children: tableCellDisplayValue }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    if ((onlyShowDate && isDateValid) || (dateWithTime && isDateTimeValid)) {
                        editCell([tableCellDisplayValue]);
                        setIsClicked(false);
                    }
                    else {
                        if (firstTimeOnClosePopover.current === false) {
                            setIsClicked(false);
                        }
                        else {
                            firstTimeOnClosePopover.current = false;
                            return;
                        }
                    }
                }, style: { display: isClicked ? 'block' : 'none' } })] }));
};
export default React.memo(LogicDateTimeCell);
