var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
var Option = Select.Option;
var ReportMetrics = function (_a) {
    var metricList = _a.metricList, reportMetrics = _a.reportMetrics, setReportMetrics = _a.setReportMetrics, showVariable = _a.showVariable;
    var _b = useState(false), showNewMetric = _b[0], setShowNewMetric = _b[1];
    var addNewReportMetric = useCallback(function (metricType) {
        var metric = metricList.find(function (metric) { return metric.metricType === metricType; });
        if (!metric)
            return;
        setReportMetrics(__spreadArray(__spreadArray([], reportMetrics, true), [__assign(__assign({}, metric), { isPlotted: !reportMetrics.length })], false));
        setShowNewMetric(false);
    }, [metricList, reportMetrics, setReportMetrics]);
    var editReportMetric = useCallback(function (metricType, index) {
        var tempReportMetrics = cloneDeep(reportMetrics);
        var metric = metricList.find(function (metric) { return metric.metricType === metricType; });
        if (!metric)
            return;
        tempReportMetrics.splice(index, 1, __assign(__assign({}, metric), { isPlotted: tempReportMetrics[index].isPlotted }));
        setReportMetrics(tempReportMetrics);
    }, [metricList, reportMetrics, setReportMetrics]);
    var removeReportMetric = useCallback(function (index) {
        var tempReportMetrics = cloneDeep(reportMetrics);
        tempReportMetrics.splice(index, 1);
        setReportMetrics(tempReportMetrics);
    }, [reportMetrics, setReportMetrics]);
    var allMetricOptions = useMemo(function () {
        return metricList.map(function (metric) { return (_jsx(Option, { value: metric.metricType, children: metric.metricLabel }, metric.metricType)); });
    }, [metricList]);
    var filteredMetricOptions = useMemo(function () {
        var selectedMetricTypes = reportMetrics.map(function (metric) { return metric.metricType; });
        return metricList
            .filter(function (metric) {
            return !selectedMetricTypes.includes(metric.metricType);
        })
            .map(function (metric) { return (_jsx(Option, { value: metric.metricType, children: "".concat(metric.metricLabel, " ").concat(metric.metricType.startsWith('budgeted') ? ' (Location Only)' : '') }, metric.metricType)); });
    }, [metricList, reportMetrics]);
    return (_jsxs(_Fragment, { children: [reportMetrics.map(function (metric, idx) { return (_jsxs("div", { className: "relative mb-2 flex items-center", children: [showVariable && (_jsxs("span", { className: "absolute -left-8 font-semibold", children: ["m", idx + 1] })), _jsx("div", { className: "SelectWrapper", children: _jsx(Select, { showSearch: true, placeholder: "Select a Report Metric", value: metric.metricType, onChange: function (value) { return editReportMetric(value, idx); }, children: allMetricOptions }) }), _jsx(Button, { icon: _jsx(CloseOutlined, {}), onClick: function () { return removeReportMetric(idx); }, title: "Remove metric" })] }, metric.metricType)); }), showNewMetric && (_jsxs("div", { className: "mb-2 flex items-center", children: [_jsx("div", { className: "SelectWrapper", children: _jsx(Select, { showSearch: true, placeholder: "Select a Report Metric", onChange: function (value) { return addNewReportMetric(value); }, children: filteredMetricOptions }) }), _jsx(Button, { icon: _jsx(CloseOutlined, {}), onClick: function () { return setShowNewMetric(false); }, title: "Remove metric" })] })), _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "AddButton", type: "primary", onClick: function () { return setShowNewMetric(true); }, disabled: showNewMetric, children: "Add Metric" })] }));
};
export default React.memo(ReportMetrics);
