import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './CommentFeed.scss';
import { renderAuthorEmployeeIcon } from 'src/utils/Account';
import moment from 'moment';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { CollectionType } from 'src/shared';
import { useRecordDetail } from 'src/hooks/component';
import { currentUserState, isCurrentUserOwnerPermission } from 'src/state';
import { useRecoilValue } from 'recoil';
var Comment = function (_a) {
    var _b, _c, _d, _e;
    var comment = _a.comment, setCommentToDelete = _a.setCommentToDelete, isLastComment = _a.isLastComment, actionMenuItems = _a.actionMenuItems;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var commentAuthor = comment.author;
    var authorTitle = commentAuthor
        ? "".concat(commentAuthor === null || commentAuthor === void 0 ? void 0 : commentAuthor.firstName, " ").concat(commentAuthor === null || commentAuthor === void 0 ? void 0 : commentAuthor.lastName)
        : 'Unknown Author';
    var timestamp = moment(comment === null || comment === void 0 ? void 0 : comment.createdAt).format('MMM D, YYYY [at] h:mma z');
    var commentText = (_b = comment === null || comment === void 0 ? void 0 : comment.body) === null || _b === void 0 ? void 0 : _b.text;
    var commentAuthorRid = (_c = comment.author) === null || _c === void 0 ? void 0 : _c.recordId;
    var userRecordId = (useRecoilValue(currentUserState) || {}).recordId;
    var isOwner = useRecoilValue(isCurrentUserOwnerPermission);
    var isCommentAuthor = userRecordId === commentAuthorRid;
    var shouldRenderDelete = setCommentToDelete && (isCommentAuthor || isOwner);
    var getDescriptionText = function (authorTitle, comment) {
        if (setCommentToDelete) {
            return (_jsx("span", { className: "cursor-pointer text-md font-semibold", onClick: navigateToAuthorRecord, children: authorTitle }));
        }
        var collectionType = comment.collectionType, recordTitle = comment.recordTitle;
        var prefix = "".concat(authorTitle, " ");
        var suffix = " the ".concat(recordTitle, " record");
        if (!collectionType && !recordTitle) {
            suffix = " a record";
        }
        else if (collectionType && !recordTitle) {
            var formattedCollectionType = "".concat(collectionType
                .charAt(0)
                .toUpperCase()).concat(collectionType.slice(1));
            suffix = " a ".concat(formattedCollectionType, " record");
        }
        else if (collectionType === CollectionType.USER) {
            suffix = " ".concat(recordTitle, "'s profile");
        }
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: "text-md font-semibold", children: prefix }), _jsx("span", { className: "text-md font-medium", children: "commented on" }), _jsx("span", { className: "text-md font-semibold", children: suffix })] }));
    };
    var navigateToAuthorRecord = function () {
        commentAuthorRid && openRecordDetail(commentAuthorRid);
    };
    var commentDescription = getDescriptionText(authorTitle, comment);
    return (_jsx("div", { className: "".concat(!isLastComment ? 'border-b border-[#EAEAEA]' : ''), children: _jsxs("div", { className: "commentContainer", children: [_jsx("div", { className: "cursor-pointer avatarContainer", onClick: navigateToAuthorRecord, children: renderAuthorEmployeeIcon({
                        actorType: 'USER',
                        authorIcon: (_e = (_d = comment === null || comment === void 0 ? void 0 : comment.author) === null || _d === void 0 ? void 0 : _d.avatarUrl) !== null && _e !== void 0 ? _e : '',
                        authorTitle: authorTitle,
                    }, true) }), _jsxs("div", { className: "commentContentContainer", children: [_jsx("div", { className: "cursor-pointer text-md font-semibold", onClick: function () {
                                if (setCommentToDelete) {
                                    return;
                                }
                                if (openRecordDetail && !!comment.recordId) {
                                    openRecordDetail(comment.recordId);
                                }
                            }, children: commentDescription }), _jsx("div", { className: "commentTimestamp text-xs text-gray-400", children: timestamp }), _jsx("div", { className: "commentBody font-medium text-gray-600", children: commentText }), shouldRenderDelete && actionMenuItems && (_jsx("span", { className: "commentDeleteMenu", onClick: function () {
                                setCommentToDelete(comment.id);
                            }, children: _jsx(Dropdown, { menu: { items: actionMenuItems }, overlayClassName: "CommentActionsMenu", children: _jsx(EllipsisOutlined, { className: "Ellipsis cursor-pointer" }) }) }))] })] }, comment.id) }));
};
export default React.memo(Comment);
