import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { ScheduleView } from 'src/services';
import { scheduleGroupBySelector, scheduleViewTypeSelector } from 'src/state';
import { selectedShiftsState } from 'src/state/atoms/schedule';
import { PasteIcon } from 'src/utils/icons/PasteIcon';
import { ScheduleGroupBy } from 'src/shared';
import './EmptyCard.scss';
import { NEW_SHIFT_TEMPLATE } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import ShiftTemplateListPopover from '../../NewShiftTemplate/ShiftTemplateListPopover';
import TemplatesPopover from '../../TemplatesPopover';
var EmptyCard = function (_a) {
    var tableId = _a.tableId, groupByRecordId = _a.groupByRecordId, setIsHovering = _a.setIsHovering, createNewShift = _a.createNewShift, pasteShift = _a.pasteShift, onApplyTemplate = _a.onApplyTemplate;
    var selectedShifts = useRecoilValue(selectedShiftsState(tableId));
    var viewType = useRecoilValue(scheduleViewTypeSelector(tableId));
    var groupBy = useRecoilValue(scheduleGroupBySelector(tableId));
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var hoverTimeoutRef = useRef();
    return (_jsx("div", { className: "EmptyCardContent", style: { paddingTop: viewType === ScheduleView.WEEK ? 24 : 12 }, onMouseLeave: function () {
            hoverTimeoutRef.current = setTimeout(function () { return setIsHovering(false); }, 750);
        }, onMouseEnter: function () {
            clearTimeout(hoverTimeoutRef.current);
        }, children: _jsx("div", { className: "EmptyCardContentInner", onClick: function (e) {
                e.stopPropagation();
                if (selectedShifts) {
                    pasteShift();
                }
            }, children: _jsxs("span", { className: "EmptyCardAddNew", children: [!selectedShifts.length && (_jsxs(_Fragment, { children: [_jsx("div", { className: "AddNewText", onClick: createNewShift, children: _jsx(PlusOutlined, { className: "PlusIcon" }) }), groupBy !== ScheduleGroupBy.TEMPLATE && (_jsx(_Fragment, { children: isNewShiftTemplateEnabled ? (_jsx(ShiftTemplateListPopover, { blockId: tableId, onApplyTemplate: onApplyTemplate, groupByRecordId: groupByRecordId })) : (_jsx(TemplatesPopover, { blockId: tableId, onApplyTemplate: onApplyTemplate })) }))] })), !!selectedShifts.length && (_jsxs("div", { className: "PasteShift", children: [_jsx(PasteIcon, { className: "PlusIcon" }), "Paste"] }))] }) }) }));
};
export default React.memo(EmptyCard);
