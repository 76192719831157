import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
var ShiftNumberInputCell = function (_a) {
    var value = _a.value, disabled = _a.disabled, updateRecordShiftNumber = _a.updateRecordShiftNumber;
    var _b = useState(value), inputNumber = _b[0], setInputNumber = _b[1];
    var inputRef = useRef(null);
    useEffect(function () { return setInputNumber(value); }, [value]);
    return (_jsx("div", { className: "ShiftNumberCellWrapper", children: _jsx(InputNumber, { ref: inputRef, bordered: false, className: "NumberInput", min: 0, value: inputNumber, disabled: disabled, onBlur: function () {
                updateRecordShiftNumber(inputNumber);
            }, onPressEnter: function () {
                var _a;
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            }, onChange: function (value) {
                if (typeof value === 'number') {
                    setInputNumber(value);
                }
            } }) }));
};
export default React.memo(ShiftNumberInputCell);
