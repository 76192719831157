import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadUrlType } from 'src/hooks/api/useUploadApi';
import { usePageApi } from 'src/hooks/api';
import { useFileApi } from 'src/hooks/api/useFileApi';
import ComponentWithUpload, { UploadFileType } from '../../ComponentWithUpload';
import HoverOptionButtons from '../HoverOptions/HoverOptionButtons';
import './ImageBlock.scss';
var ImageBlock = function (props) {
    var blockId = props.blockId, pageId = props.pageId, moveBlockUpDown = props.moveBlockUpDown;
    var _a = usePageApi(), useUpdatePageBlock = _a.useUpdatePageBlock, useGetMediaBlock = _a.useGetMediaBlock;
    var data = useGetMediaBlock({
        blockId: blockId,
        pageId: pageId,
        type: 'image',
    }).data;
    var handleDeleteFile = useFileApi().deleteFile;
    var acceptedFileImgType = useMemo(function () { return [UploadFileType.IMAGE]; }, []);
    var _b = useState(''), imageUrl = _b[0], setImageUrl = _b[1];
    useEffect(function () {
        if (data) {
            setImageUrl(data.url);
        }
    }, [data]);
    var onChangeImageUrl = useCallback(function (info) {
        var newUrl = info.file.response.data.url;
        if (newUrl && imageUrl) {
            useUpdatePageBlock.mutate({
                blockId: blockId,
                content: {
                    image: newUrl,
                },
            }, {
                onSuccess: function () {
                    var oldUrl = imageUrl;
                    handleDeleteFile(oldUrl);
                    setImageUrl(newUrl);
                },
            });
        }
    }, [imageUrl, useUpdatePageBlock, blockId, handleDeleteFile]);
    var menuChildren = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(ComponentWithUpload, { acceptedFileType: acceptedFileImgType, successAction: onChangeImageUrl, type: UploadUrlType.Block, children: _jsx(Menu.Item, { icon: _jsx(EditOutlined, { className: "EditIcon" }), children: "Replace" }, "replace") }), _jsx(Menu.Divider, {})] }));
    }, [acceptedFileImgType, onChangeImageUrl]);
    return (_jsx("div", { className: "ImageBlockWrapper", children: imageUrl && (_jsxs(_Fragment, { children: [_jsx(HoverOptionButtons, { data: {
                        blockId: blockId,
                    }, moveBlockUpDown: moveBlockUpDown, url: imageUrl, menuChildren: menuChildren, isNonTitleBlock: true }), _jsx("img", { className: "ImageDisplay", src: imageUrl })] })) }));
};
export default React.memo(ImageBlock);
