import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CUTTING_EDGE_EVENT_REPORTS_FEATURE } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import LocationDetailExportReportActionButton from './LocationDetailExportReportActionButton';
import LocationDetailAddLocationActionButton from './LocationDetailAddLocationActionButton';
var LocationDetailHeaderActions = function (_a) {
    var recordId = _a.recordId;
    var isCuttingEdgeEventReportsFeatureEnabled = useRecoilValue(featureEnableStatusState(CUTTING_EDGE_EVENT_REPORTS_FEATURE));
    return (_jsxs(_Fragment, { children: [_jsx(LocationDetailAddLocationActionButton, { recordId: recordId }), isCuttingEdgeEventReportsFeatureEnabled && (_jsx(LocationDetailExportReportActionButton, { recordId: recordId }))] }));
};
export default React.memo(LocationDetailHeaderActions);
