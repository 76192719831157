import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InputNumber, Switch } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ZiraPopover from 'src/ui/ZiraPopover';
var CensusNumberEditPopover = function (_a) {
    var children = _a.children, defaultValue = _a.defaultValue, setDefaultNumber = _a.setDefaultNumber, onClose = _a.onClose;
    var _b = useState(defaultValue), inputNumber = _b[0], setInputNumber = _b[1];
    var inputRef = useRef(null);
    var _c = useState(false), applyToAllDays = _c[0], setApplyToAllDays = _c[1];
    var updateCensusNumber = useCallback(function () {
        if (inputNumber === defaultValue) {
            onClose();
            return;
        }
        else {
            setDefaultNumber(inputNumber, applyToAllDays);
            onClose();
        }
    }, [applyToAllDays, defaultValue, inputNumber, onClose, setDefaultNumber]);
    var content = useMemo(function () {
        return (_jsxs("div", { className: "w-[170px] flex flex-col my-[10px]", children: [_jsx(InputNumber, { autoFocus: true, className: "CensusNumberInput", ref: inputRef, value: inputNumber, onChange: function (value) {
                        if (typeof value === 'number') {
                            setInputNumber(value);
                        }
                    }, onFocus: function (e) { return e.target.select(); }, onPressEnter: function () {
                        var _a;
                        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                    }, onBlur: function () { }, min: 0 }), _jsxs("div", { className: "flex space-x-2 items-center mt-3", children: [_jsx(Switch, { className: "mt-0", size: "small", checked: applyToAllDays, onChange: function (checked) {
                                setApplyToAllDays(checked);
                            } }), _jsx("span", { className: "font-medium text-xs", children: "Update All Days" })] })] }));
    }, [applyToAllDays, inputNumber]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraPopover, { open: true, content: content, overlayInnerStyle: { borderRadius: 8 }, children: children }), _jsx("div", { className: "fpOverlay", onClick: updateCensusNumber, style: { display: 'block' } })] }));
};
export default React.memo(CensusNumberEditPopover);
