import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { visibleSchemaColumnsInDetailModalSiderState } from './atom';
import { allAvailableDetailModalSchemasInSiderSelector } from './selector';
import RecordDetailSchemaColumnRow from './RecordDetailSchemaColumnRow';
var RecordDetailSchemaColumnsSection = function (_a) {
    var collectionId = _a.collectionId, recordId = _a.recordId, collectionType = _a.collectionType;
    var allVisibleDetailModalSchemas = useRecoilValue(visibleSchemaColumnsInDetailModalSiderState(collectionId));
    var allAvailableDetailModalSchemas = useRecoilValue(allAvailableDetailModalSchemasInSiderSelector({ collectionId: collectionId, collectionType: collectionType }));
    return (_jsx("div", { className: "flex overflow-hidden flex-col space-y-5 pb-5 SchemaColumnsSection", children: allAvailableDetailModalSchemas.map(function (_a) {
            var id = _a.id, name = _a.name;
            if (allVisibleDetailModalSchemas.includes(id)) {
                return (_jsx(RecordDetailSchemaColumnRow, { schemaId: id, schemaName: name, collectionId: collectionId, recordId: recordId }, id));
            }
        }) }));
};
export default React.memo(RecordDetailSchemaColumnsSection);
