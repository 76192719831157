import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import TimeOffPolicy from './TimeOffPolicy';
import TimeOffTypeItem from './TimeOffTypeItem';
var TimeOffSection = function () {
    var _a = CompliancePageState.useContainer(), timeOffPolicies = _a.timeOffPolicies, timeOffTypes = _a.timeOffTypes, addTimeOffType = _a.addTimeOffType, setTimeOffTypes = _a.setTimeOffTypes, setTimeOfftimeOffPolicies = _a.setTimeOfftimeOffPolicies, setDateSchemas = _a.setDateSchemas, addTimeOffPolicy = _a.addTimeOffPolicy;
    var useGetTimeoffSettingData = useSetting().useGetTimeoffSettingData;
    var data = useGetTimeoffSettingData().data;
    useEffect(function () {
        if (data) {
            setTimeOffTypes(data.types);
            setTimeOfftimeOffPolicies(data.policies);
            setDateSchemas(data.dateSchemas);
        }
    }, [data, setDateSchemas, setTimeOffTypes, setTimeOfftimeOffPolicies]);
    return (_jsxs("div", { className: "SectionWrapper", children: [_jsxs("div", { className: "mb-10", children: [_jsx("div", { className: "SectionDescriptionTitle", children: "Types" }), _jsx("div", { className: "SectionSubTitle", children: "Add all time of times that you wish to track here. In the policy section below you can choose to track accruals and define different policies for your employees." }), timeOffTypes.map(function (type) {
                        return _jsx(TimeOffTypeItem, { type: type }, type.id);
                    }), _jsx(Button, { className: "AddTimeOffTypeBtn", onClick: function () {
                            addTimeOffType();
                        }, children: "+ Add Time Off Type" })] }), _jsx("div", { className: "SectionDescriptionTitle", children: "Policies" }), _jsx("div", { className: "SectionSubTitle", children: "Create policies using the types you created above. Add accrual and carry over policies to keep everything organized and consistent for your team." }), timeOffPolicies.map(function (policy) {
                return _jsx(TimeOffPolicy, { policy: policy }, policy.policyId);
            }), _jsx("div", { className: "SettingsGrayButton mt-5", onClick: function () {
                    addTimeOffPolicy();
                }, children: "New Time Off Policy" })] }));
};
export default React.memo(TimeOffSection);
