import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import { dashboardShiftAlertTimeRangeState } from '../../Atoms';
import { shiftAlertTableTimeRanges } from '../../Constant';
import './DashboardAlertTableWidget.scss';
import DashboardAlertTableWrapper from '../AlertTable/DashboardAlertTableWrapper';
import AlertWidgetSiderSection from '../AlertWidgetSiderSection';
var ShiftAlertWidget = function () {
    var useGetShiftAlertStatusData = useDashboardApi().useGetShiftAlertStatusData;
    var _a = useState(), selectedShiftStatus = _a[0], setSelectedShiftStatus = _a[1];
    var _b = useRecoilState(dashboardShiftAlertTimeRangeState), selectedTimeRange = _b[0], setSelectedTimeRange = _b[1];
    var _c = useGetShiftAlertStatusData(selectedTimeRange), shiftAlertStatusInfo = _c.data, isLoading = _c.isLoading;
    //Init shift status
    useEffect(function () {
        if (shiftAlertStatusInfo &&
            shiftAlertStatusInfo.data.length !== 0 &&
            !selectedShiftStatus) {
            setSelectedShiftStatus(shiftAlertStatusInfo.data[0]);
        }
    }, [selectedShiftStatus, shiftAlertStatusInfo]);
    return (_jsxs("div", { className: "AlertTableWidgetContainer shadow-block", children: [_jsx(AlertWidgetSiderSection, { isLoading: isLoading, allStatus: shiftAlertStatusInfo === null || shiftAlertStatusInfo === void 0 ? void 0 : shiftAlertStatusInfo.data, selectedStatus: selectedShiftStatus, setSelectedStatus: setSelectedShiftStatus }), _jsxs("div", { className: "TableContentContainer shadow-block", children: [_jsxs("div", { className: "mb-5 flex justify-between", children: [_jsx("div", { className: "text-lg font-medium", children: selectedShiftStatus === null || selectedShiftStatus === void 0 ? void 0 : selectedShiftStatus.name }), _jsx(Select, { value: selectedTimeRange, className: "ShiftAlertTimeRangeSelector", dropdownMatchSelectWidth: false, onChange: setSelectedTimeRange, children: shiftAlertTableTimeRanges.map(function (range) { return (_jsx(Select.Option, { children: range.label }, range.key)); }) })] }), _jsx(DashboardAlertTableWrapper, { shiftAlertProps: {
                            selectedShiftStatus: selectedShiftStatus,
                            selectedTimeRange: selectedTimeRange,
                        } })] })] }));
};
export default React.memo(ShiftAlertWidget);
