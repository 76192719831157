import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import SelectorCell from 'src/components/TableView/TableCell/SelectorCell';
var RoleSelector = function (_a) {
    var roleId = _a.roleId, roleList = _a.roleList, selectedRoles = _a.selectedRoles, updateSelectedRole = _a.updateSelectedRole;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var displayedOption = useMemo(function () {
        var _a, _b;
        var currentRole = roleList.find(function (item) { return item.id === roleId; });
        if (!currentRole)
            return [];
        return [
            {
                id: currentRole.id,
                text: currentRole.name,
                color: (_a = currentRole.color) !== null && _a !== void 0 ? _a : '',
                userAvatar: (_b = currentRole.image) !== null && _b !== void 0 ? _b : '',
            },
        ];
    }, [roleId, roleList]);
    var availableOptions = useMemo(function () {
        var availableUsers = roleList.filter(function (item) { return !selectedRoles.includes(item.id); });
        return availableUsers.map(function (item) {
            var _a;
            return {
                id: item.id,
                text: item.name,
                userAvatar: item.image,
                color: (_a = item.color) !== null && _a !== void 0 ? _a : '',
            };
        });
    }, [roleList, selectedRoles]);
    return (_jsx(SelectorCell, { canEdit: true, inputMode: "single", displayedOption: displayedOption, availableOption: availableOptions, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen, mutateDisplayedValueInItem: function (values) {
            updateSelectedRole(values[0]);
        } }));
};
export default React.memo(RoleSelector);
