var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { OPTIONS_COLOR } from 'src/shared';
var RecipeTextWrapper = function (_a) {
    var children = _a.children, defaultColor = _a.defaultColor, color = _a.color, style = _a.style;
    return (_jsx("div", { className: "w-fit rounded-lg px-[8px] py-[2px]", style: __assign({ backgroundColor: color
                ? color
                : defaultColor
                    ? OPTIONS_COLOR[8]
                    : OPTIONS_COLOR[Math.floor(Math.random() * OPTIONS_COLOR.length)] }, style), children: children }));
};
export default React.memo(RecipeTextWrapper);
