import { atomFamily } from 'recoil';
var reportTableState = atomFamily({
    key: 'reportTableState',
    default: {},
});
var reportMetadataState = atomFamily({
    key: 'reportMetadataState',
    default: {},
});
var reportBuilderState = atomFamily({
    key: 'reportBuilderState',
    default: {},
});
var reportSearchTextState = atomFamily({
    key: 'reportSearchTextState',
    default: '',
});
export { reportTableState, reportMetadataState, reportBuilderState, reportSearchTextState };
