import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { useHealthMetricApi } from 'src/hooks/api';
import { chosenMetricWithDataState } from './Atoms';
import EditHealthRuleModal from './EditHealthMetricRuleModal';
var AddNewHealthMetricRuleCard = function () {
    var _a = useHealthMetricApi(), useGetHealthMetricConfigOptionsQuery = _a.useGetHealthMetricConfigOptionsQuery, createHealthMetricRuleRequest = _a.createHealthMetricRuleRequest;
    var configOptions = useGetHealthMetricConfigOptionsQuery({
        cacheTime: 1000 * 60,
    }).data;
    var _b = useState(false), modalVisible = _b[0], setModalVisible = _b[1];
    var navigate = useNavigate();
    var handleSaveNewRule = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (name, type, timeRange, goal, filters, interval) {
            createHealthMetricRuleRequest({
                name: name,
                type: type,
                timeRange: timeRange,
                goal: goal,
                settings: filters || interval
                    ? {
                        filters: filters,
                        interval: interval,
                    }
                    : undefined,
            }).then(function (res) {
                if (res) {
                    setModalVisible(false);
                    set(chosenMetricWithDataState, {
                        rule: res,
                        data: undefined,
                    });
                    navigate("/home/goals/".concat(res.id));
                }
            });
        };
    }, [createHealthMetricRuleRequest, navigate]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "HealthMetricGraphCard flex h-[320px] flex-col items-center justify-center rounded-2xl border border-gray-200 bg-gray-50 hover:cursor-pointer", onClick: function () { return setModalVisible(!!configOptions && true); }, children: _jsx("div", { className: "flex h-full w-full items-center justify-center", children: _jsxs("div", { className: "flex flex-col items-center justify-center", children: [_jsx("div", { className: "text-3xl font-bold text-gray-400", children: "+" }), _jsx("div", { className: "text-sm font-medium text-gray-400", children: "Add New Goal" })] }) }) }), configOptions && (_jsx(EditHealthRuleModal, { visible: modalVisible, setVisible: setModalVisible, configOptions: configOptions, onSave: handleSaveNewRule }))] }));
};
export default AddNewHealthMetricRuleCard;
