var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var BottomBarExpandIconSvg = function () { return (_jsxs("svg", { width: "15", height: "15", viewBox: "0 0 15 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_382_9708)", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.0355 3.9645C9.08291 2.01188 5.91709 2.01188 3.96447 3.9645C2.01184 5.91712 2.01184 9.08294 3.96447 11.0356C5.91709 12.9882 9.08291 12.9882 11.0355 11.0356C12.9882 9.08294 12.9882 5.91712 11.0355 3.9645ZM3.08058 3.08062C5.52136 0.639838 9.47864 0.639838 11.9194 3.08062C14.3602 5.52139 14.3602 9.47867 11.9194 11.9194C9.47864 14.3602 5.52136 14.3602 3.08058 11.9194C0.639805 9.47867 0.639806 5.52139 3.08058 3.08062Z", fill: "black", fillOpacity: "0.69" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.51055 7.72108C5.38852 7.84312 5.19065 7.84312 5.06861 7.72108L4.62667 7.27914C4.50463 7.1571 4.50463 6.95923 4.62667 6.8372L7.27832 4.18555C7.40036 4.06351 7.59822 4.06351 7.72026 4.18555L8.16221 4.62749L10.3719 6.8372C10.494 6.95923 10.494 7.1571 10.3719 7.27914L9.92997 7.72108C9.80793 7.84312 9.61007 7.84312 9.48803 7.72108L8.12436 6.35741L8.12436 10.576C8.12436 10.7486 7.98445 10.8885 7.81186 10.8885H7.18686C7.01427 10.8885 6.87436 10.7486 6.87436 10.576V6.35727L5.51055 7.72108Z", fill: "black", fillOpacity: "0.69" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_382_9708", children: _jsx("rect", { width: "15", height: "15", fill: "white" }) }) })] })); };
export var BottomBarExpandIcon = function (props) { return (_jsx(Icon, __assign({ component: BottomBarExpandIconSvg }, props))); };
