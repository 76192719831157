var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var HeartIconSvg = function (props) { return (_jsx("svg", { width: "20", height: "20", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M16.219 2.66699C14.6257 2.68699 13.1204 3.32033 12.0004 4.40833C10.8804 3.32166 9.37104 2.68699 7.76304 2.66699C4.38971 2.68699 1.65371 5.44566 1.66704 8.81233C1.66704 15.8163 8.74171 20.051 10.9097 21.183C11.251 21.3603 11.6257 21.4497 12.0004 21.4497C12.375 21.4497 12.7497 21.3603 13.0897 21.183C15.2577 20.0523 22.3337 15.8177 22.3337 8.81633C22.3457 5.44566 19.611 2.68699 16.219 2.66699Z", fill: props.fill || '#A7A8AC' }) })); };
export var HeartIcon = function (props) { return (_jsx(Icon, __assign({ component: function () { return _jsx(HeartIconSvg, __assign({}, props)); } }, props))); };
