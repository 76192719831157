import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath } from 'src/shared';
import { getNativeFieldCellOptions, getTagTextColor } from 'src/utils/Collection';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var PlacementDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var placementTag = useMemo(function () {
        var stage = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.PLACEMENT_STAGE);
        if (!stage.length)
            return null;
        var backgroundColor = stage[0].color;
        return (_jsx("span", { className: "RecordStatusTag", style: {
                backgroundColor: backgroundColor,
                color: getTagTextColor(backgroundColor),
            }, children: stage[0].name }));
    }, [modalRecord, nativeSchemaMap]);
    return _jsx(RecordDetailModalTitle, { title: "Placement", extras: placementTag });
};
export default React.memo(PlacementDetailModalTitle);
