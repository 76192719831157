var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { toast } from 'react-toastify';
import { getAppSession, getOrgIdentifierFromURL } from 'src/utils/Account';
var OctoClient = /** @class */ (function () {
    function OctoClient(serverUrl, workspaceId) {
        if (workspaceId === void 0) { workspaceId = '0'; }
        this.workspaceId = workspaceId;
        this.serverUrl = process.env.REACT_APP_SERVER_BASE_URL;
    }
    /** Function Helpers */
    OctoClient.prototype.headers = function (token) {
        var _a;
        var headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: "Bearer ".concat(token),
            'X-Requested-With': 'XMLHttpRequest',
            'X-Client-Name': 'AppDot',
            'X-Client-Platform': 'Web',
        };
        var appSessionId = (_a = getAppSession()) === null || _a === void 0 ? void 0 : _a.id;
        if (appSessionId) {
            headers['X-Session-Id'] = appSessionId;
        }
        var org = getOrgIdentifierFromURL();
        if (org) {
            headers['X-Client-Org-Name'] = org;
        }
        return headers;
    };
    OctoClient.prototype.getJson = function (response, defaultValue) {
        return __awaiter(this, void 0, void 0, function () {
            var value, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, response.json()];
                    case 1:
                        value = _b.sent();
                        return [2 /*return*/, value || defaultValue];
                    case 2:
                        _a = _b.sent();
                        return [2 /*return*/, defaultValue];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OctoClient.prototype.handleResponse = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var responseJson, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getJson(response, {})];
                    case 1:
                        responseJson = _a.sent();
                        resp = responseJson;
                        if (resp && resp.error && resp.error !== null) {
                            this.notificationError(resp.error.message);
                            return [2 /*return*/, undefined];
                        }
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    OctoClient.prototype.handleResponseQuietly = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var responseJson, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getJson(response, {})];
                    case 1:
                        responseJson = _a.sent();
                        resp = responseJson;
                        if (resp && resp.error && resp.error !== null) {
                            return [2 /*return*/, undefined];
                        }
                        return [2 /*return*/, resp];
                }
            });
        });
    };
    OctoClient.prototype.notificationError = function (comment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                toast(comment, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    type: 'error',
                });
                return [2 /*return*/];
            });
        });
    };
    /** Fetch Method */
    OctoClient.prototype.post = function (path, requestBody, token) {
        return __awaiter(this, void 0, void 0, function () {
            var body, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = JSON.stringify(requestBody);
                        return [4 /*yield*/, fetch(this.serverUrl + path, {
                                method: 'POST',
                                headers: this.headers(token),
                                body: body,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(response)];
                }
            });
        });
    };
    OctoClient.prototype.get = function (path, token) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.serverUrl + path, {
                            method: 'GET',
                            headers: this.headers(token),
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(response)];
                }
            });
        });
    };
    OctoClient.prototype.getQuietly = function (path, token) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(this.serverUrl + path, {
                            method: 'GET',
                            headers: this.headers(token),
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponseQuietly(response)];
                }
            });
        });
    };
    OctoClient.prototype.delete = function (path, requestBody, token) {
        return __awaiter(this, void 0, void 0, function () {
            var body, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = JSON.stringify(requestBody);
                        return [4 /*yield*/, fetch(this.serverUrl + path, {
                                method: 'DELETE',
                                headers: this.headers(token),
                                body: body,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(response)];
                }
            });
        });
    };
    OctoClient.prototype.put = function (path, requestBody, token) {
        return __awaiter(this, void 0, void 0, function () {
            var body, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = JSON.stringify(requestBody);
                        return [4 /*yield*/, fetch(this.serverUrl + path, {
                                method: 'PUT',
                                headers: this.headers(token),
                                body: body,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.handleResponse(response)];
                }
            });
        });
    };
    /** Endpoints */
    OctoClient.prototype.getAccountUsersById = function (token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'users';
                        return [4 /*yield*/, this.getQuietly(path, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    OctoClient.prototype.linkUserAccounts = function (body, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'users/link';
                        return [4 /*yield*/, this.post(path, body, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    /**
     * Page
     *
     * */
    OctoClient.prototype.getPageDetailById = function (pageId, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'pages/' + pageId;
                        return [4 /*yield*/, this.get(path, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    OctoClient.prototype.getCollectionSimpleList = function (request, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'collections/simple_list';
                        body = {
                            collectionId: request.collectionId,
                        };
                        return [4 /*yield*/, this.post(path, body, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    OctoClient.prototype.getSimpleUserList = function (request, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'users/simple_list';
                        body = {
                            accountId: request.accountId,
                        };
                        return [4 /*yield*/, this.post(path, body, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    OctoClient.prototype.getLocationSimpleList = function (request, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'locations/simple_list';
                        body = {
                            accountId: request.accountId,
                        };
                        return [4 /*yield*/, this.post(path, body, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    OctoClient.prototype.getRoleSimpleList = function (request, token) {
        return __awaiter(this, void 0, void 0, function () {
            var path, body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = 'roles/simple_list';
                        body = {
                            accountId: request.accountId,
                        };
                        return [4 /*yield*/, this.post(path, body, token)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/, resp ? resp.data : undefined];
                }
            });
        });
    };
    /**
     * Premission Related
     */
    OctoClient.prototype.updatePermissionToAccessGroup = function (accessGroupId, permissionId, actionType, token) {
        return __awaiter(this, void 0, void 0, function () {
            var body, path;
            return __generator(this, function (_a) {
                body = {
                    accessGroupId: accessGroupId,
                    permissionId: permissionId,
                    actionType: actionType.valueOf(),
                };
                path = 'permissions/add_remove_permission';
                return [2 /*return*/, this.post(path, body, token)];
            });
        });
    };
    return OctoClient;
}());
var octoClient = new OctoClient();
export { OctoClient };
export default octoClient;
