var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ZipExportFileSvg = function () { return (_jsxs("svg", { width: "26", height: "32", viewBox: "0 0 26 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M0 3C0 1.34315 1.34315 0 3 0H23C24.6569 0 26 1.34315 26 3V29C26 30.6569 24.6569 32 23 32H3C1.34315 32 0 30.6569 0 29V3Z", fill: "#167EE4" }), _jsx("path", { d: "M6.315 23.974L9.78 19.618H6.369V18.628H11.13V19.654L7.647 23.992H11.184V25H6.315V23.974ZM12.204 18.628H13.338V25H12.204V18.628ZM14.744 18.628H16.823C17.189 18.628 17.519 18.661 17.813 18.727C18.107 18.793 18.359 18.901 18.569 19.051C18.779 19.201 18.941 19.393 19.055 19.627C19.169 19.861 19.226 20.143 19.226 20.473C19.226 20.827 19.16 21.124 19.028 21.364C18.896 21.604 18.716 21.799 18.488 21.949C18.266 22.093 18.005 22.198 17.705 22.264C17.405 22.33 17.087 22.363 16.751 22.363H15.878V25H14.744V18.628ZM16.67 21.409C16.856 21.409 17.033 21.397 17.201 21.373C17.369 21.343 17.519 21.295 17.651 21.229C17.783 21.157 17.888 21.061 17.966 20.941C18.044 20.821 18.083 20.665 18.083 20.473C18.083 20.287 18.044 20.137 17.966 20.023C17.888 19.903 17.783 19.81 17.651 19.744C17.525 19.678 17.378 19.636 17.21 19.618C17.048 19.594 16.88 19.582 16.706 19.582H15.878V21.409H16.67Z", fill: "white" }), _jsx("mask", { id: "mask0_6320_58777", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "26", height: "32", children: _jsx("rect", { width: "26", height: "32", rx: "3", fill: "#167EE4" }) }), _jsxs("g", { mask: "url(#mask0_6320_58777)", children: [_jsx("rect", { x: "9", y: "-1", width: "13", height: "2", rx: "1", transform: "rotate(90 9 -1)", fill: "white" }), _jsx("rect", { x: "13", y: "-1", width: "6", height: "2", rx: "1", transform: "rotate(90 13 -1)", fill: "white" })] })] })); };
export var ZipExportFileIcon = function (props) { return (_jsx(Icon, __assign({ component: ZipExportFileSvg }, props))); };
