var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ReportMetricsIconSvg = function () { return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 5.5V8.5H15V5.5H4ZM2.5 3.5C2.22386 3.5 2 3.72386 2 4V10C2 10.2761 2.22386 10.5 2.5 10.5H16.5C16.7761 10.5 17 10.2761 17 10V4C17 3.72386 16.7761 3.5 16.5 3.5H2.5Z", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 15.5V18.5H15V15.5H4ZM2.5 13.5C2.22386 13.5 2 13.7239 2 14V20C2 20.2761 2.22386 20.5 2.5 20.5H16.5C16.7761 20.5 17 20.2761 17 20V14C17 13.7239 16.7761 13.5 16.5 13.5H2.5Z", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.00391 10.5V13.5H19.9999V10.5H9.00391ZM7.50391 8.5C7.22776 8.5 7.00391 8.72386 7.00391 9V15C7.00391 15.2761 7.22776 15.5 7.50391 15.5H21.4999C21.776 15.5 21.9999 15.2761 21.9999 15V9C21.9999 8.72386 21.776 8.5 21.4999 8.5H7.50391Z", fill: "white" })] })); };
export var ReportMetricsIcon = function (props) { return (_jsx(Icon, __assign({ component: ReportMetricsIconSvg }, props))); };
