var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var LogoutIconSvg = function () { return (_jsxs("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "22", height: "22", rx: "8" }), _jsx("path", { d: "M13.8298 8.99656C13.6346 8.8013 13.6346 8.48471 13.8298 8.28945L14.0655 8.05375C14.2608 7.85849 14.5774 7.85849 14.7726 8.05375L17.3654 10.6465C17.5606 10.8417 17.5606 11.1583 17.3654 11.3536L14.7726 13.9463C14.5774 14.1416 14.2608 14.1416 14.0655 13.9463L13.8298 13.7106C13.6346 13.5153 13.6346 13.1988 13.8298 13.0035L15.1666 11.6667L8.8335 11.6667C8.55735 11.6667 8.3335 11.4428 8.3335 11.1667L8.3335 10.8334C8.3335 10.5572 8.55735 10.3334 8.8335 10.3334H15.1666L13.8298 8.99656Z", fill: "red" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.3335 4.83334C4.3335 4.5572 4.55735 4.33334 4.8335 4.33334H11.1668C11.443 4.33334 11.6668 4.5572 11.6668 4.83334V7.83334C11.6668 8.10949 11.443 8.33334 11.1668 8.33334H10.8335C10.5574 8.33334 10.3335 8.10948 10.3335 7.83334V6.16668C10.3335 5.89053 10.1096 5.66668 9.8335 5.66668H6.16683C5.89069 5.66668 5.66683 5.89053 5.66683 6.16668V15.8333C5.66683 16.1095 5.89069 16.3333 6.16683 16.3333H9.8335C10.1096 16.3333 10.3335 16.1095 10.3335 15.8333V14.1667C10.3335 13.8905 10.5574 13.6667 10.8335 13.6667H11.1668C11.443 13.6667 11.6668 13.8905 11.6668 14.1667V17.1667C11.6668 17.4428 11.443 17.6667 11.1668 17.6667H4.8335C4.55735 17.6667 4.3335 17.4428 4.3335 17.1667V4.83334Z", fill: "red" })] })); };
export var LogoutIcon = function (props) { return (_jsx(Icon, __assign({ component: LogoutIconSvg }, props))); };
