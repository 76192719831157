import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { accountAccessGroupDetailsState, accountIdState, appSessionState, currentAccessGroupState, } from 'src/state';
import { getAppSession } from 'src/utils/Account';
import { useNavigate } from 'react-router-dom';
import { useAccountApi, useSessionApi } from '../api';
import { useAppSession } from '../component';
var useAccount = function () {
    var _a;
    var _b = useState(), autoSchedulerSetting = _b[0], setAutoSchedulerSetting = _b[1];
    var _c = useAuth0(), isAuthenticated = _c.isAuthenticated, logout = _c.logout;
    var clearAppCache = useAppSession().clearAppCache;
    var navigate = useNavigate();
    // !MARK: - Maintain app session here
    var _d = useRecoilState(accountIdState), accountId = _d[0], setAccountId = _d[1];
    var _e = useRecoilState(appSessionState), appSession = _e[0], setAppSession = _e[1];
    var _f = useSessionApi(), useSaveSessionMutation = _f.useSaveSessionMutation, deleteAppSession = _f.deleteAppSession;
    var _g = useAccountApi(), useGetAccountList = _g.useGetAccountList, getCurrentUserFetch = _g.getCurrentUserFetch, getCurrentAccessGroup = _g.getCurrentAccessGroup, getAccountAccessDataFetch = _g.getAccountAccessDataFetch;
    var setCurrentAccessGroup = useSetRecoilState(currentAccessGroupState);
    var setAccountAccessGroupDetails = useSetRecoilState(accountAccessGroupDetailsState);
    var _h = useGetAccountList({
        onError: function () {
            if (location.pathname.includes('/home')) {
                clearAppCache();
                logout({ logoutParams: { returnTo: "".concat(window.location.origin) } });
            }
        },
        retry: false,
        enabled: isAuthenticated,
    }), _j = _h.data, organizations = _j === void 0 ? [] : _j, isFetchingOrg = _h.isFetching;
    useEffect(function () {
        var _a;
        // TODO: temp solution for recoil latency issue
        if ((_a = getAppSession()) === null || _a === void 0 ? void 0 : _a.id) {
            getCurrentAccessGroup()
                .then(function (accessGroup) {
                setCurrentAccessGroup(accessGroup);
                if ((accessGroup === null || accessGroup === void 0 ? void 0 : accessGroup.generalPermissions.ACCESS_GROUPS) === true) {
                    getAccountAccessDataFetch().then(function (data) {
                        if (data) {
                            setAccountAccessGroupDetails(data.accessGroups);
                        }
                    });
                }
            })
                .catch(function () {
                setAccountId('');
                navigate({
                    pathname: '',
                }, { replace: true });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = getAppSession()) === null || _a === void 0 ? void 0 : _a.id, accountId]);
    useEffect(function () {
        if (accountId === (appSession === null || appSession === void 0 ? void 0 : appSession.accountId))
            return;
        if (accountId) {
            getCurrentUserFetch().then(function (currentUser) {
                if (currentUser) {
                    useSaveSessionMutation.mutate({
                        accountId: accountId,
                        accountUserId: currentUser.id,
                    }, {
                        onSuccess: function (session) {
                            if (session) {
                                // TODO: temp solution for recoil latency issue
                                localStorage.setItem('appSession', JSON.stringify(session));
                                setAppSession({
                                    id: session.id,
                                    accountId: accountId,
                                    accountUserId: currentUser.id,
                                });
                            }
                        },
                    });
                }
            });
        }
        else {
            if (appSession === null || appSession === void 0 ? void 0 : appSession.id) {
                deleteAppSession(appSession.id);
                setAppSession(undefined);
            }
        }
        // !MARK: - only run based on accountId update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);
    return {
        isFetchingOrg: isFetchingOrg,
        organizations: organizations,
        autoSchedulerSetting: autoSchedulerSetting,
        setAutoSchedulerSetting: setAutoSchedulerSetting,
    };
};
var AccountState = createContainer(useAccount);
export default AccountState;
