import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Dropdown } from 'antd';
import React from 'react';
import './ObjectPermissionDropdown.scss';
var ObjectPermissionDropdown = function (_a) {
    var overlay = _a.overlay, title = _a.title, className = _a.className, labelClassName = _a.labelClassName, canUpdate = _a.canUpdate;
    var label = (_jsx("span", { className: labelClassName ||
            "".concat(canUpdate ? 'cursor-pointer hover:bg-gray-100 ' : '', "rounded-sm bg-gray-60 px-2 py-1 text-xs font-medium text-gray-400"), children: title }));
    if (!canUpdate) {
        return label;
    }
    return (_jsx(Dropdown, { className: className || '' + ' ObjectPermissionDropdown', dropdownRender: function () { return _jsx(_Fragment, { children: overlay }); }, trigger: ['click'], overlayStyle: { width: '20rem', zIndex: 1100 }, children: label }));
};
export default React.memo(ObjectPermissionDropdown);
