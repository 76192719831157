import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { StripeBillingStatus } from 'src/shared';
import { useAccountApi, useSetting } from 'src/hooks/api';
import { accountIdState } from 'src/state';
import { ExclamationMarkIcon } from 'src/utils/icons/ExclamationMarkIcon';
var showStripeBannerSet = new Set([
    StripeBillingStatus.PAST_DUE,
    StripeBillingStatus.NO_PAYMENT,
    StripeBillingStatus.UNKNOWN,
]);
var StripeBillingWarningBanner = function () {
    var accountId = useRecoilValue(accountIdState);
    var useCreateBillingPortal = useSetting().useCreateBillingPortal;
    var useGetAccountStripeBillingStatus = useAccountApi().useGetAccountStripeBillingStatus;
    var data = useGetAccountStripeBillingStatus().data;
    var createBillingPortalMutation = useCreateBillingPortal();
    var goToPortal = useCallback(function () {
        createBillingPortalMutation.mutate({ accountId: accountId }, {
            onSuccess: function (resp) {
                if (resp === null || resp === void 0 ? void 0 : resp.portalUrl) {
                    location.assign(resp.portalUrl);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId]);
    if (!data || !showStripeBannerSet.has(data.status))
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "StripeWarningBanner flex w-full cursor-pointer items-center justify-between bg-red-500 px-6 py-3", onClick: goToPortal, children: [_jsxs("div", { className: "flex items-center ", children: [_jsx(ExclamationMarkIcon, {}), _jsx("div", { className: "ml-2 h-full text-base font-medium  text-white", children: data.message })] }), _jsx(Button, { className: "h-[25px] w-[143px] cursor-pointer rounded bg-gray-50 px-[10px] font-medium leading-[25px] hover:bg-gray-50 hover:text-black", size: "small", onClick: goToPortal, children: "Go to Billing Portal" })] }));
};
export default React.memo(StripeBillingWarningBanner);
