var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Select } from 'antd';
import { NumberBorderedIcon } from 'src/utils/icons/DropdownIcons/NumberBordered';
import { TextOutlinedBlackIcon } from 'src/utils/icons/DropdownIcons/TextOutlinedBlack';
import { GenerativeConfigurationContext } from '../GeneratedIdConfigurationModal';
import { GeneratedIdType } from '../interface/interface';
var GenerativeTypeSelector = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var options = [
        {
            value: GeneratedIdType.PLAINTEXT,
            label: (_jsxs("div", { children: [_jsx(TextOutlinedBlackIcon, { className: "-ml-[5px] pr-[3px]" }), "Text"] })),
        },
        {
            value: GeneratedIdType.INCREMENTAL,
            label: (_jsxs("div", { children: [_jsx(NumberBorderedIcon, { className: "pr-2" }), "Incremental"] })),
        },
        {
            value: GeneratedIdType.RANDOM,
            label: (_jsxs("div", { children: [_jsx(NumberBorderedIcon, { className: "pr-2" }), "Random"] })),
        },
    ];
    var _a = useContext(GenerativeConfigurationContext), generativeConfiguration = _a.generativeConfiguration, setGeneratedIdConfiguration = _a.setGeneratedIdConfiguration;
    return (_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "ID Generation Type" }), _jsx(Select, { options: options, onSelect: function (value) {
                    setGeneratedIdConfiguration(__assign(__assign({}, generativeConfiguration), { generatedIdType: value }));
                }, value: generativeConfiguration.generatedIdType, defaultValue: options[0].value, className: "w-full" })] }));
};
export default GenerativeTypeSelector;
