import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { PayRuleAdjustmentType } from 'src/components/Settings/PayRate/interface';
import { Divider } from 'antd';
import { getAccountCurrencyCode } from 'src/utils/Account';
var ShiftPayRulesLineItem = function (_a) {
    var payRuleName = _a.payRuleName, calculatedAdjustment = _a.calculatedAdjustment, lineItemType = _a.lineItemType, hasDivider = _a.hasDivider, lastSetToPayRuleTypeItemIndex = _a.lastSetToPayRuleTypeItemIndex, index = _a.index;
    var needStrickThrough = useMemo(function () {
        return (
        //Strikethrough base rate if there is a set to pay rule item below it
        (index === undefined && lastSetToPayRuleTypeItemIndex !== -1) ||
            //Strikethrough other rate when there is a set to pay rule item below it
            (index !== undefined && index < lastSetToPayRuleTypeItemIndex));
    }, [index, lastSetToPayRuleTypeItemIndex]);
    var accountCurrency = getAccountCurrencyCode();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between mx-6 text-xs", children: [_jsx("span", { children: payRuleName }), _jsxs("span", { style: needStrickThrough ? { textDecoration: 'line-through' } : undefined, children: [lineItemType !== PayRuleAdjustmentType.SET_TO &&
                                (calculatedAdjustment > 0 ? '+' : '-'), calculatedAdjustment.toLocaleString(undefined, {
                                style: 'currency',
                                currency: accountCurrency,
                                minimumFractionDigits: 2,
                            })] })] }), hasDivider && (_jsx("div", { className: "my-1 mx-6", children: _jsx(Divider, { className: "m-0" }) }))] }));
};
export default React.memo(ShiftPayRulesLineItem);
