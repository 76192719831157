var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { reportBuilderState, reportMetadataState, reportSearchTextState, reportTableState, } from 'src/state';
import { useRecoilValue } from 'recoil';
import { capitalize, isEqual } from 'lodash';
import { getReportCellValue } from 'src/services';
import { useReportApi } from 'src/hooks/api';
import { useDebounce, useReportComponent } from 'src/hooks/component';
import { Skeleton } from 'antd';
import BlockCollectionTableErrorBoundary from '../OptimizedTableView/Error/BlockCollectionTableErrorBoundary';
import ReportChart from './ReportChart';
import ReportTable from './ReportTable/ReportTable';
import './ReportBlock.scss';
import ReportColumnHeader from './ReportTable/ReportColumnHeader';
import ReportTableCell from './ReportTable/ReportTableCell';
import ReportModal from './ReportModal/ReportModal';
import ReportOptionHeader from './ReportHeader/ReportOptionHeader';
import { FAKE_UUID_FOR_TOTALS } from './Constant';
var ReportBlock = function (_a) {
    var blockId = _a.blockId, reportId = _a.reportId, openRecordDetail = _a.openRecordDetail;
    var _b = useRecoilValue(reportMetadataState(reportId)), sourceType = _b.sourceType, showChart = _b.showChart, showTable = _b.showTable;
    var _c = useRecoilValue(reportTableState(reportId)), _d = _c.rows, rows = _d === void 0 ? [] : _d, _e = _c.dateColumns, dateColumns = _e === void 0 ? [] : _e, _f = _c.metricColumns, metricColumns = _f === void 0 ? [] : _f, _g = _c.totalsRow, totalsRow = _g === void 0 ? null : _g;
    var _h = useState(false), reportModalVisible = _h[0], setReportModalVisible = _h[1];
    var reportBuilderData = useRecoilValue(reportBuilderState(reportId));
    var searchText = useRecoilValue(reportSearchTextState(reportId));
    var debouncedSearchText = useDebounce(searchText, 500);
    var _j = useState(reportBuilderData), prevReportBuilderData = _j[0], setPrevReportBuilderData = _j[1];
    var outerColumns = useMemo(function () { return (dateColumns.length ? dateColumns : ['Metrics']); }, [dateColumns]);
    var _k = useReportComponent(), setReportBlock = _k.setReportBlock, buildMetricReportCells = _k.buildMetricReportCells;
    var _l = useReportApi(), useGetReportBlock = _l.useGetReportBlock, useSaveReportBuilderData = _l.useSaveReportBuilderData;
    var _m = useGetReportBlock(reportId), data = _m.data, isLoading = _m.isLoading, isError = _m.isError;
    useEffect(function () {
        if (data) {
            setReportBlock(reportId, data);
            setPrevReportBuilderData(data.report);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    var reportBuilderResp = useSaveReportBuilderData({
        body: __assign({ reportId: reportId }, reportBuilderData),
        options: {
            enabled: !!reportBuilderData.dateDetails &&
                !!reportBuilderData.settings &&
                !isEqual(reportBuilderData, prevReportBuilderData),
        },
    }).data;
    useEffect(function () {
        if (reportBuilderResp) {
            if (!isEqual(reportBuilderData.dateDetails, prevReportBuilderData.dateDetails)) {
                setReportBlock(reportId, reportBuilderResp);
            }
            setPrevReportBuilderData(reportBuilderResp.report);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportBuilderResp]);
    var generateColumns = useCallback(function () {
        var tempColumns = [];
        // Report source column
        var reportSourceColumn = [
            {
                id: sourceType,
                Header: _jsx(ReportColumnHeader, { text: "".concat(capitalize(sourceType), "s") }),
                accessor: sourceType,
                sortType: function (rowA, rowB, sourceColumnId) {
                    var rowAValue = rowA.values[sourceColumnId].title;
                    var rowBValue = rowB.values[sourceColumnId].title;
                    return rowAValue > rowBValue ? 1 : rowAValue === rowBValue ? 0 : -1;
                },
                minWidth: 240,
                Cell: function (_a) {
                    var value = _a.value;
                    return (_jsx(ReportTableCell, { value: value, isUserType: sourceType === 'user', openRecordDetail: openRecordDetail }));
                },
            },
        ];
        tempColumns.push({
            id: 'reportSource',
            Header: _jsx(ReportColumnHeader, { text: "Report Source" }),
            accessor: 'reportSource',
            columns: reportSourceColumn,
        });
        // Outer columns of metrics
        outerColumns.forEach(function (dateColumn, dateIdx) {
            // Nested metric columns
            var tableMetricColumns = metricColumns.map(function (metricColumn, metricIdx) {
                var _a, _b;
                var columnId = "".concat(dateIdx, "-").concat(dateColumn, "-").concat((_b = (_a = metricColumn.metricType) !== null && _a !== void 0 ? _a : metricColumn.schemaId) !== null && _b !== void 0 ? _b : metricColumn.formula, "-").concat(metricIdx);
                return {
                    id: columnId,
                    Header: _jsx(ReportColumnHeader, { text: metricColumn.label }),
                    accessor: columnId,
                    sortType: function (rowA, rowB, metricColumnId) {
                        var rowAValue = getReportCellValue(rowA.values[metricColumnId]);
                        var rowBValue = getReportCellValue(rowB.values[metricColumnId]);
                        return rowAValue > rowBValue ? 1 : rowAValue === rowBValue ? 0 : -1;
                    },
                    width: 120,
                    Cell: function (_a) {
                        var value = _a.value;
                        return _jsx(ReportTableCell, { value: value });
                    },
                };
            });
            tempColumns.push({
                id: dateColumn,
                Header: _jsx(ReportColumnHeader, { text: dateColumn }),
                accessor: dateColumn,
                columns: tableMetricColumns,
            });
        });
        return tempColumns;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metricColumns, outerColumns, sourceType]);
    var rowData = useMemo(function () {
        var tempRecords = [];
        var nonFormulaMetricsLength = metricColumns.filter(function (metric) { return metric.metricType; }).length;
        // Totals row (first row)
        if (totalsRow) {
            var innerRecord = {};
            // Report source data (first column)
            innerRecord[sourceType] = {
                rid: FAKE_UUID_FOR_TOTALS,
                title: totalsRow.title,
            };
            // Metric data
            buildMetricReportCells(innerRecord, totalsRow, outerColumns, metricColumns, nonFormulaMetricsLength);
            tempRecords.push(innerRecord);
        }
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            var innerRecord = {};
            // Report source data (first column)
            innerRecord[sourceType] = {
                rid: row.recordId,
                title: row.title,
                avatar: row.avatar,
            };
            // Metric data
            buildMetricReportCells(innerRecord, row, outerColumns, metricColumns, nonFormulaMetricsLength);
            tempRecords.push(innerRecord);
        }
        return tempRecords;
    }, [buildMetricReportCells, metricColumns, outerColumns, rows, sourceType, totalsRow]);
    var getFilteredRowData = useCallback(function () {
        return rowData.filter(function (row, idx) { var _a; return ((_a = row[sourceType].title) === null || _a === void 0 ? void 0 : _a.includes(debouncedSearchText)) || idx === 0; });
    }, [debouncedSearchText, rowData, sourceType]);
    if (isError) {
        return _jsx(BlockCollectionTableErrorBoundary, { blockId: blockId });
    }
    if (isLoading) {
        return _jsx(Skeleton, { active: true });
    }
    return (_jsxs(_Fragment, { children: [_jsx(ReportOptionHeader, { blockId: blockId, reportId: reportId, openReportModal: function () { return setReportModalVisible(true); }, rowData: rowData }), showChart && (_jsxs(_Fragment, { children: [_jsx(ReportChart, { reportId: reportId, rowData: rowData, dateColumns: outerColumns, metricColumns: metricColumns }), showTable && _jsx("div", { className: "mb-6" })] })), showTable && (_jsx(ReportTable, { reportId: reportId, columns: generateColumns(), data: getFilteredRowData() })), reportModalVisible && (_jsx(ReportModal, { reportId: reportId, closeModal: function () { return setReportModalVisible(false); } }))] }));
};
export default React.memo(ReportBlock);
