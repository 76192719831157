var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { CellInputMode, CollectionTableType, CollectionType, NativePath, ValueDataType, } from 'src/shared';
import { collectionTableSchemaMapSelector, collectionTableSchemaNativePathMapSelector, collectionTableTypeSelector, } from 'src/state';
import { accountTimeZoneSelector } from 'src/state/selectors/withAccountDetailState';
import { checkStaleOptionInCell, getTagTextColor } from 'src/utils/Collection';
import { generateNativeCollectionTitle, generateShiftTitleColor, getSchemaContentTagColor, isMultiSelectSchema, } from './util';
var canGenerateTitleTagCollectionTypeSet = new Set([
    CollectionType.USER,
    CollectionType.SHIFT,
    CollectionType.CONTACT,
    CollectionType.TIMEOFF,
]);
var DateFormat = 'MMM D, YYYY';
var DateTimeFormat = 'MMM D, YYYY hh:mm A';
var RecordCardTags = function (_a) {
    var collectionId = _a.collectionId, visibleSchemaIds = _a.visibleSchemaIds, record = _a.record;
    var collectionType = useRecoilValue(collectionTableTypeSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var schemaMap = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var schemaNativePathMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(collectionId));
    var accountTimeZone = useRecoilValue(accountTimeZoneSelector);
    var tags = useMemo(function () {
        var _a, _b;
        var t = [];
        if (!record)
            return t;
        if (canGenerateTitleTagCollectionTypeSet.has(collectionType)) {
            var value = generateNativeCollectionTitle(collectionType, record, schemaNativePathMap, accountTimeZone);
            var titleColor = generateShiftTitleColor(collectionType, record, schemaNativePathMap);
            if (value) {
                t.push({
                    name: value,
                    schemaId: 'title',
                    isMulti: false,
                    value: [{ id: value, name: value, color: titleColor }],
                });
            }
        }
        for (var _i = 0, visibleSchemaIds_1 = visibleSchemaIds; _i < visibleSchemaIds_1.length; _i++) {
            var schemaId = visibleSchemaIds_1[_i];
            var schema = schemaMap[schemaId];
            var cell = record[schemaId];
            var tag = { name: schema.name, schemaId: schemaId };
            var isMulti = isMultiSelectSchema(schema);
            if (cell) {
                // For roll up schema, using cell.content to display the value
                if (cell.options.length && schema.type !== ValueDataType.ROLLUP) {
                    // Skip stale generated cells
                    if (schema.inputMode === CellInputMode.USER_DEFINED_GENERATED) {
                        var isStale = checkStaleOptionInCell(cell.options);
                        if (isStale) {
                            continue;
                        }
                    }
                    t.push(__assign(__assign({}, tag), { isMulti: isMulti, value: cell.options }));
                }
                else if (cell.versionedOptions.length) {
                    t.push(__assign(__assign({}, tag), { isMulti: isMulti, value: cell.versionedOptions }));
                }
                else if (cell.content) {
                    var type = schema.type;
                    var nativePath = schema.nativePath;
                    var name_1 = cell.content;
                    //Special handle for datetime value
                    if (nativePath !== NativePath.USER_LAST_ACTIVE &&
                        (type === ValueDataType.DATETIME || type === ValueDataType.NATIVE_DATETIME)) {
                        if (nativePath === NativePath.USER_DOB ||
                            !((_b = (_a = schema.configuration) === null || _a === void 0 ? void 0 : _a.showTime) !== null && _b !== void 0 ? _b : false)) {
                            name_1 = moment(cell.content).format(DateFormat);
                        }
                        else {
                            name_1 = moment(cell.content).tz(accountTimeZone).format(DateTimeFormat);
                        }
                    }
                    var color = getSchemaContentTagColor(schema, cell.content);
                    t.push(__assign(__assign({}, tag), { isMulti: false, value: [{ id: cell.content, name: name_1, color: color }] }));
                }
                else if (cell.files.length) {
                    t.push(__assign(__assign({}, tag), { isMulti: true, value: cell.files.map(function (file, index) { return ({
                            id: "".concat(file.name, "-").concat(index),
                            name: file.name,
                        }); }) }));
                }
            }
        }
        return t;
    }, [accountTimeZone, collectionType, record, schemaMap, schemaNativePathMap, visibleSchemaIds]);
    return (_jsx("div", { className: "flex w-full flex-wrap space-x-[2px]", children: tags.map(function (tag, index) { return (_jsx("div", { className: "RecordDetailContainer", children: tag.value.map(function (_a) {
                var id = _a.id, name = _a.name, color = _a.color;
                if (!name)
                    return null;
                return (_jsx("span", { className: "RecordDetailTagInfo", style: {
                        backgroundColor: color,
                        color: getTagTextColor(color),
                        marginRight: tag.isMulti ? '2px' : undefined,
                    }, title: name, children: name }, id));
            }) }, "".concat(tag.schemaId, "-").concat(index))); }) }));
};
export default React.memo(RecordCardTags);
