var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFetch } from './useFetch';
export var useNeedCoverageApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var queryClient = useQueryClient();
    // Need
    var useGetAllNeedTemplates = function (request) {
        return useQuery(['allNeedTemplates', JSON.stringify(request)], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/needs/list',
            }, request.body);
        }, __assign({}, request.options));
    };
    var useApplyNeedToSchedule = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/needs/apply',
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useRemoveNeedFromSchedule = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/needs/remove_from_schedule',
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useCreateNewNeedTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/needs',
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useGetExistingNeedTemplateDetail = function (request) {
        var _a, _b;
        return useQuery(['needTemplateDetail', (_b = (_a = request.body) === null || _a === void 0 ? void 0 : _a.templateId) !== null && _b !== void 0 ? _b : ''], function () {
            var _a;
            return customFetch({
                method: 'GET',
                url: "scheduling/needs/".concat((_a = request.body) === null || _a === void 0 ? void 0 : _a.templateId),
            });
        }, __assign({}, request.options));
    };
    var useUpdateNeedTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "scheduling/needs/".concat(request.templateId),
            }, request.info);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useRefreshNeedTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "scheduling/needs/refresh",
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useDeleteNeedTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'DELETE',
                url: "scheduling/needs/".concat(request.templateId),
            });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useDuplicateNeedTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "scheduling/needs/".concat(request.templateId, "/duplicate"),
            });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allNeedTemplates'] });
            },
        });
    };
    var useGetScheduleTemplateCountStats = function (request) {
        var _a;
        var body = request.body, options = request.options;
        return useQuery([
            'scheduleTemplateCountStats',
            body.blockId,
            body.timeZone,
            body.startFrom,
            body.endAt,
            JSON.stringify((_a = body.displaySettings) === null || _a === void 0 ? void 0 : _a.filters),
        ], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/needs/stats',
            }, body);
        }, __assign({}, options));
    };
    var useManuallyUpdateTemplateExpectedCount = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/needs/update_expected',
            }, request, undefined, 'Successfully updated expected count', undefined);
        });
    };
    // Coverage
    var useGetAllCoverageTemplates = function (request) {
        return useQuery(['allCoverageTemplates', JSON.stringify(request)], function () {
            return customFetch({
                method: 'POST',
                url: 'scheduling/coverage/list',
            }, request.body);
        }, __assign({}, request.options));
    };
    var useApplyCoverageToSchedule = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'scheduling/coverage/apply',
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useRemoveCoverageFromSchedule = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/coverage/remove_from_schedule',
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useCreateNewCoverageTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'scheduling/coverage',
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useGetExistingCoverageTemplateDetail = function (request) {
        var _a, _b;
        return useQuery(['coverageTemplateDetail', (_b = (_a = request.body) === null || _a === void 0 ? void 0 : _a.templateId) !== null && _b !== void 0 ? _b : ''], function () {
            var _a;
            return customFetch({
                method: 'GET',
                url: "scheduling/coverage/".concat((_a = request.body) === null || _a === void 0 ? void 0 : _a.templateId),
            });
        }, __assign({}, request.options));
    };
    var useUpdateCoverageTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "scheduling/coverage/".concat(request.templateId),
            }, request.info);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useRefreshCoverageTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "scheduling/coverage/refresh",
            }, request);
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useDuplicateCoverageTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "scheduling/coverage/".concat(request.templateId, "/duplicate"),
            });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useDeleteCoverageTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'DELETE',
                url: "scheduling/coverage/".concat(request.templateId),
            });
        }, {
            onSuccess: function () {
                queryClient.invalidateQueries({ queryKey: ['allCoverageTemplates'] });
            },
        });
    };
    var useGetStatusLogByDateRange = function (needOrCoverageId, type, request) {
        return useQuery(['GetLogByIdAndDateRange', JSON.stringify(request.body)], function () {
            var _a;
            return customFetch({
                method: 'POST',
                url: "scheduling/".concat(type, "/").concat(needOrCoverageId, "/logs/status"),
            }, {
                startAt: (_a = request.body) === null || _a === void 0 ? void 0 : _a.startAt,
            });
        }, __assign({}, request.options));
    };
    var useGetLogStatusByWeek = function (type, request) {
        return useQuery(['schedulingNeedsCoverageStatusByWeek', JSON.stringify(request.body)], function () {
            return customFetch({
                method: 'POST',
                url: "scheduling/".concat(type, "/logs/status"),
            }, request.body);
        }, __assign({}, request.options));
    };
    var useGetAllLogStatuses = function (type, request) {
        return useQuery(['allSchedulingNeedsCoverageStatus', JSON.stringify(request.body)], function () {
            return customFetch({
                method: 'POST',
                url: "scheduling/".concat(type, "/logs/status"),
            }, request.body);
        }, __assign({}, request.options));
    };
    return {
        useDeleteNeedTemplate: useDeleteNeedTemplate(),
        useCreateNewNeedTemplate: useCreateNewNeedTemplate(),
        useGetExistingNeedTemplateDetail: useGetExistingNeedTemplateDetail,
        useUpdateNeedTemplate: useUpdateNeedTemplate(),
        useRefreshNeedTemplate: useRefreshNeedTemplate(),
        useDuplicateNeedTemplate: useDuplicateNeedTemplate(),
        useGetAllNeedTemplates: useGetAllNeedTemplates,
        useApplyNeedToSchedule: useApplyNeedToSchedule(),
        useRemoveNeedFromSchedule: useRemoveNeedFromSchedule(),
        useGetScheduleTemplateCountStats: useGetScheduleTemplateCountStats,
        useManuallyUpdateTemplateExpectedCount: useManuallyUpdateTemplateExpectedCount(),
        useGetAllCoverageTemplates: useGetAllCoverageTemplates,
        useApplyCoverageToSchedule: useApplyCoverageToSchedule(),
        useRemoveCoverageFromSchedule: useRemoveCoverageFromSchedule(),
        useCreateNewCoverageTemplate: useCreateNewCoverageTemplate(),
        useGetExistingCoverageTemplateDetail: useGetExistingCoverageTemplateDetail,
        useUpdateCoverageTemplate: useUpdateCoverageTemplate(),
        useRefreshCoverageTemplate: useRefreshCoverageTemplate(),
        useDeleteCoverageTemplate: useDeleteCoverageTemplate(),
        useDuplicateCoverageTemplate: useDuplicateCoverageTemplate(),
        useGetStatusLogByDateRange: useGetStatusLogByDateRange,
        useGetLogStatusByWeek: useGetLogStatusByWeek,
        useGetAllLogStatuses: useGetAllLogStatuses,
    };
};
