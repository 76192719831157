import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Progress } from 'antd';
import React, { useCallback } from 'react';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { CalendarIcon } from 'src/utils/icons/DashboardIcons/CalendarIcon';
import { UsersIcon } from 'src/utils/icons/DashboardIcons/UserIcon';
import { getUserAlterStrokeColorByNumber } from '../DashboardUtils';
var AlertWidgetSiderSection = function (props) {
    var isLoading = props.isLoading, allStatus = props.allStatus, selectedStatus = props.selectedStatus, setSelectedStatus = props.setSelectedStatus, isUserType = props.isUserType;
    var renderUserPercentageBar = useCallback(function (item) {
        var _a;
        var strokeColor = getUserAlterStrokeColorByNumber((_a = item.percentage) !== null && _a !== void 0 ? _a : 0);
        var tailColor = item.type === (selectedStatus === null || selectedStatus === void 0 ? void 0 : selectedStatus.type) ? 'white' : '#F4F4F7';
        return (_jsx(Progress, { percent: item.percentage, size: "small", format: function (percentage) { return (_jsx("span", { className: "ml-1 text-gray-500", children: "".concat(percentage, "%") })); }, strokeColor: strokeColor, trailColor: tailColor }));
    }, [selectedStatus === null || selectedStatus === void 0 ? void 0 : selectedStatus.type]);
    if (isLoading)
        return (_jsx("div", { className: "TableStatusSummaryColumn TableStatusSummaryColumnLoading", children: _jsx(ZiraRowsSkeleton, { rowNumber: 10 }) }));
    return (_jsxs("div", { className: "TableStatusSummaryColumn ".concat(isUserType ? '' : 'justify-between'), children: [_jsx("div", { className: "pl-6 pr-2", children: _jsxs("div", { className: "mb-1 flex items-center text-xl font-medium", children: [isUserType ? (_jsx(UsersIcon, { className: "mr-2", style: { color: 'black', width: '24px' } })) : (_jsx(CalendarIcon, { className: "mr-2", style: { color: 'black', width: '24px' } })), _jsx("div", { children: "".concat(isUserType ? 'Team' : 'Shift', " Alerts") })] }) }), _jsx("div", { className: "".concat(isUserType ? 'mt-[50px]' : ''), children: allStatus === null || allStatus === void 0 ? void 0 : allStatus.map(function (item, index) {
                    var isLastItem = index === allStatus.length - 1;
                    return (_jsxs("div", { className: "".concat(!isUserType && 'flex', " ").concat(!isLastItem && 'border-b border-b-[#F2F2F2]', " cursor-pointer justify-between px-6 py-6 font-medium hover:bg-gray-50 ").concat(item.type === (selectedStatus === null || selectedStatus === void 0 ? void 0 : selectedStatus.type) ? 'bg-gray-60' : 'text-gray-500'), onClick: function () { return setSelectedStatus(item); }, children: [_jsx("div", { children: item.name }), isUserType ? renderUserPercentageBar(item) : _jsx("div", { children: item.count })] }, item.type));
                }) })] }));
};
export default React.memo(AlertWidgetSiderSection);
