var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ReportSourceIconSvg = function () { return (_jsx("svg", { width: "23", height: "23", viewBox: "0 0 23 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M21.5 2.5V1.5H20.5H2.5H1.5V2.5V10.5H1.25V1.25H21.75V10.659C21.6703 10.6199 21.5867 10.5873 21.5 10.5621V2.5ZM1.25 12.5H1.5V12.75H1.25V12.5ZM3.5 12.75V12.5H3.75V12.75H3.5ZM1.25 14.75H1.5V16V17H2.5H6.98824L6.89333 17.25H1.25V14.75ZM9.0326 17.25L9.12751 17H13.8725L13.9674 17.25H13.7H13.01H9.99001H9.30001H9.0326ZM16.1067 17.25L16.0118 17H20.5H21.5V16V14.6879C21.5867 14.6627 21.6703 14.6301 21.75 14.591V17.25H16.1067ZM19.5 12.5V12.75H8V12.5H19.5ZM14.4593 19.25H14.7267L15.6757 21.75H7.32426L8.27334 19.25H8.54075L8.20095 20.1451L7.68658 21.5H9.13585H13.8642H15.3134L14.7991 20.1451L14.4593 19.25Z", fill: "white", stroke: "white", strokeWidth: "2" }) })); };
export var ReportSourceIcon = function (props) { return (_jsx(Icon, __assign({ component: ReportSourceIconSvg }, props))); };
