import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useRef } from 'react';
import { Select } from 'antd';
import { useRecoilValue } from 'recoil';
import { automationFilterOptionsState } from '../atom';
import { AutomationFilterType } from '../Interface';
import { onSelectFilterOption } from '../util';
var Option = Select.Option, OptGroup = Select.OptGroup;
var AutomationDynamicVariableSelect = function (_a) {
    var value = _a.value, onSelect = _a.onSelect;
    var filterOptions = useRecoilValue(automationFilterOptionsState);
    var dynamicVariables = useMemo(function () {
        return filterOptions.filter(function (optionGroup) { return optionGroup.type === AutomationFilterType.RECORD; });
    }, [filterOptions]);
    var selectRef = useRef(null);
    var onSelectWithBlur = function (val, elem) {
        var _a;
        onSelect(val, elem);
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    };
    return (_jsx("div", { className: "dynamicVariableSelector", children: _jsx(Select, { ref: selectRef, className: "Selector", placeholder: "Insert Variable", onSelect: onSelectWithBlur, value: (value === null || value === void 0 ? void 0 : value.dynamicSecondarySchemaId)
                ? "".concat(value.dynamicSchemaId).concat(value.dynamicSecondarySchemaId)
                : value === null || value === void 0 ? void 0 : value.dynamicSchemaId, showSearch: true, filterOption: onSelectFilterOption, dropdownMatchSelectWidth: false, children: dynamicVariables === null || dynamicVariables === void 0 ? void 0 : dynamicVariables.map(function (group, groupIdx) { return (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option, idx) {
                    var _a;
                    var label = group.parentSchemaId !== null
                        ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                        : "".concat(option.name);
                    return (_jsx(Option, { value: "".concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), optionId: option.id, label: label, name: option.name, parentSchemaId: group.parentSchemaId, parentSchemaName: group.parentSchemaName, children: label }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx, "-").concat(option.id, "-").concat(label, "-").concat(idx)));
                }) }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx))); }) }) }));
};
export default React.memo(AutomationDynamicVariableSelect);
