import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ZiraModal from '../../ui/ZiraModal/ZiraModal';
import RecipeSummaryView from './RecipeSummary/RecipeSummaryView';
import { recipeAutomationsState, recipeDataChangesState, recipeOverriddenDataChangesState, recipePermissionChangesState, recipeSchemaIdMapState, recipeStepState, } from './atom';
import RecipeModalFooter from './RecipeModalFooter';
import RecipeDataChangesView from './RecipeDataChanges/RecipeDataChangesView';
import RecipeAutomationsView from './RecipeAutomations/RecipeAutomationsView';
import RecipePermissionChangesView from './RecipePermissionChanges/RecipePermissionChangesView';
import RecipeActivationView from './RecipeActivation/RecipeActivationView';
var RecipeModalWrapper = function (_a) {
    var recipeId = _a.recipeId;
    var _b = useRecoilState(recipeStepState), recipeStep = _b[0], setRecipeStep = _b[1];
    var resetRecipeDataChanges = useResetRecoilState(recipeDataChangesState);
    var resetRecipeOverriddenDataChanges = useResetRecoilState(recipeOverriddenDataChangesState);
    var resetRecipeSchemaIdMap = useResetRecoilState(recipeSchemaIdMapState);
    var resetRecipePermissionChanges = useResetRecoilState(recipePermissionChangesState);
    var resetRecipeAutomations = useResetRecoilState(recipeAutomationsState);
    useEffect(function () {
        setRecipeStep(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var navigate = useNavigate();
    var closeModal = useCallback(function () {
        navigate(-1);
        setRecipeStep(0);
        resetRecipeDataChanges();
        resetRecipeOverriddenDataChanges();
        resetRecipeSchemaIdMap();
        resetRecipePermissionChanges();
        resetRecipeAutomations();
    }, [
        navigate,
        resetRecipeAutomations,
        resetRecipeDataChanges,
        resetRecipeOverriddenDataChanges,
        resetRecipePermissionChanges,
        resetRecipeSchemaIdMap,
        setRecipeStep,
    ]);
    var goToNextStep = useCallback(function () {
        if (recipeStep === 4) {
            closeModal();
        }
        setRecipeStep(function (prev) { return prev + 1; });
    }, [closeModal, recipeStep, setRecipeStep]);
    return (_jsxs(ZiraModal, { show: true, onClose: closeModal, children: [_jsxs("div", { className: "flex w-full flex-col pl-[48px]", children: [recipeStep === 0 && _jsx(RecipeSummaryView, { recipeId: recipeId }), recipeStep === 1 && _jsx(RecipeDataChangesView, { recipeId: recipeId }), recipeStep === 2 && _jsx(RecipePermissionChangesView, { recipeId: recipeId }), recipeStep === 3 && _jsx(RecipeAutomationsView, { recipeId: recipeId }), recipeStep === 4 && _jsx(RecipeActivationView, { recipeId: recipeId }), _jsx(RecipeModalFooter, { recipeStep: recipeStep, onGoBack: closeModal, onGoNext: goToNextStep })] }), _jsx(Button, { className: "HeaderCloseButton", onClick: closeModal, children: _jsx(CloseOutlined, {}) })] }));
};
export default React.memo(RecipeModalWrapper);
