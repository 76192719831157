var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ArrowUpOutlinedSvg = function () { return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11.0365 5.79197L4.09716 12.7313C3.9019 12.9266 3.58531 12.9266 3.39005 12.7313L2.68295 12.0242C2.48768 11.8289 2.48768 11.5123 2.68295 11.3171L11.6829 2.31709C11.8782 2.12182 12.1948 2.12182 12.3901 2.31709L21.3901 11.3171C21.5853 11.5123 21.5853 11.8289 21.3901 12.0242L20.6829 12.7313C20.4877 12.9266 20.1711 12.9266 19.9758 12.7313L13.0365 5.79195V21.5365C13.0365 21.8127 12.8126 22.0365 12.5365 22.0365H11.5365C11.2603 22.0365 11.0365 21.8127 11.0365 21.5365V5.79197Z", fill: "#A7A8AC" }) })); };
export var ArrowUpOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ style: { transform: 'scale(0.7)' }, component: ArrowUpOutlinedSvg }, props))); };
