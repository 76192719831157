var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Checkbox, Switch, Select, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { usePageApi } from 'src/hooks/api';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { ExportPdfTemplateType, PayReportExportType, PayReportExportTemplateType, PayReportExportPdfType, pdfTemplateTypeOptions, pdfExportTypeOptions, } from '../Constant';
import './PdfExportTemplateConfigModal.scss';
var TextArea = Input.TextArea;
var Option = Select.Option;
var CheckboxGroup = Checkbox.Group;
var PdfExportTemplateConfigModal = function (_a) {
    var _b, _c, _d;
    var onCloseModal = _a.onCloseModal, existingTemplateData = _a.existingTemplateData;
    var _e = usePageApi(), useGetTemplateReportSchemasAndMetrics = _e.useGetTemplateReportSchemasAndMetrics, useCreateReportExportTemplate = _e.useCreateReportExportTemplate, useUpdateReportExportTemplate = _e.useUpdateReportExportTemplate, useDeleteReportExportTemplate = _e.useDeleteReportExportTemplate;
    var _f = useState([]), shifTableSchemas = _f[0], setShiftTableSchemas = _f[1];
    var _g = useState([]), shiftTableMetrics = _g[0], setShiftTableMetrics = _g[1];
    var _h = useState((_b = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showShiftTable) !== null && _b !== void 0 ? _b : false), showSchemasAndMetricsCheckBoxGroup = _h[0], setShowSchemasAndMetricsCheckBoxGroup = _h[1];
    var _j = useState((_c = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.pdfTemplateType) !== null && _c !== void 0 ? _c : ExportPdfTemplateType.DEFAULT), pdfTemplateType = _j[0], setPdfTemplateType = _j[1];
    var _k = useState((_d = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.summaryOnly) !== null && _d !== void 0 ? _d : false), isLocationSummaryOnly = _k[0], setIsLocationSummaryOnly = _k[1];
    var isDefaultTemplate = pdfTemplateType === ExportPdfTemplateType.DEFAULT;
    var isShiftDetailTemplate = pdfTemplateType === ExportPdfTemplateType.SHIFT_DETAIL;
    var isLocationInvoiceTemplate = pdfTemplateType === ExportPdfTemplateType.LOCATION_INVOICE;
    var isLocationSummaryTemplate = isLocationInvoiceTemplate && isLocationSummaryOnly;
    var form = Form.useForm()[0];
    useEffect(function () {
        if (!existingTemplateData) {
            useGetTemplateReportSchemasAndMetrics.mutate(PayReportExportType.ALL_SHIFT_DETAIL, {
                onSuccess: function (data) {
                    if (data) {
                        var schemas_1 = [];
                        data.schemas.forEach(function (schema) {
                            schemas_1.push({
                                value: schema.id,
                                label: schema.name,
                            });
                        });
                        setShiftTableSchemas(schemas_1);
                        var metrics_1 = [];
                        data.metrics.forEach(function (metric) {
                            metrics_1.push({
                                value: metric.metric,
                                label: metric.displayName,
                            });
                        });
                        setShiftTableMetrics(metrics_1);
                    }
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingTemplateData]);
    useEffect(function () {
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftTableSchemas) {
            var schemas_2 = [];
            existingTemplateData.shiftTableSchemas.forEach(function (schema) {
                schemas_2.push({
                    value: schema.id,
                    label: schema.name,
                });
            });
            setShiftTableSchemas(schemas_2);
        }
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftMetrics) {
            var metrics_2 = [];
            existingTemplateData.shiftMetrics.forEach(function (metric) {
                metrics_2.push({
                    value: metric.metric,
                    label: metric.displayName,
                });
            });
            setShiftTableMetrics(metrics_2);
        }
    }, [existingTemplateData]);
    var templateFormInitialValues = useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var schemas = [];
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftTableSchemas) {
            existingTemplateData.shiftTableSchemas.forEach(function (schema) {
                if (schema.showInReport) {
                    schemas.push(schema.id);
                }
            });
        }
        var metrics = [];
        if (existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.shiftMetrics) {
            existingTemplateData.shiftMetrics.forEach(function (metric) {
                if (metric.showInReport) {
                    metrics.push(metric.metric);
                }
            });
        }
        return {
            templateName: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.name,
            templateType: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.pdfTemplateType,
            groupByType: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.exportType,
            accountLogo: (_a = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showAccountLogo) !== null && _a !== void 0 ? _a : true,
            payMetrics: (_b = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showPayMetrics) !== null && _b !== void 0 ? _b : true,
            shiftTable: (_c = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showShiftTable) !== null && _c !== void 0 ? _c : false,
            shiftTableSchemas: schemas,
            shiftTableMetrics: metrics,
            timeOffTable: (_d = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showTimeoffTable) !== null && _d !== void 0 ? _d : false,
            textContent: existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.textContent,
            attestationInfo: (_e = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showAttestationInfo) !== null && _e !== void 0 ? _e : false,
            signature: (_f = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.showSignatureFields) !== null && _f !== void 0 ? _f : false,
            taxRate: (_g = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.taxRate) !== null && _g !== void 0 ? _g : 0,
            summaryOnly: (_h = existingTemplateData === null || existingTemplateData === void 0 ? void 0 : existingTemplateData.summaryOnly) !== null && _h !== void 0 ? _h : false,
        };
    }, [existingTemplateData]);
    var onSaveTemplate = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues_1, shiftTableSchemasInfo_1, shiftTableMetricsInfo_1, request, errorInfo_1;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    _k.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    formValues_1 = _k.sent();
                    shiftTableSchemasInfo_1 = [];
                    shiftTableMetricsInfo_1 = [];
                    if (!isLocationSummaryTemplate) {
                        shifTableSchemas.forEach(function (schema) {
                            var isSelected = formValues_1.shiftTable || isShiftDetailTemplate || isLocationSummaryTemplate
                                ? formValues_1.shiftTableSchemas.includes(schema.value)
                                : false;
                            shiftTableSchemasInfo_1.push({
                                id: schema.value,
                                showInReport: isSelected,
                            });
                        });
                        shiftTableMetrics.forEach(function (metric) {
                            var isSelected = formValues_1.shiftTable || isShiftDetailTemplate || isLocationSummaryTemplate
                                ? formValues_1.shiftTableMetrics.includes(metric.value)
                                : false;
                            shiftTableMetricsInfo_1.push({
                                metric: metric.value,
                                showInReport: isSelected,
                            });
                        });
                    }
                    request = {
                        type: PayReportExportTemplateType.PDF,
                        templateName: formValues_1.templateName,
                        pdfExportSettings: isDefaultTemplate
                            ? {
                                exportType: formValues_1.groupByType,
                                showAccountLogo: (_a = formValues_1.accountLogo) !== null && _a !== void 0 ? _a : false,
                                showPayMetrics: (_b = formValues_1.payMetrics) !== null && _b !== void 0 ? _b : false,
                                showShiftTable: (_c = formValues_1.shiftTable) !== null && _c !== void 0 ? _c : false,
                                shiftTableSchemas: shiftTableSchemasInfo_1,
                                shiftMetrics: shiftTableMetricsInfo_1,
                                showTimeoffTable: (_d = formValues_1.timeOffTable) !== null && _d !== void 0 ? _d : false,
                                textContent: (_e = formValues_1.textContent) !== null && _e !== void 0 ? _e : '',
                                showAttestationInfo: (_f = formValues_1.attestationInfo) !== null && _f !== void 0 ? _f : false,
                                showSignatureFields: (_g = formValues_1.signature) !== null && _g !== void 0 ? _g : false,
                            }
                            : undefined,
                        shiftDetailPdfExportSettings: isShiftDetailTemplate
                            ? {
                                exportType: formValues_1.groupByType,
                                shiftTableSchemas: shiftTableSchemasInfo_1,
                                shiftMetrics: shiftTableMetricsInfo_1,
                            }
                            : undefined,
                        locationInvoicePdfExportSettings: isLocationInvoiceTemplate
                            ? {
                                exportType: PayReportExportPdfType.PDF_PER_LOCATION,
                                summaryOnly: (_h = formValues_1.summaryOnly) !== null && _h !== void 0 ? _h : false,
                                taxRate: (_j = formValues_1.taxRate) !== null && _j !== void 0 ? _j : 0,
                                shiftTableSchemas: shiftTableSchemasInfo_1,
                                shiftMetrics: shiftTableMetricsInfo_1,
                            }
                            : undefined,
                    };
                    if (existingTemplateData) {
                        request = __assign(__assign({}, request), { id: existingTemplateData.id });
                        useUpdateReportExportTemplate.mutate(request, {
                            onSuccess: function () {
                                onCloseModal();
                            },
                        });
                    }
                    else {
                        useCreateReportExportTemplate.mutate(request, {
                            onSuccess: function () {
                                onCloseModal();
                            },
                        });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    errorInfo_1 = _k.sent();
                    return [2 /*return*/];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [
        existingTemplateData,
        form,
        isDefaultTemplate,
        isLocationInvoiceTemplate,
        isLocationSummaryTemplate,
        isShiftDetailTemplate,
        onCloseModal,
        shifTableSchemas,
        shiftTableMetrics,
        useCreateReportExportTemplate,
        useUpdateReportExportTemplate,
    ]);
    var onDeleteTemplate = useCallback(function () {
        if (existingTemplateData) {
            useDeleteReportExportTemplate.mutate(existingTemplateData.id, {
                onSuccess: function () {
                    onCloseModal();
                },
            });
        }
    }, [existingTemplateData, onCloseModal, useDeleteReportExportTemplate]);
    var modalFooter = useMemo(function () { return (_jsxs("div", { className: "mx-5 flex ".concat(existingTemplateData ? 'justify-between' : 'justify-end'), children: [existingTemplateData && (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this template ?", onConfirm: onDeleteTemplate, placement: "top", children: _jsx(Button, { className: "icon-text-btn DeleteButton", icon: _jsx(DeleteOutlined, { className: "mb-1 ml-px" }), children: "Delete" }) })), _jsxs("div", { children: [_jsx(Button, { className: "bg-gray-60 text-black hover:bg-gray-60 hover:text-black ", onClick: onCloseModal, children: "Cancel" }), _jsx(Button, { className: "bg-black text-white hover:bg-black hover:text-white", onClick: onSaveTemplate, children: "Save" })] })] })); }, [existingTemplateData, onCloseModal, onDeleteTemplate, onSaveTemplate]);
    return (_jsxs(Modal, { className: "PdfTemplateConfigModal", open: true, centered: true, footer: modalFooter, closable: false, width: 600, children: [_jsx("div", { className: "px-9 pt-6 text-lg font-semibold", children: "".concat(existingTemplateData ? 'Edit' : 'Create New', " PDF Template") }), _jsx("div", { className: "TemplateConfigForm", children: _jsxs(Form, { form: form, labelCol: { span: 6 }, wrapperCol: { span: 24 }, layout: "vertical", requiredMark: 'optional', preserve: false, initialValues: templateFormInitialValues, children: [_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Template Name" }), name: "templateName", rules: [
                                {
                                    required: true,
                                    message: 'Please enter template name.',
                                },
                            ], children: _jsx(Input, { placeholder: "Enter template name here", autoFocus: true }) }), _jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "PDF Template Type" }), name: "templateType", rules: [
                                {
                                    required: true,
                                    message: 'Please select one type.',
                                },
                            ], children: _jsx(Select, { className: "w-full", defaultValue: pdfTemplateType, value: pdfTemplateType, onChange: setPdfTemplateType, children: pdfTemplateTypeOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) }) }), !isLocationInvoiceTemplate && (_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Group By" }), name: "groupByType", rules: [
                                {
                                    required: true,
                                    message: 'Please select one type.',
                                },
                            ], children: _jsx(Select, { className: "w-full", children: pdfExportTypeOptions.map(function (option) { return (_jsx(Option, { value: option.value, children: option.displayValue }, option.value)); }) }) })), _jsx("div", { className: "h-4" }), isDefaultTemplate && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Account Logo" }), required: true, name: "accountLogo", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) }), _jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Pay Summary" }), required: true, name: "payMetrics", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) })] })), isLocationInvoiceTemplate && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Summary Only" }), required: true, name: "summaryOnly", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, { onChange: setIsLocationSummaryOnly }) }), !isLocationSummaryOnly && (_jsxs(Form.Item, { label: _jsx("div", { className: "ml-auto text-sm", children: "Tax Rate" }), name: "taxRate", rules: [
                                        {
                                            required: true,
                                            message: 'Please enter a tax rate.',
                                        },
                                    ], initialValue: 0, children: [_jsx(InputNumber, { className: "ml-auto w-[100px]", defaultValue: templateFormInitialValues.taxRate, min: 0, max: 100, step: 1, onChange: function (value) {
                                                return form.setFieldsValue({ taxRate: value });
                                            } }), _jsx("span", { className: "font-medium", children: " %" })] }))] })), !isLocationSummaryTemplate && (_jsxs("div", { className: "TableInfoConfigWrapper", children: [_jsx(Form.Item, { name: "shiftTable", label: _jsx("div", { className: "text-sm font-medium", children: "List of Shift" }), className: "TableSwitch", required: true, valuePropName: "checked", children: isDefaultTemplate && (_jsx(Switch, { onChange: function (status) {
                                            return setShowSchemasAndMetricsCheckBoxGroup(status);
                                        } })) }), (showSchemasAndMetricsCheckBoxGroup ||
                                    isShiftDetailTemplate ||
                                    isLocationInvoiceTemplate) && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "shiftTableSchemas", children: _jsx(CheckboxGroup, { className: "PropertiesCheckboxGroup", options: shifTableSchemas }) }), _jsx(Form.Item, { name: "shiftTableMetrics", className: "mb-0", children: _jsx(CheckboxGroup, { className: "PropertiesCheckboxGroup", options: shiftTableMetrics }) })] }))] })), isDefaultTemplate && (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Time Off Table" }), required: true, name: "timeOffTable", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) }), _jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Optional Text" }), name: "textContent", children: _jsx(TextArea, { placeholder: "Enter text content here" }) }), _jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Attestation Info" }), required: true, name: "attestationInfo", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) }), _jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Signature" }), required: true, name: "signature", className: "TableSwitch TemplateConfigItem", valuePropName: "checked", children: _jsx(Switch, {}) })] }))] }) })] }));
};
export default React.memo(PdfExportTemplateConfigModal);
