import { selectorFamily } from 'recoil';
import { detailModalSchemaGroups, collectionTableSchemaSelector } from 'src/state';
import { showSchemaColumnInDetailModalSidebar } from './util';
var allAvailableDetailModalSchemasInSiderSelector = selectorFamily({
    key: 'allAvailableDetailModalSchemasInSiderSelector',
    get: function (_a) {
        var collectionId = _a.collectionId, collectionType = _a.collectionType;
        return function (_a) {
            var get = _a.get;
            var collectionSchemaGroups = get(detailModalSchemaGroups(collectionId));
            var allOrderedSchemaIds = [];
            collectionSchemaGroups === null || collectionSchemaGroups === void 0 ? void 0 : collectionSchemaGroups.map(function (_a) {
                var schemas = _a.schemas;
                return schemas.map(function (schemaId) { return allOrderedSchemaIds.push(schemaId); });
            });
            var availableDetailModalSchemas = [];
            allOrderedSchemaIds.map(function (schemaId) {
                var schema = get(collectionTableSchemaSelector({ collectionId: collectionId, schemaId: schemaId }));
                if (schema && showSchemaColumnInDetailModalSidebar(schema, collectionType)) {
                    availableDetailModalSchemas.push(schema);
                }
            });
            return availableDetailModalSchemas;
        };
    },
});
export { allAvailableDetailModalSchemasInSiderSelector };
