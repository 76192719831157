var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, InputNumber, Select } from 'antd';
import { useCallback, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import PaymentRulesConfigurationContext from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesModal/PaymentRulesConfigurationContext';
import { lowerCase, startCase } from 'lodash';
import { getAccountCurrencySymbol } from 'src/utils/Account';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { currentDefaultRateState } from 'src/components/Settings/PayRate/atoms';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { FRONTEND_TOTAL_BILL } from 'src/components/FeatureGatingKeyConstant';
import { PayItemType, PayRuleAdjustmentType, PayRuleAdjustmentField, } from '../../../interface';
var PaymentAdjustmentSelector = function (_a) {
    var _b;
    var adjustment = _a.adjustment, payItemType = _a.payItemType, customCurrencySchemas = _a.customCurrencySchemas, unitSchemas = _a.unitSchemas;
    var _c = useContext(PaymentRulesConfigurationContext), paymentRule = _c.paymentRule, setRuleAdjustments = _c.setAdjustments;
    var payRuleAdjustment = paymentRule.adjustments;
    var _d = useRecoilState(currentDefaultRateState), currentDefaultRate = _d[0], setCurrentDefaultRate = _d[1];
    var payRateAdjustments = (_b = currentDefaultRate === null || currentDefaultRate === void 0 ? void 0 : currentDefaultRate.adjustments) !== null && _b !== void 0 ? _b : [];
    var adjustments = payItemType === PayItemType.PAY_RULE ? payRuleAdjustment : payRateAdjustments;
    var isBillSchemaEnabled = useRecoilValue(featureEnableStatusState(FRONTEND_TOTAL_BILL));
    var onUpdateAdjustment = useCallback(function (newAdjustment) {
        var index = adjustments.findIndex(function (adj) { return adj.id === adjustment.id; });
        var newAdjustments = adjustments.toSpliced(index, 1, newAdjustment);
        if (payItemType === PayItemType.PAY_RULE) {
            setRuleAdjustments(adjustments.toSpliced(index, 1, newAdjustment));
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { adjustments: newAdjustments }));
        }
    }, [
        adjustment.id,
        adjustments,
        currentDefaultRate,
        payItemType,
        setCurrentDefaultRate,
        setRuleAdjustments,
    ]);
    var onDeleteAdjustment = useCallback(function () {
        var newAdjustments = adjustments.filter(function (check) { return check.id !== adjustment.id; });
        if (payItemType === PayItemType.PAY_RULE) {
            setRuleAdjustments(newAdjustments);
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { adjustments: newAdjustments }));
        }
    }, [
        adjustment.id,
        adjustments,
        currentDefaultRate,
        payItemType,
        setCurrentDefaultRate,
        setRuleAdjustments,
    ]);
    var isCurrency = adjustment.type !== PayRuleAdjustmentType.BY_PERCENT;
    var currency = getAccountCurrencySymbol();
    var isPayRule = payItemType === PayItemType.PAY_RULE;
    var showCustomCurrencySelector = adjustment.type === PayRuleAdjustmentType.PER_UNIT;
    var fieldSelectorValue = adjustment.field !== PayRuleAdjustmentField.CUSTOM
        ? adjustment.field
        : adjustment.customSchemaId;
    return (_jsxs("div", { className: "flex gap-2", "data-testid": "PaymentAdjustmentSelector", children: [_jsxs(Select, { className: "flex-1", value: fieldSelectorValue, onSelect: function (value) {
                    if (value in PayRuleAdjustmentField) {
                        onUpdateAdjustment(__assign(__assign({}, adjustment), { field: value, customSchemaId: undefined }));
                    }
                    else {
                        // updating custom currency schema
                        onUpdateAdjustment(__assign(__assign({}, adjustment), { field: PayRuleAdjustmentField.CUSTOM, customSchemaId: value }));
                    }
                }, "data-testid": "PayRuleAdjustmentFieldSelector", children: [_jsx(Select.Option, { value: PayRuleAdjustmentField.PAY_RATE, children: startCase(lowerCase(PayRuleAdjustmentField.PAY_RATE.toString())) }), _jsx(Select.Option, { value: PayRuleAdjustmentField.BILL_RATE, children: startCase(lowerCase(PayRuleAdjustmentField.BILL_RATE.toString())) }), isPayRule && (_jsx(Select.Option, { value: PayRuleAdjustmentField.TOTAL_PAY, children: startCase(lowerCase(PayRuleAdjustmentField.TOTAL_PAY.toString())) })), isPayRule && isBillSchemaEnabled && (_jsx(Select.Option, { value: PayRuleAdjustmentField.TOTAL_BILL, children: startCase(lowerCase(PayRuleAdjustmentField.TOTAL_BILL.toString())) })), customCurrencySchemas.map(function (currencySchema) { return (_jsx(Select.Option, { value: currencySchema.id, children: currencySchema.name }, currencySchema.id)); })] }), isPayRule && (_jsxs(Select, { className: "flex-1", value: adjustment.type, onSelect: function (value) {
                    onUpdateAdjustment(__assign(__assign({}, adjustment), { type: value }));
                }, "data-testid": "PayRuleAdjustmentTypeSelector", children: [_jsx(Select.Option, { value: PayRuleAdjustmentType.SET_TO, children: startCase(lowerCase(PayRuleAdjustmentType.SET_TO)) }), _jsx(Select.Option, { value: PayRuleAdjustmentType.BY_AMOUNT, children: startCase(lowerCase(PayRuleAdjustmentType.BY_AMOUNT)) }), _jsx(Select.Option, { value: PayRuleAdjustmentType.BY_PERCENT, children: startCase(lowerCase(PayRuleAdjustmentType.BY_PERCENT)) }), _jsx(Select.Option, { value: PayRuleAdjustmentType.PER_UNIT, children: startCase(lowerCase(PayRuleAdjustmentType.PER_UNIT)) })] })), isPayRule && showCustomCurrencySelector && (_jsx(Select, { className: "flex-1", value: adjustment.unitSchemaId, onSelect: function (value) {
                    onUpdateAdjustment(__assign(__assign({}, adjustment), { unitSchemaId: value }));
                }, children: unitSchemas.map(function (unitSchema) { return (_jsx(Select.Option, { value: unitSchema.id, children: unitSchema.name }, unitSchema.id)); }) })), _jsx(InputNumber, { className: "flex-1 rounded-md", inputMode: "decimal", defaultValue: 0, value: adjustment.value, onChange: function (inputValue) {
                    if (inputValue != null) {
                        onUpdateAdjustment(__assign(__assign({}, adjustment), { value: inputValue }));
                    }
                }, formatter: function (value) { return "".concat(value).replace(/\B(?=(\d{3})+(?!\d))/g, ','); }, parser: function (value) { return value.replace(/\$\s?|(,*)/g, ''); }, addonAfter: _jsx("div", { className: "", children: isCurrency ? currency : '%' }), "data-testid": "PaymentAdjustmentSelectorInput" }), _jsx(Button, { className: "RecordDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-gray-60 ml-2", icon: _jsx(DeleteOutlinedIcon, {}), onClick: onDeleteAdjustment })] }));
};
export default PaymentAdjustmentSelector;
