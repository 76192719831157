var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableNonSchemaMutationsState, collectionTableNonSchemaNativePathSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath, NonSchemaNativePath } from 'src/shared';
import { getNativeFieldCellContent } from 'src/utils/Collection';
import RecordColorSelectorDropdown from 'src/components/RecordColorSelectorDropdown/RecordColorSelectorDropdown';
import UserAvatar from 'src/components/User/UserAvatar';
import { useWindowSize } from 'src/hooks/component';
import { uniqBy } from 'lodash';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var LocationDetailModalTitle = function (_a) {
    var _b;
    var recordId = _a.recordId, dataSource = _a.dataSource, recordColorSelectorProps = _a.recordColorSelectorProps, modalHeaderRef = _a.modalHeaderRef;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var locationImageUrl = (_b = useRecoilValue(collectionTableNonSchemaNativePathSelector(__assign(__assign({}, dataSource), { recordId: recordId, path: NonSchemaNativePath.LOCATION_IMAGE_URL })))) === null || _b === void 0 ? void 0 : _b.imageUrl;
    var _c = useWindowSize().windowWidth, windowWidth = _c === void 0 ? 0 : _c;
    var isWideScreenView = windowWidth > 1200;
    var _d = useState(160), modalHeaderWidth = _d[0], setModalHeaderHeight = _d[1];
    var modalHeaderObserver = useMemo(function () {
        return new ResizeObserver(function (entries) {
            if (entries[0]) {
                setModalHeaderHeight(entries[0].contentRect.width);
            }
        });
    }, []);
    useEffect(function () {
        if (modalHeaderRef === null || modalHeaderRef === void 0 ? void 0 : modalHeaderRef.current) {
            modalHeaderObserver.observe(modalHeaderRef.current);
        }
        return function () {
            modalHeaderObserver.disconnect();
        };
    }, [modalHeaderObserver, modalHeaderRef]);
    var setLocationImageUrl = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (imageUrl) {
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schema: NonSchemaNativePath.LOCATION_IMAGE_URL,
                        text: imageUrl,
                    }
                ], prev, true), 'schema');
            });
        };
    });
    var locationTitle = useMemo(function () {
        var title = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.LOCATION_NAME);
        var uploadProps = {
            canEdit: true,
            onUploadSuccess: function (newAvatarUrl) {
                setLocationImageUrl(newAvatarUrl);
            },
        };
        var avatarSize = isWideScreenView ? modalHeaderWidth : 60;
        return (_jsxs("div", { className: "flex flex-col gap-1", children: [recordColorSelectorProps && (_jsx(RecordColorSelectorDropdown, __assign({}, recordColorSelectorProps))), _jsx("div", { className: "flex ".concat(isWideScreenView ? 'flex-col gap-y-2' : 'flex-row items-center gap-x-2'), children: _jsx(UserAvatar, { name: title || 'Location', avatar: locationImageUrl, size: avatarSize, borderRadius: avatarSize / 10, uploadProps: uploadProps, color: recordColorSelectorProps === null || recordColorSelectorProps === void 0 ? void 0 : recordColorSelectorProps.currentColor }) }), _jsx("span", { children: title || 'Location' })] }));
    }, [
        modalRecord,
        nativeSchemaMap,
        isWideScreenView,
        modalHeaderWidth,
        recordColorSelectorProps,
        locationImageUrl,
        setLocationImageUrl,
    ]);
    return _jsx(RecordDetailModalTitle, { title: locationTitle });
};
export default React.memo(LocationDetailModalTitle);
