import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { SchemaPermissionLevelOptions } from '../Constants';
import ObjectPermissionDropdown from './ObjectPermissionDropdown';
import SchemaPermissionContent from './SchemaPermissionContent';
var SchemaPermissionDropdown = function (_a) {
    var currentPermission = _a.currentPermission, className = _a.className, labelClassName = _a.labelClassName, onUpdate = _a.onUpdate, canUpdate = _a.canUpdate;
    var permissionText = useMemo(function () { return SchemaPermissionLevelOptions(currentPermission); }, [currentPermission]);
    return (_jsx(ObjectPermissionDropdown, { overlay: _jsx(SchemaPermissionContent, { onChange: onUpdate, currentPermission: currentPermission }), title: permissionText, className: className, labelClassName: labelClassName, canUpdate: canUpdate }));
};
export default React.memo(SchemaPermissionDropdown);
