import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import CategoryIconWrapper from './CategoryIconWrapper';
var IndustryCategoryCard = function (props) {
    var categoryItem = props.categoryItem, goNext = props.goNext, selectedCategoryOnChange = props.selectedCategoryOnChange;
    var _a = useState(categoryItem.color || 'black'), iconColor = _a[0], setIconColor = _a[1];
    return (_jsxs("div", { className: "IndustryCard ml-2 mt-6 cursor-pointer bg-white", onClick: function () {
            goNext();
            selectedCategoryOnChange();
        }, onMouseEnter: function (e) {
            e.currentTarget.style.backgroundColor = categoryItem.color;
            setIconColor('white');
        }, onMouseLeave: function (e) {
            e.currentTarget.style.backgroundColor = 'white';
            setIconColor(categoryItem.color);
        }, children: [_jsx("div", { className: "CardImgContainer mt-2", children: _jsx(CategoryIconWrapper, { color: iconColor, path: categoryItem.icon }) }), _jsx("div", { className: "mt-2 font-medium", children: categoryItem.displayName })] }));
};
export default React.memo(IndustryCategoryCard);
