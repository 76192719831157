import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockPlaceholderImgState } from 'src/state/atoms/addNewBlockDrawerState';
var NewBlockPreviewContent = function (_a) {
    var isCreatingTableTypePage = _a.isCreatingTableTypePage;
    var img = useRecoilValue(blockPlaceholderImgState);
    return (_jsx(_Fragment, { children: img ? (_jsx("div", { className: "PreviewBlockWrapper", children: _jsx("img", { className: "PreviewBlockImgDisplay", src: img }) })) : (_jsx("div", { className: "PreviewBlockPlaceHolderWrapper", children: _jsxs("div", { className: "PreviewBlockPlaceHolderContent text-base font-medium text-gray-500", children: [isCreatingTableTypePage ? 'Table' : 'Block', " preview will appear here"] }) })) }));
};
export default React.memo(NewBlockPreviewContent);
