import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { useHealthMetricApi } from 'src/hooks/api';
import { chosenMetricWithDataState } from './Atoms';
import EditHealthRuleModal from './EditHealthMetricRuleModal';
var EditRuleButton = function (_a) {
    var _b, _c;
    var rule = _a.rule, refetch = _a.refetch;
    var _d = useHealthMetricApi(), updateHealthMetricRuleRequest = _d.updateHealthMetricRuleRequest, useGetHealthMetricConfigOptionsQuery = _d.useGetHealthMetricConfigOptionsQuery;
    var configOptions = useGetHealthMetricConfigOptionsQuery({
        cacheTime: 1000 * 60,
    }).data;
    var _e = useState(false), modalVisible = _e[0], setModalVisible = _e[1];
    var handleSave = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (name, type, timeRange, goal, filters, interval) {
            updateHealthMetricRuleRequest({
                ruleId: rule.id,
                name: name,
                type: type,
                timeRange: timeRange,
                goal: goal,
                settings: filters || interval ? { filters: filters, interval: interval } : undefined,
            }).then(function (res) {
                var _a, _b, _c, _d;
                if (res) {
                    setModalVisible(false);
                    set(chosenMetricWithDataState, {
                        rule: res,
                    });
                    if (res.type != rule.type ||
                        res.timeRange != rule.timeRange ||
                        JSON.stringify((_a = res.settings) === null || _a === void 0 ? void 0 : _a.filters) !=
                            JSON.stringify((_b = rule.settings) === null || _b === void 0 ? void 0 : _b.filters) ||
                        ((_c = res.settings) === null || _c === void 0 ? void 0 : _c.interval) != ((_d = rule.settings) === null || _d === void 0 ? void 0 : _d.interval)) {
                        refetch();
                    }
                }
            });
        };
    }, [
        refetch,
        rule.id,
        (_b = rule.settings) === null || _b === void 0 ? void 0 : _b.filters,
        (_c = rule.settings) === null || _c === void 0 ? void 0 : _c.interval,
        rule.timeRange,
        rule.type,
        updateHealthMetricRuleRequest,
    ]);
    if (!configOptions)
        return _jsx(_Fragment, {});
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { className: "flex items-center bg-white font-medium shadow-block hover:bg-accent hover:text-white hover:shadow-xl", onClick: function () { return setModalVisible(true); }, children: [_jsx(EditOutlined, {}), " Edit Metric"] }), _jsx(EditHealthRuleModal, { visible: modalVisible, setVisible: setModalVisible, rule: rule, onSave: handleSave, configOptions: configOptions })] }));
};
export default EditRuleButton;
