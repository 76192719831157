import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useWindowSize } from 'src/hooks/component';
import { TotalShiftsIcon } from 'src/utils/icons/PayrollViewIcons/TotalShiftsMetricIcon';
import { selectedShiftPayrollStatusState } from '../atom';
import { shiftPayrollCountMetricCardInfoMap } from '../Constant';
import TimesheetShiftStatusCountCard from './TimesheetShiftStatusCountCard';
var TimesheetShiftMetricCardsRow = function (_a) {
    var totalCount = _a.totalCount, statusCount = _a.statusCount;
    var windowWidth = useWindowSize().windowWidth;
    var _b = useRecoilState(selectedShiftPayrollStatusState), selectedShiftStatus = _b[0], setSelectedShiftStatus = _b[1];
    // reset selected status to undefined when unmount current pay view
    useEffect(function () {
        return function () {
            setSelectedShiftStatus(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var renderMetricCard = useCallback(function (metricType) {
        var _a;
        var isSelected = metricType === selectedShiftStatus;
        var isTotal = metricType === undefined;
        var cardBgColor = !isSelected
            ? 'white'
            : isTotal
                ? 'black'
                : shiftPayrollCountMetricCardInfoMap[metricType].cardBgColor;
        var icon = isTotal ? (_jsx(TotalShiftsIcon, {})) : (shiftPayrollCountMetricCardInfoMap[metricType].icon);
        var name = isTotal
            ? 'Total Shifts'
            : shiftPayrollCountMetricCardInfoMap[metricType].displayName;
        var value = isTotal ? totalCount : statusCount ? ((_a = statusCount[metricType]) !== null && _a !== void 0 ? _a : 0) : 0;
        return (_jsx(TimesheetShiftStatusCountCard, { isSelected: isSelected, name: name, count: value, cardBgColor: cardBgColor, icon: icon, setSelectedShiftStatus: function () { return setSelectedShiftStatus(metricType); } }));
    }, [selectedShiftStatus, setSelectedShiftStatus, statusCount, totalCount]);
    return (_jsxs("div", { className: "flex flex-wrap gap-[32px] items-center mt-10", style: {
            padding: (windowWidth || 577) > 576 ? '0px 48px 0px 48px' : '0px 0px 0px 0px',
        }, children: [renderMetricCard(undefined), Object.keys(shiftPayrollCountMetricCardInfoMap).map(function (metric) {
                return renderMetricCard(metric);
            })] }));
};
export default React.memo(TimesheetShiftMetricCardsRow);
