import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Radio, Row, Select } from 'antd';
import { generateMonthOptions, generateMonths, generateYearOptions } from 'src/utils/DatePicker';
var CalendarHeader = function (_a) {
    var value = _a.value, type = _a.type, onChange = _a.onChange, onTypeChange = _a.onTypeChange;
    var current = value.clone();
    var localeData = value.localeData();
    var months = generateMonths(current, localeData);
    var monthOptions = generateMonthOptions(months);
    var month = value.month();
    var year = value.year();
    var yearOptions = generateYearOptions();
    return (_jsx("div", { style: { padding: 8 }, children: _jsxs(Row, { gutter: 8, children: [_jsx(Col, { children: _jsx(Select, { size: "small", dropdownMatchSelectWidth: false, className: "my-year-select", onChange: function (newYear) {
                            var now = value.clone().year(Number(newYear));
                            onChange(now);
                        }, value: String(year), children: yearOptions }) }), _jsx(Col, { children: _jsx(Select, { size: "small", dropdownMatchSelectWidth: false, value: String(month), onChange: function (selectedMonth) {
                            var newValue = value.clone();
                            newValue.month(parseInt(selectedMonth, 10));
                            onChange(newValue);
                        }, children: monthOptions }) }), _jsx(Col, { children: _jsxs(Radio.Group, { size: "small", onChange: function (e) { return onTypeChange(e.target.value); }, value: type, children: [_jsx(Radio.Button, { value: "month", children: "Month" }), _jsx(Radio.Button, { value: "year", children: "Year" })] }) })] }) }));
};
export default CalendarHeader;
