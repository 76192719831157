import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useReverseCollectionApi } from 'src/hooks/api';
import { currentReverseRelationIdState, tableSelectedViewIdState, tableCollectionViewsState, } from 'src/state';
import { useReverseCollectionComponent } from '..';
export var useInitReverseCollectionViews = function (recordId, reverseRelationId, schemaId, standardRelation, collectionComponent, fetchViews) {
    if (fetchViews === void 0) { fetchViews = true; }
    var _a = useRecoilState(tableSelectedViewIdState(reverseRelationId)), viewId = _a[0], setViewId = _a[1];
    var _b = useRecoilState(tableCollectionViewsState(reverseRelationId)), reverseCollectionViews = _b[0], setReverseCollectionViews = _b[1];
    var setReverseRelationId = useSetRecoilState(currentReverseRelationIdState);
    var resetReverseCollectionState = useReverseCollectionComponent().resetReverseCollectionState;
    var useGetReverseCollectionViews = useReverseCollectionApi().useGetReverseCollectionViews;
    var initialReverseCollectionViews = useGetReverseCollectionViews({
        body: { recordId: recordId, schemaId: schemaId, standardRelation: standardRelation, standardComponent: collectionComponent },
        options: { enabled: fetchViews, cacheTime: 0 },
    }).data;
    //! Set reverse collection views
    useEffect(function () {
        if (initialReverseCollectionViews) {
            setReverseCollectionViews(initialReverseCollectionViews);
            if (!viewId ||
                !initialReverseCollectionViews.orderedViews.find(function (view) { return view.viewId === viewId; })) {
                // Set to first view if not set or if view is not found (due to being deleted)
                setViewId(initialReverseCollectionViews.orderedViews[0].viewId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialReverseCollectionViews]);
    useEffect(function () {
        setReverseRelationId(reverseRelationId);
        //! Reset reverse collection
        return function () {
            resetReverseCollectionState(reverseRelationId, reverseCollectionViews.orderedViews.map(function (view) { return view.viewId; }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return initialReverseCollectionViews;
};
