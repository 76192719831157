export var SyncType;
(function (SyncType) {
    SyncType["ONE_WAY"] = "ONE_WAY";
    SyncType["TWO_WAY"] = "TWO_WAY";
})(SyncType || (SyncType = {}));
export var SourceOfTruth;
(function (SourceOfTruth) {
    SourceOfTruth["IP"] = "IP";
    SourceOfTruth["ZIRA"] = "ZIRA";
})(SourceOfTruth || (SourceOfTruth = {}));
