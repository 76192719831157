var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var WebAccessIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", height: "24", width: "24", children: _jsxs("g", { children: [_jsx("rect", { x: "0.5", y: "2", width: "13", height: "9", rx: "0.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "6", y1: "11", x2: "5", y2: "13.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "8", y1: "11", x2: "9", y2: "13.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "4", y1: "13.5", x2: "10", y2: "13.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" })] }) })); };
export var WebAccessIcon = function (props) { return (_jsx(Icon, __assign({ component: WebAccessIconSvg }, props))); };
