var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import TimeoffSummaryTable from 'src/components/TableView/TimeoffSummaryTable';
import { usePageApi } from 'src/hooks/api';
var UserDetailTimeOffSummary = function (_a) {
    var recordId = _a.recordId;
    var _b = usePageApi(), useGetTimeoffSummaryData = _b.useGetTimeoffSummaryData, useSaveTimeoffSummaryData = _b.useSaveTimeoffSummaryData;
    var saveTimeoffData = useSaveTimeoffSummaryData();
    var _c = useState([]), timeoffSummaryData = _c[0], setTimeoffSummaryData = _c[1];
    var fetchTimeoffSummaryData = useGetTimeoffSummaryData({}, recordId).data;
    useEffect(function () {
        if (fetchTimeoffSummaryData) {
            setTimeoffSummaryData(__spreadArray([], fetchTimeoffSummaryData, true));
        }
    }, [fetchTimeoffSummaryData]);
    var generateTimeoffRequestBody = useCallback(function (newTimeoffSummaryData) {
        var requestBody = [];
        for (var i = 0; i < timeoffSummaryData.length; i++) {
            var originRecord = timeoffSummaryData[i];
            var tableRecord = newTimeoffSummaryData[i];
            var accrualAdjustment = tableRecord.hoursAccrued - originRecord.hoursAccrued;
            var usedAdjustment = tableRecord.hoursUsed - originRecord.hoursUsed;
            if (accrualAdjustment != 0) {
                requestBody.push({
                    userRid: recordId,
                    typeId: tableRecord.typeId,
                    accrualType: 'MANUAL_ACCRUAL',
                    adjustment: accrualAdjustment,
                });
            }
            if (usedAdjustment != 0) {
                requestBody.push({
                    userRid: recordId,
                    typeId: tableRecord.typeId,
                    accrualType: 'MANUAL_USER',
                    adjustment: usedAdjustment,
                });
            }
        }
        return requestBody;
    }, [recordId, timeoffSummaryData]);
    var saveTimeOffSummaryData = useCallback(function (newData) {
        var request = generateTimeoffRequestBody(newData);
        if (request) {
            var oldTimeoffSummaryData_1 = cloneDeep(timeoffSummaryData);
            setTimeoffSummaryData(newData);
            saveTimeoffData.mutateAsync(request, {
                onError: function () {
                    // Revert data
                    setTimeoffSummaryData(oldTimeoffSummaryData_1);
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generateTimeoffRequestBody]);
    return (_jsxs("div", { className: "TimeoffSummaryTableCollection", children: [_jsx("div", { className: "TimeoffSummaryTableTitle", children: "Time Off Summary" }), _jsx("div", { className: "TimeoffSummaryTable", children: _jsx(TimeoffSummaryTable, { timeoffSummaryData: timeoffSummaryData, summaryDataOnChange: saveTimeOffSummaryData }) })] }));
};
export default React.memo(UserDetailTimeOffSummary);
