var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SearchOutlinedSvg = function () { return (_jsx("svg", { width: "15", height: "16", viewBox: "0 0 15 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.25 7.375C11.25 9.79125 9.29125 11.75 6.875 11.75C4.45875 11.75 2.5 9.79125 2.5 7.375C2.5 4.95875 4.45875 3 6.875 3C9.29125 3 11.25 4.95875 11.25 7.375ZM10.4016 11.7575C9.43689 12.5347 8.21028 13 6.875 13C3.7684 13 1.25 10.4816 1.25 7.375C1.25 4.2684 3.7684 1.75 6.875 1.75C9.9816 1.75 12.5 4.2684 12.5 7.375C12.5 8.69599 12.0446 9.91062 11.2823 10.8705L13.5538 13.1419C13.6758 13.264 13.6758 13.4618 13.5538 13.5839L13.1118 14.0258C12.9898 14.1479 12.7919 14.1479 12.6699 14.0258L10.4016 11.7575Z", fill: "#A7A8AC" }) })); };
export var SearchOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: SearchOutlinedSvg }, props))); };
