var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, Row, Tag } from 'antd';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { CellInputMode, ItemRelationTypeSet } from '../../shared';
import { isDescription, isEntry, } from '../../services/Interfaces';
import CustomCell from '../Cell/CustomCell';
import CustomTableCell from '../TableView/TableCell/CustomTableCell';
import './FillFormPage.scss';
var formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 32 },
};
var FillFormContent = function (props) {
    var layout = props.layout, schemas = props.schemas, recordDetails = props.recordDetails, loading = props.loading, form = props.form, onFinish = props.onFinish;
    var renderCellValue = useCallback(function (layoutItem) {
        if (!recordDetails || !schemas)
            return null;
        var schema = schemas[layoutItem.schemaId];
        var values;
        var recordId = recordDetails.recordOrders[0];
        if (schema.inputMode === CellInputMode.NATIVE) {
            var foundNative = _.filter(recordDetails.nativeFields, function (native) { return native.schemaId === schema.id; })[0];
            values = {
                recordId: recordId,
                columnId: schema.id,
                nativeTypeValue: foundNative,
            };
        }
        else {
            var foundItem = _.find(Object.values(recordDetails.items), function (item) { return item.schemaId === schema.id; });
            if (ItemRelationTypeSet.has(schema.type)) {
                values = {
                    recordId: recordId,
                    columnId: schema.id,
                    relationValues: foundItem === null || foundItem === void 0 ? void 0 : foundItem.values.filter(function (v) { return recordDetails.relations[v]; }).map(function (valueId) { return recordDetails.relations[valueId]; }),
                };
            }
            else if (schema.inputMode === CellInputMode.PLAIN) {
                values = {
                    recordId: recordId,
                    columnId: schema.id,
                    displayValueData: foundItem === null || foundItem === void 0 ? void 0 : foundItem.data,
                };
            }
            else {
                values = {
                    recordId: recordId,
                    columnId: schema.id,
                    primitiveValues: foundItem === null || foundItem === void 0 ? void 0 : foundItem.values.map(function (valueId) { return recordDetails.selectionValues[valueId]; }),
                };
            }
        }
        return values;
    }, [recordDetails, schemas]);
    var renderEntryItem = useCallback(function (layoutItem, index) {
        if (!schemas || !schemas[layoutItem.schemaId])
            return null;
        var handleChange = function (value) {
            var obj = {};
            obj[layoutItem.schemaId] = value;
            form === null || form === void 0 ? void 0 : form.setFieldsValue(obj);
        };
        var renderLabel = function (label, required) {
            return (_jsx("div", { className: "p-0", children: _jsxs("div", { className: "FormLabelRow", children: [_jsx("div", { className: "FillFormLabel", children: label }), required && (_jsx(Tag, { color: "lightgray", className: "ItemPanelHeaderTag", children: "Required" }))] }) }));
        };
        var existingCellValue = renderCellValue(layoutItem);
        var tableCellProps = _.merge({
            schema: schemas[layoutItem.schemaId],
            editCell: handleChange,
            canEdit: layoutItem.visibility == 'EDITABLE',
            isDetailModal: true,
        }, existingCellValue);
        var simpleCellProps = {
            schema: schemas[layoutItem.schemaId],
            editCell: handleChange,
            canEdit: layoutItem.visibility == 'EDITABLE',
            defaultValue: layoutItem.defaultDisplayValue,
            updateWithDefault: true,
        };
        return (_jsxs(Form.Item, { label: renderLabel(layoutItem.label, layoutItem.required), name: isEntry(layoutItem) ? layoutItem.schemaId : '', rules: [
                {
                    required: layoutItem.required,
                    message: "".concat(layoutItem.label, " is required"),
                },
            ], colon: true, className: "FillFormItem", hidden: layoutItem.visibility == 'HIDDEN', children: [layoutItem.description && (_jsx("div", { className: "FillFormDescription", children: layoutItem.description })), _jsx("div", { className: "FillFormInputWrapper", children: existingCellValue ? (_jsx(CustomTableCell, __assign({}, tableCellProps))) : (_jsx(CustomCell, __assign({}, simpleCellProps))) })] }, index));
    }, [form, renderCellValue, schemas]);
    var renderDescriptionItem = useCallback(function (layoutItem, index) {
        return (_jsxs(Form.Item, { children: [_jsx(Row, { className: "FormLabelRow", children: _jsx("div", { className: "FillFormLabel", children: layoutItem.label }) }), _jsx(Row, { className: "FillFormDescription", children: layoutItem.description })] }, index));
    }, []);
    var renderImageItem = useCallback(function (layoutItem, index) {
        return (_jsxs(Form.Item, { children: [_jsx("div", { children: layoutItem.label }), _jsx("img", { src: layoutItem.imageUrl, width: "100%", height: "auto" })] }, index));
    }, []);
    return (_jsx("div", { className: "FillFormContainer", children: _jsxs(Form, __assign({}, formLayout, { onFinish: onFinish, form: form, requiredMark: false, scrollToFirstError: true, className: "FillForm", children: [layout === null || layout === void 0 ? void 0 : layout.map(function (layoutItem, index) {
                    return isEntry(layoutItem)
                        ? renderEntryItem(layoutItem, index)
                        : isDescription(layoutItem)
                            ? renderDescriptionItem(layoutItem, index)
                            : renderImageItem(layoutItem, index);
                }), _jsx(Form.Item, { children: _jsx(Button, { htmlType: "submit", loading: loading, className: "FormSubmitButton", children: "Submit" }) })] })) }));
};
export default React.memo(FillFormContent);
