var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { Button, Select } from 'antd';
import { BlackPlusIcon } from 'src/utils/icons/BlackPlusIcon';
import PaymentRulesConfigurationContext from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesModal/PaymentRulesConfigurationContext';
import BuilderBar from 'src/components/Automations/BuilderBar';
import { AutomationComponent, AutomationFilterType, } from 'src/components/Automations/Interface';
import { useAccountApi } from 'src/hooks/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { appSessionState } from 'src/state';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import { CollectionType } from 'src/shared';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import PaymentConditionsOperandSelector from 'src/components/Settings/PayRate/PayItemConfigModal/PaymentConditionsSection/component/PaymentConditionsOperandSelector';
import { formFilterSaveObject } from 'src/components/Automations/util';
import { v4 as uuidv4 } from 'uuid';
import './PaymentConditionsSection.scss';
import { availablePayConditionSchemasState, currentDefaultRateState, } from 'src/components/Settings/PayRate/atoms';
import { PayItemType } from '../../interface';
var PaymentConditionsSection = function (_a) {
    var _b, _c;
    var payItemType = _a.payItemType;
    var _d = useContext(PaymentRulesConfigurationContext), paymentRule = _d.paymentRule, setPayRuleConditions = _d.setConditions;
    var payRuleConditions = paymentRule.conditions;
    var _e = useRecoilState(currentDefaultRateState), currentDefaultRate = _e[0], setCurrentDefaultRate = _e[1];
    var payRateConditions = (_b = currentDefaultRate === null || currentDefaultRate === void 0 ? void 0 : currentDefaultRate.conditions) !== null && _b !== void 0 ? _b : [];
    var conditions = payItemType === PayItemType.PAY_RULE ? payRuleConditions : payRateConditions;
    var accountId = ((_c = useRecoilValue(appSessionState)) !== null && _c !== void 0 ? _c : {}).accountId;
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var availablePayConditionSchemas = useRecoilValue(availablePayConditionSchemasState);
    // Filter out second level schemas
    var filterOptions = availablePayConditionSchemas === null || availablePayConditionSchemas === void 0 ? void 0 : availablePayConditionSchemas.filter(function (group) {
        return group.type === AutomationFilterType.RECORD &&
            group.parentSchemaId === null &&
            group.parentSchemaName === null;
    });
    var useGetAccountCollectionOptionsQuery = useAccountApi().useGetAccountCollectionOptionsQuery;
    var allCollections = useGetAccountCollectionOptionsQuery().data;
    var builderSendDataBack = useCallback(function (builderState, index) {
        var newConditions = conditions.map(function (_condition, idx) {
            if (idx !== index)
                return _condition;
            else {
                return __assign(__assign({}, _condition), formFilterSaveObject(builderState));
            }
        });
        if (payItemType === PayItemType.PAY_RULE) {
            setPayRuleConditions(newConditions);
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { conditions: newConditions }));
        }
    }, [conditions, currentDefaultRate, payItemType, setCurrentDefaultRate, setPayRuleConditions]);
    var onAddCondition = useCallback(function () {
        var newConditions = __spreadArray(__spreadArray([], conditions, true), [
            {
                id: uuidv4(),
            },
        ], false);
        if (payItemType === PayItemType.PAY_RULE) {
            setPayRuleConditions(newConditions);
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { conditions: newConditions }));
        }
    }, [conditions, currentDefaultRate, payItemType, setCurrentDefaultRate, setPayRuleConditions]);
    var onDeleteCondition = useCallback(function (conditionId) {
        if (!conditionId)
            return;
        var newConditions = conditions === null || conditions === void 0 ? void 0 : conditions.filter(function (check) { return check.id !== conditionId; });
        if (payItemType === PayItemType.PAY_RULE) {
            setPayRuleConditions(newConditions);
        }
        else if (payItemType === PayItemType.PAY_RATE) {
            setCurrentDefaultRate(__assign(__assign({}, currentDefaultRate), { conditions: newConditions }));
        }
    }, [conditions, currentDefaultRate, payItemType, setCurrentDefaultRate, setPayRuleConditions]);
    if (!accountId)
        return null;
    return (_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Conditions" }), _jsxs("div", { className: "flex flex-col gap-2", children: [conditions.map(function (condition, index) { return (_jsx(_Fragment, { children: _jsxs("div", { className: "PaymentConditionSelector flex items-center overflow-hidden p-1", children: [_jsx("div", { className: "w-[200px]", children: index === 0 ? (_jsx(Select, { className: "OptionSelect w-full", disabled: true, placeholder: _jsx("div", { children: "FOR Shifts" }), bordered: false })) : (_jsx(PaymentConditionsOperandSelector, { disabled: index !== 1, payItemType: payItemType })) }), _jsx(BuilderBar, { accountId: accountId, component: AutomationComponent.FILTER, allCollections: allCollections, savedFilter: __assign(__assign({}, condition), { collectionId: shiftCollectionId, appliesTo: AutomationFilterType.RECORD }), baseCollectionId: shiftCollectionId, defaultFilterOptions: filterOptions, sendDataBack: function (builderState) {
                                        builderSendDataBack(builderState, index);
                                    } }), _jsx(Button, { className: "RecordDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-gray-60 ml-2", icon: _jsx(DeleteOutlinedIcon, {}), onClick: function () { return onDeleteCondition(condition.id); } })] }, "".concat(condition.id, "-condition")) })); }), _jsx("div", { children: _jsx(Button, { icon: _jsx(BlackPlusIcon, {}), className: "AddTableFilterButton", type: "primary", onClick: onAddCondition, children: "Add Condition" }) })] })] }));
};
export default PaymentConditionsSection;
