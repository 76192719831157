var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ColumnSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M7.66675 6.74089C7.66675 6.55679 7.81599 6.40755 8.00008 6.40755H17.3334C17.5175 6.40755 17.6667 6.55679 17.6667 6.74089V7.40755C17.6667 7.59165 17.5175 7.74089 17.3334 7.74089H8.00008C7.81599 7.74089 7.66675 7.59165 7.66675 7.40755V6.74089Z", fill: "black" }), _jsx("path", { d: "M4.33325 6.74089C4.33325 6.55679 4.48249 6.40755 4.66659 6.40755H5.33325C5.51735 6.40755 5.66659 6.55679 5.66659 6.74089V7.40755C5.66659 7.59165 5.51735 7.74089 5.33325 7.74089H4.66659C4.48249 7.74089 4.33325 7.59165 4.33325 7.40755V6.74089Z", fill: "black" }), _jsx("path", { d: "M4.33325 10.7409C4.33325 10.5568 4.48249 10.4076 4.66659 10.4076H5.33325C5.51735 10.4076 5.66659 10.5568 5.66659 10.7409V11.4076C5.66659 11.5916 5.51735 11.7409 5.33325 11.7409H4.66659C4.48249 11.7409 4.33325 11.5916 4.33325 11.4076V10.7409Z", fill: "black" }), _jsx("path", { d: "M4.33325 14.7409C4.33325 14.5568 4.48249 14.4076 4.66659 14.4076H5.33325C5.51735 14.4076 5.66659 14.5568 5.66659 14.7409V15.4076C5.66659 15.5917 5.51735 15.7409 5.33325 15.7409H4.66659C4.48249 15.7409 4.33325 15.5917 4.33325 15.4076V14.7409Z", fill: "black" }), _jsx("path", { d: "M7.66675 10.7409C7.66675 10.5568 7.81599 10.4076 8.00008 10.4076H17.3334C17.5175 10.4076 17.6667 10.5568 17.6667 10.7409V11.4076C17.6667 11.5916 17.5175 11.7409 17.3334 11.7409H8.00008C7.81599 11.7409 7.66675 11.5916 7.66675 11.4076V10.7409Z", fill: "black" }), _jsx("path", { d: "M7.66675 14.7409C7.66675 14.5568 7.81599 14.4076 8.00008 14.4076H17.3334C17.5175 14.4076 17.6667 14.5568 17.6667 14.7409V15.4076C17.6667 15.5917 17.5175 15.7409 17.3334 15.7409H8.00008C7.81599 15.7409 7.66675 15.5917 7.66675 15.4076V14.7409Z", fill: "black" })] })); };
export var ColumnIcon = function (props) { return (_jsx(Icon, __assign({ component: ColumnSvg }, props))); };
