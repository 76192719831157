var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Modal, Switch } from 'antd';
import { useRecoilValue } from 'recoil';
import { scheduleTimeRangeState } from 'src/state';
import moment from 'moment';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
import './NeedsCoverageTimeRangeSelectModal.scss';
import NeedsCoverageDateSelector from './ConfigModal/NeedsCoverageDateSelector';
var NeedsCoverageTimeRangeSelectModal = function (props) {
    var blockId = props.blockId, isModalVisible = props.isModalVisible, onCloseModal = props.onCloseModal, template = props.template, isReapply = props.isReapply, onSelectTemplate = props.onSelectTemplate;
    var _a = useState(), templateDate = _a[0], setTemplateDate = _a[1];
    var _b = useState(false), publishShifts = _b[0], setPublishShifts = _b[1];
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var showEmptyDateWarning = useMemo(function () {
        return ((isReapply || !template.autoApply) &&
            (!(templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate) || !(templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate)));
    }, [isReapply, template.autoApply, templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate, templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate]);
    var showEndBeforeStartWarning = useMemo(function () {
        if (!(templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate) || !(templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate))
            return false;
        return moment(templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate).isBefore(moment(templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate));
    }, [templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate, templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate]);
    useEffect(function () {
        if (timeRange) {
            var isAutoApply = template.autoApply;
            var startDate = moment(timeRange[0]).format('YYYY-MM-DD');
            var endDate = isAutoApply && !isReapply
                ? undefined
                : moment(timeRange[timeRange.length - 2]).format('YYYY-MM-DD');
            setTemplateDate({
                startDate: startDate,
                endDate: endDate,
            });
        }
    }, [isReapply, template.autoApply, timeRange]);
    var onEditDate = useCallback(function (date, isStart) {
        if (!templateDate)
            return;
        var tempDate = __assign({}, templateDate);
        var value = date ? date.startOf('day').format('YYYY-MM-DD') : undefined;
        if (isStart) {
            tempDate.startDate = value;
        }
        else {
            tempDate.endDate = value;
        }
        setTemplateDate(tempDate);
    }, [templateDate]);
    var onSaveTimeRange = useCallback(function () {
        onCloseModal();
        onSelectTemplate(template, publishShifts, 
        // 2023.11.01 Update: preventDuplicates is always true
        true, templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate, templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate);
    }, [
        onCloseModal,
        onSelectTemplate,
        template,
        publishShifts,
        templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate,
        templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate,
    ]);
    return (_jsxs(Modal, { open: isModalVisible, className: "NeedsCoverageTimeRangeSelectModal", centered: true, closable: false, footer: _jsx(ZiraModalFooter, { onCancel: onCloseModal, onSave: onSaveTimeRange }), children: [_jsx("div", { className: "text-lg font-semibold", children: "Please select time range for ".concat(template.name) }), isReapply && (_jsxs("div", { className: "text-md font-semibold", children: ["We will generate shifts based on latest ", template.name, " details in selected time range."] })), showEmptyDateWarning && (_jsx(Alert, { message: "Both start date and end date cannot be empty.", banner: true, type: "warning", className: "mt-4" })), showEndBeforeStartWarning && (_jsx(Alert, { message: "End date is before start date.", banner: true, type: "warning", className: "mt-4" })), _jsxs("div", { className: "TemplateDateRangeSelectSection", children: [_jsx(NeedsCoverageDateSelector, { isStartDate: true, date: (templateDate === null || templateDate === void 0 ? void 0 : templateDate.startDate) ? templateDate.startDate : undefined, onEditDate: onEditDate, allowEmptyDate: template.autoApply && !isReapply }), _jsx("div", { className: "DatePickerDivider" }), _jsx(NeedsCoverageDateSelector, { isStartDate: false, date: (templateDate === null || templateDate === void 0 ? void 0 : templateDate.endDate) ? templateDate.endDate : undefined, onEditDate: onEditDate, allowEmptyDate: template.autoApply && !isReapply })] }), _jsxs("div", { className: "flex space-x-2 items-end mt-3", children: [_jsx(Switch, { checked: publishShifts, onChange: function (checked) { return setPublishShifts(checked); } }), _jsx("span", { className: "font-medium", children: "Publish shifts" })] })] }));
};
export default React.memo(NeedsCoverageTimeRangeSelectModal);
