import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { uploadedRateFilePassVerifyState, defaultRateValidationErrorInfoState, } from 'src/components/Settings/PayRate/atoms';
import RatesUploadErrorContent from './RatesUploadErrorContent';
var VerifyFileSection = function () {
    var isVerified = useRecoilValue(uploadedRateFilePassVerifyState);
    var errorInfo = useRecoilValue(defaultRateValidationErrorInfoState);
    var contentBgColor = isVerified ? '#E5F4EF' : '#FFEDEC';
    var contentTextColor = isVerified ? '#4CB391' : '#B3342F';
    var renderContentText = useMemo(function () {
        if (isVerified && !(errorInfo === null || errorInfo === void 0 ? void 0 : errorInfo.length)) {
            return (_jsx(_Fragment, { children: "No errors were found in your upload. You are good to proceed with the import process." }));
        }
        else {
            return _jsx(RatesUploadErrorContent, { errors: errorInfo });
        }
    }, [errorInfo, isVerified]);
    return (_jsxs("div", { className: "mt-5 rounded-lg p-3 font-medium flex gap-2", style: {
            backgroundColor: contentBgColor,
            color: contentTextColor,
            alignItems: isVerified ? 'center' : 'flex-start',
        }, children: [_jsx(InfoCircleOutlined, { style: {
                    color: contentTextColor,
                    stroke: contentTextColor,
                    fontSize: '18px',
                }, size: 24 }), _jsx("div", { children: renderContentText })] }));
};
export default React.memo(VerifyFileSection);
