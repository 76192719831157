import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import ShiftTemplateTimePickerCell from 'src/components/ScheduleView/NewShiftTemplate/ShiftTemplateTimePickerCell';
import { showCrossDayIndicator } from 'src/components/ScheduleView/NewShiftTemplate/ShiftTemplateDetailModal';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
import './ShiftTemplateTimeOverrideModal.scss';
var DEFAULT_TEMPLATE_TIME_DATA = {
    startTime: '09:00',
    endTime: '17:00',
};
var ShiftTemplateTimeOverrideModal = function (props) {
    var isModalVisible = props.isModalVisible, onCloseModal = props.onCloseModal, selectedTemplate = props.selectedTemplate, selectedTemplateName = props.selectedTemplateName, overrideRecordDayOfWeekShiftTemplateTime = props.overrideRecordDayOfWeekShiftTemplateTime;
    var _a = useState(DEFAULT_TEMPLATE_TIME_DATA), templateTimeData = _a[0], setTemplateTimeData = _a[1];
    var _b = useState(false), overrideAll = _b[0], setOverrideAll = _b[1];
    useEffect(function () {
        if (selectedTemplate.dataOverride) {
            setTemplateTimeData(selectedTemplate.dataOverride);
        }
    }, [selectedTemplate.dataOverride]);
    var onEditTime = useCallback(function (isStartTime, time) {
        var newTime = cloneDeep(templateTimeData);
        if (isStartTime) {
            newTime.startTime = time;
        }
        else {
            newTime.endTime = time;
        }
        setTemplateTimeData(newTime);
    }, [templateTimeData]);
    var onSave = useCallback(function () {
        if (!overrideRecordDayOfWeekShiftTemplateTime)
            return;
        overrideRecordDayOfWeekShiftTemplateTime(selectedTemplate, templateTimeData, overrideAll);
    }, [overrideRecordDayOfWeekShiftTemplateTime, selectedTemplate, templateTimeData, overrideAll]);
    return (_jsxs(Modal, { open: isModalVisible, footer: _jsx(ZiraModalFooter, { onCancel: onCloseModal, onSave: onSave }), className: "ShiftTemplateTimeOverrideModal", centered: true, closable: false, zIndex: 1002, children: [_jsx("div", { className: "mb-5 text-lg font-semibold", children: "Template ".concat(selectedTemplateName, " Time Override") }), _jsxs("div", { className: "TemplateTimePickerSection", children: [_jsx(ShiftTemplateTimePickerCell, { isStartTime: true, time: templateTimeData.startTime, setTime: function (time) { return onEditTime(true, time); } }), _jsx("div", { className: "TimePickerDivider" }), _jsx(ShiftTemplateTimePickerCell, { isStartTime: false, time: templateTimeData.endTime, setTime: function (time) { return onEditTime(false, time); }, showCrossDayIndicator: showCrossDayIndicator(templateTimeData.startTime, templateTimeData.endTime) })] }), _jsxs("div", { className: "flex items-center", children: [_jsx(Checkbox, { checked: overrideAll, onChange: function (e) { return setOverrideAll(e.target.checked); } }), _jsx("div", { className: "ml-2", children: "Override all ".concat(selectedTemplateName, " shifts for this user") })] })] }));
};
export default React.memo(ShiftTemplateTimeOverrideModal);
