import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined, RiseOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTasksComponent } from 'src/components/Settings/Tasks/hooks/useTasks';
import { useRecordComponent } from 'src/hooks/component';
import { CollectionTableType, NativePath } from 'src/shared';
import { collectionTableCellState, collectionTableNativeSchemaSelector, currentReverseRelationIdState, ifCurrentUserHasTasksPermission, } from 'src/state';
import { TaskRestoreIcon } from 'src/utils/icons/TaskIcons/TaskRestoreIcon';
import { TasksTabIcon } from 'src/utils/icons/TaskIcons/TasksTabIcons';
var TaskRecordActions = function (_a) {
    var _b, _c, _d, _e, _f;
    var recordId = _a.recordId, collectionId = _a.collectionId, onCloseHoverOptions = _a.onCloseHoverOptions;
    var _g = useTasksComponent(), viewTaskTemplate = _g.viewTaskTemplate, viewTaskChangesModal = _g.viewTaskChangesModal;
    var editRecordCell = useRecordComponent().editRecordCell;
    var taskTemplateSchemaId = (_b = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.TASK_TEMPLATE_ID,
    }))) === null || _b === void 0 ? void 0 : _b.id;
    var taskTemplateId = (_d = (_c = useRecoilValue(collectionTableCellState({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        schemaId: taskTemplateSchemaId !== null && taskTemplateSchemaId !== void 0 ? taskTemplateSchemaId : '',
        recordId: recordId,
    }))) === null || _c === void 0 ? void 0 : _c.options[0]) === null || _d === void 0 ? void 0 : _d.id;
    var taskDismissedSchema = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.TASK_DISMISSED,
    }));
    var taskDismissedCell = useRecoilValue(collectionTableCellState({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        schemaId: (_e = taskDismissedSchema === null || taskDismissedSchema === void 0 ? void 0 : taskDismissedSchema.id) !== null && _e !== void 0 ? _e : '',
        recordId: recordId,
    }));
    var taskisDismissed = (taskDismissedCell === null || taskDismissedCell === void 0 ? void 0 : taskDismissedCell.content)
        ? (taskDismissedCell === null || taskDismissedCell === void 0 ? void 0 : taskDismissedCell.content) === 'true'
        : ((_f = taskDismissedCell === null || taskDismissedCell === void 0 ? void 0 : taskDismissedCell.options[0]) === null || _f === void 0 ? void 0 : _f.id) === 'true';
    var hasTasksPermission = useRecoilValue(ifCurrentUserHasTasksPermission);
    var taskPlacementSchema = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.TASK_PLACEMENT_RID,
    }));
    var reverseRelationId = useRecoilValue(currentReverseRelationIdState);
    var isRenderedInPlacementModal = (taskPlacementSchema === null || taskPlacementSchema === void 0 ? void 0 : taskPlacementSchema.id) === reverseRelationId;
    return (_jsxs(_Fragment, { children: [hasTasksPermission && taskTemplateId && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { className: "ml-[4px]", icon: _jsx(TasksTabIcon, {}), onClick: function () {
                            viewTaskTemplate(taskTemplateId);
                            onCloseHoverOptions();
                        }, children: _jsx("span", { className: "ml-[2px]", children: "View Template" }) }, "viewTaskTemplate"), _jsx(Menu.Divider, { className: "Divider" })] })), !!recordId && isRenderedInPlacementModal && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { className: "ml-[5px]", icon: _jsx(RiseOutlined, { className: "text-sm" }), onClick: function () {
                            viewTaskChangesModal(recordId);
                            onCloseHoverOptions();
                        }, children: _jsx("span", { className: "ml-[3px]", children: "Push Changes" }) }, "pushTaskChanges"), _jsx(Menu.Divider, { className: "Divider" })] })), (taskDismissedSchema === null || taskDismissedSchema === void 0 ? void 0 : taskDismissedSchema.canEdit) && (_jsxs(_Fragment, { children: [!taskisDismissed && (_jsx(Menu.Item, { className: "ml-[5px]", icon: _jsx(CloseOutlined, { className: "text-sm" }), onClick: function () {
                            editRecordCell(CollectionTableType.TABLE, { collectionId: collectionId, recordId: recordId, schemaId: taskDismissedSchema.id }, ['true']);
                            onCloseHoverOptions();
                        }, children: _jsx("span", { className: "ml-[3px]", children: "Dismiss" }) }, "dismissTask")), taskisDismissed && (_jsx(Menu.Item, { className: "ml-[4px]", icon: _jsx(TaskRestoreIcon, {}), onClick: function () {
                            editRecordCell(CollectionTableType.TABLE, { collectionId: collectionId, recordId: recordId, schemaId: taskDismissedSchema.id }, ['false']);
                            onCloseHoverOptions();
                        }, children: _jsx("span", { className: "ml-[2px] text-positive", children: "Restore" }) }, "restoreTask")), _jsx(Menu.Divider, { className: "Divider" })] }))] }));
};
export default React.memo(TaskRecordActions);
