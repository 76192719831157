import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import BreaksImg from '../../../../utils/icons/images/settings/Breaks.png';
import DepartmentList from './DepartmentList';
import './DepartmentConfigContent.scss';
var DepartmentConfigContent = function (_a) {
    var editingDepartmentsState = _a.editingDepartmentsState;
    return (_jsxs("div", { className: "DepartmentPage", children: [_jsxs("div", { className: "DepartmentPageContent", children: [_jsx("div", { className: "DepartmentPageTitle", children: "Chat Departments" }), _jsx("div", { className: "DepartmentPageDescription text-gray-400", children: "Only members of departments will be able to broadcast communication to your staff." }), _jsx(DepartmentList, { editingDepartmentsState: editingDepartmentsState })] }), _jsx("img", { className: "DepartmentConfigImg", src: BreaksImg })] }));
};
export default React.memo(DepartmentConfigContent);
