import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';
var AfterHourQualifierCell = function (_a) {
    var start = _a.start, end = _a.end, onChange = _a.onChange;
    var onStartTimeChange = useCallback(function (value, _dateString) {
        if (value) {
            var newTime = value.format('HH:mm:ss');
            onChange(newTime, end);
        }
        else {
            onChange('', end);
        }
    }, [end, onChange]);
    var onEndTimeChange = useCallback(function (value, _dateString) {
        if (value) {
            var newTime = value.format('HH:mm:ss');
            onChange(start, newTime);
        }
        else {
            onChange(start, '');
        }
    }, [onChange, start]);
    return (_jsxs("div", { className: "DateQualifierCellWrapper", children: [_jsx(TimePicker, { value: start ? moment(start, 'HH:mm:ss') : undefined, use12Hours: true, format: "h:mm A", onChange: onStartTimeChange, showNow: false, className: "mr-2" }), _jsx(TimePicker, { value: end ? moment(end, 'HH:mm:ss') : undefined, use12Hours: true, format: "h:mm A", onChange: onEndTimeChange, showNow: false })] }));
};
export default React.memo(AfterHourQualifierCell);
