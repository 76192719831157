import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { MobileOutlined, LineChartOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { TASKS_OVERVIEW_HEADER_HEIGHT } from '../constant';
import { isTaskSettingMobilePreviewVisibleState, isTaskSettingActivityFeedVisibleState, } from '../atom';
var TasksSettingRightSidebar = function () {
    var _a = useRecoilState(isTaskSettingMobilePreviewVisibleState), isTaskSettingMobilePreviewVisible = _a[0], setIsTaskSettingMobilePreviewVisible = _a[1];
    var _b = useRecoilState(isTaskSettingActivityFeedVisibleState), isTaskSettingActivityFeedVisible = _b[0], setIsTaskSettingActivityFeedVisible = _b[1];
    return (_jsxs("div", { className: "w-[48px] h-min border-l border-gray-60", style: {
            height: "calc(100vh - ".concat(TASKS_OVERVIEW_HEADER_HEIGHT, "px"),
        }, children: [_jsx("div", { className: "h-[48px] border-b border-gray-60 grid place-items-center cursor-pointer", style: {
                    backgroundColor: isTaskSettingMobilePreviewVisible ? '#E9E9EC' : 'white',
                }, onClick: function () {
                    setIsTaskSettingActivityFeedVisible(false);
                    setIsTaskSettingMobilePreviewVisible(!isTaskSettingMobilePreviewVisible);
                }, children: _jsx(MobileOutlined, {}) }), _jsx("div", { className: "h-[48px] border-b border-gray-60 grid place-items-center cursor-pointer", style: {
                    backgroundColor: isTaskSettingActivityFeedVisible ? '#E9E9EC' : 'white',
                }, onClick: function () {
                    setIsTaskSettingMobilePreviewVisible(false);
                    setIsTaskSettingActivityFeedVisible(!isTaskSettingActivityFeedVisible);
                }, children: _jsx(LineChartOutlined, {}) })] }));
};
export default React.memo(TasksSettingRightSidebar);
