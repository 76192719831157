import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal, Radio, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { RecurringShiftEditType } from 'src/components/ScheduleView/Constant';
import './RecurringRulePopup.scss';
var EditRecurringShiftPopup = function (props) {
    var isModalVisible = props.isModalVisible, setIsModalVisible = props.setIsModalVisible, onRecurringEditSave = props.onRecurringEditSave;
    var _a = useState(RecurringShiftEditType.THIS_SHIFT), editType = _a[0], setEditType = _a[1];
    var onSave = useCallback(function () {
        onRecurringEditSave(editType);
    }, [editType, onRecurringEditSave]);
    var modalFooter = (_jsx("div", { className: "EditRecurringModalFooter", children: _jsxs("div", { children: [_jsx(Button, { className: "CancelBtn ml-auto", onClick: function () { return setIsModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "ConfirmBtn", onClick: function (e) {
                        e.stopPropagation();
                        onSave();
                    }, children: "Save" })] }) }));
    return (_jsx(_Fragment, { children: _jsxs(Modal, { className: "EditRecurringShiftModal", width: 400, open: isModalVisible, centered: true, closable: false, destroyOnClose: true, footer: modalFooter, onCancel: function () { return setIsModalVisible(false); }, children: [_jsx("div", { className: "EditRecurringShiftModalTitle", children: "Edit recurring shift" }), _jsx(Radio.Group, { onChange: function (e) { return setEditType(e.target.value); }, value: editType, children: _jsxs(Space, { direction: "vertical", children: [_jsx(Radio, { value: RecurringShiftEditType.THIS_SHIFT, children: "This shift" }), _jsx(Radio, { value: RecurringShiftEditType.FUTURE_SHIFTS, children: "This and following shifts" }), _jsx(Radio, { value: RecurringShiftEditType.ALL_SHIFTS, children: "All shifts" })] }) })] }) }));
};
export default React.memo(EditRecurringShiftPopup);
