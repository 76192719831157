import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
export var LoadingHeaderText = [
    {
        text: 'Retrieving',
        textWithTransitionColor: 'users',
    },
    {
        text: 'Retrieving',
        textWithTransitionColor: 'schedule',
    },
    {
        text: 'Analyzing',
        textWithTransitionColor: 'schedule',
    },
    {
        text: 'Analyzing',
        textWithTransitionColor: 'users',
    },
    {
        text: 'Matching',
        textWithTransitionColor: 'qualifications',
    },
];
var ShiftRecommendationsModalLoading = function () {
    var _a = useState(0), loadingStepIndex = _a[0], setLoadingStepIndex = _a[1];
    var intervalRef = useRef(null);
    useEffect(function () {
        intervalRef.current = setInterval(function () {
            return setLoadingStepIndex(function (prev) {
                if (prev < LoadingHeaderText.length - 1) {
                    return prev + 1;
                }
                else {
                    return 0;
                }
            });
        }, 4000);
        return function () {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);
    return (_jsxs("div", { className: "ShiftRecommendationsModalLoading", children: [_jsxs("div", { className: "flex flex-col items-center absolute top-[64px]", children: [_jsx("div", { className: "mb-1 text-center text-4xl font-medium fade-in-out", children: LoadingHeaderText[loadingStepIndex].text }), _jsx("div", { className: "TextTransitionColor text-center text-4xl font-medium fade-in-out", children: LoadingHeaderText[loadingStepIndex].textWithTransitionColor })] }), _jsx("div", { className: "LoadingSign my-[64px]" })] }));
};
export default React.memo(ShiftRecommendationsModalLoading);
