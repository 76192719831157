var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Button, Form, Select } from 'antd';
import { useParams } from 'react-router-dom';
import Fuse from 'fuse.js';
import { useSetting } from 'src/hooks/api';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { duplicateFormValueValidation } from 'src/utils/Account';
import HoverRecordMenu from 'src/components/OptimizedTableView/Cell/HoverRecordMenu';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { startCase } from 'lodash';
import './IpUserMatching.scss';
import { IntegrationStepsState } from './SyncStepsContainer';
var Option = Select.Option;
var NO_MATCHED_KEY = 'noMatch';
var constructFormInitialValues = function (tbUserList, ipUserList) {
    var ret = {};
    var options = {
        includeScore: true,
        keys: ['matchingName'],
    };
    var fuse = new Fuse(ipUserList, options);
    tbUserList.map(function (tbUser) {
        //Using tb user matching name to do fuzzy search in ip user list
        var result = fuse.search(tbUser.matchingName);
        // Limit matching score under 0.2 where 0 indicates perfect match
        if (result.length !== 0 && typeof result[0].score === 'number' && result[0].score <= 0.2) {
            ret[tbUser.rid] = result[0].item.id;
        }
        else {
            ret[tbUser.rid] = NO_MATCHED_KEY;
        }
    });
    return ret;
};
var IpUserMatching = function (_a) {
    var isModalVisible = _a.isModalVisible;
    var _b = useParams().ipName, ipName = _b === void 0 ? '' : _b;
    var selectedIpCollection = IntegrationStepsState.useContainer().selectedIpCollection;
    var formInstance = Form.useForm()[0];
    var _c = useSetting(), useGetDeltaUnmatchedUserInfo = _c.useGetDeltaUnmatchedUserInfo, useSubmitDeltaMatchedUserPairs = _c.useSubmitDeltaMatchedUserPairs;
    var _d = useGetDeltaUnmatchedUserInfo({
        ipName: ipName,
        ipCollection: selectedIpCollection,
    }), unMatchedUserData = _d.data, isFetching = _d.isFetching;
    var _e = useState(false), isSubmitLoading = _e[0], setIsSubmitLoading = _e[1];
    var onSubmit = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, matchingPairs, deltaMatchedUserPairs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formInstance.validateFields()];
                case 1:
                    formValues = _a.sent();
                    setIsSubmitLoading(true);
                    matchingPairs = [];
                    Object.entries(formValues).map(function (_a) {
                        var key = _a[0], value = _a[1];
                        if (value !== NO_MATCHED_KEY) {
                            var pair = {
                                tbRid: key,
                                integrationId: value,
                            };
                            matchingPairs.push(pair);
                        }
                    });
                    deltaMatchedUserPairs = {
                        matched: matchingPairs,
                    };
                    useSubmitDeltaMatchedUserPairs.mutate({ ipName: ipName, ipCollection: selectedIpCollection, deltaMatchedUserPairs: deltaMatchedUserPairs }, {
                        onSuccess: function () {
                            isModalVisible(false);
                            setIsSubmitLoading(false);
                        },
                        onError: function () {
                            setIsSubmitLoading(false);
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [formInstance]);
    var onFilterOption = useCallback(function (input, option) {
        var optionItem = unMatchedUserData === null || unMatchedUserData === void 0 ? void 0 : unMatchedUserData.integrationPending.find(function (item) { return item.id === option.value; });
        return optionItem
            ? optionItem.displayName.toLowerCase().indexOf(input.toLowerCase()) > -1
            : false;
    }, [unMatchedUserData === null || unMatchedUserData === void 0 ? void 0 : unMatchedUserData.integrationPending]);
    if (!unMatchedUserData)
        return null;
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex items-center mb-[10px] ml-[15px]", children: [_jsxs("div", { className: "text-base font-medium w-[320px] max-w-1/2", children: ["TeamBridge ", startCase(unMatchedUserData.tbCollectionType)] }), _jsx("div", { className: "text-base font-medium", children: upperCaseStringToStartCase(unMatchedUserData.collectionName.split('_').join(' ')) })] }), _jsx("div", { className: "MatchingContent", children: isFetching ? (_jsx(ZiraRowsSkeleton, { rowNumber: 10 })) : (unMatchedUserData && (_jsx(Form, { initialValues: constructFormInitialValues(unMatchedUserData.tbPending, unMatchedUserData.integrationPending), form: formInstance, children: unMatchedUserData.tbPending
                        .sort(function (a, b) {
                        var nameA = a.displayName.toLowerCase();
                        var nameB = b.displayName.toLowerCase();
                        if (nameA < nameB)
                            //sort string ascending
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0; //default return value (no sorting)
                    })
                        .map(function (user) { return (_jsx(Form.Item, { label: _jsx(HoverRecordMenu, { rid: user.rid, title: user.displayName, children: _jsx("div", { className: "FormItemTitle", children: user.displayName }) }, user.rid), colon: false, name: user.rid, rules: [
                            {
                                required: true,
                                message: 'Please select one option!',
                            },
                            {
                                validator: function (_, value) {
                                    var formValues = formInstance.getFieldsValue(true);
                                    var allValues = Object.values(formValues);
                                    // Filter out no match value
                                    var allUsers = allValues.filter(function (value) { return value !== NO_MATCHED_KEY; });
                                    var containsDuplication = duplicateFormValueValidation(value, allUsers);
                                    if (containsDuplication) {
                                        return Promise.reject(new Error('Duplicate user match, please check again.'));
                                    }
                                    else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ], children: _jsxs(Select, { style: { width: 400, maxWidth: '100%' }, showSearch: true, filterOption: onFilterOption, children: [_jsx(Option, { value: NO_MATCHED_KEY, children: "No Match" }, NO_MATCHED_KEY), unMatchedUserData.integrationPending.map(function (user) { return (_jsx(Option, { children: user.displayName }, user.id)); })] }) }, user.rid)); }) }))) }), _jsx("div", { className: "mx-5 flex justify-end", children: _jsx(Button, { className: "ConfirmBtn", onClick: onSubmit, loading: isSubmitLoading, children: "Submit" }) })] }));
};
export default React.memo(IpUserMatching);
