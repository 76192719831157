import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import currency from 'currency.js';
import { getAccountCurrencySymbol } from '../../../../utils/Account';
import { DashboardMetricType } from '../Constant';
var DashboardMetricCard = function (_a) {
    var cardInfo = _a.cardInfo, isLoading = _a.isLoading, metricValue = _a.metricValue, timeSheetDateRange = _a.timeSheetDateRange;
    var currencyType = getAccountCurrencySymbol();
    var dataRender = useMemo(function () {
        if (!metricValue) {
            return '-';
        }
        var isCurrency = [
            DashboardMetricType.LATEST_TIMESHEET,
            DashboardMetricType.LATEST_BILL_AMOUNT,
            DashboardMetricType.CURRENT_BILL_AMOUNT,
            DashboardMetricType.CURRENT_TIMESHEET,
        ].includes(cardInfo.dataKey);
        return isCurrency ? currency(metricValue, { symbol: currencyType }).format() : metricValue;
    }, [metricValue, cardInfo.dataKey, currencyType]);
    return (_jsxs("div", { className: "DashboardMetricCard shadow-block", children: [_jsxs("div", { className: "mb-2 flex items-center", children: [_jsx("div", { className: "mr-2", children: cardInfo.icon }), isLoading ? (_jsx(LoadingOutlined, { spin: true, className: "text-[1.625rem]" })) : (_jsx("div", { className: "pt-0.5 text-[1.625rem] font-medium", children: dataRender }))] }), _jsxs("div", { className: "flex h-[40px] ".concat(timeSheetDateRange && 'flex-col', " items-center"), children: [_jsx("div", { className: "text-base font-medium", children: cardInfo.displayName }), timeSheetDateRange && (_jsx("div", { className: "text-xs text-gray-400", children: "".concat(timeSheetDateRange) }))] })] }));
};
export default React.memo(DashboardMetricCard);
