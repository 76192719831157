var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import AutomationCard from 'src/components/Settings/Automations/AutomationCard';
import { useAutomation } from 'src/hooks/api';
import { cloneDeep } from 'lodash';
import { recipeAutomationsState, recipeOverriddenDataChangesState } from '../atom';
var RecipeAutomationCard = function (_a) {
    var recipeId = _a.recipeId, automation = _a.automation;
    var recipeDataChanges = useRecoilValue(recipeOverriddenDataChangesState);
    var setRecipeAutomations = useSetRecoilState(recipeAutomationsState);
    var _b = useAutomation(), useGetDefaultAutomation = _b.useGetDefaultAutomation, useGetRecipeCustomAutomation = _b.useGetRecipeCustomAutomation;
    //! Fetch automation to save in recipeAutomationsState
    useEffect(function () {
        if ('recipeAutomationId' in automation) {
            if (automation.recipeAutomationDetail.type === 'DEFAULT') {
                var automationId_1 = automation.recipeAutomationDetail.defaultAutomationId;
                useGetDefaultAutomation.mutate(automationId_1, {
                    onSuccess: function (automationObj) {
                        if (!automationObj)
                            return;
                        var saveAutomationData = automationObj.detail;
                        setRecipeAutomations(function (prev) {
                            var temp = cloneDeep(prev);
                            var index = temp.findIndex(function (item) { return item.id === automationId_1; });
                            if (index >= 0) {
                                temp[index] = __assign(__assign({}, saveAutomationData), { id: automationId_1 });
                            }
                            else {
                                temp.push(__assign(__assign({}, saveAutomationData), { id: automationId_1 }));
                            }
                            return temp;
                        });
                    },
                });
            }
            else {
                var automationId_2 = automation.recipeAutomationId;
                useGetRecipeCustomAutomation.mutate({
                    recipeTemplateId: recipeId,
                    recipeAutomationId: automationId_2,
                    dataChanges: recipeDataChanges,
                }, {
                    onSuccess: function (automationObj) {
                        if (!automationObj)
                            return;
                        var saveAutomationData = automationObj.detail;
                        setRecipeAutomations(function (prev) {
                            var temp = cloneDeep(prev);
                            var index = temp.findIndex(function (item) { return item.id === automationId_2; });
                            if (index >= 0) {
                                temp[index] = __assign(__assign({}, saveAutomationData), { id: automationId_2 });
                            }
                            else {
                                temp.push(__assign(__assign({}, saveAutomationData), { id: automationId_2 }));
                            }
                            return temp;
                        });
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automation, recipeDataChanges, recipeId]);
    return (_jsx("div", { className: "RecipeAutomationWrapper w-min rounded-xl border-2 border-azure-50", children: _jsx(AutomationCard, { automation: automation }) }));
};
export default React.memo(RecipeAutomationCard);
