var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useRecoilCallback } from 'recoil';
import { DefaultReverseCollectionPagePagination } from 'src/shared';
import { tableCollectionCurrentSizeSelector, tableCollectionResetPageState } from 'src/state';
import { getCollectionTableRefetchSize } from 'src/utils/Collection';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useReverseCollectionApi = function () {
    var customFetch = useFetch().request;
    var useListReverseCollectionQuery = function (
    //! Omit pagination so query refetch logic behaves correctly
    request) {
        var _a, _b, _c, _d, _e, _f;
        var body = request.body;
        return useQuery([
            'reverseCollection',
            body.collectionId,
            (_b = (_a = body.schemaId) !== null && _a !== void 0 ? _a : body.standardRelation) !== null && _b !== void 0 ? _b : '',
            body.recordId,
            JSON.stringify((_c = body.displaySettings) === null || _c === void 0 ? void 0 : _c.filters),
            JSON.stringify((_d = body.displaySettings) === null || _d === void 0 ? void 0 : _d.sortBy),
            (_e = body.search) !== null && _e !== void 0 ? _e : '',
            (_f = JSON.stringify(body.pagination)) !== null && _f !== void 0 ? _f : '',
        ], function () { return listReverseCollection(body); }, __assign({ staleTime: Infinity }, request.options));
    };
    var listReverseCollection = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (body) {
            var _a, _b, _c;
            var requestBody = body;
            if (!body.pagination) {
                //! For page reset or refetch for useQuery
                var reverseRelationId = (_a = body.schemaId) !== null && _a !== void 0 ? _a : body.standardRelation;
                var currentSize = (_b = snapshot
                    .getLoadable(tableCollectionCurrentSizeSelector(reverseRelationId))
                    .valueMaybe()) !== null && _b !== void 0 ? _b : 0;
                var resetPage = (_c = snapshot
                    .getLoadable(tableCollectionResetPageState(reverseRelationId))
                    .valueMaybe()) !== null && _c !== void 0 ? _c : false;
                requestBody = __assign(__assign({}, body), { pagination: {
                        page: 0,
                        size: resetPage
                            ? DefaultReverseCollectionPagePagination.size
                            : getCollectionTableRefetchSize(currentSize, DefaultReverseCollectionPagePagination.size),
                    } });
            }
            return customFetch({
                method: 'POST',
                url: 'collections/v2/get_reverse_records',
            }, requestBody);
        };
    }, [customFetch]);
    // View endpoints
    var useGetReverseCollectionViews = function (request) {
        return useQuery(['reverseCollectionViews', JSON.stringify(request.body)], function () {
            return customFetch({
                method: 'POST',
                url: 'collections/reverse_collection_views',
            }, request.body);
        }, __assign({}, request.options));
    };
    var useAddReverseCollectionView = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'POST',
                url: 'collections/reverse_collection_views/add',
            }, body);
        });
    };
    var useUpdateReverseCollectionView = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'PUT',
                url: 'collections/reverse_collection_views/update',
            }, body);
        });
    };
    var useUpdateReverseCollectionViewOrder = function () {
        return useMutation(function (body) {
            return customFetch({
                method: 'PUT',
                url: 'collections/reverse_collection_views/update_view_order',
            }, body);
        });
    };
    var useDeleteReverseCollectionView = function () {
        return useMutation(function (viewId) {
            return customFetch({
                method: 'DELETE',
                url: "collections/reverse_collection_views/".concat(viewId),
            });
        });
    };
    return {
        useListReverseCollectionQuery: useListReverseCollectionQuery,
        listReverseCollection: listReverseCollection,
        useGetReverseCollectionViews: useGetReverseCollectionViews,
        useAddReverseCollectionView: useAddReverseCollectionView(),
        useUpdateReverseCollectionView: useUpdateReverseCollectionView(),
        useUpdateReverseCollectionViewOrder: useUpdateReverseCollectionViewOrder(),
        useDeleteReverseCollectionView: useDeleteReverseCollectionView(),
    };
};
export { useReverseCollectionApi };
