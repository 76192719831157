var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { StatusType } from 'src/components/CenterPanel/Interface';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { ScheduleGroupBy } from 'src/shared';
import { ifUserHasGeneralPermission, scheduleGroupBySelector } from 'src/state';
import { getTagTextColor } from 'src/utils/Collection';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
import './NewShiftTemplateCard.scss';
import { selectedTemplateIdForDeleteState } from './Atom';
var TemplateCard = function (props) {
    var blockId = props.blockId, template = props.template, onDeleteTemplate = props.onDeleteTemplate, openExistingTemplate = props.openExistingTemplate, onApplyTemplate = props.onApplyTemplate, style = props.style, groupByRecordId = props.groupByRecordId, draggableProvided = props.draggableProvided;
    var canUserEditTemplate = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.SCHEDULE_TEMPLATE));
    var setSelectedTemplateForDelete = useSetRecoilState(selectedTemplateIdForDeleteState);
    var disabledCard = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            if (!blockId) {
                return false;
            }
            else {
                var scheduleGroupBy = snapshot
                    .getLoadable(scheduleGroupBySelector(blockId))
                    .valueMaybe();
                // Disable card when template location/role don't contain row item location/role
                if (scheduleGroupBy === ScheduleGroupBy.LOCATION) {
                    if (!template.location)
                        return false;
                    return template.location.rid !== groupByRecordId;
                }
                else if (scheduleGroupBy === ScheduleGroupBy.ROLE) {
                    if (template.roles.length === 0)
                        return false;
                    for (var _i = 0, _a = template.roles; _i < _a.length; _i++) {
                        var role = _a[_i];
                        if (role.rid === groupByRecordId)
                            return false;
                    }
                    return true;
                }
                else if (scheduleGroupBy === ScheduleGroupBy.TEMPLATE) {
                    return template.id !== groupByRecordId;
                }
            }
            return false;
        };
    }, [blockId, groupByRecordId, template]);
    var renderDisplayTime = useCallback(function (startTime, endTime) {
        var displayStartTime = moment(startTime, 'HH:mm').format('h:mma');
        var displayEndTime = moment(endTime, 'HH:mm').format('h:mma');
        return (_jsx("div", { className: "TemplateDetailContent", children: "".concat(displayStartTime, " - ").concat(displayEndTime) }));
    }, []);
    var renderLocationRoles = useCallback(function () {
        var location = template.location;
        var roles = template.roles;
        var tags = __spreadArray([location], roles, true);
        return (_jsx(_Fragment, { children: tags.map(function (tag) {
                if (tag) {
                    return (_jsxs("div", { className: "TemplateDetailContent", children: [_jsx("span", { className: "mx-[5px]", children: "\u2022" }), tag.name] }, tag.rid));
                }
            }) }));
    }, [template.location, template.roles]);
    return (_jsxs("div", __assign({ className: "ShiftTemplateCardContainer ".concat(disabledCard() ? 'DisabledShiftTemplateCardContainer' : '') }, draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.draggableProps, { ref: draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.innerRef, style: __assign(__assign({}, style), { backgroundColor: template.color }), onClick: function () {
            if (disabledCard()) {
                return;
            }
            else if (openExistingTemplate) {
                openExistingTemplate === null || openExistingTemplate === void 0 ? void 0 : openExistingTemplate(template.id);
            }
            else if (onApplyTemplate) {
                var templateInfo = {
                    id: template.id,
                    color: template.color,
                    name: template.name,
                    status: {
                        status: StatusType.ACTIVE,
                    },
                };
                onApplyTemplate(templateInfo);
            }
        }, children: [draggableProvided && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, draggableProvided.dragHandleProps, { className: "ml-1" }))), _jsxs("div", { className: "TemplateCardContent ".concat(draggableProvided ? 'ml-2' : 'ml-4'), children: [_jsx("div", { className: "TemplateName text-sm font-semibold", style: {
                            color: getTagTextColor(template.color),
                        }, children: template.name }), _jsxs("div", { className: "TemplateDetailInfoContainer", children: [renderDisplayTime(template.startTime, template.endTime), renderLocationRoles()] })] }), canUserEditTemplate && onDeleteTemplate && (_jsx(ZiraTooltip, { title: "Delete Template", children: _jsx("div", { className: "TemplateDeleteButton rounded-md p-1 hover:cursor-pointer hover:bg-white", onClick: function (e) {
                        e.stopPropagation();
                        setSelectedTemplateForDelete(template.id);
                    }, children: _jsx(DeleteOutlinedIcon, { fill: "black" }) }) }))] })));
};
export default React.memo(TemplateCard);
