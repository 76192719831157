import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { scheduleViewTypeSelector } from 'src/state';
import { useRecoilValue } from 'recoil';
import { ScheduleView } from 'src/services';
var ScheduleWeekDateTitle = function (_a) {
    var blockId = _a.blockId, momentDate = _a.momentDate, isToday = _a.isToday;
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    return (_jsxs("div", { className: 'ScheduleTableDateColumn ' + (isToday ? 'isToday' : ''), children: [scheduleView !== ScheduleView.WEEK && (_jsx("div", { className: "text-gray-300", children: momentDate.clone().format('dddd').substring(0, 3) })), scheduleView === ScheduleView.WEEK
                ? "".concat(momentDate.clone().format('dddd'), " ")
                : "".concat(momentDate.clone().format('MMM'), " "), _jsx("span", { style: { color: 'gray' }, children: momentDate.clone().date() })] }));
};
export default React.memo(ScheduleWeekDateTitle);
