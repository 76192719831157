import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ActivityFeed from 'src/components/CenterPanel/Feed/ActivityFeed/ActivityFeed';
import ActivityFeedState from 'src/hooks/state/ActivityFeed';
import './AutomationsPage.scss';
var AutomationUsage = function (props) {
    var automationId = props.automationId;
    return (_jsxs("div", { className: "AutomationUsage", children: [_jsx("div", { className: "TotalRunsLabel", children: "Automation Runs" }), _jsx(ActivityFeedState.Provider, { children: _jsx("div", { className: "activityFeedWrapper", children: _jsx(ActivityFeed, { collapsed: false, actorId: automationId, actorType: "AUTOMATION" }) }) })] }));
};
export default React.memo(AutomationUsage);
