import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Row } from 'antd';
import { useState, useRef, useCallback } from 'react';
import { usePageApi } from 'src/hooks/api';
import SettingsState from 'src/hooks/state/Settings';
import { CollectionType } from 'src/shared';
import { toggleList } from 'src/utils/Tools/LodashTool';
import { ShiftSchemasCannotHide } from '../../../../Constants';
var HideOthersFieldOptionsDropdownMenu = function (onToggleOption, fields) {
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    if (!fields) {
        return [
            {
                key: 'loading',
                label: _jsx("div", { className: "flex items-center justify-between", children: "Loading..." }),
            },
        ];
    }
    else {
        if (!fields.length) {
            return [
                {
                    key: 'empty field',
                    label: (_jsx("div", { className: "flex items-center justify-between", children: "No fields in data source shift can be hidden" })),
                },
            ];
        }
        else {
            return fields.map(function (field) {
                var _a, _b;
                return {
                    key: field.id,
                    label: (_jsxs(Row, { onClick: function (e) { return e.stopPropagation(); }, align: "middle", justify: "space-between", children: [field.name, _jsx(Checkbox, { onChange: function () { return onToggleOption(field.id); }, defaultChecked: (_b = (_a = accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.mobileSettings.calendar.subPermissions.hideOthersShiftSchemas) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.includes(field.id) })] })),
                };
            });
        }
    }
};
var MobileHideOthersFieldOption = function (_a) {
    var _b;
    var onChange = _a.onChange;
    var _c = useState(false), dropdownVisible = _c[0], setDropdownVisible = _c[1];
    var accessGroupDetail = SettingsState.useContainer().accessGroupDetail;
    var useGetCollectionSchemaGroupsFromCollectionType = usePageApi().useGetCollectionSchemaGroupsFromCollectionType;
    var schemas = useGetCollectionSchemaGroupsFromCollectionType({
        type: CollectionType.SHIFT,
        options: {
            enabled: dropdownVisible,
            cacheTime: 0,
        },
    }).data;
    var schemasCanHide = schemas === null || schemas === void 0 ? void 0 : schemas.filter(function (schema) { return schema.nativePath != null && !ShiftSchemasCannotHide.has(schema.nativePath); });
    var fieldKeys = (_b = accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.mobileSettings.calendar.subPermissions.hideOthersShiftSchemas) === null || _b === void 0 ? void 0 : _b.values;
    var settingKeysRef = useRef();
    var toggleRequiredKeys = function (key) {
        settingKeysRef.current = toggleList(settingKeysRef.current || fieldKeys || [], key);
    };
    var handleDropdownVisibleChange = useCallback(function (visible) {
        setDropdownVisible(visible);
        var keysToSave = settingKeysRef.current || fieldKeys;
        if (!visible && keysToSave) {
            onChange(keysToSave);
        }
    }, [fieldKeys, onChange]);
    return (_jsx(Dropdown, { menu: {
            items: HideOthersFieldOptionsDropdownMenu(toggleRequiredKeys, schemasCanHide),
        }, trigger: ['click'], onOpenChange: handleDropdownVisibleChange, getPopupContainer: function (triggerNode) { return triggerNode; }, children: _jsxs("div", { className: "rounded bg-gray-50 px-3 py-1 font-medium hover:cursor-pointer", children: ["Options ", _jsx(DownOutlined, {})] }) }));
};
export default MobileHideOthersFieldOption;
