import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Modal, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { NeedsCoverageType } from 'src/components/ScheduleView/FullPageScheduleBottomBar/interface';
var NeedsCoverageRefreshConfirmModal = function (_a) {
    var needOrCoverageId = _a.needOrCoverageId, type = _a.type, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, onClose = _a.onClose;
    var _b = useState(moment()), refreshFrom = _b[0], setRefreshFrom = _b[1];
    var _c = useState(false), publish = _c[0], setPublish = _c[1];
    var useGetStatusLogByDateRange = useNeedCoverageApi().useGetStatusLogByDateRange;
    var logStatus = useGetStatusLogByDateRange(needOrCoverageId, type === NeedsCoverageType.NEED ? 'needs' : 'coverage', {
        body: { startAt: refreshFrom.toISOString() },
        options: {
            refetchInterval: 2000,
        },
    }).data;
    var itemsInTransaction = logStatus === null || logStatus === void 0 ? void 0 : logStatus.some(function (item) { return item.inTransaction || item.hasError; });
    return (_jsx(Modal, { className: "NeedsCoverageTemplateDetailModal", open: isOpen, closable: false, zIndex: 1002, centered: true, onCancel: function () { return setIsOpen(false); }, onOk: function () {
            return onClose(refreshFrom.format('YYYY-MM-DD'), publish);
        }, okButtonProps: { disabled: itemsInTransaction }, maskClosable: false, title: "Settings Updated", children: _jsx("div", { children: _jsx("div", { className: "flex items-start space-x-3", children: _jsxs("div", { className: "pt-2 font-semibold", children: ["Changes will take effect from the date selected below.", _jsx("br", {}), "Please note you will not be able to make additional changes until this complete.", _jsxs("div", { className: "mt-5 flex items-center space-x-5", children: [_jsx(DatePicker, { onChange: function (e) {
                                        if (e) {
                                            setRefreshFrom(e);
                                        }
                                    }, defaultValue: moment(), disabledDate: function (current) {
                                        return current && current <= moment().startOf('day');
                                    } }), _jsxs("div", { className: "flex space-x-2", children: [_jsx(Checkbox, { value: publish, onChange: function (v) { return setPublish(v.target.checked); } }), _jsxs("div", { className: "font-medium flex items-center", children: ["Publish Updates", _jsx(Tooltip, { title: _jsxs("div", { className: "text-[13px] font-medium", children: ["Checkbox checked: Any new or updated shifts from the refresh will be published ", _jsx("br", {}), "Check box unchecked: Any new or updated shifts from the refresh will remain unpublished"] }), color: "black", placement: "bottom", overlayClassName: "HelpArticleUrlTooltip", children: _jsx(QuestionCircleOutlined, { className: "ml-1" }) })] })] })] }), itemsInTransaction && (_jsx("div", { className: "mt-2", children: "This Template is currently applying or refreshing. Please wait until it is complete before modifying it." }))] }) }) }) }));
};
export default React.memo(NeedsCoverageRefreshConfirmModal);
