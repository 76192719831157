var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DefaultPagePagination } from 'src/shared';
import { useFetch } from './useFetch';
export var useTasksApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var queryClient = useQueryClient();
    var useGetTasksOverview = function (options) {
        return useQuery(['tasksOverview'], function () {
            return customFetch({
                method: 'GET',
                url: 'tasks/overview',
            });
        }, __assign({}, options));
    };
    var refetchTasksOverview = function () { return queryClient.invalidateQueries(['tasksOverview']); };
    var useGetUserTasks = function (recordId) {
        return useQuery(['userTasks', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "tasks/user/".concat(recordId),
            });
        });
    };
    var useAssignTaskToRecord = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'tasks/assign',
            }, request);
        });
    };
    var useUnassignTaskGroupFromUser = function () {
        return useMutation(function (taskGroupId) {
            return toastRequest({
                method: 'DELETE',
                url: "tasks/user/group/".concat(taskGroupId),
            });
        });
    };
    var useUnassignTaskFromUser = function () {
        return useMutation(function (taskId) {
            return toastRequest({
                method: 'DELETE',
                url: "tasks/user/task/".concat(taskId),
            });
        });
    };
    var useGetUserReverseCollections = function (options) {
        return useQuery(['UserReverseCollections'], function () {
            return customFetch({
                method: 'GET',
                url: "collections/user/reverse_collections",
            });
        }, __assign({}, options));
    };
    var useCreateTaskGroup = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'tasks/group',
            }, request);
        });
    };
    var useUpdateTaskGroup = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "tasks/group/".concat(request.groupId),
            }, request.body);
        });
    };
    var useDeactivateTaskGroup = function () {
        return useMutation(function (taskGroupId) {
            return toastRequest({
                method: 'PUT',
                url: "tasks/group/".concat(taskGroupId, "/inactive"),
            });
        });
    };
    var useReactivateTaskGroup = function () {
        return useMutation(function (taskGroupId) {
            return toastRequest({
                method: 'PUT',
                url: "tasks/group/".concat(taskGroupId, "/reactivate"),
            });
        });
    };
    var useDeleteTaskGroup = function () {
        return useMutation(function (taskGroupId) {
            return toastRequest({
                method: 'DELETE',
                url: "tasks/group/".concat(taskGroupId),
            });
        });
    };
    var useCreateTaskTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'tasks/template',
            }, request);
        });
    };
    var useUpdateTaskTemplate = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "tasks/template/".concat(request.templateId),
            }, request.body);
        });
    };
    var useDeactivateTaskTemplate = function () {
        return useMutation(function (taskTemplateId) {
            return toastRequest({
                method: 'PUT',
                url: "tasks/template/".concat(taskTemplateId, "/inactive"),
            });
        });
    };
    var useReactivateTaskTemplate = function () {
        return useMutation(function (taskTemplateId) {
            return toastRequest({
                method: 'PUT',
                url: "tasks/template/".concat(taskTemplateId, "/reactivate"),
            });
        });
    };
    var useDeleteTaskTemplate = function () {
        return useMutation(function (taskTemplateId) {
            return toastRequest({
                method: 'DELETE',
                url: "tasks/template/".concat(taskTemplateId),
            });
        });
    };
    var useGetTaskRules = function (options) {
        return useQuery(['taskRules'], function () {
            return customFetch({
                method: 'POST',
                url: 'task_rules/list',
            }, {
                pageable: DefaultPagePagination,
            });
        }, __assign({}, options));
    };
    var refetchTaskRules = function () { return queryClient.invalidateQueries(['taskRules']); };
    var useCreateTaskRule = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'task_rules',
            }, request);
        });
    };
    var useUpdateTaskRule = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: "task_rules/".concat(request.ruleId),
            }, request.body);
        });
    };
    var useDeleteTaskRule = function () {
        return useMutation(function (taskRuleId) {
            return toastRequest({
                method: 'DELETE',
                url: "task_rules/".concat(taskRuleId),
            });
        });
    };
    var useGetTaskGroupsAndRulesWithTaskTemplate = function (taskTemplateId) {
        return useQuery(['taskGroupsAndRulesWithTaskTemplate', taskTemplateId], function () {
            return customFetch({
                method: 'GET',
                url: "tasks/template/".concat(taskTemplateId, "/groups_and_rules"),
            });
        }, { enabled: !!taskTemplateId });
    };
    var useGetTaskRulesWithTaskGroup = function (taskGroupId) {
        return useQuery(['taskRulesWithTaskGroup', taskGroupId], function () {
            return customFetch({
                method: 'GET',
                url: "tasks/group/".concat(taskGroupId, "/rules"),
            });
        }, { enabled: !!taskGroupId });
    };
    var useUpdateTaskCompletionDetail = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'tasks/update_completion_detail',
            }, request, undefined, "Successfully ".concat(request.taskCompletionDetail ? 'linked' : 'unlinked', " record!"));
        });
    };
    var useGetRecordTitles = function (request) {
        var _a, _b;
        var recordIds = (_b = (_a = request.body) === null || _a === void 0 ? void 0 : _a.recordIds) !== null && _b !== void 0 ? _b : [];
        return useQuery(['recordTitle', JSON.stringify(recordIds)], function () {
            return customFetch({
                method: 'POST',
                url: "collections/record_titles",
            }, { recordIds: recordIds });
        }, __assign({}, request.options));
    };
    var useGetPublishTaskChangesInfo = function (recordId, options) {
        return useQuery(['getPublishTaskChangesInfo', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "jobs/publish_placement_task_info/".concat(recordId),
            });
        }, options);
    };
    var usePublishTaskChanges = function (recordId) {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "jobs/publish_placement_task/".concat(recordId),
            }, request, undefined, 'Successfully published changes');
        });
    };
    // Note: assigned task group ID is *not* the same as the task group template ID
    var useGetAssignedTaskGroupById = function (assignedTaskGroupId, options) {
        return useQuery(['getAssignedTaskGroupById', assignedTaskGroupId], function () {
            return customFetch({
                method: 'GET',
                url: "tasks/assigned_task_group/".concat(assignedTaskGroupId),
            });
        }, options);
    };
    return {
        useGetTasksOverview: useGetTasksOverview,
        refetchTasksOverview: refetchTasksOverview,
        useGetUserTasks: useGetUserTasks,
        useAssignTaskToRecord: useAssignTaskToRecord(),
        useUnassignTaskGroupFromUser: useUnassignTaskGroupFromUser(),
        useUnassignTaskFromUser: useUnassignTaskFromUser(),
        useGetUserReverseCollections: useGetUserReverseCollections,
        useCreateTaskGroup: useCreateTaskGroup(),
        useUpdateTaskGroup: useUpdateTaskGroup(),
        useDeactivateTaskGroup: useDeactivateTaskGroup(),
        useReactivateTaskGroup: useReactivateTaskGroup(),
        useDeleteTaskGroup: useDeleteTaskGroup(),
        useCreateTaskTemplate: useCreateTaskTemplate(),
        useUpdateTaskTemplate: useUpdateTaskTemplate(),
        useDeactivateTaskTemplate: useDeactivateTaskTemplate(),
        useReactivateTaskTemplate: useReactivateTaskTemplate(),
        useDeleteTaskTemplate: useDeleteTaskTemplate(),
        useGetTaskRules: useGetTaskRules,
        refetchTaskRules: refetchTaskRules,
        useCreateTaskRule: useCreateTaskRule(),
        useUpdateTaskRule: useUpdateTaskRule(),
        useDeleteTaskRule: useDeleteTaskRule(),
        useGetTaskGroupsAndRulesWithTaskTemplate: useGetTaskGroupsAndRulesWithTaskTemplate,
        useGetTaskRulesWithTaskGroup: useGetTaskRulesWithTaskGroup,
        useUpdateTaskCompletionDetail: useUpdateTaskCompletionDetail(),
        useGetRecordTitles: useGetRecordTitles,
        useGetPublishTaskChangesInfo: useGetPublishTaskChangesInfo,
        usePublishTaskChanges: usePublishTaskChanges,
        useGetAssignedTaskGroupById: useGetAssignedTaskGroupById,
    };
};
