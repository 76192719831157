import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useSetting } from '../../../hooks/api/useSettingApi';
var useFormTemplate = function () {
    var _a = useSetting(), useGetAllFormTemplates = _a.useGetAllFormTemplates, useGetAvailableSchemasInCollection = _a.useGetAvailableSchemasInCollection, useDeleteFile = _a.useDeleteFile;
    var _b = useGetAllFormTemplates(), allFormTemplateData = _b.data, refetchAllFormTemplates = _b.refetch;
    var deleteFile = useDeleteFile().mutate;
    var _c = useState(false), showCreateFormTemplateModal = _c[0], setShowCreateFormTemplateModal = _c[1];
    var _d = useState(undefined), selectedFormTemplateId = _d[0], setSelectedFormTemplateId = _d[1];
    var _e = useState(''), modalTemplateName = _e[0], setModalTemplateName = _e[1];
    var _f = useState(undefined), templateCollectionId = _f[0], setTemplateCollectionId = _f[1];
    var _g = useState([]), formLayout = _g[0], setFormLayout = _g[1];
    var _h = useState(false), isPublic = _h[0], setIsPublic = _h[1];
    var _j = useGetAvailableSchemasInCollection(templateCollectionId), availableSchemasOfSourceCollection = _j.data, fetchedAvailableSchemas = _j.isFetched;
    var closeModal = useCallback(function () {
        setShowCreateFormTemplateModal(false);
        setSelectedFormTemplateId(undefined);
        setModalTemplateName('');
        setTemplateCollectionId(undefined);
        setFormLayout([]);
    }, []);
    var editExistingTemplate = useCallback(function (data) {
        setSelectedFormTemplateId(data.id);
        setModalTemplateName(data.name);
        setTemplateCollectionId(data.sourceCollectionId);
        setFormLayout(data.layout.content);
        setShowCreateFormTemplateModal(true);
        setIsPublic(data.isPublic);
    }, []);
    return {
        allFormTemplateData: allFormTemplateData,
        showCreateFormTemplateModal: showCreateFormTemplateModal,
        selectedFormTemplateId: selectedFormTemplateId,
        modalTemplateName: modalTemplateName,
        templateCollectionId: templateCollectionId,
        formLayout: formLayout,
        availableSchemasOfSourceCollection: availableSchemasOfSourceCollection,
        fetchedAvailableSchemas: fetchedAvailableSchemas,
        isPublic: isPublic,
        deleteFile: deleteFile,
        refetchAllFormTemplates: refetchAllFormTemplates,
        setShowCreateFormTemplateModal: setShowCreateFormTemplateModal,
        setSelectedFormTemplateId: setSelectedFormTemplateId,
        setModalTemplateName: setModalTemplateName,
        setTemplateCollectionId: setTemplateCollectionId,
        closeModal: closeModal,
        setFormLayout: setFormLayout,
        editExistingTemplate: editExistingTemplate,
    };
};
var FormTemplateState = createContainer(useFormTemplate);
export default FormTemplateState;
