import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lowerCase, startCase } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { accountTimeZoneSelector } from 'src/state/selectors/withAccountDetailState';
import { chosenMetricWithDataState } from './Atoms';
import { isAboveGoal, numberFormatterWithType, ruleDataToGraphData, timeRangeToDisplayText, } from './HealthMetricCalculator';
import HealthMetricGraphBuilder from './HealthMetricGraphBuilder';
import HealthMetricLoadingBar from './HealthMetricLoadingBar';
var HealthMetricGraphCard = function (_a) {
    var _b, _c, _d;
    var rule = _a.rule, ruleData = _a.ruleData;
    var timeZone = useRecoilValue(accountTimeZoneSelector);
    var graphData = ruleDataToGraphData(ruleData || [], rule.type, timeZone, (_b = rule.settings) === null || _b === void 0 ? void 0 : _b.interval);
    var timeRange = graphData === null || graphData === void 0 ? void 0 : graphData.map(function (d) { return d.date; });
    var name = rule.name, type = rule.type;
    var latestData = graphData.length >= 2
        ? graphData === null || graphData === void 0 ? void 0 : graphData[graphData.length - 2]
        : graphData === null || graphData === void 0 ? void 0 : graphData[graphData.length - 1];
    var latestDataColor = !rule.goal
        ? 'text-black'
        : (latestData === null || latestData === void 0 ? void 0 : latestData.number) && isAboveGoal(latestData.number, rule.goal)
            ? 'text-positive'
            : 'text-negative';
    var navigate = useNavigate();
    var handleViewDetail = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            set(chosenMetricWithDataState, {
                rule: rule,
                data: ruleData,
            });
            navigate("/home/goals/".concat(rule.id));
        };
    }, [navigate, rule, ruleData]);
    return (_jsxs("div", { className: "h-[320px] min-w-[240px] rounded-[15px] bg-white px-10 py-8 shadow-block hover:cursor-pointer hover:drop-shadow-xl", onClick: handleViewDetail, children: [_jsxs("div", { className: "mb-4 flex", children: [_jsxs("div", { className: "flex-1", children: [_jsx("div", { className: "text-lg font-medium", children: name }), _jsxs("div", { className: "text-xs text-gray-400", children: [startCase(lowerCase(((_c = rule.settings) === null || _c === void 0 ? void 0 : _c.interval) || 'Daily')), ", Last", ' ', timeRangeToDisplayText(rule.timeRange)] })] }), _jsxs("div", { children: [graphData && !!graphData.length && !!latestData && (_jsx("div", { className: "text-lg font-medium ".concat(latestDataColor), children: numberFormatterWithType(latestData.number, type) })), !!rule.goal && _jsxs("div", { className: "text-xs text-gray-400", children: ["Goal : ", rule.goal] })] })] }), _jsx("div", { className: "h-[200px]", children: graphData && timeRange && !!latestData ? (_jsx(HealthMetricGraphBuilder, { title: rule.name, data: graphData, type: type, goal: rule.goal, interval: (_d = rule.settings) === null || _d === void 0 ? void 0 : _d.interval })) : (_jsx("div", { className: "flex h-[100%] w-[100%] items-center justify-center", children: _jsx(HealthMetricLoadingBar, { text: "Data will be available soon..." }) })) })] }));
};
export default React.memo(HealthMetricGraphCard);
