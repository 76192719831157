var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { reportBuilderState, reportTableState } from 'src/state';
import { DefaultValue, selectorFamily } from 'recoil';
import { getVariableDateRangeTimespan } from 'src/services';
import { ReportChartType, FAKE_UUID_FOR_TOTALS, } from 'src/components/ReportBlock';
import { reportMetadataState } from '..';
import { accountTimeZoneSelector } from './withAccountDetailState';
var reportDateColumnsSelector = selectorFamily({
    key: 'reportDateColumnsSelector',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var dateColumns = get(reportTableState(reportId)).dateColumns;
            return dateColumns !== null && dateColumns !== void 0 ? dateColumns : [];
        };
    },
});
var reportMetricColumnsSelector = selectorFamily({
    key: 'reportMetricColumnsSelector',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var metricColumns = get(reportTableState(reportId)).metricColumns;
            return metricColumns !== null && metricColumns !== void 0 ? metricColumns : [];
        };
    },
});
var reportSourceTypeSelector = selectorFamily({
    key: 'reportSourceTypeSelector',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var sourceType = get(reportMetadataState(reportId)).sourceType;
            return sourceType !== null && sourceType !== void 0 ? sourceType : 'user';
        };
    },
});
var reportChartTypeState = selectorFamily({
    key: 'reportChartTypeState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var settings = get(reportBuilderState(reportId)).settings;
            var chartType = (settings !== null && settings !== void 0 ? settings : {}).chartType;
            return chartType !== null && chartType !== void 0 ? chartType : ReportChartType.BAR;
        };
    },
    set: function (reportId) {
        return function (_a, newChartType) {
            var set = _a.set;
            if (!(newChartType instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { settings: __assign(__assign({}, prev.settings), { chartType: newChartType }) });
                });
            }
        };
    },
});
var reportChartVisibleState = selectorFamily({
    key: 'reportChartVisibleState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var settings = get(reportBuilderState(reportId)).settings;
            var showChart = (settings !== null && settings !== void 0 ? settings : {}).showChart;
            return showChart !== null && showChart !== void 0 ? showChart : true;
        };
    },
    set: function (reportId) {
        return function (_a, newChartVisible) {
            var set = _a.set;
            if (!(newChartVisible instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { settings: __assign(__assign({}, prev.settings), { showChart: newChartVisible }) });
                });
            }
        };
    },
});
var reportTableVisibleState = selectorFamily({
    key: 'reportTableVisibleState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var settings = get(reportBuilderState(reportId)).settings;
            var showTable = (settings !== null && settings !== void 0 ? settings : {}).showTable;
            return showTable !== null && showTable !== void 0 ? showTable : true;
        };
    },
    set: function (reportId) {
        return function (_a, newTableVisible) {
            var set = _a.set;
            if (!(newTableVisible instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { settings: __assign(__assign({}, prev.settings), { showTable: newTableVisible }) });
                });
            }
        };
    },
});
var reportPlottedMetricsState = selectorFamily({
    key: 'reportPlottedMetricsState',
    get: function (reportId) {
        return function (_a) {
            var _b, _c, _d;
            var get = _a.get;
            var settings = get(reportBuilderState(reportId)).settings;
            var _e = settings !== null && settings !== void 0 ? settings : {}, metrics = _e.metrics, formulas = _e.formulas, schemas = _e.schemas;
            var plottedNormalMetrics = (_b = metrics === null || metrics === void 0 ? void 0 : metrics.filter(function (metric) { return metric.isPlotted; }).map(function (_a) {
                var metricType = _a.metricType;
                return metricType;
            })) !== null && _b !== void 0 ? _b : [];
            var plottedSchemaMetrics = (_c = schemas === null || schemas === void 0 ? void 0 : schemas.filter(function (schema) { return schema.isPlotted; }).map(function (_a) {
                var schemaId = _a.schemaId;
                return schemaId;
            })) !== null && _c !== void 0 ? _c : [];
            var plottedFormulaMetrics = (_d = formulas === null || formulas === void 0 ? void 0 : formulas.filter(function (formula) { return formula.isPlotted; }).map(function (_a) {
                var formula = _a.formula;
                return formula;
            })) !== null && _d !== void 0 ? _d : [];
            return __spreadArray(__spreadArray(__spreadArray([], plottedNormalMetrics, true), plottedSchemaMetrics, true), plottedFormulaMetrics, true);
        };
    },
    set: function (reportId) {
        return function (_a, newPlottedMetrics) {
            var set = _a.set;
            if (!(newPlottedMetrics instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    var _a, _b, _c;
                    var newMetrics = (_a = prev.settings.metrics) === null || _a === void 0 ? void 0 : _a.map(function (metric) {
                        return __assign(__assign({}, metric), { isPlotted: newPlottedMetrics.includes(metric.metricType) });
                    });
                    var newSchemas = (_b = prev.settings.schemas) === null || _b === void 0 ? void 0 : _b.map(function (schema) {
                        return __assign(__assign({}, schema), { isPlotted: newPlottedMetrics.includes(schema.schemaId) });
                    });
                    var newFormulas = (_c = prev.settings.formulas) === null || _c === void 0 ? void 0 : _c.map(function (formula) {
                        return __assign(__assign({}, formula), { isPlotted: newPlottedMetrics.includes(formula.formula) });
                    });
                    return __assign(__assign({}, prev), { settings: __assign(__assign({}, prev.settings), { metrics: newMetrics, schemas: newSchemas, formulas: newFormulas }) });
                });
            }
        };
    },
});
var reportIsVariableDateRangeSelector = selectorFamily({
    key: 'reportIsVariableDateRangeSelector',
    get: function (reportId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var dateDetails = get(reportBuilderState(reportId)).dateDetails;
            var dateRange = (dateDetails !== null && dateDetails !== void 0 ? dateDetails : {}).dateRange;
            return (_b = (dateRange === null || dateRange === void 0 ? void 0 : dateRange.type) === 'variable') !== null && _b !== void 0 ? _b : true;
        };
    },
});
var reportVariableDateRangeTypeState = selectorFamily({
    key: 'reportVariableDateRangeTypeState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var isVariableDateRange = get(reportIsVariableDateRangeSelector(reportId));
            if (!isVariableDateRange)
                return undefined;
            var dateDetails = get(reportBuilderState(reportId)).dateDetails;
            var dateRangeType = (dateDetails === null || dateDetails === void 0 ? void 0 : dateDetails.dateRange).dateRangeType;
            return dateRangeType;
        };
    },
    set: function (reportId) {
        return function (_a, newDateRangeType) {
            var set = _a.set, get = _a.get;
            if (newDateRangeType && !(newDateRangeType instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    var isTimeBased = !!get(reportTimespanState(reportId));
                    return __assign(__assign({}, prev), { dateDetails: __assign(__assign({}, prev.dateDetails), { dateRange: {
                                type: 'variable',
                                dateRangeType: newDateRangeType,
                            }, timespan: isTimeBased
                                ? getVariableDateRangeTimespan(newDateRangeType)
                                : null }) });
                });
            }
        };
    },
});
var reportTimespanState = selectorFamily({
    key: 'reportTimespanState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var dateDetails = get(reportBuilderState(reportId)).dateDetails;
            var timespan = (dateDetails !== null && dateDetails !== void 0 ? dateDetails : {}).timespan;
            return timespan !== null && timespan !== void 0 ? timespan : null;
        };
    },
    set: function (reportId) {
        return function (_a, newTimespan) {
            var set = _a.set;
            if (!(newTimespan instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { dateDetails: __assign(__assign({}, prev.dateDetails), { timespan: newTimespan }) });
                });
            }
        };
    },
});
var reportDateRangeState = selectorFamily({
    key: 'reportDateRangeState',
    get: function (reportId) {
        return function (_a) {
            var _b;
            var get = _a.get;
            var isVariableDateRange = get(reportIsVariableDateRangeSelector(reportId));
            if (isVariableDateRange)
                return undefined;
            var dateDetails = get(reportBuilderState(reportId)).dateDetails;
            var _c = (_b = dateDetails === null || dateDetails === void 0 ? void 0 : dateDetails.dateRange) !== null && _b !== void 0 ? _b : {}, startDate = _c.startDate, endDate = _c.endDate;
            return { startDate: startDate, endDate: endDate };
        };
    },
    set: function (reportId) {
        return function (_a, newDateRange) {
            var set = _a.set;
            if (newDateRange && !(newDateRange instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { dateDetails: __assign(__assign({}, prev.dateDetails), { dateRange: __assign(__assign({}, prev.dateDetails.dateRange), newDateRange) }) });
                });
            }
        };
    },
});
var reportSelectedRecordsState = selectorFamily({
    key: 'reportSelectedRecordsState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var displayConfig = get(reportBuilderState(reportId)).displayConfig;
            var selectedRows = (displayConfig !== null && displayConfig !== void 0 ? displayConfig : {}).selectedRows;
            return selectedRows !== null && selectedRows !== void 0 ? selectedRows : [FAKE_UUID_FOR_TOTALS];
        };
    },
    set: function (reportId) {
        return function (_a, newSelectedRows) {
            var set = _a.set;
            if (!(newSelectedRows instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { displayConfig: __assign(__assign({}, prev.displayConfig), { selectedRows: newSelectedRows }) });
                });
            }
        };
    },
});
var reportPageSizeState = selectorFamily({
    key: 'reportPageSizeState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var displayConfig = get(reportBuilderState(reportId)).displayConfig;
            var pageSize = (displayConfig !== null && displayConfig !== void 0 ? displayConfig : {}).pageSize;
            return pageSize !== null && pageSize !== void 0 ? pageSize : 10;
        };
    },
    set: function (reportId) {
        return function (_a, newPageSize) {
            var set = _a.set;
            if (!(newPageSize instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { displayConfig: __assign(__assign({}, prev.displayConfig), { pageSize: newPageSize }) });
                });
            }
        };
    },
});
var reportSortBysState = selectorFamily({
    key: 'reportSortBysState',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var displayConfig = get(reportBuilderState(reportId)).displayConfig;
            var sortBys = (displayConfig !== null && displayConfig !== void 0 ? displayConfig : {}).sortBys;
            return sortBys !== null && sortBys !== void 0 ? sortBys : [];
        };
    },
    set: function (reportId) {
        return function (_a, newSortBys) {
            var set = _a.set;
            if (!(newSortBys instanceof DefaultValue)) {
                set(reportBuilderState(reportId), function (prev) {
                    return __assign(__assign({}, prev), { displayConfig: __assign(__assign({}, prev.displayConfig), { sortBys: newSortBys }) });
                });
            }
        };
    },
});
var reportTimeZoneSelector = selectorFamily({
    key: 'reportTimeZoneSelector',
    get: function (reportId) {
        return function (_a) {
            var get = _a.get;
            var dateRange = get(reportMetadataState(reportId)).dateRange;
            var timezone = (dateRange !== null && dateRange !== void 0 ? dateRange : {}).timezone;
            var accountTimezone = get(accountTimeZoneSelector);
            return timezone !== null && timezone !== void 0 ? timezone : accountTimezone;
        };
    },
});
export { reportDateColumnsSelector, reportMetricColumnsSelector, reportSourceTypeSelector, reportChartTypeState, reportChartVisibleState, reportTableVisibleState, reportPlottedMetricsState, reportIsVariableDateRangeSelector, reportVariableDateRangeTypeState, reportTimespanState, reportDateRangeState, reportSelectedRecordsState, reportPageSizeState, reportSortBysState, reportTimeZoneSelector, };
