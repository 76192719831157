var _a, _b, _c;
import { NativePath } from 'src/shared';
export var ShiftStatus;
(function (ShiftStatus) {
    ShiftStatus["DRAFT"] = "draft";
    ShiftStatus["OPEN"] = "open";
    ShiftStatus["UNFULFILLED"] = "unfulfilled";
    ShiftStatus["UPCOMING"] = "upcoming";
    ShiftStatus["ONGOING"] = "ongoing";
    ShiftStatus["COMPLETED"] = "completed";
    ShiftStatus["LATE"] = "late";
    ShiftStatus["NO_SHOW"] = "no_show";
    ShiftStatus["UNKNOWN"] = "unknown";
})(ShiftStatus || (ShiftStatus = {}));
export var ShiftStatusTagColorMap = (_a = {},
    _a[ShiftStatus.DRAFT] = '#F4F4F7',
    _a[ShiftStatus.OPEN] = '#EAEDFC',
    _a[ShiftStatus.UNFULFILLED] = '#FFF9ED',
    _a[ShiftStatus.UPCOMING] = '#F3F1FB',
    _a[ShiftStatus.ONGOING] = '#B2DFD0',
    _a[ShiftStatus.COMPLETED] = '#E5F4EF',
    _a[ShiftStatus.LATE] = '#FFC9C7',
    _a[ShiftStatus.NO_SHOW] = '#FFEDEC',
    _a[ShiftStatus.UNKNOWN] = '#C5C5C5',
    _a);
export var ShiftStatusCardColorMap = (_b = {},
    _b[ShiftStatus.DRAFT] = '#F4F4F7',
    _b[ShiftStatus.OPEN] = '#EAEDFC',
    _b[ShiftStatus.UNFULFILLED] = '#FFF9ED',
    _b[ShiftStatus.UPCOMING] = '#F3F1FB',
    _b[ShiftStatus.ONGOING] = '#00A76F',
    _b[ShiftStatus.COMPLETED] = '#E5F4EF',
    _b[ShiftStatus.LATE] = '#FF4A43',
    _b[ShiftStatus.NO_SHOW] = '#FFEDEC',
    _b[ShiftStatus.UNKNOWN] = '#C5C5C5',
    _b);
export var ShiftStatusDisplayNameMap = (_c = {},
    _c[ShiftStatus.DRAFT] = 'Draft',
    _c[ShiftStatus.OPEN] = 'Open',
    _c[ShiftStatus.UNFULFILLED] = 'Unfulfilled',
    _c[ShiftStatus.UPCOMING] = 'Upcoming',
    _c[ShiftStatus.ONGOING] = 'Ongoing',
    _c[ShiftStatus.COMPLETED] = 'Completed',
    _c[ShiftStatus.LATE] = 'Late',
    _c[ShiftStatus.NO_SHOW] = 'No Show',
    _c[ShiftStatus.UNKNOWN] = 'Unknown',
    _c);
export var ShiftSchemasThatRequireRefetchSet = new Set([
    NativePath.SHIFT_START_AT,
    NativePath.SHIFT_END_AT,
    NativePath.SHIFT_CLOCK_IN,
    NativePath.SHIFT_CLOCK_OUT,
    NativePath.SHIFT_USER_ID,
    NativePath.SHIFT_PUBLISHED,
]);
export var ShiftStatusDisplayNameToColorMap = {
    Draft: ShiftStatusTagColorMap[ShiftStatus.DRAFT],
    Open: ShiftStatusTagColorMap[ShiftStatus.OPEN],
    Unfulfilled: ShiftStatusTagColorMap[ShiftStatus.UNFULFILLED],
    Upcoming: ShiftStatusTagColorMap[ShiftStatus.UPCOMING],
    Ongoing: ShiftStatusTagColorMap[ShiftStatus.ONGOING],
    Completed: ShiftStatusTagColorMap[ShiftStatus.COMPLETED],
    Late: ShiftStatusTagColorMap[ShiftStatus.LATE],
    'No Show': ShiftStatusTagColorMap[ShiftStatus.NO_SHOW],
    Unknown: ShiftStatusTagColorMap[ShiftStatus.UNKNOWN],
};
export var RecurringShiftEditType;
(function (RecurringShiftEditType) {
    RecurringShiftEditType["THIS_SHIFT"] = "THIS_SHIFT";
    RecurringShiftEditType["FUTURE_SHIFTS"] = "FUTURE_SHIFTS";
    RecurringShiftEditType["ALL_SHIFTS"] = "ALL_SHIFTS";
})(RecurringShiftEditType || (RecurringShiftEditType = {}));
export var StandardScheduleConstraint;
(function (StandardScheduleConstraint) {
    StandardScheduleConstraint["ASSIGN_EVERY_SHIFT"] = "ASSIGN_EVERY_SHIFT";
    StandardScheduleConstraint["REQUIRED_ROLE_FOR_SHIFT"] = "REQUIRED_ROLE_FOR_SHIFT";
    StandardScheduleConstraint["REQUIRED_LOCATION_FOR_SHIFT"] = "REQUIRED_LOCATION_FOR_SHIFT";
    StandardScheduleConstraint["NO_OVERLAPPING_SHIFTS"] = "NO_OVERLAPPING_SHIFTS";
    StandardScheduleConstraint["UNAVAILABLE_TIME_FOR_SHIFT"] = "UNAVAILABLE_TIME_FOR_SHIFT";
    StandardScheduleConstraint["MAX_MINUTES_PER_DAY"] = "MAX_MINUTES_PER_DAY";
    StandardScheduleConstraint["MAX_MINUTES_PER_WEEK"] = "MAX_MINUTES_PER_WEEK";
    StandardScheduleConstraint["MIN_MINUTES_PER_DAY"] = "MIN_MINUTES_PER_DAY";
    StandardScheduleConstraint["MIN_MINUTES_PER_WEEK"] = "MIN_MINUTES_PER_WEEK";
    StandardScheduleConstraint["DESIRED_TIME_FOR_SHIFT"] = "DESIRED_TIME_FOR_SHIFT";
    StandardScheduleConstraint["UNDESIRED_TIME_FOR_SHIFT"] = "UNDESIRED_TIME_FOR_SHIFT";
    StandardScheduleConstraint["USER_DOCUMENT_EXPIRY_DATE"] = "USER_DOCUMENT_EXPIRY_DATE";
    StandardScheduleConstraint["CLIENT_FIELD_MATCH_0"] = "CLIENT_FIELD_MATCH_0";
    StandardScheduleConstraint["CLIENT_FIELD_MATCH_1"] = "CLIENT_FIELD_MATCH_1";
    StandardScheduleConstraint["CLIENT_FIELD_MATCH_2"] = "CLIENT_FIELD_MATCH_2";
    StandardScheduleConstraint["CLIENT_FIELD_MATCH_3"] = "CLIENT_FIELD_MATCH_3";
    StandardScheduleConstraint["CLIENT_FIELD_MATCH_4"] = "CLIENT_FIELD_MATCH_4";
    StandardScheduleConstraint["CLIENT_FIELD_MATCH_5"] = "CLIENT_FIELD_MATCH_5";
    StandardScheduleConstraint["NO_CLIENT_OVERLAPPING_SHIFTS"] = "NO_CLIENT_OVERLAPPING_SHIFTS";
    StandardScheduleConstraint["CLIENT_SHIFT_ROLE_MATCH"] = "CLIENT_SHIFT_ROLE_MATCH";
    StandardScheduleConstraint["CLIENT_SHIFT_LOCATION_MATCH"] = "CLIENT_SHIFT_LOCATION_MATCH";
})(StandardScheduleConstraint || (StandardScheduleConstraint = {}));
export var MaxMinutesScheduleConstraints = new Set([
    StandardScheduleConstraint.MAX_MINUTES_PER_DAY,
    StandardScheduleConstraint.MAX_MINUTES_PER_WEEK,
]);
export var MinMinutesScheduleConstraints = new Set([
    StandardScheduleConstraint.MIN_MINUTES_PER_DAY,
    StandardScheduleConstraint.MIN_MINUTES_PER_WEEK,
]);
export var StandardScheduleConstraintKey;
(function (StandardScheduleConstraintKey) {
    StandardScheduleConstraintKey["ASSIGN_EVERY_SHIFT"] = "assignEveryShift";
    StandardScheduleConstraintKey["REQUIRED_ROLE_FOR_SHIFT"] = "requiredRoleForShift";
    StandardScheduleConstraintKey["REQUIRED_LOCATION_FOR_SHIFT"] = "requiredLocationForShift";
    StandardScheduleConstraintKey["NO_OVERLAPPING_SHIFTS"] = "noOverlappingShifts";
    StandardScheduleConstraintKey["UNAVAILABLE_TIME_FOR_SHIFT"] = "unavailableTimeForShift";
    StandardScheduleConstraintKey["MAX_MINUTES_PER_DAY"] = "maxMinutesPerDay";
    StandardScheduleConstraintKey["MAX_MINUTES_PER_WEEK"] = "maxMinutesPerWeek";
    StandardScheduleConstraintKey["MIN_MINUTES_PER_DAY"] = "minMinutesPerDay";
    StandardScheduleConstraintKey["MIN_MINUTES_PER_WEEK"] = "minMinutesPerWeek";
    StandardScheduleConstraintKey["DESIRED_TIME_FOR_SHIFT"] = "desiredTimeForShift";
    StandardScheduleConstraintKey["UNDESIRED_TIME_FOR_SHIFT"] = "undesiredTimeForShift";
    StandardScheduleConstraintKey["USER_DOCUMENT_EXPIRY_DATE"] = "userDocumentExpiryDate";
    StandardScheduleConstraintKey["CLIENT_FIELD_MATCH_0"] = "clientFieldMatch0";
    StandardScheduleConstraintKey["CLIENT_FIELD_MATCH_1"] = "clientFieldMatch1";
    StandardScheduleConstraintKey["CLIENT_FIELD_MATCH_2"] = "clientFieldMatch2";
    StandardScheduleConstraintKey["CLIENT_FIELD_MATCH_3"] = "clientFieldMatch3";
    StandardScheduleConstraintKey["CLIENT_FIELD_MATCH_4"] = "clientFieldMatch4";
    StandardScheduleConstraintKey["CLIENT_FIELD_MATCH_5"] = "clientFieldMatch5";
    StandardScheduleConstraintKey["NO_CLIENT_OVERLAPPING_SHIFTS"] = "noClientOverlappingShifts";
    StandardScheduleConstraintKey["CLIENT_SHIFT_ROLE_MATCH"] = "clientShiftRoleMatch";
    StandardScheduleConstraintKey["CLIENT_SHIFT_LOCATION_MATCH"] = "clientShiftLocationMatch";
})(StandardScheduleConstraintKey || (StandardScheduleConstraintKey = {}));
export var RuleType;
(function (RuleType) {
    RuleType["MILD"] = "MILD";
    RuleType["MODERATE"] = "MODERATE";
    RuleType["STRICT"] = "STRICT";
})(RuleType || (RuleType = {}));
export var scheduleRuleColorSet = {
    STRICT: '#ff807b',
    MODERATE: '#FFC047',
    MILD: '#5CA5EC',
};
export var scheduleRulesCheckColorSet = {
    SUCCESS: '#4cb391',
    ERROR: '#ff807b',
};
export var SHIFT_CARD_HEIGHT_SM = 64;
export var SHIFT_CARD_ROW_GAP = 8;
export var VIRTUAL_LIST_ON_CARD_COUNT = 6;
export var WEEK_VIEW_DATE_COLUMN_MIN_WIDTH = 130;
export var MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH = 90;
