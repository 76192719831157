import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilState } from 'recoil';
import { selectedShiftsState } from 'src/state/atoms/schedule';
var ClearSelectedButton = function (_a) {
    var blockId = _a.blockId;
    var _b = useRecoilState(selectedShiftsState(blockId)), selectedShifts = _b[0], setSelectedShifts = _b[1];
    if (!selectedShifts.length)
        return null;
    return (_jsx("div", { className: "ClearSelected", onClick: function () { return setSelectedShifts([]); }, children: "Clear Selected" }));
};
export default React.memo(ClearSelectedButton);
