var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRecordComponent } from 'src/hooks/component';
import { detailModalPropsState, isNewModalVisibleState } from 'src/state';
import RecordDetailModalWrapper from './RecordDetailModalWrapper';
var RecordDetailModalViewContainer = function () {
    var getPreSaveRequestBody = useRecordComponent().getPreSaveRequestBody;
    var _a = useRecoilState(isNewModalVisibleState), isNewModalVisible = _a[0], setIsNewModalVisible = _a[1];
    var detailModalProps = useRecoilValue(detailModalPropsState);
    var location = useLocation();
    var recordId = new URLSearchParams(location.search).get('rid');
    var isExistingRecordModalVisible = !!recordId;
    return (_jsxs(_Fragment, { children: [isExistingRecordModalVisible && (_jsx(RecordDetailModalWrapper, { recordId: recordId, isCreatingNew: false, defaultTimeZone: undefined, blockId: "" })), isNewModalVisible && (_jsx(RecordDetailModalWrapper, __assign({}, detailModalProps, { isCreatingNew: !detailModalProps.bulkEditData, getPreSaveRequestBody: getPreSaveRequestBody, setIsModalVisible: setIsNewModalVisible, recordId: detailModalProps.defaultModalData.mainRecord.rows[0].recordId })))] }));
};
export default React.memo(RecordDetailModalViewContainer);
