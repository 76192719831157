var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var MobileAccessIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", height: "24", width: "24", children: _jsxs("g", { children: [_jsx("rect", { x: "2", y: "0.5", width: "10", height: "13", rx: "1", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("rect", { x: "4.5", y: "3", width: "5", height: "5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("circle", { cx: "7", cy: "10.75", r: "0.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" })] }) })); };
export var MobileAccessIcon = function (props) { return (_jsx(Icon, __assign({ component: MobileAccessIconSvg }, props))); };
