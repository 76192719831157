var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { SubMenuContentIcon } from '../../../../../../shared';
import { isEntry } from '../../../../../../services/Interfaces';
import { getColumnTypeKey } from '../../../../../../utils/Collection';
import { FileOutlinedIcon } from '../../../../../../utils/icons/DropdownIcons/FileOutlined';
import { TextOutlinedIcon } from '../../../../../../utils/icons/DropdownIcons/TextOutlined';
import ZiraDropdown from '../../../../../../ui/ZiraDropdown';
import FormTemplateState from '../../../FormTemplate';
var AddQuestionOrBlockToForm = function () {
    var _a = FormTemplateState.useContainer(), availableSchemasOfSourceCollection = _a.availableSchemasOfSourceCollection, formLayout = _a.formLayout, setFormLayout = _a.setFormLayout;
    var schemaIsAdded = useCallback(function (item) {
        return formLayout.map(function (item) { return isEntry(item) && item.schemaId; }).includes(item.id);
    }, [formLayout]);
    var addSchemaToFormLayout = useCallback(function (formTemplateSchemaData) {
        setFormLayout(function (formLayout) { return __spreadArray(__spreadArray([], formLayout, true), [
            {
                type: 'ENTRY',
                schemaId: formTemplateSchemaData.id,
                label: formTemplateSchemaData.name,
                description: '',
                visibility: 'EDITABLE',
                required: false,
                isAuthor: formTemplateSchemaData.isUserSchema ? false : null,
                defaultValue: null,
            },
        ], false); });
    }, [setFormLayout]);
    var addDescriptionToFormLayout = useCallback(function () {
        setFormLayout(function (formLayout) { return __spreadArray(__spreadArray([], formLayout, true), [
            {
                type: 'DESCRIPTION',
                label: 'New Text',
                description: 'Enter a description Here',
            },
        ], false); });
    }, [setFormLayout]);
    var addImageToFormLayout = useCallback(function () {
        setFormLayout(function (formLayout) { return __spreadArray(__spreadArray([], formLayout, true), [
            {
                type: 'IMAGE',
                label: 'New Picture',
                imageUrl: '',
            },
        ], false); });
    }, [setFormLayout]);
    var items = useMemo(function () {
        var questionGroupOptions = availableSchemasOfSourceCollection === null || availableSchemasOfSourceCollection === void 0 ? void 0 : availableSchemasOfSourceCollection.filter(function (item) { return !schemaIsAdded(item); }).map(function (item) {
            return {
                key: item.id,
                label: item.name,
                icon: SubMenuContentIcon[getColumnTypeKey(item.inputMode, item.type)],
                onClick: function () { return addSchemaToFormLayout(item); },
            };
        });
        var items = [
            {
                key: 'FormDetails',
                type: 'group',
                label: _jsx("div", { className: "font-semibold text-gray-300", children: "Form Details" }),
                children: [
                    {
                        key: 'AddTitle',
                        label: 'Add Title',
                        icon: _jsx(TextOutlinedIcon, {}),
                        onClick: addDescriptionToFormLayout,
                    },
                    {
                        key: 'AddImage',
                        label: 'Add Image',
                        icon: _jsx(FileOutlinedIcon, {}),
                        onClick: addImageToFormLayout,
                    },
                ],
            },
            {
                key: 'AddQuestion',
                type: 'group',
                label: _jsx("div", { className: "font-semibold text-gray-300", children: "Add Question" }),
                children: questionGroupOptions,
            },
        ];
        return items;
    }, [
        addDescriptionToFormLayout,
        addImageToFormLayout,
        addSchemaToFormLayout,
        availableSchemasOfSourceCollection,
        schemaIsAdded,
    ]);
    return (_jsx(ZiraDropdown, { menu: { items: items }, trigger: ['click'], children: _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "AddToFormButton", children: "Add Question/Block" }) }));
};
export default React.memo(AddQuestionOrBlockToForm);
