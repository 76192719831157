var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var VisibleEyeIconSvg = function () { return (_jsxs("svg", { width: "14", height: "8", viewBox: "0 0 14 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.9494 4C3.23772 5.67959 5.05146 6.66667 6.99995 6.66667C8.94843 6.66667 10.7622 5.67959 12.0505 4C10.7622 2.32041 8.94843 1.33333 6.99995 1.33333C5.05146 1.33333 3.23772 2.32041 1.9494 4ZM0.55547 3.65527C0.414778 3.86368 0.414778 4.13632 0.55547 4.34473C2.05844 6.57109 4.38654 8 6.99995 8C9.61336 8 11.9415 6.57109 13.4444 4.34473C13.5851 4.13632 13.5851 3.86368 13.4444 3.65527C11.9415 1.42891 9.61336 0 6.99995 0C4.38654 0 2.05844 1.42891 0.55547 3.65527Z", fill: "#00A76F" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 6C8.10457 6 9 5.10457 9 4C9 2.89543 8.10457 2 7 2C5.89543 2 5 2.89543 5 4C5 5.10457 5.89543 6 7 6Z", fill: "#00A76F" })] })); };
export var VisibleEyeIcon = function (props) { return (_jsx(Icon, __assign({ component: VisibleEyeIconSvg }, props))); };
