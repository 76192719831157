var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TaskRestoreIconSvg = function () { return (_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", children: [_jsx("path", { d: "M9.90543 8.48267L10.8483 7.53987L8.01983 4.71143L5.19141 7.53987L6.13422 8.48267L7.35316 7.26373V12.2885H8.68649V7.26373L9.90543 8.48267Z", fill: "#00A76F" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.667969 13.1665C0.667969 14.6392 1.86188 15.8332 3.33464 15.8332H12.668C14.1407 15.8332 15.3346 14.6392 15.3346 13.1665V3.83317C15.3346 2.36041 14.1407 1.1665 12.668 1.1665H3.33464C1.86188 1.1665 0.667969 2.36041 0.667969 3.83317V13.1665ZM3.33464 14.4998H12.668C13.4044 14.4998 14.0013 13.9029 14.0013 13.1665V3.83317C14.0013 3.09679 13.4044 2.49984 12.668 2.49984H3.33464C2.59826 2.49984 2.0013 3.09679 2.0013 3.83317V13.1665C2.0013 13.9029 2.59826 14.4998 3.33464 14.4998Z", fill: "#00A76F" })] })); };
export var TaskRestoreIcon = function (props) { return (_jsx(Icon, __assign({ component: TaskRestoreIconSvg }, props))); };
