import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useLocation } from 'react-router-dom';
import RecipeModalWrapper from './RecipeModalWrapper';
var RecipeModalViewContainer = function () {
    var location = useLocation();
    var recipeId = new URLSearchParams(location.search).get('recipe');
    return _jsx(_Fragment, { children: recipeId && _jsx(RecipeModalWrapper, { recipeId: recipeId }) });
};
export default React.memo(RecipeModalViewContainer);
