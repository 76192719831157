import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAccountApi, useSetting, useTasksApi } from 'src/hooks/api';
import { Image } from 'antd';
import { TeamBridgeTransparentLogo } from 'src/utils/icons/TeamBridgeTransparentLogo';
import { MobilePreviewNotification } from 'src/utils/icons/TaskIcons/MobilePreviewNotificationIcon';
import { RightArrowIcon } from 'src/utils/icons/DashboardIcons/RightArrowIcon';
import { MobilePreviewFieldPlaceholder } from 'src/utils/icons/TaskIcons/MobilePreviewFieldPlaceholder';
import { MobilePreviewImagePlaceholder } from 'src/utils/icons/TaskIcons/MobilePreviewImagePlaceholder';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
var TaskRuleMobilePreview = function (_a) {
    var _b;
    var ruleTasks = _a.tasks;
    var useGetBusinessDetails = useSetting().useGetBusinessDetails;
    var useGetCurrentUser = useAccountApi().useGetCurrentUser;
    var useGetTasksOverview = useTasksApi().useGetTasksOverview;
    var businessDetails = useGetBusinessDetails().data;
    var user = useGetCurrentUser().data;
    var taskTemplateList = useGetTasksOverview().data;
    return (_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", {}), (businessDetails === null || businessDetails === void 0 ? void 0 : businessDetails.mobileLogo) ? (_jsx(Image, { src: businessDetails.mobileLogo, style: { height: '63.5px' } })) : (_jsx(TeamBridgeTransparentLogo, { style: { width: '54.4px', height: '63.5' } })), _jsx(MobilePreviewNotification, {})] }), _jsx("div", { className: "border-b border-b-gray-100 px-5 py-3 mb-4" }), _jsxs("div", { className: "flex", children: [_jsx("span", { className: "text-xl mr-1", children: "Good morning," }), _jsx("span", { className: "text-xl font-semibold", children: ((_b = user === null || user === void 0 ? void 0 : user.fullName) === null || _b === void 0 ? void 0 : _b.split(' ')[0]) || 'User' })] }), _jsx("div", { className: "mt-5", children: ruleTasks.map(function (_a, idx) {
                    var _b;
                    var id = _a.id;
                    var associatedTaskTemplate = (_b = taskTemplateList === null || taskTemplateList === void 0 ? void 0 : taskTemplateList.tasks) === null || _b === void 0 ? void 0 : _b.find(function (task) { return task.id === id; });
                    if (!associatedTaskTemplate)
                        return null;
                    return (_jsxs("div", { className: "flex border border-gray-100 justify-between mb-4 p-3 rounded-lg", children: [_jsxs("div", { className: "flex", children: [_jsx(CustomIconInline, { iconUrl: associatedTaskTemplate.iconInfo.content, iconSize: 16, backgroundColor: associatedTaskTemplate.iconInfo.backgroundColor, className: "!min-h-[32px] !min-w-[32px] mr-[12px] self-start MiniTaskIcon", radius: 9999 }), _jsxs("div", { className: "ml-2 flex flex-col text-xs", children: [_jsx("div", { className: "mb-0.5 font-semibold", children: associatedTaskTemplate.name }), _jsx("div", { children: associatedTaskTemplate.displayId })] })] }), _jsx(RightArrowIcon, {})] }, idx));
                }) }), _jsx(MobilePreviewFieldPlaceholder, { className: "mb-4" }), _jsx(MobilePreviewImagePlaceholder, {})] }));
};
export default React.memo(TaskRuleMobilePreview);
