import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { usePageApi } from 'src/hooks/api';
import { collectionTableSchemasState, collectionTableState, quickCreateModalCollectionInfoState, } from 'src/state';
import QuickCreateModal from './QuickCreateModal';
import './QuickCreateModal.scss';
var QuickCreateModalWrapper = function () {
    var _a = useRecoilValue(quickCreateModalCollectionInfoState), collectionId = _a.collectionId, collectionViewId = _a.collectionViewId;
    var usePreSaveNewRecord = usePageApi().usePreSaveNewRecord;
    var data = usePreSaveNewRecord({
        body: {
            targetCollectionId: collectionId,
            collectionViewId: collectionViewId,
        },
        options: {
            enabled: !!collectionId,
            cacheTime: 0,
        },
    }).data;
    var setQuickCreateCollection = useSetRecoilState(collectionTableState({
        type: CollectionTableType.QUICK_CREATE_MODAL,
        collectionId: collectionId,
    }));
    var setCollectionTableSchemas = useSetRecoilState(collectionTableSchemasState(collectionId));
    useEffect(function () {
        if (data && collectionId) {
            setQuickCreateCollection(data.mainRecord);
            setCollectionTableSchemas(data.mainRecord.columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (_jsx("div", { className: "QuickCreateModalWrapper", children: _jsx(QuickCreateModal, {}) }));
};
export default React.memo(QuickCreateModalWrapper);
