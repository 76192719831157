var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MobileJobPseudoSchemaMap } from 'src/components/Settings/Permission/Constants';
import { CustomCardFieldType, } from 'src/services/Interfaces';
export var populateFieldSchema = function (f, schemaOptions) {
    if ((f === null || f === void 0 ? void 0 : f.type) === CustomCardFieldType.SCHEMA) {
        return __assign(__assign({}, f), { schema: schemaOptions.find(function (s) { return s.id === f.schemaId; }) });
    }
    else if ((f === null || f === void 0 ? void 0 : f.type) === CustomCardFieldType.PSEUDO_SCHEMA) {
        return __assign(__assign({}, f), { schema: MobileJobPseudoSchemaMap[f.pseudoPath] });
    }
    else if ((f === null || f === void 0 ? void 0 : f.type) === CustomCardFieldType.SECONDARY_SCHEMA) {
        // Note: this is not implemented/supported yet
        return f;
    }
    return f;
};
