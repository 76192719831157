import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'antd';
import './TimeZoneSelectModal.scss';
import { scheduleTimeZoneSelector } from 'src/state/selectors/schedule';
import { useScheduleComponent } from 'src/hooks/component';
import { useRecoilValue } from 'recoil';
import TimeZoneSelector from '../TimeZoneSelector/TimeZoneSelector';
import ZiraModalFooter from '../../ui/ZiraModal/ZiraModalFooter';
var TimeZoneSelectModal = function (props) {
    var isTimeZoneModalVisible = props.isTimeZoneModalVisible, onTimeZoneModalVisibleChange = props.onTimeZoneModalVisibleChange, tableId = props.tableId, type = props.type;
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(tableId));
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var _a = useState(timeZone), newTimeZone = _a[0], setNewTimeZone = _a[1];
    useEffect(function () {
        setNewTimeZone(timeZone);
    }, [timeZone]);
    var onCancelClick = useCallback(function () {
        onTimeZoneModalVisibleChange(false);
    }, [onTimeZoneModalVisibleChange]);
    var onApplyClick = useCallback(function () {
        applyScheduleDisplaySettings(tableId, { timezone: newTimeZone }, type);
        onTimeZoneModalVisibleChange(false);
    }, [applyScheduleDisplaySettings, tableId, newTimeZone, type, onTimeZoneModalVisibleChange]);
    return (_jsxs(Modal, { open: isTimeZoneModalVisible, closable: false, centered: true, width: 560, footer: _jsx(ZiraModalFooter, { onCancel: onCancelClick, onSave: onApplyClick, saveBtnText: "Apply" }), className: "TimeZoneModal", children: [_jsx("div", { className: "TimeZoneModalTitle", children: "Select Time Zone" }), _jsx("div", { className: "TimeZoneModalDescription", children: "Shifts shown on this schedule block will show in the selected time zone. Existing shift will not be affected." }), _jsx(TimeZoneSelector, { timezoneValue: newTimeZone, timezoneOnChange: function (value) {
                    setNewTimeZone(value);
                } })] }));
};
export default React.memo(TimeZoneSelectModal);
