var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var UserProfileIconSvg = function () { return (_jsxs("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M0 8C0 3.58172 3.58172 0 8 0L14 0C18.4183 0 22 3.58172 22 8V14C22 18.4183 18.4183 22 14 22H8C3.58172 22 0 18.4183 0 14L0 8Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13 12.3333H9C7.52724 12.3333 6.33333 13.5272 6.33333 15V16.3333H15.6667V15C15.6667 13.5272 14.4728 12.3333 13 12.3333ZM9 11C6.79086 11 5 12.7909 5 15V17.3333C5 17.5174 5.14924 17.6667 5.33333 17.6667H16.6667C16.8508 17.6667 17 17.5174 17 17.3333V15C17 12.7909 15.2091 11 13 11H9Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.0002 8.33331C11.7365 8.33331 12.3335 7.73636 12.3335 6.99998C12.3335 6.2636 11.7365 5.66665 11.0002 5.66665C10.2638 5.66665 9.66683 6.2636 9.66683 6.99998C9.66683 7.73636 10.2638 8.33331 11.0002 8.33331ZM11.0002 9.66665C12.4729 9.66665 13.6668 8.47274 13.6668 6.99998C13.6668 5.52722 12.4729 4.33331 11.0002 4.33331C9.5274 4.33331 8.3335 5.52722 8.3335 6.99998C8.3335 8.47274 9.5274 9.66665 11.0002 9.66665Z", fill: "black" })] })); };
export var UserProfileIcon = function (props) { return (_jsx(Icon, __assign({ component: UserProfileIconSvg }, props))); };
