var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEqual } from 'lodash';
import { DefaultValue, selector, selectorFamily } from 'recoil';
import { MessageSenderType, } from 'src/components/PageView/ChatPage/interface';
import { broadcastRecipientsFromParticipants, threadIsBroadcast, threadParticipantsToBroadcastRecipients, } from 'src/components/PageView/ChatPage/utils';
import { arrayEqualUnordered } from 'src/utils/Tools/LodashTool';
import { currentUserState } from '../atoms';
import { threadChatMessagesState, selectedThreadState, threadSummaryListState, participantsInNewThread, } from '../atoms/chatState';
import { currentUserRecordIdSelector } from './withAccountDetailState';
var selectedThreadSummarySelector = selector({
    key: 'selectedThreadSummarySelector',
    get: function (_a) {
        var get = _a.get;
        var selectedThread = get(selectedThreadState);
        var threadSummaryList = get(threadSummaryListState);
        return threadSummaryList.find(function (thread) { return thread.id === selectedThread; });
    },
});
var threadLatestMessageState = selectorFamily({
    key: 'threadLatestMessageState',
    get: function (threadId) {
        return function (_a) {
            var get = _a.get;
            var threadSummaryList = get(threadSummaryListState);
            if (!threadSummaryList) {
                return undefined;
            }
            var thread = threadSummaryList.find(function (_a) {
                var id = _a.id;
                return id === threadId;
            });
            if (!thread) {
                return undefined;
            }
            return thread.lastMessage;
        };
    },
    set: function (threadId) {
        return function (_a, newMessage) {
            var set = _a.set;
            set(threadLatestMessageState(threadId), newMessage);
        };
    },
});
var allTargetsSelector = selector({
    key: 'allTargetsSelector',
    get: function (_a) {
        var get = _a.get;
        var threadSummaryList = get(threadSummaryListState);
        var currentUser = get(currentUserState);
        var targets = new Map();
        threadSummaryList.forEach(function (thread) {
            var target = thread.participants
                .filter(function (participant) {
                return (participant.participantType === MessageSenderType.USER &&
                    participant.participantRid !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.recordId)) ||
                    participant.participantType !== MessageSenderType.DEPARTMENT;
            })
                .map(function (participant) {
                return {
                    id: participant.participantRid,
                    type: participant.participantType,
                    name: participant.name,
                };
            });
            for (var _i = 0, _a = Array.from(targets.entries()); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (arrayEqualUnordered(key, target, isEqual)) {
                    value.push(thread.id);
                    return;
                }
            }
            targets.set(target, [thread.id]);
        });
        return targets;
    },
});
// This selector is used to determine whether the user is creating a new thread
var isCreatingThreadSelector = selector({
    key: 'isCreatingThreadSelector',
    get: function (_a) {
        var get = _a.get;
        var participants = get(participantsInNewThread);
        if (!participants) {
            return undefined;
        }
        return participants.type;
    },
    set: function (_a, newValue) {
        var set = _a.set;
        if (newValue === undefined || newValue instanceof DefaultValue) {
            set(participantsInNewThread, undefined);
            return;
        }
        set(participantsInNewThread, { type: newValue, participants: [] });
    },
});
var selectedBroadcastRecipientsSelector = selector({
    key: 'selectedBroadcastRecipientsSelector',
    get: function (_a) {
        var get = _a.get;
        var selectedThreadSummary = get(selectedThreadSummarySelector);
        if (selectedThreadSummary && threadIsBroadcast(selectedThreadSummary)) {
            var participants = selectedThreadSummary.participants;
            return broadcastRecipientsFromParticipants(participants);
        }
    },
});
var broadcastsHaveSameRecipientsAsSelectedThreadSelector = selector({
    key: 'broadcastsHaveSameRecipientsAsSelectedThreadSelector',
    get: function (_a) {
        var get = _a.get;
        var selectedBroadcastRecipients = get(selectedBroadcastRecipientsSelector);
        var threadSummaryList = get(threadSummaryListState);
        if (selectedBroadcastRecipients) {
            return threadSummaryList.filter(function (thread) {
                if (!threadIsBroadcast(thread)) {
                    return false;
                }
                var threadRecipients = threadParticipantsToBroadcastRecipients(thread.participants);
                return selectedBroadcastRecipients.every(function (selectedRecipient) {
                    return threadRecipients.some(function (threadRecipient) {
                        return threadRecipient.id === selectedRecipient.id &&
                            threadRecipient.type === selectedRecipient.type;
                    }) && threadRecipients.length === selectedBroadcastRecipients.length;
                });
            });
        }
    },
});
var selectedMessagesSelector = selector({
    key: 'selectedMessagesSelector',
    get: function (_a) {
        var get = _a.get;
        var broadcastsHaveSameRecipientsAsSelectedThread = get(broadcastsHaveSameRecipientsAsSelectedThreadSelector);
        if (broadcastsHaveSameRecipientsAsSelectedThread) {
            return broadcastsHaveSameRecipientsAsSelectedThread
                .flatMap(function (thread) {
                return get(threadChatMessagesState(thread.id));
            })
                .sort(function (a, b) { return Date.parse(a.createdAt) - Date.parse(b.createdAt); });
        }
        var selectedThread = get(selectedThreadState);
        if (!selectedThread)
            return [];
        var messages = get(threadChatMessagesState(selectedThread));
        return __spreadArray([], messages, true).sort(function (a, b) { return a.seqNum - b.seqNum; });
    },
});
var hasUnreadMessageSelector = selector({
    key: 'hasUnreadMessageSelector',
    get: function (_a) {
        var _b;
        var get = _a.get;
        var currentUserRId = get(currentUserRecordIdSelector);
        var threadSummaryList = get(threadSummaryListState);
        var _loop_1 = function (thread) {
            var lastMessage = thread.lastMessage;
            var lastSeenSeqNum = (_b = threadSummaryList.find(function (item) { return item.id === thread.id; })) === null || _b === void 0 ? void 0 : _b.lastSeenSeqNum;
            if (lastMessage &&
                lastSeenSeqNum !== undefined &&
                lastMessage.seqNum > lastSeenSeqNum &&
                lastMessage.senderDetail.id !== currentUserRId) {
                return { value: true };
            }
        };
        for (var _i = 0, threadSummaryList_1 = threadSummaryList; _i < threadSummaryList_1.length; _i++) {
            var thread = threadSummaryList_1[_i];
            var state_1 = _loop_1(thread);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return false;
    },
});
export { threadLatestMessageState, selectedMessagesSelector, selectedThreadSummarySelector, allTargetsSelector, isCreatingThreadSelector, selectedBroadcastRecipientsSelector, broadcastsHaveSameRecipientsAsSelectedThreadSelector, hasUnreadMessageSelector, };
