var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ArrowDownOutlinedSvg = function () { return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M11.0367 18.2081L4.0974 11.2688C3.90214 11.0735 3.58556 11.0735 3.3903 11.2688L2.68319 11.9759C2.48793 12.1711 2.48793 12.4877 2.68319 12.683L11.6832 21.683C11.8785 21.8782 12.195 21.8782 12.3903 21.683L21.3903 12.683C21.5856 12.4877 21.5856 12.1711 21.3903 11.9759L20.6832 11.2688C20.4879 11.0735 20.1713 11.0735 19.9761 11.2688L13.0367 18.2081V2.46355C13.0367 2.18741 12.8129 1.96355 12.5367 1.96355H11.5367C11.2606 1.96355 11.0367 2.1874 11.0367 2.46355V18.2081Z", fill: "#A7A8AC" }) })); };
export var ArrowDownOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ style: { transform: 'scale(0.7)' }, component: ArrowDownOutlinedSvg }, props))); };
