import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { automationTags } from 'src/utils/Automation';
import { useSetRecoilState } from 'recoil';
import { automationPageTabKeyState, isAutomationPageModalVisibleState, } from 'src/state/atoms/automationModalState';
var AutomationTemplateDisplayCard = function (_a) {
    var automation = _a.automation;
    var setAutomationModalVisible = useSetRecoilState(isAutomationPageModalVisibleState);
    var setAutomationPageTabKey = useSetRecoilState(automationPageTabKeyState);
    return (_jsxs("div", { className: "mb-4 cursor-pointer rounded-md px-5 py-3 shadow-card", onClick: function () {
            setAutomationModalVisible(true);
            setAutomationPageTabKey('Templates');
        }, children: [_jsx("div", { className: "AutomationCardContent", children: _jsx("div", { className: "relative mb-3 flex w-full items-center", children: _jsxs("span", { className: "AutomationTags justify-start", children: [automationTags(automation.triggerCategories), !!automation.actionCategories.length && (_jsxs(_Fragment, { children: [_jsx(ArrowRightOutlined, { className: "mr-2" }), automationTags(automation.actionCategories)] }))] }) }) }), _jsx("div", { className: "text-sm font-medium", children: automation.description })] }));
};
export default React.memo(AutomationTemplateDisplayCard);
