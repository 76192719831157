import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Select } from 'antd';
var Option = Select.Option, OptGroup = Select.OptGroup;
import TextArea from 'antd/lib/input/TextArea';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue } from 'recoil';
import { MAXIMA_SERVICE_AUTOMATION_QUALIFIED_USERS } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { CollectionType } from '../../../shared';
import { AutomationFilterType, MessageAttachmentType, TargetType, } from '../Interface';
import { schemaTypeIsValidForAutomationSendMessage } from '../util';
import Attachments from './Attachments';
import AutomationFilteredTarget from './AutomationFilteredTarget';
import './MessageCard.scss';
import Personalizations from './Personalizations';
var SendInAppForm = function (props) {
    var _a, _b;
    var currentAction = props.currentAction, detail = props.detail, updateAction = props.updateAction, filterOptions = props.filterOptions, baseCollectionId = props.baseCollectionId, allCollections = props.allCollections, roleSchemaId = props.roleSchemaId, locationSchemaId = props.locationSchemaId;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var targetType = detail.targetType, targetDetail = detail.targetDetail, content = detail.content, attachments = detail.attachments;
    var _c = useState(currentAction), newActionObj = _c[0], setNewActionObj = _c[1];
    var newActionObjRef = useRef(newActionObj);
    var isAllQualifiedUsersOn = useRecoilValue(featureEnableStatusState(MAXIMA_SERVICE_AUTOMATION_QUALIFIED_USERS));
    var _d = useState(targetType), selectedTargetType = _d[0], setSelectedTargetType = _d[1];
    var _e = useState(targetDetail.schemaId), targetSchemaId = _e[0], setTargetSchemaId = _e[1];
    var _f = useState((_a = targetDetail === null || targetDetail === void 0 ? void 0 : targetDetail.secondarySchemaId) !== null && _a !== void 0 ? _a : null), targetSecondarySchemaId = _f[0], setTargetSecondarySchemaId = _f[1];
    var _g = useState(content), messageContent = _g[0], setMessageContent = _g[1];
    var _h = useState((_b = targetDetail.filters) !== null && _b !== void 0 ? _b : {
        locations: undefined,
        roles: undefined,
    }), filters = _h[0], setFilters = _h[1];
    var initialBaseCollectionId = useState(baseCollectionId)[0];
    // Reset automation determined target on base collection change
    useEffect(function () {
        if (initialBaseCollectionId &&
            initialBaseCollectionId !== baseCollectionId &&
            selectedTargetType === TargetType.AUTOMATION_DETERMINED) {
            setTargetSchemaId(null);
            setTargetSecondarySchemaId(null);
            var tempActionObj = cloneDeep(newActionObjRef.current);
            var tempActionDetail = tempActionObj.detail;
            tempActionDetail.targetDetail = {
                type: TargetType.AUTOMATION_DETERMINED,
                schemaId: null,
            };
            tempActionObj.detail = tempActionDetail;
            setNewActionObj(tempActionObj);
            updateAction(tempActionObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseCollectionId, initialBaseCollectionId]);
    var formTargetDetailObj = useCallback(function (newTargetSchemaId, newTargetSecondarySchemaId) {
        switch (selectedTargetType) {
            case TargetType.AUTOMATION_DETERMINED: {
                return {
                    type: TargetType.AUTOMATION_DETERMINED,
                    schemaId: newTargetSchemaId != '' ? newTargetSchemaId : null,
                    secondarySchemaId: newTargetSecondarySchemaId,
                };
            }
            case TargetType.FILTERED_SET: {
                return {
                    type: TargetType.AUTOMATION_DETERMINED,
                    schemaId: newTargetSchemaId,
                    filters: filters,
                };
            }
            case TargetType.QUALIFIED_USERS: {
                return {
                    collectionId: baseCollectionId,
                };
            }
        }
    }, [baseCollectionId, filters, selectedTargetType]);
    var onTargetTypeChange = useCallback(function (event) {
        setSelectedTargetType(event);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.targetType = event;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onFiltersChange = useCallback(function (newFilters) {
        setFilters(newFilters);
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        var tempTargetDetail = tempActionDetail.targetDetail;
        tempTargetDetail.filters = newFilters;
        tempActionDetail.targetDetail = tempTargetDetail;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var onContentChange = useCallback(function (event) {
        setMessageContent(event.target.value);
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.content = event.target.value;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var updateAttachments = useCallback(function (attachments) {
        // update action detail with form template id
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.attachments = attachments.map(function (attachmentData) {
            return {
                type: MessageAttachmentType.FORM,
                formTemplateId: attachmentData,
            };
        });
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [newActionObj, updateAction]);
    var updateContent = useCallback(function (personalization) {
        var newMessageContent = messageContent + personalization.label;
        // add this entry to overall personalizations object and update action object
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.content = newMessageContent;
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
        // also update content with label
        setMessageContent(newMessageContent);
    }, [messageContent, newActionObj, updateAction]);
    var onOptionSelection = useCallback(function (val, elem) {
        if (elem.parentSchemaId) {
            setTargetSchemaId(elem.parentSchemaId);
            setTargetSecondarySchemaId(elem.optionId);
        }
        else if (elem.optionId) {
            setTargetSchemaId(elem.optionId);
        }
        else {
            setTargetSchemaId(null);
            setTargetSecondarySchemaId(null);
        }
        // re-assign action detail to new object just created
        var tempActionObj = cloneDeep(newActionObj);
        var tempActionDetail = tempActionObj.detail;
        tempActionDetail.targetDetail = formTargetDetailObj(elem.parentSchemaId ? elem.parentSchemaId : elem.optionId, elem.parentSchemaId ? elem.optionId : null);
        tempActionObj.detail = tempActionDetail;
        setNewActionObj(tempActionObj);
        updateAction(tempActionObj);
    }, [formTargetDetailObj, newActionObj, updateAction]);
    var baseCollectionType = useMemo(function () {
        var _a;
        return (_a = allCollections === null || allCollections === void 0 ? void 0 : allCollections.find(function (collection) { return collection.id == baseCollectionId; })) === null || _a === void 0 ? void 0 : _a.type;
    }, [allCollections, baseCollectionId]);
    return (_jsxs("div", { className: "messageCard", children: [_jsxs("div", { className: "targetSelectorRow", children: [_jsx("p", { className: "inputLabel", children: "Send To" }), _jsxs(Select, { className: "targetSelector", defaultValue: TargetType.AUTOMATION_DETERMINED, value: selectedTargetType, onChange: function (event) { return onTargetTypeChange(event); }, children: [baseCollectionId && (_jsx(Option, { value: TargetType.AUTOMATION_DETERMINED, children: "Automation Workflow" })), _jsx(Option, { value: TargetType.FILTERED_SET, children: "Specific Group of Users" }), ((baseCollectionId && baseCollectionType == CollectionType.SHIFT) ||
                                isAllQualifiedUsersOn) && (_jsx(Option, { value: TargetType.QUALIFIED_USERS, children: "All Qualified Users" }))] })] }), !!filterOptions.length && selectedTargetType === TargetType.AUTOMATION_DETERMINED && (_jsxs(Select, { className: "attributeSelector", placeholder: "Choose contact attribute", value: targetSecondarySchemaId
                    ? "".concat(targetSchemaId).concat(targetSecondarySchemaId)
                    : (targetSchemaId !== null && targetSchemaId !== void 0 ? targetSchemaId : ''), onSelect: onOptionSelection, children: [(baseCollectionType == CollectionType.USER ||
                        baseCollectionType == CollectionType.CONTACT) && (_jsx(OptGroup, { label: "Default", children: _jsx(Option, { value: '', children: baseCollectionType == CollectionType.USER ? 'User' : 'Contact' }, "default") }, "defaultGroup")), filterOptions
                        .filter(function (group) { return group.type === AutomationFilterType.RECORD; })
                        .map(function (group) {
                        var showGroup = group.schemas.some(function (option) {
                            return schemaTypeIsValidForAutomationSendMessage(option.type, true);
                        });
                        return (_jsx(_Fragment, { children: showGroup && (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option) {
                                    var _a;
                                    if (schemaTypeIsValidForAutomationSendMessage(option.type, true)) {
                                        var label = group.parentSchemaId !== null
                                            ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                                            : "".concat(option.name);
                                        return (_jsx(Option, { value: "".concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), optionId: option.id, label: label, parentSchemaId: group.parentSchemaId, children: label }, uuidv4()));
                                    }
                                }) }, uuidv4())) }));
                    })] })), selectedTargetType === TargetType.FILTERED_SET && (_jsx(AutomationFilteredTarget, { filters: filters, onChange: onFiltersChange, roleSchemaId: roleSchemaId, locationSchemaId: locationSchemaId })), _jsx("p", { className: "inputLabel message", children: "Message" }), _jsx(TextArea, { rows: 4, allowClear: true, value: messageContent, onChange: function (event) { return setMessageContent(event.target.value); }, onBlur: function (event) { return onContentChange(event); } }), baseCollectionId && (_jsx("div", { className: "personalizationRow", children: _jsx(Personalizations, { filterOptions: filterOptions, updatePersonalizations: function (personalizations) {
                        return updateContent(personalizations);
                    } }) })), _jsx("div", { className: "personalizationRow", children: _jsx(Attachments, { attachments: attachments, updateAttachments: function (attachments) { return updateAttachments(attachments); } }) })] }));
};
export default React.memo(SendInAppForm);
