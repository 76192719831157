var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AutomationActionIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: "25", height: "25", viewBox: "0 0 25 25", fill: "none", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.1562 5.39993L5.52571 9.6101L10.5779 12.9782L18.1562 5.39993ZM11.9353 14.4493L15.0763 19.1607L19.0024 7.38214L11.9353 14.4493ZM21.1165 2.305C21.8982 2.04441 22.642 2.78815 22.3814 3.56991L16.3271 21.7328C16.0639 22.5223 15.0079 22.6637 14.5463 21.9713L9.92476 15.039C9.85153 14.9291 9.75727 14.8348 9.64741 14.7616L2.71506 10.14C2.02268 9.67845 2.16409 8.62246 2.95354 8.35931L21.1165 2.305Z", fill: "white" }) })); };
export var AutomationActionIcon = function (props) { return (_jsx(Icon, __assign({ component: AutomationActionIconSvg }, props))); };
