var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { scheduleCardShouldDelayRenderState, scheduleTimeOffSelector } from 'src/state';
import ShiftCard from './ShiftCard/ShiftCard';
import TimeOffCard from './TimeOffCard';
var ScheduleCardWrapper = function (props) {
    var blockId = props.blockId, recordId = props.recordId, index = props.index, height = props.height, disableDelayRender = props.disableDelayRender;
    var _a = useState(false), isHovering = _a[0], setIsHovering = _a[1];
    var _b = useState(false), isOverlayVisible = _b[0], setIsOverlayVisible = _b[1];
    var _c = useRecoilState(scheduleCardShouldDelayRenderState({ blockId: blockId, recordId: recordId })), isDelayLoading = _c[0], setIsDelayLoading = _c[1];
    var delayTimeout = useRef();
    //! Delay loading for better performance
    useEffect(function () {
        if (isDelayLoading) {
            delayTimeout.current = setTimeout(function () {
                setIsDelayLoading(false);
            }, (index + 1) * 150);
        }
        else {
            setIsDelayLoading(false);
            clearInterval(delayTimeout.current);
        }
        return function () {
            setIsDelayLoading(false);
            clearInterval(delayTimeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDelayLoading]);
    var timeOff = useRecoilValue(scheduleTimeOffSelector({ blockId: blockId, recordId: recordId }));
    if (isDelayLoading && !disableDelayRender) {
        return (_jsx(Skeleton.Button, { className: "w-full", active: true, shape: "square", style: {
                height: height,
                width: 'inherit',
                borderRadius: '8px',
            } }));
    }
    var extraProps = {
        isHovering: isHovering,
        isOverlayVisible: isOverlayVisible,
        setIsHovering: setIsHovering,
        setIsOverlayVisible: setIsOverlayVisible,
    };
    if (timeOff) {
        return _jsx(TimeOffCard, __assign({}, props, extraProps));
    }
    return _jsx(ShiftCard, __assign({}, props, extraProps));
};
export default React.memo(ScheduleCardWrapper);
