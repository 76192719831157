var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import ShiftRecommendationsModal from './ShiftRecommendationsModal';
import { shiftRecommendationsModalPropsState } from './atom';
var ShiftRecommendationsModalViewContainer = function () {
    var props = useRecoilValue(shiftRecommendationsModalPropsState);
    if (!props)
        return null;
    return _jsx(ShiftRecommendationsModal, __assign({}, props));
};
export default React.memo(ShiftRecommendationsModalViewContainer);
