var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var EmailSvg = function () { return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.9997 12.0791L3.58531 6L2.41406 7.62117L11.1213 13.9119C11.6456 14.2907 12.3538 14.2907 12.8781 13.9119L21.5846 7.6217L20.4133 6.00053L11.9997 12.0791Z", fill: "#FFC047" }), _jsx("mask", { id: "path-2-inside-1_7444_65672", fill: "white", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 7V17H20V7H4ZM2.5 5C2.22386 5 2 5.22386 2 5.5V18.5C2 18.7761 2.22386 19 2.5 19H21.5C21.7761 19 22 18.7761 22 18.5V5.5C22 5.22386 21.7761 5 21.5 5H2.5Z" }) }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4 7V17H20V7H4ZM2.5 5C2.22386 5 2 5.22386 2 5.5V18.5C2 18.7761 2.22386 19 2.5 19H21.5C21.7761 19 22 18.7761 22 18.5V5.5C22 5.22386 21.7761 5 21.5 5H2.5Z", fill: "#FFC047" }), _jsx("path", { d: "M4 17H2V19H4V17ZM4 7V5H2V7H4ZM20 17V19H22V17H20ZM20 7H22V5H20V7ZM6 17V7H2V17H6ZM20 15H4V19H20V15ZM18 7V17H22V7H18ZM4 9H20V5H4V9ZM4 5.5C4 6.32843 3.32843 7 2.5 7V3C1.11929 3 0 4.11929 0 5.5H4ZM4 18.5V5.5H0V18.5H4ZM2.5 17C3.32843 17 4 17.6716 4 18.5H0C0 19.8807 1.11928 21 2.5 21V17ZM21.5 17H2.5V21H21.5V17ZM20 18.5C20 17.6716 20.6716 17 21.5 17V21C22.8807 21 24 19.8807 24 18.5H20ZM20 5.5V18.5H24V5.5H20ZM21.5 7C20.6716 7 20 6.32843 20 5.5H24C24 4.11929 22.8807 3 21.5 3V7ZM2.5 7H21.5V3H2.5V7Z", fill: "#FFC047", mask: "url(#path-2-inside-1_7444_65672)" })] })); };
export var EmailIcon = function (props) { return (_jsx(Icon, __assign({ component: EmailSvg }, props))); };
