var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { cloneDeep, isArray } from 'lodash';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import DetailsTabImage from 'src/utils/icons/images/DetailModal/DetailsTabImage.svg';
import TimeOffTabImage from 'src/utils/icons/images/DetailModal/TimeOffTabImage.svg';
import ShiftTrackingTabImage from 'src/utils/icons/images/DetailModal/ShiftTrackingTabImage.svg';
import LocationsTabImage from 'src/utils/icons/images/DetailModal/LocationsTabImage.svg';
import UsersTabImage from 'src/utils/icons/images/DetailModal/UsersTabImage.svg';
import ShiftsTabImage from 'src/utils/icons/images/DetailModal/ShiftsTabImage.svg';
import BreaksTabImage from 'src/utils/icons/images/DetailModal/BreaksTabImage.svg';
import DocumentsTabImage from 'src/utils/icons/images/DetailModal/DocumentsTabImage.svg';
import CustomTabImage from 'src/utils/icons/images/DetailModal/CustomTabImage.svg';
import { BUTTON_BORDER_COLOR_OPTIONS, BUTTON_COLOR_OPTIONS, } from 'src/components/SchemaConfigModal/ButtonSchemaConfig/Constant';
import { CellInputMode, CollectionType, isColorNameStyle, isStandardNativeType, ItemRelationTypeSet, NativePath, NativeTextInputSet, RecordSchemaType, specialDefineEmptyClauseTypes, StyleColorMap, ValueDataType, } from '../shared';
import { DefaultCollectionCell, } from '../components/CenterPanel/Interface';
export var getKeyForValueDataType = function (schemaType) {
    switch (schemaType) {
        case 'title': {
            return 'title';
        }
        case 'text': {
            return 'text';
        }
        case 'number': {
            return 'number';
        }
        case 'datetime': {
            return 'datetime';
        }
        case 'currency': {
            return 'amount';
        }
        case 'signature': {
            return 'url';
        }
        // case 'phone': {
        //     valueObj.phone = val
        //     break
        // }
        // case 'currency': {
        //     valueObj.amount = val
        //     break
        // }
        // case 'shift': {
        //     valueObj.shiftId = val
        //     break
        // }
    }
    return 'text';
};
export var getNativePathKey = function (nativeType) {
    var typeString = getNativeType(nativeType);
    if (isStandardNativeType(typeString)) {
        return RecordSchemaType.CONTENT_ID;
    }
    else if (typeString === ValueDataType.NATIVE_SPECIAL) {
        //TODO: more special cases to handle, right now only Status
        return RecordSchemaType.TEXT;
    }
    else if (NativeTextInputSet.has(getNativeType(nativeType))) {
        if (typeString === ValueDataType.EMAIL ||
            typeString === ValueDataType.PHONE ||
            typeString === ValueDataType.NAME) {
            return getNativeType(nativeType);
        }
        if (typeString === ValueDataType.CURRENCY || typeString === ValueDataType.NUMBER) {
            return RecordSchemaType.NUMBER;
        }
        return RecordSchemaType.TEXT;
    }
    else if (typeString === ValueDataType.DATETIME) {
        return RecordSchemaType.TIMESTAMP;
    }
    else if (typeString === ValueDataType.NATIVE_SELECTION) {
        return RecordSchemaType.UUID;
    }
    else {
        return getNativeType(nativeType);
    }
};
export var getNativeType = function (nativeType) {
    // native type looks like: 'native_user_id' | 'native_bool'
    var typeString = nativeType.slice(7);
    switch (typeString) {
        case 'bool':
            return ValueDataType.BOOLEAN;
        case 'text':
            return ValueDataType.TEXT;
        case 'number':
            return ValueDataType.NUMBER;
        case 'datetime':
            return ValueDataType.DATETIME;
        case 'currency':
            return ValueDataType.CURRENCY;
        case 'name':
            return ValueDataType.NAME;
        case 'email':
            return ValueDataType.EMAIL;
        case 'phone':
            return ValueDataType.PHONE;
        case 'url':
            return ValueDataType.URL;
        case 'contact':
            return ValueDataType.CONTACT;
        case 'duration':
            return ValueDataType.NATIVE_DURATION;
        case 'user_id':
        case 'user_ids':
            return 'user';
        case 'user_pin':
            return ValueDataType.NATIVE_USER_PIN;
        case 'location_id':
        case 'location_ids':
            return 'location';
        case 'role_ids':
            return 'role';
        case 'break_policy_id':
            return 'break';
        case 'break_rule_id':
            return 'break_rule';
        case 'timeoff_policy_id':
        case 'timeoff_type_id':
            return 'timeoff';
        case 'overtime_policy_id':
        case 'overtime_type_id':
            return 'overtime';
        case 'user_access_group_ids':
            return 'access_group';
        case 'type_id':
            return 'timeoff_type';
        case 'geofence_id':
            return 'geofences';
        case 'generated':
            return 'generated';
        case 'special':
            return ValueDataType.NATIVE_SPECIAL;
        case 'selection':
            return ValueDataType.NATIVE_SELECTION;
        case 'template_id':
            return ValueDataType.NATIVE_TEMPLATE_ID;
        case 'holiday_policy_id':
            return ValueDataType.NATIVE_HOLIDAY_POLICY_ID;
        case 'group_id':
            return ValueDataType.NATIVE_GROUP_ID;
        case 'button':
            return ValueDataType.BUTTON;
        case 'job_rid':
            return ValueDataType.JOB;
        case 'placement_rid':
            return ValueDataType.PLACEMENT;
        case 'shift_rid':
            return ValueDataType.SHIFT;
        default:
            return undefined;
    }
};
export var getColumnTypeKey = function (inputMode, type, nativePath, refCollectionId, refGroupId, configuration) {
    if (type === ValueDataType.TITLE) {
        return 'title';
    }
    else if (inputMode === CellInputMode.NATIVE) {
        var nativeTypeKey = getNativeType(type);
        if (!nativeTypeKey)
            return 'custom';
        if (nativeTypeKey === ValueDataType.BOOLEAN) {
            return CellInputMode.SINGLE;
        }
        else if (nativeTypeKey === ValueDataType.PHONE) {
            return 'phone';
        }
        else if (nativePath === NativePath.USER_LAST_ACTIVE) {
            return CellInputMode.GENERATED;
        }
        else if (nativeTypeKey === ValueDataType.NUMBER ||
            nativeTypeKey === ValueDataType.NATIVE_USER_PIN ||
            nativeTypeKey === ValueDataType.NATIVE_DURATION) {
            return 'number';
        }
        else if (nativeTypeKey === ValueDataType.CURRENCY) {
            return 'currency';
        }
        else if (NativeTextInputSet.has(nativeTypeKey)) {
            return 'text';
        }
        else if (nativeTypeKey === ValueDataType.NATIVE_SPECIAL) {
            if (nativePath === NativePath.LOCATION_ADDRESS) {
                return 'text';
            }
            //!MARK: has only one special now
            return CellInputMode.MULTI;
        }
        else if (type == ValueDataType.NATIVE_ACCESS_GROUP_IDS ||
            [
                ValueDataType.NATIVE_SELECTION,
                ValueDataType.JOB,
                ValueDataType.PLACEMENT,
                ValueDataType.SHIFT,
            ].includes(nativeTypeKey)) {
            //!MARK: native_selection hardcoded to be single
            return CellInputMode.SINGLE;
        }
        else if (type.endsWith('_ids')) {
            return CellInputMode.MULTI;
        }
        else if (type.endsWith('_id')) {
            return CellInputMode.SINGLE;
        }
        else {
            return nativeTypeKey;
        }
    }
    else if (inputMode === CellInputMode.PLAIN && !refGroupId) {
        return type;
    }
    else if (inputMode === CellInputMode.METRIC) {
        return 'metric';
    }
    else if (inputMode === CellInputMode.USER_DEFINED_GENERATED) {
        if (type === ValueDataType.TEXT)
            return 'text';
        if (type === ValueDataType.PLAIN_SELECTION)
            return (configuration === null || configuration === void 0 ? void 0 : configuration.isMulti)
                ? 'multi'
                : 'single';
        return 'generative';
    }
    else if (type === ValueDataType.FORMULA) {
        return type;
    }
    else if (type === ValueDataType.ROLLUP) {
        return type;
    }
    else if (refCollectionId) {
        return 'linkToObj';
    }
    else if (refGroupId && !configuration) {
        return 'linkToSchema';
    }
    else {
        if (type === ValueDataType.CUSTOM || isStandardNativeType(type)) {
            return 'custom';
        }
        else {
            return inputMode;
        }
    }
};
export var isNativePrimaryColumn = function (column) {
    //TODO: add more to standard objs
    var primaryColumnSet = new Set([NativePath.LOCATION_NAME, NativePath.ROLE_NAME]);
    return (column !== undefined &&
        column.nativePath !== undefined &&
        primaryColumnSet.has(column.nativePath));
};
export var getIfNeedsFetchDataInCell = function (schema) {
    if (!schema) {
        return {};
    }
    var isUserNative = getNativeType(schema.type) === 'user';
    var isLocationNative = getNativeType(schema.type) === 'location';
    var isRoleNative = getNativeType(schema.type) === 'role';
    var isBreakNative = getNativeType(schema.type) === 'break';
    var isBreakRuleNative = getNativeType(schema.type) === 'break_rule';
    var isTimeoffNative = getNativeType(schema.type) == 'timeoff';
    var isTimeoffTypeNative = getNativeType(schema.type) == 'timeoff_type';
    var isOvertimeNative = getNativeType(schema.type) == 'overtime';
    var isGeofenceTypeNative = getNativeType(schema.type) == 'geofences';
    var isUserSelecting = schema.type === ValueDataType.USER || isUserNative;
    var isLocationSelecting = schema.type === ValueDataType.LOCATION || isLocationNative;
    var isRoleSelecting = schema.type === ValueDataType.ROLE || isRoleNative;
    var isBreakSelecting = schema.type === ValueDataType.NATIVE_BREAK_POLICY_ID || isBreakNative;
    var isBreakRuleSelecting = schema.type === ValueDataType.NATIVE_BREAK_RULE_ID || isBreakRuleNative;
    var isTimeoffSelecting = schema.type === ValueDataType.NATIVE_TIMEOFF_POLICY_ID || isTimeoffNative;
    var isTimeoffTypeSelecting = schema.type === ValueDataType.NATIVE_TYPE_ID || isTimeoffTypeNative;
    var isOvertimeSelecting = schema.type === ValueDataType.NATIVE_OVERTIME_POLICY_ID || isOvertimeNative;
    var isGeofenceTypeSelecting = schema.type == ValueDataType.NATIVE_GEOFENCE_ID || isGeofenceTypeNative;
    var isShiftGroupTypeSelecting = schema.type === ValueDataType.NATIVE_GROUP_ID;
    var isJobTypeSelecting = schema.type === ValueDataType.NATIVE_JOB_RID;
    var fetchType = isUserSelecting
        ? 'users'
        : isLocationSelecting
            ? 'locations'
            : isRoleSelecting
                ? 'roles'
                : isBreakSelecting
                    ? 'breaks'
                    : isBreakRuleSelecting
                        ? 'break_rules'
                        : isTimeoffSelecting
                            ? 'timeoff'
                            : isTimeoffTypeSelecting
                                ? 'timeoff_type'
                                : isOvertimeSelecting
                                    ? 'overtime'
                                    : isGeofenceTypeSelecting
                                        ? 'geofences'
                                        : 'collections';
    var needsFetchInfo = ItemRelationTypeSet.has(schema.type) ||
        isUserSelecting ||
        isLocationSelecting ||
        isRoleSelecting ||
        isBreakSelecting ||
        isBreakRuleSelecting ||
        isTimeoffSelecting ||
        isTimeoffTypeSelecting ||
        isOvertimeSelecting ||
        isGeofenceTypeSelecting ||
        isShiftGroupTypeSelecting ||
        isJobTypeSelecting;
    // type which has record details
    var isReferenceRecord = fetchType === 'users' ||
        fetchType === 'locations' ||
        fetchType === 'roles' ||
        fetchType === 'breaks' ||
        isShiftGroupTypeSelecting ||
        isJobTypeSelecting ||
        schema.type === ValueDataType.CUSTOM ||
        schema.type === ValueDataType.CONTACT;
    return {
        needsFetchData: needsFetchInfo,
        fetchType: fetchType,
        isReferenceRecord: isReferenceRecord,
    };
};
/** Get value of a native field cell content */
export var getNativeFieldCellContent = function (record, nativePathSchemaMap, nativePath) {
    var _a;
    var schemaId = (_a = nativePathSchemaMap[nativePath]) === null || _a === void 0 ? void 0 : _a.id;
    if (!schemaId || !record[schemaId])
        return null;
    return record[schemaId].content;
};
/** Get value of a native field cell options */
export var getNativeFieldCellOptions = function (record, nativePathSchemaMap, nativePath) {
    var _a, _b;
    var schemaId = (_a = nativePathSchemaMap[nativePath]) === null || _a === void 0 ? void 0 : _a.id;
    if (!schemaId || !record[schemaId])
        return [];
    return record[schemaId].options.length
        ? record[schemaId].options
        : ((_b = record[schemaId].versionedOptions) !== null && _b !== void 0 ? _b : []);
};
/** Get value of a native field cell files */
export var getNativeFieldCellFiles = function (record, nativePathSchemaMap, nativePath) {
    var _a;
    var schemaId = (_a = nativePathSchemaMap[nativePath]) === null || _a === void 0 ? void 0 : _a.id;
    if (!schemaId || !record[schemaId])
        return [];
    return record[schemaId].files;
};
export var disableScroll = function (dropdownOpen) {
    var ele = document.getElementById('ModalBody') ||
        document.getElementById('CenterPanelContent') ||
        document.getElementById('SettingContentView ');
    if (ele) {
        if (dropdownOpen) {
            ele.style.overflowY = 'hidden';
        }
        else {
            ele.style.overflowY = 'auto';
        }
    }
};
export var generateNativeCollectionTitle = function (type, record, schemas, timezone) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var tz = moment().tz(timezone).zoneAbbr();
    var titleCell = __assign({}, DefaultCollectionCell);
    switch (type) {
        case CollectionType.USER: {
            var firstName = (_a = getNativeFieldCellContent(record, schemas, NativePath.USER_FIRST_NAME)) !== null && _a !== void 0 ? _a : '';
            var lastName = (_b = getNativeFieldCellContent(record, schemas, NativePath.USER_LAST_NAME)) !== null && _b !== void 0 ? _b : '';
            titleCell.content =
                firstName && lastName
                    ? "".concat(firstName, " ").concat(lastName)
                    : firstName
                        ? firstName
                        : lastName;
            //! Indiciate that there can be an avatarUrl
            titleCell.content += 'avatarUrl';
            break;
        }
        case CollectionType.CONTACT: {
            var firstName = (_c = getNativeFieldCellContent(record, schemas, NativePath.CONTACT_FIRST_NAME)) !== null && _c !== void 0 ? _c : '';
            var lastName = (_d = getNativeFieldCellContent(record, schemas, NativePath.CONTACT_LAST_NAME)) !== null && _d !== void 0 ? _d : '';
            titleCell.content =
                firstName && lastName
                    ? "".concat(firstName, " ").concat(lastName)
                    : firstName
                        ? firstName
                        : lastName;
            break;
        }
        case CollectionType.SHIFT: {
            var clockIn = (_e = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_CLOCK_IN)) !== null && _e !== void 0 ? _e : '';
            var clockOut = (_f = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_CLOCK_OUT)) !== null && _f !== void 0 ? _f : '';
            var start = (_g = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_START_AT)) !== null && _g !== void 0 ? _g : '';
            var end = (_h = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_END_AT)) !== null && _h !== void 0 ? _h : '';
            var startDay = moment(clockIn || start)
                .tz(timezone)
                .format('MMM D');
            var endDay = clockOut || end
                ? moment(clockOut || end)
                    .tz(timezone)
                    .format('MMM D')
                : '';
            var startTime = clockIn || start
                ? moment(clockIn || start)
                    .tz(timezone)
                    .format('h:mma')
                : 'Start Time';
            var endTime = clockOut || end
                ? moment(clockOut || end)
                    .tz(timezone)
                    .format('h:mma')
                : 'End Time';
            titleCell.content =
                startDay && endDay && startDay !== endDay
                    ? "".concat(startDay, " - ").concat(endDay, ", ").concat(startTime, " - ").concat(endTime, ", ").concat(tz)
                    : startDay
                        ? "".concat(startDay, ", ").concat(startTime, " - ").concat(endTime, ",  ").concat(tz)
                        : '';
            break;
        }
        case CollectionType.TIMEOFF: {
            var startDay = (_j = getNativeFieldCellContent(record, schemas, NativePath.TIMEOFF_START_AT)) !== null && _j !== void 0 ? _j : '';
            var endDay = (_k = getNativeFieldCellContent(record, schemas, NativePath.TIMEOFF_END_AT)) !== null && _k !== void 0 ? _k : '';
            startDay = startDay ? moment(startDay).tz(timezone).format('MMM D') : '';
            endDay = endDay ? moment(endDay).tz(timezone).format('MMM D') : '';
            var timeOffType = (_m = (_l = getNativeFieldCellOptions(record, schemas, NativePath.TIMEOFF_TYPE_ID)) === null || _l === void 0 ? void 0 : _l[0].name) !== null && _m !== void 0 ? _m : '';
            titleCell.content =
                startDay && endDay
                    ? "".concat(timeOffType, " (").concat(startDay, " - ").concat(endDay, " ").concat(tz, ")")
                    : startDay
                        ? "".concat(timeOffType, " (").concat(startDay, " - End Time ").concat(tz, ")")
                        : endDay
                            ? "".concat(timeOffType, " (Start Time - ").concat(endDay, " ").concat(tz, ")")
                            : '';
            break;
        }
        case CollectionType.LOCATION: {
            var locationName = (_o = getNativeFieldCellContent(record, schemas, NativePath.LOCATION_NAME)) !== null && _o !== void 0 ? _o : '';
            titleCell.content = locationName;
            break;
        }
        case CollectionType.ROLE: {
            var roleName = (_p = getNativeFieldCellContent(record, schemas, NativePath.ROLE_NAME)) !== null && _p !== void 0 ? _p : '';
            titleCell.content = roleName;
            break;
        }
        default:
            break;
    }
    return titleCell;
};
export var hasTitleColumn = function (type) {
    return (isStandardNativeType(type) &&
        ![
            CollectionType.LOCATION,
            CollectionType.BREAK,
            CollectionType.ROLE,
            CollectionType.SHIFT_GROUP,
            CollectionType.JOB,
            CollectionType.PLACEMENT,
        ].includes(type));
};
export var getTagTextColor = function (backgroundColor) {
    if (!backgroundColor)
        return '#000000';
    return tinycolor(backgroundColor).darken(60).toString();
};
export var getButtonBorderColor = function (backgroundColor) {
    var colorIndex = BUTTON_COLOR_OPTIONS.indexOf(backgroundColor);
    if (colorIndex === -1) {
        return '#D9D9D9';
    }
    else {
        return BUTTON_BORDER_COLOR_OPTIONS[colorIndex];
    }
};
export var convertStyleToColors = function (style) {
    if (!style)
        return '#f4f4f7';
    if (isColorNameStyle(style)) {
        return StyleColorMap[style];
    }
    return style;
};
export var dropdownListFilterInputResult = function (input, option) {
    return option.toLowerCase().indexOf(input.toLowerCase()) > -1;
};
export var formatShiftRelationTitle = function (start, end, timezone) {
    var tz = moment().tz(timezone).zoneAbbr();
    var startDay = moment(start).tz(timezone).format('MMM D');
    var endDay = moment(end).tz(timezone).format('MMM D');
    var startTime = moment(start).tz(timezone).format('h:mma');
    var endTime = moment(end).tz(timezone).format('h:mma');
    return startDay !== endDay
        ? "".concat(startDay, " - ").concat(endDay, ", ").concat(startTime, " - ").concat(endTime, ", ").concat(tz)
        : "".concat(startDay, ", ").concat(startTime, " - ").concat(endTime, ",  ").concat(tz);
};
export var getMultiFilterNames = function (filter, data) {
    var displayName = '';
    if (typeof filter.filterValue === 'string')
        return '';
    var filterVals = filter.filterValue;
    filterVals === null || filterVals === void 0 ? void 0 : filterVals.map(function (val, idx) {
        var _a, _b;
        displayName += (_b = (_a = data.propertyNames) === null || _a === void 0 ? void 0 : _a[val]) !== null && _b !== void 0 ? _b : val;
        if (idx + 1 < filterVals.length)
            displayName += ', ';
    });
    return displayName;
};
export var getValidatedFilters = function (filters) {
    return filters.filter(function (filter) {
        if (filter.secondaryFilter) {
            return ((filter.clauseValue === 'option_contains' &&
                !specialDefineEmptyClauseTypes.has(filter.secondaryFilter.clauseValue) &&
                filter.secondaryFilter.filterValue !== '' &&
                (isArray(filter.secondaryFilter.filterValue)
                    ? filter.secondaryFilter.filterValue.length
                    : true)) ||
                specialDefineEmptyClauseTypes.has(filter.secondaryFilter.clauseValue));
        }
        else {
            return ((!specialDefineEmptyClauseTypes.has(filter.clauseValue) &&
                filter.filterValue !== '' &&
                (isArray(filter.filterValue) ? filter.filterValue.length : true)) ||
                specialDefineEmptyClauseTypes.has(filter.clauseValue));
        }
    });
};
/**
 * Returns validated display settings for updated state and API request
 */
export var getNewValidatedDisplaySettings = function (prevdisplaySettings, newDisplaySettings) {
    var tempNewDisplaySettings = cloneDeep(newDisplaySettings);
    if (tempNewDisplaySettings.filters) {
        tempNewDisplaySettings.filters = getValidatedFilters(tempNewDisplaySettings.filters);
    }
    // Is switching from list view to board view
    if (tempNewDisplaySettings.groupSetting && !prevdisplaySettings.groupSetting) {
        // Clear filters/sort with groupSetting's schemaId
        var groupSettingSchemaId_1 = tempNewDisplaySettings.groupSetting.schema;
        if (prevdisplaySettings.filters) {
            tempNewDisplaySettings.filters = prevdisplaySettings.filters.filter(function (filter) { return filter.property !== groupSettingSchemaId_1; });
        }
        if (prevdisplaySettings.sortBy) {
            tempNewDisplaySettings.sortBy = prevdisplaySettings.sortBy.filter(function (sort) { return sort.property !== groupSettingSchemaId_1; });
        }
    }
    return [__assign(__assign({}, prevdisplaySettings), tempNewDisplaySettings), tempNewDisplaySettings];
};
export var getModalTabIcon = function (type) {
    switch (type) {
        case 'details':
            return DetailsTabImage;
        case 'shift_tracking':
            return ShiftTrackingTabImage;
        case CollectionType.TIMEOFF:
            return TimeOffTabImage;
        case CollectionType.DOCUMENT:
            return DocumentsTabImage;
        case CollectionType.BREAK:
            return BreaksTabImage;
        case CollectionType.USER:
            return UsersTabImage;
        case CollectionType.LOCATION:
            return LocationsTabImage;
        case CollectionType.SHIFT:
            return ShiftsTabImage;
        case CollectionType.SHIFT_GROUP:
            return ShiftsTabImage;
        case CollectionType.JOB:
            return ShiftsTabImage;
        default:
            return CustomTabImage;
    }
};
export var isEditRecordForTimeOffApproval = function (schemaProperties) {
    for (var _i = 0, schemaProperties_1 = schemaProperties; _i < schemaProperties_1.length; _i++) {
        var schema = schemaProperties_1[_i];
        if (schema.type === RecordSchemaType.TEXT && schema[RecordSchemaType.TEXT] === 'APPROVED') {
            return true;
        }
    }
    return false;
};
export var getCollectionTableRefetchSize = function (currentSize, pageSize) {
    return currentSize > pageSize
        ? Math.max(pageSize, Math.ceil(currentSize / pageSize) * pageSize)
        : pageSize;
};
export var getCurrencyValue = function (value) {
    var _a, _b;
    return parseFloat((_b = (_a = value.match(/-?\d*\.?\d+/g)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '0').toFixed(2);
};
export var checkStaleOptionInCell = function (options) {
    var staleOption = options.filter(function (option) { return option.name === 'stale'; });
    return !!staleOption.length && !!staleOption[0].value;
};
