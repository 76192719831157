import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { useChatComponent } from 'src/hooks/component/useChat';
import { CloseConversationCheckMarkIcon } from 'src/utils/icons/ChatIcons/CloseConversationCheckMarkIcon';
var ArchiveThreadButton = function (_a) {
    var isBroadcast = _a.isBroadcast;
    var archiveThread = useChatComponent().archiveThread;
    return (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to close this ".concat(isBroadcast ? 'broadcast' : 'conversation', "?"), okText: "Yes", onConfirm: archiveThread, children: _jsxs("div", { className: "flex cursor-pointer items-center rounded-[8px] bg-green-400 px-[12px] py-[6px] text-sm font-medium text-white ArchiveConversationBtn", children: [_jsx(CloseConversationCheckMarkIcon, {}), _jsxs("div", { className: "ml-2", children: ["Close ", isBroadcast ? 'Broadcast' : 'Conversation'] })] }) }));
};
export default ArchiveThreadButton;
