var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Menu } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { disableScroll } from '../../../../utils/Collection';
import { AddColorOutlinedIcon } from '../../../../utils/icons/DropdownIcons/AddColorOutlined';
import { DeleteOutlinedIcon } from '../../../../utils/icons/DropdownIcons/DeleteOutlined';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from '../../../CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from '../../../CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import RecordColorSelectorDropdown from '../../../RecordColorSelectorDropdown/RecordColorSelectorDropdown';
import './RecordActionDropdown.scss';
import HardConfirmWrapper from '../../../HardConfirmWrapper/HardConfirmWrapper';
import StandardCollectionRecordActions from './StandardCollectionRecordActions';
var RecordActionDropdown = function (_a) {
    var recordId = _a.recordId, collectionId = _a.collectionId, collectionType = _a.collectionType, children = _a.children, parentElementId = _a.parentElementId, onDeleteRecord = _a.onDeleteRecord, onUpdateRecordColor = _a.onUpdateRecordColor, currentColor = _a.currentColor, setHoverOptionVisible = _a.setHoverOptionVisible;
    var _b = useState(false), dropdownVisible = _b[0], setDropdownVisible = _b[1];
    var _c = useState(false), colorEditMenuVisible = _c[0], setColorEditMenuVisible = _c[1];
    var _d = useState(false), optionsDisabled = _d[0], setOptionsDisabled = _d[1];
    var onConfirmDelete = useCallback(function () {
        disableScroll(false);
        setHoverOptionVisible === null || setHoverOptionVisible === void 0 ? void 0 : setHoverOptionVisible(false);
        setDropdownVisible(false);
        onDeleteRecord === null || onDeleteRecord === void 0 ? void 0 : onDeleteRecord();
    }, [onDeleteRecord, setHoverOptionVisible]);
    var menu = useMemo(function () {
        return (_jsxs(Menu, { onClick: function (e) {
                e.domEvent.stopPropagation();
            }, className: "RecordActionDropdownMenu", children: [_jsx(StandardCollectionRecordActions, { recordId: recordId, collectionId: collectionId, collectionType: collectionType, onCloseHoverOptions: function () {
                        setHoverOptionVisible(false);
                        setDropdownVisible(false);
                    } }), onUpdateRecordColor && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { icon: _jsx(AddColorOutlinedIcon, {}), onClick: function (e) {
                                e.domEvent.preventDefault();
                                e.domEvent.stopPropagation();
                                setColorEditMenuVisible(!colorEditMenuVisible);
                                setHoverOptionVisible === null || setHoverOptionVisible === void 0 ? void 0 : setHoverOptionVisible(!colorEditMenuVisible);
                                setOptionsDisabled(!colorEditMenuVisible);
                            }, children: _jsx(RecordColorSelectorDropdown, { currentColor: currentColor, visible: colorEditMenuVisible, onVisibleChange: setColorEditMenuVisible, onUpdateRecordColor: onUpdateRecordColor, children: _jsx("span", { children: "Add Color" }) }) }, "addColor"), _jsx(Menu.Divider, { className: "Divider" })] })), _jsx(HardConfirmWrapper, { changeType: "delete", entityName: "record", onConfirm: onConfirmDelete, children: _jsx(Menu.Item, { className: "ml-px", icon: _jsx(DeleteOutlinedIcon, { className: "text-red-1000" }), disabled: optionsDisabled, children: _jsx("span", { className: "-ml-px text-red-1000", children: "Delete" }) }, "delete") }), _jsx(Menu.Divider, { className: "Divider" }), _jsx("span", { onClick: function () {
                        setDropdownVisible(false);
                        disableScroll(false);
                    }, children: _jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.RECORD_ACTION], children: _jsxs("div", { className: "flex items-center bg-gray-50 py-2 pl-[17px] pr-[5px]", children: [_jsx(HelpIcon, { className: "mr-3 h-[16px] w-[16px] text-gray-400" }), _jsx("div", { className: "text-xs font-medium text-gray-400", children: "Learn more about record actions." })] }) }) })] }));
    }, [
        collectionId,
        collectionType,
        colorEditMenuVisible,
        currentColor,
        onConfirmDelete,
        onUpdateRecordColor,
        optionsDisabled,
        recordId,
        setHoverOptionVisible,
    ]);
    var dropdownProps = useMemo(function () { return ({
        className: 'RecordActionDropdown',
        overlay: menu,
        trigger: ['click'],
    }); }, [menu]);
    //!MARK: bind to parent element
    useEffect(function () {
        if (parentElementId) {
            var bindParentElement_1 = document.getElementById(parentElementId);
            if (bindParentElement_1) {
                dropdownProps.getPopupContainer = function () { return bindParentElement_1; };
            }
        }
    }, [dropdownProps, parentElementId]);
    return (_jsx(Dropdown, __assign({}, dropdownProps, { getPopupContainer: function () { return document.body; }, placement: "bottomLeft", open: dropdownVisible || colorEditMenuVisible, onOpenChange: function (visible) {
            disableScroll(visible);
            if (!visible) {
                setColorEditMenuVisible(false);
                setOptionsDisabled(false);
            }
            setHoverOptionVisible === null || setHoverOptionVisible === void 0 ? void 0 : setHoverOptionVisible(visible);
            setDropdownVisible(visible);
        }, children: children })));
};
export default React.memo(RecordActionDropdown);
