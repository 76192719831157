import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { StandardRelation } from 'src/components/CenterPanel/Interface';
import TaskDismissedToggleOption from './TaskDismissedToggleOption';
import PlacementTasksPublishOption from './PlacementTasksPublishOption';
var TaskCollectionExtraHeaderOptions = function (_a) {
    var tableId = _a.tableId, collectionId = _a.collectionId, type = _a.type, reverseCollectionProps = _a.reverseCollectionProps;
    return (_jsxs("div", { className: "flex items-center gap-[8px] ant-space-item", children: [reverseCollectionProps &&
                reverseCollectionProps.standardRelation === StandardRelation.PLACEMENT_TASKS && (_jsx(PlacementTasksPublishOption, { reverseCollectionProps: reverseCollectionProps })), _jsx(TaskDismissedToggleOption, { tableId: tableId, collectionId: collectionId, type: type })] }));
};
export default React.memo(TaskCollectionExtraHeaderOptions);
