import { useRecoilCallback } from 'recoil';
import { selectedTaskGroupIdState, selectedTaskRecordIdState, selectedTaskRuleIdState, selectedTaskTemplateIdState, taskEditorActiveTabState, taskSettingModalVisibleState, } from '../atom';
import { TaskTab } from '../interface';
var useTasks = function () {
    var viewTaskTemplate = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (taskTemplateId) {
            set(selectedTaskTemplateIdState, taskTemplateId);
            set(taskEditorActiveTabState, TaskTab.TEMPLATES);
            set(taskSettingModalVisibleState, true);
        };
    }, []);
    var viewTaskGroup = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (taskTemplateId) {
            set(selectedTaskGroupIdState, taskTemplateId);
            set(taskEditorActiveTabState, TaskTab.GROUPS);
            set(taskSettingModalVisibleState, true);
        };
    }, []);
    var viewTaskRule = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (taskRuleId) {
            set(selectedTaskRuleIdState, taskRuleId);
            set(taskEditorActiveTabState, TaskTab.RULES);
            set(taskSettingModalVisibleState, true);
        };
    }, []);
    var viewTaskChangesModal = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (recordId) {
            set(selectedTaskRecordIdState, recordId);
        };
    }, []);
    return {
        viewTaskTemplate: viewTaskTemplate,
        viewTaskGroup: viewTaskGroup,
        viewTaskRule: viewTaskRule,
        viewTaskChangesModal: viewTaskChangesModal,
    };
};
export { useTasks as useTasksComponent };
