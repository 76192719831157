import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { getTagTextColor } from 'src/utils/Collection';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useScheduleNeedsCoverageComponent } from 'src/hooks/component';
import { OPTIONS_COLOR } from 'src/shared';
import { getScheduleTemplateCountStatColor } from '../../../Utils';
import ScheduleTemplateCountStatEditPopover from './ScheduleTemplateCountStatEditPopover';
import ScheduleTemplateCountManuallyUpdatedBadge from './ScheduleTemplateCountManuallyUpdatedBadge';
var ScheduleTemplateCountStatCell = function (_a) {
    var id = _a.id, blockId = _a.blockId, name = _a.name, actual = _a.actual, budgetedExpected = _a.expected, date = _a.date, canEditExpected = _a.canEditExpected, manuallyUpdatedInfo = _a.manuallyUpdatedInfo, openShifts = _a.openShifts;
    var currentExpected = manuallyUpdatedInfo ? manuallyUpdatedInfo.expected : budgetedExpected;
    var _b = useState(currentExpected), expected = _b[0], setExpected = _b[1];
    var _c = useState(false), isEditPopoverOpen = _c[0], setIsEditPopoverOpen = _c[1];
    var openShiftLocalKey = localStorage.getItem('openShiftTestKey') == 'true';
    var backgroundColor = getScheduleTemplateCountStatColor(actual, currentExpected);
    var refetchTemplateCountStats = useScheduleNeedsCoverageComponent().refetchTemplateCountStats;
    var onSetExpected = useCallback(function (newExpected) {
        setExpected(newExpected);
        refetchTemplateCountStats();
    }, [refetchTemplateCountStats]);
    var isShowMismatchedOpenShift = openShifts !== undefined && openShiftLocalKey && actual + openShifts != currentExpected;
    var cell = (_jsxs("div", { className: "relative h-full border-b border-r border-[#f0f0f0]", children: [_jsx(ZiraTooltip, { title: canEditExpected ? 'Click to manually edit expected count' : undefined, mouseEnterDelay: 0.5, children: _jsxs("div", { className: "mx-[4px] my-[6px] flex items-center justify-center rounded py-[2px]", style: {
                        backgroundColor: backgroundColor,
                        color: getTagTextColor(backgroundColor),
                        cursor: canEditExpected ? 'pointer' : 'default',
                    }, onClick: function () {
                        if (canEditExpected && id)
                            setIsEditPopoverOpen(true);
                    }, children: [actual, "/", currentExpected, isShowMismatchedOpenShift && (_jsxs("span", { style: {
                                color: getTagTextColor(OPTIONS_COLOR[0]),
                            }, className: "ml-1 font-bold", children: ["(", openShifts, ")"] }))] }) }), manuallyUpdatedInfo && (_jsx(ScheduleTemplateCountManuallyUpdatedBadge, { manuallyUpdatedInfo: manuallyUpdatedInfo }))] }));
    if (!isEditPopoverOpen || !id) {
        return cell;
    }
    return (_jsx(ScheduleTemplateCountStatEditPopover, { blockId: blockId, id: id, name: name, actual: actual, budgetedExpected: budgetedExpected, expected: expected, manuallyUpdatedInfo: manuallyUpdatedInfo, date: date, setExpected: onSetExpected, onClose: function () { return setIsEditPopoverOpen(false); }, children: cell }));
};
export default React.memo(ScheduleTemplateCountStatCell);
