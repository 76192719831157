var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TriangleRightSvg = function () { return (_jsx("svg", { width: "10", height: "12", viewBox: "0 0 10 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M0.916992 0.75L9.08366 6L0.916992 11.25V0.75Z", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }) })); };
export var TriangleRightOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: TriangleRightSvg }, props))); };
