import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { isDuplicatingDefaultRateState } from '../../../atoms';
import { useDefaultRate } from '../useDefaultRate';
import DefaultRateImportModal from '../DefaultRateImportModal/DefaultRateImportModal';
import RateTableCell from './RateTableCell';
import RateTableHeader from './RateTableHeader';
import DefaultRateTableBuilder from './DefaultRateTableBuilder';
import DefaultRateRecordRowActionMenu from './DefaultRateRecordRowActionMenu';
var DefaultRateTable = function (_a) {
    var defaultRateData = _a.defaultRateData, isLoading = _a.isLoading, canEditTable = _a.canEditTable, openEditModal = _a.openEditModal;
    var constructDefaultRateStateItem = useDefaultRate().constructDefaultRateStateItem;
    var setIsDuplicatingDefaultRate = useSetRecoilState(isDuplicatingDefaultRateState);
    var _b = useState(false), isImportModalOpen = _b[0], setIsImportModalOpen = _b[1];
    var editDefaultRateModal = useCallback(function (defaultRate, isDuplicateAction) {
        var _a;
        if (!canEditTable)
            return;
        constructDefaultRateStateItem(defaultRate, (_a = defaultRateData === null || defaultRateData === void 0 ? void 0 : defaultRateData.headers) !== null && _a !== void 0 ? _a : [], isDuplicateAction);
        openEditModal === null || openEditModal === void 0 ? void 0 : openEditModal();
    }, [defaultRateData === null || defaultRateData === void 0 ? void 0 : defaultRateData.headers, constructDefaultRateStateItem, openEditModal, canEditTable]);
    var defaultRateRecords = useMemo(function () {
        var records = [];
        if (defaultRateData) {
            defaultRateData.defaultRates.map(function (rate) {
                var defaultRateRecord = {};
                defaultRateRecord['info'] = rate.info;
                Object.keys(rate.conditions).map(function (key) {
                    var columnInfoObj = rate.conditions[key];
                    defaultRateRecord[key] = columnInfoObj;
                });
                Object.keys(rate.adjustments).map(function (key) {
                    var columnInfoObj = rate.adjustments[key];
                    defaultRateRecord[key] = columnInfoObj;
                });
                records.push(defaultRateRecord);
            });
        }
        return records;
    }, [defaultRateData]);
    var defaultRateColumns = useMemo(function () {
        var columns = [];
        columns.push({
            Header: _jsx("div", { children: "Name" }),
            width: 150,
            accessor: 'info',
            Cell: function (_a) {
                var value = _a.value;
                return _jsx("div", { className: "flex font-medium", children: value.name });
            },
        });
        defaultRateData === null || defaultRateData === void 0 ? void 0 : defaultRateData.headers.map(function (header, index) {
            var columnType = header.type;
            var isLastColumn = index === defaultRateData.headers.length - 1;
            columns.push({
                Header: _jsx(RateTableHeader, { header: header }),
                accessor: header.id,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: function (value) {
                    var index = value.row.index;
                    var payId = value.data[index].info.id;
                    var rowData = value.row.original;
                    var cellData = value.value;
                    return (_jsxs(_Fragment, { children: [isLastColumn && canEditTable && (_jsx(DefaultRateRecordRowActionMenu, { defaultRateId: payId, openDuplicateActionModal: function () {
                                    editDefaultRateModal(rowData, true);
                                    setIsDuplicatingDefaultRate(true);
                                } })), cellData && (_jsx(RateTableCell, { columnType: columnType, cellData: cellData }))] }));
                },
            });
        });
        return columns;
    }, [defaultRateData, editDefaultRateModal, setIsDuplicatingDefaultRate, canEditTable]);
    return isLoading ? (_jsx(Skeleton, {})) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "ReactTableWrapper", children: _jsx(DefaultRateTableBuilder, { columns: (defaultRateData === null || defaultRateData === void 0 ? void 0 : defaultRateData.defaultRates.length) !== 0 ? defaultRateColumns : [], rates: defaultRateRecords, editDefaultRateModal: function (data) { return editDefaultRateModal(data, false); }, canEdit: canEditTable, openImportModal: function () { return setIsImportModalOpen(true); } }) }), isImportModalOpen && canEditTable && (_jsx(DefaultRateImportModal, { isModalVisible: isImportModalOpen, onModalClose: function () { return setIsImportModalOpen(false); } }))] }));
};
export default React.memo(DefaultRateTable);
