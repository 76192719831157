var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Button, Divider, InputNumber, Modal, Select, Switch } from 'antd';
import { startCase, lowerCase } from 'lodash';
import Pluralize from 'pluralize';
import { ClockCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import ZiraDatePicker from 'src/ui/ZiraDatePicker';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import { CarryOverLimitTypeToLabel, CarryOverLimitTypes, } from '../Interface';
import './TimeOffRule.scss';
import { TimeoffAccrualType } from '../../../../shared';
var Option = Select.Option;
var timeoffAccrualTypeOptions = [
    { id: TimeoffAccrualType.HOURS, displayLabel: 'hrs worked' },
    { id: TimeoffAccrualType.PAY, displayLabel: 'pay periods' },
];
var TimeOffRule = function (_a) {
    var policy = _a.policy, type = _a.type, rule = _a.rule;
    var _b = CompliancePageState.useContainer(), dateSchemas = _b.dateSchemas, addTimeOffRule = _b.addTimeOffRule, deleteTimeOffRule = _b.deleteTimeOffRule, updateTimeOffRule = _b.updateTimeOffRule;
    var onSwitchChange = useCallback(function (checked) {
        if (checked) {
            addTimeOffRule(policy.policyId, type.id);
        }
        else if (rule) {
            deleteTimeOffRule(policy.policyId, rule.id);
        }
    }, [addTimeOffRule, deleteTimeOffRule, policy.policyId, rule, type.id]);
    var _c = useState(false), isDetailModalOpen = _c[0], setIsDetailModalOpen = _c[1];
    var onUpdateRule = useCallback(function (rule) {
        if (rule) {
            updateTimeOffRule(policy.policyId, rule);
        }
    }, [policy.policyId, updateTimeOffRule]);
    var generateAccrualDescription = useCallback(function () {
        if ((rule === null || rule === void 0 ? void 0 : rule.rule.accrualType) == TimeoffAccrualType.NULL)
            return null;
        return (_jsxs("div", { className: "rounded-full bg-green-50 py-1 px-3 text-green-400 max-h-[32px] min-w-[254px] flex items-center gap-x-1", children: [_jsx(ClockCircleFilled, {}), "".concat(rule === null || rule === void 0 ? void 0 : rule.rule.accruedHours, " ").concat(Pluralize('hr', rule === null || rule === void 0 ? void 0 : rule.rule.accruedHours), " accrued per\n                ").concat(rule === null || rule === void 0 ? void 0 : rule.rule.accrualPeriod, " ").concat(lowerCase(startCase(rule === null || rule === void 0 ? void 0 : rule.rule.accrualType)))] }));
    }, [rule]);
    var generateMaxDailyDescription = useCallback(function () {
        if (!(rule === null || rule === void 0 ? void 0 : rule.rule.limitDayValueDetails))
            return null;
        return (_jsxs("div", { className: "rounded-full bg-green-50 py-1 px-3 text-green-400 max-h-[32px] min-w-[168px] flex items-center gap-x-1", children: [_jsx(ClockCircleFilled, {}), "".concat(rule === null || rule === void 0 ? void 0 : rule.rule.limitDayValueDetails.limitDayValue, " ").concat(Pluralize('hr', rule === null || rule === void 0 ? void 0 : rule.rule.limitDayValueDetails.limitDayValue), " max per day")] }));
    }, [rule]);
    var generateCarryOverDescription = useCallback(function () {
        if (!(rule === null || rule === void 0 ? void 0 : rule.rule.carryOverDetails))
            return null;
        return (_jsxs("div", { className: "rounded-full bg-yellow-50 py-1 px-3 text-yellow-400 max-h-[32px] min-w-[136px] flex items-center gap-x-1", children: [_jsx(ClockCircleFilled, {}), "".concat(rule === null || rule === void 0 ? void 0 : rule.rule.carryOverDetails.carryOverLimitHours, " ").concat(Pluralize('hr', rule === null || rule === void 0 ? void 0 : rule.rule.carryOverDetails.carryOverLimitHours), " rollover")] }));
    }, [rule]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center pr-3 gap-3 font-medium", children: [_jsx(Switch, { checked: !!rule, onChange: onSwitchChange }), type.name, _jsx("div", { className: "flex-grow" }), rule && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "overflow-x-auto overflow-y-hidden flex space-x-1", children: [generateAccrualDescription(), generateMaxDailyDescription(), generateCarryOverDescription()] }), _jsx(Button, { className: "bg-black rounded-full text-white font-medium h-auto hover:bg-black hover:text-white", onClick: function () { return setIsDetailModalOpen(true); }, children: "Edit" })] })), !rule && (_jsxs("div", { className: "rounded-full bg-gray-100 text-gray-500 py-1 px-4", children: ["No ", type.name] }))] }), _jsx(Divider, { className: "TimeOffRuleDivider" }), rule && (_jsx(TimeOffRuleModal, { isModalOpen: isDetailModalOpen, setIsModalOpen: setIsDetailModalOpen, type: type, rule: rule, onUpdateRule: onUpdateRule, dateSchemas: dateSchemas }))] }));
};
var TimeOffRuleModal = function (_a) {
    var _b;
    var isModalOpen = _a.isModalOpen, setIsModalOpen = _a.setIsModalOpen, type = _a.type, defaultRule = _a.rule, onUpdateRule = _a.onUpdateRule, dateSchemas = _a.dateSchemas;
    var _c = useState(defaultRule), rule = _c[0], setRule = _c[1];
    var limitDayValueSettingStatus = !!rule.rule.limitDayValueDetails;
    var accrualTrackStatus = rule.rule.accrualType !== TimeoffAccrualType.NULL;
    var carryOverSettingStatus = !!rule.rule.carryOverDetails;
    return (_jsx(Modal, { open: isModalOpen, closable: true, centered: true, onCancel: function () {
            onUpdateRule(rule);
            setIsModalOpen(false);
        }, maskClosable: false, footer: null, title: _jsxs("div", { className: "flex flex-col font-semibold gap-3", children: [_jsx("span", { className: "text-gray-500", children: "Config Time Off Type" }), _jsx("div", { className: "text-2xl flex", children: type.name })] }), destroyOnClose: true, width: 800, focusTriggerAfterClose: true, children: _jsxs("div", { className: "RulesDetailsContainer flex flex-col gap-2", children: [_jsxs("div", { className: "TimeOffRuleDetails justify-between", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Switch, { checked: !!rule.rule.limitDayValueDetails, onChange: function (e) {
                                        return setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { limitDayValueDetails: e ? { limitDayValue: 8 } : undefined }) })); });
                                    } }), _jsx("div", { className: "ml-5 font-semibold py-1.5", children: "Limit Daily Value" })] }), limitDayValueSettingStatus && rule.rule.limitDayValueDetails && (_jsxs("div", { className: "ml-10", children: [_jsx(InputNumber, { className: "TimeoffNumberInput", bordered: false, value: rule.rule.limitDayValueDetails.limitDayValue, onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { limitDayValueDetails: {
                                                    limitDayValue: value,
                                                } }) })); });
                                    }, max: 24, min: 0 }), _jsxs("span", { className: "text-xs font-semibold", children: [Pluralize('hr', rule.rule.limitDayValueDetails.limitDayValue), " max per day"] })] }))] }), _jsx(Divider, {}), _jsxs("div", { className: "TimeOffRuleDetails justify-between h-[32px]", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Switch, { checked: accrualTrackStatus, onChange: function (checked) {
                                        return setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { accrualType: checked
                                                    ? TimeoffAccrualType.HOURS
                                                    : TimeoffAccrualType.NULL }) })); });
                                    } }), _jsx("div", { className: "ml-5 font-semibold", children: "Track Accrual" })] }), accrualTrackStatus && (_jsxs("div", { className: "ml-10 flex items-center", children: [_jsx(InputNumber, { className: "TimeoffNumberInput", bordered: false, value: rule.rule.accruedHours, onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { accruedHours: value }) })); });
                                    }, max: 1000, min: 0 }), _jsxs("span", { className: "TimeOffRuleDescriptionPiece", children: [Pluralize('hr', rule.rule.accruedHours), " accrued for every"] }), _jsx(InputNumber, { className: "TimeoffNumberInput", bordered: false, value: rule.rule.accrualPeriod, onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { accrualPeriod: value }) })); });
                                    }, min: 0 }), _jsx(Select, { dropdownMatchSelectWidth: false, bordered: false, value: rule.rule.accrualType, onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { accrualType: value }) })); });
                                    }, children: timeoffAccrualTypeOptions.map(function (option) {
                                        return (_jsx(Option, { value: option.id, children: option.displayLabel }, option.id));
                                    }) })] }))] }), _jsx(Divider, {}), _jsxs("div", { className: "TimeOffRuleDetails justify-between flex", children: [_jsx(Switch, { checked: carryOverSettingStatus, onChange: function (checked) {
                                return setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { carryOverDetails: checked
                                            ? {
                                                carryOverLimitHours: 0,
                                                carryOverLimitType: 'AT_ANY_TIME',
                                            }
                                            : undefined }) })); });
                            } }), _jsx("div", { className: "ml-5 font-semibold py-1.5 flex-grow", children: "Limit Carry Over" }), carryOverSettingStatus && rule.rule.carryOverDetails && (_jsxs(_Fragment, { children: [_jsx(InputNumber, { className: "TimeoffNumberInput", bordered: false, value: rule.rule.carryOverDetails.carryOverLimitHours, onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) { return (__assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { carryOverDetails: {
                                                    carryOverLimitHours: value,
                                                    carryOverLimitType: 'AT_ANY_TIME',
                                                } }) })); });
                                    }, max: 1000, min: 0 }), _jsxs("span", { className: "text-xs font-semibold mr-5", children: [Pluralize('hr', rule.rule.carryOverDetails.carryOverLimitHours), " at"] }), _jsx(Select, { dropdownMatchSelectWidth: false, bordered: false, value: rule.rule.carryOverDetails.carryOverLimitType, onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) {
                                            var _a;
                                            if (!prev.rule.carryOverDetails)
                                                return prev;
                                            return __assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { carryOverDetails: __assign(__assign({}, prev.rule.carryOverDetails), { carryOverLimitType: value, carryOverReference: value === 'ASSIGNED_DATE'
                                                            ? moment()
                                                                .add(1, 'day')
                                                                .startOf('day')
                                                                .format('YYYY-MM-DD')
                                                            : value === 'DATE_SCHEMA'
                                                                ? (_a = dateSchemas[0]) === null || _a === void 0 ? void 0 : _a.id
                                                                : undefined }) }) });
                                        });
                                    }, children: CarryOverLimitTypes.filter(function (type) { return type !== 'DATE_SCHEMA' || dateSchemas.length > 0; }).map(function (option) {
                                        return (_jsx(Option, { value: option, children: CarryOverLimitTypeToLabel(option) }, option));
                                    }) }), rule.rule.carryOverDetails.carryOverLimitType === 'ASSIGNED_DATE' && (_jsx(ZiraDatePicker, { disabledDate: function (current) {
                                        return current && current < moment().endOf('day');
                                    }, className: "ml-5", defaultValue: moment().add(1, 'day'), onChange: function (date) {
                                        if (!date)
                                            return;
                                        setRule(function (prev) {
                                            if (!prev.rule.carryOverDetails)
                                                return prev;
                                            return __assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { carryOverDetails: __assign(__assign({}, prev.rule.carryOverDetails), { carryOverReference: date.format('YYYY-MM-DD') }) }) });
                                        });
                                    } })), rule.rule.carryOverDetails.carryOverLimitType === 'DATE_SCHEMA' && (_jsx(Select, { dropdownMatchSelectWidth: false, bordered: false, value: (_b = dateSchemas[0]) === null || _b === void 0 ? void 0 : _b.id, className: "ml-5", onChange: function (value) {
                                        if (!value)
                                            return;
                                        setRule(function (prev) {
                                            if (!prev.rule.carryOverDetails)
                                                return prev;
                                            return __assign(__assign({}, prev), { rule: __assign(__assign({}, prev.rule), { carryOverDetails: __assign(__assign({}, prev.rule.carryOverDetails), { carryOverReference: value }) }) });
                                        });
                                    }, children: dateSchemas.map(function (option) {
                                        return (_jsx(Option, { value: option.id, children: option.name }, option.id));
                                    }) }))] }))] })] }) }));
};
export default React.memo(TimeOffRule);
