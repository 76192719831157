var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SendEmailIconSvg = function () { return (_jsx("svg", { width: "17", height: "19", viewBox: "0 0 17 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.4896 3.93202L4.06922 7.14808L7.43736 9.72092L12.4896 3.93202ZM8.3423 10.8446L10.4363 14.4435L13.0537 5.44618L8.3423 10.8446ZM14.4631 1.56786C14.9842 1.36881 15.4801 1.93694 15.3063 2.5341L11.2701 16.4084C11.0947 17.0114 10.3907 17.1194 10.083 16.5905L7.00193 11.2951C6.9531 11.2111 6.89026 11.1391 6.81703 11.0832L2.19546 7.55289C1.73387 7.20029 1.82815 6.39364 2.35444 6.19262L14.4631 1.56786Z", fill: "black" }) })); };
export var SendEmailIcon = function (props) { return (_jsx(Icon, __assign({ component: SendEmailIconSvg }, props))); };
