var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import ZiraRowsSkeleton from '../../../../../ui/ZiraRowsSkeleton';
import DashboardAlertTable from './DashboardAlertTable';
import './DashboardTableBuilder.scss';
export var DASHBOARD_TABLE_PAGINATION_SIZE = 20;
var DashboardAlertTableWrapper = function (props) {
    var _a, _b, _c;
    var shiftAlertProps = props.shiftAlertProps, userAlertProps = props.userAlertProps;
    var _d = shiftAlertProps !== null && shiftAlertProps !== void 0 ? shiftAlertProps : {}, selectedShiftStatus = _d.selectedShiftStatus, selectedTimeRange = _d.selectedTimeRange;
    var selectedUserStatus = (userAlertProps !== null && userAlertProps !== void 0 ? userAlertProps : {}).selectedUserStatus;
    var _e = useDashboardApi(), useGetShiftAlertTableData = _e.useGetShiftAlertTableData, useGetUserAlertTableData = _e.useGetUserAlertTableData;
    var _f = useState(0), currentTablePage = _f[0], setCurrentTablePage = _f[1];
    var _g = useState([]), tableRows = _g[0], setTableRows = _g[1];
    var _h = useGetShiftAlertTableData({
        paramsPath: "shiftAlertType=".concat((_a = selectedShiftStatus === null || selectedShiftStatus === void 0 ? void 0 : selectedShiftStatus.type) !== null && _a !== void 0 ? _a : '', "&dateRangeType=").concat(selectedTimeRange !== null && selectedTimeRange !== void 0 ? selectedTimeRange : ''),
        pagination: {
            page: currentTablePage,
            size: DASHBOARD_TABLE_PAGINATION_SIZE,
        },
    }, {
        enabled: !!selectedShiftStatus && !!selectedTimeRange,
    }), shiftTableData = _h.data, isShiftTableDataLoading = _h.isLoading;
    var _j = useGetUserAlertTableData({
        paramsPath: "userAlertType=".concat((_b = selectedUserStatus === null || selectedUserStatus === void 0 ? void 0 : selectedUserStatus.type) !== null && _b !== void 0 ? _b : ''),
        pagination: {
            page: currentTablePage,
            size: DASHBOARD_TABLE_PAGINATION_SIZE,
        },
    }, {
        enabled: !!selectedUserStatus,
    }), userTableData = _j.data, isUserTableDataLoading = _j.isLoading;
    // Reset pagination page when shift/user status and time range update
    useEffect(function () {
        if (shiftAlertProps) {
            setCurrentTablePage(0);
            // Reset scroll position when shift prams changes
            var shiftTableEle = document.getElementById('ShiftAlertTable');
            if (shiftTableEle)
                shiftTableEle.scrollTop = 0;
        }
    }, [shiftAlertProps]);
    useEffect(function () {
        if (userAlertProps) {
            setCurrentTablePage(0);
            // Reset scroll position when user prams changes
            var userTableEle = document.getElementById('UserAlertTable');
            if (userTableEle)
                userTableEle.scrollTop = 0;
        }
    }, [userAlertProps]);
    // Table columns will only be set once at the first time fetch table data
    // Since columns data will always be the same during each fetch
    // This will avoid table scroll bar reset to top during every load more
    var _k = useState([]), tableColumns = _k[0], setTableColumns = _k[1];
    useEffect(function () {
        if (shiftTableData && currentTablePage === 0) {
            setTableColumns(shiftTableData.columns);
        }
    }, [currentTablePage, shiftTableData]);
    useEffect(function () {
        if (shiftTableData && currentTablePage === 0) {
            setTableRows(shiftTableData.alerts);
        }
        else if (shiftTableData && currentTablePage > 0) {
            setTableRows(function (prev) { return __spreadArray(__spreadArray([], prev, true), shiftTableData.alerts, true); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftTableData]);
    useEffect(function () {
        if (userTableData && currentTablePage === 0) {
            setTableColumns(userTableData.columns);
        }
    }, [currentTablePage, userTableData]);
    useEffect(function () {
        if (userTableData && currentTablePage === 0) {
            setTableRows(userTableData.alerts);
        }
        else if (userTableData && currentTablePage > 0) {
            setTableRows(function (prev) { return __spreadArray(__spreadArray([], prev, true), userTableData.alerts, true); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userTableData]);
    var onTableLoadMore = useCallback(function () {
        setCurrentTablePage(function (prev) { return prev + 1; });
    }, []);
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        // Make sure horizontally scroll table won't trigger load more
        if (event.target.scrollLeft > 0)
            return;
        var atBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 1;
        if (atBottom && (!isShiftTableDataLoading || !isUserTableDataLoading)) {
            onTableLoadMore();
        }
    }, [isShiftTableDataLoading, isUserTableDataLoading, onTableLoadMore]);
    if (tableRows.length === 0) {
        return (_jsx("div", { className: "h-[300px] text-center text-lg font-medium leading-[300px] text-gray-300", children: "No Alerts" }));
    }
    // Only show loading state at first fetch
    else if ((isShiftTableDataLoading || isUserTableDataLoading) && currentTablePage === 0) {
        return _jsx(ZiraRowsSkeleton, { rowNumber: 4, height: 300 });
    }
    return (_jsx(DashboardAlertTable, { columns: tableColumns, rows: tableRows, extraActionColumnBtn: ((_c = userAlertProps === null || userAlertProps === void 0 ? void 0 : userAlertProps.selectedUserStatus) === null || _c === void 0 ? void 0 : _c.type) === 'NOT_SIGNED_UP', handlePaginationScroll: handlePaginationScroll, tableId: shiftAlertProps ? 'ShiftAlertTable' : 'UserAlertTable' }));
};
export default React.memo(DashboardAlertTableWrapper);
