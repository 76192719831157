var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { usePageApi } from 'src/hooks/api';
import { useDebounce } from 'src/hooks/component';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
import { InView } from 'react-intersection-observer';
export var ICON_OPTION_SIZE = 16;
var ICON_COLOR_OPTIONS = [
    '',
    '#9D5A3E',
    '#FF4A43',
    '#FD7238',
    '#FFC047',
    '#00A76F',
    '#167EE4',
    '#2F4DDF',
    '#5C5396',
];
var ICON_OPTIONS_PAGINATION_SIZE = 35;
var CustomIconPicker = function (_a) {
    var _b;
    var currentIcon = _a.currentIcon, onSaveIcon = _a.onSaveIcon;
    var _c = useState([]), iconOptions = _c[0], setIconOptions = _c[1];
    var _d = useState((_b = currentIcon === null || currentIcon === void 0 ? void 0 : currentIcon.backgroundColor) !== null && _b !== void 0 ? _b : ICON_COLOR_OPTIONS[0]), currentColor = _d[0], setCurrentColor = _d[1];
    var _e = useState(0), iconOptionsPage = _e[0], setIconOptionsPage = _e[1];
    var _f = useState(''), searchQuery = _f[0], setSearchQuery = _f[1];
    var debouncedSearchQuery = useDebounce(searchQuery, 500);
    var useGetPageIconOptions = usePageApi().useGetPageIconOptions;
    var _g = useGetPageIconOptions({
        query: debouncedSearchQuery || undefined,
        pagination: {
            page: iconOptionsPage,
            size: ICON_OPTIONS_PAGINATION_SIZE,
        },
    }), data = _g.data, isFetching = _g.isFetching;
    useEffect(function () {
        if (data && iconOptionsPage === 0) {
            setIconOptions(data.icons);
        }
        else if (data && iconOptionsPage > 0) {
            setIconOptions(function (prevIcons) { return __spreadArray(__spreadArray([], prevIcons, true), data.icons, true); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    var onIconSearch = useCallback(function (e) {
        setTimeout(function () { return setIconOptionsPage(0); }, 499);
        setSearchQuery(e.target.value);
    }, []);
    var onLoadMoreIcons = useCallback(function () {
        setIconOptionsPage(function (prev) { return prev + 1; });
    }, []);
    var handlePaginationScroll = useCallback(function (event) {
        event.stopPropagation();
        var atBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <=
            100;
        if (atBottom && !isFetching) {
            onLoadMoreIcons();
        }
    }, [isFetching, onLoadMoreIcons]);
    var gridRef = useRef(null);
    return (_jsxs("div", { className: "flex w-[240px] flex-col gap-y-[16px]", children: [_jsx(Input, { className: "IconSearch", placeholder: "Search", allowClear: true, onChange: onIconSearch, prefix: _jsx(SearchOutlinedIcon, {}) }), _jsx("div", { className: "flex justify-between", children: ICON_COLOR_OPTIONS.map(function (color) { return (_jsx("div", { className: "h-[19px] w-[19px] cursor-pointer rounded", style: {
                        backgroundColor: color,
                        border: currentColor === color
                            ? 'solid 2px black'
                            : !color
                                ? 'solid 2px #D3D3D7'
                                : undefined,
                    }, onClick: function () { return setCurrentColor(color); } }, color)); }) }), _jsx("div", { className: "w-full border border-gray-50" }), _jsx("div", { className: "mt-[-16px] grid h-min w-[256px] grid-cols-7 gap-x-[12px] gap-y-[8px] overflow-hidden overflow-y-auto pr-[16px] pt-[16px]", style: {
                    maxHeight: iconOptionsPage === 0 ? 168 : 225,
                    paddingBottom: iconOptionsPage === 0 ? undefined : 8,
                }, onScroll: handlePaginationScroll, children: iconOptions.map(function (_a, index) {
                    var content = _a.content;
                    var svg = currentColor ? content : content.replaceAll('#ffffff', '#000000');
                    return (_jsx(InView, { root: gridRef.current, rootMargin: '200px 0px', children: function (_a) {
                            var inView = _a.inView, ref = _a.ref;
                            return (_jsx("div", { ref: ref, className: "h-[24px] w-[24px]", children: inView && (_jsx("div", { className: "flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded text-white transition-colors duration-500", style: {
                                        backgroundColor: currentColor,
                                    }, onClick: function () {
                                        return onSaveIcon({
                                            content: content,
                                            backgroundColor: currentColor || undefined,
                                        });
                                    }, children: _jsx("img", { height: ICON_OPTION_SIZE, width: ICON_OPTION_SIZE, src: "data:image/svg+xml;utf8,".concat(encodeURIComponent(svg)) }) })) }));
                        } }, index));
                }) }), (data === null || data === void 0 ? void 0 : data.icons.length) === ICON_OPTIONS_PAGINATION_SIZE && iconOptionsPage === 0 && (_jsx("div", { className: "light-gray-btn h-[32px] w-full justify-center text-base font-medium", onClick: onLoadMoreIcons, children: "View More" }))] }));
};
export default React.memo(CustomIconPicker);
