import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from 'antd';
import React from 'react';
import { useVT } from 'virtualizedtableforantd4';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import { scheduleRulesDrawerVisibleState, scheduleTemplateDrawerVisibleState } from 'src/state';
import { useRecoilValue } from 'recoil';
import { VIRTUALIZED_CALENDAR_DISABLED } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
var ScheduleANTDTableBuilder = function (_a) {
    var blockId = _a.blockId, dataSource = _a.dataSource, tableColumns = _a.tableColumns, isLoading = _a.isLoading, tableScrollHeight = _a.tableScrollHeight, tableSummary = _a.tableSummary;
    var isVirtualDisabled = useRecoilValue(featureEnableStatusState(VIRTUALIZED_CALENDAR_DISABLED));
    // Virtual Scroll
    var vt = useVT(function () { return ({
        id: blockId,
        scroll: { y: tableScrollHeight, scrollToFirstRowOnChange: false },
    }); }, [blockId, tableScrollHeight])[0];
    var templateDrawerVisible = useRecoilValue(scheduleTemplateDrawerVisibleState(blockId));
    var rulesDrawerVisible = useRecoilValue(scheduleRulesDrawerVisibleState(blockId));
    return (_jsx("div", { className: "TableWrapper", children: _jsx(Table, { dataSource: dataSource, pagination: false, scroll: { y: tableScrollHeight, scrollToFirstRowOnChange: false, x: 0 }, bordered: false, className: rulesDrawerVisible
                ? 'ScheduleTableShrinked'
                : templateDrawerVisible
                    ? 'ScheduleTableShrinkedTemplates'
                    : 'ScheduleTable', components: !isVirtualDisabled ? vt : undefined, loading: {
                spinning: isLoading,
                indicator: (_jsx("div", { className: "flex items-center justify-center", children: _jsx(LoadingLogo, { className: "main-loading-logo" }) })),
            }, summary: tableSummary
                ? function () { return _jsx(Table.Summary, { fixed: "bottom", children: tableSummary }); }
                : undefined, children: tableColumns }) }));
};
export default React.memo(ScheduleANTDTableBuilder);
