import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import ZiraTimePicker from 'src/ui/ZiraTimePicker';
var DISPLAY_TIME_FORMAT = 'hh:mm A';
var ShiftTemplateTimePickerCell = function (props) {
    var isStartTime = props.isStartTime, time = props.time, setTime = props.setTime, showCrossDayIndicator = props.showCrossDayIndicator;
    var _a = useState(false), isClicked = _a[0], setIsClicked = _a[1];
    var onTimeSelect = useCallback(function (value) {
        //Convert display time format into time value format
        var newTime = moment(value, DISPLAY_TIME_FORMAT).format('HH:mm');
        setTime(newTime);
    }, [setTime]);
    var displayCrossTimeIndicator = !isStartTime && showCrossDayIndicator === true;
    return (_jsx("div", { className: "SchemaRow SpecialTimePicker w-full ".concat(!isStartTime ? 'TemplateEndTimePicker' : ''), children: _jsxs("div", { className: "ModalCellWrapper".concat(isClicked ? ' border-modalAccent bg-white' : '', " bg-white"), children: [isStartTime && (_jsx("div", { className: "CellIcon", style: { left: 16 }, children: _jsx(ClockCircleOutlined, {}) })), _jsx("span", { className: "SchemaName", children: "Scheduled ".concat(isStartTime ? 'Start' : 'End') }), _jsxs("div", { className: "CellContent ".concat(displayCrossTimeIndicator ? 'CellContentWithIndicator' : ''), children: [_jsx(ZiraTimePicker, { value: moment(time, DISPLAY_TIME_FORMAT), onFocus: function () { return setIsClicked(true); }, onBlur: function () {
                                setIsClicked(false);
                            }, placeholder: "Select ".concat(isStartTime ? 'Start' : 'End', " Time"), onSelect: onTimeSelect, onChange: function (time) {
                                onTimeSelect(time);
                            } }), displayCrossTimeIndicator && (_jsx("div", { className: "TemplateTimeCrossDayIndicator", children: "+1 day" }))] })] }) }));
};
export default React.memo(ShiftTemplateTimePickerCell);
