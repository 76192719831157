import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { useSetting } from 'src/hooks/api';
import { toggleList } from 'src/utils/Tools/LodashTool';
import { Checkbox, Dropdown, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
var MobileRequiredFieldsDropdownMenu = function (_a) {
    var type = _a.type, rule = _a.rule, onChange = _a.onChange, accessGroupDetail = _a.accessGroupDetail;
    var _b = useState(false), dropdownVisible = _b[0], setDropdownVisible = _b[1];
    var accessGroupId = accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.id;
    var useGetMobileRequiredFields = useSetting().useGetMobileRequiredFields;
    var data = useGetMobileRequiredFields({
        body: {
            type: type,
            accessGroupId: accessGroupId,
        },
        options: {
            enabled: !!accessGroupId && dropdownVisible,
        },
    }).data;
    var fieldKeys = data === null || data === void 0 ? void 0 : data.requiredFields.filter(function (f) { return (rule === 'OPTIONAL' ? f.isOptional : f.isRequired); }).map(function (f) { return f.id; });
    var settingKeysRef = useRef();
    var toggleRequiredKeys = function (key) {
        settingKeysRef.current = toggleList(settingKeysRef.current || fieldKeys || [], key);
    };
    var handleDropdownVisibleChange = useCallback(function (visible) {
        setDropdownVisible(visible);
        var keysToSave = settingKeysRef.current || fieldKeys;
        if (!visible && keysToSave) {
            onChange(keysToSave);
        }
    }, [fieldKeys, onChange]);
    return (_jsx(Dropdown, { menu: {
            items: MobileRequiredFieldsOption(type, toggleRequiredKeys, rule, accessGroupDetail, (data === null || data === void 0 ? void 0 : data.requiredFields) || []),
        }, trigger: ['click'], onOpenChange: handleDropdownVisibleChange, getPopupContainer: function (triggerNode) { return triggerNode; }, children: _jsxs("div", { className: "rounded bg-gray-50 px-3 py-1 font-medium hover:cursor-pointer", children: ["Options ", _jsx(DownOutlined, {})] }) }));
};
var MobileRequiredFieldsOption = function (type, onToggleOption, rule, accessGroupDetail, fields) {
    var requiredFields = type === 'CLOCK_IN'
        ? accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.mobileSettings.timeClock.subPermissions.clockInRequiredFields
        : accessGroupDetail === null || accessGroupDetail === void 0 ? void 0 : accessGroupDetail.mobileSettings.timeClock.subPermissions.clockOutRequiredFields;
    if (!fields) {
        return [
            {
                key: 'loading',
                label: _jsx("div", { className: "flex items-center justify-between", children: "Loading..." }),
            },
        ];
    }
    else {
        if (!fields.length) {
            return [
                {
                    key: 'empty field',
                    label: (_jsx("div", { className: "flex items-center justify-between", children: "No editable custom fields in data source shift" })),
                },
            ];
        }
        else {
            return fields.map(function (field) {
                var isSetUpAsRequiredField = requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.values.find(function (o) { return o === field.id && field.isRequired; });
                return {
                    key: field.id,
                    label: (_jsxs(Row, { onClick: function (e) { return e.stopPropagation(); }, align: "middle", justify: "space-between", children: [field.name, rule == 'OPTIONAL' &&
                                isSetUpAsRequiredField &&
                                (requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.enabled) === true ? (_jsx("div", { className: "ml-1 text-red-200", children: "(Required)" })) : (_jsx(Checkbox, { onChange: function () { return onToggleOption(field.id); }, defaultChecked: (requiredFields === null || requiredFields === void 0 ? void 0 : requiredFields.values.includes(field.id)) ||
                                    (rule == 'REQUIRED' ? field.isRequired : field.isOptional) }))] })),
                };
            });
        }
    }
};
export default MobileRequiredFieldsDropdownMenu;
