import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import React, { useState } from 'react';
import { intervalOptions } from '../../../Utils';
var TemplateIntervalSelectorCell = function (_a) {
    var interval = _a.interval, updateRecordIntervalNumber = _a.updateRecordIntervalNumber;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "TemplateIntervalCell", children: _jsx(Select, { bordered: false, dropdownMatchSelectWidth: false, value: interval, onChange: function (value) {
                        updateRecordIntervalNumber(value);
                    }, onDropdownVisibleChange: function (open) {
                        setDropdownOpen(open);
                    }, options: intervalOptions() }) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setDropdownOpen(false);
                }, style: { display: dropdownOpen ? 'block' : 'none' } })] }));
};
export default React.memo(TemplateIntervalSelectorCell);
