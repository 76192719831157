var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TBTransparentLogoSvg = function () { return (_jsxs("svg", { width: "63", height: "55", viewBox: "0 0 63 55", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M44.6995 34.8777V42.1055C45.2065 42.4429 45.6743 42.7421 46.1265 43.0213L54.0895 47.9075L57.0355 42.8546L49.0725 37.9664C47.5652 37.0406 46.0952 36.0023 44.6995 34.8777Z", fill: "#121212" }), _jsx("path", { d: "M55.0369 27.2619C50.8283 24.6792 47.2657 21.0262 44.6995 16.6682V26.2779C46.9036 28.6276 49.3896 30.658 52.0909 32.3148L60.0539 37.201L62.9999 32.1481L55.0369 27.2619Z", fill: "#121212" }), _jsx("path", { d: "M34.5684 54.2057H40.3081V22.093C38.7675 25.716 36.8392 29.1703 34.5684 32.3715V54.2057Z", fill: "#121212" }), _jsx("path", { d: "M22.6872 54.2056H28.4269V39.5952C26.6416 41.3504 24.7133 42.9912 22.6872 44.4814V54.2056Z", fill: "#121212" }), _jsx("path", { d: "M28.7448 22.5587C27.032 25.5672 24.9786 28.3547 22.6434 30.847C20.0458 33.6164 17.1135 36.0123 13.9287 37.9664L5.96571 42.8546L8.91171 47.9075L16.8747 43.0213C31.2817 34.1787 40.2508 18.0119 40.3115 0.7948H34.5703C34.5409 8.3942 32.5286 15.9153 28.7448 22.5587Z", fill: "#121212" }), _jsx("path", { d: "M28.4284 0.794327H22.6891C22.6304 11.5889 16.9987 21.7188 7.963 27.2617L0 32.1499L2.946 37.2028L10.909 32.3166C21.6692 25.7153 28.3697 13.6495 28.4284 0.794327Z", fill: "#121212" })] })); };
export var TeamBridgeTransparentLogo = function (props) { return (_jsx(Icon, __assign({ component: TBTransparentLogoSvg }, props))); };
