import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import './HelpDrawer.scss';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { filter, slice } from 'lodash';
import { helpDrawerSelectedArticleIdState, helpDrawerStepIndexState, isHelpDrawerVisibleState, } from 'src/state/selectors/withHelpDrawerState';
import HelpHome from './HelpHome';
import HelpTopicCollectionView from './HelpTopicCollectionView';
import HelpArticleView from './HelpArticleView';
var HelpDrawer = function (_a) {
    var showOpenButton = _a.showOpenButton;
    var isDrawerVisible = useRecoilValue(isHelpDrawerVisibleState);
    var stepIndex = useRecoilValue(helpDrawerStepIndexState);
    var _b = useState(), selectedTopic = _b[0], setSelectedTopic = _b[1];
    var goBack = useRecoilCallback(function (_a) {
        var reset = _a.reset, set = _a.set;
        return function () {
            if (stepIndex === 2) {
                // directly go to article view
                if (!selectedTopic) {
                    set(helpDrawerStepIndexState, 0);
                }
                //go to article from topic collection page
                else {
                    set(helpDrawerStepIndexState, 1);
                }
                reset(helpDrawerSelectedArticleIdState);
            }
            if (stepIndex === 1) {
                setSelectedTopic(undefined);
                set(helpDrawerStepIndexState, 0);
            }
        };
    }, [selectedTopic, stepIndex]);
    var goToArticle = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (selectedArticle, collection) {
            if (collection) {
                setSelectedTopic(collection);
            }
            set(helpDrawerSelectedArticleIdState, selectedArticle.id);
            set(helpDrawerStepIndexState, 2);
        };
    }, []);
    var goToTopicCollectionView = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (selectedTopic) {
            setSelectedTopic(selectedTopic);
            set(helpDrawerStepIndexState, 1);
        };
    }, []);
    var onDrawerOpen = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            set(isHelpDrawerVisibleState, true);
        };
    }, []);
    var onDrawerClose = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            set(isHelpDrawerVisibleState, false);
            setTimeout(function () {
                set(helpDrawerStepIndexState, 0);
                set(helpDrawerSelectedArticleIdState, '');
            }, 400);
        };
    }, []);
    var getRelatedArticlesInCollection = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (collection) {
            var currentArticleId = snapshot
                .getLoadable(helpDrawerSelectedArticleIdState)
                .valueMaybe();
            if (!currentArticleId || !collection) {
                return [];
            }
            var collectionWithoutCurrentArticle = filter(collection.articles, function (article) { return article.id !== currentArticleId; });
            return slice(collectionWithoutCurrentArticle, 0, 3);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [showOpenButton && (_jsxs("div", { className: "centralPanelHeaderBtn", onClick: onDrawerOpen, children: [_jsx(HelpIcon, { className: "activityIcon mr-1 text-black" }), _jsx("span", { className: "helpText HeaderButtonText", children: "Help" })] })), _jsxs(Drawer, { open: isDrawerVisible, onClose: onDrawerClose, closable: false, destroyOnClose: true, className: "HelpDrawer", width: 360, children: [_jsxs("div", { className: "mb-5 flex items-start justify-between border-b-[1px] border-b-gray-60 px-3 py-2", children: [_jsxs("div", { className: "flex", children: [stepIndex !== 0 && (_jsx(Button, { className: "GoBackBtn flex", icon: _jsx(ArrowLeftOutlined, {}), onClick: goBack })), _jsx("div", { className: "ml-2 text-lg font-semibold", children: "Help" })] }), _jsx(Button, { className: "CloseDrawerBtn", icon: _jsx(CloseOutlined, {}), onClick: onDrawerClose })] }), _jsxs("div", { className: "HelpMainContent", children: [stepIndex === 0 && (_jsx(HelpHome, { goToTopicCollection: goToTopicCollectionView, goToArticle: goToArticle })), stepIndex === 1 && selectedTopic && (_jsx(HelpTopicCollectionView, { topicInfo: selectedTopic, goToArticle: goToArticle })), stepIndex === 2 && (_jsx(HelpArticleView, { goToRelatedArticle: goToArticle, relatedArticles: getRelatedArticlesInCollection(selectedTopic) }))] }), _jsxs("div", { className: "DrawerFixedFooter flex w-full items-center justify-between px-5", children: [_jsx("div", { children: "Still need more help?" }), _jsx(Button, { className: "IntercomHelpBtn", id: "IntercomHelpBtn", onClick: onDrawerClose, children: "Contact Support" })] })] })] }));
};
export default React.memo(HelpDrawer);
