import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import TaskListItemTag from './TaskListItemTag';
var TaskRuleActiveTag = function (_a) {
    var isActive = _a.isActive;
    var bgColor = isActive ? '#bce7d5' : '#ebebeb';
    var textColor = isActive ? '#33b35c' : '#939393';
    return (_jsxs(TaskListItemTag, { backgroundColor: bgColor, children: [_jsx("div", { className: "w-1.5 h-1.5 relative rounded-[20px]", style: { backgroundColor: textColor } }), _jsx("div", { style: {
                    color: textColor,
                }, children: "Active" })] }));
};
export default React.memo(TaskRuleActiveTag);
