var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PhoneOutlinedSvg = function () { return (_jsx("svg", { width: "22", height: "20", viewBox: "-4 -2 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 4C2 2.89543 2.89543 2 4 2H9.3C10.3493 2 11.2 2.85066 11.2 3.9V8.66575C11.2 9.46872 10.6952 10.185 9.93904 10.4551L8.6063 10.931C9.5135 12.7691 10.9808 14.2826 12.7848 15.2476L13.5647 13.7992C13.8961 13.1838 14.5386 12.8 15.2376 12.8H20.1C21.1493 12.8 22 13.6507 22 14.7V20C22 21.1046 21.1046 22 20 22H17.4C8.89482 22 2 15.1052 2 6.6V4ZM9.2 4H4V6.6C4 14.0006 9.99938 20 17.4 20H20V14.8H15.2973L13.6641 17.8331L12.8265 17.4807C9.82916 16.2194 7.46308 13.7571 6.32992 10.6942L5.97898 9.74564L9.2 8.59528V4Z", fill: "#A7A8AC" }) })); };
export var PhoneOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: PhoneOutlinedSvg }, props))); };
