import { jsx as _jsx } from "react/jsx-runtime";
import { MessageOutlined, WifiOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useSetRecoilState } from 'recoil';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { recipientsDropdownOpenState, selectedThreadState } from 'src/state/atoms/chatState';
import { isCreatingThreadSelector } from 'src/state/selectors/withChatState';
import { CreateThreadIcon } from 'src/utils/icons/ChatIcons/CreateThreadIcon';
import { ThreadType } from './interface';
export var CreateThreadButton = function () {
    var setCreateThreadSelector = useSetRecoilState(isCreatingThreadSelector);
    var setRecipientsDropdownOpen = useSetRecoilState(recipientsDropdownOpenState);
    var setSelectedThread = useSetRecoilState(selectedThreadState);
    var createThreadButtonDropdownItems = [
        {
            key: 'broadcast',
            icon: _jsx(WifiOutlined, {}),
            label: 'Broadcast',
            onClick: function () {
                setSelectedThread(undefined);
                setCreateThreadSelector(ThreadType.BROADCAST);
                setRecipientsDropdownOpen(true);
            },
        },
        {
            key: 'dm',
            icon: _jsx(MessageOutlined, {}),
            label: 'Direct Message',
            onClick: function () {
                setSelectedThread(undefined);
                setCreateThreadSelector(ThreadType.DM);
                setRecipientsDropdownOpen(true);
            },
        },
    ];
    return (_jsx(ZiraDropdown, { menu: { items: createThreadButtonDropdownItems }, trigger: ['click'], children: _jsx(Button, { className: "ChatSidebarNewButton", icon: _jsx(CreateThreadIcon, {}), onClick: function (e) { return e.preventDefault(); } }) }));
};
