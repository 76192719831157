import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { RollupConfigurationContext } from 'src/components/RollupConfigurationModal/RollupConfigurationModal';
import { useContext, useMemo, useState } from 'react';
import { useAccountApi } from 'src/hooks/api';
import { NativePath, ValueDataType } from 'src/shared';
import { Select } from 'antd';
import { RollupType } from '../interface/constants';
var getLabel = function (rollupType) {
    if (rollupType === 'SUM')
        return 'Number to Sum';
    if (rollupType === 'AVERAGE')
        return 'Number to Average';
};
var numberTypes = [
    ValueDataType.NUMBER,
    ValueDataType.NATIVE_NUMBER,
    ValueDataType.NATIVE_DURATION,
    ValueDataType.RATING,
];
//These are not supported in the BE
var nativePaths = [
    NativePath.SHIFT_REGULAR_HOURS,
    NativePath.SHIFT_REGULAR_PAY,
    NativePath.SHIFT_SINGLE_OVERTIME_HOURS,
    NativePath.SHIFT_DOUBLE_OVERTIME_HOURS,
    NativePath.SHIFT_DOUBLE_OVERTIME_PAY,
    NativePath.SHIFT_TOTAL_PAY,
    NativePath.SHIFT_TOTAL_BILL,
];
var NumberSchemaSelector = function (_a) {
    var rollupType = _a.rollupType;
    var _b = useContext(RollupConfigurationContext), rollupConfiguration = _b.rollupConfiguration, setSumNumberSchema = _b.setSumNumberSchema;
    var refCollectionId = rollupConfiguration.refCollectionId;
    var useGetSchemasInCollection = useAccountApi().useGetSchemasInCollection;
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var isFetching = useGetSchemasInCollection({
        collectionId: refCollectionId !== null && refCollectionId !== void 0 ? refCollectionId : '',
        options: {
            enabled: !!refCollectionId,
            onSuccess: function (data) {
                var tempOptions = (data === null || data === void 0 ? void 0 : data.filter(function (column) { return numberTypes.includes(column.type); }).filter(function (column) {
                    return !column.nativePath || !nativePaths.includes(column.nativePath);
                }).map(function (columns) { return ({
                    label: columns.name,
                    value: columns.id,
                    type: columns.type,
                }); })) || [];
                setOptions(tempOptions);
            },
        },
    }).isFetching;
    var optionsWithoutRating = useMemo(function () { return options.filter(function (option) { return option.type !== ValueDataType.RATING; }); }, [options]);
    return (_jsxs("div", { children: [_jsxs("div", { className: "font-medium text-gray-400 text-xs", children: [" ", getLabel(rollupType)] }), _jsx(Select, { labelInValue: true, disabled: isFetching || options.length === 0, options: rollupConfiguration.rollupType === RollupType.AVERAGE
                    ? options
                    : optionsWithoutRating, onSelect: function (item) {
                    var value = item.value;
                    setSumNumberSchema(value);
                }, value: rollupConfiguration.sumNumberSchemaId
                    ? options.find(function (_a) {
                        var value = _a.value;
                        return value === rollupConfiguration.sumNumberSchemaId;
                    })
                    : undefined, className: "w-[161px]" })] }));
};
export default NumberSchemaSelector;
