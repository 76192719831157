var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var CloseConversationCheckMarkIconSvg = function () { return (_jsx("svg", { width: "13", height: "12", viewBox: "0 0 13 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4.58218 10.9591C4.63906 11.0166 4.71656 11.0485 4.79655 11.0485C4.8778 11.0491 4.95592 11.0166 5.01281 10.9591L12.7102 3.26666C12.7677 3.20916 12.7996 3.13166 12.7996 3.05103C12.7996 2.9704 12.7677 2.8929 12.7102 2.83602L10.904 1.03922C10.8471 0.981718 10.769 0.949219 10.6877 0.949219C10.6071 0.949219 10.529 0.981718 10.4721 1.03922L4.79212 6.71922L2.32332 4.23922C2.26582 4.18172 2.18832 4.14922 2.10706 4.14922C2.02581 4.14922 1.94831 4.18172 1.89143 4.23922L0.0895087 6.04114C0.0320079 6.09801 -0.000491315 6.17551 0.000134285 6.25677C-0.00236556 6.34052 0.0301343 6.42052 0.0895087 6.47927L4.58218 10.9591Z", fill: "white" }) })); };
export var CloseConversationCheckMarkIcon = function (props) { return (_jsx(Icon, __assign({ component: CloseConversationCheckMarkIconSvg }, props))); };
