var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useScheduleComponent, useWindowSize } from 'src/hooks/component';
import { scheduleTableState, tableSchedulingDisplaySettingsState, isCurrentPageCanvasTypeSelector, scheduleStatsSummaryState, tableCollectionIdSelector, scheduleTableRowState, scheduleZonedTimeRangeSelector, isCurrentPageScheduleTypeSelector, } from 'src/state';
import { useRecoilValue } from 'recoil';
import { ScheduleView } from 'src/services/ScheduleService';
import { disableScroll } from 'src/utils/Collection';
import AccountState from 'src/hooks/state/Account';
import ScheduleBottomBar from '../FullPageScheduleBottomBar/ScheduleBottomBar';
import { scheduleBottomBarHeightState, schedulingBlockBottomBarExpandedStatusState, schedulingBottomBarActiveTabState, } from '../FullPageScheduleBottomBar/Atom';
import NeedsCoverageLoadingBlockerModal from '../FullPageScheduleBottomBar/Needs&Coverage/NeedsCoverageLoadingBlockerModal';
import usePageBlockerForNeedsAndCoverage from '../../../hooks/component/usePageBlockerForNeedsAndCoverage';
import ScheduleDayView from './DayView/ScheduleDayView';
import ScheduleWeekViewTable from './WeekView/ScheduleWeekViewTable';
var DEFAULT_SCHEDULE_GROUP_HEADER_HEIGHT = 46;
var SCHEDULE_DAY_WEEK_TABLE_HEADER_HEIGHT = 43;
var SCHEDULE_2_WEEK_TABLE_HEADER_HEIGHT = 65;
var DEFAULT_SCHEDULE_CANVAS_HEIGHT = 212;
var ScheduleTableViewWrapper = function (props) {
    var _a;
    var tableId = props.tableId, type = props.type, isFetching = props.isFetching, respData = props.respData, addNewShift = props.addNewShift, openShiftDetailModal = props.openShiftDetailModal, componentRef = props.componentRef;
    var _b = useScheduleComponent(), fetchAndProcessScheduleTable = _b.fetchAndProcessScheduleTable, getScheduleEvaluation = _b.getScheduleEvaluation;
    var _c = usePageBlockerForNeedsAndCoverage(tableId), shouldShowBlockerModal = _c.shouldShowBlockerModal, currentNeedOrCoverageBeingApplied = _c.currentNeedOrCoverageBeingApplied;
    var zonedTimeRange = useRecoilValue(scheduleZonedTimeRangeSelector(tableId));
    var viewDisplaySettings = useRecoilValue(tableSchedulingDisplaySettingsState(tableId));
    var scheduleStatsSummaryData = useRecoilValue(scheduleStatsSummaryState(tableId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(tableId));
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var isSchedulePageType = useRecoilValue(isCurrentPageScheduleTypeSelector);
    var _d = useMemo(function () { return viewDisplaySettings; }, [viewDisplaySettings]), scheduleView = _d.viewType, showAll = _d.showAll;
    var rowData = useRecoilValue(scheduleTableRowState(tableId));
    var table = useRecoilValue(scheduleTableState(tableId));
    var autoSchedulerSetting = AccountState.useContainer().autoSchedulerSetting;
    useEffect(function () {
        if (table &&
            ![ScheduleView.DAY, ScheduleView.MONTH].includes(scheduleView) &&
            zonedTimeRange.length) {
            getScheduleEvaluation(zonedTimeRange[0], zonedTimeRange[zonedTimeRange.length - 1], tableId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSchedulerSetting, table]);
    var _e = useWindowSize(), _f = _e.windowHeight, windowHeight = _f === void 0 ? 600 : _f, _g = _e.windowWidth, windowWidth = _g === void 0 ? 0 : _g;
    var tableScrollDiv = (_a = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-body');
    // Dynamic table scroll height
    var _h = useState(0), tableScrollHeight = _h[0], setTableScrollHeight = _h[1];
    var isSchedulingBottomBarExpanded = useRecoilValue(schedulingBlockBottomBarExpandedStatusState(tableId));
    var bottomBarHeight = useRecoilValue(scheduleBottomBarHeightState(tableId));
    var bottomBarActiveTab = useRecoilValue(schedulingBottomBarActiveTabState(tableId));
    useEffect(function () {
        if (!(componentRef === null || componentRef === void 0 ? void 0 : componentRef.current))
            return;
        var modalHeaderObserver = new ResizeObserver(function (entries) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            if (entries[0]) {
                if (isCanvasPageType) {
                    var scheduleOptionHeaderHeight = (_c = (_b = (_a = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ScheduleBlockOptionHeader')) === null || _b === void 0 ? void 0 : _b.clientHeight) !== null && _c !== void 0 ? _c : DEFAULT_SCHEDULE_CANVAS_HEIGHT;
                    var tableSummaryHeight = (_f = (_e = (_d = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _d === void 0 ? void 0 : _d.querySelector('.ant-table-summary')) === null || _e === void 0 ? void 0 : _e.clientHeight) !== null && _f !== void 0 ? _f : 0;
                    var heightForStatsButton = 90;
                    setTableScrollHeight(windowHeight -
                        scheduleOptionHeaderHeight -
                        tableSummaryHeight -
                        heightForStatsButton);
                }
                else {
                    var bottomBarHeight_1 = 40;
                    if (isSchedulingBottomBarExpanded) {
                        bottomBarHeight_1 =
                            (_j = (_h = (_g = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _g === void 0 ? void 0 : _g.querySelector('.ScheduleBottomBarFullView')) === null || _h === void 0 ? void 0 : _h.clientHeight) !== null && _j !== void 0 ? _j : bottomBarHeight_1;
                    }
                    var calendarTableHeaderHeight = DEFAULT_SCHEDULE_GROUP_HEADER_HEIGHT +
                        ([ScheduleView.DAY, ScheduleView.WEEK].includes(viewDisplaySettings.viewType)
                            ? SCHEDULE_DAY_WEEK_TABLE_HEADER_HEIGHT
                            : SCHEDULE_2_WEEK_TABLE_HEADER_HEIGHT);
                    setTableScrollHeight(entries[0].contentRect.height - calendarTableHeaderHeight - bottomBarHeight_1);
                }
            }
        });
        modalHeaderObserver.observe(componentRef.current);
        return function () {
            modalHeaderObserver.disconnect();
        };
    }, [
        componentRef,
        isCanvasPageType,
        isSchedulingBottomBarExpanded,
        viewDisplaySettings.viewType,
        windowHeight,
    ]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f;
        var extraHeight = isCanvasPageType
            ? 296
            : document.querySelector('.SchedulePageBlockHeaderContainer')
                ? document.querySelector('.SchedulePageBlockHeaderContainer')
                    .clientHeight + 154
                : DEFAULT_SCHEDULE_CANVAS_HEIGHT;
        var calendarTableHeaderHeight = [ScheduleView.DAY, ScheduleView.WEEK].includes(viewDisplaySettings.viewType)
            ? 0
            : 22;
        var bottomBarHeight = 0;
        if (!isCanvasPageType) {
            bottomBarHeight = 40;
            if (isSchedulingBottomBarExpanded) {
                bottomBarHeight =
                    (_c = (_b = (_a = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ScheduleBottomBarFullView')) === null || _b === void 0 ? void 0 : _b.clientHeight) !== null && _c !== void 0 ? _c : bottomBarHeight;
            }
        }
        else {
            bottomBarHeight =
                (_f = (_e = (_d = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _d === void 0 ? void 0 : _d.querySelector('.ant-table-summary')) === null || _e === void 0 ? void 0 : _e.clientHeight) !== null && _f !== void 0 ? _f : 0;
        }
        setTimeout(function () {
            return setTableScrollHeight(windowHeight - (extraHeight + calendarTableHeaderHeight + bottomBarHeight));
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        viewDisplaySettings.statsOptions,
        viewDisplaySettings.viewType,
        windowHeight,
        windowWidth,
        isCanvasPageType,
        isSchedulingBottomBarExpanded,
        scheduleStatsSummaryData === null || scheduleStatsSummaryData === void 0 ? void 0 : scheduleStatsSummaryData.stats.length,
        bottomBarActiveTab,
        bottomBarHeight,
    ]);
    var focusOnBlock = useCallback(function () {
        var block = document.getElementById(tableId);
        block === null || block === void 0 ? void 0 : block.scrollIntoView();
        disableScroll(true);
    }, [tableId]);
    // Pagination on scroll
    var showAllRef = useRef(showAll);
    var _j = useState(false), pageLoading = _j[0], setPageLoading = _j[1];
    var pageLoadingRef = useRef(false);
    var tableSizeRef = useRef(rowData.length);
    var _k = useState(0), prevTableSize = _k[0], setPrevTableSize = _k[1];
    var prevTableSizeRef = useRef(prevTableSize);
    var isFetchingRef = useRef(isFetching);
    useEffect(function () {
        showAllRef.current = showAll;
        setPrevTableSize(0);
    }, [showAll]);
    useEffect(function () {
        pageLoadingRef.current = pageLoading;
    }, [pageLoading]);
    useEffect(function () {
        tableSizeRef.current = rowData.length;
    }, [rowData.length]);
    useEffect(function () {
        prevTableSizeRef.current = prevTableSize;
    }, [prevTableSize]);
    useEffect(function () {
        isFetchingRef.current = isFetching;
    }, [isFetching]);
    useEffect(function () {
        if (respData) {
            setPrevTableSize(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [respData]);
    var handleTableScroll = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var table, pixelsFromBottom, loadMore, currentScrollTop_1, checkScrollInterval_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    table = tableScrollDiv !== null && tableScrollDiv !== void 0 ? tableScrollDiv : (_a = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-body');
                    if (!(table && !pageLoadingRef.current && !isFetchingRef.current)) return [3 /*break*/, 5];
                    focusOnBlock();
                    pixelsFromBottom = table.scrollHeight - table.scrollTop - table.clientHeight;
                    loadMore = pixelsFromBottom <= 100;
                    if (!(loadMore &&
                        prevTableSizeRef.current !== tableSizeRef.current &&
                        showAllRef.current)) return [3 /*break*/, 4];
                    setPageLoading(true);
                    setPrevTableSize(tableSizeRef.current);
                    currentScrollTop_1 = table.scrollTop;
                    if (!(type === 'block')) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchAndProcessScheduleTable(tableId, true)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    if (type === 'reverseCollection') {
                        //TODO reverse collection logic here
                    }
                    _b.label = 3;
                case 3:
                    setPageLoading(false);
                    checkScrollInterval_1 = setInterval(function () {
                        if (table.scrollTop <= currentScrollTop_1) {
                            table.scrollTop = currentScrollTop_1;
                            clearInterval(checkScrollInterval_1);
                        }
                    }, 10);
                    return [3 /*break*/, 5];
                case 4:
                    if (pixelsFromBottom <= 0 || table.scrollTop <= 0) {
                        disableScroll(false);
                    }
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId, type]);
    var onDayViewDataLoadMore = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(prevTableSizeRef.current !== tableSizeRef.current && showAllRef.current)) return [3 /*break*/, 3];
                    pageLoadingRef.current = true;
                    setPrevTableSize(tableSizeRef.current);
                    if (!(type === 'block')) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchAndProcessScheduleTable(tableId, true)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    if (type === 'reverseCollection') {
                        //TODO reverse collection logic here
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [tableId, fetchAndProcessScheduleTable, type]);
    useEffect(function () {
        var _a, _b, _c;
        var table = (_b = tableScrollDiv !== null && tableScrollDiv !== void 0 ? tableScrollDiv : (_a = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.ant-table-body')) !== null && _b !== void 0 ? _b : (_c = componentRef === null || componentRef === void 0 ? void 0 : componentRef.current) === null || _c === void 0 ? void 0 : _c.querySelector('.ScheduleTableScrollContainer');
        if (table) {
            table.addEventListener('scroll', handleTableScroll);
        }
        return function () { return table === null || table === void 0 ? void 0 : table.removeEventListener('scroll', handleTableScroll); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableId, respData]);
    return (_jsxs(_Fragment, { children: [scheduleView === ScheduleView.DAY && (_jsx(ScheduleDayView, { blockId: tableId, addNewShift: addNewShift, openShiftDetailModal: openShiftDetailModal, onLoadMore: onDayViewDataLoadMore })), scheduleView !== ScheduleView.DAY && (_jsxs(_Fragment, { children: [_jsx(ScheduleWeekViewTable, __assign({ scheduleView: scheduleView, collectionId: collectionId, isLoading: isFetching || pageLoading, tableScrollHeight: tableScrollHeight, tableScrollDiv: tableScrollDiv }, props)), !isCanvasPageType && type === 'block' && (_jsx(ScheduleBottomBar, { blockId: tableId }))] })), shouldShowBlockerModal && currentNeedOrCoverageBeingApplied && isSchedulePageType && (_jsx(NeedsCoverageLoadingBlockerModal, __assign({}, currentNeedOrCoverageBeingApplied)))] }));
};
export default React.memo(ScheduleTableViewWrapper);
