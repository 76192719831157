import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import MobileTaskTemplateCard from '../TaskGroupDetail/MobileTaskTemplateCard';
import TaskGroupCompletionStatus from '../shared/TaskGroupCompletionStatus';
var TaskGroupMobilePreview = function (_a) {
    var groupInfo = _a.groupInfo;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "font-semibold text-lg", children: groupInfo.name }), _jsx(TaskGroupCompletionStatus, { completed: Math.ceil(groupInfo.orderedTaskIds.length / 2), total: groupInfo.orderedTaskIds.length }), _jsxs("div", { className: "mt-3", children: [_jsx("div", { className: "text-gray-400 font-semibold", children: "Description" }), _jsx("div", { children: groupInfo.description })] }), _jsxs("div", { className: "mt-5", children: [_jsx("div", { className: "text-gray-400 font-semibold", children: "Tasks" }), _jsx("div", { className: "mt-3", children: groupInfo.orderedTaskIds.map(function (taskId, index) {
                            var isChecked = index < groupInfo.orderedTaskIds.length / 2;
                            return (_jsx(MobileTaskTemplateCard, { taskId: taskId, isChecked: isChecked }, taskId));
                        }) })] })] }));
};
export default React.memo(TaskGroupMobilePreview);
