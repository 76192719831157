import moment from 'moment-timezone';
export var timeValidation = function (time) {
    return moment(time, 'h:mm A', true).isValid() || false;
};
export var generateStartTimeIntervals = function () {
    var interval = 30; //minutes interval
    var times = []; // time array
    var startTime = 0; // start time
    var ap = ['AM', 'PM']; // AM-PM
    //loop to increment the time and push results in array
    for (var i = 0; startTime < 24 * 60; i++) {
        var hh = Math.floor(startTime / 60); // getting hours of day in 0-24 format
        var mm = startTime % 60; // getting minutes of the hour in 0-55 format
        if (hh % 12 === 0) {
            times.push('12' + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)]);
        }
        else {
            var time = ('' + (hh % 12)).slice(-2) +
                ':' +
                ('0' + mm).slice(-2) +
                ' ' +
                ap[Math.floor(hh / 12)];
            times.push(time);
        }
        startTime = startTime + interval;
    }
    return times;
};
export var generateEndTimeIntervals = function (timeString) {
    var a = moment('0:00AM', 'h:mm A');
    var time = moment(timeString, 'h:mm A');
    var intervalOne = 15;
    var times = [];
    var startTime = time.diff(a, 'minutes');
    var ap = ['AM', 'PM', 'AM', 'PM'];
    // generate 15min time intervals within an hour
    for (var i = 0; i < 4; i++) {
        var hh = Math.floor(startTime / 60);
        var mm = startTime % 60;
        if (hh % 12 === 0) {
            times.push('12' + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)]);
        }
        else {
            var time_1 = ('' + (hh % 12)).slice(-2) +
                ':' +
                ('0' + mm).slice(-2) +
                ' ' +
                ap[Math.floor(hh / 12)];
            times.push(time_1);
        }
        startTime = startTime + intervalOne;
    }
    // generate 30min time intervals for the rest 23hr
    var intervalTwo = 30;
    for (var i = 0; i < 46; i++) {
        var hh = Math.floor(startTime / 60);
        var mm = startTime % 60;
        if (hh % 12 === 0) {
            times.push('12' + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)]);
        }
        else {
            var time_2 = ('' + (hh % 12)).slice(-2) +
                ':' +
                ('0' + mm).slice(-2) +
                ' ' +
                ap[Math.floor(hh / 12)];
            times.push(time_2);
        }
        startTime = startTime + intervalTwo;
    }
    var first = times.shift();
    times.push(first);
    return times;
};
export var isTargetTimeInRange = function (targetTime, startTime, endTime) {
    var timeMoment = moment(targetTime, 'h:mm A');
    var startMoment = moment(startTime, 'h:mm A');
    var endMoment = moment(endTime, 'h:mm A');
    // deal with corner case when target time is 11:59 PM
    if (endTime === '11:59 PM' && timeMoment === endMoment) {
        return true;
    }
    return timeMoment.isBetween(startMoment, endMoment, null, '[]');
};
