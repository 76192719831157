export var BUTTON_COLOR_OPTIONS = [
    '#FFFFFF', //white
    '#EAEDFB', //purple
    '#E7F1FF', //blue
    '#E8F4EF', //green
    '#FEF9EE', //yellow
    '#FDF1EC', //orange
    '#FCEEEC', //red
    '#F4EFEC', //brown
    '#F4F4F7', //gray
];
export var BUTTON_BORDER_COLOR_OPTIONS = [
    '#D9D9D9', //white
    '#9CADFB', //purple
    '#9DC4FA', //blue
    '#94E2C1', //green
    '#F7DEA8', //yellow
    '#F6BEA7', //orange
    '#F7CBC5', //red
    '#D8C6BC', //brown
    '#C8C8D7', //gray
];
