var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { Select } from 'antd';
import PaymentRulesConfigurationContext from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesModal/PaymentRulesConfigurationContext';
import { PayItemType, PayRuleConditionOperand } from '../../../interface';
var PaymentConditionsOperandSelector = function (_a) {
    var disabled = _a.disabled, payItemType = _a.payItemType;
    var _b = useContext(PaymentRulesConfigurationContext), paymentRule = _b.paymentRule, setPayRuleInfo = _b.setPayRuleInfo;
    var info = paymentRule.info;
    var onSelectOperand = useCallback(function (item) {
        if (payItemType === PayItemType.PAY_RULE) {
            setPayRuleInfo(__assign(__assign({}, info), { conditionOperand: item }));
        }
    }, [info, payItemType, setPayRuleInfo]);
    return (_jsxs(Select, { className: "w-full", value: info.conditionOperand, disabled: disabled || payItemType === PayItemType.PAY_RATE, onSelect: onSelectOperand, bordered: false, children: [_jsx(Select.Option, { value: PayRuleConditionOperand.AND, children: "AND" }), payItemType === PayItemType.PAY_RULE && (_jsx(Select.Option, { value: PayRuleConditionOperand.OR, children: "OR" }))] }));
};
export default PaymentConditionsOperandSelector;
