var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs } from 'antd';
import React from 'react';
import ZiraDropdown from '../ZiraDropdown';
import './ZiraVisibilityDropdown.scss';
var ZiraVisibilityDropdown = function (_a) {
    var tabTitles = _a.tabTitles, tabContents = _a.tabContents, children = _a.children, footer = _a.footer, props = __rest(_a, ["tabTitles", "tabContents", "children", "footer"]);
    var menu = (_jsxs("div", { className: "ZiraVisibilityDropdown flex w-[384px] flex-col rounded bg-white shadow-2xl", children: [_jsx(Tabs, { className: "mb-0 w-full", tabBarStyle: { width: '100%', margin: 0 }, children: tabTitles.map(function (tabTitle, tabIndex) { return (_jsx(Tabs.TabPane, { tab: tabTitle, children: _jsx("div", { className: "ZiraVisibilityDropdownTabContent", children: tabContents[tabIndex] }) }, "".concat(tabTitle, "-").concat(tabIndex))); }) }), footer && _jsx(_Fragment, { children: footer })] }));
    if (tabTitles.length !== tabContents.length) {
        throw new Error('tabTitles & tabContents must have the same length');
    }
    return (_jsx(ZiraDropdown, __assign({ overlay: menu, trigger: ['click'] }, props, { children: children })));
};
export default React.memo(ZiraVisibilityDropdown);
