var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
export var useAccountHelpContentApi = function () {
    var customFetch = useFetch().request;
    var useGetHelpTopicCollections = function (params) {
        return useQuery(['HelpTopicCollections'], function () {
            return customFetch({
                method: 'GET',
                url: 'intercom/grouped_articles',
            });
        }, __assign({}, params === null || params === void 0 ? void 0 : params.options));
    };
    var useGetHelpArticleContent = function (request) {
        var id = request.articleId;
        return useQuery(['helpArticleDetail', id], function () {
            return customFetch({
                method: 'GET',
                url: "intercom/article_detail/".concat(id),
            });
        }, request.options);
    };
    return {
        useGetHelpTopicCollections: useGetHelpTopicCollections,
        useGetHelpArticleContent: useGetHelpArticleContent,
    };
};
