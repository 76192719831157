import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useRecordDetail } from 'src/hooks/component';
import { scheduleEvaluationState, scheduleGroupBySelector, scheduleTimeZoneSelector, } from 'src/state';
import { useRecoilValue } from 'recoil';
import UserTagWithIcon from 'src/components/User/UserTagWithIcon';
import { NoShowOpenModalButtonSet, ScheduleGroupBy } from 'src/shared';
import UserEvaluationPopover from '../../UserEvaluationPopover';
var ScheduleGroupByRowTitle = function (_a) {
    var blockId = _a.blockId, record = _a.groupByRow;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var scheduleEvaluation = useRecoilValue(scheduleEvaluationState(blockId));
    var groupBy = useRecoilValue(scheduleGroupBySelector(blockId));
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var userIndictments = useMemo(function () {
        var _a;
        return (_a = find(scheduleEvaluation === null || scheduleEvaluation === void 0 ? void 0 : scheduleEvaluation.indictments.userIndictments, function (user) { return user.userRid == record.rowKey; })) === null || _a === void 0 ? void 0 : _a.indictmentNames;
    }, [record.rowKey, scheduleEvaluation === null || scheduleEvaluation === void 0 ? void 0 : scheduleEvaluation.indictments.userIndictments]);
    var renderGroupStats = useCallback(function (stats) {
        return (_jsxs("div", { className: "GroupStatsContent flex items-center", children: [stats, _jsx(Tooltip, { title: "This is an estimate calculation, please go to report for more detailed info.", children: _jsx(InfoCircleOutlined, { className: "ml-2" }) })] }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(UserTagWithIcon, { name: record.title, className: "UserTag", avatarProps: {
                    avatar: record.avatarUrl,
                }, children: _jsx("div", { className: "UserIndictmentPopoverWrapper", children: _jsx(UserEvaluationPopover, { indictmentNames: userIndictments }) }) }), !NoShowOpenModalButtonSet.has(record.title) &&
                groupBy !== ScheduleGroupBy.TEMPLATE && (_jsx("div", { className: "OpenDetailButton", onClick: function () { return openRecordDetail(record.rowKey, timeZone); }, children: "OPEN" })), groupBy === ScheduleGroupBy.USER && renderGroupStats(record.subtitle)] }));
};
export default React.memo(ScheduleGroupByRowTitle);
