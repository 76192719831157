import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import './ActivityFeedSider.scss';
import { Layout } from 'antd';
import FeedContainer from '../FeedContainer';
var Sider = Layout.Sider;
var ActivityFeedSider = function (props) {
    var collapsed = props.collapsed, onCollapse = props.onCollapse;
    var location = useLocation();
    var getSiderClassName = useCallback(function () {
        var isPagePath = location.pathname.split('/')[2] === 'page';
        if (!isPagePath)
            return 'ActivityFeedsSiderInNonPageView';
        return 'ActivityFeedsSider';
    }, [location.pathname]);
    return useMemo(function () {
        return (_jsx(Sider, { collapsible: true, collapsed: collapsed, onCollapse: onCollapse, trigger: null, theme: "light", collapsedWidth: 0, width: 360, className: getSiderClassName(), children: _jsx(FeedContainer, { collapsed: collapsed }) }));
    }, [collapsed, getSiderClassName, onCollapse]);
};
export default React.memo(ActivityFeedSider);
