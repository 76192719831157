var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NativePath } from 'src/shared';
import { find, pickBy } from 'lodash';
var getShiftRequestSchema = function (shiftCollection) {
    var shiftStartSchema = Object.values(shiftCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_START_AT; });
    var shiftEndSchema = Object.values(shiftCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_END_AT; });
    var shiftLocationSchema = Object.values(shiftCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_LOCATION_ID; });
    var shiftRoleSchema = Object.values(shiftCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_ROLE_IDS; });
    return {
        shiftStartSchema: shiftStartSchema,
        shiftEndSchema: shiftEndSchema,
        shiftLocationSchema: shiftLocationSchema,
        shiftRoleSchema: shiftRoleSchema,
    };
};
var getShiftGroupRequestSchema = function (shiftGroupCollection) {
    var shiftGroupStartSchema = Object.values(shiftGroupCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_GROUP_START_AT; });
    var shiftGroupEndSchema = Object.values(shiftGroupCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_GROUP_END_AT; });
    var shiftGroupLocationSchema = Object.values(shiftGroupCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_GROUP_LOCATIONS; });
    var shiftGroupRoleSchema = Object.values(shiftGroupCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_GROUP_ROLES; });
    var shiftGroupNameSchema = Object.values(shiftGroupCollection.schemas).find(function (schema) { return schema.nativePath === NativePath.SHIFT_GROUP_NAME; });
    return {
        shiftGroupStartSchema: shiftGroupStartSchema,
        shiftGroupEndSchema: shiftGroupEndSchema,
        shiftGroupLocationSchema: shiftGroupLocationSchema,
        shiftGroupRoleSchema: shiftGroupRoleSchema,
        shiftGroupNameSchema: shiftGroupNameSchema,
    };
};
var formatShiftGroupRequestInfo = function (shiftGroupCollection, requestsInfo, shiftGroupsInfo) {
    var _a = getShiftGroupRequestSchema(shiftGroupCollection), shiftGroupStartSchema = _a.shiftGroupStartSchema, shiftGroupEndSchema = _a.shiftGroupEndSchema, shiftGroupLocationSchema = _a.shiftGroupLocationSchema, shiftGroupRoleSchema = _a.shiftGroupRoleSchema, shiftGroupNameSchema = _a.shiftGroupNameSchema;
    var filteredRequests = pickBy(requestsInfo, function (v) {
        return v.isShiftGroup;
    });
    shiftGroupCollection.recordOrders.forEach(function (recordId) {
        var _a, _b, _c, _d, _e, _f;
        var shiftGroupStart = (_a = find(shiftGroupCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftGroupStartSchema === null || shiftGroupStartSchema === void 0 ? void 0 : shiftGroupStartSchema.id);
        })) === null || _a === void 0 ? void 0 : _a.timestamp;
        var shiftGroupEnd = (_b = find(shiftGroupCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftGroupEndSchema === null || shiftGroupEndSchema === void 0 ? void 0 : shiftGroupEndSchema.id);
        })) === null || _b === void 0 ? void 0 : _b.timestamp;
        var shiftGroupLocationNative = find(shiftGroupCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftGroupLocationSchema === null || shiftGroupLocationSchema === void 0 ? void 0 : shiftGroupLocationSchema.id);
        });
        var shiftGroupRolesNative = find(shiftGroupCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftGroupRoleSchema === null || shiftGroupRoleSchema === void 0 ? void 0 : shiftGroupRoleSchema.id);
        });
        var shiftGroupName = (_d = (_c = find(shiftGroupCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftGroupNameSchema === null || shiftGroupNameSchema === void 0 ? void 0 : shiftGroupNameSchema.id);
        })) === null || _c === void 0 ? void 0 : _c.text) !== null && _d !== void 0 ? _d : '';
        //TODO: add tage color into obj
        shiftGroupsInfo[recordId] = __assign({ name: shiftGroupName, recordId: recordId, startAt: shiftGroupStart || '', endAt: shiftGroupEnd || '', locations: ((_e = shiftGroupLocationNative === null || shiftGroupLocationNative === void 0 ? void 0 : shiftGroupLocationNative.nativeObjects) === null || _e === void 0 ? void 0 : _e.map(function (location) { return ({
                label: location.text || '',
                color: location.color,
            }); })) || [], roles: ((_f = shiftGroupRolesNative === null || shiftGroupRolesNative === void 0 ? void 0 : shiftGroupRolesNative.nativeObjects) === null || _f === void 0 ? void 0 : _f.map(function (role) { return ({
                label: role.text || '',
                color: role.color,
            }); })) || [] }, filteredRequests[recordId]);
    });
};
var formatShiftRequestInfo = function (shiftCollection, requestsInfo, shiftsInfo) {
    var _a = getShiftRequestSchema(shiftCollection), shiftStartSchema = _a.shiftStartSchema, shiftEndSchema = _a.shiftEndSchema, shiftLocationSchema = _a.shiftLocationSchema, shiftRoleSchema = _a.shiftRoleSchema;
    var filteredRequests = pickBy(requestsInfo, function (v) {
        return !v.isShiftGroup;
    });
    shiftCollection.recordOrders.forEach(function (recordId) {
        var _a, _b, _c;
        var shiftStart = (_a = find(shiftCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftStartSchema === null || shiftStartSchema === void 0 ? void 0 : shiftStartSchema.id);
        })) === null || _a === void 0 ? void 0 : _a.timestamp;
        var shiftEnd = (_b = find(shiftCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftEndSchema === null || shiftEndSchema === void 0 ? void 0 : shiftEndSchema.id);
        })) === null || _b === void 0 ? void 0 : _b.timestamp;
        var shiftLocationNative = find(shiftCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftLocationSchema === null || shiftLocationSchema === void 0 ? void 0 : shiftLocationSchema.id);
        });
        var shiftRolesNative = find(shiftCollection.nativeFields, function (field) {
            return field.recordId === recordId && field.schemaId === (shiftRoleSchema === null || shiftRoleSchema === void 0 ? void 0 : shiftRoleSchema.id);
        });
        var hasLocation = !!(shiftLocationNative === null || shiftLocationNative === void 0 ? void 0 : shiftLocationNative.text);
        var locations = [];
        if (hasLocation) {
            locations = [
                {
                    label: shiftLocationNative.text,
                    color: shiftLocationNative.color,
                },
            ];
        }
        //TODO: add tage color into obj
        shiftsInfo[recordId] = __assign({ recordId: recordId, startAt: shiftStart || '', endAt: shiftEnd || '', locations: locations, roles: ((_c = shiftRolesNative === null || shiftRolesNative === void 0 ? void 0 : shiftRolesNative.nativeObjects) === null || _c === void 0 ? void 0 : _c.map(function (role) { return ({
                label: role.text || '',
                color: role.color,
            }); })) || [] }, filteredRequests[recordId]);
    });
};
export var parseRequestedShifts = function (dataToParse, tempRecordData) {
    if (tempRecordData === void 0) { tempRecordData = []; }
    var shiftsInfo = {};
    var shiftGroupsInfo = {};
    var requestsInfo = {};
    dataToParse.requests.forEach(function (request) {
        requestsInfo[request.recordId] = {
            openCount: request.openCount,
            claimUsers: request.claimUsers.map(function (user) { return user.recordId; }),
            originalUser: request.originalUser,
            isShiftGroup: request.isShiftGroup,
            shiftCount: request.shiftCount,
        };
    });
    var shiftCollection = dataToParse.shiftCollection;
    var shiftGroupCollection = dataToParse.shiftGroupCollection;
    formatShiftRequestInfo(shiftCollection, requestsInfo, shiftsInfo);
    if (shiftGroupCollection) {
        formatShiftGroupRequestInfo(shiftGroupCollection, requestsInfo, shiftGroupsInfo);
    }
    // each claim user is a row
    dataToParse.requests.forEach(function (request) {
        request.claimUsers.forEach(function (user) {
            tempRecordData.push({
                shift: shiftsInfo[request.recordId],
                shiftGroup: shiftGroupsInfo[request.recordId],
                user: user,
            });
        });
    });
    return tempRecordData;
};
