import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Checkbox } from 'antd';
var ZiraVisibilityDropdownToggleItem = function (_a) {
    var title = _a.title, isVisible = _a.isVisible, onVisibilityToggled = _a.onVisibilityToggled, showDivider = _a.showDivider;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex w-full cursor-pointer items-center rounded-sm border-b-2 border-[#F8F8F9] py-[12px]", onClick: function () { return onVisibilityToggled(!isVisible); }, children: [_jsx("span", { className: "ml-4 text-ellipsis text-sm", children: title }), _jsx("span", { className: "ml-auto mr-4 cursor-pointer", children: _jsx(Checkbox, { checked: isVisible, onClick: function (e) {
                                e.stopPropagation();
                                e.preventDefault();
                            } }) })] }), showDivider && _jsx("div", { className: "w-full bg-[#F8F8F9] py-[12px]" })] }));
};
export default React.memo(ZiraVisibilityDropdownToggleItem);
