import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import UserAvatar from 'src/components/User/UserAvatar';
import { useRecoilValue } from 'recoil';
import LocationBroadcastIcon from 'src/utils/icons/ChatIcons/LocationBroadcastIcon';
import RoleBroadcastIcon from 'src/utils/icons/ChatIcons/RoleBroadcastIcon';
import AccountBroadcastIcon from 'src/utils/icons/ChatIcons/AccountBroadcastIcon';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import { MessageSenderType, ThreadReceiverParticipantType } from '../interface';
import { participantsInThreadTitle } from '../utils';
var ThreadAvatar = function (_a) {
    var participants = _a.participants;
    var currentUserRId = useRecoilValue(currentUserRecordIdSelector);
    var avatarParticipants = useMemo(function () { return participantsInThreadTitle(participants, currentUserRId); }, [participants, currentUserRId]);
    // To render the avatar for the location as it will be at end of list
    var locationParticipant = useMemo(function () {
        var _a;
        return ((_a = avatarParticipants[avatarParticipants.length - 1]) === null || _a === void 0 ? void 0 : _a.participantType) ===
            ThreadReceiverParticipantType.BROADCAST_LOCATION
            ? avatarParticipants[avatarParticipants.length - 1]
            : undefined;
    }, [avatarParticipants]);
    var isDoubleAvatar = useMemo(function () {
        var _a;
        return (avatarParticipants[1] &&
            ((_a = avatarParticipants[1]) === null || _a === void 0 ? void 0 : _a.participantType) !== MessageSenderType.DEPARTMENT);
    }, [avatarParticipants]);
    var renderIcon = useCallback(function (participant) {
        if ([MessageSenderType.USER, MessageSenderType.DEPARTMENT].includes(participant.participantType)) {
            return (_jsx(UserAvatar, { name: participant.name, isCirclular: true, size: isDoubleAvatar ? 26 : 40, avatar: participant.avatarUrl }));
        }
        else if (participant.participantType === ThreadReceiverParticipantType.BROADCAST_ACCOUNT) {
            return participant.avatarUrl ? (_jsx(UserAvatar, { name: participant.name, isCirclular: true, size: 40, avatar: participant.avatarUrl })) : (_jsx(AccountBroadcastIcon, { isLargeIcon: true }));
        }
        else if (participant.participantType === ThreadReceiverParticipantType.BROADCAST_LOCATION) {
            return (_jsx(LocationBroadcastIcon, { size: isDoubleAvatar ? 'medium' : 'large', color: participant.color }));
        }
        else if (participant.participantType === ThreadReceiverParticipantType.BROADCAST_ROLE) {
            return (_jsx(RoleBroadcastIcon, { size: isDoubleAvatar ? 'medium' : 'large', color: participant.color }));
        }
    }, [isDoubleAvatar]);
    return (_jsxs("div", { className: "relative h-[40px] w-[40px] min-w-[40px]", children: [isDoubleAvatar && (_jsx("div", { className: avatarParticipants[0] || locationParticipant
                    ? 'absolute right-[0px] top-[0px]'
                    : undefined, children: renderIcon(locationParticipant ? locationParticipant : avatarParticipants[1]) })), avatarParticipants[0] && (_jsx("div", { className: isDoubleAvatar ? 'absolute bottom-[0px] left-[0px]' : undefined, children: renderIcon(avatarParticipants[0]) }))] }));
};
export default ThreadAvatar;
