import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { FixedSizeList as List } from 'react-window';
import TemplateCard from './NewShiftTemplateCard';
var ShiftTemplateListInPopover = function (props) {
    var blockId = props.blockId, templateList = props.templateList, onApplyTemplate = props.onApplyTemplate, listHeight = props.listHeight, listWidth = props.listWidth, groupByRecordId = props.groupByRecordId;
    return (_jsx(_Fragment, { children: _jsx(List, { className: "List", height: listHeight, itemCount: templateList.length, itemSize: 95, width: listWidth, children: function (_a) {
                var index = _a.index, style = _a.style;
                var template = templateList[index];
                return (_jsx(TemplateCard, { blockId: blockId, style: style, template: template, onApplyTemplate: onApplyTemplate, groupByRecordId: groupByRecordId }));
            } }) }));
};
export default React.memo(ShiftTemplateListInPopover);
