import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Calendar } from 'antd';
import moment from 'moment';
import { useScheduleComponent } from 'src/hooks/component';
import { ScheduleView } from 'src/services';
import { useRecoilValue } from 'recoil';
import { scheduleViewTypeSelector } from 'src/state/selectors/schedule';
import { scheduleTimeRangeState } from 'src/state/atoms/schedule';
import ZiraPopover from '../../ui/ZiraPopover';
var calculateOffsetWeeks = function (blockId, current, target, viewType) {
    var unit = viewType === ScheduleView.DAY ? 'day' : 'week';
    var currentStartOfWeek = current.locale(blockId).startOf(unit);
    var targetStartOfWeek = target.locale(blockId).startOf(unit);
    return targetStartOfWeek.diff(currentStartOfWeek, unit);
};
var ScheduleTimePickerDropdown = function (props) {
    var children = props.children, blockId = props.blockId;
    var changeCalView = useScheduleComponent().changeCalView;
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var _a = useState(undefined), selectedDate = _a[0], setSelectedDate = _a[1];
    var _b = useState(false), isOverlayVisible = _b[0], setIsOverlayVisible = _b[1];
    var calendarDataOnSelect = useCallback(function (e) {
        setSelectedDate(e);
    }, []);
    var menu = (_jsx(Calendar, { className: "CalendarCard", fullscreen: false, onSelect: calendarDataOnSelect, value: selectedDate }));
    var dropdownVisibleOnChange = useCallback(function (visible) {
        if (!visible &&
            selectedDate &&
            // Selected date not within time range
            !timeRange.slice(0, -1).includes(selectedDate.toISOString())) {
            var offset = calculateOffsetWeeks(blockId, moment(timeRange[0]), selectedDate, scheduleView);
            changeCalView(blockId, scheduleView, offset);
            setSelectedDate(undefined);
        }
        setIsOverlayVisible(visible);
    }, [selectedDate, timeRange, scheduleView, changeCalView, blockId]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraPopover, { content: menu, trigger: ['click'], placement: "bottom", onLocalOpenChange: dropdownVisibleOnChange, children: children }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setIsOverlayVisible(false);
                }, style: { display: isOverlayVisible ? 'block' : 'none' } })] }));
};
export default React.memo(ScheduleTimePickerDropdown);
