import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import tinycolor from 'tinycolor2';
var LOCATION_SVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="14" width="14"><g><path d="M11.5,5c0,2.49-4.5,8.5-4.5,8.5S2.5,7.49,2.5,5a4.5,4.5,0,0,1,9,0Z" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="7" cy="5" r="1.5" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"></circle></g></svg>';
var LocationBroadcastIcon = function (_a) {
    var size = _a.size, color = _a.color;
    var backgroundColor = color ? tinycolor(color).darken(40).toString() : '#167EE4';
    var iconSize = size === 'large' ? '40px' : size === 'medium' ? '26px' : '20px';
    var imgSize = size === 'large' ? 24 : size === 'medium' ? 16 : 12;
    return (_jsx("div", { className: "flex h-[36px] w-[36px] items-center justify-center rounded-[24px] text-white", style: {
            backgroundColor: backgroundColor,
            width: iconSize,
            height: iconSize,
        }, children: _jsx("img", { height: imgSize, width: imgSize, src: "data:image/svg+xml;utf8,".concat(encodeURIComponent(LOCATION_SVG)) }) }));
};
export default React.memo(LocationBroadcastIcon);
