var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, Checkbox, Tooltip } from 'antd';
import './ArchiveOptionsPopup.scss';
import Modal from 'antd/lib/modal/Modal';
import { archiveOptionsPopupState, scheduleCollectionIdState } from 'src/state';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useArchiveApi } from 'src/hooks/api/useArchiveApi';
import { startCase } from 'lodash';
import { CollectionType } from 'src/shared';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
var ArchiveOptionsPopup = function () {
    var useProcessArchiveSettings = useArchiveApi().useProcessArchiveSettings;
    var refetchCollection = useRefetchCollection();
    var userCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.USER));
    var _a = useRecoilState(archiveOptionsPopupState), archiveOptionsState = _a[0], setArchiveOptionsState = _a[1];
    var _b = useState(true), unpublishFutureShifts = _b[0], setUnpublishFutureShifts = _b[1];
    var _c = useState(true), removeFromUsers = _c[0], setRemoveFromUsers = _c[1];
    var processArchiveSettingsMutate = useProcessArchiveSettings.mutate, isLoading = useProcessArchiveSettings.isLoading;
    var onSubmit = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            if (archiveOptionsState) {
                processArchiveSettingsMutate({
                    rids: archiveOptionsState.rids,
                    type: archiveOptionsState.type,
                    settings: {
                        unpublishFutureShifts: unpublishFutureShifts,
                        removeFromUsers: archiveOptionsState.type === CollectionType.USER
                            ? false
                            : removeFromUsers,
                    },
                }, {
                    onSuccess: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var shiftCollectionId, _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    shiftCollectionId = snapshot
                                        .getLoadable(scheduleCollectionIdState)
                                        .valueMaybe();
                                    if (shiftCollectionId) {
                                        refetchCollection(shiftCollectionId);
                                    }
                                    if (userCollectionId && removeFromUsers) {
                                        refetchCollection(userCollectionId);
                                    }
                                    _a = (archiveOptionsState === null || archiveOptionsState === void 0 ? void 0 : archiveOptionsState.onSubmit);
                                    if (!_a) return [3 /*break*/, 2];
                                    return [4 /*yield*/, (archiveOptionsState === null || archiveOptionsState === void 0 ? void 0 : archiveOptionsState.onSubmit())];
                                case 1:
                                    _a = (_b.sent());
                                    _b.label = 2;
                                case 2:
                                    _a;
                                    setArchiveOptionsState(undefined);
                                    return [2 /*return*/];
                            }
                        });
                    }); },
                });
            }
        };
    }, [
        refetchCollection,
        unpublishFutureShifts,
        removeFromUsers,
        archiveOptionsState,
        setArchiveOptionsState,
        processArchiveSettingsMutate,
        userCollectionId,
    ]);
    var modalFooter = (_jsx("div", { className: "ArchiveOptionsPopupFooter", children: _jsxs("div", { children: [(archiveOptionsState === null || archiveOptionsState === void 0 ? void 0 : archiveOptionsState.onSubmit) && (_jsx(Button, { className: "CancelBtn", onClick: function () { return setArchiveOptionsState(undefined); }, children: "Cancel" })), _jsx(Button, { loading: isLoading, className: "ConfirmBtn", onClick: function (e) {
                        e.stopPropagation();
                        onSubmit();
                    }, children: "Submit" })] }) }));
    if (!archiveOptionsState)
        return null;
    var headerCopy = archiveOptionsState.onSubmit
        ? "Archive ".concat(startCase(archiveOptionsState.type), " Options")
        : "This ".concat(archiveOptionsState.type, " has been archived");
    var textLineCopy = archiveOptionsState.onSubmit
        ? "Note: Archiving this ".concat(archiveOptionsState.type, " will unassign all future shifts associated with it.")
        : "Note: All future shifts associated with this ".concat(archiveOptionsState.type, " will be unassigned.");
    return (_jsx(_Fragment, { children: _jsxs(Modal, { width: 350, open: true, centered: true, closable: false, destroyOnClose: true, footer: modalFooter, children: [_jsx("div", { className: "ArchiveOptionsPopupTitle", children: headerCopy }), _jsxs("div", { className: "ArchiveOptionsCheckboxLine", children: [_jsxs("div", { children: ["Unpublish future shifts", _jsx(Tooltip, { title: _jsx("div", { className: "text-[13px] font-medium", children: "Unpublish future shifts associated with this ".concat(archiveOptionsState.type, ", making them drafts.") }), color: "black", placement: "bottom", overlayClassName: "HelpArticleUrlTooltip", children: _jsx(QuestionCircleOutlined, { className: "ml-1" }) })] }), _jsx(Checkbox, { checked: unpublishFutureShifts, onClick: function () { return setUnpublishFutureShifts(function (prev) { return !prev; }); } })] }), archiveOptionsState.type !== 'user' && (_jsx(_Fragment, { children: _jsxs("div", { className: "ArchiveOptionsCheckboxLine", children: [_jsxs("div", { children: ["Remove from users", _jsx(Tooltip, { title: _jsx("div", { className: "text-[13px] font-medium", children: "Remove this ".concat(archiveOptionsState.type, " from all users that currently have it.") }), color: "black", placement: "bottom", overlayClassName: "HelpArticleUrlTooltip", children: _jsx(QuestionCircleOutlined, { className: "ml-1" }) })] }), _jsx(Checkbox, { checked: removeFromUsers, onClick: function () { return setRemoveFromUsers(function (prev) { return !prev; }); } })] }) })), _jsx("div", { className: "ArchiveOptionsTextLine", children: textLineCopy })] }) }));
};
export default React.memo(ArchiveOptionsPopup);
