import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SubMenuContentIcon } from 'src/shared';
import ColumnHeaderFromBlock from './Column/ColumnHeaderFromBlock';
import ColumnHeaderFromModal from './Column/ColumnHeaderFromModal';
import PlainHeaderDisplay from './Column/PlainHeaderDisplay';
import { TITLE_COLUMN_ID } from './Constants';
var ColumnHeader = function (props) {
    var schemaId = props.schemaId, collectionId = props.collectionId, blockId = props.blockId, reverseRelationId = props.reverseRelationId;
    if (blockId && schemaId !== TITLE_COLUMN_ID) {
        return _jsx(ColumnHeaderFromBlock, { schemaId: schemaId, blockId: blockId });
    }
    else if (reverseRelationId && schemaId !== TITLE_COLUMN_ID) {
        // Reverse collection
        return (_jsx(ColumnHeaderFromModal, { schemaId: schemaId, collectionId: collectionId, reverseRelationId: reverseRelationId }));
    }
    return _jsx(PlainHeaderDisplay, { icon: SubMenuContentIcon[TITLE_COLUMN_ID], title: "Title" });
};
export default React.memo(ColumnHeader);
