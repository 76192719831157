var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRecipeApi } from 'src/hooks/api';
import { activateRecipeRequestSelector } from '../atom';
var RecipeActivationView = function (_a) {
    var recipeId = _a.recipeId;
    var activationRequest = useRecoilValue(activateRecipeRequestSelector);
    var useActivateRecipe = useRecipeApi().useActivateRecipe;
    var isLoading = useActivateRecipe(__assign(__assign({}, activationRequest), { recipeTemplateId: recipeId })).isLoading;
    return (_jsx("div", { className: "grid place-items-center h-full w-full text-xl font-semibold", children: isLoading ? 'Let me cook...' : 'Cooking Complete' }));
};
export default React.memo(RecipeActivationView);
