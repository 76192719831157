var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Row, Col, DatePicker } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import { useSetting } from 'src/hooks/api';
import moment from 'moment';
import SettingsContentHeader from '../SettingsContentHeader';
import './SchedulingSettingsPage.scss';
var SchedulingSettingsPage = function () {
    var _a = useSetting(), useGetSchedulingSettings = _a.useGetSchedulingSettings, useUpdateSchedulingSettings = _a.useUpdateSchedulingSettings;
    var _b = useState(), schedulingSettings = _b[0], setSchedulingSettings = _b[1];
    var _c = useGetSchedulingSettings(), fetchSchedulingSettingsData = _c.data, refetch = _c.refetch;
    useEffect(function () {
        if (fetchSchedulingSettingsData) {
            setSchedulingSettings(fetchSchedulingSettingsData);
        }
    }, [fetchSchedulingSettingsData]);
    var onWeekOneStartDateChange = useCallback(function (date) {
        setSchedulingSettings(function (prev) { return (__assign(__assign({}, prev), { weekOneStartDate: date ? moment(date).format('YYYY-MM-DD') : null })); });
    }, []);
    var onSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!schedulingSettings) return [3 /*break*/, 2];
                    return [4 /*yield*/, useUpdateSchedulingSettings.mutateAsync(schedulingSettings, {
                            onError: function () {
                                refetch();
                            },
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [refetch, schedulingSettings, useUpdateSchedulingSettings]);
    return (_jsxs("div", { className: "SchedulingSettings", children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Scheduling" }) }), _jsx("div", { className: "SettingsPageContainer", children: _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [_jsx("div", { className: "SchedulingSettingsTitle", children: "Scheduling" }), _jsx("div", { className: "SchedulingSettingDescription", children: "Manage your scheduling here" }), _jsxs("div", { className: "SchedulingSettingsSection", children: [_jsx("div", { className: "SchedulingSettingsSubTitle", children: "Needs & Coverage" }), _jsxs(Row, { justify: "space-between", children: [_jsxs(Col, { span: 15, children: [_jsx("div", { className: "text-lg font-medium", children: "Week One Start Date" }), _jsxs("div", { className: "SchedulingSettingDescription", children: ["Set the start date for your needs and coverage week one.", _jsx("br", {}), "This will act as a reference date to determine whether you are applying from week one or week two of your needs/coverage."] })] }), _jsx(Col, { span: 9, children: _jsx(DatePicker, { showToday: false, value: (schedulingSettings === null || schedulingSettings === void 0 ? void 0 : schedulingSettings.weekOneStartDate)
                                                    ? moment(schedulingSettings.weekOneStartDate)
                                                    : null, inputReadOnly: true, onChange: onWeekOneStartDateChange, allowClear: false, disabledDate: function (date) {
                                                    if (!date)
                                                        return true;
                                                    if (date.isAfter(moment().startOf('day'))) {
                                                        return true;
                                                    }
                                                    return false;
                                                } }) })] })] })] }) }), _jsx("div", { className: "SettingContentFooter", children: _jsx(ZiraActionButton, { className: "SaveSettingBtn", onClickFn: onSave, children: "Save" }) })] }));
};
export default React.memo(SchedulingSettingsPage);
