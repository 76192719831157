import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath } from 'src/shared';
import { getNativeFieldCellFiles, getNativeFieldCellOptions, getTagTextColor, } from 'src/utils/Collection';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var DocumentDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var documentTitle = useMemo(function () {
        var _a;
        var title = (_a = getNativeFieldCellFiles(modalRecord, nativeSchemaMap, NativePath.DOCUMENT_FILE)[0]) === null || _a === void 0 ? void 0 : _a.name;
        return _jsx("span", { children: title || 'Document' });
    }, [modalRecord, nativeSchemaMap]);
    var documentTag = useMemo(function () {
        var status = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.DOCUMENT_STATUS);
        if (!status.length)
            return null;
        var backgroundColor = status[0].color;
        return (_jsx("span", { className: "RecordStatusTag", style: {
                backgroundColor: backgroundColor,
                color: getTagTextColor(backgroundColor),
            }, children: status[0].name }));
    }, [modalRecord, nativeSchemaMap]);
    return _jsx(RecordDetailModalTitle, { title: documentTitle, extras: documentTag });
};
export default React.memo(DocumentDetailModalTitle);
