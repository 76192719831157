import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment-timezone';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHealthMetricApi } from 'src/hooks/api';
import { accountTimeZoneSelector } from 'src/state/selectors/withAccountDetailState';
import AddNewHealthMetricRuleCard from './AddNewHealthMetricRuleCard';
import { deletedRuleIdsState } from './Atoms';
import { findRuleMatchedData, getStartDateByEndAndTimeRange } from './HealthMetricCalculator';
import HealthMetricGraphCard from './HealthMetricGraphCard';
import HealthMetricLoadingBar from './HealthMetricLoadingBar';
import './HealthMetricsView.scss';
var HealthMetricsView = function () {
    var _a;
    var _b = useHealthMetricApi(), useGetHealthMetricRulesQuery = _b.useGetHealthMetricRulesQuery, useGetHealthMetricDataQuery = _b.useGetHealthMetricDataQuery;
    var _c = useGetHealthMetricRulesQuery(), rulesResp = _c.data, isLoading = _c.isLoading;
    var deletedRules = useRecoilValue(deletedRuleIdsState);
    var timeZone = useRecoilValue(accountTimeZoneSelector);
    var rulesToRequest = (rulesResp === null || rulesResp === void 0 ? void 0 : rulesResp.rules.map(function (rule) {
        var _a;
        var beforeAt = moment().tz(timeZone).startOf('day').toISOString();
        return {
            ruleId: rule.id,
            interval: (_a = rule.settings) === null || _a === void 0 ? void 0 : _a.interval,
            // request with 0:00AM of today
            beforeAt: beforeAt,
            startFrom: getStartDateByEndAndTimeRange(beforeAt, rule.timeRange).toISOString(),
        };
    }).filter(function (rule) { return !deletedRules.find(function (dd) { return dd === rule.ruleId; }); })) || [];
    var dataResp = useGetHealthMetricDataQuery({
        body: {
            rules: rulesToRequest,
        },
        options: {
            enabled: rulesToRequest.length > 0,
            refetchInterval: function (data, _query) {
                // poll every 3s if there is no data
                if (!getIfAllDataLoaded(data))
                    return 3000;
                return false;
            },
            cacheTime: 1000 * 60 * 5,
            refetchOnMount: false,
        },
    }).data;
    if (isLoading ||
        !rulesResp ||
        !dataResp ||
        (!getIfPartialDataLoaded(dataResp) && rulesResp && rulesResp.rules.length > 0))
        return (_jsx("div", { className: "flex h-[100%] w-[100%] items-center justify-center", children: _jsx(HealthMetricLoadingBar, { text: "We're preparing the data for you..." }) }));
    return (_jsx("div", { className: "HealthMetricsContentContainer px-10 py-3", children: _jsxs("div", { className: "grid grid-cols-1 gap-6 md:grid-flow-row md:grid-cols-2", children: [(_a = rulesResp === null || rulesResp === void 0 ? void 0 : rulesResp.rules) === null || _a === void 0 ? void 0 : _a.map(function (rule) { return (_jsx(HealthMetricGraphCard, { rule: rule, ruleData: findRuleMatchedData(rule.id, dataResp === null || dataResp === void 0 ? void 0 : dataResp.data) }, rule.id)); }), _jsx(AddNewHealthMetricRuleCard, {})] }) }));
};
// -- private --
var getIfPartialDataLoaded = function (data) {
    return ((data === null || data === void 0 ? void 0 : data.data.filter(function (d) { return d.data.length > 0; }).length) || 0) > 0;
};
var getIfAllDataLoaded = function (data) {
    return ((data === null || data === void 0 ? void 0 : data.data.filter(function (d) { return d.data.length === 0; }).length) || 0) === 0;
};
export default React.memo(HealthMetricsView);
