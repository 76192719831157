var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { uniqBy } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { collectionTableNonSchemaMutationsState } from 'src/state';
import { NonSchemaNativePath } from '../../shared';
var useUserShiftDetailModal = function (_a) {
    var dataSource = _a.dataSource;
    var onEditUserAvatar = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (avatarUrl) {
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schema: NonSchemaNativePath.USER_AVATAR,
                        text: avatarUrl,
                    }
                ], prev, true), 'schema');
            });
        };
    }, [dataSource]);
    return {
        onEditUserAvatar: onEditUserAvatar,
    };
};
export { useUserShiftDetailModal as useUserDetailModalComponent };
