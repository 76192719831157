import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { scheduleCalendarState, scheduleTimeRangeState, tableSchedulingDisplaySettingsState, tableSearchTextState, scheduleTimeOffsState, tableDisplaySettingsState, } from 'src/state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ScheduleView } from 'src/services/ScheduleService';
import { useScheduleApi } from 'src/hooks/api';
import { useCollectionComponent } from 'src/hooks/component';
import ScheduleCalendarViewWrapper from './ScheduleCalendarViewWrapper';
var ScheduleTableCalendarView = function (_a) {
    var tableId = _a.tableId, type = _a.type, addNewShift = _a.addNewShift, openShiftDetailModal = _a.openShiftDetailModal;
    var timeRange = useRecoilValue(scheduleTimeRangeState(tableId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(tableId));
    var viewDisplaySettings = useRecoilValue(tableSchedulingDisplaySettingsState(tableId));
    var searchQuery = useRecoilValue(tableSearchTextState(tableId));
    var scheduleView = useMemo(function () { return viewDisplaySettings; }, [viewDisplaySettings]).viewType;
    var processTableCollectionIntoState = useCollectionComponent().processTableCollectionIntoState;
    var useGetMonthSchedule = useScheduleApi().useGetMonthSchedule;
    var setScheduleCalendarData = useSetRecoilState(scheduleCalendarState(tableId));
    var setSchedleTimeOffs = useSetRecoilState(scheduleTimeOffsState(tableId));
    var data = useGetMonthSchedule({
        body: {
            blockId: tableId,
            start: moment(timeRange[0])
                // Start of month view calendar
                .startOf('month')
                .startOf('week')
                .subtract(1, 'day') //! Extend 1 extra day to get hidden shifts due to different block timezone
                .toISOString(),
            end: moment(timeRange[0])
                // End of month view calendar
                .endOf('month')
                .add(1, 'week') //! Sometimes theres an extra row of next month's dates (depends on day of week of 1st of month)
                .endOf('week')
                .add(1, 'day') //! Extend 1 extra day to get hidden shifts due to different block timezone
                .toISOString(),
            displaySettings: displaySettings,
            schedulingDisplaySettings: viewDisplaySettings,
            searchQuery: searchQuery || undefined,
            pagination: {
                page: 0,
                size: 0,
            },
        },
        options: {
            enabled: !!displaySettings &&
                !!viewDisplaySettings &&
                !!timeRange.length &&
                scheduleView === ScheduleView.MONTH,
        },
    }).data;
    useEffect(function () {
        if (data) {
            var collection = data.collection, calendar = data.calendar, timeOffs = data.timeOffs;
            processTableCollectionIntoState(tableId, collection, undefined, type === 'block');
            setScheduleCalendarData(calendar);
            setSchedleTimeOffs(timeOffs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    return (_jsx(ScheduleCalendarViewWrapper, { tableId: tableId, viewType: viewDisplaySettings.viewType, addNewShift: addNewShift, openShiftDetailModal: openShiftDetailModal }));
};
export default React.memo(ScheduleTableCalendarView);
