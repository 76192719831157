import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Divider } from 'antd';
import { CheckMarkIcon } from 'src/utils/icons/DashboardIcons/CheckMarkIcon';
import { RightArrowIcon } from 'src/utils/icons/DashboardIcons/RightArrowIcon';
import SVG from 'react-inlinesvg';
var OnboardingCheckListItem = function (_a) {
    var itemInfo = _a.itemInfo, isLastItem = _a.isLastItem, openModal = _a.openModal, setSelectedItem = _a.setSelectedItem;
    var textColor = itemInfo.isFinished ? '#A7A8AC' : 'black';
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex cursor-pointer items-center justify-between", onClick: function () {
                    setSelectedItem();
                    openModal();
                }, children: [_jsxs("div", { className: "flex items-center", style: { color: textColor }, children: [_jsx("div", { className: "mr-3 flex h-[35px] w-[35px] items-center justify-center rounded-[20px] bg-[#F6F6FA]", children: _jsx(SVG, { src: itemInfo.icon, color: textColor }) }), _jsxs("div", { children: [_jsx("div", { className: "text-sm font-medium", children: itemInfo.title }), _jsx("div", { className: "text-xs", children: itemInfo.description })] })] }), _jsx("div", { className: "mr-3 flex h-[35px] w-[35px] items-center justify-center rounded-[20px] border border-gray-50", children: itemInfo.isFinished ? _jsx(CheckMarkIcon, {}) : _jsx(RightArrowIcon, {}) })] }), !isLastItem && _jsx(Divider, {})] }));
};
export default React.memo(OnboardingCheckListItem);
