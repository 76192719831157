var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useScheduleTemplateApi } from 'src/hooks/api';
import { useTemplateComponent } from 'src/hooks/component';
var TemplatesPopover = function (_a) {
    var blockId = _a.blockId, children = _a.children, onApplyTemplate = _a.onApplyTemplate;
    var fetchScheduleTemplateList = useScheduleTemplateApi().fetchScheduleTemplateList;
    var _b = useState([]), templateList = _b[0], setTemplateList = _b[1];
    var _c = useState(false), isVisible = _c[0], setIsVisible = _c[1];
    var locationFilter = useTemplateComponent(blockId).locationFilter;
    var fetchTemplateList = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchScheduleTemplateList({
                        filters: locationFilter,
                    })];
                case 1:
                    data = _a.sent();
                    if (data) {
                        setTemplateList(data.templates);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [fetchScheduleTemplateList, locationFilter]);
    useEffect(function () {
        if (isVisible) {
            fetchTemplateList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);
    var renderTemplatesPopover = useCallback(function () {
        return (_jsxs("div", { className: "TemplatePopConfirm", children: [!!(templateList === null || templateList === void 0 ? void 0 : templateList.length) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "ModalTitle", children: "Shift Templates" }), _jsx("div", { className: "ModalSubtitle", children: "Select a template below" })] })), _jsxs("div", { className: "TemplatesContainer", children: [templateList === null || templateList === void 0 ? void 0 : templateList.map(function (template) { return (_jsx("div", { className: 'TemplateCardContainer hover:border', onClick: function () {
                                onApplyTemplate(template);
                            }, children: _jsxs("div", { className: "TemplateCardContent", children: [_jsx("span", { className: "TemplateName", children: template.name }), _jsxs("span", { className: "TemplateShift", children: ["".concat(template.numberOfShifts, " ") +
                                                (template.numberOfShifts > 1 ? 'shifts' : 'shift'), ' '] })] }) }, template.id)); }), !(templateList === null || templateList === void 0 ? void 0 : templateList.length) && (_jsxs("div", { className: "w-40", children: [_jsx("span", { className: "text-warning", children: "No Templates Available:" }), _jsxs("div", { children: [_jsx("span", { className: "font-semibold", children: "Create a template" }), " or", ' ', _jsx("span", { className: "font-semibold", children: "Change your templates filter" }), ' ', "to see available templates."] })] }))] })] }));
    }, [onApplyTemplate, templateList]);
    return (_jsx(Popover, { content: renderTemplatesPopover, trigger: "click", placement: "leftBottom", visible: isVisible, onVisibleChange: setIsVisible, children: children !== null && children !== void 0 ? children : _jsx("div", { className: "AddNewText", children: "Template" }) }));
};
export default React.memo(TemplatesPopover);
