import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import { tableSelectedViewIdState, tableCollectionViewsState } from 'src/state';
import { useCollectionComponent } from '..';
export var useInitBlockViews = function (blockId, fetchViews) {
    if (fetchViews === void 0) { fetchViews = true; }
    var _a = useRecoilState(tableSelectedViewIdState(blockId)), viewId = _a[0], setViewId = _a[1];
    var _b = useRecoilState(tableCollectionViewsState(blockId)), blockViews = _b[0], setBlockViews = _b[1];
    var resetBlockCollectionState = useCollectionComponent().resetBlockCollectionState;
    var useGetBlockViews = usePageApi().useGetBlockViews;
    var initialBlockViews = useGetBlockViews({
        blockId: blockId,
        options: { enabled: fetchViews, cacheTime: 0 },
    }).data;
    //! Set block views
    useEffect(function () {
        if (initialBlockViews) {
            setBlockViews(initialBlockViews);
            if (!viewId || !initialBlockViews.orderedViews.find(function (view) { return view.viewId === viewId; })) {
                // Set to first view if not set or if view is not found (due to being deleted)
                setViewId(initialBlockViews.orderedViews[0].viewId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialBlockViews]);
    //! Reset block
    useEffect(function () {
        return function () {
            resetBlockCollectionState(blockId, blockViews.orderedViews.map(function (view) { return view.viewId; }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return initialBlockViews;
};
