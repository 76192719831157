var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Divider, Switch } from 'antd';
var GeneralTaskTemplateConfiguration = function (_a) {
    var taskDetail = _a.taskDetail, showBottomMargin = _a.showBottomMargin, updateTaskInfo = _a.updateTaskInfo;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col w-full pl-[42px] justify-start items-start gap-4", children: [_jsxs("div", { className: "w-full flex items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "text-base font-semibold pb-2", children: "External Task" }), _jsx("div", { className: "text-sm font-medium opacity-50", children: "Will be designated with \"External\" visiblity, intended for the assignee to complete. (Only \"External\" tasks are visible in the assignee's app.)" })] }), _jsx(Switch, { className: "m-0 self-start", checked: taskDetail.configuration.isExternal, onChange: function (isExternal) {
                                    return updateTaskInfo({
                                        configuration: __assign(__assign({}, taskDetail.configuration), { isExternal: isExternal }),
                                    });
                                } })] }), _jsx(Divider, {})] }), _jsxs("div", { className: "flex flex-col w-full pl-[42px] justify-start items-start gap-4", children: [_jsxs("div", { className: "w-full flex items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "text-base font-semibold pb-2", children: "Published" }), _jsx("div", { className: "text-sm font-medium opacity-50", children: "When task is added, it will be pending and immediately visible to the assignee (if marked \"External\")." })] }), _jsx(Switch, { className: "m-0 self-start", checked: taskDetail.configuration.isPublished, onChange: function (isPublished) {
                                    return updateTaskInfo({
                                        configuration: __assign(__assign({}, taskDetail.configuration), { isPublished: isPublished }),
                                    });
                                } })] }), _jsx(Divider, { className: showBottomMargin ? undefined : 'mb-0' })] })] }));
};
export default React.memo(GeneralTaskTemplateConfiguration);
