export var ShiftPayrollStatus;
(function (ShiftPayrollStatus) {
    ShiftPayrollStatus["PAID"] = "PAID";
    ShiftPayrollStatus["APPROVED"] = "APPROVED";
    ShiftPayrollStatus["PENDING"] = "PENDING";
    ShiftPayrollStatus["CORRECTED"] = "CORRECTED";
})(ShiftPayrollStatus || (ShiftPayrollStatus = {}));
export var ReportSourceType;
(function (ReportSourceType) {
    ReportSourceType["USER"] = "USER";
    ReportSourceType["LOCATION"] = "LOCATION";
})(ReportSourceType || (ReportSourceType = {}));
export var PayExportType;
(function (PayExportType) {
    PayExportType["REPORT"] = "REPORT";
    PayExportType["INTEGRATION"] = "INTEGRATION";
})(PayExportType || (PayExportType = {}));
