import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { InputNumber } from 'antd';
import { PercentageIcon } from 'src/utils/icons/PercentageIcon';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { CurrencyMap } from '../../../../../../shared';
import { BillRateSettingType } from '../Interface';
var BillRateInputCell = function (_a) {
    var payRate = _a.payRate, defaultBillRateSetting = _a.billRateSetting, billRate = _a.billRate, currency = _a.currency, updateWageRecordBillRate = _a.updateWageRecordBillRate, canEditBillRateType = _a.canEditBillRateType;
    var currencySymbol = CurrencyMap[currency];
    var _b = useState(billRate), rateValue = _b[0], setRateValue = _b[1];
    var _c = useState(defaultBillRateSetting), billRateSetting = _c[0], setBillRateSetting = _c[1];
    var inputRef = useRef(null);
    useEffect(function () { return setRateValue(billRate); }, [billRate]);
    useEffect(function () { return setBillRateSetting(defaultBillRateSetting); }, [defaultBillRateSetting]);
    var isPercentageType = billRateSetting.type === BillRateSettingType.PERCENTAGE;
    var onChangeBillRateType = useCallback(function () {
        var newBillRateSetting = cloneDeep(defaultBillRateSetting);
        var newBillRateValue = cloneDeep(rateValue);
        // Switch to value type
        if (defaultBillRateSetting.type === BillRateSettingType.PERCENTAGE) {
            newBillRateSetting.percentage = undefined;
            newBillRateSetting.type = BillRateSettingType.VALUE;
        }
        // Switch to percentage type
        else {
            var newPercentage = payRate ? Number(((rateValue / payRate) * 100).toFixed(2)) : 0;
            newBillRateSetting.percentage = newPercentage;
            newBillRateSetting.type = BillRateSettingType.PERCENTAGE;
            newBillRateValue = Number(((payRate * newPercentage) / 100).toFixed(2));
        }
        updateWageRecordBillRate(newBillRateSetting, newBillRateValue);
    }, [defaultBillRateSetting, payRate, rateValue, updateWageRecordBillRate]);
    return (_jsxs("div", { className: "BillRateInputContainer", children: [_jsxs("div", { className: "BillRateInput", children: [_jsx(InputNumber, { ref: inputRef, bordered: false, className: "PayNumberInput", style: { width: isPercentageType ? '70px' : '120px' }, min: 0, precision: isPercentageType ? 2 : undefined, prefix: isPercentageType ? undefined : CurrencyMap[currency], value: isPercentageType ? billRateSetting.percentage : rateValue, onFocus: function (e) { return e.target.select(); }, onBlur: function () {
                            updateWageRecordBillRate(billRateSetting, rateValue);
                        }, onPressEnter: function () {
                            var _a;
                            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                        }, onChange: function (value) {
                            if (typeof value === 'number') {
                                if (isPercentageType) {
                                    var newBillRateSetting = cloneDeep(billRateSetting);
                                    var newPercentageNumber = Number(value.toFixed(2));
                                    var newRateValue = Number(((payRate * newPercentageNumber) / 100).toFixed(2));
                                    setRateValue(newRateValue);
                                    newBillRateSetting.percentage = newPercentageNumber;
                                    setBillRateSetting(newBillRateSetting);
                                }
                                else {
                                    setRateValue(value);
                                }
                            }
                        } }), isPercentageType && (_jsx(ZiraTooltip, { title: "".concat(currencySymbol).concat(rateValue), children: _jsx("div", { className: "RatioTypeDisplayRateValueNumber", children: "(".concat(currencySymbol).concat(rateValue, ")") }) }))] }), canEditBillRateType && (_jsx(ZiraTooltip, { title: "Turn ".concat(isPercentageType ? 'Off' : 'On', " Percent Mode"), children: _jsx("div", { className: "BillRatePercentageToggle", style: { backgroundColor: isPercentageType ? '#416EFF' : '#F2F2F2' }, onClick: onChangeBillRateType, children: _jsx(PercentageIcon, { style: { color: isPercentageType ? 'white' : 'black' } }) }) }))] }));
};
export default React.memo(BillRateInputCell);
