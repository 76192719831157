var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FileUploadIconSvg = function () { return (_jsxs("svg", { width: "16", height: "17", viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M14 10.2266V12.8932C14 13.2469 13.8595 13.586 13.6095 13.836C13.3594 14.0861 13.0203 14.2266 12.6667 14.2266H3.33333C2.97971 14.2266 2.64057 14.0861 2.39052 13.836C2.14048 13.586 2 13.2469 2 12.8932V10.2266", stroke: "#818A9B", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M11.3334 5.5599L8.00002 2.22656L4.66669 5.5599", stroke: "#818A9B", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M8 2.22656V10.2266", stroke: "#818A9B", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var FileUploadIcon = function (props) { return (_jsx(Icon, __assign({ component: FileUploadIconSvg }, props))); };
