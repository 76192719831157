var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ReportTypeIconSvg = function () { return (_jsxs("svg", { width: "25", height: "25", viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M4.16634 14.6253H3.16634V15.6253V20.8337V21.8337H4.16634H8.33301H9.33301V20.8337V15.6253V14.6253H8.33301H4.16634ZM3.08301 14.542H9.41634V21.917H3.08301V14.542Z", fill: "white", stroke: "white", strokeWidth: "2" }), _jsx("path", { d: "M16.6663 9.41683H15.6663V10.4168V20.8335V21.8335H16.6663H20.833H21.833V20.8335V10.4168V9.41683H20.833H16.6663ZM15.583 9.3335H21.9163V21.9168H15.583V9.3335Z", fill: "white", stroke: "white", strokeWidth: "2" }), _jsx("path", { d: "M10.4163 3.16683H9.41634V4.16683V20.8335V21.8335H10.4163H14.583H15.583V20.8335V4.16683V3.16683H14.583H10.4163ZM9.33301 3.0835H15.6663V21.9168H9.33301V3.0835Z", fill: "white", stroke: "white", strokeWidth: "2" })] })); };
export var ReportTypeIcon = function (props) { return (_jsx(Icon, __assign({ component: ReportTypeIconSvg }, props))); };
