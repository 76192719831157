var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAccountApi } from 'src/hooks/api';
import AccountState from 'src/hooks/state/Account';
import { getRipplingAuthCode } from 'src/utils/Account';
import ZiraTooltip from '../../ui/ZiraTooltip';
import './AccountSelectPage.scss';
var AccountSelectPage = function () {
    var organizations = AccountState.useContainer().organizations;
    var getAuthCallbackWithLogin = useAccountApi().getAuthCallbackWithLogin;
    var saveCodeAndNavigateToAccount = useCallback(function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
        var ripplingAuthCode;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ripplingAuthCode = getRipplingAuthCode();
                    if (!ripplingAuthCode) {
                        toast('No Rippling authentication code found', {
                            type: 'error',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                    }
                    // call endpoint to save rippling auth code
                    return [4 /*yield*/, getAuthCallbackWithLogin(accountId, ripplingAuthCode)
                        // redirect back to rippling
                    ];
                case 1:
                    // call endpoint to save rippling auth code
                    _a.sent();
                    // redirect back to rippling
                    location.replace('https://app.rippling.com/apps/PLATFORM/TeamBridge/return');
                    return [2 /*return*/];
            }
        });
    }); }, [getAuthCallbackWithLogin]);
    return (_jsx("div", { className: "AccountSelectContainer", children: _jsxs("div", { className: "flex h-full flex-col", children: [_jsx("div", { className: "AccountSelectTitle", children: "Select Your Account" }), _jsx("div", { className: "h-full overflow-y-auto", children: organizations === null || organizations === void 0 ? void 0 : organizations.sort(function (a, b) {
                        return a.accountName > b.accountName ? 1 : -1;
                    }).map(function (org) {
                        var matches = org.accountName.match(/\b(\w)/g);
                        var acronym = matches === null || matches === void 0 ? void 0 : matches.join('').substring(0, 2);
                        return (_jsx(ZiraTooltip, { title: org.accountName, placement: "right", children: _jsxs("div", { className: "AccountOption", onClick: function () { return saveCodeAndNavigateToAccount(org.accountId); }, children: [(org === null || org === void 0 ? void 0 : org.webLogo) ? (_jsx("img", { className: "AccountIcon", src: org.webLogo })) : (_jsx("div", { className: "AccountIcon", children: acronym })), _jsx("div", { className: "AccountName", children: org.accountName })] }, org.accountId) }, org.accountId));
                    }) })] }) }));
};
export default React.memo(AccountSelectPage);
