var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useTasksApi } from 'src/hooks/api/useTasksApi';
import { parseBackBooleanCollectionCells } from 'src/services/CollectionDataService/CollectionCellService';
import ActivityFeed from 'src/components/CenterPanel/Feed/ActivityFeed/ActivityFeed';
import { collectionTableCellDataSelector, collectionTableSchemaMutationsState } from 'src/state';
import { CollectionTableType, CollectionType } from 'src/shared';
import { isEmptyCellData } from 'src/components/OptimizedTableView/Cell/util';
import { pickBy } from 'lodash';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import IconSelectorPopover from 'src/components/IconSelector/IconSelectorPopover';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import { isTaskSettingActivityFeedVisibleState, isTaskSettingMobilePreviewVisibleState, selectedTaskTemplateIdState, taskGroupDetailState, } from '../atom';
import { TaskTemplateType, ProfileTaskSchemaType, } from '../interface';
import { DEFAULT_TASK_TEMPLATE_ICON, DEFAULT_TASK_TEMPLATE_INFO, NEW_TASK_TEMPLATE_ID, } from '../constant';
import TaskSaveWarningModal from '../shared/TaskSaveWarningModal';
import { taskTemplateSelector } from '../selector';
import TaskCollapsePanel from '../shared/TaskCollapsePanel';
import { generateRandomFourDigitNumber } from '../util';
import TaskMobilePreview from '../shared/TaskMobilePreview';
import TaskSaveOptionsModal from '../shared/TaskSaveOptionsModal';
import TaskTemplateCustomFields from './TaskCustomFields/TaskTemplateCustomFields';
import SelectTaskDetailType from './SelectTaskDetailType';
import TaskTemplateDueDate from './TaskTemplateDueDate';
import TaskTemplateDescription from './TaskTemplateDescription';
import TaskTemplateReferences from './TaskTemplateReferences';
import { getTaskWarningModalContent } from './util';
import WorkspaceTypeTaskSettingSection from './TaskSettingSection/WorkspaceTypeTaskSettingSection';
import ProfileDataTypeTaskSettingSection from './ProfileDataTypeTask/ProfileDataTypeTaskSettingSection';
import ExternalLinkTaskSettingSection from './TaskSettingSection/ExternalLinkTaskSettingSection';
import ViewOnlyTaskSettingSection from './TaskSettingSection/ViewOnlyTaskSettingSection';
import SigningPartnerTaskSettingSection from './TaskSettingSection/SigningPartnerTaskSettingSection';
import GeneralTaskTemplateConfiguration from './TaskTemplateConfiguration/GeneralTaskTemplateConfiguration';
import ProfileDataTaskTemplateConfiguration from './TaskTemplateConfiguration/ProfileDataTaskTemplateConfiguration';
var TaskTemplateDetailContent = function () {
    var _a, _b;
    var taskCollectionId = (_a = useRecoilValue(standardCollectionIdSelector(CollectionType.TASK))) !== null && _a !== void 0 ? _a : '';
    var isTaskSettingMobilePreviewVisible = useRecoilValue(isTaskSettingMobilePreviewVisibleState);
    var isTaskSettingActivityFeedVisible = useRecoilValue(isTaskSettingActivityFeedVisibleState);
    var _c = useRecoilState(selectedTaskTemplateIdState), selectedTaskTemplateId = _c[0], setSelectedTaskTemplateId = _c[1];
    var _d = useState(false), taskSaveWarningModalVisible = _d[0], setTaskSaveWarningModalVisible = _d[1];
    var _e = useState(false), taskSaveOptionsModalVisible = _e[0], setTaskSaveOptionsModalVisible = _e[1];
    var isCreatingNewTask = selectedTaskTemplateId === NEW_TASK_TEMPLATE_ID;
    var selectedTask = useRecoilValue(taskTemplateSelector(selectedTaskTemplateId !== null && selectedTaskTemplateId !== void 0 ? selectedTaskTemplateId : ''));
    var _f = useState(), taskDetail = _f[0], setTaskDetail = _f[1];
    var _g = useTasksApi(), useCreateTaskTemplate = _g.useCreateTaskTemplate, useUpdateTaskTemplate = _g.useUpdateTaskTemplate, refetchTasksOverview = _g.refetchTasksOverview;
    var _h = useState(false), iconSelectorVisible = _h[0], setIconSelectorVisible = _h[1];
    useEffect(function () {
        if (!isCreatingNewTask) {
            setTaskDetail(selectedTask);
        }
        else {
            // create new task
            var newTask = __assign(__assign({}, DEFAULT_TASK_TEMPLATE_INFO), { displayId: "task.template.".concat(generateRandomFourDigitNumber()), type: undefined, taskData: undefined, iconInfo: DEFAULT_TASK_TEMPLATE_ICON, customFieldInfoAndView: {
                    customFieldInfo: {
                        customFieldSchemas: [],
                        customFieldValues: [],
                        descriptionVisible: true,
                        dueDateVisible: true,
                    },
                } });
            setTaskDetail(newTask);
        }
    }, [isCreatingNewTask, selectedTask, selectedTaskTemplateId]);
    var updateTaskType = useCallback(function (type) {
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { type: type, taskData: type === TaskTemplateType.PROFILE_DATA
                        ? {
                            type: type,
                            collectionId: '',
                            requiredSchemaIds: [],
                            preSaveFields: [],
                            taskSchemas: [],
                            cells: {},
                        }
                        : type === TaskTemplateType.WORKSPACE_LINK
                            ? { type: type, pageId: '' }
                            : type === TaskTemplateType.VIEW_ONLY
                                ? { type: type, buttonLabel: 'Confirm', allowCompletion: true }
                                : type === TaskTemplateType.SIGNING_PARTNER
                                    ? {
                                        type: type,
                                        buttonLabel: 'Complete',
                                        collectionId: '',
                                        fileSchemaId: '',
                                        templateId: '',
                                        signingPartner: '',
                                        taskSchemas: [],
                                        cells: {},
                                    }
                                    : { type: type, url: '' } });
            }
        });
    }, []);
    var updateTaskInfo = useCallback(function (task) {
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), task);
            }
        });
    }, []);
    var updateTaskCustomFieldInfo = useCallback(function (customFields, descriptionVisible, dueDateVisible) {
        setTaskDetail(produce(function (draft) {
            if (draft) {
                if (customFields) {
                    draft.customFieldInfoAndView.customFieldInfo.customFieldSchemas =
                        customFields;
                }
                else if (descriptionVisible !== undefined) {
                    draft.customFieldInfoAndView.customFieldInfo.descriptionVisible =
                        descriptionVisible;
                }
                else if (dueDateVisible !== undefined) {
                    draft.customFieldInfoAndView.customFieldInfo.dueDateVisible =
                        dueDateVisible;
                }
            }
        }));
    }, []);
    var updateWorkSpacePageId = useCallback(function (pageId) {
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { taskData: __assign(__assign({}, prev.taskData), { pageId: pageId }) });
            }
        });
    }, []);
    var updateExternalLink = useCallback(function (url) {
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { taskData: __assign(__assign({}, prev.taskData), { url: url }) });
            }
        });
    }, []);
    var updateViewOnly = useCallback(function (_a) {
        var buttonLabel = _a.buttonLabel, allowCompletion = _a.allowCompletion;
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { taskData: __assign(__assign({}, prev.taskData), { buttonLabel: buttonLabel, allowCompletion: allowCompletion }) });
            }
        });
    }, []);
    var updateSigningPartnerTask = useCallback(function (taskData) {
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { taskData: __assign(__assign({}, prev.taskData), taskData) });
            }
        });
    }, []);
    var updateProfileData = useCallback(function (profileData) {
        setTaskDetail(function (prev) {
            if (prev) {
                return __assign(__assign({}, prev), { taskData: __assign(__assign({}, prev.taskData), profileData) });
            }
        });
    }, []);
    var renderTaskMainSettingContent = useMemo(function () {
        var _a;
        if (taskDetail) {
            switch (taskDetail.type) {
                case TaskTemplateType.WORKSPACE_LINK:
                    return (_jsx(WorkspaceTypeTaskSettingSection, { pageId: (_a = taskDetail.taskData) === null || _a === void 0 ? void 0 : _a.pageId, updateWorkSpacePageId: updateWorkSpacePageId }));
                case TaskTemplateType.PROFILE_DATA:
                    return (_jsx(ProfileDataTypeTaskSettingSection, { selectedTaskId: selectedTaskTemplateId, profileData: taskDetail.taskData, updateProfileData: updateProfileData }));
                case TaskTemplateType.EXTERNAL_LINK:
                    return (_jsx(ExternalLinkTaskSettingSection, { link: taskDetail.taskData.url, updateExternalLink: updateExternalLink }));
                case TaskTemplateType.VIEW_ONLY:
                    return (_jsx(ViewOnlyTaskSettingSection, { buttonLabel: taskDetail.taskData.buttonLabel, allowCompletion: taskDetail.taskData.allowCompletion, updateViewOnly: updateViewOnly }));
                case TaskTemplateType.SIGNING_PARTNER:
                    return (_jsx(SigningPartnerTaskSettingSection, { buttonLabel: taskDetail.taskData.buttonLabel, templateId: taskDetail.taskData.templateId, collectionId: taskDetail.taskData.collectionId, fileSchemaId: taskDetail.taskData.fileSchemaId, selectedTaskId: selectedTaskTemplateId, taskSchemas: taskDetail.taskData.taskSchemas, cells: taskDetail.taskData.cells, updateSigningPartnerTask: updateSigningPartnerTask }));
            }
        }
    }, [
        taskDetail,
        updateWorkSpacePageId,
        updateProfileData,
        updateExternalLink,
        updateViewOnly,
        updateSigningPartnerTask,
        selectedTaskTemplateId,
    ]);
    var warningModalContent = useMemo(function () {
        return getTaskWarningModalContent(taskDetail);
    }, [taskDetail]);
    var onSaveTemplate = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (saveOptions) { return __awaiter(void 0, void 0, void 0, function () {
            var customSchemaMutations, requestBody, taskData, schemaMutations_1, parsedCells, taskSchemas, invalidLockedHiddenFields, newTemplate_1, request;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!taskDetail || !taskDetail.type || !taskDetail.taskData)
                            return [2 /*return*/];
                        customSchemaMutations = snapshot
                            .getLoadable(collectionTableSchemaMutationsState({
                            type: CollectionTableType.TASK_TEMPLATE_MODAL,
                            collectionId: taskCollectionId,
                        }))
                            .valueMaybe();
                        requestBody = {
                            name: taskDetail.name,
                            iconInfo: taskDetail.iconInfo,
                            displayId: taskDetail.displayId,
                            description: taskDetail.description,
                            daysUntilDue: taskDetail.daysUntilDue,
                            type: taskDetail.type,
                            taskData: taskDetail.taskData,
                            customFieldInfo: {
                                customFieldValues: [],
                                customFieldSchemas: (_a = taskDetail.customFieldInfoAndView) === null || _a === void 0 ? void 0 : _a.customFieldInfo.customFieldSchemas.map(function (field) { return (__assign(__assign({}, field), { value: customSchemaMutations === null || customSchemaMutations === void 0 ? void 0 : customSchemaMutations.find(function (m) { return m.schemaId === field.schemaId; }) })); }),
                                descriptionVisible: taskDetail.customFieldInfoAndView.customFieldInfo.descriptionVisible,
                                dueDateVisible: taskDetail.customFieldInfoAndView.customFieldInfo.dueDateVisible,
                            },
                            configuration: taskDetail.configuration,
                        };
                        taskData = taskDetail.taskData;
                        if (taskDetail.type === TaskTemplateType.PROFILE_DATA ||
                            taskDetail.type === TaskTemplateType.SIGNING_PARTNER) {
                            schemaMutations_1 = snapshot
                                .getLoadable(collectionTableSchemaMutationsState({
                                type: CollectionTableType.TASK_TEMPLATE_MODAL,
                                collectionId: taskData.collectionId,
                            }))
                                .valueMaybe();
                            if (!schemaMutations_1)
                                return [2 /*return*/];
                            parsedCells = parseBackBooleanCollectionCells((_b = taskData.cells) !== null && _b !== void 0 ? _b : {});
                            taskSchemas = taskData.taskSchemas.map(function (schema) {
                                var mutation = schemaMutations_1.find(function (mutation) { return mutation.schemaId === schema.schemaId; });
                                var cell = snapshot
                                    .getLoadable(collectionTableCellDataSelector({
                                    type: CollectionTableType.TASK_TEMPLATE_MODAL,
                                    collectionId: taskData.collectionId,
                                    schemaId: schema.schemaId,
                                    recordId: selectedTaskTemplateId,
                                }))
                                    .valueMaybe();
                                // Ignore empty cells and LINK_FROM_TASK as it doesn't need a default value (gets value from linked task)
                                var defaultValue = cell &&
                                    !isEmptyCellData(cell) &&
                                    schema.taskSchemaType !== ProfileTaskSchemaType.LINK_FROM_TASK
                                    ? mutation
                                    : undefined;
                                return __assign(__assign({}, schema), { defaultValue: defaultValue });
                            });
                            invalidLockedHiddenFields = taskSchemas.filter(function (schema) {
                                return !schema.defaultValue &&
                                    [ProfileTaskSchemaType.LOCKED, ProfileTaskSchemaType.HIDDEN].includes(schema.taskSchemaType);
                            });
                            if (invalidLockedHiddenFields.length > 0) {
                                toast('A default value must be provided for all locked/hidden fields.', {
                                    type: 'error',
                                    position: toast.POSITION.BOTTOM_CENTER,
                                });
                                return [2 /*return*/];
                            }
                            // Save cells so we can render them when returned from the API
                            requestBody.taskData = __assign(__assign({}, taskData), { taskSchemas: taskSchemas, cells: 
                                // Remove extra cells that are not in the task schemas
                                pickBy(parsedCells, function (_, key) {
                                    return taskData.taskSchemas.find(function (s) { return s.schemaId === key; });
                                }) });
                        }
                        if (!isCreatingNewTask) return [3 /*break*/, 2];
                        return [4 /*yield*/, useCreateTaskTemplate.mutateAsync(requestBody)
                            //! Add new task to group if in group detail
                        ];
                    case 1:
                        newTemplate_1 = _c.sent();
                        //! Add new task to group if in group detail
                        if (newTemplate_1) {
                            set(taskGroupDetailState, function (prev) {
                                if (prev) {
                                    return __assign(__assign({}, prev), { orderedTaskIds: __spreadArray(__spreadArray([], prev.orderedTaskIds, true), [newTemplate_1.id], false) });
                                }
                                return prev;
                            });
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        if (!saveOptions) return [3 /*break*/, 4];
                        request = {
                            templateId: selectedTaskTemplateId,
                            body: __assign(__assign({}, requestBody), { saveOptions: saveOptions }),
                        };
                        return [4 /*yield*/, useUpdateTaskTemplate.mutateAsync(request)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        refetchTasksOverview();
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCreatingNewTask, selectedTaskTemplateId, taskCollectionId, taskDetail]);
    var contentFooter = useMemo(function () { return (_jsxs("div", { className: "TaskDetailMainContentFooter", children: [_jsx(Button, { className: "CancelBtn", onClick: function () {
                    setSelectedTaskTemplateId(undefined);
                }, children: "Cancel" }), _jsx(Button, { className: "SaveBtn", onClick: function () {
                    if (warningModalContent.length) {
                        setTaskSaveWarningModalVisible(true);
                        return;
                    }
                    if (isCreatingNewTask) {
                        onSaveTemplate();
                    }
                    else {
                        setTaskSaveOptionsModalVisible(true);
                    }
                }, children: "Save Task" })] })); }, [isCreatingNewTask, onSaveTemplate, setSelectedTaskTemplateId, warningModalContent.length]);
    var _j = useState(['1', '2', '3']), activeSteps = _j[0], setActiveSteps = _j[1];
    var panelRefOne = useRef(null);
    var panelRefTwo = useRef(null);
    var panelRefThree = useRef(null);
    var scrollRef = useRef(null);
    var isProfileDataTask = (taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.type) === TaskTemplateType.PROFILE_DATA;
    useEffect(function () {
        setActiveSteps(['1', '2', '3']);
    }, [selectedTaskTemplateId]);
    var goToStep = useCallback(function (step, fromStep) {
        var _a, _b;
        setActiveSteps(function (prev) { return prev.filter(function (s) { return s !== fromStep; }).concat(step); });
        var panelHeaderElement = (_b = (_a = (step === '1' ? panelRefOne : step === '2' ? panelRefTwo : panelRefThree).current) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement;
        if (panelHeaderElement) {
            setTimeout(function () {
                var _a;
                return (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    behavior: 'smooth',
                    top: panelHeaderElement.offsetTop - 128,
                });
            }, 250);
        }
    }, []);
    if (!taskDetail)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "TaskDetailContainer", children: [_jsxs("div", { className: "TaskDetailMainContentWrapper", children: [_jsxs("div", { className: "TaskDetailMainContent", ref: scrollRef, children: [_jsxs("div", { className: "flex items-center w-full", children: [_jsx(IconSelectorPopover, { currentIcon: taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.iconInfo, selectorVisible: iconSelectorVisible, onSelectorVisibleChange: setIconSelectorVisible, onSaveIcon: function (iconInfo) { return updateTaskInfo({ iconInfo: iconInfo }); }, onSaveEmoji: function () { }, iconSize: 50, isCircularIcon: true, customIconOnly: true }), _jsxs("div", { className: "w-1/2", children: [_jsx(Input, { className: "TaskNameInput", bordered: false, autoFocus: true, placeholder: "Enter Template Name Here", value: taskDetail.name, onChange: function (e) { return updateTaskInfo({ name: e.target.value }); } }), _jsx("hr", { className: "TaskContentDivider" })] }), taskDetail.id !== NEW_TASK_TEMPLATE_ID && (_jsx(TaskTemplateReferences, { id: taskDetail.id }))] }), _jsxs("div", { children: [_jsx("div", { className: "TaskSectionLabel", children: "Task ID*" }), _jsx(Input.TextArea, { className: "TaskDescriptionInput", value: (_b = taskDetail === null || taskDetail === void 0 ? void 0 : taskDetail.displayId) !== null && _b !== void 0 ? _b : '', onChange: function (e) { return updateTaskInfo({ displayId: e.target.value }); }, placeholder: "Enter Task ID Here" })] }), _jsxs(TaskCollapsePanel, { ref: panelRefOne, step: "1", activeSteps: activeSteps, title: "Task Details", subtitle: "Assign task ID and custom fields to your new task", setActiveSteps: setActiveSteps, children: [_jsxs("div", { className: "flex flex-col gap-5", children: [_jsx(TaskTemplateDescription, { description: taskDetail.description, visible: taskDetail.customFieldInfoAndView.customFieldInfo
                                                    .descriptionVisible, onDescriptionUpdate: function (description) {
                                                    return updateTaskInfo({
                                                        description: description,
                                                    });
                                                }, onVisibleToggle: function () {
                                                    return updateTaskCustomFieldInfo(undefined, !taskDetail.customFieldInfoAndView.customFieldInfo
                                                        .descriptionVisible);
                                                } }), _jsx(TaskTemplateDueDate, { daysUntilDue: taskDetail.daysUntilDue, visible: taskDetail.customFieldInfoAndView.customFieldInfo.dueDateVisible, onDueDateUpdate: function (daysUntilDue) {
                                                    return updateTaskInfo({
                                                        daysUntilDue: daysUntilDue,
                                                    });
                                                }, onVisibleToggle: function () {
                                                    return updateTaskCustomFieldInfo(undefined, undefined, !taskDetail.customFieldInfoAndView.customFieldInfo
                                                        .dueDateVisible);
                                                } }), _jsx(TaskTemplateCustomFields, { selectedTaskId: selectedTaskTemplateId, customFieldInfoAndView: taskDetail.customFieldInfoAndView, setCustomFields: function (customFields) {
                                                    return updateTaskCustomFieldInfo(customFields);
                                                } })] }), _jsx("div", { className: "flex justify-end pt-[16px]", children: _jsx(Button, { className: "CancelBtn", onClick: function () {
                                                goToStep('2', '1');
                                            }, children: "Next Step" }) })] }), _jsxs(TaskCollapsePanel, { ref: panelRefTwo, step: "2", activeSteps: activeSteps, title: "Task Action", subtitle: "Choose what action the task should perform", setActiveSteps: setActiveSteps, children: [_jsx(SelectTaskDetailType, { updateTaskType: updateTaskType, taskDetail: taskDetail }), renderTaskMainSettingContent, _jsxs("div", { className: "flex justify-end pt-[16px] space-x-[8px]", children: [_jsx(Button, { className: "CancelBtn", onClick: function () {
                                                    goToStep('1', '2');
                                                }, children: "Previous Step" }), _jsx(Button, { className: "CancelBtn", onClick: function () {
                                                    goToStep('3', '2');
                                                }, children: "Next Step" })] })] }), _jsxs(TaskCollapsePanel, { ref: panelRefThree, step: "3", activeSteps: activeSteps, title: "Configuration", subtitle: "Set more preferences for the new task", setActiveSteps: setActiveSteps, children: [_jsx(GeneralTaskTemplateConfiguration, { taskDetail: taskDetail, showBottomMargin: isProfileDataTask, updateTaskInfo: updateTaskInfo }), isProfileDataTask && (_jsx(ProfileDataTaskTemplateConfiguration, { taskDetail: taskDetail, updateTaskInfo: updateTaskInfo })), _jsx("div", { className: "flex justify-end pt-[16px]", children: _jsx(Button, { className: "CancelBtn", onClick: function () {
                                                goToStep('2', '3');
                                            }, children: "Previous Step" }) })] })] }), contentFooter, taskSaveWarningModalVisible && (_jsx(TaskSaveWarningModal, { onClose: function () { return setTaskSaveWarningModalVisible(false); }, type: 'task', content: warningModalContent })), taskSaveOptionsModalVisible && (_jsx(TaskSaveOptionsModal, { type: "task", onClose: function () { return setTaskSaveOptionsModalVisible(false); }, onSave: function (saveOptions) { return onSaveTemplate(saveOptions); } }))] }), isTaskSettingMobilePreviewVisible && _jsx(TaskMobilePreview, { templateInfo: taskDetail }), taskDetail.id !== NEW_TASK_TEMPLATE_ID && isTaskSettingActivityFeedVisible && (_jsx("div", { className: "activityFeedWrapper min-w-[420px] bg-gray-40", children: _jsx(ActivityFeed, { collapsed: false, recordId: taskDetail.id }) }))] }));
};
export default React.memo(TaskTemplateDetailContent);
