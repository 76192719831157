import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import '../../../App.less';
import './DatePickerPopover.scss';
import { useTimeZone } from 'src/hooks/component/useTimeZone';
import { Input, Calendar, Menu, Tooltip, Select, Row, Col, Radio } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import { ValueDataType } from '../../../shared';
import ZiraPopover from '../../../ui/ZiraPopover';
// input value validation
var dateValidation = function (startDate) {
    return moment(startDate, 'MMM D, YYYY', true).isValid();
};
var timeValidation = function (time) {
    return moment(time, 'h:mm A', true).isValid();
};
var combinedTimeValidation = function (startDate, endDate, startTime, endTime) {
    var startTimeMoment = moment(startTime, 'h:mm A', true);
    var endTimeMoment = moment(endTime, 'h:mm A', true);
    var startTimeHour = startTime ? startTimeMoment.hour() : 0;
    var endTimeHour = endTime ? endTimeMoment.hour() : 0;
    var startTimeMin = startTime ? startTimeMoment.minute() : 0;
    var endTimeMin = endTime ? endTimeMoment.minute() : 0;
    var startTimeCom = moment(startDate, 'MMM D, YYYY', true)
        .hour(startTimeHour)
        .minute(startTimeMin);
    var endTimeCom = moment(endDate || startDate, 'MMM D, YYYY', true)
        .hour(endTimeHour)
        .minute(endTimeMin);
    return startTimeCom.isSameOrBefore(endTimeCom);
};
var DateInput = function (props) {
    var startOrEnd = props.startOrEnd, startDate = props.startDate, endDate = props.endDate, isDateValid = props.isDateValid, startDateOnChange = props.startDateOnChange, endDateOnChange = props.endDateOnChange, dateValidOnChange = props.dateValidOnChange, focusOnChange = props.focusOnChange;
    var _a = useState(''), dateValue = _a[0], setDateValue = _a[1];
    useEffect(function () {
        if (startOrEnd === 'start') {
            setDateValue(startDate);
        }
    }, [startDate, startOrEnd]);
    useEffect(function () {
        if (endDate && startOrEnd === 'end') {
            setDateValue(endDate);
        }
    }, [endDate, startOrEnd]);
    var changeFocus = useCallback(function () {
        var _a, _b;
        if (startOrEnd === 'start') {
            (_a = document.getElementById('endDate')) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            (_b = document.getElementById('startDate')) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, [startOrEnd]);
    var handleValidation = useCallback(function () {
        var _a;
        if (startOrEnd == 'start') {
            // only have start date
            if (endDate === undefined) {
                if (dateValidation(dateValue)) {
                    startDateOnChange(dateValue);
                }
                dateValidOnChange(dateValidation(dateValue));
            }
            // both start and end date
            else {
                if (dateValidation(dateValue) &&
                    combinedTimeValidation(dateValue, endDate, undefined, undefined)) {
                    startDateOnChange(dateValue);
                }
                if (dateValidation(dateValue) &&
                    !combinedTimeValidation(dateValue, endDate, undefined, undefined)) {
                    startDateOnChange(dateValue);
                    endDateOnChange(dateValue);
                }
                dateValidOnChange(dateValidation(dateValue) &&
                    combinedTimeValidation(dateValue, endDate, undefined, undefined));
            }
        }
        else {
            if (dateValidation(dateValue) &&
                combinedTimeValidation(startDate, dateValue, undefined, undefined)) {
                endDateOnChange(dateValue);
            }
            if (dateValidation(dateValue) &&
                !combinedTimeValidation(startDate, dateValue, undefined, undefined)) {
                startDateOnChange(dateValue);
                (_a = document.getElementById('endDate')) === null || _a === void 0 ? void 0 : _a.focus();
            }
            dateValidOnChange(dateValidation(dateValue));
        }
    }, [
        dateValidOnChange,
        dateValue,
        endDate,
        endDateOnChange,
        startDate,
        startDateOnChange,
        startOrEnd,
    ]);
    var onFocusChange = useCallback(function () {
        focusOnChange(startOrEnd);
    }, [focusOnChange, startOrEnd]);
    var onBlurChange = useCallback(function () {
        handleValidation();
    }, [handleValidation]);
    var onPressEnter = useCallback(function (e) {
        e.currentTarget.blur();
        changeFocus();
    }, [changeFocus]);
    var onInputChange = useCallback(function (e) {
        setDateValue(e.target.value);
    }, []);
    var onClick = useCallback(function (e) {
        e.currentTarget.focus();
    }, []);
    return (_jsx("div", { className: "DateInfo", style: { borderColor: isDateValid ? '#D9D9D9' : 'red' }, children: _jsx(Input, { autoFocus: startOrEnd === 'start' ? true : false, className: "DatePickerInput", id: startOrEnd === 'start' ? 'startDate' : 'endDate', bordered: false, value: dateValue, onFocus: onFocusChange, onBlur: onBlurChange, onPressEnter: onPressEnter, onChange: onInputChange, onClick: onClick }) }));
};
var TimeInput = function (props) {
    var startTime = props.startTime, endTime = props.endTime, startDate = props.startDate, endDate = props.endDate, startOrEnd = props.startOrEnd, timeOnChange = props.timeOnChange, timeValidOnChange = props.timeValidOnChange;
    var _a = useState(startOrEnd === 'start' ? startTime : endTime), timeValue = _a[0], setStartTimeValue = _a[1];
    var handleValidation = useCallback(function () {
        if (startOrEnd == 'start') {
            if (!startDate && !endDate) {
                if (timeValidation(timeValue)) {
                    timeOnChange(timeValue);
                }
                timeValidOnChange(timeValidation(timeValue));
            }
            else {
                if (timeValidation(timeValue)) {
                    timeOnChange(timeValue);
                }
                timeValidOnChange(timeValidation(timeValue) &&
                    combinedTimeValidation(startDate, endDate, timeValue, endTime));
            }
        }
        else {
            if (timeValidation(timeValue)) {
                timeOnChange(timeValue);
            }
            timeValidOnChange(timeValidation(timeValue) &&
                combinedTimeValidation(startDate, endDate, startTime, timeValue));
        }
    }, [
        endDate,
        endTime,
        startDate,
        startOrEnd,
        startTime,
        timeOnChange,
        timeValidOnChange,
        timeValue,
    ]);
    var onPressEnter = useCallback(function (e) {
        e.currentTarget.blur();
    }, []);
    var onInputChange = useCallback(function (e) {
        setStartTimeValue(e.target.value);
    }, []);
    var onClick = useCallback(function (e) {
        e.currentTarget.focus();
    }, []);
    return (_jsx(Input, { className: "TimeInput", bordered: false, value: timeValue, onBlur: handleValidation, onPressEnter: onPressEnter, onChange: onInputChange, onClick: onClick }));
};
var StartDateTimeInput = function (props) {
    var date = props.date, time = props.time, dateOnChange = props.dateOnChange, timeOnChange = props.timeOnChange, focusOnChange = props.focusOnChange;
    var _a = useState(true), isDateValid = _a[0], setDateValid = _a[1];
    var _b = useState(true), isTimeValid = _b[0], setTimeValid = _b[1];
    var dateValidOnChange = useCallback(function (status) {
        setDateValid(status);
    }, []);
    var timeValidOnChange = useCallback(function (status) {
        setTimeValid(status);
    }, []);
    return (_jsx(Tooltip, { title: "Invalid Input", color: "black", placement: "right", open: !(isDateValid && isTimeValid), destroyTooltipOnHide: true, children: _jsxs("div", { className: "DateTimeInfo", style: {
                borderColor: isDateValid && isTimeValid ? '#D9D9D9' : 'red',
            }, children: [_jsx(DateInput, { startOrEnd: 'start', startDate: date, endDate: undefined, startDateOnChange: dateOnChange, endDateOnChange: function () { }, isDateValid: isDateValid, dateValidOnChange: dateValidOnChange, focusOnChange: focusOnChange }), _jsx("span", { className: "Divider", children: "|" }), _jsx(TimeInput, { startOrEnd: "start", startTime: time, endTime: undefined, startDate: undefined, endDate: undefined, timeOnChange: timeOnChange, isTimeValid: isTimeValid, timeValidOnChange: timeValidOnChange })] }) }));
};
var StartEndDateInput = function (props) {
    var startDate = props.startDate, endDate = props.endDate, startDateOnChange = props.startDateOnChange, endDateOnChange = props.endDateOnChange, focusOnChange = props.focusOnChange;
    var _a = useState(true), isStartDateValid = _a[0], setStartDateValid = _a[1];
    var _b = useState(true), isEndDateValid = _b[0], setEndDateValid = _b[1];
    var startDateValidOnChange = useCallback(function (status) {
        setStartDateValid(status);
    }, []);
    var endDateValidOnChange = useCallback(function (status) {
        setEndDateValid(status);
    }, []);
    useEffect(function () {
        if (dateValidation(startDate) &&
            dateValidation(endDate) &&
            combinedTimeValidation(startDate, endDate, undefined, undefined)) {
            setStartDateValid(true);
            setEndDateValid(true);
        }
        else {
            setStartDateValid(false);
            setEndDateValid(false);
        }
    }, [endDate, startDate]);
    return (_jsx(Tooltip, { title: "Invalid Input", color: "black", placement: "right", open: !(isStartDateValid && isEndDateValid), destroyTooltipOnHide: true, children: _jsxs("div", { className: "StartEndDateInfo", children: [_jsx("div", { className: "DateContainer", style: { borderColor: isStartDateValid ? '#D9D9D9' : 'red' }, children: _jsx(DateInput, { startOrEnd: 'start', startDate: startDate, endDate: endDate, isDateValid: isStartDateValid, startDateOnChange: startDateOnChange, endDateOnChange: endDateOnChange, dateValidOnChange: startDateValidOnChange, focusOnChange: focusOnChange }) }), _jsx("div", { className: "DateContainer", style: { borderColor: isEndDateValid ? '#D9D9D9' : 'red' }, children: _jsx(DateInput, { startOrEnd: 'end', startDate: startDate, endDate: endDate, isDateValid: isEndDateValid, startDateOnChange: startDateOnChange, endDateOnChange: endDateOnChange, dateValidOnChange: endDateValidOnChange, focusOnChange: focusOnChange }) })] }) }));
};
var StartEndDateTimeInput = function (props) {
    var startDate = props.startDate, endDate = props.endDate, startDateOnChange = props.startDateOnChange, endDateOnChange = props.endDateOnChange, startTime = props.startTime, endTime = props.endTime, startTimeOnChange = props.startTimeOnChange, endTimeOnChange = props.endTimeOnChange, focusOnChange = props.focusOnChange;
    var _a = useState(true), isStartDateValid = _a[0], setStartDateValid = _a[1];
    var _b = useState(true), isStartTimeValid = _b[0], setStartTimeValid = _b[1];
    var _c = useState(true), isEndDateValid = _c[0], setEndDateValid = _c[1];
    var _d = useState(true), isEndTimeValid = _d[0], setEndTimeValid = _d[1];
    var startDateValidOnChange = useCallback(function (status) {
        setStartDateValid(status);
    }, []);
    var endDateValidOnChange = useCallback(function (status) {
        setEndDateValid(status);
    }, []);
    var startTimeValidOnChange = useCallback(function (status) {
        setStartTimeValid(status);
    }, []);
    var endTimeValidOnChange = useCallback(function (status) {
        setEndTimeValid(status);
    }, []);
    useEffect(function () {
        if (dateValidation(startDate) &&
            dateValidation(endDate) &&
            combinedTimeValidation(startDate, endDate, undefined, undefined) &&
            timeValidation(startTime) &&
            timeValidation(endTime) &&
            combinedTimeValidation(startDate, endDate, startTime, endTime)) {
            setStartDateValid(true);
            setEndDateValid(true);
            setStartTimeValid(true);
            setEndTimeValid(true);
        }
        else {
            setStartDateValid(false);
            setEndDateValid(false);
            setStartTimeValid(false);
            setEndTimeValid(false);
        }
    }, [endDate, endTime, startDate, startTime]);
    return (_jsx(Tooltip, { title: "Invalid Input", color: "black", placement: "right", open: !(isStartDateValid && isEndDateValid && isStartTimeValid && isEndTimeValid), destroyTooltipOnHide: true, children: _jsxs("div", { className: "StartEndDateTimeInfo", children: [_jsxs("div", { className: "DateTimeInfo", style: {
                        borderColor: isStartDateValid && isStartTimeValid ? '#D9D9D9' : 'red',
                    }, children: [_jsx(DateInput, { startOrEnd: 'start', startDate: startDate, endDate: endDate, isDateValid: isStartDateValid, startDateOnChange: startDateOnChange, endDateOnChange: endDateOnChange, dateValidOnChange: startDateValidOnChange, focusOnChange: focusOnChange }), _jsx("span", { className: "Divider", children: "|" }), _jsx(TimeInput, { startOrEnd: "start", startTime: startTime, endTime: endTime, startDate: startDate, endDate: endDate, timeOnChange: startTimeOnChange, isTimeValid: isStartTimeValid, timeValidOnChange: startTimeValidOnChange })] }), _jsxs("div", { className: "DateTimeInfo", style: { borderColor: isEndDateValid && isEndTimeValid ? '#D9D9D9' : 'red' }, children: [_jsx(DateInput, { startOrEnd: 'end', startDate: startDate, endDate: endDate, isDateValid: isEndDateValid, startDateOnChange: startDateOnChange, endDateOnChange: endDateOnChange, dateValidOnChange: endDateValidOnChange, focusOnChange: focusOnChange }), _jsx("span", { className: "Divider", children: "|" }), _jsx(TimeInput, { startOrEnd: "end", startTime: startTime, endTime: endTime, startDate: startDate, endDate: endDate, timeOnChange: endTimeOnChange, isTimeValid: isEndTimeValid, timeValidOnChange: endTimeValidOnChange })] })] }) }));
};
// default value
var defaultTime = '12:00 AM';
var DatePickerPopover = function (props) {
    var data = props.data, onEditTime = props.onEditTime, canEdit = props.canEdit, isDetailModal = props.isDetailModal, updateWithDefault = props.updateWithDefault, id = props.id, timeZone = props.timeZone, isFilter = props.isFilter, zIndex = props.zIndex;
    var timeFormatFn = useTimeZone(timeZone).timeFormatFn;
    var _a = useState(false), popOverVisible = _a[0], setPopOverVisible = _a[1];
    var defaultStartDate = data.type === ValueDataType.DATETIME
        ? data.dateTime
        : data.from;
    var _b = useState(defaultStartDate ? timeFormatFn(defaultStartDate, 'MMM D, YYYY', false) : undefined), startDate = _b[0], setStartDate = _b[1];
    var defaultEndDate = data.type === ValueDataType.DATETIME ? undefined : data.to;
    var _c = useState(defaultEndDate ? timeFormatFn(defaultEndDate, 'MMM D, YYYY', false) : undefined), endDate = _c[0], setEndDate = _c[1];
    var startTimeDefault = useMemo(function () {
        if (!defaultStartDate)
            return '';
        if (data.showTime && data.type === ValueDataType.DATETIME) {
            return timeFormatFn(data.dateTime, 'h:mm A', false);
        }
        if (data.showTime && data.type === ValueDataType.DATETIME_RANGE) {
            return timeFormatFn(data.from, 'h:mm A', false);
        }
        return '';
    }, [data, defaultStartDate, timeFormatFn]);
    var _d = useState(startTimeDefault), startTime = _d[0], setStartTime = _d[1];
    var endTimeDefault = useMemo(function () {
        if (!defaultEndDate)
            return '';
        if (data.showTime && data.type === ValueDataType.DATETIME_RANGE) {
            return timeFormatFn(data.to, 'h:mm A', false);
        }
        return '';
    }, [data, defaultEndDate, timeFormatFn]);
    var _e = useState(endTimeDefault), endTime = _e[0], setEndTime = _e[1];
    var isEndDateShown = useMemo(function () { return !(data.type === ValueDataType.DATETIME); }, [data]);
    var isTimeShown = useMemo(function () { return data.showTime || false; }, [data]);
    var onlyStartDate = !isEndDateShown && !isTimeShown;
    var startDateWithTime = !isEndDateShown && isTimeShown;
    var startEndDate = isEndDateShown && !isTimeShown;
    var startEndDateWithTime = isEndDateShown && isTimeShown;
    useEffect(function () {
        if (updateWithDefault) {
            setStartDate(defaultStartDate ? moment(defaultStartDate).format('MMM D, YYYY') : undefined);
            setEndDate(defaultEndDate ? moment(defaultEndDate).format('MMM D, YYYY') : undefined);
            setStartTime(startTimeDefault);
            setEndTime(endTimeDefault);
        }
    }, [updateWithDefault, defaultStartDate, defaultEndDate, startTimeDefault, endTimeDefault]);
    var defaultTimeData = useMemo(function () {
        var startDate = defaultStartDate
            ? timeFormatFn(defaultStartDate, 'MMM D, YYYY', false)
            : '';
        var endDate = defaultEndDate ? timeFormatFn(defaultEndDate, 'MMM D, YYYY', false) : '';
        if (onlyStartDate) {
            return startDate;
        }
        else if (startDateWithTime) {
            if (!startDate && !startTimeDefault)
                return '';
            return startDate + ' ' + startTimeDefault;
        }
        else if (defaultEndDate) {
            if (!startDate && !endDate)
                return '';
            return startDate + ' → ' + endDate;
        }
        else if (startEndDateWithTime) {
            return startDate + ' ' + startTime + ' → ' + (endDate + ' ' + endTime);
        }
        return '';
    }, [
        defaultEndDate,
        defaultStartDate,
        endTime,
        onlyStartDate,
        startDateWithTime,
        startEndDateWithTime,
        startTime,
        startTimeDefault,
        timeFormatFn,
    ]);
    var _f = useState(defaultTimeData), defaultDisplayTime = _f[0], setDefaultDisplayTime = _f[1];
    var _g = useState(true), isDateValid = _g[0], setDateValid = _g[1];
    var _h = useState('start'), focus = _h[0], setFocus = _h[1];
    var startDateOnChange = useCallback(function (date) {
        setStartDate(date);
    }, []);
    var startTimeOnChange = useCallback(function (Time) {
        setStartTime(Time);
    }, []);
    var endDateOnChange = useCallback(function (date) {
        setEndDate(date);
    }, []);
    var endTimeOnChange = useCallback(function (Time) {
        setEndTime(Time);
    }, []);
    var focusOnChange = useCallback(function (name) {
        setFocus(name);
    }, []);
    var dateValidOnChange = useCallback(function (status) {
        setDateValid(status);
    }, []);
    var dropdownStyle = zIndex ? { zIndex: zIndex + 1 } : undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var calendarHeaderRender = function (_a) {
        var value = _a.value, type = _a.type, onChange = _a.onChange, onTypeChange = _a.onTypeChange;
        var start = 0;
        var end = 12;
        var monthOptions = [];
        var current = value.clone();
        var localeData = value.localeData();
        var months = [];
        for (var i = 0; i < 12; i++) {
            current.month(i);
            months.push(localeData.monthsShort(current));
        }
        for (var index = start; index < end; index++) {
            monthOptions.push(_jsx(Select.Option, { className: "month-item", value: "".concat(index), children: months[index] }, "".concat(index)));
        }
        var month = value.month();
        var year = value.year();
        var options = [];
        for (var i = 1900; i < year + 10; i += 1) {
            options.push(_jsx(Select.Option, { value: i, className: "year-item", children: i }, i));
        }
        return (_jsx("div", { style: { padding: 8 }, children: _jsxs(Row, { gutter: 8, children: [_jsx(Col, { children: _jsx(Select, { size: "small", dropdownMatchSelectWidth: false, className: "my-year-select", onChange: function (newYear) {
                                var now = value.clone().year(Number(newYear));
                                onChange(now);
                            }, value: String(year), dropdownStyle: dropdownStyle, children: options }) }), _jsx(Col, { children: _jsx(Select, { size: "small", dropdownMatchSelectWidth: false, value: String(month), onChange: function (selectedMonth) {
                                var newValue = value.clone();
                                newValue.month(parseInt(selectedMonth, 10));
                                onChange(newValue);
                            }, dropdownStyle: dropdownStyle, children: monthOptions }) }), _jsx(Col, { children: _jsxs(Radio.Group, { size: "small", onChange: function (e) { return onTypeChange(e.target.value); }, value: type, children: [_jsx(Radio.Button, { value: "month", children: "Month" }), _jsx(Radio.Button, { value: "year", children: "Year" })] }) })] }) }));
    };
    var calendarCellRender = function (date) {
        var isToday = date.toDate().toDateString() === new Date().toDateString();
        var extraStyleString = isToday ? '1px solid #1890ff' : '';
        if (date.format('MMM D, YYYY') === startDate || date.format('MMM D, YYYY') === endDate) {
            return _jsxs("div", { className: "CalendarStartEndCell", children: [" ", date.date(), " "] });
        }
        else if (moment(date, 'MMM D, YYYY').isBetween(moment(startDate, 'MMM D, YYYY'), moment(endDate, 'MMM D, YYYY'), undefined, '[]') &&
            isEndDateShown)
            return (_jsx("div", { className: "CalendarInBetweenCell", style: {
                    border: extraStyleString,
                }, children: date.date() }));
        return (_jsx("div", { className: "CalendarCell", style: { border: extraStyleString }, children: date.date() }));
    };
    var calendarDataOnSelect = useCallback(function (e) {
        var _a, _b, _c;
        if (focus === 'start') {
            setStartDate(e.format('MMM D, YYYY'));
            setEndDate(e.format('MMM D, YYYY'));
            (_a = document.getElementById('endDate')) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            if (combinedTimeValidation(startDate, e.format('MMM D, YYYY'), undefined, undefined)) {
                setEndDate(e.format('MMM D, YYYY'));
                (_b = document.getElementById('endDate')) === null || _b === void 0 ? void 0 : _b.focus();
            }
            else {
                setStartDate(e.format('MMM D, YYYY'));
                setEndDate(e.format('MMM D, YYYY'));
                (_c = document.getElementById('endDate')) === null || _c === void 0 ? void 0 : _c.focus();
            }
        }
    }, [focus, startDate]);
    var handleClear = useCallback(function () {
        setStartDate(undefined);
        setEndDate(undefined);
        setStartTime('');
        setEndTime('');
    }, []);
    var tableCellDisplayValue = useMemo(function () {
        if (onlyStartDate) {
            return startDate || '';
        }
        else if (startDateWithTime) {
            if (startDate) {
                return startDate + ' ' + startTime;
            }
            return startTime;
        }
        else if (startEndDate &&
            combinedTimeValidation(startDate, endDate, undefined, undefined)) {
            return startDate + ' → ' + endDate;
        }
        else if (startEndDateWithTime &&
            combinedTimeValidation(startDate, endDate, startTime, endTime)) {
            return ((startDate ? startDate + ' ' + startTime + ' → ' : '') +
                (endDate ? endDate + ' ' + endTime : ''));
        }
        return '';
    }, [
        endDate,
        endTime,
        onlyStartDate,
        startDate,
        startDateWithTime,
        startEndDate,
        startEndDateWithTime,
        startTime,
    ]);
    var handleSave = useCallback(function () {
        onEditTime(_.split(tableCellDisplayValue, ' → '));
        setPopOverVisible(false);
        setDefaultDisplayTime(tableCellDisplayValue);
    }, [onEditTime, tableCellDisplayValue]);
    var content = (_jsxs("div", { className: "DatePickerContainer", children: [_jsxs("div", { className: "DateSettingInput", children: [onlyStartDate && (_jsx(Tooltip, { title: "Invalid Input", color: "black", placement: "right", open: !isDateValid, destroyTooltipOnHide: true, children: _jsx(DateInput, { startOrEnd: 'start', startDate: startDate || moment().format('MMM D, YYYY'), endDate: undefined, isDateValid: isDateValid, startDateOnChange: startDateOnChange, endDateOnChange: function () { }, dateValidOnChange: dateValidOnChange, focusOnChange: focusOnChange }) })), startDateWithTime && (_jsx(StartDateTimeInput, { date: startDate || moment().format('MMM D, YYYY'), time: startTime || defaultTime, dateOnChange: startDateOnChange, timeOnChange: startTimeOnChange, focusOnChange: focusOnChange })), startEndDate && (_jsx(StartEndDateInput, { startDate: startDate || moment().format('MMM D, YYYY'), endDate: endDate || moment().format('MMM D, YYYY'), startDateOnChange: startDateOnChange, endDateOnChange: endDateOnChange, focusOnChange: focusOnChange })), startEndDateWithTime && (_jsx(StartEndDateTimeInput, { startDate: startDate || moment().format('MMM D, YYYY'), startTime: startTime || defaultTime, endDate: endDate || moment().format('MMM D, YYYY'), endTime: endTime || defaultTime, startDateOnChange: startDateOnChange, startTimeOnChange: startTimeOnChange, endDateOnChange: endDateOnChange, endTimeOnChange: endTimeOnChange, focusOnChange: focusOnChange }))] }), _jsx(Calendar, { className: "CalendarCard", fullscreen: false, onSelect: calendarDataOnSelect, value: focus === 'start'
                    ? startDate
                        ? moment(startDate, 'MMM D, YYYY')
                        : moment()
                    : endDate
                        ? moment(endDate, 'MMM D, YYYY')
                        : moment(), dateFullCellRender: calendarCellRender, headerRender: calendarHeaderRender }), _jsxs(Menu, { className: "shadow-none", children: [_jsx(Menu.Divider, {}), _jsx(Menu.Item, { onClick: handleClear, children: "Clear" }, "clear"), _jsx(Menu.Item, { onClick: handleSave, className: "flex items-center rounded bg-accent font-semibold text-white", children: "Save" }, "save")] })] }));
    var onVisibleChange = useCallback(function (visible) {
        if (!canEdit)
            return;
        setPopOverVisible(visible);
    }, [canEdit]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraPopover, { className: "DatePickerPopOver", id: id !== null && id !== void 0 ? id : '', content: content, trigger: "click", destroyTooltipOnHide: true, open: popOverVisible, onLocalOpenChange: onVisibleChange, zIndex: zIndex, children: defaultDisplayTime || (_jsx("span", { className: isDetailModal ? 'EmptyContent' : '', children: isDetailModal ? 'Insert Value' : '' })) }), !isFilter && (_jsx("div", { className: "fpOverlay", onClick: function () {
                    setPopOverVisible(false);
                }, style: { display: popOverVisible ? 'block' : 'none' } }))] }));
};
export default DatePickerPopover;
