import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import DateRangeShiftPropagationModal from 'src/components/DateRangeShiftPropagationModal/DateRangeShiftPropagationModal';
import usePayRateV2Api from '../../usePayRateV2Api';
import { isDuplicatingDefaultRateState, isConfigDefaultRateModalOpenState, isCreatingDefaultRateState, currentDefaultRateState, isDateRangePropagationModalOpenState, } from '../../atoms';
import DefaultRateModalWrapper from './DefaultRateModalWrapper';
import DefaultRateTable from './DefaultRatesTable/DefaultRateTable';
var DefaultRatesTab = function () {
    var _a = useRecoilState(isDateRangePropagationModalOpenState), isDateRangePropagationModalOpen = _a[0], setIsDateRangePropagationModalOpen = _a[1];
    var _b = useRecoilState(isConfigDefaultRateModalOpenState), isConfigModalOpen = _b[0], setIsConfigModalOpen = _b[1];
    var setIsCreatingRate = useSetRecoilState(isCreatingDefaultRateState);
    var setIsDuplicatingDefaultRate = useSetRecoilState(isDuplicatingDefaultRateState);
    var resetCurrentDefaultRate = useResetRecoilState(currentDefaultRateState);
    var useGetDefaultRatesForAccount = usePayRateV2Api().useGetDefaultRatesForAccount;
    var _c = useGetDefaultRatesForAccount(), defaultRateData = _c.data, isLoading = _c.isLoading;
    return (_jsxs("div", { className: "mt-5", children: [_jsx(DefaultRateTable, { defaultRateData: defaultRateData, isLoading: isLoading, openEditModal: function () { return setIsConfigModalOpen(true); }, canEditTable: true }), isDateRangePropagationModalOpen && (_jsx(DateRangeShiftPropagationModal, { onClose: function () {
                    setIsDateRangePropagationModalOpen(false);
                } })), isConfigModalOpen && (_jsx(DefaultRateModalWrapper, { onClose: function () {
                    setIsConfigModalOpen(false);
                    setIsCreatingRate(false);
                    setIsDuplicatingDefaultRate(false);
                    resetCurrentDefaultRate();
                } }))] }));
};
export default React.memo(DefaultRatesTab);
