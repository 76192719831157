import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlined, DownOutlined, ExclamationOutlined, UpOutlined } from '@ant-design/icons';
import { Divider, Popover, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import { RuleType, scheduleRuleColorSet, scheduleRulesCheckColorSet } from './Constant';
import './IndictmentPopover.scss';
var IndictmentPopover = function (_a) {
    var showIndictmentCount = _a.showIndictmentCount, requirementsMet = _a.requirementsMet, brokenRulesTypeEvaluation = _a.brokenRulesTypeEvaluation, indictments = _a.indictments, trigger = _a.trigger, className = _a.className, children = _a.children;
    var _b = useState(indictments.length === 0), requirementsMetListVisible = _b[0], setRequirementsMetListVisible = _b[1];
    var requirementsMetList = useMemo(function () {
        return requirementsMet.map(function (name) {
            return (_jsxs("span", { children: [_jsxs("div", { className: "PopoverItem", children: [_jsx("div", { title: name, className: "RuleDisplayName", children: name }), _jsx(Tag, { className: "ItemTag", color: scheduleRulesCheckColorSet.SUCCESS, children: _jsx(CheckOutlined, {}) })] }), _jsx(Divider, {})] }, name));
        });
    }, [requirementsMet]);
    var indictmentList = useMemo(function () {
        return indictments.map(function (indictment, index) {
            var name = indictment.name, type = indictment.type;
            return (_jsxs("span", { children: [_jsxs("div", { className: "PopoverItem", children: [_jsx("div", { title: name, className: "RuleDisplayName", children: name }), _jsx(Tag, { className: "ItemTag", color: scheduleRuleColorSet[type], children: _jsx(ExclamationOutlined, {}) })] }), index !== indictments.length - 1 && _jsx(Divider, {})] }, name));
        });
    }, [indictments]);
    var indictmentPopover = useMemo(function () {
        var requirementsMetCount = requirementsMet.length;
        return (_jsxs(_Fragment, { children: [!!requirementsMetCount && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "PopoverItem", children: [_jsxs("span", { className: "BoldText", children: [indictmentList.length === 0 ? 'All' : requirementsMetCount, ' ', "Requirement", requirementsMetCount > 1 ? 's' : '', " Met"] }), !requirementsMetListVisible ? (_jsx(DownOutlined, { className: "ArrowButton", onClick: function (e) {
                                        e.stopPropagation();
                                        setRequirementsMetListVisible(true);
                                    } })) : (_jsx(UpOutlined, { className: "ArrowButton", onClick: function (e) {
                                        e.stopPropagation();
                                        setRequirementsMetListVisible(false);
                                    } })), _jsx(Tag, { className: "ItemTag", color: scheduleRulesCheckColorSet.SUCCESS, children: _jsx(CheckOutlined, {}) })] }), (indictmentList.length > 0 || requirementsMetListVisible) && _jsx(Divider, {}), requirementsMetListVisible && _jsx(_Fragment, { children: requirementsMetList })] })), _jsx(_Fragment, { children: indictmentList })] }));
    }, [indictmentList, requirementsMet.length, requirementsMetList, requirementsMetListVisible]);
    var indictmentIndicatorClassName = useMemo(function () {
        if (brokenRulesTypeEvaluation) {
            if (brokenRulesTypeEvaluation === RuleType.STRICT) {
                return 'BreakStrickRuleIndicator';
            }
            else if (brokenRulesTypeEvaluation === RuleType.MODERATE) {
                return 'BreakModerateRuleIndicator';
            }
        }
        return '';
    }, [brokenRulesTypeEvaluation]);
    return (_jsx(Popover, { placement: "right", content: indictmentPopover, trigger: trigger, overlayClassName: "ScheduleEvaluationPopover", id: "ScheduleEvaluationPopover", destroyTooltipOnHide: true, children: children ? (children) : indictments.length > 0 ? (showIndictmentCount ? (_jsx("div", { className: "BrokenRulesCountIndicator ".concat(indictmentIndicatorClassName), children: indictmentList.length })) : (_jsx("div", { onClick: function (e) { return e.stopPropagation(); }, className: "".concat(className, " ").concat(indictmentIndicatorClassName) }))) : (_jsx("div", { className: "RulesMatchedTag", children: _jsx(CheckOutlined, {}) })) }));
};
export default React.memo(IndictmentPopover);
