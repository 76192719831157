import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { RATING_ICON_COLOR_OPTIONS } from './Constant';
var RatingIconColorSelector = function (_a) {
    var currentColor = _a.currentColor, onUpdateColor = _a.onUpdateColor;
    return (_jsx("div", { className: "flex mt-2 space-x-2", children: RATING_ICON_COLOR_OPTIONS.map(function (color, index) {
            var isSelected = currentColor === color;
            return (_jsx("div", { className: "w-[30px] h-[30px] rounded-lg cursor-pointer", style: {
                    backgroundColor: color,
                    borderColor: isSelected ? '#717177' : undefined,
                    borderWidth: isSelected ? '1.5px' : undefined,
                }, onClick: function () { return onUpdateColor(color); } }, "".concat(color, "-").concat(index)));
        }) }));
};
export default React.memo(RatingIconColorSelector);
