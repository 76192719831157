var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../../App.less';
import './RecordDetailModal.scss';
import { collectionTableSchemaMutationsState, collectionTableSchemasState, detailModalActiveTab, detailModalIsFeedVisibleState, existingDetailModalInfo, ifCurrentUserHasActivityHistoryPermission, ifCurrentUserHasChatPermission, ifCurrentUserHasCommentsPermission, ifCurrentUserHasDataBuilderPermission, shiftDetailModalBreakdownInfoState, shiftModalTimeZoneIdState, } from 'src/state';
import { usePageApi } from 'src/hooks/api';
import ActivityFeedState from 'src/hooks/state/ActivityFeed';
import { useRecordComponent, useSchemaComponent, useWindowSize } from 'src/hooks/component';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useShiftModalComponent } from 'src/hooks/component/useShiftModal/useShiftModal';
import { Draggable } from 'react-beautiful-dnd';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
import { useModalRecordComponent } from 'src/hooks/component/useModalRecord';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { usePlacementModalComponent } from 'src/hooks/component/usePlacementModal/usePlacementModal';
import ShiftPayRulesBreakdown from 'src/components/RecordDetailModal/component/ShiftPayRulesBreakdown/ShiftPayRulesBreakdown';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { FRONTEND_TOTAL_BILL, PAYMENT_RULES_TAB } from 'src/components/FeatureGatingKeyConstant';
import usePayRateV2Api from '../Settings/PayRate/usePayRateV2Api';
import { CollectionTableType, CollectionType, NativePath, NonSchemaNativePath, ValueDataType, } from '../../shared';
import TableHeaderDropdown from '../TableView/TableHeader/TableHeaderDropdown';
import ZiraModal from '../../ui/ZiraModal/ZiraModal';
import ModalCellWrapper from '../OptimizedTableView/Cell/ModalCellWrapper/ModalCellWrapper';
import ZiraActionButton from '../../ui/ZiraActionButton';
import FeedContainer from '../CenterPanel/Feed/FeedContainer';
import { useArchiveOptions } from '../ArchiveOptions/useArchiveOptions';
import RecordDetailModalHeaderWrapper from './RecordDetailModalHeader/RecordDetailModalHeaderWrapper';
import DetailModalReverseCollection from './ReverseCollections/DetailModalReverseCollection';
import RecordDetailModalTabs from './RecordDetailModalTabs';
import SchemaGroupList from './SchemaGroup/SchemaGroupList';
import RecordDetailModalBackButton from './RecordDetailModalBackButton';
import EditRecurringShiftPopup from './RecordDetailModalHeader/Actions/Shift/RecurringRule/EditRecurringShiftPopup';
import DetailModalCellExtraContent from './DetailModalCellExtraContent/DetailModalCellExtraContent';
export var SchemaWithNewValuesType;
(function (SchemaWithNewValuesType) {
    SchemaWithNewValuesType[SchemaWithNewValuesType["NORMAL"] = 0] = "NORMAL";
    SchemaWithNewValuesType[SchemaWithNewValuesType["SPECIAL"] = 1] = "SPECIAL";
})(SchemaWithNewValuesType || (SchemaWithNewValuesType = {}));
var RecordDetailModal = function (_a) {
    var _b, _c, _d;
    var _e = _a.blockId, blockId = _e === void 0 ? '' : _e, isCreatingNew = _a.isCreatingNew, referenceRid = _a.referenceRid, getPreSaveRequestBody = _a.getPreSaveRequestBody, defaultTimeZone = _a.defaultTimeZone, recordId = _a.recordId, modalData = _a.defaultModalData, bulkEditData = _a.bulkEditData, onClose = _a.onClose, isStackedModal = _a.isStackedModal;
    var bulkMode = !!bulkEditData;
    var _f = useRecoilState(detailModalIsFeedVisibleState), isFeedVisible = _f[0], setIsFeedVisible = _f[1];
    var toggleFeedVisibility = function () {
        setIsFeedVisible(function (prev) { return !prev; });
    };
    var _g = useState(false), isEditRecurringModalVisible = _g[0], setIsEditRecurringModalVisible = _g[1];
    var mainRecord = modalData.mainRecord;
    var collectionInfo = mainRecord.collectionInfo, nonSchemaNativeFieldsMap = mainRecord.nonSchemaNativeFieldsMap, permissions = mainRecord.permissions, rows = mainRecord.rows;
    var nonSchemaNativeFields = nonSchemaNativeFieldsMap[recordId];
    var collectionId = collectionInfo.id, collectionType = collectionInfo.type;
    var hasDataBuilderAccess = useRecoilValue(ifCurrentUserHasDataBuilderPermission);
    var hasActivityHistoryAccess = useRecoilValue(ifCurrentUserHasActivityHistoryPermission);
    var hasChatAccess = useRecoilValue(ifCurrentUserHasChatPermission);
    var hasCommentsAccess = useRecoilValue(ifCurrentUserHasCommentsPermission);
    var timeZoneIdState = useRecoilState(shiftModalTimeZoneIdState({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
    }))[0];
    var _h = useState(defaultTimeZone), timeZoneId = _h[0], setTimeZoneId = _h[1];
    var isPaymentRulesTabOn = useRecoilValue(featureEnableStatusState(PAYMENT_RULES_TAB));
    var isTotalBillOn = useRecoilValue(featureEnableStatusState(FRONTEND_TOTAL_BILL));
    // Always show db timezone if updating from list, else take timezone in url
    useEffect(function () {
        if (timeZoneIdState && !isCreatingNew) {
            setTimeZoneId(timeZoneIdState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var canEditCollection = permissions.canEditCollection, canEditSchemas = permissions.canEditSchemas;
    var recordColor = (_b = rows[0]) === null || _b === void 0 ? void 0 : _b.color;
    var canEditSchemasOverride = hasDataBuilderAccess && canEditSchemas;
    var collectionTableType = isCreatingNew
        ? CollectionTableType.NEW_DETAIL_MODAL
        : CollectionTableType.DETAIL_MODAL;
    var tableDataSource = useMemo(function () { return ({ type: collectionTableType, collectionId: collectionId }); }, [collectionTableType, collectionId]);
    var schemaList = useRecoilValue(collectionTableSchemasState(collectionId));
    var _j = useWindowSize().windowWidth, windowWidth = _j === void 0 ? 0 : _j;
    var isMobileView = windowWidth <= 768;
    // Hook for bespoke shift detail logic
    var _k = useShiftModalComponent({
        recordId: recordId,
        dataSource: tableDataSource,
        collectionInfo: collectionInfo,
        isCreatingNew: isCreatingNew,
        nonSchemaNativeFields: nonSchemaNativeFields,
        defaultTimeZone: timeZoneIdState || timeZoneId,
    }), shiftTimeDetails = _k.shiftTimeDetails, renderShiftTimeSchemas = _k.renderShiftTimeSchemas, shiftFacePicData = _k.shiftFacePicData, recurringShiftData = _k.recurringShiftData, setRecurringShiftData = _k.setRecurringShiftData, saveRecurringRule = _k.saveRecurringRule, editRecurringShiftWithType = _k.editRecurringShiftWithType, duplicateData = _k.duplicateData, setDuplicateData = _k.setDuplicateData, getDuplicateFocusDate = _k.getDuplicateFocusDate, duplicateShifts = _k.duplicateShifts, isProjectTrackingEnabled = _k.isProjectTrackingEnabled, renderShiftAssigneeSchemaCell = _k.renderShiftAssigneeSchemaCell, renderShiftClientSchemaCell = _k.renderShiftClientSchemaCell, shiftTemplateData = _k.shiftTemplateData, applyShiftTemplateFields = _k.applyShiftTemplateFields, onDetachExistingTemplate = _k.onDetachExistingTemplate, applyOldTemplateFields = _k.applyTemplateFields;
    var useBreakdownPayRules = usePayRateV2Api().useBreakdownPayRules;
    var breakdownData = useBreakdownPayRules(collectionType === CollectionType.SHIFT, recordId).data;
    var setShiftDetailModalBreakdownInfo = useSetRecoilState(shiftDetailModalBreakdownInfoState(recordId));
    useEffect(function () {
        if (breakdownData && collectionType === CollectionType.SHIFT) {
            setShiftDetailModalBreakdownInfo(breakdownData.breakdowns);
        }
    }, [breakdownData, collectionType, setShiftDetailModalBreakdownInfo]);
    // Hook for bespoke placement detail logic
    usePlacementModalComponent({
        recordId: recordId,
        dataSource: tableDataSource,
        collectionInfo: collectionInfo,
        isCreatingNew: isCreatingNew,
    });
    var createNewRecord = useModalRecordComponent().createNewRecord;
    var _l = useRecordComponent(), editRecord = _l.editRecord, editRecordCell = _l.editRecordCell;
    var addNewColumn = useSchemaComponent().addNewColumn;
    var handleArchiveOptionsState = useArchiveOptions().handleArchiveOptionsState;
    var useClickButtonCell = usePageApi().useClickButtonCell;
    var refetchCollection = useRefetchCollection();
    // Set default isActive value of new job role
    useEffect(function () {
        var _a;
        if (isCreatingNew && collectionType === CollectionType.ROLE) {
            var roleIsActiveSchemaId = (_a = schemaList.find(function (schema) { return schema.nativePath === NativePath.ROLE_IS_ACTIVE; })) === null || _a === void 0 ? void 0 : _a.id;
            if (roleIsActiveSchemaId) {
                editRecordCell(collectionTableType, {
                    collectionId: collectionId,
                    recordId: mainRecord.rows[0].recordId,
                    schemaId: roleIsActiveSchemaId,
                }, ['false']);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainRecord]);
    var onRecurringEditSave = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (editType) { return __awaiter(void 0, void 0, void 0, function () {
            var schemasToMutate;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: 
                    // first call update_record on original shift
                    return [4 /*yield*/, editRecord(tableDataSource, collectionType, (_a = bulkEditData === null || bulkEditData === void 0 ? void 0 : bulkEditData.recordIds) !== null && _a !== void 0 ? _a : [recordId])];
                    case 1:
                        // first call update_record on original shift
                        _b.sent();
                        if (Object.keys(duplicateData).length > 0) {
                            duplicateShifts();
                        }
                        schemasToMutate = snapshot
                            .getLoadable(collectionTableSchemaMutationsState(tableDataSource))
                            .valueMaybe();
                        return [4 /*yield*/, editRecurringShiftWithType(editType, schemasToMutate !== null && schemasToMutate !== void 0 ? schemasToMutate : [])];
                    case 2:
                        _b.sent();
                        onClose(true);
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bulkEditData === null || bulkEditData === void 0 ? void 0 : bulkEditData.recordIds, collectionType, recordId, tableDataSource, duplicateData]);
    var handleSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var oldRecurringRule, duplicateShiftsCall, recurringShfitsCall, _a, _b, _c;
        var _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (!isCreatingNew) return [3 /*break*/, 2];
                    return [4 /*yield*/, createNewRecord(tableDataSource, collectionType, referenceRid)];
                case 1:
                    _h.sent();
                    return [3 /*break*/, 6];
                case 2:
                    if (!(collectionType === CollectionType.SHIFT && recurringShiftData)) return [3 /*break*/, 3];
                    oldRecurringRule = (_e = (_d = nonSchemaNativeFieldsMap[recordId]) === null || _d === void 0 ? void 0 : _d.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_RECURRING_RULE_ID; })) === null || _e === void 0 ? void 0 : _e.recurringRule;
                    // if the recurring rule itself changed, don't show edit type modal
                    if (isEqual(recurringShiftData, oldRecurringRule === null || oldRecurringRule === void 0 ? void 0 : oldRecurringRule.recurrenceDetails)) {
                        setIsEditRecurringModalVisible(true);
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 6];
                case 3:
                    if (![CollectionType.USER, CollectionType.ROLE, CollectionType.LOCATION].includes(collectionType)) return [3 /*break*/, 4];
                    handleArchiveOptionsState((_f = bulkEditData === null || bulkEditData === void 0 ? void 0 : bulkEditData.recordIds) !== null && _f !== void 0 ? _f : [recordId], collectionType, tableDataSource, function () { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, editRecord(tableDataSource, collectionType, (_a = bulkEditData === null || bulkEditData === void 0 ? void 0 : bulkEditData.recordIds) !== null && _a !== void 0 ? _a : [recordId])];
                                case 1:
                                    _b.sent();
                                    onClose(true);
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    return [2 /*return*/];
                case 4: return [4 /*yield*/, editRecord(tableDataSource, collectionType, (_g = bulkEditData === null || bulkEditData === void 0 ? void 0 : bulkEditData.recordIds) !== null && _g !== void 0 ? _g : [recordId])];
                case 5:
                    _h.sent();
                    _h.label = 6;
                case 6:
                    if (!(collectionType === CollectionType.SHIFT)) return [3 /*break*/, 10];
                    duplicateShiftsCall = void 0;
                    recurringShfitsCall = void 0;
                    if (Object.keys(duplicateData).length > 0) {
                        duplicateShiftsCall = duplicateShifts();
                    }
                    if (recurringShiftData) {
                        recurringShfitsCall = saveRecurringRule();
                    }
                    _b = (_a = Promise).all;
                    return [4 /*yield*/, duplicateShiftsCall];
                case 7:
                    _c = [_h.sent()];
                    return [4 /*yield*/, recurringShfitsCall];
                case 8: return [4 /*yield*/, _b.apply(_a, [_c.concat([_h.sent()])])];
                case 9:
                    _h.sent();
                    _h.label = 10;
                case 10:
                    onClose(true);
                    return [2 /*return*/];
            }
        });
    }); }, [
        isCreatingNew,
        collectionType,
        onClose,
        createNewRecord,
        tableDataSource,
        referenceRid,
        recurringShiftData,
        nonSchemaNativeFieldsMap,
        recordId,
        editRecord,
        bulkEditData === null || bulkEditData === void 0 ? void 0 : bulkEditData.recordIds,
        duplicateData,
        duplicateShifts,
        saveRecurringRule,
        handleArchiveOptionsState,
    ]);
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(collectionTableType, cellPos, newValues);
    }, [collectionTableType, editRecordCell]);
    var onClickButtonCell = useCallback(function (cellPos) {
        var request = {
            recordIds: [cellPos.recordId],
            schemaId: cellPos.schemaId,
            collectionId: cellPos.collectionId,
        };
        useClickButtonCell.mutate(request, {
            onSuccess: function (resp) {
                if (resp) {
                    var collectionId_1 = cellPos.collectionId;
                    refetchCollection(collectionId_1);
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var renderSchema = useCallback(function (schema, index) {
        var recordId = mainRecord.rows[0].recordId;
        if (schema.nativePath == NativePath.SHIFT_TOTAL_BILL && !isTotalBillOn) {
            return null;
        }
        return (_jsx(Draggable, { draggableId: schema.id, index: index, children: function (provided, snapshot) {
                var _a, _b, _c, _d;
                if (snapshot.isDragging) {
                    //! Add drag offset due to size of modal background mask
                    var modal = document.querySelector('.ZiraModal');
                    var yOffset = (_a = modal === null || modal === void 0 ? void 0 : modal.getBoundingClientRect().top) !== null && _a !== void 0 ? _a : 50;
                    var xOffset = (_b = modal === null || modal === void 0 ? void 0 : modal.getBoundingClientRect().left) !== null && _b !== void 0 ? _b : 140;
                    provided.draggableProps.style.top -= yOffset;
                    provided.draggableProps.style.left -= xOffset;
                }
                var breakdownItem = (_c = breakdownData === null || breakdownData === void 0 ? void 0 : breakdownData.breakdowns) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.schemaId === schema.id; });
                var showPayRuleData = collectionType === CollectionType.SHIFT &&
                    isPaymentRulesTabOn &&
                    breakdownItem &&
                    isPaymentRulesTabOn;
                var defaultRateName = (_d = breakdownData === null || breakdownData === void 0 ? void 0 : breakdownData.mapping) === null || _d === void 0 ? void 0 : _d.defaultRateName;
                return (_jsx("div", __assign({}, provided.draggableProps, { ref: provided.innerRef, children: _jsxs("div", { className: "SchemaRow", children: [!isMobileView && (_jsxs("div", { className: "flex min-w-[68px]", children: [canEditCollection && (_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, provided.dragHandleProps, { className: "DnDIcon" }))), canEditSchemasOverride && (_jsx(TableHeaderDropdown, { blockId: blockId, recordId: recordId, column: schema, canEdit: canEditSchemasOverride, isDetailModal: true, collectionId: collectionId, getPreSaveRequestBody: getPreSaveRequestBody, isCreatingNew: isCreatingNew }))] })), schema.nativePath === NativePath.SHIFT_USER_ID && (_jsx(_Fragment, { children: renderShiftAssigneeSchemaCell() })), collectionType === CollectionType.SHIFT &&
                                schema.type === ValueDataType.CONTACT && (_jsx(_Fragment, { children: renderShiftClientSchemaCell() })), (collectionType !== CollectionType.SHIFT ||
                                (collectionType === CollectionType.SHIFT &&
                                    schema.type !== ValueDataType.CONTACT &&
                                    schema.nativePath !== NativePath.SHIFT_USER_ID)) && (_jsx(ModalCellWrapper, { cellPos: {
                                    collectionId: collectionId,
                                    recordId: recordId,
                                    schemaId: schema.id,
                                }, collectionTableType: collectionTableType, editCell: onEditModalCell, clickButtonCell: onClickButtonCell, extraContent: schema.nativePath && (_jsx(DetailModalCellExtraContent, { recordId: recordId, nativePath: schema.nativePath, dataSource: tableDataSource, isCreatingNew: isCreatingNew })), expandedContent: showPayRuleData && (_jsx(ShiftPayRulesBreakdown, { breakdown: breakdownItem, defaultRateName: defaultRateName })) }))] }) })));
            } }, schema.id));
    }, [
        mainRecord.rows,
        collectionType,
        isPaymentRulesTabOn,
        isTotalBillOn,
        isMobileView,
        canEditCollection,
        canEditSchemasOverride,
        blockId,
        collectionId,
        getPreSaveRequestBody,
        isCreatingNew,
        renderShiftAssigneeSchemaCell,
        renderShiftClientSchemaCell,
        collectionTableType,
        onEditModalCell,
        onClickButtonCell,
        breakdownData,
        tableDataSource,
    ]);
    var onAddNewColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (column) { return __awaiter(void 0, void 0, void 0, function () {
            var release, isNewReverseCollectionRecord, referenceDetailModalInfo, request, requestFrom, schema;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        isNewReverseCollectionRecord = !!new URLSearchParams(location.search).get('rid') && isCreatingNew;
                        referenceDetailModalInfo = isNewReverseCollectionRecord
                            ? snapshot.getLoadable(existingDetailModalInfo).valueMaybe()
                            : undefined;
                        request = getPreSaveRequestBody &&
                            isNewReverseCollectionRecord &&
                            referenceDetailModalInfo
                            ? getPreSaveRequestBody(blockId, collectionId, referenceDetailModalInfo.collectionInfo.id, referenceDetailModalInfo.rows[0].recordId)
                            : getPreSaveRequestBody
                                ? getPreSaveRequestBody(blockId, collectionId)
                                : undefined;
                        requestFrom = isCreatingNew ? 'pre_save' : 'record_details';
                        return [4 /*yield*/, addNewColumn(requestFrom, blockId, { recordId: recordId }, column, request, collectionId)];
                    case 2:
                        schema = _a.sent();
                        return [2 /*return*/, schema];
                    case 3:
                        release();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addNewColumn, blockId, collectionId, isCreatingNew, recordId]);
    var activeTab = useRecoilValue(detailModalActiveTab(recordId));
    var location = useLocation();
    var shouldShowFeed = hasActivityHistoryAccess || hasChatAccess || hasCommentsAccess;
    return (_jsx(ZiraModal, { show: true, onClose: onClose, wrapperClassName: "ZiraModalWrapper visible", showCloseButton: true, size: "lg", children: _jsxs("div", { className: "ModalContainer", children: [_jsx(RecordDetailModalHeaderWrapper, { isCreatingNew: isCreatingNew, recordId: recordId, dataSource: tableDataSource, canEditCollection: canEditCollection, collectionType: collectionType, bulkMode: bulkMode, defaultTimeZone: timeZoneId, onCloseModal: onClose, recordColor: recordColor, shiftDetailProps: collectionType === CollectionType.SHIFT
                        ? {
                            shiftTimeDetails: shiftTimeDetails,
                            duplicateAction: {
                                defaultDate: getDuplicateFocusDate,
                                duplicateData: duplicateData,
                                setDuplicateData: setDuplicateData,
                            },
                            onApplyTemplateFields: applyShiftTemplateFields,
                            onApplyOldTemplateFields: applyOldTemplateFields,
                            onDetachExistingTemplate: onDetachExistingTemplate,
                            shiftFacePicData: shiftFacePicData,
                            recurringShiftAction: {
                                recurringShiftData: recurringShiftData,
                                setRecurringShiftData: setRecurringShiftData,
                            },
                            shiftTemplateData: shiftTemplateData,
                        }
                        : undefined, userDetailProps: collectionType === CollectionType.USER
                        ? {
                            avatarUrl: mainRecord.rows[0].avatarUrl,
                        }
                        : undefined }), _jsxs("div", { className: "ModalBodyWrapper", children: [_jsxs("div", { className: "BodyHeader", children: [_jsx(RecordDetailModalTabs, { recordId: recordId, modalData: modalData, isProjectTrackingEnabled: isProjectTrackingEnabled !== null && isProjectTrackingEnabled !== void 0 ? isProjectTrackingEnabled : false, isBulkEdit: bulkMode }), _jsxs("div", { className: "BodyHeaderButtons", children: [shouldShowFeed && (_jsx(Button, { className: "HeaderButton HeaderActivityButton", onClick: toggleFeedVisibility, children: "Feed" })), _jsx(ZiraActionButton, { className: "HeaderButton HeaderSaveButton", onClickFn: handleSave, children: "Save" })] })] }), _jsx("div", { className: "ModalDivider" }), _jsxs("div", { className: "ModalBody", id: "ModalBody", children: [_jsx("div", { id: "Details", style: {
                                        width: '100%',
                                        display: activeTab === 'Details' ? 'block' : 'none',
                                    }, children: _jsx(SchemaGroupList, { collectionId: collectionId, collectionType: collectionType, canEditCollection: canEditCollection, renderSchema: renderSchema, onAddNewColumn: !bulkMode && canEditSchemasOverride ? onAddNewColumn : undefined, shiftDetailProps: collectionType === CollectionType.SHIFT
                                            ? {
                                                renderShiftTimeSchemas: renderShiftTimeSchemas,
                                            }
                                            : undefined }) }), (!!((_c = modalData.allReverseCollections) === null || _c === void 0 ? void 0 : _c.length) ||
                                    [CollectionType.SHIFT, CollectionType.USER].includes(collectionType)) &&
                                    !isCreatingNew &&
                                    activeTab !== 'Details' && (_jsx(DetailModalReverseCollection, { recordId: recordId, reverseCollections: (_d = modalData.allReverseCollections) !== null && _d !== void 0 ? _d : [], referenceCollectionId: collectionId, referenceRecordId: recordId, modalData: modalData, dataSource: tableDataSource, extras: {
                                        shiftTimeDetails: shiftTimeDetails,
                                    } }))] })] }), isFeedVisible && shouldShowFeed && (_jsx("div", { className: "ModalDrawer", children: _jsx(ActivityFeedState.Provider, { children: _jsx(FeedContainer, { collapsed: isFeedVisible, recordId: recordId, isModal: true, userModalChatDataSource: collectionType === CollectionType.USER
                                ? tableDataSource
                                : undefined }) }) })), isEditRecurringModalVisible && (_jsx(EditRecurringShiftPopup, { isModalVisible: isEditRecurringModalVisible, setIsModalVisible: setIsEditRecurringModalVisible, onRecurringEditSave: onRecurringEditSave })), isStackedModal && !isMobileView && (_jsx(RecordDetailModalBackButton, { onClose: function () { return onClose(true); } }))] }) }));
};
export default React.memo(RecordDetailModal);
