import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Popconfirm, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { usePayApi } from 'src/hooks/api/usePayApi';
import { useTimeZone, useWindowSize } from 'src/hooks/component';
import { usePayComponent } from 'src/hooks/component/usePay';
import { isPayReportApprovedState } from '../atom';
import IndividualPayCardPrintInformationModal from '../IndividualPayCardPrintInformationModal';
import ExportIntegrationModal from '../PayExport/ExportIntegration/ExportIntegrationModal';
import ExportReportModal from '../PayExport/ExportReportModal';
import PayReportMetricRow from '../PayReportMetricRow';
import PayExportOptionModal from '../PayExport/PayExportOptionModal';
import TimesheetShiftMetricCardsRow from './TimesheetShiftMetricCardsRow';
var TotalPayReportCard = function (_a) {
    var payPeriodId = _a.payPeriodId, recordId = _a.recordId, handlePrint = _a.handlePrint, setPrintMetric = _a.setPrintMetric, setHeaderTimeRange = _a.setHeaderTimeRange, payrollEnhancementEnabled = _a.payrollEnhancementEnabled;
    var isIndividualPayReport = !!recordId;
    var _b = useState(false), isExportReportModalVisible = _b[0], setIsExportReportModalVisible = _b[1];
    var _c = useState(false), isExportIntegrationModalVisible = _c[0], setIsExportIntegrationModalVisible = _c[1];
    var _d = useState(false), isExportOptionModalVisible = _d[0], setIsExportOptionModalVisible = _d[1];
    var _e = useRecoilState(isPayReportApprovedState(isIndividualPayReport ? recordId : payPeriodId)), isApproved = _e[0], setIsApproved = _e[1];
    var _f = useState(false), isPrintInformationModalVisible = _f[0], setIsPrintInformationModalVisible = _f[1];
    var _g = usePayComponent(), refetchPayPeriodShiftStatusCount = _g.refetchPayPeriodShiftStatusCount, refetchIndividualPayCardList = _g.refetchIndividualPayCardList;
    var _h = usePayApi(), useApprovePayPeriod = _h.useApprovePayPeriod, useGetPayPeriodDataV2 = _h.useGetPayPeriodDataV2;
    var _j = useGetPayPeriodDataV2({
        body: {
            payPeriodId: payPeriodId,
            recordId: recordId,
        },
        options: {
            enabled: !!payPeriodId,
        },
    }), payRollData = _j.data, isLoading = _j.isLoading;
    var _k = payRollData !== null && payRollData !== void 0 ? payRollData : {}, startDate = _k.startDate, endDate = _k.endDate, payMetrics = _k.payMetrics, durationMetrics = _k.durationMetrics, shiftsPerStatus = _k.shiftsPerStatus, totalShiftsCount = _k.totalShiftsCount;
    var onOpenExportReportModal = useCallback(function () {
        setIsExportReportModalVisible(true);
    }, []);
    var onOpenExportIntegrationModal = useCallback(function () {
        setIsExportIntegrationModalVisible(true);
    }, []);
    var onOpenExportOptionModal = useCallback(function () {
        setIsExportOptionModalVisible(true);
    }, []);
    useEffect(function () {
        if (payRollData) {
            setIsApproved(payRollData.isApproved);
            if (durationMetrics && setPrintMetric) {
                setPrintMetric(durationMetrics);
            }
            if (setHeaderTimeRange && startDate && endDate) {
                setHeaderTimeRange([startDate, endDate]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payRollData, setPrintMetric, setHeaderTimeRange, startDate, endDate]);
    var approvePayRollButton = useCallback(function () { return (_jsx(Popconfirm, { title: "Are you sure you want to approve this payroll?", onConfirm: function () {
            useApprovePayPeriod.mutate({ payPeriodId: payPeriodId, isApproved: true }, {
                onSuccess: function () {
                    if (payrollEnhancementEnabled) {
                        refetchIndividualPayCardList();
                        refetchPayPeriodShiftStatusCount();
                    }
                    setIsApproved(true);
                },
            });
        }, placement: "leftTop", children: _jsx(Button, { className: "ml-auto mr-4 w-36 bg-green-500 text-white font-medium hover:bg-green-500 hover:text-white", type: "text", children: "Approve Payroll" }) })); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payPeriodId]);
    var windowWidth = useWindowSize().windowWidth;
    var _l = useTimeZone(), timeFormatFn = _l.timeFormatFn, timezoneAbbr = _l.timezoneAbbr;
    if (!payRollData && isLoading) {
        return (_jsx(Skeleton, { style: {
                padding: (windowWidth !== null && windowWidth !== void 0 ? windowWidth : 577) > 576 ? '0px 48px 64px' : '0px 0px 64px',
            }, className: "TotalPay", active: true, paragraph: { rows: 1, width: '100%' } }));
    }
    return (_jsxs(_Fragment, { children: [payrollEnhancementEnabled && !isIndividualPayReport && (_jsx(TimesheetShiftMetricCardsRow, { totalCount: totalShiftsCount !== null && totalShiftsCount !== void 0 ? totalShiftsCount : 0, statusCount: shiftsPerStatus })), _jsxs("div", { className: "w-full", style: {
                    padding: (windowWidth !== null && windowWidth !== void 0 ? windowWidth : 577) > 576 ? '64px 48px' : '64px 0px',
                }, children: [isIndividualPayReport && (_jsxs("div", { className: "h-9 w-fit rounded-t-lg bg-black px-4 py-2 font-medium text-white shadow-block", children: [timeFormatFn(startDate, 'MMM DD', false), " -", ' ', timeFormatFn(endDate, 'MMM DD', false), '  ', timezoneAbbr()] })), _jsxs("div", { className: "overflow-hidden rounded-2xl bg-white py-4 pl-5 shadow-block", children: [_jsxs("div", { className: "flex w-full items-center", children: [_jsx(PayReportMetricRow, { aggregateMetrics: payMetrics !== null && payMetrics !== void 0 ? payMetrics : [], largeValue: true }), !recordId && (_jsx(_Fragment, { children: isApproved ? (_jsx("div", { className: "ml-auto mr-4 flex h-8 w-36 items-center justify-center rounded bg-green-50 font-semibold text-green-500 ", children: "Approved" })) : (_jsx(_Fragment, { children: approvePayRollButton() })) }))] }), _jsx(Divider, {}), _jsxs("div", { className: "flex items-center", children: [_jsx(PayReportMetricRow, { aggregateMetrics: durationMetrics !== null && durationMetrics !== void 0 ? durationMetrics : [], largeValue: true }), handlePrint && (_jsx(Button, { className: "ml-auto mr-4 w-36 bg-accent text-white hover:bg-accent hover:text-white", type: "text", onClick: function () { return setIsPrintInformationModalVisible(true); }, children: "Print" })), _jsx(Button, { className: "".concat(handlePrint ? '' : 'ml-auto', " mr-4 w-36 bg-black text-white font-medium hover:bg-black hover:text-white"), type: "text", onClick: function () {
                                            // Show export option modal if for entire pay period export
                                            if (!recordId) {
                                                onOpenExportOptionModal();
                                            }
                                            else {
                                                // Only show export report modal if for individual record export
                                                onOpenExportReportModal();
                                            }
                                        }, children: "Export Payroll" })] })] })] }), isExportReportModalVisible && (_jsx(ExportReportModal, { payPeriodId: payPeriodId, recordId: recordId, isModalVisible: isExportReportModalVisible, setIsModalVisible: setIsExportReportModalVisible, payrollEnhancementEnabled: payrollEnhancementEnabled })), isExportIntegrationModalVisible && (_jsx(ExportIntegrationModal, { isModalVisible: isExportIntegrationModalVisible, setIsModalVisible: setIsExportIntegrationModalVisible })), isExportOptionModalVisible && (_jsx(PayExportOptionModal, { onCloseExportOptionModal: function () { return setIsExportOptionModalVisible(false); }, onOpenExportReportModal: onOpenExportReportModal, onOpenExportIntegrationModal: onOpenExportIntegrationModal })), isPrintInformationModalVisible && handlePrint && (_jsx(IndividualPayCardPrintInformationModal, { onConfirm: function () {
                    handlePrint(null);
                    setIsPrintInformationModalVisible(false);
                }, onClose: function () { return setIsPrintInformationModalVisible(false); } }))] }));
};
export default React.memo(TotalPayReportCard);
