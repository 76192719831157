import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useCallback } from 'react';
import TaskCompletionStatus from 'src/components/Settings/Tasks/shared/TaskCompletionStatus';
import TaskMenuPopover from 'src/components/Settings/Tasks/shared/TaskMenuPopover';
import { useTasksApi } from 'src/hooks/api';
import { useTimeZone } from 'src/hooks/component';
var RecordDetailTask = function (_a) {
    var task = _a.task, isGroupTask = _a.isGroupTask, refetchTasks = _a.refetchTasks;
    var id = task.id, taskTemplate = task.taskTemplate, isCompleted = task.isCompleted, addedAt = task.addedAt, completedAt = task.completedAt, dueDate = task.dueDate;
    var name = taskTemplate.name;
    var useUnassignTaskFromUser = useTasksApi().useUnassignTaskFromUser;
    var unassignTask = useCallback(function () {
        useUnassignTaskFromUser.mutate(id, {
            onSuccess: function (resp) {
                if (resp) {
                    refetchTasks();
                    return;
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    var timeFormatFn = useTimeZone().timeFormatFn;
    var isDue = !isCompleted && !!dueDate;
    var isPastDue = !isCompleted && moment(dueDate).isBefore();
    return (_jsxs("div", { className: "flex flex-wrap items-center border-2 rounded-3xl border-[#EBF4F6] w-full py-[10px] px-[20px] relative", children: [_jsxs("div", { className: "flex items-center space-x-[16px]", children: [_jsx(TaskCompletionStatus, { isCompleted: isCompleted }), _jsx("div", { className: "text-sm font-semibold", children: name })] }), _jsxs("div", { className: "flex items-center space-x-[16px] pl-[53px] pr-[32px] ml-auto text-gray-300 text-xs font-medium overflow-hidden", children: [_jsx("div", { className: "w-[160px]", style: isDue ? (isPastDue ? { color: 'red' } : { color: 'black' }) : undefined, children: isCompleted && completedAt
                            ? "Completed on ".concat(timeFormatFn(completedAt, 'M/D/YYYY', false))
                            : isDue
                                ? "Due ".concat(moment(dueDate).fromNow())
                                : null }), _jsxs("div", { className: "w-[140px]", children: ["Added on ", timeFormatFn(addedAt, 'M/D/YYYY', false)] })] }), !isGroupTask && (_jsx(TaskMenuPopover, { content: _jsx("div", { className: "TaskMenuItem text-red-1000", onClick: unassignTask, children: "Unassign" }), children: _jsx("div", { className: "absolute top-[1/4] right-[32px] cursor-pointer", onClick: function (e) { return e.stopPropagation(); }, children: _jsx(MoreOutlined, { className: "text-[24px]" }) }) }))] }));
};
export default React.memo(RecordDetailTask);
