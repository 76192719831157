var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Checkbox } from 'antd';
import { collectionTableNonSchemaMutationsState, shiftModalIsGroupState, } from 'src/state';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { uniqBy } from 'lodash';
import { NonSchemaNativePath } from 'src/shared';
import { InfoCircleOutlined } from '@ant-design/icons';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useMemo } from 'react';
var ShiftDetailCreateGroup = function (_a) {
    var recordId = _a.recordId, dataSource = _a.dataSource, canEdit = _a.canEdit, isCreatingNew = _a.isCreatingNew;
    var _b = useRecoilState(shiftModalIsGroupState(__assign(__assign({}, dataSource), { recordId: recordId }))), isGroupState = _b[0], setIsGroupState = _b[1];
    var onIsGroupChange = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (isGroup) {
            setIsGroupState(isGroup);
            set(collectionTableNonSchemaMutationsState(dataSource), function (prev) {
                return uniqBy(__spreadArray([
                    {
                        schema: NonSchemaNativePath.SHIFT_IS_GROUP,
                        boolean: isGroup !== null && isGroup !== void 0 ? isGroup : false,
                    }
                ], prev, true), 'schema');
            });
        };
    }, [dataSource, setIsGroupState]);
    var createGroupCheckbox = useMemo(function () { return (_jsx(Checkbox, { checked: isGroupState, onChange: function (e) {
            onIsGroupChange(e.target.checked);
        } })); }, [isGroupState, onIsGroupChange]);
    if (!canEdit)
        return _jsx(_Fragment, {});
    return isCreatingNew ? (_jsx("div", { className: "flex flex-col mt-3", children: _jsxs("div", { className: "flex space-x-[12px]", children: [_jsx("div", { className: "flex", children: _jsxs(ZiraTooltip, { title: "Check this box to bundle together all shifts created from open count or through a recurring rule.", children: [_jsx("span", { className: "font-medium text-sm m-auto mr-1", children: "Create Group" }), _jsx(InfoCircleOutlined, {})] }) }), createGroupCheckbox] }) })) : (_jsx("div", {}));
};
export default React.memo(ShiftDetailCreateGroup);
