var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AutoApplyTemplateIconWithWhiteBgSvg = function () { return (_jsx("svg", { width: "6", height: "10", viewBox: "0 0 6 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.9663 4.42846L1.59634 9.94059C1.56584 9.97831 1.51925 10.0002 1.47007 9.99996C1.44446 10.0006 1.41909 9.99465 1.39655 9.98277C1.36325 9.96604 1.33701 9.93844 1.32239 9.90475C1.3078 9.87106 1.30574 9.83343 1.31663 9.79841L2.49466 5.99241H0.157857C0.128354 5.99232 0.0994154 5.98424 0.0743074 5.96908C0.0491995 5.95391 0.0288868 5.93226 0.0156001 5.90648C0.0034788 5.88165 -0.00174944 5.85411 0.000515587 5.82667C0.00274668 5.79923 0.0123701 5.77287 0.0283873 5.75024L4.26732 0.0647141C4.29546 0.0266634 4.33961 0.00299999 4.38756 0.000265178C4.43555 -0.00246897 4.4822 0.0160516 4.51467 0.0506526C4.54713 0.0852536 4.56195 0.132288 4.55502 0.178769L3.99399 4.17691H5.84011C5.87008 4.17685 5.89945 4.18502 5.92486 4.20051C5.9503 4.21597 5.97078 4.23811 5.98397 4.26441C5.99699 4.29064 6.00225 4.31991 5.99912 4.34888C5.99602 4.37788 5.98467 4.40545 5.96639 4.42846H5.9663Z", fill: "white" }) })); };
export var AutoApplyTemplateIconWithWhiteBg = function (props) { return (_jsx(Icon, __assign({ component: AutoApplyTemplateIconWithWhiteBgSvg }, props))); };
