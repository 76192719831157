var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var HelpIconSvg = function () { return (_jsxs("svg", { width: "17", height: "18", viewBox: "1 2.5 18 17", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" }), _jsx("path", { d: "M7.7758 7.15696C7.73308 7.32164 7.59671 7.46154 7.42353 7.46154H6.79638C6.62319 7.46154 6.48075 7.32309 6.50213 7.15446C6.65617 5.93991 7.71192 5 8.9914 5C10.3769 5 11.5 6.10207 11.5 7.46154C11.5 8.60852 10.7005 9.57227 9.61855 9.84553V10.2308C9.61855 10.4007 9.47816 10.5385 9.30498 10.5385H8.67783C8.50464 10.5385 8.36425 10.4007 8.36425 10.2308V9C8.36425 8.83007 8.50464 8.69231 8.67783 8.69231H8.9914C9.68413 8.69231 10.2457 8.14127 10.2457 7.46154C10.2457 6.7818 9.68413 6.23077 8.9914 6.23077C8.40581 6.23077 7.91394 6.62454 7.7758 7.15696Z" }), _jsx("path", { d: "M8.67783 11.7692C8.50464 11.7692 8.36425 11.907 8.36425 12.0769V12.6923C8.36425 12.8622 8.50464 13 8.67783 13H9.30498C9.47816 13 9.61855 12.8622 9.61855 12.6923V12.0769C9.61855 11.907 9.47816 11.7692 9.30498 11.7692H8.67783Z" })] })); };
export var HelpIcon = function (props) { return (_jsx(Icon, __assign({ component: HelpIconSvg }, props))); };
