import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Select } from 'antd';
import { useQuery } from 'react-query';
import { useContext } from 'react';
import { useFetch } from 'src/hooks/api';
import { RollupConfigurationContext } from '../../RollupConfigurationModal';
import RollupAddFilter from './component/RollupAddFilter';
var RollupReverseCollectionSelector = function (_a) {
    var type = _a.type, hasDenominator = _a.hasDenominator;
    var _b = useContext(RollupConfigurationContext), rollupConfiguration = _b.rollupConfiguration, setRefIds = _b.setRefIds;
    var collectionId = rollupConfiguration.collectionId, refCollectionId = rollupConfiguration.refCollectionId, refSchemaId = rollupConfiguration.refSchemaId;
    var customFetch = useFetch().request;
    var _c = useQuery(['useGetReverseCollectionsByCollectionId', collectionId], function () {
        return customFetch({
            method: 'GET',
            url: "collections/schemas/reverse/".concat(collectionId),
        });
    }, {
        onSuccess: function (data) {
            if (data && data.length > 0 && (!refCollectionId || !refSchemaId)) {
                setRefIds(data[0].collectionId, data[0].schemaId);
            }
        },
    }), reverseSchemas = _c.data, status = _c.status;
    var reverseCollections = [];
    var reverseCollectionSchemas = {};
    reverseSchemas === null || reverseSchemas === void 0 ? void 0 : reverseSchemas.forEach(function (collectionSchema) {
        if (!reverseCollections.find(function (item) { return item.value === collectionSchema.collectionId; })) {
            reverseCollections.push({
                label: collectionSchema.collectionName,
                value: collectionSchema.collectionId,
            });
        }
        var schemaOption = {
            label: collectionSchema.schemaName,
            value: collectionSchema.schemaId,
        };
        if (reverseCollectionSchemas[collectionSchema.collectionId]) {
            reverseCollectionSchemas[collectionSchema.collectionId].push(schemaOption);
        }
        else {
            reverseCollectionSchemas[collectionSchema.collectionId] = [schemaOption];
        }
    });
    var options = (reverseSchemas || []).map(function (item) { return ({
        label: "".concat(item.collectionName, " - ").concat(item.schemaName),
        value: "".concat(item.collectionId, ",").concat(item.schemaId),
    }); });
    return (_jsx("div", { className: "flex flex-col", children: options.length > 0 && status === 'success' ? (_jsxs(_Fragment, { children: [_jsx("div", { className: "capitalize font-medium text-gray-400 text-xs mb-2", children: hasDenominator
                        ? "".concat(type, " Count")
                        : "".concat(rollupConfiguration.rollupType.toLowerCase()) }), _jsxs("div", { className: "flex flex-col space-y-2", children: [_jsxs("div", { className: "flex flex-row space-x-2", children: [_jsx("div", { className: "min-w-[161px]", children: _jsx(Select, { disabled: type === 'denominator', labelInValue: true, options: reverseCollections, onSelect: function (item) {
                                            var _a;
                                            var value = item.value;
                                            setRefIds(value, ((_a = reverseCollectionSchemas === null || reverseCollectionSchemas === void 0 ? void 0 : reverseCollectionSchemas[value]) === null || _a === void 0 ? void 0 : _a[0].value) ||
                                                undefined);
                                        }, value: reverseCollections.find(function (item) { return item.value === refCollectionId; }), className: "w-full" }) }), _jsx("div", { className: "min-w-[161px]", children: _jsx(Select, { className: "w-full", labelInValue: true, disabled: type === 'denominator' ||
                                            refCollectionId === undefined ||
                                            ((reverseCollectionSchemas === null || reverseCollectionSchemas === void 0 ? void 0 : reverseCollectionSchemas[refCollectionId]) || [])
                                                .length <= 1, options: refCollectionId
                                            ? reverseCollectionSchemas[refCollectionId]
                                            : [], onSelect: function (item) {
                                            var value = item.value;
                                            setRefIds(refCollectionId, value);
                                        }, value: refCollectionId
                                            ? ((reverseCollectionSchemas === null || reverseCollectionSchemas === void 0 ? void 0 : reverseCollectionSchemas[refCollectionId]) || []).find(function (item) { return item.value === refSchemaId; })
                                            : undefined }) })] }), _jsx("div", { children: _jsx(RollupAddFilter, { type: type }) })] })] })) : (_jsx("div", { children: "No reverse collections found. Unable to Rollup" })) }));
};
export default RollupReverseCollectionSelector;
