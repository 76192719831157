import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInputCell.scss';
var PhoneInputCell = function (props) {
    var phone = props.phone, editCell = props.editCell, canEdit = props.canEdit;
    var _a = useState(!phone), showEmptyPlaceholder = _a[0], setShowEmptyPlaceholder = _a[1];
    var _b = useState(false), autoFocus = _b[0], setAutoFocus = _b[1];
    var _c = useState(phone), phoneInput = _c[0], setPhoneInput = _c[1];
    var _d = useState(0), formattedPhoneInputLength = _d[0], setFormattedPhoneInputLength = _d[1];
    var _e = useState(0), expectedPhoneLength = _e[0], setExpectedPhoneLength = _e[1];
    var _f = useState(true), isInputValid = _f[0], setIsInputValid = _f[1];
    var _g = useState(false), isOverlayVisible = _g[0], setIsOverlayVisible = _g[1];
    var firstTimeClickOut = useRef(true);
    var onEdit = useCallback(function () {
        setShowEmptyPlaceholder(!phoneInput);
        // pass phone validation
        if (formattedPhoneInputLength === expectedPhoneLength || phoneInput === '') {
            if (phone === phoneInput) {
                setIsOverlayVisible(false);
                setIsInputValid(true);
                return;
            }
            setIsInputValid(true);
            var newPhoneValue = !phoneInput ? '' : "+".concat(phoneInput);
            editCell === null || editCell === void 0 ? void 0 : editCell(newPhoneValue);
            setIsOverlayVisible(false);
        }
        // fail phone validation
        else {
            setIsInputValid(false);
            // second time click overlay area, set everything to original status
            if (firstTimeClickOut.current === false) {
                setIsOverlayVisible(false);
                setIsInputValid(true);
                setPhoneInput(phone);
                setShowEmptyPlaceholder(!phone);
            }
            else {
                // first time click overlay area
                firstTimeClickOut.current = false;
                return;
            }
        }
    }, [editCell, expectedPhoneLength, formattedPhoneInputLength, phone, phoneInput]);
    if (showEmptyPlaceholder) {
        return (_jsx("div", { className: "EmptyCellPlacerholder", onClick: function () {
                setShowEmptyPlaceholder(false);
                setAutoFocus(true);
                setIsInputValid(true);
            }, children: "Insert Value" }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { placement: "top", title: "Invalid phone format, can not be saved!", destroyTooltipOnHide: true, open: !isInputValid, children: _jsx(PhoneInput, { inputProps: {
                        autoFocus: autoFocus,
                    }, containerClass: "PhoneInputContainer", containerStyle: { border: !isInputValid ? '1px solid red' : 'none' }, inputClass: "PhoneInput", dropdownClass: "CountryDropdown", value: phoneInput, country: "us", onChange: function (value, countryData, e, formattedValue) {
                        setPhoneInput(value);
                        setFormattedPhoneInputLength(formattedValue.length);
                        if (expectedPhoneLength !== countryData.format.length) {
                            setExpectedPhoneLength(countryData.format.length);
                        }
                    }, disabled: !canEdit, onFocus: function () {
                        firstTimeClickOut.current = true;
                        setIsOverlayVisible(true);
                    }, onKeyDown: function (e) {
                        if (e.key === 'Enter') {
                            onEdit();
                        }
                    } }) }), _jsx("div", { className: "fpOverlay", onClick: onEdit, style: { display: isOverlayVisible ? 'block' : 'none' } })] }));
};
export default React.memo(PhoneInputCell);
