var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Breadcrumb, Checkbox, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import { useSetting } from 'src/hooks/api';
import SettingsContentHeader from '../SettingsContentHeader';
import './DashboardSettings.scss';
import { PayMetricValueOptions, PayMetricValueType, ShiftMetricValueOptions, } from './interface';
var DashboardSettingsPage = function () {
    var _a = useSetting(), useGetDashboardSettings = _a.useGetDashboardSettings, useUpdateDashboardSettings = _a.useUpdateDashboardSettings;
    var _b = useState(), dashboardSettings = _b[0], setDashboardSettings = _b[1];
    var _c = useGetDashboardSettings(), fetchDashboardSettingsData = _c.data, refetch = _c.refetch;
    useEffect(function () {
        if (fetchDashboardSettingsData) {
            if (fetchDashboardSettingsData.payDisplayType !== undefined &&
                fetchDashboardSettingsData.payDisplayType !== 'NONE') {
                setDashboardSettings({
                    payDisplayType: PayMetricValueType.NONE,
                    showPayMetrics: [fetchDashboardSettingsData.payDisplayType],
                    showShiftMetrics: fetchDashboardSettingsData.showShiftMetrics,
                    showAccountOnboardingPanel: fetchDashboardSettingsData.showAccountOnboardingPanel,
                });
            }
            else {
                setDashboardSettings(fetchDashboardSettingsData);
            }
        }
    }, [fetchDashboardSettingsData]);
    var onPayValueCheckboxChange = useCallback(function (payMetricArray) {
        setDashboardSettings(__assign(__assign({}, dashboardSettings), { payDisplayType: PayMetricValueType.NONE, showPayMetrics: payMetricArray }));
    }, [dashboardSettings]);
    var onShiftValueCheckboxChange = useCallback(function (shiftMetricArray) {
        setDashboardSettings(__assign(__assign({}, dashboardSettings), { showShiftMetrics: shiftMetricArray }));
    }, [dashboardSettings]);
    var onShowAccountOnboardingPanelToggle = useCallback(function (checked) {
        setDashboardSettings(__assign(__assign({}, dashboardSettings), { showAccountOnboardingPanel: checked }));
    }, [dashboardSettings]);
    var onSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!dashboardSettings) return [3 /*break*/, 2];
                    return [4 /*yield*/, useUpdateDashboardSettings.mutateAsync(dashboardSettings, {
                            onError: function () {
                                refetch();
                            },
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [refetch, dashboardSettings, useUpdateDashboardSettings]);
    return (_jsxs("div", { children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Dashboard" }) }), _jsx("div", { className: "DashboardSettingsPage", children: _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [_jsx("div", { className: "text-[32px] font-semibold", children: "Dashboard" }), _jsx("div", { className: "text-sm font-medium text-[#666] mb-8", children: "Manage and configure settings for your home page dashboard here" }), _jsxs("div", { className: "mb-8", children: [_jsx("div", { className: "text-[24px] font-semibold mb-2", children: "Metric Cards" }), _jsxs("div", { className: "flex flex-col gap-4", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("div", { className: "text-lg font-medium", children: "Pay Value" }), _jsx("div", { className: "text-sm text-[#666] font-medium", children: "Select which metric you want displayed on the last pay metric card on the homepage. This can either be the total amount paid to staff or total amount billed over the last pay period." })] }), _jsx(Checkbox.Group, { options: PayMetricValueOptions.map(function (option) { return ({
                                                label: option.displayValue,
                                                value: option.value,
                                            }); }), value: dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings.showPayMetrics, onChange: onPayValueCheckboxChange })] }), _jsxs("div", { className: "flex flex-col gap-4 mt-8", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("div", { className: "text-lg font-medium", children: "Shifts" }), _jsx("div", { className: "text-sm text-[#666] font-medium", children: "Select which shift metrics you want displayed on the homepage." })] }), _jsx(Checkbox.Group, { options: ShiftMetricValueOptions.map(function (option) { return ({
                                                label: option.displayValue,
                                                value: option.value,
                                            }); }), value: dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings.showShiftMetrics, onChange: onShiftValueCheckboxChange })] })] }), _jsxs("div", { children: [_jsx("div", { className: "text-[24px] font-semibold mb-2", children: "Layout" }), _jsxs("div", { className: "mt-5 flex items-end space-x-2", children: [_jsx(Switch, { checked: dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings.showAccountOnboardingPanel, onChange: onShowAccountOnboardingPanelToggle }), _jsx("span", { className: "font-medium", children: "Display account onboarding panel" })] })] })] }) }), _jsx("div", { className: "SettingContentFooter", children: _jsx(ZiraActionButton, { className: "SaveSettingBtn", onClickFn: onSave, children: "Save" }) })] }));
};
export default React.memo(DashboardSettingsPage);
