import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import '../TimeZoneSelectModal.scss';
import { scheduleStartDayOfWeekSelector } from 'src/state/selectors/schedule';
import { StartDayOfWeek } from 'src/shared';
import { upperCase } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useScheduleComponent } from 'src/hooks/component';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
var Option = Select.Option;
var StartDayOfWeekSelectModal = function (_a) {
    var isModalVisible = _a.isModalVisible, onModalVisibleChange = _a.onModalVisibleChange, tableId = _a.tableId, type = _a.type;
    var startDayOfWeek = useRecoilValue(scheduleStartDayOfWeekSelector(tableId));
    var _b = useState(startDayOfWeek), tempStartDayOfWeek = _b[0], setTempStartDayOfWeek = _b[1];
    useEffect(function () {
        setTempStartDayOfWeek(startDayOfWeek);
    }, [startDayOfWeek]);
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var onCancelClick = useCallback(function () {
        onModalVisibleChange(false);
    }, [onModalVisibleChange]);
    var onApplyClick = useCallback(function () {
        applyScheduleDisplaySettings(tableId, { startDayOfWeek: tempStartDayOfWeek }, type);
        onModalVisibleChange(false);
    }, [applyScheduleDisplaySettings, onModalVisibleChange, tableId, tempStartDayOfWeek, type]);
    return (_jsxs(Modal, { open: isModalVisible, closable: false, centered: true, width: 560, footer: _jsx(ZiraModalFooter, { saveBtnText: "Apply", onCancel: onCancelClick, onSave: onApplyClick }), children: [_jsx("div", { className: "text-[18px] font-semibold", children: "Select Start Day of Week" }), _jsx("div", { className: "mb-[10px] font-medium text-gray-400", children: "This schedule block will have the selected start day of week." }), _jsx(Select, { className: "w-full", value: tempStartDayOfWeek, onChange: setTempStartDayOfWeek, children: StartDayOfWeek.map(function (item) { return (_jsx(Option, { value: upperCase(item), children: item }, item)); }) })] }));
};
export default React.memo(StartDayOfWeekSelectModal);
