var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { orderBy, sortBy, isEqual } from 'lodash';
import moment from 'moment';
import { ThreadReceiverParticipantType, MessageSenderType, } from 'src/components/PageView/ChatPage/interface';
import { arrayEqualUnordered } from 'src/utils/Tools/LodashTool';
export var threadIsBroadcast = function (thread) {
    return threadIsBroadcastFromParticipants(thread.participants);
};
export var threadIsBroadcastFromParticipants = function (participants) {
    return participants.some(function (participant) {
        return participant.participantType === ThreadReceiverParticipantType.BROADCAST_ACCOUNT ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_LOCATION ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_ROLE;
    });
};
export var threadIsBroadcastFromChatRecipients = function (recipients) {
    return recipients.some(function (recipient) {
        return recipient.type === ThreadReceiverParticipantType.BROADCAST_ACCOUNT ||
            recipient.type === ThreadReceiverParticipantType.BROADCAST_LOCATION ||
            recipient.type === ThreadReceiverParticipantType.BROADCAST_ROLE;
    });
};
export var participantsInThreadTitle = function (participants, currentUserRid) {
    participants = participants.filter(function (participant) {
        return !(participant.participantRid === currentUserRid &&
            participant.participantType === MessageSenderType.USER);
    });
    if (threadIsBroadcastFromParticipants(participants)) {
        participants = participants.filter(function (participant) { return participant.participantType !== MessageSenderType.DEPARTMENT; });
    }
    participants = __spreadArray(__spreadArray([], sortBy(participants.filter(function (p) { return p.participantType === MessageSenderType.USER; }), 'name'), true), orderBy(orderBy(participants.filter(function (p) { return p.participantType !== MessageSenderType.USER; }), 'name'), 'participantType', 'desc'), true);
    return participants;
};
export var broadcastRecipientsFromParticipants = function (participants) {
    return participants
        .filter(function (participant) {
        return participant.participantType === ThreadReceiverParticipantType.BROADCAST_ACCOUNT ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_LOCATION ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_ROLE;
    })
        .map(function (participant) { return ({
        name: participant.name,
        id: participant.participantRid,
        type: participant.participantType,
    }); });
};
export var threadParticipantsToBroadcastChatParticipants = function (participants) {
    return participants
        .filter(function (participant) {
        return participant.participantType === ThreadReceiverParticipantType.BROADCAST_ACCOUNT ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_LOCATION ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_ROLE;
    })
        .map(function (participant) { return ({
        name: participant.name,
        id: participant.participantRid,
        type: participant.participantType,
    }); });
};
export var threadParticipantsToBroadcastRecipients = function (participants) {
    return participants
        .filter(function (participant) {
        return participant.participantType === ThreadReceiverParticipantType.BROADCAST_ACCOUNT ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_LOCATION ||
            participant.participantType === ThreadReceiverParticipantType.BROADCAST_ROLE;
    })
        .map(function (participant) { return ({
        name: participant.name,
        id: participant.participantRid,
        type: participant.participantType,
    }); });
};
export var chatParticipantsToBroadcastRecipients = function (participants) {
    return participants
        .filter(function (participant) {
        return participant.type === ThreadReceiverParticipantType.BROADCAST_ACCOUNT ||
            participant.type === ThreadReceiverParticipantType.BROADCAST_LOCATION ||
            participant.type === ThreadReceiverParticipantType.BROADCAST_ROLE;
    })
        .map(function (participant) { return ({
        name: participant.name,
        id: participant.id,
        type: participant.type,
    }); });
};
export var getTimeSince = function (dateString) {
    var date = new Date(dateString);
    var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    var intervalType;
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        intervalType = 'y';
    }
    else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            intervalType = 'mo';
        }
        else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                intervalType = 'd';
            }
            else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    intervalType = 'h';
                }
                else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        intervalType = 'm';
                    }
                    else {
                        interval = seconds;
                        intervalType = 'now';
                    }
                }
            }
        }
    }
    return intervalType === 'now' ? intervalType : "".concat(interval).concat(intervalType);
};
export var getMessageTimeStamp = function (createdAt) {
    if (moment(createdAt).isSame(moment(), 'day')) {
        return "Today, ".concat(moment(createdAt).format('h:mm A'));
    }
    else {
        return moment(createdAt).format('MMM D, h:mm A');
    }
};
export var userToUserThreadInfo = function (participants) {
    if ((participants.length === 2 &&
        participants[0].participantType === MessageSenderType.USER &&
        participants[1].participantType === MessageSenderType.USER) ||
        (participants.length === 1 && participants[0].participantType === MessageSenderType.USER)) {
        return { userRids: participants.map(function (p) { return p.participantRid; }) };
    }
    return undefined;
};
export var userToDepartmentThreadInfo = function (participants) {
    var participantTypes = participants.map(function (p) { return p.participantType; });
    if (participants.length === 2 &&
        arrayEqualUnordered(participantTypes, ['USER', 'DEPARTMENT'], isEqual)) {
        var department = participants.find(function (p) { return p.participantType === MessageSenderType.DEPARTMENT; });
        return department ? { departmentId: department.participantRid } : undefined;
    }
    return undefined;
};
