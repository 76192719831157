import { useMutation, useQuery } from 'react-query';
import { useCallback } from 'react';
import { useFetch } from './useFetch';
export var useShiftRecommendationsApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var useGetShiftRecommendationSummary = function (request) {
        return useQuery(['ShiftRecommendationSummary', JSON.stringify(request)], function () {
            return customFetch({
                method: 'POST',
                url: 'shift_recommendations/summary_counts',
            }, request);
        });
    };
    var useGetShiftRecommendationEvaluations = function (request) {
        return useQuery(['shiftRecommendationEvaluations', JSON.stringify(request.body)], function () { return getShiftRecommendationEvaluations(request.body); }, request.options);
    };
    var getShiftRecommendationEvaluations = useCallback(function (request) {
        return customFetch({
            method: 'POST',
            url: 'shift_recommendations',
        }, request);
    }, [customFetch]);
    var useRecommendShiftToUser = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'shift_recommendations/recommend',
            }, request);
        });
    };
    var useRecommendShiftToUserGroup = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'shift_recommendations/recommend_for_group',
            }, request);
        });
    };
    return {
        useGetShiftRecommendationSummary: useGetShiftRecommendationSummary,
        useGetShiftRecommendationEvaluations: useGetShiftRecommendationEvaluations,
        getShiftRecommendationEvaluations: getShiftRecommendationEvaluations,
        useRecommendShiftToUser: useRecommendShiftToUser(),
        useRecommendShiftToUserGroup: useRecommendShiftToUserGroup(),
    };
};
