import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './MessageItem.scss';
import { useRecordDetail } from 'src/hooks/component';
import { getMessageTimeStamp } from '../../utils';
import MessageItemUserAvatar from './MessageItemUserAvatar';
import MessageAttachmentItem from './MessageAttachmentItem';
import TextWithLinks from './TextWithLinks';
var ReceiverMessageItem = function (_a) {
    var _b;
    var message = _a.message;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    return (_jsx("div", { className: "ChatMessageListItem OtherMessage", children: _jsxs("div", { className: "MessageChatView", children: [_jsx(MessageItemUserAvatar, { message: message }), _jsxs("div", { className: "MessageChatItem", children: [_jsxs("span", { className: "MessageSenderName", children: [_jsx("span", { className: "cursor-pointer hover:underline", onClick: function () { return openRecordDetail(message.senderDetail.id); }, children: message.senderDetail.name }), message.departmentSenderDetail &&
                                    " \u2022 ".concat((_b = message.departmentSenderDetail) === null || _b === void 0 ? void 0 : _b.name), _jsx("span", { className: "MessageTime", children: getMessageTimeStamp(message.createdAt) })] }), message.body.text && (_jsx("div", { className: "MessageChat", children: _jsx("p", { className: "text-sm font-medium", children: _jsx(TextWithLinks, { text: message.body.text }) }) })), message.body.attachments.map(function (attachment, index) {
                            return (_jsx(MessageAttachmentItem, { attachment: attachment }, "attachment-".concat(index)));
                        })] })] }) }));
};
export default ReceiverMessageItem;
