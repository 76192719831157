var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import ModalCellWrapper from 'src/components/OptimizedTableView/Cell/ModalCellWrapper/ModalCellWrapper';
import RecordColorSelectorDropdown from 'src/components/RecordColorSelectorDropdown/RecordColorSelectorDropdown';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { getCurrencyValue } from 'src/utils/Collection';
import { CollectionTableType, CollectionType, ValueDataType } from 'src/shared';
import { useScheduleTemplateApi } from 'src/hooks/api';
import { useRecordComponent } from 'src/hooks/component';
import { useShiftTemplateComponent } from 'src/hooks/component/useShiftTemplate';
import { collectionTableRecordState, collectionTableState, selectedShiftTemplateIdState, } from 'src/state';
import { shiftTemplateDefaultSchemasState, shiftTemplateSchemaOptionsState, } from 'src/state/selectors/schedule/withTemplateDetailState';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import { NameInputPenIcon } from 'src/utils/icons/NameInputPenIcon';
import './ShiftTemplateDetailModal.scss';
import ShiftTemplateTimePickerCell from './ShiftTemplateTimePickerCell';
export var showCrossDayIndicator = function (startTime, endTime) {
    var startTimeMoment = moment(startTime, 'hh:mm');
    var endTimeMoment = moment(endTime, 'hh:mm');
    return endTimeMoment.isBefore(startTimeMoment);
};
var DEFAULT_TEMPLATE_COLOR = '#F4F4F7';
//!Mark  Just a placeholder rid string so the state/functions works
var NEW_TEMPLATE_RID = 'new-shift-template';
var DEFAULT_TEMPLATE_NAME = 'Untitled Template';
var DEFAULT_TEMPLATE_TIME_DATA = {
    startTime: '09:00',
    endTime: '17:00',
};
var ShiftTemplateDetailModal = function (props) {
    var collectionId = props.collectionId, isModalVisible = props.isModalVisible, closeModal = props.closeModal;
    var editRecordCell = useRecordComponent().editRecordCell;
    var _a = useShiftTemplateComponent(), createOrUpdateTemplate = _a.createOrUpdateTemplate, refetchShiftTemplates = _a.refetchShiftTemplates;
    var templateId = useRecoilValue(selectedShiftTemplateIdState);
    var isCreatingNew = !templateId;
    var _b = useState(''), templateName = _b[0], setTemplateName = _b[1];
    var _c = useState(false), colorEditMenuVisible = _c[0], setColorEditMenuVisible = _c[1];
    var _d = useState(DEFAULT_TEMPLATE_COLOR), currentColor = _d[0], setCurrentColor = _d[1];
    var _e = useState(DEFAULT_TEMPLATE_TIME_DATA), templateTimeData = _e[0], setTemplateTimeData = _e[1];
    var useGetShiftTemplateDetail = useScheduleTemplateApi().useGetShiftTemplateDetail;
    var _f = useGetShiftTemplateDetail({
        body: {
            templateId: templateId !== null && templateId !== void 0 ? templateId : '',
        },
        options: {
            enabled: !!templateId,
        },
    }), shiftTemplateDetail = _f.data, isFetching = _f.isFetching;
    var collectionTableType = CollectionTableType.SHIFT_TEMPLATE;
    var schemaOptions = useRecoilValue(shiftTemplateSchemaOptionsState(collectionId));
    var defaultSchemas = useRecoilValue(shiftTemplateDefaultSchemasState(collectionId));
    var dataSource = useMemo(function () { return ({
        type: CollectionTableType.SHIFT_TEMPLATE,
        collectionId: collectionId,
    }); }, [collectionId]);
    var setShiftTemplateData = useSetRecoilState(collectionTableState(dataSource));
    var dataSourceRid = isCreatingNew ? NEW_TEMPLATE_RID : templateId;
    var recordData = useRecoilValue(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: dataSourceRid })));
    var selectedSchemas = useMemo(function () {
        return Object.keys(recordData !== null && recordData !== void 0 ? recordData : {});
    }, [recordData]);
    // Init modal data
    useEffect(function () {
        var _a, _b;
        var _c, _d;
        var cellData = {};
        if (isCreatingNew) {
            setTemplateName(DEFAULT_TEMPLATE_NAME);
            setTemplateTimeData(DEFAULT_TEMPLATE_TIME_DATA);
            var templateModalRecord_1 = {};
            defaultSchemas.map(function (schema) {
                templateModalRecord_1[schema] = {
                    content: null,
                    options: [],
                    files: [],
                    versionedOptions: [],
                };
            });
            cellData = (_a = {}, _a[NEW_TEMPLATE_RID] = templateModalRecord_1, _a);
        }
        // Existing template
        else if (shiftTemplateDetail) {
            var templateModalRecord = Object.values(shiftTemplateDetail.columns)[0];
            cellData = (_b = {},
                _b[templateId] = templateModalRecord,
                _b);
            setTemplateName(shiftTemplateDetail.name);
            setCurrentColor((_c = shiftTemplateDetail.color) !== null && _c !== void 0 ? _c : DEFAULT_TEMPLATE_COLOR);
            var timeData = {
                startTime: shiftTemplateDetail.startTime,
                endTime: shiftTemplateDetail.endTime,
            };
            setTemplateTimeData(timeData);
            var _loop_1 = function (schemaId, newCell) {
                var isCurrency = ((_d = schemaOptions.find(function (schema) { return schema.id === schemaId; })) === null || _d === void 0 ? void 0 : _d.type) ===
                    ValueDataType.NATIVE_CURRENCY;
                // Prefill mutation schema state with existing cell data
                editRecordCell(collectionTableType, {
                    collectionId: collectionId,
                    recordId: dataSourceRid,
                    schemaId: schemaId,
                }, newCell.content
                    ? [
                        isCurrency && newCell.content
                            ? getCurrencyValue(newCell.content)
                            : newCell.content,
                    ]
                    : newCell.options.length
                        ? newCell.options
                        : newCell.files);
            };
            for (var _i = 0, _e = Object.entries(templateModalRecord); _i < _e.length; _i++) {
                var _f = _e[_i], schemaId = _f[0], newCell = _f[1];
                _loop_1(schemaId, newCell);
            }
        }
        setShiftTemplateData({
            collectionInfo: {
                id: collectionId,
                type: CollectionType.SHIFT,
                name: 'Shift',
            },
            columns: [],
            rows: [],
            cells: cellData,
            nonSchemaNativeFieldsMap: {},
            permissions: { canEditCollection: false, canEditSchemas: false },
        });
    }, [
        collectionId,
        collectionTableType,
        dataSourceRid,
        defaultSchemas,
        editRecordCell,
        isCreatingNew,
        schemaOptions,
        setShiftTemplateData,
        shiftTemplateDetail,
        templateId,
    ]);
    var onEditTime = useCallback(function (isStartTime, time) {
        var newTime = cloneDeep(templateTimeData);
        if (isStartTime) {
            newTime.startTime = time;
        }
        else {
            newTime.endTime = time;
        }
        setTemplateTimeData(newTime);
    }, [templateTimeData]);
    var onEditModalCell = useCallback(function (cellPos, newValues) {
        editRecordCell(collectionTableType, cellPos, newValues);
    }, [collectionTableType, editRecordCell]);
    var onAddField = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId) {
            if (!recordData)
                return;
            var newRecordData = cloneDeep(recordData);
            newRecordData[schemaId] = {
                content: null,
                options: [],
                files: [],
                versionedOptions: [],
            };
            set(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: dataSourceRid })), newRecordData);
        };
    }, [dataSource, dataSourceRid, recordData]);
    var onDeleteField = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId) {
            if (!recordData)
                return;
            var newRecordData = cloneDeep(recordData);
            delete newRecordData[schemaId];
            set(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: dataSourceRid })), newRecordData);
        };
    }, [dataSource, dataSourceRid, recordData]);
    var dropdownMenuItems = useMemo(function () {
        return schemaOptions.map(function (schema) {
            return {
                key: schema.id,
                label: schema.name,
                onClick: function () { return onAddField(schema.id); },
                disabled: selectedSchemas.includes(schema.id), // Disable option when it's already included
            };
        });
    }, [onAddField, schemaOptions, selectedSchemas]);
    var onSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, createOrUpdateTemplate(templateName, (_a = templateTimeData === null || templateTimeData === void 0 ? void 0 : templateTimeData.startTime) !== null && _a !== void 0 ? _a : '', (_b = templateTimeData === null || templateTimeData === void 0 ? void 0 : templateTimeData.endTime) !== null && _b !== void 0 ? _b : '', dataSource, selectedSchemas, currentColor, templateId)];
                case 1:
                    resp = _c.sent();
                    if (resp) {
                        refetchShiftTemplates();
                        closeModal(templateId);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentColor, dataSource, selectedSchemas, templateName, templateTimeData, templateId]);
    return (_jsxs(Modal, { open: isModalVisible, footer: null, closable: false, className: "ShiftTemplateConfigModal", width: 800, zIndex: 1001, bodyStyle: { height: 'calc(100vh - 200px)', overflowY: 'auto' }, centered: true, children: [_jsx(Button, { className: "ModalCloseButton", onClick: function () {
                    closeModal();
                }, children: "\u2715" }), isFetching ? (_jsx("div", { className: "logo-container h-[716px] w-full", children: _jsx(LoadingLogo, { className: "main-loading-logo" }) })) : (_jsxs("div", { children: [_jsxs("div", { className: "TemplateModalHeader", children: [_jsx(RecordColorSelectorDropdown, { currentColor: currentColor, visible: colorEditMenuVisible, onVisibleChange: setColorEditMenuVisible, onUpdateRecordColor: function (color) { return setCurrentColor(color); }, children: _jsxs("div", { className: "ColorSelectionButton", onClick: function () { return setColorEditMenuVisible(true); }, children: [_jsx("div", { className: "mr-[5px] h-[15px] w-[15px]", style: {
                                                backgroundColor: currentColor || 'white',
                                                border: !currentColor ? '1px solid black' : undefined,
                                            } }), _jsx("div", { children: "Template Color" })] }) }), _jsx(Button, { className: "HeaderSaveButton", onClick: onSave, children: "Save" })] }), _jsx(Input, { className: "TemplateNameInput", suffix: _jsx(NameInputPenIcon, {}), bordered: false, autoFocus: true, placeholder: "Enter Template Name Here", value: templateName, onChange: function (e) { return setTemplateName(e.target.value); } }), _jsx("hr", { className: "TemplateContentDivider" }), _jsxs("div", { className: "TemplateConfigSection mt-[50px]", children: [_jsxs("div", { className: "TemplateTimePickerSection", children: [_jsx(ShiftTemplateTimePickerCell, { isStartTime: true, time: templateTimeData.startTime, setTime: function (time) { return onEditTime(true, time); } }), _jsx("div", { className: "TimePickerDivider" }), _jsx(ShiftTemplateTimePickerCell, { isStartTime: false, time: templateTimeData.endTime, setTime: function (time) { return onEditTime(false, time); }, showCrossDayIndicator: showCrossDayIndicator(templateTimeData.startTime, templateTimeData.endTime) })] }), _jsx("div", { className: "TemplateFieldSection", children: schemaOptions.map(function (schema) {
                                    if (selectedSchemas.includes(schema.id)) {
                                        return (_jsxs("div", { className: "TemplateSchemaRow", children: [_jsx(ModalCellWrapper, { cellPos: {
                                                        collectionId: collectionId,
                                                        recordId: dataSourceRid,
                                                        schemaId: schema.id,
                                                    }, collectionTableType: CollectionTableType.SHIFT_TEMPLATE, editCell: onEditModalCell }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete ".concat(schema.name, " field?"), onConfirm: function () { return onDeleteField(schema.id); }, placement: "leftBottom", children: _jsx(ZiraTooltip, { title: "Delete Template Field", children: _jsx("div", { className: "p-1 hover:cursor-pointer", children: _jsx(DeleteOutlinedIcon, { fill: "black", className: "mx-[0.2em]" }) }) }) })] }, schema.id));
                                    }
                                }) })] }), _jsx(Dropdown, { menu: { items: dropdownMenuItems }, trigger: ['click'], overlayClassName: "NewPageDropdownMenu", children: _jsx(Button, { className: "AddNewFieldButton", icon: _jsx(PlusOutlined, { style: {
                                    color: 'black',
                                    fontSize: '14px',
                                    stroke: 'black',
                                    strokeWidth: '10px',
                                    marginBottom: 2,
                                } }), children: "Add Field" }) })] }))] }));
};
export default React.memo(ShiftTemplateDetailModal);
