import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tableFiltersSelector, scheduleTimeRangeState, scheduleTimeZoneSelector, scheduleTableState, tableCollectionIdSelector, ifUserHasGeneralPermission, } from 'src/state';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import moment from 'moment-timezone';
import { useScheduleNeedsCoverageComponent } from 'src/hooks/component';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { currentNeedOrCoverageBeingAppliedState, needsOrCoverageInTransactionState, } from '../Atoms/Atoms';
import NeedsCoverageTimeRangeSelectModal from '../NeedsCoverageTimeRangeSelectModal';
import NeedsCoverageTemplateSelector from './NeedsCoverageTemplateSelector';
import NeedsCoverageTags from './NeedsCoverageTags';
var ScheduleNeedsSummaryInfo = function (_a) {
    var blockId = _a.blockId;
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var blockFilters = useRecoilValue(tableFiltersSelector(blockId));
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var table = useRecoilValue(scheduleTableState(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var refetchCollection = useRefetchCollection();
    var hasNeedsAndCoverageEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE));
    var hasNeedsEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_EDIT_PERMISSION)) ||
        hasNeedsAndCoverageEditPermission;
    var _b = useState(), selectedTemplateToApply = _b[0], setSelectedTemplateToApply = _b[1];
    var _c = useState(false), isReapply = _c[0], setIsReapply = _c[1];
    var _d = useNeedCoverageApi(), useGetAllNeedTemplates = _d.useGetAllNeedTemplates, useApplyNeedToSchedule = _d.useApplyNeedToSchedule, useRemoveNeedFromSchedule = _d.useRemoveNeedFromSchedule, useRefreshNeedTemplate = _d.useRefreshNeedTemplate;
    var refetchTemplateCountStats = useScheduleNeedsCoverageComponent().refetchTemplateCountStats;
    var setCurrentNeedOrCoverageBeingApplied = useSetRecoilState(currentNeedOrCoverageBeingAppliedState);
    var _e = useGetAllNeedTemplates({
        body: {
            start: timeRange[0],
            end: moment(timeRange[timeRange.length - 2])
                .endOf('day')
                .toISOString(),
            filters: blockFilters.length ? blockFilters : undefined,
        },
        options: {
            enabled: !!timeRange.length,
        },
    }), allNeedTemplates = _e.data, refetch = _e.refetch;
    useEffect(function () {
        refetchTemplateCountStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allNeedTemplates]);
    useEffect(function () {
        if (table) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table]);
    var removeNeedLog = useCallback(function (logId, removeShifts, clearError) {
        var _a, _b;
        var associatedNeed = (_a = allNeedTemplates === null || allNeedTemplates === void 0 ? void 0 : allNeedTemplates.find(function (t) { return t.appliedRange.find(function (r) { return r.id === logId; }); })) !== null && _a !== void 0 ? _a : null;
        var associatedRange = (_b = associatedNeed === null || associatedNeed === void 0 ? void 0 : associatedNeed.appliedRange.find(function (r) { return r.id === logId; })) !== null && _b !== void 0 ? _b : null;
        setCurrentNeedOrCoverageBeingApplied({
            item: associatedNeed,
            type: 'need',
            range: associatedRange,
        });
        var request = {
            logId: logId,
            removeShifts: removeShifts,
            clearError: clearError,
        };
        useRemoveNeedFromSchedule.mutate(request, { onSuccess: function () { return refetch(); } });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, allNeedTemplates]);
    var onCloseModal = useCallback(function () {
        setIsReapply(false);
        setSelectedTemplateToApply(undefined);
    }, []);
    var applyNeed = useCallback(function (template, publish, preventDuplicates, startDate, endDate) {
        setCurrentNeedOrCoverageBeingApplied({
            item: template,
            range: { id: '', startTime: startDate, endTime: endDate, inTransaction: true },
            type: 'need',
        });
        var request = {
            needId: template.id,
            startDate: startDate,
            endDate: endDate,
            timeZone: timeZone,
            publish: publish,
            allowOverlapTimeRange: isReapply,
        };
        useApplyNeedToSchedule.mutate(request, {
            onSuccess: function () {
                if (isReapply) {
                    // Handle refetching on reapply (on reapply a new log is created then deletes itself)
                    setTimeout(function () {
                        refetchCollection(collectionId);
                    }, 1000);
                }
                else {
                    refetch();
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetch, timeZone, isReapply]);
    var refreshNeedTemplate = useCallback(function (needId, refreshFrom) {
        var request = {
            id: needId,
            refreshFrom: refreshFrom.tz(timeZone).startOf('day').toISOString(),
        };
        useRefreshNeedTemplate.mutate(request, {
            onSuccess: function () {
                refetch();
            },
        });
    }, [refetch, timeZone, useRefreshNeedTemplate]);
    var reapplyNeed = useCallback(function (template) {
        setIsReapply(true);
        setSelectedTemplateToApply(template);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var ifNeedsOrCoverageInTransactionState = useRecoilValue(needsOrCoverageInTransactionState);
    return (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "mr-[8px] font-medium text-gray-600", children: "Needs" }), allNeedTemplates && (_jsx(NeedsCoverageTags, { blockId: blockId, type: "needs", tags: allNeedTemplates, onRemoveTag: removeNeedLog, onRefreshTag: refreshNeedTemplate, onReapplyTag: reapplyNeed, canEdit: hasNeedsEditPermission })), !ifNeedsOrCoverageInTransactionState && hasNeedsEditPermission && (_jsx(NeedsCoverageTemplateSelector, { blockId: blockId, type: "need", appliedNeedCoverageTemplates: allNeedTemplates !== null && allNeedTemplates !== void 0 ? allNeedTemplates : [], onSelectTemplateToApply: function (template) {
                    setSelectedTemplateToApply(template);
                    setIsReapply(false);
                } })), selectedTemplateToApply && hasNeedsEditPermission && (_jsx(NeedsCoverageTimeRangeSelectModal, { blockId: blockId, isModalVisible: !!selectedTemplateToApply, onCloseModal: onCloseModal, template: selectedTemplateToApply, onSelectTemplate: applyNeed, isReapply: isReapply }))] }));
};
export default React.memo(ScheduleNeedsSummaryInfo);
