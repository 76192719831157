var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Input, Switch } from 'antd';
import { useCallback, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionIdToBlockIdsState, collectionTableCellDataBulkSelector, collectionTableSchemasState, tableCollectionRowsState, } from 'src/state';
import { CollectionTableType } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import { GenerativeConfigurationContext } from '../GeneratedIdConfigurationModal';
import { GeneratedIdType } from '../interface/interface';
import { isEmptyCellData } from '../../Cell/util';
import GeneratedIdVariableSelector from './GeneratedIdVariableSelector';
import { getDisplayValue, insertAlphaNumericPlaceholders } from './util';
var GeneratedIdConfigurationSection = function (_a) {
    var recordsCount = _a.recordsCount, setOkDisabled = _a.setOkDisabled;
    var _b = useContext(GenerativeConfigurationContext), generativeConfiguration = _b.generativeConfiguration, setGeneratedIdConfiguration = _b.setGeneratedIdConfiguration;
    var collectionSchemas = useRecoilValue(collectionTableSchemasState(generativeConfiguration.collectionId));
    var collectionBlocks = useRecoilValue(collectionIdToBlockIdsState(generativeConfiguration.collectionId));
    var collectionRows = useRecoilValue(tableCollectionRowsState(collectionBlocks[0]));
    var cells = useMemo(function () {
        if (!collectionRows.length)
            return [];
        var recordId = collectionRows[0].recordId;
        return collectionSchemas.map(function (schema) {
            return {
                collectionId: generativeConfiguration.collectionId,
                schemaId: schema.id,
                recordId: recordId,
            };
        });
    }, [collectionRows, collectionSchemas, generativeConfiguration]);
    var collectionCells = useRecoilValue(collectionTableCellDataBulkSelector({
        dataSource: {
            type: CollectionTableType.TABLE,
            collectionId: generativeConfiguration.collectionId,
        },
        cells: cells,
    }));
    var onChangeFormat = function (format) {
        setGeneratedIdConfiguration(__assign(__assign({}, generativeConfiguration), { format: format }));
    };
    var onVariableSelect = function (schemaId) {
        var value = collectionSchemas.find(function (schema) { return schema.id === schemaId; }).name;
        var variable = value.replace(' ', '_');
        var format = generativeConfiguration.format;
        format += "{{".concat(variable, "}}");
        setGeneratedIdConfiguration(__assign(__assign({}, generativeConfiguration), { format: format }));
    };
    var setRequireUnique = function (requireUnique) {
        setGeneratedIdConfiguration(__assign(__assign({}, generativeConfiguration), { requireUnique: requireUnique }));
    };
    var formatHint = useMemo(function () {
        switch (generativeConfiguration.generatedIdType) {
            case GeneratedIdType.INCREMENTAL:
                return 'Use # to indicate a number';
            case GeneratedIdType.RANDOM:
                return 'Use # to indicate a number, and * to indicate a letter';
            default:
                return '';
        }
    }, [generativeConfiguration]);
    var timeFormatFn = useTimeZone().timeFormatFn;
    var idPreview = useMemo(function () {
        var format = generativeConfiguration.format;
        var allVariables = Array.from(format.matchAll(/{{.*?}}/g), function (m) { return m[0]; });
        var variableToSchemaIndex = {};
        collectionSchemas.forEach(function (schema, i) {
            var variable = "{{".concat(schema.name.replace(' ', '_'), "}}");
            variableToSchemaIndex[variable] = i;
        });
        allVariables === null || allVariables === void 0 ? void 0 : allVariables.forEach(function (variable) {
            var index = variableToSchemaIndex[variable];
            var cellData = collectionCells[index];
            var replaceString = cellData && !isEmptyCellData(cellData)
                ? getDisplayValue(cellData, timeFormatFn)
                : variable;
            format = format.replace(variable, replaceString);
        });
        format = insertAlphaNumericPlaceholders(format, generativeConfiguration.generatedIdType);
        return format;
    }, [generativeConfiguration, collectionSchemas, collectionCells, timeFormatFn]);
    var requiredPlaceholderLength = useMemo(function () { return recordsCount.toString().length + 2; }, [recordsCount]);
    var hasEnoughDigits = useCallback(function (format) {
        return Array.from(format).filter(function (c) { return c === '#'; }).length >= requiredPlaceholderLength;
    }, [requiredPlaceholderLength]);
    var hasEnoughDigitsAndLetters = useCallback(function (format) {
        return (Array.from(format).filter(function (c) { return c === '#' || c === '*'; }).length >=
            requiredPlaceholderLength);
    }, [requiredPlaceholderLength]);
    var formatRules = useMemo(function () { return [
        {
            validator: function (_, value) {
                if (!value || value.trim().length === 0) {
                    setOkDisabled === null || setOkDisabled === void 0 ? void 0 : setOkDisabled(true);
                    return Promise.reject(new Error('Format cannot be empty'));
                }
                if (generativeConfiguration.generatedIdType == GeneratedIdType.INCREMENTAL &&
                    !hasEnoughDigits(value)) {
                    setOkDisabled === null || setOkDisabled === void 0 ? void 0 : setOkDisabled(true);
                    return Promise.reject(new Error("Format should have enough digits for your table, which has ".concat(recordsCount, " rows. Please include at least ").concat(requiredPlaceholderLength, " digits to prevent eventual overflow.")));
                }
                if (generativeConfiguration.generatedIdType == GeneratedIdType.RANDOM &&
                    !hasEnoughDigitsAndLetters(value)) {
                    setOkDisabled === null || setOkDisabled === void 0 ? void 0 : setOkDisabled(true);
                    return Promise.reject(new Error("Format should have enough number and letter characters to avoid repeats. Please include at least ".concat(requiredPlaceholderLength, " to prevent collisions.")));
                }
                if (generativeConfiguration.generatedIdType == GeneratedIdType.PLAINTEXT &&
                    value.length == 0) {
                    setOkDisabled === null || setOkDisabled === void 0 ? void 0 : setOkDisabled(true);
                    return Promise.reject(new Error('Format cannot be empty'));
                }
                setOkDisabled === null || setOkDisabled === void 0 ? void 0 : setOkDisabled(false);
                return Promise.resolve();
            },
        },
    ]; }, [
        generativeConfiguration,
        requiredPlaceholderLength,
        hasEnoughDigits,
        hasEnoughDigitsAndLetters,
        recordsCount,
        setOkDisabled,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: _jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Format" }), required: false, validateTrigger: false, rules: formatRules, name: "format", className: "mb-2", children: _jsx(Input, { className: "bg-gray-50 hover:bg-gray-50", bordered: false, onChange: function (e) { return onChangeFormat(e.target.value); }, suffix: _jsx(GeneratedIdVariableSelector, { collectionSchemas: collectionSchemas, placeholder: "{}", onSelect: onVariableSelect }), placeholder: "{{Location}}-{{created_at}}-###**######" }) }), _jsx("div", { className: "flex flex-col mb-3", children: _jsx("div", { className: "font-medium text-gray-400 text-xs ml-auto", children: formatHint }) }), _jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Configuration" }), _jsxs("div", { className: "flex flex-row justify-between", children: [_jsx("div", { children: "Require Uniqueness" }), _jsx(Form.Item, { required: false, name: "requireUnique", valuePropName: "checked", getValueFromEvent: function (checked) { return checked; }, className: "-mt-1", children: _jsx(Switch, { onChange: setRequireUnique }) })] }), _jsx("div", { className: "font-medium text-gray-400 text-xs", children: "Preview" }), _jsx("div", { className: "flex items-center bg-gray-50 rounded-lg h-[38px] px-3", children: idPreview })] }));
};
export default GeneratedIdConfigurationSection;
