import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import React, { useCallback } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';
import { automationModalPropsState, isSingleAutomationModalVisibleState, } from 'src/state/atoms/automationModalState';
import { automationTags } from '../../../utils/Automation';
import './AutomationCard.scss';
var AutomationCard = function (_a) {
    var automation = _a.automation;
    var setAutomationProps = useSetRecoilState(automationModalPropsState);
    var setIsAutomationModalVisible = useSetRecoilState(isSingleAutomationModalVisibleState);
    // const { useGetDefaultAutomation, useGetRecipeCustomAutomation } = useAutomation()
    // useEffect(() => {
    //     if ('recipeAutomationId' in automation) {
    //         if (automation.recipeAutomationDetail.type === 'DEFAULT') {
    //             useGetDefaultAutomation.mutate(automation.recipeAutomationId, {
    //                 onSuccess: (automationObj) => {
    //                     if (!automationObj) return
    //                     processAutomationObject(automationObj)
    //                 },
    //             })
    //         } else {
    //             const recipeId = new URLSearchParams(location.search).get('recipe')
    //             const recipeDetails = {
    //                 recipeTemplateId: recipeId,
    //                 dataChanges: recipeDataChanges,
    //             }
    //             useGetRecipeCustomAutomation.mutate(
    //                 {
    //                     recipeTemplateId: recipeId,
    //                     recipeAutomationId: automationId,
    //                     dataChanges: recipeDataChanges,
    //                 },
    //                 {
    //                     onSuccess: (automationObj) => {
    //                         if (!automationObj) return
    //                         processAutomationObject(automationObj)
    //                     },
    //                 }
    //             )
    //         }
    //     }
    // }, [automation])
    var openAutomationModal = useCallback(function (isActive, automation) {
        var _a;
        var id = automation.id, name = automation.name;
        var defaultAutomationId = 'recipeAutomationDetail' in automation &&
            automation.recipeAutomationDetail.type === 'DEFAULT'
            ? automation.recipeAutomationDetail.defaultAutomationId
            : undefined;
        var recipeAutomationId = 'recipeAutomationId' in automation &&
            automation.recipeAutomationDetail.type === 'CUSTOM'
            ? automation.recipeAutomationId
            : undefined;
        setAutomationProps({
            isActive: 'isActive' in automation ? automation.isActive : isActive,
            automationId: (_a = id !== null && id !== void 0 ? id : defaultAutomationId) !== null && _a !== void 0 ? _a : recipeAutomationId,
            name: name,
            isDefault: defaultAutomationId || recipeAutomationId ? true : false,
            isRecipeCustom: recipeAutomationId ? true : false,
        });
        setIsAutomationModalVisible(true);
    }, [setAutomationProps, setIsAutomationModalVisible]);
    return (_jsx(Button, { className: "AutomationCard", onClick: function () { return openAutomationModal(true, automation); }, children: _jsxs("span", { className: "AutomationCardContent", children: [_jsx("div", { className: "relative flex w-full items-center", children: _jsxs("span", { className: "AutomationTags justify-start", children: [automationTags(automation.triggerCategories), !!automation.actionCategories.length && (_jsxs(_Fragment, { children: [_jsx(ArrowRightOutlined, {}), automationTags(automation.actionCategories)] }))] }) }), _jsx("span", { className: "AutomationName", children: automation.name }), _jsx("span", { className: "AutomationView", children: 'isActive' in automation ? (automation.isActive ? 'View' : 'Turn On') : 'View' })] }) }, automation.id));
};
export default React.memo(AutomationCard);
