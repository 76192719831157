import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Input } from 'antd';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import './JobCodesPage.scss';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
var JobCodeEntry = function (props) {
    var project = props.project, deleteProject = props.deleteProject, updateName = props.updateName;
    var _a = useState(project.name), projectName = _a[0], setProjectName = _a[1];
    return (_jsxs("div", { className: "ProjectHolder", children: [_jsx(Input, { className: "ProjectNameInput", placeholder: "Enter a job code", value: projectName, onChange: function (e) { return setProjectName(e.target.value); }, onBlur: function (e) {
                    if (e.target.value === project.name)
                        return;
                    // only call api when name actually changed
                    updateName(project.id, e.target.value);
                } }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this job code?", onConfirm: deleteProject, placement: "left", children: _jsx("div", { className: "GroupRuleDeleteBtn", children: _jsx(TrashOutlinedIcon, {}) }) })] }, project.id));
};
export default React.memo(JobCodeEntry);
