import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { OPTIONS_COLOR } from 'src/shared';
import { disableScroll } from 'src/utils/Collection';
import { colorPickerItem } from '../OptimizedTableView/Cell/SelectionCell/LogicSelectionCell';
import ZiraDropdown from '../../ui/ZiraDropdown';
import ZiraTooltip from '../../ui/ZiraTooltip';
import './RecordColorSelectorDropdown.scss';
var RecordColorSelectorDropdown = function (_a) {
    var _b = _a.currentColor, currentColor = _b === void 0 ? '' : _b, visible = _a.visible, onVisibleChange = _a.onVisibleChange, onUpdateRecordColor = _a.onUpdateRecordColor, children = _a.children;
    useEffect(function () {
        disableScroll(visible);
        return function () { return disableScroll(false); };
    }, [visible]);
    var handleMenuClick = useCallback(function (info) {
        info.domEvent.stopPropagation();
        var newColor = info.key;
        if (newColor !== currentColor) {
            onUpdateRecordColor === null || onUpdateRecordColor === void 0 ? void 0 : onUpdateRecordColor(newColor);
            onVisibleChange(false);
        }
    }, [currentColor, onUpdateRecordColor, onVisibleChange]);
    var colorOptions = useMemo(function () {
        return OPTIONS_COLOR.map(function (_color, index) {
            return {
                key: OPTIONS_COLOR[index],
                label: colorPickerItem(index, currentColor),
            };
        });
    }, [currentColor]);
    return (_jsx(ZiraDropdown, { menu: { items: colorOptions, onClick: handleMenuClick }, dropdownRender: function (menu) { return (_jsxs("div", { className: "RecordColorSelectorDropdownContainer", children: [_jsx("span", { className: "p-[16px] text-xs", onClick: function (e) { return e.stopPropagation(); }, children: "Select a color." }), React.cloneElement(menu)] })); }, overlayClassName: "selector-dropdown-overlay selector-menu", trigger: ['click'], open: visible, onOpenChange: onVisibleChange, children: children !== null && children !== void 0 ? children : (_jsx(ZiraTooltip, { title: "Change Record Color", placement: "right", children: _jsx("div", { className: "h-[21px] w-[21px] cursor-pointer rounded-sm", style: {
                    backgroundColor: currentColor || 'white',
                    border: !currentColor ? '1px solid black' : undefined,
                } }) })) }));
};
export default React.memo(RecordColorSelectorDropdown);
