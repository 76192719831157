import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { CheckOutlined, ExclamationCircleOutlined, InfoCircleOutlined, LoadingOutlined, QuestionCircleOutlined, } from '@ant-design/icons';
import { AutoApplyTemplateIcon } from 'src/utils/icons/SchedulingIcons/AutoApplyTemplateIcon';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tableCollectionIdSelector, blockTimeZoneState, scheduleTimeRangeState } from 'src/state';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { Checkbox, DatePicker, Dropdown, Tooltip } from 'antd';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useScheduleNeedsCoverageComponent, useTimeZone } from 'src/hooks/component';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import moment from 'moment';
import ZiraWarningPopconfirm from 'src/ui/ZiraWarningPopconfirm';
import { NeedsCoverageType, TemplateStatusType, } from '../../interface';
import { needsOrCoverageInTransactionState } from '../Atoms/Atoms';
import { needsCoverageConfigModalActiveTabState, isNeedsCoverageConfigModalVisibleState, } from '../../Atom';
import { ASSOCIATE_SHIFTS_HINTS } from '../HintsText';
var NeedsCoverageTags = function (_a) {
    var blockId = _a.blockId, type = _a.type, tags = _a.tags, onRemoveTag = _a.onRemoveTag, onRefreshTag = _a.onRefreshTag, onReapplyTag = _a.onReapplyTag, canEdit = _a.canEdit;
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    //!!MARK: init with undefined to indicate that this is a first time render
    var _b = useState(), shouldFetchStatus = _b[0], setShouldFetchStatus = _b[1];
    var useGetLogStatusByWeek = useNeedCoverageApi().useGetLogStatusByWeek;
    var appliedRangesIds = tags.map(function (tag) { return tag.appliedRange.map(function (range) { return range.id; }); }).flat();
    var logStatus = useGetLogStatusByWeek(type, {
        body: appliedRangesIds,
        options: {
            enabled: !!appliedRangesIds.length && shouldFetchStatus !== false,
            refetchInterval: 2000,
        },
    }).data;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var refetchCollection = useRefetchCollection();
    var setNeedsOrCoverageInTransactionState = useSetRecoilState(needsOrCoverageInTransactionState);
    useEffect(function () {
        var isAnyLogInTransaction = logStatus === null || logStatus === void 0 ? void 0 : logStatus.some(function (status) { return status.inTransaction; });
        if (!isAnyLogInTransaction && shouldFetchStatus !== undefined) {
            setTimeout(function () {
                refetchCollection(collectionId);
            }, 1000);
        }
        setShouldFetchStatus(isAnyLogInTransaction);
        setNeedsOrCoverageInTransactionState(isAnyLogInTransaction !== null && isAnyLogInTransaction !== void 0 ? isAnyLogInTransaction : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionId, logStatus, shouldFetchStatus]);
    var getIfLogInTransaction = function (logId) { var _a; return (_a = logStatus === null || logStatus === void 0 ? void 0 : logStatus.find(function (status) { return status.logId === logId; })) === null || _a === void 0 ? void 0 : _a.inTransaction; };
    var getIfLogHasError = function (logId) { var _a; return (_a = logStatus === null || logStatus === void 0 ? void 0 : logStatus.find(function (status) { return status.logId === logId; })) === null || _a === void 0 ? void 0 : _a.hasError; };
    var getIfLogLastAppliedBeforeRangeStart = function (logId) {
        var _a;
        return (((_a = logStatus === null || logStatus === void 0 ? void 0 : logStatus.find(function (status) { return status.logId === logId; })) === null || _a === void 0 ? void 0 : _a.lastApplied) || timeRange[0]) <
            timeRange[0];
    };
    var blockTimeZone = useRecoilValue(blockTimeZoneState(blockId));
    var timeFormatFn = useTimeZone(blockTimeZone).timeFormatFn;
    // Menu state
    var _c = useState(undefined), rangeWithDropdownOpen = _c[0], setRangeWithDropdownOpen = _c[1];
    var _d = useState(false), isDeletePopupOpen = _d[0], setIsDeletePopupOpen = _d[1];
    var _e = useState(true), ifRemoveAssociatedShifts = _e[0], setIfRemoveAssociatedShifts = _e[1];
    var onOpenExistingTemplateDetailModal = useScheduleNeedsCoverageComponent().onOpenExistingTemplateDetailModal;
    var setActiveTab = useSetRecoilState(needsCoverageConfigModalActiveTabState);
    var setIsNeedsConverageConfigModalVisible = useSetRecoilState(isNeedsCoverageConfigModalVisibleState);
    var onOpenExistingNeedCoverageTemplateDetailModal = useCallback(function (templateId) {
        onOpenExistingTemplateDetailModal(templateId, !canEdit);
        setActiveTab(type === 'needs' ? NeedsCoverageType.NEED : NeedsCoverageType.COVERAGE);
        setIsNeedsConverageConfigModalVisible(true);
    }, [
        onOpenExistingTemplateDetailModal,
        setActiveTab,
        setIsNeedsConverageConfigModalVisible,
        type,
        canEdit,
    ]);
    var _f = useState(moment()), refreshFrom = _f[0], setRefreshFrom = _f[1];
    var needsCoverageDropdownItems = function (template, range, canEdit) { return [
        template.status.status !== TemplateStatusType.ARCHIVED
            ? {
                key: 'view',
                label: (_jsx("div", { onClick: function () { return onOpenExistingNeedCoverageTemplateDetailModal(template.id); }, children: "View" })),
            }
            : null,
        !getIfLogInTransaction(range.id) && canEdit
            ? {
                key: 'reapply',
                label: (_jsx("div", { onClick: function () {
                        onReapplyTag === null || onReapplyTag === void 0 ? void 0 : onReapplyTag(template);
                    }, children: "Reapply" })),
            }
            : null,
        !getIfLogInTransaction(range.id) && canEdit
            ? {
                key: 'refresh',
                label: (_jsx(ZiraWarningPopconfirm, { overlayInnerStyle: { width: '300px' }, title: _jsxs("div", { className: "flex flex-col space-y-[16px]", children: [_jsxs("span", { children: ["Pick a date to refresh the schedule with this", ' ', type === 'needs' ? 'need' : 'coverage', "'s", " updates."] }), _jsx(DatePicker, { popupClassName: "z-[1060]", onChange: function (e) {
                                    if (e) {
                                        setRefreshFrom(e);
                                    }
                                }, defaultValue: moment(), disabledDate: function (current) {
                                    return current && current <= moment().startOf('day');
                                } })] }), onConfirm: function () {
                        onRefreshTag === null || onRefreshTag === void 0 ? void 0 : onRefreshTag(template.id, refreshFrom);
                    }, placement: "bottomLeft", okText: "Refresh", onOpenChange: function () {
                        setRefreshFrom(moment());
                    }, children: _jsx("div", { children: "Refresh" }) })),
            }
            : null,
        !getIfLogInTransaction(range.id) && !template.autoApply
            ? {
                key: 'delete',
                label: (_jsx(ZiraDeletePopconfirm, { overlayInnerStyle: { width: '400px' }, title: _jsxs("div", { className: "flex flex-col space-y-[16px] font-medium", children: [_jsxs("span", { children: ["Are you sure you want to remove this", ' ', type === 'needs' ? 'need' : 'coverage', "?", _jsx("br", {}), "If you do NOT want to remove associated shifts, please uncheck the box below."] }), _jsxs("div", { className: "font-semibold flex items-center space-x-2", children: [_jsx(Checkbox, { defaultChecked: true, value: ifRemoveAssociatedShifts, onChange: function (e) {
                                            return setIfRemoveAssociatedShifts(e.target.checked);
                                        } }), ' ', _jsxs("span", { children: ["Remove associated shifts", ' ', "(".concat(timeFormatFn(moment(range.startTime) <= moment()
                                                ? moment().toISOString()
                                                : range.startTime, 'MMM D', false), " -\n                                          ").concat(timeFormatFn(range.endTime, 'MMM D', false), ")")] }), _jsx(Tooltip, { title: _jsx("div", { className: "text-[13px] font-medium", children: ASSOCIATE_SHIFTS_HINTS }), color: "black", placement: "bottom", overlayClassName: "HelpArticleUrlTooltip", children: _jsx(QuestionCircleOutlined, {}) })] })] }), onConfirm: function () {
                        var _a;
                        onRemoveTag === null || onRemoveTag === void 0 ? void 0 : onRemoveTag(range.id, ifRemoveAssociatedShifts, (_a = getIfLogHasError(range.id)) !== null && _a !== void 0 ? _a : false);
                    }, placement: "bottomLeft", okText: "Remove", onOpenChange: function (open) {
                        setIsDeletePopupOpen(open);
                        if (open) {
                            setIfRemoveAssociatedShifts(true);
                        }
                    }, children: _jsx("div", { children: "Delete" }) })),
            }
            : null,
    ]; };
    return (_jsx("div", { className: "items center flex", children: tags.map(function (tag) {
            var id = tag.id, name = tag.name, autoApply = tag.autoApply, appliedRange = tag.appliedRange;
            return appliedRange.map(function (range, idx) {
                return (_jsx(ZiraTooltip, { title: "".concat(name, ": ").concat(timeFormatFn(range.startTime, 'MMM D', false), " - ").concat(timeFormatFn(range.endTime, 'MMM D', false)), placement: "bottom", children: _jsx(Dropdown, { menu: { items: needsCoverageDropdownItems(tag, range, canEdit) }, onOpenChange: function (open) {
                            if (open) {
                                setRangeWithDropdownOpen(range.id);
                            }
                            else if (!isDeletePopupOpen) {
                                setRangeWithDropdownOpen(undefined);
                            }
                        }, open: rangeWithDropdownOpen === range.id, placement: "top", children: _jsxs("div", { className: "mr-1 flex cursor-pointer items-center whitespace-nowrap rounded border border-gray-30 bg-white px-2 py-[6px] hover:bg-gray-50", children: [autoApply && !range.endTime && (_jsx(AutoApplyTemplateIcon, { className: "mr-1" })), _jsx("div", { className: "mr-2 text-xs font-medium", children: name }), getIfLogInTransaction(range.id) ? (_jsx(LoadingOutlined, { className: "mr-1 text-accent" })) : (_jsxs(_Fragment, { children: [!getIfLogHasError(range.id) &&
                                            autoApply &&
                                            getIfLogLastAppliedBeforeRangeStart(range.id) && (_jsx(Tooltip, { title: "This is an auto apply setting, we'll schedule tasks to create shifts for you by 6 weeks ahead.", children: _jsx(InfoCircleOutlined, { className: "mr-1" }) })), !getIfLogHasError(range.id) &&
                                            !getIfLogLastAppliedBeforeRangeStart(range.id) && (_jsx(Tooltip, { title: "This template was successfully applied to the schedule.", children: _jsx(CheckOutlined, { className: "text-green-500 mr-1" }) })), getIfLogHasError(range.id) && (_jsx(Tooltip, { title: "Failed to apply this setting, please remove it from schedule.", children: _jsx(ExclamationCircleOutlined, { className: "mr-1 text-red-500" }) }))] }))] }) }) }, "".concat(id, "-").concat(idx)));
            });
        }) }));
};
export default React.memo(NeedsCoverageTags);
