import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EyeOutlined } from '@ant-design/icons';
import { Space, Divider } from 'antd';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useRecordDetail } from 'src/hooks/component';
import { currentUserRecordIdSelector } from 'src/state/selectors/withAccountDetailState';
import { getMessageTimeStamp } from '../../utils';
import MessageAttachmentItem from './MessageAttachmentItem';
import MessageItemUserAvatar from './MessageItemUserAvatar';
import ChatUserListModal from './ChatUserListModal';
var BroadcastMessageItem = function (_a) {
    var _b;
    var message = _a.message;
    var currentUserRId = useRecoilValue(currentUserRecordIdSelector);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    // Filter out current user/sender
    var messageViewedUserList = useMemo(function () { var _a; return (_a = message.readBy) === null || _a === void 0 ? void 0 : _a.filter(function (user) { return user.id !== currentUserRId; }); }, [currentUserRId, message.readBy]);
    return (_jsxs("div", { className: "BroadcastMessageItem shadow-block", children: [_jsxs(Space, { className: "BroadcastMessageItemHeader", children: [_jsx(MessageItemUserAvatar, { message: message }), _jsxs("div", { className: "BroadcastMessageItemHeaderLabel", children: [_jsxs(Space, { className: "BroadcastMessageItemHeaderLabelSender", children: [_jsx(ZiraTooltip, { title: "View Details", children: _jsx("span", { className: "cursor-pointer hover:underline", onClick: function () { return openRecordDetail(message.senderDetail.id); }, children: message.senderDetail.name }) }), message.departmentSenderDetail && (_jsx("div", { className: "ChatDepartmentTag", children: (_b = message.departmentSenderDetail) === null || _b === void 0 ? void 0 : _b.name }))] }), _jsx("div", { className: "BroadcastMessageItemTime", children: getMessageTimeStamp(message.createdAt) })] })] }), _jsx(Divider, { className: "Divider" }), _jsx("div", { className: "BroadcastMessageItemContent", children: message.body.text }), message.body.attachments.map(function (attachment, index) {
                return _jsx(MessageAttachmentItem, { attachment: attachment }, "attachment-".concat(index));
            }), _jsx(Divider, { className: "Divider" }), _jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "BroadcastMessageItemDeliverySummary", children: "Delivered" }), messageViewedUserList && messageViewedUserList.length > 0 && (_jsx("div", { className: "ml-auto", children: _jsx(ChatUserListModal, { defaultUserList: messageViewedUserList, showReadStatus: true, children: _jsx(ZiraTooltip, { title: "Open Viewed List", placement: "bottomRight", children: _jsxs("div", { className: "BroadcastMessageItemDeliverySummary cursor-pointer", children: [_jsx(EyeOutlined, { className: "pb-[2px] pr-[4px]" }), _jsxs("span", { className: "underline", children: [messageViewedUserList.length, " View", messageViewedUserList.length > 1 ? 's' : ''] })] }) }) }) }))] })] }));
};
export default BroadcastMessageItem;
