import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Tabs } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blockTabsSelector, currentPageBlockTab } from 'src/state';
import { disableScroll } from 'src/utils/Collection';
var TabPane = Tabs.TabPane;
var BlockTabs = function (_a) {
    var pageId = _a.pageId, collapsed = _a.collapsed;
    var blockTabs = useRecoilValue(blockTabsSelector(pageId));
    var _b = useRecoilState(currentPageBlockTab), activeTabKey = _b[0], setActiveTabKey = _b[1];
    var tabsContainerMaxWidth = useMemo(function () {
        if (window.innerWidth < 576)
            return 0;
        return "calc(100vw - ".concat(collapsed ? 0 : 260, "px - 250px)");
    }, [collapsed]);
    var tabOnChange = useCallback(function (activeKey) {
        setActiveTabKey(activeKey);
        var selectEle = document.getElementById(activeKey);
        var scrollEle = document.getElementById('CenterPanelContent');
        var pageInfoEle = document.getElementsByClassName('PageInfo')[0];
        if (!selectEle || !scrollEle || !pageInfoEle)
            return;
        disableScroll(false);
        scrollEle === null || scrollEle === void 0 ? void 0 : scrollEle.scrollTo({
            top: selectEle.offsetTop + pageInfoEle.clientHeight,
            behavior: 'smooth',
        });
    }, [setActiveTabKey]);
    if (blockTabs.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Tabs, { className: "BlockTabs", style: { maxWidth: tabsContainerMaxWidth }, activeKey: activeTabKey, onChange: tabOnChange, type: "card", children: blockTabs.map(function (block) { return (_jsx(TabPane, { tab: block.name }, block.id)); }) }));
};
export default React.memo(BlockTabs);
