import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Breadcrumb, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountNameState } from 'src/state/selectors/withAccountDetailState';
import { getPrevPageRoute, setPrevPageRoute } from '../../utils/Account';
var SettingsContentHeader = function (props) {
    var children = props.children;
    var navigate = useNavigate();
    var accountName = useRecoilValue(accountNameState);
    var onRouteBack = useCallback(function () {
        var prevPageRoute = getPrevPageRoute();
        if (prevPageRoute) {
            navigate(prevPageRoute);
            setPrevPageRoute('');
        }
        else {
            navigate('/home');
        }
    }, [navigate]);
    return (_jsxs("div", { className: "SettingContentHeader", children: [_jsxs(Breadcrumb, { children: [_jsx(Breadcrumb.Item, { children: accountName }), children] }), _jsx("div", { children: _jsx(Button, { className: "CloseSettingBtn", onClick: onRouteBack, children: "Close Settings" }) })] }));
};
export default React.memo(SettingsContentHeader);
