var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { isEqual, uniqWith } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useChatComponent } from 'src/hooks/component/useChat';
import { participantsInNewThread, recipientsDropdownOpenState, selectedThreadState, threadSectionExpandedStatusState, threadSummaryListState, } from 'src/state/atoms/chatState';
import { isCreatingThreadSelector } from 'src/state/selectors/withChatState';
import { ThreadType } from '../interface';
import { threadIsBroadcast, threadParticipantsToBroadcastRecipients } from '../utils';
import ChatItem from './ChatItem';
import ThreadSectionExpandActionButton from './ThreadSectionExpandActionButton';
var ChatList = function (_a) {
    var onChatSiderCollapsed = _a.onChatSiderCollapsed;
    var threadSummaryList = useRecoilValue(threadSummaryListState);
    var setSelectedThread = useSetRecoilState(selectedThreadState);
    var setCreateThreadSelector = useSetRecoilState(isCreatingThreadSelector);
    var setParticipantsInThread = useSetRecoilState(participantsInNewThread);
    var setRecipientsDropdownOpen = useSetRecoilState(recipientsDropdownOpenState);
    var sortedThread = useChatComponent().sortedThread;
    var broadcasts = useMemo(function () {
        return sortedThread(uniqWith(threadSummaryList.filter(function (thread) { return threadIsBroadcast(thread); }), function (a, b) {
            return isEqual(threadParticipantsToBroadcastRecipients(a.participants), threadParticipantsToBroadcastRecipients(b.participants));
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadSummaryList]);
    var directMessages = useMemo(function () {
        return sortedThread(threadSummaryList.filter(function (thread) { return !threadIsBroadcast(thread); }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadSummaryList]);
    var resetThread = useCallback(function (threadType) {
        setSelectedThread(undefined);
        setParticipantsInThread(function (prev) { return (__assign(__assign({}, prev), { type: threadType, participants: [] })); });
        setCreateThreadSelector(threadType);
        setRecipientsDropdownOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var isBroadcastSectionExpanded = useRecoilValue(threadSectionExpandedStatusState(ThreadType.BROADCAST));
    var isDMSectionExpanded = useRecoilValue(threadSectionExpandedStatusState(ThreadType.DM));
    return (_jsxs("div", { className: "ChatSidebarScrollbar", children: [_jsxs("div", { className: "ChatSidebarGroupName", children: [_jsxs("div", { className: "flex items-center", children: [!!broadcasts.length && (_jsx(ThreadSectionExpandActionButton, { type: ThreadType.BROADCAST })), _jsx("div", { className: "mt-[2px]".concat(broadcasts.length ? '' : ' pl-[24px]'), children: "Broadcasts" })] }), _jsx(ZiraTooltip, { title: "New Broadcast", children: _jsx(PlusOutlined, { className: "rounded-lg p-[4px] hover:bg-gray-60", onClick: function () {
                                resetThread(ThreadType.BROADCAST);
                            } }) })] }), isBroadcastSectionExpanded &&
                broadcasts.map(function (item) { return (_jsx(ChatItem, { threadId: item.id, participants: item.participants, onChatSiderCollapsed: onChatSiderCollapsed }, item.id)); }), _jsxs("div", { className: "ChatSidebarGroupName !border-t-0", children: [_jsxs("div", { className: "flex items-center", children: [!!directMessages.length && (_jsx(ThreadSectionExpandActionButton, { type: ThreadType.DM })), _jsx("div", { className: "mt-[2px]".concat(directMessages.length ? '' : ' pl-[24px]'), children: "Direct Messages" })] }), _jsx(ZiraTooltip, { title: "New Direct Message", children: _jsx(PlusOutlined, { className: "rounded-lg p-[4px] hover:bg-gray-60", onClick: function () {
                                resetThread(ThreadType.DM);
                            } }) })] }), isDMSectionExpanded &&
                (directMessages === null || directMessages === void 0 ? void 0 : directMessages.map(function (item) { return (_jsx(ChatItem, { threadId: item.id, participants: item.participants, onChatSiderCollapsed: onChatSiderCollapsed }, item.id)); }))] }));
};
export default ChatList;
