var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Input } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BlackPlusIcon } from 'src/utils/icons/BlackPlusIcon';
import { v4 as uuidv4 } from 'uuid';
import { OPTIONS_COLOR } from 'src/shared';
import { useSchemaComponent } from 'src/hooks/component';
import { usePageApi } from 'src/hooks/api';
import SelectOptionItem from './SelectOptionItem';
var SelectOptionsSection = function (_a) {
    var collectionId = _a.collectionId, selectOptions = _a.selectOptions, setSelectOptions = _a.setSelectOptions, schema = _a.schema, schemaTypeChanged = _a.schemaTypeChanged, addToSelectSchemaEditQueue = _a.addToSelectSchemaEditQueue;
    var _b = useState(''), newValue = _b[0], setNewValue = _b[1];
    var reorderColumnOptions = useSchemaComponent().reorderColumnOptions;
    var existingSchema = useMemo(function () { return !!schema.id; }, [schema]);
    var handleDragEnd = useCallback(function (result) {
        var tempSelectOptions = __spreadArray([], selectOptions, true);
        var source = result.source, destination = result.destination;
        if (destination === undefined || destination === null) {
            return tempSelectOptions;
        }
        var sourceIndex = source.index;
        var destIndex = destination.index;
        if (sourceIndex === destIndex || destIndex === -1 || sourceIndex === -1) {
            return tempSelectOptions;
        }
        var deletedSelectOption = tempSelectOptions.splice(sourceIndex, 1)[0];
        tempSelectOptions.splice(destIndex, 0, deletedSelectOption);
        setSelectOptions(tempSelectOptions);
        if (existingSchema) {
            var requestFrom = {
                requestFrom: 'block',
                blockId: collectionId,
            };
            addToSelectSchemaEditQueue(reorderColumnOptions, requestFrom, schema, tempSelectOptions.map(function (option) { return option.id; }), collectionId);
        }
    }, [
        selectOptions,
        setSelectOptions,
        existingSchema,
        collectionId,
        addToSelectSchemaEditQueue,
        reorderColumnOptions,
        schema,
    ]);
    var generateRandomIndex = function () { return Math.floor(Math.random() * OPTIONS_COLOR.length); };
    var _c = usePageApi(), createSchemaOption = _c.createSchemaOption, updateSchemaOption = _c.updateSchemaOption, deleteSchemaOption = _c.deleteSchemaOption;
    var onAddValue = useCallback(function () {
        var randomColor = OPTIONS_COLOR[generateRandomIndex()];
        var newOption = {
            id: uuidv4(),
            name: newValue,
            color: randomColor,
        };
        var tempOptions = __spreadArray([], selectOptions, true);
        tempOptions.push(newOption);
        setSelectOptions(tempOptions);
        setNewValue('');
        if (existingSchema && !schemaTypeChanged) {
            addToSelectSchemaEditQueue(createSchemaOption, {
                collectionId: collectionId,
                schemaId: schema.id,
                proposedOptionId: newOption.id,
                newOptionData: {
                    text: newValue,
                    color: randomColor,
                    type: 'text',
                    inputMode: schema.inputMode,
                },
            });
        }
    }, [
        newValue,
        selectOptions,
        setSelectOptions,
        existingSchema,
        schemaTypeChanged,
        addToSelectSchemaEditQueue,
        createSchemaOption,
        collectionId,
        schema,
    ]);
    var onDeleteValue = useCallback(function (index) {
        var tempOptions = __spreadArray([], selectOptions, true);
        var deletedOption = tempOptions.splice(index, 1)[0];
        setSelectOptions(tempOptions);
        if (existingSchema) {
            addToSelectSchemaEditQueue(deleteSchemaOption, {
                valueId: deletedOption.id,
                collectionId: collectionId,
                viewId: collectionId,
            });
        }
    }, [
        addToSelectSchemaEditQueue,
        collectionId,
        deleteSchemaOption,
        existingSchema,
        selectOptions,
        setSelectOptions,
    ]);
    var onUpdateOptionText = useCallback(function (index, text) {
        var tempOptions = __spreadArray([], selectOptions, true);
        tempOptions[index].name = text;
        setSelectOptions(tempOptions);
        if (existingSchema) {
            addToSelectSchemaEditQueue(updateSchemaOption, tempOptions[index].id, {
                schemaId: schema.id,
                newOptionData: {
                    text: tempOptions[index].name,
                    color: tempOptions[index].color,
                    type: 'text',
                    inputMode: schema.inputMode,
                },
                requestFrom: 'block',
                blockId: collectionId,
            });
        }
    }, [
        addToSelectSchemaEditQueue,
        collectionId,
        existingSchema,
        schema,
        selectOptions,
        setSelectOptions,
        updateSchemaOption,
    ]);
    var onUpdateOptionColor = useCallback(function (index, color) {
        var tempOptions = __spreadArray([], selectOptions, true);
        tempOptions[index].color = color;
        setSelectOptions(tempOptions);
        if (existingSchema) {
            addToSelectSchemaEditQueue(updateSchemaOption, tempOptions[index].id, {
                schemaId: schema.id,
                newOptionData: {
                    text: tempOptions[index].name,
                    color: color,
                    type: 'text',
                    inputMode: schema.inputMode,
                },
                requestFrom: 'block',
                blockId: collectionId,
            });
        }
    }, [
        addToSelectSchemaEditQueue,
        collectionId,
        existingSchema,
        schema,
        selectOptions,
        setSelectOptions,
        updateSchemaOption,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-1 text-xs font-medium opacity-[0.45]", children: "Values" }), _jsx(DragDropContext, { onDragEnd: handleDragEnd, children: _jsx(Droppable, { droppableId: "select-options-modal", direction: "vertical", children: function (provided) { return (_jsxs("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, children: [selectOptions.map(function (option, index) { return (_jsx(Draggable, { draggableId: option.id, index: index, children: function (draggableProvided) {
                                    return (_jsx("div", { className: "flex flex-row justify-between", children: _jsx(SelectOptionItem, { option: option, onDeleteOption: function () { return onDeleteValue(index); }, onUpdateOptionColor: function (e) {
                                                return onUpdateOptionColor(index, e);
                                            }, onUpdateOptionText: function (e) {
                                                return onUpdateOptionText(index, e);
                                            }, draggableProvided: draggableProvided }) }));
                                } }, option.id)); }), provided.placeholder] }))); } }) }), _jsxs("div", { className: "flex flex-row justify-between mb-2", children: [_jsx(Input, { placeholder: "Add a value", value: newValue, onChange: function (event) { return setNewValue(event.target.value); }, onKeyDown: function (e) {
                            if (e.key === 'Enter' && newValue.length) {
                                onAddValue();
                            }
                        }, className: "mr-2" }), _jsx(Button, { icon: _jsx(BlackPlusIcon, {}), type: "primary", className: "AddTableFilterButton mr-auto", onClick: onAddValue, children: "Add Value" })] })] }));
};
export default SelectOptionsSection;
