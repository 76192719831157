var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PlusGreyOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M4.33325 10.7038C4.33325 10.5197 4.48249 10.3704 4.66659 10.3704H17.3333C17.5173 10.3704 17.6666 10.5197 17.6666 10.7038V11.3704C17.6666 11.5545 17.5173 11.7038 17.3333 11.7038H4.66658C4.48249 11.7038 4.33325 11.5545 4.33325 11.3704V10.7038Z", fill: "#A7A8AC" }), _jsx("path", { d: "M10.3333 4.70378C10.3333 4.51968 10.4825 4.37045 10.6666 4.37045H11.3333C11.5173 4.37045 11.6666 4.51968 11.6666 4.70378V17.3704C11.6666 17.5545 11.5173 17.7038 11.3333 17.7038H10.6666C10.4825 17.7038 10.3333 17.5545 10.3333 17.3704V4.70378Z", fill: "#A7A8AC" })] })); };
export var PlusGreyOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: PlusGreyOutlinedSvg }, props))); };
