import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import UserAvatar from 'src/components/User/UserAvatar';
import { getTagTextColor } from 'src/utils/Collection';
import { DashboardTableColumnType } from '../../Constant';
import './DashboardTableCell.scss';
var DashboardTableCell = function (_a) {
    var cellData = _a.cellData;
    var cellContent = useMemo(function () {
        switch (cellData.type) {
            case DashboardTableColumnType.USER: {
                var userInfo = cellData.value;
                return (_jsxs("div", { className: "flex items-center", children: [_jsx(UserAvatar, { name: userInfo.fullName, isCirclular: true, size: 20, avatar: userInfo.avatarUrl }), _jsx("div", { className: "ml-1 text-[12px] font-medium", children: userInfo.fullName })] }));
            }
            case DashboardTableColumnType.TEXT: {
                var text = cellData.value.text;
                return _jsx("div", { className: "font-medium", children: text });
            }
            case DashboardTableColumnType.TAGS:
                {
                    var tags = cellData.value.tags;
                    return tags.map(function (_a, index) {
                        var displayValue = _a.displayValue, color = _a.color;
                        if (!displayValue)
                            return _jsx(_Fragment, {});
                        return (_jsx("span", { style: {
                                backgroundColor: color,
                                color: getTagTextColor(color),
                            }, className: "TagValue text-xs", children: displayValue }, "".concat(index, "+").concat(displayValue, "+").concat(color)));
                    });
                }
                return _jsx("div", { children: "Cell Type Not Support" });
        }
    }, [cellData]);
    return _jsx("div", { className: "DashboardTableCellContainer text-xs", children: cellContent });
};
export default React.memo(DashboardTableCell);
