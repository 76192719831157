var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var RightArrowSvg = function () { return (_jsx("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M12.1627 7.33365L7.53648 2.70743C7.40631 2.57726 7.40631 2.3662 7.53648 2.23603L8.00789 1.76462C8.13806 1.63445 8.34912 1.63445 8.47929 1.76462L14.4793 7.76462C14.6095 7.8948 14.6095 8.10585 14.4793 8.23603L8.47929 14.236C8.34912 14.3662 8.13806 14.3662 8.00789 14.236L7.53648 13.7646C7.40631 13.6344 7.40631 13.4234 7.53648 13.2932L12.1627 8.66698H1.66634C1.48225 8.66698 1.33301 8.51774 1.33301 8.33365V7.66698C1.33301 7.48289 1.48225 7.33365 1.66634 7.33365H12.1627Z", fill: "black" }) })); };
export var RightArrowIcon = function (props) { return (_jsx(Icon, __assign({ component: RightArrowSvg }, props))); };
