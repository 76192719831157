import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from 'antd';
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
var PayItemTableSearch = function (_a) {
    var placeholder = _a.placeholder, globalFilter = _a.globalFilter, setGlobalFilter = _a.setGlobalFilter;
    var _b = useState(globalFilter), value = _b[0], setValue = _b[1];
    var onChange = useAsyncDebounce(function (value) {
        setGlobalFilter(value || undefined);
    }, 200);
    return (_jsx(Input, { className: "PayItemTableSearch", value: value || '', onChange: function (e) {
            setValue(e.target.value);
            onChange(e.target.value);
        }, placeholder: placeholder ? placeholder : 'Search' }));
};
export default React.memo(PayItemTableSearch);
