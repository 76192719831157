import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { BUTTON_COLOR_OPTIONS } from './Constant';
var SELECTED_BORDER_COLOR = '#7E85F9';
var ButtonColorSelector = function (_a) {
    var currentColor = _a.currentColor, onUpdateColor = _a.onUpdateColor;
    return (_jsx("div", { className: "flex mt-2 space-x-2", children: BUTTON_COLOR_OPTIONS.map(function (color, index) {
            var isSelected = currentColor === color;
            return (_jsx("div", { className: "w-[30px] h-[30px] rounded-lg border-[1.5px] border-[rgba(176, 208, 255, 0.3)] cursor-pointer", style: {
                    backgroundColor: color,
                    borderColor: isSelected ? SELECTED_BORDER_COLOR : undefined,
                }, onClick: function () { return onUpdateColor(color); } }, "".concat(color, "-").concat(index)));
        }) }));
};
export default React.memo(ButtonColorSelector);
