var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Breadcrumb, Divider, Switch } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import { CopyIcon } from 'src/utils/icons/CopyIcon';
import { RefreshIcon } from 'src/utils/icons/RefreshIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSetting } from 'src/hooks/api';
import { accountIdState } from 'src/state';
import { useRecoilValue } from 'recoil';
import SettingsContentHeader from '../SettingsContentHeader';
var AccountBranding = function () {
    var _a = useSetting(), useInitBrandingSettings = _a.useInitBrandingSettings, useUpdateBrandingSettings = _a.useUpdateBrandingSettings, useGetBrandingOrganization = _a.useGetBrandingOrganization;
    var accountId = useRecoilValue(accountIdState);
    var _b = useGetBrandingOrganization(undefined, undefined, accountId), orgData = _b.data, refetch = _b.refetch;
    var _c = useState(), urlName = _c[0], setUrlName = _c[1];
    var _d = useState(false), allowSelfRegister = _d[0], setAllowSelfRegister = _d[1];
    var _e = useState(), mobileCode = _e[0], setMobileCode = _e[1];
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (orgData) {
            setUrlName(orgData.name);
            setAllowSelfRegister((_c = (_b = (_a = orgData.settings) === null || _a === void 0 ? void 0 : _a.selfRegister) === null || _b === void 0 ? void 0 : _b.enabled) !== null && _c !== void 0 ? _c : false);
            setMobileCode((_e = (_d = orgData.settings) === null || _d === void 0 ? void 0 : _d.selfRegister) === null || _e === void 0 ? void 0 : _e.code);
        }
    }, [orgData]);
    var brandedUrlInput = useMemo(function () { return (_jsxs("div", { className: "flex items-center space-x-1 w-[45%] rounded-md bg-gray-50 px-3 py-2 font-medium text-gray-400 min-w-[240px] max-w-[350px]", children: [_jsx("span", { children: "https://" }), _jsx("input", { placeholder: "app", className: "bg-gray-50 text-black font-semibold w-[50%] focus-visible:outline-none", defaultValue: urlName, disabled: !!urlName, onBlur: function (e) {
                    setUrlName(e.target.value);
                }, onKeyDown: function (e) {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                    }
                } }), _jsx("span", { children: ".teambridge.com" })] })); }, [urlName]);
    var generateMobileCode = useCallback(function () {
        // generate 5 digit char code, exclude 0, O, I, l
        var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
        var code = Array.from({ length: 5 }, function () { return characters[Math.floor(Math.random() * characters.length)]; }).join('');
        setMobileCode(code);
    }, []);
    var _f = useState(false), copied = _f[0], setCopied = _f[1];
    var onCopy = useCallback(function () {
        setCopied(true);
        setTimeout(function () {
            setCopied(false);
        }, 2000);
    }, []);
    var onSaveSetting = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!urlName)
                        return [2 /*return*/];
                    request = {
                        name: urlName,
                        settings: {
                            selfRegister: {
                                enabled: allowSelfRegister,
                                code: mobileCode,
                            },
                        },
                    };
                    if (!orgData) return [3 /*break*/, 2];
                    return [4 /*yield*/, useUpdateBrandingSettings.mutateAsync({
                            id: orgData.id,
                            settings: request.settings,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, useInitBrandingSettings.mutateAsync(request)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    refetch();
                    return [2 /*return*/];
            }
        });
    }); }, [allowSelfRegister, mobileCode, orgData, urlName]);
    var saveButton = useMemo(function () { return (_jsx("div", { className: "SettingContentFooter", children: _jsx(ZiraActionButton, { onClickFn: onSaveSetting, className: "SaveSettingBtn", disabled: !urlName, children: "Save" }) })); }, [onSaveSetting, urlName]);
    return (_jsxs("div", { className: "BusinessNameSettingContainer", children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Branding" }) }), _jsxs("div", { className: "SettingContentView", id: "SettingContentView", children: [_jsx("span", { className: "MainSettingsFlexWrap", children: _jsxs("span", { className: "MainSettingsWrapper", children: [_jsx("div", { className: "BusinessSettingTitle", children: "Branding" }), _jsx("div", { className: "BusinessSettingDescription", children: "Select how and where your team can login and register." }), _jsxs("div", { className: "pt-3", children: [_jsx("div", { className: "SettingOptionTitle", children: "Access URL" }), _jsxs("div", { className: "BusinessSettingDescription", children: ["Create a customized domain for your team\u2019s access.", _jsxs("span", { className: "text-negative font-semibold", children: [_jsx("br", {}), "This needs to be unique and can\u2019t be changed later."] })] }), brandedUrlInput] })] }) }), _jsx(Divider, {}), _jsxs("div", { className: "flex items-start space-x-2 ".concat(!urlName && 'pointer-events-none'), children: [_jsx(Switch, { disabled: !urlName, checked: allowSelfRegister, onChange: function (checked) {
                                    setAllowSelfRegister(checked);
                                } }), _jsx("div", { className: "p-2 font-medium text-gray-400", children: _jsxs("div", { children: [_jsxs("div", { className: "flex space-x-3 pb-2", children: [_jsx("span", { className: "font-semibold text-black", children: "Allow Self Registration" }), _jsx("span", { className: "font-normal", children: "Branded access URL required" })] }), allowSelfRegister && (_jsxs(_Fragment, { children: [_jsx("span", { children: "To allow users to sign up directly for your account. They will be added into default access group." }), _jsx("div", { className: "py-2 text-black", children: "Mobile Code (optional)" }), _jsxs("div", { className: "".concat(mobileCode ? 'text-black' : 'text-gray', " flex items-center gap-x-0"), children: [_jsx("div", { className: "w-[100px] h-[40px] bg-gray-50 rounded-lg flex justify-center items-center hover:cursor-default", children: mobileCode !== null && mobileCode !== void 0 ? mobileCode : 'Not Set' }), _jsx(CopyToClipboard, { text: mobileCode !== null && mobileCode !== void 0 ? mobileCode : '', onCopy: onCopy, children: _jsx("div", { className: "scale-[40%] w-6 ml-1 hover:cursor-pointer", children: _jsx(CopyIcon, {}) }) }), _jsx("div", { className: "scale-[40%] hover:cursor-pointer", onClick: generateMobileCode, children: _jsx(RefreshIcon, {}) }), copied && _jsx("span", { className: "text-green-500", children: "Copied!" })] })] }))] }) })] })] }), saveButton] }));
};
export default React.memo(AccountBranding);
