import { jsx as _jsx } from "react/jsx-runtime";
import { MoreOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ZiraVisibilityDropdown, ZiraVisibilityDropdownToggleItem, ZiraVisibilityDropdownVisibleItem, } from 'src/ui/ZiraVisibilityDropdown';
import { useDashboardComponent } from 'src/hooks/component/useDashboard';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { schedulingSnapshotOptionSettingState } from '../Atoms';
import { ScheduleSnapshotStatus } from '../Constant';
import { numberOfVisibleSchedulingSnapshotListSelector } from '../Selectors';
var SchedulingSnapshotOptionsDropdown = function () {
    var schedulingSnapshotOptionsSetting = useRecoilValue(schedulingSnapshotOptionSettingState);
    var numberOfSchedulingSnapshotVisibleList = useRecoilValue(numberOfVisibleSchedulingSnapshotListSelector);
    var schedulingSnapshotOptionVisibleOnChange = useDashboardComponent().schedulingSnapshotOptionVisibleOnChange;
    var allSortedOptions = useMemo(function () {
        return sortBy(Object.keys(ScheduleSnapshotStatus), function (k) {
            var _a;
            var key = k;
            var isVisible = (_a = schedulingSnapshotOptionsSetting[key]) !== null && _a !== void 0 ? _a : false;
            return isVisible ? 0 : 1;
        });
    }, [schedulingSnapshotOptionsSetting]);
    var allVisibleOptions = useMemo(function () {
        var _a;
        return (_a = Object.keys(ScheduleSnapshotStatus).filter(function (k) {
            var _a;
            var key = k;
            var isVisible = (_a = schedulingSnapshotOptionsSetting[key]) !== null && _a !== void 0 ? _a : false;
            if (isVisible)
                return key;
        })) !== null && _a !== void 0 ? _a : [];
    }, [schedulingSnapshotOptionsSetting]);
    var allVisibleOptionsContent = useMemo(function () {
        if (allVisibleOptions.length === 0)
            return (_jsx("div", { className: "flex items-center justify-center text-base font-medium text-gray-300", children: "No visible shift types" }));
        return (_jsx("div", { className: "flex w-full flex-col space-y-3 p-4", children: allVisibleOptions.map(function (k) {
                var key = k;
                var title = upperCaseStringToStartCase(key);
                return (_jsx(ZiraVisibilityDropdownVisibleItem, { title: title, onToggleOffItem: function () {
                        return schedulingSnapshotOptionVisibleOnChange(key, false);
                    } }, key));
            }) }));
    }, [allVisibleOptions, schedulingSnapshotOptionVisibleOnChange]);
    var allOptionsContent = useMemo(function () {
        return (_jsx("div", { className: "flex w-full flex-col", children: allSortedOptions.map(function (k, index) {
                var _a;
                var key = k;
                var title = upperCaseStringToStartCase(key);
                var isVisible = (_a = schedulingSnapshotOptionsSetting[key]) !== null && _a !== void 0 ? _a : false;
                var isLastVisible = isVisible && index === allVisibleOptions.length - 1;
                return (_jsx(ZiraVisibilityDropdownToggleItem, { title: title, isVisible: isVisible, onVisibilityToggled: function () {
                        return schedulingSnapshotOptionVisibleOnChange(key, !isVisible);
                    }, showDivider: isLastVisible }, key));
            }) }));
    }, [
        allSortedOptions,
        schedulingSnapshotOptionsSetting,
        allVisibleOptions.length,
        schedulingSnapshotOptionVisibleOnChange,
    ]);
    return (_jsx(ZiraVisibilityDropdown, { tabTitles: ['Visible Shift Types', 'All Types'], tabContents: [allVisibleOptionsContent, allOptionsContent], trigger: ['hover'], placement: "bottomLeft", destroyPopupOnHide: true, children: _jsx(Button, { className: numberOfSchedulingSnapshotVisibleList === 0 ? 'PulseAnimation' : '', icon: _jsx(MoreOutlined, { className: "text-[20px]", style: { color: 'black' } }) }) }));
};
export default React.memo(SchedulingSnapshotOptionsDropdown);
