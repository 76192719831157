import moment from 'moment';
import { ShiftStatus } from '../components/ScheduleView/Constant';
export var getShiftStatus = function (startAt, endAt, clockInAt, clockOutAt, hasAssignee) {
    var shiftStatus = ShiftStatus.UPCOMING;
    var now = moment();
    // Scheduled shift
    if (startAt) {
        if (!hasAssignee) {
            // Unassigned shift
            if (!clockInAt) {
                if (now.isBefore(startAt)) {
                    shiftStatus = ShiftStatus.OPEN;
                }
                else {
                    shiftStatus = ShiftStatus.UNFULFILLED;
                }
            }
            else {
                shiftStatus = ShiftStatus.UNKNOWN;
            }
        }
        else {
            // Assigned Shift
            if (!clockInAt) {
                // Not clocked in shift
                if (now.isBefore(startAt)) {
                    shiftStatus = ShiftStatus.UPCOMING;
                }
                else if (now.isBefore(endAt)) {
                    shiftStatus = ShiftStatus.LATE;
                }
                else {
                    shiftStatus = ShiftStatus.NO_SHOW;
                }
            }
            else {
                // Clocked in shift
                if (clockOutAt) {
                    shiftStatus = ShiftStatus.COMPLETED;
                }
                else {
                    shiftStatus = ShiftStatus.ONGOING;
                }
            }
        }
    }
    else {
        // Unscheduled shift
        if (hasAssignee) {
            if (clockOutAt) {
                shiftStatus = ShiftStatus.COMPLETED;
            }
            else {
                shiftStatus = ShiftStatus.ONGOING;
            }
        }
        else {
            shiftStatus = ShiftStatus.UNKNOWN;
        }
    }
    return shiftStatus;
};
export var findHiddenColumnColor = function (nativeFields, colorCodingSchemaId, shiftId) {
    var _a, _b, _c, _d, _e;
    var foundNativeField = (_a = Object.values(nativeFields)) === null || _a === void 0 ? void 0 : _a.find(function (nativeField) { return nativeField.schemaId === colorCodingSchemaId && nativeField.id === shiftId; });
    return (_e = (_b = foundNativeField === null || foundNativeField === void 0 ? void 0 : foundNativeField.color) !== null && _b !== void 0 ? _b : (_d = (_c = foundNativeField === null || foundNativeField === void 0 ? void 0 : foundNativeField.nativeObjects) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.color) !== null && _e !== void 0 ? _e : '#F4F4F7';
};
export var getDayOffset = function (startDate, endDate) {
    return endDate.diff(startDate, 'days');
};
export var getQualifyingRates = function (record, wages, holidayMultipliers, shiftTimeDetails, timeZone) {
    var _a, _b, _c, _d, _e, _f;
    var wageMatchIndex = wages.findIndex(function (wage) { return Object.keys(wage.qualifiers).length === 0; }); // Index of matching wage (Defaults to base rate index)
    var maxTotalMatchCount = 0; // Total qualifier matches
    var maxDateMatchCount = 0; // Date qualifier matches
    for (var wageIndex = 0; wageIndex < wages.length; wageIndex++) {
        var totalMatchCount = 0;
        var dateMatchCount = 0; // Tie breaker for wages with same total match count
        var wage = wages[wageIndex];
        var qualifiers = wage.qualifiers;
        var allQualifiersMatched = true;
        var _loop_1 = function (qualifier) {
            if (qualifier.type === 'COLLECTION') {
                // Roles/Location qualifier
                var recordMatched = false;
                for (var _h = 0, _j = Object.values(record); _h < _j.length; _h++) {
                    var cell = _j[_h];
                    if ((_a = cell.options) === null || _a === void 0 ? void 0 : _a.find(function (option) { return option.id === qualifier.recordId; })) {
                        recordMatched = true;
                        break;
                    }
                }
                if (recordMatched) {
                    totalMatchCount++;
                }
                else {
                    allQualifiersMatched = false;
                    return "break";
                }
            }
            else if (qualifier.type === 'SELECTION') {
                // Selection qualifer
                var optionMatched = false;
                for (var _k = 0, _l = Object.values(record); _k < _l.length; _k++) {
                    var cell = _l[_k];
                    if ((_b = cell.options) === null || _b === void 0 ? void 0 : _b.find(function (option) { return option.id === qualifier.selectionOptionId; })) {
                        optionMatched = true;
                        break;
                    }
                }
                if (optionMatched) {
                    totalMatchCount++;
                }
                else {
                    allQualifiersMatched = false;
                    return "break";
                }
            }
            else if (qualifier.type === 'TIME') {
                // Time qualifer
                var shiftTime = (_c = record[qualifier.schemaId]) === null || _c === void 0 ? void 0 : _c.content;
                if (shiftTime) {
                    var currentShiftTime = moment(moment(shiftTime).tz(timeZone).format('HH:mm:ss'), 'HH:mm:ss');
                    var qualifierTime = moment(qualifier.time, 'HH:mm:ss');
                    var isOn = qualifier.symbol.includes('on');
                    var isBefore = qualifier.symbol.includes('before');
                    var isAfter = qualifier.symbol.includes('after');
                    if ((isBefore && currentShiftTime.isBefore(qualifierTime)) ||
                        (isAfter && currentShiftTime.isAfter(qualifierTime))) {
                        totalMatchCount++;
                        dateMatchCount++;
                    }
                    else if (isOn && currentShiftTime.isSame(qualifierTime)) {
                        totalMatchCount++;
                        dateMatchCount++;
                    }
                    else {
                        allQualifiersMatched = false;
                        return "break";
                    }
                }
            }
        };
        for (var _i = 0, _g = Object.values(qualifiers); _i < _g.length; _i++) {
            var qualifier = _g[_i];
            var state_1 = _loop_1(qualifier);
            if (state_1 === "break")
                break;
        }
        if (allQualifiersMatched && totalMatchCount > 0) {
            if (totalMatchCount > maxTotalMatchCount ||
                (totalMatchCount === maxTotalMatchCount && dateMatchCount > maxDateMatchCount)) {
                // Higher total qualifier matches or equal with date qualifier match count as tiebreaker
                maxTotalMatchCount = totalMatchCount;
                maxDateMatchCount = Math.max(maxDateMatchCount, dateMatchCount);
                wageMatchIndex = wageIndex;
            }
            else if (totalMatchCount === maxTotalMatchCount &&
                dateMatchCount === maxDateMatchCount &&
                wages[wageIndex].regularRate < wages[wageMatchIndex].regularRate) {
                // Equal total & date qualifier matches (atleast 1 match) with wage as tie breaker
                maxTotalMatchCount = totalMatchCount;
                wageMatchIndex = wageIndex;
            }
        }
    }
    // Holiday multiplier
    var shiftStartTime = ((_d = shiftTimeDetails.startAt) !== null && _d !== void 0 ? _d : shiftTimeDetails.clockInAt)
        ? // Adjust for timezone; want the time in the user's timezone
            moment((_e = shiftTimeDetails.startAt) !== null && _e !== void 0 ? _e : shiftTimeDetails.clockInAt).add(moment.tz(timeZone).utcOffset() - moment().utcOffset(), 'minutes')
        : null;
    var holidayMultiplier = shiftStartTime
        ? ((_f = holidayMultipliers.find(function (holidayMultiplier) {
            // Have a year constant since we want to ignore the year of the holidays
            var yearConstant = 1337;
            var yearDiff = // Handle possible year change (12-31 to 1-1)
             Number(moment(holidayMultiplier.endDate).format('YYYY')) -
                Number(moment(holidayMultiplier.startDate).format('YYYY'));
            var endDateYearConstant = yearConstant + yearDiff;
            return moment(shiftStartTime.format("".concat(yearConstant, "-MM-DDTHH:mm"))).isBetween(moment(moment(holidayMultiplier.startDate).format("".concat(yearConstant, "-MM-DDTHH:mm"))), moment(moment(holidayMultiplier.endDate).format("".concat(endDateYearConstant, "-MM-DDTHH:mm"))), 'minutes', '[]');
        })) !== null && _f !== void 0 ? _f : { payMultiplier: 1, billMultiplier: 1 })
        : {
            payMultiplier: 1,
            billMultiplier: 1,
        };
    if (!wages[wageMatchIndex]) {
        return {
            payRate: 0,
            billRate: 0,
            wageId: undefined,
            isHoliday: false,
        };
    }
    return {
        payRate: wages[wageMatchIndex].regularRate * holidayMultiplier.payMultiplier,
        billRate: wages[wageMatchIndex].billRate * holidayMultiplier.billMultiplier,
        wageId: wages[wageMatchIndex].id,
        isHoliday: 'id' in holidayMultiplier ? true : false,
    };
};
