import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import UserAvatar from 'src/components/User/UserAvatar';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useRecordDetail } from 'src/hooks/component';
var MessageItemUserAvatar = function (_a) {
    var message = _a.message;
    var senderDetail = message.senderDetail, departmentSenderDetail = message.departmentSenderDetail;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    return (_jsx(ZiraTooltip, { title: "View Details", placement: "topLeft", children: _jsxs("div", { className: "MessageItemAvatar", onClick: function () { return openRecordDetail(senderDetail.id); }, children: [_jsx(UserAvatar, { name: senderDetail.name, isCirclular: true, size: 36, avatar: senderDetail.avatarUrl }), departmentSenderDetail && (_jsx(UserAvatar, { className: "MessageItemUserDepartmentAvatar", name: departmentSenderDetail.name, isCirclular: true, size: 20, avatar: departmentSenderDetail.avatarUrl }))] }) }));
};
export default MessageItemUserAvatar;
