import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { archiveOptionsPopupState } from 'src/state';
import ArchiveOptionsPopup from './ArchiveOptionsPopup';
var ArchiveOptionsViewContainer = function () {
    var visible = useRecoilValue(archiveOptionsPopupState);
    if (!visible)
        return null;
    return _jsx(ArchiveOptionsPopup, {});
};
export default React.memo(ArchiveOptionsViewContainer);
