import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilState } from 'recoil';
import TaskSettingModal from './TaskSettingModal';
import ReviewTaskChangesModal from './ReviewTaskChangesModal/ReviewTaskChangesModal';
import { selectedTaskRecordIdState } from './atom';
var TasksViewContainer = function () {
    var _a = useRecoilState(selectedTaskRecordIdState), selectedTaskRecordIdForChangesReview = _a[0], setSelectedTaskRecordIdForChangesReview = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx(TaskSettingModal, {}), !!selectedTaskRecordIdForChangesReview && (_jsx(ReviewTaskChangesModal, { recordId: selectedTaskRecordIdForChangesReview, onClose: function () { return setSelectedTaskRecordIdForChangesReview(null); } }))] }));
};
export default React.memo(TasksViewContainer);
