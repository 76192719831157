var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PercentageOutlinedSvg = function () { return (_jsxs("svg", { width: "12", height: "13", viewBox: "0 0 12 13", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M10.8514 1.19037C11.1609 1.49912 11.1609 2.00083 10.8514 2.30958L1.35139 11.8096C1.04264 12.1191 0.540929 12.1191 0.232179 11.8096C-0.0773117 11.5008 -0.0773117 10.9991 0.232179 10.6904L9.73218 1.19037C10.0409 0.880879 10.5426 0.880879 10.8514 1.19037Z", fill: "currentColor" }), _jsx("path", { d: "M9.50001 12.0419C10.3751 12.0419 11.0838 11.3331 11.0838 10.4581C11.0838 9.58378 10.375 8.875 9.50001 8.875C8.62571 8.875 7.91693 9.5838 7.91693 10.4581C7.91693 11.3331 8.62573 12.0419 9.50001 12.0419Z", fill: "currentColor" }), _jsx("path", { d: "M1.58382 4.12491C2.45813 4.12491 3.1669 3.41611 3.1669 2.54183C3.1669 1.66678 2.45811 0.958008 1.58382 0.958008C0.708776 0.958008 0 1.6668 0 2.54183C0 3.41613 0.708795 4.12491 1.58382 4.12491Z", fill: "currentColor" })] })); };
export var PercentageIcon = function (props) { return (_jsx(Icon, __assign({ component: PercentageOutlinedSvg }, props))); };
