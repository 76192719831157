import { cloneDeep, max } from 'lodash';
import moment from 'moment';
import { getAccountCurrencySymbol } from 'src/utils/Account';
var findRuleMatchedData = function (ruleId, data) {
    var _a;
    return (_a = data === null || data === void 0 ? void 0 : data.find(function (d) { return d.ruleId === ruleId; })) === null || _a === void 0 ? void 0 : _a.data;
};
var timeRangeToDisplayText = function (range) {
    switch (range) {
        case '7D':
            return '7 Days';
        case '30D':
            return '30 Days';
        case '3M':
            return '3 Months';
        case '6M':
            return '6 Months';
        case '12M':
            return '12 Months';
    }
};
var isAboveGoal = function (value, goal) {
    if (!goal)
        return true;
    return value >= goal;
};
var ruleDataToGraphData = function (ruleData, type, timezone, interval) {
    var _a, _b;
    if (type === void 0) { type = 'INTEGER'; }
    if (interval === void 0) { interval = 'DAILY'; }
    return (_b = (_a = cloneDeep(ruleData)) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) { return moment(a === null || a === void 0 ? void 0 : a.date).diff(moment(b === null || b === void 0 ? void 0 : b.date)); })) === null || _b === void 0 ? void 0 : _b.map(function (data) { return ({
        // parse based on timezone
        date: moment(data === null || data === void 0 ? void 0 : data.date).tz(timezone).format('MMM DD, YYYY') +
            getEndDateLabelByInterval(data === null || data === void 0 ? void 0 : data.date, interval),
        number: parseGraphData((data === null || data === void 0 ? void 0 : data.value) || 0, type),
    }); });
};
var numberFormatterWithType = function (number, type, requireThousandUnit) {
    if (requireThousandUnit === void 0) { requireThousandUnit = false; }
    var currency = getAccountCurrencySymbol();
    var isPercentage = type === 'PERCENTAGE';
    var isCurrency = type === 'CURRENCY';
    var formattedNumber = requireThousandUnit && type !== 'PERCENTAGE' ? kFormatter(number) : number;
    return isCurrency
        ? "".concat(currency).concat(formattedNumber.toLocaleString())
        : isPercentage
            ? "".concat(number.toFixed(2), "%")
            : formattedNumber.toLocaleString();
};
var getStartDateByEndAndTimeRange = function (endAt, timeRange) {
    switch (timeRange) {
        case '7D':
            return moment(endAt).subtract(7, 'days');
        case '30D':
            return moment(endAt).subtract(30, 'days');
        case '3M':
            return moment(endAt).subtract(3, 'months');
        case '6M':
            return moment(endAt).subtract(6, 'months');
        case '12M':
            return moment(endAt).subtract(12, 'months');
    }
};
var getEndDateByInterval = function (startAt, interval) {
    if (interval === void 0) { interval = 'DAILY'; }
    switch (interval) {
        case 'DAILY':
            return moment(startAt).add(1, 'days');
        case 'WEEKLY':
            return moment(startAt).add(1, 'weeks');
        case 'MONTHLY':
            return moment(startAt).add(1, 'months');
    }
};
var getMaxYFromGraphData = function (graphData) {
    return (max(graphData.map(function (d) { return d.number; })) || 1) * 1.2;
};
var getStepSizeByGraphData = function (graphData, type) {
    if (type === 'PERCENTAGE')
        return 25;
    var maxY = getMaxYFromGraphData(graphData);
    var initStep = Math.max(roundToNextMultiple(roundToNearest(maxY / 5, 10), 100), Math.floor(Math.min(maxY, 5))).toString();
    return parseInt(initStep[0] + '0'.repeat(initStep.length - 1));
};
export { findRuleMatchedData, timeRangeToDisplayText, isAboveGoal, ruleDataToGraphData, numberFormatterWithType, getStartDateByEndAndTimeRange, getEndDateByInterval, getMaxYFromGraphData, getStepSizeByGraphData, };
// -- Private functions -- //
var parseGraphData = function (number, type) {
    var roundedNumber = Number((number * (type === 'PERCENTAGE' ? 100 : 1)).toFixed(2));
    return type === 'INTEGER' ? Math.round(number) : roundedNumber;
};
var getEndDateLabelByInterval = function (date, interval) {
    var _a;
    if (interval === void 0) { interval = 'DAILY'; }
    if (interval === 'DAILY')
        return '';
    return ' - ' + ((_a = getEndDateByInterval(date, interval)) === null || _a === void 0 ? void 0 : _a.subtract(1, 'days').format('MMM DD, YYYY'));
};
var kFormatter = function (num) {
    var numberDividedByThousand = Math.abs(num) / 1000;
    var numberDividedByMillion = Math.abs(num) / 1000000;
    return Math.abs(num) > 999999
        ? (Math.sign(num) * numberDividedByMillion).toFixed(parseInt(numberDividedByMillion.toString()) === numberDividedByMillion ? 0 : 2) + 'M'
        : Math.abs(num) > 9999
            ? (Math.sign(num) * numberDividedByThousand).toFixed(parseInt(numberDividedByThousand.toString()) === numberDividedByThousand ? 0 : 2) + 'K'
            : Math.sign(num) * Math.abs(num);
};
var roundToNearest = function (num, nearest) { return Math.round(num / nearest) * nearest; };
var roundToNextMultiple = function (num, multiple) { return Math.ceil(num / multiple) * multiple; };
