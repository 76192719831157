import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import LogicSignatureCell from './LogicSignatureCell';
var SignatureCellHOC = function (props) {
    var signature = props.signature, editCell = props.editCell, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked;
    return (_jsx(_Fragment, { children: isClicked ? (_jsx(LogicSignatureCell, { url: signature, editCell: editCell, isClicked: isClicked, setIsClicked: setIsClicked })) : (_jsx("div", { className: "SignatureCell", onClick: function () {
                if (canEdit) {
                    setIsClicked(true);
                }
            }, children: _jsx("img", { height: 40, src: signature !== null && signature !== void 0 ? signature : undefined }) })) }));
};
export default React.memo(SignatureCellHOC);
