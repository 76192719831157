var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AdminLogoSvg = function () { return (_jsx("svg", { width: "20", height: "21", viewBox: "0 0 20 21", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M17.9201 2.82274C13.5639 2.40423 11.1001 0.468077 11.1001 0.446517C10.4625 -0.0811308 9.56013 -0.0811308 8.92248 0.446517C8.90014 0.468002 6.43626 2.40417 2.08004 2.82274C1.20004 2.91039 0.540039 3.63656 0.540039 4.51656C0.540039 16.8366 8.96626 20.6428 9.3176 20.7966C9.5376 20.8842 9.7576 20.9289 9.99995 20.9289C10.2423 20.9289 10.4623 20.8851 10.6823 20.7966C11.0347 20.6427 19.4599 16.8366 19.4599 4.51656C19.4599 3.63656 18.7999 2.91039 17.9199 2.82274H17.9201ZM9.97769 19.3003C9.84534 19.2341 8.32769 18.5527 6.67769 16.8579L6.89769 14.944C7.03003 13.8879 7.91004 13.0964 8.96533 13.0964H11.0115C12.0677 13.0964 12.9692 13.8887 13.0792 14.944L13.2992 16.8579C11.6724 18.5525 10.1323 19.2341 9.9776 19.3003H9.97769ZM10 11.1165C8.94385 11.1165 8.08621 10.2589 8.08621 9.20269C8.08621 8.14652 8.94385 7.28887 10 7.28887C11.0562 7.28887 11.9139 8.14652 11.9139 9.20269C11.9139 10.258 11.0562 11.1165 10 11.1165ZM14.7738 15.0981L14.73 14.7457C14.5762 13.3819 13.6738 12.2819 12.4862 11.7533C13.1462 11.0933 13.5638 10.1909 13.5638 9.20084C13.5638 7.2432 11.9577 5.63707 10.0001 5.63707C8.04247 5.63707 6.43629 7.24324 6.43629 9.20084C6.43629 10.2132 6.85394 11.1147 7.51394 11.7533C6.32629 12.2595 5.42394 13.3809 5.27016 14.7457L5.22633 15.0981C3.62015 12.7881 2.23389 9.37808 2.25633 4.4943C7.22877 4.01047 9.95633 1.7443 10.0439 1.7443C10.1539 1.83195 12.9039 4.03186 17.7663 4.4943L17.8102 4.53813C17.8102 9.40057 16.4025 12.8106 14.7739 15.0981L14.7738 15.0981Z", fill: "white" }) })); };
export var AdminLogo = function (props) { return (_jsx(Icon, __assign({ component: AdminLogoSvg }, props))); };
