import { atom } from 'recoil';
var availableMetricsDataState = atom({
    key: 'availableMetricsDataState',
    default: undefined,
});
var metricDetailsState = atom({
    key: 'metricDetailsState',
    default: undefined,
});
export { availableMetricsDataState, metricDetailsState };
