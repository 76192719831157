var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var BatteryIconSvg = function () { return (_jsxs("svg", { width: "25", height: "12", viewBox: "0 0 25 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_2998_50809)", children: [_jsx("rect", { opacity: "0.4", x: "0.5", y: "0.5", width: "21", height: "10.3333", rx: "2.16667", stroke: "black" }), _jsx("path", { opacity: "0.4", d: "M23 3.66675V7.66675C23.8047 7.32797 24.328 6.53988 24.328 5.66675C24.328 4.79361 23.8047 4.00552 23 3.66675Z", fill: "black" }), _jsx("rect", { x: "2", y: "2", width: "18", height: "7.33333", rx: "1.33333", fill: "black" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_2998_50809", children: _jsx("rect", { width: "24.328", height: "11.3333", fill: "white" }) }) })] })); };
export var BatteryIcon = function (props) { return (_jsx(Icon, __assign({ component: BatteryIconSvg }, props))); };
