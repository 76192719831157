import { atom, atomFamily, selectorFamily } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { localStorageEffect } from '../localStorageEffects';
var collectionTableState = atomFamily({
    key: 'collectionTableState',
    default: undefined,
});
var collectionTableNonSchemaNativeFieldsState = atomFamily({
    key: 'collectionTableNonSchemaNativeFieldsState',
    default: selectorFamily({
        key: 'collectionTableNonSchemaNativeFieldsState/Default',
        get: function (dataSource) {
            return function (_a) {
                var _b, _c;
                var get = _a.get;
                return (_c = (_b = get(collectionTableState(dataSource))) === null || _b === void 0 ? void 0 : _b.nonSchemaNativeFieldsMap) !== null && _c !== void 0 ? _c : {};
            };
        },
    }),
});
var collectionTableSchemasState = atomFamily({
    key: 'collectionTableSchemasState',
    default: selectorFamily({
        key: 'collectionTableSchemasState/Default',
        get: function (collectionId) {
            return function (_a) {
                var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                var get = _a.get;
                return ((_l = (_j = (_g = (_e = (_c = (_b = get(collectionTableState({ type: CollectionTableType.TABLE, collectionId: collectionId }))) === null || _b === void 0 ? void 0 : _b.columns) !== null && _c !== void 0 ? _c : (_d = get(collectionTableState({
                    type: CollectionTableType.DETAIL_MODAL,
                    collectionId: collectionId,
                }))) === null || _d === void 0 ? void 0 : _d.columns) !== null && _e !== void 0 ? _e : (_f = get(collectionTableState({
                    type: CollectionTableType.NEW_DETAIL_MODAL,
                    collectionId: collectionId,
                }))) === null || _f === void 0 ? void 0 : _f.columns) !== null && _g !== void 0 ? _g : (_h = get(collectionTableState({
                    type: CollectionTableType.QUICK_CREATE_MODAL,
                    collectionId: collectionId,
                }))) === null || _h === void 0 ? void 0 : _h.columns) !== null && _j !== void 0 ? _j : (_k = get(collectionTableState({
                    type: CollectionTableType.TASK_TEMPLATE_MODAL,
                    collectionId: collectionId,
                }))) === null || _k === void 0 ? void 0 : _k.columns) !== null && _l !== void 0 ? _l : []);
            };
        },
    }),
});
var collectionIdToBlockIdsState = atomFamily({
    key: 'collectionIdToBlockIdsState',
    default: [],
    effects: function (id) { return [localStorageEffect("collectionIdToBlockIdsState".concat(id))]; },
});
var collectionTableSchemaMutationsState = atomFamily({
    key: 'collectionTableSchemaMutationsState',
    default: [],
});
var collectionTableNonSchemaMutationsState = atomFamily({
    key: 'collectionTableNonSchemaMutationsState',
    default: [],
});
var collectionTableFilterPropertiesState = atomFamily({
    key: 'collectionTableFilterPropertiesState',
    default: { propertyNames: {}, propertyColors: {} },
});
//! Key: tableId = blockId/reverseRelationId
var collectionTableSelectedRecordIdsState = atomFamily({
    key: 'collectionTableSelectedRecordIdsState',
    default: [],
});
var tableCollectionInfoState = atomFamily({
    key: 'tableCollectionInfoState',
    default: undefined,
});
var tableCollectionViewsState = atomFamily({
    key: 'tableCollectionViewsState',
    default: {
        displaySettings: {},
        orderedViews: [],
        schedulingDisplaySettings: {},
    },
    effects: function (tableId) { return [localStorageEffect("tableCollectionViews:".concat(tableId))]; },
});
var tableSelectedViewIdState = atomFamily({
    key: 'tableSelectedViewIdState',
    default: undefined,
    effects: function (tableId) { return [localStorageEffect("tableSelectedViewId:".concat(tableId))]; },
});
var tableRecordCountsState = atomFamily({
    key: 'tableRecordCountsState',
    default: undefined,
});
var collectionTotalRecordsCountState = atomFamily({
    key: 'collectionTotalRecordsCountState',
    default: 0,
});
var nextPageOfTableState = atomFamily({
    key: 'nextPageOfTableState',
    default: 0,
    effects: function (tableId) { return [localStorageEffect("nextPageOfTableState:".concat(tableId))]; },
});
var tableSearchTextState = atomFamily({
    key: 'tableSearchTextState',
    default: '',
});
var tableCollectionRowsState = atomFamily({
    key: 'tableCollectionRowsState',
    default: [],
});
var tableViewRecordOrdersState = atomFamily({
    key: 'tableViewRecordOrdersState',
    default: undefined,
});
//! On filters/search change
var tableCollectionResetPageState = atomFamily({
    key: 'tableCollectionResetPageState',
    default: true,
});
// undefined -> hide user archive options modal
var archiveOptionsPopupState = atom({
    key: 'archiveOptionsPopupInfo',
    default: undefined,
});
var payRateValueShouldManuallyUpdateState = atomFamily({
    key: 'payRateValueShouldManuallyUpdateState',
    default: true,
});
var billRateValueShouldManuallyUpdateState = atomFamily({
    key: 'billRateValueShouldManuallyUpdateState',
    default: true,
});
export { collectionTableState, collectionTableNonSchemaNativeFieldsState, collectionTableSchemasState, collectionIdToBlockIdsState, collectionTableSchemaMutationsState, collectionTableNonSchemaMutationsState, collectionTableFilterPropertiesState, collectionTableSelectedRecordIdsState, tableCollectionInfoState, tableCollectionViewsState, tableSelectedViewIdState, tableRecordCountsState, collectionTotalRecordsCountState, nextPageOfTableState, tableSearchTextState, tableCollectionRowsState, tableViewRecordOrdersState, tableCollectionResetPageState, archiveOptionsPopupState, payRateValueShouldManuallyUpdateState, billRateValueShouldManuallyUpdateState, };
