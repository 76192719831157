var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ToolsSettingsIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", height: "24", width: "24", children: _jsxs("g", { children: [_jsx("rect", { x: "0.5", y: "0.5", width: "13", height: "13", rx: "1", transform: "translate(0 14) rotate(-90)", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "4.5", y1: "11", x2: "4.5", y2: "6", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("circle", { cx: "4.5", cy: "4.5", r: "1.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "9.5", y1: "3", x2: "9.5", y2: "6", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "9.5", y1: "9", x2: "9.5", y2: "11", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("circle", { cx: "9.5", cy: "7.5", r: "1.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" })] }) })); };
export var ToolsSettingsIcon = function (props) { return (_jsx(Icon, __assign({ component: ToolsSettingsIconSvg }, props))); };
