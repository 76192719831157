var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { evaluate } from 'mathjs';
import { selectorFamily } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { collectionTableCellDataSelector } from '.';
var evaluateFormulaSelector = selectorFamily({
    key: 'evaluateFormulaSelector',
    get: function (_a) {
        var cellPos = _a.cellPos, formulaStack = _a.formulaStack;
        return function (_a) {
            var get = _a.get;
            var exp = '';
            if (!formulaStack)
                return;
            formulaStack.map(function (val) {
                if (val.length === 36) {
                    var newCellPos = {
                        schemaId: val,
                        collectionId: cellPos.collectionId,
                        recordId: cellPos.recordId,
                    };
                    var schemaVal = get(collectionTableCellDataSelector(__assign(__assign({}, newCellPos), { type: CollectionTableType.TABLE })));
                    if (!schemaVal)
                        return '';
                    if ('text' in schemaVal) {
                        exp += schemaVal.text.value;
                    }
                    if ('amount' in schemaVal) {
                        exp += schemaVal.amount.value;
                    }
                }
                else {
                    exp += val;
                }
            });
            try {
                var val = evaluate(exp);
                return val;
            }
            catch (_b) {
                return '';
            }
        };
    },
});
export { evaluateFormulaSelector };
