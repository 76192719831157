import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { disableScroll } from 'src/utils/Collection';
import { isBoardViewSettingModalVisibleState } from './atom';
var ViewTypeOptionsDropdown = function (_a) {
    var viewId = _a.viewId, groupBySetting = _a.groupBySetting, applyGroupBySetting = _a.applyGroupBySetting;
    var isBoardView = !!groupBySetting;
    var setIsBoardViewSettingModalVisible = useSetRecoilState(isBoardViewSettingModalVisibleState(viewId));
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var items = useMemo(function () {
        if (isBoardView) {
            return [
                {
                    key: 'list_view',
                    label: 'List View',
                    onClick: function () {
                        // Clear block group by setting
                        applyGroupBySetting(null);
                        setDropdownOpen(false);
                        disableScroll(false);
                    },
                },
            ];
        }
        else {
            return [
                {
                    key: 'board_view',
                    label: 'Board View',
                    onClick: function () {
                        setIsBoardViewSettingModalVisible(true);
                        setDropdownOpen(false);
                        disableScroll(false);
                    },
                },
            ];
        }
    }, [applyGroupBySetting, isBoardView, setIsBoardViewSettingModalVisible]);
    return (_jsx(ZiraDropdown, { menu: { items: items }, trigger: ['click'], overlayClassName: "ViewTypeOptionsDropdownMenuOverlay", open: dropdownOpen, onLocalVisibleChange: function (open) { return setDropdownOpen(open); }, children: _jsx(ZiraTooltip, { title: "Switch View Type", children: _jsx("div", { className: "flex cursor-pointer items-center rounded-md p-[4px] hover:bg-gray-50", children: _jsx("span", { className: "OptionTitle", children: "".concat(isBoardView ? 'Board' : 'List', " View") }) }) }) }));
};
export default React.memo(ViewTypeOptionsDropdown);
