import { useQuery } from 'react-query';
import { useFetch } from '.';
var useRecipeApi = function () {
    var customFetch = useFetch().request;
    var useGetRecipeSummary = function (recipeId) {
        return useQuery(['reportBuilder', recipeId], function () { return getRecipeSummary(recipeId); });
    };
    var getRecipeSummary = function (recipeId) {
        return customFetch({
            method: 'GET',
            url: "recipes/summary/".concat(recipeId),
        });
    };
    var useGetRecipeDataChanges = function (recipeId) {
        return useQuery(['recipeDataChanges', recipeId], function () { return getRecipeDataChanges(recipeId); });
    };
    var getRecipeDataChanges = function (recipeId) {
        return customFetch({
            method: 'GET',
            url: "recipes/data_changes/".concat(recipeId),
        });
    };
    var useGetRecipePermissionChanges = function (request) {
        return useQuery(['recipePermissionChanges', JSON.stringify(request)], function () {
            return getRecipePermissionChanges(request);
        });
    };
    var getRecipePermissionChanges = function (request) {
        return customFetch({
            method: 'POST',
            url: 'recipes/permission_changes',
        }, request);
    };
    var useGetRecipeAutomations = function (recipeId) {
        return useQuery(['recipeAutomations', recipeId], function () { return getRecipeAutomations(recipeId); });
    };
    var getRecipeAutomations = function (recipeId) {
        return customFetch({
            method: 'GET',
            url: "recipes/automations/".concat(recipeId),
        });
    };
    var useActivateRecipe = function (request) {
        return useQuery(['activateRecipe'], function () {
            return customFetch({
                method: 'POST',
                url: 'recipes/activate',
            }, request);
        });
    };
    return {
        useGetRecipeSummary: useGetRecipeSummary,
        useGetRecipeDataChanges: useGetRecipeDataChanges,
        useGetRecipePermissionChanges: useGetRecipePermissionChanges,
        useGetRecipeAutomations: useGetRecipeAutomations,
        useActivateRecipe: useActivateRecipe,
    };
};
export { useRecipeApi };
