import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'src/hooks/component';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
var TableSearch = function (_a) {
    var searchInTable = _a.searchInTable;
    var _b = useState(), searchText = _b[0], setSearchText = _b[1];
    var debouncedSearchText = useDebounce(searchText, 500);
    // Call search endpoint
    useEffect(function () {
        if (debouncedSearchText !== undefined) {
            searchInTable(debouncedSearchText.trim());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText]);
    var onSearchInputChange = useCallback(function (e) {
        setSearchText(e.target.value);
    }, []);
    return (_jsx(Input, { className: "TableHeaderOptionSearchInput", prefix: _jsx(SearchOutlinedIcon, {}), placeholder: "Search...", bordered: false, onChange: onSearchInputChange }));
};
export default React.memo(TableSearch);
