import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ExceptionOutlined } from '@ant-design/icons/lib/icons';
import { Tag, Tooltip } from 'antd';
import { upperFirst } from 'lodash';
import HoverRecordMenu from 'src/components/OptimizedTableView/Cell/HoverRecordMenu';
import UserAvatar from 'src/components/User/UserAvatar';
import { getTagTextColor } from 'src/utils/Collection';
export var renderUserCell = function (recordId, time, name, onOpenUserRecord, avatarUrl, extraText) {
    return (_jsx(HoverRecordMenu, { rid: recordId, title: name, children: _jsxs("div", { className: "UserCell", onClick: function () { return onOpenUserRecord(); }, children: [_jsx(UserAvatar, { name: name, size: 36, avatar: avatarUrl }), _jsxs("span", { className: "UserTextWrapper", children: [_jsx("div", { className: "UserName", children: name }), _jsxs("div", { className: "UserRequestTime", children: [extraText, time] })] })] }) }, recordId));
};
export var renderOriginalAssigneeCell = function (onOpenUserRecord, originalAssignee) {
    if (!originalAssignee)
        return _jsx("div", { className: "UserCell" });
    return (_jsx(HoverRecordMenu, { rid: originalAssignee.recordId, title: originalAssignee.name, children: _jsxs("div", { className: "UserCell", onClick: function () { return onOpenUserRecord(); }, children: [_jsx(UserAvatar, { name: originalAssignee.name, size: 36, avatar: originalAssignee.avatarUrl }), _jsxs("span", { className: "UserTextWrapper", children: [_jsx("div", { className: "UserName", children: originalAssignee.name }), _jsx("div", { className: "UserRequestTime", children: "Current Assignee" })] })] }) }, originalAssignee.recordId));
};
export var noteColumn = function (notes) {
    var content = notes ? (_jsx(Tooltip, { title: notes, children: _jsx(ExceptionOutlined, { style: { scale: '120%' } }) })) : (_jsx("span", {}));
    return _jsx("div", { className: "m-auto flex flex-[0.5] justify-center", children: content });
};
export var requestStatusTag = function (status) {
    return (_jsx("div", { className: "StatusCell", children: _jsx(Tag, { className: "TagCell ActionTagCell", style: {
                color: status === 'approved'
                    ? getTagTextColor('#E5F4EF')
                    : getTagTextColor('#FFEDEC'),
                backgroundColor: status === 'approved' ? '#E5F4EF' : '#FFEDEC',
            }, children: upperFirst(status) }) }));
};
export var shiftSimpleTag = function (text, color) {
    if (color === void 0) { color = '#F4F4F7'; }
    return (_jsx(Tag, { color: color, className: "TagCell", children: text }));
};
export var renderOtherVisibleShiftColumns = function (recordId, schemaOptions, tableData) {
    var pillTagData = [];
    schemaOptions.map(function (schemaOpt) {
        var _a, _b, _c;
        if (schemaOpt.hidden || !tableData)
            return null;
        var schema = tableData.shiftCollection.schemas[schemaOpt.schemaId];
        if (!schema)
            return null;
        if (!schema.nativePath) {
            var item = Object.values(tableData.shiftCollection.items).find(function (item) { return item.schemaId === schema.id && item.recordId === recordId; });
            if (!item)
                return null;
            var values = item.values;
            if (values.length > 0) {
                values.map(function (value) {
                    var _a, _b, _c, _d;
                    var valueData = tableData.shiftCollection.selectionValues[value];
                    if (valueData) {
                        pillTagData.push({
                            name: (_a = valueData === null || valueData === void 0 ? void 0 : valueData.data) === null || _a === void 0 ? void 0 : _a.text,
                            color: (_c = (_b = valueData === null || valueData === void 0 ? void 0 : valueData.data) === null || _b === void 0 ? void 0 : _b.color) !== null && _c !== void 0 ? _c : undefined,
                        });
                    }
                    else {
                        var relations = tableData.shiftCollection.relations[value];
                        pillTagData.push({
                            name: relations === null || relations === void 0 ? void 0 : relations.title,
                            color: (_d = relations === null || relations === void 0 ? void 0 : relations.color) !== null && _d !== void 0 ? _d : undefined,
                        });
                    }
                });
            }
            else if (item.data) {
                pillTagData.push({
                    name: item.data.text,
                    color: (_a = item.data.color) !== null && _a !== void 0 ? _a : undefined,
                });
            }
        }
        else {
            var nativeItem = tableData.shiftCollection.nativeFields.find(function (field) { return field.schemaId === schema.id && field.recordId === recordId; });
            if (!nativeItem)
                return null;
            pillTagData.push({
                name: (_b = nativeItem.name) !== null && _b !== void 0 ? _b : nativeItem.number,
                color: (_c = nativeItem.color) !== null && _c !== void 0 ? _c : undefined,
            });
        }
    });
    return (_jsx(_Fragment, { children: pillTagData.map(function (data) {
            if (data.name != undefined)
                return shiftSimpleTag(data.name, data.color);
        }) }));
};
