import { useState, useCallback, useRef } from 'react';
import { useDebounce } from './useDebounce';
var DEBOUNCE_DEFAULT_TIMER = 500;
var BOTTOM_THRESHOLD_PIXELS = 100;
/**
 * Hook for managing pagination with search functionality.
 *
 * @param {PaginationOptions} options - Configuration options.
 * @param {number} [options.debounceTimer] - Debounce timer for search input (in milliseconds). Default: 500
 * @param {number} [options.bottomThresholdPixels] - Bottom threshold in pixels to trigger loading more. Default: 100
 * @returns {PaginationState} Pagination state.
 */
export var usePaginationWithSearch = function (_a) {
    var _b = _a.debounceTimer, debounceTimer = _b === void 0 ? DEBOUNCE_DEFAULT_TIMER : _b, _c = _a.bottomThresholdPixels, bottomThresholdPixels = _c === void 0 ? BOTTOM_THRESHOLD_PIXELS : _c;
    var _d = useState(0), page = _d[0], setPage = _d[1];
    var _e = useState(), searchText = _e[0], setSearchText = _e[1];
    var debouncedSearchText = useDebounce(searchText, debounceTimer);
    var isLoadingRef = useRef(false);
    var _f = useState(true), canLoadMore = _f[0], setCanLoadMore = _f[1];
    var setIsLoading = useCallback(function (loading) {
        isLoadingRef.current = loading;
    }, []);
    var isAtBottom = useCallback(function (target) {
        return (target.scrollHeight - target.scrollTop - target.clientHeight <=
            bottomThresholdPixels);
    }, [bottomThresholdPixels]);
    var onScroll = useCallback(function (event) {
        event.stopPropagation();
        var target = event.currentTarget;
        if (canLoadMore && !isLoadingRef.current && isAtBottom(target)) {
            setPage(function (prev) { return prev + 1; });
            isLoadingRef.current = true;
        }
    }, [canLoadMore, isAtBottom]);
    var onSearch = useCallback(function (search) {
        setSearchText((search === null || search === void 0 ? void 0 : search.toLowerCase().trim()) || undefined);
        setPage(0);
        setCanLoadMore(true);
    }, []);
    var resetPage = useCallback(function () {
        setPage(0);
        setCanLoadMore(true);
    }, []);
    return {
        page: page,
        searchText: debouncedSearchText,
        canLoadMore: canLoadMore,
        setCanLoadMore: setCanLoadMore,
        onScroll: onScroll,
        onSearch: onSearch,
        setIsLoading: setIsLoading,
        resetPage: resetPage,
    };
};
