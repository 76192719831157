import moment from 'moment';
export var generateDashboardHeaderGreetings = function () {
    var currentHour = moment().hour();
    if (currentHour >= 3 && currentHour < 12) {
        return 'Good Morning';
    }
    else if (currentHour >= 12 && currentHour < 15) {
        return 'Good Afternoon';
    }
    else if (currentHour >= 15 && currentHour < 20) {
        return 'Good Evening';
    }
    else if (currentHour >= 20 && currentHour < 3) {
        return 'Good Night';
    }
    else {
        return 'Hello';
    }
};
export var getStrokeColorByNumber = function (number) {
    if (number < 30) {
        return '#FF4A43';
    }
    else if (number < 50) {
        return '#FFC047';
    }
    return '#57BF93';
};
export var numberStringIntoSingleDigitArray = function (number) {
    return number.toString().split('');
};
export var calculateOnboardingProgress = function (data) {
    var totalNumber = data.length;
    if (totalNumber === 0)
        return 0;
    var checkedItemNumber = 0;
    for (var i = 0; i < totalNumber; i++) {
        if (data[i].isFinished) {
            checkedItemNumber++;
        }
    }
    return (checkedItemNumber / totalNumber) * 100;
};
export var getUserAlterStrokeColorByNumber = function (number) {
    if (number > 80) {
        return '#FF4A43';
    }
    else if (number > 50) {
        return '#FD7238';
    }
    return '#FFC047';
};
export var getSnapshotListTotalNumber = function (data, status) {
    var _a;
    var targetSnapshotInfo = data.find(function (item) { return item.type === status; });
    return (_a = targetSnapshotInfo === null || targetSnapshotInfo === void 0 ? void 0 : targetSnapshotInfo.count) !== null && _a !== void 0 ? _a : 0;
};
export var isAutomationLogEmpty = function (data) {
    if (data.length === 0)
        return true;
    // only when all automatons run count are 0, consider automation status is empty
    for (var i = 0; i < data.length; i++) {
        if (data[i].runCount !== 0) {
            return false;
        }
    }
    return true;
};
