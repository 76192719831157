import { jsx as _jsx } from "react/jsx-runtime";
import PaymentRulesTag from 'src/components/Settings/PayRate/PaymentRulesTab/component/PaymentRulesTable/component/PaymentRulesTableCell/component/PaymentRulesTag';
import moment from 'moment-timezone';
import { useTimeZone } from 'src/hooks/component';
var PaymentRuleDateTimeConditionTag = function (_a) {
    var _b, _c;
    var condition = _a.condition;
    var timeZone = useTimeZone().timeZone;
    var type = ((_b = condition.filterValue) === null || _b === void 0 ? void 0 : _b.type) || '';
    var value = (_c = condition.filterValue) === null || _c === void 0 ? void 0 : _c[type];
    return _jsx(PaymentRulesTag, { children: moment(value).tz(timeZone).format('h:mm A') });
};
export default PaymentRuleDateTimeConditionTag;
