var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import ZiraPopover from 'src/ui/ZiraPopover';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { getTagTextColor } from 'src/utils/Collection';
import { useRecordDetail } from 'src/hooks/component';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { ifUserHasGeneralPermission, scheduleTimeZoneSelector, tableCollectionIdSelector, } from 'src/state';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { getScheduleTemplateCountStatColor, getScheduleTemplateCountStatStatus, } from '../../../Utils';
var ScheduleTemplateCountStatEditPopover = function (_a) {
    var blockId = _a.blockId, children = _a.children, id = _a.id, name = _a.name, actual = _a.actual, budgetedExpected = _a.budgetedExpected, expected = _a.expected, date = _a.date, manuallyUpdatedInfo = _a.manuallyUpdatedInfo, onClose = _a.onClose, setExpected = _a.setExpected;
    var _b = useState(expected), newExpected = _b[0], setNewExpected = _b[1];
    var statusColor = getScheduleTemplateCountStatColor(actual, expected);
    var statusText = getScheduleTemplateCountStatStatus(actual, expected);
    var useManuallyUpdateTemplateExpectedCount = useNeedCoverageApi().useManuallyUpdateTemplateExpectedCount;
    var refetchCollection = useRefetchCollection();
    var userHasNeedsCoverageEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE));
    var userHasExpectedEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.EXPECTED_EDIT_PERMISSION)) ||
        userHasNeedsCoverageEditPermission;
    var updateTemplateExpectedCount = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var timeZone;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (newExpected === expected) {
                            onClose();
                            return [2 /*return*/];
                        }
                        timeZone = snapshot
                            .getLoadable(scheduleTimeZoneSelector(blockId))
                            .valueMaybe();
                        if (!timeZone) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, useManuallyUpdateTemplateExpectedCount.mutateAsync({ date: date, templateId: id, needs: newExpected, timeZone: timeZone }, {
                                onSuccess: function () {
                                    setExpected(newExpected);
                                    var collectionId = snapshot
                                        .getLoadable(tableCollectionIdSelector(blockId))
                                        .valueMaybe();
                                    if (collectionId) {
                                        setTimeout(function () {
                                            refetchCollection(collectionId);
                                        }, 500);
                                    }
                                },
                            })];
                    case 1:
                        _a.sent();
                        onClose();
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, id, newExpected, onClose]);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var content = useMemo(function () {
        return (_jsxs("div", { className: "ScheduleTemplateCountStatsPopover m-[16px] flex w-[200px] flex-col text-base", children: [_jsxs("div", { className: "mb-[16px] flex flex-col", children: [_jsx("div", { className: "mb-[4px] w-min rounded-xl px-[12px] font-medium", style: {
                                backgroundColor: statusColor,
                                color: getTagTextColor(statusColor),
                            }, children: statusText }), _jsx("div", { className: "break-all text-lg font-semibold", children: name }), _jsx("div", { className: "font-medium text-gray-400", children: moment(date).format('dddd, MMM D') })] }), _jsxs("div", { className: "mr-[-13px]", children: [_jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "font-semibold", children: "Assigned" }), _jsx("div", { className: "ml-auto mr-[13px] rounded bg-gray-50 px-[16px] font-medium", children: actual })] }), _jsx(Divider, { className: "my-[8px]" }), _jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "font-semibold", children: "Budgeted" }), _jsx("div", { className: "ml-auto mr-[13px] rounded bg-gray-50 px-[16px] font-medium", children: budgetedExpected })] }), _jsx(Divider, { className: "my-[8px]" }), _jsxs("div", { children: [_jsxs("div", { className: "flex items-center", children: [_jsx("span", { className: "font-semibold", children: "Expected" }), _jsxs("div", { className: "CountEditor ml-auto mr-[-12px]", children: [_jsx("div", { className: "MinusCount".concat(newExpected === 0 || !userHasExpectedEditPermission ? ' ZeroCount' : ''), onClick: function () {
                                                        if (userHasExpectedEditPermission) {
                                                            setNewExpected(function (prevCount) {
                                                                return Math.max(prevCount - 1, 0);
                                                            });
                                                        }
                                                    }, children: _jsx(MinusOutlined, {}) }), _jsx("div", { className: "mx-[4px] rounded bg-gray-50 px-[16px] font-medium", children: newExpected }), _jsx("div", { className: !userHasExpectedEditPermission
                                                        ? 'PlusCount ZeroCount'
                                                        : 'PlusCount', onClick: function () {
                                                        if (userHasExpectedEditPermission) {
                                                            setNewExpected(function (prevCount) { return prevCount + 1; });
                                                        }
                                                    }, children: _jsx(PlusOutlined, {}) })] })] }), manuallyUpdatedInfo && (_jsxs("div", { className: "text-sm text-gray-300", children: ["Updated by", ' ', _jsx("span", { className: "cursor-pointer hover:underline", onClick: function () {
                                                openRecordDetail(manuallyUpdatedInfo.actor.recordId);
                                                onClose();
                                            }, children: manuallyUpdatedInfo.actor.name })] }))] })] })] }));
    }, [
        actual,
        budgetedExpected,
        date,
        manuallyUpdatedInfo,
        name,
        newExpected,
        onClose,
        openRecordDetail,
        statusColor,
        statusText,
        userHasExpectedEditPermission,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraPopover, { open: true, content: content, overlayInnerStyle: { borderRadius: 8 }, children: children }), _jsx("div", { className: "fpOverlay", onClick: updateTemplateExpectedCount, style: { display: 'block' } })] }));
};
export default React.memo(ScheduleTemplateCountStatEditPopover);
