import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
var TaskSaveWarningModal = function (_a) {
    var type = _a.type, content = _a.content, onClose = _a.onClose;
    return (_jsxs(Modal, { open: true, centered: true, zIndex: 1002, footer: null, onCancel: onClose, children: [_jsxs("div", { className: "flex items-center justify-between rounded bg-yellow-50 p-4 w-[95%]", children: [_jsx(ExclamationCircleOutlined, { style: {
                            color: '#FFC047',
                            fontSize: '24px',
                            stroke: '#FFC047',
                            strokeWidth: '10',
                        }, size: 24 }), _jsxs("div", { className: "ml-3 text-sm font-medium", children: ["Please fill required fields below if you want to save this ", type, "."] })] }), _jsx("div", { className: "mt-5 pl-3", children: content.map(function (item, index) { return (_jsxs("div", { className: "mb-2 text-sm font-medium", children: [_jsx("span", { className: "mr-2", children: "\u2022" }), item] }, "".concat(index, "+").concat(item))); }) })] }));
};
export default React.memo(TaskSaveWarningModal);
