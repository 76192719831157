export var checkAllZeroValueMetricsPayInfo = function (item) {
    var result = true;
    for (var i = 0; i < item.metrics.length; i++) {
        var nameArray = item.metrics[i].metricLabel.split(' ');
        var isPayMetrics = nameArray[nameArray.length - 1] === 'Pay' ||
            item.metrics[i].metricLabel === 'Bill Amount';
        var metricValue = '';
        if (isPayMetrics) {
            var value = item.metrics[i].value;
            //Special case for CA$ currency symbol
            if (value.includes('CA$')) {
                metricValue = value.substring(3);
            }
            else {
                metricValue = value.substring(1);
            }
        }
        else {
            metricValue = item.metrics[i].value;
        }
        var valueNumber = Number(metricValue);
        if (valueNumber !== 0) {
            result = false;
            break;
        }
    }
    return result;
};
export var checkAllZeroValueShiftStatusCount = function (shiftPayrollStatusMap) {
    for (var key in shiftPayrollStatusMap) {
        var status_1 = key;
        var count = shiftPayrollStatusMap[status_1];
        if (count !== 0) {
            return false;
        }
    }
    return true;
};
