import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { CollectionType, EmitterEventType } from 'src/shared';
import Emitter from 'src/utils/EventEmitter';
var usePay = function () {
    var queryClient = useQueryClient();
    var refetchPayPeriodShiftStatusCount = useCallback(function () {
        queryClient.invalidateQueries(['payPeriodDataV2']);
    }, [queryClient]);
    var refetchIndividualPayCardList = useCallback(function () {
        queryClient.invalidateQueries(['payReportListData']);
    }, [queryClient]);
    var refetchPayViewDataForShiftAndTimeoffRecordMutation = useCallback(function (collectionType) {
        if (location.pathname.split('/')[2] === 'pay' &&
            (collectionType === CollectionType.SHIFT ||
                collectionType === CollectionType.TIMEOFF)) {
            if (collectionType === CollectionType.SHIFT) {
                refetchPayPeriodShiftStatusCount();
            }
            Emitter.emit(EmitterEventType.REFETCH_PAYCARD_LIST, null);
        }
    }, [refetchPayPeriodShiftStatusCount]);
    return {
        refetchPayPeriodShiftStatusCount: refetchPayPeriodShiftStatusCount,
        refetchIndividualPayCardList: refetchIndividualPayCardList,
        refetchPayViewDataForShiftAndTimeoffRecordMutation: refetchPayViewDataForShiftAndTimeoffRecordMutation,
    };
};
export { usePay as usePayComponent };
