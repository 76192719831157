var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'antd';
import { useRecoilValue } from 'recoil';
import { NEWS_FEED_BANNER } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import PaginationRow from 'src/components/OptimizedTableView/PaginationRow';
import './NewsFeed.scss';
import { SearchOutlinedIcon } from '../../../utils/icons/SearchOutlined';
import HoverOptionButtons from '../../PageView/HoverOptions/HoverOptionButtons';
import { useFeed } from '../../../hooks/api/useFeedApi';
import NewsFeedPostMessageBox from './NewsFeedPostMessageBox';
import NewsFeedPost from './NewsFeedPost';
import NewsFeedBanner from './NewsFeedBanner';
var NewsFeed = function (props) {
    var blockId = props.blockId, moveBlockUpDown = props.moveBlockUpDown;
    var _a = useFeed(), useGetAuthor = _a.useGetAuthor, useFetchNewsFeed = _a.useFetchNewsFeed;
    var data = useGetAuthor().data;
    var _b = useState(), author = _b[0], setAuthor = _b[1];
    useEffect(function () {
        if (data) {
            setAuthor(data);
        }
    }, [data]);
    var fetchNewsFeedMutation = useFetchNewsFeed();
    var _c = useState([]), feedData = _c[0], setFeedData = _c[1];
    var _d = useState('all'), currentFilter = _d[0], setCurrentFilter = _d[1];
    var _e = useState(''), searchText = _e[0], setSearchText = _e[1];
    var _f = useState(moment().toISOString()), fromTimestamp = _f[0], setFromTimestamp = _f[1];
    var _g = useState(false), isLoading = _g[0], setIsLoading = _g[1];
    var feedDataRef = useRef(feedData);
    useEffect(function () {
        feedDataRef.current = feedData;
    }, [feedData]);
    var fetchFeed = useCallback(function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (filters) {
            if (filters === void 0) { filters = null; }
            return __generator(this, function (_a) {
                fetchNewsFeedMutation.mutate({
                    filters: filters,
                    from: fromTimestamp,
                    pageSize: 20,
                }, {
                    onSuccess: function (resp) {
                        if (resp) {
                            setFeedData(feedData.concat(resp.posts));
                            if (resp.nextFrom && resp.nextFrom != fromTimestamp) {
                                setFromTimestamp(resp.nextFrom);
                            }
                            setIsLoading(false);
                        }
                    },
                });
                return [2 /*return*/];
            });
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromTimestamp]);
    useEffect(function () {
        fetchFeed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var updateReplyCount = useCallback(function (postId) {
        var tempData = cloneDeep(feedDataRef.current);
        tempData === null || tempData === void 0 ? void 0 : tempData.map(function (p) {
            if (p.id == postId) {
                p.replyCount++;
            }
        });
        setFeedData(tempData);
    }, []);
    var isBannerEnabled = useRecoilValue(featureEnableStatusState(NEWS_FEED_BANNER));
    var searchWithFilters = useCallback(function (filter, searchValue) {
        if (filter === void 0) { filter = null; }
        if (searchValue === void 0) { searchValue = null; }
        var filters = {
            postByMe: null,
            onlyToMe: null,
            textSearch: searchValue !== null ? searchValue : searchText,
            senderType: null,
        };
        switch (filter || currentFilter) {
            case 'posted_by_me':
                filters.postByMe = true;
                break;
            case 'direct_messages':
                filters.onlyToMe = true;
                break;
            case 'posted_by_automation':
                filters.senderType = 'AUTOMATION';
                break;
            case 'all':
                break;
        }
        fetchFeed(filters);
    }, [currentFilter, fetchFeed, searchText]);
    var onSearch = useCallback(function (e) {
        setSearchText(e.target.value);
        searchWithFilters(null, e.target.value);
    }, [searchWithFilters]);
    var selectFilter = useCallback(function (filter) {
        setCurrentFilter(filter);
        searchWithFilters(filter);
    }, [searchWithFilters]);
    return useMemo(function () {
        return (_jsxs("div", { className: "newsfeedWrapper", children: [_jsx(HoverOptionButtons, { data: { blockId: blockId }, moveBlockUpDown: moveBlockUpDown, isNonTitleBlock: true }), isBannerEnabled && _jsx(NewsFeedBanner, {}), _jsx(NewsFeedPostMessageBox, { author: author, sendDataBack: function (data) { return setFeedData(data.posts); } }), _jsxs("div", { className: "feedSearchFilterBar", children: [_jsx("div", { className: "feedHeader", children: "Most Recent" }), _jsx(Input, { className: "searchBar", prefix: _jsx(SearchOutlinedIcon, {}), placeholder: "Search...", onChange: onSearch }), _jsxs("div", { className: "filterWrapper", children: [_jsx("div", { className: 'filterOption ' + (currentFilter == 'all' ? 'selected' : ''), onClick: function () { return selectFilter('all'); }, children: "All" }), _jsx("div", { className: 'filterOption ' +
                                        (currentFilter == 'posted_by_me' ? 'selected' : ''), onClick: function () { return selectFilter('posted_by_me'); }, children: "Posted by me" }), _jsx("div", { className: 'filterOption ' +
                                        (currentFilter == 'direct_messages' ? 'selected' : ''), onClick: function () { return selectFilter('direct_messages'); }, children: "Direct Messages" }), _jsx("div", { className: 'filterOption ' +
                                        (currentFilter == 'posted_by_automation' ? 'selected' : ''), onClick: function () { return selectFilter('posted_by_automation'); }, children: "Posted by automation" })] })] }), _jsx("hr", {}), feedData.map(function (post) {
                    return (_jsx(NewsFeedPost, { post: post, updateReplyCount: updateReplyCount, updateFeedData: function (data) { return setFeedData(data.posts); } }, post.id));
                }), !!feedData.length && feedData.length % 20 === 0 && (_jsx(PaginationRow, { isLoading: isLoading, onLoadMore: function () {
                        if (isLoading)
                            return;
                        setIsLoading(true);
                        fetchFeed();
                    }, noRecordsFound: false }))] }));
    }, [
        blockId,
        moveBlockUpDown,
        isBannerEnabled,
        author,
        onSearch,
        currentFilter,
        feedData,
        isLoading,
        selectFilter,
        updateReplyCount,
        fetchFeed,
    ]);
};
export default React.memo(NewsFeed);
