var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useTasksApi } from 'src/hooks/api';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
import './ReviewTaskChangesModal.scss';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import TaskTemplateReferences from '../TaskTemplate/TaskTemplateReferences';
import PropagationSettings from './PropagationSettings';
import ChangesDiff from './ChangesDiff';
var ReviewTaskChangesModal = function (_a) {
    var _b;
    var recordId = _a.recordId, onClose = _a.onClose;
    var _c = useState({
        placed: false,
        pending: false,
        future: false,
    }), changePropagationSettings = _c[0], setChangePropagationSettings = _c[1];
    var _d = useTasksApi(), useGetPublishTaskChangesInfo = _d.useGetPublishTaskChangesInfo, usePublishTaskChanges = _d.usePublishTaskChanges;
    var _e = useGetPublishTaskChangesInfo(recordId, {
        enabled: !!recordId,
    }), changesInfo = _e.data, isFetching = _e.isFetching;
    var _f = usePublishTaskChanges(recordId), publishTaskChanges = _f.mutateAsync, isPublishing = _f.isLoading;
    var handlePublishChanges = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, publishTaskChanges(changePropagationSettings)];
                case 1:
                    _a.sent();
                    handleCloseModal();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCloseModal = function () {
        onClose();
    };
    var taskTemplate = changesInfo === null || changesInfo === void 0 ? void 0 : changesInfo.template;
    var differences = new Map(Object.entries((_b = changesInfo === null || changesInfo === void 0 ? void 0 : changesInfo.differences) !== null && _b !== void 0 ? _b : {}));
    var hasNoSettingConfigured = !changePropagationSettings.placed &&
        !changePropagationSettings.pending &&
        !changePropagationSettings.future;
    return (_jsx(Modal, { className: "ReviewTaskChangesModal min-w-[700px]", open: !!recordId, closable: true, centered: true, onCancel: handleCloseModal, destroyOnClose: true, title: _jsx("h2", { className: "font-semibold", children: "Review Task Changes" }), footer: _jsxs("div", { className: "flex flex-row items-center justify-end", children: [_jsx(Button, { style: { border: '1px solid rgba(0,0,0,.1)', borderRadius: '20px' }, className: "text-black hover:text-black", onClick: handleCloseModal, children: "Cancel" }), _jsx(ZiraActionButton, { disabled: isPublishing || hasNoSettingConfigured, className: "AddNewButton", onClickFn: handlePublishChanges, children: "Apply" })] }), children: _jsxs(_Fragment, { children: [isFetching && (_jsx("div", { className: "p-6", children: _jsx(Skeleton, {}) })), taskTemplate && !isFetching && (_jsxs("div", { className: "px-8 py-4 max-h-[580px] overflow-auto", children: [_jsxs("section", { className: "flex flex-row items-center justify-between", children: [_jsxs("div", { className: "flex flex-row", children: [_jsx(CustomIconInline, { iconUrl: taskTemplate.iconInfo.content, iconSize: 16, backgroundColor: taskTemplate.iconInfo.backgroundColor, className: "!min-h-[32px] !min-w-[32px] mr-[12px] self-start", radius: 9999 }), _jsxs("div", { className: "flex flex-col gap-1", children: [_jsxs("span", { children: [_jsx("span", { className: "font-semibold mr-2", children: taskTemplate.name }), _jsxs("span", { className: "text-xs bg-[#F3F1FB] font-semibold text-[#2F4DDF] px-2 py-1 rounded-xl", children: [differences.size, " Change(s)"] })] }), _jsx("span", { className: "text-gray-600", children: taskTemplate.displayId })] })] }), taskTemplate.id && _jsx(TaskTemplateReferences, { id: taskTemplate.id })] }), _jsx(ChangesDiff, { differences: differences }), _jsx(PropagationSettings, { changesInfo: changesInfo, changePropagationSettings: changePropagationSettings, configureSettings: setChangePropagationSettings })] }))] }) }));
};
export default React.memo(ReviewTaskChangesModal);
