import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal } from 'antd';
import { PAYMENT_RULES_MODAL_Z_INDEX } from '../constant';
import PaymentAdjustmentSection from './PaymentAdjustmentSection/PaymentAdjustmentSection';
import PaymentConditionsSection from './PaymentConditionsSection/PaymentConditionsSection';
import PayItemInfoInput from './PayItemInfoInput';
var PayItemModal = function (_a) {
    var isLoading = _a.isLoading, payItemType = _a.payItemType, onModalApply = _a.onModalApply, onModalClose = _a.onModalClose;
    return (_jsx(Modal, { width: 700, zIndex: PAYMENT_RULES_MODAL_Z_INDEX, open: true, destroyOnClose: true, onCancel: onModalClose, onOk: onModalApply, okButtonProps: { disabled: isLoading }, closable: false, centered: true, children: _jsxs("div", { className: "flex flex-col gap-4", children: [_jsx(PayItemInfoInput, { payItemType: payItemType }), _jsx(PaymentConditionsSection, { payItemType: payItemType }), _jsx(PaymentAdjustmentSection, { payItemType: payItemType }), _jsx("div", { className: "bg-gray-50 rounded-md px-3 py-2", children: "Please note that the fixed amounts and percentages are with respect to the base/regular pay. Changes in the base pay affects the total pay/bill invoiced." })] }) }));
};
export default React.memo(PayItemModal);
