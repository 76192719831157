var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AutomationFilterIconSvg = function () { return (_jsx("svg", { width: "20", height: "22", viewBox: "0 0 20 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M7.74981 10.0244V9.68423L7.54243 9.41464L1.30053 1.30016C1.28354 1.27808 1.28195 1.26538 1.28143 1.25882C1.28059 1.24811 1.28255 1.23135 1.29182 1.21254C1.30108 1.19372 1.31317 1.18195 1.32217 1.17608C1.32769 1.17249 1.33872 1.16602 1.36658 1.16602H18.633C18.6609 1.16602 18.6719 1.17249 18.6774 1.17609C18.6865 1.18195 18.6985 1.19373 18.7078 1.21254C18.7171 1.23135 18.719 1.24811 18.7182 1.25882C18.7177 1.26538 18.7161 1.27807 18.6991 1.30016L12.4572 9.41464L12.2498 9.68423V10.0244V20.2146L7.74981 17.9646V10.0244ZM3.56668 1.33268H1.53582L2.77406 2.94239L7.91648 9.62754V17.2436V17.8616L8.46926 18.138L10.6359 19.2214L12.0831 19.945V18.3269V9.62754L17.2256 2.94239L18.4638 1.33268H16.4329H3.56668Z", fill: "black", stroke: "black", strokeWidth: "2" }) })); };
export var AutomationFilterIcon = function (props) { return (_jsx(Icon, __assign({ component: AutomationFilterIconSvg }, props))); };
