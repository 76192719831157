var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Input, Select } from 'antd';
import { useRecipeApi } from 'src/hooks/api';
import { toggleList } from 'src/utils/Tools/LodashTool';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { collectionTableSchemasState } from 'src/state';
import { cloneDeep, startCase } from 'lodash';
import { getAutomationValueType } from 'src/utils/Automation';
import RecipeModalHeader from '../RecipeModalHeader';
import { RECIPE_MODAL_FOOTER_HEIGHT } from '../constant';
import { recipeDataChangesState, recipeOverriddenDataChangesState, recipeSchemaIdMapState, } from '../atom';
import RecipeTextWrapper from '../RecipeTextWrapper';
import RecipeLoading from '../RecipeLoading';
import { getRecipeValueTypeColor } from '../util';
var Option = Select.Option;
var RecipeDataChangesView = function (_a) {
    var _b, _c;
    var recipeId = _a.recipeId;
    var _d = useState([]), dataChangesUsingExistingSchemas = _d[0], setDataChangesUsingExistingSchemas = _d[1];
    var setDataChanges = useSetRecoilState(recipeDataChangesState);
    var setOverriddenDataChanges = useSetRecoilState(recipeOverriddenDataChangesState);
    var setSchemaIdMap = useSetRecoilState(recipeSchemaIdMapState);
    var useGetRecipeDataChanges = useRecipeApi().useGetRecipeDataChanges;
    var _e = useGetRecipeDataChanges(recipeId), data = _e.data, isLoading = _e.isLoading;
    var _f = data !== null && data !== void 0 ? data : { dataChanges: [], recipeSchemaIdMap: {} }, dataChanges = _f.dataChanges, recipeSchemaIdMap = _f.recipeSchemaIdMap;
    var columns = useRecoilValue(collectionTableSchemasState((_c = (_b = dataChanges[0]) === null || _b === void 0 ? void 0 : _b.collectionId) !== null && _c !== void 0 ? _c : ''));
    useEffect(function () {
        if (data) {
            setDataChanges(dataChanges);
            setOverriddenDataChanges(dataChanges.map(function (_a) {
                var schemaId = _a.schemaId, schemaName = _a.schemaName;
                return ({ schemaId: schemaId, schemaName: schemaName });
            }));
            setSchemaIdMap(recipeSchemaIdMap);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataChanges]);
    var onSchemaNameChange = useCallback(function (schemaId, schemaName) {
        setOverriddenDataChanges(function (prev) {
            var temp = cloneDeep(prev);
            var index = temp.findIndex(function (item) { return item.schemaId === schemaId; });
            if (index !== -1) {
                temp[index] = __assign(__assign({}, temp[index]), { schemaName: schemaName });
            }
            return temp;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onChooseExistingSchema = useCallback(function (schemaId, existingSchemaId) {
        setOverriddenDataChanges(function (prev) {
            var temp = cloneDeep(prev);
            var index = temp.findIndex(function (item) { return item.schemaId === schemaId; });
            if (index !== -1) {
                temp[index] = __assign(__assign({}, temp[index]), { schemaName: '', existingSchemaId: existingSchemaId });
            }
            return temp;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isLoading) {
        return _jsx(RecipeLoading, {});
    }
    return (_jsx("div", { className: "flex", style: { height: "calc(100% - ".concat(RECIPE_MODAL_FOOTER_HEIGHT, "px") }, children: _jsxs("div", { className: "flex flex-col w-full overflow-y-auto pt-[48px]", children: [_jsx(RecipeModalHeader, { title: "Create Custom Fields", description: "Confirm details for the custom fields that are part of this recipe" }), _jsx("div", { className: "space-y-[32px] py-[32px]", children: dataChanges.map(function (_a, index) {
                        var schemaName = _a.schemaName, schemaId = _a.schemaId, schemaType = _a.schemaType, collectionName = _a.collectionName, defaultOptions = _a.defaultOptions;
                        return (_jsxs("div", { className: "flex flex-col space-y-[16px] text-sm font-medium", children: [_jsxs("div", { className: "text-xl", children: ["Field ", index + 1, ": ", schemaName] }), _jsxs("div", { className: "text-xs", children: [_jsx(Checkbox, { onClick: function () {
                                                setDataChangesUsingExistingSchemas(function (prev) {
                                                    return toggleList(prev, schemaId);
                                                });
                                            } }), _jsx("span", { className: "pl-[8px]", children: "Check this box if this field already appears in your account" })] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Parent Object" }), _jsx(RecipeTextWrapper, { children: collectionName })] }), dataChangesUsingExistingSchemas.includes(schemaId) ? (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Existing Field" }), _jsx(Select, { className: "w-[200px] max-w-full", onChange: function (id) {
                                                return onChooseExistingSchema(schemaId, id);
                                            }, children: columns
                                                .filter(function (column) { return column.type === schemaType; })
                                                .map(function (c) { return (_jsx(Option, { value: c.id, children: c.name }, c.id)); }) })] })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Field Name" }), _jsx(Input, { className: "w-[200px] max-w-full", defaultValue: schemaName, onChange: function (e) {
                                                        return onSchemaNameChange(schemaId, e.target.value);
                                                    } })] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Field Type" }), _jsx(RecipeTextWrapper, { color: getRecipeValueTypeColor(schemaType), children: startCase(getAutomationValueType(schemaType)) })] }), defaultOptions && (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Field Options" }), _jsx("div", { className: "flex space-x-[8px]", children: defaultOptions.map(function (option) {
                                                        var _a;
                                                        return (_jsx(RecipeTextWrapper, { color: (_a = option.color) !== null && _a !== void 0 ? _a : undefined, children: option.name }, option.valueId));
                                                    }) })] }))] }))] }, index));
                    }) })] }) }));
};
export default React.memo(RecipeDataChangesView);
