import { jsx as _jsx } from "react/jsx-runtime";
import { Popconfirm } from 'antd';
import React, { useCallback } from 'react';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { useAccountApi } from 'src/hooks/api';
import { SendUserInviteIcon } from 'src/utils/icons/DashboardIcons/SendUserInviteIcon';
var UserDetailHeaderActions = function (_a) {
    var recordId = _a.recordId;
    var useInviteIndividualEmployee = useAccountApi().useInviteIndividualEmployee;
    var inviteUser = useCallback(function () {
        useInviteIndividualEmployee.mutate(recordId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);
    return (_jsx(Popconfirm, { title: "Are you sure you want to send an invite to this user?", okText: "Invite", cancelText: "Cancel", placement: "bottomRight", trigger: "click", onConfirm: inviteUser, children: _jsx(ZiraTooltip, { title: "Send Invite to User", children: _jsx("div", { className: "ActionButton", children: _jsx(SendUserInviteIcon, { className: "scale-[1.3]" }) }) }) }));
};
export default React.memo(UserDetailHeaderActions);
