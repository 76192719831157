import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
var Option = Select.Option, OptGroup = Select.OptGroup;
import React, { useCallback, useRef } from 'react';
import { AutomationFilterType } from '../Interface';
import './MessageCard.scss';
var Personalizations = function (props) {
    var filterOptions = props.filterOptions, updatePersonalizations = props.updatePersonalizations;
    var selectRef = useRef(null);
    var onOptionSelection = useCallback(function (val, elem) {
        var _a;
        elem.name = elem.name.replaceAll(' ', '_');
        if (elem.parentSchemaId) {
            updatePersonalizations({
                label: '{{' +
                    elem.parentSchemaName
                        .replace('Initiating User ', 'Initiating_User.')
                        .replaceAll(' ', '_') +
                    '.' +
                    elem.name +
                    '}}',
            });
        }
        else {
            if (elem.type === AutomationFilterType.ACTOR) {
                updatePersonalizations({
                    label: '{{Initiating_User.' + elem.name + '}}',
                });
            }
            else {
                updatePersonalizations({
                    label: '{{' + elem.name + '}}',
                });
            }
        }
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    }, [updatePersonalizations]);
    return (_jsx(Select, { ref: selectRef, className: "personalizationSelector", mode: "multiple", placeholder: "Insert Variable", onSelect: onOptionSelection, value: [], children: filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.map(function (group, groupIdx) { return (_jsx(OptGroup, { label: group.collectionName, children: group.schemas.map(function (option, idx) {
                var _a;
                var label = group.parentSchemaId !== null
                    ? "".concat(group.parentSchemaName, " / ").concat(option.name)
                    : "".concat(option.name);
                return (_jsx(Option, { value: "".concat(group.type).concat((_a = group.parentSchemaId) !== null && _a !== void 0 ? _a : '').concat(option.id), label: label, name: option.name, parentSchemaId: group.parentSchemaId, parentSchemaName: group.parentSchemaName, type: group.type, children: label }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx, "-").concat(option.id, "-").concat(label, "-").concat(idx)));
            }) }, "".concat(group.type, "-").concat(group.collectionId, "-").concat(groupIdx))); }) }));
};
export default React.memo(Personalizations);
