var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DefaultValue, selector } from 'recoil';
import { helpDrawerState } from '../atoms/helpDrawerState';
var isHelpDrawerVisibleState = selector({
    key: 'isHelpDrawerVisibleState',
    set: function (_a, visible) {
        var set = _a.set;
        if (!(visible instanceof DefaultValue)) {
            set(helpDrawerState, function (prev) {
                return __assign(__assign({}, prev), { isVisible: visible });
            });
        }
    },
    get: function (_a) {
        var get = _a.get;
        var drawerState = get(helpDrawerState);
        return drawerState.isVisible;
    },
});
var helpDrawerStepIndexState = selector({
    key: 'helpDrawerStepIndexState',
    set: function (_a, step) {
        var set = _a.set;
        if (!(step instanceof DefaultValue)) {
            set(helpDrawerState, function (prev) {
                return __assign(__assign({}, prev), { step: step });
            });
        }
    },
    get: function (_a) {
        var get = _a.get;
        var drawerState = get(helpDrawerState);
        return drawerState.step;
    },
});
var helpDrawerSelectedArticleIdState = selector({
    key: 'helpDrawerSelectedArticleIdState',
    set: function (_a, id) {
        var set = _a.set;
        if (!(id instanceof DefaultValue)) {
            set(helpDrawerState, function (prev) {
                return __assign(__assign({}, prev), { selectedHelpArticleId: id });
            });
        }
    },
    get: function (_a) {
        var get = _a.get;
        var drawerState = get(helpDrawerState);
        return drawerState.selectedHelpArticleId;
    },
});
export { isHelpDrawerVisibleState, helpDrawerStepIndexState, helpDrawerSelectedArticleIdState };
