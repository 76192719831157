import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import BlockDisplayCard from './BlockDisplayCard';
var BlockSelectionStep = function (_a) {
    var blockCategories = _a.blockCategories, goNextStep = _a.goNextStep, onSelectBlockCard = _a.onSelectBlockCard, isCreatingTableTypePage = _a.isCreatingTableTypePage;
    var blockCategoryMap = useMemo(function () {
        if (isCreatingTableTypePage) {
            // Only surface table section
            return Object.fromEntries(Object.entries(blockCategories.blockCategoryMap).filter(function (_a) {
                var key = _a[0];
                return key.includes('Table');
            }));
        }
        else {
            return blockCategories.blockCategoryMap;
        }
    }, [blockCategories.blockCategoryMap, isCreatingTableTypePage]);
    var renderBlockCategory = useMemo(function () {
        return Object.entries(blockCategoryMap).map(function (_a, idx) {
            var category = _a[0], blocks = _a[1];
            var accessibleBlocks = blocks.filter(function (block) { return block.hasAccess; });
            if (!accessibleBlocks.length)
                return null;
            return (_jsxs("div", { className: "mb-8", children: [_jsx("div", { className: "text-gray-500", children: category }), _jsx(Row, { gutter: 8, children: accessibleBlocks.map(function (block) { return (_jsx(Col, { span: 12, children: _jsx(BlockDisplayCard, { blockInfo: block, goNextStep: goNextStep, onSelectCard: function () { return onSelectBlockCard(block); } }) }, "".concat(block.blockName, "-").concat(block.blockType))); }) })] }, "".concat(category, "-").concat(idx)));
        });
    }, [blockCategoryMap, goNextStep, onSelectBlockCard]);
    return (_jsxs("div", { className: "BlockSelectionStepContainer", children: [_jsx("div", { className: "px-5 text-gray-500", children: isCreatingTableTypePage
                    ? 'Choose a table to be your workspace. Hover over a table option to see the preview.'
                    : 'Choose a block to add it to the page. Hover over a block option to see the preview.' }), _jsx("div", { className: "AllBlockCategoriesContent mt-8 px-5 pb-6", children: renderBlockCategory })] }));
};
export default React.memo(BlockSelectionStep);
