var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { ValueDataType } from 'src/shared';
import { usePageApi } from 'src/hooks/api';
var Option = Select.Option;
var ReportSchemas = function (_a) {
    var reportSchemas = _a.reportSchemas, setReportSchemas = _a.setReportSchemas, sourceCollectionId = _a.sourceCollectionId, showVariable = _a.showVariable;
    var _b = useState(false), showNewSchema = _b[0], setShowNewSchema = _b[1];
    var useGetCollectionSchemas = usePageApi().useGetCollectionSchemas;
    var data = useGetCollectionSchemas({
        body: { collectionId: sourceCollectionId },
        options: { enabled: !!sourceCollectionId },
    }).data;
    var schemaOptions = data === null || data === void 0 ? void 0 : data.filter(function (schema) {
        return [
            ValueDataType.NUMBER,
            ValueDataType.NATIVE_NUMBER,
            ValueDataType.CURRENCY,
            ValueDataType.NATIVE_CURRENCY,
        ].includes(schema.type);
    });
    var addNewReportSchema = useCallback(function (schemaId) {
        var schema = schemaOptions === null || schemaOptions === void 0 ? void 0 : schemaOptions.find(function (schema) { return schema.id === schemaId; });
        if (!schema)
            return;
        setReportSchemas(__spreadArray(__spreadArray([], reportSchemas, true), [{ schemaId: schema.id, isPlotted: false }], false));
        setShowNewSchema(false);
    }, [schemaOptions, reportSchemas, setReportSchemas]);
    var editReportSchema = useCallback(function (schemaId, index) {
        var tempReportSchemas = cloneDeep(reportSchemas);
        var schema = schemaOptions === null || schemaOptions === void 0 ? void 0 : schemaOptions.find(function (schema) { return schema.id === schemaId; });
        if (!schema)
            return;
        tempReportSchemas.splice(index, 1, {
            schemaId: schema.id,
            isPlotted: tempReportSchemas[index].isPlotted,
        });
        setReportSchemas(tempReportSchemas);
    }, [schemaOptions, reportSchemas, setReportSchemas]);
    var removeReportSchema = useCallback(function (index) {
        var tempReportSchemas = cloneDeep(reportSchemas);
        tempReportSchemas.splice(index, 1);
        setReportSchemas(tempReportSchemas);
    }, [reportSchemas, setReportSchemas]);
    var allSchemaOptions = useMemo(function () {
        return schemaOptions === null || schemaOptions === void 0 ? void 0 : schemaOptions.map(function (schema) { return (_jsx(Option, { value: schema.id, children: schema.name }, schema.id)); });
    }, [schemaOptions]);
    var filteredSchemaOptions = useMemo(function () {
        if (!schemaOptions)
            return [];
        var selectedSchemas = reportSchemas.map(function (schema) { return schema.schemaId; });
        return schemaOptions
            .filter(function (schema) {
            return !selectedSchemas.includes(schema.id);
        })
            .map(function (schema) { return (_jsx(Option, { value: schema.id, children: schema.name }, schema.id)); });
    }, [schemaOptions, reportSchemas]);
    return (_jsxs(_Fragment, { children: [reportSchemas === null || reportSchemas === void 0 ? void 0 : reportSchemas.map(function (schema, idx) { return (_jsxs("div", { className: "relative mb-2 flex items-center", children: [showVariable && (_jsxs("span", { className: "absolute font-semibold", style: { left: '-25.5px' }, children: ["c", idx + 1] })), _jsx("div", { className: "SelectWrapper", children: _jsx(Select, { showSearch: true, placeholder: "Select a Column", value: schema.schemaId, onChange: function (value) { return editReportSchema(value, idx); }, children: allSchemaOptions }) }), _jsx(Button, { icon: _jsx(CloseOutlined, {}), onClick: function () { return removeReportSchema(idx); }, title: "Remove column" })] }, schema.schemaId)); }), showNewSchema && (_jsxs("div", { className: "mb-2 mt-4 flex items-center", children: [_jsx("div", { className: "SelectWrapper", children: _jsx(Select, { showSearch: true, placeholder: "Select a Column", onChange: function (value) { return addNewReportSchema(value); }, children: filteredSchemaOptions }) }), _jsx(Button, { icon: _jsx(CloseOutlined, {}), onClick: function () { return setShowNewSchema(false); }, title: "Remove column" })] })), _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "AddButton", type: "primary", onClick: function () { return setShowNewSchema(true); }, disabled: showNewSchema, children: "Add Column" })] }));
};
export default React.memo(ReportSchemas);
