var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EditOutlined, CopyOutlined, DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined, } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useWindowSize } from 'src/hooks/component';
import { useReverseCollectionApi } from 'src/hooks/api';
import { tableCollectionResetPageState, tableSelectedViewIdState, tableCollectionViewsState, reverseCollectionIdSelector, } from 'src/state';
import { disableScroll } from 'src/utils/Collection';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import CollectionViewDropdown from './CollectionViewDropdown';
import CollectionViewTabs from './CollectionViewTabs';
import './CollectionViewOptions.scss';
var ReverseCollectionViewOptions = function (_a) {
    var reverseRelationId = _a.reverseRelationId;
    var reverseCollectionId = useRecoilValue(reverseCollectionIdSelector(reverseRelationId));
    var _b = useRecoilState(tableCollectionViewsState(reverseRelationId)), reverseCollectionViews = _b[0], setReverseCollectionViews = _b[1];
    var _c = useRecoilState(tableSelectedViewIdState(reverseRelationId)), selectedViewId = _c[0], setSelectedViewId = _c[1];
    var _d = useState(), viewIdInRename = _d[0], setViewIdInRename = _d[1];
    var _e = useState(''), newViewName = _e[0], setNewViewName = _e[1];
    var _f = useState(''), viewIdMenuOpen = _f[0], setViewIdMenuOpen = _f[1];
    var _g = useState(false), deleteConfirmOpen = _g[0], setDeleteConfirmOpen = _g[1];
    var setResetPage = useSetRecoilState(tableCollectionResetPageState(reverseRelationId));
    var displaySettingsMap = reverseCollectionViews.displaySettings, orderedViews = reverseCollectionViews.orderedViews;
    var _h = useReverseCollectionApi(), useAddReverseCollectionView = _h.useAddReverseCollectionView, useUpdateReverseCollectionView = _h.useUpdateReverseCollectionView, useUpdateReverseCollectionViewOrder = _h.useUpdateReverseCollectionViewOrder, useDeleteReverseCollectionView = _h.useDeleteReverseCollectionView;
    // Close delete popconfirm on menu close
    useEffect(function () {
        if (!viewIdMenuOpen) {
            setDeleteConfirmOpen(false);
        }
    }, [viewIdMenuOpen]);
    var onViewChange = useCallback(function (viewId) {
        setSelectedViewId(viewId);
        setResetPage(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onAddView = useCallback(function (duplicateViewId) {
        var _a;
        var initialName;
        var initialDisplaySettings;
        if (duplicateViewId) {
            var view = orderedViews.find(function (view) { return view.viewId === duplicateViewId; });
            if (!view)
                return;
            var viewId = view.viewId, name_1 = view.name;
            initialDisplaySettings = displaySettingsMap[viewId];
            var duplicateOfDuplicateIndex = (_a = name_1.match(/\(\d+\)$/)) === null || _a === void 0 ? void 0 : _a.index; // Check if name being duplicated ends in "(digit)"
            // Append " (1)"  to duplicated view name or increment the number
            initialName =
                duplicateOfDuplicateIndex && duplicateOfDuplicateIndex >= 0
                    ? "".concat(name_1.substring(0, duplicateOfDuplicateIndex), " (").concat((parseInt(name_1.substring(duplicateOfDuplicateIndex).slice(1, -1)) + 1).toString(), ")")
                    : "".concat(name_1, " (1)");
        }
        else {
            // Duplicate display settings of current view
            var view = orderedViews.find(function (view) { return view.viewId === selectedViewId; });
            if (!view)
                return;
            initialDisplaySettings = displaySettingsMap[view.viewId];
        }
        useAddReverseCollectionView.mutate({ reverseCollectionId: reverseCollectionId, name: initialName, displaySettings: initialDisplaySettings }, {
            onSuccess: function (newView) {
                if (newView) {
                    var viewId_1 = newView.viewId, displaySettings_1 = newView.displaySettings, schedulingDisplaySettings_1 = newView.schedulingDisplaySettings, name_2 = newView.name;
                    setReverseCollectionViews(function (prev) {
                        var _a, _b;
                        var newSchedulingDisplaySettings = prev.schedulingDisplaySettings;
                        if (schedulingDisplaySettings_1) {
                            if (newSchedulingDisplaySettings) {
                                newSchedulingDisplaySettings[viewId_1] =
                                    schedulingDisplaySettings_1;
                            }
                            else {
                                newSchedulingDisplaySettings = (_a = {},
                                    _a[viewId_1] = schedulingDisplaySettings_1,
                                    _a);
                            }
                        }
                        return __assign(__assign({}, prev), { displaySettings: __assign(__assign({}, prev.displaySettings), (_b = {}, _b[viewId_1] = displaySettings_1, _b)), schedulingDisplaySettings: newSchedulingDisplaySettings, orderedViews: __spreadArray(__spreadArray([], prev.orderedViews, true), [{ viewId: viewId_1, name: name_2 }], false) });
                    });
                    setSelectedViewId(viewId_1);
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reverseCollectionId, displaySettingsMap, orderedViews, selectedViewId]);
    var removeReverseCollectionView = useCallback(function (viewId) {
        setSelectedViewId(viewId !== orderedViews[0].viewId ? orderedViews[0].viewId : orderedViews[1].viewId);
        setReverseCollectionViews(function (prev) {
            var temp = cloneDeep(prev);
            delete temp.displaySettings[viewId];
            temp.orderedViews = temp.orderedViews.filter(function (view) { return view.viewId !== viewId; });
            return temp;
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedViews]);
    var onUpdateViewName = useCallback(function (viewId) {
        if (!newViewName) {
            setViewIdInRename(undefined);
            setNewViewName('');
            return;
        }
        setReverseCollectionViews(function (prev) {
            var temp = cloneDeep(prev);
            var index = temp.orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            if (index === -1)
                return temp;
            temp.orderedViews[index].name = newViewName;
            return temp;
        });
        useUpdateReverseCollectionView.mutate({ viewId: viewId, name: newViewName }, {
            onError: function () {
                removeReverseCollectionView(viewId);
            },
        });
        setViewIdInRename(undefined);
        setNewViewName('');
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [newViewName, removeReverseCollectionView]);
    var onUpdateViewOrder = useCallback(function (viewId, newIndex) {
        if (newIndex < 0)
            return;
        setReverseCollectionViews(function (prev) {
            var temp = cloneDeep(prev);
            var oldIndex = temp.orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            if (oldIndex === -1)
                return temp;
            var view = temp.orderedViews.splice(oldIndex, 1)[0];
            temp.orderedViews.splice(newIndex, 0, view);
            return temp;
        });
        useUpdateReverseCollectionViewOrder.mutate({ viewId: viewId, orderIndex: newIndex }, {
            onError: function () {
                removeReverseCollectionView(viewId);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeReverseCollectionView]);
    var onDeleteView = useCallback(function (viewId) {
        setViewIdMenuOpen('');
        setDeleteConfirmOpen(false);
        if (orderedViews.length === 1)
            return;
        useDeleteReverseCollectionView.mutate(viewId, {
            onSuccess: function () {
                removeReverseCollectionView(viewId);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedViews.length, removeReverseCollectionView]);
    var handleViewMenuClick = useCallback(function (info, viewId) {
        var _a, _b;
        info.domEvent.stopPropagation();
        disableScroll(false);
        var key = info.key;
        if (key !== 'delete') {
            setViewIdMenuOpen('');
        }
        if (key === 'rename') {
            setViewIdInRename(viewId);
            setNewViewName((_b = (_a = orderedViews.find(function (view) { return view.viewId === viewId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '');
            return;
        }
        if (key === 'duplicate') {
            onAddView(viewId);
            return;
        }
        if (key === 'left') {
            var currentIndex = orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            onUpdateViewOrder(viewId, currentIndex - 1);
            return;
        }
        if (key === 'right') {
            var currentIndex = orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            onUpdateViewOrder(viewId, currentIndex + 1);
            return;
        }
        if (key === 'delete') {
            setDeleteConfirmOpen(true);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedViews]);
    var _j = useWindowSize().windowWidth, windowWidth = _j === void 0 ? 0 : _j;
    var isLargeScreen = windowWidth >= 992;
    var childProps = {
        id: reverseCollectionId,
        selectedViewId: selectedViewId,
        orderedViews: orderedViews,
        viewIdInRename: viewIdInRename,
        canEditViews: true,
        setNewViewName: setNewViewName,
        onViewChange: onViewChange,
        onAddView: onAddView,
        onUpdateViewName: onUpdateViewName,
        onUpdateViewOrder: onUpdateViewOrder,
        viewIdMenuOpen: viewIdMenuOpen,
        setViewIdMenuOpen: setViewIdMenuOpen,
        viewMenuProps: function (viewId) {
            var _a, _b;
            return {
                items: __spreadArray(__spreadArray([
                    {
                        label: 'Rename',
                        key: 'rename',
                        icon: _jsx(EditOutlined, {}),
                    },
                    {
                        label: 'Duplicate',
                        key: 'duplicate',
                        icon: _jsx(CopyOutlined, {}),
                    }
                ], (isLargeScreen
                    ? [
                        ((_a = orderedViews[0]) === null || _a === void 0 ? void 0 : _a.viewId) !== viewId
                            ? {
                                label: 'Move Left',
                                key: 'left',
                                icon: _jsx(ArrowLeftOutlined, {}),
                            }
                            : null,
                        ((_b = orderedViews[orderedViews.length - 1]) === null || _b === void 0 ? void 0 : _b.viewId) !== viewId
                            ? {
                                label: 'Move Right',
                                key: 'right',
                                icon: _jsx(ArrowRightOutlined, {}),
                            }
                            : null,
                    ]
                    : []), true), [
                    orderedViews.length > 1
                        ? {
                            label: 'Delete',
                            key: 'delete',
                            danger: true,
                            icon: (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this view?", placement: "leftBottom", open: deleteConfirmOpen, onCancel: function () {
                                    setDeleteConfirmOpen(false);
                                    setViewIdMenuOpen('');
                                }, onConfirm: function () { return onDeleteView(viewId); }, children: _jsx(DeleteOutlined, {}) })),
                        }
                        : null,
                ], false),
                onClick: function (info) { return handleViewMenuClick(info, viewId); },
            };
        },
    };
    return (_jsx("div", { className: "CollectionViewOptions", children: isLargeScreen ? (_jsx(CollectionViewTabs, __assign({}, childProps))) : (_jsx(CollectionViewDropdown, __assign({}, childProps))) }));
};
export default React.memo(ReverseCollectionViewOptions);
