var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TrashOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "path-1-inside-1_3046_48408", fill: "white", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.375 5.75H6.625V18H15.375V5.75ZM4.875 4V19.3125C4.875 19.5541 5.07088 19.75 5.3125 19.75H16.6875C16.9291 19.75 17.125 19.5541 17.125 19.3125V4H4.875Z" }) }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.375 5.75H6.625V18H15.375V5.75ZM4.875 4V19.3125C4.875 19.5541 5.07088 19.75 5.3125 19.75H16.6875C16.9291 19.75 17.125 19.5541 17.125 19.3125V4H4.875Z", fill: "currentColor" }), _jsx("path", { d: "M6.625 5.75V3.75H4.625V5.75H6.625ZM15.375 5.75H17.375V3.75H15.375V5.75ZM6.625 18H4.625V20H6.625V18ZM15.375 18V20H17.375V18H15.375ZM4.875 4V2H2.875V4H4.875ZM17.125 4H19.125V2H17.125V4ZM6.625 7.75H15.375V3.75H6.625V7.75ZM8.625 18V5.75H4.625V18H8.625ZM15.375 16H6.625V20H15.375V16ZM13.375 5.75V18H17.375V5.75H13.375ZM5.3125 17.75C6.17545 17.75 6.875 18.4496 6.875 19.3125H2.875C2.875 20.6587 3.9663 21.75 5.3125 21.75V17.75ZM16.6875 17.75H5.3125V21.75H16.6875V17.75ZM15.125 19.3125C15.125 18.4496 15.8246 17.75 16.6875 17.75V21.75C18.0337 21.75 19.125 20.6587 19.125 19.3125H15.125ZM4.875 6H17.125V2H4.875V6ZM6.875 19.3125V4H2.875V19.3125H6.875ZM15.125 4V19.3125H19.125V4H15.125Z", fill: "currentColor", mask: "url(#path-1-inside-1_3046_48408)" }), _jsx("path", { d: "M3.625 5.25V4.5H18.375V5.25H3.625Z", fill: "black", stroke: "currentColor" }), _jsx("path", { d: "M12.375 15.75V8H13.125V15.75H12.375Z", fill: "black", stroke: "currentColor" }), _jsx("path", { d: "M8.875 15.75V8H9.625V15.75H8.875Z", fill: "black", stroke: "currentColor" }), _jsx("path", { d: "M8.875 3.5V2.75H13.125V3.5H8.875Z", fill: "black", stroke: "currentColor" })] })); };
export var TrashOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: TrashOutlinedSvg }, props, { style: { color: props.color } }))); };
