import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
var PayReportMetricRow = function (_a) {
    var metrics = _a.metrics, aggregateMetrics = _a.aggregateMetrics, largeValue = _a.largeValue;
    return (_jsxs("div", { className: "flex flex-wrap items-center justify-start", children: [aggregateMetrics === null || aggregateMetrics === void 0 ? void 0 : aggregateMetrics.map(function (_a) {
                var metricName = _a.metricName, metricValue = _a.metricValue;
                return (_jsxs("div", { className: "".concat(largeValue ? 'min-w-120' : 'min-w-100', " mr-4 w-fit flex-col"), children: [_jsx("div", { className: "font-medium text-gray-300", children: metricName }), _jsx("div", { className: "".concat(largeValue ? 'text-lg' : 'text-base', " font-medium"), children: metricValue })] }, uuidv4()));
            }), metrics === null || metrics === void 0 ? void 0 : metrics.map(function (_a) {
                var metricLabel = _a.metricLabel, value = _a.value;
                return (_jsxs("div", { className: "".concat(largeValue ? 'min-w-120' : 'min-w-100', " mr-4 w-fit flex-col"), children: [_jsx("div", { className: "font-medium text-gray-300", children: metricLabel }), _jsx("div", { className: "".concat(largeValue ? 'text-lg' : 'text-base', " font-medium"), children: value })] }, uuidv4()));
            })] }));
};
export default React.memo(PayReportMetricRow);
