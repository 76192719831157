import { selector, selectorFamily } from 'recoil';
import { GeneralPermissionKey, PagePermissionLevel, } from 'src/components/Settings/Permission/Constants';
import { AccessGroupType } from 'src/services/Interfaces';
import { TASKS_FEATURE } from 'src/components/FeatureGatingKeyConstant';
import { currentAccessGroupState, pagePermissionFamilyState } from '../atoms';
import { currentPageIdSelector } from './withPageState';
import { featureEnableStatusState } from './withAccountFeatureFlagState';
var currentGeneralPermissions = selector({
    key: 'currentGeneralPermissions',
    get: function (_a) {
        var get = _a.get;
        var currentAccessGroup = get(currentAccessGroupState);
        return currentAccessGroup === null || currentAccessGroup === void 0 ? void 0 : currentAccessGroup.generalPermissions;
    },
});
var ifUserHasGeneralPermission = selectorFamily({
    key: 'ifUserHasGeneralPermission',
    get: function (permissionKey) {
        return function (_a) {
            var get = _a.get;
            var generalPermissions = get(currentGeneralPermissions);
            return (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions[permissionKey]) === true;
        };
    },
});
var ifCurrentUserHasDataBuilderPermission = selector({
    key: 'ifCurrentUserHasDataBuilderPermission',
    get: function (_a) {
        var get = _a.get;
        return get(ifUserHasGeneralPermission(GeneralPermissionKey.BUILD_DATA));
    },
});
var ifCurrentUserHasWebAccessPermission = selector({
    key: 'ifCurrentUserHasWebAccessPermission',
    get: function (_a) {
        var get = _a.get;
        return get(ifUserHasGeneralPermission(GeneralPermissionKey.READ_WEB));
    },
});
var ifCurrentUserHasEditWorkspacePermission = selector({
    key: 'ifCurrentUserHasEditWorkspacePermission',
    get: function (_a) {
        var get = _a.get;
        return get(ifUserHasGeneralPermission(GeneralPermissionKey.EDIT_WORKSPACE));
    },
});
var ifCurrentUserHasActivityHistoryPermission = selector({
    key: 'ifCurrentUserHasActivityHistoryPermission',
    get: function (_a) {
        var get = _a.get;
        return get(ifUserHasGeneralPermission(GeneralPermissionKey.ACTIVITY_HISTORY));
    },
});
var ifCurrentUserHasChatPermission = selector({
    key: 'ifCurrentUserHasChatPermission',
    get: function (_a) {
        var get = _a.get;
        return get(ifUserHasGeneralPermission(GeneralPermissionKey.CHAT));
    },
});
var ifCurrentUserHasCommentsPermission = selector({
    key: 'ifCurrentUserHasCommentsPermission',
    get: function (_a) {
        var get = _a.get;
        return get(ifUserHasGeneralPermission(GeneralPermissionKey.COMMENTS));
    },
});
var ifCurrentUserHasTasksPermission = selector({
    key: 'ifCurrentUserHasTasksPermission',
    get: function (_a) {
        var get = _a.get;
        return (get(featureEnableStatusState(TASKS_FEATURE)) &&
            get(ifUserHasGeneralPermission(GeneralPermissionKey.TASKS)));
    },
});
var showSettingsSiderSelector = selector({
    key: 'showSettingsSider',
    get: function (_a) {
        var get = _a.get;
        var generalPermissions = get(currentGeneralPermissions);
        if (!generalPermissions)
            return false;
        return (generalPermissions.EDIT_ACCOUNT ||
            generalPermissions.TIME_TRACKING ||
            generalPermissions.ACCESS_GROUPS ||
            generalPermissions.BUILD_DATA ||
            generalPermissions.PAY ||
            generalPermissions.INTEGRATIONS ||
            generalPermissions.BILLING ||
            generalPermissions.PAY_PERIODS);
    },
});
var firstAvailableSettingSectionPathSelector = selector({
    key: 'firstSettingSectionPath',
    get: function (_a) {
        var get = _a.get;
        var generalPermissions = get(currentGeneralPermissions);
        if (!generalPermissions)
            return '';
        if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.EDIT_ACCOUNT)
            return 'business';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.TIME_TRACKING)
            return 'timeoff';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.PAY)
            return 'payrates';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.PAY_PERIODS)
            return 'payperiods';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.ACCESS_GROUPS)
            return 'access_group';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.BUILD_DATA)
            return 'collections';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.INTEGRATIONS)
            return 'integrations';
        else if (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.BILLING)
            return 'billing';
        else
            return '';
    },
});
var canUpdateFilterOnPageSelector = selectorFamily({
    key: 'canUpdateFilterOnPage',
    get: function (pageId) {
        return function (_a) {
            var get = _a.get;
            var pagePermission = get(pagePermissionFamilyState(pageId));
            return (parseInt((pagePermission === null || pagePermission === void 0 ? void 0 : pagePermission.permission) || '0') >=
                parseInt(PagePermissionLevel.APPLY_FILTER));
        };
    },
});
var canUpdateFilterOnCurrentPageSelector = selector({
    key: 'canUpdateFilterOnCurrentPage',
    get: function (_a) {
        var get = _a.get;
        var pageId = get(currentPageIdSelector);
        if (!pageId)
            return false;
        return get(canUpdateFilterOnPageSelector(pageId));
    },
});
var canUpdatePageHeaderInfoSelector = selector({
    key: 'canUpdatePageHeaderInfo',
    get: function (_a) {
        var get = _a.get;
        var pageId = get(currentPageIdSelector);
        if (!pageId)
            return false;
        var pagePermission = get(pagePermissionFamilyState(pageId));
        var canEditWorkSpace = get(ifCurrentUserHasEditWorkspacePermission);
        return (parseInt((pagePermission === null || pagePermission === void 0 ? void 0 : pagePermission.permission) || '0') > parseInt(PagePermissionLevel.READ) &&
            canEditWorkSpace);
    },
});
var isCurrentUserOwnerPermission = selector({
    key: 'isCurrentUserOwnerPermission',
    get: function (_a) {
        var get = _a.get;
        var currentAccessGroup = get(currentAccessGroupState);
        return (currentAccessGroup === null || currentAccessGroup === void 0 ? void 0 : currentAccessGroup.type) === AccessGroupType.OWNER;
    },
});
export { currentGeneralPermissions, ifUserHasGeneralPermission, ifCurrentUserHasDataBuilderPermission, ifCurrentUserHasEditWorkspacePermission, ifCurrentUserHasActivityHistoryPermission, ifCurrentUserHasWebAccessPermission, ifCurrentUserHasTasksPermission, showSettingsSiderSelector, firstAvailableSettingSectionPathSelector, canUpdateFilterOnPageSelector, canUpdatePageHeaderInfoSelector, canUpdateFilterOnCurrentPageSelector, isCurrentUserOwnerPermission, ifCurrentUserHasChatPermission, ifCurrentUserHasCommentsPermission, };
