var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var HiddenEyeOutlinedSvg = function () { return (_jsxs("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "20", height: "20", rx: "8", fill: "white" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.9494 10C6.23772 11.6796 8.05146 12.6667 9.99995 12.6667C11.9484 12.6667 13.7622 11.6796 15.0505 10C13.7622 8.32041 11.9484 7.33333 9.99995 7.33333C8.05146 7.33333 6.23772 8.32041 4.9494 10ZM3.55547 9.65527C3.41478 9.86368 3.41478 10.1363 3.55547 10.3447C5.05844 12.5711 7.38654 14 9.99995 14C12.6134 14 14.9415 12.5711 16.4444 10.3447C16.5851 10.1363 16.5851 9.86368 16.4444 9.65527C14.9415 7.42891 12.6134 6 9.99995 6C7.38654 6 5.05844 7.42891 3.55547 9.65527Z", fill: "#A7A8AC" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z", fill: "#A7A8AC" })] })); };
export var HiddenEyeOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: HiddenEyeOutlinedSvg }, props))); };
