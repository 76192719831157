var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { useAccountApi, useFetch } from '../../../hooks/api';
import { RollupType } from '../interface/constants';
var useRollup = function (rollupId, options) {
    var _a, _b;
    var customFetch = useFetch().request;
    var queryClient = useQueryClient();
    var useGetSchemasInCollection = useAccountApi().useGetSchemasInCollection;
    var _c = useQuery(['useGetRollupQuery', rollupId], function () {
        return customFetch({
            method: 'GET',
            url: "rollup/".concat(rollupId),
        });
    }, __assign(__assign({ enabled: Boolean(rollupId) }, options), { refetchOnMount: false })), data = _c.data, refetch = _c.refetch, isLoading = _c.isLoading, isFetching = _c.isFetching;
    // Fetch schemas in collection if rollup average is linked to a rating schema
    var shouldEnableSchemasFetch = !!data && !!data.refCollectionId && ((_a = data.settings) === null || _a === void 0 ? void 0 : _a.rollupType) === RollupType.AVERAGE;
    var collectionSchemas = useGetSchemasInCollection({
        collectionId: (_b = data === null || data === void 0 ? void 0 : data.refCollectionId) !== null && _b !== void 0 ? _b : '',
        options: {
            enabled: shouldEnableSchemasFetch,
            refetchOnMount: true,
        },
    }).data;
    var calculateRollup = useMutation(function (rollupId) {
        return customFetch({
            method: 'POST',
            url: "rollup/calculate/".concat(rollupId),
        });
    });
    var refetchCollection = useRefetchCollection();
    var updateRollup = useMutation(function (_a) {
        var rollupId = _a.rollupId, refCollectionId = _a.refCollectionId, refSchemaId = _a.refSchemaId, rollupType = _a.rollupType, filterSettings = _a.filterSettings, denominatorFilterSettings = _a.denominatorFilterSettings, viewSettings = _a.viewSettings, sumNumberSchemaId = _a.sumNumberSchemaId;
        return customFetch({
            method: 'POST',
            url: "rollup",
        }, {
            id: rollupId,
            refCollectionId: refCollectionId,
            refSchemaId: refSchemaId,
            settings: {
                filterSettings: filterSettings,
                rollupType: rollupType,
                denominatorFilterSettings: denominatorFilterSettings,
                viewSettings: viewSettings,
                numberSchemaId: sumNumberSchemaId,
            },
        });
    }, {
        onSuccess: function (data) {
            if (data === null || data === void 0 ? void 0 : data.id) {
                queryClient.invalidateQueries({ queryKey: ['useGetRollupQuery', data.id] });
            }
            if (data === null || data === void 0 ? void 0 : data.collectionId) {
                refetchCollection(data.collectionId);
            }
        },
    });
    // Find the rating schema settings if the rollup average is linked to a rating schema
    var averageRatingSchemasSettings = useMemo(function () {
        var _a, _b, _c;
        if (collectionSchemas &&
            ((_a = data === null || data === void 0 ? void 0 : data.settings) === null || _a === void 0 ? void 0 : _a.numberSchemaId) &&
            ((_b = data === null || data === void 0 ? void 0 : data.settings) === null || _b === void 0 ? void 0 : _b.rollupType) === RollupType.AVERAGE) {
            var averageRollupSchema = collectionSchemas.find(function (schema) { var _a; return schema.id === ((_a = data === null || data === void 0 ? void 0 : data.settings) === null || _a === void 0 ? void 0 : _a.numberSchemaId); });
            if (averageRollupSchema) {
                return (_c = averageRollupSchema.settings) === null || _c === void 0 ? void 0 : _c.configuration;
            }
        }
    }, [collectionSchemas, data]);
    return {
        data: data,
        refetch: refetch,
        calculateRollup: calculateRollup.mutate,
        updateRollup: updateRollup.mutate,
        isLoading: isLoading,
        isFetching: isFetching,
        averageRatingSchemasSettings: averageRatingSchemasSettings,
    };
};
export default useRollup;
