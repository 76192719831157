var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import { useReportApi } from 'src/hooks/api';
import { toast } from 'react-toastify';
import './ReportModal.scss';
import { useReportComponent, useTimeZone } from 'src/hooks/component';
import ReportBuilder from './ReportBuilder';
var ReportModal = function (_a) {
    var _b, _c;
    var reportId = _a.reportId, closeModal = _a.closeModal, onCreateReport = _a.onCreateReport;
    var saveReportBuilderSettings = useReportComponent().saveReportBuilderSettings;
    var useGetReportBuilderData = useReportApi().useGetReportBuilderData;
    var initialReportBuilderData = useGetReportBuilderData(reportId).data;
    var _d = useState(), reportBuilderData = _d[0], setReportBuilderData = _d[1];
    var onSaveReport = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(reportBuilderData === null || reportBuilderData === void 0 ? void 0 : reportBuilderData.settings.sourceCollectionId)) {
                        toast('Must select a report source.', {
                            type: 'error',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        return [2 /*return*/];
                    }
                    if (!((_a = reportBuilderData === null || reportBuilderData === void 0 ? void 0 : reportBuilderData.settings.metrics) === null || _a === void 0 ? void 0 : _a.length)) {
                        toast('Must select atleast 1 report metric.', {
                            type: 'error',
                            position: toast.POSITION.BOTTOM_CENTER,
                        });
                        return [2 /*return*/];
                    }
                    if (!reportId) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveReportBuilderSettings(reportId, reportBuilderData)];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    onCreateReport === null || onCreateReport === void 0 ? void 0 : onCreateReport(reportBuilderData);
                    _b.label = 3;
                case 3:
                    closeModal();
                    return [2 /*return*/];
            }
        });
    }); }, [closeModal, onCreateReport, reportBuilderData, reportId, saveReportBuilderSettings]);
    var modalFooter = useMemo(function () { return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "cursor-pointer", onClick: closeModal, children: "Cancel" }), _jsx(ZiraActionButton, { className: "SaveButton", onClickFn: onSaveReport, children: "Save" })] })); }, [closeModal, onSaveReport]);
    var timeZone = useTimeZone().timeZone;
    return (_jsxs(Modal, { className: "ReportModalWrapper", open: true, closable: true, footer: modalFooter, centered: true, width: '100%', onCancel: closeModal, destroyOnClose: true, children: [_jsx("div", { className: "ReportModalHeaderWrapper", children: _jsx("div", { className: "ReportHeader", children: "Report Builder" }) }), initialReportBuilderData && (_jsx("div", { className: "ReportModalBodyWrapper", children: _jsx(ReportBuilder, { timeZone: timeZone, defaultReportValue: (_b = initialReportBuilderData === null || initialReportBuilderData === void 0 ? void 0 : initialReportBuilderData.report) !== null && _b !== void 0 ? _b : undefined, metricList: (_c = initialReportBuilderData === null || initialReportBuilderData === void 0 ? void 0 : initialReportBuilderData.allMetrics) !== null && _c !== void 0 ? _c : [], setReportBuilderData: setReportBuilderData }) }))] }));
};
export default React.memo(ReportModal);
