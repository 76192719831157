var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from 'antd';
import { cloneDeep, startCase } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useRecoilCallback } from 'recoil';
import ModalCellWrapper from 'src/components/OptimizedTableView/Cell/ModalCellWrapper/ModalCellWrapper';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { CollectionTableType } from 'src/shared';
import { collectionTableRecordState, collectionTableSchemaMutationsState, } from 'src/state';
import { DragHandlerIcon } from 'src/utils/icons/DragHandlerIcon';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { ProfileTaskSchemaType, TaskTemplateType } from '../../interface';
import './ProfileDataTaskSchemaRow.scss';
import { VALID_SIGNING_PARTNER_TASK_SCHEMA_TYPES } from '../../constant';
var ProfileDataTaskSchemaRow = function (_a) {
    var taskType = _a.taskType, taskSchema = _a.taskSchema, schema = _a.schema, dataSource = _a.dataSource, selectedCollectionId = _a.selectedCollectionId, dataSourceRid = _a.dataSourceRid, isUserProfile = _a.isUserProfile, index = _a.index, recordData = _a.recordData, onEditModalCell = _a.onEditModalCell, onUpdateTaskSchemaType = _a.onUpdateTaskSchemaType, onRemoveTaskSchema = _a.onRemoveTaskSchema;
    var _b = useState(false), taskTypeSelectOpen = _b[0], setTaskTypeSelectOpen = _b[1];
    var validTaskSchemaTypes = useMemo(function () {
        if (isUserProfile) {
            // Only show required/optional since this task edits the user profile
            return [ProfileTaskSchemaType.REQUIRED, ProfileTaskSchemaType.OPTIONAL];
        }
        if (schema.refCollectionId) {
            // Show all types including LINK_FROM_TASK for relation type schemas
            var types_1 = Object.values(ProfileTaskSchemaType);
            if (taskType === TaskTemplateType.SIGNING_PARTNER) {
                // Filter types for signing partner task
                return types_1.filter(function (type) { return VALID_SIGNING_PARTNER_TASK_SCHEMA_TYPES.has(type); });
            }
            return types_1;
        }
        // Hide LINK_FROM_TASK for non-relation type schemas
        var types = [
            ProfileTaskSchemaType.REQUIRED,
            ProfileTaskSchemaType.OPTIONAL,
            ProfileTaskSchemaType.LOCKED,
            ProfileTaskSchemaType.HIDDEN,
        ];
        if (taskType === TaskTemplateType.SIGNING_PARTNER) {
            // Filter types for signing partner task
            return types.filter(function (type) { return VALID_SIGNING_PARTNER_TASK_SCHEMA_TYPES.has(type); });
        }
        return types;
    }, [isUserProfile, schema.refCollectionId, taskType]);
    var onDeleteColumn = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId) {
            if (!recordData)
                return;
            // Remove from rendered list
            onRemoveTaskSchema(schemaId);
            // Remove cell state for deleted column
            var newRecordData = cloneDeep(recordData);
            delete newRecordData[schemaId];
            set(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: dataSourceRid })), newRecordData);
            set(collectionTableSchemaMutationsState(dataSource), function (prev) {
                return prev.filter(function (schema) { return schema.schemaId !== schemaId; });
            });
        };
    }, [dataSource, dataSourceRid, onRemoveTaskSchema, recordData]);
    return (_jsx(Draggable, { draggableId: schema.id, index: index, children: function (provided) { return (_jsx("div", __assign({ className: "DraggableItem", ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { onMouseDown: function (e) { return e.currentTarget.focus(); }, children: _jsxs("div", { className: "SchemaRow", children: [_jsx(DragHandlerIcon, { className: "flex items-center mr-2" }), _jsxs("div", { className: "flex w-full items-center justify-between min-w-0 TaskRowWrapper", children: [_jsx(ModalCellWrapper, { cellPos: {
                                    collectionId: selectedCollectionId,
                                    recordId: dataSourceRid,
                                    schemaId: schema.id,
                                }, collectionTableType: CollectionTableType.TASK_TEMPLATE_MODAL, editCell: onEditModalCell, style: {
                                    border: 'none',
                                    margin: '0',
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    pointerEvents: taskSchema.taskSchemaType ===
                                        ProfileTaskSchemaType.LINK_FROM_TASK
                                        ? 'none'
                                        : 'auto',
                                } }), _jsxs("div", { className: "flex items-center", children: [_jsx(Select, { className: "min-w-[104px] w-full rounded-lg", value: taskSchema.taskSchemaType, onChange: function (value) { return onUpdateTaskSchemaType(schema.id, value); }, open: taskTypeSelectOpen, onDropdownVisibleChange: setTaskTypeSelectOpen, dropdownMatchSelectWidth: false, children: validTaskSchemaTypes.map(function (type) { return (_jsx(Select.Option, { value: type, disabled: type === ProfileTaskSchemaType.OPTIONAL &&
                                                schema.isRequired, children: startCase(type.toLowerCase()) }, type)); }) }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete ".concat(schema.name, " field?"), onConfirm: function () { return onDeleteColumn(schema.id); }, placement: "leftBottom", children: _jsx(ZiraTooltip, { title: "Delete Field", children: _jsx("div", { className: "rounded-lg p-1 hover:cursor-pointer hover:bg-gray-60 border-2 border-[#f4f4f5] mx-2", children: _jsx(DeleteOutlinedIcon, { fill: "black" }) }) }) })] })] })] }) }))); } }));
};
export default React.memo(ProfileDataTaskSchemaRow);
