import { atom } from 'recoil';
var chosenMetricWithDataState = atom({
    key: 'chosenMetricWithDataState',
    default: undefined,
});
var deletedRuleIdsState = atom({
    key: 'deletedRuleIds',
    default: [],
});
export { chosenMetricWithDataState, deletedRuleIdsState };
