import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Button, Input, Select } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reportChartTypeState, reportSearchTextState, reportChartVisibleState, reportTableVisibleState, } from 'src/state';
import { useNaviTreeComponent } from 'src/hooks/component';
import { blockTitleSelector } from 'src/state';
import { BarChartOutlined, LineChartOutlined } from '@ant-design/icons';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
import TableTitleHOC from '../../OptimizedTableView/OptionHeader/Title/TableTitleHOC';
import { ReportChartType } from '..';
import ReportDateRangeSelector from './ReportDateRangeSelector';
import ReportMetricsDropdown from './ReportMetricsDropdown';
import ReportMoreOptionsDropdown from './ReportMoreOptionsDropdown';
var Option = Select.Option;
var ReportOptionHeader = function (_a) {
    var blockId = _a.blockId, reportId = _a.reportId, openReportModal = _a.openReportModal, rowData = _a.rowData;
    var _b = useRecoilState(reportChartTypeState(reportId)), chartType = _b[0], setChartType = _b[1];
    var _c = useRecoilState(reportSearchTextState(reportId)), searchText = _c[0], setSearchText = _c[1];
    var chartIsVisible = useRecoilValue(reportChartVisibleState(reportId));
    var tableIsVisible = useRecoilValue(reportTableVisibleState(reportId));
    var title = useRecoilValue(blockTitleSelector(blockId));
    var saveRenameNode = useNaviTreeComponent().saveRenameNode;
    var onUpdateTitle = useCallback(function (title, requestSave) {
        saveRenameNode('block', blockId, title, requestSave);
    }, [blockId, saveRenameNode]);
    var onSearchChange = useCallback(function (e) {
        setSearchText(e.target.value);
    }, [setSearchText]);
    return (_jsxs("div", { className: "ReportOptionHeader", children: [_jsx("div", { className: "mr-auto", children: _jsx(TableTitleHOC, { title: title, updateTitle: onUpdateTitle }) }), tableIsVisible && (_jsx(Input, { className: "w-[240px] rounded-md", prefix: _jsx(SearchOutlinedIcon, {}), value: searchText, placeholder: "Search...", onChange: onSearchChange })), chartIsVisible && (_jsxs(_Fragment, { children: [_jsxs(Select, { className: "ml-2 w-[90px]", value: chartType, onChange: setChartType, children: [_jsx(Option, { value: ReportChartType.BAR, children: _jsxs("div", { className: "flex items-center", children: [_jsx(BarChartOutlined, {}), "\u00A0Bar"] }) }), _jsx(Option, { value: ReportChartType.LINE, children: _jsxs("div", { className: "flex items-center", children: [_jsx(LineChartOutlined, {}), "\u00A0Line"] }) })] }), _jsx(ReportMetricsDropdown, { reportId: reportId })] })), _jsx(ReportDateRangeSelector, { reportId: reportId }), _jsx(Button, { className: "ml-2 bg-white text-black", onClick: openReportModal, children: "Edit Report" }), _jsx(ReportMoreOptionsDropdown, { reportId: reportId, rowData: rowData })] }));
};
export default React.memo(ReportOptionHeader);
