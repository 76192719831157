import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { OrganizationContext } from 'src/App';
import noAccessImg from '../../utils/icons/images/NoWebAccess.png';
import iosDownload from '../../utils/icons/images/AppStoreDownloadWithBlackBg.png';
import googleDownload from '../../utils/icons/images/GooglePlayDownloadWithBlackBg.png';
var NoWebAccessEmptyView = function () {
    var org = useContext(OrganizationContext);
    return (_jsx("div", { className: "NoWebAccessEmptyViewContainer", children: _jsxs("div", { className: "NoWebAccessEmptyViewContent", children: [_jsx("img", { src: noAccessImg, className: "NoAccessImg" }), _jsx("div", { className: "mb-[8px] text-4xl font-semibold", children: "No Web Access" }), _jsx("div", { className: "mb-[24px] text-sm font-medium text-gray-500", children: "You do not have access to view this account on the web platform. Please switch accounts or login via the mobile app to access the account." }), _jsx("div", { className: "mb-[56px] text-sm font-medium text-gray-500", children: "If you believe this to be an error please reach out to an account admin to update your Access Group permission." }), !org && (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-[16px] text-lg font-semibold text-gray-600", children: "Download TeamBridge:" }), _jsxs("div", { className: "MobileAppDownloadBtnContainer", children: [_jsx("a", { href: 'https://apps.apple.com/us/app/teambridge/id1591119474', target: "_blank", rel: "noreferrer", children: _jsx("img", { src: iosDownload }) }), _jsx("a", { href: 'https://play.google.com/store/apps/details?id=ai.zira.rome.workforce&hl=en', target: "_blank", rel: "noreferrer", children: _jsx("img", { src: googleDownload }) })] })] }))] }) }));
};
export default React.memo(NoWebAccessEmptyView);
