/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EventEmitter from 'eventemitter3';
var eventEmitter = new EventEmitter();
var Emitter = {
    on: function (event, fn) { return eventEmitter.on(event, fn); },
    once: function (event, fn) { return eventEmitter.once(event, fn); },
    off: function (event, fn) { return eventEmitter.off(event, fn); },
    emit: function (event, payload) { return eventEmitter.emit(event, payload); },
};
Object.freeze(Emitter);
export default Emitter;
