import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { cloneDeep, filter, findIndex, first } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useAccountApi } from 'src/hooks/api';
import { NativePath } from 'src/shared';
import { collectionTableNativeSchemaSelector, tableCollectionIdSelector } from 'src/state';
import RatioSettingRow from './RatioSettingRow';
var RatioSettingSection = function (_a) {
    var _b, _c;
    var blockId = _a.blockId, ratioSettingData = _a.ratioSettingData, updateRatioSettingData = _a.updateRatioSettingData;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var roleSchemaId = (_c = (_b = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.SHIFT_ROLE_IDS,
    }))) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : '';
    var useGetUniversalSimpleList = useAccountApi().useGetUniversalSimpleList;
    var roleList = useGetUniversalSimpleList({
        body: {
            schemaId: roleSchemaId,
        },
        options: {
            enabled: !!roleSchemaId,
        },
    }).data;
    var selectedRoles = useMemo(function () {
        return ratioSettingData.map(function (item) { return item.roleRid; });
    }, [ratioSettingData]);
    var availableRoles = useMemo(function () {
        if (!roleList)
            return [];
        var selectedRoles = ratioSettingData.map(function (item) { return item.roleRid; });
        return roleList.filter(function (item) { return !selectedRoles.includes(item.id); });
    }, [ratioSettingData, roleList]);
    var addNewRatioSettingRow = useCallback(function () {
        var newRatioSettingData = cloneDeep(ratioSettingData);
        var firstAvailableRole = first(availableRoles);
        if (!firstAvailableRole)
            return;
        var newRatioRow = {
            roleRid: firstAvailableRole.id,
            data: 0,
        };
        newRatioSettingData.push(newRatioRow);
        updateRatioSettingData(newRatioSettingData);
    }, [availableRoles, ratioSettingData, updateRatioSettingData]);
    var deleteRatioSettingRow = useCallback(function (roleId) {
        var temp = cloneDeep(ratioSettingData);
        var newRatioSettingData = filter(temp, function (item) { return item.roleRid !== roleId; });
        updateRatioSettingData(newRatioSettingData);
    }, [ratioSettingData, updateRatioSettingData]);
    var updateSelectedRole = useCallback(function (currentRoleId, newRoleId) {
        var newRatioSettingData = cloneDeep(ratioSettingData);
        var currentRoleIndex = findIndex(newRatioSettingData, function (item) { return item.roleRid === currentRoleId; });
        if (currentRoleIndex !== -1) {
            var newRole = roleList === null || roleList === void 0 ? void 0 : roleList.find(function (item) { return item.id === newRoleId; });
            if (!newRole)
                return;
            newRatioSettingData[currentRoleIndex].roleRid = newRoleId;
            updateRatioSettingData(newRatioSettingData);
        }
    }, [ratioSettingData, roleList, updateRatioSettingData]);
    var updateRatioNumber = useCallback(function (currentRoleId, newRatioValue) {
        var newRatioSettingData = cloneDeep(ratioSettingData);
        var currentRoleIndex = findIndex(newRatioSettingData, function (item) { return item.roleRid === currentRoleId; });
        if (currentRoleIndex !== -1) {
            newRatioSettingData[currentRoleIndex].data = newRatioValue;
            updateRatioSettingData(newRatioSettingData);
        }
    }, [ratioSettingData, updateRatioSettingData]);
    return (_jsxs("div", { className: "mt-8 w-[80%]", children: [_jsxs("div", { className: "RatioSettingRowContainer", children: [_jsx("div", { className: "font-medium", children: "Role" }), _jsx("div", { className: "font-medium", children: "Ratio" })] }), Object.values(ratioSettingData).map(function (item) {
                return (_jsx(RatioSettingRow, { roleList: roleList !== null && roleList !== void 0 ? roleList : [], rowData: item, selectedRoles: selectedRoles, deleteRatioSettingRow: deleteRatioSettingRow, updateSelectedRole: function (newRoleId) {
                        return updateSelectedRole(item.roleRid, newRoleId);
                    }, updateRatioNumber: function (newRatioValue) {
                        return updateRatioNumber(item.roleRid, newRatioValue);
                    } }, item.roleRid));
            }), _jsx(Button, { icon: _jsx(PlusOutlined, {}), className: "SaveLocationRatioBtn mt-[20px]", onClick: addNewRatioSettingRow, disabled: availableRoles.length === 0, children: "Add New" })] }));
};
export default React.memo(RatioSettingSection);
