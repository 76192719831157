import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useAccountApi } from 'src/hooks/api';
import { SendUserInviteIcon } from 'src/utils/icons/DashboardIcons/SendUserInviteIcon';
var SendUserInviteButton = function (props) {
    var recordId = props.recordId;
    var _a = useAccountApi(), useInviteEmployees = _a.useInviteEmployees, useInviteIndividualEmployee = _a.useInviteIndividualEmployee;
    var buttonStyle = recordId ? 'text-xs m-2 p-1.5' : 'text-sm font-medium py-1.5 px-2';
    var onUserInvite = useCallback(function () {
        if (!recordId) {
            // Send to all inactive users
            useInviteEmployees.mutate(false);
        }
        else {
            // Send to individual user
            useInviteIndividualEmployee.mutate(recordId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);
    return (_jsxs("div", { onClick: onUserInvite, className: "AlertWidgetActionButton ".concat(buttonStyle), children: [_jsx(SendUserInviteIcon, { className: "mr-1" }), _jsx("div", { children: "Resend Invite" })] }));
};
export default React.memo(SendUserInviteButton);
