/**
 * Note:
 * This file is for using ref on dom node.
 * Can help on listening to init event after it gets mounted.
 *
 * Reference:
 * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
import { useCallback, useState } from 'react';
export var useDomNodeRef = function (_a) {
    var _b = _a.onInit, onInit = _b === void 0 ? undefined : _b;
    var _c = useState(), node = _c[0], setNode = _c[1];
    var ref = useCallback(function (n) {
        if (n !== null) {
            setNode(n);
            onInit === null || onInit === void 0 ? void 0 : onInit(n);
        }
    }, [onInit]);
    return {
        node: node,
        ref: ref,
    };
};
