import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CellHOC from 'src/components/OptimizedTableView/Cell/CellHOC';
import { CollectionTableType } from 'src/shared';
var RecordDetailSchemaColumnRow = function (_a) {
    var schemaId = _a.schemaId, schemaName = _a.schemaName, collectionId = _a.collectionId, recordId = _a.recordId;
    return (_jsxs("div", { children: [_jsx("div", { className: "text-[11px] font-medium text-gray-300", children: schemaName }), _jsx(CellHOC, { cellPos: {
                    collectionId: collectionId,
                    recordId: recordId,
                    schemaId: schemaId,
                }, collectionTableType: CollectionTableType.DETAIL_MODAL, editCell: function () { }, clickButtonCell: function () { }, blockId: "", canEdit: false, canOnlyUpdateEmpty: false, isClicked: false, setIsClicked: function () { } })] }));
};
export default React.memo(RecordDetailSchemaColumnRow);
