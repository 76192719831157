import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
var TitleInput = function (props) {
    var title = props.title, updateTitle = props.updateTitle;
    var onTitleInputBlur = useCallback(function (e) {
        updateTitle === null || updateTitle === void 0 ? void 0 : updateTitle(e.currentTarget.innerText, true);
    }, [updateTitle]);
    return (_jsx("div", { className: 'TitleInput', contentEditable: true, onBlur: onTitleInputBlur, placeholder: "Untitled", suppressContentEditableWarning: true, children: title }));
};
export default React.memo(TitleInput);
