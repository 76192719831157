import { PayRuleAdjustmentType } from 'src/components/Settings/PayRate/interface';
export var findLastSetToPayRuleTypeItemIndex = function (items) {
    var index = -1;
    for (var i = items.length - 1; i >= 0; i--) {
        if (items[i].type === PayRuleAdjustmentType.SET_TO) {
            index = i;
            break;
        }
    }
    return index;
};
