var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var RightArrowOutlinedSvg = function () { return (_jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M15.7319 12.3536C15.9271 12.1584 15.9271 11.8418 15.7319 11.6465L8.14608 4.06074C7.95082 3.86547 7.95082 3.54889 8.14608 3.35363L8.85319 2.64652C9.04845 2.45126 9.36503 2.45126 9.56029 2.64652L18.5603 11.6465C18.7556 11.8418 18.7556 12.1584 18.5603 12.3536L9.56029 21.3536C9.36503 21.5489 9.04845 21.5489 8.85319 21.3536L8.14608 20.6465C7.95082 20.4513 7.95082 20.1347 8.14608 19.9394L15.7319 12.3536Z", fill: "currentColor" }) })); };
export var RightArrowOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: RightArrowOutlinedSvg }, props))); };
