import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { useRecoilValue } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import { collectionTableCanEditSelector, tableDisplaySettingsState, tableSelectedViewIdState, tableVisibleColumnIdsSelector, } from 'src/state';
import { useCollectionComponent, useDisplaySettings, useRecordComponent } from 'src/hooks/component';
import { CollectionTableType, CollectionType } from 'src/shared';
import { displaySettingsForCollectionTable } from 'src/services';
import CollectionBoardView from '../BoardView/CollectionBoardView';
import CollectionTableErrorBoundary from '../Error/CollectionTableErrorBoundary';
var ReverseCollectionBoardView = function (_a) {
    var _b;
    var title = _a.title, collectionId = _a.collectionId, referenceCollectionId = _a.referenceCollectionId, referenceRecordId = _a.referenceRecordId, schemaId = _a.schemaId, standardRelation = _a.standardRelation, reverseRelationId = _a.reverseRelationId, defaultDisplaySettings = _a.initialDisplaySettings, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading;
    var initialDisplaySettings = useState(defaultDisplaySettings)[0];
    var viewId = useRecoilValue(tableSelectedViewIdState(reverseRelationId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(reverseRelationId));
    var boardViewGroupBySetting = displaySettings.groupSetting;
    var processBoardViewCollectionIntoState = useCollectionComponent().processBoardViewCollectionIntoState;
    var useGetBoardViewGroupsData = usePageApi().useGetBoardViewGroupsData;
    var requestBody = useMemo(function () { return ({
        recordId: referenceRecordId,
        collectionId: collectionId,
        reverseCollectionViewId: viewId,
        schemaId: schemaId,
        standardRelation: standardRelation,
        displaySettings: displaySettingsForCollectionTable(initialDisplaySettings, displaySettings, isLoading),
    }); }, [
        viewId,
        collectionId,
        displaySettings,
        initialDisplaySettings,
        isLoading,
        referenceRecordId,
        schemaId,
        standardRelation,
    ]);
    var _c = useGetBoardViewGroupsData({
        body: requestBody,
        options: {
            enabled: !!viewId &&
                !!requestBody.displaySettings &&
                !!requestBody.displaySettings.groupSetting,
        },
    }), data = _c.data, isFetching = _c.isFetching, isFetched = _c.isFetched;
    useEffect(function () {
        if (data && !isFetching) {
            processBoardViewCollectionIntoState(data, reverseRelationId);
            setIsLoading(false);
            setColumns(data.orderedGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFetching]);
    var _d = useState([]), columns = _d[0], setColumns = _d[1];
    var collectionType = (_b = data === null || data === void 0 ? void 0 : data.collectionInfo.type) !== null && _b !== void 0 ? _b : CollectionType.USER;
    var onCreateNewRecordInColumn = useRecordComponent().onCreateNewRecordInColumn;
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var canAddNewRecord = useRecoilValue(collectionTableCanEditSelector({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var handleAddNewRecord = useCallback(function (newValue) {
        if (!boardViewGroupBySetting)
            return;
        var groupBySchemaId = boardViewGroupBySetting.schema;
        onCreateNewRecordInColumn('', collectionId, groupBySchemaId, newValue, referenceCollectionId, referenceRecordId, schemaId);
    }, [
        boardViewGroupBySetting,
        collectionId,
        onCreateNewRecordInColumn,
        referenceCollectionId,
        referenceRecordId,
        schemaId,
    ]);
    var handleUpdateGroupSetting = useCallback(function (newSetting) {
        applyDisplaySettingsToView(reverseRelationId, { groupSetting: newSetting }, true, false);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reverseRelationId]);
    var paginationBaseRequestBody = useMemo(function () { return ({
        recordId: referenceRecordId,
        collectionId: collectionId,
        schemaId: schemaId,
        standardRelation: standardRelation,
        displaySettings: displaySettings,
    }); }, [collectionId, displaySettings, referenceRecordId, schemaId, standardRelation]);
    var visibleSchemaIds = useRecoilValue(tableVisibleColumnIdsSelector({ tableId: reverseRelationId, collectionId: collectionId }));
    if (!data && isFetched) {
        return _jsx(CollectionTableErrorBoundary, { title: title });
    }
    return isLoading ? (_jsx(Skeleton, { active: true })) : (_jsx(CollectionBoardView, { collectionId: collectionId, collectionType: collectionType, columns: columns, setColumns: setColumns, groupBySetting: boardViewGroupBySetting, onUpdateGroupSetting: handleUpdateGroupSetting, onAddNewRecord: canAddNewRecord ? handleAddNewRecord : undefined, paginationBaseRequestBody: paginationBaseRequestBody, visibleSchemaIds: visibleSchemaIds }));
};
export default React.memo(ReverseCollectionBoardView);
