import { atom } from 'recoil';
import { DefaultSelfOnboardingCreateAccountInfo } from 'src/components/SelfOnboarding/Constant';
import { localStorageEffect } from './localStorageEffects';
var isSelfOnboardingOnState = atom({
    key: 'isSelfOnboardingOnState',
    default: false,
    effects: [localStorageEffect('isSelfOnboardingOnState')],
});
var selfOnboardingStepState = atom({
    key: 'selfOnboardingStepState',
    default: 0,
});
var selfOnboardingCreateAccountInfoState = atom({
    key: 'selfOnboardingCreateAccountInfoState',
    default: DefaultSelfOnboardingCreateAccountInfo,
});
export { isSelfOnboardingOnState, selfOnboardingStepState, selfOnboardingCreateAccountInfoState };
