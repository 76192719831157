import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from 'antd';
import React from 'react';
import { LoginWidgetLogo } from '../../utils/icons/LoginWidgetLogo';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import ResendEmailVerificationButton from './ResendEmailVerificationButton';
var EmailVerificationPage = function () {
    return (_jsxs("div", { className: "logo-container", children: [_jsx(LoginWidgetLogo, { className: "main-loading-logo" }), _jsxs(Space, { direction: "vertical", align: "center", children: [_jsx("div", { children: "Please verify your email to login" }), _jsx(ResendEmailVerificationButton, {}), _jsx(LoginButton, { text: "Login Again" }), _jsx(LogoutButton, {})] })] }));
};
export default React.memo(EmailVerificationPage);
