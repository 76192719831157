var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Breadcrumb, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import DepartmentConfigContent from 'src/components/PageView/ChatPage/DepartmentConfigContent/DepartmentConfigContent';
import ZiraActionButton from 'src/ui/ZiraActionButton';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { useSetting } from 'src/hooks/api';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { useChatComponent } from 'src/hooks/component/useChat';
import SettingsContentHeader from '../SettingsContentHeader';
import './ChatSettingPage.scss';
var ChatSettingPage = function () {
    var _a = useSetting(), useGetChatSmsSetting = _a.useGetChatSmsSetting, useUpdateChatSmsSetting = _a.useUpdateChatSmsSetting;
    var _b = useGetChatSmsSetting(), smsSetting = _b.data, smsSettingLoading = _b.isLoading;
    var _c = useState(false), smsEnabledStatus = _c[0], setSmsEnabledStatus = _c[1];
    useEffect(function () {
        if (smsSetting)
            setSmsEnabledStatus(smsSetting.isEnabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smsSetting]);
    var useGetAllDepartments = useChatApi().useGetAllDepartments;
    var saveDepartment = useChatComponent().saveDepartment;
    var _d = useGetAllDepartments(), initialDepartments = _d.data, departmentsInfoLoading = _d.isLoading, refetchAllDepartments = _d.refetch;
    var _e = useState([]), editingDepartments = _e[0], setEditingDepartments = _e[1];
    useEffect(function () {
        if (initialDepartments) {
            setEditingDepartments(initialDepartments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDepartments]);
    var onSaveButtonClick = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (initialDepartments) {
                saveDepartment(initialDepartments, __spreadArray([], editingDepartments, true)).then(function () {
                    return refetchAllDepartments();
                });
            }
            useUpdateChatSmsSetting.mutate({ status: smsEnabledStatus }, {
                onError: function () {
                    var _a;
                    setSmsEnabledStatus((_a = smsSetting === null || smsSetting === void 0 ? void 0 : smsSetting.isEnabled) !== null && _a !== void 0 ? _a : false);
                },
            });
            refetchAllDepartments();
            return [2 /*return*/];
        });
    }); }, [initialDepartments, editingDepartments, smsSetting, smsEnabledStatus]);
    return (_jsxs("div", { className: "ChatSettingContainer", children: [_jsx(SettingsContentHeader, { children: _jsx(Breadcrumb.Item, { children: "Team Chat" }) }), _jsx("div", { className: "SettingContentView", id: "SettingContentView", children: smsSettingLoading || departmentsInfoLoading ? (_jsx(ZiraRowsSkeleton, { rowNumber: 10 })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "max-[300px] flex w-4/5 items-center justify-between", children: [_jsx("div", { className: "text-2xl font-semibold", children: "Mobile SMS Fallback" }), _jsx(Switch, { className: "BlackSwitch", checked: smsEnabledStatus, onChange: function (status) { return setSmsEnabledStatus(status); } })] }), _jsx("div", { className: "mt-3 text-gray-400", children: "Have your users receive communication messages through text (SMS) if they do not have mobile app." }), _jsx(DepartmentConfigContent, { editingDepartmentsState: [editingDepartments, setEditingDepartments] })] })) }), _jsx("div", { className: "SettingContentFooter", children: _jsx(ZiraActionButton, { onClickFn: onSaveButtonClick, className: "SaveSettingBtn", children: "Save" }) })] }));
};
export default React.memo(ChatSettingPage);
