import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Skeleton, Spin } from 'antd';
import './NeedsCoverageLoadingBlockerModal.scss';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
var NeedsCoverageLoadingBlockerModal = function (props) {
    var type = props.type, item = props.item, range = props.range;
    var content = useMemo(function () {
        if (!item || !range) {
            return _jsx(Skeleton, {});
        }
        else {
            return (_jsx("div", { className: "flex flex-col gap-6", children: _jsxs("span", { className: "text-md font-medium", children: ["Your schedule will be editable when ", item.name, " ", type, "s are updated."] }) }));
        }
    }, [type, item, range]);
    // Note: getContainer + absolute positioning on wrap + mask (with position: relative in PageView.scss) is required to render the modal *only* over the schedule stuff
    // and not the entire screen
    return (_jsx(Modal, { getContainer: ".PageView", className: "NeedsCoverageBlockerModal", wrapClassName: "absolute", maskStyle: { position: 'absolute' }, centered: true, open: true, title: _jsxs("div", { className: "flex flex-row gap-3 items-center", children: [_jsx(Spin, { size: "small" }), _jsxs("span", { className: "text-lg font-semibold", children: ["Updating ", type ? "".concat(capitalize(type), "s") : ''] })] }), closable: false, footer: null, children: content }));
};
export default NeedsCoverageLoadingBlockerModal;
