export var FAKE_UUID_FOR_TOTALS = '00000000-0000-0000-0000-000000000000';
export var ReportSourceType;
(function (ReportSourceType) {
    ReportSourceType["SHIFT"] = "SHIFT";
    ReportSourceType["USER"] = "USER";
    ReportSourceType["LOCATION"] = "LOCATION";
    ReportSourceType["ROLE"] = "ROLE";
    ReportSourceType["PROJECT"] = "PROJECT";
})(ReportSourceType || (ReportSourceType = {}));
export var ReportChartType;
(function (ReportChartType) {
    ReportChartType["BAR"] = "BAR";
    ReportChartType["LINE"] = "LINE";
})(ReportChartType || (ReportChartType = {}));
export var ReportChartTypeDisplay;
(function (ReportChartTypeDisplay) {
    ReportChartTypeDisplay["BAR"] = "bar";
    ReportChartTypeDisplay["LINE"] = "line";
})(ReportChartTypeDisplay || (ReportChartTypeDisplay = {}));
export var TrendReportTimespan;
(function (TrendReportTimespan) {
    TrendReportTimespan["DAY"] = "DAY";
    TrendReportTimespan["WEEK"] = "WEEK";
    TrendReportTimespan["MONTH"] = "MONTH";
})(TrendReportTimespan || (TrendReportTimespan = {}));
export var TrendReportTimespanDisplayText;
(function (TrendReportTimespanDisplayText) {
    TrendReportTimespanDisplayText["DAY"] = "Daily";
    TrendReportTimespanDisplayText["WEEK"] = "Weekly";
    TrendReportTimespanDisplayText["MONTH"] = "Monthly";
})(TrendReportTimespanDisplayText || (TrendReportTimespanDisplayText = {}));
export var TrendReportDatePickerType;
(function (TrendReportDatePickerType) {
    TrendReportDatePickerType["DAY"] = "date";
    TrendReportDatePickerType["WEEK"] = "week";
    TrendReportDatePickerType["MONTH"] = "month";
})(TrendReportDatePickerType || (TrendReportDatePickerType = {}));
export var ReportMetricType;
(function (ReportMetricType) {
    ReportMetricType["COUNT"] = "COUNT";
    ReportMetricType["DURATION"] = "DURATION";
    ReportMetricType["CURRENCY"] = "CURRENCY";
    ReportMetricType["PERCENTAGE"] = "PERCENTAGE";
    ReportMetricType["FORMULA"] = "FORMULA";
})(ReportMetricType || (ReportMetricType = {}));
