import { atomFamily, selectorFamily } from 'recoil';
import { scheduleTimeZoneSelector } from 'src/state/selectors';
import { localStorageEffect } from '../localStorageEffects';
var blockTimeZoneState = atomFamily({
    key: 'blockTimeZoneState',
    default: selectorFamily({
        key: 'blockTimeZoneState/Default',
        get: function (blockId) {
            return function (_a) {
                var get = _a.get;
                return get(scheduleTimeZoneSelector(blockId));
            };
        },
    }),
    effects: function (blockId) { return [localStorageEffect("blockTimeZone:".concat(blockId))]; },
});
export { blockTimeZoneState };
