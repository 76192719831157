import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useAutomation } from 'src/hooks/api';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import AutomationTemplateDisplayCard from './AutomationTemplateDisplayCard';
var TabPane = Tabs.TabPane;
var AutomationTemplatesContent = function () {
    var _a;
    var useGetDefaultsList = useAutomation().useGetDefaultsList;
    var _b = useGetDefaultsList(), automationTemplateCategories = _b.data, isLoading = _b.isLoading;
    var _c = useState(''), selectedTemplateCategory = _c[0], setSelectedTemplateCategory = _c[1];
    var templateCardList = automationTemplateCategories === null || automationTemplateCategories === void 0 ? void 0 : automationTemplateCategories.defaultCategories.find(function (category) { return category.categoryTitle === selectedTemplateCategory; });
    useEffect(function () {
        if (automationTemplateCategories &&
            (automationTemplateCategories === null || automationTemplateCategories === void 0 ? void 0 : automationTemplateCategories.defaultCategories.length) !== 0 &&
            !selectedTemplateCategory) {
            setSelectedTemplateCategory(automationTemplateCategories === null || automationTemplateCategories === void 0 ? void 0 : automationTemplateCategories.defaultCategories[0].categoryTitle);
        }
    }, [automationTemplateCategories, selectedTemplateCategory]);
    if (isLoading) {
        return (_jsx("div", { className: "mt-3", children: _jsx(ZiraRowsSkeleton, { rowNumber: 5, height: 350 }) }));
    }
    var templateDisplayCards = (_a = templateCardList === null || templateCardList === void 0 ? void 0 : templateCardList.defaultCards) === null || _a === void 0 ? void 0 : _a.map(function (automation) { return (_jsx(AutomationTemplateDisplayCard, { automation: automation }, automation.id)); });
    var tabPanes = automationTemplateCategories === null || automationTemplateCategories === void 0 ? void 0 : automationTemplateCategories.defaultCategories.map(function (collection) { return (_jsx(TabPane, { tab: collection.categoryTitle }, collection.categoryTitle)); });
    return (_jsxs("div", { className: "AutomationTemplateList shadow-block", children: [_jsx(Tabs, { className: "TemplateSectionTabs", type: "card", onChange: function (activeKey) { return setSelectedTemplateCategory(activeKey); }, children: tabPanes }), _jsx("div", { className: "AutomationTemplateCardsContainer h-[350px] overflow-y-auto", children: templateDisplayCards })] }));
};
export default React.memo(AutomationTemplatesContent);
