var OrganizationUrls = [
    {
        key: 'openshift',
        url: 'client.openshift.app',
    },
    {
        key: 'careindeed',
        url: 'app.careindeed.com',
    },
];
export { OrganizationUrls };
