import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableSchemaRatingSchemaSettingSelector } from 'src/state';
import './RatingCell.scss';
import RatingSchemaCellContent from './RatingSchemaCellContent';
var RatingCell = function (_a) {
    var value = _a.value, cellPos = _a.cellPos, canEdit = _a.canEdit, editCell = _a.editCell, setIsClicked = _a.setIsClicked;
    var ratingSchemaConfig = useRecoilValue(collectionTableSchemaRatingSchemaSettingSelector(cellPos));
    if (!ratingSchemaConfig) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "RatingCellContainer", onClick: function () { return setIsClicked(true); }, children: _jsx(RatingSchemaCellContent, { iconType: ratingSchemaConfig.iconType, color: ratingSchemaConfig.color, currentValue: value <= ratingSchemaConfig.maxRating ? value : ratingSchemaConfig.maxRating, maxValueNumber: ratingSchemaConfig.maxRating, canEdit: canEdit, editCell: editCell }) }));
};
export default React.memo(RatingCell);
