var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Divider, Select } from 'antd';
import { produce } from 'immer';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SortSettingClause } from '../../../../shared';
import { disableScroll } from '../../../../utils/Collection';
import { NaviBarAddButton } from '../../../../utils/icons/NaviBarAddButton';
import { TableSortIcon } from '../../../../utils/icons/TableSortIcon';
import SortEntry from './SortEntry';
import './TableSort.scss';
var checkDuplicateSortColumn = function (sortEntries) {
    if (!sortEntries || sortEntries.length <= 1)
        return false;
    var sortColumnIds = sortEntries.map(function (entry) { return entry.property; });
    var dedupIds = _.uniqWith(sortColumnIds);
    return sortColumnIds.length !== dedupIds.length;
};
var TableSort = function (_a) {
    var sortBy = _a.sortBy, columns = _a.columns, applySort = _a.applySort, currentGroupBySchemaId = _a.currentGroupBySchemaId;
    var _b = useState(sortBy), sortEntries = _b[0], setSortEntries = _b[1];
    var _c = useState(false), dropdownOpen = _c[0], setDropdownOpen = _c[1];
    var _d = useState(), lock = _d[0], setLock = _d[1];
    var _e = useState(false), isOverlayVisible = _e[0], setIsOverlayVisible = _e[1];
    useEffect(function () {
        setSortEntries(sortBy);
    }, [sortBy]);
    var lockRef = useRef(lock);
    var onDeleteSortEntry = useCallback(function (index) {
        setSortEntries(produce(function (draft) {
            draft.splice(index, 1);
        }));
    }, []);
    var onEditSortEntry = useCallback(function (newSortSetting, index) {
        setSortEntries(function (prev) {
            if (prev.length === 0)
                return [newSortSetting];
            var tempSortEntries = __spreadArray([], prev, true);
            tempSortEntries[index] = newSortSetting;
            return tempSortEntries;
        });
    }, []);
    var onAddNewSortEntry = useCallback(function () {
        var newEntry = {
            id: uuidv4(),
            property: Object.keys(columns)[0],
            order: SortSettingClause.ASC,
        };
        setSortEntries(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newEntry], false); });
    }, [columns]);
    useEffect(function () {
        lockRef.current = lock;
    }, [lock]);
    var onDropdownVisibleChange = useCallback(function (open) {
        if (lockRef.current)
            return;
        if (checkDuplicateSortColumn(sortEntries))
            return;
        setDropdownOpen(open);
        disableScroll(open);
        if (!open) {
            applySort(sortEntries);
        }
    }, [applySort, sortEntries]);
    var dropDownRender = function () {
        return (_jsxs("div", { className: "TableSortContent", children: [_jsx("div", { className: "MainTitleWrapper", children: _jsx("span", { className: "MainTitle", children: " Sort " }) }), _jsx("span", { className: "SubTitleWrapper", children: "Choose which property to sort by." }), _jsx(Divider, { className: "Divider" }), checkDuplicateSortColumn(sortEntries) && (_jsx(Alert, { message: "Contains duplicate properties, please delete or re-edit.", type: "error" })), sortEntries.map(function (sort, index) { return (_jsx(SortEntry, { columns: columns, existingEntry: sort, lockClose: function () { return setLock(100); }, lockOpen: function () { return setLock(null); }, onDeleteEntry: function () { return onDeleteSortEntry(index); }, onEditEntry: function (newEntry) { return onEditSortEntry(newEntry, index); }, currentGroupBySchemaId: currentGroupBySchemaId }, sort.id)); }), sortEntries.length < 3 && (_jsxs(Button, { onClick: onAddNewSortEntry, className: "icon-text-btn", children: [_jsx(NaviBarAddButton, {}), _jsx("span", { className: "AddNewButtonText", children: "New Sort" })] }))] }));
    };
    var sortCount = sortBy.length;
    var getSortEntryName = useCallback(function () {
        var _a, _b, _c;
        var propertyId = (_a = sortBy[0]) === null || _a === void 0 ? void 0 : _a.property;
        if (!propertyId)
            return '';
        return (_c = (_b = columns[propertyId]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '';
    }, [columns, sortBy]);
    var activeSortTitle = (_jsxs("div", { className: "ActiveFilterSortTitle", children: [_jsx(TableSortIcon, {}), _jsx("span", { className: "OptionTitle", children: "Sort" }), sortCount !== 0 && (_jsx("span", { className: "OptionContent", children: sortCount >= 2 ? "".concat(sortCount, " fields") : getSortEntryName() }))] }));
    return (_jsxs(_Fragment, { children: [_jsx(Select, { open: dropdownOpen, onClick: function () { return setIsOverlayVisible(true); }, className: "TableSort OptionSelect", dropdownMatchSelectWidth: 450, popupClassName: "DropdownSelect", onDropdownVisibleChange: onDropdownVisibleChange, bordered: false, suffixIcon: null, placeholder: activeSortTitle, dropdownRender: dropDownRender }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setIsOverlayVisible(false);
                }, style: { display: isOverlayVisible ? 'block' : 'none' } })] }));
};
export default React.memo(TableSort);
