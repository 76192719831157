import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableSchemaButtonSchemaSettingSelector } from 'src/state';
import './ButtonCell.scss';
import { getButtonBorderColor } from 'src/utils/Collection';
var DEFAULT_BUTTON_COLOR = '#FFFFFF';
var ButtonCell = function (_a) {
    var _b;
    var cellPos = _a.cellPos, canEdit = _a.canEdit, onClickButton = _a.onClickButton;
    var buttonConfig = useRecoilValue(collectionTableSchemaButtonSchemaSettingSelector(cellPos));
    var name = buttonConfig === null || buttonConfig === void 0 ? void 0 : buttonConfig.title;
    var color = (_b = buttonConfig === null || buttonConfig === void 0 ? void 0 : buttonConfig.color) !== null && _b !== void 0 ? _b : DEFAULT_BUTTON_COLOR;
    var darkerColor = getButtonBorderColor(color);
    var clickButton = useCallback(function () {
        if (!canEdit)
            return;
        if (canEdit) {
            onClickButton === null || onClickButton === void 0 ? void 0 : onClickButton(cellPos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canEdit, cellPos]);
    if (!buttonConfig || !buttonConfig.title) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "ButtonCellWrapper", children: _jsx("div", { className: "ButtonCell ".concat(canEdit ? 'cursor-pointer' : 'cursor-not-allowed'), style: {
                borderColor: darkerColor,
            }, onClick: clickButton, children: _jsx("div", { className: "ButtonContent", style: {
                    backgroundColor: color,
                }, children: name }) }) }));
};
export default React.memo(ButtonCell);
