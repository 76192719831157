import { CollectionType, NativePath, ValueDataType } from 'src/shared';
// Filter out certain columns to avoid duplicate info in modal sidebar
export var showSchemaColumnInDetailModalSidebar = function (schema, collectionType) {
    if (schema.type === ValueDataType.BUTTON) {
        return false;
    }
    else if (schema.type === ValueDataType.NATIVE_NAME) {
        return false;
    }
    if (collectionType === CollectionType.USER) {
        if (schema.nativePath === NativePath.USER_LAST_ACTIVE ||
            schema.nativePath === NativePath.USER_ARCHIVED) {
            return false;
        }
    }
    else if (collectionType === CollectionType.SHIFT) {
        if (schema.nativePath === NativePath.SHIFT_USER_ID) {
            return false;
        }
    }
    else if (collectionType === CollectionType.DOCUMENT) {
        if (schema.nativePath === NativePath.DOCUMENT_STATUS ||
            schema.nativePath === NativePath.DOCUMENT_FILE) {
            return false;
        }
    }
    else if (collectionType === CollectionType.TASK) {
        if (schema.nativePath === NativePath.TASK_STATUS) {
            return false;
        }
    }
    return true;
};
