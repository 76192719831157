import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var parseText = function (text) {
    var urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    var parts = text.split(urlRegex);
    return parts;
};
var TextWithLinks = function (_a) {
    var text = _a.text;
    var segments = parseText(text);
    return (_jsx("div", { children: segments.map(function (segment, index) {
            if (/https?:\/\/[^\s]+|www\.[^\s]+/.test(segment)) {
                // Add the protocol to the URL if it's missing to prevent from localhost being relative path
                var href = segment.startsWith('www.') ? "http://".concat(segment) : segment;
                return (_jsx("a", { className: "underline text-blue-500 hover:text-blue-800", href: href, target: "_blank", rel: "noopener noreferrer", children: segment }, index));
            }
            return _jsx("span", { children: segment }, index);
        }) }));
};
export default React.memo(TextWithLinks);
