import { jsx as _jsx } from "react/jsx-runtime";
import { Popconfirm } from 'antd';
import React from 'react';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { usePageApi } from 'src/hooks/api';
import { AddLocationToUsersIcon } from 'src/utils/icons/images/DetailModal/AddLocationToUsersIcon';
var LocationDetailAddLocationActionButton = function (_a) {
    var recordId = _a.recordId;
    var addLocationToAllUsers = usePageApi().addLocationToAllUsers;
    return (_jsx(Popconfirm, { title: "Are you sure you want to add this location to all users?", okText: "Add", cancelText: "Cancel", placement: "bottomRight", trigger: "click", onConfirm: function () { return addLocationToAllUsers(recordId); }, children: _jsx(ZiraTooltip, { title: "Add Location to All Users", children: _jsx("div", { className: "ActionButton", children: _jsx(AddLocationToUsersIcon, {}) }) }) }));
};
export default React.memo(LocationDetailAddLocationActionButton);
