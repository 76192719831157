var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import Pluralize from 'pluralize';
import { Calendar, Menu, Checkbox } from 'antd';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import './PublishShiftDropdown.scss';
import { EmitterEventType } from 'src/shared';
import Emitter from 'src/utils/EventEmitter';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { scheduleTimeRangeState } from 'src/state/atoms/schedule';
import { tableCollectionIdSelector, tableFiltersSelector, scheduleTimeZoneSelector, } from 'src/state';
import ZiraDropdown from '../../ui/ZiraDropdown';
import { useScheduleApi } from '../../hooks/api';
import { disableScroll } from '../../utils/Collection';
import ZiraActionButton from '../../ui/ZiraActionButton';
import HelpActionWrapper from '../CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from '../CenterPanel/Header/HelpDrawer/Constant';
var PublishShiftDropdown = function (_a) {
    var tableId = _a.tableId;
    var _b = useScheduleApi(), usePublishShifts = _b.usePublishShifts, useGetUnpublishedShiftCountQuery = _b.useGetUnpublishedShiftCountQuery;
    var timeRange = useRecoilValue(scheduleTimeRangeState(tableId));
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(tableId));
    var _c = useState(moment(timeRange[0])), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(moment(timeRange[timeRange.length - 2])), endDate = _d[0], setEndDate = _d[1];
    var _e = useState(true), notifyEmployees = _e[0], setNotifyEmployees = _e[1];
    var _f = useState(false), sendEmail = _f[0], setSendEmail = _f[1];
    var _g = useState(false), sendSms = _g[0], setSendSms = _g[1];
    var _h = useState(false), dropdownOpen = _h[0], setDropdownOpen = _h[1];
    var publishShiftsMutation = usePublishShifts();
    var panelChangeRef = useRef();
    var filters = useRecoilValue(tableFiltersSelector(tableId)) || [];
    var _j = useGetUnpublishedShiftCountQuery({
        body: {
            blockId: tableId,
            startDate: startDate.startOf('day').format('YYYY-MM-DD'),
            endDate: (endDate || startDate).format('YYYY-MM-DD'),
            timeZone: timeZone,
            filters: filters,
        },
        options: {
            enabled: dropdownOpen,
            cacheTime: 0,
        },
    }).data, unpublishedCount = _j === void 0 ? 0 : _j;
    var calendarPanelOnChange = function (_, mode) {
        panelChangeRef.current = mode;
    };
    var calendarDataOnSelect = useCallback(function (e) {
        if (panelChangeRef.current !== undefined) {
            if (panelChangeRef.current === 'month') {
                panelChangeRef.current = undefined;
                return;
            }
            panelChangeRef.current = undefined;
        }
        if (!startDate && !endDate) {
            setStartDate(e);
        }
        else if (startDate && endDate) {
            setStartDate(e);
            setEndDate(undefined);
        }
        else {
            if (e.isAfter(startDate)) {
                setEndDate(e);
            }
            else if (e.isSameOrBefore(startDate)) {
                setStartDate(e);
                setEndDate(undefined);
            }
        }
    }, [endDate, startDate]);
    var calendarCellRender = function (date) {
        var isToday = date.toDate().toDateString() === new Date().toDateString();
        var isStartEnd = (startDate && moment(date).format('ll') === moment(startDate).format('ll')) ||
            (endDate && moment(date).format('ll') === moment(endDate).format('ll'));
        var isInBetween = endDate && date.isBetween(startDate, endDate, undefined, '[]');
        var className = 'CalendarCell ' +
            (isStartEnd ? 'CalendarStartEndCell' : '') +
            ' ' +
            (isInBetween ? 'CalendarInBetweenCell' : '') +
            ' ' +
            (isToday ? 'CalendarTodayCell' : '');
        return _jsxs("div", { className: className, children: [" ", date.date()] });
    };
    var publishShiftOnClick = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var filters;
            return __generator(this, function (_a) {
                if (!unpublishedCount)
                    return [2 /*return*/];
                if (!startDate)
                    return [2 /*return*/];
                setDropdownOpen(false);
                filters = snapshot.getLoadable(tableFiltersSelector(tableId)).valueMaybe();
                if (!filters)
                    return [2 /*return*/];
                publishShiftsMutation.mutateAsync({
                    blockId: tableId,
                    startDate: startDate.startOf('day').format('YYYY-MM-DD'),
                    endDate: (endDate || startDate).format('YYYY-MM-DD'),
                    timeZone: timeZone,
                    notifyEmployees: notifyEmployees,
                    sendEmail: sendEmail,
                    sendSms: sendSms,
                    filters: filters,
                }, {
                    onSuccess: function () {
                        var shiftCollectionId = snapshot
                            .getLoadable(tableCollectionIdSelector(tableId))
                            .valueMaybe();
                        Emitter.emit(EmitterEventType.REFRESH_SCHEDULE, shiftCollectionId);
                    },
                    onError: function () { },
                });
                setDropdownOpen(false);
                disableScroll(false);
                return [2 /*return*/];
            });
        }); };
    }, [
        tableId,
        endDate,
        publishShiftsMutation,
        notifyEmployees,
        sendEmail,
        sendSms,
        startDate,
        timeZone,
        unpublishedCount,
    ]);
    var menu = (_jsxs(Menu, { className: "PublishShiftDropdownMenu", children: [_jsx(Calendar, { className: "CalendarCard", fullscreen: false, onSelect: calendarDataOnSelect, onPanelChange: calendarPanelOnChange, dateFullCellRender: calendarCellRender, defaultValue: moment(timeRange[0]) }), _jsx(Menu.Divider, {}), _jsxs("div", { className: "NotifyOption", children: [_jsx("div", { children: "Notify By Push Notification" }), _jsx(Checkbox, { value: notifyEmployees, onChange: function (e) { return setNotifyEmployees(e.target.checked); }, defaultChecked: true })] }), _jsx(Menu.Divider, {}), _jsxs("div", { className: "NotifyOption", children: [_jsx("div", { children: "Notify By Email" }), _jsx(Checkbox, { value: sendEmail, onChange: function (e) { return setSendEmail(e.target.checked); }, defaultChecked: false })] }), _jsx(Menu.Divider, {}), _jsxs("div", { className: "NotifyOption", children: [_jsx("div", { children: "Notify By SMS" }), _jsx(Checkbox, { value: sendSms, onChange: function (e) { return setSendSms(e.target.checked); }, defaultChecked: false })] }), _jsx(Menu.Divider, {}), _jsx("div", { className: "PublishShiftOption", children: _jsxs(ZiraActionButton, { disabled: !unpublishedCount, className: "PublishShiftBtn", onClickFn: publishShiftOnClick, children: ["Publish ", unpublishedCount, " ", Pluralize('Shift', unpublishedCount)] }) }), _jsx("span", { onClick: function () { return setDropdownOpen(false); }, children: _jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.PUBLISH_SHIFT], children: _jsxs("div", { className: "flex items-center bg-gray-50 px-5 py-2", children: [_jsx(HelpIcon, { className: "mr-1 h-[16px] w-[16px] text-gray-400" }), _jsx("div", { className: "text-xs font-medium text-gray-400", children: "Learn more about publish shifts." })] }) }) })] }));
    return (_jsx(ZiraDropdown, { overlayClassName: "PublishShiftDropdown", overlay: menu, trigger: ['click'], visible: dropdownOpen, onLocalVisibleChange: function (visible) {
            setStartDate(moment(timeRange[0]));
            setEndDate(moment(timeRange[timeRange.length - 2]));
            setDropdownOpen(visible);
        }, placement: "bottomRight", children: _jsx("div", { className: "PublishDropdownBtn", children: "Publish All" }) }));
};
export default React.memo(PublishShiftDropdown);
