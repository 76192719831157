var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useChatComponent } from 'src/hooks/component/useChat';
import { currentUserState } from 'src/state';
import { selectedThreadState, participantsInNewThread, departmentsState, } from 'src/state/atoms/chatState';
import { isCreatingThreadSelector } from 'src/state/selectors/withChatState';
import { MessageSenderType, ThreadType } from '../../interface';
import AddNewMessageSection from '../MessagesScreen/AddNewMessageSection';
var NewThreadFooter = function () {
    var recordId = new URLSearchParams(location.search).get('rid');
    var isChatInRecordModal = !!recordId;
    var _a = useRecoilState(participantsInNewThread), userInThread = _a[0], setUserInThread = _a[1];
    var isBroadcast = useMemo(function () {
        return (userInThread === null || userInThread === void 0 ? void 0 : userInThread.type) === ThreadType.BROADCAST;
    }, [userInThread]);
    var user = useRecoilValue(currentUserState);
    var setSelectedThread = useSetRecoilState(selectedThreadState);
    var setIsCreatingThread = useSetRecoilState(isCreatingThreadSelector);
    var departmentCanSendAs = useRecoilValue(departmentsState);
    var _b = useChatComponent(), createThread = _b.createThread, sendMessage = _b.sendMessage, getThreadByParticipant = _b.getThreadByParticipant;
    var onMessageSentSuccess = function (data) {
        if (data) {
            setSelectedThread(data.threadId);
            if (!isChatInRecordModal) {
                setUserInThread(undefined);
                setIsCreatingThread(undefined);
            }
        }
    };
    var onSendMessage = function (message, department, attachments) {
        if (!userInThread || userInThread.participants.length === 0) {
            return;
        }
        else {
            var findThread = getThreadByParticipant(userInThread);
            if (findThread) {
                sendMessage(message, findThread.id, userInThread.sender, attachments, onMessageSentSuccess);
            }
            else {
                createThread(userInThread.participants, message, userInThread.sender, onMessageSentSuccess, attachments);
            }
        }
    };
    var senderOptions = useMemo(function () {
        return __spreadArray(__spreadArray([], (isBroadcast
            ? []
            : [
                {
                    id: user === null || user === void 0 ? void 0 : user.recordId,
                    name: user === null || user === void 0 ? void 0 : user.fullName,
                    type: MessageSenderType.USER,
                },
            ]), true), (departmentCanSendAs
            ? departmentCanSendAs.map(function (department) {
                return {
                    id: department.id,
                    name: department.title,
                    type: MessageSenderType.DEPARTMENT,
                };
            })
            : []), true);
    }, [departmentCanSendAs, isBroadcast, user]);
    return (_jsx("div", { className: "ChatFooter NewThreadFooter", children: _jsx(AddNewMessageSection, { senderOptions: senderOptions, onSendMessage: onSendMessage }) }));
};
export default NewThreadFooter;
