import { SchemaConfigurationType } from 'src/shared';
import { GenerativeType } from '../interface/constants';
import { GeneratedIdType } from '../interface/interface';
export function getDisplayValue(cellData, timeFormatFn) {
    if ('text' in cellData)
        return cellData.text.value || 'N/A';
    if ('title' in cellData)
        return cellData.title.value || 'N/A';
    if ('address' in cellData)
        return cellData.address || 'N/A';
    if ('amount' in cellData)
        return cellData.amount.value || 'N/A';
    if ('phone' in cellData)
        return cellData.phone || 'N/A';
    if ('rollup' in cellData)
        return cellData.rollup.value || 'N/A';
    if ('signature' in cellData)
        return cellData.signature || 'N/A';
    if ('formula' in cellData)
        return cellData.formula || 'N/A';
    if ('button' in cellData)
        return cellData.button.value || 'N/A';
    if ('time' in cellData) {
        if (!cellData.time || !cellData.time.length)
            return 'N/A';
        var format = timeFormatFn(cellData.time[0], 'MM/DD/YYYY h:mm A', false);
        return format ? format : 'N/A';
    }
    if ('selections' in cellData) {
        return cellData.selections.map(function (selection) { return selection.label; }).join(' ');
    }
    if ('users' in cellData) {
        return cellData.users.map(function (user) { return user.name; }).join(' ');
    }
    if ('files' in cellData) {
        return cellData.files.data.map(function (file) { return file.name; }).join(' ');
    }
    return 'N/A';
}
export function insertAlphaNumericPlaceholders(format, generatedIdType) {
    if (generatedIdType == GeneratedIdType.INCREMENTAL) {
        var finalNumberIndex = format.lastIndexOf('#');
        if (finalNumberIndex === -1)
            return format;
        var result = format.substring(0, finalNumberIndex) + '1' + format.substring(finalNumberIndex + 1);
        return result.replaceAll('#', '0');
    }
    if (generatedIdType == GeneratedIdType.RANDOM) {
        return format.replaceAll('*', 'A').replaceAll('#', '0');
    }
    return format;
}
export function getSchemaConfigurationFromGenerativeConfiguration(configuration) {
    if (configuration.type === GenerativeType.GENERATED_ID) {
        return {
            type: SchemaConfigurationType.GENERATED_ID,
            format: configuration.format,
            generatedIdType: configuration.generatedIdType,
            requireUnique: !!configuration.requireUnique,
        };
    }
    throw new Error('Not implemented');
}
export function getGenerativeConfigurationFromSchemaConfiguration(configuration) {
    if (configuration.type === SchemaConfigurationType.GENERATED_ID) {
        return {
            type: GenerativeType.GENERATED_ID,
            format: configuration.format,
            generatedIdType: configuration.generatedIdType,
            requireUnique: configuration.requireUnique,
        };
    }
    return undefined;
}
