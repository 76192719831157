import moment from 'moment';
import Pluralize from 'pluralize';
import { RuleType, ShiftStatus, ShiftStatusCardColorMap, } from 'src/components/ScheduleView/Constant';
import { ruleTypeSortOrder } from 'src/components/ScheduleView/RulesDrawer';
import { CollectionType, NativePath, ScheduleGroupBy } from 'src/shared';
import { getWeekDay } from 'src/utils/Account';
import { getShiftStatus } from 'src/utils/Schedule';
export var schedulePageSize = 20;
export var ScheduleView;
(function (ScheduleView) {
    ScheduleView["DAY"] = "DAY";
    ScheduleView["WEEK"] = "WEEK";
    ScheduleView["TWO_WEEK"] = "TWO_WEEK";
    ScheduleView["FOUR_WEEK"] = "FOUR_WEEK";
    ScheduleView["MONTH"] = "MONTH";
})(ScheduleView || (ScheduleView = {}));
export var ScheduleCardHeight;
(function (ScheduleCardHeight) {
    ScheduleCardHeight["DEFAULT"] = "DEFAULT";
    ScheduleCardHeight["COMFORTABLE"] = "COMFORTABLE";
    ScheduleCardHeight["COMPACT"] = "COMPACT";
})(ScheduleCardHeight || (ScheduleCardHeight = {}));
export var initCurrentStartDay = function (startDay, viewType, currentDay // when switch day view to other week view, using this value to cal start day
) {
    var today = moment().clone().startOf('day');
    var day = currentDay ? currentDay.clone().startOf('day') : today;
    // in day view ignore start of week logic
    if (viewType === ScheduleView.DAY) {
        return day;
    }
    for (var i = -6; i <= 0; i++) {
        var candidate = day.clone().add(i, 'days');
        if (getWeekDay(candidate.day()) === startDay) {
            return candidate.startOf('day');
        }
    }
    return day;
};
export var scheduleViewToNumber = function (scheduleView) {
    switch (scheduleView) {
        case ScheduleView.WEEK:
            return 1;
        case ScheduleView.TWO_WEEK:
            return 2;
        case ScheduleView.FOUR_WEEK:
            return 4;
        default:
            return 0;
    }
};
export var scheduleViewDisplayString = function (scheduleView) {
    switch (scheduleView) {
        case ScheduleView.DAY:
            return 'Day';
        case ScheduleView.WEEK:
            return 'Week';
        case ScheduleView.TWO_WEEK:
            return '2 Weeks';
        case ScheduleView.FOUR_WEEK:
            return '4 Weeks';
        case ScheduleView.MONTH:
            return 'Month';
    }
};
export var brokenRulesTypeEvaluation = function (indictmentNames, autoSchedulerSetting) {
    var a = RuleType.MILD;
    for (var _i = 0, indictmentNames_1 = indictmentNames; _i < indictmentNames_1.length; _i++) {
        var name_1 = indictmentNames_1[_i];
        var rule = autoSchedulerSetting.rulesMap[name_1];
        if ((rule === null || rule === void 0 ? void 0 : rule.type) &&
            ruleTypeSortOrder.indexOf(rule === null || rule === void 0 ? void 0 : rule.type) - ruleTypeSortOrder.indexOf(a) < 0) {
            a = rule === null || rule === void 0 ? void 0 : rule.type;
        }
    }
    return a;
};
export var getCardColor = function (content, colorCodingSchemaId, schemaMap) {
    var _a, _b, _c, _d, _e, _f;
    if (colorCodingSchemaId == 'status') {
        var shiftStatus = !content.published
            ? ShiftStatus.DRAFT
            : getShiftStatus(content.startAt, content.endAt, content.clockIn, content.clockOut, content.isAssigned);
        return ShiftStatusCardColorMap[shiftStatus] || '#F4F4F7';
    }
    var schema = schemaMap[colorCodingSchemaId];
    if ((schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_LOCATION_ID) {
        return ((_a = content.location) === null || _a === void 0 ? void 0 : _a.color) || '#F4F4F7';
    }
    else if ((schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_ROLE_IDS) {
        return ((_c = (_b = content.roles) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.color) || '#F4F4F7';
    }
    else if ((schema === null || schema === void 0 ? void 0 : schema.nativePath) === NativePath.SHIFT_TEMPLATE_ID) {
        return ((_d = content.template) === null || _d === void 0 ? void 0 : _d.color) || '#F4F4F7';
    }
    else if (content.customSelects) {
        return ((_f = (_e = content.customSelects[colorCodingSchemaId]) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.color) || '#F4F4F7';
    }
    return '#F4F4F7';
};
export var getCardHeight = function (cardDensity) {
    switch (cardDensity) {
        case ScheduleCardHeight.COMPACT:
            return 74;
        case ScheduleCardHeight.COMFORTABLE:
            return 192;
        default:
            return 144;
    }
};
var findCollectionNameByType = function (type, collectionList) {
    var targetCollection = collectionList.find(function (collection) { return collection.type === type; });
    return targetCollection ? Pluralize.singular(targetCollection.name) : '';
};
export var getGroupByCollectionName = function (option, collectionList) {
    switch (option) {
        case ScheduleGroupBy.USER:
            return findCollectionNameByType(CollectionType.USER, collectionList);
        case ScheduleGroupBy.LOCATION:
            return findCollectionNameByType(CollectionType.LOCATION, collectionList);
        case ScheduleGroupBy.ROLE:
            return findCollectionNameByType(CollectionType.ROLE, collectionList);
        case ScheduleGroupBy.TEMPLATE:
            return 'Template';
        default:
            return '';
    }
};
