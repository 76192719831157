import moment from 'moment-timezone';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { NativePath, ValueDataType, CollectionType } from 'src/shared';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, collectionTableSchemasState, } from 'src/state';
import { getCurrencyValue } from 'src/utils/Collection';
import { usePageApi } from '../../api';
import { useRecordComponent } from '../useRecord';
var usePlacementModal = function (_a) {
    var _b, _c, _d, _e;
    var recordId = _a.recordId, dataSource = _a.dataSource, collectionInfo = _a.collectionInfo, isCreatingNew = _a.isCreatingNew;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var schemaList = useRecoilValue(collectionTableSchemasState(dataSource.collectionId));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var editRecordCell = useRecordComponent().editRecordCell;
    var useGetJobPrefillDetail = usePageApi().useGetJobPrefillDetail;
    var jobRid = ((_b = nativeSchemaMap[NativePath.PLACEMENT_JOB_RID]) === null || _b === void 0 ? void 0 : _b.id) &&
        ((_e = (_d = modalRecord[(_c = nativeSchemaMap[NativePath.PLACEMENT_JOB_RID]) === null || _c === void 0 ? void 0 : _c.id]) === null || _d === void 0 ? void 0 : _d.options[0]) === null || _e === void 0 ? void 0 : _e.id);
    useGetJobPrefillDetail({
        body: {
            jobRid: jobRid !== null && jobRid !== void 0 ? jobRid : '',
        },
        options: {
            enabled: isCreatingNew && collectionInfo.type === CollectionType.PLACEMENT && !!jobRid,
            onSuccess: function (prefillDetails) {
                if (prefillDetails === null || prefillDetails === void 0 ? void 0 : prefillDetails.columns) {
                    applyJobPrefillToRecordDetail(Object.values(prefillDetails.columns)[0]);
                }
            },
        },
    });
    var applyJobPrefillToRecordDetail = useCallback(function (prefillDetails) {
        var _a, _b, _c, _d, _e;
        var startDateSchemaId = (_a = schemaList.find(function (schema) { return schema.nativePath == NativePath.PLACEMENT_START_DATE; })) === null || _a === void 0 ? void 0 : _a.id;
        var endDateSchemaId = (_b = schemaList.find(function (schema) { return schema.nativePath == NativePath.PLACEMENT_END_DATE; })) === null || _b === void 0 ? void 0 : _b.id;
        if (!startDateSchemaId || !endDateSchemaId)
            return;
        var newStartAt = (_c = prefillDetails[startDateSchemaId]) === null || _c === void 0 ? void 0 : _c.content;
        var newEndAt = (_d = prefillDetails[endDateSchemaId]) === null || _d === void 0 ? void 0 : _d.content;
        if (newStartAt) {
            // Add prefill start time to current start date
            var newStart = moment(newStartAt).toISOString();
            // Apply prefill start/end time
            editRecordCell(dataSource.type, {
                collectionId: collectionInfo.id,
                recordId: recordId,
                schemaId: startDateSchemaId,
            }, [newStart]);
        }
        if (newEndAt) {
            // Add prefill end time to current end date
            var newEnd = moment(moment(newEndAt))
                .add(moment(newStartAt).isAfter(moment(newEndAt)) ? 1 : 0, 'day')
                .toISOString();
            // Apply prefill start/end time
            editRecordCell(dataSource.type, {
                collectionId: collectionInfo.id,
                recordId: recordId,
                schemaId: endDateSchemaId,
            }, [newEnd]);
        }
        var _loop_1 = function (schemaId, newCell) {
            if (![startDateSchemaId, endDateSchemaId].includes(schemaId)) {
                var isCurrency = ((_e = schemaList.find(function (schema) { return schema.id === schemaId; })) === null || _e === void 0 ? void 0 : _e.type) ===
                    ValueDataType.NATIVE_CURRENCY;
                editRecordCell(dataSource.type, {
                    collectionId: collectionInfo.id,
                    recordId: recordId,
                    schemaId: schemaId,
                }, newCell.content
                    ? [
                        isCurrency && newCell.content
                            ? getCurrencyValue(newCell.content)
                            : newCell.content,
                    ]
                    : newCell.options.length
                        ? newCell.options
                        : newCell.options);
            }
        };
        // Apply all other template values
        for (var _i = 0, _f = Object.entries(prefillDetails); _i < _f.length; _i++) {
            var _g = _f[_i], schemaId = _g[0], newCell = _g[1];
            _loop_1(schemaId, newCell);
        }
    }, [collectionInfo.id, dataSource.type, editRecordCell, recordId, schemaList]);
};
export { usePlacementModal as usePlacementModalComponent };
