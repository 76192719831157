var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFlexLayout, useResizeColumns, useTable, useFilters, useGlobalFilter, } from 'react-table';
import { useSetRecoilState } from 'recoil';
import './DefaultRateTable.scss';
import { Button } from 'antd';
import PayItemTableSearch from '../../../PayItemTableSearch';
import { isConfigDefaultRateModalOpenState, isCreatingDefaultRateState, isDateRangePropagationModalOpenState, } from '../../../atoms';
var globalFilterFunction = function (rows, columnIds, filterValue) {
    if (!filterValue)
        return rows;
    return rows.filter(function (row) {
        var _a;
        for (var _i = 0, columnIds_1 = columnIds; _i < columnIds_1.length; _i++) {
            var columnId = columnIds_1[_i];
            var cell = (_a = row.values) === null || _a === void 0 ? void 0 : _a[columnId];
            var cellNameValue = '';
            var cellRateValue = '';
            // For adjustment(rate number) cell
            if (cell && 'field' in cell) {
                cellRateValue = cell.value.toString();
            }
            // For info(name) cell
            else if (cell && 'name' in cell) {
                cellNameValue = cell.name;
            }
            if ((cellNameValue &&
                cellNameValue
                    .trim()
                    .toLowerCase()
                    .includes(filterValue.trim().toLowerCase())) ||
                (cellRateValue === null || cellRateValue === void 0 ? void 0 : cellRateValue.includes(filterValue))) {
                return true;
            }
        }
        return false;
    });
};
var DefaultRateTableBuilder = function (_a) {
    var columns = _a.columns, rates = _a.rates, canEdit = _a.canEdit, editDefaultRateModal = _a.editDefaultRateModal, openImportModal = _a.openImportModal;
    var setIsDateRangePropagationModalOpen = useSetRecoilState(isDateRangePropagationModalOpenState);
    var setIsConfigModalOpen = useSetRecoilState(isConfigDefaultRateModalOpenState);
    var setIsCreatingRate = useSetRecoilState(isCreatingDefaultRateState);
    var _b = useTable({
        columns: columns,
        data: rates,
        globalFilter: globalFilterFunction,
        autoResetGlobalFilter: false,
    }, useResizeColumns, useFlexLayout, useFilters, useGlobalFilter), getTableProps = _b.getTableProps, getTableBodyProps = _b.getTableBodyProps, headerGroups = _b.headerGroups, rows = _b.rows, prepareRow = _b.prepareRow, globalFilter = _b.state.globalFilter, setGlobalFilter = _b.setGlobalFilter;
    return (_jsxs("div", { className: "DefaultRateTable ", children: [_jsxs("div", { className: "flex justify-between items-center mb-8", children: [_jsx(PayItemTableSearch, { placeholder: "Search default rate here", globalFilter: globalFilter, setGlobalFilter: setGlobalFilter }), canEdit && (_jsxs("div", { className: "flex gap-2", children: [_jsx("button", { onClick: openImportModal, className: "font-medium cursor-pointer p-2 rounded-full text-white bg-black w-[90px] text-center", children: "Import" }), _jsx(Button, { onClick: function () {
                                    setIsDateRangePropagationModalOpen(true);
                                }, className: "rounded-full font-medium text-white bg-black p-2 text-center h-auto", children: "Rematch" }), _jsx("div", { className: "font-medium cursor-pointer p-2 rounded-full text-white bg-black w-[150px] text-center", onClick: function () {
                                    setIsConfigModalOpen(true);
                                    setIsCreatingRate(true);
                                }, children: "Add Default Rate" })] }))] }), _jsxs("table", __assign({}, getTableProps(), { className: "table", children: [_jsx("thead", { className: "thead", children: headerGroups.map(function (headerGroup) { return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps(), { key: headerGroup.id, className: "tr" }), headerGroup.headers.map(function (column, index) {
                            return (_createElement("th", __assign({}, column.getHeaderProps(), { className: "th ".concat(column.isResizing ? 'isResizing' : ''), key: index }),
                                _jsx("span", { children: column.render('Header') }),
                                index !== headerGroup.headers.length - 1 && (_jsx("div", __assign({}, column.getResizerProps(), { className: "resizer ".concat(column.isResizing ? 'isResizing' : '') })))));
                        }))); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { className: "gap-3", children: rows.map(function (row) {
                            prepareRow(row);
                            return (_createElement("tr", __assign({}, row.getRowProps(), { key: row.id, className: "tr", onClick: function () {
                                    editDefaultRateModal(row.original);
                                } }), row.cells.map(function (cell, columnIndex) {
                                var left = columnIndex === 0 && 'rounded-l-lg border-r-0';
                                var center = columnIndex !== 0 &&
                                    columnIndex !== row.cells.length - 1 &&
                                    'border-r-0';
                                var right = columnIndex === row.cells.length - 1 &&
                                    'rounded-r-lg border-r';
                                return (_createElement("td", __assign({}, cell.getCellProps(), { key: columnIndex, className: "table-cell text-left align-top border border-gray-100 ".concat(left, " ").concat(center, " ").concat(right, " px-4 py-2") }), cell.render('Cell')));
                            })));
                        }) }))] }))] }));
};
export default DefaultRateTableBuilder;
