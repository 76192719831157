import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Progress } from 'antd';
import { getStrokeColorByNumber } from '../DashboardUtils';
import DashboardOnboardingModal from './DashboardOnboardingModal';
import OnboardingCheckListItem from './OnboardingCheckListItem';
var OnboardingCheckList = function (_a) {
    var list = _a.list, progressPercentageNumber = _a.progressPercentageNumber, refetch = _a.refetch;
    var _b = useState(false), isModalVisible = _b[0], setIsModalVisible = _b[1];
    var _c = useState(), selectedItem = _c[0], setSelectedItem = _c[1];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-3 text-xl font-medium", children: "Get Started with Your Account" }), _jsx("div", { className: "flex justify-center", children: _jsx(Progress, { type: "circle", percent: progressPercentageNumber, strokeColor: getStrokeColorByNumber(progressPercentageNumber), strokeWidth: 8, width: 150 }) }), _jsx("div", { className: "mb-3 mt-5", children: list.map(function (item, index) { return (_jsx(OnboardingCheckListItem, { openModal: function () { return setIsModalVisible(true); }, itemInfo: item, isLastItem: index === list.length - 1, setSelectedItem: function () { return setSelectedItem(item); } }, "".concat(item.type, "+").concat(item.title))); }) }), selectedItem && (_jsx(DashboardOnboardingModal, { isVisible: isModalVisible, closeModal: function () {
                    setIsModalVisible(false);
                }, itemInfo: selectedItem, refetch: refetch }))] }));
};
export default React.memo(OnboardingCheckList);
