var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { CollectionTableType } from 'src/shared';
import { collectionTableNonSchemaMutationsState, collectionTableSchemaMutationsState, collectionTableSchemasState, tableVisibleColumnIdsSelector, } from 'src/state';
import { usePageApi } from 'src/hooks/api';
import { useRecordComponent } from 'src/hooks/component';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import SVG from 'react-inlinesvg';
import { getColumnTypeKey } from 'src/utils/Collection';
import CellHOC from '../../Cell/CellHOC';
import PlainHeaderDisplay from '../../Column/PlainHeaderDisplay';
import { columnIcon } from '../../Column/TableHeaderDropdown';
import { BULK_EDIT_ICON } from '../../Constants';
var BulkEditColumnButton = function (_a) {
    var blockId = _a.blockId, reverseRelationId = _a.reverseRelationId, collectionId = _a.collectionId, selectedRecordIds = _a.selectedRecordIds;
    var numberOfSelectedRecords = selectedRecordIds.length;
    var schemaList = useRecoilValue(collectionTableSchemasState(collectionId));
    var blockVisibleSchemaIds = useRecoilValue(tableVisibleColumnIdsSelector({ tableId: blockId !== null && blockId !== void 0 ? blockId : '', collectionId: collectionId }));
    var reverseCollectionVisibleSchemaIds = useRecoilValue(tableVisibleColumnIdsSelector({
        tableId: reverseRelationId !== null && reverseRelationId !== void 0 ? reverseRelationId : '',
        collectionId: collectionId,
    }));
    var visibleSchemaIds = useMemo(function () { return (blockId ? blockVisibleSchemaIds : reverseCollectionVisibleSchemaIds); }, [blockId, blockVisibleSchemaIds, reverseCollectionVisibleSchemaIds]);
    var editableSchemas = useMemo(function () {
        return schemaList
            .filter(function (schema) {
            return schema.canEdit;
        })
            .sort(function (a, b) {
            var aIndex = visibleSchemaIds.indexOf(a.id);
            var bIndex = visibleSchemaIds.indexOf(b.id);
            if (aIndex === -1)
                return 1;
            if (bIndex === -1)
                return -1;
            return aIndex - bIndex;
        });
    }, [schemaList, visibleSchemaIds]);
    var _b = useState(false), isClicked = _b[0], setIsClicked = _b[1];
    var _c = useState(false), isDropdownOpen = _c[0], setIsDropdownOpen = _c[1];
    var _d = useState(), selectedSchema = _d[0], setSelectedSchema = _d[1];
    var schemaMutations = useRecoilValue(collectionTableSchemaMutationsState({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var nonSchemaMutations = useRecoilValue(collectionTableNonSchemaMutationsState({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var isBulkEditing = useMemo(function () { return (schemaMutations.length > 0 || nonSchemaMutations.length > 0) && !isDropdownOpen; }, [isDropdownOpen, nonSchemaMutations.length, schemaMutations.length]);
    useEffect(function () {
        setIsDropdownOpen(isClicked);
    }, [isClicked]);
    var closeDropdown = useCallback(function () {
        setIsClicked(false);
        setIsDropdownOpen(false);
        setSelectedSchema(undefined);
    }, []);
    var editRecordCell = useRecordComponent().editRecordCell;
    var useClickButtonCell = usePageApi().useClickButtonCell;
    var refetchCollection = useRefetchCollection();
    var bulkEditRecords = useCallback(function (cellPos, newValues) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            closeDropdown();
            editRecordCell(CollectionTableType.TABLE, cellPos, newValues, undefined, undefined, selectedRecordIds);
            return [2 /*return*/];
        });
    }); }, [closeDropdown, editRecordCell, selectedRecordIds]);
    var openBulkEditConfirmation = useCallback(function (cellPos, newValues) {
        setIsClicked(false);
        var recordText = numberOfSelectedRecords === 1 ? 'record' : 'records';
        Modal.confirm({
            className: 'PopoverConfirm BulkEditConfirm',
            title: (_jsxs("div", { className: "font-semibold", children: ["Edit \"", selectedSchema.name, "\" of ", numberOfSelectedRecords, ' ', "selected ", recordText] })),
            content: (_jsxs("div", { children: ["Are you sure you want to edit ", numberOfSelectedRecords, " ", recordText, "?"] })),
            okText: "Edit ".concat(numberOfSelectedRecords, " ").concat(recordText),
            centered: true,
            maskClosable: true,
            okType: 'primary',
            onOk: function () { return bulkEditRecords(cellPos, newValues); },
            zIndex: 2000,
        });
    }, [bulkEditRecords, numberOfSelectedRecords, selectedSchema]);
    var onBulkClickButtonCell = useCallback(function (cellPos) {
        var request = {
            recordIds: selectedRecordIds,
            schemaId: cellPos.schemaId,
            collectionId: cellPos.collectionId,
        };
        useClickButtonCell.mutate(request, {
            onSuccess: function (resp) {
                if (resp) {
                    var collectionId_1 = cellPos.collectionId;
                    setTimeout(function () {
                        refetchCollection(collectionId_1);
                    }, 1000);
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRecordIds]);
    var openBulkButtonClickActionConfirmation = useCallback(function (cellPos) {
        setIsClicked(false);
        var recordText = numberOfSelectedRecords === 1 ? 'record' : 'records';
        Modal.confirm({
            className: 'PopoverConfirm BulkEditConfirm',
            title: (_jsxs("div", { className: "font-semibold", children: ["Trigger \"", selectedSchema.name, "\" action for", ' ', numberOfSelectedRecords, " selected ", recordText] })),
            content: (_jsxs("div", { children: ["Are you sure you want to trigger button action for ", numberOfSelectedRecords, ' ', recordText, "?"] })),
            okText: "Confirm",
            centered: true,
            maskClosable: true,
            okType: 'primary',
            onOk: function () { return onBulkClickButtonCell(cellPos); },
            zIndex: 2000,
        });
    }, [numberOfSelectedRecords, onBulkClickButtonCell, selectedSchema]);
    var renderOptionDropdownMenu = function () {
        return (_jsx("div", { className: "flex flex-col w-[240px] rounded-xl bg-white shadow-2xl max-h-[60vh] overflow-y-auto", children: editableSchemas.map(function (schema, index) {
                return (_jsxs(_Fragment, { children: [_jsx("div", { className: "py-2 px-[16px] text-sm font-medium cursor-pointer hover:bg-gray-50", onClick: function () {
                                setSelectedSchema(schema);
                                setIsClicked(true);
                            }, children: schema.name }, schema.id), index !== editableSchemas.length - 1 && _jsx(Divider, { className: "my-0" })] }));
            }) }));
    };
    var renderEditDropdownMenu = function () {
        if (!selectedSchema)
            return null;
        return (_jsxs("div", { className: "w-[360px] bg-white shadow-hover", children: [_jsx("div", { className: "flex items-center p-2 pt-1 text-base font-medium", children: _jsx(PlainHeaderDisplay, { icon: columnIcon(getColumnTypeKey(selectedSchema.inputMode, selectedSchema.type, selectedSchema.nativePath)), title: selectedSchema.name }) }), _jsx(Divider, { className: "my-0" }), _jsx(CellHOC, { cellPos: {
                        collectionId: collectionId,
                        recordId: selectedRecordIds[0],
                        schemaId: selectedSchema.id,
                    }, collectionTableType: CollectionTableType.TABLE, editCell: numberOfSelectedRecords === 1 ? bulkEditRecords : openBulkEditConfirmation, clickButtonCell: numberOfSelectedRecords === 1
                        ? onBulkClickButtonCell
                        : openBulkButtonClickActionConfirmation, blockId: "", canEdit: true, canOnlyUpdateEmpty: false, isClicked: isClicked, setIsClicked: setIsClicked })] }));
    };
    return (_jsx(ZiraDropdown, { dropdownRender: selectedSchema ? renderEditDropdownMenu : renderOptionDropdownMenu, open: isDropdownOpen, onOpenChange: function (open) {
            if (isBulkEditing)
                return;
            setIsDropdownOpen(open);
            setSelectedSchema(undefined);
        }, placement: "bottomLeft", trigger: ['click'], destroyPopupOnHide: true, children: _jsxs("div", { className: "BulkEditButtonOption space-x-[6px]", style: { cursor: isBulkEditing ? 'not-allowed' : 'pointer' }, children: [_jsx(SVG, { src: BULK_EDIT_ICON }), _jsx("span", { children: "Edit" })] }) }));
};
export default React.memo(BulkEditColumnButton);
