import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { scheduleVisibleStatsOptionsSelector, scheduleTimeRangeState, scheduleViewTypeSelector, } from 'src/state';
import { getAccountCurrencySymbol } from 'src/utils/Account';
import { ScheduleView } from 'src/services';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, } from '../../Constant';
import { computeTotalStatsValue, computeAverageStatValue, renderDisplayValue, convertSecondsToHours, generateScheduleStatOptionName, isHoursMetric, isCostMetric, generateDescription, } from '../Utils';
import SingleStatsValueCell from './SingleStatsValueCell';
import ComboStatsValueCell from './ComboStatsValueCell';
import './ScheduleSummaryTable.scss';
var ScheduleSummaryTable = function (_a) {
    var blockId = _a.blockId, tableData = _a.tableData, widths = _a.widths;
    var tableWidth = widths.tableWidth, tableFirstColumnWith = widths.tableFirstColumnWith;
    var accountCurrency = getAccountCurrencySymbol();
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var schedulingStats = useRecoilValue(scheduleVisibleStatsOptionsSelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var timeRangeDayCount = timeRange.slice(0, -1).length;
    var cellMinWidth = scheduleView !== ScheduleView.WEEK
        ? MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH
        : WEEK_VIEW_DATE_COLUMN_MIN_WIDTH;
    var renderStatsRowHeader = useCallback(function (option, data) {
        var title = generateScheduleStatOptionName(option);
        var description = generateDescription(option);
        if (option.second || !data) {
            return (_jsx("div", { className: "font-medium text-sm flex justify-center content-center items-center", children: _jsx(ZiraTooltip, { title: description, placement: "right", children: _jsx("div", { className: "StatValue cursor-pointer", children: title }) }) }));
        }
        else {
            var total = computeTotalStatsValue(data.data);
            var average = computeAverageStatValue(data.data);
            var displayValue = renderDisplayValue(data.key === 'SHOW_RATE' ? average : total, accountCurrency, data);
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "font-medium text-sm flex justify-center content-center items-center", children: _jsx(ZiraTooltip, { title: description, placement: "right", children: _jsx("div", { className: "StatValue cursor-pointer", children: title }) }) }), _jsx("div", { className: "font-semibold text-xs", children: displayValue })] }));
        }
    }, [accountCurrency]);
    if (tableData.length === 0) {
        return (_jsxs("div", { className: "flex h-[150px] flex-col justify-center", children: [_jsx("div", { className: "text-center text-base font-medium text-gray-300", children: "No Data" }), _jsxs("div", { className: "flex justify-center text-gray-300", children: ["Click", _jsx("span", { className: "mx-1 rounded bg-gray-40 px-1 text-center font-medium text-gray-500", children: "Configure Stats" }), "to change settings"] })] }));
    }
    return (_jsx("div", { className: "StatsRows SummaryScrollContainer", style: { width: tableWidth }, children: schedulingStats.map(function (option) {
            var firstStat = tableData.find(function (row) { return row.key === option.first; });
            var secondStat = tableData.find(function (row) { return row.key === option.second; });
            return (_jsxs("div", { className: "flex h-[45px] border-b border-gray-30", children: [_jsx("div", { className: "StatsRowHeader flex shrink-0 justify-between", style: {
                            width: tableFirstColumnWith,
                            minWidth: tableFirstColumnWith,
                        }, children: renderStatsRowHeader(option, firstStat) }), _jsx("div", { className: "StatsValueCellsContainer", style: {
                            width: "calc(".concat(tableWidth, " - ").concat(tableFirstColumnWith, "px)"),
                            gridTemplateColumns: "repeat(".concat(timeRangeDayCount, ", minmax(").concat(cellMinWidth, "px, 1fr))"),
                        }, children: timeRange.slice(0, -1).map(function (time, colIndex) {
                            var _a, _b;
                            if (!option.second && firstStat) {
                                var value = firstStat === null || firstStat === void 0 ? void 0 : firstStat.data[moment(time).format('YYYY-MM-DD')];
                                return (_jsx(SingleStatsValueCell, { value: value, statKey: option.first }, colIndex));
                            }
                            else if (option.second &&
                                option.operation &&
                                firstStat &&
                                secondStat) {
                                var firstRawValue = (_a = firstStat.data[moment(time).format('YYYY-MM-DD')]) !== null && _a !== void 0 ? _a : 0;
                                var secondRawValue = (_b = secondStat.data[moment(time).format('YYYY-MM-DD')]) !== null && _b !== void 0 ? _b : 0;
                                var needTimeConversion = isHoursMetric(option.first) && isHoursMetric(option.second);
                                var needCurrencySign = isCostMetric(option.first) && isCostMetric(option.second);
                                var firstValue = needTimeConversion
                                    ? Number(convertSecondsToHours(firstRawValue))
                                    : needCurrencySign
                                        ? Number(firstRawValue.toFixed(2))
                                        : firstRawValue;
                                var secondValue = needTimeConversion
                                    ? Number(convertSecondsToHours(secondRawValue))
                                    : needCurrencySign
                                        ? Number(secondRawValue.toFixed(2))
                                        : secondRawValue;
                                return (_jsx(ComboStatsValueCell, { firstValue: firstValue, secondValue: secondValue, operationType: option.operation, extraSign: needCurrencySign ? accountCurrency : undefined }, colIndex));
                            }
                        }) })] }, "".concat(option.first, "+").concat(option.second)));
        }) }));
};
export default React.memo(ScheduleSummaryTable);
