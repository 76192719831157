import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from 'antd';
import React from 'react';
import { exportFileItemIcon } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import FileItemActionButton from './FileItemActionButton';
var FileItem = function (props) {
    var _a;
    var file = props.file;
    var timeFormatFn = useTimeZone().timeFormatFn;
    return (_jsxs("div", { className: "mt-5 w-full max-w-[1000px]", children: [_jsxs("div", { className: "flex justify-between", children: [_jsxs("div", { className: "flex", children: [_jsx("div", { className: "FileIconContainer", children: exportFileItemIcon[file.fileType] }), _jsxs("div", { children: [_jsx("div", { className: "font-medium", children: (_a = file.fileName) !== null && _a !== void 0 ? _a : 'Untitled Export' }), _jsx("div", { className: "font-medium text-gray-400", children: "Requested by ".concat(file.actor.name, " on ").concat(timeFormatFn(file.requestedAt, 'MMM D, YYYY', false)) })] })] }), _jsx(FileItemActionButton, { status: file.status, downloadUrl: file.fileUrl })] }), _jsx(Divider, { className: "mt-5" })] }));
};
export default React.memo(FileItem);
