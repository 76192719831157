import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
var BlockTemplateCard = function (_a) {
    var template = _a.template, selectedTemplate = _a.selectedTemplate, onTemplateSelect = _a.onTemplateSelect;
    var templateCardBorder = useMemo(function () {
        if ((selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.identifier) === template.identifier) {
            return 'border-black border-2';
        }
        return 'border border-gray-60';
    }, [selectedTemplate, template]);
    return (_jsxs("div", { className: "mb-5 cursor-pointer rounded-sm ".concat(templateCardBorder, " p-4"), onClick: onTemplateSelect, children: [_jsx("div", { className: "mb-2 font-medium", children: template.templateName }), _jsx("div", { className: "text-xs text-gray-600", children: template.description })] }));
};
export default React.memo(BlockTemplateCard);
