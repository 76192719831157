import { atom, selector } from 'recoil';
import { replaceAutomationRecipeSchemaIds } from './util';
var recipeStepState = atom({
    key: 'recipeStepState',
    default: 0,
});
var recipeDataChangesState = atom({
    key: 'recipeDataChangesState',
    default: [],
});
var recipeOverriddenDataChangesState = atom({
    key: 'recipeOverriddenDataChangesState',
    default: [],
});
var recipeSchemaIdMapState = atom({
    key: 'recipeSchemaIdMapState',
    default: {},
});
var recipePermissionChangesState = atom({
    key: 'recipePermissionChangesState',
    default: [],
});
var recipeAutomationsState = atom({
    key: 'recipeAutomationsState',
    default: [],
});
var activateRecipeRequestSelector = selector({
    key: 'activateRecipeRequestSelector',
    get: function (_a) {
        var get = _a.get;
        var dataChanges = get(recipeOverriddenDataChangesState);
        var permissionChanges = get(recipePermissionChangesState);
        var recipeSchemaIdMap = get(recipeSchemaIdMapState);
        var automations = replaceAutomationRecipeSchemaIds(get(recipeAutomationsState), recipeSchemaIdMap);
        return {
            dataChanges: dataChanges,
            permissionChanges: permissionChanges,
            automations: automations,
            recipeSchemaIdMap: recipeSchemaIdMap,
        };
    },
});
export { recipeStepState, recipeDataChangesState, recipeOverriddenDataChangesState, recipeSchemaIdMapState, recipePermissionChangesState, recipeAutomationsState, activateRecipeRequestSelector, };
