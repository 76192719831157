var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Divider, Button, Form, Switch } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CollectionType, HoursSelectorsType, IpName } from 'src/shared';
import { getIntegrationIcon, getIpDisplayText } from 'src/utils/Account';
import { useAccountApi, usePageApi, useSetting } from 'src/hooks/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { accountIdState } from 'src/state';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { dropdownListFilterInputResult } from 'src/utils/Collection';
import { standardCollectionIdSelector } from 'src/state/selectors/withAccountDetailState';
import ExportIntegrationUnconnectedPartner from './ExportIntegrationUnconnectedPartner';
var formItemRule = {
    required: true,
    message: 'Please select an option',
};
var Option = Select.Option;
var ExportPayrollIntegrationContent = function (_a) {
    var setIsModalVisible = _a.setIsModalVisible, setIsLoading = _a.setIsLoading;
    var formInstance = Form.useForm()[0];
    var _b = useParams().payPeriodId, payPeriodId = _b === void 0 ? '' : _b;
    var navigate = useNavigate();
    var accountId = useRecoilValue(accountIdState);
    var _c = usePageApi(), useGetAvailablePayRollIp = _c.useGetAvailablePayRollIp, useGetEarningCode = _c.useGetEarningCode, useSendPayRoll = _c.useSendPayRoll, useGetADPPayRollGroupCodes = _c.useGetADPPayRollGroupCodes, useGetPaychexPayPeriods = _c.useGetPaychexPayPeriods;
    var useGetSchemaColumnsInCollection = useAccountApi().useGetSchemaColumnsInCollection;
    var _d = useState(), selectedPartner = _d[0], setSelectedPartner = _d[1];
    var partnerDisplayName = getIpDisplayText(selectedPartner);
    var partnerDisplayIcon = getIntegrationIcon(selectedPartner);
    var _e = useGetAvailablePayRollIp(), payRollIpData = _e.data, isPayRollIpDataLoading = _e.isLoading;
    useEffect(function () {
        setIsLoading(isPayRollIpDataLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPayRollIpDataLoading]);
    useEffect(function () {
        if (payRollIpData === null || payRollIpData === void 0 ? void 0 : payRollIpData.connectedPartner) {
            setSelectedPartner(payRollIpData.connectedPartner);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payRollIpData]);
    var isConnectedToPartner = !!selectedPartner && selectedPartner === (payRollIpData === null || payRollIpData === void 0 ? void 0 : payRollIpData.connectedPartner);
    var isSelectedAdpConnected = isConnectedToPartner && selectedPartner === 'adp';
    var isSelectedPaychexConnected = isConnectedToPartner && selectedPartner === 'paychex';
    var isSelectedCheckrPayConnected = isConnectedToPartner && selectedPartner === 'checkrpay';
    var useGetIpUnmatchedUserCount = useSetting().useGetIpUnmatchedUserCount;
    var _f = useGetIpUnmatchedUserCount(selectedPartner !== null && selectedPartner !== void 0 ? selectedPartner : '', {
        enabled: isSelectedAdpConnected,
    }), ipUnmatchedUserCount = _f.data, isUnmatchedCountLoading = _f.isLoading;
    var _g = useState(false), showExportActions = _g[0], setShowExportActions = _g[1];
    useEffect(function () {
        if (isConnectedToPartner) {
            if (selectedPartner === 'adp' &&
                typeof (ipUnmatchedUserCount === null || ipUnmatchedUserCount === void 0 ? void 0 : ipUnmatchedUserCount.count) === 'number' &&
                ipUnmatchedUserCount.count > 0) {
                setShowExportActions(false);
            }
            else {
                setShowExportActions(true);
            }
        }
    }, [ipUnmatchedUserCount, isConnectedToPartner, payRollIpData, selectedPartner]);
    var availablePaychexPayPeriods = useGetPaychexPayPeriods({
        enabled: isSelectedPaychexConnected && showExportActions,
    }).data;
    var availableEarningCode = useGetEarningCode(selectedPartner !== null && selectedPartner !== void 0 ? selectedPartner : '', {
        enabled: isConnectedToPartner && !isSelectedCheckrPayConnected && showExportActions,
    }).data;
    var adpPayrollGroupCodes = useGetADPPayRollGroupCodes({
        enabled: isSelectedAdpConnected && showExportActions,
    }).data;
    var onSendPayRoll = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, pairs, requestBody;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!selectedPartner)
                        return [2 /*return*/];
                    return [4 /*yield*/, formInstance.validateFields()];
                case 1:
                    formValues = _b.sent();
                    pairs = [];
                    HoursSelectorsType.map(function (type) {
                        return pairs.push({
                            ziraCode: type,
                            ipCode: formValues[type],
                        });
                    });
                    requestBody = selectedPartner === 'adp'
                        ? {
                            accountId: accountId,
                            ipName: selectedPartner,
                            payPeriodId: payPeriodId,
                            earningCodeMatches: pairs,
                            payRollGroupCode: formValues.groupCode,
                        }
                        : selectedPartner === 'paychex'
                            ? {
                                accountId: accountId,
                                ipName: selectedPartner,
                                payPeriodId: payPeriodId,
                                earningCodeMatches: pairs,
                                ipPayPeriod: formValues.selectedPaychexPayPeriod,
                            }
                            : selectedPartner === 'checkrpay'
                                ? {
                                    accountId: accountId,
                                    ipName: selectedPartner,
                                    payPeriodId: payPeriodId,
                                    orderedDescriptionSchemaIds: (_a = formValues.selectedSchemas) !== null && _a !== void 0 ? _a : [],
                                }
                                : {
                                    accountId: accountId,
                                    ipName: selectedPartner,
                                    payPeriodId: payPeriodId,
                                    earningCodeMatches: pairs,
                                    payrollRunId: '',
                                };
                    setIsLoading(true);
                    useSendPayRoll.mutate(requestBody, {
                        onSettled: function () {
                            setIsModalVisible(false);
                            setIsLoading(false);
                            toast('We are processing your payroll export request. Please check your email in a few minutes for the completion status. Thank you.', {
                                type: 'info',
                                position: toast.POSITION.BOTTOM_CENTER,
                            });
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, formInstance, payPeriodId, selectedPartner, setIsModalVisible]);
    var showWarningContentOrButton = useMemo(function () {
        return typeof (ipUnmatchedUserCount === null || ipUnmatchedUserCount === void 0 ? void 0 : ipUnmatchedUserCount.count) === 'number' &&
            ipUnmatchedUserCount.count > 0 &&
            !showExportActions &&
            selectedPartner === 'adp';
    }, [ipUnmatchedUserCount === null || ipUnmatchedUserCount === void 0 ? void 0 : ipUnmatchedUserCount.count, selectedPartner, showExportActions]);
    var shiftCollectionId = useRecoilValue(standardCollectionIdSelector(CollectionType.SHIFT));
    var shiftSchemas = useGetSchemaColumnsInCollection({
        collectionId: shiftCollectionId !== null && shiftCollectionId !== void 0 ? shiftCollectionId : '',
        options: {
            enabled: !!shiftCollectionId,
        },
    }).data;
    var modalFooter = useMemo(function () {
        if (showWarningContentOrButton) {
            // Buttons for warning content
            return (_jsxs("div", { className: "w-min ml-auto flex space-x-[8px] mt-[-8px] mb-[-16px]", children: [_jsx(Button, { className: "CancelBtn", onClick: function () {
                            return navigate("/settings/integrations/".concat(selectedPartner === null || selectedPartner === void 0 ? void 0 : selectedPartner.toLocaleLowerCase()));
                        }, children: "Go to Setting" }), _jsx(Button, { className: "ConfirmBtn", onClick: function (e) {
                            e.stopPropagation();
                            setShowExportActions(true);
                        }, disabled: !isConnectedToPartner, children: "Continue Export" })] }));
        }
        else {
            return (
            // Buttons for actual export actions
            _jsxs("div", { className: "w-min ml-auto flex space-x-[8px] mt-[-8px] mb-[-16px]", children: [_jsx(Button, { className: "CancelBtn", onClick: function () { return setIsModalVisible(false); }, children: "Cancel" }), _jsx(Button, { className: "ConfirmBtn", onClick: function (e) {
                            e.stopPropagation();
                            if (isConnectedToPartner) {
                                onSendPayRoll();
                            }
                            else {
                                navigate("/settings/integrations/".concat(selectedPartner !== null && selectedPartner !== void 0 ? selectedPartner : ''));
                            }
                        }, children: isConnectedToPartner ? "Send to ".concat(partnerDisplayName) : 'Go to Setting' })] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payRollIpData === null || payRollIpData === void 0 ? void 0 : payRollIpData.connectedPartner, selectedPartner, showWarningContentOrButton]);
    var warningForUnmatchedUser = useMemo(function () {
        if (!ipUnmatchedUserCount)
            return _jsx(_Fragment, {});
        if (isUnmatchedCountLoading)
            return _jsx(ZiraRowsSkeleton, { rowNumber: 4 });
        return (_jsxs("div", { className: "flex items-center rounded bg-yellow-50 p-4", children: [_jsx(ExclamationCircleOutlined, { style: {
                        color: '#FFC047',
                        fontSize: '24px',
                        stroke: '#FFC047',
                        strokeWidth: '10',
                    }, size: 24 }), _jsx("div", { className: "ml-3 text-sm font-medium", children: "You have ".concat(ipUnmatchedUserCount.count, " unmatched user").concat(ipUnmatchedUserCount.count > 1 ? 's' : '', " that need to be reconciled.") })] }));
    }, [ipUnmatchedUserCount, isUnmatchedCountLoading]);
    var onFilterOption = useCallback(function (input, option) {
        var optionItem = availableEarningCode === null || availableEarningCode === void 0 ? void 0 : availableEarningCode.find(function (item) { return item.codeValue === option.value; });
        return optionItem ? dropdownListFilterInputResult(input, optionItem.displayName) : false;
    }, [availableEarningCode]);
    var renderModalMainContent = useCallback(function () {
        // Selected ip is already connected
        if (!payRollIpData)
            return _jsx(_Fragment, {});
        if (isConnectedToPartner) {
            if (showWarningContentOrButton) {
                return warningForUnmatchedUser;
            }
            return (_jsxs("div", { className: "ConnectedParternContainer", children: [_jsxs("div", { className: "ConnectedPartnerWithIcon", children: ["Already Connected with", partnerDisplayIcon] }), selectedPartner === IpName.ADP && (_jsx(Form.Item, { name: "groupCode", label: _jsx("div", { className: "SelectorTitle", children: "Payroll Group Code" }), rules: [formItemRule], children: _jsx(Select, { children: adpPayrollGroupCodes === null || adpPayrollGroupCodes === void 0 ? void 0 : adpPayrollGroupCodes.map(function (_a) {
                                var code = _a.code, displayName = _a.displayName;
                                return (_jsx(Option, { value: code, children: displayName }, code));
                            }) }) }, "group-code")), selectedPartner !== IpName.CHECKRPAY ? (_jsxs(_Fragment, { children: [_jsx(_Fragment, { children: HoursSelectorsType.map(function (select, index) {
                                    return (_jsx(Form.Item, { name: select, label: _jsxs("div", { className: "SelectorTitle", children: [upperCaseStringToStartCase(select), " Hours exported as"] }), rules: [formItemRule], children: _jsx(Select, { showSearch: true, filterOption: onFilterOption, children: availableEarningCode === null || availableEarningCode === void 0 ? void 0 : availableEarningCode.sort(function (a, b) {
                                                return a.displayName > b.displayName
                                                    ? 1
                                                    : -1;
                                            }).map(function (selection) {
                                                return (_jsx(Option, { value: selection.codeValue, children: selection.displayName }, selection.codeValue));
                                            }) }) }, index));
                                }) }), _jsxs(Form.Item, { name: "exportApprovedOnly", className: "ExportSwitch", valuePropName: "checked", children: [_jsx(Switch, {}), _jsx("span", { className: "font-medium ml-3", children: "Export Approved Only" })] }), _jsxs(Form.Item, { name: "markExportPaid", className: "ExportSwitch", valuePropName: "checked", children: [_jsx(Switch, {}), _jsx("span", { className: "font-medium ml-3", children: "Mark Exported as Paid" })] })] })) : (_jsx(Form.Item, { label: _jsx("div", { className: "text-sm font-medium", children: "Shift Fields" }), name: "selectedSchemas", rules: selectedPartner !== IpName.CHECKRPAY
                            ? [
                                {
                                    required: true,
                                    message: 'Please at least select one field.',
                                },
                            ]
                            : undefined, children: _jsx(Select, { className: "w-full", mode: "multiple", children: shiftSchemas === null || shiftSchemas === void 0 ? void 0 : shiftSchemas.columns.map(function (schema) {
                                return (_jsx(Option, { value: schema.id, children: schema.name }, schema.id));
                            }) }) }))] }));
        }
        return (_jsx(ExportIntegrationUnconnectedPartner, { partnerDisplayName: partnerDisplayName, partnerDisplayIcon: partnerDisplayIcon }));
    }, [
        adpPayrollGroupCodes,
        availableEarningCode,
        isConnectedToPartner,
        partnerDisplayIcon,
        partnerDisplayName,
        payRollIpData,
        selectedPartner,
        shiftSchemas === null || shiftSchemas === void 0 ? void 0 : shiftSchemas.columns,
        showWarningContentOrButton,
        warningForUnmatchedUser,
        onFilterOption,
    ]);
    return (_jsxs(Form, { layout: "vertical", form: formInstance, requiredMark: false, children: [_jsx(Form.Item, { name: "selectedPayrollPartner", label: _jsx("div", { className: "SelectorTitle", children: "Please select payroll export partner" }), initialValue: payRollIpData === null || payRollIpData === void 0 ? void 0 : payRollIpData.connectedPartner, children: _jsx(Select, { className: "ExportPartnerSelector", value: selectedPartner, onChange: function (value) {
                        setSelectedPartner(value);
                        if (value === 'adp') {
                            setShowExportActions(false);
                        }
                    }, children: payRollIpData === null || payRollIpData === void 0 ? void 0 : payRollIpData.availablePartners.map(function (ip) {
                        return (_jsx(Option, { value: ip.ipInternalName, children: ip.displayName }, ip.ipInternalName));
                    }) }) }), isSelectedPaychexConnected && (_jsx(Form.Item, { name: "selectedPaychexPayPeriod", label: _jsx("div", { className: "SelectorTitle", children: "Please select paychex pay period" }), rules: [formItemRule], children: _jsx(Select, { className: "ExportPartnerSelector", children: availablePaychexPayPeriods === null || availablePaychexPayPeriods === void 0 ? void 0 : availablePaychexPayPeriods.map(function (payPeriod) {
                        var _a;
                        return (_jsx(Option, { value: payPeriod.id, children: (_a = payPeriod.name) !== null && _a !== void 0 ? _a : 'Untitled Pay Period' }, payPeriod.id));
                    }) }) })), _jsx(Divider, {}), renderModalMainContent(), _jsx(Divider, {}), modalFooter] }));
};
export default React.memo(ExportPayrollIntegrationContent);
