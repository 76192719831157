import { useMutation } from 'react-query';
import { useFetch } from './useFetch';
var useArchive = function () {
    var useProcessArchiveSettings = function () {
        var customFetch = useFetch().request;
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "archive/process_settings",
            }, request);
        });
    };
    return {
        useProcessArchiveSettings: useProcessArchiveSettings(),
    };
};
export { useArchive as useArchiveApi };
