import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, MinusOutlined, PlusOutlined, RightOutlined, } from '@ant-design/icons';
import { Button, Calendar } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import './DuplicateShiftCalendarPopup.scss';
var DuplicateShiftCalendarPopup = function (props) {
    var duplicateData = props.duplicateData, setDuplicateData = props.setDuplicateData, defaultDate = props.defaultDate;
    var duplicateDataRef = useRef(duplicateData);
    var totalDuplicateShiftCount = useMemo(function () {
        var totalDuplicateShiftNumber = 0;
        for (var date in duplicateData) {
            var copies = duplicateData[date];
            totalDuplicateShiftNumber += copies;
        }
        if (totalDuplicateShiftNumber === 0)
            return '';
        return "".concat(totalDuplicateShiftNumber, " shift").concat(totalDuplicateShiftNumber === 1 ? '' : 's');
    }, [duplicateData]);
    useEffect(function () {
        duplicateDataRef.current = duplicateData;
    }, [duplicateData]);
    var removeShiftFromDate = useCallback(function (dateKey) {
        var tempDuplicateData = cloneDeep(duplicateDataRef.current);
        if (dateKey in tempDuplicateData) {
            if (tempDuplicateData[dateKey] !== 0) {
                tempDuplicateData[dateKey]--;
            }
            if (tempDuplicateData[dateKey] == 0) {
                delete tempDuplicateData[dateKey];
            }
            setDuplicateData(tempDuplicateData);
        }
    }, [setDuplicateData]);
    var addShiftToDate = useCallback(function (dateKey) {
        var tempduplicateData = cloneDeep(duplicateDataRef.current);
        if (dateKey in tempduplicateData) {
            tempduplicateData[dateKey]++;
        }
        else {
            tempduplicateData[dateKey] = 1;
        }
        setDuplicateData(tempduplicateData);
    }, [setDuplicateData]);
    var renderDate = useCallback(function (currentDate) {
        var dateKey = moment(currentDate).format('YYYY-MM-DD');
        return (_jsxs("div", { className: "CountEditor", children: [_jsx("div", { className: "MinusShift".concat(!duplicateData[dateKey] ? ' ZeroCount' : ''), children: _jsx(MinusOutlined, { onClick: function (e) {
                            e.stopPropagation();
                            removeShiftFromDate(dateKey);
                        } }) }), _jsxs("div", { className: "NumShiftsDateSelector", children: [_jsx("span", { className: "NumShifts", children: duplicateData[dateKey] || 0 }), _jsx("span", { className: "Shifts", children: " shifts" })] }), _jsx("div", { className: "PlusShift", onClick: function (e) {
                        e.stopPropagation();
                        addShiftToDate(dateKey);
                    }, children: _jsx(PlusOutlined, {}) })] }));
    }, [addShiftToDate, duplicateData, removeShiftFromDate]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var calendarHeaderRender = function (_a) {
        var value = _a.value, onChange = _a.onChange;
        var month = value.month();
        var year = value.year();
        return (_jsxs("div", { className: "mb-2 flex items-center justify-between", children: [_jsxs("div", { className: "ml-2 flex items-center p-2", children: [_jsx(Button, { icon: _jsx(DoubleLeftOutlined, {}), onClick: function () {
                                var newValue = value.clone();
                                newValue.year(year - 1);
                                onChange(newValue);
                            } }), _jsx(Button, { icon: _jsx(LeftOutlined, {}), onClick: function () {
                                var newValue = value.clone();
                                newValue.month(month - 1);
                                onChange(newValue);
                            } }), _jsx("div", { className: "font-medium", children: value.format('MMMM, YYYY') }), _jsx(Button, { icon: _jsx(RightOutlined, {}), onClick: function () {
                                var newValue = value.clone();
                                newValue.month(month + 1);
                                onChange(newValue);
                            } }), _jsx(Button, { icon: _jsx(DoubleRightOutlined, {}), onClick: function () {
                                var newValue = value.clone();
                                newValue.year(year + 1);
                                onChange(newValue);
                            } })] }), _jsx("div", { className: "mr-3", children: totalDuplicateShiftCount })] }));
    };
    return (_jsx(Calendar, { className: "DuplicateCalendar", fullscreen: false, dateCellRender: function (current) { return renderDate(current); }, defaultValue: defaultDate ? moment(defaultDate, 'MMM D, YYYY') : moment(), headerRender: calendarHeaderRender }));
};
export default React.memo(DuplicateShiftCalendarPopup);
