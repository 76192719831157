var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';
import { isCurrentPageCanvasTypeSelector } from 'src/state';
import { EMPTY_GROUP_FAKED_ID } from '../OptionHeader/ViewTypeOptions/Constants';
import ZiraTooltip from '../../../ui/ZiraTooltip';
import RecordCardList from './RecordCardList';
import { getColumnContentTextColor } from './util';
export var defaultColumnColor = '#F4F4F7';
var BoardViewColumn = function (_a) {
    var collectionId = _a.collectionId, column = _a.column, index = _a.index, paginationBaseRequestBody = _a.paginationBaseRequestBody, visibleSchemaIds = _a.visibleSchemaIds, setColumns = _a.setColumns, addNewRecord = _a.addNewRecord;
    var contentColor = column.color
        ? getColumnContentTextColor(column.color)
        : getColumnContentTextColor(defaultColumnColor);
    var columnRef = useRef(null);
    var _b = useState(500), columnHeight = _b[0], setColumnHeight = _b[1];
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var columnObserver = useMemo(function () {
        return new ResizeObserver(function (entries) {
            var isBlock = 'blockId' in paginationBaseRequestBody;
            if (isBlock && entries[0]) {
                setColumnHeight(entries[0].contentRect.height);
            }
        });
    }, [paginationBaseRequestBody]);
    useEffect(function () {
        if (columnRef === null || columnRef === void 0 ? void 0 : columnRef.current) {
            columnObserver.observe(columnRef.current);
        }
        return function () {
            columnObserver.disconnect();
        };
    }, [isCanvasPageType, columnObserver]);
    var onAddNewRecordWithPrefillOption = useCallback(function () {
        if (!addNewRecord)
            return;
        var isEmptyGroup = column.id === EMPTY_GROUP_FAKED_ID;
        var newCellOption = {
            id: column.id,
            name: column.title,
            color: column.color,
        };
        addNewRecord(isEmptyGroup ? undefined : newCellOption);
    }, [addNewRecord, column.color, column.id, column.title]);
    return (_jsx(Draggable, { draggableId: column.id, index: index, children: function (provided) { return (_jsxs("div", __assign({ className: "RecordColumnContainer" }, provided.draggableProps, { ref: function (el) {
                provided.innerRef(el);
                columnRef.current = el;
            }, children: [_jsxs("div", __assign({ className: "ColumnTitle text-sm font-medium", style: { color: contentColor } }, provided.dragHandleProps, { children: [_jsx(ZiraTooltip, { title: column.title, children: _jsx("div", { className: "overflow-hidden text-ellipsis whitespace-nowrap", children: column.title }) }), _jsx("div", { className: "ml-2 bg-gray-50 font-semibold px-2 rounded", children: column.totalRecordsCount })] })), _jsx("div", { className: "ColumnBar", style: { backgroundColor: contentColor } }), _jsx(RecordCardList, { collectionId: collectionId, column: column, index: index, height: !isCanvasPageType ? columnHeight - 80 : 650, paginationBaseRequestBody: paginationBaseRequestBody, visibleSchemaIds: visibleSchemaIds, setColumns: setColumns }), addNewRecord && (_jsxs("div", { className: "mt-[5px] flex cursor-pointer items-center rounded px-2 py-1 text-gray-500 hover:bg-gray-50", onClick: onAddNewRecordWithPrefillOption, children: [_jsx(PlusOutlined, { className: "mr-1" }), "New"] }))] }))); } }));
};
export default React.memo(BoardViewColumn);
