import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { NeedsCoverageConfigIcon } from 'src/utils/icons/SchedulingIcons/NeedsCoverageConfigIcon';
import { isRatioConfigModalVisibleState } from './Atom';
var RatioConfigureButton = function () {
    var setIsConfigModalVisible = useSetRecoilState(isRatioConfigModalVisibleState);
    return (_jsxs("div", { className: "mr-2 flex cursor-pointer items-center whitespace-nowrap rounded-lg bg-gray-40 px-2 py-1", onClick: function () {
            setIsConfigModalVisible(true);
        }, children: [_jsx(NeedsCoverageConfigIcon, {}), _jsx("div", { className: "ml-1 text-sm font-medium", children: "Configure Ratio" })] }));
};
export default React.memo(RatioConfigureButton);
