import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Spin, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
var RangePicker = DatePicker.RangePicker;
var DateRangeShiftPropagationModalContent = function (_a) {
    var shifts = _a.shifts, isShiftsLoading = _a.isShiftsLoading, isPropagationLoading = _a.isPropagationLoading, startAt = _a.startAt, endAt = _a.endAt, setStartAt = _a.setStartAt, setEndAt = _a.setEndAt;
    {
        return isPropagationLoading ? (_jsxs("div", { className: "flex flex-col align-center items-center", children: [_jsxs("div", { children: ["Propagating to ", (shifts === null || shifts === void 0 ? void 0 : shifts.length) || 0, " shifts"] }), _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) })] })) : (_jsxs("div", { children: [_jsx("div", { children: "Changes will take effect from the date range selected below." }), _jsx("div", { children: !isShiftsLoading
                        ? "Changes will affect ".concat((shifts === null || shifts === void 0 ? void 0 : shifts.length) || 0, " ").concat((shifts === null || shifts === void 0 ? void 0 : shifts.length) !== 1 ? 'Shifts' : 'Shift')
                        : 'Calculating shifts...' }), _jsx("div", { className: "flex flex-col gap-2", children: _jsx(RangePicker, { defaultValue: [startAt, endAt], onChange: function (dates) {
                            setStartAt((dates === null || dates === void 0 ? void 0 : dates[0]) || moment());
                            setEndAt((dates === null || dates === void 0 ? void 0 : dates[1]) || null);
                        }, allowEmpty: [false, true] }) })] }));
    }
};
export default DateRangeShiftPropagationModalContent;
