import { atom, atomFamily } from 'recoil';
var currentAccessGroupState = atom({
    key: 'currentAccessGroupState',
    default: undefined,
});
var accountAccessGroupDetailsState = atom({
    key: 'accountAccessGroupDetailsState',
    default: [],
});
var pagePermissionFamilyState = atomFamily({
    key: 'pagePermissionFamilyState',
    default: undefined,
});
export { currentAccessGroupState, accountAccessGroupDetailsState, pagePermissionFamilyState };
