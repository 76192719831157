var ChartColors = {
    blue: {
        base: 'rgba(47, 77, 223, 1)',
    },
    gray: {
        dark: 'rgba(0, 0, 0, 0.5)',
        light: 'rgba(0, 0, 0, 0.2)',
        extraLight: 'rgba(0, 0, 0, 0.03)',
    },
};
var getChartGradient = function (ctx, baseColor) {
    var gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, rgbaOverride(baseColor, 0.2));
    gradient.addColorStop(0.4, rgbaOverride(baseColor, 0.05));
    gradient.addColorStop(0.8, rgbaOverride(baseColor, 0.01));
    gradient.addColorStop(1, rgbaOverride(baseColor, 0));
    return gradient;
};
export { ChartColors, getChartGradient };
// --- private functions --- //
var rgbaOverride = function (color, opacity) {
    return color.replace(/[^,]+(?=\))/, opacity.toString());
};
