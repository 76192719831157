import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import ZiraModal from 'src/ui/ZiraModal/ZiraModal';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useShiftRecommendationsApi } from 'src/hooks/api/useShiftRecommendationsApi';
import { useDebounce } from 'src/hooks/component';
import { getValidatedFilters } from 'src/utils/Collection';
import { shiftRecommendationsModalPropsState } from './atom';
import { SHIFT_RECOMMENDATIONS_MODAL_PADDING, ShiftRecommendationsView } from './constant';
import ShiftRecommendationsModalHeader from './ShiftRecommendationsModalHeader';
import ShiftRecommendationsTabView from './ShiftRecommendationsTabView';
import './ShiftRecommendationsModal.scss';
import ShiftRecommendationsModalLoading from './ShiftRecommendationsModalLoading';
var ShiftRecommendationsModal = function (_a) {
    var recordId = _a.recordId, startAt = _a.startAt, endAt = _a.endAt;
    var setModalProps = useSetRecoilState(shiftRecommendationsModalPropsState);
    var _b = useState(ShiftRecommendationsView.ALL), view = _b[0], setView = _b[1];
    var _c = useState(''), searchText = _c[0], setSearchText = _c[1];
    var debouncedSearchText = useDebounce(searchText, 500);
    var _d = useState([]), filters = _d[0], setFilters = _d[1];
    var _e = useState(), summaryCounts = _e[0], setSummaryCounts = _e[1];
    var sessionId = useMemo(function () { return uuidv4(); }, []);
    var baseRequest = {
        sessionId: sessionId,
        shiftRid: recordId,
        start: moment(startAt).startOf('week').toISOString(),
        end: moment(endAt).add(1, 'week').startOf('week').toISOString(),
        query: debouncedSearchText || undefined,
        filters: filters.length
            ? getValidatedFilters(filters.filter(function (filter) { return !!filter; }))
            : undefined,
    };
    var useGetShiftRecommendationSummary = useShiftRecommendationsApi().useGetShiftRecommendationSummary;
    var _f = useGetShiftRecommendationSummary(baseRequest), data = _f.data, refetch = _f.refetch;
    useEffect(function () {
        if (data) {
            setSummaryCounts(data);
        }
    }, [data]);
    return (_jsx(ZiraModal, { show: true, modalStyle: { maxWidth: 640 }, onClose: function () { return setModalProps(undefined); }, showCloseButton: true, children: summaryCounts ? (_jsxs("div", { className: "ShiftRecommendationsModal flex w-full flex-col text-base", style: {
                paddingLeft: SHIFT_RECOMMENDATIONS_MODAL_PADDING,
                paddingTop: SHIFT_RECOMMENDATIONS_MODAL_PADDING,
            }, children: [_jsx(ShiftRecommendationsModalHeader, { startAt: startAt, endAt: endAt, assignedCount: summaryCounts.assignedShiftsCount, totalCount: summaryCounts.shiftsTotalCount, searchText: searchText, view: view, filters: filters, setView: setView, setSearchText: setSearchText, setFilters: setFilters }), _jsx("div", { className: "overflow-y-auto relative", style: {
                        paddingRight: SHIFT_RECOMMENDATIONS_MODAL_PADDING,
                        paddingBottom: SHIFT_RECOMMENDATIONS_MODAL_PADDING,
                    }, children: _jsx(ShiftRecommendationsTabView, { view: view, baseRequest: baseRequest, summaryCounts: summaryCounts, shiftStartAt: startAt, refetchCounts: refetch }) })] })) : (_jsx(ShiftRecommendationsModalLoading, {})) }));
};
export default React.memo(ShiftRecommendationsModal);
