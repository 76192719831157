var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var BulkDuplicateSvg = function () { return (_jsx("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M0.573071 3.2386H2.54796V0.429688H16.041V13.7594H13.4931V16.5683H0V3.2386H0.573071ZM3.66027 9.33072H6.1744V6.81659H7.31991V9.33072H9.83404V10.4762H7.31991V12.9904H6.1744V10.4762H3.66027V9.33072ZM3.69414 3.2386C7.52114 3.2386 9.66679 3.2386 13.4938 3.2386V12.6138H14.8963V1.5743H3.69414V3.2386ZM12.3482 4.38411H1.14601V15.4236H12.3482V4.38411Z", fill: "black" }) })); };
export var BulkDuplicateIcon = function (props) { return (_jsx(Icon, __assign({ component: BulkDuplicateSvg }, props))); };
