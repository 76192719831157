var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Switch } from 'antd';
import { collectionTableNativeSchemaSelector, tableFiltersSelector } from 'src/state';
import { useRecoilValue } from 'recoil';
import { useDisplaySettings } from 'src/hooks/component';
import { NativePath } from 'src/shared';
var TaskDismissedToggleOption = function (_a) {
    var tableId = _a.tableId, collectionId = _a.collectionId, type = _a.type;
    var filters = useRecoilValue(tableFiltersSelector(tableId));
    var taskDismissedSchema = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.TASK_DISMISSED,
    }));
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var dismissedFilter = useMemo(function () { return filters.find(function (filter) { return filter.property === (taskDismissedSchema === null || taskDismissedSchema === void 0 ? void 0 : taskDismissedSchema.id); }); }, [filters, taskDismissedSchema === null || taskDismissedSchema === void 0 ? void 0 : taskDismissedSchema.id]);
    var onToggleDismissed = useCallback(function (checked) {
        var filtersWithoutDismissed = filters.filter(function (filter) { return filter.property !== taskDismissedSchema.id; });
        if (checked) {
            // Remove dismissed filter
            applyDisplaySettingsToView(tableId, {
                filters: filtersWithoutDismissed,
            }, true, type == 'block');
        }
        else {
            // Add dismissed filter
            applyDisplaySettingsToView(tableId, {
                filters: __spreadArray(__spreadArray([], filtersWithoutDismissed, true), [
                    {
                        property: taskDismissedSchema.id,
                        clauseType: 'native_bool',
                        clauseValue: '=',
                        filterValue: 'false',
                        uniqueId: taskDismissedSchema.id,
                    },
                ], false),
            }, true, type == 'block');
        }
    }, [applyDisplaySettingsToView, filters, tableId, taskDismissedSchema, type]);
    if (!taskDismissedSchema)
        return null;
    return (_jsxs("div", { className: "flex items-center gap-2 text-sm font-medium", children: ["Show Dismissed", _jsx(Switch, { className: "mt-0", checked: (dismissedFilter === null || dismissedFilter === void 0 ? void 0 : dismissedFilter.filterValue) !== 'false', onChange: onToggleDismissed })] }));
};
export default React.memo(TaskDismissedToggleOption);
