var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var NeedsCoverageConfigIconSvg = function () { return (_jsxs("svg", { width: "12", height: "13", viewBox: "0 0 12 13", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M2.75 6C2.88807 6 3 5.88807 3 5.75L3 2.25C3 2.11193 2.88807 2 2.75 2L2.25 2C2.11193 2 2 2.11193 2 2.25L2 5.75C2 5.88807 2.11193 6 2.25 6H2.75Z", fill: "black" }), _jsx("path", { d: "M9.75 7C9.88807 7 10 6.88807 10 6.75L10 2.25C10 2.11193 9.88807 2 9.75 2L9.25 2C9.11193 2 9 2.11193 9 2.25L9 6.75C9 6.88807 9.11193 7 9.25 7H9.75Z", fill: "black" }), _jsx("path", { d: "M6.25 11C6.38807 11 6.5 10.8881 6.5 10.75L6.5 5.75C6.5 5.61193 6.38807 5.5 6.25 5.5L5.75 5.5C5.61193 5.5 5.5 5.61193 5.5 5.75L5.5 10.75C5.5 10.8881 5.61193 11 5.75 11L6.25 11Z", fill: "black" }), _jsx("path", { d: "M6.25 4C6.38807 4 6.5 3.88807 6.5 3.75L6.5 2.25C6.5 2.11193 6.38807 2 6.25 2L5.75 2C5.61193 2 5.5 2.11193 5.5 2.25L5.5 3.75C5.5 3.88807 5.61193 4 5.75 4H6.25Z", fill: "black" }), _jsx("path", { d: "M2.75 11C2.88807 11 3 10.8881 3 10.75L3 7.25C3 7.11193 2.88807 7 2.75 7L2.25 7C2.11193 7 2 7.11193 2 7.25L2 10.75C2 10.8881 2.11193 11 2.25 11H2.75Z", fill: "black" }), _jsx("path", { d: "M3.75 8C3.88807 8 4 7.88807 4 7.75V7.25C4 7.11193 3.88807 7 3.75 7L1.25 7C1.11193 7 1 7.11193 1 7.25L1 7.75C1 7.88807 1.11193 8 1.25 8L3.75 8Z", fill: "black" }), _jsx("path", { d: "M9.75 11C9.88807 11 10 10.8881 10 10.75L10 8.75C10 8.61193 9.88807 8.5 9.75 8.5L9.25 8.5C9.11193 8.5 9 8.61193 9 8.75L9 10.75C9 10.8881 9.11193 11 9.25 11H9.75Z", fill: "black" }), _jsx("path", { d: "M10.75 9C10.8881 9 11 8.88807 11 8.75V8.25C11 8.11193 10.8881 8 10.75 8L8.25 8C8.11193 8 8 8.11193 8 8.25L8 8.75C8 8.88807 8.11193 9 8.25 9L10.75 9Z", fill: "black" }), _jsx("path", { d: "M7.25 4.5C7.38807 4.5 7.5 4.38807 7.5 4.25V3.75C7.5 3.61193 7.38807 3.5 7.25 3.5L4.75 3.5C4.61193 3.5 4.5 3.61193 4.5 3.75L4.5 4.25C4.5 4.38807 4.61193 4.5 4.75 4.5L7.25 4.5Z", fill: "black" })] })); };
export var NeedsCoverageConfigIcon = function (props) { return (_jsx(Icon, __assign({ component: NeedsCoverageConfigIconSvg }, props))); };
