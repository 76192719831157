import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath } from 'src/shared';
import { getNativeFieldCellOptions, getTagTextColor } from 'src/utils/Collection';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var TaskDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var taskTag = useMemo(function () {
        var status = getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.TASK_STATUS);
        if (!status.length)
            return null;
        var backgroundColor = status[0].color;
        return (_jsx("span", { className: "RecordStatusTag", style: {
                backgroundColor: backgroundColor,
                color: getTagTextColor(backgroundColor),
            }, children: status[0].name }));
    }, [modalRecord, nativeSchemaMap]);
    return _jsx(RecordDetailModalTitle, { title: "Task", extras: taskTag });
};
export default React.memo(TaskDetailModalTitle);
