var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React, { useMemo } from 'react';
import { useSticky } from 'react-table-sticky';
import { useRecoilValue } from 'recoil';
import { useBlockLayout, useSortBy, useTable } from 'react-table';
import { onTableDataKeyDown } from 'src/shared';
import { needsCoverageTemplateVisibleOption } from '../../Atom';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var NeedCoverageTableBuilder = function (props) {
    var defaultColumns = props.columns, defaultData = props.data;
    var columns = useMemo(function () { return defaultColumns; }, [defaultColumns]);
    var data = useMemo(function () { return defaultData; }, [defaultData]);
    var _a = useTable({
        columns: columns,
        data: data,
        autoResetSortBy: false,
    }, useBlockLayout, useSticky, useSortBy), getTableProps = _a.getTableProps, getTableBodyProps = _a.getTableBodyProps, headerGroups = _a.headerGroups, rows = _a.rows, prepareRow = _a.prepareRow;
    var viewOnly = useRecoilValue(needsCoverageTemplateVisibleOption).viewOnly;
    return (_jsx("div", { className: "TableBuilder", children: _jsxs("table", __assign({}, getTableProps(), { className: "sticky table", style: { pointerEvents: viewOnly ? 'none' : 'auto' }, children: [_jsx("thead", { className: "thead", children: headerGroups.map(function (headerGroup) { return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps(), { key: headerGroup.id, className: "tr" }), headerGroup.headers.map(function (column, index) {
                        return (_createElement("th", __assign({}, column.getHeaderProps(column.getSortByToggleProps()), { className: "TableHeader th ".concat(column.isResizing ? 'isResizing' : ''), key: index }),
                            column.render('Header'),
                            index !== headerGroup.headers.length - 1 && (_jsx("div", { className: "resizer ".concat(column.isResizing ? 'isResizing' : '') }))));
                    }))); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { children: rows.map(function (row) {
                        prepareRow(row);
                        return (_createElement("tr", __assign({}, row.getRowProps(), { key: row.id, className: "tr" }), row.cells.map(function (cell, columnIndex) {
                            return (_createElement("td", __assign({}, cell.getCellProps(), { key: columnIndex, className: "td", onKeyDown: function (e) { return onTableDataKeyDown(e, columnIndex); } }), cell.render('Cell')));
                        })));
                    }) }))] })) }));
};
export default React.memo(NeedCoverageTableBuilder);
