var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var MobilePreviewFieldPlaceholderSvg = function () { return (_jsxs("svg", { width: "324", height: "55", viewBox: "0 0 324 55", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "12.2275", y: "0.349854", width: "299.545", height: "54.189", rx: "12.042", fill: "#F1F1F1" }), _jsx("rect", { width: "95.5834", height: "6.021", rx: "3.0105", transform: "matrix(1 0 0 -1 24.2695 30.4548)", fill: "#8F8F8F" }), _jsx("rect", { x: "222.963", y: "12.3918", width: "76.7678", height: "30.105", rx: "15.0525", fill: "#C2C2C2" }), _jsx("rect", { width: "52.6838", height: "6.021", rx: "3.0105", transform: "matrix(1 0 0 -1 235.005 30.4548)", fill: "#8F8F8F" })] })); };
export var MobilePreviewFieldPlaceholder = function (props) { return (_jsx(Icon, __assign({ component: MobilePreviewFieldPlaceholderSvg }, props))); };
