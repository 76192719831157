import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Radio, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHealthMetricApi } from 'src/hooks/api';
import { HealthMetricTimeRange } from 'src/hooks/api/interfaces/Interface';
import { LeftArrowIcon } from 'src/utils/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { lowerCase, startCase } from 'lodash';
import { accountTimeZoneSelector } from 'src/state/selectors/withAccountDetailState';
import moment from 'moment-timezone';
import EditRuleButton from './EditRuleButton';
import { getStartDateByEndAndTimeRange, isAboveGoal, numberFormatterWithType, ruleDataToGraphData, } from './HealthMetricCalculator';
import HealthMetricGraphBuilder from './HealthMetricGraphBuilder';
import './HealthMetricDetailView.scss';
import HealthMetricDetailTable from './HealthMetricDetailTable';
import HealthMetricLoadingBar from './HealthMetricLoadingBar';
import { chosenMetricWithDataState } from './Atoms';
import DeleteRuleButton from './DeleteRuleButton';
var HealthMetricDetailView = function () {
    var _a, _b, _c, _d, _e;
    var _f = useParams().ruleId, ruleId = _f === void 0 ? '' : _f;
    var _g = useRecoilState(chosenMetricWithDataState), chosenRuleData = _g[0], setChosenRuleData = _g[1];
    var _h = useHealthMetricApi(), useGetSingleMetricRuleQuery = _h.useGetSingleMetricRuleQuery, useGetHealthMetricDataQuery = _h.useGetHealthMetricDataQuery;
    var fetchedRule = useGetSingleMetricRuleQuery({
        ruleId: ruleId,
        options: {
            enabled: !!ruleId,
        },
    }).data;
    var _j = useState(), rule = _j[0], setRule = _j[1];
    useEffect(function () {
        setRule((chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule) || fetchedRule);
    }, [chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule, fetchedRule]);
    var _k = useState(rule === null || rule === void 0 ? void 0 : rule.timeRange), selectedTimeRange = _k[0], setSelectedTimeRange = _k[1];
    useEffect(function () {
        setSelectedTimeRange((chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule.timeRange) || (rule === null || rule === void 0 ? void 0 : rule.timeRange));
    }, [chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule.timeRange, rule === null || rule === void 0 ? void 0 : rule.timeRange]);
    var ruleGoalRef = useRef((chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule.goal) || (rule === null || rule === void 0 ? void 0 : rule.goal));
    useEffect(function () {
        ruleGoalRef.current = (chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule.goal) || (rule === null || rule === void 0 ? void 0 : rule.goal);
    }, [chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule.goal, rule === null || rule === void 0 ? void 0 : rule.goal]);
    var _l = useState((_a = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _a === void 0 ? void 0 : _a.interval), selectedInterval = _l[0], setSelectedInterval = _l[1];
    useEffect(function () {
        var _a, _b, _c;
        setSelectedInterval(((_b = (_a = chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.interval) || ((_c = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _c === void 0 ? void 0 : _c.interval));
    }, [(_c = (_b = chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.rule) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.interval, (_d = rule === null || rule === void 0 ? void 0 : rule.settings) === null || _d === void 0 ? void 0 : _d.interval]);
    var timeZone = useRecoilValue(accountTimeZoneSelector);
    var ruleRequest = useMemo(function () {
        var beforeAt = moment().tz(timeZone).startOf('day').toISOString();
        return {
            ruleId: ruleId,
            beforeAt: beforeAt,
            startFrom: getStartDateByEndAndTimeRange(beforeAt, selectedTimeRange || '30D').toISOString(),
            interval: selectedInterval,
        };
    }, [ruleId, selectedInterval, selectedTimeRange, timeZone]);
    var _m = useGetHealthMetricDataQuery({
        body: {
            rules: [ruleRequest],
        },
        options: {
            enabled: !!ruleId && !!(rule === null || rule === void 0 ? void 0 : rule.id) && !chosenRuleData,
            refetchInterval: function (data, _query) {
                // poll every 3s if there is no data
                if (data === null || data === void 0 ? void 0 : data.data.filter(function (d) { return !d.data.length; }).length)
                    return 3000;
                return false;
            },
        },
    }), ruleData = _m.data, refetch = _m.refetch;
    var graphData = ruleDataToGraphData(((_e = ruleData === null || ruleData === void 0 ? void 0 : ruleData.data[0]) === null || _e === void 0 ? void 0 : _e.data) || (chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.data) || [], rule === null || rule === void 0 ? void 0 : rule.type, timeZone, selectedInterval);
    var dataLoaded = (chosenRuleData === null || chosenRuleData === void 0 ? void 0 : chosenRuleData.data) || (ruleData === null || ruleData === void 0 ? void 0 : ruleData.data);
    var latestData = graphData === null || graphData === void 0 ? void 0 : graphData[graphData.length - 1];
    var latestDataColor = (latestData === null || latestData === void 0 ? void 0 : latestData.number) && isAboveGoal(latestData.number, rule === null || rule === void 0 ? void 0 : rule.goal)
        ? 'text-positive'
        : 'text-negative';
    var navigate = useNavigate();
    var handleGoBack = useCallback(function () {
        navigate({
            pathname: "/home/goals",
        });
        setChosenRuleData(undefined);
    }, [navigate, setChosenRuleData]);
    var timeRangeRadioGroup = useMemo(function () {
        var availableRanges = Array.from(HealthMetricTimeRange.values());
        return (_jsx(Radio.Group, { buttonStyle: "solid", defaultValue: selectedTimeRange, onChange: function (e) { return setSelectedTimeRange(e.target.value); }, children: availableRanges.map(function (range) { return (_jsx(Radio.Button, { className: "font-medium", value: range, children: range }, range)); }) }));
    }, [selectedTimeRange]);
    var intervalDropdown = useMemo(function () {
        //!!TODO: get from API
        var availableIntervals = ['DAILY', 'WEEKLY', 'MONTHLY'];
        return (_jsx(Select, { className: "w-24 font-medium", defaultValue: selectedInterval || 'DAILY', options: availableIntervals.map(function (interval) { return ({
                value: interval,
                label: startCase(lowerCase(interval)),
            }); }), onChange: function (value) { return setSelectedInterval(value); } }));
    }, [selectedInterval]);
    var loadingBlock = (_jsx("div", { className: "flex h-[100%] w-[100%] items-center justify-center", children: _jsx(HealthMetricLoadingBar, {}) }));
    if (!rule)
        return loadingBlock;
    return (_jsxs("div", { className: "HealthMetricDetailView px-10 py-3", children: [_jsxs("div", { className: "mb-3 flex items-center space-x-2", children: [_jsx("div", { className: "rounded-lg border border-gray-60 p-1 hover:cursor-pointer", onClick: handleGoBack, children: _jsx(LeftArrowIcon, { style: {
                                fontSize: '10px',
                            } }) }), _jsx("div", { className: "ml-2 text-lg font-medium", children: rule.name }), _jsx("div", { className: "flex-auto" }), _jsx(DeleteRuleButton, { ruleId: rule.id }), _jsx(EditRuleButton, { rule: rule, refetch: refetch })] }), !latestData || !rule ? (_jsx("div", { className: "flex h-[100%] w-[100%] items-center justify-center", children: _jsx(HealthMetricLoadingBar, {}) })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-4 flex items-center space-x-2 pl-11", children: [_jsxs("div", { children: [_jsx("div", { className: "text-xs font-medium text-gray-500", children: "Most Recent" }), _jsx("div", { className: "text-lg ".concat(latestDataColor, " font-medium"), children: dataLoaded
                                            ? numberFormatterWithType(latestData.number, rule.type) || '-'
                                            : '-' })] }), _jsxs("div", { className: "ml-6", children: [_jsx("div", { className: "text-xs font-medium text-gray-500", children: "Goal" }), _jsx("div", { className: "text-lg font-medium", children: dataLoaded && ruleGoalRef.current
                                            ? numberFormatterWithType(ruleGoalRef.current, rule.type) || '-'
                                            : '-' })] }), _jsx("div", { className: "flex-auto" }), intervalDropdown, timeRangeRadioGroup] }), _jsx("div", { className: "h-[400px] rounded-lg border border-gray-50 bg-white px-10 py-8 shadow-block", children: _jsx(HealthMetricGraphBuilder, { title: rule.name, data: graphData, type: rule.type, goal: ruleGoalRef.current, smallLabelStep: true, interval: selectedInterval }) }), _jsx("div", { className: "mt-8", children: _jsx(HealthMetricDetailTable, { title: rule.name, data: graphData, type: rule.type }) })] }))] }));
};
export default React.memo(HealthMetricDetailView);
