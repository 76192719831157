var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TemplatePageTypeIconSvg = function () { return (_jsxs("svg", { width: "15", height: "15", viewBox: "0 0 14 15", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.625 2.5H2.5V5.625H5.625V2.5ZM1.5625 1.25C1.38991 1.25 1.25 1.38991 1.25 1.5625V6.5625C1.25 6.73509 1.38991 6.875 1.5625 6.875H6.5625C6.73509 6.875 6.875 6.73509 6.875 6.5625V1.5625C6.875 1.38991 6.73509 1.25 6.5625 1.25H1.5625Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5 2.5H9.375V5.625H12.5V2.5ZM8.4375 1.25C8.26491 1.25 8.125 1.38991 8.125 1.5625V6.5625C8.125 6.73509 8.26491 6.875 8.4375 6.875H13.4375C13.6101 6.875 13.75 6.73509 13.75 6.5625V1.5625C13.75 1.38991 13.6101 1.25 13.4375 1.25H8.4375Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.625 9.375H2.5V12.5H5.625V9.375ZM1.5625 8.125C1.38991 8.125 1.25 8.26491 1.25 8.4375V13.4375C1.25 13.6101 1.38991 13.75 1.5625 13.75H6.5625C6.73509 13.75 6.875 13.6101 6.875 13.4375V8.4375C6.875 8.26491 6.73509 8.125 6.5625 8.125H1.5625Z", fill: "black" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.5 9.375H9.375V12.5H12.5V9.375ZM8.4375 8.125C8.26491 8.125 8.125 8.26491 8.125 8.4375V13.4375C8.125 13.6101 8.26491 13.75 8.4375 13.75H13.4375C13.6101 13.75 13.75 13.6101 13.75 13.4375V8.4375C13.75 8.26491 13.6101 8.125 13.4375 8.125H8.4375Z", fill: "black" })] })); };
export var TemplatePageTypeIcon = function (props) { return (_jsx(Icon, __assign({ component: TemplatePageTypeIconSvg }, props))); };
