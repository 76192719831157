import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { getAccountCurrencySymbol } from 'src/utils/Account';
import { convertToPercentNumber } from 'src/utils/Headcount';
import { convertSecondsToHours, isCostMetric, isHoursMetric, isRateMetric } from '../Utils';
var SingleStatsValueCell = function (_a) {
    var statKey = _a.statKey, value = _a.value;
    var accountCurrency = getAccountCurrencySymbol();
    var renderDisplayValue = useCallback(function () {
        var showPercent = isRateMetric(statKey);
        var needConversion = isHoursMetric(statKey);
        var isCurrency = isCostMetric(statKey);
        //MARK: can't defined by (!value), otherwise if value is 0, it will be true
        if (value === undefined)
            return '-';
        if (showPercent) {
            return "".concat(convertToPercentNumber(value), "%");
        }
        else if (needConversion) {
            return convertSecondsToHours(value);
        }
        var displayValue = isCurrency ? value === null || value === void 0 ? void 0 : value.toFixed(2) : value;
        return "".concat(isCurrency ? accountCurrency : '', " ").concat(displayValue);
    }, [accountCurrency, statKey, value]);
    return _jsx("div", { className: "StatsCellValue", children: renderDisplayValue() });
};
export default React.memo(SingleStatsValueCell);
