var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SchedulingStatsIconSvg = function () { return (_jsxs("svg", { width: "13", height: "13", viewBox: "0 0 13 13", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M8.66732 1.33325C8.66732 1.19518 8.55539 1.08325 8.41732 1.08325H4.04232C3.90425 1.08325 3.79232 1.19518 3.79232 1.33325V4.08325C3.79232 4.22132 3.68039 4.33325 3.54232 4.33325L1.33398 4.33325C1.19591 4.33325 1.08398 4.44518 1.08398 4.58325V5.16658C1.08398 5.30466 1.19591 5.41658 1.33398 5.41658H3.54232C3.68039 5.41658 3.79232 5.52851 3.79232 5.66658V8.12492C3.79232 9.53647 2.71245 10.6958 1.33378 10.8219C1.19628 10.8345 1.08398 10.9452 1.08398 11.0833V11.6666C1.08398 11.8047 1.19626 11.9174 1.33404 11.9085C3.31155 11.7797 4.87565 10.135 4.87565 8.12492V5.66658C4.87565 5.52851 4.98758 5.41658 5.12565 5.41658H6.79232C6.93039 5.41658 7.04232 5.30466 7.04232 5.16658V4.58325C7.04232 4.44518 6.93039 4.33325 6.79232 4.33325L5.12565 4.33325C4.98758 4.33325 4.87565 4.22132 4.87565 4.08325V2.41659C4.87565 2.27851 4.98758 2.16659 5.12565 2.16659H8.41732C8.55539 2.16659 8.66732 2.05466 8.66732 1.91659V1.33325Z" }), _jsx("path", { d: "M9.35726 7.94047C9.45489 8.0381 9.61318 8.0381 9.71081 7.94047L10.8159 6.83539C10.9135 6.73776 11.0718 6.73776 11.1695 6.83539L11.5819 7.24787C11.6796 7.3455 11.6796 7.50379 11.5819 7.60142L10.4768 8.7065C10.3792 8.80413 10.3792 8.96243 10.4768 9.06006L11.6903 10.2735C11.7879 10.3711 11.7879 10.5294 11.6903 10.6271L11.2778 11.0395C11.1802 11.1372 11.0219 11.1372 10.9243 11.0395L9.71081 9.82609C9.61318 9.72846 9.45489 9.72846 9.35726 9.82609L8.14382 11.0395C8.04619 11.1372 7.8879 11.1372 7.79027 11.0395L7.37779 10.6271C7.28016 10.5294 7.28016 10.3711 7.37779 10.2735L8.59123 9.06006C8.68886 8.96243 8.68886 8.80413 8.59123 8.7065L7.48614 7.60142C7.38851 7.50379 7.38851 7.3455 7.48614 7.24787L7.89862 6.83539C7.99625 6.73776 8.15455 6.73776 8.25218 6.83539L9.35726 7.94047Z" })] })); };
export var SchedulingStatsIcon = function (props) { return (_jsx(Icon, __assign({ component: SchedulingStatsIconSvg }, props))); };
