var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var TimeClockAccessIconSvg = function () { return (_jsxs("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_17829_7748)", children: [_jsx("path", { d: "M24.5 14C24.5 11.9223 23.8836 9.89132 22.7288 8.1641C21.574 6.43689 19.9328 5.09109 18.0128 4.29706C16.0928 3.50303 13.9804 3.29646 11.943 3.7035C9.90552 4.11054 8.03465 5.11289 6.56713 6.58368C5.09961 8.05446 4.10143 9.92757 3.69892 11.9659C3.29642 14.0042 3.50768 16.1162 4.30598 18.0344C5.10428 19.9526 6.45372 21.5908 8.18351 22.7418C9.91329 23.8927 11.9456 24.5046 14.0233 24.5", stroke: "#212121", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14 8.16666V14L15.1667 15.1667", stroke: "#212121", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M25.6667 18.6667C25.6667 23.3333 22.75 25.6667 21.5833 25.6667C20.4167 25.6667 17.5 23.3333 17.5 18.6667C18.6667 18.6667 20.4167 18.0833 21.5833 16.9167C22.75 18.0833 24.5 18.6667 25.6667 18.6667Z", stroke: "#212121", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_17829_7748", children: _jsx("rect", { width: "28", height: "28", fill: "white" }) }) })] })); };
export var TimeClockAccessIcon = function (props) { return (_jsx(Icon, __assign({ component: TimeClockAccessIconSvg }, props))); };
