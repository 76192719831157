var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ShiftRulesIconSvg = function () { return (_jsx("svg", { width: "10", height: "11", viewBox: "0 0 10 11", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.49998 2.22902C2.49999 2.50516 2.72384 2.729 2.99998 2.729C3.27612 2.729 3.49997 2.50516 3.49998 2.22902H6.5C6.50001 2.50516 6.72386 2.729 7 2.729C7.27614 2.729 7.49999 2.50516 7.5 2.22902H9V9.22902H1V2.22902H2.49998ZM3.49998 1.22902H6.5V0.729004C6.5 0.452862 6.72386 0.229004 7 0.229004C7.27614 0.229004 7.5 0.452861 7.5 0.729004V1.22902H9.75C9.88807 1.22902 10 1.34095 10 1.47902V9.97902C10 10.1171 9.88807 10.229 9.75 10.229H0.25C0.111929 10.229 0 10.1171 0 9.97902V1.47902C0 1.34095 0.111929 1.22902 0.25 1.22902H2.49998V0.729004C2.49998 0.452862 2.72384 0.229004 2.99998 0.229004C3.27612 0.229004 3.49998 0.452862 3.49998 0.729004V1.22902ZM4.15565 7.8982C4.20935 7.9519 4.2814 7.97606 4.35161 7.97069C4.42223 7.97646 4.49484 7.95233 4.54886 7.8983L7.43782 5.00935C7.53545 4.91172 7.53545 4.75343 7.43782 4.6558L7.08426 4.30225C6.98663 4.20462 6.82834 4.20462 6.73071 4.30225L4.35231 6.68065L3.38383 5.71216C3.28619 5.61453 3.1279 5.61453 3.03027 5.71216L2.67672 6.06571C2.57909 6.16334 2.57909 6.32164 2.67672 6.41927L4.15565 7.8982Z", fill: "#717177" }) })); };
export var ShiftRulesIcon = function (props) { return (_jsx(Icon, __assign({ component: ShiftRulesIconSvg }, props))); };
