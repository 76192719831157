import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
var ZiraRowsSkeleton = function (_a) {
    var rowNumber = _a.rowNumber, height = _a.height;
    var skeletonWidthRef = useRef(null);
    var _b = useState(200), skeletonWidth = _b[0], setSkeletonHeight = _b[1];
    var modalHeaderObserver = useMemo(function () {
        return new ResizeObserver(function (entries) {
            if (entries[0]) {
                setSkeletonHeight(entries[0].contentRect.width);
            }
        });
    }, []);
    useEffect(function () {
        if (skeletonWidthRef === null || skeletonWidthRef === void 0 ? void 0 : skeletonWidthRef.current) {
            modalHeaderObserver.observe(skeletonWidthRef.current);
        }
        return function () {
            modalHeaderObserver.disconnect();
        };
    }, [modalHeaderObserver]);
    if (rowNumber <= 0)
        return _jsx(_Fragment, {});
    return (_jsx("div", { ref: skeletonWidthRef, className: "w-full", children: _jsx(Skeleton, { active: true, paragraph: {
                rows: rowNumber,
                width: skeletonWidth,
            }, title: false, style: { height: height } }) }));
};
export default React.memo(ZiraRowsSkeleton);
