var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { validate as uuidValidate } from 'uuid';
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdState, currentUserState } from 'src/state';
import { ToastMessage } from '../../shared';
import { useFetch } from './useFetch';
import useOrganizationApi from './useOrganizationApi';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useSetting = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var currentUserInfo = useRecoilValue(currentUserState);
    var accountId = useRecoilValue(accountIdState);
    //account pay rate setting related hooks
    var useGetAccountPayRateSettingData = function (options) {
        return useQuery(['accountPaySettingData', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "account_wages/settings/".concat(accountId),
                onAuthorizedError: function () {
                    return;
                },
            });
        }, __assign({}, options));
    };
    var useGetRecordQualifierPayRateSettingData = function (recordId, options) {
        return useQuery(['recordQualifierPayRateSettingData', recordId], function () {
            return customFetch({
                method: 'GET',
                url: "account_wages/settings/record_qualifier/".concat(recordId),
                onAuthorizedError: function () {
                    return;
                },
            });
        }, __assign({}, options));
    };
    var disablePayRates = function () {
        return toastRequest({
            url: "pay/disable_pay_rate",
            method: 'POST',
        }, null, '', 'Successfully disabled pay rate!', '');
    };
    var enablePayRates = function () {
        return toastRequest({
            url: "pay/enable_pay_rate",
            method: 'POST',
        }, null, '', 'Successfully enabled pay rate!', '');
    };
    var enableIndividualPayRates = function () {
        return toastRequest({
            url: 'pay/enable_individual_pay_rate',
            method: 'PUT',
        }, null, '', 'Individual pay rates enabled!', '');
    };
    var disableIndividualPayRates = function () {
        return toastRequest({
            url: 'pay/disable_individual_pay_rate',
            method: 'PUT',
        }, null, '', 'Individual pay rates disabled!', '');
    };
    var useGetPayRatesSetting = function (options) {
        return useQuery(['useGetPayRatesSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "pay/get_pay_rate_setting",
            });
        }, __assign({}, options));
    };
    var useGetUserPayRateSettingData = function (userRId, options) {
        return useQuery(['userWageData', userRId], function () {
            return customFetch({
                method: 'GET',
                url: "account_wages/settings/individual/".concat(userRId),
                onAuthorizedError: function () {
                    return;
                },
            });
        }, __assign({}, options));
    };
    var updateUserIndividualPayRate = function (body) {
        return toastRequest({
            method: 'PUT',
            url: "account_wages/settings/indiv/".concat(accountId),
        }, body, undefined, "Successfully updated user's pay rate!");
    };
    var rematchShiftsWage = function (request) {
        return toastRequest({
            url: 'pay/rematch_shifts',
            method: 'POST',
        }, __assign(__assign({}, request), { accountId: accountId }), undefined, 'Initiated rematching of shifts!', undefined);
    };
    var useGetAvailableQualifyObject = function () {
        return useQuery(['availableQualifyObject', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "account_wages/settings/avl_qualify_obj/".concat(accountId),
            });
        });
    };
    var useGetCollectionOptionList = function (request) {
        var collectionId = request.collectionId;
        return useQuery(["collectionSimpleList", collectionId], function () {
            // eslint-disable-next-line prettier/prettier
            return customFetch({
                method: 'POST',
                url: 'collections/simple_list',
            }, {
                collectionId: collectionId,
            });
        }, request.options);
    };
    var useGetSelectionOptionList = function (request) {
        var groupId = request.groupId;
        return useQuery(["selectionSimpleList", groupId], function () {
            return customFetch({
                method: 'POST',
                url: 'collections/select_options/simple_list',
            }, {
                groupId: groupId,
            });
        }, request.options);
    };
    var useSaveQuickMenuSettings = function () {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'POST',
                url: "collections/toggle_quick_create_schema",
            }, requestBody, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetUserQuickMenuSettings = function () {
        return useQuery(['userQuickMenu', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "users/get_quick_menu_settings/" + accountId,
            });
        }, {
            enabled: accountId !== '',
        });
    };
    var useSaveUserQuickMenuSettings = function () {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'POST',
                url: "users/save_quick_menu_settings",
            }, __assign(__assign({}, requestBody), { accountId: accountId }), '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useSavePayRateSettingData = function () {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'PUT',
                url: "account_wages/settings/".concat(accountId),
            }, requestBody, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useSaveRecordQualifierPayRateSettingData = function (recordId) {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'PUT',
                url: "account_wages/settings/record_qualifier/".concat(recordId),
            }, requestBody, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var usePopulateIndividualPaySettings = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "account_wages/settings/".concat(accountId, "/populate"),
            }, request);
        });
    };
    // pay differential settings
    var useGetPayDifferentialSettingData = function () {
        return useQuery(['getPayDifferentialSetting', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "settings/differentials/".concat(accountId),
            });
        });
    };
    var useSavePayDifferentialSetting = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "settings/differentials/".concat(accountId),
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    // pay period settings
    var useGetPayPeriodSettingData = function (accountId) {
        return useQuery(['getPayPeriodSetting', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "accounts/pay_period/".concat(accountId),
            });
        });
    };
    var useSavePayPeriod = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'accounts/pay_period',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetPayrollEnhancementSetting = function (request) {
        return useQuery(['payrollEnhancementSetting'], function () {
            return customFetch({
                method: 'GET',
                url: 'pay/payroll_enhancement',
            });
        }, request === null || request === void 0 ? void 0 : request.options);
    };
    var useSavePayrollEnhancementSetting = function () {
        return useMutation(function (newSetting) {
            return toastRequest({
                method: 'PUT',
                url: "pay/payroll_enhancement/".concat(newSetting),
            }, null, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetBillableSeatsData = function (accountId) {
        return useQuery(['getBillableSeats', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "billing/billable_seats/".concat(accountId),
            });
        });
    };
    // geofence settings
    var useGeGeofenceStatusSetting = function () {
        return useQuery(['useGeGeofenceStatusSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "geofences/get_geofence_setting",
            });
        });
    };
    var disableGeofence = function () {
        return toastRequest({
            url: "geofences/disable_geofence",
            method: 'POST',
        }, null, '', 'Successfully disabled geofence!', '');
    };
    var enableGeofence = function () {
        return toastRequest({
            url: "geofences/enable_geofence",
            method: 'POST',
        }, null, '', 'Successfully enabled geofence!', '');
    };
    var useGetGeofenceData = function (requestBody) {
        return useQuery(['getGeofences', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "geofences/".concat(accountId),
            });
        }, requestBody === null || requestBody === void 0 ? void 0 : requestBody.options);
    };
    var useDeleteGeofence = function () {
        return useMutation(function (geofenceId) {
            return customFetch({
                method: 'DELETE',
                url: 'geofences/' + geofenceId,
            }, {
                geofenceId: geofenceId,
            });
        });
    };
    var useSaveGeofence = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'geofences/save',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useCreateBillingPortal = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'billing/portal',
            }, request);
        });
    };
    //integration related hooks
    var useConnectToIp = function (requestBody, ipName) {
        return useQuery(['connectToIp', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/oauth/".concat(ipName, "/").concat(accountId),
            });
        }, requestBody.options);
    };
    var useConnectToSignNow = function (request, ipName) {
        return useQuery(['connectToSignNow', ipName], function () {
            return customFetch({
                method: 'POST',
                url: "ip/oauth/".concat(ipName, "/key_id/").concat(accountId),
            }, request.body);
        }, request.options);
    };
    var useConnectToIntegrationClient = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/oauth/".concat(request.ipName, "/authenticate/").concat(accountId),
            }, {
                clientId: request.clientId,
                clientSecret: request.clientSecret,
            });
        });
    };
    var useConnectToIntegrationAPI = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/oauth/".concat(request.ipName, "/apiKey/").concat(accountId),
            }, {
                apiKey: request.apiKey,
            });
        });
    };
    var getGustoCompanyProvision = function (body) {
        var _a;
        return customFetch({
            method: 'POST',
            url: "ip/gusto/provision",
        }, __assign(__assign({}, body), { accountId: accountId, userRId: (_a = currentUserInfo === null || currentUserInfo === void 0 ? void 0 : currentUserInfo.recordId) !== null && _a !== void 0 ? _a : '' }));
    };
    var usePeriodReSync = function () {
        return useMutation(function (ipName) {
            return customFetch({
                method: 'POST',
                url: "ip/setup/periodic_sync/".concat(ipName),
            });
        });
    };
    var useGetAvailableIpCollections = function (ipName) {
        return useQuery(['getAvailableIpCollections', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/collection_mapping/".concat(accountId, "/").concat(ipName),
            });
        });
    };
    var useDisconnectIpCollection = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/setup/remove/".concat(accountId, "/").concat(request.ipName, "/").concat(request.ziraCollectionId),
            });
        });
    };
    var useLinkCollections = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'ip/setup/collection_mapping',
            }, request);
        });
    };
    var useGetSchemaMappingData = function (requestBody) {
        return useQuery(['getSchemaMappingData', requestBody], function () {
            return customFetch({
                method: 'POST',
                url: 'ip/setup/schema_mapping/v2',
            }, requestBody);
        });
    };
    var useSaveSchemasMappingResult = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'PUT',
                url: 'ip/setup/schema_mapping',
            }, request);
        });
    };
    var useGetProposedMatches = function (ziraCollectionId) {
        return useQuery(['getRecordMappingData', ziraCollectionId], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/record_mapping/".concat(accountId, "/").concat(ziraCollectionId),
            });
        });
    };
    var useSaveRecordMappingResult = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'ip/setup/record_mapping',
            }, request);
        });
    };
    var useGetIntegrationSyncSettingOptions = function (ipCollection, options) {
        return useQuery(['getIntegrationSyncSettingOptions', ipCollection], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/sync_settings/".concat(ipCollection),
            });
        }, __assign({}, options));
    };
    var useGetDeltaUnmatchedUserInfo = function (params, options) {
        var ipName = params.ipName, ipCollection = params.ipCollection;
        return useQuery(['getDeltaUnmatchedUserInfo', ipName, ipCollection], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/delta/".concat(ipName, "/").concat(ipCollection),
            });
        }, __assign({}, options));
    };
    var useSubmitDeltaMatchedUserPairs = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "ip/setup/delta/".concat(request.ipName, "/").concat(request.ipCollection),
            }, request.deltaMatchedUserPairs);
        });
    };
    var useGetIpUnmatchedUserCount = function (ipName, options) {
        return useQuery(['getIpUnmatchedUserCount', ipName], function () {
            return customFetch({
                method: 'GET',
                url: "ip/setup/not_matched_count/".concat(ipName),
            });
        }, options);
    };
    //forms related hooks
    var useGetFormTemplate = function (formTemplateId) {
        return useQuery(['getFormTemplate', formTemplateId], function () {
            return customFetch({
                method: 'GET',
                url: "form/template/".concat(formTemplateId),
            });
        });
    };
    var useGetAllFormTemplates = function (options) {
        return useQuery(['allFormTemplates', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "form/template/all/".concat(accountId),
            });
        }, options);
    };
    var useGetAvailableSchemasInCollection = function (collectionId) {
        return useQuery(['availableFormSchemas', collectionId], function () {
            return customFetch({
                method: 'GET',
                url: "form/template/available-schemas/".concat(collectionId),
            });
        }, { enabled: collectionId !== undefined });
    };
    var useCreateFormTemplate = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'form/template',
            }, request);
        });
    };
    var useUpdateFormTemplate = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'PUT',
                url: 'form/template',
            }, request);
        });
    };
    var useDeleteFile = function () {
        return useMutation(function (url) {
            return customFetch({
                method: 'POST',
                url: 'pages/delete',
            }, {
                url: url,
            });
        });
    };
    //time off related hooks
    var useGetTimeoffSettingData = function () {
        return useQuery(['getTimeoffSettingData', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "timeoff/get_timeoff_settings/".concat(accountId),
            });
        });
    };
    var useGetTimeoffPolicyUserCounts = function () {
        return useQuery(['getTimeoffPolicyUserCounts'], function () {
            return customFetch({
                method: 'GET',
                url: "timeoff/get_policy_user_counts",
            });
        });
    };
    var useGetOvertimePolicyUserCounts = function () {
        return useQuery(['getOvertimePolicyUserCounts'], function () {
            return customFetch({
                method: 'GET',
                url: "overtime/get_policy_user_counts",
            });
        });
    };
    var useSaveTimeoffSetting = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'timeoff/save_rules',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useDeleteTimeoffPolicy = function () {
        return useMutation(function (policyId) {
            return customFetch({
                method: 'DELETE',
                url: "timeoff/delete_policy/".concat(policyId),
            });
        });
    };
    var useDeleteTimeoffRule = function () {
        return useMutation(function (ruleId) {
            return customFetch({
                method: 'DELETE',
                url: "timeoff/delete_rule/".concat(ruleId),
            });
        });
    };
    var useArchiveTimeoffType = function () {
        return useMutation(function (typeId) {
            return customFetch({
                method: 'POST',
                url: "timeoff/archive_type/".concat(typeId),
            });
        });
    };
    var useApplyAllTimeOffSetting = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'timeoff/apply_policy_to_all',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var disableTimeoffs = function () {
        return toastRequest({
            url: "timeoff/disable_timeoffs",
            method: 'POST',
        }, null, '', 'Successfully disabled time off!', '');
    };
    var enableTimeoffs = function () {
        return toastRequest({
            url: "timeoff/enable_timeoffs",
            method: 'POST',
        }, null, '', 'Successfully enabled time off!', '');
    };
    var useGetTimeoffsSetting = function () {
        return useQuery(['useGetTimeoffsSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "timeoff/get_timeoff_setting",
            });
        });
    };
    //break related hooks
    var disableBreaks = function () {
        return toastRequest({
            url: "breaks/disable_breaks",
            method: 'POST',
        }, null, '', 'Successfully disabled breaks!', '');
    };
    var enableBreaks = function () {
        return toastRequest({
            url: "breaks/enable_breaks",
            method: 'POST',
        }, null, '', 'Successfully enabled breaks!', '');
    };
    var useGetBreaksSetting = function () {
        return useQuery(['useGetBreaksSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "breaks/get_break_setting",
            });
        });
    };
    var useGetBreakSettingData = function () {
        return useQuery(['useGetBreakSettingData', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "breaks/break_settings/".concat(accountId),
            });
        });
    };
    var useGetBreakLocationCount = function () {
        return useQuery(['useGetBreakSettingData'], function () {
            return customFetch({
                method: 'GET',
                url: "breaks/get_policy_location_counts",
            });
        });
    };
    var useSaveBreakSetting = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'breaks/save_break_policies',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useDeleteBreakPolicy = function () {
        return useMutation(function (policyId) {
            return customFetch({
                method: 'DELETE',
                url: "breaks/delete_policy/".concat(policyId),
            });
        });
    };
    var useArchiveBreakRule = function () {
        return useMutation(function (ruleId) {
            return customFetch({
                method: 'POST',
                url: "breaks/archive_rule/".concat(ruleId),
            });
        });
    };
    // overtime related hooks
    var disableOvertime = function () {
        return toastRequest({
            url: "overtime/disable_overtime",
            method: 'POST',
        }, null, '', 'Successfully disabled overtime!', '');
    };
    var enableOvertime = function () {
        return toastRequest({
            url: "overtime/enable_overtime",
            method: 'POST',
        }, null, '', 'Successfully enabled overtime!', '');
    };
    var useGetOvertimeStatusSetting = function () {
        return useQuery(['useGetOvertimeStatusSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "overtime/get_overtime_setting",
            });
        });
    };
    var useGetOvertimeSettingData = function () {
        return useQuery(['useGetOvertimeSettingData', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "overtime/all/".concat(accountId),
            });
        });
    };
    var useDeleteOvertimePolicy = function () {
        return useMutation(function (policyId) {
            return customFetch({
                method: 'DELETE',
                url: "overtime/delete_policy/".concat(policyId),
            });
        });
    };
    var useApplyAllOvertimeSetting = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'overtime/apply_to_all',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetWeeklyAverageOvertimeEnabled = function (onSuccess) {
        return useQuery(['useGetWeeklyOvertimeEnabledSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "overtime/weekly_overtime_enabled_setting",
            });
        }, {
            onSuccess: function (data) {
                onSuccess(data || false);
            },
        });
    };
    var useSaveWeeklyAverageOvertimeEnabled = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'overtime/weekly_overtime_enabled_setting',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useSaveOvertimeSetting = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'overtime',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetOvertimeCalculationSetting = function () {
        return useQuery(['useGetOvertimeCalculationSetting', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'overtime/get_overtime_calculation_setting',
            });
        });
    };
    var useGetBillOvertimeCalculationSetting = function () {
        return useQuery(['useGetBillOvertimeCalculationSetting', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'overtime/get_bill_overtime_calculation_setting',
            });
        });
    };
    var saveOvertimeCalculationSetting = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'overtime/update_overtime_calculation_setting',
        }, request);
    };
    var saveBillOvertimeCalculationSetting = function (request) {
        return toastRequest({
            method: 'POST',
            url: 'overtime/update_bill_overtime_calculation_setting',
        }, request);
    };
    // access groups and permission
    var useGetAccountAllUsers = function () {
        return customFetch({
            method: 'POST',
            url: 'users/list',
        }, { accountId: accountId });
    };
    var getAccountAccessDataFetch = function () {
        return customFetch({
            method: 'GET',
            url: "permissions/account_access_groups/".concat(accountId),
        });
    };
    var useGetAccessGroupDetail = function (accessGroupId) {
        return customFetch({
            method: 'GET',
            url: "permissions/access_group/".concat(accessGroupId),
        });
    };
    var useCreateAccessGroup = function () {
        return useMutation(function (name) {
            return customFetch({
                method: 'POST',
                url: 'permissions/access_group',
            }, { accountId: accountId, name: name });
        });
    };
    var useUpdateAccessGroupMutation = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'permissions/access_group/update',
            }, request);
        });
    };
    var useDeleteAccessGroup = function () {
        return useMutation(function (accessGroupId) {
            return toastRequest({
                method: 'DELETE',
                url: "permissions/access_group/".concat(accessGroupId),
            });
        });
    };
    var useDuplicateAccessGroup = function () {
        return useMutation(function (accessGroupId) {
            return toastRequest({
                method: 'POST',
                url: 'permissions/access_group/duplicate',
            }, { accountId: accountId, accessGroupId: accessGroupId });
        });
    };
    var useMakeAccessGroupDefaultMutation = function () {
        return useMutation(function (accessGroupId) {
            return toastRequest({
                method: 'POST',
                url: 'permissions/access_group/make_default',
            }, { accessGroupId: accessGroupId });
        });
    };
    var useSaveAccessGroupDetails = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'permissions/update_access_group',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useGetWorkspacePermission = function (accessGroupId) {
        return useQuery(['useGetWorkspacePermission', accountId, accessGroupId], function () {
            return customFetch({
                method: 'POST',
                url: 'permissions/workspace',
            }, { accessGroupId: accessGroupId });
        });
    };
    var useGetDataSourcePermission = function (accessGroupId) {
        return useQuery(['useGetDataSourcePermission', accountId, accessGroupId], function () {
            return customFetch({
                method: 'POST',
                url: 'permissions/data_source',
            }, { accessGroupId: accessGroupId });
        });
    };
    var useGetObjectPermissionQuery = function (request) {
        var _a, _b, _c, _d, _e, _f;
        return useQuery([
            'useGetObjectPermission',
            accountId,
            (_a = request.body) === null || _a === void 0 ? void 0 : _a.accessGroupIds.toString(),
            (_b = request.body) === null || _b === void 0 ? void 0 : _b.type,
            (_c = request.body) === null || _c === void 0 ? void 0 : _c.objectId,
        ], function () {
            return customFetch({
                method: 'POST',
                url: 'permissions/object',
            }, request.body);
        }, {
            enabled: !!((_e = (_d = request.body) === null || _d === void 0 ? void 0 : _d.accessGroupIds) === null || _e === void 0 ? void 0 : _e.length) && ((_f = request.options) === null || _f === void 0 ? void 0 : _f.enabled),
        });
    };
    var useGetBatchObjectPermissionQuery = function (request) {
        var _a, _b, _c, _d, _e, _f, _g;
        var objectIds = ((_b = (_a = request.body) === null || _a === void 0 ? void 0 : _a.objectIds) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return uuidValidate(id); })) || [];
        return useQuery([
            'useGetBatchObjectPermission',
            accountId,
            (_c = request.body) === null || _c === void 0 ? void 0 : _c.objectIds.toString(),
            (_d = request.body) === null || _d === void 0 ? void 0 : _d.type,
            (_e = request.body) === null || _e === void 0 ? void 0 : _e.accessGroupId,
        ], function () {
            return customFetch({
                method: 'POST',
                url: 'permissions/get_batch_objects',
            }, request.body
                ? __assign(__assign({}, request.body), { objectIds: objectIds }) : undefined);
        }, {
            enabled: !!objectIds.length && ((_f = request.options) === null || _f === void 0 ? void 0 : _f.enabled) && !!((_g = request.body) === null || _g === void 0 ? void 0 : _g.accessGroupId),
        });
    };
    var useUpdateObjectPermission = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'permissions/object/update',
            }, request);
        });
    };
    var useGetGlobalFilterCollectionOptions = function () {
        return useQuery(['useGetGlobalFilterCollectionOptions'], function () {
            return customFetch({
                method: 'GET',
                url: 'permissions/global_filters/collection_options',
            });
        });
    };
    var useGetAccessGroupGlobalFiltersQuery = function (query) {
        var _a;
        return useQuery(['useGetAccessGroupGlobalFilters', accountId, query.accessGroupId], function () {
            return customFetch({
                method: 'GET',
                url: "permissions/global_filters/".concat(query.accessGroupId),
            });
        }, {
            enabled: !!query.accessGroupId && ((_a = query.options) === null || _a === void 0 ? void 0 : _a.enabled) && !!accountId,
        });
    };
    var useUpdateAccessGroupFilterMutation = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'permissions/global_filters/update/v2',
            }, request);
        });
    };
    var useGetMobileRequiredFields = function (query) {
        var _a;
        return useQuery(['useGetMobileRequiredFields', query.body], function () {
            return customFetch({
                method: 'POST',
                url: 'permissions/mobile_required_fields',
            }, query.body);
        }, {
            enabled: !!((_a = query.options) === null || _a === void 0 ? void 0 : _a.enabled),
        });
    };
    var useGetMobileProfileLinkedData = function (accessGroupId) {
        return useQuery(['useGetMobileProfileLinkedData', accessGroupId, accountId], function () {
            return customFetch({
                method: 'GET',
                url: "permissions/mobile_profile_linked_data/".concat(accessGroupId),
            });
        });
    };
    // business detail setting
    var useGetBusinessDetails = function () {
        return useQuery(['useGetBusinessDetails', accountId], function () {
            return customFetch({
                method: 'GET',
                url: "accounts/get_business_settings/".concat(accountId),
            });
        });
    };
    var useSaveBusinessDetails = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: "accounts/update_business_settings",
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useUpdateDeleteAccountLogoImg = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "accounts/update_business_settings",
            }, request);
        });
    };
    // project tracking aka job code related
    var disableProjectTracking = function () {
        return toastRequest({
            url: "projects/disable_project_tracking/".concat(accountId),
            method: 'POST',
        }, null, '', 'Successfully disabled job code!', '');
    };
    var enableProjectTracking = function () {
        return toastRequest({
            url: "projects/enable_project_tracking/".concat(accountId),
            method: 'POST',
        }, null, '', 'Successfully enabled job code!', '');
    };
    var useGetProjectTrackingSetting = function () {
        return useQuery(['useGetProjectTrackingSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "projects/get_project_tracking_setting/".concat(accountId),
            });
        });
    };
    var useGetProjectTrackingProjects = function (params) {
        return useQuery(['useGetProjectTrackingProjects'], function () {
            return customFetch({
                method: 'GET',
                url: "projects/list",
            });
        }, __assign({}, params === null || params === void 0 ? void 0 : params.options));
    };
    var useUpdateProjectName = function () {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'POST',
                url: "projects/update_name",
            }, requestBody, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useCreateProject = function () {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'POST',
                url: "projects/create",
            }, requestBody, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useDeleteProject = function () {
        return useMutation(function (projectId) {
            return toastRequest({
                method: 'DELETE',
                url: "projects/delete/".concat(projectId),
            });
        });
    };
    // Chat Setting
    var useGetChatSmsSetting = function () {
        return useQuery(['useGetChatSetting'], function () {
            return customFetch({
                method: 'GET',
                url: 'chat/sms_setting',
            });
        });
    };
    var useUpdateChatSmsSetting = function () {
        return useMutation(function (requestBody) {
            return toastRequest({
                method: 'POST',
                url: 'chat/sms_setting',
            }, requestBody, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    // Scheduling Settings
    var useGetSchedulingSettings = function () {
        return useQuery(['getSchedulingSetting', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'accounts/scheduling',
            });
        });
    };
    var useUpdateSchedulingSettings = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'accounts/scheduling',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    // Branding Settings
    var getBrandingOrganization = useOrganizationApi().getBrandingOrganization;
    var useGetBrandingOrganization = function (name, id, accountId) {
        return useQuery(['getAccountOrganization', name, id, accountId], function () { return getBrandingOrganization(name, id, accountId); }, {
            enabled: !!name || !!id || !!accountId,
            retry: false,
        });
    };
    var useInitBrandingSettings = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'organizations/setup',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    var useUpdateBrandingSettings = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'PUT',
                url: 'organizations/' + request.id,
            }, { settings: request.settings }, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    // Localization Settings
    var useGetLocalizationSettings = function () {
        return useQuery(['getLocalizationSettings', accountId], function () {
            return customFetch({
                method: 'GET',
                url: 'accounts/localization_settings',
            });
        });
    };
    var useUpdateLocalizationSettings = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'accounts/localization_settings',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    // Dashboard Settings
    var useGetDashboardSettings = function () {
        return useQuery(['getDashboardSetting'], function () {
            return customFetch({
                method: 'GET',
                url: "accounts/dashboard_settings",
            });
        });
    };
    var useUpdateDashboardSettings = function () {
        return useMutation(function (request) {
            return toastRequest({
                method: 'POST',
                url: 'accounts/dashboard_settings',
            }, request, '', ToastMessage.SAVE_SUCCESS, ToastMessage.SAVE_FAIL);
        });
    };
    return {
        useGetAccountPayRateSettingData: useGetAccountPayRateSettingData,
        useGetRecordQualifierPayRateSettingData: useGetRecordQualifierPayRateSettingData,
        useGetUserPayRateSettingData: useGetUserPayRateSettingData,
        updateUserIndividualPayRate: updateUserIndividualPayRate,
        useGetAvailableQualifyObject: useGetAvailableQualifyObject,
        useGetCollectionOptionList: useGetCollectionOptionList,
        useGetSelectionOptionList: useGetSelectionOptionList,
        useSavePayRateSettingData: useSavePayRateSettingData,
        useSaveRecordQualifierPayRateSettingData: useSaveRecordQualifierPayRateSettingData,
        usePopulateIndividualPaySettings: usePopulateIndividualPaySettings,
        getGustoCompanyProvision: getGustoCompanyProvision,
        enablePayRates: enablePayRates,
        disablePayRates: disablePayRates,
        enableIndividualPayRates: enableIndividualPayRates,
        disableIndividualPayRates: disableIndividualPayRates,
        useGetPayRatesSetting: useGetPayRatesSetting,
        rematchShiftsWage: rematchShiftsWage,
        useGetIpUnmatchedUserCount: useGetIpUnmatchedUserCount,
        useConnectToIp: useConnectToIp,
        useConnectToSignNow: useConnectToSignNow,
        useConnectToIntegrationClient: useConnectToIntegrationClient(),
        useConnectToIntegrationAPI: useConnectToIntegrationAPI(),
        usePeriodReSync: usePeriodReSync,
        useGetAvailableIpCollections: useGetAvailableIpCollections,
        useDisconnectIpCollection: useDisconnectIpCollection,
        useLinkCollections: useLinkCollections(),
        useGetSchemaMappingData: useGetSchemaMappingData,
        useSaveSchemasMappingResult: useSaveSchemasMappingResult,
        useGetProposedMatches: useGetProposedMatches,
        useSaveRecordMappingResult: useSaveRecordMappingResult,
        useGetIntegrationSyncSettingOptions: useGetIntegrationSyncSettingOptions,
        useGetDeltaUnmatchedUserInfo: useGetDeltaUnmatchedUserInfo,
        useSubmitDeltaMatchedUserPairs: useSubmitDeltaMatchedUserPairs(),
        useGetFormTemplate: useGetFormTemplate,
        useGetAllFormTemplates: useGetAllFormTemplates,
        useGetAvailableSchemasInCollection: useGetAvailableSchemasInCollection,
        useCreateFormTemplate: useCreateFormTemplate,
        useUpdateFormTemplate: useUpdateFormTemplate,
        useDeleteFile: useDeleteFile,
        useGetTimeoffSettingData: useGetTimeoffSettingData,
        useGetTimeoffPolicyUserCounts: useGetTimeoffPolicyUserCounts,
        useSaveTimeoffSetting: useSaveTimeoffSetting,
        useDeleteTimeoffPolicy: useDeleteTimeoffPolicy(),
        useDeleteTimeoffRule: useDeleteTimeoffRule(),
        useArchiveTimeoffType: useArchiveTimeoffType(),
        useApplyAllTimeOffSetting: useApplyAllTimeOffSetting(),
        enableTimeoffs: enableTimeoffs,
        disableTimeoffs: disableTimeoffs,
        useGetTimeoffsSetting: useGetTimeoffsSetting,
        useGetBreakSettingData: useGetBreakSettingData,
        useGetBreakLocationCount: useGetBreakLocationCount,
        useSaveBreakSetting: useSaveBreakSetting,
        useDeleteBreakPolicy: useDeleteBreakPolicy(),
        useArchiveBreakRule: useArchiveBreakRule(),
        enableBreaks: enableBreaks,
        disableBreaks: disableBreaks,
        useGetBreaksSetting: useGetBreaksSetting,
        useGetOvertimeSettingData: useGetOvertimeSettingData,
        useSaveOvertimeSetting: useSaveOvertimeSetting,
        useGetOvertimePolicyUserCounts: useGetOvertimePolicyUserCounts,
        useDeleteOvertimePolicy: useDeleteOvertimePolicy(),
        useApplyAllOvertimeSetting: useApplyAllOvertimeSetting(),
        useGetOvertimeStatusSetting: useGetOvertimeStatusSetting,
        enableOvertime: enableOvertime,
        disableOvertime: disableOvertime,
        useGeGeofenceStatusSetting: useGeGeofenceStatusSetting,
        enableGeofence: enableGeofence,
        disableGeofence: disableGeofence,
        useGetGeofenceData: useGetGeofenceData,
        useSaveGeofence: useSaveGeofence,
        useDeleteGeofence: useDeleteGeofence,
        useGetAccountAllUsers: useGetAccountAllUsers,
        getAccountAccessDataFetch: getAccountAccessDataFetch,
        useGetAccessGroupDetail: useGetAccessGroupDetail,
        useCreateAccessGroup: useCreateAccessGroup(),
        useDeleteAccessGroup: useDeleteAccessGroup(),
        useDuplicateAccessGroup: useDuplicateAccessGroup(),
        useMakeAccessGroupDefaultMutation: useMakeAccessGroupDefaultMutation(),
        useSaveAccessGroupDetails: useSaveAccessGroupDetails,
        useUpdateAccessGroupMutation: useUpdateAccessGroupMutation,
        useGetObjectPermissionQuery: useGetObjectPermissionQuery,
        useGetBatchObjectPermissionQuery: useGetBatchObjectPermissionQuery,
        useUpdateObjectPermission: useUpdateObjectPermission,
        useGetWorkspacePermission: useGetWorkspacePermission,
        useGetDataSourcePermission: useGetDataSourcePermission,
        useGetGlobalFilterCollectionOptions: useGetGlobalFilterCollectionOptions,
        useGetAccessGroupGlobalFiltersQuery: useGetAccessGroupGlobalFiltersQuery,
        useUpdateAccessGroupFilterMutation: useUpdateAccessGroupFilterMutation,
        useGetMobileRequiredFields: useGetMobileRequiredFields,
        useGetMobileProfileLinkedData: useGetMobileProfileLinkedData,
        useGetBusinessDetails: useGetBusinessDetails,
        useSaveBusinessDetails: useSaveBusinessDetails(),
        useUpdateDeleteAccountLogoImg: useUpdateDeleteAccountLogoImg(),
        useGetPayPeriodSettingData: useGetPayPeriodSettingData,
        useSavePayPeriod: useSavePayPeriod,
        useGetPayrollEnhancementSetting: useGetPayrollEnhancementSetting,
        useSavePayrollEnhancementSetting: useSavePayrollEnhancementSetting(),
        useGetBillableSeatsData: useGetBillableSeatsData,
        useCreateBillingPortal: useCreateBillingPortal,
        enableProjectTracking: enableProjectTracking,
        disableProjectTracking: disableProjectTracking,
        useGetProjectTrackingSetting: useGetProjectTrackingSetting,
        useGetProjectTrackingProjects: useGetProjectTrackingProjects,
        useUpdateProjectName: useUpdateProjectName(),
        useDeleteProject: useDeleteProject(),
        useCreateProject: useCreateProject(),
        useGetPayDifferentialSettingData: useGetPayDifferentialSettingData,
        useSavePayDifferentialSetting: useSavePayDifferentialSetting(),
        useSaveQuickMenuSettings: useSaveQuickMenuSettings(),
        useGetUserQuickMenuSettings: useGetUserQuickMenuSettings,
        useSaveUserQuickMenuSettings: useSaveUserQuickMenuSettings(),
        useGetOvertimeCalculationSetting: useGetOvertimeCalculationSetting,
        saveOvertimeCalculationSetting: saveOvertimeCalculationSetting,
        useGetWeeklyAverageOvertimeEnabled: useGetWeeklyAverageOvertimeEnabled,
        useSaveWeeklyAverageOvertimeEnabled: useSaveWeeklyAverageOvertimeEnabled,
        useGetBillOvertimeCalculationSetting: useGetBillOvertimeCalculationSetting,
        saveBillOvertimeCalculationSetting: saveBillOvertimeCalculationSetting,
        useGetChatSmsSetting: useGetChatSmsSetting,
        useUpdateChatSmsSetting: useUpdateChatSmsSetting(),
        useGetSchedulingSettings: useGetSchedulingSettings,
        useUpdateSchedulingSettings: useUpdateSchedulingSettings(),
        useGetBrandingOrganization: useGetBrandingOrganization,
        useInitBrandingSettings: useInitBrandingSettings(),
        useUpdateBrandingSettings: useUpdateBrandingSettings(),
        useGetDashboardSettings: useGetDashboardSettings,
        useUpdateDashboardSettings: useUpdateDashboardSettings(),
        useGetLocalizationSettings: useGetLocalizationSettings,
        useUpdateLocalizationSettings: useUpdateLocalizationSettings(),
    };
};
