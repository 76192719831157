import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Popover, Tabs } from 'antd';
import './IconSelectorPopover.scss';
import CustomIconPicker, { ICON_OPTION_SIZE } from './CustomIconPicker';
import CustomIconInline from './CustomIconInline';
import EmojiPicker from './EmojiPicker';
import { CUSTOM_EMOJIS } from './constant';
var TabPane = Tabs.TabPane;
var IconSelectorPopover = function (_a) {
    var _b;
    var currentIcon = _a.currentIcon, selectorVisible = _a.selectorVisible, onSelectorVisibleChange = _a.onSelectorVisibleChange, onSaveIcon = _a.onSaveIcon, onSaveEmoji = _a.onSaveEmoji, _c = _a.iconSize, iconSize = _c === void 0 ? ICON_OPTION_SIZE : _c, customIconOnly = _a.customIconOnly, _d = _a.isCircularIcon, isCircularIcon = _d === void 0 ? false : _d;
    var isEmoji = typeof currentIcon === 'string';
    var _e = useState(isEmoji ? 'emojis' : 'icons'), activeTab = _e[0], setActiveTab = _e[1];
    useEffect(function () {
        setActiveTab(isEmoji ? 'emojis' : 'icons');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectorVisible]);
    return (_jsx(Popover, { placement: "bottomLeft", content: _jsxs(Tabs, { className: "IconSelector", activeKey: activeTab, onChange: setActiveTab, destroyInactiveTabPane: true, children: [_jsx(TabPane, { tab: "Icons", children: selectorVisible && (_jsx(CustomIconPicker, { currentIcon: !isEmoji ? currentIcon : undefined, onSaveIcon: onSaveIcon })) }, "icons"), !customIconOnly && (_jsx(TabPane, { tab: "Emojis", children: _jsx(EmojiPicker, { onSaveEmoji: onSaveEmoji }) }, "emojis"))] }), trigger: "click", open: selectorVisible, onOpenChange: onSelectorVisibleChange, destroyTooltipOnHide: true, children: _jsx("div", { className: "PageInput mr-[2px] cursor-pointer", onClick: function () { return onSelectorVisibleChange(true); }, children: isEmoji ? (_jsx("span", { className: "emoji", children: currentIcon.startsWith(':') ? (_jsx("img", { height: iconSize + 6, width: iconSize + 6, src: (_b = CUSTOM_EMOJIS.find(function (emojiGroup) {
                        return emojiGroup.emojis[0].shortcodes === currentIcon;
                    })) === null || _b === void 0 ? void 0 : _b.emojis[0].skins[0].src })) : (currentIcon) })) : (_jsx(CustomIconInline, { iconUrl: currentIcon.content, iconSize: iconSize, backgroundColor: currentIcon.backgroundColor, className: "mx-[6px]", radius: isCircularIcon ? 9999 : 8 })) }) }));
};
export default React.memo(IconSelectorPopover);
