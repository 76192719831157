import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Menu, Modal, Popconfirm, Switch } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { disableScroll } from 'src/utils/Collection';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import { tableFiltersSelector, canUpdateFilterOnCurrentPageSelector, collectionTableCanEditSelector, scheduleGroupBySelector, scheduleShowAllSelector, scheduleShowShiftTimesSelector, scheduleTimeZoneSelector, } from 'src/state';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { scheduleTimeRangeState } from 'src/state/atoms/schedule';
import { useTimeZone } from 'src/hooks/component/useTimeZone';
import { useScheduleComponent, useScheduleNeedsCoverageComponent } from 'src/hooks/component';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { HIDE_START_DAY_OF_WEEK_FROM_CALENDAR } from 'src/components/FeatureGatingKeyConstant';
import ZiraDropdown from '../../ui/ZiraDropdown';
import DatePickerPopover from '../TableView/TableCell/DatePickerPopover';
import { CollectionTableType, ScheduleGroupBy, ValueDataType } from '../../shared';
import './ShowMoreSchedulingDropdown.scss';
import { useScheduleApi } from '../../hooks/api';
import HardConfirmWrapper from '../HardConfirmWrapper/HardConfirmWrapper';
import TimeZoneSelectModal from './TimeZoneSelectModal';
import StartDayOfWeekSelectModal from './MoreSchedulingOptions/StartDayOfWeekSelectModal';
import CardHeightOptionPopover from './TableView/SchedulingOptions/CardHeightOptionPopover';
var ShowMoreSchedulingDropdown = function (_a) {
    var tableId = _a.tableId, collectionId = _a.collectionId, type = _a.type, handlePrint = _a.handlePrint;
    var _b = useState(false), isDropdownVisible = _b[0], setIsDropdownVisible = _b[1];
    var _c = useState(false), isModalVisible = _c[0], setModalVisible = _c[1];
    var _d = useState(false), isCopySchedule = _d[0], setCopySchedule = _d[1];
    var _e = useState(false), isClearSchedule = _e[0], setClearSchedule = _e[1];
    var timeRange = useRecoilValue(scheduleTimeRangeState(tableId));
    var showAllGroupByOption = useRecoilValue(scheduleShowAllSelector(tableId));
    var groupBy = useRecoilValue(scheduleGroupBySelector(tableId));
    var showShiftTimes = useRecoilValue(scheduleShowShiftTimesSelector(tableId));
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(tableId));
    var _f = useState(false), isTimeZoneModalVisible = _f[0], setTimeZoneModalVisible = _f[1];
    var _g = useState([]), fromRange = _g[0], setFromRange = _g[1];
    var _h = useState([]), toRange = _h[0], setToRange = _h[1];
    var _j = useState(false), isStartOfWeekModalVisible = _j[0], setIsStartOfWeekModalVisible = _j[1];
    var isHideStartDayOfWeekFromCalendarView = useRecoilValue(featureEnableStatusState(HIDE_START_DAY_OF_WEEK_FROM_CALENDAR));
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var _k = useScheduleNeedsCoverageComponent(), refetchNeedsTemplatesInBottomBar = _k.refetchNeedsTemplatesInBottomBar, refetchCoverageTemplatesInBottomBar = _k.refetchCoverageTemplatesInBottomBar;
    useEffect(function () {
        setFromRange([
            moment(timeRange[0]).toISOString(),
            moment(timeRange[timeRange.length - 2]).toISOString(),
        ]);
        var currentScheduleViewDays = moment(timeRange[timeRange.length - 1]).diff(moment(timeRange[0]), 'days');
        setToRange([
            moment(timeRange[0]).add(currentScheduleViewDays, 'days').toISOString(),
            moment(timeRange[timeRange.length - 2])
                .add(currentScheduleViewDays, 'days')
                .toISOString(),
        ]);
    }, [timeRange, isCopySchedule, isClearSchedule]);
    var _l = useScheduleApi(), useCopySchedule = _l.useCopySchedule, useClearSchedule = _l.useClearSchedule;
    var copyMutation = useCopySchedule();
    var clearMutation = useClearSchedule();
    var refetchCollection = useRefetchCollection();
    var canUpdatePageFilter = useRecoilValue(canUpdateFilterOnCurrentPageSelector);
    var canEditCollectionPermission = useRecoilValue(collectionTableCanEditSelector({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
    }));
    var renderShowAllText = useMemo(function () {
        var option = '';
        if (groupBy === ScheduleGroupBy.USER) {
            option = 'Users';
        }
        else if (groupBy === ScheduleGroupBy.ROLE) {
            option = 'Roles';
        }
        else if (groupBy === ScheduleGroupBy.LOCATION) {
            option = 'Locations';
        }
        else if (groupBy === ScheduleGroupBy.TEMPLATE) {
            option = 'Templates';
        }
        return "Show All ".concat(option);
    }, [groupBy]);
    var menu = (_jsxs(Menu, { className: "menuWrapper", onClick: function (e) {
            if (['showAll', 'showShiftTimes', 'cardDensity'].includes(e.key)) {
                return;
            }
            setIsDropdownVisible(false);
            disableScroll(false);
        }, children: [canUpdatePageFilter && (_jsxs(_Fragment, { children: [type === 'block' && (_jsx(Menu.Item, { onClick: function () {
                            applyScheduleDisplaySettings(tableId, {
                                showAll: !showAllGroupByOption,
                            }, type);
                        }, children: _jsxs("div", { className: "flex h-[38px] items-center justify-between", children: [_jsx("div", { children: renderShowAllText }), _jsx(Switch, { checked: showAllGroupByOption, size: "small", className: "ml-3 mt-0" })] }) }, "showAll")), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { onClick: function () {
                            applyScheduleDisplaySettings(tableId, {
                                showShiftTimes: !showShiftTimes,
                            }, type);
                        }, children: _jsxs("div", { className: "flex h-[38px] items-center justify-between", children: ["Show Shift Times", _jsx(Switch, { checked: showShiftTimes, size: "small", className: "mt-0" })] }) }, "showShiftTimes"), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { children: _jsx(CardHeightOptionPopover, { tableId: tableId, type: type, children: _jsx("div", { className: "flex h-[38px] items-center", children: "Card Height" }) }) }, "cardDensity"), _jsx(Menu.Divider, {})] })), canEditCollectionPermission && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { className: "color-coding-entry", onClick: function () {
                            setCopySchedule(true);
                            setClearSchedule(false);
                            setModalVisible(true);
                        }, children: "Copy Schedule" }), _jsx(Menu.Divider, {})] })), canUpdatePageFilter && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { className: "color-coding-entry", onClick: function () {
                            setClearSchedule(true);
                            setCopySchedule(false);
                            setModalVisible(true);
                        }, children: "Clear Schedule" }), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { className: "color-coding-entry", onClick: function () { return setTimeZoneModalVisible(true); }, children: "Timezone Selector" }), _jsx(Menu.Divider, {}), !isHideStartDayOfWeekFromCalendarView && (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { className: "color-coding-entry", onClick: function () { return setIsStartOfWeekModalVisible(true); }, children: "Start Day of Week" }), _jsx(Menu.Divider, {})] }))] })), _jsx(Menu.Item, { className: "color-coding-entry", onClick: handlePrint, children: "Print" })] }));
    var onMutateScheduleSuccess = useCallback(function () {
        refetchCollection(collectionId);
        setCopySchedule(false);
        setClearSchedule(false);
    }, [collectionId, refetchCollection]);
    var mutateSchedule = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var release = snapshot.retain();
            try {
                setModalVisible(false);
                if (fromRange.length == 0 || toRange.length == 0)
                    return;
                var fromStart = moment(fromRange[0]).startOf('day').format('YYYY-MM-DD');
                var fromEnd = fromRange.length == 1
                    ? moment(fromRange[0]).startOf('day').format('YYYY-MM-DD')
                    : moment(fromRange[1]).startOf('day').format('YYYY-MM-DD');
                var toStart = moment(toRange[0]).startOf('day').format('YYYY-MM-DD');
                var toEnd = toRange.length == 1
                    ? moment(toRange[0]).startOf('day').format('YYYY-MM-DD')
                    : moment(toRange[1]).startOf('day').format('YYYY-MM-DD');
                var filters = snapshot.getLoadable(tableFiltersSelector(tableId)).valueMaybe();
                if (!filters)
                    return;
                if (isCopySchedule) {
                    copyMutation.mutate({
                        blockId: tableId,
                        fromStart: fromStart,
                        fromEnd: fromEnd,
                        toStart: toStart,
                        toEnd: toEnd,
                        timeZone: timeZone,
                        filters: filters,
                    }, {
                        onSuccess: function () {
                            onMutateScheduleSuccess();
                        },
                    });
                }
                else if (isClearSchedule) {
                    clearMutation.mutate({
                        blockId: tableId,
                        from: fromStart,
                        to: fromEnd,
                        timeZone: timeZone,
                        filters: filters,
                    }, {
                        onSuccess: function () {
                            onMutateScheduleSuccess();
                            refetchCoverageTemplatesInBottomBar();
                            refetchNeedsTemplatesInBottomBar();
                        },
                    });
                }
            }
            finally {
                release();
            }
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId, fromRange, isClearSchedule, isCopySchedule, onMutateScheduleSuccess, toRange]);
    var renderCopySchedule = useCallback(function () {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "copyScheduleTitle", children: "Copy Schedule" }), _jsx(Divider, {}), _jsxs("div", { className: "copyScheduleRow copyFrom", children: [_jsx("div", { className: "copyScheduleLabel", children: "Copy from" }), _jsx(DatePickerPopover, { data: {
                                type: ValueDataType.DATETIME_RANGE,
                                from: fromRange[0],
                                to: fromRange[1],
                                showTime: false,
                            }, onEditTime: function (range) { return setFromRange(range); }, canEdit: true, isDetailModal: true, timeZone: timeZone })] }), _jsxs("div", { className: "copyScheduleRow", children: [_jsx("div", { className: "copyScheduleLabel", children: "Copy to" }), _jsx(DatePickerPopover, { data: {
                                type: ValueDataType.DATETIME_RANGE,
                                from: toRange[0],
                                to: toRange[1],
                                showTime: false,
                            }, onEditTime: function (range) { return setToRange(range); }, canEdit: true, isDetailModal: true, timeZone: timeZone })] })] }));
    }, [fromRange, timeZone, toRange]);
    var renderClearSchedule = useCallback(function () {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "copyScheduleTitle", children: "Clear Schedule" }), _jsx(Divider, {}), _jsxs("div", { className: "copyScheduleRow copyFrom", children: [_jsx("div", { className: "copyScheduleLabel", children: "Clear from" }), _jsx(DatePickerPopover, { data: {
                                type: ValueDataType.DATETIME,
                                dateTime: fromRange[0],
                                showTime: false,
                            }, onEditTime: function (range) {
                                setFromRange(function (prev) {
                                    prev.splice(0, 1, range[0]);
                                    return cloneDeep(prev);
                                });
                            }, canEdit: true, isDetailModal: true, timeZone: timeZone })] }), _jsxs("div", { className: "copyScheduleRow", children: [_jsx("div", { className: "copyScheduleLabel", children: "Clear to" }), _jsx(DatePickerPopover, { data: {
                                type: ValueDataType.DATETIME,
                                dateTime: fromRange[1],
                                showTime: false,
                            }, onEditTime: function (range) {
                                setFromRange(function (prev) {
                                    prev.splice(1, 1, range[0]);
                                    return cloneDeep(prev);
                                });
                            }, canEdit: true, isDetailModal: true, timeZone: timeZone })] })] }));
    }, [fromRange, timeZone]);
    var timezoneAbbr = useTimeZone(timeZone).timezoneAbbr;
    var renderFromTimeRangeText = useCallback(function () {
        var from = moment(fromRange[0]).format('MMM D, YYYY');
        var to = moment(fromRange[1]).format('MMM D, YYYY');
        return "from ".concat(from, " to ").concat(to, " ").concat(timezoneAbbr(true));
    }, [fromRange, timezoneAbbr]);
    var renderToTimeRangeText = useCallback(function () {
        var from = moment(toRange[0]).format('MMM D, YYYY');
        var to = moment(toRange[1]).format('MMM D, YYYY');
        return "".concat(from, " to ").concat(to, " ").concat(timezoneAbbr(true));
    }, [timezoneAbbr, toRange]);
    var modalFooter = useMemo(function () { return (_jsxs("div", { className: "flex flex-row gap-2 justify-end", children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: function () { return setModalVisible(false); }, children: "Cancel" }), isClearSchedule && (_jsx(HardConfirmWrapper, { changeType: "clear", entityName: "schedule ".concat(renderFromTimeRangeText()), onConfirm: mutateSchedule, children: _jsx(Button, { className: "ZiraModalSaveBtn", children: "Apply" }) })), isCopySchedule && (_jsx(Popconfirm, { title: _jsxs("div", { children: ["Are you sure you want to copy schedule ", renderFromTimeRangeText(), _jsx("br", {}), " over to ", renderToTimeRangeText(), " ?"] }), onConfirm: mutateSchedule, placement: "top", okText: "Copy", children: _jsx(Button, { className: "ZiraModalSaveBtn", children: "Apply" }) }))] })); }, [
        isClearSchedule,
        isCopySchedule,
        mutateSchedule,
        renderFromTimeRangeText,
        renderToTimeRangeText,
    ]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraDropdown, { placement: "bottomRight", overlay: menu, trigger: ['click'], overlayClassName: "MoreScheduleOptionDropdown", overlayStyle: { minWidth: '200px' }, open: isDropdownVisible, onLocalVisibleChange: setIsDropdownVisible, children: _jsx(EllipsisOutlined, { className: "EllipsisIcon" }) }), _jsx(Modal, { open: isModalVisible, closable: true, centered: true, width: 560, footer: modalFooter, destroyOnClose: true, onCancel: function () { return setModalVisible(false); }, children: isCopySchedule ? renderCopySchedule() : renderClearSchedule() }), _jsx(TimeZoneSelectModal, { isTimeZoneModalVisible: isTimeZoneModalVisible, onTimeZoneModalVisibleChange: setTimeZoneModalVisible, tableId: tableId, type: type }), _jsx(StartDayOfWeekSelectModal, { isModalVisible: isStartOfWeekModalVisible, onModalVisibleChange: setIsStartOfWeekModalVisible, tableId: tableId, type: type })] }));
};
export default React.memo(ShowMoreSchedulingDropdown);
