var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { getTagTextColor } from 'src/utils/Collection';
import UserAvatar from 'src/components/User/UserAvatar';
import { useShiftRecommendationsApi } from 'src/hooks/api/useShiftRecommendationsApi';
import { useRecoilValue } from 'recoil';
import { OPTIONS_COLOR_MAP } from 'src/shared';
import { Divider } from 'antd';
import { RecommendShiftIcon } from 'src/utils/icons/SchedulingIcons/RecommendShiftIcon';
import UserEvaluationPopover from 'src/components/ScheduleView/UserEvaluationPopover';
import { SHIFT_RECOMMENDATIONS_RECOMMENDATION_BUTTON_WIDTH, ShiftRecommendationStatus, ShiftRecommendationGroupColorMap, ShiftRecommendationGroupDisplayNameMap, ShiftRecommendationMatchGroupSet, } from './constant';
import { shiftRecommendationsModalPropsState } from './atom';
var ShiftRecommendationsUserItem = function (_a) {
    var user = _a.user, groupType = _a.groupType, canRecommend = _a.canRecommend, style = _a.style, onRefetchGroup = _a.onRefetchGroup;
    var recordId = user.recordId, name = user.name, avatarUrl = user.avatarUrl, indictmentNames = user.indictmentNames, shiftRecommendationStatus = user.shiftRecommendationStatus;
    var shiftRId = useRecoilValue(shiftRecommendationsModalPropsState).recordId;
    var useRecommendShiftToUser = useShiftRecommendationsApi().useRecommendShiftToUser;
    var recommendShiftToUser = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!canRecommend)
                        return [2 /*return*/];
                    return [4 /*yield*/, useRecommendShiftToUser.mutateAsync({
                            shiftRid: shiftRId,
                            userRids: [recordId],
                        }, {
                            onSuccess: function () {
                                onRefetchGroup();
                            },
                        })
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                    ];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [canRecommend, onRefetchGroup, recordId, shiftRId]);
    return (_jsxs("div", { style: style, children: [_jsxs("div", { className: "flex items-center font-medium overflow-hidden w-full", children: [_jsxs("div", { className: "flex items-center space-x-[8px] text-base max-w-1/2", children: [_jsx(UserAvatar, { name: name, isCirclular: true, size: 32, avatar: avatarUrl }), _jsx("span", { className: "w-full overflow-ellipsis overflow-hidden", children: name })] }), _jsxs("div", { className: "ml-auto flex space-x-[8px] text-sm", children: [ShiftRecommendationMatchGroupSet.has(groupType) && (_jsx(UserEvaluationPopover, { trigger: "click", indictmentNames: indictmentNames, children: _jsx("div", { className: "ShiftRecommendationsTag cursor-pointer w-[32px]", style: {
                                        backgroundColor: user.indictmentNames.length === 0
                                            ? OPTIONS_COLOR_MAP.Green
                                            : ShiftRecommendationGroupColorMap[groupType],
                                        color: getTagTextColor(user.indictmentNames.length === 0
                                            ? OPTIONS_COLOR_MAP.Green
                                            : ShiftRecommendationGroupColorMap[groupType]),
                                    }, children: user.indictmentNames.length === 0 ? (_jsx(CheckOutlined, {})) : (user.indictmentNames.length) }) })), shiftRecommendationStatus && (_jsxs("div", { className: "ShiftRecommendationsTag space-x-[4px] ", style: {
                                    backgroundColor: ShiftRecommendationGroupColorMap[shiftRecommendationStatus],
                                    color: getTagTextColor(ShiftRecommendationGroupColorMap[shiftRecommendationStatus]),
                                    width: SHIFT_RECOMMENDATIONS_RECOMMENDATION_BUTTON_WIDTH,
                                }, children: [user.shiftRecommendationStatus ===
                                        ShiftRecommendationStatus.ASSIGNED && _jsx(CheckOutlined, {}), _jsx("div", { children: ShiftRecommendationGroupDisplayNameMap[shiftRecommendationStatus] })] })), !shiftRecommendationStatus && (_jsxs("div", { className: "ShiftRecommendationsTag space-x-[4px] bg-[#F2F8FA] ", style: {
                                    width: SHIFT_RECOMMENDATIONS_RECOMMENDATION_BUTTON_WIDTH,
                                    opacity: canRecommend ? 1 : 0.5,
                                    cursor: canRecommend ? 'pointer' : 'not-allowed',
                                    pointerEvents: canRecommend ? 'all' : 'none',
                                }, onClick: recommendShiftToUser, children: [_jsx(RecommendShiftIcon, {}), _jsx("span", { children: "Recommend" })] }))] })] }), _jsx(Divider, {})] }));
};
export default React.memo(ShiftRecommendationsUserItem);
