var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { toUpper, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import DatePickerPopover from 'src/components/TableView/TableCell/DatePickerPopover';
import { useAccountApi } from 'src/hooks/api';
import { useTimeZone } from 'src/hooks/component/useTimeZone';
import { getDisplayedValueData, TimeOffApprovalStatusFilterOptions } from 'src/services';
import { blockTimeZoneState } from 'src/state';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { usePaginationWithSearch } from 'src/hooks/component';
import { CellInputMode, ClauseToHideFilterValueSet, convertCurrentUserClause, NativeMultiSet, NativePath, NativeTextInputSet, RecordMetricType, SELECTION_DROPDOWN_PAGE_SIZE, specialDefineEmptyClauseTypes, ValueDataType, } from 'src/shared';
import { dropdownListFilterInputResult, getIfNeedsFetchDataInCell, getNativeType, getTagTextColor, } from 'src/utils/Collection';
import { FilterValueType, } from 'src/components/CenterPanel/Interface';
import '../Filter/FilterEntry.scss';
var Option = Select.Option;
var FilterValueSelector = React.memo(function (props) {
    var schema = props.schema, initFilterValue = props.initFilterValue, onChange = props.onChange, value = props.value, showCurrentUserFilter = props.showCurrentUserFilter;
    var adjustedSchema = schema.secondaryFilter || schema;
    var _a = useAccountApi(), useGetUniversalSimpleList = _a.useGetUniversalSimpleList, useGetObjSimpleList = _a.useGetObjSimpleList;
    // handle selector options fetching
    var _b = useState(initFilterValue), availableFilterOptions = _b[0], setAvailableFilterOptions = _b[1];
    var _c = useState(false), isFocused = _c[0], setIsFocused = _c[1];
    var needsFetchData = getIfNeedsFetchDataInCell(adjustedSchema).needsFetchData;
    var isNativeBooleanType = useMemo(function () { return adjustedSchema.type === ValueDataType.NATIVE_BOOL; }, [adjustedSchema.type]);
    var isTimeOffRequestNativePath = useMemo(function () { return adjustedSchema.nativePath === NativePath.TIMEOFF_APPROVAL_STATUS; }, [adjustedSchema.nativePath]);
    var _d = usePaginationWithSearch({}), page = _d.page, searchText = _d.searchText, canLoadMore = _d.canLoadMore, setCanLoadMore = _d.setCanLoadMore, onScroll = _d.onScroll, onSearch = _d.onSearch, setIsLoading = _d.setIsLoading, resetPage = _d.resetPage;
    var _e = useGetUniversalSimpleList({
        body: {
            schemaId: adjustedSchema.id,
            pagination: {
                page: page,
                size: SELECTION_DROPDOWN_PAGE_SIZE,
            },
            query: searchText,
        },
        options: {
            enabled: (isFocused ||
                !value ||
                !availableFilterOptions.find(function (op) { return op.id === value; })) &&
                !isNativeBooleanType &&
                !isTimeOffRequestNativePath &&
                schema.id !== 'request_claimers' &&
                canLoadMore,
            onSuccess: function (data) {
                if (data) {
                    setCanLoadMore(data.length > 0);
                }
            },
            onSettled: function () {
                setIsLoading(false);
            },
        },
    }, schema.refCollectionId), data = _e.data, isFetchingUniversalList = _e.isFetching;
    var _f = useGetObjSimpleList('users', {
        body: {},
        options: {
            enabled: adjustedSchema.id === 'request_claimers',
        },
    }), objData = _f.data, isFetchingClaimUsers = _f.isFetching;
    useEffect(function () {
        var options = [];
        if (data && !isNativeBooleanType && !isTimeOffRequestNativePath) {
            options = data.map(function (option) { return ({
                id: option.id,
                label: option.name,
                color: option.color,
            }); });
            if ((showCurrentUserFilter !== null && showCurrentUserFilter !== void 0 ? showCurrentUserFilter : true) &&
                [ValueDataType.NATIVE_USER_ID, ValueDataType.USER].includes(schema.type)) {
                options.unshift({ id: 'current_user', label: 'Current User' });
            }
        }
        else if (isNativeBooleanType) {
            options = [
                { id: 'true', label: 'Yes' },
                { id: 'false', label: 'No' },
            ];
        }
        else if (isTimeOffRequestNativePath) {
            options = TimeOffApprovalStatusFilterOptions;
        }
        else if (objData) {
            if ('users' in objData) {
                options = objData.users.map(function (user) { return ({
                    id: user.recordId,
                    label: user.name,
                }); });
            }
        }
        if (page === 0) {
            setAvailableFilterOptions(options);
        }
        else {
            setAvailableFilterOptions(function (prev) { return uniqBy(__spreadArray(__spreadArray([], prev, true), options, true), 'id'); });
        }
        if (options.length && !value) {
            // Default to first option
            onChange === null || onChange === void 0 ? void 0 : onChange(options[0].id, []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, objData, isNativeBooleanType, schema.type]);
    useEffect(function () {
        resetPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema]);
    var multiSelectorOptions = useCallback(function () {
        if (needsFetchData || availableFilterOptions.length) {
            return availableFilterOptions.map(function (option) { return (_jsx(Option, { value: option.id, children: _jsx("span", { className: "px-1", style: {
                        color: getTagTextColor(option.color),
                        backgroundColor: option.color,
                        border: '1px solid ' + option.color,
                    }, children: option.label }) }, option.id)); });
        }
        return ((adjustedSchema === null || adjustedSchema === void 0 ? void 0 : adjustedSchema.values) || []).map(function (op) { return (_jsx(Option, { value: op.id, children: getDisplayedValueData(op.data) }, op.id)); });
    }, [availableFilterOptions, needsFetchData, adjustedSchema]);
    var onFilterOption = useCallback(function (input, option) {
        var search = input.trim().toLowerCase();
        var optionItem = availableFilterOptions.find(function (item) { return item.id === option.value; });
        return optionItem ? dropdownListFilterInputResult(search, optionItem.label) : false;
    }, [availableFilterOptions]);
    var isFetchingData = isFetchingUniversalList || isFetchingClaimUsers;
    return (_jsx(Select, __assign({}, props, { filterOption: onFilterOption, showAction: ['focus', 'click'], onSearch: onSearch, onPopupScroll: onScroll, onDropdownVisibleChange: function (visible) {
            setIsFocused(visible);
            if (!visible) {
                resetPage();
            }
        }, loading: isFetchingData, children: multiSelectorOptions() })));
});
FilterValueSelector.displayName = 'FilterValueSelector';
var getIfSelectorType = function (schema) {
    var inputMode = schema.inputMode, type = schema.type, nativePath = schema.nativePath;
    var needsFetchData = getIfNeedsFetchDataInCell(schema).needsFetchData;
    return (inputMode === CellInputMode.MULTI ||
        inputMode === CellInputMode.SINGLE ||
        (inputMode === CellInputMode.USER_DEFINED_GENERATED &&
            type === ValueDataType.PLAIN_SELECTION) ||
        NativeMultiSet.has(nativePath) ||
        needsFetchData ||
        getNativeType(type) === ValueDataType.BOOLEAN ||
        nativePath === NativePath.SHIFT_STATUS ||
        nativePath === NativePath.PLACEMENT_STATUS ||
        getNativeType(type) === ValueDataType.NATIVE_SELECTION ||
        nativePath === NativePath.USER_ACCESS_GROUPS ||
        nativePath === NativePath.SHIFT_TEMPLATE_ID ||
        nativePath === NativePath.TIMEOFF_APPROVAL_STATUS ||
        nativePath === NativePath.SHIFT_GROUP_ID);
};
var FilterEntry = React.memo(function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var columns = props.columns, existingFilter = props.existingFilter, onDeleteEntry = props.onDeleteEntry, onEditEntry = props.onEditEntry, options = props.options, blockId = props.blockId, currentGroupBySchemaId = props.currentGroupBySchemaId;
    var _j = options || {}, lockClose = _j.lockClose, lockOpen = _j.lockOpen, className = _j.className, autoFocusOnDropdown = _j.autoFocusOnDropdown, showCurrentUserFilter = _j.showCurrentUserFilter;
    var blockTimeZone = useRecoilValue(blockTimeZoneState(blockId !== null && blockId !== void 0 ? blockId : ''));
    var _k = useTimeZone(blockTimeZone), timeZone = _k.timeZone, timeFormatFn = _k.timeFormatFn;
    var columnsContentArr = Object.values(columns);
    var existingColumn = existingFilter
        ? existingFilter.secondaryFilter
            ? columns[existingFilter.secondaryFilter.uniqueId]
            : columns[existingFilter.property]
        : undefined;
    var _l = useState(existingColumn
        ? ((_a = existingColumn.secondaryFilter) === null || _a === void 0 ? void 0 : _a.uniqueId)
            ? (_b = existingColumn.secondaryFilter) === null || _b === void 0 ? void 0 : _b.uniqueId
            : existingColumn.uniqueId
        : columnsContentArr[0].uniqueId), chosenColumn = _l[0], setChosenColumn = _l[1];
    var _m = useState((_c = columns[chosenColumn].type) !== null && _c !== void 0 ? _c : ValueDataType.TEXT), inputType = _m[0], setInputType = _m[1];
    var defaultClauseValue = (columnsContentArr[0].secondaryFilter || columnsContentArr[0])
        .modifiers[0].value;
    var _o = useState(existingFilter
        ? convertCurrentUserClause(((_d = existingFilter.secondaryFilter) === null || _d === void 0 ? void 0 : _d.clauseValue) || existingFilter.clauseValue, (_f = (((_e = existingFilter.secondaryFilter) === null || _e === void 0 ? void 0 : _e.filterValue) || (existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.filterValue))) !== null && _f !== void 0 ? _f : '') || defaultClauseValue
        : defaultClauseValue), clause = _o[0], setClause = _o[1];
    var _p = useState(existingColumn && getIfSelectorType(existingColumn.secondaryFilter || existingColumn)
        ? ((_g = existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) === null || _g === void 0 ? void 0 : _g.filterValue) || (existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.filterValue) || ''
        : ''), multiSelectedValue = _p[0], setMultiSelectedValue = _p[1];
    var isCurrentUserValue = multiSelectedValue === 'current_user';
    var _q = useState(((_h = existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) === null || _h === void 0 ? void 0 : _h.filterValue) || (existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.filterValue) || ''), inputValueForFilter = _q[0], setInputValueForFilter = _q[1];
    var needsFetchData = getIfNeedsFetchDataInCell(columns[chosenColumn].secondaryFilter || columns[chosenColumn]).needsFetchData;
    var getUpdatedFilterSetting = useCallback(function (column, clauseValue, filterValue, clauseType) {
        var secondaryColumn = column.secondaryFilter;
        if (secondaryColumn) {
            return {
                id: existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.id,
                property: column.id,
                clauseValue: 'option_contains',
                clauseType: column.type,
                filterValue: [],
                uniqueId: column.uniqueId,
                secondaryFilter: {
                    property: secondaryColumn.id,
                    clauseType: clauseType || secondaryColumn.type,
                    clauseValue: clauseValue,
                    filterValue: filterValue,
                    uniqueId: column.uniqueId,
                    valueType: secondaryColumn.nativePath === NativePath.PLACEMENT_STATUS
                        ? FilterValueType.PLACEMENT_STATUS
                        : secondaryColumn.nativePath === NativePath.SHIFT_STATUS
                            ? FilterValueType.SHIFT_STATUS
                            : undefined,
                },
            };
        }
        else {
            return {
                id: existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.id,
                property: column.id,
                clauseType: clauseType || column.type,
                clauseValue: clauseValue,
                filterValue: filterValue,
                uniqueId: column.uniqueId,
                valueType: column.nativePath === NativePath.PLACEMENT_STATUS
                    ? FilterValueType.PLACEMENT_STATUS
                    : column.nativePath === NativePath.SHIFT_STATUS
                        ? FilterValueType.SHIFT_STATUS
                        : undefined,
            };
        }
    }, [existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.id]);
    var getFilterValue = useCallback(function (primary) {
        if (primary === void 0) { primary = false; }
        var _a = (!primary && columns[chosenColumn].secondaryFilter) || columns[chosenColumn], inputMode = _a.inputMode, nativePath = _a.nativePath, type = _a.type;
        var isInputText = inputMode === CellInputMode.PLAIN ||
            (inputMode === CellInputMode.NATIVE &&
                type !== ValueDataType.NATIVE_BOOL &&
                !needsFetchData &&
                !NativeMultiSet.has(nativePath));
        return (isInputText ? inputValueForFilter : multiSelectedValue) || '';
    }, [columns, chosenColumn, needsFetchData, inputValueForFilter, multiSelectedValue]);
    var onChangeColumnSelect = useCallback(function (value) {
        var _a;
        setChosenColumn(value);
        var foundColumn = columns[value];
        var tempInputType = foundColumn === null || foundColumn === void 0 ? void 0 : foundColumn.type;
        var tempSecondaryInputType = (_a = foundColumn.secondaryFilter) === null || _a === void 0 ? void 0 : _a.type;
        setInputType(tempInputType);
        var clauseOptions = (foundColumn.secondaryFilter || foundColumn).modifiers;
        var clauseValue = clauseOptions[0].value;
        setClause(clauseValue);
        setInputValueForFilter('');
        var filterSelectValue = '';
        setMultiSelectedValue(filterSelectValue);
        onEditEntry(getUpdatedFilterSetting(foundColumn, clauseValue, filterSelectValue, tempSecondaryInputType || tempInputType));
    }, [columns, onEditEntry, getUpdatedFilterSetting]);
    var onChangeFilterClause = useCallback(function (value) {
        var _a, _b;
        setClause(value);
        var foundColumn = columns[chosenColumn];
        var clauseValue = (_b = (_a = (foundColumn.secondaryFilter || foundColumn).modifiers.find(function (m) { return m.value === value; })) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
        if (isCurrentUserValue && clauseValue) {
            clauseValue = convertCurrentUserClause(clauseValue, multiSelectedValue);
        }
        onEditEntry(getUpdatedFilterSetting(foundColumn, clauseValue, ClauseToHideFilterValueSet.has(clauseValue) ? undefined : getFilterValue()));
    }, [
        chosenColumn,
        columns,
        getFilterValue,
        isCurrentUserValue,
        multiSelectedValue,
        onEditEntry,
        getUpdatedFilterSetting,
    ]);
    var onEditTextInputEntry = useCallback(function (e) {
        var _a;
        setInputValueForFilter(e.target.value);
        var foundColumn = columns[chosenColumn];
        onEditEntry(getUpdatedFilterSetting(foundColumn, ((_a = (foundColumn.secondaryFilter || foundColumn).modifiers.find(function (m) { return m.value === clause; })) === null || _a === void 0 ? void 0 : _a.value) || '', e.target.value));
    }, [chosenColumn, clause, columns, onEditEntry, getUpdatedFilterSetting]);
    var onEditTimePickerEntry = useCallback(function (newTime) {
        var _a;
        var filterTimeValue = timeFormatFn(newTime[0], 'MMM D, YYYY h:mm A', false, true);
        setInputValueForFilter(filterTimeValue);
        var foundColumn = columns[chosenColumn];
        onEditEntry(getUpdatedFilterSetting(foundColumn, ((_a = (foundColumn.secondaryFilter || foundColumn).modifiers.find(function (m) { return m.value === clause; })) === null || _a === void 0 ? void 0 : _a.value) || '', filterTimeValue));
    }, [chosenColumn, clause, columns, onEditEntry, timeFormatFn, getUpdatedFilterSetting]);
    var onEditMultiSelectEntry = useCallback(function (value) {
        var _a;
        setMultiSelectedValue(!isCurrentUserValue ? value : [value]);
        var foundColumn = columns[chosenColumn];
        var clauseValue = ((_a = (foundColumn.secondaryFilter || foundColumn).modifiers.find(function (m) { return m.value === clause; })) === null || _a === void 0 ? void 0 : _a.value) || '';
        var filterValue = inputType === ValueDataType.NATIVE_BOOL || !isCurrentUserValue ? value : [value];
        if (value === 'current_user' || value[value.length - 1] === 'current_user') {
            // Selecting current_user as filter value
            clauseValue = convertCurrentUserClause(clauseValue, 'current_user');
            filterValue = undefined;
            setMultiSelectedValue('current_user');
        }
        onEditEntry(getUpdatedFilterSetting(foundColumn, clauseValue, filterValue, undefined));
    }, [
        chosenColumn,
        clause,
        columns,
        isCurrentUserValue,
        onEditEntry,
        getUpdatedFilterSetting,
        inputType,
    ]);
    var filterValueChangeBar = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var adjustedColumn = columns[chosenColumn].secondaryFilter || columns[chosenColumn];
        var inputMode = adjustedColumn.inputMode, type = adjustedColumn.type, name = adjustedColumn.name, recordMetricType = adjustedColumn.recordMetricType;
        // case 1: PLAIN TEXT
        var isTextInput = (inputMode === CellInputMode.PLAIN && type !== ValueDataType.DATETIME) ||
            (CellInputMode.NATIVE &&
                NativeTextInputSet.has(getNativeType(type)) &&
                inputMode !== CellInputMode.GENERATED) ||
            (inputMode === CellInputMode.USER_DEFINED_GENERATED &&
                (type == ValueDataType.GENERATED_ID || type == ValueDataType.TEXT));
        var isMetric = inputMode === CellInputMode.METRIC;
        var isGeneratedNumber = inputMode === CellInputMode.GENERATED &&
            getNativeType(type) === ValueDataType.NATIVE_NUMBER;
        var isRecordMetricNumber = type === ValueDataType.RECORD_METRIC &&
            recordMetricType === RecordMetricType.NUMBER_METRIC;
        var isRollupType = type === ValueDataType.ROLLUP && inputMode === CellInputMode.GENERATED;
        var isRatingType = type === ValueDataType.RATING && inputMode === CellInputMode.RATING;
        // case 2: DATETIME
        var isNativeTimeInput = inputMode === CellInputMode.NATIVE && getNativeType(type) === ValueDataType.DATETIME;
        var isRecordMetricDate = type === ValueDataType.RECORD_METRIC &&
            recordMetricType === RecordMetricType.DATETIME_METRIC;
        //TODO: how to handle time range?
        var isPlainTimeInput = inputMode === CellInputMode.PLAIN && type === ValueDataType.DATETIME;
        // case 3: SELECT
        var isSelect = getIfSelectorType(adjustedColumn);
        if (isTextInput ||
            isMetric ||
            isGeneratedNumber ||
            isRecordMetricNumber ||
            isRollupType ||
            isRatingType) {
            return (_jsx(Input, { placeholder: 'Value', className: "FilterInput", defaultValue: inputValueForFilter, onChange: onEditTextInputEntry, onBlur: onEditTextInputEntry, onPressEnter: function (e) {
                    e.currentTarget.blur();
                }, autoFocus: !!inputValueForFilter }));
        }
        else if (isNativeTimeInput || isPlainTimeInput || isRecordMetricDate) {
            var defaultTimeData = {
                type: ValueDataType.DATETIME,
                showTime: false,
                dateTime: (_b = (_a = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _a === void 0 ? void 0 : _a.filterValue) !== null && _b !== void 0 ? _b : '',
            };
            return (_jsx("div", { className: "ValueBorder", onClick: function (e) { return e.stopPropagation(); }, children: _jsx(DatePickerPopover, { data: defaultTimeData, onEditTime: onEditTimePickerEntry, canEdit: true, id: "FilterDatePicker", timeZone: timeZone, isFilter: true, zIndex: options === null || options === void 0 ? void 0 : options.popoverZIndex }) }));
        }
        else if (isSelect) {
            var displayNames_1 = ((_d = (_c = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _c === void 0 ? void 0 : _c.displayName) === null || _d === void 0 ? void 0 : _d.split(',')) || [];
            var initFilterValues = ['string', 'boolean'].includes(typeof ((_e = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _e === void 0 ? void 0 : _e.filterValue))
                ? [
                    {
                        id: ((_f = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _f === void 0 ? void 0 : _f.filterValue) || multiSelectedValue[0],
                        label: ((_g = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _g === void 0 ? void 0 : _g.displayName) ||
                            upperCaseStringToStartCase(toUpper(multiSelectedValue[0])),
                    },
                ]
                : (((_h = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _h === void 0 ? void 0 : _h.filterValue) || []).map(function (val, idx) {
                    return { id: val, label: displayNames_1[idx] || '' };
                });
            var nativePath = adjustedColumn.nativePath;
            var isNativePlacementStage = nativePath === NativePath.PLACEMENT_STAGE;
            var isNativeJobStatus = nativePath === NativePath.JOB_STATUS;
            var isMultiSelectNativeSelection = isNativeJobStatus || isNativePlacementStage;
            var isNativeSelection = type === ValueDataType.NATIVE_SELECTION;
            var isMultiSelectFilter = !isCurrentUserValue &&
                !['true', 'false'].includes(multiSelectedValue) &&
                (isMultiSelectNativeSelection || !isNativeSelection) &&
                !['option_is', 'option_is_not', 'generated_is'].includes((_k = (_j = ((existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.secondaryFilter) || existingFilter)) === null || _j === void 0 ? void 0 : _j.clauseValue) !== null && _k !== void 0 ? _k : '');
            return (_jsx(FilterValueSelector, { onClick: function (e) { return e.stopPropagation(); }, showSearch: true, mode: isMultiSelectFilter ? 'multiple' : undefined, value: multiSelectedValue, onChange: onEditMultiSelectEntry, className: "FilterSelect FilterValue", dropdownMatchSelectWidth: false, popupClassName: "DropdownSelect", onMouseEnter: lockClose, onMouseLeave: lockOpen, schema: columns[chosenColumn].secondaryFilter
                    ? columns[chosenColumn].secondaryFilter
                    : columns[chosenColumn], initFilterValue: initFilterValues, autoFocus: (autoFocusOnDropdown !== null && autoFocusOnDropdown !== void 0 ? autoFocusOnDropdown : true) && !!multiSelectedValue.length, showCurrentUserFilter: showCurrentUserFilter }));
        }
        return _jsx(_Fragment, { children: "".concat(name, " not supported") });
    };
    var onFilterColumnOption = useCallback(function (input, option) {
        var optionItem = Object.values(columns).find(function (item) {
            var _a;
            return item.uniqueId === option.value ||
                ((_a = item.secondaryFilter) === null || _a === void 0 ? void 0 : _a.uniqueId) === option.value;
        });
        return (optionItem === null || optionItem === void 0 ? void 0 : optionItem.secondaryFilter)
            ? dropdownListFilterInputResult(input, "".concat(optionItem.name, " / ").concat(optionItem.secondaryFilter.name))
            : optionItem
                ? dropdownListFilterInputResult(input, optionItem.name)
                : false;
    }, [columns]);
    if (!columns[chosenColumn])
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "FilterEntryWrapper ".concat(className), children: [_jsx(Select, { showSearch: true, value: chosenColumn, className: "FilterSelect min-w-52", dropdownMatchSelectWidth: false, popupClassName: "DropdownSelect", onMouseEnter: lockClose, onMouseLeave: lockOpen, onChange: onChangeColumnSelect, filterOption: onFilterColumnOption, showAction: ['focus', 'click'], autoFocus: (autoFocusOnDropdown !== null && autoFocusOnDropdown !== void 0 ? autoFocusOnDropdown : true) && !(existingFilter === null || existingFilter === void 0 ? void 0 : existingFilter.filterValue), children: Object.values(columns).map(function (c) {
                    var _a, _b, _c;
                    return (_jsx(Option, { value: ((_a = c.secondaryFilter) === null || _a === void 0 ? void 0 : _a.uniqueId) || c.id, disabled: (((_b = c.secondaryFilter) === null || _b === void 0 ? void 0 : _b.id) || c.id) === currentGroupBySchemaId, children: c.secondaryFilter ? "".concat(c.name, " / ").concat(c.secondaryFilter.name) : c.name }, ((_c = c.secondaryFilter) === null || _c === void 0 ? void 0 : _c.uniqueId) || c.id));
                }) }), _jsx(Select, { value: clause, onChange: onChangeFilterClause, className: "FilterSelect FilterClause", dropdownMatchSelectWidth: 150, popupClassName: "DropdownSelect", onMouseEnter: lockClose, onMouseLeave: lockOpen, children: (columns[chosenColumn].secondaryFilter || columns[chosenColumn]).modifiers.map(function (modifier) { return (_jsx(Option, { value: modifier.value, children: modifier.displayLabel }, modifier.value)); }) }), _jsx("div", { className: "FilterValueDiv", children: !specialDefineEmptyClauseTypes.has(clause) && filterValueChangeBar() }), onDeleteEntry && (_jsx(Button, { className: "DeleteButton", icon: _jsx(DeleteOutlined, {}), onClick: onDeleteEntry }))] }));
});
FilterEntry.displayName = 'FilterEntry';
export default FilterEntry;
