import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath } from 'src/shared';
import { getNativeFieldCellContent, getNativeFieldCellOptions, getTagTextColor, } from 'src/utils/Collection';
import { isArray } from 'lodash';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
import { TagStatusColor } from '../../RecordDetailModalHeaderWrapper';
var TimeOffDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var timeOffTag = useMemo(function () {
        var _a, _b;
        var timeOffStatus = (_a = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.TIMEOFF_APPROVAL_STATUS)) !== null && _a !== void 0 ? _a : getNativeFieldCellOptions(modalRecord, nativeSchemaMap, NativePath.TIMEOFF_APPROVAL_STATUS);
        var timeOffStatusValue = isArray(timeOffStatus)
            ? (_b = timeOffStatus[0]) === null || _b === void 0 ? void 0 : _b.id
            : timeOffStatus;
        if (timeOffStatusValue) {
            var backgroundColor = '';
            if (timeOffStatusValue === 'APPROVED') {
                backgroundColor = TagStatusColor.green;
            }
            else if (timeOffStatusValue === 'REJECTED') {
                backgroundColor = TagStatusColor.red;
            }
            else {
                backgroundColor = TagStatusColor.grey;
            }
            return (_jsx("span", { className: "RecordStatusTag", style: {
                    backgroundColor: backgroundColor,
                    color: getTagTextColor(backgroundColor),
                }, children: timeOffStatusValue }));
        }
        return null;
    }, [modalRecord, nativeSchemaMap]);
    return _jsx(RecordDetailModalTitle, { title: "Time Off", extras: timeOffTag });
};
export default React.memo(TimeOffDetailModalTitle);
