import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Form, Select } from 'antd';
import _ from 'lodash';
import { usePageApi } from 'src/hooks/api';
import { dropdownListFilterInputResult } from 'src/utils/Collection';
import { ValueDataType } from '../../shared';
import { OtherOptions, requiredRules, SpecialNewSchemaTypes } from './Constant';
var plainSelectionOptions = ['single', 'multi'];
var lookUpTypeOptions = ['Name', 'Other schema'];
var Option = Select.Option;
var ImportSelectorCombo = function (props) {
    var useGetCollectionSchemas = usePageApi().useGetCollectionSchemas;
    var csvColumnName = props.csvColumnName, ziraSchemas = props.ziraSchemas, dateFormats = props.dateFormats, newSchemaTypeOptions = props.newSchemaTypeOptions;
    var _a = useState(), ziraSchemaType = _a[0], setZiraSchemaType = _a[1];
    var _b = useState(), refCollectionId = _b[0], setRefCollectionId = _b[1];
    var _c = useState(), newSchemaType = _c[0], setNewSchemaType = _c[1];
    var _d = useState(), lookUpType = _d[0], setLookUpType = _d[1];
    var lookUpSchemaOptions = useGetCollectionSchemas({
        body: { collectionId: refCollectionId },
        options: { enabled: !!refCollectionId && lookUpType === 'Other schema' },
    }).data;
    var lookUpTypeOnChange = useCallback(function (value) {
        setLookUpType(value);
    }, []);
    var refCollectionSchemaSelector = useCallback(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Select, { className: "HeaderMappingSubSelector", dropdownMatchSelectWidth: false, onChange: lookUpTypeOnChange, children: lookUpTypeOptions.map(function (option) {
                        return (_jsx(Option, { value: option, children: option }, option));
                    }) }), lookUpType === 'Other schema' && lookUpSchemaOptions && (_jsx(Form.Item, { name: [csvColumnName, 'schemaId'], rules: requiredRules, children: _jsx(Select, { className: "HeaderMappingSubSelector", dropdownMatchSelectWidth: false, children: lookUpSchemaOptions.map(function (option) {
                            return (_jsx(Option, { value: option.id, children: option.name }, option.id));
                        }) }) }))] }));
    }, [csvColumnName, lookUpSchemaOptions, lookUpType, lookUpTypeOnChange]);
    var onSchemaChange = useCallback(function (value) {
        if (value === OtherOptions.CREATE_NEW || value === OtherOptions.NO_MATCHING) {
            setZiraSchemaType(value);
        }
        else {
            var selectedSchema = _.find(ziraSchemas, function (schema) { return schema.id === value; });
            if (selectedSchema) {
                if (selectedSchema.refCollectionId) {
                    setZiraSchemaType('hasRefCollection');
                    setRefCollectionId(selectedSchema.refCollectionId);
                }
                else {
                    setZiraSchemaType(selectedSchema.type);
                }
            }
        }
    }, [ziraSchemas]);
    var onFilterSchemaOption = useCallback(function (input, option) {
        var optionItem = ziraSchemas.find(function (item) { return item.id === option.value; });
        return optionItem ? dropdownListFilterInputResult(input, optionItem.name) : false;
    }, [ziraSchemas]);
    return (_jsxs("div", { className: "SelectorComboWrapper", children: [_jsx(Form.Item, { name: [csvColumnName, 'id'], rules: requiredRules, children: _jsxs(Select, { onChange: onSchemaChange, className: "HeaderMappingSelector", dropdownMatchSelectWidth: false, showSearch: true, filterOption: onFilterSchemaOption, children: [ziraSchemas === null || ziraSchemas === void 0 ? void 0 : ziraSchemas.map(function (option) {
                            return (_jsx(Option, { value: option.id, children: option.name }, option.id));
                        }), _jsx(Option, { value: OtherOptions.CREATE_NEW, children: "Create New Column" }, OtherOptions.CREATE_NEW), _jsx(Option, { value: OtherOptions.NO_MATCHING, children: "No Matching Column" }, OtherOptions.NO_MATCHING)] }) }), (ziraSchemaType === ValueDataType.NATIVE_DATETIME ||
                ziraSchemaType === ValueDataType.DATETIME) && (_jsx(Form.Item, { name: [csvColumnName, 'date'], rules: requiredRules, children: _jsx(Select, { className: "HeaderMappingSubSelector", dropdownMatchSelectWidth: false, children: dateFormats === null || dateFormats === void 0 ? void 0 : dateFormats.map(function (option) {
                        return (_jsx(Option, { value: option, children: option }, option));
                    }) }) })), ziraSchemaType === 'hasRefCollection' && refCollectionSchemaSelector(), ziraSchemaType === OtherOptions.CREATE_NEW && (_jsx(Form.Item, { name: [csvColumnName, 'schemaType'], rules: requiredRules, children: _jsx(Select, { className: "HeaderMappingSubSelector", dropdownMatchSelectWidth: false, onChange: function (value) { return setNewSchemaType(value); }, children: newSchemaTypeOptions.map(function (option) {
                        return (_jsx(Option, { value: option, children: _.startCase(option) }, option));
                    }) }) })), ziraSchemaType === OtherOptions.CREATE_NEW &&
                newSchemaType === SpecialNewSchemaTypes.DATE_TIME && (_jsx(Form.Item, { name: [csvColumnName, 'date'], rules: requiredRules, children: _jsx(Select, { className: "HeaderMappingAdditionalSelector", dropdownMatchSelectWidth: false, children: dateFormats === null || dateFormats === void 0 ? void 0 : dateFormats.map(function (option) {
                        return (_jsx(Option, { value: option, children: option }, option));
                    }) }) })), ziraSchemaType === OtherOptions.CREATE_NEW &&
                newSchemaType === SpecialNewSchemaTypes.PLAIN_SELECTION && (_jsx(Form.Item, { name: [csvColumnName, 'select'], rules: requiredRules, children: _jsx(Select, { className: "HeaderMappingAdditionalSelector", dropdownMatchSelectWidth: false, children: plainSelectionOptions.map(function (option) {
                        return (_jsx(Option, { value: option, children: _.startCase(option) }, option));
                    }) }) }))] }));
};
export default React.memo(ImportSelectorCombo);
