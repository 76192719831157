import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './CenterPanelHeader.scss';
import './AccountHistoryIndicator.scss';
import { Layout } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useAccountApi } from 'src/hooks/api';
import { Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';
import { ifCurrentUserHasActivityHistoryPermission, ifCurrentUserHasWebAccessPermission, isCurrentPageCanvasTypeSelector, naviTreeStructureStateSelector, } from 'src/state';
import { accountNameState } from 'src/state/selectors/withAccountDetailState';
import { getTagTextColor } from 'src/utils/Collection';
import PageHeaderSection from 'src/components/PageView/PageHeaderSection';
import { ActivityFeedIcon } from '../../../utils/icons/ActivityFeedIcon';
import BlockTabs from './BlockTabs';
import HelpDrawer from './HelpDrawer/HelpDrawer';
import ActivityHistoryPollingIndicator from './ActivityHistoryPollingIndicator';
import StripeBillingWarningBanner from './StripeBillingWarningBanner';
import FeedbackBtn from './FeedbackBtn';
var Header = Layout.Header;
var CenterPanelHeader = function (props) {
    var _a = useParams().pageId, pageId = _a === void 0 ? '' : _a;
    var collapsed = props.collapsed, toggleNaviBar = props.toggleNaviBar, toggleActivityFeed = props.toggleActivityFeed;
    var treeNode = useRecoilValue(naviTreeStructureStateSelector);
    var hasActivityHistoryAccess = useRecoilValue(ifCurrentUserHasActivityHistoryPermission);
    var _b = useState(false), showIndicator = _b[0], setShowIndicator = _b[1];
    var pageName = useMemo(function () { var _a, _b; return (_b = (_a = treeNode === null || treeNode === void 0 ? void 0 : treeNode.items[pageId]) === null || _a === void 0 ? void 0 : _a.data.name) !== null && _b !== void 0 ? _b : ''; }, [pageId, treeNode === null || treeNode === void 0 ? void 0 : treeNode.items]);
    var accountName = useRecoilValue(accountNameState);
    useEffect(function () {
        if (accountName) {
            document.title = pageName ? "".concat(accountName, " | ").concat(pageName) : accountName;
        }
    }, [accountName, pageId, pageName]);
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var hasWebAccess = useRecoilValue(ifCurrentUserHasWebAccessPermission);
    return (_jsxs(Header, { className: "centerPanelHeader", children: [_jsx(StripeBillingWarningBanner, {}), _jsxs("div", { className: "spaceAlignContainer ".concat(hasWebAccess ? 'justify-between' : 'justify-end'), children: [_jsx("div", { className: "spaceAlignBlock", children: _jsxs("div", { className: "flex w-full items-center", children: [_jsx("div", { onClick: toggleNaviBar, className: "trigger", children: collapsed ? _jsx(MenuUnfoldOutlined, {}) : _jsx(MenuFoldOutlined, {}) }), hasWebAccess &&
                                    (isCanvasPageType ? (_jsx(BlockTabs, { pageId: pageId, collapsed: collapsed })) : (_jsx("div", { className: "flex items-center text-xl font-medium", children: _jsx("div", { className: "mr-[12px]", children: _jsx(PageHeaderSection, { fullTablePageHeader: true }) }) })))] }) }), _jsxs("div", { className: 'floatRight', children: [_jsx(GlobalFiltersTags, {}), hasWebAccess && (_jsx("div", { className: "menuItemHolder", children: _jsx("div", { className: "centralPanelHeaderBtn", children: _jsx(FeedbackBtn, {}) }) })), hasWebAccess && (_jsx("div", { className: "menuItemHolder", children: _jsx(HelpDrawer, { showOpenButton: true }) })), hasWebAccess && hasActivityHistoryAccess && (_jsx("div", { className: "menuItemHolder", onClick: toggleActivityFeed, children: _jsxs("div", { className: "centralPanelHeaderBtn", children: [_jsx(ActivityHistoryPollingIndicator, { showIndicator: showIndicator, onIndicatorVisibleChange: function (visible) {
                                                return setShowIndicator(visible);
                                            } }), _jsx(ActivityFeedIcon, { className: "activityIcon mr-1", style: { opacity: "".concat(showIndicator ? 0 : 1) } }), _jsx("span", { className: "activityText", children: "Feed" })] }) }))] })] })] }));
};
var GlobalFiltersTags = function () {
    var useGetGlobalSettingsV2 = useAccountApi().useGetGlobalSettingsV2;
    var globalFilterSettingsV2 = useGetGlobalSettingsV2().data;
    var renderToolTipList = useCallback(function (options) {
        return options.map(function (option) {
            return (_jsx("div", { className: "filterToolTipItem mt-2 rounded px-1 text-center", style: { backgroundColor: option.color, color: getTagTextColor(option.color) }, children: option.name }, option.id));
        });
    }, []);
    var renderTags = useCallback(function (options, tag) {
        if (!options.length)
            return _jsx("span", { className: "FilterValueTag", children: tag });
        if (options.length > 1) {
            return (_jsx(Tooltip, { title: renderToolTipList(options), children: _jsxs("span", { className: "FilterValueTag", children: [options.length, " ", tag] }) }));
        }
        else {
            var option = options[0];
            return (_jsx("span", { className: "FilterValueTag", style: {
                    backgroundColor: option.color,
                    color: getTagTextColor(option.color),
                }, children: option.name }));
        }
    }, [renderToolTipList]);
    var renderGlobalFiltersTag = useCallback(function () {
        if (globalFilterSettingsV2) {
            return (_jsx("div", { className: "globalFiltersTag", children: globalFilterSettingsV2.map(function (item) { return (_jsx("div", { className: "filterTag", children: renderTags(item.accessibleRecords, item.collectionName) }, item.collectionId)); }) }));
        }
    }, [renderTags, globalFilterSettingsV2]);
    return _jsx(_Fragment, { children: renderGlobalFiltersTag() });
};
export default React.memo(CenterPanelHeader);
