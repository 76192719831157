var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { scheduleIsDraggingState, selectedShiftsState } from 'src/state/atoms/schedule';
import { CollectionTableType } from 'src/shared';
import { scheduleCardHeightSelector, scheduleShiftCardBasicInfoSelector, scheduleShiftCardColorSelector, scheduleShiftOpenCountState, scheduleViewTypeSelector, } from 'src/state';
import { ScheduleView } from 'src/services';
import { isDarkColor } from '../../../../utils/Tools/TypeGuard';
import './ShiftCard.scss';
import { SHIFT_CARD_HEIGHT_SM } from '../../Constant';
import ShiftCardIndictmentBadge from './ShiftCardIndictmentBadge';
import ShiftCardDisplayTime from './ShiftCardDisplayTime';
import ShiftCardTags from './ShiftCardTags';
import ShiftCardButtons from './ShiftCardButtons';
var ShiftCard = function (props) {
    var blockId = props.blockId, collectionId = props.collectionId, recordId = props.recordId, columnKey = props.columnKey, isHovering = props.isHovering, isOverlayVisible = props.isOverlayVisible, setIsHovering = props.setIsHovering, setIsOverlayVisible = props.setIsOverlayVisible, onDragCard = props.onDragCard, openShiftDetailModal = props.openShiftDetailModal;
    var content = useRecoilValue(scheduleShiftCardBasicInfoSelector({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
    }));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var cardHeight = useRecoilValue(scheduleCardHeightSelector(blockId));
    var cardColor = useRecoilValue(scheduleShiftCardColorSelector({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        blockId: blockId,
        recordId: recordId,
    }));
    var _a = useRecoilState(selectedShiftsState(blockId)), selectedShifts = _a[0], setSelectedShifts = _a[1];
    var selected = useMemo(function () { return selectedShifts.includes(recordId); }, [recordId, selectedShifts]);
    var openCount = useRecoilValue(scheduleShiftOpenCountState({ type: CollectionTableType.TABLE, collectionId: collectionId, recordId: recordId }));
    var contentWithColumnKey = {
        columnKey: columnKey,
        content: content,
    };
    var setIsDragging = useSetRecoilState(scheduleIsDraggingState(blockId));
    var _b = useDrag(function () { return ({
        type: 'ShiftCard',
        collect: function (monitor) {
            var isDragging = !!monitor.isDragging();
            if (isDragging) {
                setIsDragging(true);
            }
            return { isDragging: isDragging };
        },
        item: { contentWithColumnKey: contentWithColumnKey },
        end: function (_, monitor) {
            if (monitor.didDrop()) {
                setTimeout(function () { return onDragCard(content, columnKey); }, 0);
            }
            setIsDragging(false);
        },
    }); }, [content, columnKey]), drag = _b[1];
    var toggleSelected = useCallback(function () {
        if (!selected) {
            setSelectedShifts(function (prev) {
                return __spreadArray(__spreadArray([], prev, true), [recordId], false);
            });
        }
        else {
            var shiftIndex_1 = selectedShifts.findIndex(function (rid) { return rid === recordId; });
            if (shiftIndex_1 >= 0) {
                setSelectedShifts(function (prev) {
                    var newSelectedShifts = cloneDeep(prev);
                    newSelectedShifts.splice(shiftIndex_1, 1);
                    return newSelectedShifts;
                });
            }
        }
    }, [recordId, selected, selectedShifts, setSelectedShifts]);
    if (!content.startAt && !content.clockIn)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: drag, className: 'ShiftCard', style: {
                    background: cardColor,
                    color: isDarkColor(cardColor) === 'dark' ? 'white' : 'black',
                    height: scheduleView === ScheduleView.WEEK ? cardHeight : SHIFT_CARD_HEIGHT_SM,
                }, onClick: function (e) {
                    var _a, _b, _c, _d;
                    e.preventDefault();
                    var target = e.target;
                    var ifNotCardDiv = typeof target.className === 'string' &&
                        (((_b = ((_a = target.className) !== null && _a !== void 0 ? _a : '')) === null || _b === void 0 ? void 0 : _b.split(' ').includes('anticon-ellipsis')) ||
                            ((_d = ((_c = target.className) !== null && _c !== void 0 ? _c : '')) === null || _d === void 0 ? void 0 : _d.split(' ').includes('ant-dropdown-menu-title-content')));
                    if (e.shiftKey) {
                        toggleSelected();
                    }
                    else if (target.tagName == 'svg' && ifNotCardDiv) {
                        return false;
                    }
                    else {
                        openShiftDetailModal(false, recordId);
                    }
                }, onMouseOver: function () { return setIsHovering(true); }, onMouseLeave: function () { return setIsHovering(false); }, children: [_jsx(ShiftCardIndictmentBadge, { blockId: blockId, recordId: recordId }), _jsx(ShiftCardDisplayTime, { blockId: blockId, recordId: recordId }), openCount >= 1 && _jsx("div", { className: "OpenCountIndicator", children: openCount }), _jsx(ShiftCardTags, { blockId: blockId, recordId: recordId }), (isHovering || isOverlayVisible) && (_jsx(ShiftCardButtons, __assign({}, props, { type: "shift", isOverlayVisible: isOverlayVisible, setIsOverlayVisible: setIsOverlayVisible }))), selected && _jsx("div", { className: "ShiftCardSelectedOverlay" })] }), isOverlayVisible && (_jsx("div", { className: "fpOverlay", onClick: function () {
                    setIsOverlayVisible(false);
                }, style: { display: 'block' } }))] }));
};
export default React.memo(ShiftCard);
