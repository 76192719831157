var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ReportsSvg = function () { return (_jsxs("svg", { width: "29", height: "29", viewBox: "0 0 29 29", stroke: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M10.5211 17.8381V16.8381H9.52111H4.88985H3.88985V17.8381V23.6272V24.6272H4.88985H9.52111H10.5211V23.6272V17.8381ZM3.57422 16.5225H10.8367V24.9428H3.57422V16.5225Z", strokeWidth: "2" }), _jsx("path", { d: "M24.4156 12.049V11.049H23.4156H18.7844H17.7844V12.049V23.6272V24.6272H18.7844H23.4156H24.4156V23.6272V12.049ZM17.4688 24.9428V10.7334H24.7313V24.9428H17.4688Z", strokeWidth: "2" }), _jsx("path", { d: "M17.4664 5.10176V4.10176H16.4664H11.8352H10.8352V5.10176V23.6268V24.6268H11.8352H16.4664H17.4664V23.6268V5.10176ZM10.5195 3.78613H17.7821V24.9424H10.5195V3.78613Z", strokeWidth: "2" })] })); };
export var ReportsIcon = function (props) { return (_jsx(Icon, __assign({ component: ReportsSvg }, props))); };
