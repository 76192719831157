import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import Pluralize from 'pluralize';
import { useTasksApi } from 'src/hooks/api';
import { Popover } from 'antd';
import { TaskDiagonalArrow } from 'src/utils/icons/TaskIcons/TaskDiagonalArrow';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { JOBS_AND_PLACEMENTS } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { useTasksComponent } from '../hooks/useTasks';
var TaskTemplateReferences = function (_a) {
    var _b, _c;
    var id = _a.id;
    var isTaskRuleEnabled = useRecoilValue(featureEnableStatusState(JOBS_AND_PLACEMENTS));
    var useGetTaskGroupsAndRulesWithTaskTemplate = useTasksApi().useGetTaskGroupsAndRulesWithTaskTemplate;
    var data = useGetTaskGroupsAndRulesWithTaskTemplate(id).data;
    var _d = useTasksComponent(), viewTaskGroup = _d.viewTaskGroup, viewTaskRule = _d.viewTaskRule;
    var references = useMemo(function () {
        return (_jsxs("div", { className: "flex flex-col text-sm font-medium", children: [data === null || data === void 0 ? void 0 : data.groups.map(function (group) { return (_jsxs("div", { className: "flex items-center gap-4 p-2 cursor-pointer justify-between", onClick: function () { return viewTaskGroup(group.id); }, children: [_jsx("span", { children: group.name }), _jsxs("div", { className: "text-gray-400 ml-auto flex items-center gap-2", children: [group.displayId, " ", _jsx(TaskDiagonalArrow, { style: { paddingBottom: 3 } })] })] }, group.id)); }), data === null || data === void 0 ? void 0 : data.rules.map(function (rule) { return (_jsxs("div", { className: "flex items-center gap-4 p-2 cursor-pointer justify-between", onClick: function () { return viewTaskRule(rule.id); }, children: [_jsx("span", { children: rule.name }), _jsxs("div", { className: "text-gray-400 ml-autoflex items-center gap-1", children: [rule.displayId, " ", _jsx(TaskDiagonalArrow, { style: { paddingBottom: 3 } })] })] }, rule.id)); })] }));
    }, [data === null || data === void 0 ? void 0 : data.groups, data === null || data === void 0 ? void 0 : data.rules, viewTaskGroup, viewTaskRule]);
    return (_jsx(Popover, { placement: "bottomRight", content: references, overlayClassName: "TaskPopover", showArrow: false, trigger: "hover", children: _jsxs("div", { className: "flex flex-row items-center gap-2 text-sm bg-gray-50 ml-auto font-medium text-gray-400 px-3 py-1 rounded-xl cursor-pointer", children: [_jsxs("span", { children: ["Linked to ", (_b = data === null || data === void 0 ? void 0 : data.groups.length) !== null && _b !== void 0 ? _b : 0, " ", Pluralize('group', data === null || data === void 0 ? void 0 : data.groups.length), isTaskRuleEnabled && (_jsxs(_Fragment, { children: [", ", (_c = data === null || data === void 0 ? void 0 : data.rules.length) !== null && _c !== void 0 ? _c : 0, " ", Pluralize('rule', data === null || data === void 0 ? void 0 : data.rules.length)] }))] }), _jsx(InfoCircleOutlined, {})] }) }));
};
export default React.memo(TaskTemplateReferences);
