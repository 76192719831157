var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
var TaskSearchBar = function (props) {
    var _a = props.className, className = _a === void 0 ? '' : _a, otherProps = __rest(props, ["className"]);
    return (_jsx("div", { className: className + ' w-full sticky top-0 p-[16px] z-10 bg-white', children: _jsx(Input, __assign({ className: "rounded-lg h-[36px] w-full pl-[12px] pr-[18px] border-1 border-gray-100", prefix: _jsx(SearchOutlined, { className: "text-gray-100 text-[16px]" }) }, otherProps)) }));
};
export default React.memo(TaskSearchBar);
