var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRecoilCallback } from 'recoil';
import { DefaultRateTableHeaderType, } from '../../interface';
import { currentDefaultRateState } from '../../atoms';
var useDefaultRate = function () {
    var constructDefaultRateStateItem = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (rate, headers, isDuplicateAction) {
            var info = rate['info'];
            var conditions = [];
            var adjustments = [];
            headers.map(function (header) {
                var data = rate[header.id];
                if (header.type === DefaultRateTableHeaderType.CONDITION && data) {
                    var condtion = data;
                    conditions.push(condtion);
                }
                else if (header.type === DefaultRateTableHeaderType.ADJUSTMENT && data) {
                    var adjustment = data;
                    adjustments.push(adjustment);
                }
            });
            var newInfo = __assign({}, info);
            if (isDuplicateAction) {
                newInfo.name = "".concat(info.name, " (Copy)");
            }
            set(currentDefaultRateState, {
                info: newInfo,
                conditions: conditions,
                adjustments: adjustments,
            });
        };
    }, []);
    return {
        constructDefaultRateStateItem: constructDefaultRateStateItem,
    };
};
export { useDefaultRate };
