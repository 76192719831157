import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ifCurrentUserHasActivityHistoryPermission } from 'src/state';
import { ActivityFeedIcon } from 'src/utils/icons/ActivityFeedIcon';
import HelpDrawer from './HelpDrawer/HelpDrawer';
import FeedbackBtn from './FeedbackBtn';
var HelpAndActivityBtnsSection = function (_a) {
    var toggleActivityFeed = _a.toggleActivityFeed, isChatHeader = _a.isChatHeader;
    var hasActivityHistoryAccess = useRecoilValue(ifCurrentUserHasActivityHistoryPermission);
    return (_jsxs("div", { className: "flex ".concat(isChatHeader && 'min-w-[185px]', " items-center"), children: [_jsx("div", { className: "cursor-pointer rounded-[15px] bg-white px-[12px] py-[8px] text-sm font-medium shadow-block mr-3 text-black", children: _jsx(FeedbackBtn, {}) }), _jsx("div", { className: "mr-3 cursor-pointer rounded-[15px] bg-white px-[12px] py-[8px] text-sm font-medium shadow-block", children: _jsx(HelpDrawer, { showOpenButton: true }) }), hasActivityHistoryAccess && (_jsxs("div", { className: "cursor-pointer rounded-[15px] bg-white px-[12px] py-[8px] text-sm font-medium shadow-block", onClick: toggleActivityFeed, children: [_jsx(ActivityFeedIcon, { className: "activityIcon mr-1" }), _jsx("span", { className: "HeaderButtonText", children: "Feed" })] }))] }));
};
export default React.memo(HelpAndActivityBtnsSection);
