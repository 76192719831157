import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useMemo } from 'react';
import { ThreadReceiverParticipantType } from './interface';
var InfoIconColor = '#2F4DDF';
var BroadcastBanner = function (_a) {
    var broadcastRecipients = _a.broadcastRecipients;
    var _b = useMemo(function () {
        return {
            //Also includes BROADCAST_ACCOUNT
            locationNames: broadcastRecipients === null || broadcastRecipients === void 0 ? void 0 : broadcastRecipients.filter(function (r) { return r.type !== ThreadReceiverParticipantType.BROADCAST_ROLE; }).map(function (r) { return r.name; }).join(', '),
            roleNames: broadcastRecipients === null || broadcastRecipients === void 0 ? void 0 : broadcastRecipients.filter(function (r) { return r.type === ThreadReceiverParticipantType.BROADCAST_ROLE; }).map(function (r) { return r.name; }).join(', '),
        };
    }, [broadcastRecipients]), locationNames = _b.locationNames, roleNames = _b.roleNames;
    return (_jsxs(Space, { className: "flex w-full flex-row items-center bg-blue-50 p-[8px] text-sm font-medium text-blue-400", children: [_jsx(InfoCircleOutlined, { style: {
                    marginBottom: '3px',
                    color: InfoIconColor,
                    stroke: InfoIconColor,
                    fontSize: '14px',
                    strokeWidth: '25',
                } }), _jsxs("div", { children: ["A broadcast message is sent to all", broadcastRecipients ? (_jsx(_Fragment, { children: " ".concat(roleNames, " users").concat(locationNames ? " in ".concat(locationNames) : '') })) : (' targets'), ". Their replies come in privately to the department they reply to."] })] }));
};
export default BroadcastBanner;
