import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { selectedTaskGroupIdState, selectedTaskRuleIdState, selectedTaskTemplateIdState, taskEditorActiveTabState, } from '../atom';
import { TaskTab } from '../interface';
import TaskGroupDetailContent from '../TaskGroupDetail/TaskGroupDetailContent';
import TaskTemplateDetailContent from '../TaskTemplate/TaskTemplateDetailContent';
import TasksSettingEmptyContent from './TasksSettingEmptyContent';
import TaskRuleDetailContent from './TaskRules/TaskRuleDetailContent';
var TasksSettingContent = function () {
    var activeTab = useRecoilValue(taskEditorActiveTabState);
    var showTaskGroupDetail = !!useRecoilValue(selectedTaskGroupIdState);
    var showTaskTemplateDetail = !!useRecoilValue(selectedTaskTemplateIdState);
    var showTaskRuleDetail = !!useRecoilValue(selectedTaskRuleIdState);
    return (_jsxs(_Fragment, { children: [activeTab === TaskTab.TEMPLATES && showTaskTemplateDetail && (_jsx(TaskTemplateDetailContent, {})), activeTab === TaskTab.GROUPS && showTaskGroupDetail && _jsx(TaskGroupDetailContent, {}), activeTab === TaskTab.RULES && showTaskRuleDetail && _jsx(TaskRuleDetailContent, {}), ((!showTaskTemplateDetail && activeTab === TaskTab.TEMPLATES) ||
                (!showTaskGroupDetail && activeTab === TaskTab.GROUPS) ||
                (!showTaskRuleDetail && activeTab === TaskTab.RULES)) && (_jsx(TasksSettingEmptyContent, { activeTab: activeTab }))] }));
};
export default React.memo(TasksSettingContent);
