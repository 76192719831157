var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-key */
/**
 * @description This component is used to display the detail of a health metric
 * @author bingqi
 *
 * !!NOTE: We're using react-table here instead of our TableBuilder to save resources
 *      !! since we only need a simple table with a fixed number of columns
 */
import { startCase } from 'lodash';
import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import './HealthMetricDetailTable.scss';
import { numberFormatterWithType } from './HealthMetricCalculator';
var HealthMetricDetailTable = function (_a) {
    var title = _a.title, data = _a.data, type = _a.type;
    var tableData = useMemo(function () {
        return data
            .map(function (d) { return ({
            date: d.date,
            data: d.number,
        }); })
            .reverse();
    }, [data]);
    var columnDiv = function (value) { return _jsx("div", { className: "ColumnTitle", children: value }); };
    var columns = React.useMemo(function () { return [
        {
            Header: columnDiv('Date'),
            accessor: 'date',
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx("div", { className: "px-[0.4rem] py-2 font-medium", children: value }));
            },
        },
        {
            Header: columnDiv(startCase(title)),
            accessor: 'data',
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx("div", { className: "px-[0.4rem] py-2", children: numberFormatterWithType(value, type) }));
            },
        },
    ]; }, [title, type]);
    var _b = useTable({
        columns: columns,
        data: tableData,
    }), getTableProps = _b.getTableProps, getTableBodyProps = _b.getTableBodyProps, headerGroups = _b.headerGroups, prepareRow = _b.prepareRow, rows = _b.rows;
    return (_jsx("div", { className: "HealthMetricDetailTable", children: _jsx("div", { className: "HealthMetricDetailTableWrap shadow-block", children: _jsxs("table", __assign({}, getTableProps(), { className: "sticky", children: [_jsx("thead", { className: "header", children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column) { return (_jsx("th", __assign({}, column.getHeaderProps(), { children: column.render('Header') }))); }) }))); }) }), _jsx("tbody", __assign({ className: "body" }, getTableBodyProps(), { children: rows.map(function (row) {
                            prepareRow(row);
                            return (_jsx("tr", __assign({}, row.getRowProps(), { children: row.cells.map(function (cell) {
                                    return (_jsx("td", __assign({}, cell.getCellProps(), { children: cell.render('Cell') })));
                                }) })));
                        }) }))] })) }) }));
};
export default React.memo(HealthMetricDetailTable);
