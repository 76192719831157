import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { Select, TimePicker } from 'antd';
import moment from 'moment';
var TimeQualifierModifiers = [
    { label: 'Is Before', value: 'is_before' },
    { label: 'Is After', value: 'is_after' },
    { label: 'Is On or Before', value: 'is_on_or_before' },
    { label: 'Is On or After', value: 'is_on_or_after' },
];
var DateQualifierCell = function (_a) {
    var defaultModifier = _a.defaultModifier, defaultTime = _a.defaultTime, onChange = _a.onChange, canEdit = _a.canEdit;
    var _b = useState(defaultModifier), modifier = _b[0], setModifier = _b[1];
    var _c = useState(defaultTime), time = _c[0], setTime = _c[1];
    var onModifierChange = useCallback(function (newModifier) {
        setModifier(newModifier);
        onChange(newModifier, time);
    }, [onChange, time]);
    var onTimeChange = useCallback(function (value, _dateString) {
        if (value) {
            var newTime = value.format('HH:mm:ss');
            setTime(newTime);
            onChange(modifier, newTime);
        }
        else {
            setTime('');
            onChange(modifier, '');
        }
    }, [modifier, onChange]);
    return (_jsxs("div", { className: "DateQualifierCellWrapper", children: [_jsx(Select, { options: TimeQualifierModifiers, value: modifier || undefined, onChange: onModifierChange, onClear: function () { return onModifierChange(''); }, allowClear: true, disabled: !canEdit, dropdownMatchSelectWidth: false }), _jsx(TimePicker, { value: defaultTime ? moment(defaultTime, 'HH:mm:ss') : undefined, use12Hours: true, format: "h:mm A", onChange: onTimeChange, showNow: false, disabled: !canEdit })] }));
};
export default React.memo(DateQualifierCell);
