var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ScheduleView } from 'src/services';
import { CellInputMode, CollectionTableType, NativeMultiSet, ValueDataType, } from 'src/shared';
import { tableCollectionIdSelector, tableVisibleColumnIdsSelector, collectionTableRecordState, collectionTableSchemaMapSelector, scheduleViewTypeSelector, } from 'src/state';
import { checkStaleOptionInCell, getTagTextColor } from 'src/utils/Collection';
import { accountTimeZoneSelector } from 'src/state/selectors/withAccountDetailState';
var DateFormat = 'MMM D, YYYY';
var DateTimeFormat = 'MMM D, YYYY hh:mm A';
var ShiftCardTags = function (_a) {
    var blockId = _a.blockId, recordId = _a.recordId, style = _a.style;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var visibleSchemas = useRecoilValue(tableVisibleColumnIdsSelector({ tableId: blockId, collectionId: collectionId }));
    var schemaMap = useRecoilValue(collectionTableSchemaMapSelector(collectionId));
    var shiftRecord = useRecoilValue(collectionTableRecordState({ type: CollectionTableType.TABLE, collectionId: collectionId, recordId: recordId }));
    var accountTimeZone = useRecoilValue(accountTimeZoneSelector);
    var tags = useMemo(function () {
        var _a, _b;
        var t = [];
        if (!shiftRecord)
            return t;
        var schemasToRender = scheduleView === ScheduleView.WEEK ? visibleSchemas : visibleSchemas.slice(0, 1);
        for (var _i = 0, schemasToRender_1 = schemasToRender; _i < schemasToRender_1.length; _i++) {
            var schemaId = schemasToRender_1[_i];
            var schema = schemaMap[schemaId];
            var cell = shiftRecord[schemaId];
            var tag = { name: schema.name, schemaId: schemaId };
            if (cell) {
                // For roll up schema, using cell.content to display the value
                if (cell.options.length && schema.type !== ValueDataType.ROLLUP) {
                    // Skip stale generated cells
                    if (schema.inputMode === CellInputMode.USER_DEFINED_GENERATED) {
                        var isStale = checkStaleOptionInCell(cell.options);
                        if (isStale) {
                            continue;
                        }
                    }
                    t.push(__assign(__assign({}, tag), { isMulti: schema.inputMode === 'multi' ||
                            NativeMultiSet.has(schemaMap[schemaId].nativePath), value: cell.options }));
                }
                else if (cell.versionedOptions.length) {
                    t.push(__assign(__assign({}, tag), { isMulti: schema.inputMode === 'multi' ||
                            NativeMultiSet.has(schemaMap[schemaId].nativePath), value: cell.versionedOptions }));
                }
                else if (cell.content) {
                    var name_1 = cell.content;
                    if (schema.type === ValueDataType.DATETIME ||
                        schema.type === ValueDataType.NATIVE_DATETIME) {
                        if (!((_b = (_a = schema.configuration) === null || _a === void 0 ? void 0 : _a.showTime) !== null && _b !== void 0 ? _b : false)) {
                            name_1 = moment(cell.content).format(DateFormat);
                        }
                        else {
                            name_1 = moment(cell.content).tz(accountTimeZone).format(DateTimeFormat);
                        }
                    }
                    t.push(__assign(__assign({}, tag), { isMulti: false, value: [{ id: cell.content, name: name_1 }] }));
                }
                else if (cell.files.length) {
                    t.push(__assign(__assign({}, tag), { isMulti: true, value: cell.files.map(function (file, index) { return ({
                            id: "".concat(file.name, "-").concat(index),
                            name: file.name,
                        }); }) }));
                }
            }
        }
        return t;
    }, [accountTimeZone, scheduleView, schemaMap, shiftRecord, visibleSchemas]);
    return (_jsx("div", { className: "flex w-full flex-wrap space-x-[2px]", style: style, children: tags.map(function (tag, index) { return (_jsx("div", { className: "ShiftTagsContainer", style: tag.isMulti ? { flexWrap: 'wrap', rowGap: '4px' } : undefined, children: tag.value.map(function (_a) {
                var id = _a.id, name = _a.name, color = _a.color;
                if (!name)
                    return null;
                return (_jsx("span", { className: "ShiftTagInfo", style: {
                        backgroundColor: color,
                        color: getTagTextColor(color),
                    }, title: name, children: name }, id));
            }) }, "".concat(tag.schemaId, "-").concat(index))); }) }));
};
export default React.memo(ShiftCardTags);
