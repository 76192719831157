import { useCallback } from 'react';
import { getAppSession } from 'src/utils/Account';
import { useSessionApi } from '../api';
var useAppSession = function () {
    var deleteAppSession = useSessionApi().deleteAppSession;
    var clearAppCache = useCallback(function (keepAuth) {
        var _a;
        if (keepAuth === void 0) { keepAuth = false; }
        var appSessionId = (_a = getAppSession()) === null || _a === void 0 ? void 0 : _a.id;
        if (appSessionId) {
            deleteAppSession(appSessionId);
        }
        if (!keepAuth) {
            localStorage.clear();
        }
        else {
            for (var key in localStorage) {
                if (!key.includes('auth0') && key !== 'accountId') {
                    localStorage.removeItem(key);
                }
            }
        }
    }, [deleteAppSession]);
    return { clearAppCache: clearAppCache };
};
export { useAppSession };
