import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getTagTextColor } from 'src/utils/Collection';
var TemplateStatusTag = function (_a) {
    var isActive = _a.isActive;
    var backgroundColor = isActive ? '#e5f4ef' : '#ffedec';
    return (_jsx("div", { className: "TemplateStatusTagWrapper", children: _jsx("div", { className: "TemplateStatusTag", style: {
                backgroundColor: backgroundColor,
                color: getTagTextColor(backgroundColor),
            }, children: isActive ? 'Active' : 'Archived' }) }));
};
export default React.memo(TemplateStatusTag);
