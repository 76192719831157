var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-key */
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd';
import { InView, useInView } from 'react-intersection-observer';
import { useBlockLayout, useResizeColumns, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useRecoilValue } from 'recoil';
import { onTableDataKeyDown } from 'src/shared';
import { isCurrentPageCanvasTypeSelector, isCurrentPageTableTypeSelector } from 'src/state';
import './TableBuilder.scss';
import { TITLE_COLUMN_ID, ADD_NEW_COLUMN_COLUMN_ID, SELECT_RECORD_COLUMN_ID } from './Constants';
var TABLE_HEADER_HEIGHT = 40;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var TableBuilder = function (props) {
    var _a;
    var defaultColumns = props.columns, defaultData = props.data, tableClassNameOverrideProp = props.tableClassNameOverride, pagination = props.pagination, footer = props.footer, summary = props.summary, onEndResize = props.onEndResize, onEndDragAndDrop = props.onEndDragAndDrop, defaultDisableDnd = props.disableDnd, _b = props.isBlock, isBlock = _b === void 0 ? false : _b, rootMarginOverride = props.rootMarginOverride, scrollContainerClassName = props.scrollContainerClassName, tableScrollProps = props.tableScrollProps, _c = props.isPrintOnly, isPrintOnly = _c === void 0 ? false : _c, _d = props.grayedOutRecordIds, grayedOutRecordIds = _d === void 0 ? [] : _d;
    var _e = tableScrollProps !== null && tableScrollProps !== void 0 ? tableScrollProps : {}, scrollTableClassNameOverride = _e.tableClassNameOverride, handlePaginationScroll = _e.handlePaginationScroll, tableId = _e.tableId;
    var columns = useMemo(function () { return defaultColumns; }, [defaultColumns]);
    var data = useMemo(function () { return defaultData; }, [defaultData]);
    var disableDnd = useMemo(function () { return defaultDisableDnd || false; }, [defaultDisableDnd]);
    var _f = useTable({
        columns: columns,
        data: data,
    }, useBlockLayout, useResizeColumns, useSticky), getTableProps = _f.getTableProps, getTableBodyProps = _f.getTableBodyProps, headerGroups = _f.headerGroups, rows = _f.rows, visibleColumns = _f.visibleColumns, prepareRow = _f.prepareRow, columnResizing = _f.state.columnResizing;
    var tableClassNameOverride = scrollTableClassNameOverride !== null && scrollTableClassNameOverride !== void 0 ? scrollTableClassNameOverride : tableClassNameOverrideProp;
    useEffect(function () {
        // On resize end
        if (columnResizing.isResizingColumn === null) {
            if (onEndResize)
                onEndResize(columnResizing.columnWidths);
        }
    }, [columnResizing, onEndResize]);
    var handleDragEnd = useCallback(function (result) {
        var tempColumnIds = __spreadArray([], visibleColumns.map(function (_a) {
            var id = _a.id;
            return id;
        }), true);
        if (disableDnd) {
            return tempColumnIds;
        }
        var source = result.source, destination = result.destination;
        if (destination === undefined || destination === null) {
            return tempColumnIds;
        }
        var sourceIndex = source.index;
        var destIndex = destination.index;
        if (sourceIndex === destIndex || destIndex === -1 || sourceIndex === -1) {
            return tempColumnIds;
        }
        var deletedColumnId = tempColumnIds.splice(sourceIndex, 1)[0];
        tempColumnIds.splice(destIndex, 0, deletedColumnId);
        onEndDragAndDrop === null || onEndDragAndDrop === void 0 ? void 0 : onEndDragAndDrop(tempColumnIds);
    }, [disableDnd, onEndDragAndDrop, visibleColumns]);
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var isTablePageType = useRecoilValue(isCurrentPageTableTypeSelector);
    var pageScrollContainerRef = useRef(document.querySelector("".concat(scrollContainerClassName !== null && scrollContainerClassName !== void 0 ? scrollContainerClassName : (isBlock && isCanvasPageType
        ? '.CenterPanelContent'
        : isBlock && isTablePageType
            ? '.BlockTableWrapper'
            : '.ModalBody'))));
    var tableContainerRef = useRef(null);
    var hasPageScrollContainer = !!pageScrollContainerRef.current;
    //! Fix getting scroll container ref for full table page
    useEffect(function () {
        if (isBlock && isTablePageType) {
            pageScrollContainerRef.current = document.querySelector('.BlockTableWrapper');
        }
    }, [isBlock, isTablePageType]);
    //! Sticky header logic
    var needStickyHeaderLogic = isBlock && !isTablePageType;
    var stickyHeaderRef = useRef(null);
    var tableWidth = (_a = stickyHeaderRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth;
    var _g = useInView({
        root: pageScrollContainerRef.current,
        rootMargin: "-".concat(TABLE_HEADER_HEIGHT, "px 0px 0px 0px"),
    }), tableHeaderInView = _g.inView, tableHeaderRef = _g.ref, entry = _g.entry;
    var animateStickyHeader = useCallback(function () {
        var newTranslateYPixels = Math.max(pageScrollContainerRef.current.getBoundingClientRect().top -
            tableContainerRef.current.getBoundingClientRect().top, 0);
        stickyHeaderRef.current.style.transform = "translate3d(0px, ".concat(newTranslateYPixels, "px, 0px)");
        // To prevent sticky header going past bottom of table (Times 2 to account for height of itself and summary)
        var maxTranslateYPixels = tableContainerRef.current.clientHeight - 2 * TABLE_HEADER_HEIGHT;
        if (maxTranslateYPixels < newTranslateYPixels) {
            stickyHeaderRef.current.style.transform = "translate3d(0px, ".concat(maxTranslateYPixels, "px, 0px)");
        }
    }, []);
    // Scroll listener logic for sticky header
    useEffect(function () {
        var _a;
        if (needStickyHeaderLogic &&
            !tableScrollProps &&
            hasPageScrollContainer &&
            !tableHeaderInView &&
            entry &&
            pageScrollContainerRef.current &&
            tableContainerRef.current &&
            stickyHeaderRef.current) {
            pageScrollContainerRef.current.addEventListener('scroll', animateStickyHeader);
        }
        else if (hasPageScrollContainer && tableHeaderInView) {
            stickyHeaderRef.current.style.transform = '';
            (_a = pageScrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', animateStickyHeader);
        }
        return function () { var _a; 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return (_a = pageScrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', animateStickyHeader); };
    }, [
        animateStickyHeader,
        entry,
        hasPageScrollContainer,
        isTablePageType,
        needStickyHeaderLogic,
        tableHeaderInView,
        tableScrollProps,
    ]);
    return (_jsxs("div", { className: tableClassNameOverride ? tableClassNameOverride : 'TableBuilder', style: { position: 'relative' }, ref: tableContainerRef, onScroll: handlePaginationScroll, id: tableId, children: [_jsxs("table", __assign({}, getTableProps(), { className: "table ".concat(isPrintOnly && 'PrintTable'), children: [_jsx(DragDropContext, { onDragEnd: handleDragEnd, children: _jsx(Droppable, { droppableId: "customtable-droppable", direction: "horizontal", isDropDisabled: disableDnd, children: function (provided) { return (_jsx("div", { ref: stickyHeaderRef, className: hasPageScrollContainer
                                    ? 'TableHeader z-[1] bg-white will-change-transform'
                                    : 'TableHeader', style: isPrintOnly ? { position: 'sticky', top: '0' } : {}, children: _jsx("thead", __assign({ ref: provided.innerRef }, provided.droppableProps, { children: headerGroups.map(function (headerGroup) { return (_jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { className: "tr", children: headerGroup.headers.map(function (column, index) {
                                            var isSchemaColumn = column.id !== TITLE_COLUMN_ID &&
                                                column.id !== ADD_NEW_COLUMN_COLUMN_ID &&
                                                column.id !== SELECT_RECORD_COLUMN_ID;
                                            return (_jsx(Draggable, { draggableId: column.id, index: index, isDragDisabled: disableDnd || !isSchemaColumn, children: function (provided, snapshot) {
                                                    if (snapshot.isDragging) {
                                                        //! Add drag offset
                                                        var yOffset = stickyHeaderRef.current.getBoundingClientRect()
                                                            .top;
                                                        var xOffset = stickyHeaderRef.current.getBoundingClientRect()
                                                            .left;
                                                        provided.draggableProps
                                                            .style.top -= yOffset;
                                                        provided.draggableProps
                                                            .style.left -= xOffset;
                                                    }
                                                    return (_jsxs("th", __assign({ ref: provided.innerRef }, column.getHeaderProps(), { className: "th ".concat(column.isResizing
                                                            ? 'isResizing'
                                                            : ''), children: [_jsx("span", __assign({}, (isSchemaColumn
                                                                ? provided.draggableProps
                                                                : undefined), (isSchemaColumn
                                                                ? provided.dragHandleProps
                                                                : undefined), { children: column.render('Header') })), isSchemaColumn && (_jsx("div", __assign({}, column.getResizerProps(), { className: "resizer ".concat(column.isResizing
                                                                    ? 'isResizing'
                                                                    : '') })))] })));
                                                } }, column.id));
                                        }) }))); }) })) })); } }) }), !tableScrollProps && needStickyHeaderLogic && (_jsx("div", { style: { height: TABLE_HEADER_HEIGHT }, ref: tableHeaderRef })), _jsx("tbody", __assign({}, getTableBodyProps(), { className: "tbody", children: rows.map(function (row, index) {
                            prepareRow(row);
                            return (_jsx(InView, { root: tableScrollProps
                                    ? tableContainerRef.current
                                    : pageScrollContainerRef.current, rootMargin: rootMarginOverride
                                    ? rootMarginOverride
                                    : isTablePageType
                                        ? '1000px 0px'
                                        : '500px 0px', children: function (_a) {
                                    var inView = _a.inView, ref = _a.ref, entry = _a.entry;
                                    var rowInView = hasPageScrollContainer || tableScrollProps ? inView : true;
                                    var recordId = row.cells[0].value.recordId;
                                    var grayedOutRow = grayedOutRecordIds.includes(recordId);
                                    return (_jsx("div", { ref: ref, style: {
                                            minHeight: 43,
                                            height: inView
                                                ? undefined
                                                : entry === null || entry === void 0 ? void 0 : entry.boundingClientRect.height,
                                            opacity: grayedOutRow ? 0.5 : 1,
                                        }, className: "relative", children: _jsx("tr", __assign({}, row.getRowProps(), { className: "tr", children: (rowInView || isPrintOnly) && (_jsx(_Fragment, { children: row.cells.map(function (cell, columnIndex) {
                                                    return (_jsx("td", __assign({}, cell.getCellProps(), { className: "td", onKeyDown: function (e) {
                                                            return onTableDataKeyDown(e, columnIndex);
                                                        }, children: cell.render('Cell') })));
                                                }) })) })) }));
                                } }, index));
                        }) })), footer && (_jsx("div", { className: "tfoot", style: {
                            width: tableWidth,
                        }, children: footer })), pagination && !!data.length && _jsx("div", { className: "tfoot", children: pagination })] })), summary && _jsx("div", { className: "Summary", children: summary })] }));
};
export default React.memo(TableBuilder, function (prevProps, nextProps) {
    return (isEqual(prevProps.columns, nextProps.columns) &&
        isEqual(prevProps.data, nextProps.data) &&
        isEqual(prevProps.summary, nextProps.summary));
});
