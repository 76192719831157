import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloudSyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { getSecondsAgo, secondsToString } from 'src/utils/Metric';
import './MetricBar.scss';
var CalculateMetricBar = function (_a) {
    var lastCalculatedAt = _a.lastCalculatedAt, onRecalculate = _a.onRecalculate;
    var _b = useState(getSecondsAgo(lastCalculatedAt)), elapsedSeconds = _b[0], setElapsedSeconds = _b[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setElapsedSeconds(function (s) { return s + 60; });
        }, 60000);
        return function () { return clearInterval(interval); };
    }, [lastCalculatedAt]);
    useEffect(function () {
        setElapsedSeconds(getSecondsAgo(lastCalculatedAt));
    }, [lastCalculatedAt]);
    return (_jsxs(Button, { className: "RecalculateButton", onClick: onRecalculate, children: [_jsx(CloudSyncOutlined, {}), "Last updated: ", secondsToString(elapsedSeconds)] }));
};
export default React.memo(CalculateMetricBar);
