import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { PagePermissionLevel, PagePermissionLevelOptions } from '../Constants';
import ObjectPermissionContent from './ObjectPermissionContent';
var PagePermissionContent = function (_a) {
    var onChange = _a.onChange, currentPermission = _a.currentPermission;
    var options = Object.values(PagePermissionLevel).map(function (key) { return ({
        id: key,
        title: PagePermissionLevelOptions(key),
    }); });
    return (_jsx(ObjectPermissionContent, { title: "Page Functions", options: options, onChange: function (id) { return onChange(id); }, chosenPermission: currentPermission }));
};
export default React.memo(PagePermissionContent);
