import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getTagTextColor } from 'src/utils/Collection';
var RecordDetailMetricWrapper = function (_a) {
    var title = _a.title, content = _a.content, backgroundColor = _a.backgroundColor;
    return (_jsxs("div", { className: "flex flex-col ", children: [_jsx("div", { className: "text-[11px] font-medium text-gray-300", children: title }), _jsx("div", { className: "text-[13px] font-medium w-fit flex items-center", style: backgroundColor
                    ? {
                        backgroundColor: backgroundColor,
                        color: getTagTextColor(backgroundColor),
                        borderRadius: 8,
                        padding: '0px 7px',
                    }
                    : undefined, children: content })] }));
};
export default React.memo(RecordDetailMetricWrapper);
