import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { PAYMENT_RULES_TAB, SMART_TEXT_SCHEMA } from 'src/components/FeatureGatingKeyConstant';
import SmartFieldSwitch from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/SmartFieldSwitch';
import { CellInputMode, SchemaConfigurationType } from 'src/shared';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import CurrencyConfigurationSection from 'src/components/CollectionComponents/CreateSchemaModal/configuration/component/CurrencyConfigurationSection';
import RatingSchemaConfigurationSection from '../../../SchemaConfigModal/RatingSchemaConfig/RatingSchemaConfigurationSection';
import TextSchemaConfigurationSection from './TextSchemaConfigurationSection';
import LinkToObjSchemaConfigurationSection from './LinkToObjSchemaConfigurationSection';
import DateSchemaConfigurationSection from './DateSchemaConfigurationSection';
import LinkToSelectionSchemaConfigurationSection from './LinkToSelectionSchemaConfigurationSection';
import RollupSchemaConfigurationSection from './RollupSchemaConfigurationSection';
import GeneratedIdSchemaConfigurationSection from './GeneratedIdSchemaConfigurationSection';
import ButtonSchemaConfigurationSection from './ButtonSchemaConfigurationSection';
import FormulaSchemaConfigurationSection from './FormulaSchemaConfigurationSection';
import SelectSchemaConfigurationSection from './Select/SelectSchemaConfigurationSection';
var SchemaConfigurationSection = function (_a) {
    var _b;
    var collectionId = _a.collectionId, schemaId = _a.schemaId, column = _a.column, columnName = _a.columnName, isCreatingNewColumn = _a.isCreatingNewColumn, modalType = _a.modalType, changeSchemaConfig = _a.changeSchemaConfig, onLinkToObjChange = _a.onLinkToObjChange, onLinkToSelectSchemaChange = _a.onLinkToSelectSchemaChange, onRollupSchemaChange = _a.onRollupSchemaChange, onFormulaSchemaChange = _a.onFormulaSchemaChange, setOkDisabled = _a.setOkDisabled, setIsSmartInputMode = _a.setIsSmartInputMode, collectionOptions = _a.collectionOptions, selectOptions = _a.selectOptions, setSelectOptions = _a.setSelectOptions, schemaTypeChanged = _a.schemaTypeChanged, addToSelectSchemaEditQueue = _a.addToSelectSchemaEditQueue;
    var _c = useState(column.inputMode == CellInputMode.USER_DEFINED_GENERATED), isSmartField = _c[0], setIsSmartField = _c[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(function () { return setIsSmartInputMode === null || setIsSmartInputMode === void 0 ? void 0 : setIsSmartInputMode(isSmartField); }, [isSmartField]);
    var isPaymentRulesTabOn = useRecoilValue(featureEnableStatusState(PAYMENT_RULES_TAB));
    var smartTextEnabled = useRecoilValue(featureEnableStatusState(SMART_TEXT_SCHEMA));
    var showSmartFieldSwitch = useMemo(function () { return modalType == 'text' || modalType == 'single' || modalType == 'multi'; }, [modalType]);
    var showConfiguration = useMemo(function () {
        if ([
            'datetime',
            'linkToObj',
            'linkToSchema',
            'rollup',
            'generative',
            'button',
            'formula',
            'single',
            'multi',
            'rating',
        ].includes(modalType)) {
            return true;
        }
        if (isPaymentRulesTabOn && modalType == 'currency') {
            return true;
        }
        return smartTextEnabled && modalType == 'text';
    }, [modalType, smartTextEnabled, isPaymentRulesTabOn]);
    var smartFieldSwitch = useMemo(function () {
        return (_jsx(_Fragment, { children: showSmartFieldSwitch && (_jsx(SmartFieldSwitch, { isSmartField: isSmartField, setIsSmartField: setIsSmartField, smartTextEnabled: smartTextEnabled })) }));
    }, [isSmartField, showSmartFieldSwitch, smartTextEnabled]);
    return showConfiguration ? (_jsxs(_Fragment, { children: [modalType == 'text' && (_jsx(TextSchemaConfigurationSection, { isSmartField: isSmartField, smartFieldSwitch: smartFieldSwitch, changeSchemaConfig: changeSchemaConfig, collectionId: collectionId, generativeSchemaConfig: column.configuration })), modalType == 'datetime' && (_jsx(DateSchemaConfigurationSection, { column: {
                    columnName: columnName,
                    dateSchemaConfig: column.configuration,
                }, schemaConfigOnChange: function (config) {
                    return changeSchemaConfig(SchemaConfigurationType.DATETIME, config);
                } })), modalType == 'linkToObj' && (_jsx(LinkToObjSchemaConfigurationSection, { linkedCollectionId: ((_b = column.configuration) === null || _b === void 0 ? void 0 : _b.refCollectionId) || column.refCollectionId, inputMode: column.inputMode, optionsForDropdown: collectionOptions, isCreatingNewColumn: isCreatingNewColumn, onChangeOption: onLinkToObjChange })), modalType == 'linkToSchema' && (_jsx(LinkToSelectionSchemaConfigurationSection, { onChangeOption: onLinkToSelectSchemaChange })), modalType == 'rollup' && (_jsx(RollupSchemaConfigurationSection, { onRollupSchemaSave: onRollupSchemaChange, collectionId: collectionId, preexistingRollupId: column === null || column === void 0 ? void 0 : column.rollupId, setOkDisabled: setOkDisabled })), modalType == 'formula' && (_jsx(FormulaSchemaConfigurationSection, { collectionId: collectionId, formulaSchemaOnChange: onFormulaSchemaChange, schemaId: schemaId })), modalType == 'currency' && (_jsx(CurrencyConfigurationSection, { collectionId: collectionId, onChange: changeSchemaConfig, schemaConfig: column === null || column === void 0 ? void 0 : column.configuration, setOkDisabled: setOkDisabled })), modalType == 'generative' && (_jsx(GeneratedIdSchemaConfigurationSection, { collectionId: collectionId, onGenerativeSchemaSave: changeSchemaConfig, generativeSchemaConfig: column === null || column === void 0 ? void 0 : column.configuration, setOkDisabled: setOkDisabled })), modalType == 'button' && (_jsx(ButtonSchemaConfigurationSection, { buttonSchemaConfig: column === null || column === void 0 ? void 0 : column.configuration, schemaConfigOnChange: function (config) {
                    return changeSchemaConfig(SchemaConfigurationType.BUTTON, config);
                } })), modalType === 'rating' && (_jsx(RatingSchemaConfigurationSection, { ratingSchemaConfig: column === null || column === void 0 ? void 0 : column.configuration, schemaConfigOnChange: function (config) {
                    return changeSchemaConfig(SchemaConfigurationType.RATING, config);
                } })), (modalType == 'single' || modalType == 'multi') && (_jsx(SelectSchemaConfigurationSection, { isSmartField: isSmartField, smartFieldSwitch: smartFieldSwitch, changeSchemaConfig: changeSchemaConfig, collectionId: collectionId, generativeSchemaConfig: column.configuration, selectOptions: selectOptions, setSelectOptions: setSelectOptions, schema: column, schemaTypeChanged: schemaTypeChanged, addToSelectSchemaEditQueue: addToSelectSchemaEditQueue }))] })) : (_jsx(_Fragment, {}));
};
export default React.memo(SchemaConfigurationSection);
