import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isNeedsCoverageConfigModalVisibleState, needsCoverageConfigModalActiveTabState, } from '../Atom';
import { NeedsCoverageType } from '../interface';
import NeedsCoverageTemplateConfigModal from './ConfigModal/NeedsCoverageTemplateConfigModal';
var NeedsCoverageTableEmptyContent = function (_a) {
    var blockId = _a.blockId, type = _a.type;
    var _b = useRecoilState(isNeedsCoverageConfigModalVisibleState), isConfigModalVisible = _b[0], setIsConfigModalVisible = _b[1];
    var setConfigModalActiveTab = useSetRecoilState(needsCoverageConfigModalActiveTabState);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex h-[150px] flex-col justify-center", children: [_jsx("div", { className: "text-center text-base font-medium text-gray-300", children: "No Data" }), _jsxs("div", { className: "flex justify-center text-gray-300", children: ["Click", _jsx("span", { className: "mx-1 cursor-pointer rounded bg-gray-40 px-1 text-center font-medium text-gray-500", onClick: function () {
                                    setIsConfigModalVisible(true);
                                    if (type === 'coverage') {
                                        setConfigModalActiveTab(NeedsCoverageType.COVERAGE);
                                    }
                                }, children: "Configure Needs & Coverage" }), "to change settings"] })] }), isConfigModalVisible && _jsx(NeedsCoverageTemplateConfigModal, { blockId: blockId })] }));
};
export default React.memo(NeedsCoverageTableEmptyContent);
