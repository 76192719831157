import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'antd';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isQuickCreateModalVisibleState, quickCreateModalCollectionInfoState } from 'src/state';
var QuickCreateModalHeader = function () {
    var collectionName = useRecoilValue(quickCreateModalCollectionInfoState).collectionName;
    var setQuickCreateModalVisible = useSetRecoilState(isQuickCreateModalVisibleState);
    return (_jsxs("div", { className: "QuickCreateModalHeader", children: [_jsxs("div", { children: ["New ", collectionName] }), _jsx(Button, { className: "CloseButton", onClick: function () { return setQuickCreateModalVisible(false); }, children: "\u2715" })] }));
};
export default React.memo(QuickCreateModalHeader);
