import { atom } from 'recoil';
var isAddNewBlockDrawerVisible = atom({
    key: 'isAddNewBlockDrawerVisibleState',
    default: false,
});
var blockPlaceholderImgState = atom({
    key: ' blockPlaceholderImgState',
    default: undefined,
});
export { isAddNewBlockDrawerVisible, blockPlaceholderImgState };
