/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { isCurrentPageScheduleTypeSelector, tableFiltersSelector, } from 'src/state';
import { currentNeedOrCoverageBeingAppliedState } from 'src/components/ScheduleView/FullPageScheduleBottomBar/Needs&Coverage/Atoms/Atoms';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { NEEDS_COVERAGE_BLOCKING_MODAL } from 'src/components/FeatureGatingKeyConstant';
/**
 * The main purpose of this hook is to determine whether to block the Schedule page's main content
 * with an uncloseable modal while a need or coverage is applying or being deleted.
 *
 * To do so, we first need access to the needs and coverage templates, because each template has an array of
 * appliedRange IDs, representing all time ranges where that need has been applied. We need that string array of IDs
 * to be able to poll a logs/status endpoint for whether or not any of those IDs are "in transaction" (aka being applied).
 *
 * We only want to keep polling until all appliedRange IDs are done transacting.
 *
 * When a user clicks to apply a need or coverage, client-side recoil state is immediately set as currentNeedOrCoverageBeingApplied
 * to instantly block the screen until the API and polling catch up (aka until the templates GET updates with the correct applied ranges
 * and then the polling restarts with the new appliedRange IDs). The client-side recoil state only gets reset once the polling stops
 * and there are no more transacting ranges.
 *
 * At this point in time, we only want to show the blocker when the relevant filters are applied -- aka if the Schedule is filtered on
 * Location: Philly, then we only want to check for needs that match that location. That's why we need tableId to be passed to the hook
 * so we can access the current "Schedule block"'s filters.
 *
 * Other note: Removing a need or coverage does nothing in the logs endpoint on the BE. On the FE, there is "coincidental" support for showing the blocker
 * when a range is "removed" via the UI. Basically, when the removal is initiated, we set the client side recoil state, templates are refetched, polling
 * is started, and polling ultimately shows nothing is in transaction, and the "loop" closes again. This is virtually unknowable without having written it
 * yourself and it's also a symptom of invalidating react-query keys behind the scenes (aka removing a need or coverage from a range will invalidate the
 * templates query and trigger a refetch).
 */
var usePageBlockerForNeedsAndCoverage = function (tableId) {
    var _a;
    var isCurrentPageScheduleType = useRecoilValue(isCurrentPageScheduleTypeSelector);
    var isNeedsCoverageBlockerEnabled = useRecoilValue(featureEnableStatusState(NEEDS_COVERAGE_BLOCKING_MODAL));
    var _b = useNeedCoverageApi(), useGetAllLogStatuses = _b.useGetAllLogStatuses, useGetAllNeedTemplates = _b.useGetAllNeedTemplates, useGetAllCoverageTemplates = _b.useGetAllCoverageTemplates;
    var _c = useState([]), appliedRangeIDsForNeeds = _c[0], setAppliedRangeIDsForNeeds = _c[1];
    var _d = useState([]), appliedRangeIDsForCoverage = _d[0], setAppliedRangeIDsForCoverage = _d[1];
    // Default these states to true so we can immediately check if something is inTransaction when a user returns to the page
    var _e = useState(true), shouldRefetchNeedsStatuses = _e[0], setShouldRefetchNeedsStatuses = _e[1];
    var _f = useState(true), shouldRefetchCoverageStatuses = _f[0], setShouldRefetchCoverageStatuses = _f[1];
    var currentNeedOrCoverageBeingApplied = useRecoilValue(currentNeedOrCoverageBeingAppliedState);
    var setCurrentNeedOrCoverageBeingApplied = useSetRecoilState(currentNeedOrCoverageBeingAppliedState);
    var blockFilters = useRecoilValue(tableFiltersSelector(tableId));
    var templateFilters = (_a = blockFilters === null || blockFilters === void 0 ? void 0 : blockFilters.filter(function (filter) {
        return ['native_location_id', 'native_roles_id'].includes(filter.clauseType);
    })) !== null && _a !== void 0 ? _a : [];
    /* Logic for needs */
    var allNeedTemplates = useGetAllNeedTemplates({
        body: {
            filters: templateFilters,
        },
        options: {
            onSuccess: function (all) {
                setAppliedRangeIDsForNeeds(getAppliedRangeIds(all));
                setTimeout(function () {
                    // 1. We need to reset the should refetch to true to re-kick off polling
                    // 2. We need to delay by 3 seconds to give the backend/logs a chance to update -- otherwise we may fetch
                    // too soon and run into a race condition
                    setShouldRefetchNeedsStatuses(true);
                }, 3000);
            },
            enabled: isNeedsCoverageBlockerEnabled,
        },
    }).data;
    var needsLogStatuses = useGetAllLogStatuses('needs', {
        body: appliedRangeIDsForNeeds,
        options: {
            enabled: isNeedsCoverageBlockerEnabled &&
                isCurrentPageScheduleType &&
                shouldRefetchNeedsStatuses,
            refetchInterval: 1000,
        },
    }).data;
    useEffect(function () {
        var allAppliedNeedRangesHaveCompletedTransacting = allNeedTemplates &&
            needsLogStatuses &&
            !needsLogStatuses.some(function (log) { return log.inTransaction; });
        if (allAppliedNeedRangesHaveCompletedTransacting) {
            // Clear client-side recoil state
            setCurrentNeedOrCoverageBeingApplied(null);
            // Stop polling (until another range is applied)
            setShouldRefetchNeedsStatuses(false);
        }
    }, [
        appliedRangeIDsForNeeds,
        needsLogStatuses,
        setCurrentNeedOrCoverageBeingApplied,
        shouldRefetchNeedsStatuses,
        isNeedsCoverageBlockerEnabled,
    ]);
    /**/
    /* Logic for coverage */
    var allCoverageTemplates = useGetAllCoverageTemplates({
        body: {
            filters: templateFilters,
        },
        options: {
            onSuccess: function (all) {
                setAppliedRangeIDsForCoverage(getAppliedRangeIds(all));
                setTimeout(function () {
                    // 1. We need to reset the should refetch to true to re-kick off polling
                    // 2. We need to delay by 3 seconds to give the backend/logs a chance to update -- otherwise we may fetch
                    // too soon and run into a race condition
                    setShouldRefetchCoverageStatuses(true);
                }, 3000);
            },
            enabled: isNeedsCoverageBlockerEnabled,
        },
    }).data;
    var coverageLogStatuses = useGetAllLogStatuses('coverage', {
        body: appliedRangeIDsForCoverage,
        options: {
            enabled: isNeedsCoverageBlockerEnabled &&
                isCurrentPageScheduleType &&
                shouldRefetchCoverageStatuses,
            refetchInterval: 1000,
        },
    }).data;
    var needInTransaction = useMemo(function () { return getPendingNeedOrCoverage(needsLogStatuses, allNeedTemplates, 'need'); }, [needsLogStatuses, allNeedTemplates, appliedRangeIDsForNeeds]);
    var coverageInTransaction = useMemo(function () { return getPendingNeedOrCoverage(coverageLogStatuses, allCoverageTemplates, 'coverage'); }, [coverageLogStatuses, allCoverageTemplates, appliedRangeIDsForCoverage]);
    useEffect(function () {
        var allAppliedCoverageRangesHaveCompletedTransacting = allCoverageTemplates &&
            coverageLogStatuses &&
            !coverageLogStatuses.some(function (log) { return log.inTransaction; });
        if (allAppliedCoverageRangesHaveCompletedTransacting) {
            // Clear client-side recoil state
            setCurrentNeedOrCoverageBeingApplied(null);
            // Stop polling (until another range is applied)
            setShouldRefetchCoverageStatuses(false);
        }
    }, [
        appliedRangeIDsForCoverage,
        coverageLogStatuses,
        setCurrentNeedOrCoverageBeingApplied,
        shouldRefetchCoverageStatuses,
        isNeedsCoverageBlockerEnabled,
    ]);
    /**/
    /* Logic for both needs and coverage */
    useEffect(function () {
        // Immediately reset the appliedRange IDs to make sure we only include the relevant templates
        setAppliedRangeIDsForNeeds(getAppliedRangeIds(allNeedTemplates));
        setAppliedRangeIDsForCoverage(getAppliedRangeIds(allCoverageTemplates));
        // Immediately kick off polling again for transacting ranges when a filter changes (this can happen when the user manually
        // changes the filter on the same page or switches to a different schedule page with different filters)
        setShouldRefetchNeedsStatuses(true);
        setShouldRefetchCoverageStatuses(true);
    }, [blockFilters]);
    var shouldShowBlockerModal = useMemo(function () {
        // We only ever want to show the blocker when we're on a schedule PAGE but *not* when we're on a canvas page that happens to have a schedule block
        if (!isCurrentPageScheduleType || !isNeedsCoverageBlockerEnabled)
            return false;
        return (!!currentNeedOrCoverageBeingApplied ||
            (coverageLogStatuses === null || coverageLogStatuses === void 0 ? void 0 : coverageLogStatuses.some(function (log) { return log.inTransaction; })) ||
            (needsLogStatuses === null || needsLogStatuses === void 0 ? void 0 : needsLogStatuses.some(function (log) { return log.inTransaction; })));
    }, [
        currentNeedOrCoverageBeingApplied,
        appliedRangeIDsForNeeds,
        appliedRangeIDsForCoverage,
        needsLogStatuses,
        coverageLogStatuses,
        isCurrentPageScheduleType,
        isNeedsCoverageBlockerEnabled,
    ]);
    return {
        shouldShowBlockerModal: shouldShowBlockerModal,
        currentNeedOrCoverageBeingApplied: currentNeedOrCoverageBeingApplied || needInTransaction || coverageInTransaction,
    };
};
export default usePageBlockerForNeedsAndCoverage;
/** Helper funcs */
var getAppliedRangeIds = function (templates) {
    var _a;
    return (_a = templates === null || templates === void 0 ? void 0 : templates.map(function (tag) { return tag.appliedRange.map(function (range) { return range.id; }); }).flat()) !== null && _a !== void 0 ? _a : [];
};
var getPendingNeedOrCoverage = function (statuses, templates, type) {
    var pendingStatus = statuses === null || statuses === void 0 ? void 0 : statuses.find(function (log) { return log.inTransaction; });
    if (!pendingStatus)
        return null;
    var associatedItem = templates === null || templates === void 0 ? void 0 : templates.find(function (item) {
        return item.appliedRange.find(function (range) { return range.id === pendingStatus.logId; });
    });
    var pendingRange = associatedItem === null || associatedItem === void 0 ? void 0 : associatedItem.appliedRange.find(function (range) { return range.id === pendingStatus.logId; });
    if (!associatedItem || !pendingRange)
        return null;
    return { item: associatedItem, range: pendingRange, type: type };
};
/**/
