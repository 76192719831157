var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { useTimeZone } from 'src/hooks/component';
import { emailValidation, phoneNumberValidation } from 'src/utils/Tools/InputValidation';
import { CellInputMode, NativePath, NativeTextInputSet, selectionStandardTypes, ValueDataType, } from '../../shared';
import { useAccountApi } from '../../hooks/api';
import { getIfNeedsFetchDataInCell, getNativeType } from '../../utils/Collection';
import FileCell from '../OptimizedTableView/Cell/FileCell/FileCell';
import AddressCell from '../TableView/TableCell/AddressCell';
import ApprovalStatusCell from '../TableView/TableCell/ApprovalStatusCell';
import DatePickerPopover from '../TableView/TableCell/DatePickerPopover';
import SelectorCell from '../TableView/TableCell/SelectorCell';
import TextCellWithPopUp from '../TableView/TableCell/TextCellWithPopUp';
var SimpleSelectorCellWrapper = function (props) {
    var schema = props.schema, editCell = props.editCell, canEdit = props.canEdit, defaultValue = props.defaultValue, updateWithDefault = props.updateWithDefault;
    var inputMode = schema.inputMode, nativePath = schema.nativePath, type = schema.type;
    var isNativeBool = type === ValueDataType.NATIVE_BOOL;
    var _a = useState(isNativeBool
        ? [
            {
                id: 'true',
                text: 'Yes',
                color: '#E5F4EF',
            },
            {
                id: 'false',
                text: 'No',
                color: '#FFEDEC',
            },
        ]
        : []), availableOptions = _a[0], setAvailableOptions = _a[1];
    var useGetUniversalSimpleListWithoutAuth = useAccountApi().useGetUniversalSimpleListWithoutAuth;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var fetchType = getIfNeedsFetchDataInCell(schema).fetchType;
    //TODO: add pagenation here
    var data = useGetUniversalSimpleListWithoutAuth({
        schemaId: schema.id,
        options: { enabled: dropdownOpen && !isNativeBool },
    }).data;
    useEffect(function () {
        if (data && !isNativeBool) {
            setAvailableOptions(data.map(function (option) { return ({
                id: option.id,
                text: option.name,
                color: option.color || '',
                userAvatar: option.image,
            }); }));
        }
    }, [data, isNativeBool]);
    var onMutateDisplayValuesInItem = useCallback(function (values) { return editCell(values); }, [editCell]);
    return (_jsx(SelectorCell, { nativePath: nativePath, inputMode: inputMode, isUserSelecting: fetchType === 'users', availableOption: availableOptions, canEdit: canEdit, isForm: true, isDetailModal: false, type: type, showColorMenu: true, displayedOption: [], defaultValue: defaultValue, mutateDisplayedValueInItem: onMutateDisplayValuesInItem, updateWithDefault: updateWithDefault, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen }));
};
var CustomCell = function (props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var schema = props.schema, editCell = props.editCell, canEdit = props.canEdit, defaultValue = props.defaultValue, defaultFileValues = props.defaultFileValues, updateWithDefault = props.updateWithDefault;
    var dataType = schema.type, inputMode = schema.inputMode;
    var timeZone = useTimeZone().timeZone;
    var onEditCellWithSingleValue = useCallback(function (newValue) {
        if (newValue) {
            editCell([newValue]);
        }
    }, [editCell]);
    if (dataType === ValueDataType.FILE) {
        return (_jsx("span", { className: "ml-1", children: _jsx(FileCell, { files: defaultFileValues || [], editCell: editCell, canEdit: canEdit, mode: schema.inputMode === CellInputMode.NATIVE ? 'single' : 'multiple', placeHolder: _jsx("span", { className: "relative mr-1 inline-block min-h-full min-w-full py-1 font-medium text-gray-300", children: "Upload File" }), linkTemplateEnabled: true, isModalCell: false }) }));
    }
    /**
     * native item
     */
    if (inputMode === CellInputMode.NATIVE) {
        var nativeType = schema.type;
        var nativePath = schema.nativePath;
        if (getNativeType(nativeType) === ValueDataType.DATETIME) {
            if (nativePath === NativePath.USER_LAST_ACTIVE) {
                return (_jsx(TextCellWithPopUp, { text: defaultValue && defaultValue[0], editCell: onEditCellWithSingleValue, canEdit: canEdit, isDetailModal: true, updateWithDefault: updateWithDefault }));
            }
            return (_jsx(DatePickerPopover, { data: {
                    type: ValueDataType.DATETIME,
                    showTime: true,
                    dateTime: defaultValue && defaultValue[0],
                }, onEditTime: editCell, canEdit: canEdit, isDetailModal: true, updateWithDefault: updateWithDefault, timeZone: timeZone }));
        }
        else if (NativeTextInputSet.has(getNativeType(nativeType))) {
            if (nativePath === NativePath.LOCATION_ADDRESS) {
                return (_jsx(AddressCell, { isDetailModal: true, defaultValue: defaultValue && defaultValue[0], updateWithDefault: updateWithDefault }));
            }
            var validationFunction = dataType === ValueDataType.NATIVE_EMAIL
                ? emailValidation
                : dataType === ValueDataType.NATIVE_PHONE
                    ? phoneNumberValidation
                    : undefined;
            return (_jsx(TextCellWithPopUp, { text: defaultValue && defaultValue[0], editCell: onEditCellWithSingleValue, canEdit: canEdit, isDetailModal: true, updateWithDefault: updateWithDefault, validationFunction: validationFunction }));
        }
        else if (selectionStandardTypes.has(getNativeType(nativeType))) {
            return _jsx(SimpleSelectorCellWrapper, __assign({}, props));
        }
        else if (getNativeType(nativeType) === ValueDataType.BOOLEAN ||
            getNativeType(nativeType) === ValueDataType.NATIVE_SELECTION) {
            return _jsx(SimpleSelectorCellWrapper, __assign({}, props));
        }
        else if (getNativeType(nativeType) === ValueDataType.NATIVE_SPECIAL) {
            // !More special native to be handled here
            if (nativePath === NativePath.TIMEOFF_APPROVAL_STATUS) {
                return (_jsx(ApprovalStatusCell, { extraProps: props, editCell: onEditCellWithSingleValue, isDetailModal: true }));
            }
        }
        else {
            return _jsxs("div", { style: { padding: '0.6rem 0.5rem' }, children: [nativePath, " not supported yet."] });
        }
    }
    /**
     * multiSelection
     */
    if (inputMode == CellInputMode.SINGLE ||
        inputMode == CellInputMode.MULTI ||
        inputMode == CellInputMode.INTEGRATION) {
        return _jsx(SimpleSelectorCellWrapper, __assign({}, props));
    }
    /**
     * Plain or Metric Type Cell
     */
    // Datetime type
    if (dataType === ValueDataType.DATETIME) {
        var isDateRange = defaultValue && defaultValue.length == 2;
        return !isDateRange ? (_jsx(DatePickerPopover, { data: {
                type: ValueDataType.DATETIME,
                showTime: true,
                dateTime: defaultValue && defaultValue[0],
            }, onEditTime: editCell, canEdit: canEdit, isDetailModal: true, updateWithDefault: updateWithDefault, timeZone: timeZone })) : (_jsx(DatePickerPopover, { data: {
                type: ValueDataType.DATETIME_RANGE,
                from: defaultValue && defaultValue[0],
                to: defaultValue && defaultValue[1],
                showTime: true,
            }, onEditTime: editCell, canEdit: canEdit, isDetailModal: true, updateWithDefault: updateWithDefault, timeZone: timeZone }));
    }
    //TODO: deal with more keys
    return (_jsx(TextCellWithPopUp, { text: defaultValue && defaultValue[0], editCell: onEditCellWithSingleValue, canEdit: canEdit, isDetailModal: true, updateWithDefault: updateWithDefault }));
};
// TODO: https://linear.app/zira/issue/ZIRA-1120/separate-customtablecell-logics-business-logic-base-ui-logic
export default React.memo(CustomCell);
