import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Switch } from 'antd';
import { SchemaConfigurationType } from 'src/shared';
var DateSchemaConfigurationSection = function (_a) {
    var column = _a.column, schemaConfigOnChange = _a.schemaConfigOnChange;
    var isShowTime = useMemo(function () {
        var _a;
        return ((_a = column.dateSchemaConfig) === null || _a === void 0 ? void 0 : _a.showTime) || false;
    }, [column]);
    var isCountdown = useMemo(function () {
        var _a;
        return ((_a = column.dateSchemaConfig) === null || _a === void 0 ? void 0 : _a.isCountdown) || false;
    }, [column]);
    var setIsShowTime = useCallback(function (showTime) {
        var _a;
        schemaConfigOnChange({
            type: SchemaConfigurationType.DATETIME,
            showTime: showTime,
            isCountdown: ((_a = column.dateSchemaConfig) === null || _a === void 0 ? void 0 : _a.isCountdown) || false,
        });
    }, [column, schemaConfigOnChange]);
    var setIsCountdown = useCallback(function (isCountdown) {
        var _a;
        schemaConfigOnChange({
            type: SchemaConfigurationType.DATETIME,
            showTime: ((_a = column.dateSchemaConfig) === null || _a === void 0 ? void 0 : _a.showTime) || false,
            isCountdown: isCountdown,
        });
    }, [column, schemaConfigOnChange]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center justify-between font-semibold mb-3", children: [_jsx("div", { children: "Include Time" }), _jsx(Switch, { checked: isShowTime, onChange: setIsShowTime })] }), _jsxs("div", { className: "flex items-center justify-between font-semibold", children: [_jsx("div", { children: "Include Countdown" }), _jsx(Switch, { checked: isCountdown, onChange: setIsCountdown })] })] }));
};
export default React.memo(DateSchemaConfigurationSection);
