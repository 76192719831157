var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Switch } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import ZiraTooltip from 'src/ui/ZiraTooltip';
var PropagationSettings = function (_a) {
    var changesInfo = _a.changesInfo, changePropagationSettings = _a.changePropagationSettings, configureSettings = _a.configureSettings;
    return (_jsxs("section", { className: "mt-4", children: [_jsxs("div", { className: "flex flex-row items-center gap-1 text-gray-400 uppercase text-[10px] font-semibold", children: [_jsxs("span", { children: ["Configure ", changesInfo.jobName, " Placements"] }), _jsx(ZiraTooltip, { title: "Select at least one setting below to publish", children: _jsx(InfoCircleOutlined, { className: "cursor-pointer mb-[1px]" }) })] }), _jsxs("div", { className: "flex flex-row items-center justify-between mt-2 pt-2 pb-3 border-b border-[#E9E9EC]", children: [_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex flex-row items-center font-semibold", children: [_jsx("span", { className: "text-sm mr-2", children: "Placed Placements" }), _jsx("div", { className: "bg-[#F3F1FB] py-[5px] px-[6px] mt-[1px] flex flex-row items-center justify-center rounded-full text-xs text-[#2F4DDF]", children: changesInfo.placedPlacementCount })] }), _jsxs("span", { children: ["Placements for which an employee is actively being placed.", _jsx(ZiraTooltip, { title: "Push changes to placements in the 'Placed' stage", children: _jsx(InfoCircleOutlined, { className: "text-sm cursor-pointer ml-1 mb-[3px] text-gray-400" }) })] })] }), _jsx("div", { children: _jsx(Switch, { checked: changePropagationSettings.placed, onChange: function (val) {
                                return configureSettings(__assign(__assign({}, changePropagationSettings), { placed: val }));
                            } }) })] }), _jsxs("div", { className: "flex flex-row items-center justify-between mt-2 pt-2 pb-3 border-b border-[#E9E9EC]", children: [_jsxs("div", { className: "flex flex-col", children: [_jsxs("div", { className: "flex flex-row items-center font-semibold", children: [_jsx("span", { className: "text-sm mr-2", children: "Pending Placements" }), _jsx("div", { className: "bg-[#F3F1FB] py-[5px] px-[6px] mt-[1px] flex flex-row items-center justify-center rounded-full text-xs text-[#2F4DDF]", children: changesInfo.pendingPlacementCount })] }), _jsxs("span", { children: ["Placements for which applications are received and are yet to be placed in job.", _jsx(ZiraTooltip, { title: "Push changes to placements in the 'Draft' or 'Published' stage", children: _jsx(InfoCircleOutlined, { className: "text-sm cursor-pointer ml-1 mb-[3px] text-gray-400" }) })] })] }), _jsx("div", { children: _jsx(Switch, { checked: changePropagationSettings.pending, onChange: function (val) {
                                return configureSettings(__assign(__assign({}, changePropagationSettings), { pending: val }));
                            } }) })] }), _jsxs("div", { className: "flex flex-row items-center justify-between mt-2 pt-2", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "flex flex-row font-semibold", children: _jsx("span", { className: "text-sm mr-2", children: "All Future Placements" }) }), _jsxs("span", { children: ["These are all placements in the future that occur after the task changes.", _jsx(ZiraTooltip, { title: "Push changes to the task template", children: _jsx(InfoCircleOutlined, { className: "text-sm cursor-pointer ml-1 mb-[3px] text-gray-400" }) })] })] }), _jsx("div", { children: _jsx(Switch, { checked: changePropagationSettings.future, onChange: function (val) {
                                return configureSettings(__assign(__assign({}, changePropagationSettings), { future: val }));
                            } }) })] })] }));
};
export default React.memo(PropagationSettings);
