import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TaskTab } from '../interface';
import './TasksSettingEmptyContent.scss';
import { isTaskSettingMobilePreviewVisibleState, selectedTaskGroupIdState, selectedTaskRuleIdState, selectedTaskTemplateIdState, } from '../atom';
import { NEW_TASK_GROUP_ID, NEW_TASK_RULE_ID, NEW_TASK_TEMPLATE_ID } from '../constant';
import TaskMobilePreview from '../shared/TaskMobilePreview';
var TasksSettingEmptyContent = function (_a) {
    var activeTab = _a.activeTab;
    var formattedActiveTab = "".concat(activeTab.charAt(0)).concat(activeTab.slice(1, -1).toLowerCase());
    var setSelectedTaskGroupId = useSetRecoilState(selectedTaskGroupIdState);
    var setSelectedTemplateId = useSetRecoilState(selectedTaskTemplateIdState);
    var setSelectedTaskRuleId = useSetRecoilState(selectedTaskRuleIdState);
    var isTaskSettingMobilePreviewVisible = useRecoilValue(isTaskSettingMobilePreviewVisibleState);
    return (_jsxs("div", { className: "TaskDetailContainer", children: [_jsxs("div", { className: "h-full w-full flex flex-col items-center justify-center", children: [_jsxs("span", { className: "font-semibold", children: ["No ", activeTab.toLowerCase(), " added"] }), _jsxs("span", { className: "font-medium text-gray-300 mt-2", children: ["Get started by adding a new ", activeTab.slice(0, -1).toLowerCase()] }), _jsx(Button, { className: "AddNewButton bg-black mt-2", onClick: function () {
                            if (activeTab === TaskTab.TEMPLATES) {
                                setSelectedTemplateId(NEW_TASK_TEMPLATE_ID);
                            }
                            else if (activeTab === TaskTab.GROUPS) {
                                setSelectedTaskGroupId(NEW_TASK_GROUP_ID);
                            }
                            else if (activeTab === TaskTab.RULES) {
                                setSelectedTaskRuleId(NEW_TASK_RULE_ID);
                            }
                        }, children: _jsxs("div", { children: [_jsx(PlusOutlined, { className: "mr-1" }), _jsx("span", { className: "overflow-hidden text-ellipsis", children: "New ".concat(formattedActiveTab) })] }) })] }), isTaskSettingMobilePreviewVisible && _jsx(TaskMobilePreview, {})] }));
};
export default React.memo(TasksSettingEmptyContent);
