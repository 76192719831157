import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ReportMetricsIcon } from 'src/utils/icons/ReportMetricsIcon';
import ReportFormulas from './ReportFormulas';
import ReportMetrics from './ReportMetrics';
import ReportSchemas from './ReportSchemas';
var ReportMetricsCard = function (_a) {
    var metricList = _a.metricList, reportMetrics = _a.reportMetrics, setReportMetrics = _a.setReportMetrics, reportSchemas = _a.reportSchemas, setReportSchemas = _a.setReportSchemas, reportFormulas = _a.reportFormulas, setReportFormulas = _a.setReportFormulas, sourceCollectionId = _a.sourceCollectionId;
    return (_jsxs("div", { className: "ReportBuilderCard", children: [_jsx("div", { className: "CardHeader", children: _jsxs("div", { className: "HeaderText", children: [_jsx(ReportMetricsIcon, { className: "CardIcon" }), _jsx("div", { className: "HeaderTitle", children: "Report Metrics" }), _jsx("div", { className: "HeaderSubtitle", children: "Pick the metrics that will define the data the report showcases" })] }) }), _jsxs("div", { className: "CardContent", children: [_jsx(ReportMetrics, { metricList: metricList, reportMetrics: reportMetrics, setReportMetrics: setReportMetrics, showVariable: !!reportFormulas.length }), !!reportSchemas.length && _jsx("div", { className: "mt-4" }), _jsx(ReportSchemas, { reportSchemas: reportSchemas, setReportSchemas: setReportSchemas, sourceCollectionId: sourceCollectionId, showVariable: !!reportFormulas.length }), !!reportFormulas.length && _jsx("div", { className: "mt-4" }), _jsx(ReportFormulas, { reportFormulas: reportFormulas, setReportFormulas: setReportFormulas, reportMetricNames: reportMetrics.map(function (_a) {
                            var metricLabel = _a.metricLabel;
                            return metricLabel;
                        }), reportSchemaIds: reportSchemas.map(function (_a) {
                            var schemaId = _a.schemaId;
                            return schemaId;
                        }), enabled: !!reportMetrics.length || !!reportSchemas.length })] })] }));
};
export default React.memo(ReportMetricsCard);
