import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileOutlined } from '@ant-design/icons';
import { Space, Image } from 'antd';
import { useMemo } from 'react';
var MessageAttachmentItem = function (_a) {
    var attachment = _a.attachment;
    var isImage = useMemo(function () { return attachment.match(/\.(jpeg|jpg|gif|png)$/i); }, [attachment]);
    var fileName = useMemo(function () { return attachment.substring(attachment.lastIndexOf('/') + 1); }, [attachment]);
    return (_jsx("div", { children: isImage ? (_jsx("div", { className: "ImageAttachment", children: _jsx(Image, { src: attachment, alt: "attachment", className: "max-h-[320px]" }) })) : (_jsxs(Space, { className: "FileAttachment", children: [_jsx(FileOutlined, {}), _jsx("a", { href: attachment, target: "_blank", rel: "noreferrer", children: fileName })] })) }));
};
export default MessageAttachmentItem;
