var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var MobilePreviewLinkSvg = function () { return (_jsxs("svg", { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsxs("g", { clipPath: "url(#clip0_13634_8350)", children: [_jsx("path", { d: "M8.11605 4H4.11605C3.76243 4 3.42329 4.14048 3.17324 4.39052C2.92319 4.64057 2.78271 4.97971 2.78271 5.33333V12C2.78271 12.3536 2.92319 12.6928 3.17324 12.9428C3.42329 13.1929 3.76243 13.3333 4.11605 13.3333H10.7827C11.1363 13.3333 11.4755 13.1929 11.7255 12.9428C11.9756 12.6928 12.116 12.3536 12.116 12V8", stroke: "#FFF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M7.44971 8.66699L13.4497 2.66699", stroke: "#FFF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M10.1162 2.66699H13.4495V6.00033", stroke: "#FFF", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_13634_8350", children: _jsx("rect", { width: "16", height: "16", fill: "white", transform: "translate(0.116211)" }) }) })] })); };
export var MobilePreviewExternalLink = function (props) { return (_jsx(Icon, __assign({ component: MobilePreviewLinkSvg }, props))); };
