var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var SendUserInviteSvg = function () { return (_jsx("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.4342 3.00233L3.06643 5.45827L6.01355 7.42302L10.4342 3.00233ZM6.80537 8.28111L8.63759 11.0294L10.9279 4.15863L6.80537 8.28111ZM12.161 1.19696C12.6171 1.04495 13.0509 1.4788 12.8989 1.93482L9.36722 12.5299C9.21371 12.9904 8.59772 13.0729 8.32846 12.669L5.63254 8.6251C5.58982 8.56102 5.53484 8.50603 5.47076 8.46331L1.42689 5.7674C1.02299 5.49814 1.10549 4.88214 1.566 4.72864L12.161 1.19696Z", fill: "black" }) })); };
export var SendUserInviteIcon = function (props) { return (_jsx(Icon, __assign({ component: SendUserInviteSvg }, props))); };
