var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'antd';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { SchemaConfigurationType } from 'src/shared';
import { useRecoilState } from 'recoil';
import { collectionTotalRecordsCountState } from 'src/state';
import { usePageApi } from 'src/hooks/api';
import { GeneratedIdType, } from 'src/components/OptimizedTableView/GenerativeConfigurationModal/interface/interface';
import { GenerativeType } from 'src/components/OptimizedTableView/GenerativeConfigurationModal/interface/constants';
import { getGenerativeConfigurationFromSchemaConfiguration, getSchemaConfigurationFromGenerativeConfiguration, } from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/util';
import GenerativeTypeSelector from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/GenerativeTypeSelector';
import { GenerativeConfigurationContext } from 'src/components/OptimizedTableView/GenerativeConfigurationModal/GeneratedIdConfigurationModal';
import GeneratedIdConfigurationSection from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/GeneratedIdConfigurationSection';
var initialState = {
    collectionId: undefined,
    type: GenerativeType.GENERATED_ID,
    generatedIdType: GeneratedIdType.PLAINTEXT,
    format: '',
};
export var GENERATIVE_MODAL_Z_INDEX = 1100;
export var GenerativeActionType;
(function (GenerativeActionType) {
    GenerativeActionType[GenerativeActionType["SET_GENERATED_ID_CONFIGURATION"] = 0] = "SET_GENERATED_ID_CONFIGURATION";
})(GenerativeActionType || (GenerativeActionType = {}));
function generativeReducer(state, action) {
    switch (action.type) {
        case GenerativeActionType.SET_GENERATED_ID_CONFIGURATION:
            return __assign(__assign({}, state), action.payload);
    }
}
var GeneratedIdSchemaConfigurationSection = function (_a) {
    var collectionId = _a.collectionId, onGenerativeSchemaSave = _a.onGenerativeSchemaSave, generativeSchemaConfig = _a.generativeSchemaConfig, setOkDisabled = _a.setOkDisabled;
    var generatedConfiguration = generativeSchemaConfig
        ? getGenerativeConfigurationFromSchemaConfiguration(generativeSchemaConfig)
        : {};
    var _b = useReducer(generativeReducer, __assign(__assign(__assign({}, initialState), generatedConfiguration), { collectionId: collectionId })), generativeConfiguration = _b[0], dispatch = _b[1];
    var _c = useRecoilState(collectionTotalRecordsCountState(collectionId)), allRecordsCount = _c[0], setAllRecordsCount = _c[1];
    var useGetAllRecordsCount = usePageApi().useGetAllRecordsCount;
    var recordsCount = useGetAllRecordsCount(collectionId).data;
    var formInstance = Form.useForm()[0];
    formInstance.validateFields();
    useEffect(function () {
        if (recordsCount)
            setAllRecordsCount(recordsCount.count);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordsCount]);
    useEffect(function () {
        formInstance.validateFields();
    }, [formInstance]);
    useEffect(function () {
        var schemaConfiguration = getSchemaConfigurationFromGenerativeConfiguration(generativeConfiguration);
        onGenerativeSchemaSave(SchemaConfigurationType.GENERATED_ID, schemaConfiguration);
    }, [onGenerativeSchemaSave, generativeConfiguration]);
    var setGeneratedIdConfiguration = useCallback(function (generatedIdConfiguration) {
        dispatch({
            type: GenerativeActionType.SET_GENERATED_ID_CONFIGURATION,
            payload: generatedIdConfiguration,
        });
        formInstance.setFieldsValue(generatedIdConfiguration);
    }, [formInstance]);
    var isGeneratedId = useMemo(function () { return generativeConfiguration.type === GenerativeType.GENERATED_ID; }, [generativeConfiguration]);
    return (_jsx(GenerativeConfigurationContext.Provider, { value: {
            generativeConfiguration: generativeConfiguration,
            setGeneratedIdConfiguration: setGeneratedIdConfiguration,
        }, children: _jsxs("div", { className: "flex flex-col", children: [_jsx(GenerativeTypeSelector, {}), _jsx(Form, { className: "mt-4", layout: "vertical", labelCol: { span: 6 }, wrapperCol: { span: 24 }, form: formInstance, initialValues: generativeConfiguration, children: isGeneratedId && (_jsx(GeneratedIdConfigurationSection, { recordsCount: allRecordsCount, setOkDisabled: setOkDisabled })) })] }) }));
};
export default GeneratedIdSchemaConfigurationSection;
