var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined, } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useBlockLayout, usePagination, useSortBy, useTable } from 'react-table';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reportSelectedRecordsState } from 'src/state';
import { reportChartVisibleState, reportPageSizeState, reportSortBysState, reportSourceTypeSelector, } from 'src/state/selectors/withReportState';
var Option = Select.Option;
var ReportTable = function (_a) {
    var reportId = _a.reportId, columns = _a.columns, data = _a.data;
    var _b = useRecoilState(reportPageSizeState(reportId)), initialPageSize = _b[0], setInitialPageSize = _b[1];
    var _c = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0,
            pageSize: initialPageSize,
        },
    }, useBlockLayout, useSortBy, usePagination), getTableProps = _c.getTableProps, getTableBodyProps = _c.getTableBodyProps, headerGroups = _c.headerGroups, prepareRow = _c.prepareRow, page = _c.page, canPreviousPage = _c.canPreviousPage, canNextPage = _c.canNextPage, pageOptions = _c.pageOptions, pageCount = _c.pageCount, gotoPage = _c.gotoPage, nextPage = _c.nextPage, previousPage = _c.previousPage, toggleSortBy = _c.toggleSortBy, setPageSize = _c.setPageSize, _d = _c.state, pageIndex = _d.pageIndex, pageSize = _d.pageSize;
    var showChart = useRecoilValue(reportChartVisibleState(reportId));
    var sourceType = useRecoilValue(reportSourceTypeSelector(reportId));
    var _e = useRecoilState(reportSelectedRecordsState(reportId)), selectedRecords = _e[0], setSelectedRecords = _e[1];
    var _f = useRecoilState(reportSortBysState(reportId)), sortBys = _f[0], setSortBys = _f[1];
    useEffect(function () {
        setInitialPageSize(pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);
    var onRowClick = useCallback(function (rid) {
        if (!showChart)
            return;
        if (!selectedRecords.includes(rid)) {
            setSelectedRecords(function (prev) { return __spreadArray(__spreadArray([], prev, true), [rid], false); });
        }
        else {
            setSelectedRecords(function (prev) { return __spreadArray([], prev.filter(function (selectedRid) { return selectedRid !== rid; }), true); });
        }
    }, [selectedRecords, setSelectedRecords, showChart]);
    var onToggleSortyBy = useCallback(function (columnIndex, isSorted, isSortedDesc) {
        var isDescending = isSorted ? (isSortedDesc ? undefined : true) : false; // Sort Order: false -> true -> undefined
        var newSortBy = {
            columnIndex: columnIndex,
            isDescending: isDescending,
        };
        setSortBys(function (prevSortBys) {
            var tempSortBys = cloneDeep(prevSortBys);
            var sortByIndex = tempSortBys.findIndex(function (sortBy) { return sortBy.columnIndex === columnIndex; });
            if (sortByIndex === -1 && isDescending === false) {
                // Add new ascending sort
                return __spreadArray(__spreadArray([], tempSortBys, true), [newSortBy], false);
            }
            else if (sortByIndex !== -1 && isDescending) {
                // Change to descending sort
                tempSortBys[sortByIndex] = newSortBy;
            }
            else if (sortByIndex !== -1 && isDescending === undefined) {
                // Remove sort
                tempSortBys.splice(sortByIndex, 1);
            }
            return tempSortBys;
        });
    }, [setSortBys]);
    return (_jsx("div", { className: "ReportTable", children: _jsxs("table", __assign({}, getTableProps(), { children: [_jsx("thead", { children: headerGroups.map(function (headerGroup) { return (
                    // eslint-disable-next-line react/jsx-key
                    _jsx("tr", __assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map(function (column, columnIdx) {
                            if (!column.columns) {
                                var sortBy = sortBys.find(function (sortBy) { return sortBy.columnIndex === columnIdx; });
                                if (sortBy) {
                                    if ((sortBy.isDescending && !column.isSortedDesc) ||
                                        (!sortBy.isDescending &&
                                            column.isSortedDesc === undefined)) {
                                        //! Sort done here
                                        toggleSortBy(column.id, sortBy.isDescending, true);
                                    }
                                }
                                else if (!sortBy && column.isSorted) {
                                    // Remove sort
                                    toggleSortBy(column.id, undefined, true);
                                }
                            }
                            return (
                            // eslint-disable-next-line react/jsx-key
                            _jsxs("th", __assign({}, column.getHeaderProps(!column.columns
                                ? column.getSortByToggleProps({
                                    onClick: function () {
                                        return onToggleSortyBy(columnIdx, column.isSorted, column.isSortedDesc);
                                    },
                                })
                                : undefined), { children: [!column.columns && !!columnIdx && (_jsx("span", { className: "pl-1 pt-2", children: column.isSorted
                                            ? column.isSortedDesc
                                                ? '🔽'
                                                : '🔼'
                                            : '' })), column.render('Header'), !column.columns && !columnIdx && (_jsx("span", { className: "pr-1 pt-2", children: column.isSorted
                                            ? column.isSortedDesc
                                                ? '🔽'
                                                : '🔼'
                                            : '' }))] })));
                        }) }))); }) }), _jsx("tbody", __assign({}, getTableBodyProps(), { children: page.map(function (row) {
                        prepareRow(row);
                        return (
                        /* eslint-disable react/jsx-key */
                        _jsx("tr", __assign({}, row.getRowProps(), { className: showChart ? 'cursor-pointer' : undefined, style: showChart &&
                                selectedRecords.includes(row.values[sourceType].rid)
                                ? {
                                    background: '#F4F4F7',
                                    boxShadow: 'inset 0px 0px 8px #B9D8F7',
                                }
                                : undefined, onClick: function () { return onRowClick(row.values[sourceType].rid); }, children: row.cells.map(function (cell) {
                                /* eslint-disable react/jsx-key */
                                return _jsx("td", __assign({}, cell.getCellProps(), { children: cell.render('Cell') }));
                            }) })));
                    }) })), _jsx("tfoot", { className: "tfoot", children: _jsx("tr", { children: _jsxs("div", { className: "flex h-10 items-center", children: [_jsx(Button, { icon: _jsx(DoubleLeftOutlined, {}), onClick: function () { return gotoPage(0); }, disabled: !canPreviousPage }), _jsx(Button, { icon: _jsx(LeftOutlined, {}), onClick: previousPage, disabled: !canPreviousPage }), _jsx(Button, { icon: _jsx(RightOutlined, {}), onClick: nextPage, disabled: !canNextPage }), _jsx(Button, { icon: _jsx(DoubleRightOutlined, {}), onClick: function () { return gotoPage(pageCount - 1); }, disabled: !canNextPage }), _jsxs("span", { children: ["Page", ' ', _jsxs("strong", { children: [pageIndex + 1, " of ", pageOptions.length] })] }), _jsx(Select, { value: pageSize, onChange: setPageSize, children: [10, 20, 30, 40, 50].map(function (pageSize) { return (_jsxs(Option, { value: pageSize, children: ["Show ", pageSize] }, pageSize)); }) }), !!selectedRecords.length && (_jsx(Button, { onClick: function () { return setSelectedRecords([]); }, children: _jsx("span", { className: "underline", children: "Clear Selected" }) })), !!sortBys.length && (_jsx(Button, { style: selectedRecords.length ? { paddingLeft: '0px' } : undefined, onClick: function () { return setSortBys([]); }, children: _jsx("span", { className: "underline", children: "Clear Sort" }) }))] }) }) })] })) }));
};
export default React.memo(ReportTable);
