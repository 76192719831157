var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var OpenShiftsMetricSvg = function () { return (_jsxs("svg", { width: "31", height: "31", viewBox: "0 0 31 31", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.5", y: "0.5", width: "30", height: "30", rx: "15", fill: "#8476D6" }), _jsx("path", { d: "M19.55 10.0996H11.45C9.95883 10.0996 8.75 11.3084 8.75 12.7996V19.5496C8.75 21.0408 9.95883 22.2496 11.45 22.2496H19.55C21.0412 22.2496 22.25 21.0408 22.25 19.5496V12.7996C22.25 11.3084 21.0412 10.0996 19.55 10.0996Z", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.8 8.75V11.45M18.2 8.75V11.45M8.75 14.15H22.25", stroke: "white", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var OpenShiftsMetricIcon = function (props) { return (_jsx(Icon, __assign({ component: OpenShiftsMetricSvg }, props))); };
