import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useRecoilState } from 'recoil';
import { useScheduleTemplateApi } from 'src/hooks/api';
import ZiraRowsSkeleton from 'src/ui/ZiraRowsSkeleton';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { selectedTemplateIdForDeleteState } from './Atom';
var TemplateCardDeleteActionModal = function (props) {
    var onDeleteTemplate = props.onDeleteTemplate;
    var useCheckTemplateRelations = useScheduleTemplateApi().useCheckTemplateRelations;
    var _a = useRecoilState(selectedTemplateIdForDeleteState), selectedTemplateId = _a[0], setSelectedTemplate = _a[1];
    var _b = useCheckTemplateRelations(selectedTemplateId !== null && selectedTemplateId !== void 0 ? selectedTemplateId : ''), data = _b.data, isFetching = _b.isFetching;
    var canDeleteTemplate = useMemo(function () {
        var _a, _b;
        return (!data ||
            (!data.needs && !data.coverage) ||
            (((_a = data.coverage) === null || _a === void 0 ? void 0 : _a.length) === 0 && ((_b = data.needs) === null || _b === void 0 ? void 0 : _b.length) === 0));
    }, [data]);
    var modalContent = useMemo(function () {
        if (canDeleteTemplate) {
            return (_jsxs("div", { className: "flex items-center font-semibold", children: [_jsx(ExclamationCircleFilled, { style: { color: 'red' } }), _jsx("div", { className: "ml-2 text-sm", children: "Are you sure you want to delete this shift template?" })] }));
        }
        else {
            return (_jsxs("div", { children: [_jsx("div", { className: "flex items-center rounded-sm bg-red-50 px-2 py-1 text-sm font-semibold text-red-500", children: "Please remove this shift template from the associated needs or coverage listed below if you want to delete this template." }), (data === null || data === void 0 ? void 0 : data.needs) && data.needs.length !== 0 && (_jsxs("div", { className: "mt-3", children: [_jsx("div", { className: "font-semibold", children: "Linked Needs:" }), data.needs.map(function (need) { return (_jsx("div", { children: need.name }, need.id)); })] })), (data === null || data === void 0 ? void 0 : data.coverage) && data.coverage.length !== 0 && (_jsxs("div", { className: "mt-3", children: [_jsx("div", { className: "font-semibold", children: "Linked Coverage:" }), data.coverage.map(function (coverage) { return (_jsx("div", { children: coverage.name }, coverage.id)); })] }))] }));
        }
    }, [canDeleteTemplate, data]);
    var modalFooter = useMemo(function () {
        if (isFetching) {
            return null;
        }
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: function () { return setSelectedTemplate(undefined); }, children: canDeleteTemplate ? 'Cancel' : 'Close' }), selectedTemplateId && canDeleteTemplate && (_jsx(Button, { className: "ZiraModalDeleteBtn", onClick: function () {
                        onDeleteTemplate(selectedTemplateId);
                        setSelectedTemplate(undefined);
                    }, children: "Delete" }))] }));
    }, [canDeleteTemplate, isFetching, onDeleteTemplate, selectedTemplateId, setSelectedTemplate]);
    return (_jsx(Modal, { open: true, centered: true, footer: modalFooter, closable: false, width: 500, children: isFetching ? _jsx(ZiraRowsSkeleton, { rowNumber: 3 }) : modalContent }));
};
export default React.memo(TemplateCardDeleteActionModal);
