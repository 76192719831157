var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'antd';
import { setRipplingAuthCode } from 'src/utils/Account';
import { OrganizationContext } from 'src/App';
var LoginButton = function (props) {
    var loginWithRedirect = useAuth0().loginWithRedirect;
    var buttonClassName = props.className, text = props.text, ripplingAuthCode = props.ripplingAuthCode;
    var displayText = useMemo(function () { return text || 'Login'; }, [text]);
    useEffect(function () {
        if (ripplingAuthCode) {
            setRipplingAuthCode(ripplingAuthCode);
        }
    }, [ripplingAuthCode]);
    var org = useContext(OrganizationContext);
    return (_jsx(Button, { className: buttonClassName, type: "primary", onClick: function () {
            ripplingAuthCode
                ? loginWithRedirect({
                    authorizationParams: {
                        redirect_uri: process.env.REACT_APP_CLIENT_BASE_URL + 'accounts/select',
                    },
                })
                : loginWithRedirect({
                    authorizationParams: __assign({}, (org && { organization: org })),
                });
        }, children: displayText }));
};
export default React.memo(LoginButton);
