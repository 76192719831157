var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EditOutlined, CopyOutlined, DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined, } from '@ant-design/icons';
import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { usePageApi } from 'src/hooks/api';
import { useWindowSize } from 'src/hooks/component';
import { tableCollectionResetPageState, tableCollectionViewsState, tableFiltersSelector, tableRecordCountsState, tableSelectedViewIdState, canUpdateFilterOnPageSelector, isCurrentPageCanvasTypeSelector, } from 'src/state';
import { disableScroll } from 'src/utils/Collection';
import { useInView } from 'react-intersection-observer';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { usePrevious } from 'src/hooks/component/usePrevious';
import CollectionViewDropdown from './CollectionViewDropdown';
import CollectionViewTabs from './CollectionViewTabs';
import './CollectionViewOptions.scss';
var BlockViewOptions = function (_a) {
    var blockId = _a.blockId;
    var _b = useRecoilState(tableCollectionViewsState(blockId)), blockViews = _b[0], setBlockViews = _b[1];
    var _c = useRecoilState(tableSelectedViewIdState(blockId)), selectedViewId = _c[0], setSelectedViewId = _c[1];
    var _d = useRecoilState(tableRecordCountsState(blockId)), recordCounts = _d[0], setRecordCounts = _d[1];
    var blockFilters = useRecoilValue(tableFiltersSelector(blockId));
    var prevBlockFilters = usePrevious(blockFilters);
    var _e = useState(), viewIdInRename = _e[0], setViewIdInRename = _e[1];
    var _f = useState(''), newViewName = _f[0], setNewViewName = _f[1];
    var _g = useState(''), viewIdMenuOpen = _g[0], setViewIdMenuOpen = _g[1];
    var _h = useState(false), deleteConfirmOpen = _h[0], setDeleteConfirmOpen = _h[1];
    var setResetPage = useSetRecoilState(tableCollectionResetPageState(blockId));
    var displaySettingsMap = blockViews.displaySettings, orderedViews = blockViews.orderedViews;
    var _j = usePageApi(), useGetBlockViewsRecordCount = _j.useGetBlockViewsRecordCount, useAddBlockView = _j.useAddBlockView, useUpdateBlockView = _j.useUpdateBlockView, useDeleteBlockView = _j.useDeleteBlockView;
    var _k = useInView({
        root: document.querySelector('.CenterPanelContent'),
        triggerOnce: true,
    }), inView = _k.inView, ref = _k.ref;
    var _l = useGetBlockViewsRecordCount(blockId, {
        body: {
            viewIds: !recordCounts
                ? blockViews.orderedViews.map(function (view) { return view.viewId; })
                : [selectedViewId],
            overrideFilters: recordCounts ? blockFilters : undefined,
        },
        options: { enabled: !!blockViews.orderedViews.length && inView },
    }), recordCountData = _l.data, refetchRecordCounts = _l.refetch;
    // Update record counts
    useEffect(function () {
        var applyRecordCountToViewsWithSameFilters = function (recordCount) {
            setRecordCounts(function (prev) {
                var _a;
                var temp = cloneDeep(prev);
                for (var _i = 0, _b = blockViews.orderedViews; _i < _b.length; _i++) {
                    var viewId = _b[_i].viewId;
                    var viewFilters = blockViews.displaySettings[viewId].filters;
                    if (isEqual(viewFilters, blockFilters) ||
                        // Also apply record count if filtered view has higher count than unfiltered view (due to being stale)
                        (!viewFilters.length && recordCount > temp[viewId])) {
                        temp = __assign(__assign({}, temp), (_a = {}, _a[viewId] = recordCount, _a));
                    }
                }
                return temp;
            });
        };
        if (recordCountData) {
            if (recordCounts && Object.values(recordCountData.counts).length === 1) {
                applyRecordCountToViewsWithSameFilters(Object.values(recordCountData.counts)[0]);
            }
            else {
                setRecordCounts(function (prev) {
                    return __assign(__assign({}, prev), recordCountData.counts);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordCountData]);
    // Refetch on filters change
    useEffect(function () {
        if (recordCounts && !isEqual(blockFilters, prevBlockFilters)) {
            refetchRecordCounts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockFilters]);
    // Close delete popconfirm on menu close
    useEffect(function () {
        if (!viewIdMenuOpen) {
            setDeleteConfirmOpen(false);
        }
    }, [viewIdMenuOpen]);
    var onViewChange = useCallback(function (viewId) {
        setSelectedViewId(viewId);
        setResetPage(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var onAddView = useCallback(function (duplicateViewId) {
        var _a;
        var initialName;
        var initialDisplaySettings;
        if (duplicateViewId) {
            var view = orderedViews.find(function (view) { return view.viewId === duplicateViewId; });
            if (!view)
                return;
            var viewId = view.viewId, name_1 = view.name;
            initialDisplaySettings = displaySettingsMap[viewId];
            var duplicateOfDuplicateIndex = (_a = name_1.match(/\(\d+\)$/)) === null || _a === void 0 ? void 0 : _a.index; // Check if name being duplicated ends in "(digit)"
            // Append " (1)"  to duplicated view name or increment the number
            initialName =
                duplicateOfDuplicateIndex && duplicateOfDuplicateIndex >= 0
                    ? "".concat(name_1.substring(0, duplicateOfDuplicateIndex), " (").concat((parseInt(name_1.substring(duplicateOfDuplicateIndex).slice(1, -1)) + 1).toString(), ")")
                    : "".concat(name_1, " (1)");
        }
        else {
            // Duplicate display settings of current view
            var view = orderedViews.find(function (view) { return view.viewId === selectedViewId; });
            if (!view)
                return;
            initialDisplaySettings = displaySettingsMap[view.viewId];
        }
        useAddBlockView.mutate({ blockId: blockId, name: initialName, displaySettings: initialDisplaySettings }, {
            onSuccess: function (newBlockView) {
                if (newBlockView) {
                    var viewId_1 = newBlockView.viewId, displaySettings_1 = newBlockView.displaySettings, name_2 = newBlockView.name;
                    setBlockViews(function (prev) {
                        var _a;
                        return {
                            displaySettings: __assign(__assign({}, prev.displaySettings), (_a = {}, _a[viewId_1] = displaySettings_1, _a)),
                            orderedViews: __spreadArray(__spreadArray([], prev.orderedViews, true), [{ viewId: viewId_1, name: name_2 }], false),
                        };
                    });
                    onViewChange(viewId_1);
                    refetchRecordCounts();
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockId, displaySettingsMap, orderedViews, selectedViewId]);
    var removeBlockView = useCallback(function (viewId) {
        setSelectedViewId(viewId !== orderedViews[0].viewId ? orderedViews[0].viewId : orderedViews[1].viewId);
        setBlockViews(function (prev) {
            var temp = cloneDeep(prev);
            delete temp.displaySettings[viewId];
            temp.orderedViews = temp.orderedViews.filter(function (view) { return view.viewId !== viewId; });
            return temp;
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedViews]);
    var onUpdateViewName = useCallback(function (viewId) {
        if (!newViewName) {
            setViewIdInRename(undefined);
            setNewViewName('');
            return;
        }
        setBlockViews(function (prev) {
            var temp = cloneDeep(prev);
            var index = temp.orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            if (index === -1)
                return temp;
            temp.orderedViews[index].name = newViewName;
            return temp;
        });
        useUpdateBlockView.mutate({ blockId: blockId, viewId: viewId, name: newViewName }, {
            onError: function () {
                removeBlockView(viewId);
            },
        });
        setViewIdInRename(undefined);
        setNewViewName('');
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockId, newViewName, removeBlockView]);
    var onUpdateViewOrder = useCallback(function (viewId, newIndex) {
        if (newIndex < 0)
            return;
        setBlockViews(function (prev) {
            var temp = cloneDeep(prev);
            var oldIndex = temp.orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            if (oldIndex === -1)
                return temp;
            var view = temp.orderedViews.splice(oldIndex, 1)[0];
            temp.orderedViews.splice(newIndex, 0, view);
            return temp;
        });
        useUpdateBlockView.mutate({ blockId: blockId, viewId: viewId, orderIndex: newIndex }, {
            onError: function () {
                removeBlockView(viewId);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blockId, removeBlockView]);
    var onDeleteView = useCallback(function (viewId) {
        setViewIdMenuOpen('');
        setDeleteConfirmOpen(false);
        if (orderedViews.length === 1)
            return;
        useDeleteBlockView.mutate(viewId, {
            onSuccess: function () {
                removeBlockView(viewId);
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedViews.length, removeBlockView]);
    var handleViewMenuClick = useCallback(function (info, viewId) {
        var _a, _b;
        info.domEvent.stopPropagation();
        disableScroll(false);
        var key = info.key;
        if (key !== 'delete') {
            setViewIdMenuOpen('');
        }
        if (key === 'rename') {
            setViewIdInRename(viewId);
            setNewViewName((_b = (_a = orderedViews.find(function (view) { return view.viewId === viewId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '');
            return;
        }
        if (key === 'duplicate') {
            onAddView(viewId);
            return;
        }
        if (key === 'left') {
            var currentIndex = orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            onUpdateViewOrder(viewId, currentIndex - 1);
            return;
        }
        if (key === 'right') {
            var currentIndex = orderedViews.findIndex(function (view) { return view.viewId === viewId; });
            onUpdateViewOrder(viewId, currentIndex + 1);
            return;
        }
        if (key === 'delete') {
            setDeleteConfirmOpen(true);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedViews]);
    var _m = useWindowSize().windowWidth, windowWidth = _m === void 0 ? 0 : _m;
    var isLargeScreen = windowWidth >= 992;
    var _o = useParams().pageId, pageId = _o === void 0 ? '' : _o;
    var canEditViews = useRecoilValue(canUpdateFilterOnPageSelector(pageId));
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var childProps = {
        id: blockId,
        selectedViewId: selectedViewId,
        orderedViews: orderedViews,
        recordCounts: recordCounts,
        viewIdInRename: viewIdInRename,
        canEditViews: canEditViews,
        showRecordCount: true,
        setNewViewName: setNewViewName,
        onViewChange: onViewChange,
        onAddView: onAddView,
        onUpdateViewName: onUpdateViewName,
        onUpdateViewOrder: onUpdateViewOrder,
        viewIdMenuOpen: viewIdMenuOpen,
        setViewIdMenuOpen: setViewIdMenuOpen,
        viewMenuProps: function (viewId) {
            var _a, _b;
            return {
                items: __spreadArray(__spreadArray([
                    {
                        label: 'Rename',
                        key: 'rename',
                        icon: _jsx(EditOutlined, {}),
                    },
                    {
                        label: 'Duplicate',
                        key: 'duplicate',
                        icon: _jsx(CopyOutlined, {}),
                    }
                ], (isLargeScreen
                    ? [
                        ((_a = orderedViews[0]) === null || _a === void 0 ? void 0 : _a.viewId) !== viewId
                            ? {
                                label: 'Move Left',
                                key: 'left',
                                icon: _jsx(ArrowLeftOutlined, {}),
                            }
                            : null,
                        ((_b = orderedViews[orderedViews.length - 1]) === null || _b === void 0 ? void 0 : _b.viewId) !== viewId
                            ? {
                                label: 'Move Right',
                                key: 'right',
                                icon: _jsx(ArrowRightOutlined, {}),
                            }
                            : null,
                    ]
                    : []), true), [
                    orderedViews.length > 1
                        ? {
                            label: 'Delete',
                            key: 'delete',
                            danger: true,
                            icon: (_jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this view?", placement: "leftBottom", open: deleteConfirmOpen, onCancel: function () {
                                    setDeleteConfirmOpen(false);
                                    setViewIdMenuOpen('');
                                }, onConfirm: function () { return onDeleteView(viewId); }, children: _jsx(DeleteOutlined, {}) })),
                        }
                        : null,
                ], false),
                onClick: function (info) { return handleViewMenuClick(info, viewId); },
            };
        },
    };
    return (_jsx("div", { className: "CollectionViewOptions", ref: ref, children: isLargeScreen ? (_jsx(CollectionViewTabs, __assign({}, childProps, { isCanvasPageType: isCanvasPageType }))) : (_jsx(CollectionViewDropdown, __assign({}, childProps))) }));
};
export default React.memo(BlockViewOptions);
