var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableSchemasState } from 'src/state';
import { DeleteOutlined, DownCircleOutlined } from '@ant-design/icons';
import { GenerativeConfigurationContext } from 'src/components/CollectionComponents/CreateSchemaModal/configuration/Context';
import GeneratedIdVariableSelector from './GeneratedIdVariableSelector';
var ContextFieldSelectionWrapper = function (_a) {
    var collectionId = _a.collectionId;
    var context = useContext(GenerativeConfigurationContext);
    if (!context)
        throw new Error('context not provided!');
    var value = context.value, setValue = context.setValue;
    var collectionSchemas = useRecoilValue(collectionTableSchemasState(collectionId));
    var selectedSchemas = useMemo(function () { return collectionSchemas.filter(function (schema) { return value.contextSchemaIds.includes(schema.id); }); }, [collectionSchemas, value]);
    var onAddField = function (schemaId) {
        var contextSchemaIds = __spreadArray(__spreadArray([], value.contextSchemaIds, true), [schemaId], false);
        setValue(__assign(__assign({}, value), { contextSchemaIds: contextSchemaIds }));
    };
    var onDeleteField = function (schemaId) {
        var contextSchemaIds = value.contextSchemaIds.filter(function (id) { return id !== schemaId; });
        setValue(__assign(__assign({}, value), { contextSchemaIds: contextSchemaIds }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "font-medium text-gray-400 text-xs mb-2", children: "Context Fields" }), selectedSchemas.map(function (schema) { return (_jsxs("div", { className: "flex items-center px-[10px] py-[6px] border border-gray-100 rounded-lg mb-2 text-sm", children: [_jsx(DownCircleOutlined, { className: "mr-[6px] h-[14px]" }), _jsx("span", { className: "text-gray-500", children: schema.name }), _jsx("button", { className: "ml-auto -mr-1", onClick: function () { return onDeleteField(schema.id); }, children: _jsx("div", { className: "border border-gray-100 rounded-lg pt-[2px] pb-[4px] px-[6px] hover:bg-gray-50", children: _jsx(DeleteOutlined, {}) }) })] }, schema.id)); }), _jsx(GeneratedIdVariableSelector, { collectionSchemas: collectionSchemas, onSelect: onAddField, placeholder: "Add Field" })] }));
};
export default ContextFieldSelectionWrapper;
