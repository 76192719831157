import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { SchemaPermissionLevel, SchemaPermissionLevelOptions } from '../Constants';
import ObjectPermissionContent from './ObjectPermissionContent';
var SchemaPermissionContent = function (_a) {
    var onChange = _a.onChange, currentPermission = _a.currentPermission;
    var options = Object.values(SchemaPermissionLevel).map(function (key) { return ({
        id: key,
        title: SchemaPermissionLevelOptions(key),
    }); });
    return (_jsx(ObjectPermissionContent, { title: "Schema Functions", options: options, onChange: function (id) { return onChange(id); }, chosenPermission: currentPermission }));
};
export default React.memo(SchemaPermissionContent);
