var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AutomationIconRoundSvg = function () { return (_jsxs("svg", { width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("circle", { cx: "18", cy: "18", r: "18", fill: "url(#paint0_linear_7068_5332)" }), _jsx("path", { d: "M21.8674 20.2792V22.5113C22.0302 22.6161 22.18 22.7081 22.3254 22.7937L24.8868 24.3046L25.8333 22.7424L23.274 21.2443C22.7852 20.9519 22.3154 20.6295 21.8674 20.2792V20.2792Z", fill: "white" }), _jsx("path", { d: "M25.1907 17.9272C23.8289 17.1199 22.688 15.9966 21.8674 14.655V17.6233C22.5719 18.3455 23.3704 18.9723 24.2422 19.4873L26.8035 20.9982L27.75 19.4359L25.1907 17.9272Z", fill: "white" }), _jsx("path", { d: "M18.6123 26.2501H20.4574V16.3309C19.9605 17.4515 19.3418 18.5156 18.6123 19.5046V26.2501Z", fill: "white" }), _jsx("path", { d: "M14.7935 26.2499H16.6385V21.7344C16.0605 22.2797 15.4439 22.7839 14.7935 23.2431V26.2499Z", fill: "white" }), _jsx("path", { d: "M16.7388 16.4741C16.1938 17.4039 15.5379 18.266 14.7851 19.0422C13.9542 19.8949 13.0118 20.6347 11.9828 21.2421L9.42358 22.7402L10.3635 24.3024L12.9249 22.8044C15.2105 21.4479 17.1038 19.5346 18.4229 17.2485C19.7419 14.9623 20.4425 12.3801 20.4571 9.75H18.6121C18.599 12.1149 17.9527 14.4344 16.7388 16.4741Z", fill: "white" }), _jsx("path", { d: "M16.6385 9.75H14.7935C14.7799 11.3982 14.3375 13.0153 13.5086 14.4467C12.6796 15.878 11.492 17.0757 10.0592 17.925L7.5 19.4338L8.44642 20.996L11.0143 19.4873C12.719 18.4748 14.1314 17.0477 15.1162 15.3427C16.1009 13.6377 16.6251 11.712 16.6385 9.75V9.75Z", fill: "white" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_7068_5332", x1: "18", y1: "0", x2: "18", y2: "36", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#E75C90" }), _jsx("stop", { offset: "1", stopColor: "#426FFF" })] }) })] })); };
export var AutomationIconRound = function (props) { return (_jsx(Icon, __assign({ component: AutomationIconRoundSvg }, props))); };
