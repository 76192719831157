import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import Picker from '@emoji-mart/react';
import { CUSTOM_EMOJIS } from './constant';
var EmojiPicker = function (_a) {
    var onSaveEmoji = _a.onSaveEmoji;
    //!MARK: This is a hack to focus on the search bar of emoji picker (issues due to shadow dom)
    useEffect(function () {
        var intervalId = setInterval(function () {
            var _a, _b;
            var emojiPickerSearch = (_b = (_a = document
                .querySelector('em-emoji-picker')) === null || _a === void 0 ? void 0 : _a.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('input');
            if (emojiPickerSearch) {
                emojiPickerSearch.addEventListener('click', function (e) {
                    var searchElement = e.target;
                    searchElement === null || searchElement === void 0 ? void 0 : searchElement.focus();
                });
                clearInterval(intervalId);
            }
        }, 250);
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    return (_jsx(Picker, { theme: "light", emojiSize: 18, perLine: 7, previewPosition: "bottom", navPosition: "bottom", custom: CUSTOM_EMOJIS, noResultsEmoji: "psyduck", onEmojiSelect: function (val) {
            var _a;
            onSaveEmoji((_a = val.native) !== null && _a !== void 0 ? _a : val.shortcodes);
        } }));
};
export default React.memo(EmojiPicker);
