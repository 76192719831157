var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { upperCaseStringToStartCase } from 'src/utils/Tools/LodashTool';
import { IntegrationStepsState } from '../SyncStepsContainer';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import { CollectionType, ZIRA_TITLE } from '../../../../shared';
import { getIpDisplayText } from '../../../../utils/Account';
import { SyncType, SourceOfTruth } from '../Interface';
import IntegrationHeaderContent from './IntegrationHeaderContent';
var Option = Select.Option;
var SyncSettingStep = function () {
    var accountId = useRecoilValue(accountIdState);
    var _a = useSetting(), useLinkCollections = _a.useLinkCollections, useGetIntegrationSyncSettingOptions = _a.useGetIntegrationSyncSettingOptions;
    var _b = IntegrationStepsState.useContainer(), setIsLoading = _b.setIsLoading, selectedIpCollection = _b.selectedIpCollection, selectedZiraCollectionId = _b.selectedZiraCollectionId, goNextStep = _b.goNextStep, setSelectedZiraCollectionId = _b.setSelectedZiraCollectionId;
    var ipName = useParams().ipName;
    var _c = useState(SyncType.ONE_WAY), syncType = _c[0], setSyncType = _c[1];
    var _d = useState(SourceOfTruth.IP), sourceOfTruth = _d[0], setSourceOfTruth = _d[1];
    var syncSettingOptions = useGetIntegrationSyncSettingOptions(selectedIpCollection, {
        enabled: !!selectedIpCollection,
    }).data;
    var _e = syncSettingOptions !== null && syncSettingOptions !== void 0 ? syncSettingOptions : {}, supportedTypes = _e.supportedTypes, supportedSourceOfTruth = _e.supportedSourceOfTruth, supportedCollections = _e.supportedCollections;
    useEffect(function () {
        var _a, _b, _c, _d;
        if (syncSettingOptions) {
            var supportedTypes_1 = syncSettingOptions.supportedTypes, supportedSourceOfTruth_1 = syncSettingOptions.supportedSourceOfTruth, supportedCollections_1 = syncSettingOptions.supportedCollections;
            setSyncType(supportedTypes_1[0]);
            setSourceOfTruth(supportedSourceOfTruth_1[0]);
            setSelectedZiraCollectionId((_d = (_b = (_a = supportedCollections_1.find(function (collection) { return collection.type === CollectionType.USER; })) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : (_c = supportedCollections_1[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syncSettingOptions]);
    var handleGoNext = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var linkCollectionsRequest;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    linkCollectionsRequest = {
                        accountId: accountId,
                        ziraCollectionId: selectedZiraCollectionId === '' ? null : selectedZiraCollectionId,
                        ipCollectionName: selectedIpCollection,
                        syncType: syncType,
                        sourceOfTruth: sourceOfTruth,
                    };
                    setIsLoading(true);
                    return [4 /*yield*/, useLinkCollections.mutateAsync(linkCollectionsRequest, {
                            onSuccess: function (data) {
                                setSelectedZiraCollectionId(data.ziraCollectionId);
                                goNextStep();
                            },
                        })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        accountId,
        goNextStep,
        selectedIpCollection,
        selectedZiraCollectionId,
        setIsLoading,
        setSelectedZiraCollectionId,
        sourceOfTruth,
        syncType,
        useLinkCollections,
    ]);
    return (_jsxs("div", { className: "IntegrationWrapper", children: [_jsx(IntegrationHeaderContent, { children: _jsx("div", { children: _jsx(Popconfirm, { placement: "bottomLeft", title: "Are you sure to go to next step", okText: "Confirm", onConfirm: handleGoNext, children: _jsx(Button, { children: _jsx("span", { children: "Next" }) }) }) }) }), _jsx("div", { className: "StepTitle", children: "Sync Settings" }), _jsxs("div", { className: "StepDescription", children: ["With your ", getIpDisplayText(ipName), " account successfully linked, there are a few remaining settings to finalize before we can proceed with importing and reconciling data within the platform."] }), _jsx("div", { className: "IntegrationSubTitle", children: "Sync Type" }), _jsx(Select, { value: syncType, onChange: setSyncType, children: supportedTypes === null || supportedTypes === void 0 ? void 0 : supportedTypes.map(function (type) {
                    return (_jsx(Option, { value: type, children: upperCaseStringToStartCase(type.split('_').join(' ')) }, type));
                }) }), syncType === SyncType.ONE_WAY && (_jsxs(_Fragment, { children: [_jsx("div", { className: "IntegrationSubTitle", children: "One way" }), _jsxs(Select, { value: sourceOfTruth, onChange: setSourceOfTruth, children: [(supportedSourceOfTruth === null || supportedSourceOfTruth === void 0 ? void 0 : supportedSourceOfTruth.includes(SourceOfTruth.IP)) && (_jsxs(Option, { value: SourceOfTruth.IP, children: ["Import from ", getIpDisplayText(ipName)] })), (supportedSourceOfTruth === null || supportedSourceOfTruth === void 0 ? void 0 : supportedSourceOfTruth.includes(SourceOfTruth.ZIRA)) && (_jsxs(Option, { value: SourceOfTruth.ZIRA, children: ["Export to ", getIpDisplayText(ipName)] }))] })] })), syncType === SyncType.TWO_WAY && (_jsxs(_Fragment, { children: [_jsx("div", { className: "IntegrationSubTitle", children: "Two way: in case of a conflict, we should keep" }), _jsxs(Select, { value: sourceOfTruth, onChange: setSourceOfTruth, children: [(supportedSourceOfTruth === null || supportedSourceOfTruth === void 0 ? void 0 : supportedSourceOfTruth.includes(SourceOfTruth.IP)) && (_jsxs(Option, { value: SourceOfTruth.IP, children: [ipName, "'s version"] })), (supportedSourceOfTruth === null || supportedSourceOfTruth === void 0 ? void 0 : supportedSourceOfTruth.includes(SourceOfTruth.ZIRA)) && (_jsxs(Option, { value: SourceOfTruth.ZIRA, children: [ZIRA_TITLE, "'s version"] }))] })] })), _jsxs("div", { className: "IntegrationSubTitle", children: ["Select the ", ZIRA_TITLE, " Collection to Sync to"] }), _jsx(Select, { value: selectedZiraCollectionId, onSelect: setSelectedZiraCollectionId, children: supportedCollections === null || supportedCollections === void 0 ? void 0 : supportedCollections.map(function (option) {
                    return (_jsx(Option, { value: option.id, children: option.name }, option.id));
                }) })] }));
};
export default React.memo(SyncSettingStep);
