import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecipeApi } from 'src/hooks/api';
import RecipeModalHeader from '../RecipeModalHeader';
import RecipeModalImagePreview from '../RecipeModalImagePreview';
import { RECIPE_MODAL_FOOTER_HEIGHT } from '../constant';
import RecipeLoading from '../RecipeLoading';
import summaryImage from '../images/recipe1a.png';
import RecipeSummaryDataChanges from './RecipeSummaryDataChanges';
import RecipeSummaryPermissionChanges from './RecipeSummaryPermissionChanges';
import RecipeSummaryAutomations from './RecipeSummaryAutomations';
var RecipeSummaryView = function (_a) {
    var recipeId = _a.recipeId;
    var useGetRecipeSummary = useRecipeApi().useGetRecipeSummary;
    var _b = useGetRecipeSummary(recipeId), summary = _b.data, isLoading = _b.isLoading;
    if (!summary || isLoading) {
        return _jsx(RecipeLoading, {});
    }
    return (_jsxs("div", { className: "flex", style: { height: "calc(100% - ".concat(RECIPE_MODAL_FOOTER_HEIGHT, "px") }, children: [_jsxs("div", { className: "flex w-[70%] flex-col overflow-y-auto pt-[48px]", children: [_jsx(RecipeModalHeader, { title: summary.recipeTemplate.title, description: summary.recipeTemplate.description }), _jsx("div", { className: "pb-[16px] pt-[32px] text-lg font-semibold", children: "What is in this recipe?" }), _jsxs("div", { className: "space-y-[32px]", children: [_jsx(RecipeSummaryDataChanges, { dataChanges: summary.dataChanges.dataChanges }), _jsx(RecipeSummaryPermissionChanges, { permissionChanges: summary.permissionChanges }), _jsx(RecipeSummaryAutomations, { automationCards: summary.automations.automationCards })] })] }), _jsx(RecipeModalImagePreview, { image: summaryImage })] }));
};
export default React.memo(RecipeSummaryView);
