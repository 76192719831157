import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'antd';
import React from 'react';
import { SendEmailIcon } from 'src/utils/icons/SendEmailIcon';
import { StartChatIcon } from 'src/utils/icons/StartChatIcon';
var ExpertContactItem = function (_a) {
    var info = _a.info, setEmailContactModalInfo = _a.setEmailContactModalInfo;
    return (_jsxs("div", { className: "mb-5 flex w-full items-center justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "text-sm font-medium", children: info.name }), _jsx("div", { className: "text-xs", children: info.title })] }), info.email ? (_jsx(Button, { className: "ExpertContactBtn", icon: _jsx(SendEmailIcon, {}), onClick: setEmailContactModalInfo, children: "Contact" })) : (_jsx(Button, { className: "ExpertContactBtn", icon: _jsx(StartChatIcon, {}), id: "IntercomHelpBtn", children: "Start Chat" }))] }));
};
export default React.memo(ExpertContactItem);
