var _a;
export var AccountLanguageValueType;
(function (AccountLanguageValueType) {
    AccountLanguageValueType["ENGLISH"] = "ENGLISH";
    AccountLanguageValueType["SPANISH"] = "SPANISH";
})(AccountLanguageValueType || (AccountLanguageValueType = {}));
export var LanguageDisplayMap = (_a = {},
    _a[AccountLanguageValueType.ENGLISH] = 'English',
    _a[AccountLanguageValueType.SPANISH] = 'Spanish',
    _a);
