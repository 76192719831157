import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { DuplicateOutlinedIcon } from 'src/utils/icons/DropdownIcons/DuplicateOutlined';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { useScheduleNeedsCoverageComponent, useTimeZone } from 'src/hooks/component';
import { ifUserHasGeneralPermission, scheduleTimeZoneSelector } from 'src/state';
import moment from 'moment';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { TemplateStatusType, } from '../../interface';
import { NEED_COVERAGE_TEMPLATE_TABLE_FIRST_COLUMN_WIDTH } from '../../Constant';
import AutoApplySwitchCell from './TableCell/AutoApplySwitchCell';
import TemplateStatusTag from './TableCell/TemplateStatusTag';
import NeedCoverageTableBuilder from './NeedCoverageTableBuilder';
var NeedsCoverageTemplateGeneralSettingTable = function (_a) {
    var blockId = _a.blockId, onOpenExistingTemplateDetailModal = _a.onOpenExistingTemplateDetailModal, tableData = _a.tableData, refetchNeedTemplates = _a.refetchNeedTemplates, refetchCoverageTemplates = _a.refetchCoverageTemplates, isNeedTemplates = _a.isNeedTemplates, showArchived = _a.showArchived, sortKey = _a.sortKey, sortOrder = _a.sortOrder, searchTerm = _a.searchTerm;
    var timezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var timeFormatFn = useTimeZone(timezone).timeFormatFn;
    var _b = useNeedCoverageApi(), useUpdateNeedTemplate = _b.useUpdateNeedTemplate, useDeleteNeedTemplate = _b.useDeleteNeedTemplate, useDuplicateNeedTemplate = _b.useDuplicateNeedTemplate, useApplyNeedToSchedule = _b.useApplyNeedToSchedule, useUpdateCoverageTemplate = _b.useUpdateCoverageTemplate, useDeleteCoverageTemplate = _b.useDeleteCoverageTemplate, useDuplicateCoverageTemplate = _b.useDuplicateCoverageTemplate, useApplyCoverageToSchedule = _b.useApplyCoverageToSchedule;
    var _c = useScheduleNeedsCoverageComponent(), refetchNeedsTemplatesInBottomBar = _c.refetchNeedsTemplatesInBottomBar, refetchCoverageTemplatesInBottomBar = _c.refetchCoverageTemplatesInBottomBar;
    var needsCoverageEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE));
    var needsEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_EDIT_PERMISSION)) ||
        needsCoverageEditPermission;
    var coverageEditPermission = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.COVERAGE_EDIT_PERMISSION)) ||
        needsCoverageEditPermission;
    var onToggleTemplateAutoApply = useCallback(function (templateId, autoApplyStatus, removeShifts) {
        var updateInfo = {
            autoApply: autoApplyStatus,
            removeShifts: removeShifts,
        };
        var request = {
            templateId: templateId,
            info: updateInfo,
        };
        var today = moment().startOf('day').format('YYYY-MM-DD');
        if (isNeedTemplates) {
            useUpdateNeedTemplate.mutate(request, {
                onSuccess: function (resp) {
                    if (resp) {
                        refetchNeedTemplates();
                        if (autoApplyStatus) {
                            var autoApplyRequest = {
                                needId: resp.need.id,
                                startDate: today,
                                timeZone: timezone,
                            };
                            useApplyNeedToSchedule.mutate(autoApplyRequest, {
                                onSuccess: function (resp) {
                                    // Refetch need templates in bottom bar after auto apply need template
                                    if (resp) {
                                        refetchNeedsTemplatesInBottomBar();
                                    }
                                },
                            });
                        }
                        else {
                            refetchNeedsTemplatesInBottomBar();
                        }
                    }
                },
            });
        }
        else {
            useUpdateCoverageTemplate.mutate(request, {
                onSuccess: function (resp) {
                    if (resp) {
                        refetchCoverageTemplates();
                        if (autoApplyStatus) {
                            var autoApplyRequest = {
                                coverageId: resp.coverage.id,
                                startDate: today,
                                timeZone: timezone,
                            };
                            useApplyCoverageToSchedule.mutate(autoApplyRequest, {
                                onSuccess: function (resp) {
                                    // Refetch coverage templates in bottom bar after auto apply coverage template
                                    if (resp) {
                                        refetchCoverageTemplatesInBottomBar();
                                    }
                                },
                            });
                        }
                        else {
                            refetchCoverageTemplatesInBottomBar();
                        }
                    }
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNeedTemplates, timezone]);
    var onDuplicateTemplate = useCallback(function (templateId) {
        if (isNeedTemplates) {
            useDuplicateNeedTemplate.mutate({ templateId: templateId }, {
                onSuccess: function (resp) {
                    if (resp) {
                        refetchNeedTemplates();
                    }
                },
            });
        }
        else {
            useDuplicateCoverageTemplate.mutate({ templateId: templateId }, {
                onSuccess: function (resp) {
                    if (resp) {
                        refetchCoverageTemplates();
                    }
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNeedTemplates]);
    var onArchiveTemplate = useCallback(function (templateId) {
        if (isNeedTemplates) {
            useDeleteNeedTemplate.mutate({ templateId: templateId }, {
                onSuccess: function (resp) {
                    if (resp) {
                        refetchNeedTemplates();
                        refetchNeedsTemplatesInBottomBar();
                    }
                },
            });
        }
        else {
            useDeleteCoverageTemplate.mutate({ templateId: templateId }, {
                onSuccess: function (resp) {
                    if (resp) {
                        refetchCoverageTemplates();
                        refetchCoverageTemplatesInBottomBar();
                    }
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNeedTemplates]);
    var parseToRecordsData = useMemo(function () {
        var tempRecords = [];
        tableData.forEach(function (record) {
            var _a, _b, _c, _d;
            var autoApplyObj = {
                templateId: record.id,
                isAutoApplyOn: record.autoApply,
                isActive: record.status.status === TemplateStatusType.ACTIVE,
            };
            var statusObj = {
                templateId: record.id,
                isActive: record.status.status === TemplateStatusType.ACTIVE,
            };
            var lastUpdatedObj = {
                lastUpdatedTime: (_b = (_a = record.status.data) === null || _a === void 0 ? void 0 : _a.updatedAt) !== null && _b !== void 0 ? _b : '',
                lastUpdatedBy: (_d = (_c = record.status.data) === null || _c === void 0 ? void 0 : _c.actor.name) !== null && _d !== void 0 ? _d : '',
            };
            var templateActionsObj = {
                templateId: record.id,
                isActive: record.status.status === TemplateStatusType.ACTIVE,
                isAutoApplyOn: record.autoApply,
            };
            var templateRecordValue = {
                id: record.id,
                name: record.name,
                autoApply: autoApplyObj,
                status: statusObj,
                lastUpdated: lastUpdatedObj,
                templateActions: templateActionsObj,
            };
            //Only surface the template with ACTIVE or ARCHIVED status
            if (record.status.status !== TemplateStatusType.UPDATED) {
                tempRecords.push(templateRecordValue);
            }
        });
        return tempRecords;
    }, [tableData]);
    var parseToColumnData = useMemo(function () {
        var tempColumns = [];
        tempColumns.push({
            Header: _jsx("div", { className: "TableColumnTitle", children: "Name" }),
            accessor: 'name',
            width: NEED_COVERAGE_TEMPLATE_TABLE_FIRST_COLUMN_WIDTH,
            sticky: 'left',
            Cell: function (_a) {
                var value = _a.value;
                return _jsx("div", { className: "flex h-full items-center text-sm font-medium", children: value });
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "TableColumnTitle", children: "Auto-Apply" }),
            accessor: 'autoApply',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return (_jsx(AutoApplySwitchCell, { isAutoApplyOn: value.isAutoApplyOn, onToggleTemplateAutoApply: function (removeShifts) {
                        onToggleTemplateAutoApply(value.templateId, !value.isAutoApplyOn, removeShifts);
                    }, isActive: value.isActive }));
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "TableColumnTitle", children: "Status" }),
            accessor: 'status',
            width: 150,
            Cell: function (_a) {
                var value = _a.value;
                return _jsx(TemplateStatusTag, { isActive: value.isActive });
            },
        });
        tempColumns.push({
            Header: _jsx("div", { className: "TableColumnTitle", children: "Last Updated" }),
            accessor: 'lastUpdated',
            width: 250,
            Cell: function (_a) {
                var value = _a.value;
                var displayTime = value.lastUpdatedTime
                    ? timeFormatFn(value.lastUpdatedTime, 'MM/DD/YY', false)
                    : '';
                return (_jsxs("div", { className: "flex h-full space-x-1", children: [_jsx("div", { className: "flex items-center text-xs font-medium", children: displayTime }), _jsx("div", { className: "flex items-center text-xs font-medium", children: value.lastUpdatedBy ? "by ".concat(value.lastUpdatedBy) : "" })] }));
            },
        });
        tempColumns.push({
            Header: _jsx(_Fragment, {}),
            accessor: 'templateActions',
            width: 250,
            Cell: function (_a) {
                var value = _a.value;
                var extraText = value.isAutoApplyOn
                    ? 'This template has auto apply turned on, if you archive it we will remove the auto applied range. '
                    : '';
                var hasEditPermission = isNeedTemplates
                    ? needsEditPermission
                    : coverageEditPermission;
                return (_jsxs("div", { className: "flex space-x-1 py-3", children: [value.isActive && hasEditPermission ? (_jsx(ZiraDeletePopconfirm, { title: "".concat(extraText, "Are you sure you want to archive this template?"), placement: "leftBottom", onCancel: function (e) { return e === null || e === void 0 ? void 0 : e.stopPropagation(); }, onConfirm: function () { return onArchiveTemplate(value.templateId); }, okText: "Yes", overlayStyle: { width: 300 }, children: _jsx(ZiraTooltip, { title: value.isActive ? 'Archive Template' : 'Archived Already', children: _jsx("div", { className: "TemplateActionButton p-1", children: _jsx(DeleteOutlinedIcon, {}) }) }) })) : (
                        //Button placeholder for alignment
                        _jsx("div", { className: "w-[32px]" })), hasEditPermission ? (_jsx(ZiraTooltip, { title: "Duplicate Template", children: _jsx("div", { className: "TemplateActionButton p-1", onClick: function () { return onDuplicateTemplate(value.templateId); }, children: _jsx(DuplicateOutlinedIcon, {}) }) })) : (
                        //Button placeholder for alignment
                        _jsx("div", { className: "w-[32px]" })), _jsx(Button, { className: "TemplateEditButton w-20", onClick: function () {
                                return onOpenExistingTemplateDetailModal(value.templateId, !value.isActive || !hasEditPermission);
                            }, children: value.isActive && hasEditPermission ? 'Edit' : 'View' })] }));
            },
        });
        return tempColumns;
    }, [
        onToggleTemplateAutoApply,
        timeFormatFn,
        isNeedTemplates,
        needsEditPermission,
        coverageEditPermission,
        onArchiveTemplate,
        onDuplicateTemplate,
        onOpenExistingTemplateDetailModal,
    ]);
    return (_jsx(NeedCoverageTableBuilder, { columns: parseToColumnData, data: parseToRecordsData
            .filter(function (data) {
            return showArchived || data.status.isActive;
        })
            .sort(function (a, b) {
            var key = sortKey || 'lastUpdated';
            var order = sortOrder || 1;
            if (key === 'lastUpdated') {
                var timeA = a.lastUpdated.lastUpdatedTime
                    ? Date.parse(a.lastUpdated.lastUpdatedTime)
                    : Number.MIN_SAFE_INTEGER;
                var timeB = b.lastUpdated.lastUpdatedTime
                    ? Date.parse(b.lastUpdated.lastUpdatedTime)
                    : Number.MIN_SAFE_INTEGER;
                return (timeA - timeB) * order;
            }
            return a.name.localeCompare(b.name) * order;
        })
            .filter(function (templateInfo) {
            return searchTerm === '' || !searchTerm
                ? true
                : templateInfo.name.toLowerCase().includes(searchTerm.toLowerCase());
        }) }));
};
export default React.memo(NeedsCoverageTemplateGeneralSettingTable);
