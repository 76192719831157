import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Skeleton } from 'antd';
import { startCase } from 'lodash';
import { usePageApi } from 'src/hooks/api';
import RecordDetailMetricWrapper from './RecordDetailMetricWrapper';
var UserDetailMetricsRow = function (_a) {
    var userRecordId = _a.userRecordId;
    var useGetUserDetailMetrics = usePageApi().useGetUserDetailMetrics;
    var _b = useGetUserDetailMetrics({
        options: {
            enabled: !!userRecordId,
        },
    }, userRecordId), userDetailMetrics = _b.data, isFetching = _b.isFetching;
    if (isFetching || !userDetailMetrics) {
        return (_jsx("div", { className: "border-b-2 border-b-gray-50 pb-8 pt-7", children: _jsx(Skeleton, { title: false, paragraph: { rows: 1 }, active: true }) }));
    }
    return (_jsx("div", { className: "flex w-max flex-col space-y-5 overflow-hidden", children: Object.keys(userDetailMetrics).map(function (metricKey) {
            return (_jsx(RecordDetailMetricWrapper, { title: startCase(metricKey), content: userDetailMetrics[metricKey] }, metricKey));
        }) }));
};
export default React.memo(UserDetailMetricsRow);
