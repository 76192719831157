var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useAccountApi } from 'src/hooks/api';
import { IpName } from 'src/shared';
import { TaskTemplateType } from '../../interface';
import { TaskTypeTagInfoMap } from '../../constant';
import TaskListItemTag from './TaskListItemTag';
var TaskTemplateTypeTag = function (_a) {
    var type = _a.type;
    var useGetAccountDocSigningTemplatesListQuery = useAccountApi().useGetAccountDocSigningTemplatesListQuery;
    var signingTemplates = useGetAccountDocSigningTemplatesListQuery().data;
    var docusignTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.DOCUSIGN; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var signNowTemplates = useMemo(function () {
        var _a;
        return ((_a = signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates.filter(function (template) { return template.partner === IpName.SIGNNOW; })) !== null && _a !== void 0 ? _a : []);
    }, [signingTemplates === null || signingTemplates === void 0 ? void 0 : signingTemplates.templates]);
    var taskTypeOptions = useMemo(function () {
        var _a, _b;
        var isSigningPartnerType = type === TaskTemplateType.SIGNING_PARTNER;
        var isDocusignTask = docusignTemplates && docusignTemplates.length > 0 && isSigningPartnerType;
        var isSignNowTask = signNowTemplates && signNowTemplates.length > 0 && isSigningPartnerType;
        if (isDocusignTask) {
            return __assign(__assign({}, TaskTypeTagInfoMap), (_a = {}, _a[type] = __assign(__assign({}, TaskTypeTagInfoMap[type]), { name: 'Docusign' }), _a));
        }
        else if (isSignNowTask) {
            return __assign(__assign({}, TaskTypeTagInfoMap), (_b = {}, _b[type] = __assign(__assign({}, TaskTypeTagInfoMap[type]), { name: 'SignNow' }), _b));
        }
        else {
            return TaskTypeTagInfoMap;
        }
    }, [docusignTemplates, signNowTemplates, type]);
    var bgColor = taskTypeOptions[type].backgroundColor;
    var textColor = taskTypeOptions[type].textColor;
    return (_jsx(TaskListItemTag, { backgroundColor: bgColor, children: _jsx("div", { style: {
                color: textColor,
            }, children: taskTypeOptions[type].name }) }));
};
export default React.memo(TaskTemplateTypeTag);
