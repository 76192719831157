var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button, Input, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import './IntegrationSetup.scss';
import { Link, useParams } from 'react-router-dom';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { HelpDrawerLinkedArticleMap, HelpArticleType, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import { useRecoilValue } from 'recoil';
import { signNowCredentialInfoState } from 'src/state/atoms/integrationSettingState';
import SyncStepsContainer, { IntegrationStepsState } from '../SyncStepsContainer';
import { getIntegrationIcon, getIpDisplayText } from '../../../../utils/Account';
import { IpName } from '../../../../shared';
import { useSetting } from '../../../../hooks/api/useSettingApi';
import SettingsContentHeader from '../../SettingsContentHeader';
import IpUserMatchModal from '../IpUserMatchModal';
import { ADP_IP_COLLECION_NAME } from '../constant';
import ADPSetUpCard from './ADPSetUpCard';
import IntegrationClientConnectionModal from './IntegrationClientConnectionModal';
import IntegrationAPIConnectionModal from './IntegrationAPIConnectionModal';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var IntegrationSetup = function () {
    var _a, _b;
    var _c = useParams().ipName, ipName = _c === void 0 ? '' : _c;
    var _d = IntegrationStepsState.useContainer(), isLoading = _d.isLoading, setStepIndex = _d.setStepIndex, setSelectedIpCollection = _d.setSelectedIpCollection;
    var _e = useSetting(), useConnectToIp = _e.useConnectToIp, useConnectToSignNow = _e.useConnectToSignNow, usePeriodReSync = _e.usePeriodReSync, getGustoCompanyProvision = _e.getGustoCompanyProvision;
    var _f = useState(false), isResyncLoading = _f[0], setIsResyncLoading = _f[1];
    var _g = useState(''), connectUrl = _g[0], setConnectUrl = _g[1];
    var _h = useState(false), showIntegrationModal = _h[0], setShowIntegrationModal = _h[1];
    var _j = useState(false), isClientConnectionModalVisible = _j[0], setIsClientConnectionModalVisible = _j[1];
    var _k = useState(false), isAPIConnectionModalVisible = _k[0], setIsAPIConnectionModalVisible = _k[1];
    // Reset step index on modal close
    useEffect(function () {
        if (!showIntegrationModal) {
            setStepIndex(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showIntegrationModal]);
    var _l = useState(false), isConnected = _l[0], setIsConnected = _l[1];
    var signNowCredentialInfo = useRecoilValue(signNowCredentialInfoState);
    var isSignNow = ipName === IpName.SIGNNOW;
    var requiresIntegrationClientConnection = ipName === IpName.PAYCHEX ||
        ipName === IpName.CHECKRPAY ||
        ipName === IpName.PROLIANT_READYPAY;
    var requiresIntegrationAPIConnection = ipName === IpName.SmartRecruiters || ipName === IpName.TITAN;
    var hideIntegrationSync = ipName === IpName.DOCUSIGN ||
        ipName === IpName.SIGNNOW ||
        ipName === IpName.CHECKRPAY ||
        ipName === IpName.SmartRecruiters;
    var data = useConnectToIp({
        options: {
            enabled: !showIntegrationModal && !isSignNow,
        },
    }, ipName).data;
    var signNowConnectionData = useConnectToSignNow({
        body: {
            apiKey: (_a = signNowCredentialInfo === null || signNowCredentialInfo === void 0 ? void 0 : signNowCredentialInfo.apiKey) !== null && _a !== void 0 ? _a : '',
            clientId: (_b = signNowCredentialInfo === null || signNowCredentialInfo === void 0 ? void 0 : signNowCredentialInfo.clientId) !== null && _b !== void 0 ? _b : '',
        },
        options: {
            enabled: !showIntegrationModal && isSignNow && !!signNowCredentialInfo,
        },
    }, ipName).data;
    var mutation = usePeriodReSync();
    useEffect(function () {
        if (data && !isSignNow) {
            setConnectUrl(data.data);
            setIsConnected(data.isConnected);
        }
        else if (signNowConnectionData && isSignNow) {
            setConnectUrl(signNowConnectionData.data);
            setIsConnected(signNowConnectionData.isConnected);
        }
    }, [data, isSignNow, signNowConnectionData]);
    var onIntegrationModalClose = useCallback(function () {
        setShowIntegrationModal(false);
    }, []);
    var onResyncClick = useCallback(function () {
        setIsResyncLoading(true);
        mutation.mutate(ipName, {
            onSettled: function () {
                setIsResyncLoading(false);
            },
        });
    }, [ipName, mutation]);
    var _m = useState(false), gustoInfoModalVisible = _m[0], setGustoInfoModalVisible = _m[1];
    var _o = useState(''), email = _o[0], setEmail = _o[1];
    var _p = useState(''), phoneNumber = _p[0], setPhoneNumber = _p[1];
    var addGustoCompanyProvision = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getGustoCompanyProvision({ email: email, phone: phoneNumber })];
                case 1:
                    data = _b.sent();
                    if (data === null || data === void 0 ? void 0 : data.url) {
                        (_a = window.open(data.url)) === null || _a === void 0 ? void 0 : _a.focus();
                    }
                    setGustoInfoModalVisible(false);
                    return [2 /*return*/];
            }
        });
    }); }, [email, getGustoCompanyProvision, phoneNumber]);
    var _q = useState(false), adpUserMatchModalVisible = _q[0], setAdpUserMatchModalVisible = _q[1];
    return (_jsxs(_Fragment, { children: [_jsxs(Spin, { spinning: isResyncLoading, tip: "Loading...", size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: [_jsxs(SettingsContentHeader, { children: [_jsx(Breadcrumb.Item, { children: _jsx(Link, { to: "/settings/integrations", children: "Integrations" }) }), _jsx(Breadcrumb.Item, { children: getIpDisplayText(ipName) })] }), _jsx("div", { className: "IntegrationSetupWrapper", children: _jsxs("div", { className: "IntegrationSetupContent", children: [_jsx("div", { className: "IntegrationSetupLogo", children: getIntegrationIcon(ipName) }), _jsxs("div", { className: "my-[16px] flex items-center", children: [_jsxs("div", { className: "IntegrationSetupSubHeading", children: ["Connect with your ", getIpDisplayText(ipName), " account"] }), ipName === 'docusign' && (_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.DOCUSIGN_SETUP], children: _jsx(HelpIcon, { className: "ml-2 h-[16px] text-black" }) }))] }), _jsxs("div", { className: "IntegrationConnectBtnWrapper", children: [isConnected ? (_jsx(Button, { className: "IntegrationConnectedBtn", disabled: true, children: "Already connected" })) : (ipName !== IpName.ADP && (_jsx(Button, { className: "IntegrationConnectBtn", onClick: function () {
                                                if (requiresIntegrationClientConnection) {
                                                    setIsClientConnectionModalVisible(true);
                                                    return;
                                                }
                                                else if (requiresIntegrationAPIConnection) {
                                                    setIsAPIConnectionModalVisible(true);
                                                    return;
                                                }
                                                window.location.href = connectUrl;
                                            }, children: "Connect" }))), ipName === 'gusto' && (_jsxs(_Fragment, { children: [_jsx(Button, { className: "IntegrationAddAccountBtn", onClick: function () { return setGustoInfoModalVisible(true); }, children: "Add Account" }), _jsxs(Modal, { open: gustoInfoModalVisible, centered: true, onOk: addGustoCompanyProvision, onCancel: function () { return setGustoInfoModalVisible(false); }, children: [_jsxs("div", { className: "w-full", children: ["Email:", ' ', _jsx(Input, { onChange: function (e) { return setEmail(e.target.value); } })] }), _jsxs("div", { className: "w-full", children: ["Phone #:", ' ', _jsx(Input, { onChange: function (e) { return setPhoneNumber(e.target.value); } })] })] })] })), isConnected && !hideIntegrationSync && (_jsxs(_Fragment, { children: [_jsx(Button, { className: "StartIntegrationBtn", onClick: function () { return setShowIntegrationModal(true); }, children: "Start integration setting" }), _jsxs(Button, { className: "ResyncDataBtn mr-[10px]", onClick: onResyncClick, children: ["Resync ", getIpDisplayText(ipName), " Data"] }), ipName === IpName.ADP && (_jsx(Button, { className: "ResyncDataBtn", onClick: function () {
                                                        setSelectedIpCollection(ADP_IP_COLLECION_NAME);
                                                        setAdpUserMatchModalVisible(true);
                                                    }, children: "Reconcile Pending Users" }))] }))] }), _jsx(Modal, { open: showIntegrationModal, footer: null, closable: true, width: "80%", destroyOnClose: false, onCancel: function () { return setShowIntegrationModal(false); }, children: _jsx(Spin, { spinning: isLoading, tip: "Loading...", size: "large", indicator: LoadingIcon, style: { position: 'initial' }, children: _jsx(SyncStepsContainer, { onIntegrationModalClose: onIntegrationModalClose }) }) }), _jsx("div", { className: "HorizontalScrollingWrapper", children: ipName === IpName.ADP && !isConnected && _jsx(ADPSetUpCard, {}) }), _jsx("div", { className: "IntegrationSetupSubHeading", children: "Integration Details" }), _jsxs("div", { children: ["Import information directly from ", getIpDisplayText(ipName), " \u00A0\u2714\uFE0F"] }), _jsx("div", { children: "Sync of account information \u00A0\u2714\uFE0F" }), _jsx("div", { children: "24/7 support from your TeamBridge Pilot \u00A0 \u2714\uFE0F" })] }) })] }), adpUserMatchModalVisible && (_jsx(IpUserMatchModal, { isModalVisible: adpUserMatchModalVisible, setIsModalVisible: setAdpUserMatchModalVisible })), isClientConnectionModalVisible && (_jsx(IntegrationClientConnectionModal, { ipName: ipName, onCloseModal: function () { return setIsClientConnectionModalVisible(false); }, updateConnectionStatus: function () { return setIsConnected(true); } })), isAPIConnectionModalVisible && (_jsx(IntegrationAPIConnectionModal, { ipName: ipName, onCloseModal: function () { return setIsAPIConnectionModalVisible(false); }, updateConnectionStatus: function () { return setIsConnected(true); } }))] }));
};
export default React.memo(IntegrationSetup);
