var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { NameInputPenIcon } from 'src/utils/icons/NameInputPenIcon';
import { useScheduleTemplateApi } from 'src/hooks/api';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { useScheduleNeedsCoverageComponent } from 'src/hooks/component';
import { useSetRecoilState } from 'recoil';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DEFAULT_COVERAGE_TEMPLATE_DETAIL_TABLE_RECORD, DEFAULT_NEED_TEMPLATE_DETAIL_TABLE_RECORD, } from '../../Constant';
import { NeedsCoverageType, } from '../../interface';
import './NeedsCoverageTemplateDetailModal.scss';
import { currentNeedOrCoverageBeingAppliedState } from '../Atoms/Atoms';
import NeedsCoverageTemplateDetailSettingTable from './NeedsCoverageTemplateDetailSettingTable';
import LocationSelector from './LocationSelector';
import NeedsCoverageRefreshConfirmModal from './NeedsCoverageRefreshConfirmModal';
import NeedsCoverageAutoApplyConfirmModal from './NeedsCoverageAutoApplyConfirmModal';
var NeedsCoverageTemplateDetailModal = function (_a) {
    var blockId = _a.blockId, isModalVisible = _a.isModalVisible, defaultViewOnly = _a.viewOnly, onCloseModal = _a.onCloseModal, selectedTab = _a.selectedTab, templateId = _a.templateId, //needs or coverage template id
    defaultLocation = _a.defaultLocation, onCloseConfigModal = _a.onCloseConfigModal;
    var _b = useState(''), templateName = _b[0], setTemplateName = _b[1];
    var isCreatingNew = !templateId;
    var viewOnly = defaultViewOnly !== null && defaultViewOnly !== void 0 ? defaultViewOnly : false;
    var _c = useState('AllLocations'), selectedLocationOption = _c[0], setSelectedLocationOption = _c[1];
    var _d = useState([]), needsTableData = _d[0], setNeedsTableData = _d[1];
    var _e = useState([]), coverageTableData = _e[0], setCoverageTableData = _e[1];
    var useGetAllShiftTemplates = useScheduleTemplateApi().useGetAllShiftTemplates;
    // Using endpoint to get all shift templates instead of block state
    // Since here we don't need to apply block filter
    var shiftTemplatesResp = useGetAllShiftTemplates({
        body: { filters: [] },
        options: { enabled: true },
    }).data;
    var filteredShiftTemplatesByLocationOption = useMemo(function () {
        var list = [];
        if (shiftTemplatesResp === null || shiftTemplatesResp === void 0 ? void 0 : shiftTemplatesResp.data) {
            if (selectedLocationOption === 'AllLocations') {
                Object.values(shiftTemplatesResp.data).map(function (template) {
                    if (template)
                        list.push(template);
                });
            }
            else {
                Object.values(shiftTemplatesResp.data).filter(function (template) {
                    var _a;
                    if (template &&
                        (((_a = template.location) === null || _a === void 0 ? void 0 : _a.rid) === selectedLocationOption || !template.location))
                        list.push(template);
                });
            }
        }
        return list;
    }, [selectedLocationOption, shiftTemplatesResp]);
    var _f = useNeedCoverageApi(), useCreateNewNeedTemplate = _f.useCreateNewNeedTemplate, useGetExistingNeedTemplateDetail = _f.useGetExistingNeedTemplateDetail, useUpdateNeedTemplate = _f.useUpdateNeedTemplate, useCreateNewCoverageTemplate = _f.useCreateNewCoverageTemplate, useGetExistingCoverageTemplateDetail = _f.useGetExistingCoverageTemplateDetail, useUpdateCoverageTemplate = _f.useUpdateCoverageTemplate;
    var needTemplateDetails = useGetExistingNeedTemplateDetail({
        body: {
            templateId: templateId !== null && templateId !== void 0 ? templateId : '',
        },
        options: {
            enabled: !!templateId && selectedTab === NeedsCoverageType.NEED,
        },
    }).data;
    var coverageTemplateDetails = useGetExistingCoverageTemplateDetail({
        body: {
            templateId: templateId !== null && templateId !== void 0 ? templateId : '',
        },
        options: {
            enabled: !!templateId && selectedTab === NeedsCoverageType.COVERAGE,
        },
    }).data;
    // Init modal data
    useEffect(function () {
        var _a, _b;
        // New template
        if (isCreatingNew) {
            var defaultTemplateName = selectedTab === NeedsCoverageType.NEED ? 'Untitled Need' : 'Untitled Coverage';
            setTemplateName(defaultTemplateName);
            if (defaultLocation !== 'BlockFilter' && defaultLocation !== 'AllLocations') {
                setSelectedLocationOption(defaultLocation);
            }
            if (selectedTab === NeedsCoverageType.NEED) {
                setNeedsTableData([DEFAULT_NEED_TEMPLATE_DETAIL_TABLE_RECORD]);
            }
            else {
                setCoverageTableData([DEFAULT_COVERAGE_TEMPLATE_DETAIL_TABLE_RECORD]);
            }
        }
        // Existing template
        else {
            if (selectedTab === NeedsCoverageType.NEED && needTemplateDetails) {
                setTemplateName(needTemplateDetails.need.name);
                setNeedsTableData(needTemplateDetails.data);
                setSelectedLocationOption((_a = needTemplateDetails.need.locationRid) !== null && _a !== void 0 ? _a : 'AllLocations');
            }
            else {
                if (coverageTemplateDetails) {
                    setTemplateName(coverageTemplateDetails.coverage.name);
                    setSelectedLocationOption((_b = coverageTemplateDetails.coverage.locationRid) !== null && _b !== void 0 ? _b : 'AllLocations');
                    var tableData = coverageTemplateDetails.data;
                    setCoverageTableData(tableData);
                }
            }
        }
    }, [coverageTemplateDetails, defaultLocation, isCreatingNew, needTemplateDetails, selectedTab]);
    var _g = useState(false), isRefreshSettingVisible = _g[0], setIsRefreshSettingVisible = _g[1];
    var _h = useState(false), isAutoApplySettingVisible = _h[0], setIsAutoApplySettingVisible = _h[1];
    var createOrUpdateTemplate = useCallback(function () {
        if (isCreatingNew) {
            setIsAutoApplySettingVisible(true);
        }
        else {
            setIsRefreshSettingVisible(true);
        }
    }, [isCreatingNew]);
    var setCurrentNeedOrCoverageBeingApplied = useSetRecoilState(currentNeedOrCoverageBeingAppliedState);
    var saveAutoApplySettings = useCallback(function (autoApply) {
        onCloseModal();
        setIsAutoApplySettingVisible(false);
        var locationRid = selectedLocationOption === 'AllLocations' ? undefined : selectedLocationOption;
        var request = {
            name: templateName,
            locationRid: locationRid,
            autoApply: autoApply,
        };
        if (autoApply) {
            setCurrentNeedOrCoverageBeingApplied({
                item: {
                    name: templateName,
                    autoApply: autoApply,
                    id: '',
                    appliedRange: [],
                    status: {
                        status: 'ACTIVE',
                    },
                },
                type: selectedTab.toLowerCase(),
                range: {
                    startTime: moment().toISOString(),
                    endTime: moment().add(8, 'weeks').toISOString(),
                    id: '',
                    inTransaction: true,
                },
            });
        }
        if (selectedTab === NeedsCoverageType.NEED) {
            useCreateNewNeedTemplate.mutate(__assign(__assign({}, request), { data: needsTableData }), {
                onSuccess: function (resp) {
                    if (resp) {
                        // Only close config modal on autoApply because you want to see the blocking modal (which would otherwise render behind the config modal)
                        // while the auto-applied changes apply, but if you're going to manually apply, then you don't need to worry about the blocker modal at this stage
                        if (autoApply) {
                            onCloseConfigModal();
                        }
                    }
                },
                onError: function () {
                    toast('Something went wrong. Refresh the page and try again.', {
                        type: 'error',
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                    setCurrentNeedOrCoverageBeingApplied(null);
                },
            });
        }
        else {
            useCreateNewCoverageTemplate.mutate(__assign(__assign({}, request), { data: coverageTableData }), {
                onSuccess: function (resp) {
                    if (resp) {
                        // Only close config modal on autoApply because you want to see the blocking modal (which would otherwise render behind the config modal)
                        // while the auto-applied changes apply, but if you're going to manually apply, then you don't need to worry about the blocker modal at this stage
                        if (autoApply) {
                            onCloseConfigModal();
                        }
                    }
                },
                onError: function () {
                    toast('Something went wrong. Refresh the page and try again.', {
                        type: 'error',
                        position: toast.POSITION.BOTTOM_CENTER,
                    });
                    setCurrentNeedOrCoverageBeingApplied(null);
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coverageTableData, needsTableData, selectedLocationOption, selectedTab, templateName]);
    var refetchNeedsCoverageLogsStatus = useScheduleNeedsCoverageComponent().refetchNeedsCoverageLogsStatus;
    var saveRefreshSettings = useCallback(function (refreshFrom, publish) {
        setIsRefreshSettingVisible(false);
        if (!templateId)
            return;
        var locationRid = selectedLocationOption === 'AllLocations' ? undefined : selectedLocationOption;
        if (selectedTab === NeedsCoverageType.NEED) {
            var info = {
                name: templateName,
                locationRid: locationRid,
                data: needsTableData,
                refreshFrom: refreshFrom,
                publish: publish,
            };
            var request = {
                templateId: templateId,
                info: info,
            };
            useUpdateNeedTemplate.mutate(request, {
                onSuccess: function (resp) {
                    if (resp) {
                        onCloseModal();
                        if (refreshFrom) {
                            refetchNeedsCoverageLogsStatus();
                        }
                    }
                },
            });
        }
        else {
            var info = {
                name: templateName,
                locationRid: locationRid,
                data: coverageTableData,
                refreshFrom: refreshFrom,
                publish: publish,
            };
            var request = {
                templateId: templateId,
                info: info,
            };
            useUpdateCoverageTemplate.mutate(request, {
                onSuccess: function (resp) {
                    if (resp) {
                        onCloseModal();
                        if (refreshFrom) {
                            refetchNeedsCoverageLogsStatus();
                        }
                    }
                },
            });
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        coverageTableData,
        needsTableData,
        selectedLocationOption,
        selectedTab,
        templateId,
        templateName,
    ]);
    var modalFooter = useMemo(function () {
        return (_jsxs(_Fragment, { children: [_jsx(Button, { className: "ZiraModalCancelBtn", onClick: onCloseModal, children: "Cancel" }), !viewOnly && (_jsx(Button, { className: "ZiraModalSaveBtn", onClick: function () {
                        createOrUpdateTemplate();
                    }, children: "Save" }))] }));
    }, [createOrUpdateTemplate, onCloseModal, viewOnly]);
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, { className: "NeedsCoverageTemplateDetailModal", open: isModalVisible, closable: false, width: "93%", footer: modalFooter, zIndex: 1001, centered: true, bodyStyle: {
                    height: 'calc(100vh - 153px)',
                    overflowY: 'auto',
                }, children: [_jsx(Button, { className: "ModalCloseButton", onClick: onCloseModal, children: "\u2715" }), _jsxs("div", { style: { pointerEvents: viewOnly ? 'none' : 'auto' }, children: [_jsx(Input, { className: "TemplateNameInput", suffix: viewOnly ? undefined : _jsx(NameInputPenIcon, {}), bordered: false, autoFocus: !viewOnly, placeholder: "Enter Template Name Here", value: templateName, onChange: function (e) { return setTemplateName(e.target.value); } }), _jsx("hr", { className: "TemplateContentDivider" }), _jsxs("div", { className: "mt-[40px]", children: [_jsx("div", { className: "font-medium", children: "Location" }), _jsx("div", { className: "text-xs font-medium text-gray-600", children: "Choose a location to add ".concat(selectedTab === NeedsCoverageType.NEED ? 'need' : 'coverage') }), _jsx(LocationSelector, { blockId: blockId, selectedLocationOption: selectedLocationOption, updateSelectedLocationOption: setSelectedLocationOption, isDetailConfigModal: true, isTableDataEmpty: (selectedTab === NeedsCoverageType.NEED &&
                                            needsTableData.length === 0) ||
                                            (selectedTab === NeedsCoverageType.COVERAGE &&
                                                coverageTableData.length === 0), clearTableData: function () {
                                            if (selectedTab === NeedsCoverageType.NEED &&
                                                needsTableData.length !== 0) {
                                                setNeedsTableData([DEFAULT_NEED_TEMPLATE_DETAIL_TABLE_RECORD]);
                                            }
                                            else if (coverageTableData.length !== 0) {
                                                setCoverageTableData([
                                                    DEFAULT_COVERAGE_TEMPLATE_DETAIL_TABLE_RECORD,
                                                ]);
                                            }
                                        } })] })] }), _jsx(NeedsCoverageTemplateDetailSettingTable, { blockId: blockId, isNeedTemplate: selectedTab === NeedsCoverageType.NEED, tableData: selectedTab === NeedsCoverageType.NEED ? needsTableData : coverageTableData, setTableData: selectedTab === NeedsCoverageType.NEED
                            ? setNeedsTableData
                            : setCoverageTableData, shiftTemplateList: filteredShiftTemplatesByLocationOption })] }), _jsx(NeedsCoverageRefreshConfirmModal, { isOpen: isRefreshSettingVisible, setIsOpen: setIsRefreshSettingVisible, onClose: saveRefreshSettings, needOrCoverageId: templateId || '', type: selectedTab }), _jsx(NeedsCoverageAutoApplyConfirmModal, { isOpen: isAutoApplySettingVisible, setIsOpen: setIsAutoApplySettingVisible, onClose: saveAutoApplySettings })] }));
};
export default React.memo(NeedsCoverageTemplateDetailModal);
