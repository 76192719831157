import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import { NativePath } from 'src/shared';
import { getNativeFieldCellContent } from 'src/utils/Collection';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var ContactDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var contactTitle = useMemo(function () {
        var firstName = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.CONTACT_FIRST_NAME);
        var lastName = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.CONTACT_LAST_NAME);
        if (!firstName && !lastName) {
            return 'Contact Name';
        }
        return _jsx(_Fragment, { children: firstName + ' ' + lastName });
    }, [modalRecord, nativeSchemaMap]);
    return _jsx(RecordDetailModalTitle, { title: contactTitle });
};
export default React.memo(ContactDetailModalTitle);
