var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createContext } from 'react';
import { PayRuleAdjustmentField, PayRuleAdjustmentType, PayRuleConditionOperand, } from 'src/components/Settings/PayRate/interface';
import { v4 as uuidv4 } from 'uuid';
export var initialPayrule = {
    info: {
        collectionId: '',
        name: '',
        conditionOperand: PayRuleConditionOperand.AND,
        isActive: false,
    },
    conditions: [{ id: uuidv4() }],
    adjustments: [
        {
            id: uuidv4(),
            field: PayRuleAdjustmentField.PAY_RATE,
            type: PayRuleAdjustmentType.SET_TO,
            value: 0,
        },
    ],
};
var PaymentRulesConfigurationContext = createContext({
    paymentRule: initialPayrule,
    setConditions: function (payload) {
        // eslint-disable-next-line
        console.error("Not implemented, ".concat(payload, " not set"));
    },
    setAdjustments: function (payload) {
        // eslint-disable-next-line
        console.error("Not implemented, ".concat(payload, " not set"));
    },
    setPayRuleInfo: function (payload) {
        // eslint-disable-next-line
        console.error("Not implemented, ".concat(payload, " not set"));
    },
});
export var PayRulesActionType;
(function (PayRulesActionType) {
    PayRulesActionType[PayRulesActionType["INITIALIZE"] = 0] = "INITIALIZE";
    PayRulesActionType[PayRulesActionType["UPDATE_INFO"] = 1] = "UPDATE_INFO";
    PayRulesActionType[PayRulesActionType["UPDATE_CONDITIONS"] = 2] = "UPDATE_CONDITIONS";
    PayRulesActionType[PayRulesActionType["UPDATE_ADJUSTMENTS"] = 3] = "UPDATE_ADJUSTMENTS";
})(PayRulesActionType || (PayRulesActionType = {}));
export var PaymentRulesReducer = function (state, action) {
    switch (action.type) {
        case PayRulesActionType.INITIALIZE:
            return __assign({}, state);
        case PayRulesActionType.UPDATE_INFO:
            return __assign(__assign({}, state), { info: action.payload });
        case PayRulesActionType.UPDATE_CONDITIONS:
            return __assign(__assign({}, state), { conditions: action.payload ? action.payload : [] });
        case PayRulesActionType.UPDATE_ADJUSTMENTS:
            return __assign(__assign({}, state), { adjustments: action.payload
                    ? action.payload
                    : [] });
    }
};
export default PaymentRulesConfigurationContext;
