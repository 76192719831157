var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ButtonSchemaOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 16 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M9.286 15.25H3.75C2.645 15.25 1.75 14.355 1.75 13.25V4.75C1.75 3.645 2.645 2.75 3.75 2.75H14.25C15.355 2.75 16.25 3.645 16.25 4.75V9.652", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M4.25 6C4.66421 6 5 5.66421 5 5.25C5 4.83579 4.66421 4.5 4.25 4.5C3.83579 4.5 3.5 4.83579 3.5 5.25C3.5 5.66421 3.83579 6 4.25 6Z", fill: "#959595" }), _jsx("path", { d: "M6.75 6C7.16421 6 7.5 5.66421 7.5 5.25C7.5 4.83579 7.16421 4.5 6.75 4.5C6.33579 4.5 6 4.83579 6 5.25C6 5.66421 6.33579 6 6.75 6Z", fill: "#959595" }), _jsx("path", { d: "M10.655 10.269L17.052 12.606C17.322 12.704 17.314 13.088 17.04 13.176L14.112 14.113L13.175 17.041C13.088 17.314 12.704 17.322 12.605 17.053L10.268 10.656C10.18 10.415 10.414 10.182 10.654 10.27L10.655 10.269Z", stroke: "#959595", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var ButtonSchemaOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: ButtonSchemaOutlinedSvg }, props))); };
