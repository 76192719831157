import { CollectionType } from 'src/shared';
export var SearchableCollectionTypeSet = new Set([
    CollectionType.CUSTOM,
    CollectionType.USER,
    CollectionType.LOCATION,
    CollectionType.ROLE,
    CollectionType.CONTACT,
    CollectionType.PROJECT,
    CollectionType.TASK,
    CollectionType.JOB,
    CollectionType.PLACEMENT,
]);
export var NoTitleColorCollectionTypeSet = new Set([
    CollectionType.SHIFT,
    CollectionType.DOCUMENT,
    CollectionType.TASK,
    CollectionType.JOB,
]);
export var TITLE_COLUMN_ID = 'title';
export var TITLE_COLUMN_WIDTH = 300;
export var ADD_NEW_COLUMN_COLUMN_ID = 'addNewColumnButton';
export var SELECT_RECORD_COLUMN_ID = 'selectRecordColumn';
export var SELECT_RECORD_COLUMN_WIDTH = 30;
export var BULK_EDIT_ICON = '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.25C1 1.11193 1.11193 1 1.25 1H7.25C7.38807 1 7.5 1.11193 7.5 1.25V1.75C7.5 1.88807 7.38807 2 7.25 2H1.25C1.11193 2 1 1.88807 1 1.75V1.25Z" fill="black"/><path d="M1 5.75043C1 5.61236 1.11193 5.50043 1.25 5.50043H10.75C10.8881 5.50043 11 5.61236 11 5.75043V6.25043C11 6.3885 10.8881 6.50043 10.75 6.50043H1.25C1.11193 6.50043 1 6.3885 1 6.25043V5.75043Z" fill="black"/><path d="M1 10.25C1 10.1119 1.11193 10 1.25 10H3.75C3.88807 10 4 10.1119 4 10.25V10.75C4 10.8881 3.88807 11 3.75 11H1.25C1.11193 11 1 10.8881 1 10.75V10.25Z" fill="black"/></svg>';
