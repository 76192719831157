import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Content } from 'antd/lib/layout/layout';
import { useState } from 'react';
import ChatHeader from './ChatHeader';
import ChatPage from './ChatPage';
var ChatScreen = function (_a) {
    var toggleActivityFeed = _a.toggleActivityFeed, leftNavCollapsed = _a.leftNavCollapsed, toggleNaviBar = _a.toggleNaviBar;
    var _b = useState(false), chatSiderCollapsed = _b[0], setChatSiderCollapsed = _b[1];
    return (_jsxs("div", { className: "ChatView", children: [_jsx(ChatHeader, { toggleActivityFeed: toggleActivityFeed, chatSiderCollapsed: chatSiderCollapsed, toggleChatSiderBar: setChatSiderCollapsed, leftNavCollapsed: leftNavCollapsed, toggleNaviBar: toggleNaviBar }), _jsx(Content, { children: _jsx(ChatPage, { chatSiderCollapsed: chatSiderCollapsed, setChatSiderCollapsed: setChatSiderCollapsed }) })] }));
};
export default ChatScreen;
