var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var AddLocationToUsersSvg = function () { return (_jsx("svg", { width: "21px", height: "21px", version: "1.1", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", children: _jsxs("g", { children: [_jsx("path", { d: "m19.27 35.934c0 10.18 8.2812 18.465 18.465 18.465 10.18 0 18.465-8.2812 18.465-18.465 0-10.18-8.2812-18.465-18.465-18.465-10.18 0-18.465 8.2852-18.465 18.465zm28.445 0c0 5.5039-4.4766 9.9805-9.9805 9.9805s-9.9805-4.4766-9.9805-9.9805 4.4766-9.9805 9.9805-9.9805 9.9805 4.4766 9.9805 9.9805z" }), _jsx("path", { d: "m13.484 82.531c0-9.1094 7.4102-16.523 16.523-16.523h15.457c9.1094 0 16.523 7.4102 16.523 16.523h8.4844c0-13.789-11.219-25.008-25.008-25.008h-15.457c-13.789 0-25.008 11.219-25.008 25.008z" }), _jsx("path", { d: "m95 51.387v-4.2422h-11.566v-11.566h-8.4844v11.566h-11.566v8.4844h11.566v11.562h8.4844v-11.562h11.566z" })] }) })); };
export var AddLocationToUsersIcon = function (props) { return (_jsx(Icon, __assign({ component: AddLocationToUsersSvg }, props))); };
