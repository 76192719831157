var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _, { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useRecoilCallback } from 'recoil';
import { TEMP_NODE_TITLE, CellInputMode, ValueDataType, EmitterEventType, SchemaConfigurationType, } from 'src/shared';
import { addSchemaIdToDisplaySettings, removeSchemaIdFromDisplaySettings, removeValueIdFromDisplaySettings, replaceSchemaIdFromDisplaySettings, } from 'src/services';
import { tableCollectionIdSelector, tableDisplaySettingsState, tableSelectedViewIdState, collectionIdToBlockIdsState, collectionTableSchemaSelector, collectionTableSchemasState, currentReverseRelationIdState, detailModalSchemaGroups, } from 'src/state';
import { schemaFormulaState } from 'src/state/atoms/schemaState';
import Emitter from 'src/utils/EventEmitter';
import { usePageApi } from '../api';
import { useFormulaApi } from '../api/useFormulaApi';
import { useGenerativeApi } from '../api/useGenerativeApi';
import { useRefetchCollection } from './useRefetchCollection';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useSchema = function () {
    var _a = usePageApi(), addCollectionSchema = _a.addCollectionSchema, updateCollectionSchema = _a.updateCollectionSchema, deleteCollectionSchema = _a.deleteCollectionSchema, createSchemaOption = _a.createSchemaOption, updateSchemaOption = _a.updateSchemaOption, deleteSchemaOption = _a.deleteSchemaOption, createCollectionObjectInSchemaColumn = _a.createCollectionObjectInSchemaColumn;
    var backfillGenerativeSchema = useGenerativeApi().backfillGenerativeSchema;
    var refetchCollection = useRefetchCollection();
    var queryClient = useQueryClient();
    var addNewColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (addTo, blockId, options, column, preSaveRequest, fromCollectionId, reverseRelationId) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionId, _a, recordId, relativeSchemaId, insertLocation, toDuplicate, proposedSchemaId, duplicateSchema, newColumnName, newColumnInputMode, newColumnType, newColumnConfiguration, createNewSchemaPostBody, insertAt, newSchema, reverseId, allBlocksIds, _i, allBlocksIds_1, blockId_1;
            var _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (column && !column.name) {
                            toast('Column name cannot be empty.', {
                                type: 'error',
                                position: toast.POSITION.BOTTOM_CENTER,
                            });
                            return [2 /*return*/];
                        }
                        if (blockId && addTo === 'block') {
                            collectionId = snapshot
                                .getLoadable(tableCollectionIdSelector(blockId))
                                .valueMaybe();
                        }
                        else {
                            collectionId = fromCollectionId;
                        }
                        if (!collectionId)
                            return [2 /*return*/];
                        _a = options || {}, recordId = _a.recordId, relativeSchemaId = _a.relativeSchemaId, insertLocation = _a.insertLocation, toDuplicate = _a.toDuplicate, proposedSchemaId = _a.proposedSchemaId;
                        duplicateSchema = toDuplicate && relativeSchemaId
                            ? snapshot
                                .getLoadable(collectionTableSchemaSelector({
                                collectionId: collectionId,
                                schemaId: relativeSchemaId,
                            }))
                                .valueMaybe()
                            : null;
                        newColumnName = duplicateSchema
                            ? 'Copy of ' + duplicateSchema.name
                            : ((_b = column === null || column === void 0 ? void 0 : column.name) !== null && _b !== void 0 ? _b : TEMP_NODE_TITLE);
                        newColumnInputMode = (_c = duplicateSchema === null || duplicateSchema === void 0 ? void 0 : duplicateSchema.inputMode) !== null && _c !== void 0 ? _c : CellInputMode.PLAIN;
                        newColumnType = (_d = duplicateSchema === null || duplicateSchema === void 0 ? void 0 : duplicateSchema.type) !== null && _d !== void 0 ? _d : ValueDataType.TEXT;
                        newColumnConfiguration = duplicateSchema === null || duplicateSchema === void 0 ? void 0 : duplicateSchema.configuration;
                        createNewSchemaPostBody = {
                            requestFrom: addTo,
                            newSchema: column
                                ? __assign(__assign({}, column), { collectionId: collectionId }) : {
                                collectionId: collectionId,
                                name: newColumnName,
                                inputMode: newColumnInputMode,
                                type: newColumnType,
                                configuration: newColumnConfiguration,
                            },
                        };
                        if (addTo === 'record_details') {
                            createNewSchemaPostBody = _.merge(createNewSchemaPostBody, {
                                recordId: recordId,
                            });
                        }
                        else if (addTo === 'pre_save') {
                            createNewSchemaPostBody = _.merge(createNewSchemaPostBody, {
                                proposedRecordId: recordId,
                                request: preSaveRequest,
                            });
                        }
                        else {
                            createNewSchemaPostBody = _.merge(createNewSchemaPostBody, {
                                blockId: blockId,
                            });
                        }
                        insertAt = relativeSchemaId && insertLocation
                            ? {
                                nextToSchemaId: relativeSchemaId,
                                location: insertLocation,
                            }
                            : undefined;
                        if (insertAt) {
                            createNewSchemaPostBody = _.merge(createNewSchemaPostBody, {
                                insertAt: insertAt,
                            });
                        }
                        if (proposedSchemaId) {
                            createNewSchemaPostBody.newSchema = _.merge(createNewSchemaPostBody.newSchema, {
                                proposedSchemaId: proposedSchemaId,
                            });
                        }
                        return [4 /*yield*/, addCollectionSchema(createNewSchemaPostBody)];
                    case 1:
                        newSchema = _e.sent();
                        if (!newSchema)
                            return [2 /*return*/];
                        set(collectionTableSchemasState(collectionId), function (prev) { return __spreadArray(__spreadArray([], prev, true), [newSchema], false); });
                        if (addTo === 'record_details') {
                            reverseId = reverseRelationId !== null && reverseRelationId !== void 0 ? reverseRelationId : snapshot.getLoadable(currentReverseRelationIdState).valueMaybe();
                            if (reverseId) {
                                set(tableDisplaySettingsState(reverseId), function (prev) {
                                    return addSchemaIdToDisplaySettings(prev, newSchema.id);
                                });
                            }
                        }
                        allBlocksIds = snapshot
                            .getLoadable(collectionIdToBlockIdsState(collectionId))
                            .valueMaybe();
                        if (allBlocksIds) {
                            for (_i = 0, allBlocksIds_1 = allBlocksIds; _i < allBlocksIds_1.length; _i++) {
                                blockId_1 = allBlocksIds_1[_i];
                                set(tableDisplaySettingsState(blockId_1), function (prev) {
                                    return addSchemaIdToDisplaySettings(prev, newSchema.id, insertAt);
                                });
                            }
                        }
                        else {
                            refetchCollection(collectionId);
                        }
                        return [2 /*return*/, newSchema];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var deleteColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (body, schemaId, fromCollectionId, reverseRelationId) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionId, deleteSchemaPostBody, resp, allBlocksIds, _i, allBlocksIds_2, blockId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        collectionId = body.requestFrom === 'block'
                            ? snapshot
                                .getLoadable(tableCollectionIdSelector(body.blockId))
                                .valueMaybe()
                            : fromCollectionId;
                        deleteSchemaPostBody = _.merge({
                            collectionId: collectionId,
                            schemaId: schemaId,
                        }, body);
                        return [4 /*yield*/, deleteCollectionSchema(deleteSchemaPostBody)];
                    case 1:
                        resp = _a.sent();
                        if (!resp)
                            return [2 /*return*/];
                        if (reverseRelationId) {
                            set(tableDisplaySettingsState(reverseRelationId), function (prev) {
                                return removeSchemaIdFromDisplaySettings(prev, schemaId);
                            });
                        }
                        allBlocksIds = snapshot
                            .getLoadable(collectionIdToBlockIdsState(collectionId))
                            .valueMaybe();
                        if (allBlocksIds) {
                            for (_i = 0, allBlocksIds_2 = allBlocksIds; _i < allBlocksIds_2.length; _i++) {
                                blockId = allBlocksIds_2[_i];
                                set(tableDisplaySettingsState(blockId), function (prev) {
                                    return removeSchemaIdFromDisplaySettings(prev, schemaId);
                                });
                            }
                            Emitter.emit(EmitterEventType.REFETCH_COLLECTION, collectionId);
                        }
                        else {
                            refetchCollection(collectionId);
                        }
                        set(collectionTableSchemasState(collectionId), function (prev) {
                            return prev.filter(function (schema) { return schema.id !== schemaId; });
                        });
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var updateColumnName = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (body, schemaId, newSchemaContent, fromCollectionId) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionId, requestBody, newSchema;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!newSchemaContent.name) {
                            toast('Column name cannot be empty.', {
                                type: 'error',
                                position: toast.POSITION.BOTTOM_CENTER,
                            });
                            return [2 /*return*/];
                        }
                        collectionId = body.requestFrom === 'block'
                            ? snapshot
                                .getLoadable(tableCollectionIdSelector(body.blockId))
                                .valueMaybe()
                            : fromCollectionId;
                        requestBody = _.merge({
                            schemaToUpdate: __assign({ collectionId: collectionId, schemaId: schemaId }, newSchemaContent),
                        }, body);
                        return [4 /*yield*/, updateCollectionSchema(requestBody)];
                    case 1:
                        newSchema = _a.sent();
                        if (!newSchema)
                            return [2 /*return*/];
                        set(collectionTableSchemasState(collectionId), function (prev) {
                            return prev.map(function (schema) { return (schema.id !== schemaId ? schema : newSchema); });
                        });
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var mutateColumn = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (body, schemaId, newSchemaContent, fromCollectionId, reverseRelationId) { return __awaiter(void 0, void 0, void 0, function () {
            var collectionId, postBody, newSchema, isFromDetail, allBlocksIds, _i, allBlocksIds_3, blockId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        collectionId = body.requestFrom === 'block'
                            ? snapshot
                                .getLoadable(tableCollectionIdSelector(body.blockId))
                                .valueMaybe()
                            : fromCollectionId;
                        postBody = _.merge({
                            schemaToUpdate: __assign({ collectionId: collectionId, schemaId: schemaId }, newSchemaContent),
                        }, body);
                        return [4 /*yield*/, updateCollectionSchema(postBody)];
                    case 1:
                        newSchema = _a.sent();
                        if (!newSchema)
                            return [2 /*return*/];
                        isFromDetail = body.requestFrom === 'record_details' || body.requestFrom === 'pre_save';
                        if (isFromDetail) {
                            set(detailModalSchemaGroups(collectionId), function (prevSchemaGroups) {
                                var schemaGroupIndex = prevSchemaGroups.findIndex(function (schemaGroup) {
                                    return schemaGroup.schemas.includes(schemaId);
                                });
                                if (schemaGroupIndex === -1)
                                    return prevSchemaGroups;
                                var schemaIndex = prevSchemaGroups[schemaGroupIndex].schemas.findIndex(function (groupSchemaId) { return groupSchemaId === schemaId; });
                                if (schemaIndex === -1)
                                    return prevSchemaGroups;
                                var temp = cloneDeep(prevSchemaGroups);
                                temp[schemaGroupIndex].schemas.splice(schemaIndex, 1, newSchema.id);
                                return temp;
                            });
                            if (reverseRelationId) {
                                set(tableDisplaySettingsState(reverseRelationId), function (prev) {
                                    return replaceSchemaIdFromDisplaySettings(prev, schemaId, newSchema.id);
                                });
                            }
                        }
                        allBlocksIds = snapshot
                            .getLoadable(collectionIdToBlockIdsState(collectionId))
                            .valueMaybe();
                        if (allBlocksIds) {
                            for (_i = 0, allBlocksIds_3 = allBlocksIds; _i < allBlocksIds_3.length; _i++) {
                                blockId = allBlocksIds_3[_i];
                                set(tableDisplaySettingsState(blockId), function (prev) {
                                    return replaceSchemaIdFromDisplaySettings(prev, schemaId, newSchema.id);
                                });
                            }
                            Emitter.emit(EmitterEventType.REFETCH_COLLECTION, collectionId);
                        }
                        else {
                            refetchCollection(collectionId);
                        }
                        set(collectionTableSchemasState(collectionId), function (prev) {
                            return prev.map(function (schema) { return (schema.id !== schemaId ? schema : newSchema); });
                        });
                        return [2 /*return*/, newSchema];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var backfillGenerativeColumn = useCallback(function (collectionId, schema, backfillExisting) { return __awaiter(void 0, void 0, void 0, function () {
        var postBody;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    postBody = {
                        schemaId: schema.id,
                        backfillExisting: backfillExisting,
                    };
                    return [4 /*yield*/, backfillGenerativeSchema(postBody, schema.name).catch(function (e) {
                            if (e !== 'IgnoreApiError')
                                throw e;
                        })];
                case 1:
                    _a.sent();
                    refetchCollection(collectionId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var refetchColumnOptions = useCallback(function (schemaId, refCollectionId) {
        if (refCollectionId === void 0) { refCollectionId = ''; }
        if (schemaId) {
            queryClient.invalidateQueries(["universalSimpleList", refCollectionId, schemaId]);
        }
        else {
            queryClient.invalidateQueries(["universalSimpleList", refCollectionId]);
        }
    }, [queryClient]);
    var addColumnOption = useCallback(function (schemaId, newOptionId, newOptionName, newOptionColor, inputMode, collectionId) { return __awaiter(void 0, void 0, void 0, function () {
        var newOptionData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newOptionData = {
                        type: 'text',
                        text: newOptionName,
                        color: newOptionColor,
                        inputMode: inputMode,
                    };
                    return [4 /*yield*/, createSchemaOption({
                            collectionId: collectionId,
                            schemaId: schemaId,
                            proposedOptionId: newOptionId,
                            newOptionData: newOptionData,
                        })];
                case 1:
                    _a.sent();
                    refetchColumnOptions(schemaId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var addNewStandardObjInColumnOption = useCallback(function (collectionId, schemaId, proposedRecordId, name, color) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createCollectionObjectInSchemaColumn({
                        schemaId: schemaId,
                        proposedRecordId: proposedRecordId,
                        name: name,
                        color: color,
                    })];
                case 1:
                    _a.sent();
                    refetchColumnOptions(schemaId, collectionId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var mutateColumnOption = useRecoilCallback(function () {
        return function (requestFrom, schemaId, optionId, newOptionData) { return __awaiter(void 0, void 0, void 0, function () {
            var body, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        body = _.merge({
                            schemaId: schemaId,
                            newOptionData: newOptionData,
                        }, requestFrom);
                        return [4 /*yield*/, updateSchemaOption(optionId, body)];
                    case 1:
                        resp = _a.sent();
                        if (resp) {
                            refetchColumnOptions(schemaId);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var deleteColumnOption = useRecoilCallback(function (_a) {
        var set = _a.set, snapshot = _a.snapshot;
        return function (requestFrom, valueId, schemaId, collectionId) { return __awaiter(void 0, void 0, void 0, function () {
            var resp, blockId, viewId, allBlocksIds, _i, allBlocksIds_4, blockId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!('blockId' in requestFrom)) return [3 /*break*/, 2];
                        blockId = requestFrom.blockId;
                        viewId = snapshot
                            .getLoadable(tableSelectedViewIdState(blockId))
                            .valueMaybe();
                        if (!viewId)
                            return [2 /*return*/];
                        return [4 /*yield*/, deleteSchemaOption({
                                valueId: valueId,
                                collectionId: collectionId,
                                viewId: viewId,
                            })];
                    case 1:
                        resp = _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!('recordId' in requestFrom)) return [3 /*break*/, 4];
                        return [4 /*yield*/, deleteSchemaOption({
                                valueId: valueId,
                                collectionId: collectionId,
                                recordId: requestFrom.recordId,
                            })];
                    case 3:
                        resp = _a.sent();
                        _a.label = 4;
                    case 4:
                        if (resp) {
                            refetchColumnOptions(schemaId);
                            allBlocksIds = snapshot
                                .getLoadable(collectionIdToBlockIdsState(collectionId))
                                .valueMaybe();
                            if (allBlocksIds) {
                                for (_i = 0, allBlocksIds_4 = allBlocksIds; _i < allBlocksIds_4.length; _i++) {
                                    blockId = allBlocksIds_4[_i];
                                    set(tableDisplaySettingsState(blockId), function (prev) {
                                        return removeValueIdFromDisplaySettings(prev, valueId);
                                    });
                                }
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var reorderColumnOptions = useCallback(function (body, schema, optionIds, collectionId) { return __awaiter(void 0, void 0, void 0, function () {
        var configuration, postBody, newSchema;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    configuration = (schema.configuration
                        ? _.cloneDeep(schema.configuration)
                        : { type: SchemaConfigurationType.SELECT });
                    configuration.selectOptionOrder = optionIds;
                    postBody = _.merge({
                        schemaToUpdate: {
                            collectionId: collectionId,
                            schemaId: schema.id,
                            name: schema.name,
                            inputMode: schema.inputMode,
                            type: schema.type,
                            refCollectionId: schema.refCollectionId,
                            nativePath: schema.nativePath,
                            formulaId: schema.formulaId,
                            configuration: configuration,
                        },
                    }, body);
                    return [4 /*yield*/, updateCollectionSchema(postBody)];
                case 1:
                    newSchema = _a.sent();
                    if (!newSchema)
                        return [2 /*return*/];
                    refetchColumnOptions(schema.id);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var getFormulaById = useFormulaApi().getFormulaById;
    var fetchFormulaForSchema = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId, formulaId) { return __awaiter(void 0, void 0, void 0, function () {
            var formula;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getFormulaById(formulaId)];
                    case 1:
                        formula = _a.sent();
                        if (formula)
                            set(schemaFormulaState(schemaId), formula);
                        return [2 /*return*/];
                }
            });
        }); };
    }, [getFormulaById]);
    return {
        addNewColumn: addNewColumn,
        deleteColumn: deleteColumn,
        updateColumnName: updateColumnName,
        refetchColumnOptions: refetchColumnOptions,
        mutateColumn: mutateColumn,
        backfillGenerativeColumn: backfillGenerativeColumn,
        addColumnOption: addColumnOption,
        addNewStandardObjInColumnOption: addNewStandardObjInColumnOption,
        deleteColumnOption: deleteColumnOption,
        mutateColumnOption: mutateColumnOption,
        reorderColumnOptions: reorderColumnOptions,
        fetchFormulaForSchema: fetchFormulaForSchema,
    };
};
export { useSchema as useSchemaComponent };
