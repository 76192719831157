var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { selectorFamily } from 'recoil';
import { NonSchemaNativePath } from 'src/shared';
import { collectionTableState } from 'src/state';
var boardViewShiftCardOpenCountState = selectorFamily({
    key: 'boardViewShiftCardOpenCountState',
    get: function (_a) {
        var recordId = _a.recordId, dataSource = __rest(_a, ["recordId"]);
        return function (_a) {
            var _b, _c, _d, _e, _f;
            var get = _a.get;
            var collectionTableNonSchemaNativeFields = (_c = (_b = get(collectionTableState(dataSource))) === null || _b === void 0 ? void 0 : _b.nonSchemaNativeFieldsMap) !== null && _c !== void 0 ? _c : {};
            return ((_f = (_e = (_d = collectionTableNonSchemaNativeFields[recordId]) === null || _d === void 0 ? void 0 : _d.find(function (field) { return field.path === NonSchemaNativePath.SHIFT_OPEN_COUNT; })) === null || _e === void 0 ? void 0 : _e.openCount) !== null && _f !== void 0 ? _f : 0);
        };
    },
});
export { boardViewShiftCardOpenCountState };
