import { CollectionTableType } from 'src/shared';
export var isEmptyCellData = function (value) {
    var _a, _b, _c, _d;
    return (value !== undefined &&
        !((_a = Object.values(value)[0]) === null || _a === void 0 ? void 0 : _a.length) &&
        !((_b = Object.values(value)[0]) === null || _b === void 0 ? void 0 : _b.value) &&
        !((_d = (_c = Object.values(value)[0]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.length));
};
export var canEditCell = function (canEdit, canOnlyUpdateEmpty, collectionTableType, cellData) {
    return (canEdit &&
        (!canOnlyUpdateEmpty ||
            isEmptyCellData(cellData) ||
            collectionTableType === CollectionTableType.NEW_DETAIL_MODAL));
};
