var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var WorkspaceSettingsIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 14 14", height: "24", width: "24", children: _jsxs("g", { children: [_jsx("path", { d: "M12,13.5H2a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H12a1,1,0,0,1,1,1v11A1,1,0,0,1,12,13.5Z", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "4", y1: "0.5", x2: "4", y2: "13.5", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("line", { x1: "7.5", y1: "4", x2: "9.5", y2: "4", fill: "none", stroke: "#000000", strokeLinecap: "round", strokeLinejoin: "round" })] }) })); };
export var WorkspaceSettingsIcon = function (props) { return (_jsx(Icon, __assign({ component: WorkspaceSettingsIconSvg }, props))); };
