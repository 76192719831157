import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, } from 'src/state';
import UserAvatar from 'src/components/User/UserAvatar';
import { NativePath } from 'src/shared';
import { getNativeFieldCellContent } from 'src/utils/Collection';
import { useWindowSize } from 'src/hooks/component';
import { useUserDetailModalComponent } from 'src/hooks/component/useUserDetailModal';
import RecordDetailModalTitle from '../RecordDetailModalTitle';
var UserDetailModalTitle = function (_a) {
    var dataSource = _a.dataSource, renderActiveOrNotTag = _a.renderActiveOrNotTag, avatarUrl = _a.avatarUrl, recordColor = _a.recordColor, modalHeaderRef = _a.modalHeaderRef;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var onEditUserAvatar = useUserDetailModalComponent({
        dataSource: dataSource,
    }).onEditUserAvatar;
    var _b = useWindowSize().windowWidth, windowWidth = _b === void 0 ? 0 : _b;
    var isWideScreenView = windowWidth > 1200;
    var _c = useState(160), modalHeaderWidth = _c[0], setModalHeaderHeight = _c[1];
    var modalHeaderObserver = useMemo(function () {
        return new ResizeObserver(function (entries) {
            if (entries[0]) {
                setModalHeaderHeight(entries[0].contentRect.width);
            }
        });
    }, []);
    useEffect(function () {
        if (modalHeaderRef === null || modalHeaderRef === void 0 ? void 0 : modalHeaderRef.current) {
            modalHeaderObserver.observe(modalHeaderRef.current);
        }
        return function () {
            modalHeaderObserver.disconnect();
        };
    }, [modalHeaderObserver, modalHeaderRef]);
    var userTitle = useMemo(function () {
        var firstName = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.USER_FIRST_NAME);
        var lastName = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.USER_LAST_NAME);
        if (!firstName && !lastName) {
            return 'User Name';
        }
        var uploadProps = {
            canEdit: true,
            onUploadSuccess: function (newAvatarUrl) {
                onEditUserAvatar(newAvatarUrl);
            },
        };
        var fullName = (firstName !== null && firstName !== void 0 ? firstName : '') + ' ' + (lastName !== null && lastName !== void 0 ? lastName : '');
        var avatarSize = isWideScreenView ? modalHeaderWidth : 60;
        return (_jsxs("div", { className: "flex ".concat(isWideScreenView ? 'flex-col gap-y-2' : 'flex-row items-center gap-x-2'), children: [_jsx(UserAvatar, { avatar: avatarUrl, name: fullName, size: avatarSize, borderRadius: avatarSize / 10, uploadProps: uploadProps, color: recordColor }), _jsx("span", { children: fullName })] }));
    }, [
        avatarUrl,
        isWideScreenView,
        modalRecord,
        onEditUserAvatar,
        recordColor,
        nativeSchemaMap,
        modalHeaderWidth, // Update avatar size on header width change
    ]);
    return (_jsx(RecordDetailModalTitle, { title: userTitle, extras: renderActiveOrNotTag(NativePath.USER_ARCHIVED) }));
};
export default React.memo(UserDetailModalTitle);
