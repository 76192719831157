import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { RightArrowOutlinedIcon } from 'src/utils/icons/RightArrowOutlined';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
import { taskTemplateSelector } from '../selector';
import { DEFAULT_TASK_TEMPLATE_ICON } from '../constant';
var MobileTaskTemplateCard = function (_a) {
    var taskId = _a.taskId;
    var task = useRecoilValue(taskTemplateSelector(taskId));
    if (!task) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: "border border-[#EBF4F6] rounded-2xl p-4 mb-4", children: _jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("div", { className: "flex items-center mr-2", children: [_jsx(CustomIconInline, { iconUrl: task.iconInfo
                                ? task.iconInfo.content
                                : DEFAULT_TASK_TEMPLATE_ICON.content, iconSize: 16, backgroundColor: task.iconInfo.backgroundColor, className: "!min-h-[32px] !min-w-[32px] self-start", radius: 9999 }), _jsxs("div", { className: "ml-3", children: [_jsx("div", { className: "text-base font-semibold", children: task.name }), _jsx("div", { className: "text-gray-400", children: task.description })] })] }), _jsx(RightArrowOutlinedIcon, {})] }) }, task.id));
};
export default React.memo(MobileTaskTemplateCard);
