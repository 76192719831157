import { useQuery } from 'react-query';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useAccountHistoryApi = function () {
    var customFetch = useFetch().request;
    var getActivityHistory = function (request) {
        return customFetch({
            method: 'POST',
            url: "account_histories",
        }, request);
    };
    var useGetAccountHistoryQuery = function (query) {
        var _a, _b, _c;
        return useQuery(['useGetAccountHistoryQuery', (_b = (_a = query.body) === null || _a === void 0 ? void 0 : _a.accountId) !== null && _b !== void 0 ? _b : ''], function () { return getActivityHistory(query.body); }, {
            enabled: !!query.body && !!query.body.accountId,
            refetchInterval: ((_c = query.options) === null || _c === void 0 ? void 0 : _c.refetchInterval) || false,
        });
    };
    return {
        getActivityHistory: getActivityHistory,
        useGetAccountHistoryQuery: useGetAccountHistoryQuery,
    };
};
