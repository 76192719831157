var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var HealthMetricsOutlinedSvg = function () { return (_jsxs("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M1.16602 7.29199C1.16602 7.13091 1.2966 7.00033 1.45768 7.00033H2.04102C2.2021 7.00033 2.33268 7.13091 2.33268 7.29199V12.542C2.33268 12.7031 2.2021 12.8337 2.04102 12.8337H1.45768C1.2966 12.8337 1.16602 12.7031 1.16602 12.542V7.29199Z", fill: "black" }), _jsx("path", { d: "M8.16602 9.62533C8.16602 9.46424 8.2966 9.33366 8.45768 9.33366H9.04102C9.2021 9.33366 9.33268 9.46424 9.33268 9.62533V12.542C9.33268 12.7031 9.2021 12.8337 9.04102 12.8337H8.45768C8.2966 12.8337 8.16602 12.7031 8.16602 12.542V9.62533Z", fill: "black" }), _jsx("path", { d: "M11.666 1.45866C11.666 1.29758 11.7966 1.16699 11.9577 1.16699H12.541C12.7021 1.16699 12.8327 1.29758 12.8327 1.45866V12.542C12.8327 12.7031 12.7021 12.8337 12.541 12.8337H11.9577C11.7966 12.8337 11.666 12.7031 11.666 12.542V1.45866Z", fill: "black" }), _jsx("path", { d: "M4.66602 3.79199C4.66602 3.63091 4.7966 3.50033 4.95768 3.50033H5.54102C5.7021 3.50033 5.83268 3.63091 5.83268 3.79199V12.542C5.83268 12.7031 5.7021 12.8337 5.54102 12.8337H4.95768C4.7966 12.8337 4.66602 12.7031 4.66602 12.542V3.79199Z", fill: "black" })] })); };
export var HealthMetricsIcon = function (props) { return (_jsx(Icon, __assign({ component: HealthMetricsOutlinedSvg }, props))); };
