import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { Input, Modal } from 'antd';
import UserAvatar from 'src/components/User/UserAvatar';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
var ChatUserListModal = function (_a) {
    var defaultUserList = _a.defaultUserList, showReadStatus = _a.showReadStatus, children = _a.children;
    var _b = useState(false), isModalVisible = _b[0], setIsModalVisible = _b[1];
    var _c = useState([]), userList = _c[0], setUserList = _c[1];
    useEffect(function () {
        setUserList(defaultUserList);
    }, [defaultUserList]);
    var searchItems = function (searchInput) {
        var isInputEmpty = !searchInput.replace(/\s/g, '').length;
        if (!isInputEmpty) {
            var filteredData = defaultUserList.filter(function (item) {
                return item.name.toLowerCase().includes(searchInput.toLowerCase());
            });
            setUserList(filteredData);
        }
        else {
            setUserList(defaultUserList);
        }
    };
    var emptyContent = useMemo(function () {
        return _jsx("div", { children: "No Members" });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { onClick: function () { return setIsModalVisible(true); }, children: children }), isModalVisible && (_jsx(Modal, { open: isModalVisible, centered: true, onCancel: function () { return setIsModalVisible(false); }, closable: false, footer: null, className: "MessageViewedListModal", destroyOnClose: true, children: defaultUserList.length === 0 ? (emptyContent) : (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-3 pl-[15px] pr-[24px]", children: _jsx(Input, { prefix: _jsx(SearchOutlinedIcon, {}), placeholder: "Search...", onChange: function (e) { return searchItems(e.target.value); } }) }), _jsx("div", { className: "ReadByUsersList", children: _jsx(List, { height: 400, itemCount: userList.length, itemSize: 50, width: 480, children: function (_a) {
                                    var index = _a.index, style = _a.style;
                                    var user = userList[index];
                                    return (_jsxs("div", { className: "ReadByUserItem", style: style, children: [_jsxs("div", { className: "flex items-center", children: [_jsx(UserAvatar, { className: "ReadByUserAvatar", name: user.name, isCirclular: true, size: 35, avatar: user.avatarUrl }), _jsx("span", { className: "ReadByUserName", children: user.name })] }), showReadStatus && (_jsx("div", { className: "ReadLabel", children: "Read" }))] }, user.id));
                                } }) })] })) }))] }));
};
export default React.memo(ChatUserListModal);
