var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ClockOutlinedSvg = function () { return (_jsxs("svg", { width: "100%", height: "100%", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("mask", { id: "path-1-inside-1_6790_62706", fill: "white", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" }) }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" }), _jsx("path", { d: "M18 12C18 15.3137 15.3137 18 12 18V22C17.5228 22 22 17.5228 22 12H18ZM12 6C15.3137 6 18 8.68629 18 12H22C22 6.47715 17.5228 2 12 2V6ZM6 12C6 8.68629 8.68629 6 12 6V2C6.47715 2 2 6.47715 2 12H6ZM12 18C8.68629 18 6 15.3137 6 12H2C2 17.5228 6.47715 22 12 22V18ZM20 12C20 16.4183 16.4183 20 12 20V24C18.6274 24 24 18.6274 24 12H20ZM12 4C16.4183 4 20 7.58172 20 12H24C24 5.37258 18.6274 0 12 0V4ZM4 12C4 7.58172 7.58172 4 12 4V0C5.37258 0 0 5.37258 0 12H4ZM12 20C7.58172 20 4 16.4183 4 12H0C0 18.6274 5.37258 24 12 24V20Z", mask: "url(#path-1-inside-1_6790_62706)" }), _jsx("path", { d: "M11 6.5C11 6.22386 11.2239 6 11.5 6H12.5C12.7761 6 13 6.22386 13 6.5V12.5C13 12.7761 12.7761 13 12.5 13H11.5C11.2239 13 11 12.7761 11 12.5V6.5Z" }), _jsx("path", { d: "M11.5 13C11.2239 13 11 12.7761 11 12.5V11.5C11 11.2239 11.2239 11 11.5 11H14.5C14.7761 11 15 11.2239 15 11.5V12.5C15 12.7761 14.7761 13 14.5 13H11.5Z" })] })); };
export var ClockOutlined = function (props) { return (_jsx(Icon, __assign({ component: ClockOutlinedSvg }, props))); };
