import { find } from 'lodash';
import moment from 'moment';
export var generateTimeIntervals = function (startTime) {
    var times = []; // time array
    var start = moment(startTime);
    for (var i = 0; i < 24; i++) {
        times.push(start
            .clone()
            .add(30 * i, 'minutes')
            .toISOString());
    }
    return times;
};
export var convertToPercentNumber = function (num) {
    return Number((num * 100).toFixed(2));
};
export var getBackgroundColorByPercentageNumber = function (number) {
    if (number < 83) {
        return '#eee361';
    }
    else if (number < 100) {
        return 'rgba(238, 227, 97, 0.5)';
    }
    else if (number < 125) {
        return '#3371ac';
    }
    return '#eee361';
};
export var getPercentageNumberColor = function (number) {
    if (number < 83) {
        return 'black';
    }
    else if (number < 100) {
        return '#1a180380';
    }
    else if (number < 125) {
        return 'white';
    }
    return 'black';
};
export var initDefaultExpectedData = function (timeIntervals, expected) {
    var newExpectedData = [];
    timeIntervals.map(function (interval) {
        var existingExpectedEntry = find(expected, function (item) {
            return moment(item.time).isSame(moment(interval));
        });
        // fill null expected entry with default data
        newExpectedData.push({
            time: interval,
            count: (existingExpectedEntry === null || existingExpectedEntry === void 0 ? void 0 : existingExpectedEntry.count) || 0,
        });
    });
    return newExpectedData;
};
