import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input, InputNumber, Select } from 'antd';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import { BreakType } from '../../../../shared';
import './BreakRule.scss';
var Option = Select.Option;
var payStatusOptions = [
    { id: BreakType.UNPAID, displayLabel: 'Unpaid' },
    { id: BreakType.PAID, displayLabel: 'Paid' },
];
var BreakRule = function (props) {
    var _a = CompliancePageState.useContainer(), changeBreakRuleName = _a.changeBreakRuleName, deleteBreakRule = _a.deleteBreakRule, changeBreakRuleBreakTime = _a.changeBreakRuleBreakTime, changeBreakRulePayStatus = _a.changeBreakRulePayStatus, changeBreakRuleTimePeriod = _a.changeBreakRuleTimePeriod;
    var rule = props.rule, policyId = props.policyId;
    return (_jsxs("div", { className: "BreakRuleWrapper", children: [_jsx(Input, { className: "BreakNameInput", placeholder: "Enter a name for this rule", value: rule.name, onChange: function (e) { return changeBreakRuleName(policyId, rule.id, e.target.value); } }), _jsxs("div", { className: "RulesDetailsContainer", children: [_jsxs("div", { className: "numberInputContainer", children: [_jsx(InputNumber, { className: "settingNumberInput", value: rule.lengthInMinutes, bordered: false, onChange: function (value) {
                                    if (value) {
                                        changeBreakRuleBreakTime(policyId, rule.id, value);
                                    }
                                }, min: 0 }), _jsx("span", { className: "settingNumberInputSuffix", children: "mins" })] }), _jsx(Select, { dropdownMatchSelectWidth: false, bordered: false, value: rule.type, onChange: function (value) { return changeBreakRulePayStatus(policyId, rule.id, value); }, children: payStatusOptions.map(function (option) {
                            return (_jsx(Option, { value: option.id, children: option.displayLabel }, option.id));
                        }) }), _jsx("span", { className: "RuleDescriptionPiece", children: "for every" }), _jsxs("div", { className: "numberInputContainer", children: [_jsx(InputNumber, { className: "settingNumberInput", value: rule.periodInHours, bordered: false, onChange: function (value) {
                                    if (value) {
                                        changeBreakRuleTimePeriod(policyId, rule.id, value);
                                    }
                                }, min: 0 }), _jsx("span", { className: "settingNumberInputSuffix", children: "hrs" })] }), _jsx("span", { className: "RuleDescriptionPiece", children: "worked" })] }), _jsx("div", { className: "GroupRuleDeleteBtn", onClick: function () { return deleteBreakRule(policyId, rule.id); }, children: _jsx(TrashOutlinedIcon, {}) })] }));
};
export default React.memo(BreakRule);
