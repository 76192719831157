import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'antd';
import './Generative.scss';
import { useMemo } from 'react';
import { CellInputMode } from 'src/shared';
var Option = Select.Option;
var GeneratedIdVariableSelector = function (_a) {
    var collectionSchemas = _a.collectionSchemas, onSelect = _a.onSelect, placeholder = _a.placeholder;
    var nonGenerativeSchemas = useMemo(function () {
        return collectionSchemas.filter(function (schema) { return schema.inputMode !== CellInputMode.USER_DEFINED_GENERATED; });
    }, [collectionSchemas]);
    return (_jsx("div", { children: _jsx(Select, { className: "GeneratedIdVariableSelector max-w-content pr-0", size: "small", dropdownStyle: { minWidth: 240 }, placeholder: placeholder, suffixIcon: "", onClick: function (e) {
                e.stopPropagation();
            }, value: placeholder, onSelect: onSelect, children: nonGenerativeSchemas === null || nonGenerativeSchemas === void 0 ? void 0 : nonGenerativeSchemas.map(function (schema, idx) { return (_jsx(Option, { value: "".concat(schema.id), optionId: schema.id, label: schema.name, name: schema.name, children: schema.name }, "".concat(schema.id, "-").concat(schema.name, "-").concat(idx))); }) }) }));
};
export default GeneratedIdVariableSelector;
