var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import ZiraWarningPopconfirm from 'src/ui/ZiraWarningPopconfirm';
import { useTasksApi } from 'src/hooks/api';
import { useRecordDetail } from 'src/hooks/component';
import { NonSchemaNativePath } from 'src/shared';
import { collectionTableNonSchemaNativePathSelector } from 'src/state';
import { collectionNameSelector } from 'src/state/selectors/withAccountDetailState';
import pluralize from 'pluralize';
import TaskDetailLinkedRecordDropdown from './TaskDetailLinkedRecordDropdown';
var TaskDetailLinkedRecordContent = function (_a) {
    var _b, _c;
    var recordId = _a.recordId, dataSource = _a.dataSource;
    var completionDetail = (_b = useRecoilValue(collectionTableNonSchemaNativePathSelector(__assign(__assign({}, dataSource), { recordId: recordId, path: NonSchemaNativePath.TASK_COMPLETION_DETAIL })))) === null || _b === void 0 ? void 0 : _b.completionDetail;
    var _d = useState((_c = completionDetail === null || completionDetail === void 0 ? void 0 : completionDetail.completingRecords) !== null && _c !== void 0 ? _c : []), completionDetailRecords = _d[0], setCompletionRecords = _d[1];
    var completionDetailRecordIds = useMemo(function () { return completionDetailRecords.map(function (record) { return record.recordId; }); }, [completionDetailRecords]);
    var getCollectionName = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (collectionId) {
            var _a;
            return (_a = snapshot.getLoadable(collectionNameSelector(collectionId)).valueMaybe()) !== null && _a !== void 0 ? _a : '';
        };
    }, []);
    useEffect(function () {
        var _a;
        setCompletionRecords((_a = completionDetail === null || completionDetail === void 0 ? void 0 : completionDetail.completingRecords) !== null && _a !== void 0 ? _a : []);
    }, [completionDetail]);
    var _e = useTasksApi(), useUpdateTaskCompletionDetail = _e.useUpdateTaskCompletionDetail, useGetRecordTitles = _e.useGetRecordTitles;
    var recordTitles = useGetRecordTitles({
        body: {
            recordIds: completionDetailRecordIds ? completionDetailRecordIds : [],
        },
        options: {
            enabled: !!completionDetailRecordIds,
        },
    }).data;
    var getLinkedRecordTitle = useCallback(function (linkedRecordId, collectionId) {
        if (linkedRecordId) {
            return ((recordTitles === null || recordTitles === void 0 ? void 0 : recordTitles[linkedRecordId]) ||
                "Empty/Deleted ".concat(getCollectionName(collectionId) ? pluralize(getCollectionName(collectionId), 1) : 'record'));
        }
    }, [getCollectionName, recordTitles]);
    var updateLinkedRecord = useCallback(function (completionDetails) {
        useUpdateTaskCompletionDetail.mutate({
            assignedTaskRId: recordId,
            taskCompletionDetail: completionDetails,
        }, {
            onSuccess: function (data) {
                var _a;
                if (data) {
                    setCompletionRecords((_a = completionDetails === null || completionDetails === void 0 ? void 0 : completionDetails.completingRecords) !== null && _a !== void 0 ? _a : []);
                }
            },
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recordId]);
    var onRemoveLinkedRecord = useCallback(function (recordId) {
        var newCompletionRecords = completionDetailRecords === null || completionDetailRecords === void 0 ? void 0 : completionDetailRecords.filter(function (record) { return record.recordId !== recordId; });
        updateLinkedRecord((newCompletionRecords === null || newCompletionRecords === void 0 ? void 0 : newCompletionRecords.length)
            ? { completingRecords: newCompletionRecords }
            : undefined);
    }, [completionDetailRecords, updateLinkedRecord]);
    var onAddLinkedRecord = useCallback(function (completionDetail) {
        var newCompletionRecords = __spreadArray(__spreadArray([], completionDetailRecords, true), [completionDetail], false);
        updateLinkedRecord({ completingRecords: newCompletionRecords });
    }, [completionDetailRecords, updateLinkedRecord]);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var ref = useRef(null);
    return (_jsxs("div", { className: "mr-[10px] flex items-center gap-[4px] max-w-1/2 w-[360px]", children: [_jsxs("div", { ref: ref, className: "py-1 px-2 min-h-8 w-full min-w-0 bg-gray-40 text-xs font-medium rounded flex flex-col items-center justify-center my-2", children: [completionDetailRecords.map(function (_a) {
                        var recordId = _a.recordId, collectionId = _a.collectionId;
                        return (_jsxs("div", { className: "flex items-center w-full", children: [_jsx(ZiraTooltip, { title: "View Details", placement: "topLeft", children: _jsxs("div", { className: "max-w-[90%] cursor-pointer hover:underline flex items-center mr-2", onClick: function () { return openRecordDetail(recordId); }, children: [_jsx(LinkOutlined, { className: "mr-1" }), _jsx("span", { className: "overflow-hidden text-ellipsis whitespace-nowrap", children: getLinkedRecordTitle(recordId, collectionId) })] }) }), _jsx(ZiraWarningPopconfirm, { title: "Are you sure you want to unlink this record?", onConfirm: function () { return onRemoveLinkedRecord(recordId); }, children: _jsx(ZiraTooltip, { title: "Unlink Record", placement: "topLeft", children: _jsx(CloseOutlined, { className: "ml-auto cursor-pointer text-gray-200 hover:text-black" }) }) })] }, recordId));
                    }), !completionDetailRecords.length && (_jsx(TaskDetailLinkedRecordDropdown, { recordId: recordId, dataSource: dataSource, onLinkRecord: onAddLinkedRecord, children: _jsx("div", { className: "mx-auto", children: "Add Link" }) }))] }), !!completionDetailRecords.length && (_jsx(TaskDetailLinkedRecordDropdown, { recordId: recordId, dataSource: dataSource, onLinkRecord: onAddLinkedRecord, children: _jsx(ZiraTooltip, { title: "Add Link", placement: "topLeft", children: _jsx("div", { className: "bg-gray-40 p-2 flex items-center", children: _jsx(PlusOutlined, {}) }) }) }))] }));
};
export default React.memo(TaskDetailLinkedRecordContent);
