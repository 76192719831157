import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, Input } from 'antd';
import { useRecoilState } from 'recoil';
import './TaskSelectionDropdown.scss';
import { SearchOutlinedIcon } from 'src/utils/icons/SearchOutlined';
import { useTasksApi } from 'src/hooks/api';
import { EditOutlined } from '@ant-design/icons';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { taskGroupListState, taskTemplateListState } from '../atom';
import { useTasksComponent } from '../hooks/useTasks';
var TaskSelectionDropdown = function (_a) {
    var children = _a.children, selectedTaskIds = _a.selectedTasks, _b = _a.selectedTaskGroups, selectedTaskGroupIds = _b === void 0 ? [] : _b, _c = _a.isTaskAssignment, isTaskAssignment = _c === void 0 ? false : _c, _d = _a.initialFetch, initialFetch = _d === void 0 ? false : _d, onAddTask = _a.onAddTask, onAddTaskGroup = _a.onAddTaskGroup;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var _f = useState(false), fetchedTasks = _f[0], setFetchedTasks = _f[1];
    var _g = useRecoilState(taskTemplateListState), taskTemplateList = _g[0], setTaskTemplateList = _g[1];
    var _h = useRecoilState(taskGroupListState), taskGroupList = _h[0], setTaskGroupList = _h[1];
    var activeTaskTemplates = useMemo(function () { return taskTemplateList.filter(function (template) { return template.isActive; }); }, [taskTemplateList]);
    var activeTaskGroups = useMemo(function () { return taskGroupList.filter(function (group) { return group.isActive; }); }, [taskGroupList]);
    var _j = useTasksComponent(), viewTaskTemplate = _j.viewTaskTemplate, viewTaskGroup = _j.viewTaskGroup;
    //! Fetch tasks
    var useGetTasksOverview = useTasksApi().useGetTasksOverview;
    useGetTasksOverview({
        enabled: open || (initialFetch && !fetchedTasks),
        onSuccess: function (data) {
            if (data) {
                setTaskGroupList(data.taskGroups);
                setTaskTemplateList(data.tasks);
                setFetchedTasks(true);
            }
        },
    });
    var _k = useState([]), filteredTaskGroupList = _k[0], setFilteredTaskGroupList = _k[1];
    var _l = useState([]), filteredTaskTemplateList = _l[0], setFilteredTaskTemplateList = _l[1];
    useEffect(function () {
        setFilteredTaskGroupList(activeTaskGroups);
        setFilteredTaskTemplateList(activeTaskTemplates);
    }, [activeTaskGroups, activeTaskTemplates]);
    var searchTasks = useCallback(function (searchInput) {
        var actualSearchValue = searchInput.trim().toLowerCase();
        if (actualSearchValue.length > 0) {
            var filteredTaskTemplates = activeTaskTemplates.filter(function (item) {
                return (item.name.toLowerCase().includes(actualSearchValue) ||
                    item.displayId.toLowerCase().includes(actualSearchValue) ||
                    item.description.toLowerCase().includes(actualSearchValue));
            });
            var filteredTaskGroups = activeTaskGroups.filter(function (item) {
                return (item.name.toLowerCase().includes(actualSearchValue) ||
                    item.displayId.toLowerCase().includes(actualSearchValue) ||
                    item.description.toLowerCase().includes(actualSearchValue));
            });
            setFilteredTaskGroupList(filteredTaskGroups);
            setFilteredTaskTemplateList(filteredTaskTemplates);
        }
        else {
            setFilteredTaskGroupList(activeTaskGroups);
            setFilteredTaskTemplateList(activeTaskTemplates);
        }
    }, [activeTaskGroups, activeTaskTemplates]);
    var dropdownMenuItems = useMemo(function () {
        var items = [];
        if (!!onAddTaskGroup && filteredTaskGroupList.length > 0) {
            items.push({
                key: 'task-groups',
                type: 'group',
                label: (_jsx("div", { className: "text-gray-400 font-semibold text-xs pr-5 mt-5", children: "Task Groups" })),
                children: filteredTaskGroupList.map(function (taskGroup) {
                    // Disable option when it's already included
                    var isDisabled = !isTaskAssignment && selectedTaskGroupIds.includes(taskGroup.id);
                    return {
                        key: taskGroup.id,
                        label: (_jsxs("div", { className: "TaskMenuItem", children: [taskGroup.name, _jsx(ZiraTooltip, { title: "Edit", children: _jsx("div", { className: "TaskMenuItemEdit", onClick: function (e) {
                                            e.stopPropagation();
                                            setOpen(false);
                                            viewTaskGroup(taskGroup.id);
                                        }, children: _jsx(EditOutlined, {}) }) })] })),
                        onClick: function () {
                            onAddTaskGroup(taskGroup);
                            setOpen(false);
                        },
                        disabled: isDisabled,
                    };
                }),
            });
        }
        if (!!onAddTask && filteredTaskTemplateList.length > 0) {
            items.push({
                key: 'task-templates',
                type: 'group',
                label: (_jsx("div", { className: "text-gray-400 font-semibold text-xs pr-5 mt-5", children: "Task Templates" })),
                children: filteredTaskTemplateList.map(function (task) {
                    // Disable option when it's already included
                    var isDisabled = !isTaskAssignment && selectedTaskIds.includes(task.id);
                    return {
                        key: task.id,
                        label: (_jsxs("div", { className: "TaskMenuItem", style: isDisabled ? { pointerEvents: 'none' } : undefined, children: [task.name, _jsx(ZiraTooltip, { title: "Edit", children: _jsx("div", { className: "TaskMenuItemEdit", onClick: function (e) {
                                            e.stopPropagation();
                                            setOpen(false);
                                            viewTaskTemplate(task.id);
                                        }, children: _jsx(EditOutlined, {}) }) })] })),
                        onClick: function () {
                            onAddTask === null || onAddTask === void 0 ? void 0 : onAddTask(task);
                            setOpen(false);
                        },
                        disabled: isDisabled,
                    };
                }),
            });
        }
        return items;
    }, [
        filteredTaskGroupList,
        filteredTaskTemplateList,
        isTaskAssignment,
        onAddTask,
        onAddTaskGroup,
        selectedTaskGroupIds,
        selectedTaskIds,
        viewTaskGroup,
        viewTaskTemplate,
    ]);
    return (_jsx(Dropdown, { overlayClassName: "TaskSelectionDropdown", menu: { items: dropdownMenuItems }, dropdownRender: function (menu) { return (_jsxs("div", { className: "TaskDropdownMenuContainer", children: [_jsx("div", { className: "px-5", children: _jsx(Input
                    // Reset the uncontrolled input value when the dropdown opens/closes
                    , { prefix: _jsx(SearchOutlinedIcon, {}), placeholder: "Search Task", className: "TaskSearchBar", onChange: function (e) { return searchTasks(e.target.value); } }, open ? 'open' : 'closed') }), dropdownMenuItems.length === 0 ? (_jsx("div", { className: "TaskDropdownEmptyState py-6 text-gray-300", children: "No task templates" })) : (React.cloneElement(menu))] })); }, trigger: ['click'], placement: "bottomRight", open: open, onOpenChange: function (open) {
            setOpen(open);
            if (!open) {
                // Reset the filtered lists when the dropdown closes
                searchTasks('');
            }
        }, children: children }));
};
export default React.memo(TaskSelectionDropdown);
