import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Switch } from 'antd';
import './Generative.scss';
import { MagicGradientIcon } from 'src/utils/icons/DropdownIcons/MagicGradient';
import { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { StarGradientIcon } from 'src/utils/icons/DropdownIcons/StarGradient';
var SmartFieldSwitch = function (_a) {
    var isSmartField = _a.isSmartField, setIsSmartField = _a.setIsSmartField, smartTextEnabled = _a.smartTextEnabled;
    var _b = useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: isSmartField ? 'GradientWrapper Checked' : 'GradientWrapper', children: _jsxs("div", { className: "SmartSwitch flex items-center justify-between w-full mt-auto py-2 px-3", style: isSmartField ? { backgroundColor: '#F3F1FB' } : { backgroundColor: 'white' }, children: [_jsxs("div", { className: (isSmartField ? 'GradientText ' : '') +
                                'mt-[1px] flex items-center text-sm font-medium', children: [_jsx(MagicGradientIcon, { className: "SmartIcon mr-1 mb-[2px]" }), "SmartField"] }), smartTextEnabled ? (_jsx(Switch, { className: "mt-auto", checked: isSmartField, onChange: setIsSmartField })) : (_jsxs("div", { className: "flex items-center font-medium GradientTextHorizontal hover:cursor-pointer", onClick: function () { return setIsExpanded(!isExpanded); }, children: [_jsx("div", { children: "Premium Field Type" }), _jsx("div", { className: " ml-1 mb-[2px]", children: isExpanded ? (_jsx(UpOutlined, { className: "text-black" })) : (_jsx(DownOutlined, { className: "text-black" })) })] }))] }) }), isExpanded && (_jsx("div", { className: "GradientWrapper Checked", children: _jsxs("div", { className: "flex flex-col SmartSwitch bg-white items-center w-full py-2 px-3", children: [_jsx(StarGradientIcon, { className: "-mb-2" }), _jsx("div", { className: "font-semibold text-[15px]", children: "Discover TeamBridge A.I." }), _jsx("div", { className: "text-xs text-center mx-4 mb-4", children: "Eliminate repetitive admin tasks, up-level processes, and uncover insights like never before using SmartField. Schedule time with our sales team to unlock this feature." }), _jsx("a", { href: "https://teambridge.com/product/ai/", target: "_blank", rel: "noreferrer", children: _jsx("div", { className: "rounded-lg bg-black text-white px-2 py-1 mb-2", children: "Learn More" }) })] }) }))] }));
};
export default SmartFieldSwitch;
