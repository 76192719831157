import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { RiseOutlined } from '@ant-design/icons';
import { useTasksComponent } from 'src/components/Settings/Tasks/hooks/useTasks';
import { useRecoilValue } from 'recoil';
import { collectionTableNativeSchemaSelector } from 'src/state';
import { NativePath } from 'src/shared';
var TaskDetailHeaderActions = function (_a) {
    var _b, _c;
    var recordId = _a.recordId, collectionRecord = _a.collectionRecord, collectionId = _a.collectionId;
    var viewTaskChangesModal = useTasksComponent().viewTaskChangesModal;
    var taskPlacementSchema = useRecoilValue(collectionTableNativeSchemaSelector({
        collectionId: collectionId,
        nativePath: NativePath.TASK_PLACEMENT_RID,
    }));
    var taskPlacementValue = collectionRecord[(_b = taskPlacementSchema === null || taskPlacementSchema === void 0 ? void 0 : taskPlacementSchema.id) !== null && _b !== void 0 ? _b : ''];
    var taskHasPlacement = ((_c = taskPlacementValue === null || taskPlacementValue === void 0 ? void 0 : taskPlacementValue.options) === null || _c === void 0 ? void 0 : _c.length) > 0;
    return taskHasPlacement ? (_jsx(ZiraTooltip, { title: "Push Changes", children: _jsx("div", { role: "button", className: "ActionButton", onClick: function () { return viewTaskChangesModal(recordId); }, children: _jsx(RiseOutlined, { className: "text-sm" }) }) })) : (_jsx(_Fragment, {}));
};
export default React.memo(TaskDetailHeaderActions);
