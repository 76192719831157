import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { AutoApplyTemplateIcon } from 'src/utils/icons/SchedulingIcons/AutoApplyTemplateIcon';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { tableFiltersSelector, blockTimeZoneState } from 'src/state';
import { useRecoilValue } from 'recoil';
import { useTimeZone } from 'src/hooks/component';
import { TemplateStatusType } from '../../interface';
import NeedsCoverageTableEmptyContent from '../NeedsCoverageTableEmptyContent';
var NeedsCoverageTemplateSelector = function (_a) {
    var blockId = _a.blockId, type = _a.type, appliedNeedCoverageTemplates = _a.appliedNeedCoverageTemplates, onSelectTemplateToApply = _a.onSelectTemplateToApply;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var blockFilters = useRecoilValue(tableFiltersSelector(blockId));
    var _c = useNeedCoverageApi(), useGetAllNeedTemplates = _c.useGetAllNeedTemplates, useGetAllCoverageTemplates = _c.useGetAllCoverageTemplates;
    var allNeedTemplates = useGetAllNeedTemplates({
        body: {
            filters: blockFilters.length ? blockFilters : undefined,
        },
        options: {
            enabled: dropdownOpen && type === 'need',
        },
    }).data;
    var allCoverageTemplates = useGetAllCoverageTemplates({
        body: {
            filters: blockFilters.length ? blockFilters : undefined,
        },
        options: {
            enabled: dropdownOpen && type === 'coverage',
        },
    }).data;
    var blockTimeZone = useRecoilValue(blockTimeZoneState(blockId));
    var timeFormatFn = useTimeZone(blockTimeZone).timeFormatFn;
    var templateTooltip = useCallback(function (template) {
        var foundTemplate = appliedNeedCoverageTemplates.find(function (_a) {
            var id = _a.id;
            return template.id === id;
        });
        return (_jsxs("div", { children: [template.name, template.autoApply ? ' (with auto apply)' : '', !!(foundTemplate === null || foundTemplate === void 0 ? void 0 : foundTemplate.appliedRange.length) && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsx("br", {}), "Applied Dates:", _jsx("br", {}), foundTemplate === null || foundTemplate === void 0 ? void 0 : foundTemplate.appliedRange.map(function (range) { return (_jsxs("div", { children: [timeFormatFn(range.startTime, 'MMM D', false), " -", ' ', timeFormatFn(range.endTime, 'MMM D', false), _jsx("br", {})] }, range.id)); })] }))] }));
    }, [appliedNeedCoverageTemplates, timeFormatFn]);
    var dropdownItems = useMemo(function () {
        var _a;
        var activeTemplates = ((_a = (type === 'need' ? allNeedTemplates : allCoverageTemplates)) !== null && _a !== void 0 ? _a : []).filter(function (template) { return template.status.status === TemplateStatusType.ACTIVE; });
        if (dropdownOpen && !activeTemplates.length) {
            return [
                {
                    key: 'emptyTemplates',
                    label: _jsx(NeedsCoverageTableEmptyContent, { blockId: blockId, type: type }),
                    onClick: function () { return setDropdownOpen(false); },
                },
            ];
        }
        return activeTemplates.map(function (template) {
            var foundTemplate = appliedNeedCoverageTemplates.find(function (_a) {
                var id = _a.id;
                return template.id === id;
            });
            return {
                key: template.id,
                label: (_jsx(ZiraTooltip, { title: templateTooltip(template), placement: "right", children: _jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { className: "TemplateTagName text-xs font-medium", children: template.name }), template.autoApply && (_jsx(AutoApplyTemplateIcon, { className: "ml-[4px] mr-auto" })), !!(foundTemplate === null || foundTemplate === void 0 ? void 0 : foundTemplate.appliedRange.length) && (_jsx("div", { className: "ml-auto h-[8px] w-[8px] rounded bg-accent" }))] }) })),
                onClick: function () {
                    onSelectTemplateToApply(template);
                },
            };
        });
    }, [
        allCoverageTemplates,
        allNeedTemplates,
        appliedNeedCoverageTemplates,
        blockId,
        dropdownOpen,
        onSelectTemplateToApply,
        templateTooltip,
        type,
    ]);
    return (_jsx(_Fragment, { children: _jsx("div", { className: "mr-8 flex items-center", children: _jsx(Dropdown, { menu: {
                    items: dropdownItems,
                }, trigger: ['click'], placement: "top", overlayClassName: "NeedsCoverageTemplatesSelectionDropdown", open: dropdownOpen, onOpenChange: function (open) { return setDropdownOpen(open); }, children: _jsx("div", { className: "cursor-pointer rounded border border-gray-30 bg-white px-2 py-1", children: _jsx(PlusOutlined, { style: {
                            color: 'black',
                            fontSize: '12px',
                            stroke: 'black',
                            strokeWidth: '20px',
                        } }) }) }) }) }));
};
export default React.memo(NeedsCoverageTemplateSelector);
