export var SchedulingSummaryType;
(function (SchedulingSummaryType) {
    SchedulingSummaryType["SCHEDULING_STATS"] = "SCHEDULING_STATS";
    SchedulingSummaryType["NEEDS_AND_COVERAGE"] = "NEEDS_AND_COVERAGE";
    SchedulingSummaryType["DEMAND_RATIO"] = "DEMAND_RATIO";
})(SchedulingSummaryType || (SchedulingSummaryType = {}));
export var NeedsCoverageType;
(function (NeedsCoverageType) {
    NeedsCoverageType["NEED"] = "NEED";
    NeedsCoverageType["COVERAGE"] = "COVERAGE";
})(NeedsCoverageType || (NeedsCoverageType = {}));
export var TemplateStatusType;
(function (TemplateStatusType) {
    TemplateStatusType["ACTIVE"] = "ACTIVE";
    TemplateStatusType["ARCHIVED"] = "ARCHIVED";
    TemplateStatusType["UPDATED"] = "UPDATED";
})(TemplateStatusType || (TemplateStatusType = {}));
