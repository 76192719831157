import { selectorFamily } from 'recoil';
import { blockItemsSelector } from '../withNaviTree';
var blockTitleSelector = selectorFamily({
    key: 'blockTitleSelector',
    get: function (blockId) {
        return function (_a) {
            var get = _a.get;
            var blockItems = get(blockItemsSelector);
            var block = blockItems.find(function (item) { return item.id === blockId; });
            if (!block)
                return '';
            return block.data.name;
        };
    },
});
export { blockTitleSelector };
