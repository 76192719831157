var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useRecoilCallback } from 'recoil';
import { SCHEDULE_UNASSIGNED_KEY, ScheduleGroupBy } from 'src/shared';
import { collectionTableSchemaMutationsState, scheduleCollectionIdState, scheduleGroupBySelector, scheduleTimeZoneSelector, } from 'src/state';
import { useScheduleTemplateApi } from '../api';
import { useRefetchCollection } from './useRefetchCollection';
var useShiftTemplate = function () {
    var _a = useScheduleTemplateApi(), useCreateNewShiftTemplate = _a.useCreateNewShiftTemplate, useUpdateShiftTemplate = _a.useUpdateShiftTemplate, useApplyShiftTemplate = _a.useApplyShiftTemplate;
    var refetchCollection = useRefetchCollection();
    var queryClient = useQueryClient();
    var createOrUpdateTemplate = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (name, startTime, endTime, collectionDataSource, selectedSchemas, color, templateId) { return __awaiter(void 0, void 0, void 0, function () {
            var release, schemasToMutate, isCreatingNewTemplate, schemasToMutateTemp, schemaProperties, baseRequest, resp, request, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        release = snapshot.retain();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 6, 7]);
                        schemasToMutate = snapshot
                            .getLoadable(collectionTableSchemaMutationsState(collectionDataSource))
                            .valueMaybe();
                        if (!schemasToMutate) {
                            return [2 /*return*/];
                        }
                        isCreatingNewTemplate = !templateId;
                        schemasToMutateTemp = cloneDeep(schemasToMutate);
                        schemaProperties = schemasToMutateTemp.filter(function (schema) {
                            return selectedSchemas.includes(schema.schemaId);
                        });
                        baseRequest = {
                            name: name,
                            startTime: startTime,
                            endTime: endTime,
                            schemaProperties: schemaProperties,
                            color: color,
                        };
                        if (!isCreatingNewTemplate) return [3 /*break*/, 3];
                        return [4 /*yield*/, useCreateNewShiftTemplate.mutateAsync(baseRequest)];
                    case 2:
                        resp = _a.sent();
                        return [2 /*return*/, resp];
                    case 3:
                        request = {
                            templateId: templateId,
                            templateInfo: baseRequest,
                        };
                        return [4 /*yield*/, useUpdateShiftTemplate.mutateAsync(request)];
                    case 4:
                        resp = _a.sent();
                        return [2 /*return*/, resp];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        release();
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var applyShiftTemplate = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (templateId, toColKey, blockId, recordId) { return __awaiter(void 0, void 0, void 0, function () {
            var droppedDate, blockTimeZone, groupBy, request, currentGroupBy;
            return __generator(this, function (_a) {
                droppedDate = toColKey;
                blockTimeZone = snapshot
                    .getLoadable(scheduleTimeZoneSelector(blockId))
                    .valueMaybe();
                groupBy = snapshot.getLoadable(scheduleGroupBySelector(blockId)).valueMaybe();
                if (!blockTimeZone || !groupBy)
                    return [2 /*return*/];
                request = {
                    templateId: templateId,
                    applyToDate: droppedDate,
                    timezone: blockTimeZone,
                };
                if (recordId && recordId !== SCHEDULE_UNASSIGNED_KEY) {
                    currentGroupBy = groupBy;
                    switch (currentGroupBy) {
                        case ScheduleGroupBy.USER:
                            request.assigneeRid = recordId;
                            break;
                        case ScheduleGroupBy.LOCATION:
                            request.locationRid = recordId;
                            break;
                        case ScheduleGroupBy.ROLE:
                            request.roleRids = [recordId];
                            break;
                        default:
                            break;
                    }
                }
                useApplyShiftTemplate.mutate(request, {
                    onSuccess: function () {
                        var shiftCollectionId = snapshot
                            .getLoadable(scheduleCollectionIdState)
                            .valueMaybe();
                        if (shiftCollectionId) {
                            refetchCollection(shiftCollectionId);
                        }
                    },
                });
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refetchCollection]);
    var refetchShiftTemplates = useCallback(function () {
        queryClient.invalidateQueries(['allShiftTemplates']);
    }, [queryClient]);
    return {
        createOrUpdateTemplate: createOrUpdateTemplate,
        applyShiftTemplate: applyShiftTemplate,
        refetchShiftTemplates: refetchShiftTemplates,
    };
};
export { useShiftTemplate as useShiftTemplateComponent };
