var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DnDHandleIcon } from 'src/utils/icons/DnDHandleIcon';
import { EllipsisOutlined } from '@ant-design/icons';
import CustomIconInline from 'src/components/IconSelector/CustomIconInline';
import { Switch } from 'antd';
import { taskTemplateSelector } from '../selector';
import { useTasksComponent } from '../hooks/useTasks';
import TaskTemplateTypeTag from '../TasksSettingView/TaskTags/TaskTemplateTypeTag';
import TaskMenuPopover from './TaskMenuPopover';
import './TaskTemplateDragCard.scss';
var TaskTemplateDragCard = function (_a) {
    var _b;
    var taskId = _a.taskId, index = _a.index, onRemoveTask = _a.onRemoveTask, draggableProvided = _a.draggableProvided, isPreRequirement = _a.isPreRequirement, onToggleCountCompletion = _a.onToggleCountCompletion;
    var task = useRecoilValue(taskTemplateSelector(taskId));
    var viewTaskTemplate = useTasksComponent().viewTaskTemplate;
    return (_jsxs("div", __assign({ className: "flex items-center" }, draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.draggableProps, { ref: draggableProvided === null || draggableProvided === void 0 ? void 0 : draggableProvided.innerRef, children: [_jsx(DnDHandleIcon, __assign({ style: { cursor: 'grab' } }, draggableProvided.dragHandleProps, { className: "mr-[4px]" })), _jsxs("div", { className: "w-full flex justify-between border border-gray-60 p-[16px] rounded-lg items-center cursor-pointer bg-white", children: [task && (_jsx(CustomIconInline, { iconUrl: task.iconInfo.content, iconSize: 16, backgroundColor: task.iconInfo.backgroundColor, className: "!min-h-[32px] !min-w-[32px] mr-[12px] self-start MiniTaskIcon", radius: 9999 })), _jsxs("div", { className: "w-full flex flex-col items-start grow min-w-0", children: [_jsxs("div", { className: "flex items-center w-full", children: [_jsx("div", { className: "TaskItemTitle", children: (_b = task === null || task === void 0 ? void 0 : task.name) !== null && _b !== void 0 ? _b : 'Deleted Task' }), _jsxs("div", { className: "flex items-center ml-auto gap-2", children: [task && _jsx(TaskTemplateTypeTag, { type: task.type }), _jsx(TaskMenuPopover, { content: _jsxs(_Fragment, { children: [_jsx("div", { className: "TaskMenuItem", onClick: function () {
                                                                if (!task)
                                                                    return;
                                                                viewTaskTemplate(task.id);
                                                            }, children: "View Task" }), _jsx("div", { className: "TaskMenuItem text-red-1000", onClick: function () {
                                                                onRemoveTask(index);
                                                            }, children: "Remove Task" })] }), children: _jsx("div", { className: "rounded-md p-1 cursor-pointer hover:bg-gray-60 self-start mt-[-4px]", onClick: function (e) {
                                                        e.stopPropagation();
                                                    }, children: _jsx(EllipsisOutlined, { className: "text-[24px]" }) }) })] })] }), _jsxs("div", { className: "w-full flex justify-between mt-1", children: [_jsx("div", { className: "TaskItemSubtitle", children: task === null || task === void 0 ? void 0 : task.displayId }), onToggleCountCompletion && (_jsxs("div", { className: "flex", children: [_jsx("div", { children: "Pre-compliance" }), _jsx("div", { children: _jsx(Switch, { className: "m-0 self-start ml-3", checked: isPreRequirement, onChange: function () {
                                                        onToggleCountCompletion(taskId, !isPreRequirement);
                                                    } }) })] }))] })] })] })] })));
};
export default React.memo(TaskTemplateDragCard);
