import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { Input } from 'antd';
import { DeleteOutlinedIcon } from 'src/utils/icons/DropdownIcons/DeleteOutlined';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
var TimeOffTypeItem = function (props) {
    var _a = CompliancePageState.useContainer(), changeTimeOffTypeName = _a.changeTimeOffTypeName, deleteTimeOffType = _a.deleteTimeOffType;
    var type = props.type;
    var _b = useState(type.name), inputName = _b[0], setInputName = _b[1];
    var handleOnBlur = useCallback(function (e) {
        if (!e.target.value) {
            setInputName(type.name);
            changeTimeOffTypeName(type.id, type.name);
        }
        else {
            changeTimeOffTypeName(type.id, e.target.value);
        }
    }, [changeTimeOffTypeName, type.id, type.name]);
    return (_jsxs("div", { className: "TimeOffTypeItem", children: [_jsx(Input, { width: 300, className: "TimeOffTypeNameInput", placeholder: "Enter a name for type", value: inputName, onChange: function (e) { return setInputName(e.target.value); }, onBlur: handleOnBlur, onPressEnter: function (e) { return e.currentTarget.blur(); }, bordered: false }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to this time off type?", onConfirm: function () { return deleteTimeOffType(type.id); }, children: _jsx("div", { className: "ml-5 rounded-md p-1 hover:cursor-pointer hover:bg-gray-50", children: _jsx(DeleteOutlinedIcon, {}) }) })] }));
};
export default React.memo(TimeOffTypeItem);
