import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { CellInputMode, SelectionTypeSet } from 'src/shared';
import { collectionTableSchemaSelector } from 'src/state';
import './PlaceholderCell.scss';
var PlaceholderCell = function (_a) {
    var cellPos = _a.cellPos, canEdit = _a.canEdit, setIsClicked = _a.setIsClicked;
    var collectionId = cellPos.collectionId, schemaId = cellPos.schemaId;
    var schema = useRecoilValue(collectionTableSchemaSelector({ collectionId: collectionId, schemaId: schemaId }));
    var _b = schema !== null && schema !== void 0 ? schema : {}, name = _b.name, inputMode = _b.inputMode, type = _b.type;
    var isSelectType = (inputMode && [CellInputMode.SINGLE, CellInputMode.MULTI].includes(inputMode)) ||
        (type && SelectionTypeSet.has(type));
    var showLogicCell = useCallback(function () {
        if (canEdit) {
            setIsClicked(true);
        }
    }, [canEdit, setIsClicked]);
    if (!canEdit || !schema) {
        return _jsx("div", { className: "PlaceholderCell", children: "N/A" });
    }
    return (_jsx("div", { className: "PlaceholderCell", onClick: showLogicCell, children: isSelectType ? (_jsxs("div", { className: "flex h-[25px] w-max items-center rounded-sm bg-[#F7F7F8] px-2 text-[13px] font-medium not-italic text-gray-200", children: ["No ", name, " Selected"] })) : ("Enter ".concat(name)) }));
};
export default React.memo(PlaceholderCell);
