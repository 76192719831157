import { atom } from 'recoil';
var currentPageState = atom({
    key: 'pageFamilyState',
    default: undefined,
});
var currentPageBlockTab = atom({
    key: 'currentPageBlockTab',
    default: undefined,
});
export { currentPageState, currentPageBlockTab };
