import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ZiraDropdown from 'src/ui/ZiraDropdown';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import { reportSourceTypeSelector, reportTableState } from 'src/state';
import { useRecoilValue } from 'recoil';
import { capitalize } from 'lodash';
import { getReportCellDisplayValue } from 'src/services';
var ReportMoreOptionsDropdown = function (_a) {
    var reportId = _a.reportId, rowData = _a.rowData;
    var reportTable = useRecoilValue(reportTableState(reportId));
    var sourceType = useRecoilValue(reportSourceTypeSelector(reportId));
    var _b = useState(false), isDropdownVisible = _b[0], setIsDropdownVisible = _b[1];
    var _c = useState(), csvData = _c[0], setCsvData = _c[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var csvLink = useRef(null);
    var generateCsvData = useCallback(function () {
        if (!Object.keys(reportTable).length || !rowData.length)
            return [];
        var dataRows = [['Report Source'], ["".concat(capitalize(sourceType), "s")]];
        var outerColumns = reportTable.dateColumns.length ? reportTable.dateColumns : ['Metrics'];
        for (var _i = 0, outerColumns_1 = outerColumns; _i < outerColumns_1.length; _i++) {
            var outerColumn = outerColumns_1[_i];
            for (var _a = 0, _b = reportTable.metricColumns; _a < _b.length; _a++) {
                var metricColumn = _b[_a];
                dataRows[0].push(outerColumn);
                dataRows[1].push(metricColumn.label);
            }
        }
        rowData.forEach(function (row, rowIndex) {
            dataRows[rowIndex + 2] = [];
            for (var _i = 0, _a = Object.keys(row); _i < _a.length; _i++) {
                var columnId = _a[_i];
                dataRows[rowIndex + 2].push(String(getReportCellDisplayValue(row[columnId])));
            }
        });
        return dataRows;
    }, [reportTable, rowData, sourceType]);
    useEffect(function () {
        if (csvLink.current && csvData) {
            csvLink.current.link.click();
            setCsvData(undefined);
        }
    }, [csvData]);
    var dropdownMenu = useMemo(function () {
        return {
            items: [
                {
                    key: 'export',
                    label: 'Export Table as CSV',
                    onClick: function () { return setCsvData(generateCsvData()); },
                },
            ],
        };
    }, [generateCsvData]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraDropdown, { className: "ml-2", menu: dropdownMenu, open: isDropdownVisible, trigger: ['click'], onLocalVisibleChange: function (visible) {
                    setIsDropdownVisible(visible);
                }, children: _jsx(Button, { className: "bg-white", children: "Export" }) }), csvData && (_jsx(CSVLink, { ref: csvLink, data: csvData, filename: "".concat(capitalize(sourceType), "s Report") }))] }));
};
export default React.memo(ReportMoreOptionsDropdown);
