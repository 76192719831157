import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Button } from 'antd'; // Assuming you're using Ant Design
import { PlusOutlined } from '@ant-design/icons'; // Assuming you're using Ant Design icons
import { useMemo } from 'react';
import { LanguageDisplayMap } from './interface';
import LanguageItem from './LanguageItem';
import { getDropdownMenuItems } from './util';
var LanguageList = function (_a) {
    var _b;
    var localizationSettings = _a.localizationSettings, setLocalizationSettings = _a.setLocalizationSettings;
    var dropdownMenuItems = useMemo(function () { return getDropdownMenuItems(localizationSettings, setLocalizationSettings); }, [localizationSettings, setLocalizationSettings]);
    return (_jsxs("div", { className: "mt-8 text-sm", children: [_jsx("div", { className: "font-semibold", children: "Supported Languages" }), _jsx("div", { className: "text-gray-400 mt-2", children: "Choose which languages you would like to support in TeamBridge. Note these will show when the device settings are mapped to the language code listed below." }), _jsx("div", { className: "section mb-4", children: (_b = localizationSettings === null || localizationSettings === void 0 ? void 0 : localizationSettings.accountLanguages) === null || _b === void 0 ? void 0 : _b.map(function (language) { return (_jsx(LanguageItem, { type: language, name: LanguageDisplayMap[language] || language, localizationSettings: localizationSettings, setLocalizationSettings: setLocalizationSettings }, language)); }) }), _jsx(Dropdown, { menu: { items: dropdownMenuItems }, trigger: ['click'], overlayClassName: "NewPageDropdownMenu", children: _jsx(Button, { className: "AddLanguageButton", icon: _jsx(PlusOutlined, { className: "text-black text-sm stroke-black mb-[2px] stroke-2" }), children: "Add Language" }) })] }));
};
export default LanguageList;
