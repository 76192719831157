import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Input, Tooltip } from 'antd';
import { TrashOutlinedIcon } from 'src/utils/icons/DropdownIcons/TrashOutlined';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import { DuplicateOutlinedIcon } from 'src/utils/icons/DropdownIcons/DuplicateOutlined';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CompliancePageState } from '../../../../hooks/state/Compliance';
import TimeOffRule from './TimeOffRule';
import './TimeOffPolicy.scss';
var TimeOffPolicy = function (props) {
    var _a = CompliancePageState.useContainer(), timeOffTypes = _a.timeOffTypes, deleteTimeOffPolicy = _a.deleteTimeOffPolicy, changeTimeOffPolicyName = _a.changeTimeOffPolicyName, duplicateTimeOffPolicy = _a.duplicateTimeOffPolicy, setPolicyAsDefault = _a.setPolicyAsDefault;
    var policy = props.policy;
    var generateTimeOffRule = useCallback(function (type) {
        var foundRule = policy.rules.find(function (rule) { return rule.typeId === type.id; });
        return _jsx(TimeOffRule, { policy: policy, type: type, rule: foundRule });
    }, [policy]);
    var defaultPolicyTooltip = (_jsx(Tooltip, { title: _jsx("div", { className: "text-[13px] font-medium", children: "Users without any policy assigned will request time off with a default policy" }), color: "black", placement: "bottom", overlayClassName: "HelpArticleUrlTooltip", children: _jsx(QuestionCircleOutlined, {}) }));
    return (_jsxs("div", { className: "rounded-md bg-gray-50 px-4 pb-7 pt-4 my-5 font-medium", children: [_jsxs("div", { className: "flex pb-4 pr-2 items-center", children: [_jsx(Input, { className: "PolicyNameInput", placeholder: "Enter a name for this group", value: policy.policyName, onChange: function (e) { return changeTimeOffPolicyName(policy.policyId, e.target.value); } }), policy.isDefault ? (_jsx("div", { className: "rounded bg-purple-200 mr-2 px-3 py-1 text-purple-900", children: "Default" })) : (_jsxs("div", { className: "w-[150px] cursor-pointer flex items-center gap-x-1 mr-1", onClick: function () { return setPolicyAsDefault(policy.policyId); }, children: ["Make Default", defaultPolicyTooltip] })), _jsx("div", { className: "scale-[120%] cursor-pointer", title: "Duplicate policy", onClick: function () { return duplicateTimeOffPolicy(policy.policyId); }, children: _jsx(DuplicateOutlinedIcon, {}) }), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this policy?", onConfirm: function () { return deleteTimeOffPolicy(policy.policyId); }, placement: "bottom", children: _jsx("div", { className: "GroupRuleDeleteBtn hover:bg-gray-50", children: _jsx(TrashOutlinedIcon, {}) }) })] }), _jsx("div", { className: "PolicyWrapper", children: timeOffTypes.map(function (type) { return generateTimeOffRule(type); }) })] }));
};
export default React.memo(TimeOffPolicy);
