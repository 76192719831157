var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var NaviBarAddButtonSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 23 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.658203", y: "0.462891", width: "22", height: "22", rx: "8", fill: "#F4F4F7" }), _jsx("path", { d: "M4.99219 11.1292C4.99219 10.9451 5.14143 10.7959 5.32552 10.7959H17.9922C18.1763 10.7959 18.3255 10.9451 18.3255 11.1292V11.7959C18.3255 11.98 18.1763 12.1292 17.9922 12.1292H5.32552C5.14143 12.1292 4.99219 11.98 4.99219 11.7959V11.1292Z", fill: "#A7A8AC" }), _jsx("path", { d: "M10.9922 5.12923C10.9922 4.94514 11.1414 4.7959 11.3255 4.7959H11.9922C12.1763 4.7959 12.3255 4.94514 12.3255 5.12923V17.7959C12.3255 17.98 12.1763 18.1292 11.9922 18.1292H11.3255C11.1414 18.1292 10.9922 17.98 10.9922 17.7959V5.12923Z", fill: "#A7A8AC" })] })); };
export var NaviBarAddButton = function (props) { return (_jsx(Icon, __assign({ component: NaviBarAddButtonSvg }, props))); };
