var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var UsersSvg = function () { return (_jsxs("svg", { width: "100%", height: "100%", viewBox: "0 0 24 24", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M10.0003 2.03098C9.83637 2.01053 9.66942 2 9.5 2C7.29086 2 5.5 3.79086 5.5 6C5.5 8.20914 7.29086 10 9.5 10C9.66942 10 9.83637 9.98947 10.0003 9.96902C9.46795 9.366 9.05497 8.65515 8.79827 7.87343C8.03984 7.58922 7.5 6.85765 7.5 6C7.5 5.14235 8.03984 4.41078 8.79827 4.12657C9.05497 3.34485 9.46795 2.634 10.0003 2.03098Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.5 8C15.6046 8 16.5 7.10457 16.5 6C16.5 4.89543 15.6046 4 14.5 4C13.3954 4 12.5 4.89543 12.5 6C12.5 7.10457 13.3954 8 14.5 8ZM14.5 10C16.7091 10 18.5 8.20914 18.5 6C18.5 3.79086 16.7091 2 14.5 2C12.2909 2 10.5 3.79086 10.5 6C10.5 8.20914 12.2909 10 14.5 10Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17 14H12C10.3431 14 9 15.3431 9 17V20H20V17C20 15.3431 18.6569 14 17 14ZM12 12C9.23858 12 7 14.2386 7 17V21.5C7 21.7761 7.22386 22 7.5 22H21.5C21.7761 22 22 21.7761 22 21.5V17C22 14.2386 19.7614 12 17 12H12Z" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.10102 12H7C4.23858 12 2 14.2386 2 17V21.5C2 21.7761 2.22386 22 2.5 22H5V20H4V17C4 15.8905 4.60226 14.9217 5.49769 14.4027C5.86189 13.4917 6.41196 12.6752 7.10102 12Z" })] })); };
export var UsersIcon = function (props) { return (_jsx(Icon, __assign({ component: UsersSvg }, props))); };
