var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { SchemaPermissionLevelOptions } from 'src/components/Settings/Permission/Constants';
import RecipeTextWrapper from '../RecipeTextWrapper';
var RecipeSummaryPermissionChanges = function (_a) {
    var permissionChanges = _a.permissionChanges;
    var allAccessGroups = permissionChanges.allAccessGroups, _b = permissionChanges.allRecipeSchemas, allRecipeSchemas = _b === void 0 ? [] : _b, _c = permissionChanges.permissionChanges, changes = _c === void 0 ? [] : _c;
    var accessGroupMap = allAccessGroups.reduce(function (acc, accessGroup) {
        var _a;
        return __assign(__assign({}, acc), (_a = {}, _a[accessGroup.id] = accessGroup, _a));
    }, {});
    var recipeSchemaMap = allRecipeSchemas.reduce(function (acc, schema) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[schema.schemaId] = schema, _a)));
    }, {});
    return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "pb-[8px] text-base font-medium", children: "Permission Changes" }), _jsx("div", { className: "flex space-x-[16px]", children: changes.map(function (_a, index) {
                    var applicableAccessGroupIds = _a.applicableAccessGroupIds, schemaPermissions = _a.schemaPermissions, mobilePermissions = _a.mobilePermissions;
                    return (_jsxs("div", { className: "flex w-[400px] flex-col justify-center space-y-[16px] rounded-xl border-2 border-azure-50 p-[16px] text-sm font-medium", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Access Group" }), applicableAccessGroupIds.map(function (accessGroupId) {
                                        return (_jsx(RecipeTextWrapper, { defaultColor: true, children: accessGroupMap[accessGroupId].name }, accessGroupId));
                                    })] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: "Data" }), _jsx("div", { className: "flex flex-col space-y-[8px]", children: Object.values(schemaPermissions).map(function (permissionLevel, permissionIndex) {
                                            var schema = recipeSchemaMap[Object.keys(schemaPermissions)[permissionIndex]];
                                            if (!schema)
                                                return null;
                                            return (_jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "text-xs text-gray-300", children: schema.schemaName }), _jsx(RecipeTextWrapper, { defaultColor: true, children: SchemaPermissionLevelOptions(permissionLevel) })] }, "".concat(schema.schemaName, "-").concat(permissionIndex)));
                                        }) })] }), _jsx("div", { className: "flex flex-col", children: (mobilePermissions.requiredFieldsSchemaIds ||
                                    mobilePermissions.optionalFieldsSchemaIds) && (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-xs text-gray-300", children: "Mobile" }), _jsxs("div", { className: "flex flex-col space-y-[8px]", children: [!!mobilePermissions.requiredFieldsSchemaIds
                                                    .length && (_jsxs("div", { className: "flex flex-col gap-[8px]", children: [_jsx("div", { children: "Required Fields" }), _jsx("div", { className: "flex gap-[8px]", children: mobilePermissions.requiredFieldsSchemaIds.map(function (schemaId) {
                                                                return (_jsx(RecipeTextWrapper, { defaultColor: true, children: recipeSchemaMap[schemaId].schemaName }, schemaId));
                                                            }) })] })), !!mobilePermissions.optionalFieldsSchemaIds
                                                    .length && (_jsxs("div", { className: "flex flex-col gap-[8px]", children: [_jsx("div", { children: "Optional Fields" }), _jsx("div", { className: "flex gap-[8px]", children: mobilePermissions.optionalFieldsSchemaIds.map(function (schemaId) {
                                                                return (_jsx(RecipeTextWrapper, { defaultColor: true, children: recipeSchemaMap[schemaId].schemaName }, schemaId));
                                                            }) })] }))] })] })) })] }, index));
                }) })] }));
};
export default React.memo(RecipeSummaryPermissionChanges);
