import { jsx as _jsx } from "react/jsx-runtime";
import { CaretRightOutlined } from '@ant-design/icons';
import React from 'react';
import { useRecoilState } from 'recoil';
import { threadSectionExpandedStatusState } from 'src/state/atoms/chatState';
var ThreadSectionExpandActionButton = function (_a) {
    var type = _a.type;
    var _b = useRecoilState(threadSectionExpandedStatusState(type)), isExpanded = _b[0], setIsExpanded = _b[1];
    return (_jsx(CaretRightOutlined, { style: {
            transform: isExpanded ? 'rotate(90deg)' : undefined,
        }, className: "mx-[2px] rounded-lg p-[4px] transition-all duration-200 hover:bg-gray-60", onClick: function () {
            var newStatus = !isExpanded;
            setIsExpanded(newStatus);
        } }));
};
export default React.memo(ThreadSectionExpandActionButton);
