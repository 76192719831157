import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import SchemaMappingStep from './SyncStepsPopupModal/SchemaMappingStep';
import SyncSettingStep from './SyncStepsPopupModal/SyncSettingStep';
import IpCollectionStep from './SyncStepsPopupModal/IpCollectionStep';
import '../../../App.less';
import './SyncStepsContainer.scss';
import IpUserMatching from './IpUserMatching';
var useStepIndex = function () {
    var _a = useState(0), stepIndex = _a[0], setStepIndex = _a[1];
    var _b = useState(''), selectedIpCollection = _b[0], setSelectedIpCollection = _b[1];
    var _c = useState(''), selectedZiraCollectionId = _c[0], setSelectedZiraCollectionId = _c[1];
    var _d = useState(''), selectedPK = _d[0], setSelectedPK = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var goNextStep = function () { return setStepIndex(function (prevIndex) { return prevIndex + 1; }); };
    var goPrevStep = function () { return setStepIndex(function (prevIndex) { return prevIndex - 1; }); };
    return {
        stepIndex: stepIndex,
        selectedIpCollection: selectedIpCollection,
        selectedZiraCollectionId: selectedZiraCollectionId,
        selectedPK: selectedPK,
        isLoading: isLoading,
        goNextStep: goNextStep,
        goPrevStep: goPrevStep,
        setSelectedIpCollection: setSelectedIpCollection,
        setSelectedZiraCollectionId: setSelectedZiraCollectionId,
        setSelectedPK: setSelectedPK,
        setStepIndex: setStepIndex,
        setIsLoading: setIsLoading,
    };
};
export var IntegrationStepsState = createContainer(useStepIndex);
var SyncStepsContainer = function (_a) {
    var onIntegrationModalClose = _a.onIntegrationModalClose;
    var _b = IntegrationStepsState.useContainer(), stepIndex = _b.stepIndex, setStepIndex = _b.setStepIndex;
    //set up broser popup alert when user try to close window
    useEffect(function () {
        window.addEventListener('beforeunload', function (e) {
            e.preventDefault();
            e.returnValue = '';
        });
        return function () {
            window.removeEventListener('beforeunload', function (e) {
                e.preventDefault();
                e.returnValue = '';
            });
        };
    }, []);
    var isModalVisible = useCallback(function (visible) {
        if (!visible) {
            setStepIndex(0);
            onIntegrationModalClose();
        }
    }, [onIntegrationModalClose, setStepIndex]);
    if (stepIndex === 0) {
        return _jsx(IpCollectionStep, { onIntegrationModalClose: onIntegrationModalClose });
    }
    if (stepIndex === 1) {
        return _jsx(SyncSettingStep, {});
    }
    if (stepIndex === 2) {
        return _jsx(SchemaMappingStep, {});
    }
    return _jsx(IpUserMatching, { isModalVisible: isModalVisible });
};
export default SyncStepsContainer;
