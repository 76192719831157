import { useMutation } from 'react-query';
import { useFetch } from '.';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useFormulaApi = function () {
    var customFetch = useFetch().request;
    var useUpdateFormula = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'formulas/update',
            }, request);
        });
    };
    var getFormulaById = function (formulaId) {
        return customFetch({
            method: 'GET',
            url: "formulas/get/".concat(formulaId),
        });
    };
    return {
        useUpdateFormula: useUpdateFormula,
        getFormulaById: getFormulaById,
    };
};
export { useFormulaApi };
