import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { InputNumber } from 'antd';
var RatioNumberInput = function (_a) {
    var value = _a.value, updateRatioNumber = _a.updateRatioNumber;
    var _b = useState(value), inputNumber = _b[0], setInputNumber = _b[1];
    var inputRef = useRef(null);
    useEffect(function () { return setInputNumber(value); }, [value]);
    return (_jsx(InputNumber, { className: "RatioNumberInput", ref: inputRef, value: inputNumber, onChange: function (value) {
            if (typeof value === 'number') {
                setInputNumber(value);
            }
        }, onFocus: function (e) { return e.target.select(); }, onPressEnter: function () {
            var _a;
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        }, onBlur: function () {
            var fixedInputNumber = Number(inputNumber.toFixed(2));
            updateRatioNumber(fixedInputNumber);
        }, min: 0 }));
};
export default React.memo(RatioNumberInput);
