var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var InAppMsgSvg = function () { return (_jsxs("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 4H8V20H16V4ZM6.5 2C6.22386 2 6 2.22386 6 2.5V21.5C6 21.7761 6.22386 22 6.5 22H17.5C17.7761 22 18 21.7761 18 21.5V2.5C18 2.22386 17.7761 2 17.5 2H6.5Z", fill: "#2F4DDF" }), _jsx("path", { d: "M10 3.5C10 3.22386 10.2239 3 10.5 3H13.5C13.7761 3 14 3.22386 14 3.5V4.5C14 4.77614 13.7761 5 13.5 5H10.5C10.2239 5 10 4.77614 10 4.5V3.5Z", fill: "#2F4DDF" }), _jsx("path", { d: "M11 17.5C11 17.2239 11.2239 17 11.5 17H12.5C12.7761 17 13 17.2239 13 17.5V18.5C13 18.7761 12.7761 19 12.5 19H11.5C11.2239 19 11 18.7761 11 18.5V17.5Z", fill: "#2F4DDF" })] })); };
export var InAppMsgIcon = function (props) { return (_jsx(Icon, __assign({ component: InAppMsgSvg }, props))); };
