var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilCallback } from 'recoil';
import { collectionTableRecordState } from 'src/state';
var AddFieldDropdown = function (_a) {
    var availableSchemas = _a.availableSchemas, selectedSchemaIds = _a.selectedSchemaIds, recordData = _a.recordData, selectedCollectionId = _a.selectedCollectionId, dataSource = _a.dataSource, dataSourceRid = _a.dataSourceRid, onEditModalCell = _a.onEditModalCell, onAddNewTaskSchema = _a.onAddNewTaskSchema;
    var onAddColumn = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (schemaId) {
            if (!recordData || !selectedCollectionId)
                return;
            // Add to rendered list
            onAddNewTaskSchema(schemaId);
            // Initialize empty cell state for new column
            var newRecordData = cloneDeep(recordData);
            newRecordData[schemaId] = {
                content: null,
                options: [],
                files: [],
                versionedOptions: [],
            };
            set(collectionTableRecordState(__assign(__assign({}, dataSource), { recordId: dataSourceRid })), newRecordData);
            onEditModalCell({
                collectionId: selectedCollectionId,
                recordId: dataSourceRid,
                schemaId: schemaId,
            }, []);
        };
    }, [
        dataSource,
        dataSourceRid,
        onAddNewTaskSchema,
        onEditModalCell,
        recordData,
        selectedCollectionId,
    ]);
    var dropdownMenuItems = useMemo(function () {
        return availableSchemas.map(function (schema) {
            return {
                key: schema.id,
                label: schema.name,
                onClick: function () { return onAddColumn(schema.id); },
                disabled: selectedSchemaIds.includes(schema.id),
            };
        });
    }, [availableSchemas, onAddColumn, selectedSchemaIds]);
    return (_jsx(Dropdown, { menu: { items: dropdownMenuItems }, trigger: ['click'], overlayClassName: "CollectionSchemaDropdownMenu", children: _jsx(Button, { className: "AddNewCollectionColumnButton", icon: _jsx(PlusOutlined, { style: {
                    color: 'black',
                    fontSize: '14px',
                    stroke: 'black',
                    strokeWidth: '10px',
                    marginBottom: 2,
                } }), children: "Add Field" }) }));
};
export default React.memo(AddFieldDropdown);
