import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { MobilePreviewImagePlaceholder } from 'src/utils/icons/TaskIcons/MobilePreviewImagePlaceholder';
import { taskEditorActiveTabState } from '../atom';
var TaskEmptyMobilePreview = function () {
    var type = useRecoilValue(taskEditorActiveTabState).toLowerCase();
    return (_jsxs("div", { className: "flex flex-col items-center justify-center h-[60vh]", children: [_jsx(MobilePreviewImagePlaceholder, {}), _jsxs("div", { className: "mt-3 text-gray-500 p-2", children: ["You will see a mobile preview here once you start adding ", type, " and configuring actions."] })] }));
};
export default React.memo(TaskEmptyMobilePreview);
