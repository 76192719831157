var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import { ScheduleGroupBy, SCHEDULE_UNASSIGNED_KEY } from 'src/shared';
import { collectionOptionsState, scheduleDropTargetState, scheduleGroupBySelector, scheduleTimeRangeState, scheduleTimeZoneSelector, selectedShiftsState, } from 'src/state';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { getGroupByCollectionName, ScheduleView } from 'src/services';
import { useScheduleComponent, useWindowSize } from 'src/hooks/component';
import { usePageApi } from 'src/hooks/api';
import { useRefetchCollection } from 'src/hooks/component/useRefetchCollection';
import Column from 'antd/lib/table/Column';
import ShiftCardDiv from '../../ScheduleCard/ShiftCardDiv';
import ScheduleCellWrapper from '../../ScheduleCard/ScheduleCellWrapper';
import EmptyCardWrapper from '../../ScheduleCard/EmptyCard/EmptyCardWrapper';
import { MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, VIRTUAL_LIST_ON_CARD_COUNT, WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, } from '../../Constant';
import ScheduleGroupByRowTitle from './ScheduleGroupByRowTitle';
import ScheduleANTDTableBuilder from './ScheduleANTDTableBuilder';
import ScheduleWeekDateTitle from './ScheduleWeekDateTitle';
var ScheduleWeekViewTable = function (_a) {
    var collectionId = _a.collectionId, scheduleView = _a.scheduleView, tableId = _a.tableId, dataSource = _a.dataSource, isLoading = _a.isLoading, tableScrollHeight = _a.tableScrollHeight, tableScrollDiv = _a.tableScrollDiv, addNewShift = _a.addNewShift, onApplyTemplate = _a.onApplyTemplate, onApplyOldTemplate = _a.onApplyOldTemplate, openNewOrBulkShiftDetailModal = _a.openNewOrBulkShiftDetailModal, openShiftDetailModal = _a.openShiftDetailModal;
    var useDeleteCollectionRecords = usePageApi().useDeleteCollectionRecords;
    var _b = useScheduleComponent(), dragShiftByDefault = _b.dragShiftByDefault, getNewShiftDefaults = _b.getNewShiftDefaults, pasteShiftByDefault = _b.pasteShiftByDefault, getBulkEditInfo = _b.getBulkEditInfo;
    var timeRange = useRecoilValue(scheduleTimeRangeState(tableId));
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(tableId));
    var collectionOptions = useRecoilValue(collectionOptionsState);
    var groupBy = useRecoilValue(scheduleGroupBySelector(tableId));
    var dragAndDropShift = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (draggedShift, columnKey) {
            var release = snapshot.retain();
            try {
                var dropTarget = snapshot
                    .getLoadable(scheduleDropTargetState(tableId))
                    .valueMaybe();
                if (!dropTarget)
                    return;
                dragShiftByDefault(tableId, draggedShift, columnKey, dropTarget.columnKey, dropTarget.recordKey, dataSource);
            }
            finally {
                release();
            }
        };
    }, [tableId, dataSource, dragShiftByDefault]);
    var createNewShift = useCallback(function (colKey, groupByObj) { return __awaiter(void 0, void 0, void 0, function () {
        var defaultStartTime, defaultEndTime, schemaDefaults;
        return __generator(this, function (_a) {
            defaultStartTime = moment
                .tz(colKey, timeZone)
                .startOf('day')
                .add(9, 'hours')
                .toISOString();
            defaultEndTime = moment
                .tz(colKey, timeZone)
                .startOf('day')
                .add(17, 'hours')
                .toISOString();
            schemaDefaults = getNewShiftDefaults(defaultStartTime, defaultEndTime, tableId, groupByObj);
            if (!schemaDefaults)
                return [2 /*return*/];
            addNewShift(schemaDefaults);
            return [2 /*return*/];
        });
    }); }, [addNewShift, tableId, getNewShiftDefaults, timeZone]);
    var refetchCollection = useRefetchCollection();
    var deleteRecord = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot, set = _a.set;
        return function (recordId) { return __awaiter(void 0, void 0, void 0, function () {
            var selectedShifts, recordIds;
            return __generator(this, function (_a) {
                if (!collectionId)
                    return [2 /*return*/];
                selectedShifts = snapshot
                    .getLoadable(selectedShiftsState(tableId))
                    .valueMaybe();
                recordIds = (selectedShifts === null || selectedShifts === void 0 ? void 0 : selectedShifts.includes(recordId)) ? selectedShifts : [recordId];
                useDeleteCollectionRecords.mutate({ collectionId: collectionId, recordIds: recordIds }, {
                    onSuccess: function () {
                        refetchCollection(collectionId);
                        if (selectedShifts === null || selectedShifts === void 0 ? void 0 : selectedShifts.includes(recordId)) {
                            set(selectedShiftsState(tableId), []);
                        }
                    },
                });
                return [2 /*return*/];
            });
        }); };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableId, collectionId]);
    var onPasteShift = useCallback(function (momentString, rowKey) {
        pasteShiftByDefault(tableId, momentString, rowKey, dataSource);
    }, [tableId, dataSource, pasteShiftByDefault]);
    // const scheduleRecordCell = useCallback(
    //     (time: string, groupByRow: ScheduleTableRowEntry, recordIds: string[]) => {
    //         const momentString = moment(time).format('YYYY-MM-DD')
    //         const tempGroupbyObj: CellOption = {
    //             id: groupByRow.rowKey,
    //             name: groupByRow.title,
    //             avatarUrl: groupByRow.avatarUrl,
    //         }
    //         const indexedObj =
    //             groupByRow.rowKey === SCHEDULE_UNASSIGNED_KEY ? undefined : tempGroupbyObj
    //         return (
    //             <ShiftCardDiv
    //                 blockId={blockId}
    //                 columnKey={momentString}
    //                 shiftRIds={recordIds}
    //                 openShiftDetailModal={async (isMulti, recordId) => {
    //                     if (!isMulti) {
    //                         openShiftDetailModal(recordId)
    //                     } else {
    //                         const { bulkEditModalData, recordIds } =
    //                             (await getBulkEditInfo(blockId)) ?? {}
    //                         if (bulkEditModalData && recordIds?.length) {
    //                             const tempBulkRecordId = bulkEditModalData.rows[0].recordId
    //                             openNewOrBulkShiftDetailModal(
    //                                 blockId,
    //                                 tempBulkRecordId,
    //                                 { mainRecord: bulkEditModalData },
    //                                 {
    //                                     recordIds: recordIds,
    //                                 }
    //                             )
    //                         }
    //                     }
    //                 }}
    //                 deleteRecord={deleteRecord}
    //                 onDragCard={dragAndDropShift}
    //                 onSaveAsTemplate={() => {
    //                     // setTemplateConfirmVisible(true)
    //                 }}
    //                 createNewShift={() => createNewShift(momentString, indexedObj)}
    //                 pasteShift={async () => {
    //                     onPasteShift(momentString, groupByRow.rowKey)
    //                 }}
    //             />
    //         )
    //     },
    //     [
    //         blockId,
    //         createNewShift,
    //         deleteRecord,
    //         dragAndDropShift,
    //         getBulkEditInfo,
    //         onPasteShift,
    //         openNewOrBulkShiftDetailModal,
    //         openShiftDetailModal,
    //     ]
    // )
    // const scheduleEmptyCell = useCallback(
    //     (time: string, groupByRow: ScheduleTableRowEntry) => {
    //         const momentString = moment(time).format('YYYY-MM-DD')
    //         const tempGroupbyObj: CellOption = {
    //             id: groupByRow.rowKey,
    //             name: groupByRow.title,
    //             avatarUrl: groupByRow.avatarUrl,
    //         }
    //         const indexedObj =
    //             groupByRow.rowKey === SCHEDULE_UNASSIGNED_KEY ? undefined : tempGroupbyObj
    //         return (
    //             <EmptyCardWrapper
    //                 blockId={blockId}
    //                 createNewShift={() => createNewShift(momentString, indexedObj)}
    //                 pasteShift={async () => {
    //                     onPasteShift(momentString, groupByRow.rowKey)
    //                 }}
    //                 onApplyTemplate={(template) =>
    //                     onApplyTemplate(template, momentString, groupByRow.rowKey)
    //                 }
    //             />
    //         )
    //     },
    //     [blockId, createNewShift, onApplyTemplate, onPasteShift]
    // )
    // const columns = useMemo(() => {
    //     const columns: (ReactTableColumn<Record<string, ScheduleCellPos>> & {
    //         sticky?: 'left' | 'right'
    //     })[] = []
    //     // Group by title column
    //     columns.push({
    //         id: 'groupBy',
    //         accessor: 'groupBy',
    //         Header: <></>,
    //         sticky: 'left',
    //         minWidth: 240,
    //         Cell: ({ value }: { value: ScheduleCellPos }) => {
    //             if (value.hideGroupBy) return <></>
    //             return <ScheduleGroupByRowTitle blockId={blockId} groupByRow={value.groupByRow} />
    //         },
    //     })
    //     // Date columns (shift cells)
    //     timeRangeColumns.forEach((time, colIndex) => {
    //         const timeKey = moment(time).format('YYYY-MM-DD')
    //         const isToday = moment(time).isSame(new Date(), 'day')
    //         columns.push({
    //             id: timeKey,
    //             accessor: timeKey,
    //             Header: dateString(moment(time), isToday),
    //             minWidth: scheduleView !== ScheduleView.WEEK ? 60 : 130,
    //             Cell: ({ value }: { value: ScheduleCellPos }) => {
    //                 const { groupByRow, groupByRowIndex, recordIds } = value
    //                 return (
    //                     <div className="ShiftCard">
    //                         <ShiftCardIndictmentBadge
    //                             blockId={blockId}
    //                             recordId={dataSource[0][timeKey][0]}
    //                         />
    //                         <ShiftCardDisplayTime
    //                             blockId={blockId}
    //                             recordId={dataSource[0][timeKey][0]}
    //                         />
    //                         <div className="OpenCountIndicator">{5}</div>
    //                     </div>
    //                 )
    //                 return (
    //                     <ScheduleCellWrapper
    //                         recordKey={groupByRow.rowKey}
    //                         recordIndex={groupByRowIndex}
    //                         columnKey={timeKey}
    //                         columnIndex={colIndex}
    //                         blockId={blockId}
    //                     >
    //                         <div className="ShiftCard">ShiftCard</div>
    //                     </ScheduleCellWrapper>
    //                 )
    //                 return (
    //                     <ScheduleCellWrapper
    //                         recordKey={groupByRow.rowKey}
    //                         recordIndex={groupByRowIndex}
    //                         columnKey={timeKey}
    //                         columnIndex={colIndex}
    //                         blockId={blockId}
    //                     >
    //                         {recordIds?.length
    //                             ? scheduleRecordCell(time, groupByRow, recordIds)
    //                             : scheduleEmptyCell(time, groupByRow)}
    //                     </ScheduleCellWrapper>
    //                 )
    //             },
    //         })
    //     })
    //     return columns
    // }, [
    //     blockId,
    //     dataSource,
    //     dateString,
    //     scheduleEmptyCell,
    //     scheduleRecordCell,
    //     scheduleView,
    //     timeRangeColumns,
    // ])
    // const data = useMemo(() => {
    //     const data: Record<string, ScheduleCellPos>[] = []
    //     scheduleTableRows.forEach((_, groupByRowIndex) => {
    //         const dataSourceRow = dataSource[groupByRowIndex]
    //         const { groupByRow } = dataSourceRow
    //         // for (let cellIndex = 0; cellIndex < maxCells; cellIndex++) {
    //         const scheduleRow: Record<string, ScheduleCellPos> = {}
    //         // Group by cell
    //         scheduleRow['groupBy'] = {
    //             groupByRow,
    //             groupByRowIndex,
    //         }
    //         timeRangeColumns.forEach((time) => {
    //             const timeKey = moment(time).format('YYYY-MM-DD')
    //             const recordIds = dataSourceRow[timeKey]
    //             // Schedule cell
    //             scheduleRow[timeKey] = {
    //                 groupByRow,
    //                 groupByRowIndex,
    //                 recordIds,
    //             }
    //         })
    //         data.push(scheduleRow)
    //         // }
    //     })
    //     return data
    //     //!IMPORTANT - depends on dataSource, scheduleTableRows, timeRangeColumns only
    // }, [dataSource, scheduleTableRows, timeRangeColumns])
    var scheduleCellChildren = useCallback(function (time, record) {
        var _a;
        var groupByRow = record.groupByRow, maxCells = record.maxCells;
        var momentString = moment(time).format('YYYY-MM-DD');
        var tempGroupbyObj = {
            id: groupByRow.rowKey,
            name: groupByRow.title,
            avatarUrl: groupByRow.avatarUrl,
        };
        var indexedObj = groupByRow.rowKey === SCHEDULE_UNASSIGNED_KEY ? undefined : tempGroupbyObj;
        var recordIds = record[momentString];
        var groupByRecordId = (_a = (record.groupByRow.rowKey === SCHEDULE_UNASSIGNED_KEY
            ? undefined
            : record.groupByRow.rowKey)) !== null && _a !== void 0 ? _a : '';
        if (recordIds.length) {
            return (_jsxs(_Fragment, { children: [_jsx(ShiftCardDiv, { blockId: tableId, collectionId: collectionId, openShiftDetailModal: function (isMulti, recordId) { return __awaiter(void 0, void 0, void 0, function () {
                            var _a, bulkEditModalData, recordIds_1, tempBulkRecordId;
                            var _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        if (!!isMulti) return [3 /*break*/, 1];
                                        openShiftDetailModal(recordId);
                                        return [3 /*break*/, 3];
                                    case 1: return [4 /*yield*/, getBulkEditInfo(tableId)];
                                    case 2:
                                        _a = (_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}, bulkEditModalData = _a.bulkEditModalData, recordIds_1 = _a.recordIds;
                                        if (bulkEditModalData && (recordIds_1 === null || recordIds_1 === void 0 ? void 0 : recordIds_1.length)) {
                                            tempBulkRecordId = bulkEditModalData.rows[0].recordId;
                                            openNewOrBulkShiftDetailModal(tableId, tempBulkRecordId, { mainRecord: bulkEditModalData }, {
                                                recordIds: recordIds_1,
                                            });
                                        }
                                        _c.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }, deleteRecord: deleteRecord, columnKey: momentString, shiftRIds: record[momentString], onDragCard: dragAndDropShift, createNewShift: function () { return createNewShift(momentString, indexedObj); }, pasteShift: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                onPasteShift(momentString, groupByRow.rowKey);
                                return [2 /*return*/];
                            });
                        }); }, onApplyTemplate: function (template) {
                            if ('color' in template) {
                                onApplyTemplate(template, momentString, groupByRow.rowKey);
                            }
                            else {
                                onApplyOldTemplate(template, momentString, groupByRow.rowKey);
                            }
                        }, groupByRecordId: groupByRecordId, tableScrollDiv: tableScrollDiv }), recordIds.length < Math.min(maxCells, VIRTUAL_LIST_ON_CARD_COUNT - 1) && (_jsx(EmptyCardWrapper, { tableId: tableId, createNewShift: function () { return createNewShift(momentString, indexedObj); }, pasteShift: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                onPasteShift(momentString, groupByRow.rowKey);
                                return [2 /*return*/];
                            });
                        }); }, onApplyTemplate: function (template) {
                            if ('color' in template) {
                                onApplyTemplate(template, momentString, groupByRow.rowKey);
                            }
                            else {
                                onApplyOldTemplate(template, momentString, groupByRow.rowKey);
                            }
                        }, groupByRecordId: groupByRecordId }))] }));
        }
        return (_jsx(EmptyCardWrapper, { tableId: tableId, createNewShift: function () { return createNewShift(momentString, indexedObj); }, pasteShift: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    onPasteShift(momentString, groupByRow.rowKey);
                    return [2 /*return*/];
                });
            }); }, onApplyTemplate: function (template) {
                if ('color' in template) {
                    onApplyTemplate(template, momentString, groupByRow.rowKey);
                }
                else {
                    onApplyOldTemplate(template, momentString, groupByRow.rowKey);
                }
            }, groupByRecordId: groupByRecordId }));
    }, [
        tableId,
        collectionId,
        createNewShift,
        deleteRecord,
        dragAndDropShift,
        getBulkEditInfo,
        onApplyOldTemplate,
        onApplyTemplate,
        onPasteShift,
        openNewOrBulkShiftDetailModal,
        openShiftDetailModal,
        tableScrollDiv,
    ]);
    var _c = useWindowSize().windowWidth, windowWidth = _c === void 0 ? 0 : _c;
    var tableColumns = useMemo(function () {
        var columns = [];
        columns.push(_jsx(Column, { ellipsis: true, width: windowWidth > 576 ? 220 : 155, dataIndex: "name", fixed: 'left', className: 'ScheduleTablePrimaryColumn', render: function (_, record) {
                var groupByRow = cloneDeep(record.groupByRow);
                // Match to the correct collection name
                if (groupByRow.rowKey === SCHEDULE_UNASSIGNED_KEY &&
                    groupBy !== ScheduleGroupBy.USER) {
                    groupByRow.title = "No ".concat(getGroupByCollectionName(groupBy, collectionOptions));
                }
                return _jsx(ScheduleGroupByRowTitle, { blockId: tableId, groupByRow: groupByRow });
            } }, "name"));
        timeRange.slice(0, -1).map(function (time, colIndex) {
            var momentString = moment(time).format('YYYY-MM-DD');
            var isToday = moment(time).isSame(new Date(), 'day');
            var dayOfWeek = moment(time).day();
            var isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
            columns.push(_jsx(Column, { ellipsis: true, width: scheduleView !== ScheduleView.WEEK
                    ? MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH
                    : WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, title: _jsx(ScheduleWeekDateTitle, { blockId: tableId, momentDate: moment(time), isToday: isToday }), dataIndex: momentString, className: "ScheduleTableDateColumn".concat(isToday ? ' isToday ' : '').concat(isWeekend ? ' isWeekend ' : ''), render: function (_, record, index) {
                    return (_jsx(ScheduleCellWrapper, { recordKey: record.groupByRow.rowKey, recordIndex: index, columnKey: momentString, columnIndex: colIndex, blockId: tableId, children: scheduleCellChildren(time, record) }));
                } }, momentString));
        });
        return columns;
    }, [
        windowWidth,
        timeRange,
        groupBy,
        tableId,
        collectionOptions,
        scheduleView,
        scheduleCellChildren,
    ]);
    return (_jsx(ScheduleANTDTableBuilder, { blockId: tableId, dataSource: dataSource, tableColumns: tableColumns, isLoading: isLoading, tableScrollHeight: tableScrollHeight }));
    // return <ScheduleTableBuilder columns={columns} data={data} dataSource={dataSource} />
};
export default React.memo(ScheduleWeekViewTable);
