var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation, useQuery } from 'react-query';
import { useFetch } from '.';
var useHolidayPayApi = function () {
    var _a = useFetch(), customFetch = _a.request, toastRequest = _a.toastRequest;
    var useGetHolidayPayEnableStatus = function () {
        return useQuery(['useGetHolidayPayEnableStatus'], function () {
            return customFetch({
                method: 'GET',
                url: 'holiday/get_holiday_setting',
            });
        });
    };
    var enableHolidayPay = function () {
        return toastRequest({
            url: "holiday/enable",
            method: 'POST',
        }, null, '', 'Successfully enabled holiday pay!', '');
    };
    var disableHolidayPay = function () {
        return toastRequest({
            url: "holiday/disable",
            method: 'POST',
        }, null, '', 'Successfully disabled holiday pay!', '');
    };
    var useGetHolidayMultipliers = function () {
        return useQuery(['holidayMultipliers'], function () {
            return customFetch({
                method: 'GET',
                url: 'holiday/get_multipliers',
            });
        });
    };
    var useCreateHolidayMultiplier = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "holiday/create_multiplier",
            }, request);
        });
    };
    var useUpdateHolidayMultiplier = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'PUT',
                url: "holiday/update_multiplier",
            }, request);
        });
    };
    var useDeleteHolidayMultiplier = function () {
        return useMutation(function (id) {
            return customFetch({
                method: 'DELETE',
                url: "holiday/multiplier/".concat(id),
            });
        });
    };
    var useGetHolidayPolicies = function () {
        return useQuery(['holidayPolicies'], function () {
            return customFetch({
                method: 'GET',
                url: 'holiday/get_policies',
            });
        });
    };
    var useCreateHolidayPolicy = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: "holiday/create_policy",
            }, request);
        });
    };
    var useUpdateHolidayPolicy = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'PUT',
                url: "holiday/update_policy",
            }, request);
        });
    };
    var useDeleteHolidayPolicy = function () {
        return useMutation(function (id) {
            return customFetch({
                method: 'DELETE',
                url: "holiday/policy/".concat(id),
            });
        });
    };
    var useGetLocationHolidayMultipliers = function (request) {
        var _a;
        return useQuery(['locationHolidayMultipliers', ((_a = request.body) === null || _a === void 0 ? void 0 : _a.locationRid) || ''], function () {
            return customFetch({
                method: 'POST',
                url: 'holiday/location_holiday_multipliers',
            }, request.body);
        }, __assign({}, request.options));
    };
    return {
        useGetHolidayPayEnableStatus: useGetHolidayPayEnableStatus,
        enableHolidayPay: enableHolidayPay,
        disableHolidayPay: disableHolidayPay,
        useGetHolidayMultipliers: useGetHolidayMultipliers,
        useCreateHolidayMultiplier: useCreateHolidayMultiplier(),
        useUpdateHolidayMultiplier: useUpdateHolidayMultiplier(),
        useDeleteHolidayMultiplier: useDeleteHolidayMultiplier(),
        useGetHolidayPolicies: useGetHolidayPolicies,
        useCreateHolidayPolicy: useCreateHolidayPolicy(),
        useUpdateHolidayPolicy: useUpdateHolidayPolicy(),
        useDeleteHolidayPolicy: useDeleteHolidayPolicy(),
        useGetLocationHolidayMultipliers: useGetLocationHolidayMultipliers,
    };
};
export { useHolidayPayApi };
