import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { lazy, Suspense } from 'react';
import DummyTextCell from '../DummyTextCell';
var LogicAddressCell = lazy(function () { return import('./LogicAddressCell'); });
var AddressCellHOC = function (props) {
    var address = props.address, editCell = props.editCell, canEdit = props.canEdit, isClicked = props.isClicked, setIsClicked = props.setIsClicked;
    var dummyCell = (_jsx(_Fragment, { children: !isClicked && (_jsx(DummyTextCell, { text: address, setIsClicked: setIsClicked, canEdit: canEdit })) }));
    return (_jsxs(_Fragment, { children: [isClicked && (_jsx(Suspense, { fallback: dummyCell, children: _jsx(LogicAddressCell, { address: address, editCell: editCell, setIsClicked: setIsClicked }) })), dummyCell] }));
};
export default React.memo(AddressCellHOC);
