var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var StarGradient = function () { return (_jsxs("svg", { width: "89", height: "89", viewBox: "0 0 89 89", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M29.6222 43.1214L27.0687 58.0544C26.882 59.1669 27.3375 60.2869 28.2409 60.9513C29.1444 61.6233 30.3466 61.7279 31.3545 61.2201L44.794 54.1569L58.2336 61.2201C59.2416 61.7503 60.4587 61.6607 61.377 60.9887C62.2953 60.3242 62.7583 59.1893 62.5641 58.0694L60.0107 43.1364L70.852 32.5488C71.6659 31.7574 71.9571 30.5701 71.6062 29.4875C71.2552 28.4049 70.3219 27.6209 69.1945 27.4566L54.2615 25.2765L47.5567 11.6725C47.0564 10.6496 46.0186 10 44.8763 10C43.7339 10 42.6961 10.6496 42.1959 11.6725L35.491 25.2765L20.558 27.4566C19.4306 27.6209 18.4973 28.4049 18.1464 29.4875C17.7954 30.5702 18.0866 31.7573 18.9005 32.5488L29.6222 43.1214ZM37.8204 30.8914C38.7986 30.7495 39.6423 30.1373 40.0754 29.2487L44.7943 19.7215L49.5132 29.2935C49.9612 30.1596 50.7974 30.7569 51.7681 30.8913L62.3256 32.3846L54.68 39.851C53.9258 40.5454 53.5749 41.5757 53.7541 42.5838L55.561 53.1115L46.1233 48.1387C45.2572 47.6833 44.2119 47.6833 43.3459 48.1387L33.9082 53.1115L35.8345 42.5838C36.0063 41.6131 35.6852 40.6276 34.9834 39.9406L27.3377 32.4742L37.8204 30.8914Z", fill: "url(#paint0_linear_11416_57577)" }), _jsx("defs", { children: _jsxs("linearGradient", { id: "paint0_linear_11416_57577", x1: "44.8763", y1: "10", x2: "44.8763", y2: "61.5632", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: "#E75C90" }), _jsx("stop", { offset: "1", stopColor: "#426FFF" })] }) })] })); };
export var StarGradientIcon = function (props) { return (_jsx(Icon, __assign({ component: StarGradient }, props))); };
