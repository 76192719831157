var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useRecordDetail } from 'src/hooks/component';
import { CollectionTableType } from 'src/shared';
import RecordCardTags from './RecordCardTags';
import './RecordCard.scss';
import { boardViewShiftCardOpenCountState } from './Selector';
var getStyle = function (provided, style, isDragging) {
    var _a;
    var combined = __assign(__assign({}, style), provided.draggableProps.style);
    var marginBottom = 10;
    var withSpacing = __assign(__assign({}, combined), { height: isDragging ? combined.height : ((_a = combined.height) !== null && _a !== void 0 ? _a : 100 - marginBottom) });
    return withSpacing;
};
var RecordCard = function (_a) {
    var collectionId = _a.collectionId, info = _a.info, style = _a.style, draggableProvided = _a.draggableProvided, cardColor = _a.cardColor, isDragging = _a.isDragging, visibleSchemaIds = _a.visibleSchemaIds;
    var containerStyle = getStyle(draggableProvided, style, isDragging);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var recordId = info.rid;
    var openShiftCount = useRecoilValue(boardViewShiftCardOpenCountState({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
    }));
    return (_jsxs("div", __assign({ className: "RecordCardContainer" }, draggableProvided.draggableProps, draggableProvided.dragHandleProps, { ref: draggableProvided.innerRef, style: __assign(__assign({}, containerStyle), { backgroundColor: cardColor, cursor: isDragging ? 'grab' : 'pointer' }), onClick: function () { return openRecordDetail(info.rid); }, children: [openShiftCount >= 1 && _jsx("div", { className: "OpenCountIndicator", children: openShiftCount }), _jsx(RecordCardTags, { collectionId: collectionId, visibleSchemaIds: visibleSchemaIds, record: info.recordColumnInfo })] })));
};
export default React.memo(RecordCard);
