import { atom } from 'recoil';
import { defaultSnapshotOptionSetting } from 'src/components/CenterPanel/Dashboard/Constant';
import { localStorageEffect } from 'src/state/atoms/localStorageEffects';
var schedulingSnapshotOptionSettingState = atom({
    key: 'schedulingSnapshotOptionSettingState',
    default: defaultSnapshotOptionSetting,
    effects: [localStorageEffect('schedulingSnapshotOptionSetting')],
});
var dashboardShiftAlertTimeRangeState = atom({
    key: 'dashboardShiftAlertTimeRangeState',
    default: 'this_week',
    effects: [localStorageEffect('dashboardShiftAlertTimeRange')],
});
export { schedulingSnapshotOptionSettingState, dashboardShiftAlertTimeRangeState };
