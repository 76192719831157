import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilCallback } from 'recoil';
import { helpDrawerState } from 'src/state/atoms/helpDrawerState';
var HelpActionWrapper = function (props) {
    var articleId = props.articleId, children = props.children;
    var onOpenArticle = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function () {
            set(helpDrawerState, {
                isVisible: true,
                step: 2,
                selectedHelpArticleId: articleId,
            });
        };
    }, [articleId]);
    return (_jsx("span", { onClick: onOpenArticle, className: "cursor-pointer", children: children }));
};
export default React.memo(HelpActionWrapper);
