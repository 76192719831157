var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { useDisplaySettings, useNaviTreeComponent, useRecordComponent, useSchemaComponent, } from 'src/hooks/component';
import { tableCollectionIdSelector, currentPageIdSelector, tableDisplaySettingsState, canUpdateFilterOnPageSelector, tableSearchTextState, collectionTableCanEditSchemasSelector, collectionTableCanEditSelector, blockTitleSelector, collectionTableNameSelector, isCurrentPageCanvasTypeSelector, blockContentTypeSelector, collectionTableHasSearchbarSelector, isCurrentPageScheduleTypeSelector, tableSelectedViewIdState, collectionTableSelectedRecordIdsState, tableSchedulingDisplaySettingsState, } from 'src/state';
import { CollectionTableType, CollectionType } from 'src/shared';
import HoverOptionButtons from 'src/components/PageView/HoverOptions/HoverOptionButtons';
import { useParams } from 'react-router-dom';
import { ScheduleView } from 'src/services';
import TableMoreOptionDropdown from '../TableMoreOptionDropdown';
import TableOptionHeader from './TableOptionHeader';
import TableFiltersRow from './TableFiltersRow';
import BlockViewOptions from './Views/BlockViewOptions';
import BulkEditButtonOptions from './BulkEdit/BulkEditButtonOptions';
import StandardCollectionExtraHeaderOptions from './ExtraOptions/StandardCollectionExtraHeaderOptions';
var TableOptionHeaderFromBlock = function (_a) {
    var _b;
    var blockId = _a.blockId, collectionType = _a.collectionType, moreOptionsDropdown = _a.moreOptionsDropdown, colorCoding = _a.colorCoding, schedulePublishShift = _a.schedulePublishShift, isPayPeriodModal = _a.isPayPeriodModal;
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var title = useRecoilValue(blockTitleSelector(blockId));
    var tableDataSource = { type: CollectionTableType.TABLE, collectionId: collectionId };
    var collectionName = useRecoilValue(collectionTableNameSelector(tableDataSource));
    var canEditCollection = useRecoilValue(collectionTableCanEditSelector(tableDataSource));
    var canAddNewColumn = useRecoilValue(collectionTableCanEditSchemasSelector(tableDataSource));
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var addNewColumn = useSchemaComponent().addNewColumn;
    var applyDisplaySettingsToView = useDisplaySettings().applyDisplaySettingsToView;
    var saveRenameNode = useNaviTreeComponent().saveRenameNode;
    var displaySettingsRef = useRef(displaySettings);
    useEffect(function () {
        displaySettingsRef.current = displaySettings;
    }, [displaySettings]);
    var handleSearchInBlock = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (searchQuery) {
            set(tableSearchTextState(blockId), searchQuery);
        };
    }, [blockId]);
    var handleUpdateTitle = useCallback(function (title, requestSave) {
        saveRenameNode('block', blockId, title, requestSave);
    }, [blockId, saveRenameNode]);
    var handleAddNewRecord = useCallback(function () {
        onCreateNewRecord(blockId, collectionId);
    }, [blockId, collectionId, onCreateNewRecord]);
    var handleAddNewColumn = useCallback(function () {
        addNewColumn === null || addNewColumn === void 0 ? void 0 : addNewColumn('block', blockId, {
            proposedSchemaId: uuidv4(),
        });
    }, [addNewColumn, blockId]);
    var isPayPeriodModalShiftOrTimeoff = useMemo(function () {
        if (isPayPeriodModal) {
            if (collectionType == CollectionType.TIMEOFF) {
                return 'TIMEOFF';
            }
            else if (collectionType == CollectionType.SHIFT) {
                return 'SHIFT';
            }
        }
        return undefined;
    }, [isPayPeriodModal, collectionType]);
    var handleApplyFilters = useCallback(function (filters, shouldSave) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(blockId, { filters: filters }, shouldSave, undefined, undefined, isPayPeriodModalShiftOrTimeoff)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, isPayPeriodModalShiftOrTimeoff]);
    var handleApplySortEntries = useCallback(function (sortBy) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(blockId, { sortBy: sortBy }, undefined, undefined, undefined, isPayPeriodModalShiftOrTimeoff)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, isPayPeriodModalShiftOrTimeoff]);
    var handleApplySchemaOptions = useCallback(function (schemaOptions) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(blockId, { schemaOptions: schemaOptions }, undefined, undefined, undefined, isPayPeriodModalShiftOrTimeoff)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, isPayPeriodModalShiftOrTimeoff]);
    var handleGroupBySetting = useCallback(function (groupSetting) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, applyDisplaySettingsToView(blockId, { groupSetting: groupSetting }, undefined, undefined, undefined, isPayPeriodModalShiftOrTimeoff)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [applyDisplaySettingsToView, blockId, isPayPeriodModalShiftOrTimeoff]);
    var pageId = useParams().pageId;
    var canApplyFilterOnPage = useRecoilValue(canUpdateFilterOnPageSelector(pageId !== null && pageId !== void 0 ? pageId : ''));
    var canEditView = pageId ? canApplyFilterOnPage : true;
    var isCanvasPageType = useRecoilValue(isCurrentPageCanvasTypeSelector);
    var isScheduleTypePage = useRecoilValue(isCurrentPageScheduleTypeSelector);
    var blockContentType = useRecoilValue(blockContentTypeSelector(blockId));
    var isCollectionTableBlock = blockContentType === 'collection';
    var isScheduleBlock = blockContentType === 'scheduling';
    var scheduleView = useRecoilValue(tableSchedulingDisplaySettingsState(blockId)).viewType;
    var hasSearchbar = (useRecoilValue(collectionTableHasSearchbarSelector(tableDataSource)) || isScheduleBlock) &&
        scheduleView !== ScheduleView.MONTH;
    var viewId = useRecoilValue(tableSelectedViewIdState(blockId));
    var selectedRecordIds = useRecoilValue(collectionTableSelectedRecordIdsState(blockId));
    var canBulkEdit = canEditCollection && selectedRecordIds.length > 0;
    return (_jsxs(_Fragment, { children: [_jsx(TableOptionHeader, { viewId: viewId, collectionId: collectionId, displaySettings: displaySettings, searchRecord: hasSearchbar ? handleSearchInBlock : undefined, title: !pageId || (isCanvasPageType && !isCollectionTableBlock)
                    ? title || collectionName
                    : undefined, updateTitle: handleUpdateTitle, addNewRecord: canEditCollection ? handleAddNewRecord : undefined, addNewColumn: canAddNewColumn ? handleAddNewColumn : undefined, applySort: canEditView && !isScheduleBlock ? handleApplySortEntries : undefined, applySchemaOptions: canEditView ? handleApplySchemaOptions : undefined, applyGroupBySetting: canEditView ? handleGroupBySetting : undefined, hoverOptionButton: canEditCollection && isCanvasPageType ? (_jsx(BlockHoverOptionButton, { blockId: blockId })) : undefined, moreOptionsDropdown: moreOptionsDropdown ? (moreOptionsDropdown) : (_jsx(TableMoreOptionDropdown, { blockId: blockId, canEditView: canEditView, displaySettings: displaySettings })), collectionViews: pageId && isCollectionTableBlock ? (_jsx(BlockViewOptions, { blockId: blockId })) : undefined, bulkEditOptions: canBulkEdit ? (_jsx(BulkEditButtonOptions, { blockId: blockId, collectionId: collectionId })) : undefined, tableFilters: viewId && canApplyFilterOnPage ? (_jsx(TableFiltersRow, { filters: displaySettings.filters, blockProps: {
                        blockId: blockId,
                        collectionViewId: viewId,
                        isSchedule: isScheduleBlock,
                    }, applyFilter: handleApplyFilters, collectionId: collectionId, canApplyFilter: canApplyFilterOnPage, currentGroupBySchemaId: (_b = displaySettings.groupSetting) === null || _b === void 0 ? void 0 : _b.schema, isPayPeriodModal: isPayPeriodModal })) : undefined, extraOptions: _jsx(StandardCollectionExtraHeaderOptions, { tableId: blockId, collectionId: collectionId, collectionType: collectionType, type: "block" }), scheduleColorCoding: canEditView ? colorCoding : undefined, schedulePublishShift: schedulePublishShift, isScheduleTypePage: isScheduleTypePage, canSwitchViewType: canEditView && !isScheduleBlock }), !pageId && viewId && (_jsx(TableFiltersRow, { filters: displaySettings.filters, blockProps: {
                    blockId: blockId,
                    collectionViewId: viewId,
                    isSchedule: isScheduleBlock,
                }, applyFilter: handleApplyFilters, collectionId: collectionId, canApplyFilter: canApplyFilterOnPage, isPayPeriodModal: isPayPeriodModal }))] }));
};
export var BlockHoverOptionButton = memo(function (_a) {
    var blockId = _a.blockId;
    var moveBlockUpOrDown = useNaviTreeComponent().moveBlockUpDown;
    var blockContentType = useRecoilValue(blockContentTypeSelector(blockId));
    var isNonTitleBlock = useMemo(function () { return blockContentType === 'shift_requests'; }, [blockContentType]);
    var moveBlockUpDown = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (blockId, isUp) { return __awaiter(void 0, void 0, void 0, function () {
            var release, pageId;
            return __generator(this, function (_a) {
                release = snapshot.retain();
                try {
                    pageId = snapshot.getLoadable(currentPageIdSelector).valueMaybe();
                    if (!pageId)
                        return [2 /*return*/];
                    moveBlockUpOrDown(pageId, blockId, isUp);
                }
                finally {
                    release();
                }
                return [2 /*return*/];
            });
        }); };
    }, [moveBlockUpOrDown]);
    return (_jsx(HoverOptionButtons, { data: {
            blockId: blockId,
        }, moveBlockUpDown: function (isUp) { return moveBlockUpDown(blockId, isUp); }, isNonTitleBlock: isNonTitleBlock }));
});
BlockHoverOptionButton.displayName = 'BlockHoverOptionButton';
export default React.memo(TableOptionHeaderFromBlock);
