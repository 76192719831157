var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CellInputMode, SchemaConfigurationType } from 'src/shared';
import ContextFieldSelectionWrapper from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/ContextFieldSelectionWrapper';
import TextArea from 'antd/lib/input/TextArea';
import GenerativeTestWrapper from 'src/components/OptimizedTableView/GenerativeConfigurationModal/components/GenerativeTestWrapper';
import { GenerativeConfigurationContext } from '../Context';
import SelectOptionsSection from './SelectOptionsSection';
var initialState = {
    type: SchemaConfigurationType.GENERATIVE_SELECT,
    selectPrompt: '',
    contextSchemaIds: [],
    isMulti: false,
    defaultValue: undefined,
    selectOptionOrder: [],
};
var SelectSchemaConfigurationSection = function (_a) {
    var isSmartField = _a.isSmartField, smartFieldSwitch = _a.smartFieldSwitch, collectionId = _a.collectionId, onGenerativeSchemaSave = _a.onGenerativeSchemaSave, generativeSchemaConfig = _a.generativeSchemaConfig, changeSchemaConfig = _a.changeSchemaConfig, selectOptions = _a.selectOptions, setSelectOptions = _a.setSelectOptions, schema = _a.schema, schemaTypeChanged = _a.schemaTypeChanged, addToSelectSchemaEditQueue = _a.addToSelectSchemaEditQueue;
    var configurationType = useMemo(function () {
        return isSmartField
            ? SchemaConfigurationType.GENERATIVE_SELECT
            : SchemaConfigurationType.SELECT;
    }, [isSmartField]);
    var _b = useState(__assign(__assign(__assign({}, initialState), generativeSchemaConfig), { type: configurationType, isMulti: generativeSchemaConfig
            ? generativeSchemaConfig.isMulti
            : schema.inputMode == CellInputMode.MULTI })), generativeSelectConfiguration = _b[0], setGenerativeSelectConfiguration = _b[1];
    var setConfiguration = useCallback(function (configuration) {
        setGenerativeSelectConfiguration(__assign(__assign({}, configuration), { type: configurationType }));
        changeSchemaConfig(configurationType, configuration);
    }, [changeSchemaConfig, configurationType]);
    var formInstance = Form.useForm()[0];
    // Handles setting form values when the type is switched between
    // smartfield states
    useEffect(function () {
        if (isSmartField) {
            formInstance.setFieldsValue(generativeSelectConfiguration);
        }
    }, [generativeSelectConfiguration, formInstance, isSmartField]);
    // Switches between select and generative select configurations
    // when the smartfield switch is toggled
    useEffect(function () {
        setConfiguration(__assign(__assign({}, generativeSelectConfiguration), { type: configurationType }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationType]);
    // Handles switching between single and multi select when smartfield switch
    // is toggled on; necessary because the smartfield switch overrides the
    // input mode that would otherwise define the difference between single and
    // multi select fields.
    useEffect(function () {
        if (schema.inputMode !== CellInputMode.USER_DEFINED_GENERATED && isSmartField) {
            setConfiguration(__assign(__assign({}, generativeSelectConfiguration), { type: configurationType, isMulti: schema.inputMode == CellInputMode.MULTI }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema.inputMode]);
    var handleGenerativeSchemaSave = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formInstance.validateFields()];
                case 1:
                    _a.sent();
                    onGenerativeSchemaSave === null || onGenerativeSchemaSave === void 0 ? void 0 : onGenerativeSchemaSave(SchemaConfigurationType.GENERATIVE_SELECT, generativeSelectConfiguration);
                    return [2 /*return*/];
            }
        });
    }); }, [formInstance, onGenerativeSchemaSave, generativeSelectConfiguration]);
    var onChangePrompt = useCallback(function (prompt) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setConfiguration(__assign(__assign({}, generativeSelectConfiguration), { selectPrompt: prompt }));
            handleGenerativeSchemaSave();
            return [2 /*return*/];
        });
    }); }, [generativeSelectConfiguration, setConfiguration, handleGenerativeSchemaSave]);
    var promptRules = [
        {
            validator: function (_, value) {
                if (!value || value.trim().length === 0) {
                    return Promise.reject(new Error('Prompt cannot be empty'));
                }
                return Promise.resolve();
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(SelectOptionsSection, { collectionId: collectionId, selectOptions: selectOptions, setSelectOptions: setSelectOptions, schema: schema, schemaTypeChanged: schemaTypeChanged, addToSelectSchemaEditQueue: addToSelectSchemaEditQueue }), _jsx("div", { className: "mt-4" }), smartFieldSwitch, isSmartField && (_jsx("div", { children: _jsx(GenerativeConfigurationContext.Provider, { value: {
                        value: generativeSelectConfiguration,
                        setValue: setConfiguration,
                    }, children: _jsxs("div", { className: "flex flex-col", children: [_jsxs(Form, { layout: "vertical", labelCol: { span: 6 }, wrapperCol: { span: 24 }, form: formInstance, initialValues: generativeSelectConfiguration, children: [_jsx(ContextFieldSelectionWrapper, { collectionId: collectionId }), _jsx(Form.Item, { label: _jsx("div", { className: "font-medium text-gray-400 text-xs mt-4", children: "Prompt" }), required: false, validateTrigger: false, name: "selectPrompt", className: "mb-2", rules: promptRules, children: _jsx(TextArea, { rows: 4, className: "bg-textAreaAccent hover:bg-textAreaAccent", bordered: false, onChange: function (e) { return onChangePrompt(e.target.value); }, placeholder: "If user's name starts with a vowel, it should read 'vowel'. Otherwise, it should read 'consonant'" }) })] }), _jsx(GenerativeTestWrapper, { collectionId: collectionId, selectOptions: selectOptions })] }) }) }))] }));
};
export default SelectSchemaConfigurationSection;
