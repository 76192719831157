import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import ApplyAccountTemplateModal from './AccountTemplateModal/ApplyAccountTemplateModal';
import CreateAccountDetailModal from './CreateAccountDetailModal/CreateAccountDetailModal';
var AccountSetUpModal = function (props) {
    var isModalVisible = props.isModalVisible, modalOnClose = props.modalOnClose, defaultValues = props.defaultValues;
    var _a = useState(false), showTemplateModal = _a[0], setShowTemplateModal = _a[1];
    var _b = useState(), newAccountInfo = _b[0], setNewAccountInfo = _b[1];
    var newAccountInfoOnChange = useCallback(function (info) {
        setNewAccountInfo(info);
        setShowTemplateModal(true);
    }, []);
    return (_jsx(Modal, { className: "AccountTemplateModal", open: isModalVisible, closable: true, width: "100%", centered: true, onCancel: modalOnClose, destroyOnClose: true, footer: null, children: showTemplateModal && newAccountInfo ? (_jsx(ApplyAccountTemplateModal, { accountInfo: newAccountInfo })) : (_jsx(CreateAccountDetailModal, { newAccountInfoOnChange: newAccountInfoOnChange, defaultValues: defaultValues })) }));
};
export default React.memo(AccountSetUpModal);
