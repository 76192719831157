export var RecurringFrequency;
(function (RecurringFrequency) {
    RecurringFrequency["DAILY"] = "DAILY";
    RecurringFrequency["WEEKLY"] = "WEEKLY";
    RecurringFrequency["MONTHLY"] = "MONTHLY";
    RecurringFrequency["BIWEEKLY"] = "BIWEEKLY";
})(RecurringFrequency || (RecurringFrequency = {}));
export var DefaultFrequencyOptions = [
    {
        value: RecurringFrequency.DAILY,
        displayValue: 'Days',
    },
    {
        value: RecurringFrequency.WEEKLY,
        displayValue: 'Weeks',
    },
    {
        value: RecurringFrequency.MONTHLY,
        displayValue: 'Months',
    },
    {
        value: RecurringFrequency.BIWEEKLY,
        displayValue: 'Biweekly',
    },
];
export var DayOfWeekOptions = [
    {
        label: 'M',
        value: 'MO',
    },
    {
        label: 'T',
        value: 'TU',
    },
    {
        label: 'W',
        value: 'WE',
    },
    {
        label: 'T',
        value: 'TH',
    },
    {
        label: 'F',
        value: 'FR',
    },
    {
        label: 'S',
        value: 'SA',
    },
    {
        label: 'S',
        value: 'SU',
    },
];
