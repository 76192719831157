import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo } from 'react';
import TableBuilder from 'src/components/OptimizedTableView/TableBuilder';
import { useRecordDetail } from 'src/hooks/component';
import { DashboardTableColumnType } from '../../Constant';
import SendUserInviteButton from '../UserAlert/SendUserInviteButton';
import DashboardTableCell from './DashboardTableCell';
var DashboardAlertTable = function (props) {
    var columns = props.columns, rows = props.rows, extraActionColumnBtn = props.extraActionColumnBtn, handlePaginationScroll = props.handlePaginationScroll, tableId = props.tableId;
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var renderTableCell = useCallback(function (isFirstColumn, cellData) {
        return (_jsxs(_Fragment, { children: [_jsx(DashboardTableCell, { cellData: cellData }), isFirstColumn && (_jsx("div", { className: "OpenRecordBtn", onClick: function () { return openRecordDetail(cellData.rid); }, children: "OPEN" }))] }));
    }, [openRecordDetail]);
    var parseToColumnData = useMemo(function () {
        var tempColumns = [];
        columns.map(function (column, index) {
            var columnWidth = column.type === DashboardTableColumnType.TEXT
                ? 220
                : column.type === DashboardTableColumnType.USER
                    ? 180
                    : 150;
            tempColumns.push({
                Header: _jsx("div", { className: "ColumnTitle", children: column.displayName }),
                accessor: column.identifier,
                width: columnWidth,
                Cell: function (_a) {
                    var value = _a.value;
                    if (!value)
                        return _jsx(_Fragment, {});
                    return renderTableCell(index === 0, value);
                },
            });
        });
        if (extraActionColumnBtn)
            tempColumns.push({
                Header: _jsx(_Fragment, {}),
                accessor: 'actionButton',
                width: 140,
                minWidth: 140,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Cell: function (value) {
                    var index = value.row.index;
                    var recordData = value.data[index];
                    var rid = Object.values(recordData)[0].rid;
                    return _jsx(SendUserInviteButton, { recordId: rid });
                },
            });
        return tempColumns;
    }, [columns, extraActionColumnBtn, renderTableCell]);
    var parseToRecordsData = useMemo(function () {
        var tempRecords = [];
        rows.map(function (record) {
            var tempTestRecordValue = {};
            Object.keys(record.valueMap).map(function (key) {
                var columnInfoObj = record.valueMap[key];
                tempTestRecordValue[key] = {
                    rid: record.recordId,
                    value: columnInfoObj,
                    type: columnInfoObj.type,
                };
            });
            tempRecords.push(tempTestRecordValue);
        });
        return tempRecords;
    }, [rows]);
    return (_jsx(TableBuilder, { columns: parseToColumnData, data: parseToRecordsData, rootMarginOverride: "200px 0px", tableScrollProps: {
            tableId: tableId,
            tableClassNameOverride: 'DashboardTableBuilder',
            handlePaginationScroll: handlePaginationScroll,
        } }));
};
export default React.memo(DashboardAlertTable);
