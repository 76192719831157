import { CollectionType } from '../../shared';
export var importTypeSet = new Set([
    CollectionType.USER,
    CollectionType.LOCATION,
    CollectionType.TIMEOFF,
    CollectionType.ROLE,
    CollectionType.SHIFT,
    CollectionType.CUSTOM,
    CollectionType.CONTACT,
]);
export var OtherOptions;
(function (OtherOptions) {
    OtherOptions["CREATE_NEW"] = "createNew";
    OtherOptions["NO_MATCHING"] = "noMatching";
})(OtherOptions || (OtherOptions = {}));
export var SpecialNewSchemaTypes;
(function (SpecialNewSchemaTypes) {
    SpecialNewSchemaTypes["DATE_TIME"] = "datetime";
    SpecialNewSchemaTypes["PLAIN_SELECTION"] = "plain_selection";
})(SpecialNewSchemaTypes || (SpecialNewSchemaTypes = {}));
export var requiredRules = [
    {
        required: true,
        message: 'Please select an option',
    },
];
