import moment from 'moment';
import { CollectionType, NativeMultiSet, NativePath, StyleColorMap } from 'src/shared';
import { getNativeFieldCellContent, getNativeFieldCellOptions } from 'src/utils/Collection';
import tinycolor from 'tinycolor2';
import { ShiftStatusDisplayNameToColorMap, } from '../../ScheduleView/Constant';
export var getColumnContentTextColor = function (backgroundColor) {
    return tinycolor(backgroundColor).darken(50).toString();
};
export var isMultiSelectSchema = function (schema) {
    return schema.inputMode === 'multi' || NativeMultiSet.has(schema.nativePath);
};
export var isNativeAccessGroupSchema = function (schema) {
    return schema.nativePath === NativePath.USER_ACCESS_GROUPS;
};
export var generateNativeCollectionTitle = function (type, record, schemas, timezone) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var tz = moment().tz(timezone).zoneAbbr();
    var displayText = '';
    switch (type) {
        case CollectionType.USER: {
            var firstName = (_a = getNativeFieldCellContent(record, schemas, NativePath.USER_FIRST_NAME)) !== null && _a !== void 0 ? _a : '';
            var lastName = (_b = getNativeFieldCellContent(record, schemas, NativePath.USER_LAST_NAME)) !== null && _b !== void 0 ? _b : '';
            displayText =
                firstName && lastName
                    ? "".concat(firstName, " ").concat(lastName)
                    : firstName
                        ? firstName
                        : lastName;
            break;
        }
        case CollectionType.CONTACT: {
            var firstName = (_c = getNativeFieldCellContent(record, schemas, NativePath.CONTACT_FIRST_NAME)) !== null && _c !== void 0 ? _c : '';
            var lastName = (_d = getNativeFieldCellContent(record, schemas, NativePath.CONTACT_LAST_NAME)) !== null && _d !== void 0 ? _d : '';
            displayText =
                firstName && lastName
                    ? "".concat(firstName, " ").concat(lastName)
                    : firstName
                        ? firstName
                        : lastName;
            break;
        }
        case CollectionType.SHIFT: {
            var clockIn = (_e = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_CLOCK_IN)) !== null && _e !== void 0 ? _e : '';
            var clockOut = (_f = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_CLOCK_OUT)) !== null && _f !== void 0 ? _f : '';
            var start = (_g = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_START_AT)) !== null && _g !== void 0 ? _g : '';
            var end = (_h = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_END_AT)) !== null && _h !== void 0 ? _h : '';
            var startDay = moment(clockIn || start)
                .tz(timezone)
                .format('MMM D');
            var endDay = clockOut || end
                ? moment(clockOut || end)
                    .tz(timezone)
                    .format('MMM D')
                : '';
            var startTime = clockIn || start
                ? moment(clockIn || start)
                    .tz(timezone)
                    .format('h:mma')
                : 'Start Time';
            var endTime = clockOut || end
                ? moment(clockOut || end)
                    .tz(timezone)
                    .format('h:mma')
                : 'End Time';
            displayText =
                startDay && endDay && startDay !== endDay
                    ? "".concat(startDay, " - ").concat(endDay, ", ").concat(startTime, " - ").concat(endTime, ", ").concat(tz)
                    : startDay
                        ? "".concat(startDay, ", ").concat(startTime, " - ").concat(endTime, ",  ").concat(tz)
                        : '';
            break;
        }
        case CollectionType.TIMEOFF: {
            var startDay = (_j = getNativeFieldCellContent(record, schemas, NativePath.TIMEOFF_START_AT)) !== null && _j !== void 0 ? _j : '';
            var endDay = (_k = getNativeFieldCellContent(record, schemas, NativePath.TIMEOFF_END_AT)) !== null && _k !== void 0 ? _k : '';
            startDay = startDay ? moment(startDay).tz(timezone).format('MMM D') : '';
            endDay = endDay ? moment(endDay).tz(timezone).format('MMM D') : '';
            var timeOffType = (_m = (_l = getNativeFieldCellOptions(record, schemas, NativePath.TIMEOFF_TYPE_ID)) === null || _l === void 0 ? void 0 : _l[0].name) !== null && _m !== void 0 ? _m : '';
            displayText =
                startDay && endDay
                    ? "".concat(timeOffType, " (").concat(startDay, " - ").concat(endDay, " ").concat(tz, ")")
                    : startDay
                        ? "".concat(timeOffType, " (").concat(startDay, " - End Time ").concat(tz, ")")
                        : endDay
                            ? "".concat(timeOffType, " (Start Time - ").concat(endDay, " ").concat(tz, ")")
                            : '';
            break;
        }
        default:
            break;
    }
    return displayText;
};
export var getShiftStatusColor = function (status) {
    return ShiftStatusDisplayNameToColorMap[status];
};
export var generateShiftTitleColor = function (type, record, schemas) {
    var _a;
    if (type !== CollectionType.SHIFT)
        return undefined;
    var shiftStatus = (_a = getNativeFieldCellContent(record, schemas, NativePath.SHIFT_STATUS)) !== null && _a !== void 0 ? _a : '';
    if (!shiftStatus)
        return undefined;
    return getShiftStatusColor(shiftStatus);
};
export var getSchemaContentTagColor = function (schema, content) {
    if (schema.nativePath === NativePath.USER_LAST_ACTIVE) {
        return content !== 'No activity yet' ? StyleColorMap['GREEN'] : StyleColorMap['GREY'];
    }
    else if (schema.nativePath == NativePath.SHIFT_STATUS && content) {
        return getShiftStatusColor(content);
    }
    return undefined;
};
