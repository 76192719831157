import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from 'antd';
import React from 'react';
import TaskTemplateCustomFieldVisibilityToggle from './TaskCustomFields/TaskTemplateCustomFieldVisibilityToggle';
var TaskTemplateDueDate = function (_a) {
    var daysUntilDue = _a.daysUntilDue, visible = _a.visible, onDueDateUpdate = _a.onDueDateUpdate, onVisibleToggle = _a.onVisibleToggle;
    return (_jsxs("div", { className: "flex w-full", children: [_jsxs("div", { className: "w-full", children: [_jsx("div", { className: "TaskSectionLabel", children: "Days until Due" }), _jsx(Input, { className: "TaskDetailModalInput w-[98%]", type: "number", min: 1, value: daysUntilDue, onChange: function (e) {
                            return onDueDateUpdate(e.target.value ? Number(e.target.value) : undefined);
                        }, allowClear: true })] }), _jsx("div", { className: "self-end mb-[3px] ml-auto mr-[50px]", children: _jsx(TaskTemplateCustomFieldVisibilityToggle, { visible: visible, onToggle: onVisibleToggle }) })] }));
};
export default React.memo(TaskTemplateDueDate);
