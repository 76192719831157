var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FormulaOutlinedSvg = function () { return (_jsxs("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M13.6666 4.65756C13.6666 4.51949 13.5547 4.40756 13.4166 4.40756H7.91659C7.77851 4.40756 7.66659 4.51949 7.66659 4.65756V8.15756C7.66659 8.29563 7.55466 8.40756 7.41659 8.40756L4.58325 8.40756C4.44518 8.40756 4.33325 8.51949 4.33325 8.65756V9.4909C4.33325 9.62897 4.44518 9.7409 4.58325 9.7409L7.41659 9.7409C7.55466 9.7409 7.66659 9.85282 7.66659 9.9909V13.0742C7.66659 14.8311 6.30744 16.2705 4.58326 16.3983C4.44557 16.4085 4.33325 16.5195 4.33325 16.6576V17.4909C4.33325 17.629 4.44533 17.7416 4.58321 17.7343C7.04432 17.6044 8.99992 15.5677 8.99992 13.0742V9.9909C8.99992 9.85282 9.11185 9.7409 9.24992 9.7409H11.4166C11.5547 9.7409 11.6666 9.62897 11.6666 9.49089V8.65756C11.6666 8.51949 11.5547 8.40756 11.4166 8.40756L9.24992 8.40756C9.11185 8.40756 8.99992 8.29563 8.99992 8.15756V5.9909C8.99992 5.85282 9.11185 5.7409 9.24992 5.7409H13.4166C13.5547 5.7409 13.6666 5.62897 13.6666 5.4909V4.65756Z", fill: "#A7A8AC" }), _jsx("path", { d: "M14.5565 12.888C14.6542 12.9856 14.8125 12.9856 14.9101 12.888L16.3518 11.4463C16.4494 11.3487 16.6077 11.3487 16.7053 11.4463L17.2946 12.0356C17.3922 12.1332 17.3922 12.2915 17.2946 12.3891L15.8529 13.8308C15.7553 13.9285 15.7553 14.0867 15.8529 14.1844L17.428 15.7594C17.5256 15.8571 17.5256 16.0154 17.428 16.113L16.8387 16.7022C16.7411 16.7999 16.5828 16.7999 16.4852 16.7022L14.9101 15.1272C14.8125 15.0296 14.6542 15.0296 14.5565 15.1272L12.9815 16.7022C12.8839 16.7999 12.7256 16.7999 12.6279 16.7022L12.0387 16.113C11.941 16.0154 11.941 15.8571 12.0387 15.7594L13.6137 14.1844C13.7114 14.0867 13.7114 13.9285 13.6137 13.8308L12.172 12.3891C12.0744 12.2915 12.0744 12.1332 12.172 12.0356L12.7613 11.4463C12.8589 11.3487 13.0172 11.3487 13.1148 11.4463L14.5565 12.888Z", fill: "#A7A8AC" })] })); };
export var FormulaOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: FormulaOutlinedSvg }, props))); };
