var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Collapse } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
var Panel = Collapse.Panel;
var SchemaGroup = function (_a) {
    var groupId = _a.groupId, groupName = _a.groupName, groupSchemaIds = _a.groupSchemaIds, groupDescription = _a.groupDescription, schemas = _a.schemas, isCollapsed = _a.isCollapsed, isFirstGroup = _a.isFirstGroup, canEditCollection = _a.canEditCollection, onEditSchemaGroupDescription = _a.onEditSchemaGroupDescription, renderSchema = _a.renderSchema, shiftDetailProps = _a.shiftDetailProps, _b = _a.hidden, hidden = _b === void 0 ? false : _b, panelProps = __rest(_a, ["groupId", "groupName", "groupSchemaIds", "groupDescription", "schemas", "isCollapsed", "isFirstGroup", "canEditCollection", "onEditSchemaGroupDescription", "renderSchema", "shiftDetailProps", "hidden"]);
    var renderShiftTimeSchemas = (shiftDetailProps !== null && shiftDetailProps !== void 0 ? shiftDetailProps : {}).renderShiftTimeSchemas;
    return (_createElement(Panel, __assign({}, panelProps, { className: "SchemaGroup", style: hidden ? { display: 'none' } : undefined, header: _jsxs("div", { className: "flex flex-col", children: [_jsx("div", { className: "SchemaGroupHeader", children: _jsx("span", { className: "min-h-[23px] text-[15px] font-medium text-black", children: groupName }) }), _jsx("div", { style: { display: isCollapsed ? 'none' : undefined }, className: "pointer-events-auto", children: _jsx(TextArea, { className: "resize-none p-0 text-[13px] text-[#747474]", placeholder: "Enter a group description text here", defaultValue: groupDescription, onClick: function (e) { return e.stopPropagation(); }, onBlur: function (e) { return onEditSchemaGroupDescription(e.target.value); }, onPressEnter: function (e) {
                            return onEditSchemaGroupDescription(e.target.value);
                        }, bordered: false, tabIndex: -1, autoSize: true, autoFocus: true }) })] }), key: groupId }),
        isFirstGroup && renderShiftTimeSchemas && _jsx(_Fragment, { children: renderShiftTimeSchemas() }),
        _jsx(Droppable, { droppableId: groupId, children: function (provided) { return (_jsxs("div", __assign({}, provided.droppableProps, { ref: provided.innerRef, children: [groupSchemaIds.map(function (schemaId, schemaIndex) {
                        var schema = schemas[schemaId];
                        return schema ? (renderSchema(schema, schemaIndex)) : (
                        //! Hidden draggable so correct index is preserved
                        _jsx(Draggable, { draggableId: schemaId, index: schemaIndex, children: function (provided) { return (_jsx("div", __assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }))); } }, schemaId));
                    }), !groupSchemaIds.length && _jsx("div", { className: "h-1" }), provided.placeholder] }))); } })));
};
export default React.memo(SchemaGroup);
