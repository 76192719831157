var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react';
import { Button, message } from 'antd';
import { TransactionTableNameMap } from 'src/shared';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { accountIdState, scheduleCollectionIdState } from 'src/state';
import { timeOffRecordForUnassignShiftsState } from 'src/state/atoms/unassignShiftsOnTimeOffApprovalModalState';
import { usePageApi } from '../api';
import { useNaviTreeComponent } from '.';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var usePage = function (pageId) {
    var pageIdRef = useRef(pageId);
    useEffect(function () {
        pageIdRef.current = pageId;
    }, [pageId]);
    var accountId = useRecoilValue(accountIdState);
    var _a = usePageApi(), useUpdatePage = _a.useUpdatePage, getTimeoffRecordInfo = _a.getTimeoffRecordInfo;
    var _b = useNaviTreeComponent(), saveRenameNode = _b.saveRenameNode, addBlockToPage = _b.addBlockToPage;
    var navigate = useNavigate();
    var updatePageDescription = useCallback(function (newDescription) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId;
        return __generator(this, function (_a) {
            tempPageId = cloneDeep(pageIdRef.current);
            if (!tempPageId)
                return [2 /*return*/];
            useUpdatePage.mutate({
                pageId: tempPageId,
                description: newDescription,
            });
            return [2 /*return*/];
        });
    }); }, []);
    var updatePageTitle = useCallback(function (newTitle, requestSave) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, saveRenameNode(TransactionTableNameMap.PAGE, tempPageId, newTitle, requestSave)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [saveRenameNode]);
    var updatePageCoverImage = useCallback(function (newImage) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId;
        return __generator(this, function (_a) {
            tempPageId = cloneDeep(pageIdRef.current);
            if (!tempPageId)
                return [2 /*return*/];
            useUpdatePage.mutate({
                pageId: tempPageId,
                image: newImage,
            });
            return [2 /*return*/];
        });
    }); }, [useUpdatePage]);
    /**
     * create block
     */
    var _c = usePageApi(), useCreateNewBlockInPage = _c.useCreateNewBlockInPage, useApplyTemplateCollectionToPage = _c.useApplyTemplateCollectionToPage;
    var createCollectionFromTemplate = useCallback(function (templateIdentifier, newPageName) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useApplyTemplateCollectionToPage.mutateAsync({
                            pageId: tempPageId,
                            templateIdentifier: templateIdentifier,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: resp.contentType,
                        title: resp.title,
                    });
                    if (newPageName) {
                        updatePageTitle(newPageName, true);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, []);
    /**
     * create markdown block
     */
    var createMarkdownBlock = useCallback(function (nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            contentType: 'markdown',
                            pageId: tempPageId,
                            nextToBlockId: nextToBlockId,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'markdown',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    /**
     * create image block
     *
     * */
    var createImgBlock = useCallback(function (url, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            contentType: 'image',
                            pageId: tempPageId,
                            nextToBlockId: nextToBlockId,
                            content: {
                                image: url,
                            },
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'image',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    /**
     * create video block
     *
     * */
    var createVideoBlock = useCallback(function (url, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            contentType: 'video',
                            pageId: tempPageId,
                            nextToBlockId: nextToBlockId,
                            content: {
                                video: url,
                            },
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'video',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    /**
     * create geomap block
     *
     * */
    var createGeomapBlock = useCallback(function (nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            contentType: 'geomap',
                            pageId: tempPageId,
                            nextToBlockId: nextToBlockId,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'geomap',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    /**
     * create headcount block
     *
     * */
    var createHeadcountBlock = useCallback(function (nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            contentType: 'headcount',
                            pageId: tempPageId,
                            nextToBlockId: nextToBlockId,
                            start: moment().startOf('day').toISOString(),
                            end: moment().startOf('day').add(6, 'hours').toISOString(),
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'headcount',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    /**
     * create schedule block
     *
     * */
    var createScheduleBlock = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function (proposedTitle, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
            var tempPageId, scheduleCollectionId, defaultStartOfThisWeek, defaultStartOfNextWeek, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tempPageId = cloneDeep(pageIdRef.current);
                        if (!tempPageId)
                            return [2 /*return*/];
                        scheduleCollectionId = snapshot
                            .getLoadable(scheduleCollectionIdState)
                            .valueMaybe();
                        if (!scheduleCollectionId)
                            return [2 /*return*/];
                        defaultStartOfThisWeek = moment().clone().startOf('week').toISOString();
                        defaultStartOfNextWeek = moment()
                            .clone()
                            .add(1, 'week')
                            .startOf('week')
                            .toISOString();
                        return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                                accountId: accountId,
                                pageId: tempPageId,
                                contentType: 'scheduling',
                                collectionId: scheduleCollectionId,
                                start: defaultStartOfThisWeek,
                                end: defaultStartOfNextWeek,
                                nextToBlockId: nextToBlockId,
                                proposedTitle: proposedTitle,
                            })];
                    case 1:
                        resp = _a.sent();
                        if (!resp)
                            return [2 /*return*/];
                        addBlockToPage(tempPageId, {
                            id: resp.id,
                            contentType: 'scheduling',
                            title: proposedTitle,
                        }, nextToBlockId);
                        return [2 /*return*/];
                }
            });
        }); };
    }, []);
    /**
     * create custom collection block
     *
     * */
    var createCollection = useCallback(function (proposedTitle, options) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, _a, existingCollectionId, nextToBlockId, newPageName, resp;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    _a = options || {}, existingCollectionId = _a.existingCollectionId, nextToBlockId = _a.nextToBlockId, newPageName = _a.newPageName;
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            pageId: tempPageId,
                            proposedTitle: proposedTitle, // ? Used as table name for new collections; used as block name for refCollections
                            contentType: 'collection',
                            collectionId: existingCollectionId,
                            nextToBlockId: nextToBlockId,
                        })];
                case 1:
                    resp = _b.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'collection',
                        title: proposedTitle,
                    }, nextToBlockId);
                    if (newPageName) {
                        updatePageTitle(newPageName, true);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var createReportBlock = useCallback(function (proposedTitle, reportBuilderData, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            pageId: tempPageId,
                            contentType: 'report',
                            settings: reportBuilderData.settings,
                            dateDetails: reportBuilderData.dateDetails,
                            nextToBlockId: nextToBlockId,
                            proposedTitle: proposedTitle,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'report',
                        title: proposedTitle,
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    var createNewsFeedBlock = useCallback(function (nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            accountId: accountId,
                            pageId: tempPageId,
                            contentType: 'newsfeed',
                            nextToBlockId: nextToBlockId,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'newsfeed',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId]);
    var createButtonBlock = useCallback(function (nextToBlockId, buttonLabel, buttonType, link) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            pageId: tempPageId,
                            contentType: 'button',
                            nextToBlockId: nextToBlockId,
                            label: buttonLabel,
                            type: buttonType,
                            link: link,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'button',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var createShiftRequestBlock = useCallback(function (proposedTitle, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            accountId: accountId,
                            contentType: 'shift_requests',
                            pageId: tempPageId,
                            proposedTitle: proposedTitle,
                            nextToBlockId: nextToBlockId,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'shift_requests',
                        title: proposedTitle,
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, useCreateNewBlockInPage]);
    var createShiftReleaseApprovalBlock = useCallback(function (proposedTitle, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            accountId: accountId,
                            contentType: 'release_requests',
                            pageId: tempPageId,
                            proposedTitle: proposedTitle,
                            nextToBlockId: nextToBlockId,
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'release_requests',
                        title: proposedTitle,
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, [accountId, useCreateNewBlockInPage]);
    var createMetricBarBlock = useCallback(function (data, nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, newBlockRequest, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    newBlockRequest = {
                        accountId: accountId,
                        pageId: tempPageId,
                        contentType: 'metric_bar',
                        nextToBlockId: nextToBlockId,
                        metricBarDataView: {
                            staticDashboardMetrics: data,
                            dynamicDashboardMetrics: null,
                        },
                    };
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync(newBlockRequest)];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'metric_bar',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, [accountId]);
    var createPayPeriodBlock = useCallback(function (nextToBlockId) { return __awaiter(void 0, void 0, void 0, function () {
        var tempPageId, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempPageId = cloneDeep(pageIdRef.current);
                    if (!tempPageId)
                        return [2 /*return*/];
                    return [4 /*yield*/, useCreateNewBlockInPage.mutateAsync({
                            accountId: accountId,
                            pageId: tempPageId,
                            contentType: 'pay_periods',
                            nextToBlockId: nextToBlockId,
                            proposedTitle: 'Pay Periods',
                        })];
                case 1:
                    resp = _a.sent();
                    if (!resp)
                        return [2 /*return*/];
                    addBlockToPage(tempPageId, {
                        id: resp.id,
                        contentType: 'pay_periods',
                        title: 'Pay Periods',
                    }, nextToBlockId);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var openUnassignShiftsModal = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (recordId) { return __awaiter(void 0, void 0, void 0, function () {
            var timeoffRecordInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTimeoffRecordInfo(recordId)];
                    case 1:
                        timeoffRecordInfo = _a.sent();
                        if (timeoffRecordInfo) {
                            set(timeOffRecordForUnassignShiftsState, __assign(__assign({}, timeoffRecordInfo), { recordId: recordId }));
                        }
                        return [2 /*return*/];
                }
            });
        }); };
    }, []);
    var showExportMsg = useCallback(function (path) {
        message.info({
            content: (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "font-medium text-white", children: "We are processing your export. You can track progress and download the file from the Files Hub." }), _jsx(Button, { className: "GoToFilesViewBtn", onClick: function () {
                            navigate({ pathname: "".concat(path) });
                            message.destroy();
                        }, size: "small", children: "Go to Files" })] })),
            className: 'ExportMsgContainer',
            duration: 10,
        });
    }, []);
    return {
        updatePageDescription: updatePageDescription,
        updatePageTitle: updatePageTitle,
        updatePageCoverImage: updatePageCoverImage,
        createCollectionFromTemplate: createCollectionFromTemplate,
        createMarkdownBlock: createMarkdownBlock,
        createImgBlock: createImgBlock,
        createVideoBlock: createVideoBlock,
        createGeomapBlock: createGeomapBlock,
        createScheduleBlock: createScheduleBlock,
        createCollection: createCollection,
        createReportBlock: createReportBlock,
        createNewsFeedBlock: createNewsFeedBlock,
        createButtonBlock: createButtonBlock,
        createShiftRequestBlock: createShiftRequestBlock,
        createShiftReleaseApprovalBlock: createShiftReleaseApprovalBlock,
        createMetricBarBlock: createMetricBarBlock,
        createPayPeriodBlock: createPayPeriodBlock,
        createHeadcountBlock: createHeadcountBlock,
        openUnassignShiftsModal: openUnassignShiftsModal,
        showExportMsg: showExportMsg,
    };
};
export { usePage };
