import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ActionCheckMarkIcon } from 'src/utils/icons/ActionCheckMarkIcon';
import { WarningIcon } from 'src/utils/icons/WarningIcon';
var SUCCESS_MESSAGE = 'Your pay rates are successfully imported. We recommend checking the rates table for confirmation.';
var ERROR_MESSAGE = 'We’ve encountered some problem in importing your pay rates. Kindly check your file and try again.';
var ImportResultSection = function (_a) {
    var isSuccess = _a.isSuccess;
    return (_jsxs("div", { className: "flex gap-2 items-center", children: [isSuccess ? _jsx(ActionCheckMarkIcon, {}) : _jsx(WarningIcon, {}), _jsx("div", { className: "text-base font-semibold", children: isSuccess ? SUCCESS_MESSAGE : ERROR_MESSAGE })] }));
};
export default React.memo(ImportResultSection);
