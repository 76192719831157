var _a;
export var HelpArticleType;
(function (HelpArticleType) {
    HelpArticleType[HelpArticleType["BREAK_SETTING"] = 0] = "BREAK_SETTING";
    HelpArticleType[HelpArticleType["OVERTIME_SETTING"] = 1] = "OVERTIME_SETTING";
    HelpArticleType[HelpArticleType["PAY_PERIOD_SETTING"] = 2] = "PAY_PERIOD_SETTING";
    HelpArticleType[HelpArticleType["PAY_RATE_SETTING"] = 3] = "PAY_RATE_SETTING";
    HelpArticleType[HelpArticleType["TIME_CLOCK_SETTING"] = 4] = "TIME_CLOCK_SETTING";
    HelpArticleType[HelpArticleType["TIME_OFF_SETTING"] = 5] = "TIME_OFF_SETTING";
    HelpArticleType[HelpArticleType["GEOFENCE_SETTING"] = 6] = "GEOFENCE_SETTING";
    HelpArticleType[HelpArticleType["DOCUSIGN_SETUP"] = 7] = "DOCUSIGN_SETUP";
    HelpArticleType[HelpArticleType["ACTIVITY_FEED"] = 8] = "ACTIVITY_FEED";
    HelpArticleType[HelpArticleType["SCHEDULING_TEMPLATE"] = 9] = "SCHEDULING_TEMPLATE";
    HelpArticleType[HelpArticleType["PUBLISH_SHIFT"] = 10] = "PUBLISH_SHIFT";
    HelpArticleType[HelpArticleType["PAY_PERIOD"] = 11] = "PAY_PERIOD";
    HelpArticleType[HelpArticleType["ADD_NEW_BLOCK"] = 12] = "ADD_NEW_BLOCK";
    HelpArticleType[HelpArticleType["RECORD_ACTION"] = 13] = "RECORD_ACTION";
    HelpArticleType[HelpArticleType["CREATE_SCHEMA"] = 14] = "CREATE_SCHEMA";
    HelpArticleType[HelpArticleType["TIME_OFF_TABLE"] = 15] = "TIME_OFF_TABLE";
})(HelpArticleType || (HelpArticleType = {}));
export var HelpDrawerLinkedArticleMap = (_a = {},
    _a[HelpArticleType.BREAK_SETTING] = '6540320',
    _a[HelpArticleType.OVERTIME_SETTING] = '6540379',
    _a[HelpArticleType.PAY_PERIOD_SETTING] = '6539735',
    _a[HelpArticleType.PAY_RATE_SETTING] = '6540060',
    _a[HelpArticleType.TIME_CLOCK_SETTING] = '6542838',
    _a[HelpArticleType.TIME_OFF_SETTING] = '6540444',
    _a[HelpArticleType.GEOFENCE_SETTING] = '6535468',
    _a[HelpArticleType.ACTIVITY_FEED] = '6505994',
    _a[HelpArticleType.DOCUSIGN_SETUP] = '6520600',
    _a[HelpArticleType.SCHEDULING_TEMPLATE] = '5924411',
    _a[HelpArticleType.PUBLISH_SHIFT] = '5901440',
    _a[HelpArticleType.PAY_PERIOD] = '6545332',
    _a[HelpArticleType.ADD_NEW_BLOCK] = '5906009',
    _a[HelpArticleType.RECORD_ACTION] = '6505836',
    _a[HelpArticleType.CREATE_SCHEMA] = '6505881',
    _a[HelpArticleType.TIME_OFF_TABLE] = '6536888',
    _a);
