var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ApprovedShiftTagIconSvg = function () { return (_jsxs("svg", { width: "100%", height: "100%", viewBox: "0 0 34 35", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "1.5", y: "2.5", width: "30", height: "30", rx: "15", fill: "none" }), _jsx("path", { d: "M13.7185 22.063C13.4419 21.7782 13.4419 21.3165 13.7185 21.0318L21.9038 12.6057C22.1805 12.3209 22.629 12.3209 22.9056 12.6057L23.9073 13.6369C24.1839 13.9216 24.1839 14.3833 23.9073 14.6681L15.7219 23.0942C15.4453 23.3789 14.9968 23.3789 14.7202 23.0942L13.7185 22.063Z", fill: "currentColor" }), _jsx("path", { d: "M16.6112 22.0632C16.8878 21.7785 16.8878 21.3168 16.6112 21.032L12.4209 16.7185C12.1443 16.4337 11.6958 16.4337 11.4191 16.7185L10.4174 17.7496C10.1408 18.0344 10.1408 18.4961 10.4174 18.7808L14.6077 23.0944C14.8843 23.3792 15.3328 23.3792 15.6095 23.0944L16.6112 22.0632Z", fill: "currentColor" })] })); };
export var ApprovedShiftTagIcon = function (props) { return (_jsx(Icon, __assign({ component: ApprovedShiftTagIconSvg }, props))); };
