import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CHAT_FEATURE_GATING_KEY } from 'src/components/FeatureGatingKeyConstant';
import { currentGeneralPermissions } from 'src/state';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
var NewsFeedBanner = function () {
    var navigate = useNavigate();
    var generalPermissions = useRecoilValue(currentGeneralPermissions);
    var isChatFeatureEnabled = useRecoilValue(featureEnableStatusState(CHAT_FEATURE_GATING_KEY));
    var redirectToTeamChat = useCallback(function () {
        // Only redirect when chat feature is enabled and user has chat permission
        if (isChatFeatureEnabled && (generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.CHAT)) {
            navigate('/home/team_chat');
        }
    }, [generalPermissions === null || generalPermissions === void 0 ? void 0 : generalPermissions.CHAT, isChatFeatureEnabled, navigate]);
    return (_jsxs("div", { className: "mb-3 rounded-xl bg-azure-50 px-5 py-3 text-xs font-medium", children: ["Using the feature below, you can send push notifications to your team members. These can be found in the notification icon on their mobile dashboard. If you want to broadcast or communicate via chat messages,", ' ', _jsxs("span", { className: "font-semibold", children: ["navigate to the", ' ', _jsx("span", { className: "cursor-pointer text-blue-500", onClick: redirectToTeamChat, children: "Team Chat" }), ' ', "dashboard on the left nav."] })] }));
};
export default React.memo(NewsFeedBanner);
