import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { schemaFormulaState } from 'src/state/atoms/schemaState';
import { evaluateFormulaSelector } from 'src/state/selectors/withFormulaState';
import DummyTextCell from '../DummyTextCell';
var FormulaCellHOC = function (props) {
    var cellPos = props.cellPos, canEdit = props.canEdit;
    // get state from selectorFamily
    var formula = useRecoilValue(schemaFormulaState(cellPos.schemaId));
    var formulaVal = useRecoilValue(evaluateFormulaSelector({ cellPos: cellPos, formulaStack: formula === null || formula === void 0 ? void 0 : formula.expression })) ||
        '0';
    return _jsx(DummyTextCell, { text: formulaVal, canEdit: canEdit });
};
export default React.memo(FormulaCellHOC);
