var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { cloneDeep } from 'lodash';
import { evaluate } from 'mathjs';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { getReportCellValue } from 'src/services';
import { reportMetadataState, reportTableState, reportBuilderState } from 'src/state';
import { useReportApi } from '../api';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useReport = function () {
    var saveReportBuilderData = useReportApi().saveReportBuilderData;
    var setReportBlock = useRecoilCallback(function (_a) {
        var set = _a.set;
        return function (reportId, reportBlock) {
            set(reportMetadataState(reportId), reportBlock.reportMetadata);
            set(reportTableState(reportId), reportBlock.table);
            set(reportBuilderState(reportId), reportBlock.report);
        };
    }, []);
    var saveReportBuilderSettings = useCallback(function (reportId, reportBuilderData) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveReportBuilderData(__assign({ reportId: reportId }, reportBuilderData))];
                case 1:
                    data = _a.sent();
                    if (data) {
                        setReportBlock(reportId, data);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    /**
     * Builds the metric report cells for a row. Mutates the `innerRecord` that gets passed
     */
    var buildMetricReportCells = useCallback(function (innerRecord, row, outerColumns, metricColumns, nonFormulaMetricsLength) {
        outerColumns.forEach(function (dateColumn, dateIdx) {
            var prevFormulaValues = [];
            metricColumns.forEach(function (metricColumn, metricIdx) {
                var _a, _b;
                var columnId = "".concat(dateIdx, "-").concat(dateColumn, "-").concat((_b = (_a = metricColumn.metricType) !== null && _a !== void 0 ? _a : metricColumn.schemaId) !== null && _b !== void 0 ? _b : metricColumn.formula, "-").concat(metricIdx);
                if (metricColumn.metricType || metricColumn.schemaId) {
                    innerRecord[columnId] = row.columns[dateIdx].cells[metricIdx];
                }
                else if (metricColumn.formula) {
                    var formula_1 = cloneDeep(metricColumn.formula);
                    metricColumns.forEach(function (metric, variableIdx) {
                        if (metric.metricType && variableIdx < metricIdx) {
                            formula_1 = formula_1.replaceAll("m".concat(variableIdx + 1), "".concat(getReportCellValue(row.columns[dateIdx].cells[variableIdx])));
                        }
                        else if (metric.schemaId && variableIdx < metricIdx) {
                            formula_1 = formula_1.replaceAll("c".concat(variableIdx - nonFormulaMetricsLength + 1), "".concat(getReportCellValue(row.columns[dateIdx].cells[variableIdx])));
                        }
                        else if (metric.formula && variableIdx < metricIdx) {
                            formula_1 = formula_1.replaceAll("f".concat(variableIdx - nonFormulaMetricsLength + 1), "".concat(prevFormulaValues[variableIdx - nonFormulaMetricsLength]));
                        }
                    });
                    var formulaValue = evaluate(formula_1.replace(/[^-()\d/*+.]/g, ''));
                    prevFormulaValues.push(!isNaN(formulaValue) ? formulaValue : 0);
                    var formulaCell = {
                        formula: !isNaN(formulaValue) ? parseFloat(formulaValue.toFixed(2)) : 0,
                    };
                    innerRecord[columnId] = formulaCell;
                }
            });
        });
    }, []);
    return {
        setReportBlock: setReportBlock,
        saveReportBuilderSettings: saveReportBuilderSettings,
        buildMetricReportCells: buildMetricReportCells,
    };
};
export { useReport as useReportComponent };
