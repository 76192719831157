import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Select, DatePicker } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { reportDateRangeState, reportTimespanState, reportTimeZoneSelector } from 'src/state';
import { useTimeZone } from 'src/hooks/component';
import moment from 'moment';
import { TrendReportDatePickerType, TrendReportTimespan, TrendReportTimespanDisplayText } from '..';
var Option = Select.Option;
var RangePicker = DatePicker.RangePicker;
var ReportFixedDateRangeSelector = function (_a) {
    var reportId = _a.reportId;
    var _b = useRecoilState(reportTimespanState(reportId)), timespan = _b[0], setTimespan = _b[1];
    var _c = useRecoilState(reportDateRangeState(reportId)), dateRange = _c[0], setDateRange = _c[1];
    var reportTimeZone = useRecoilValue(reportTimeZoneSelector(reportId));
    var _d = useState(dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate), newStartDate = _d[0], setNewStartDate = _d[1];
    var _e = useState(dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate), newEndDate = _e[0], setNewEndDate = _e[1];
    var newStartDateRef = useRef(newStartDate);
    var newEndDateRef = useRef(newStartDate);
    useEffect(function () {
        setNewStartDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate);
    }, [dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate]);
    useEffect(function () {
        setNewEndDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate);
    }, [dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate]);
    useEffect(function () {
        newStartDateRef.current = newStartDate;
    }, [newStartDate]);
    useEffect(function () {
        newEndDateRef.current = newEndDate;
    }, [newEndDate]);
    var timeZone = useTimeZone().timeZone;
    var unit = useMemo(function () {
        return timespan === TrendReportTimespan.DAY
            ? 'day'
            : timespan === TrendReportTimespan.WEEK
                ? 'week'
                : timespan === TrendReportTimespan.MONTH
                    ? 'month'
                    : 'day'; // default to day for when Time Based Report is off
    }, [timespan]);
    var onTimespanChange = useCallback(function (newTimespan) {
        setNewStartDate(function (prev) { return moment(prev).tz(timeZone).startOf(unit).toISOString(); });
        setNewEndDate(function (prev) { return moment(prev).tz(timeZone).endOf(unit).toISOString(); });
        setTimespan(newTimespan);
        setTimeout(function () {
            setDateRange({
                startDate: newStartDateRef.current,
                endDate: newEndDateRef.current,
            });
        }, 0);
    }, [setDateRange, setTimespan, timeZone, unit]);
    var onDateRangeChange = useCallback(function (momentDates) {
        if (!momentDates)
            return;
        setNewStartDate(momentDates[0].tz(timeZone).startOf(unit).toISOString());
        setNewEndDate(momentDates[1].tz(timeZone).endOf(unit).toISOString());
    }, [timeZone, unit]);
    var onPickerClose = useCallback(function () {
        if (!newStartDateRef.current || !newEndDateRef.current)
            return;
        setDateRange({ startDate: newStartDateRef.current, endDate: newEndDateRef.current });
    }, [setDateRange]);
    return (_jsxs("div", { className: "flex h-8", children: [timespan && (_jsx(Select, { value: timespan, onChange: onTimespanChange, className: "w-[100px]", children: Object.values(TrendReportTimespan).map(function (ts) { return (_jsx(Option, { value: ts, children: TrendReportTimespanDisplayText[ts] }, ts)); }) })), _jsx(RangePicker, { className: "ml-2 min-w-[240px] rounded-md", picker: TrendReportDatePickerType[timespan !== null && timespan !== void 0 ? timespan : TrendReportTimespan.DAY], value: [
                    moment
                        .utc(newStartDate)
                        .utcOffset(moment.tz(reportTimeZone).utcOffset())
                        .tz(timeZone),
                    moment
                        .utc(newEndDate)
                        .utcOffset(moment.tz(reportTimeZone).utcOffset())
                        .tz(timeZone)
                        .startOf('day'),
                ], onChange: onDateRangeChange, onOpenChange: function (open) {
                    if (!open) {
                        setTimeout(function () { return onPickerClose(); }, 0);
                    }
                }, allowClear: false })] }));
};
export default React.memo(ReportFixedDateRangeSelector);
