var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DateOutlinedSvg = function () { return (_jsx("svg", { width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.16659 7.74088C7.89044 7.74088 7.66659 7.51702 7.66659 7.24088V7.07421H5.66659V9.07421H16.3333V7.07421H14.3333V7.24088C14.3333 7.51702 14.1094 7.74088 13.8333 7.74088H13.4999C13.2238 7.74088 12.9999 7.51702 12.9999 7.24088V7.07421H8.99992V7.24088C8.99992 7.51702 8.77606 7.74088 8.49992 7.74088H8.16659ZM12.9999 5.74088H8.99992V4.90755C8.99992 4.6314 8.77606 4.40755 8.49992 4.40755H8.16659C7.89044 4.40755 7.66659 4.6314 7.66659 4.90755V5.74088H4.66659C4.48249 5.74088 4.33325 5.89012 4.33325 6.07421V9.07421V10.4075V17.4075C4.33325 17.5916 4.48249 17.7409 4.66658 17.7409H17.3333C17.5173 17.7409 17.6666 17.5916 17.6666 17.4075V10.4075V9.07421V6.07421C17.6666 5.89012 17.5173 5.74088 17.3333 5.74088H14.3333V4.90755C14.3333 4.6314 14.1094 4.40755 13.8333 4.40755H13.4999C13.2238 4.40755 12.9999 4.6314 12.9999 4.90755V5.74088ZM16.3333 10.4075V16.4075H5.66659V10.4075H16.3333Z", fill: "#A7A8AC" }) })); };
export var DateOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: DateOutlinedSvg }, props))); };
