var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Input, Menu, Modal } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useNaviTreeComponent } from 'src/hooks/component';
import { blockTabsSelector } from 'src/state';
import { disableScroll } from 'src/utils/Collection';
import { TextOutlinedIcon } from 'src/utils/icons/DropdownIcons/TextOutlined';
import { ArrowDownOutlinedIcon } from '../../../../utils/icons/ArrowDownOutline';
import { ArrowUpOutlinedIcon } from '../../../../utils/icons/ArrowUpOutlined';
import { DeleteOutlinedIcon } from '../../../../utils/icons/DropdownIcons/DeleteOutlined';
import ZiraDeletePopconfirm from '../../../../ui/ZiraDeletePopconfirm';
import './BlockActionDropdown.scss';
var BlockActionDropdown = function (props) {
    var _a, _b;
    var blockId = props.blockId, children = props.children, parentElementId = props.parentElementId, moveBlockUpDown = props.moveBlockUpDown, url = props.url, menuChildren = props.menuChildren, setHoverOptionVisible = props.setHoverOptionVisible, isNonTitleBlock = props.isNonTitleBlock;
    var _c = useNaviTreeComponent(), removeNode = _c.removeNode, saveRenameNode = _c.saveRenameNode;
    var _d = useState(false), dropdownVisible = _d[0], setDropdownVisible = _d[1];
    var onDeleteBlock = useCallback(function () {
        removeNode(blockId, url);
    }, [blockId, removeNode, url]);
    var onConfirmDelete = useCallback(function () {
        disableScroll(false);
        setDropdownVisible(false);
        onDeleteBlock();
    }, [onDeleteBlock]);
    var _e = useParams().pageId, pageId = _e === void 0 ? '' : _e;
    var blockTitle = (_b = (_a = useRecoilValue(blockTabsSelector(pageId)).find(function (tab) { return tab.id === blockId; })) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '';
    var _f = useState(blockTitle), newBlockTitle = _f[0], setNewBlockTitle = _f[1];
    var _g = useState(false), isBlockTitleModalVisible = _g[0], setIsBlockTitleModalVisible = _g[1];
    var onUpdateBlockTitle = useCallback(function () {
        saveRenameNode('block', blockId, newBlockTitle, true);
        disableScroll(false);
        setDropdownVisible(false);
        setHoverOptionVisible === null || setHoverOptionVisible === void 0 ? void 0 : setHoverOptionVisible(false);
    }, [blockId, newBlockTitle, saveRenameNode, setHoverOptionVisible]);
    var updateBlockTitlePopover = useCallback(function () { return (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { className: "ml-px", icon: _jsx(TextOutlinedIcon, {}), onClick: function () { return setIsBlockTitleModalVisible(true); }, children: "Update Title" }, "update-title"), _jsx(Menu.Divider, {}), _jsx(Modal, { title: _jsxs(_Fragment, { children: [_jsx("div", { className: "text-lg font-semibold", children: "Update Title" }), _jsx("div", { className: "text-sm", children: "(Having a title will create a navigation tab above)" })] }), open: isBlockTitleModalVisible, centered: true, onOk: function () {
                    setIsBlockTitleModalVisible(false);
                    onUpdateBlockTitle();
                }, onCancel: function () { return setIsBlockTitleModalVisible(false); }, children: _jsx(Input, { defaultValue: newBlockTitle, onChange: function (e) {
                        setNewBlockTitle(e.target.value);
                    }, autoFocus: true }) })] })); }, [isBlockTitleModalVisible, newBlockTitle, onUpdateBlockTitle]);
    var menu = useMemo(function () {
        return (_jsxs(Menu, { className: "BlockActionMenu", onClick: function (e) {
                if (e.key && e.key !== 'update-title') {
                    disableScroll(false);
                    setDropdownVisible(false);
                    setHoverOptionVisible === null || setHoverOptionVisible === void 0 ? void 0 : setHoverOptionVisible(false);
                }
            }, children: [menuChildren, isNonTitleBlock && _jsx(_Fragment, { children: updateBlockTitlePopover() }), _jsx(Menu.Item, { icon: _jsx(ArrowUpOutlinedIcon, {}), onClick: function () { return moveBlockUpDown(true); }, children: "Move Up" }, "move-up"), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { icon: _jsx(ArrowDownOutlinedIcon, {}), onClick: function () { return moveBlockUpDown(false); }, children: "Move Down" }, "move-down"), _jsx(Menu.Divider, {}), _jsx(ZiraDeletePopconfirm, { title: "Are you sure you want to delete this block?", onConfirm: onConfirmDelete, placement: "rightBottom", children: _jsx(Menu.Item, { icon: _jsx(DeleteOutlinedIcon, { className: "ml-px" }), children: _jsx("span", { className: "ml-px text-red-1000", children: "Delete" }) }) })] }));
    }, [
        isNonTitleBlock,
        menuChildren,
        moveBlockUpDown,
        onConfirmDelete,
        setHoverOptionVisible,
        updateBlockTitlePopover,
    ]);
    var dropdownProps = useMemo(function () { return ({
        className: 'BlockActionDropdown',
        overlay: menu,
        trigger: ['click'],
    }); }, [menu]);
    //!MARK: bind to parent element
    useEffect(function () {
        if (parentElementId) {
            var bindParentElement_1 = document.getElementById(parentElementId);
            if (bindParentElement_1) {
                dropdownProps.getPopupContainer = function () { return bindParentElement_1; };
            }
        }
    }, [dropdownProps, parentElementId]);
    return (_jsx(Dropdown, __assign({}, dropdownProps, { open: dropdownVisible, onOpenChange: function (visible) {
            disableScroll(visible);
            setDropdownVisible(visible);
            setHoverOptionVisible === null || setHoverOptionVisible === void 0 ? void 0 : setHoverOptionVisible(visible);
        }, children: children })));
};
export default memo(BlockActionDropdown);
