import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var ACCOUNT_SVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height="14" width="14"><g><polygon points="8.5 13.5 0.5 13.5 0.5 4 4.5 0.5 8.5 4 8.5 13.5" fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round"></polygon><polyline points="8.5 13.5 13.5 13.5 13.5 6.5 8.5 6.5" fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round"></polyline><line x1="4.5" y1="13.5" x2="4.5" y2="11.5" fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round"></line><line x1="3" y1="8.5" x2="6" y2="8.5" fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round"></line><line x1="3" y1="5.5" x2="6" y2="5.5" fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round"></line></g></svg>';
var AccountBroadcastIcon = function (_a) {
    var isLargeIcon = _a.isLargeIcon;
    var iconSize = isLargeIcon ? '40px' : '20px';
    var imgSize = isLargeIcon ? 24 : 12;
    return (_jsx("div", { className: "flex h-[36px] w-[36px] items-center justify-center rounded-[24px] border border-black bg-white text-white", style: {
            width: iconSize,
            height: iconSize,
        }, children: _jsx("img", { height: imgSize, width: imgSize, src: "data:image/svg+xml;utf8,".concat(encodeURIComponent(ACCOUNT_SVG)) }) }));
};
export default React.memo(AccountBroadcastIcon);
