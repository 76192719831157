import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { scheduleVisibleStatsOptionsSelector, scheduleStatsSummaryState, scheduleTableState, scheduleViewTypeSelector, scheduleZonedTimeRangeSelector, } from 'src/state';
import { useScheduleComponent } from 'src/hooks/component';
import { isEqual } from 'lodash';
import { usePrevious } from 'src/hooks/component/usePrevious';
import { ScheduleView } from 'src/services';
import { SchedulingSummaryType } from '../interface';
import ScheduleSummaryTable from './ScheduleSummaryTable';
var ScheduleStatsSummary = function (_a) {
    var _b;
    var blockId = _a.blockId, widths = _a.widths;
    var scheduleStatsSummaryData = useRecoilValue(scheduleStatsSummaryState(blockId));
    var table = useRecoilValue(scheduleTableState(blockId));
    var zonedTimeRange = useRecoilValue(scheduleZonedTimeRangeSelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var statsOptions = useRecoilValue(scheduleVisibleStatsOptionsSelector(blockId));
    var prevStatsOptions = usePrevious(statsOptions);
    var getScheduleStatsSummaryData = useScheduleComponent().getScheduleStatsSummaryData;
    useEffect(function () {
        if (table && ![(ScheduleView.DAY, ScheduleView.MONTH)].includes(scheduleView)) {
            getScheduleStatsSummaryData(blockId, zonedTimeRange[0], zonedTimeRange[zonedTimeRange.length - 1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table]);
    useEffect(function () {
        if (!isEqual(statsOptions, prevStatsOptions) &&
            ![(ScheduleView.DAY, ScheduleView.MONTH)].includes(scheduleView)) {
            getScheduleStatsSummaryData(blockId, zonedTimeRange[0], zonedTimeRange[zonedTimeRange.length - 1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statsOptions]);
    return (_jsx(ScheduleSummaryTable, { blockId: blockId, type: SchedulingSummaryType.SCHEDULING_STATS, tableData: (_b = scheduleStatsSummaryData === null || scheduleStatsSummaryData === void 0 ? void 0 : scheduleStatsSummaryData.stats) !== null && _b !== void 0 ? _b : [], widths: widths }));
};
export default React.memo(ScheduleStatsSummary);
