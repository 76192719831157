var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var DownArrowOutlinedSvg = function () { return (_jsxs("svg", { width: "10", height: "11", viewBox: "0 0 10 11", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("g", { clipPath: "url(#clip0_6894_7520)", children: _jsx("path", { d: "M0.893703 2.84733C0.754127 2.84733 0.612336 2.90436 0.506171 3.01608C0.293841 3.23952 0.293841 3.60866 0.506171 3.8317L4.47652 8.0098C4.57897 8.11761 4.72374 8.17855 4.86405 8.17855C5.00325 8.17855 5.14171 8.12543 5.25158 8.0098L9.2168 3.8371C9.43878 3.61718 9.44175 3.23905 9.22942 3.01601C9.01709 2.79257 8.66631 2.79257 8.45436 3.01601L4.87153 6.79371L1.28129 3.01561C1.17513 2.90389 1.03333 2.84686 0.893761 2.84686L0.893703 2.84733Z", fill: "#BDBDC2" }) }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_6894_7520", children: _jsx("rect", { width: "9.50276", height: "10", fill: "white", transform: "translate(0.115234 0.513306)" }) }) })] })); };
export var DownArrowOutlinedIcon = function (props) { return (_jsx(Icon, __assign({ component: DownArrowOutlinedSvg }, props))); };
