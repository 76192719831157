var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var BlackPlusIconSvg = function () { return (_jsxs("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("path", { d: "M1.33325 7.66666C1.33325 7.48257 1.48249 7.33333 1.66659 7.33333H14.3333C14.5173 7.33333 14.6666 7.48257 14.6666 7.66666V8.33333C14.6666 8.51742 14.5173 8.66666 14.3333 8.66666H1.66658C1.48249 8.66666 1.33325 8.51742 1.33325 8.33333V7.66666Z", fill: "black" }), _jsx("path", { d: "M7.33325 1.66666C7.33325 1.48257 7.48249 1.33333 7.66659 1.33333H8.33325C8.51735 1.33333 8.66659 1.48257 8.66659 1.66666V14.3333C8.66659 14.5174 8.51735 14.6667 8.33325 14.6667H7.66659C7.48249 14.6667 7.33325 14.5174 7.33325 14.3333V1.66666Z", fill: "black" })] })); };
export var BlackPlusIcon = function (props) { return (_jsx(Icon, __assign({ component: BlackPlusIconSvg }, props))); };
