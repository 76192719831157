export var TaskTab;
(function (TaskTab) {
    TaskTab["TEMPLATES"] = "TEMPLATES";
    TaskTab["GROUPS"] = "GROUPS";
    TaskTab["RULES"] = "RULES";
})(TaskTab || (TaskTab = {}));
export var TaskTemplateType;
(function (TaskTemplateType) {
    TaskTemplateType["WORKSPACE_LINK"] = "WORKSPACE_LINK";
    TaskTemplateType["PROFILE_DATA"] = "PROFILE_DATA";
    TaskTemplateType["EXTERNAL_LINK"] = "EXTERNAL_LINK";
    TaskTemplateType["VIEW_ONLY"] = "VIEW_ONLY";
    TaskTemplateType["SIGNING_PARTNER"] = "SIGNING_PARTNER";
})(TaskTemplateType || (TaskTemplateType = {}));
export var ProfileTaskSchemaType;
(function (ProfileTaskSchemaType) {
    ProfileTaskSchemaType["REQUIRED"] = "REQUIRED";
    ProfileTaskSchemaType["OPTIONAL"] = "OPTIONAL";
    ProfileTaskSchemaType["LOCKED"] = "LOCKED";
    ProfileTaskSchemaType["HIDDEN"] = "HIDDEN";
    ProfileTaskSchemaType["LINK_FROM_TASK"] = "LINK_FROM_TASK";
})(ProfileTaskSchemaType || (ProfileTaskSchemaType = {}));
export var TaskRuleConditionOperator;
(function (TaskRuleConditionOperator) {
    TaskRuleConditionOperator["AND"] = "AND";
    TaskRuleConditionOperator["OR"] = "OR";
})(TaskRuleConditionOperator || (TaskRuleConditionOperator = {}));
export var TaskRuleAssignedTaskType;
(function (TaskRuleAssignedTaskType) {
    TaskRuleAssignedTaskType["TEMPLATE"] = "TEMPLATE";
    TaskRuleAssignedTaskType["GROUP"] = "GROUP";
})(TaskRuleAssignedTaskType || (TaskRuleAssignedTaskType = {}));
export var TaskRuleJobStatus;
(function (TaskRuleJobStatus) {
    TaskRuleJobStatus["OPEN"] = "OPEN";
    TaskRuleJobStatus["PLACED"] = "PLACED";
    TaskRuleJobStatus["ACTIVE"] = "ACTIVE";
})(TaskRuleJobStatus || (TaskRuleJobStatus = {}));
