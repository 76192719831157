var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var FilePreviewSvg = function () { return (_jsxs("svg", { width: "36", height: "37", viewBox: "0 0 36 37", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { y: "0.5", width: "36", height: "36", rx: "8", fill: "#F2F8FA" }), _jsx("rect", { x: "0.5", y: "1", width: "35", height: "35", rx: "7.5", stroke: "black", strokeOpacity: "0.2" }), _jsx("path", { d: "M18.6666 11.833H14C13.6463 11.833 13.3072 11.9735 13.0572 12.2235C12.8071 12.4736 12.6666 12.8127 12.6666 13.1663V23.833C12.6666 24.1866 12.8071 24.5258 13.0572 24.7758C13.3072 25.0259 13.6463 25.1663 14 25.1663H22C22.3536 25.1663 22.6927 25.0259 22.9428 24.7758C23.1928 24.5258 23.3333 24.1866 23.3333 23.833V16.4997L18.6666 11.833Z", stroke: "#818A9B", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M18.6666 11.833V16.4997H23.3333", stroke: "#818A9B", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] })); };
export var FilePreview = function (props) { return (_jsx(Icon, __assign({ component: FilePreviewSvg }, props))); };
