import { jsx as _jsx } from "react/jsx-runtime";
import { trim } from 'lodash';
import { CalendarIcon } from 'src/utils/icons/DashboardIcons/CalendarIcon';
import { ClockOutlined } from 'src/utils/icons/DashboardIcons/ClockOutlined';
import { UsersIcon } from 'src/utils/icons/DashboardIcons/UserIcon';
import { ApplicationTrackingIcon } from 'src/utils/icons/SelfOnboarding/ApplicationTrackingIcon';
import { DocumentIcon } from 'src/utils/icons/SelfOnboarding/DocumentIcon';
import { PayrollIcon } from 'src/utils/icons/SelfOnboarding/PayrollIcon';
import { ReportsIcon } from 'src/utils/icons/SelfOnboarding/ReportsIcon';
export var IndustryType;
(function (IndustryType) {
    IndustryType["CONSTRUCTION"] = "CONSTRUCTION";
    IndustryType["EDUCATION"] = "EDUCATION";
    IndustryType["FIELD_SERVICES"] = "FIELD_SERVICES";
    IndustryType["FOOD_AND_BEVERAGE"] = "FOOD_AND_BEVERAGE";
    IndustryType["HEALTHCARE"] = "HEALTHCARE";
    IndustryType["RETAIL"] = "RETAIL";
    IndustryType["OTHER"] = "OTHER";
    IndustryType["MANUFACTURING"] = "MANUFACTURING";
})(IndustryType || (IndustryType = {}));
export var IndustryCategoryOptions = [
    {
        key: IndustryType.CONSTRUCTION,
        displayName: 'Construction',
    },
    {
        key: IndustryType.EDUCATION,
        displayName: 'Education',
    },
    {
        key: IndustryType.FIELD_SERVICES,
        displayName: 'Field Services',
    },
    {
        key: IndustryType.FOOD_AND_BEVERAGE,
        displayName: 'Food and Beverage',
    },
    {
        key: IndustryType.HEALTHCARE,
        displayName: 'Healthcare',
    },
    {
        key: IndustryType.MANUFACTURING,
        displayName: 'Manufacturing',
    },
    {
        key: IndustryType.RETAIL,
        displayName: 'Retail',
    },
    {
        key: IndustryType.OTHER,
        displayName: 'Other',
    },
];
export var CompanySize;
(function (CompanySize) {
    CompanySize["ZERO_TO_TEN"] = "ZERO_TO_TEN";
    CompanySize["TEN_TO_FIFTY"] = "TEN_TO_FIFTY";
    CompanySize["FIFTY_TO_HUNDRED"] = "FIFTY_TO_HUNDRED";
    CompanySize["HUNDRED_TO_FIVE_HUNDRED"] = "HUNDRED_TO_FIVE_HUNDRED";
    CompanySize["FIVE_HUNDRED_TO_THOUSAND"] = "FIVE_HUNDRED_TO_THOUSAND";
    CompanySize["OVER_THOUSAND"] = "OVER_THOUSAND";
})(CompanySize || (CompanySize = {}));
export var CompanySizeOptions = [
    {
        key: CompanySize.ZERO_TO_TEN,
        displayValue: '0-10',
    },
    {
        key: CompanySize.TEN_TO_FIFTY,
        displayValue: '10-50',
    },
    {
        key: CompanySize.FIFTY_TO_HUNDRED,
        displayValue: '50-100',
    },
    {
        key: CompanySize.HUNDRED_TO_FIVE_HUNDRED,
        displayValue: '100-500',
    },
    {
        key: CompanySize.FIVE_HUNDRED_TO_THOUSAND,
        displayValue: '500-1000',
    },
    {
        key: CompanySize.OVER_THOUSAND,
        displayValue: '1000+',
    },
];
export var WorkspaceCardType;
(function (WorkspaceCardType) {
    WorkspaceCardType["TEAM_MANAGEMENT"] = "TEAM_MANAGEMENT";
    WorkspaceCardType["SCHEDULING"] = "SCHEDULING";
    WorkspaceCardType["TIME_AND_ATTENDANCE"] = "TIME_AND_ATTENDANCE";
    WorkspaceCardType["APPLICANT_TRACKING"] = "APPLICANT_TRACKING";
    WorkspaceCardType["REPORTS"] = "REPORTS";
    WorkspaceCardType["PAYROLL_TRACKING"] = "PAYROLL_TRACKING";
    WorkspaceCardType["DOC_MANAGEMENT"] = "DOC_MANAGEMENT";
})(WorkspaceCardType || (WorkspaceCardType = {}));
export var WorkspaceCards = [
    {
        key: WorkspaceCardType.TEAM_MANAGEMENT,
        title: 'Team Management',
        icon: _jsx(UsersIcon, { style: { width: '28px' } }),
    },
    {
        key: WorkspaceCardType.SCHEDULING,
        title: 'Scheduling',
        icon: _jsx(CalendarIcon, { style: { width: '28px' } }),
    },
    {
        key: WorkspaceCardType.TIME_AND_ATTENDANCE,
        title: 'Time & Attendance',
        icon: _jsx(ClockOutlined, { style: { width: '32px' } }),
    },
    {
        key: WorkspaceCardType.APPLICANT_TRACKING,
        title: 'Applicant Tracking',
        icon: _jsx(ApplicationTrackingIcon, {}),
    },
    {
        key: WorkspaceCardType.REPORTS,
        title: 'Reports',
        icon: _jsx(ReportsIcon, {}),
    },
    {
        key: WorkspaceCardType.PAYROLL_TRACKING,
        title: 'Payroll tracking',
        icon: _jsx(PayrollIcon, {}),
    },
    {
        key: WorkspaceCardType.DOC_MANAGEMENT,
        title: 'Doc Management',
        icon: _jsx(DocumentIcon, {}),
    },
];
export var LoadingPageHeaderText = [
    {
        text: 'Initializing your',
        textWithTransitionColor: 'account',
    },
    {
        text: 'Adding',
        textWithTransitionColor: 'workspaces',
    },
    {
        text: 'Building your',
        textWithTransitionColor: 'automations',
    },
    {
        text: 'Adding',
        textWithTransitionColor: 'sample data',
    },
];
export var DefaultSelfOnboardingCreateAccountInfo = {
    firstName: '',
    lastName: '',
    accountName: '',
    email: '',
    phone: '',
    timezone: '',
    workspaces: [],
    adminEmails: [],
};
// Make sure required string in account creation is not full of empty space
export var requiredStringInputExtraValidation = function (value, inputField) {
    if (!value) {
        return Promise.resolve();
    }
    else if (!trim(value, ' ')) {
        return Promise.reject(new Error("Please enter a valid ".concat(inputField, "!")));
    }
    else {
        return Promise.resolve();
    }
};
