import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import UserAvatar from 'src/components/User/UserAvatar';
import { useDashboardComponent } from 'src/hooks/component/useDashboard';
import { useRecordDetail } from 'src/hooks/component';
var SnapshotShiftCard = function (_a) {
    var index = _a.index, style = _a.style, cardColor = _a.cardColor, shiftInfo = _a.shiftInfo, setRowHeight = _a.setRowHeight;
    var rowRef = useRef(null);
    useEffect(function () {
        if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
        // eslint-disable-next-line
    }, [rowRef]);
    var openRecordDetail = useRecordDetail().openRecordDetail;
    var renderShiftDataTags = useDashboardComponent().renderShiftDataTags;
    return (_jsx("div", { className: "SimpleShiftCardsContainer", style: style, children: _jsxs("div", { className: "rounded-[10px] p-3", style: {
                backgroundColor: cardColor,
                border: !cardColor ? '1px solid #F4F4F7' : '',
            }, ref: rowRef, onClick: function () { return openRecordDetail(shiftInfo.recordId); }, children: [_jsx("div", { className: "text-xs font-semibold", children: shiftInfo.displayTime }), !isEmpty(shiftInfo.location) && renderShiftDataTags([shiftInfo.location]), !isEmpty(shiftInfo.roles) && renderShiftDataTags(shiftInfo.roles), _jsxs("div", { className: "flex items-center py-1", children: [_jsx(UserAvatar, { name: shiftInfo.assignee.fullName, isCirclular: true, size: 20, avatar: shiftInfo.assignee.avatarUrl }), _jsx("div", { className: "ml-1 text-[12px] font-medium", children: shiftInfo.assignee.fullName })] })] }) }));
};
export default React.memo(SnapshotShiftCard);
