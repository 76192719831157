import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { getTagTextColor } from 'src/utils/Collection';
import { convertToPercentNumber } from 'src/utils/Headcount';
import { SchedulingStatsOperationType } from '../../Interfaces';
import { getScheduleTemplateCountStatColor } from '../Utils';
var ComboStatsValueCell = function (props) {
    var firstValue = props.firstValue, secondValue = props.secondValue, extraSign = props.extraSign, operationType = props.operationType;
    var backgroundColor = getScheduleTemplateCountStatColor(firstValue, secondValue);
    var percentageOperationType = operationType === SchedulingStatsOperationType.PERCENTAGE;
    // Handle 0 denominator, avoid showing NaN
    if (secondValue === 0) {
        return (_jsx("div", { className: "flex items-center justify-between border-r border-gray-30 px-[10px] font-medium text-xs", children: "-" }));
    }
    return (_jsx("div", { className: "flex items-center justify-between border-r border-gray-30 px-[10px] font-medium text-xs", children: percentageOperationType ? (
        // Simple percentage value
        _jsxs("div", { className: "font-medium text-xs", children: [convertToPercentNumber(firstValue / secondValue), "%"] })) : (
        // Value comparison with color coding percentage tag
        _jsxs("div", { className: "flex flex-col", children: [_jsx(ZiraTooltip, { title: "".concat(extraSign ? extraSign : '').concat(firstValue, "/").concat(extraSign ? extraSign : '').concat(secondValue), placement: "top", children: _jsxs("div", { className: "max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap", children: [extraSign, firstValue, _jsxs("span", { className: "text-gray-300", children: ["/", extraSign, secondValue] })] }) }), _jsxs("div", { className: "rounded mt-1 text-xs font-medium", style: {
                        backgroundColor: backgroundColor,
                        color: getTagTextColor(backgroundColor),
                    }, children: [convertToPercentNumber(firstValue / secondValue), "%"] })] })) }));
};
export default React.memo(ComboStatsValueCell);
