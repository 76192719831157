var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Progress, Space, Tag } from 'antd';
import React, { useMemo } from 'react';
import { PayExceptionType } from './Constant';
import './ExceptionsDrawer.scss';
export var PayExceptionInfoMap = (_a = {},
    _a[PayExceptionType.NO_SHOW] = {
        name: 'No Show',
        description: 'Shift is not clocked in',
    },
    _a[PayExceptionType.MISSED_CLOCK_OUT] = {
        name: 'Missed Clock Out',
        description: 'Clocked in shift is not clocked out',
    },
    _a[PayExceptionType.UNSCHEDULED_SHIFT] = {
        name: 'Unscheduled Shifts',
        description: 'Shifts is not scheduled',
    },
    _a[PayExceptionType.OVERTIME_SHIFT] = {
        name: 'Overtime',
        description: 'Shifts is in overtime',
    },
    _a[PayExceptionType.EXCESSIVE_TIME] = {
        name: 'Excessive Time',
        description: 'Shift work time greatly exceeds scheduled time',
    },
    _a);
var ExceptionsDrawer = function (_a) {
    var closeDrawer = _a.closeDrawer, shiftCounts = _a.shiftCounts, matchSummary = _a.exceptionMatchSummary;
    var _b = shiftCounts !== null && shiftCounts !== void 0 ? shiftCounts : {
        correctShiftCount: 0,
        totalShiftCount: 0,
    }, correctShiftCount = _b.correctShiftCount, totalShiftCount = _b.totalShiftCount;
    var correctShifts = useMemo(function () {
        var percent = Math.floor((correctShiftCount / totalShiftCount) * 100) || 0;
        var shiftCountPopover = (_jsxs("div", { children: [_jsx("span", { className: "BoldText", children: correctShiftCount }), " out of", ' ', _jsx("span", { className: "BoldText", children: totalShiftCount }), " shifts"] }));
        return (_jsxs("div", { className: "RightSpacing", children: [_jsxs(Space, { align: "baseline", children: [_jsxs("span", { className: "Percentage BoldText", children: [percent, "%"] }), _jsx("span", { className: "CorrectShifts", children: "Correctness" })] }), _jsx(Popover, { content: shiftCountPopover, arrowPointAtCenter: true, children: _jsx(Progress, { percent: percent, showInfo: false }) })] }));
    }, [correctShiftCount, totalShiftCount]);
    var exceptionMatchSummary = useMemo(function () {
        if (matchSummary) {
            var constraintSummary = Object.fromEntries(Object.entries(matchSummary));
            return Object.fromEntries(Object.entries(constraintSummary));
        }
        else {
            return {};
        }
    }, [matchSummary]);
    var exceptionList = useMemo(function () {
        return (_jsx("div", { className: "PayExceptionList", children: Object.keys(exceptionMatchSummary).map(function (key) {
                var _a;
                var exception = key;
                var matchCount = exceptionMatchSummary[exception];
                return (_jsxs("div", { className: "PayExceptionItem", children: [_jsx("h4", { children: (_a = PayExceptionInfoMap[exception]) === null || _a === void 0 ? void 0 : _a.name }), _jsxs(Tag, { className: "ExceptionTag", color: matchCount > 0 ? 'error' : 'success', children: [matchCount > 0 && matchCount, matchCount <= 0 && _jsx(CheckOutlined, {})] })] }, exception));
            }) }));
    }, [exceptionMatchSummary]);
    return (_jsxs("div", { className: "ExceptionsDrawerWrapper", children: [_jsxs("div", { className: "ExceptionsHeaderWrapper", children: [_jsx("span", { className: "DrawerTitle", children: "Pay Exceptions" }), _jsx(Button, { className: "CloseButton", icon: _jsx(MinusOutlined, {}), onClick: closeDrawer })] }), _jsx(Divider, {}), totalShiftCount ? (_jsx(_Fragment, { children: correctShifts })) : (_jsxs("div", { className: "Placeholder", children: [_jsx("span", { className: "Header", children: "No Shifts" }), _jsx("span", { className: "SubText", children: "Add shifts to pay period to view pay exceptions." })] })), exceptionList] }));
};
export default React.memo(ExceptionsDrawer);
