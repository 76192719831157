import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { useTimeZone } from 'src/hooks/component';
import { tableCollectionIdSelector, scheduleShiftCardBasicInfoSelector, scheduleShowShiftTimesSelector, scheduleTimeZoneSelector, scheduleViewTypeSelector, } from 'src/state';
var ShiftCardDisplayTime = function (_a) {
    var blockId = _a.blockId, recordId = _a.recordId;
    var showDisplayTime = useRecoilValue(scheduleShowShiftTimesSelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var timezone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var timeFormatFn = useTimeZone(timezone).timeFormatFn;
    var _b = useRecoilValue(scheduleShiftCardBasicInfoSelector({
        type: CollectionTableType.TABLE,
        collectionId: collectionId,
        recordId: recordId,
    })), startAt = _b.startAt, endAt = _b.endAt, clockIn = _b.clockIn, clockOut = _b.clockOut;
    if (!showDisplayTime || (!startAt && !clockIn))
        return null;
    return (_jsx("div", { className: 'tagContainer ' + (scheduleView == 'WEEK') ? 'displayedTime' : 'displayedTimeSm', children: _jsxs("span", { children: [timeFormatFn(clockIn || startAt, 'h:mma', false).slice(0, -1), ' - ', clockIn && clockOut
                    ? timeFormatFn(clockOut, 'h:mma', false).slice(0, -1)
                    : endAt
                        ? timeFormatFn(endAt, 'h:mma', false).slice(0, -1)
                        : ''] }) }));
};
export default React.memo(ShiftCardDisplayTime);
