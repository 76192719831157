var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { useFetch } from '.';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
var useProjectApi = function () {
    var customFetch = useFetch().request;
    var accountId = useRecoilValue(accountIdState);
    var getProjects = function () {
        return customFetch({
            method: 'GET',
            url: 'projects/' + accountId,
        });
    };
    var getProjectTrackingRows = function (shiftRid) {
        return customFetch({
            method: 'GET',
            url: 'projects/get_project_tracking_rows/' + shiftRid,
        });
    };
    var saveProject = function (req) {
        return customFetch({
            method: 'POST',
            url: 'projects/save_project_tracking_row',
        }, __assign(__assign({}, req), { accountId: accountId }));
    };
    return {
        getProjects: getProjects,
        getProjectTrackingRows: getProjectTrackingRows,
        saveProject: saveProject,
    };
};
export { useProjectApi };
