import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '../../../App.less';
import './DatePickerPopover.scss';
import { isDateToday } from 'src/utils/DatePicker';
import CalendarHeader from 'src/components/CalendarHeader';
import { Calendar, Menu, Tooltip } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import DateInput from 'src/components/OptimizedTableView/Cell/DateTimeCell/DateInput';
import { ValueDataType } from '../../../shared';
import ZiraPopover from '../../../ui/ZiraPopover';
var DOBDatePicker = function (props) {
    var data = props.data, onEditTime = props.onEditTime, canEdit = props.canEdit, isDetailModal = props.isDetailModal, updateWithDefault = props.updateWithDefault, id = props.id;
    var _a = useState(false), popOverVisible = _a[0], setPopOverVisible = _a[1];
    var defaultStartDate = data.type === ValueDataType.DATETIME
        ? data.dateTime
        : data.from;
    var _b = useState(defaultStartDate ? moment(defaultStartDate).format('MMM D, YYYY') : undefined), startDate = _b[0], setStartDate = _b[1];
    var defaultTimeData = useMemo(function () {
        return defaultStartDate ? moment(defaultStartDate).format('MMM D, YYYY') : '';
    }, [defaultStartDate]);
    var _c = useState(defaultTimeData), defaultDisplayTime = _c[0], setDefaultDisplayTime = _c[1];
    useEffect(function () {
        if (updateWithDefault) {
            setStartDate(defaultStartDate ? moment(defaultStartDate).format('MMM D, YYYY') : undefined);
        }
    }, [updateWithDefault, defaultStartDate]);
    var _d = useState(true), isDateValid = _d[0], setDateValid = _d[1];
    var startDateOnChange = useCallback(function (date) {
        setStartDate(date);
    }, []);
    var dateValidOnChange = useCallback(function (status) {
        setDateValid(status);
    }, []);
    var calendarCellRender = function (date) {
        var isToday = isDateToday(date);
        var extraStyleString = isToday ? '1px solid #1890ff' : '';
        if (date.format('MMM D, YYYY') === startDate) {
            return _jsxs("div", { className: "CalendarStartEndCell", children: [" ", date.date(), " "] });
        }
        return (_jsx("div", { className: "CalendarCell", style: { border: extraStyleString }, children: date.date() }));
    };
    var calendarDataOnSelect = useCallback(function (e) {
        setStartDate(e.format('MMM D, YYYY'));
    }, []);
    var handleClear = useCallback(function () {
        setStartDate(undefined);
    }, []);
    var tableCellDisplayValue = useMemo(function () {
        return startDate || '';
    }, [startDate]);
    var handleSave = useCallback(function () {
        onEditTime(_.split(tableCellDisplayValue, ' → '));
        setPopOverVisible(false);
        setDefaultDisplayTime(tableCellDisplayValue);
    }, [onEditTime, tableCellDisplayValue]);
    var content = (_jsxs("div", { className: "DatePickerContainer", children: [_jsx("div", { className: "DateSettingInput", children: _jsx(Tooltip, { title: "Invalid Input", color: "black", placement: "right", open: !isDateValid, destroyTooltipOnHide: true, children: _jsx(DateInput, { startDate: startDate || moment().format('MMM D, YYYY'), isDateValid: isDateValid, startDateOnChange: startDateOnChange, dateValidOnChange: dateValidOnChange }) }) }), _jsx(Calendar, { className: "CalendarCard", fullscreen: false, onSelect: calendarDataOnSelect, value: startDate ? moment(startDate, 'MMM D, YYYY') : moment(), dateFullCellRender: calendarCellRender, headerRender: CalendarHeader }), _jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: handleClear, children: "Clear" }, "clear"), _jsx(Menu.Item, { onClick: handleSave, className: "flex items-center rounded bg-accent font-semibold text-white", children: "Save" }, "save")] })] }));
    var onVisibleChange = useCallback(function (visible) {
        if (!canEdit)
            return;
        setPopOverVisible(visible);
    }, [canEdit]);
    return (_jsxs(_Fragment, { children: [_jsx(ZiraPopover, { className: "DatePickerPopOver", id: id !== null && id !== void 0 ? id : '', content: content, trigger: "click", destroyTooltipOnHide: true, open: popOverVisible, onLocalOpenChange: onVisibleChange, children: defaultDisplayTime || (_jsx("span", { className: isDetailModal ? 'EmptyContent' : '', children: isDetailModal ? 'Insert Value' : '' })) }), _jsx("div", { className: "fpOverlay", onClick: function () {
                    setPopOverVisible(false);
                }, style: { display: popOverVisible ? 'block' : 'none' } })] }));
};
export default React.memo(DOBDatePicker);
