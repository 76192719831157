var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Select, Skeleton } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useProjectApi } from 'src/hooks/api/useProjectApi';
import { ValueDataType } from 'src/shared';
import moment from 'moment';
import './ProjectTrackingTable.scss';
import { columnIcon } from '../OptimizedTableView/Column/TableHeaderDropdown';
import PlainHeaderDisplay from '../OptimizedTableView/Column/PlainHeaderDisplay';
import ZiraActionButton from '../../ui/ZiraActionButton';
import LogicDateTimeCell from '../OptimizedTableView/Cell/DateTimeCell/LogicDateTimeCell';
import TableBuilder from '../OptimizedTableView/TableBuilder';
var Option = Select.Option;
export var SimpleTimeCell = function (props) {
    var data = props.data, editTime = props.editTime;
    var _a = useState(false), isClicked = _a[0], setIsClicked = _a[1];
    var _b = useState(data), timeData = _b[0], setTimeData = _b[1];
    return (_jsx(LogicDateTimeCell, { data: timeData, editCell: function (newTime) {
            timeData.dateTime = moment(newTime[0]).toISOString();
            setTimeData(timeData);
            editTime(moment(newTime[0]).toISOString());
        }, isClicked: isClicked, setIsClicked: setIsClicked }));
};
var ProjectTrackingTable = function (_a) {
    var shiftRid = _a.shiftRid, defaultData = _a.defaultData;
    var tableData = useState(defaultData || [])[0];
    var _b = useState(), projects = _b[0], setProjects = _b[1];
    var _c = useProjectApi(), getProjects = _c.getProjects, saveProject = _c.saveProject, getProjectTrackingRows = _c.getProjectTrackingRows;
    var _d = useState([]), recordData = _d[0], setRecordData = _d[1];
    var projRef = useRef(projects);
    useEffect(function () {
        projRef.current = projects;
    }, [projects]);
    var parseRecordData = useCallback(function (rows) {
        var tempRecordData = cloneDeep(recordData);
        var tempProjects = cloneDeep(projRef.current);
        rows.map(function (row) {
            var newRecord = {};
            var project = tempProjects === null || tempProjects === void 0 ? void 0 : tempProjects.filter(function (proj) { return proj.recordId == row.projectRid; })[0];
            newRecord[row.id] = {
                id: row.id,
                projectName: (project === null || project === void 0 ? void 0 : project.name) || '',
                clockIn: row.clockIn,
                clockOut: row.clockOut,
            };
            tempRecordData.push(newRecord);
        });
        setRecordData(tempRecordData);
    }, [recordData]);
    var getRecordData = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var rows;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!shiftRid)
                        return [2 /*return*/];
                    return [4 /*yield*/, getProjectTrackingRows(shiftRid)];
                case 1:
                    rows = _a.sent();
                    if (!rows)
                        return [2 /*return*/];
                    parseRecordData(rows);
                    return [2 /*return*/];
            }
        });
    }); }, [getProjectTrackingRows, parseRecordData, shiftRid]);
    var fetchProjects = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var projs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getProjects()];
                case 1:
                    projs = _a.sent();
                    setProjects(projs || []);
                    getRecordData();
                    return [2 /*return*/];
            }
        });
    }); }, [getProjects, getRecordData]);
    useEffect(function () {
        if (!projects)
            fetchProjects();
    }, [fetchProjects, projects]);
    var projectNameSelected = useCallback(function (id, projectId) {
        if (!shiftRid)
            return;
        var record = recordData.filter(function (record) { return Object.keys(record)[0] == id; })[0][id];
        var projectRid = projects === null || projects === void 0 ? void 0 : projects.filter(function (proj) { return proj.id == projectId; })[0].recordId;
        if (!projectRid)
            return;
        var req = {
            id: id,
            projectRid: projectRid,
            shiftRid: shiftRid,
            clockIn: record.clockIn,
            clockOut: record.clockOut,
        };
        saveProject(req);
    }, [projects, recordData, saveProject, shiftRid]);
    var _e = useState(), selectedProjectId = _e[0], setSelectedProjectId = _e[1];
    var projectCell = useCallback(function (value) {
        var _a;
        if (!value)
            return null;
        var selectedProj = (_a = projects === null || projects === void 0 ? void 0 : projects.find(function (proj) { return proj.name == value.projectName; })) === null || _a === void 0 ? void 0 : _a.id;
        return (_jsx(Select, { value: selectedProjectId || selectedProj, className: "ProjectSelectorCellWrapper", onChange: function (val) {
                projectNameSelected(value.id, val);
                setSelectedProjectId(val);
            }, children: projects === null || projects === void 0 ? void 0 : projects.map(function (proj) {
                return (_jsx(Option, { value: proj.id, label: proj.name, children: proj.name }, proj.id));
            }) }));
    }, [projectNameSelected, projects, selectedProjectId]);
    var editTime = useCallback(function (value, time, type) {
        var selectedProject = projects === null || projects === void 0 ? void 0 : projects.find(function (proj) { return proj.name == value.projectName; });
        if (!selectedProject || !shiftRid)
            return;
        var req = {
            id: value.id,
            projectRid: selectedProject.recordId,
            shiftRid: shiftRid,
            clockIn: type == 'clockIn' ? time : value.clockIn,
            clockOut: type == 'clockOut' ? time : value.clockOut,
        };
        saveProject(req);
    }, [projects, saveProject, shiftRid]);
    var simpleTimeCell = useCallback(function (value, type) {
        var clockTime = type == 'clockIn' ? value.clockIn : value.clockOut;
        var data = {
            type: ValueDataType.DATETIME,
            showTime: true,
            dateTime: clockTime,
        };
        return (_jsx(SimpleTimeCell, { data: data, editTime: function (newTime) { return editTime(value, newTime, type); } }));
    }, [editTime]);
    var columnData = useMemo(function () {
        var tempColumns = [];
        tempColumns.push({
            id: 'projectName',
            Header: _jsx(PlainHeaderDisplay, { icon: columnIcon('single'), title: 'Project Name' }),
            accessor: function (record) {
                var values = Object.values(record);
                if (values.length)
                    return values[0];
            },
            minWidth: 200,
            width: 300,
            Cell: function (_a) {
                var value = _a.value;
                return projectCell(value);
            },
        }, {
            id: 'clockIn',
            Header: _jsx(PlainHeaderDisplay, { icon: columnIcon('datetime'), title: 'Clock In' }),
            accessor: function (record) {
                var values = Object.values(record);
                if (values.length)
                    return values[0];
            },
            minWidth: 200,
            width: 200,
            Cell: function (_a) {
                var value = _a.value;
                return simpleTimeCell(value, 'clockIn');
            },
        }, {
            id: 'clockOut',
            Header: _jsx(PlainHeaderDisplay, { icon: columnIcon('datetime'), title: 'Clock Out' }),
            accessor: function (record) {
                var values = Object.values(record);
                if (values.length)
                    return values[0];
            },
            minWidth: 200,
            width: 200,
            Cell: function (_a) {
                var value = _a.value;
                return simpleTimeCell(value, 'clockOut');
            },
        });
        return tempColumns;
    }, [projectCell, simpleTimeCell]);
    var onAddNewRecord = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var tempRecordData, newRecord, newId;
        return __generator(this, function (_a) {
            tempRecordData = cloneDeep(recordData);
            newRecord = {};
            newId = uuidv4();
            newRecord[newId] = { id: newId, projectName: '', clockIn: '', clockOut: '' };
            tempRecordData.push(newRecord);
            setRecordData(tempRecordData);
            return [2 /*return*/];
        });
    }); }, [recordData]);
    /**
     * Main Table Related
     */
    return !tableData || !shiftRid ? (_jsx("div", { className: "BlockTableWrapper", children: _jsx(Skeleton, { active: true }) })) : (_jsx("div", { className: "BlockTableWrapper ProjectTrackingTable", children: _jsx(TableBuilder, { columns: columnData, data: recordData, footer: _jsx(ZiraActionButton, { className: "AddNewRecordRowBtn ml-0.5 flex items-center p-2 font-medium text-gray-300 hover:cursor-pointer", onClickFn: onAddNewRecord, icon: _jsx(PlusOutlined, {}), children: "New Project Tracking Row" }), scrollContainerClassName: "ModalBody" }) }));
};
export default React.memo(ProjectTrackingTable);
