var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation, useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { accountIdState } from 'src/state';
import { useFetch } from './useFetch';
export var useHealthMetricApi = function () {
    var request = useFetch().request;
    var accountId = useRecoilValue(accountIdState);
    var getHealthMetricRules = function () {
        return request({
            method: 'GET',
            url: 'health_metrics/rule_list',
        });
    };
    var useGetHealthMetricRulesQuery = function () {
        return useQuery(['rule_list', accountId], getHealthMetricRules);
    };
    var getSingleMetricRule = function (ruleId) {
        return request({
            method: 'GET',
            url: "health_metrics/rule/".concat(ruleId),
        });
    };
    var useGetSingleMetricRuleQuery = function (req) {
        return useQuery(['rule_list', accountId, req.ruleId], function () { return getSingleMetricRule(req.ruleId); }, req.options);
    };
    var deleteMetricRule = function (ruleId) {
        return request({
            method: 'DELETE',
            url: "health_metrics/delete_rule/".concat(ruleId),
        });
    };
    var useDeleteMetricRuleMutation = function () {
        return useMutation(deleteMetricRule);
    };
    var getHealthMetricData = function (body) {
        return request({
            method: 'POST',
            url: 'health_metrics/get_health_metric_data',
        }, body);
    };
    var useGetHealthMetricDataQuery = function (request) {
        var _a, _b, _c;
        return useQuery(__spreadArray(__spreadArray([
            'health_metrics_data'
        ], (((_a = request.body) === null || _a === void 0 ? void 0 : _a.rules.map(function (r) { return JSON.stringify(r); })) || []), true), [
            accountId,
        ], false), function () { return getHealthMetricData(request.body); }, {
            enabled: (((_b = request.options) === null || _b === void 0 ? void 0 : _b.enabled) || true) && !!request.body,
            refetchInterval: ((_c = request.options) === null || _c === void 0 ? void 0 : _c.refetchInterval) || false,
        });
    };
    var getHealthMetricConfigOptions = function () {
        return request({
            method: 'GET',
            url: 'health_metrics/config_options',
        });
    };
    var useGetHealthMetricConfigOptionsQuery = function (options) {
        return useQuery(['health_metrics/config_options', accountId], getHealthMetricConfigOptions, options);
    };
    var createHealthMetricRuleRequest = function (body) {
        return request({
            method: 'POST',
            url: 'health_metrics/create_rule',
        }, body);
    };
    var updateHealthMetricRuleRequest = function (body) {
        return request({
            method: 'POST',
            url: 'health_metrics/update_rule',
        }, body);
    };
    var getPresetConfig = function (preset) {
        return request({
            method: 'GET',
            url: "health_metrics/preset_config/".concat(preset),
        });
    };
    var useGetPresetConfigQuery = function (req) {
        return useQuery(['health_metrics/preset_config', accountId], function () { return getPresetConfig(req.preset); }, req.options);
    };
    return {
        useGetHealthMetricRulesQuery: useGetHealthMetricRulesQuery,
        useGetSingleMetricRuleQuery: useGetSingleMetricRuleQuery,
        useGetHealthMetricDataQuery: useGetHealthMetricDataQuery,
        useDeleteMetricRuleMutation: useDeleteMetricRuleMutation,
        useGetHealthMetricConfigOptionsQuery: useGetHealthMetricConfigOptionsQuery,
        createHealthMetricRuleRequest: createHealthMetricRuleRequest,
        updateHealthMetricRuleRequest: updateHealthMetricRuleRequest,
        useGetPresetConfigQuery: useGetPresetConfigQuery,
    };
};
