import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import SelectorCell from 'src/components/TableView/TableCell/SelectorCell';
var UserSelectorCell = function (props) {
    var userRid = props.userRid, userMap = props.userMap, userList = props.userList, updateRecordSelectedUser = props.updateRecordSelectedUser, selectedUsers = props.selectedUsers;
    var _a = useState(false), dropdownOpen = _a[0], setDropdownOpen = _a[1];
    var displayedOption = useMemo(function () {
        var _a, _b;
        var selectedUser = userRid ? userMap[userRid] : undefined;
        if (!selectedUser)
            return [];
        return [
            {
                id: selectedUser.id,
                text: selectedUser.name,
                color: (_a = selectedUser.color) !== null && _a !== void 0 ? _a : '',
                userAvatar: (_b = selectedUser.image) !== null && _b !== void 0 ? _b : '',
            },
        ];
    }, [userMap, userRid]);
    var availableOptions = useMemo(function () {
        var availableUsers = userList.filter(function (item) { return !selectedUsers.includes(item.id); });
        return availableUsers.map(function (item) {
            return {
                id: item.id,
                text: item.name,
                userAvatar: item.image,
                color: '',
            };
        });
    }, [selectedUsers, userList]);
    return (_jsx("div", { className: "UserSelectorCellWrapper ".concat(!userRid ? 'ShiftTemplateSelectorCellWrapperWithWarning' : ''), children: _jsx(SelectorCell, { canEdit: true, inputMode: "single", displayedOption: displayedOption, availableOption: availableOptions, dropdownOpen: dropdownOpen, setDropdownOpen: setDropdownOpen, mutateDisplayedValueInItem: function (values) {
                updateRecordSelectedUser(values[0]);
            }, isUserSelecting: true }) }));
};
export default React.memo(UserSelectorCell);
