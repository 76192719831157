import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var CustomIconInline = function (_a) {
    var iconUrl = _a.iconUrl, backgroundColor = _a.backgroundColor, _b = _a.iconSize, iconSize = _b === void 0 ? 16 : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.radius, radius = _d === void 0 ? 8 : _d;
    return (_jsx("div", { className: "flex cursor-pointer items-center justify-center text-white TBIconContainer ".concat(className), style: {
            backgroundColor: backgroundColor,
            height: iconSize + 6,
            width: iconSize + 6,
            borderRadius: "".concat(radius, "px"),
        }, children: _jsx("img", { height: iconSize, width: iconSize, src: "data:image/svg+xml;utf8,".concat(encodeURIComponent(backgroundColor ? iconUrl : iconUrl.replaceAll('#ffffff', '#000000'))) }) }));
};
export default React.memo(CustomIconInline);
