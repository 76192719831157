var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Switch } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { NEEDS_COVERAGE, NEW_SHIFT_TEMPLATE } from 'src/components/FeatureGatingKeyConstant';
import { featureEnableStatusState } from 'src/state/selectors/withAccountFeatureFlagState';
import { ifUserHasGeneralPermission, scheduleBottomBarSettingsSelector } from 'src/state';
import { GeneralPermissionKey } from 'src/components/Settings/Permission/Constants';
import { useScheduleComponent } from 'src/hooks/component';
import { SchedulingSummaryType } from './interface';
import { scheduleBottomBarTabsMap } from './Constant';
var BottomSectionTabConfigDropdown = function (_a) {
    var blockId = _a.blockId;
    var _b = useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var bottomBarSettings = useRecoilValue(scheduleBottomBarSettingsSelector(blockId));
    var bottomSectionTabOptions = bottomBarSettings.bottomBarTabs;
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    var tabOptionVisibleOnChange = useCallback(function (type, visible) {
        var newOptionsSetting = cloneDeep(bottomSectionTabOptions);
        newOptionsSetting[type] = visible;
        applyScheduleDisplaySettings(blockId, {
            bottomBarSettings: __assign(__assign({}, bottomBarSettings), { bottomBarTabs: newOptionsSetting }),
        });
    }, [applyScheduleDisplaySettings, blockId, bottomBarSettings, bottomSectionTabOptions]);
    var handleMenuClick = useCallback(function (menuInfo) {
        var key = menuInfo.key;
        tabOptionVisibleOnChange(key, !bottomSectionTabOptions[key]);
    }, [bottomSectionTabOptions, tabOptionVisibleOnChange]);
    var isNewShiftTemplateEnabled = useRecoilValue(featureEnableStatusState(NEW_SHIFT_TEMPLATE));
    var isNeedsCoverageFeatureEnabled = useRecoilValue(featureEnableStatusState(NEEDS_COVERAGE));
    var isNeedsCoveragePermissionAllowed = useRecoilValue(ifUserHasGeneralPermission(GeneralPermissionKey.NEEDS_COVERAGE));
    var needAndCoverageEnabled = isNeedsCoverageFeatureEnabled &&
        isNeedsCoveragePermissionAllowed &&
        isNewShiftTemplateEnabled;
    var items = useMemo(function () {
        return Object.entries(scheduleBottomBarTabsMap).map(function (_a) {
            var key = _a[0], info = _a[1];
            var tabType = key;
            if ((tabType === SchedulingSummaryType.NEEDS_AND_COVERAGE ||
                tabType === SchedulingSummaryType.DEMAND_RATIO) &&
                !needAndCoverageEnabled) {
                return null;
            }
            return {
                key: tabType,
                icon: info.icon,
                label: (_jsxs("div", { className: "flex items-center justify-between", children: [_jsx("div", { children: info.name }), _jsx(Switch, { className: "BlackSwitch", checked: bottomSectionTabOptions[tabType], size: "small" })] })),
            };
        });
    }, [bottomSectionTabOptions, needAndCoverageEnabled]);
    return (_jsx(Dropdown, { menu: { items: items, onClick: handleMenuClick }, trigger: ['click'], overlayClassName: "BottomSectionTabConfigDropdown", placement: "topLeft", overlayStyle: { width: 250 }, open: dropdownOpen, onOpenChange: function (open) { return setDropdownOpen(open); }, children: _jsx("div", { className: "mr-2 cursor-pointer rounded-lg bg-gray-40 px-2 py-1", children: _jsx(MoreOutlined, { className: "text-[16px]" }) }) }));
};
export default React.memo(BottomSectionTabConfigDropdown);
