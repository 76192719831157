import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetting } from 'src/hooks/api';
import { payRateDataHasDuplicateQualifiers } from '../../util';
import PayRateTable from './PayRateTable';
var AccountPayRatesWrapper = function (_a) {
    var enabledStatuses = _a.enabledStatuses, recordQualifierId = _a.recordQualifierId, defaultQualifiers = _a.defaultQualifiers, onPayRateChange = _a.onPayRateChange;
    var _b = useSetting(), useGetAccountPayRateSettingData = _b.useGetAccountPayRateSettingData, useGetRecordQualifierPayRateSettingData = _b.useGetRecordQualifierPayRateSettingData;
    var isPayEnabled = (enabledStatuses !== null && enabledStatuses !== void 0 ? enabledStatuses : {}).accountRatesEnabled;
    var _c = useGetAccountPayRateSettingData({
        enabled: isPayEnabled && !recordQualifierId,
    }), fetchPayRateData = _c.data, isLoadingAccountPayRates = _c.isLoading;
    var _d = useGetRecordQualifierPayRateSettingData(recordQualifierId, {
        enabled: isPayEnabled && !!recordQualifierId,
    }), fetchRecordQualifierPayRateData = _d.data, isLoadingRecordQualifierPayRates = _d.isLoading;
    var _e = useState(), payRateData = _e[0], setPayRateData = _e[1];
    useEffect(function () {
        if (fetchRecordQualifierPayRateData && recordQualifierId) {
            setPayRateData(fetchRecordQualifierPayRateData);
        }
        else if (fetchPayRateData) {
            setPayRateData(fetchPayRateData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPayRateData, fetchRecordQualifierPayRateData]);
    var payRateDataOnChange = useCallback(function (newData) {
        setPayRateData(newData);
        onPayRateChange(newData);
    }, [onPayRateChange]);
    // check duplicate qualifier setting
    var hasDuplicateQualifierWages = useMemo(function () {
        if (!payRateData)
            return false;
        return payRateDataHasDuplicateQualifiers(payRateData);
    }, [payRateData]);
    if (isLoadingAccountPayRates || isLoadingRecordQualifierPayRates)
        return _jsx(_Fragment, {});
    return (_jsx(_Fragment, { children: payRateData && (_jsx(PayRateTable, { payRateData: payRateData, payRateDataOnChange: payRateDataOnChange, canEdit: true, showDuplicateWarning: hasDuplicateQualifierWages, defaultQualifiers: defaultQualifiers })) }));
};
export default React.memo(AccountPayRatesWrapper);
