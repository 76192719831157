var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { selectorFamily } from 'recoil';
import { shiftTemplateNativeSchemaNativePathSet, shiftTemplateCustomSchemaInputModeSet, shiftTemplateCustomSchemaTypeSet, } from 'src/components/ScheduleView/NewShiftTemplate/Constant';
import { NativePath } from 'src/shared';
import { collectionTableSchemasState } from 'src/state/atoms';
var shiftTemplateSchemaOptionsState = selectorFamily({
    key: 'shiftTemplateSchemaOptionsState',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var shiftCollectionSchemas = get(collectionTableSchemasState(collectionId));
            var templateNativeSchemaOptions = [];
            var templateCustomSchemaOptions = [];
            shiftCollectionSchemas.map(function (schema) {
                var nativePath = schema === null || schema === void 0 ? void 0 : schema.nativePath;
                if (nativePath && shiftTemplateNativeSchemaNativePathSet.has(nativePath)) {
                    templateNativeSchemaOptions.push(schema);
                }
                else if (shiftTemplateCustomSchemaInputModeSet.has(schema.inputMode) &&
                    !shiftTemplateCustomSchemaTypeSet.has(schema.type)) {
                    templateCustomSchemaOptions.push(schema);
                }
            });
            return __spreadArray(__spreadArray([], templateNativeSchemaOptions, true), templateCustomSchemaOptions, true);
        };
    },
});
var shiftTemplateDefaultSchemasState = selectorFamily({
    key: 'shiftTemplateDefaultSchemasState',
    get: function (collectionId) {
        return function (_a) {
            var get = _a.get;
            var shiftCollectionSchemas = get(collectionTableSchemasState(collectionId));
            var defaultSchemas = [];
            shiftCollectionSchemas.map(function (schema) {
                var nativePath = schema === null || schema === void 0 ? void 0 : schema.nativePath;
                if (nativePath &&
                    (nativePath === NativePath.SHIFT_LOCATION_ID ||
                        nativePath === NativePath.SHIFT_ROLE_IDS)) {
                    defaultSchemas.push(schema.id);
                }
            });
            return defaultSchemas;
        };
    },
});
export { shiftTemplateSchemaOptionsState, shiftTemplateDefaultSchemasState };
