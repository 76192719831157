import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Skeleton } from 'antd';
import { useInView } from 'react-intersection-observer';
import { useInitBlockViews } from 'src/hooks/component';
import { tableCollectionInfoState, tableGroupBySettingSelector, tableSelectedViewIdState, } from 'src/state';
import { CollectionType } from 'src/shared';
import BlockCollectionBoardView from './BlockCollectionBoardView';
import BlockCollectionTable from './BlockCollectionTable';
import TableOptionHeaderFromBlock, { BlockHoverOptionButton, } from './OptionHeader/TableOptionHeaderFromBlock';
var BlockCollectionView = function (_a) {
    var blockId = _a.blockId, isPayPeriodModal = _a.isPayPeriodModal;
    var groupSettings = useRecoilValue(tableGroupBySettingSelector(blockId));
    var isBoardView = !!groupSettings;
    var viewId = useRecoilValue(tableSelectedViewIdState(blockId));
    var collectionInfo = useRecoilValue(tableCollectionInfoState(blockId));
    var _b = useState(true), isLoadingRecords = _b[0], setIsLoadingRecords = _b[1];
    var _c = useInView({
        root: document.querySelector('#CenterPanelContent'),
        triggerOnce: true,
    }), inView = _c.inView, ref = _c.ref;
    var initialBlockViews = useInitBlockViews(blockId, isLoadingRecords && inView);
    var initialDisplaySettings = initialBlockViews === null || initialBlockViews === void 0 ? void 0 : initialBlockViews.displaySettings[viewId];
    var isPayPeriodModalShiftOrTimeoff = useMemo(function () {
        if (isPayPeriodModal) {
            if ((collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.type) == CollectionType.TIMEOFF) {
                return 'TIMEOFF';
            }
            else if ((collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.type) == CollectionType.SHIFT) {
                return 'SHIFT';
            }
        }
        return undefined;
    }, [isPayPeriodModal, collectionInfo === null || collectionInfo === void 0 ? void 0 : collectionInfo.type]);
    return !viewId || !initialBlockViews ? (_jsxs("div", { className: "BlockTableWrapper my-[100px]", ref: ref, children: [_jsx(BlockHoverOptionButton, { blockId: blockId }), _jsx(Skeleton, { active: true })] })) : (_jsxs(_Fragment, { children: [collectionInfo && (_jsx(TableOptionHeaderFromBlock, { blockId: blockId, collectionType: collectionInfo.type, isPayPeriodModal: isPayPeriodModal })), (isLoadingRecords ? initialDisplaySettings : true) && (_jsx(_Fragment, { children: isBoardView ? (_jsx(BlockCollectionBoardView, { blockId: blockId, initialDisplaySettings: initialDisplaySettings, setIsLoading: setIsLoadingRecords, isLoading: isLoadingRecords })) : (_jsx(BlockCollectionTable, { blockId: blockId, initialDisplaySettings: initialDisplaySettings, setIsLoading: setIsLoadingRecords, isLoading: isLoadingRecords, isPayPeriodModal: isPayPeriodModalShiftOrTimeoff })) }))] }));
};
export default React.memo(BlockCollectionView);
