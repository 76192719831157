var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from 'antd';
import React, { useCallback, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flatfile } from '@flatfile/sdk';
import { usePageApi } from 'src/hooks/api';
import { CellInputMode } from 'src/shared';
import { useRecordComponent } from 'src/hooks/component';
import { isAutomationPageModalVisibleState, isSingleAutomationModalVisibleState, automationPageTabKeyState, } from 'src/state/atoms/automationModalState';
import { useDashboardApi } from 'src/hooks/api/useDashboardApi';
import { currentGeneralPermissions } from 'src/state';
import { useDashboardComponent } from 'src/hooks/component/useDashboard';
import { OnboardingProgressListItemType } from '../Constant';
import AutomationDashboardOnboardingModalImg from '../../../../utils/icons/images/AutomationDashboardOnboardingModalImg.png';
var DashboardOnboardingModal = function (props) {
    var isVisible = props.isVisible, closeModal = props.closeModal, itemInfo = props.itemInfo, refetch = props.refetch;
    var clickedActionButton = useRef(false);
    var currentPermissions = useRecoilValue(currentGeneralPermissions);
    var setAutomationModalVisible = useSetRecoilState(isAutomationPageModalVisibleState);
    var setSingleAutomationModalVisible = useSetRecoilState(isSingleAutomationModalVisibleState);
    var setAutomationPageTabKey = useSetRecoilState(automationPageTabKeyState);
    var updateOnboardingStatus = useDashboardApi().updateOnboardingStatus;
    var onCreateNewRecord = useRecordComponent().onCreateNewRecord;
    var _a = usePageApi(), useGetCollectionSchemas = _a.useGetCollectionSchemas, useGetFlatFileToken = _a.useGetFlatFileToken, useImportByFlatFile = _a.useImportByFlatFile;
    var getTargetCollectionId = useDashboardComponent().getTargetCollectionId;
    var targetCollectionId = getTargetCollectionId(itemInfo.type);
    var collectionSchemas = useGetCollectionSchemas({
        body: { collectionId: targetCollectionId },
        options: { enabled: !!targetCollectionId },
    }).data;
    var flatFileToken = useGetFlatFileToken(targetCollectionId, {
        enabled: !!targetCollectionId,
    }).data;
    var customFieldsForFlatFile = useMemo(function () {
        var result = [];
        if (!collectionSchemas)
            return result;
        var schemas = Object.values(collectionSchemas);
        schemas.forEach(function (schema) {
            if (schema.inputMode !== CellInputMode.NATIVE) {
                result.push({
                    field: schema.id,
                    type: 'string',
                    label: schema.name,
                });
            }
        });
        return result;
    }, [collectionSchemas]);
    var onLaunchFlatFile = useCallback(function () {
        if (flatFileToken && targetCollectionId) {
            Flatfile.requestDataFromUser({
                token: flatFileToken,
                customFields: customFieldsForFlatFile,
                onComplete: function (_a) {
                    var batchId = _a.batchId;
                    useImportByFlatFile.mutate({
                        flatFileBatchId: batchId,
                        collectionId: targetCollectionId,
                    });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flatFileToken, targetCollectionId, customFieldsForFlatFile]);
    var openNewUserOrShiftDetailModal = useCallback(function () {
        if (!targetCollectionId)
            return;
        onCreateNewRecord('', targetCollectionId);
    }, [onCreateNewRecord, targetCollectionId]);
    var onCheckListItem = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var request, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (itemInfo.isFinished)
                        return [2 /*return*/];
                    request = {
                        itemType: itemInfo.type,
                        isFinished: true,
                    };
                    return [4 /*yield*/, updateOnboardingStatus(request)];
                case 1:
                    resp = _a.sent();
                    return [2 /*return*/, resp];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemInfo]);
    var renderVideoOrImg = useMemo(function () {
        if (itemInfo.type === OnboardingProgressListItemType.AUTOMATION) {
            return _jsx("img", { src: AutomationDashboardOnboardingModalImg, className: "w-full" });
        }
        else {
            return (itemInfo.actionModalContent.videoUrl && (_jsx("video", { src: itemInfo.actionModalContent.videoUrl, controls: true, className: "w-[90%]" })));
        }
    }, [itemInfo]);
    var renderModalActionButtons = useMemo(function () {
        if ((itemInfo.type === OnboardingProgressListItemType.USER ||
            itemInfo.type === OnboardingProgressListItemType.SHIFT) &&
            targetCollectionId) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "WhiteBgActionButton", onClick: function () {
                            clickedActionButton.current = true;
                            openNewUserOrShiftDetailModal();
                        }, children: "Add Manually" }), _jsx("div", { className: "BlackBgActionButton", onClick: function () {
                            clickedActionButton.current = true;
                            onLaunchFlatFile();
                        }, children: "Import CSV" })] }));
        }
        else if (itemInfo.type === OnboardingProgressListItemType.AUTOMATION &&
            (currentPermissions === null || currentPermissions === void 0 ? void 0 : currentPermissions.AUTOMATION)) {
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "WhiteBgActionButton", onClick: function () {
                            clickedActionButton.current = true;
                            setAutomationModalVisible(true);
                            setSingleAutomationModalVisible(true);
                        }, children: "Start from scratch" }), _jsx("div", { className: "BlackBgActionButton", onClick: function () {
                            clickedActionButton.current = true;
                            setAutomationModalVisible(true);
                            setAutomationPageTabKey('Templates');
                        }, children: "Automation templates" })] }));
        }
        else {
            return _jsx(_Fragment, {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentPermissions === null || currentPermissions === void 0 ? void 0 : currentPermissions.AUTOMATION,
        targetCollectionId,
        itemInfo.type,
        onLaunchFlatFile,
        openNewUserOrShiftDetailModal,
        setAutomationModalVisible,
        setAutomationPageTabKey,
        setSingleAutomationModalVisible,
    ]);
    var onCloseModal = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Early return conditions
                    if (itemInfo.isFinished ||
                        (clickedActionButton.current === false &&
                            itemInfo.type !== OnboardingProgressListItemType.GENERAL)) {
                        closeModal();
                        return [2 /*return*/];
                    }
                    if (!(itemInfo.type === OnboardingProgressListItemType.GENERAL ||
                        clickedActionButton.current === true)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onCheckListItem()];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        refetch();
                    }
                    if (itemInfo.type === OnboardingProgressListItemType.AUTOMATION) {
                        // Set automation page to default view
                        setAutomationPageTabKey('Manage');
                    }
                    clickedActionButton.current = false;
                    closeModal();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [itemInfo, onCheckListItem]);
    return (_jsxs(Modal, { open: isVisible, footer: null, className: "DashboardOnboardingModal", closable: false, maskStyle: { backgroundColor: 'rgba(31, 32, 41, 0.75)' }, centered: true, width: '50%', zIndex: 999, destroyOnClose: true, children: [_jsx(Button, { className: "ModalCloseButton", onClick: onCloseModal, children: "\u2715" }), _jsx("div", { className: "mb-5 flex justify-center", children: renderVideoOrImg }), _jsxs("div", { className: "flex flex-col items-center", children: [_jsx("div", { className: "mb-2 text-center text-xl font-medium", children: itemInfo.actionModalContent.title }), _jsx("div", { className: "mb-5 w-[90%] text-xs text-gray-300", children: itemInfo.actionModalContent.description }), _jsx("div", { className: "flex justify-center space-x-6", children: renderModalActionButtons })] })] }));
};
export default React.memo(DashboardOnboardingModal);
