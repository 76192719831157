var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var RefreshIconSvg = function () { return (_jsx("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "-0.5 -0.5 48 48", height: "48", width: "48", children: _jsxs("g", { children: [_jsx("path", { d: "M12.102 12.083a16.098 16.098 0 0 1 16.352 -3.917 2.448 2.448 0 1 0 1.488 -4.661A21.052 21.052 0 0 0 4.015 31.333a0.49 0.49 0 0 1 -0.196 0.587l-2.742 1.821a1.958 1.958 0 0 0 -0.842 1.958 1.958 1.958 0 0 0 1.528 1.547l8.636 1.919 0.392 0a1.958 1.958 0 0 0 1.077 -0.333 1.958 1.958 0 0 0 0.842 -1.234l1.782 -8.636a1.958 1.958 0 0 0 -0.822 -1.958 1.958 1.958 0 0 0 -2.174 0l-2.624 1.723a0.568 0.568 0 0 1 -0.431 0 0.548 0.548 0 0 1 -0.313 -0.313 16.215 16.215 0 0 1 3.975 -16.332Z", fill: "#000000", strokeWidth: "1" }), _jsx("path", { d: "M46.765 11.417a1.958 1.958 0 0 0 -1.488 -1.567L36.68 7.833a1.958 1.958 0 0 0 -2.35 1.469l-1.958 8.577a1.958 1.958 0 0 0 0.783 1.958 1.958 1.958 0 0 0 1.136 0.372 1.841 1.841 0 0 0 1.038 -0.313l2.82 -1.762a0.568 0.568 0 0 1 0.431 0 0.548 0.548 0 0 1 0.313 0.313A16.156 16.156 0 0 1 18.741 38.932a2.448 2.448 0 0 0 -3.075 1.625 2.428 2.428 0 0 0 1.606 3.055 20.758 20.758 0 0 0 6.247 0.94A21.052 21.052 0 0 0 43.083 15.667a0.529 0.529 0 0 1 0.196 -0.607l2.644 -1.645a1.958 1.958 0 0 0 0.842 -1.998Z", fill: "#000000", strokeWidth: "1" })] }) })); };
export var RefreshIcon = function (props) { return (_jsx(Icon, __assign({ component: RefreshIconSvg }, props))); };
