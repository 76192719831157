import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import './PaginationRow.scss';
var PaginationRow = function (_a) {
    var isLoading = _a.isLoading, noRecordsFound = _a.noRecordsFound, onLoadMore = _a.onLoadMore, loadMoreText = _a.loadMoreText, recordType = _a.recordType, className = _a.className;
    var Text = Typography.Text;
    return (_jsxs("div", { className: "flex items-center space-x-2 p-2 font-medium text-gray-300 hover:cursor-pointer ".concat(className), onClick: onLoadMore, children: [_jsx(ArrowDownOutlined, {}), _jsx("span", { children: loadMoreText ? loadMoreText : 'Load More' }), isLoading && (_jsx(_Fragment, { children: _jsx("span", { className: "LoadingIcon", children: _jsx(LoadingOutlined, { style: { color: 'blue' }, spin: true }) }) })), !isLoading && noRecordsFound && (_jsxs(Text, { type: "warning", children: ["No ", recordType !== null && recordType !== void 0 ? recordType : 'records', " found."] }))] }));
};
export default React.memo(PaginationRow);
