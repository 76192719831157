var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var PendingShiftsIconSvg = function () { return (_jsxs("svg", { width: "32", height: "31", viewBox: "0 0 32 31", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.5", width: "31", height: "31", rx: "15.5", fill: "#FFC047" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 9.3335C12.3181 9.3335 9.33333 12.3183 9.33333 16.0002C9.33333 19.6821 12.3181 22.6668 16 22.6668C19.6819 22.6668 22.6667 19.6821 22.6667 16.0002C22.6667 12.3183 19.6819 9.3335 16 9.3335ZM16 7.66683C11.3976 7.66683 7.66666 11.3978 7.66666 16.0002C7.66666 20.6025 11.3976 24.3335 16 24.3335C20.6024 24.3335 24.3333 20.6025 24.3333 16.0002C24.3333 11.3978 20.6024 7.66683 16 7.66683Z", fill: "white" }), _jsx("path", { d: "M16.8333 16.4168C16.8333 16.6469 16.6468 16.8335 16.4167 16.8335H15.5833C15.3532 16.8335 15.1667 16.6469 15.1667 16.4168V12.2502C15.1667 12.02 15.3532 11.8335 15.5833 11.8335H16.4167C16.6468 11.8335 16.8333 12.02 16.8333 12.2502V16.4168Z", fill: "white" }), _jsx("path", { d: "M16.8333 19.7502C16.8333 19.9803 16.6468 20.1668 16.4167 20.1668H15.5833C15.3532 20.1668 15.1667 19.9803 15.1667 19.7502V18.9168C15.1667 18.6867 15.3532 18.5002 15.5833 18.5002H16.4167C16.6468 18.5002 16.8333 18.6867 16.8333 18.9168V19.7502Z", fill: "white" })] })); };
export var PendingShiftsIcon = function (props) { return (_jsx(Icon, __assign({ component: PendingShiftsIconSvg }, props))); };
