import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select } from 'antd';
import { useAccountApi } from 'src/hooks/api/useAccountApi';
import _ from 'lodash';
import { dropdownListFilterInputResult } from 'src/utils/Collection';
var Option = Select.Option, OptGroup = Select.OptGroup;
var LinkToSelectionSchemaConfigurationSection = function (props) {
    var onChangeOption = props.onChangeOption;
    var useGetAccountPlainSelectSchemasQuery = useAccountApi().useGetAccountPlainSelectSchemasQuery;
    var plainSelectSchemasData = useGetAccountPlainSelectSchemasQuery({
        options: {
            enabled: true,
        },
    }).data;
    var findSelectedSchema = useCallback(function (value) {
        if (!plainSelectSchemasData)
            return;
        var optionArray = Object.values(plainSelectSchemasData);
        for (var _i = 0, optionArray_1 = optionArray; _i < optionArray_1.length; _i++) {
            var option = optionArray_1[_i];
            var selectedSchema = _.find(option.plainSelectSchemas, function (schema) {
                return schema.schemaId === value;
            });
            if (selectedSchema) {
                return selectedSchema;
            }
        }
    }, [plainSelectSchemasData]);
    var onSelectChange = useCallback(function (value) {
        var selectedSchema = findSelectedSchema(value);
        if (!selectedSchema)
            return;
        onChangeOption(selectedSchema.refGroupId, selectedSchema.inputMode);
    }, [findSelectedSchema, onChangeOption]);
    var onFilterOption = useCallback(function (input, option) {
        var allSchemaOptions = [];
        if (!plainSelectSchemasData)
            return false;
        Object.values(plainSelectSchemasData).forEach(function (collection) {
            allSchemaOptions.push.apply(allSchemaOptions, collection.plainSelectSchemas);
        });
        var optionItem = allSchemaOptions.find(function (item) { return item.schemaId === option.value; });
        return optionItem ? dropdownListFilterInputResult(input, optionItem.schemaName) : false;
    }, [plainSelectSchemasData]);
    return (_jsxs("div", { children: [plainSelectSchemasData && (_jsx(Select, { placeholder: "Please select a column to link...", showSearch: true, className: "w-full", onChange: onSelectChange, filterOption: onFilterOption, children: _.entries(plainSelectSchemasData).map(function (_a) {
                    var collectionId = _a[0], collectionSchemas = _a[1];
                    return (_jsx(OptGroup, { label: collectionSchemas.collectionName, children: collectionSchemas.plainSelectSchemas.map(function (schema) { return (_jsx(Option, { value: schema.schemaId, children: _jsxs("div", { className: "flex justify-between", children: [_jsx("span", { className: "mr-[6px] font-medium", children: schema.schemaName }), _jsx("span", { className: "text-gray-300 overflow-hidden text-ellipsis", children: "Selection Mode: ".concat(schema.inputMode) })] }) }, schema.schemaId)); }) }, collectionId));
                }) })), _jsx("div", { className: "bg-gray-50 text-gray-400 text-xs font-medium rounded p-2 mt-3", children: "Link this column to an existing column from other collections." })] }));
};
export default React.memo(LinkToSelectionSchemaConfigurationSection);
