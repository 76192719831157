import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from 'antd';
import React from 'react';
import { useRecoilCallback } from 'recoil';
import { blockItemsSelector } from 'src/state';
import { BlockErrorIcon } from 'src/utils/icons/BlockErrorIcon';
import { BlockHoverOptionButton } from '../OptionHeader/TableOptionHeaderFromBlock';
import './BlockCollectionTableErrorBoundary.scss';
//TODO: time travel to previous state
var BlockCollectionTableErrorBoundary = function (_a) {
    var blockId = _a.blockId, pageId = _a.pageId;
    var title = useRecoilCallback(function (_a) {
        var snapshot = _a.snapshot;
        return function () {
            var _a;
            var blockName = (_a = snapshot
                .getLoadable(blockItemsSelector)
                .valueMaybe()
                .find(function (item) { return item.id === blockId; })) === null || _a === void 0 ? void 0 : _a.data.name;
            return blockName ? blockName : 'This block';
        };
    }, [blockId]);
    return (_jsxs("div", { className: "BlockTableWrapper", children: [pageId && _jsx(BlockHoverOptionButton, { blockId: blockId }), _jsx("div", { role: "alert", className: "BlockErrorDisplayContainer", children: _jsxs(Space, { size: 18, children: [_jsx(BlockErrorIcon, {}), _jsxs("div", { children: [_jsx("div", { className: "ErrorTitle", children: "Error" }), _jsxs("div", { className: "ErrorDescription", children: [title(), " failed to load. Please refresh the page to try again."] }), _jsx("div", { className: "ErrorDescription", children: "If the problem persists please contact tech support." })] })] }) })] }));
};
export default React.memo(BlockCollectionTableErrorBoundary);
