var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo } from 'react';
import { cloneDeep, orderBy } from 'lodash';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useChatApi } from 'src/hooks/api/useChatApi';
import { collectionTableModalRecordSelector, collectionTableSchemaNativePathMapSelector, currentUserState, } from 'src/state';
import { departmentsState, participantsInNewThread, selectedThreadState, threadSummaryListState, } from 'src/state/atoms/chatState';
import { selectedThreadSummarySelector } from 'src/state/selectors/withChatState';
import { useChatComponent } from 'src/hooks/component/useChat';
import { getNativeFieldCellContent } from 'src/utils/Collection';
import { NativePath } from 'src/shared';
import DirectMessageScreen from '../PageView/ChatPage/ChatContent/MessagesScreen/DirectMessageScreen';
import NewThreadScreen from '../PageView/ChatPage/ChatContent/NewThreadScreen/NewThreadScreen';
import { ThreadType, } from '../PageView/ChatPage/interface';
import ZiraRowsSkeleton from '../../ui/ZiraRowsSkeleton';
var currentUserThreadSummary = function (threadSummary, currentUserRid) {
    return threadSummary.filter(function (thread) {
        var participants = thread.participants;
        if (participants.length === 1) {
            // select thread current user self chat thread
            return participants[0].participantRid === currentUserRid;
        }
        else {
            // select thread in between user and department
            var currentUserParticipant = participants.find(function (participant) { return participant.participantRid === currentUserRid; });
            var otherDepartmentParticipant = participants.find(function (participant) { return participant.participantType === 'DEPARTMENT'; });
            return currentUserParticipant && otherDepartmentParticipant;
        }
    });
};
var ChatInRecordDetailModal = function (_a) {
    var _b;
    var dataSource = _a.dataSource, recordId = _a.recordId;
    var currentUserRid = (_b = useRecoilValue(currentUserState)) === null || _b === void 0 ? void 0 : _b.recordId;
    var modalRecord = useRecoilValue(collectionTableModalRecordSelector(dataSource));
    var nativeSchemaMap = useRecoilValue(collectionTableSchemaNativePathMapSelector(dataSource.collectionId));
    var recordUserName = useMemo(function () {
        var firstName = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.USER_FIRST_NAME);
        var lastName = getNativeFieldCellContent(modalRecord, nativeSchemaMap, NativePath.USER_LAST_NAME);
        if (!firstName && !lastName) {
            return 'User Name';
        }
        else {
            return "".concat(firstName, " ").concat(lastName);
        }
    }, [modalRecord, nativeSchemaMap]);
    var setInitialDepartments = useSetRecoilState(departmentsState);
    var setThreadSummaryList = useSetRecoilState(threadSummaryListState);
    var setSelectedThread = useSetRecoilState(selectedThreadState);
    var selectedThreadSummary = useRecoilValue(selectedThreadSummarySelector);
    var _c = useRecoilState(participantsInNewThread), participantsInThread = _c[0], setParticipantsInThread = _c[1];
    var _d = useChatComponent(), getThreadByParticipant = _d.getThreadByParticipant, sortedThread = _d.sortedThread;
    var _e = useChatApi(), useGetAllThreadsWithUser = _e.useGetAllThreadsWithUser, useGetDepartmentCanSendAs = _e.useGetDepartmentCanSendAs, getThreads = _e.getThreads;
    var _f = useGetAllThreadsWithUser(recordId !== null && recordId !== void 0 ? recordId : ''), recordDetailThreadSummaryList = _f.data, isLoadingThreadsInModal = _f.isLoading;
    var _g = useGetDepartmentCanSendAs(), allDepartments = _g.data, isLoadingAllDepartments = _g.isLoading;
    useEffect(function () {
        setInitialDepartments(allDepartments !== null && allDepartments !== void 0 ? allDepartments : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDepartments]);
    useEffect(function () {
        var _a;
        if (recordDetailThreadSummaryList) {
            if (!recordId || !currentUserRid) {
                return;
            }
            // Only support 1 to 1 chat in record modal and sort by last message time
            var oneToOneThreadSummaryList = sortedThread(recordDetailThreadSummaryList.filter(function (thread) { return thread.participants.length <= 2; }));
            // For current user record, filter out 1 to 1 chat with other individual user
            var availableThreadSummaryList = recordId !== currentUserRid
                ? oneToOneThreadSummaryList
                : currentUserThreadSummary(oneToOneThreadSummaryList, currentUserRid);
            setThreadSummaryList(availableThreadSummaryList);
            if (availableThreadSummaryList.length === 0) {
                var defaultParticipants = [
                    {
                        id: recordId,
                        type: 'USER',
                        name: recordUserName,
                    },
                ];
                setParticipantsInThread({ participants: defaultParticipants, type: ThreadType.DM });
            }
            else {
                setSelectedThread(availableThreadSummaryList[0].id);
                var option_1 = availableThreadSummaryList[0].participants.find(function (participant) { return participant.participantRid === recordId; });
                if (!option_1) {
                    return;
                }
                var tempParticipantsInThread = cloneDeep(participantsInThread);
                var prevParticipantsInThread = tempParticipantsInThread
                    ? tempParticipantsInThread
                    : { participants: [], type: ThreadType.DM };
                var newParticipants = orderBy(orderBy(__spreadArray(__spreadArray([], ((_a = prevParticipantsInThread === null || prevParticipantsInThread === void 0 ? void 0 : prevParticipantsInThread.participants) !== null && _a !== void 0 ? _a : []).filter(function (participant) { return participant.id !== option_1.participantRid; }), true), [
                    {
                        id: option_1.participantRid,
                        type: option_1.participantType,
                        name: option_1.name,
                        color: option_1.color,
                        avatarUrl: option_1.avatarUrl,
                    },
                ], false), 'name'), 'type', 'desc');
                var newParticipantsInThread = __assign(__assign({}, prevParticipantsInThread), { participants: newParticipants });
                setParticipantsInThread(newParticipantsInThread);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserRid, recordDetailThreadSummaryList, recordId, recordUserName]);
    // select thread if thread with same participants already exists
    useEffect(function () {
        if (participantsInThread === null || participantsInThread === void 0 ? void 0 : participantsInThread.participants.length) {
            var thread = getThreadByParticipant(participantsInThread);
            setSelectedThread(thread === null || thread === void 0 ? void 0 : thread.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participantsInThread]);
    var fetchAccountThreads = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getThreads()];
                case 1:
                    resp = _a.sent();
                    if (resp) {
                        setThreadSummaryList(resp);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, []);
    // reset account chat state when close chat in modal
    useEffect(function () {
        return function () {
            fetchAccountThreads();
            setSelectedThread(undefined);
            setParticipantsInThread(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (isLoadingAllDepartments || isLoadingThreadsInModal) {
        return (_jsx("div", { className: "w-full ChatInRecordDetailModalContainer", children: _jsx(ZiraRowsSkeleton, { rowNumber: 10 }) }));
    }
    return (_jsx("div", { className: "w-full ChatInRecordDetailModalContainer", children: selectedThreadSummary ? (_jsx("div", { className: "ChatContentScreen", children: _jsx(DirectMessageScreen, {}) })) : (_jsx(NewThreadScreen, { isInRecordModal: true })) }));
};
export default React.memo(ChatInRecordDetailModal);
