import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'antd';
import './ADPSetUpCard.scss';
var ADPSetUpCard = function () {
    return (_jsx("div", { children: _jsxs("div", { className: "ADPSetUpCardContainer", children: [_jsx("div", { className: "CardTitle", children: "Connect ADP" }), _jsx("div", { className: "CardDescriptionText", children: "If you have an ADP account, please click the link below and follow the steps there to purchase a Connector. Once a Connector is purchased, you also need to authorize TeamBridge to access your data. Once TeamBridge is authorized, it takes up to 10 mins before you could start matching users. If you have any questions, please contact TeamBridge support." }), _jsx(Button, { className: "ADPOptionBtn", onClick: function () {
                        window.open('https://apps.adp.com/en-US/apps/392052', '_blank');
                    }, children: "Purchase Connector" })] }) }));
};
export default React.memo(ADPSetUpCard);
