var _a, _b;
import { OPTIONS_COLOR_MAP } from 'src/shared';
export var ShiftRecommendationsView;
(function (ShiftRecommendationsView) {
    ShiftRecommendationsView["ALL"] = "all";
    ShiftRecommendationsView["RECOMMENDED"] = "recommended";
})(ShiftRecommendationsView || (ShiftRecommendationsView = {}));
export var ShiftRecommendationStatus;
(function (ShiftRecommendationStatus) {
    ShiftRecommendationStatus["ASSIGNED"] = "ASSIGNED";
    ShiftRecommendationStatus["ACCEPTED"] = "ACCEPTED";
    ShiftRecommendationStatus["PENDING"] = "PENDING";
    ShiftRecommendationStatus["REJECTED"] = "REJECTED";
})(ShiftRecommendationStatus || (ShiftRecommendationStatus = {}));
export var ShiftRecommendationGroupType;
(function (ShiftRecommendationGroupType) {
    ShiftRecommendationGroupType["TOP_MATCHES"] = "TOP_MATCHES";
    ShiftRecommendationGroupType["QUALIFIED"] = "QUALIFIED";
    ShiftRecommendationGroupType["NOT_QUALIFIED"] = "NOT_QUALIFIED";
    ShiftRecommendationGroupType["ASSIGNED"] = "ASSIGNED";
    ShiftRecommendationGroupType["ACCEPTED"] = "ACCEPTED";
    ShiftRecommendationGroupType["PENDING"] = "PENDING";
    ShiftRecommendationGroupType["REJECTED"] = "REJECTED";
})(ShiftRecommendationGroupType || (ShiftRecommendationGroupType = {}));
export var ShiftRecommendationMatchGroupSet = new Set([
    ShiftRecommendationGroupType.TOP_MATCHES,
    ShiftRecommendationGroupType.QUALIFIED,
    ShiftRecommendationGroupType.NOT_QUALIFIED,
]);
export var ShiftRecommendationStatusGroupSet = new Set([
    ShiftRecommendationGroupType.ASSIGNED,
    ShiftRecommendationGroupType.ACCEPTED,
    ShiftRecommendationGroupType.PENDING,
    ShiftRecommendationGroupType.REJECTED,
]);
export var ShiftRecommendationGroupColorMap = (_a = {},
    _a[ShiftRecommendationStatus.ASSIGNED] = OPTIONS_COLOR_MAP.Green,
    _a[ShiftRecommendationStatus.ACCEPTED] = OPTIONS_COLOR_MAP.Blue,
    _a[ShiftRecommendationStatus.PENDING] = OPTIONS_COLOR_MAP.Yellow,
    _a[ShiftRecommendationStatus.REJECTED] = OPTIONS_COLOR_MAP.Red,
    _a[ShiftRecommendationGroupType.TOP_MATCHES] = OPTIONS_COLOR_MAP.Green,
    _a[ShiftRecommendationGroupType.QUALIFIED] = OPTIONS_COLOR_MAP.Yellow,
    _a[ShiftRecommendationGroupType.NOT_QUALIFIED] = OPTIONS_COLOR_MAP.Red,
    _a);
export var ShiftRecommendationGroupDisplayNameMap = (_b = {},
    _b[ShiftRecommendationStatus.ASSIGNED] = 'Assigned',
    _b[ShiftRecommendationStatus.ACCEPTED] = 'Requested',
    _b[ShiftRecommendationStatus.PENDING] = 'Pending',
    _b[ShiftRecommendationStatus.REJECTED] = 'Dismissed',
    _b[ShiftRecommendationGroupType.TOP_MATCHES] = 'Top Matches',
    _b[ShiftRecommendationGroupType.QUALIFIED] = 'Qualified',
    _b[ShiftRecommendationGroupType.NOT_QUALIFIED] = 'Not Qualified',
    _b);
export var SHIFT_RECOMMENDATION_GROUP_PAGE_SIZE = 10;
export var SHIFT_RECOMMENDATIONS_MODAL_PADDING = 24;
export var SHIFT_RECOMMENDATIONS_USER_ITEM_HEIGHT = 65;
export var SHIFT_RECOMMENDATIONS_RECOMMENDATION_BUTTON_WIDTH = 144;
