var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { DatePicker, Modal } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import ZiraModalFooter from 'src/ui/ZiraModal/ZiraModalFooter';
import { useSetting } from '../../../../../hooks/api/useSettingApi';
var PayRateRematchShiftsModal = function (_a) {
    var isModalVisible = _a.isModalVisible, setIsModalVisible = _a.setIsModalVisible;
    var rematchShiftsWage = useSetting().rematchShiftsWage;
    var _b = useState(null), startFromDate = _b[0], setStartFromDate = _b[1];
    var onStartFromDateChange = useCallback(function (date) {
        setStartFromDate(date ? moment(date).format('YYYY-MM-DD') : null);
    }, []);
    var confirmRematchShiftsWage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!startFromDate) {
                toast('Must select a date to rematch shifts: Please select a date and try again.', {
                    type: 'error',
                    position: toast.POSITION.BOTTOM_CENTER,
                });
                return [2 /*return*/];
            }
            rematchShiftsWage({ startAt: startFromDate, overrideExisting: true });
            setIsModalVisible(false);
            return [2 /*return*/];
        });
    }); }, [rematchShiftsWage, setIsModalVisible, startFromDate]);
    return (_jsxs(Modal, { open: isModalVisible, centered: true, closable: false, footer: _jsx(ZiraModalFooter, { onSave: confirmRematchShiftsWage, saveBtnText: "Rematch Shifts", onCancel: function () { return setIsModalVisible(false); } }), children: [_jsx("div", { className: "mt-3 font-medium", children: "Select a date below to rematch all shifts from that date to the future." }), _jsx(DatePicker, { className: "mt-[16px]", showToday: false, value: startFromDate ? moment(startFromDate) : null, inputReadOnly: true, onChange: onStartFromDateChange, allowClear: false, disabledDate: function (date) {
                    if (!date)
                        return true;
                    if (date.startOf('day').isAfter(moment().startOf('day'))) {
                        return true;
                    }
                    return false;
                } })] }));
};
export default React.memo(PayRateRematchShiftsModal);
