var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { isEqual } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CollectionTableType } from 'src/shared';
import { usePageApi } from 'src/hooks/api';
import { useRecordComponent, useTimeZone } from 'src/hooks/component';
import { collectionTableNonSchemaMutationsState, collectionTableNonSchemaNativeFieldsState, collectionTableRecordState, collectionTableSchemaMutationsState, collectionTableSchemasState, collectionTableState, detailModalActiveTab, detailModalSchemaGroups, existingDetailModalInfo, } from 'src/state';
import { prevIpSettingUrlState } from 'src/state/atoms/integrationSettingState';
import { LoadingLogo } from 'src/utils/icons/LoadingLogo';
import ZiraModal from '../../ui/ZiraModal/ZiraModal';
import RecordDetailModal from './RecordDetailModal';
var RecordDetailModalWrapper = function (props) {
    var _a, _b;
    var defaultModalData = props.defaultModalData, recordId = props.recordId, bulkEditData = props.bulkEditData, isCreatingNew = props.isCreatingNew, setIsModalVisible = props.setIsModalVisible, schemaDefaults = props.schemaDefaults, defaultTimeZone = props.defaultTimeZone;
    var bulkMode = !!bulkEditData;
    var needsInit = !defaultModalData && !bulkMode && !isCreatingNew;
    var timeZone = useTimeZone(defaultTimeZone).timeZone;
    var _c = usePageApi(), useGetCollectionRecordDetailV3 = _c.useGetCollectionRecordDetailV3, useGetCollectionSchemaGroups = _c.useGetCollectionSchemaGroups;
    var editRecordCell = useRecordComponent().editRecordCell;
    var _d = useGetCollectionRecordDetailV3({
        recordId: recordId,
        options: {
            enabled: needsInit,
            cacheTime: 0,
        },
    }), modalData = _d.data, isFetchingModalData = _d.isFetching;
    var initialModalData = modalData !== null && modalData !== void 0 ? modalData : defaultModalData;
    var initDetailModalCollection = useRecoilCallback(function (_a) {
        var set = _a.set, reset = _a.reset;
        return function (collectionTableData) {
            var collectionId = collectionTableData.collectionInfo.id;
            var dataSource = {
                type: isCreatingNew
                    ? CollectionTableType.NEW_DETAIL_MODAL
                    : CollectionTableType.DETAIL_MODAL,
                collectionId: collectionId,
            };
            set(collectionTableState(dataSource), collectionTableData);
            set(collectionTableSchemasState(collectionId), function (pre) {
                if (isEqual(pre, collectionTableData.columns))
                    return pre;
                return collectionTableData.columns;
            });
            reset(collectionTableNonSchemaNativeFieldsState(dataSource));
            reset(collectionTableSchemaMutationsState(dataSource));
            reset(collectionTableNonSchemaMutationsState(dataSource));
            if (schemaDefaults === null || schemaDefaults === void 0 ? void 0 : schemaDefaults.length) {
                //! setTimeout so collectionTableSchemasState gets set first
                setTimeout(function () {
                    for (var _i = 0, schemaDefaults_1 = schemaDefaults; _i < schemaDefaults_1.length; _i++) {
                        var schemaDefault = schemaDefaults_1[_i];
                        editRecordCell(dataSource.type, { collectionId: collectionId, recordId: recordId, schemaId: schemaDefault.schemaId }, schemaDefault.newValues, timeZone);
                    }
                }, 0);
            }
            if (!isCreatingNew) {
                //! Update global record state if loading an existing record
                set(collectionTableRecordState({
                    type: CollectionTableType.TABLE,
                    collectionId: collectionId,
                    recordId: recordId,
                }), collectionTableData.cells[recordId]);
            }
        };
    }, [editRecordCell, isCreatingNew, recordId, schemaDefaults, timeZone]);
    useEffect(function () {
        if (initialModalData) {
            initDetailModalCollection(initialModalData.mainRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialModalData]);
    var _e = useGetCollectionSchemaGroups({
        collectionId: (_a = initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id) !== null && _a !== void 0 ? _a : '',
        options: {
            enabled: !!(initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id),
            cacheTime: 0,
        },
    }), schemaGroups = _e.data, isFetchingSchemaGroups = _e.isFetching;
    var setDetailModalSchemaGroup = useSetRecoilState(detailModalSchemaGroups((_b = initialModalData === null || initialModalData === void 0 ? void 0 : initialModalData.mainRecord.collectionInfo.id) !== null && _b !== void 0 ? _b : ''));
    var prevIpSettingUrl = useRecoilValue(prevIpSettingUrlState);
    useEffect(function () {
        if (schemaGroups) {
            setDetailModalSchemaGroup(schemaGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schemaGroups]);
    var setExistingDetailModalInfo = useSetRecoilState(existingDetailModalInfo);
    useEffect(function () {
        if (modalData) {
            setExistingDetailModalInfo(modalData.mainRecord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData]);
    var location = useLocation();
    var locationState = location.state;
    var navigate = useNavigate();
    var isStackedModal = !!((locationState === null || locationState === void 0 ? void 0 : locationState.referrer) ||
        ((new URLSearchParams(location.search).get('rid') ||
            new URLSearchParams(location.search).get('ridForPay')) &&
            isCreatingNew));
    var queryClient = useQueryClient();
    var resetActiveTab = useResetRecoilState(detailModalActiveTab(recordId));
    var closeModal = useCallback(function (isBack) {
        if ((locationState === null || locationState === void 0 ? void 0 : locationState.referrer) && !isCreatingNew && !isBack && isStackedModal) {
            navigate("".concat(location.pathname.split('?')[0]), { replace: true });
        }
        else if ((locationState === null || locationState === void 0 ? void 0 : locationState.referrer) && !isCreatingNew && isBack) {
            // Stacked record modal (Clicking "Save" or "Back")
            navigate(-1);
        }
        // Close under workspace view
        if (location.pathname.split('/')[1] === 'home') {
            if (isCreatingNew || bulkMode) {
                // New record/bulk edit modal
                setIsModalVisible === null || setIsModalVisible === void 0 ? void 0 : setIsModalVisible(false);
            }
            else {
                // Single record modal or stacked record modal (Clicking "X" or mask overlay)
                navigate("".concat(location.pathname.split('?')[0]), { replace: true });
            }
        }
        // Close under integration settings
        else if (location.pathname.split('/').includes('settings') &&
            location.pathname.split('/').includes('integrations')) {
            if (!isStackedModal) {
                navigate("".concat(prevIpSettingUrl), { replace: true });
            }
        }
        if (!isCreatingNew) {
            // Invalidate reverse collection queries so it refetch on detail modal open
            queryClient.invalidateQueries({
                predicate: function (query) {
                    return query.queryKey[0] === 'reverseCollection' && query.queryKey[3] === recordId;
                },
                refetchActive: false,
            });
        }
        resetActiveTab();
    }, [
        locationState === null || locationState === void 0 ? void 0 : locationState.referrer,
        isCreatingNew,
        location.pathname,
        resetActiveTab,
        navigate,
        bulkMode,
        setIsModalVisible,
        isStackedModal,
        prevIpSettingUrl,
        queryClient,
        recordId,
    ]);
    if (isFetchingModalData || isFetchingSchemaGroups || !initialModalData || !schemaGroups) {
        return (_jsx(ZiraModal, { show: true, onClose: closeModal, size: "lg", children: _jsx("div", { className: "logo-container h-full w-full", children: _jsx(LoadingLogo, { className: "main-loading-logo" }) }) }));
    }
    return (_jsx(RecordDetailModal, __assign({}, props, { defaultModalData: initialModalData, onClose: closeModal, isStackedModal: isStackedModal, defaultTimeZone: timeZone })));
};
export default React.memo(RecordDetailModalWrapper);
