var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@ant-design/icons';
var ShiftRequestMetricSvg = function () { return (_jsxs("svg", { width: "29", height: "29", viewBox: "0 0 29 29", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { width: "29", height: "29", rx: "14.5", fill: "#FFC047" }), _jsxs("g", { clipPath: "url(#clip0_7444_65701)", children: [_jsx("path", { d: "M11.6562 10.4785C12.6826 11.7311 13.3083 12.3571 14.5113 13.3335C15.7142 12.3571 16.34 11.7311 17.3663 10.4785", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M14.5 13.3184V6.91016", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M18.1439 15.4902H20.8523C21.5229 15.4902 22.0666 16.0339 22.0666 16.7045V19.185C22.0666 20.3777 21.2004 21.3938 20.0228 21.5829L19.3118 21.697C16.1251 22.2085 12.877 22.2085 9.6902 21.697L8.97925 21.5829C7.80163 21.3938 6.93555 20.3777 6.93555 19.185V16.7045C6.93555 16.0339 7.4792 15.4902 8.14983 15.4902H10.8582C11.5288 15.4902 12.0557 16.0598 12.3513 16.6618C12.6724 17.3158 13.3154 17.9188 14.501 17.9188C15.6867 17.9188 16.3297 17.3158 16.6508 16.6618C16.9463 16.0598 17.4733 15.4902 18.1439 15.4902Z", stroke: "white", strokeWidth: "1.5", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_7444_65701", children: _jsx("rect", { width: "17", height: "17", fill: "white", transform: "translate(6 6)" }) }) })] })); };
export var ShiftRequestMetricIcon = function (props) { return (_jsx(Icon, __assign({ component: ShiftRequestMetricSvg }, props))); };
