import { useMutation } from 'react-query';
import { useFetch } from './useFetch';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var useInit = function () {
    var customFetch = useFetch().request;
    var useCreateAccount = function () {
        return useMutation(function (request) {
            return customFetch({
                method: 'POST',
                url: 'accounts',
            }, request);
        });
    };
    var useResendEmailVerificationEmail = function () {
        return useMutation(function () {
            return customFetch({
                method: 'POST',
                url: 'users/resend_email_verification',
            });
        });
    };
    return {
        useCreateAccount: useCreateAccount,
        useResendEmailVerificationEmail: useResendEmailVerificationEmail,
    };
};
