import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Button, Popover } from 'antd';
import { StatusType } from 'src/components/CenterPanel/Interface';
import ZiraDeletePopconfirm from 'src/ui/ZiraDeletePopconfirm';
import './ShiftModalTemplateTagPopover.scss';
var ShiftModalTemplateTagPopover = function (props) {
    var template = props.template, onDetachExistingTemplate = props.onDetachExistingTemplate, lastUpdatedTime = props.lastUpdatedTime, children = props.children, canEdit = props.canEdit;
    var renderContent = useMemo(function () {
        var extraText = '';
        if (template.status.status === StatusType.ARCHIVED) {
            extraText = ", and it is already been archived ".concat(template.status.data ? "by ".concat(template.status.data.actor.name) : '', ".");
        }
        else if (template.status.status === StatusType.ACTIVE && lastUpdatedTime) {
            extraText = ", and it is outdated. It got updated on ".concat(lastUpdatedTime !== null && lastUpdatedTime !== void 0 ? lastUpdatedTime : '', " ").concat(template.status.data ? "by ".concat(template.status.data.actor.name) : '', ".");
        }
        var displayText = "This shift is created using template ".concat(template.name).concat(extraText ? extraText : '.');
        return (_jsxs("div", { className: "TemplateTagPopoverContent", children: [_jsx("div", { className: "mb-2", children: displayText }), canEdit && (_jsx(ZiraDeletePopconfirm, { title: _jsxs("div", { children: ["Are you sure you want detach this shift", _jsx("br", {}), "from template ", template.name, "?"] }), onConfirm: onDetachExistingTemplate, placement: "right", okText: "Yes", children: onDetachExistingTemplate && (_jsx(Button, { className: "DetachTemplateButton", children: "Detach Template" })) }))] }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template]);
    return (_jsx(Popover, { content: renderContent, trigger: "hover", placement: "top", destroyTooltipOnHide: true, overlayClassName: "ShiftModalTemplateTagPopover", children: children }));
};
export default React.memo(ShiftModalTemplateTagPopover);
