import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { OrganizationContext } from 'src/App';
import { useSetting } from 'src/hooks/api';
import DashboardMainSection from './DashboardMainSection';
import DashboardSiderSection from './DashboardSiderSection';
import './DashboardView.scss';
var DashboardView = function () {
    var orgMode = useContext(OrganizationContext);
    var useGetDashboardSettings = useSetting().useGetDashboardSettings;
    var dashboardSettings = useGetDashboardSettings().data;
    var shouldShowSiderSection = !!(dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings.showAccountOnboardingPanel);
    return (_jsxs("div", { className: "DashboardContentContainer", children: [_jsx(DashboardMainSection, {}), !orgMode && shouldShowSiderSection && _jsx(DashboardSiderSection, {})] }));
};
export default React.memo(DashboardView);
